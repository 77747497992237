import { forwardRef, Slider } from '@heroui/react'

const DurationRange = forwardRef((props, ref) => {
  return (
    <div className="flex flex-col gap-2">
      <Slider
        defaultValue={12}
        label="Max Duration"
        maxValue={120}
        formatOptions={{ style: 'unit', unit: 'minute' }}
        minValue={1}
        step={0.1}
        {...props}
        ref={ref}
        classNames={{
          label: 'text-small font-medium text-foreground',
        }}
      />
      <p className="text-tiny text-foreground-400">
        When the call starts, a timer is set for the
        {' '}
        <b>max_duration</b>
        {' '}
        minutes.
        At the end of that timer, if the call is still active it will be
        automatically ended.
      </p>
    </div>
  )
})

export default DurationRange
