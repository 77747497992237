// import LoadingAnimation from "components/Reusables/GradientLoadingAnimation";
import GradientLoadingAnimation from 'components/Reusables/GradientLoadingAnimation'
import { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { $fetch } from 'utils/fetch'
import { deleteInbound } from 'utils/funcs/byot/deleteInbound'
import { getInbound } from 'utils/funcs/byot/getInbound'
import { insertInbound } from 'utils/funcs/byot/insertInbound'
import ConfigurationSection from './BYOTConfig'

function Trash() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <polyline points="3 6 5 6 21 6"></polyline>
      <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
    </svg>
  )
}
//
function Copy() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
      <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
    </svg>
  )
}

function Search() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <circle cx="11" cy="11" r="8"></circle>
      <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
    </svg>
  )
}

function TwilioNumbers({ onSwitchTab }) {
  const [encryptedKeys, setEncryptedKeys] = useState([])
  const [selectedKey, setSelectedKey] = useState(null)
  const [numbers, setNumbers] = useState([])
  const [loading, setLoading] = useState(false)
  const [loadingVariant, setLoadingVariant] = useState('keys')
  const [error, setError] = useState(null)
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedNumbers, setSelectedNumbers] = useState([])
  const [pagination, setPagination] = useState({
    page: 0,
    pageSize: 20,
    hasMore: true,
  })

  const fetchEncryptedKeys = useCallback(async () => {
    setLoading(true)
    setLoadingVariant('keys')
    setError(null)
    try {
      const response = await $fetch('/byot/get_keys', {
        method: 'GET',
      })
      const data = response.data
      setEncryptedKeys(data.map(item => item.id))
    }
    catch (err) {
      setError(
        `Failed to fetch encrypted keys. Please try again. Error: ${err.message}`,
      )
      console.error('Failed to fetch keys:', err)
    }
    finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    fetchEncryptedKeys()
  }, [fetchEncryptedKeys])

  const fetchNumbers = useCallback(
    async (options = {}) => {
      const {
        reset = false,
        newPageSize = pagination.pageSize,
        newPage = pagination.page,
      } = options

      if (!selectedKey)
        return

      setLoading(true)
      setLoadingVariant('numbers')
      setError(null)
      try {
        const response = await $fetch('/byot/get_twilio', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            encrypted_key: selectedKey,
            page: newPage,
            page_size: newPageSize,
          }),
        })

        const { inbound_numbers } = await getInbound(selectedKey)

        const twilioNumbers = response.data?.map(num => ({
          number: num,
          imported: inbound_numbers.some(
            inbound => inbound.phone_number === num,
          ),
        }))

        // .sort((a, b) =>
        //   a.imported === b.imported ? 0 : a.imported ? -1 : 1,
        // );
        if (reset || newPage === 0) {
          setNumbers(twilioNumbers)
        }
        else {
          setNumbers(prevNumbers => [...prevNumbers, ...twilioNumbers])
        }
        setPagination(prev => ({
          ...prev,
          page: newPage,
          pageSize: newPageSize,
          hasMore: newPageSize < 1000 && twilioNumbers?.length > 0,
        }))
      }
      catch (err) {
        setError(
          `Failed to fetch some Twilio numbers. Please try again later.`,
        )
        console.error('Failed to fetch numbers:', err)
      }
      finally {
        setLoading(false)
      }
    },
    [selectedKey],
  )

  const handleDelete = async (number) => {
    setLoading(true)
    setLoadingVariant('import')
    setError(null)
    try {
      const data = await deleteInbound(selectedKey, number)
      if (data?.success) {
        setNumbers(
          numbers.map(num =>
            num.number === number ? { ...num, imported: false } : num,
          ),
        )
        toast.success(`Successfully deleted ${number}`)
      }
      else {
        throw new Error(data?.message || 'Failed to delete number')
      }
    }
    catch (err) {
      setError(
        `Failed to delete ${number}. Please try again. Error: ${err.message}`,
      )
      console.error('Failed to delete number:', err)
    }
    finally {
      setLoading(false)
    }
  }

  const handleImportSelected = async () => {
    setLoading(true)
    setLoadingVariant('import')
    setError(null)
    try {
      const numbersToImport = selectedNumbers.filter(
        num => !numbers.find(n => n.number === num).imported,
      )
      const data = await insertInbound(selectedKey, numbersToImport)
      if (data.status === 'success') {
        setNumbers(
          numbers.map((num) => {
            if (selectedNumbers.includes(num.number)) {
              return { ...num, imported: true }
            }
            return num
          }),
        )
        setSelectedNumbers([])
        toast.success(
          `Successfully imported ${numbersToImport.length} Twilio numbers.`,
        )
      }
      else {
        throw new Error(data.message || 'Failed to import numbers')
      }
    }
    catch (err) {
      setError(
        `Failed to import selected numbers. Please try again. Error: ${err.message}`,
      )
      console.error('Failed to import selected numbers:', err)
    }
    finally {
      setLoading(false)
    }
  }

  const copyToClipboard = (key) => {
    navigator.clipboard.writeText(key).then(
      () => {
        toast.success('Encrypted key copied to clipboard')
      },
      (err) => {
        toast.error(`Failed to copy encrypted key: ${err.message}`)
      },
    )
  }

  const filteredNumbers = numbers?.filter(num =>
    num.number.toLowerCase().includes(searchTerm.toLowerCase()),
  )

  useEffect(() => {
    if (selectedKey) {
      // Reset pagination and fetch initial numbers
      setPagination({
        page: 0,
        pageSize: 20,
        hasMore: true,
      })
      fetchNumbers({ reset: true, newPage: 0, newPageSize: 20 })
    }
  }, [selectedKey, fetchNumbers])

  // Simplified handlers
  const loadMoreNumbers = () => {
    fetchNumbers({
      newPage: pagination.page + 1,
      newPageSize: pagination.pageSize,
    })
  }

  const loadAllNumbers = () => {
    fetchNumbers({
      reset: true,
      newPage: 0,
      newPageSize: 1000,
    })
  }

  const getLoadingMessage = () => {
    switch (loadingVariant) {
      case 'keys':
        return 'Fetching encrypted keys...'
      case 'numbers':
        return 'Fetching Twilio numbers...'
      case 'import':
        return 'Importing numbers...'
      default:
        return ''
    }
  }

  const handleGenerateNewKey = () => {
    onSwitchTab(1) // Switch to the "Generate Key" tab
  }

  return (
    <div className="max-w-6xl mx-auto p-4 bg-white min-h-screen">
      <h1 className="text-xl font-bold mb-5 text-gray-800 border-b pb-2.5">
        Twilio Number Management
      </h1>

      {error && (
        <div className="bg-red-50 border-l-4 border-red-400 p-2.5 mb-4 rounded-r">
          <p className="text-red-700">{error}</p>
        </div>
      )}

      <div className="mb-8">
        <h2 className="text-lg font-semibold mb-2.5 text-gray-700">
          Step 1: Select or Generate Encrypted Key
        </h2>
        <p className="text-gray-600 mb-2.5">
          An encrypted key is required to securely manage your Twilio numbers.
          Select an existing key or generate a new one.
        </p>
        {loading && loadingVariant === 'keys'
          ? (
              <div className="w-full h-full fixed inset-0">
                <GradientLoadingAnimation
                  variant="mintForest"
                  message={getLoadingMessage()}
                />
              </div>
            )
          : encryptedKeys.length > 0
            ? (
                <>
                  <p className="text-xs text-gray-500 mb-1.5">
                    Select an encrypted key:
                  </p>
                  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2.5">
                    {encryptedKeys.map(key => (
                      <div
                        key={key}
                        className={`p-2.5 rounded-md border ${
                          selectedKey === key
                            ? 'border-blue-500 bg-blue-50'
                            : 'border-gray-200'
                        } hover:border-blue-300 transition-colors cursor-pointer shadow-md`}
                        onClick={() => {
                          setSelectedKey(key)
                        }}
                      >
                        <div className="flex items-center justify-between">
                          <span className="font-mono text-xs text-gray-600">
                            {key.slice(0, 4)}
                            ...
                            {key.slice(-4)}
                          </span>
                          <button
                            onClick={(e) => {
                              e.stopPropagation()
                              copyToClipboard(key)
                            }}
                            className="text-gray-400 hover:text-gray-600"
                            title="Copy encrypted key"
                          >
                            <Copy />
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )
            : (
                <div className="bg-yellow-50 border-l-4 border-yellow-400 p-2.5 rounded-r">
                  <p className="text-yellow-700 mb-1.5">
                    No encrypted keys found. Generate a key to get started.
                  </p>
                  <button
                    onClick={handleGenerateNewKey}
                    className="px-2.5 py-1.5 bg-yellow-500 text-white rounded hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-opacity-50"
                  >
                    Generate New Key
                  </button>
                </div>
              )}
      </div>

      {selectedKey && (
        <>
          <div className="mb-8">
            <h2 className="text-lg font-semibold mb-2.5 text-gray-700">
              Step 2: Manage Twilio Numbers
            </h2>
            <p className="text-gray-600 mb-2.5">
              View, search, and manage your Twilio numbers. You can import new
              numbers or remove existing ones.
            </p>
            <div className="mb-2.5 flex items-center">
              <input
                type="text"
                placeholder="Search numbers"
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
                className="flex-grow p-1.5 border border-gray-300 rounded-l-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <div className="bg-gray-100 p-1.5 rounded-r-md">
                <Search />
              </div>
            </div>
            <div className="bg-white border border-gray-200 rounded-md overflow-hidden shadow-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-4 py-2 text-left text-2xs font-medium text-gray-500 uppercase tracking-wider">
                      Twilio Number
                    </th>
                    <th className="px-4 py-2 text-left text-2xs font-medium text-gray-500 uppercase tracking-wider">
                      Import Status
                    </th>
                    <th className="px-4 py-2 text-right text-2xs font-medium text-gray-500 uppercase tracking-wider">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {filteredNumbers?.map(num => (
                    <tr key={num.number}>
                      <td className="px-4 py-2.5 whitespace-nowrap">
                        <div className="flex items-center">
                          <input
                            type="checkbox"
                            checked={selectedNumbers.includes(num.number)}
                            onChange={() => {
                              setSelectedNumbers(prev =>
                                prev.includes(num.number)
                                  ? prev.filter(n => n !== num.number)
                                  : [...prev, num.number],
                              )
                            }}
                            className="mr-2 h-2.5 w-2.5 text-blue-600 focus:ring-blue-500 border-gray-300 rounded-sm"
                            disabled={num.imported}
                          />
                          <span className="font-medium text-gray-900">
                            {num.number}
                          </span>
                        </div>
                      </td>
                      <td className="px-4 py-2.5 whitespace-nowrap">
                        <span
                          className={`px-1.5 inline-flex text-2xs leading-3 font-semibold rounded-full ${
                            num.imported
                              ? 'bg-green-100 text-green-800'
                              : 'bg-yellow-100 text-yellow-800'
                          }`}
                        >
                          {num.imported ? 'Imported' : 'Not Imported'}
                        </span>
                      </td>
                      <td className="px-4 py-2.5 whitespace-nowrap text-right text-xs font-medium">
                        {num.imported && (
                          <button
                            onClick={() => handleDelete(num.number)}
                            className="text-red-600 hover:text-red-900"
                            title="Remove number"
                          >
                            <Trash />
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {pagination.hasMore && (
              <div className="mt-2.5 flex justify-between">
                <button
                  onClick={loadMoreNumbers}
                  className="px-2.5 py-1.5 bg-gray-200 text-gray-700 rounded hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50"
                >
                  Load More Numbers
                </button>
                <button
                  onClick={loadAllNumbers}
                  className="px-2.5 py-1.5 bg-gray-200 text-gray-700 rounded hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50"
                >
                  Load All Numbers
                </button>
              </div>
            )}
            <div className="mt-2.5 flex justify-between items-center">
              <span className="text-xs text-gray-500">
                {selectedNumbers.length}
                {' '}
                number
                {selectedNumbers.length !== 1 ? 's' : ''}
                {' '}
                selected
              </span>
              <button
                onClick={handleImportSelected}
                disabled={selectedNumbers.length === 0 || loading}
                className="px-2.5 py-1.5 bg-blue-600 text-white rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                Import Selected Numbers
              </button>
            </div>
          </div>

          <div className="mb-8">
            <h2 className="text-lg font-semibold mb-2.5 text-gray-700">
              Step 3: Twilio Configuration
            </h2>
            <p className="text-gray-600 mb-2.5">
              Set up your Twilio account configuration for the selected
              encrypted key.
            </p>
            <ConfigurationSection encryptedKey={selectedKey} />
          </div>
        </>
      )}
      {/*
      <AnimatePresence>
        {loading && (
          <GradientLoadingAnimation
            variant={"arcticAurora"}
            message={getLoadingMessage()}
          />
        )}
      </AnimatePresence> */}
    </div>
  )
}

export default TwilioNumbers
