import axios from 'axios'
import config from 'config'
import { getOrgId } from 'utils/funcs/browser/getOrgId'
import { getAuthToken } from '../browser/getAuthToken'

export async function cancelRecharge(userId) {
  const token = getAuthToken()
  const orgId = getOrgId()
  const headers = {
    authorization: `${token}`,
    ...(orgId && { 'x-bland-org-id': orgId }),
  }
  try {
    const response = await axios.post(
      `${config.API_URL}/recharge/cancel`,
      {
        user_id: userId,
      },
      { headers },
    )
    return response.data
  }
  catch (error) {
    console.log(error)
  }
}
