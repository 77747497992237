import { $fetch } from 'utils/fetch'

// returns array of org members or null
export async function GetOrgMembers(orgId, loading = null, setloading = null) {
  try {
    if (!orgId)
      return null
    if (loading !== null && setloading)
      setloading(true)

    const data = await $fetch(`/orgs/${orgId}/members`, { method: 'GET' })
    if (!data?.data || !Array.isArray(data.data))
      return null
    return data.data
  }
  catch (error) {
    console.error(`ERROR @ _GetOrgMembers -> ${error}`)
    return null
  }
  finally {
    if (loading !== null && setloading)
      setloading(false)
  }
}

// returns {success: boolean, message: string}
export async function UpdateOrgMember(action, phone, orgId, role = null, loading = null, setLoading = null, endpoint = 'members') {
  try {
    if (!orgId || !phone || !action)
      return { success: false, message: 'Missing required fields' }
    if (loading !== null && setLoading)
      setLoading(true)

    const object = {
      target: phone,
      action,
    }

    if (role && typeof role === 'string') {
      object.permissions = [role?.toLowerCase()]
    }

    const response = await $fetch(`/orgs/${orgId}/${endpoint}`, {
      method: 'PATCH',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify(object),
      ignoreResponseError: true,
    })

    if (response?.errors?.length) {
      const alreadyMemberError = response.errors.find(
        item =>
          item.message
          === 'Error: User is already a member of this organization.',
      )
      const maximumOrgsError = response.errors.find(
        item =>
          item.message
          === 'User has reached the maximum number of organizations they can join.',
      )
      const inviteExistsError = response.errors.find(
        item =>
          item.message
          === 'An invite has already been sent to this phone number.',
      )
      if (alreadyMemberError) {
        return {
          success: false,
          message: 'Error: User is already a member of this organization.',
        }
      }
      else if (maximumOrgsError) {
        return {
          success: false,
          message:
            'User has reached the maximum number of organizations they can join.',
        }
      }
      else if (inviteExistsError) {
        return {
          success: false,
          message: 'Error: An invite has already been sent to this phone number.',
        }
      }
      else {
        return {
          success: false,
          message:
            'Error updating organization member. Please ensure the user already has an account.',
        }
      }
    }

    return {
      success: true,
      message: action === 'remove' ? 'Successfully removed organization member.' : response.data?.isInvite ? 'Successfully invited user.' : 'Successfully added organization member.',
    }
  }
  catch (error) {
    console.error(`ERROR @ _UpdateOrgMember -> ${error}`)
    return { success: false, message: 'Error inviting user.' }
  }
  finally {
    if (loading !== null && setLoading)
      setLoading(false)
  }
}

export async function CreateOrg(name, loading = null, setLoading = null) {
  try {
    if (!name)
      return { success: false, message: 'Missing required fields', data: null }
    if (loading !== null && setLoading)
      setLoading(true)

    const response = await $fetch(`/orgs/create`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({ name }),
      ignoreResponseError: true,
    })

    if (!response.data)
      return { success: false, message: 'Error creating org.' }
    if (response?.errors) {
      return {
        success: false,
        message: response.errors[0].message,
        data: null,
      }
    }
    return {
      success: true,
      message: 'Org created successfully.',
      data: response.data,
    }
  }
  catch (error) {
    console.error(`ERROR @ _CreateOrg -> ${error}`)
    return { success: false, message: 'Error creating org.', data: null }
  }
  finally {
    if (loading !== null && setLoading)
      setLoading(false)
  }
}

async function DeleteOrg(orgId, loading = null, setLoading = null) {
  if (!orgId)
    return
  if (loading !== null && setLoading)
    setLoading(true)

  try {
    const response = await $fetch(`/orgs/${orgId}`, {
      method: 'DELETE',
      ignoreResponseError: true,
    })

    if (response?.errors?.length) {
      return {
        success: false,
        message: 'Error deleting org.',
        data: null,
      }
    }

    return {
      success: true,
      message: 'Org deleted successfully.',
      data: response?.data,
    }
  }
  catch (error) {
    console.error(`ERROR @ deleteOrg -> ${error}`)
    return { success: false, message: 'Error deleting org.', data: null }
  }
  finally {
    if (loading !== null && setLoading)
      setLoading(false)
  }
}

export async function LeaveOrg(orgId, loading = null, setLoading = null) {
  if (!orgId)
    return
  if (loading !== null && setLoading)
    setLoading(true)

  try {
    const response = await $fetch(`/orgs/self/leave`, {
      method: 'DELETE',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({ org_id: orgId }),
      ignoreResponseError: true,
    })

    if (response?.errors?.length) {
      return {
        success: false,
        message: 'Error leaving org.',
        data: null,
      }
    }

    return {
      success: true,
      message: 'Org left successfully.',
      data: response?.data,
    }
  }
  catch (error) {
    console.error(`ERROR @ leaveOrg -> ${error}`)
    return { success: false, message: 'Error leaving org.', data: null }
  }
  finally {
    if (loading !== null && setLoading)
      setLoading(false)
  }
}
