import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
} from '@/components/ui/dialog'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import {
  Badge,
  Button,
  Checkbox,
  Flex,
  IconButton,
  Skeleton,
  Switch,
  Text,
} from '@radix-ui/themes'
import ActionBar from 'components/core/ActionBar'
import { PageTitle } from 'components/core/PageTitle'
import { PageWrapper } from 'components/core/PageWrapper'

import config from 'config'
import { useAuth } from 'hooks/useAuth'
import {
  AlertCircle,
  Book,
  CheckCircle,
  ChevronDown,
  ChevronRight,
  Code,
  Code2,
  Flame,
  Globe,
  PlayCircle,
  Plus,
  PlusIcon,
  Trash,
  Users,
  X,
  XCircle,
  Zap,
} from 'lucide-react'
import { useEffect, useRef, useState } from 'react'
import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { $fetch } from 'utils/fetch'
import { objectToKeyValue } from 'utils/formatting/objToKeyValues'
import { AllValuesToObj, KeyValuesToObj } from 'utils/formatting/ValuesToObj'
import { getApiKey } from 'utils/funcs/browser/getApiKey'
import { getAuthToken } from 'utils/funcs/browser/getAuthToken'
import { getOrgId } from 'utils/funcs/browser/getOrgId'
import { validateAndFormatPhoneNumber } from 'utils/validation/ultimateValidator'
import SlideOut from '../CallLogs/SlideOut'
import JsonViewer from '../Events/components/JsonView'
import JsonEditor from './components/JsonEditor'
import { ToolPayload } from './components/ToolPayload'

const customInputStyles = `
  .custom-input:focus {
    outline: none;
    box-shadow: none;
  }
`

const banned_tool_names = [
  'input',
  'speak',
  'transfer',
  'switch',
  'wait',
  'finish',
  'press',
  'button',
  'say',
  'pause',
  'record',
  'play',
  'dial',
  'hang',
]

function checkForRequirementsInQueryParams() {
  const queryParams = new URLSearchParams(window.location.search)
  const encodedRequirements = queryParams.get('set_qp_requirements')
  const decodedRequirements = encodedRequirements
    ? JSON.parse(decodeURIComponent(encodedRequirements))
    : null
  if (!decodedRequirements)
    return null

  return encodedRequirements
    ? JSON.parse(decodeURIComponent(encodedRequirements))
    : null
}

function JsonRenderer({ data, isInResponseAlready, onExtractVariable }) {
  const [openItems, setOpenItems] = useState({})

  const toggleOpen = (key) => {
    setOpenItems(prev => ({ ...prev, [key]: !prev[key] }))
  }

  const extractVariable = (key, value, path) => {
    // const formattedPath = path.split('.').map(part => part.replace(/\d+/, `[${part}]`)).join('.');
    const output = {
      key: `{{${key}}}`,
      path: `$.${path}`,
    }
    onExtractVariable(output.key, output.path)
  }

  const renderValue = (key, value, path) => {
    const isObject = typeof value === 'object' && value !== null
    const fullPath = path + key
    const isOpen = openItems[fullPath]

    return (
      <div
        key={fullPath}
        className={`border-b border-gray-200 last:border-b-0 ${
          isInResponseAlready(`$.${fullPath}`)
            ? 'opacity-50 pointer-events-none'
            : ''
        }`}
      >
        <div className="flex items-center justify-between py-1.5 px-2.5">
          <div className="flex items-center">
            {isObject && (
              <Button
                type="button"
                variant="ghost"
                size="1"
                onClick={() => toggleOpen(fullPath)}
                className="p-0"
                style={{ cursor: 'pointer' }}
              >
                {isOpen
                  ? (
                      <ChevronDown size={12} />
                    )
                  : (
                      <ChevronRight size={12} />
                    )}
              </Button>
            )}
            <span
              style={{ marginLeft: 5, paddingLeft: 5 }}
              className="font-medium text-gray-600 text-xs"
            >
              {key}
              :
            </span>
          </div>
          <div className="flex items-center">
            {isObject
              ? (
                  <span className="text-gray-500 text-2xs mr-1.5">
                    {Array.isArray(value) ? 'Array' : 'Object'}
                    [
                    {Object.keys(value).length}
                    ]
                  </span>
                )
              : (
                  <span className="text-gray-800 text-xs mr-1.5">
                    {JSON.stringify(value)}
                  </span>
                )}
            <Button
              type="button"
              variant="ghost"
              size="1"
              style={{ cursor: 'pointer' }}
              onClick={() => extractVariable(key, value, fullPath)}
              className="text-blue-500 hover:text-blue-600 transition-colors duration-150 ease-in-out flex items-center"
              disabled={isInResponseAlready(`$.${fullPath}`)}
            >
              <span className="mr-0.5 text-2xs">Extract</span>
              <Plus size={12} />
            </Button>
          </div>
        </div>
        {isObject && isOpen && (
          <div className="pl-4 border-l border-gray-200 ml-2.5">
            {Object.entries(value).map(([nestedKey, nestedValue]) =>
              renderValue(nestedKey, nestedValue, `${fullPath}.`),
            )}
          </div>
        )}
      </div>
    )
  }

  return (
    <div>
      {Object.entries(data || {})?.map(([key, value]) =>
        renderValue(key, value, ''),
      )}
    </div>
  )
}

function SimplifiedApiTestResult({ result, methods }) {
  const [isExpanded, setIsExpanded] = useState(true)

  const { register, control, watch } = methods
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'response',
  })

  // const renderJsonWithButtons = (obj, path = '') => {
  //     const [openItems, setOpenItems] = useState({});

  //     const toggleOpen = (key) => {
  //       setOpenItems(prev => ({ ...prev, [key]: !prev[key] }));
  //     };

  //     const extractVariable = (key, value) => {
  //       // Implement your extraction logic here
  //       console.log(`Extracting ${key}:`, value);
  //     };

  //     const renderValue = (key, value, fullPath) => {
  //       const isObject = typeof value === 'object' && value !== null;
  //       const isOpen = openItems[fullPath];

  //       return (
  //         <div key={fullPath} className="border-b border-gray-200 last:border-b-0">
  //           <div className="flex items-center justify-between py-1.5 px-2.5">
  //             <div className="flex items-center">
  //               {isObject && (
  //                 <Button
  //                   type="button"
  //                   variant="ghost"
  //                   size="1"
  //                   onClick={() => toggleOpen(fullPath)}
  //                   className="mr-1.5 p-0"
  //                 >
  //                   {isOpen ? <ChevronDown size={16} /> : <ChevronRight size={16} />}
  //                 </Button>
  //               )}
  //               <span className="font-medium text-gray-600 text-xs">{key}:</span>
  //             </div>
  //             <div className="flex items-center">
  //               {isObject ? (
  //                 <span className="text-gray-500 text-2xs mr-1.5">
  //                   {Array.isArray(value) ? 'Array' : 'Object'}
  //                   [{Object.keys(value).length}]
  //                 </span>
  //               ) : (
  //                 <span className="text-gray-800 text-xs mr-1.5">{JSON.stringify(value)}</span>
  //               )}
  //               <Button
  //                 type="button"
  //                 variant="ghost"
  //                 size="1"
  //                 onClick={() => extractVariable(key, value)}
  //                 className="text-blue-500 hover:text-blue-600 transition-colors duration-150 ease-in-out flex items-center"
  //               >
  //                 <span className="mr-0.5 text-2xs">Extract</span>
  //                 <Copy size={12} />
  //               </Button>
  //             </div>
  //           </div>
  //           {isObject && isOpen && (
  //             <div className="pl-4 border-l border-gray-200 ml-2.5">
  //               {renderJsonWithButtons(value, `${fullPath}.`)}
  //             </div>
  //           )}
  //         </div>
  //       );
  //     };

  //     return Object.entries(obj).map(([key, value]) => renderValue(key, value, `${path}${key}`));
  //   };

  return (
    <div className="mt-0 bg-white border border-gray-200 rounded-md shadow-sm">
      <div className="p-2.5 border-b border-gray-200">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <CheckCircle className="w-3 h-3 text-green-500 mr-1.5" />
            <h3 style={{ fontSize: 12 }} className="font-medium text-gray-900">
              API Test Successful
            </h3>
          </div>
        </div>
        <div className="mt-1.5 text-xs text-gray-600">
          <p style={{ fontSize: 12 }}>
            <strong>Status Code:</strong>
            {' '}
            {result.statusCode}
          </p>
          <p style={{ marginTop: 4, fontSize: 12 }}>
            <strong>Response Time:</strong>
            {' '}
            {result.responseTime}
          </p>
        </div>
      </div>
      {isExpanded && (
        <div className="p-2.5">
          <div className="mb-2.5">
            <h4 className="text-md font-medium text-gray-900 mb-1.5">
              Response Preview:
            </h4>
            <div
              style={{
                columnGap: 10,
                display: 'flex',
                flexDirection: 'column',
              }}
              className="bg-gray-50 p-2.5 rounded text-xs overflow-x-auto"
            >
              {/* {renderJsonWithButtons(result.responseData)} */}
              <JsonRenderer
                data={result.responseData}
                isInResponseAlready={(path) => {
                  const response = methods.getValues('response')
                  const objResponse = KeyValuesToObj(response) || {}
                  const values = Object.values(objResponse) || []
                  if (values.includes(path))
                    return true
                  return false
                }}
                onExtractVariable={(key, path) => {
                  // handle here;
                  append({ key, value: path })
                }}
              />
            </div>
          </div>
          {/* <div className="flex space-x-2.5">
                        <div className="flex-1 border-2 border-gray-300 rounded p-2.5">
                            <h4 className="text-md font-medium text-gray-900 mb-1.5">Input Schema</h4>
                            {schema.input.map((item, index) => (
                                <div key={index} className="bg-blue-50 text-blue-700 px-1.5 py-0.5 rounded text-xs mb-1.5">
                                    {item.key}: {item.type}
                                </div>
                            ))}
                            {schema.input.length === 0 && (
                                <p className="text-gray-500 text-xs">Click 'Input' on variables to add them here</p>
                            )}
                        </div>
                        <div className="flex-1 border-2 border-gray-300 rounded p-2.5">
                            <h4 className="text-md font-medium text-gray-900 mb-1.5">Output Schema</h4>
                            {schema.output.map((item, index) => (
                                <div key={index} className="bg-green-50 text-green-700 px-1.5 py-0.5 rounded text-xs mb-1.5">
                                    {item.key}: {item.type}
                                </div>
                            ))}
                            {schema.output.length === 0 && (
                                <p className="text-gray-500 text-xs">Click 'Output' on variables to add them here</p>
                            )}
                        </div>
                    </div> */}
        </div>
      )}
    </div>
  )
}

function SectionBasicInformation({ methods }) {
  const { register } = methods
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: 20,
        width: '100%',
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
        <Label>Tool Name</Label>
        <p
          style={{ fontSize: 10, color: 'GrayText', fontWeight: '400' }}
          className="mt-[-4px]"
        >
          An actionable tool name, e.g., SendConfirmationText.
        </p>
        <StyledInput
          type="text"
          placeholder="Enter Tool Name"
          {...register('name')}
        />
      </div>

      <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
        <Label>Tool Speech</Label>
        <p
          style={{ fontSize: 10, color: 'GrayText', fontWeight: '400' }}
          className="mt-[-4px]"
        >
          The speech the agent should say while executing the tool, leave blank
          if you want the agent to remain silent.
        </p>
        <StyledInput
          type="text"
          placeholder="Enter Tool Speech"
          {...register('speech')}
        />
      </div>

      <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
        <Label>Description</Label>
        <p
          style={{ fontSize: 10, color: 'GrayText', fontWeight: '400' }}
          className="mt-[-4px]"
        >
          A description of the tool purpose and capabilities for the LLM.
        </p>
        <StyledTextArea
          placeholder="Describe your tool..."
          style={{ cursor: 'text', maxHeight: 300 }}
          {...register('description')}
        />
      </div>
    </div>
  )
}

function SectionAPIConfig({ methods, path, isNewPayload, isIntegratedTool }) {
  const [loading, setLoading] = useState(false)
  const [apiTestResponse, setApiTestResponse] = useState({
    statusCode: null,
    responseData: null,
    responseTime: null,
  })

  const [timeout, setTimeout] = useState('')
  const [error, setError] = useState('')

  const { register, control, watch, setValue, getValues } = methods
  const { fields, append, remove } = useFieldArray({
    control,
    name: `${path}.headers`,
  })

  const {
    fields: RFields,
    append: RAppend,
    remove: RRemove,
  } = useFieldArray({
    control,
    name: `${path}.response`,
  })

  const { append: QAppend, remove: QRemove } = useFieldArray({
    control,
    name: `${path}.query`,
  })

  const { append: BAppend, remove: BRemove } = useFieldArray({
    control,
    name: `${path}.body`,
  })

  const handleTimeoutChange = (e) => {
    const value = e.target.value

    if (value === '' || /^\d+$/.test(value)) {
      setValue(`${path}.timeout`, value)
      setError('')
    }
    else {
      setError('Please enter only numbers.')
    }
  }

  const getUrlWithAppendedParams = () => {
    try {
      const url = getValues(`${path}.url`)
      const query = getValues(`${path}.query`)

      if (!url || url === '' || url === null || url === undefined)
        return 'NO URL'
      if (query?.length === 0)
        return url

      const urlWithParams = new URL(url)
      query.forEach((param) => {
        urlWithParams.searchParams.append(param.key, param.value)
      })

      return urlWithParams.toString()
    }
    catch (error) {
      return null
    }
  }

  const include_entire_response = watch(`${path}.include_entire_response`)

  const isDisabled = () => {
    const url = getValues(`${path}.url`)
    if (!url || url === '' || url === null || url === undefined)
      return true
    return false
  }
  const testApiHandler = async () => {
    try {
      const testUrl = getValues(`${path}.url`)
      const method = getValues(`${path}.method`)
      const headers = getValues(`${path}.headers`)
      let body = getValues(`${path}.body`) || null
      if (!isNewPayload) {
        body = KeyValuesToObj(getValues(`${path}.body`)) || {}
        Object.keys(body).forEach((key) => {
          if (key !== '' && body[key] === 'value') {
            body[key] = getValues(`${path}.body`).find(
              item => item.key === key,
            ).val
          }
        })
      }

      if (!testUrl || testUrl === '' || !testUrl?.includes('https://')) {
        setLoading(false)
        return toast.error('Invalid API Endpoint URL.')
      }

      setLoading(true)
      const result = await $fetch('/tools/run', {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          'Authorization': getAuthToken(),
        },
        body: JSON.stringify({
          url: testUrl,
          method,
          headers: KeyValuesToObj(headers),
          body: Object.keys(body).length === 0 ? undefined : body,
        }),
      })

      try {
        const response = result
        setApiTestResponse({
          statusCode: response.data?.statusCode,
          responseTime: response.data?.responseTime,
          responseData: response.data?.responseData,
        })
        setLoading(false)
      }
      catch (error) {
        throw new Error('An error occurred')
      }
    }
    catch (error) {
      setApiTestResponse({
        statusCode: 'REQUEST_ERROR',
        responseTime: 'None',
        responseData: null,
      })
      setLoading(false)
    }
  }
  
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: 20,
        width: '100%',
      }}
    >
      {!isIntegratedTool ? (<>
      <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
        <Label>API Endpoint URL</Label>
        <StyledInput
          placeholder="https://api.example.com/v1/log"
          style={{
            cursor: 'text',
            border:
              checkForRequirementsInQueryParams()?.find(
                item => item.key === 'url',
              ) && getValues(`${path}.url`) === ''
                ? '1px solid #ef233c'
                : '1px solid #e5e5e5',
          }}
          {...register(`${path}.url`)}
        />
      </div>

      <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
        <Label>HTTP Method</Label>
        <Select
          onCloseAutoFocus={e => e.preventDefault()}
          className="text-xs"
          style={{ outline: 'none' }}
          onValueChange={value => setValue(`${path}.method`, value)}
          value={getValues(`${path}.method`)}
        >
          <SelectTrigger
            style={{ fontSize: 12 }}
            className="w-full p-4 ml-0 pl-2"
          >
            <SelectValue
              autoFocus={false}
              className="text-xs"
              placeholder="GET"
            />
          </SelectTrigger>

          <SelectContent>
            <SelectGroup>
              <SelectItem
                className="h-8"
                style={{ fontSize: 10, fontWeight: '400' }}
                value="GET"
              >
                GET - Receive Data
              </SelectItem>
              <SelectItem
                className="h-8"
                style={{ fontSize: 10, fontWeight: '400' }}
                value="PUT"
              >
                PUT - Update Data
              </SelectItem>
              <SelectItem
                className="h-8"
                style={{ fontSize: 10, fontWeight: '400' }}
                value="PATCH"
              >
                PATCH - Update Data
              </SelectItem>
              <SelectItem
                className="h-8"
                style={{ fontSize: 10, fontWeight: '400' }}
                value="POST"
              >
                POST - Send Data
              </SelectItem>
            </SelectGroup>
          </SelectContent>
        </Select>
      </div>

      <div className="w-full space-y-1.5">
        <Label htmlFor="timeout">Timeout (milliseconds)</Label>
        <div className="relative w-[100%]">
          <StyledInput
            id="timeout"
            type="text"
            style={{ width: '100%' }}
            placeholder="Enter timeout in ms"
            value={watch(`${path}.timeout`)}
            onChange={handleTimeoutChange}
            className={`custom-input ${error ? 'border-red-500 pr-6' : 'border-gray-300'}`}
          />
          {error && (
            <div className="absolute inset-y-0 right-0 pr-2 flex items-center pointer-events-none">
              <AlertCircle className="h-3 w-3 text-red-500" />
            </div>
          )}
        </div>
        {error && <p className="text-xs text-red-500 mt-0.5">{error}</p>}
      </div>
      </>
) : (
  <>
  </>
)}
      <div>
        <Label style={{ fontSize: 15 }}>Query Parameters</Label>
        <div className="bg-gray-50 p-2.5 rounded space-y-2.5 space-x-0 mt-1.5">
          {watch(`${path}.query`)?.length > 0 && (
            <KeyValueWrapper>
              {watch(`${path}.query`)?.map((param, index) => (
                <KeyValueGrid key={param.id}>
                  <StyledInput
                    placeholder="Key"
                    {...register(`${path}.query[${index}].key`)}
                  />
                  <StyledInput
                    placeholder="Value"
                    {...register(`${path}.query[${index}].value`)}
                  />
                  <HoverDiv
                    onClick={() => QRemove(index)}
                    style={{
                      height: '100%',
                      width: '100%',
                      display: 'flex',
                      justifyItems: 'center',
                      justifyContent: 'center',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}
                  >
                    <X color="gray" height={19} width={19} />
                  </HoverDiv>
                </KeyValueGrid>
              ))}
            </KeyValueWrapper>
          )}

          <Flex
            style={{ width: '100%' }}
            direction="row"
            align="center"
            justify="between"
          >
            <Button
              onClick={() => QAppend({ key: '', value: '' })}
              variant="ghost"
              style={{ cursor: 'pointer' }}
              type="button"
            >
              <PlusIcon height={12} width={12} />
              <Text color="iris" style={{ fontSize: 12, fontWeight: 500 }}>
                Add Query Parameter
              </Text>
            </Button>
          </Flex>
        </div>
      </div>

      <div>
        <Label style={{ fontSize: 15 }}>Headers</Label>
        <div className="bg-gray-50 p-2.5 rounded space-y-2.5 space-x-0 mt-1.5">
          {getValues(`${path}.headers`)?.length > 0 && (
            <KeyValueWrapper>
              {fields?.map((param, index) => (
                <KeyValueGrid key={param.id}>
                  <StyledInput
                    placeholder="Key"
                    {...register(`${path}.headers[${index}].key`)}
                  />
                  <StyledInput
                    placeholder="Value"
                    style={{
                      border:
                        checkForRequirementsInQueryParams()?.find(
                          item =>
                            item.key
                            === getValues(`${path}.headers[${index}].key`),
                        ) && getValues(`${path}.headers[${index}].value`) === ''
                          ? '1px solid #ef233c'
                          : '1px solid #e5e5e5',
                    }}
                    {...register(`${path}.headers[${index}].value`)}
                  />
                  <HoverDiv
                    onClick={() => remove(index)}
                    style={{
                      height: '100%',
                      width: '100%',
                      display: 'flex',
                      justifyItems: 'center',
                      justifyContent: 'center',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}
                  >
                    <X color="gray" height={19} width={19} />
                  </HoverDiv>
                </KeyValueGrid>
              ))}
            </KeyValueWrapper>
          )}

          <Button
            onClick={() => {
              append({ key: '', value: '' })
            }}
            variant="ghost"
            style={{ cursor: 'pointer' }}
            type="button"
          >
            <PlusIcon height={12} width={12} />
            <Text color="iris" style={{ fontSize: 12, fontWeight: 500 }}>
              Add Header
            </Text>
          </Button>
        </div>
      </div>

      {(watch(`${path}.method`) === 'POST'
        || watch(`${path}.method`) === 'PUT'
        || watch(`${path}.method`) === 'PATCH') && (
        <div>
          <div>
            <Label style={{ fontSize: 15 }}>Payload</Label>
          </div>

          <div className="bg-gray-50 p-2.5 rounded space-y-2.5 space-x-0 mt-1.5">
            {(getValues(`${path}.body`)?.length > 0 || isNewPayload) && (
              <>
                {!isNewPayload
                  ? (
                      <>
                        <BodyKeyValueWrapper>
                          {getValues(`${path}.body`)?.map((param, index) => (
                            <BodyKeyValueGridTwo
                              key={param.id}
                              columns={
                                getValues(`${path}.body[${index}].value`)
                                === 'value'
                                  ? 3
                                  : 2
                              }
                            >
                              <StyledInput
                                placeholder="Key"
                                {...register(`${path}.body[${index}].key`)}
                              />

                              {getValues(`${path}.body[${index}].value`)
                                === 'value' && (
                                <StyledInput
                                  placeholder="{{variable}} or static value"
                                  {...register(`${path}.body[${index}].val`)}
                                />
                              )}

                              <Select
                                onValueChange={(value) => {
                                  setValue(`${path}.body[${index}].value`, value)
                                }}
                                value={getValues(`${path}.body[${index}].value`)}
                                onCloseAutoFocus={e => e.preventDefault()}
                                className="text-xs"
                                style={{ outline: 'none' }}
                                defaultOpen={false}
                              >
                                <SelectTrigger
                                  style={{ fontSize: 12 }}
                                  className="w-full p-4 ml-0 pl-2"
                                >
                                  <SelectValue
                                    autoFocus={false}
                                    className="text-xs"
                                    placeholder="Type"
                                  />
                                </SelectTrigger>

                                <SelectContent>
                                  <SelectGroup>
                                    <SelectItem
                                      className="h-8"
                                      style={{ fontSize: 10, fontWeight: '400' }}
                                      value="value"
                                    >
                                      Value
                                    </SelectItem>
                                    <SelectItem
                                      className="h-8"
                                      style={{ fontSize: 10, fontWeight: '400' }}
                                      value="string"
                                    >
                                      String (Text)
                                    </SelectItem>
                                    <SelectItem
                                      className="h-8"
                                      style={{ fontSize: 10, fontWeight: '400' }}
                                      value="integer"
                                    >
                                      Number
                                    </SelectItem>
                                    <SelectItem
                                      className="h-8"
                                      style={{ fontSize: 10, fontWeight: '400' }}
                                      value="boolean"
                                    >
                                      Boolean (True/False)
                                    </SelectItem>
                                  </SelectGroup>
                                </SelectContent>
                              </Select>
                              <HoverDiv
                                onClick={() => BRemove(index)}
                                style={{
                                  height: '100%',
                                  width: '100%',
                                  display: 'flex',
                                  justifyItems: 'center',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  cursor: 'pointer',
                                }}
                              >
                                <X color="gray" height={19} width={19} />
                              </HoverDiv>
                            </BodyKeyValueGridTwo>
                          ))}
                        </BodyKeyValueWrapper>
                      </>
                    )
                  : (
                      <>
                        <JsonEditor
                          fieldName={path === 'staging' ? `staging.body` : 'body'}
                          inputSuggestions={
                            path === 'staging'
                              ? getValues('staging.input_schema')?.map(item => ({
                                label: item.key,
                                value: item.key,
                                description: `{{input.${item.key}}}`,
                              }))
                              : getValues('input_schema')?.map(item => ({
                                label: item.key,
                                value: item.key,
                                description: `{{input.${item.key}}}`,
                              }))
                          }
                        />
                        <Label>Variables</Label>
                        <ToolPayload
                          fieldName={
                            path === 'staging'
                              ? `staging.input_schema`
                              : 'input_schema'
                          }
                        />
                      </>
                    )}
              </>
            )}

            {!isNewPayload && (
              <Button
                onClick={() => {
                  BAppend({ key: '', value: '' })
                }}
                variant="ghost"
                style={{ cursor: 'pointer' }}
                type="button"
              >
                <PlusIcon height={12} width={12} />
                <Text color="iris" style={{ fontSize: 12, fontWeight: 500 }}>
                  Add Payload
                </Text>
              </Button>
            )}
          </div>
        </div>
      )}

      <div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Label style={{ fontSize: 15 }}>Response Variables</Label>

          <div
            style={{
              display: 'flex',
              columnGap: 10,
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Label
              style={{ fontSize: 10, color: 'GrayText', textAlign: 'right' }}
            >
              Include Entire Response
              {' '}
            </Label>
            <Checkbox
              onClick={(event) => {
                setValue(
                  `${path}.include_entire_response`,
                  !include_entire_response,
                )
              }}
              checked={include_entire_response}
              style={{ cursor: 'pointer' }}
              value={include_entire_response}
            />
          </div>
        </div>

        <div className="bg-gray-50 p-2.5 rounded space-y-2.5 space-x-0 mt-1.5">
          {watch(`${path}.response`)?.length > 0 && (
            <KeyValueWrapper>
              {watch(`${path}.response`)?.map((param, index) => (
                <KeyValueGrid key={param.id}>
                  <StyledInput
                    placeholder="{{variable}}"
                    {...register(`${path}.response[${index}].key`)}
                  />
                  <StyledInput
                    placeholder="$.variable.path"
                    {...register(`${path}.response[${index}].value`)}
                  />
                  <HoverDiv
                    onClick={() => RRemove(index)}
                    style={{
                      height: '100%',
                      width: '100%',
                      display: 'flex',
                      justifyItems: 'center',
                      justifyContent: 'center',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}
                  >
                    <X color="gray" height={19} width={19} />
                  </HoverDiv>
                </KeyValueGrid>
              ))}
            </KeyValueWrapper>
          )}

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <Button
              onClick={() => {
                RAppend({ key: '', value: '' })
                setValue(`${path}.include_entire_response`, false)
              }}
              variant="ghost"
              style={{ cursor: 'pointer' }}
              type="button"
            >
              <PlusIcon height={12} width={12} />
              <Text color="iris" style={{ fontSize: 12, fontWeight: 500 }}>
                Add Variable
              </Text>
            </Button>

            <Button
              disabled={isDisabled()}
              loading={loading}
              type="button"
              onClick={() => testApiHandler()}
              style={{ cursor: 'pointer' }}
              color="blue"
              variant="ghost"
              size="1"
            >
              <PlayCircle height={10} width={10} />
              <Text>Extract Variables from API Response</Text>
            </Button>
          </div>
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Label style={{ fontSize: 15 }}>Test API Configuration</Label>
        <Button
          onClick={() => testApiHandler()}
          loading={loading}
          size="1"
          variant="solid"
          color="blue"
          type="button"
          style={{ marginTop: 5, cursor: 'pointer' }}
        >
          <PlayCircle height={14} width={14} />
          <p style={{ fontWeight: '500', fontSize: 14 }}>Test API</p>
        </Button>
      </div>

      {apiTestResponse.statusCode !== 200
        && apiTestResponse.statusCode !== null && (
        <div
          className="mt-0 p-2.5 rounded bg-red-50 border border-red-200"
          style={{}}
        >
          <div className="flex items-center">
            <XCircle className="w-3 h-3 text-red-500 mr-1.5" />
            <h3 className="text-sm font-medium text-red-900">
              API Test Failed
            </h3>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              columnGap: 0,
              marginTop: 5,
            }}
          >
            <p>
              <strong>Status Code:</strong>
              {' '}
              {apiTestResponse.statusCode}
            </p>
          </div>

          {apiTestResponse.responseData && (
            <div
              className="mt-[0px]"
              style={{ maxWidth: '100%', overflowX: 'scroll' }}
            >
              <p className="mb-1.5">
                <strong>Response Data:</strong>
              </p>
              <pre
                style={{
                  fontSize: 12,
                  overflowX: 'scroll',
                  maxWidth: '100vw',
                }}
                className="bg-gray-100 p-1.5 rounded overflow-x-auto"
              >
                {JSON.stringify(apiTestResponse.responseData, null, 2)}
              </pre>
            </div>
          )}
        </div>
      )}

      {apiTestResponse.statusCode === 200
        && apiTestResponse.statusCode !== null && (
        <SimplifiedApiTestResult
          methods={methods}
          result={apiTestResponse}
          path={path}
        />
      )}
    </div>
  )
}

function SectionSettings({
  methods,
  toolId,
  handleRemoveShowcase,
  handleCreateShowcase,
}) {
  const public_tool = methods.watch('is_public')
  const public_config = methods.watch('public_config')
  const { features, tool_store_name, tool_store_description } = public_config
  const [isOpen, setIsOpen] = useState(!!public_tool)
  const fileInputRef = useRef(null)
  const { user } = useAuth()
  const [loadingUpload, setLoadingUpload] = useState(false)
  const [loadingDelete, setLoadingDelete] = useState(false)

  const toggleAccordion = () => {
    setIsOpen(!isOpen)
  }

  const addFeature = () => {
    return methods.setValue('public_config.features', [...features, ''])
  }

  const removeFeature = (index) => {
    methods.setValue(
      'public_config.features',
      features.filter((_, i) => i !== index),
    )
  }

  const updateFeature = (index, key, value) => {
    // Ensure features are stored as strings
    if (key === 'name') {
      methods.setValue(`public_config.features[${index}]`, value) // Store as string
    }
    else {
      methods.setValue(`public_config.features[${index}].[${key}]`, value)
    }
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0]
    if (file) {
      if (
        file.type === 'image/png'
        || file.type === 'image/jpeg'
        || file.type === 'image/jpg'
      ) {
        const reader = new FileReader()
        reader.onloadend = async () => {
          try {
            setLoadingUpload(true)
            const response = await $fetch('/tools/upload', {
              method: 'POST',
              body: JSON.stringify({
                fileName: file.name,
                fileData: reader.result,
                tool_id: toolId,
                user_id: user?.user?.id,
              }),
              ignoreResponseError: true,
            })

            if (!response.errors) {
              const data = await response.json()
              if (data?.url) {
                methods.setValue('public_config.tool_image', data.url)
              }
            }

            setLoadingUpload(false)
          }
          catch (error) {
            setLoadingUpload(false)
            console.log(error)
          }
        }
        reader.readAsDataURL(file)
      }
    }
  }

  const handleDeleteImage = async (imageId) => {
    try {
      setLoadingDelete(true)
      const response = await $fetch('/tools/upload/delete', {
        method: 'POST',
        body: JSON.stringify({ imageId, user_id: user?.user?.id }),
      })

      methods.setValue('public_config.tool_image', null)
      setLoadingDelete(false)
    }
    catch (error) {
      setLoadingDelete(false)
      console.log(error)
    }
  }

  const getSpecificValue = (index) => {
    return methods.getValues(`public_config.features[${index}]`)
  }

  const returnSensitiveHeaders = () => {
    const headers = methods.watch('headers')

    const all_sensitive_headers = [
      'authorization',
      'api_key',
      'api-jey',
      'api-key',
      'api_token',
      'x-api-key',
      'X-API-KEY',
      'x-api-token',
      'X-API-TOKEN',
      'access_token',
      'Access-Token',
      'access-token',
      'access-token',
    ]
    return headers.filter(header =>
      all_sensitive_headers.includes(header.key.toLowerCase()),
    )
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: 20,
        width: '100%',
      }}
    >
      {public_tool ? (
        <div>
          <Flex align="center" justify="between">
            <Flex align="center" gap="4">
              <Label style={{ fontSize: 16 }}>Public Tool</Label>
              <Badge size="2" color="teal">
                PUBLIC
                {/* <ExternalLink height={12} width={12} /> */}
              </Badge>
            </Flex>
            <Switch
              style={{ cursor: 'pointer' }}
              checked={public_tool}
              onClick={() => {
                methods.setValue('is_public', !public_tool)
                if (!public_tool === false) {
                  handleRemoveShowcase()
                }
              }}
              variant="solid"
              size="2"
            />
          </Flex>
          <div style={{ marginTop: 20 }}>
            <Label>Store Title</Label>
            <StyledInput
              value={tool_store_name}
              onChange={event =>
                methods.setValue(
                  'public_config.tool_store_name',
                  event.target.value,
                )}
              style={{ width: '100%', marginTop: 5 }}
              placeholder={methods.getValues('name')}
            />
          </div>

          <div style={{ marginTop: 20 }}>
            <Label>Store Description</Label>
            <StyledInput
              defaultValue={methods.getValues('description')}
              value={tool_store_description}
              onChange={event =>
                methods.setValue(
                  'public_config.tool_store_description',
                  event.target.value,
                )}
              style={{ width: '100%', marginTop: 5 }}
              placeholder={methods.getValues('description')}
            />
          </div>

          {/* <div style={{ marginTop: 20 }}>
                        <Label>Requirements</Label>

                        {/* api key
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 10 }}>
                            <Key color="black" height={14} width={14} />
                            <div style={{ marginLeft: 20 }}>
                                <Label style={{}}>API Key</Label>
                                <p style={{ color: "GrayText", fontSize: 12, fontWeight: '400' }}>User will provide their own API key to use this tool.</p>
                            </div>

                            <Checkbox checked size={"2"} style={{ cursor: 'pointer', marginLeft: 'auto' }} />
                        </div>
                    </div> */}

          <div style={{ marginTop: 20 }}>
            <Label>Settings</Label>
            <p style={{ color: 'GrayText', fontSize: 12, fontWeight: '400' }}>
              This is for sensitive or dynamic data like URL's, API Keys, etc.
              For example, you have a tool that sends data to a webhook. The
              user needs to provider their own webhook URL, you would leave "API
              Endpoint URL" empty.
            </p>

            <Flex direction="column" gap="4" style={{ marginTop: 20 }}>
              <Flex align="center" justify="between">
                <p style={{ fontWeight: '400' }}>API Endpoint URL</p>

                <div style={{ width: '50%' }}>
                  {methods
                    .watch('public_config.settings')
                    ?.find(setting => setting.key === 'url')
                    ?.is_sensitive
                    ? (
                        <>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              columnGap: 10,
                            }}
                          >
                            <p
                              style={{
                                fontSize: 12,
                                fontWeight: '400',
                                marginLeft: 'auto',
                              }}
                            >
                              Sensitive
                            </p>
                            <Checkbox
                              checked={methods.getValues(
                                'public_config.settings[0].is_sensitive',
                              )}
                              onClick={() => {
                                methods.setValue(
                                  'public_config.settings[0].is_sensitive',
                                  !methods.getValues(
                                    'public_config.settings[0].is_sensitive',
                                  ),
                                )
                                methods.setValue(
                                  'public_config.settings[0].value',
                                  methods.getValues('url'),
                                )
                              }}
                            />
                          </div>

                          <p
                            style={{
                              color: 'GrayText',
                              fontSize: 10,
                              fontWeight: '400',
                              marginTop: 2.5,
                              textAlign: 'right',
                            }}
                          >
                            User will provide their own API Endpoint URL to use this
                            tool when checked.
                          </p>
                        </>
                      )
                    : (
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            columnGap: 10,
                          }}
                        >
                          <StyledInput
                            value={methods.getValues(
                              'public_config.settings[0].value',
                            )}
                            style={{ width: '100%', textAlign: 'left' }}
                            onChange={(event) => {
                              methods.setValue(
                                'public_config.settings[0].value',
                                event.target.value,
                              )
                            }}
                          />

                          <IconButton
                            type="button"
                            onClick={() => {
                              methods.setValue(
                                'public_config.settings[0].value',
                                '',
                              )
                              methods.setValue(
                                'public_config.settings[0].is_sensitive',
                                true,
                              )
                            }}
                            size="1"
                            variant="ghost"
                            color="red"
                            style={{ marginLeft: 'auto', cursor: 'pointer' }}
                          >
                            <Trash size={14} />
                          </IconButton>
                        </div>
                      )}
                </div>
              </Flex>

              <div
                style={{ display: 'flex', flexDirection: 'column', gap: 20 }}
              >
                {returnSensitiveHeaders()?.map((header, index) => (
                  <Flex
                    key={index}
                    align="center"
                    justify="between"
                    direction="row"
                    style={{ borderTop: '1px solid #f0f0f0', paddingTop: 10 }}
                  >
                    <p style={{ fontWeight: '400' }}>{header.key}</p>

                    {methods
                      .getValues(`public_config.settings`)
                      ?.find(setting => setting.key === header.key)
                      ?.is_sensitive
                      ? (
                          <>
                            <div>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                  columnGap: 10,
                                }}
                              >
                                <p
                                  style={{
                                    fontSize: 12,
                                    fontWeight: '400',
                                    marginLeft: 'auto',
                                  }}
                                >
                                  Sensitive
                                </p>
                                <Checkbox
                                  checked={
                                    methods
                                      .getValues('public_config.settings')
                                      ?.find(
                                        setting => setting.key === header.key,
                                      )
                                      ?.is_sensitive
                                  }
                                />
                              </div>

                              <p
                                style={{
                                  color: 'GrayText',
                                  fontSize: 10,
                                  fontWeight: '400',
                                  marginTop: 2.5,
                                  textAlign: 'right',
                                }}
                              >
                                User will provide their own
                                {' '}
                                {header.key}
                                {' '}
                                to use this
                                tool when checked.
                              </p>
                            </div>
                          </>
                        )
                      : (
                          <>
                            <div>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                  columnGap: 10,
                                }}
                              >
                                <p
                                  style={{
                                    fontSize: 12,
                                    fontWeight: '400',
                                    marginLeft: 'auto',
                                  }}
                                >
                                  Sensitive
                                </p>
                                <Checkbox checked />
                              </div>

                              <p
                                style={{
                                  color: 'GrayText',
                                  fontSize: 10,
                                  fontWeight: '400',
                                  marginTop: 2.5,
                                  textAlign: 'right',
                                }}
                              >
                                User will provide their own
                                {' '}
                                {header.key}
                                {' '}
                                to use this
                                tool when checked.
                              </p>
                            </div>
                          </>
                        )}
                  </Flex>
                ))}
              </div>
            </Flex>
          </div>

          <div style={{ marginTop: 20, transition: 'all 0.3s ease' }}>
            <Label>Features</Label>
            {features?.length === 0 && (
              <div
                style={{
                  margin: '10px 0px',
                  padding: 20,
                  border: '1px solid #eeeeee',
                  borderRadius: 10,
                }}
              >
                <p style={{ textAlign: 'center' }}>No features defined yet.</p>
                <p
                  style={{
                    textAlign: 'center',
                    color: 'GrayText',
                    fontSize: 10,
                  }}
                >
                  Add your first features to get started.
                </p>
              </div>
            )}

            {features?.length > 0 && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 10,
                  marginTop: 5,
                }}
              >
                {features?.map((feature, index) => (
                  <div
                    className="bg-gray-50"
                    key={index}
                    style={{
                      padding: 10,
                      borderRadius: 2,
                      border: '1px solid #eeeeee',
                    }}
                  >
                    {/* <Label style={{ fontSize: 12, marginBottom: 5, color: '#000', fontWeight: '400' }}>Feature Name</Label> */}
                    <Flex align="center" justify="between">
                      <StyledInput
                        style={{ width: '100%' }}
                        placeholder="e.g., Retrieves Contact Information"
                        value={getSpecificValue(index)}
                        onChange={event =>
                          updateFeature(index, 'name', event.target.value)}
                      />

                      <IconButton
                        type="button"
                        onClick={() => removeFeature(index)}
                        style={{
                          marginLeft: '5px',
                          cursor: 'pointer',
                          padding: 5,
                        }}
                        size="1"
                        variant="ghost"
                        color="red"
                      >
                        <Trash size={14} />
                      </IconButton>
                    </Flex>

                    {/* <Label style={{ fontSize: 12, marginBottom: 5, marginTop: 10, color: "#000", fontWeight: '400' }}>Description</Label>
                                        <StyledInput
                                            style={{ width: '100%' }}
                                            placeholder="Briefly describe the feature's functionality"
                                            value={getSpecificValue("description", index)}
                                            onChange={(event) => updateByIndex("description", event.target.value, index)}
                                        />

                                        <div style={{ marginTop: 20, width: '100%', justifyContent: 'right', display: 'flex' }} >
                                            <Button type="button" onClick={() => removeFeature(index)} style={{ marginLeft: "auto", cursor: "pointer" }} size={'1'} variant="ghost" color="red">
                                                <Trash size={12} />
                                                Remove
                                            </Button>
                                        </div> */}
                  </div>
                ))}
              </div>
            )}
            <Button
              onClick={addFeature}
              type="button"
              variant="soft"
              size="2"
              style={{ width: '100%', marginTop: 5, cursor: 'pointer' }}
            >
              <PlusIcon height={16} width={16} />
              Add Feature
            </Button>
          </div>

          <div style={{ marginTop: 20 }}>
            <Label>Tool Image Upload</Label>
            <div style={{ marginTop: 5 }}>
              <Button
                type="button"
                variant="soft"
                onClick={() => fileInputRef.current.click()}
                size="2"
                color="gray"
                className="w-full"
                loading={loadingUpload}
                style={{ cursor: 'pointer' }}
              >
                Choose File
              </Button>
              <input
                type="file"
                accept=".png,.jpg,.jpeg"
                className="hidden"
                ref={fileInputRef}
                onChange={handleFileChange}
              />
            </div>

            {methods.watch('public_config.tool_image') && (
              <div
                style={{
                  marginTop: 20,
                  position: 'relative',
                  display: 'flex',
                  alignItems: 'flex-start',
                }}
              >
                <img
                  src={methods.watch('public_config.tool_image')}
                  className="max-w-full h-[100px] rounded-"
                  style={{ borderRadius: 4 }}
                  alt="tool_image"
                  loading="lazy"
                />
                <IconButton
                  type="button"
                  variant="solid"
                  radius="full"
                  color="red"
                  loading={loadingDelete}
                  onClick={() => {
                    const firstHash = methods.getValues(
                      'public_config.tool_image',
                    )
                    const secondHash = firstHash
                      ?.split(
                        'https://imagedelivery.net/Ti0vzYu1N20y4I9CN-0DEQ/',
                      )[1]
                      ?.split('/public')[0]
                    // console.log(firstHash, secondHash);
                    handleDeleteImage(secondHash)
                  }}
                  className="absolute top-0"
                  style={{ marginTop: -10, marginLeft: -10, cursor: 'pointer' }}
                >
                  <X size={20} />
                </IconButton>
              </div>
            )}
          </div>
        </div>
      ) : (
        <>
          <div className="" style={{ alignItems: 'center' }}>
            <h2 className="text-lg font-bold text-gray-900">
              Make Your Tool Public
            </h2>
            <p className="text-gray-600 mt-1.5">
              You haven't made your tool public yet. Publishing your tool allows
              others to discover and use it.
            </p>
          </div>
          <div className="bg-white border border-gray-200 rounded-md shadow-md w-full overflow-hidden mx-auto">
            <div className="p-4 space-y-2.5">
              <div className="space-y-2.5 mt-0">
                <div
                  className="flex items-start space-x-3 align-center"
                  style={{ alignItems: 'center' }}
                >
                  <Users className="h-4 w-4 text-green-500 flex-shrink-0 " />
                  <div>
                    <h2 className="text-lg font-semibold text-gray-900">
                      Wider Reach
                    </h2>
                    <p className="text-xs text-gray-600">
                      Your tool becomes accessible to the entire community.
                    </p>
                  </div>
                </div>

                <div
                  className="flex items-start space-x-3"
                  style={{ alignItems: 'center' }}
                >
                  <Zap className="h-4 w-4 text-yellow-500 flex-shrink-0" />
                  <div>
                    <h2 className="text-lg font-semibold text-gray-900">
                      Collaboration Opportunities
                    </h2>
                    <p className="text-xs text-gray-600">
                      Attract potential collaborators and receive valuable
                      feedback.
                    </p>
                  </div>
                </div>
              </div>

              <div className="bg-gray-50 p-2.5 rounded-">
                <p className="text-xs text-gray-600">
                  <strong>Note:</strong>
                  {' '}
                  Making a tool public means it will be
                  visible to all users. Ensure your tool doesn't contain
                  sensitive information before publishing.
                </p>
              </div>
            </div>

            <div className="bg-gray-50 px-4 py-2.5 border-t border-gray-200 flex justify-end">
              <Button
                type="button"
                onClick={() => {
                  methods.setValue('is_public', !!toolId)
                  methods.setValue('public_config.settings', [
                    {
                      key: 'url',
                      is_sensitive: false,
                      value: methods.getValues('url'),
                    },
                    ...returnSensitiveHeaders()?.map(header => ({
                      key: header.key,
                      is_sensitive: true,
                      value: '',
                    })),
                  ])
                  handleCreateShowcase()
                }}
                variant="outline"
                style={{ cursor: 'pointer' }}
                color="bronze"
              >
                <Flex align="center" direction="row" gap="2">
                  <span>Make Tool Public</span>
                  <Flame className="h-3 w-3" />
                </Flex>
              </Button>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default function Tools() {
  const { id } = useParams()
  const [activeSection, setActiveSection] = useState('basic')
  const [modalVisible, setModalVisible] = useState(false)
  const [toolId, setToolId] = useState(id ?? null)
  const current_url = new URL(window.location.href)
  const [loadingCreate, setLoadingCreate] = useState(false)
  const [loadingToolById, setLoadingToolById] = useState(false)
  const navigate = useNavigate()
  const orgId = getOrgId()
  const methods = useForm({
    defaultValues: {
      name: '',
      description: '',
      speech: '',
      url: '',
      method: 'GET',
      headers: [],
      body: [],
      query: [],
      response: [],
      timeout: 10000,
      is_editing: false,
      include_entire_response: false,
      input_schema: [],
      is_public: false,
      test_payload: [],
      public_config: {
        tool_store_name: '',
        tool_store_description: '',
        tool_image_url: null,
        settings: [
          {
            key: 'url',
            is_sensitive: false,
            value: '',
          },
        ],
        features: [],
        staging: {
          url: '',
          method: 'GET',
          headers: [],
          body: [],
          query: [],
          response: [],
          timeout: 10000,
          include_entire_response: false,
          input_schema: [],
        },
      },
    },
  })

  const [isNewPayloadTool, setIsNewPayloadTool] = useState(false)
  const is_editing = methods.watch('is_editing')
  const toolData = methods.watch()
  
  const [testToolData, setTestToolData] = useState({
    phone_number: '',
    prompt: ``,
  })
  const getValidToolData = (isStaging = false) => {
    const path = isStaging ? 'staging' : ''

    const tool = {
      name: isStaging ? `${toolData.name} (Staging)` : toolData.name,
      description: toolData.description,
      speech: toolData.speech,
      url: toolData[path]?.url || toolData.url,
      method: toolData[path]?.method || toolData.method,
      headers:
        KeyValuesToObj(toolData[path]?.headers || toolData.headers) || {},
      query: KeyValuesToObj(toolData[path]?.query || toolData.query) || {},
      response:
        toolData[path]?.include_entire_response
        || toolData.include_entire_response
          ? '{{data}}'
          : KeyValuesToObj(toolData[path]?.response || toolData.response),
      timeout: toolData[path]?.timeout || toolData.timeout || 999999,
      public: isStaging ? false : toolData.is_public,
    }

    if (
      tool.method === 'POST'
      || tool.method === 'PUT'
      || tool.method === 'PATCH'
    ) {
      const body = AllValuesToObj(toolData[path]?.body || toolData.body) || {}

      Object.keys(body).forEach((key) => {
        if (key !== '' && body[key] === 'value') {
          body[key] = (toolData[path]?.body || toolData.body).find(
            item => item.key === key,
          ).val
        }
      })

      if (!isNewPayloadTool) {
        tool.body = '{{input}}'
        tool.input_schema = {
          required: Object.keys(body),
          properties: body,
          type: 'object',
          example: body,
        }
      }
      else {
        const { body, input_schema } = getValidPayload(isStaging)
        tool.body = body
        tool.input_schema = input_schema
      }
    }

    return tool
  }

  const getValidPayload = (isStaging = false) => {
    const path = isStaging ? `staging.input_schema` : 'input_schema'
    const _payload = methods.getValues(path)
    const body = {}
    const examples = {}
    const required = []
    const properties = {}

    if (_payload) {
      for (const payloadItem of _payload) {
        if (payloadItem.key !== '') {
          if (payloadItem.static) {
            body[payloadItem.key] = payloadItem.value
          }

          if (!payloadItem.static) {
            properties[payloadItem.key] = payloadItem.value
            body[payloadItem.key] = `{{input.${payloadItem.key}}}`

            if (payloadItem.required) {
              required.push(payloadItem.key)
            }

            if (payloadItem.example_value && payloadItem.example_value !== '') {
              examples[payloadItem.key] = payloadItem.example_value
            }
          }
        }
      }
    }

    let res_body
    try {
      res_body = isStaging
        ? JSON.parse(methods.getValues('staging.body') || '{}') || {}
        : JSON.parse(methods.getValues('body') || '{}') || {}
    }
    catch (error) {
      res_body = isStaging
        ? methods.getValues('staging.body') || '{}'
        : methods.getValues('staging.body') || '{}'
    }

    return {
      body: res_body,
      input_schema: {
        examples,
        required,
        properties,
      },
    }
  }

  const reverseNewPayload = (body = {}, input_schema = {}) => {
    if (typeof body !== 'object' || typeof input_schema !== 'object')
      return []
    const body_ = []
    const _body = structuredClone(body)
    const { properties = {}, required = [], examples = {} } = input_schema

    // Object.keys(_body).forEach((key, index) => {
    //   if (!_body[key].includes("{{input.")) {
    //     body_.push({
    //       key,
    //       value: _body[key],
    //       example_value: "",
    //       static: true,
    //       required: false,
    //     });
    //   }
    // });

    Object.keys(properties).forEach((key, item) => {
      const example = examples[key]
      const inclRequired = required?.includes(key)
      const property = properties[key]

      body_.push({
        key,
        value: property,
        example_value: example || '',
        static: false,
        required: inclRequired || true,
      })
    })

    return {
      body: JSON.stringify(body),
      input_schema: body_,
    }
  }

  const testToolHandler = async () => {
    try {
      const tool = getValidToolData()
      const response = await fetch(`${config.API_URL}/v1/calls`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': getApiKey(),
          ...(orgId && { 'x-bland-org-id': orgId }),
        },
        body: JSON.stringify({
          phone_number: testToolData.phone_number,
          task: testToolData.prompt,
          temperature: 0.6,
          model: 'sonnet',
          tools: [tool],
        }),
      })
    }
    catch (error) {}
  }

  const createToolHandler = async () => {
    try {
      setLoadingCreate(true)

      const mainTool = getValidToolData()
      const stagingTool = getValidToolData(true)

      const response = await fetch(`${config.API_URL}/v1/tools`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': getApiKey(),
          ...(orgId && { 'x-bland-org-id': orgId }),
        },
        body: JSON.stringify({
          ...mainTool,
          staging_tool: stagingTool,
        }),
      })

      if (response.ok && (response.status === 200 || response.status === 201)) {
        const jsonData = await response.json()
        if (methods.getValues('is_public') && jsonData.tool_id) {
          const public_config = methods.getValues('public_config')
          const showcaseRes = await fetch(
            `${config.API_URL}/tools/showcase?tool_id=${jsonData.tool_id?.split('TL-')[1]}`,
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': getApiKey(),
                ...(orgId && { 'x-bland-org-id': orgId }),
              },
              body: JSON.stringify({
                action: 'create',
                name: public_config.tool_store_name,
                description: public_config.tool_store_description,
                features: public_config.features,
                settings: public_config.settings || [],
              }),
            },
          )
        }
        setToolId(jsonData.tool_id)
        navigate(`/dashboard/tool/${jsonData.tool_id}`)
        setLoadingCreate(false)
        // navigate(`/all-tools`);
        return toast.success('Tool Created Successfully')
      }
    }
    catch (error) {
      setLoadingCreate(false)
      return toast.error('Error creating new tool')
    }
  }

  const updateToolHandler = async () => {
    try {
      if (!toolId)
        return toast.error('Missing tool ID')
      setLoadingCreate(true)
      const queryParams = new URLSearchParams(window.location.search)
      queryParams.delete('set_qp_requirements')
      window.history.replaceState(
        {},
        '',
        `${window.location.pathname}?${queryParams}`,
      )

      const mainTool = getValidToolData()
      const stagingTool = getValidToolData(true)

      const response = await fetch(`${config.API_URL}/v1/tools/${toolId}`, {
        method: 'POST',
        headers: {
          'Authorization': getApiKey(),
          'Content-type': 'application/json',
          ...(orgId && { 'x-bland-org-id': orgId }),
        },
        body: JSON.stringify({
          ...getValidToolData(),
          staging_tool: stagingTool,
        }),
      })

      setLoadingCreate(false)

      if (response.ok && response.status === 200) {
        const jsonData = await response.json()
        if (methods.getValues('is_public') && jsonData.tool_id) {
          const public_config = methods.getValues('public_config')
          if (
            !public_config.tool_store_name
            || public_config.tool_store_name === ''
          ) {
            return toast.error('Please provide a name for the tool showcase')
          }

          const syncHeadersAndSettings = () => {
            const headers = methods.getValues('headers')
            const settings = methods.getValues('public_config.settings')
            const newSettings = []

            const all_sensitive_headers = [
              'authorization',
              'api_key',
              'api-jey',
              'api-key',
              'api_token',
              'x-api-key',
              'X-API-KEY',
              'x-api-token',
              'X-API-TOKEN',
              'access_token',
              'Access-Token',
              'access-token',
              'access-token',
            ]

            headers.forEach((header) => {
              const existingSetting = settings.find(
                setting => setting.key === header.key,
              )
              if (
                !existingSetting
                && all_sensitive_headers.includes(header.key)
              ) {
                newSettings.push({
                  key: header.key,
                  is_sensitive: true,
                  value: '',
                })
              }
            })

            return [...newSettings, ...settings]
          }

          const showcaseRes = await fetch(
            `${config.API_URL}/tools/showcase?tool_id=${jsonData.tool_id?.split('TL-')[1]}`,
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': getApiKey(),
                ...(orgId && { 'x-bland-org-id': orgId }),
              },
              body: JSON.stringify({
                action: 'update',
                name: public_config.tool_store_name,
                description: public_config.tool_store_description,
                features: public_config.features,
                settings: syncHeadersAndSettings(),
                image_url: public_config.tool_image ?? null,
              }),
            },
          )
        }
        return toast.success('Tool Updated Successfully', {
          position: 'bottom-right',
        })
      }

      return toast.info(`Error: ${await response.json()?.error}`)
    }
    catch (error) {
      setLoadingCreate(false)
      return toast.error('Error updating tool')
    }
  }

  const getToolById = async (toolId) => {
    try {
      setLoadingToolById(true)
      const response = await fetch(`${config.API_URL}/v1/tools/${toolId}`, {
        method: 'GET',
        headers: {
          Authorization: getApiKey(),
          ...(orgId && { 'x-bland-org-id': orgId }),
        },
      })

      if (response.ok && response.status === 200) {
        const jData = await response.json()
        let mainBody, mainInputSchema, stagingBody, stagingInputSchema

        const processBody = (toolData) => {
          const newBody = []
          if (toolData?.body && toolData?.input_schema) {
            Object.keys(toolData.input_schema.properties).forEach((key) => {
              const item = {
                key,
                value: toolData.input_schema.properties[key],
              }
              if (
                item.value !== 'string'
                && item.value !== 'number'
                && item.value !== 'boolean'
              ) {
                item.val = item.value
                item.value = 'value'
              }
              newBody.push(item)
            })
          }
          return newBody
        }

        if (
          jData?.tool?.tool?.body !== '{{input}}'
          && jData?.tool?.tool?.method !== 'GET'
          && id
        ) {
          setIsNewPayloadTool(true)
          const resMain = reverseNewPayload(
            jData?.tool?.tool?.body,
            jData?.tool?.tool?.input_schema,
          )
          mainBody = resMain.body
          mainInputSchema = resMain.input_schema
          methods.setValue('input_schema', mainInputSchema)

          const resStaging = reverseNewPayload(
            jData?.tool?.staging_tool?.body,
            jData?.tool?.staging_tool?.input_schema,
          )

          stagingBody = resStaging.body
          stagingInputSchema = resStaging.input_schema
        }
        else {
          mainBody = processBody(jData?.tool?.tool)
          stagingBody = processBody(jData?.tool?.staging_tool)
        }

        methods.reset({
          ...methods.getValues(),
          name: jData?.tool?.tool?.name,
          url: jData?.tool?.tool?.url,
          method: jData?.tool?.tool?.method,
          speech: jData?.tool?.tool?.speech,
          headers: objectToKeyValue(jData?.tool?.tool?.headers || {}),
          query: objectToKeyValue(jData?.tool?.tool?.query || {}),
          timeout: jData?.tool?.tool?.timeout || 10000,
          description: jData?.tool?.tool?.description,
          is_public: jData?.tool?.public,
          body: mainBody,
          response: objectToKeyValue(jData?.tool?.tool?.response || {}),
          include_entire_response: jData?.tool?.tool?.include_entire_response,
          staging: {
            url: jData?.tool?.staging_tool?.url,
            method: jData?.tool?.staging_tool?.method,
            headers: objectToKeyValue(jData?.tool?.staging_tool?.headers || {}),
            query: objectToKeyValue(jData?.tool?.staging_tool?.query || {}),
            timeout: jData?.tool?.staging_tool?.timeout || 10000,
            body: stagingBody,
            response: objectToKeyValue(
              jData?.tool?.staging_tool?.response || {},
            ),
            include_entire_response:
              jData?.tool?.staging_tool?.include_entire_response,
            input_schema: stagingInputSchema || [],
          },
        })

        if (jData?.tool?.public) {
          await fetchShowcaseData(jData?.tool?.tool_id)
        }
      }
    }
    catch (error) {
      console.error('Error fetching tool data:', error)
    }
    finally {
      setLoadingToolById(false)
    }
  }

  const fetchShowcaseData = async (toolId) => {
    try {
      const scReq = await fetch(
        `${config.API_URL}/tools/showcase?tool_id=${toolId.split('TL-')[1]}`,
        {
          method: 'POST',
          headers: {
            'Authorization': getApiKey(),
            'Content-Type': 'application/json',
            ...(orgId && { 'x-bland-org-id': orgId }),
          },
          body: JSON.stringify({
            action: 'get',
          }),
        },
      )

      if (scReq.ok && scReq.status === 200) {
        const scData = await scReq.json()

        const { title, description, features, settings } = scData?.data
        methods.setValue('public_config', {
          tool_store_name: title,
          tool_store_description: description,
          tool_image: scData?.data?.image_url ?? null,
          features: features || [],
          settings: settings
            ? [
                settings.find(setting => setting.key === 'url'),
                ...settings.filter(setting => setting.key !== 'url'),
              ]
            : [],
        })
      }
    }
    catch (error) {
      console.error('Error fetching showcase data:', error)
    }
  }

  const handleRemoveShowcase = async () => {
    if (!toolId)
      return
    const response = await fetch(
      `${config.API_URL}/tools/showcase?tool_id=${toolId?.split('TL-')[1]}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': getApiKey(),
          ...(orgId && { 'x-bland-org-id': orgId }),
        },
        body: JSON.stringify({
          action: 'remove',
        }),
      },
    )

    if (response.ok && response.status === 200) {
      return toast.success('Tool removed from showcase', {
        position: 'bottom-right',
      })
    }
  }

  const handleCreateShowcase = async () => {
    if (!toolId) {
      return toast.error(
        'Missing tool ID, please create or save the tool first',
      )
    }
    const response = await fetch(
      `${config.API_URL}/tools/showcase?tool_id=${toolId?.split('TL-')[1]}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': getApiKey(),
          ...(orgId && { 'x-bland-org-id': orgId }),
        },
        body: JSON.stringify({
          action: 'create',
          name: methods.getValues('name'),
          settings: methods.getValues('public_config.settings'),
        }),
      },
    )

    if (response.ok && response.status === 200) {
      await fetch(`${config.API_URL}/sitemap/refreshSitemap`, {
        method: 'GET',
        headers: {
          Authorization: getApiKey(),
          ...(orgId && { 'x-bland-org-id': orgId }),
        },
      })
      return toast.success('Tool added to showcase', {
        position: 'bottom-right',
      })
    }
  }

  useEffect(() => {
    console.log('Tool ID:', id)
    if (id) {
      setToolId(id)
      getToolById(id)
    }
    else {
      setIsNewPayloadTool(true)
    }
  }, [])

  useEffect(() => {
    if (current_url.searchParams.get('set_qp_requirements')) {
      setActiveSection('api')
    }
  }, [])

  const SectionHeader = ({ title, icon: Icon, isActive, onClick }) => (
    <button
      type="button"
      className={`flex items-center w-full p-2.5 text-left ${
        isActive
          ? 'bg-indigo-50 border-l-4 border-indigo-600'
          : 'bg-white border-l-4 border-white'
      }`}
      onClick={onClick}
    >
      <Icon
        className={`w-3 h-3 mr-2 ${
          isActive ? 'text-indigo-600' : 'text-gray-400'
        }`}
      />
      <span
        className={`font-medium ${
          isActive ? 'text-indigo-600' : 'text-gray-700'
        }`}
      >
        {title}
      </span>
      {/* {!isActive ? (
                <ChevronUp className="w-3 h-3 ml-auto text-indigo-600" />
            ) : (
                <ChevronDown className="w-3 h-3 ml-auto text-gray-400" />
            )} */}
    </button>
  )

  const [showCode, setShowCode] = useState(false)

  return (
    <PageWrapper>
      <FormProvider {...methods}>
        <Form>
          <ActionBar top spaceBetween>
            <PageTitle
              style={{ cursor: 'pointer' }}
              onClick={() => {
                navigate('/dashboard/tools')
              }}
            >
              Tools
            </PageTitle>
            {/* <p>is payload new type: {isNewPayloadTool ? "true" : "false"}</p> */}

            <Flex direction="row" gap="3">
              {/* <Button type="button" style={{ cursor: 'pointer' }} variant="soft" color="gray">Save as Draft</Button> */}
              <Button
                onClick={() => {
                  // testToolHandler()
                  setModalVisible(true)
                }}
                type="button"
                color="purple"
                variant="soft"
                style={{ cursor: 'pointer' }}
              >
                Test Tool
              </Button>

              <Button
                variant="soft"
                type="button"
                style={{ cursor: 'pointer' }}
                onClick={() => setShowCode(!showCode)}
              >
                {showCode ? 'Hide' : 'Show'}
                {' '}
                Code
                <Code2 size={14} />
              </Button>

              <Button
                loading={loadingCreate}
                onClick={() => {
                  if (toolId && id) {
                    updateToolHandler()
                  }
                  else {
                    createToolHandler()
                  }
                }}
                type="button"
                style={{ cursor: 'pointer' }}
                variant="solid"
                color="iris"
              >
                Save Tool
              </Button>
            </Flex>
          </ActionBar>

          {checkForRequirementsInQueryParams() && (
            <div
              style={{
                padding: 20,
                background: '#f9f9f9',
                borderRadius: 10,
                margin: 10,
                border: '1px solid #e5e5e5',
              }}
            >
              <h1 style={{ fontSize: 18, fontWeight: '600', color: '#000' }}>
                Requirements
              </h1>
              <p style={{ width: '100%' }}>
                You have requirements to meet before creating and using a tool.
                Please meet the requirements below.
              </p>

              <div
                style={{
                  marginTop: 10,
                  display: 'flex',
                  flexDirection: 'row',
                  gap: 10,
                  flexWrap: 'wrap',
                }}
              >
                {checkForRequirementsInQueryParams().map(
                  (requirement, index) => (
                    <Badge size="2" variant="surface" color="red" key={index}>
                      {requirement.key}
                    </Badge>
                  ),
                )}
              </div>
            </div>
          )}

          <MasterContainer>
            <div
              id="header"
              className="border-b border-gray-200 bg-gray-50 px-4 py-4 w-[full]"
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <p
                className="text-lg font-semibold text-gray-900"
                style={{ fontSize: 18, fontWeight: '600', color: '#000' }}
              >
                {toolId
                  ? `Updating Tool: ${toolData?.name}`
                  : 'Create New Tool'}
              </p>

              <div style={{ marginLeft: 'auto' }}>
                <IconButton
                  style={{
                    cursor: 'pointer',
                    justifyItems: 'center',
                    alignItems: 'center',
                    display: 'flex',
                  }}
                  type="button"
                  variant="ghost"
                >
                  <Book height={18} width={18} />
                </IconButton>
              </div>
            </div>

            <div className="flex">
              <div
                style={{ flexShrink: 0 }}
                className="w-[200px] border-r border-gray-200"
              >
                <SectionHeader
                  title="Basic Information"
                  icon={Globe}
                  isActive={activeSection === 'basic'}
                  onClick={() => setActiveSection('basic')}
                />
                <SectionHeader
                  title="API Configuration"
                  icon={Code}
                  isActive={activeSection === 'api'}
                  onClick={() => setActiveSection('api')}
                />

                <SectionHeader
                  title="Staging API"
                  icon={Code}
                  isActive={activeSection === 'staging_api'}
                  onClick={() => setActiveSection('staging_api')}
                />
                {/* <SectionHeader
                                    title="Authentication"
                                    icon={Lock}
                                    isActive={activeSection === 'auth'}
                                    onClick={() => setActiveSection('auth')}
                                /> */}
                <SectionHeader
                  title="Tool Showcase"
                  icon={Zap}
                  isActive={activeSection === 'settings'}
                  onClick={() => setActiveSection('settings')}
                />
              </div>

              {loadingToolById
                ? (
                    <Skeleton height="317.5px" style={{ width: '100%' }} />
                  )
                : (
                    <div className="flex-grow p-4 space-y-4">
                      {activeSection === 'basic' && (
                        <SectionBasicInformation methods={methods} />
                      )}

                      {activeSection === 'api' && (
                        <SectionAPIConfig
                          methods={methods}
                          path=""
                          isNewPayload={isNewPayloadTool}
                          isIntegratedTool={toolData.integration}
                        />
                      )}

                      {activeSection === 'staging_api' && (
                        <div>
                          <h2 className="text-base font-semibold mb-2.5">
                            Staging API Configuration
                          </h2>
                          <SectionAPIConfig
                            methods={methods}
                            path="staging"
                            isNewPayload={isNewPayloadTool}
                            isIntegratedTool={toolData.integration}
                          />
                        </div>
                      )}

                      {activeSection === 'settings' && (
                        <SectionSettings
                          methods={methods}
                          toolId={toolId}
                          handleCreateShowcase={() => handleCreateShowcase()}
                          handleRemoveShowcase={() => handleRemoveShowcase()}
                        />
                      )}
                    </div>
                  )}
            </div>
          </MasterContainer>

          {/* <p>{JSON.stringify(toolData)}</p>
                    <p>{JSON.stringify(getValidToolData())}</p> */}

          {/* <p>{JSON.stringify(methods.watch("public_config"))}</p> */}
        </Form>

        {/* <p>{JSON.stringify(methods.getValues("body"))}</p>
        <p>{JSON.stringify({...getValidToolData()})}</p> */}

        <Dialog open={modalVisible} onOpenChange={() => setModalVisible(false)}>
          <DialogContent className="md:min-w-[50%] sm: min-w-[40%] p-6">
            <div style={{}}>
              <Label style={{ fontSize: 18, fontWeight: 525 }}>
                Test Your Tool
              </Label>
            </div>

            <Label>Phone Number</Label>
            <StyledInput
              value={testToolData.phone_number}
              onChange={(event) => {
                const currentVal = event.target.value
                // const validationRes = validateAndFormatPhoneNumber(currentVal);
                setTestToolData(prevState => ({
                  ...prevState,
                  phone_number: currentVal,
                }))
              }}
              placeholder="+19543004000"
            />

            {testToolData.phone_number?.length > 1
              && validateAndFormatPhoneNumber(testToolData.phone_number).error && (
              <p
                style={{ color: '#c1121f', fontWeight: '500', fontSize: 13 }}
              >
                {
                  validateAndFormatPhoneNumber(testToolData.phone_number)
                    .error
                }
              </p>
            )}

            <Label>Prompt</Label>
            <StyledInput
              placeholder={`You are calling to test tool: {{${
                toolData?.name
              }}}. You will test the tool = {{${methods.getValues(
                'name',
              )}}}, provide the response of the tool, and then politely hang up.`}
              value={testToolData.prompt}
              // defaultValue={`You are calling to test tool: {{${toolData?.name}}}. You will test the tool = {{${methods.getValues("name")}}}, provide the response of the tool, and then politely hang up.`}
              onChange={(event) => {
                const currentVal = event.target.value
                setTestToolData(prevState => ({
                  ...prevState,
                  prompt: currentVal,
                }))
              }}
            />

            <p
              onClick={() => {
                setTestToolData(prevState => ({
                  ...prevState,
                  prompt: `You are calling to test tool: {{${
                    toolData?.name
                  }}}. You will test the tool = {{${methods.getValues(
                    'name',
                  )}}}, provide the response of the tool, and then politely hang up.`,
                }))
              }}
              style={{
                fontSize: 12,
                fontWeight: '500',
                color: '#8E4EC6',
                cursor: 'pointer',
                marginTop: -5,
                textDecoration: 'underline',
              }}
            >
              Use Recommended Test Prompt
            </p>

            <DialogFooter className="sm:justify-start mt-[10px]">
              <DialogClose asChild>
                <Flex direction="row">
                  <Button
                    onClick={() => {
                      if (
                        toolData.url === ''
                        || !toolData.url.includes('https://')
                      ) {
                        return toast.error('URL must be valid')
                      }
                      if (toolData.name == '' || toolData.description == '') {
                        return toast.error('Missing name or description')
                      }
                      if (testToolData.prompt == '') {
                        return toast.error('Missing prompt or prompt invalid')
                      }
                      testToolHandler()
                    }}
                    style={{
                      cursor: 'pointer',
                      padding: '5px 10px',
                      marginRight: 15,
                      borderRadius: 4,
                      background: '#8E4EC6',
                    }}
                    type="button"
                    variant="solid"
                  >
                    <p
                      style={{ fontSize: 10, fontWeight: '500', color: '#fff' }}
                    >
                      Send Call
                    </p>
                  </Button>
                  <Button
                    style={{
                      cursor: 'pointer',
                      padding: '5px 10px',
                      borderRadius: 4,
                    }}
                    type="button"
                    color="gray"
                    variant="soft"
                  >
                    <p style={{ fontSize: 10, fontWeight: '500' }}>Close</p>
                  </Button>
                </Flex>
              </DialogClose>
            </DialogFooter>
          </DialogContent>
        </Dialog>

        <SlideOut
          title={`${activeSection === 'staging_api' ? 'Staging' : 'Production'} Tool JSON`}
          isOpen={showCode}
          onClose={() => setShowCode(false)}
        >
          <JsonViewer
            data={{
              // test_payload: methods.getValues("test_payload"),
              ...getValidToolData(
                activeSection === 'staging_api',
              ),
            }}
          />
        </SlideOut>
      </FormProvider>
    </PageWrapper>
  )
}

const HoverDiv = styled.div`
  &:hover {
    background-color: #f5f5f5;
    border-radius: 5px;
  }
`

const Label = styled.p`
  margin: 0px;
  padding: 0px;
  color: #000;
  font-size: 13px;
  font-weight: 500;
  width: 100%;
`

const StyledTextArea = styled.textarea`
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  padding: 6px;
  font-size: 12px;
  color: ${({ disabled }) => (disabled ? 'grey' : 'black')};
  letter-spacing: 0.125px;
  &:focus {
    outline: none;
  }
`

const StyledInput = styled.input`
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  padding: 6px;
  font-size: 12px;
  color: ${({ disabled }) => (disabled ? 'grey' : 'black')};
  letter-spacing: 0.125px;
  &:focus {
    outline: none;
  }
`

const MasterContainer = styled.div`
  width: 100%;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  overflow-x: clip;
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  row-gap: 30px;
`

const BodyKeyValueWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
  gap: 5px;
`

const BodyKeyValueGridTwo = styled.div`
  display: grid;
  grid-template-columns: ${props =>
    props.columns === 3 ? 'repeat(2, 23.25%) 1fr 30px' : 'repeat(2, 1fr) 30px'};
  background-color: #fbfbfa;
  grid-gap: 10px;
  width: 100%;
`

const KeyValueWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
  gap: 5px;
`

const KeyValueGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 30px;
  background-color: #fbfbfa;
  grid-gap: 10px;
  width: 100%;
`

const HeaderKeyValueGrid = styled.div`
  display: grid;
  grid-template-columns: 90px 1fr 1fr 30px;
  background-color: #fbfbfa;
  grid-gap: 10px;
  width: 100%;
`

const HighlightOnHover = styled.div`
  cursor: pointer;
  padding: 5px;
  border-radius: 4px;
  &:hover {
    background-color: #f0f0f0;
  }
`
