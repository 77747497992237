import {
  LinkAuthenticationElement,
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import Button from 'components/core/Button'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'

export default function CheckoutForm({ amount, onSuccess }) {
  const stripe = useStripe()
  const elements = useElements()
  const [message, setMessage] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const methods = useForm()

  const handlePay = async () => {
    if (!stripe || !elements) {
      return
    }
    setIsLoading(true)
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/dashboard`,
      },
      redirect: 'if_required',
    })

    if (error) {
      if (error.type === 'card_error' || error.type === 'validation_error') {
        setMessage(error.message)
      }
      else {
        setMessage('An unexpected error occured.')
      }
    }
    else {
      onSuccess()
    }
    setIsLoading(false)
  }

  return (
    <Form>
      <LinkAuthenticationElement />
      <PaymentElement />
      <Button
        onClick={methods.handleSubmit(handlePay)}
        loading={isLoading}
        disabled={!stripe || !elements}
      >
        Pay Now $
        {amount}
      </Button>
      {message && (
        <div className="my-5 " id="payment-message">
          {message}
        </div>
      )}
    </Form>
  )
}

const Form = styled.form`
  display: flex;
  flex-direction: column;
  row-gap: 30px;
`
