import { forwardRef, Select, SelectItem, SelectSection } from '@heroui/react'
import { useQuery } from '@tanstack/react-query'
import React from 'react'
import { $fetch } from 'utils/fetch'

const ToolSelect = forwardRef(({ onChange, value, ...props }, ref) => {
  const { data: tools } = useQuery({
    queryKey: ['tools'],
    queryFn: async () => {
      const { tools } = await $fetch('/v1/tools')
      return tools?.map(toolData => ({
        ...toolData.tool,
        ...toolData,
      }))
    },
  })

  const { data: kbs } = useQuery({
    queryKey: ['kbs'],
    queryFn: async () => {
      const { data } = await $fetch('/v1/knowledgebases')
      return data?.vectors
    },
  })

  return (
    <Select
      label="Tools and Knowledge Bases"
      placeholder="Select tools and knowledge bases"
      selectionMode="multiple"      
      variant="bordered"      
      radius="sm"
      labelPlacement="outside"
      selectedKeys={value}
      onSelectionChange={(keys) => {        
        const selectedKeys = Array.from(keys)
        onChange(selectedKeys)
      }}
      {...props}
      ref={ref}
    >
      <SelectSection title="Tools">
        {tools?.map(tool => (
          <SelectItem
            key={tool.tool_id}
            value={tool.tool_id}
            endContent={
              <p className="text-xs font-geist-mono text-muted-foreground">{tool.tool_id}</p>
            }
          >
            {tool.name}
          </SelectItem>
        ))}
      </SelectSection>
      <SelectSection title="Knowledge Bases">
        {kbs?.map(kb => (
          <SelectItem
            key={kb.vector_id}
            value={kb.vector_id}
            endContent={
              <p className="text-xs font-geist-mono text-muted-foreground">{kb.vector_id}</p>
            }
          >
            {kb.name}
          </SelectItem>
        ))}
      </SelectSection>
    </Select>
  )
})

export default ToolSelect
