import Input from 'components/core/Input'

export default function EncryptedKey({ fieldName }) {
  return (
    <Input
      fieldName={fieldName}
      label="Encrypted Key (BYOT)"
      description={(
        <a
          href="https://docs.bland.ai/enterprise-features/custom-twilio"
          target="_blank"
          rel="noreferrer"
        >
          Use your own Twilio account - Click here for docs
        </a>
      )}
    />
  )
}
