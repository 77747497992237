import axios from 'axios'
import config from 'config'
import { getOrgId } from 'utils/funcs/browser/getOrgId'
import { getAuthToken } from '../browser/getAuthToken'

export async function deleteInbound(encrypted_key, phone_number) {
  const orgId = getOrgId()

  const headers = {
    authorization: getAuthToken(),
    encrypted_key,
    ...(orgId && { 'x-bland-org-id': orgId }),
  }
  phone_number = encodeURIComponent(phone_number)
  try {
    const { data } = await axios.post(
      `${config.API_URL}/v1/inbound/${phone_number}/delete`,
      {},
      { headers },
    )
    if (data.status === 'success') {
      return { success: true, data }
    }
    else {
      return { success: false, message: data.message }
    }
  }
  catch (error) {
    console.error('Error in deleteInbound:', error)
    return {
      success: false,
      message: error.response?.data?.message || error.message,
    }
  }
}
