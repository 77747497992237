import { useAuth } from 'hooks/useAuth'
import { motion } from 'framer-motion'
import {
  Cpu,
  Database,
  Fan,
  HardDrive,
  NetworkIcon,
  Shield,
} from 'lucide-react'
import { useEffect, useState } from 'react'

// Generate log entries with local time (user's time zone)
function generateLogEntry(type = 'system') {
  const types = {
    system: {
      prefixes: [
        'kernel',
        'systemd',
        'NetworkManager',
        'docker',
        'etcd',
        'daemon',
      ],
      messages: [
        'Initiated microservice handshake',
        'Allocated new memory block',
        'Network interface eth0 up',
        'CPU frequency scaling governor changed',
        'Service config reloaded',
        'Synchronized micro-kernel modules',
        'Orchestrator ping success',
        'Resource allocation check completed',
      ],
    },
    security: {
      prefixes: ['sshd', 'firewalld', 'selinux', 'audit', 'vault', 'crowdsec'],
      messages: [
        'New connection from 192.168.1.100',
        'Security policy updated',
        'Authentication success',
        'Port scan detected',
        'Cryptographic handshake verified',
        'Key rotation event triggered',
        'Federated trust established',
        'Privilege check completed',
      ],
    },
    application: {
      prefixes: ['app', 'nginx', 'postgres', 'redis', 'worker', 'scheduler'],
      messages: [
        'Processing batch job #45892',
        'Cache hit ratio: 89.4%',
        'Query optimization completed',
        'Background task scheduled',
        'Initiated container deployment',
        'Microservice registry updated',
        'Workflow pipeline triggered',
        'Autoscaling policy applied',
      ],
    },
  }

  const severity = ['INFO', 'WARN', 'COMPUTE', 'DEBUG'][
    Math.floor(Math.random() * 4)
  ]
  const { prefixes, messages } = types[type]
  const prefix = prefixes[Math.floor(Math.random() * prefixes.length)]
  const message = messages[Math.floor(Math.random() * messages.length)]
  const pid = Math.floor(Math.random() * 9999) + 1000

  // Store logs in local time for the user's time zone
  const timestamp = new Date().toLocaleTimeString([], {
    hour12: false,
    timeZoneName: 'short',
  })

  return {
    timestamp,
    severity,
    prefix,
    message,
    pid,
    id: Math.random().toString(36).substr(2, 9),
  }
}

// Basic fade-in
const fadeIn = {
  hidden: { opacity: 0, y: 8 },
  visible: {
    opacity: 1,
    y: 0,
    transition: { duration: 0.75 },
  },
}

// Spin variant for the fan's "indicator ring"
const fanRingVariant = {
  animate: {
    rotate: 360,
    transition: {
      repeat: Infinity,
      duration: 1.5,
      ease: 'linear',
    },
  },
}

// Slight bounce for the fan percentage text
const fanTextVariant = {
  animate: {
    y: [0, -1, 0],
    transition: {
      duration: 1,
      repeat: Infinity,
    },
  },
}

// Light pulsing effect for system status indicators
const pulseVariant = {
  animate: {
    opacity: [0.5, 1, 0.5],
    transition: {
      duration: 2,
      repeat: Infinity,
    },
  },
}

function MetricGauge({ value, label, icon: Icon }) {
  const gaugeSize = 40
  const strokeWidth = 4
  const radius = (gaugeSize - strokeWidth) / 2
  const circumference = 2 * Math.PI * radius
  const offset = circumference - (value / 100) * circumference

  const gaugeStroke = '#2dd4bf' // Subtle retro-ish color

  return (
    <motion.div
      className="flex flex-col items-center justify-center bg-[#EEE8DE]/20 rounded-md p-0.5 border border-[#A3968A] relative overflow-hidden"
      variants={fadeIn}
      initial="hidden"
      animate="visible"
      style={{ width: 'fit-content' }}
    >
      {/* Centered icon */}
      <div className="flex items-center justify-center mb-px">
        <Icon size={12} className="text-[#5C5753]" />
      </div>
      <div
        style={{ width: gaugeSize, height: gaugeSize }}
        className="mx-auto flex items-center justify-center"
      >
        <svg width={gaugeSize} height={gaugeSize}>
          <circle
            cx={gaugeSize / 2}
            cy={gaugeSize / 2}
            r={radius}
            fill="none"
            stroke="#e2e8f0"
            strokeWidth={strokeWidth}
          />
          <circle
            cx={gaugeSize / 2}
            cy={gaugeSize / 2}
            r={radius}
            fill="none"
            stroke={gaugeStroke}
            strokeWidth={strokeWidth}
            strokeDasharray={circumference}
            strokeDashoffset={offset}
            transform={`rotate(-90 ${gaugeSize / 2} ${gaugeSize / 2})`}
            className="transition-all duration-500"
          />
          <text
            x="50%"
            y="50%"
            dy=".3em"
            textAnchor="middle"
            className="text-[8px] font-bold fill-[#665E56]"
          >
            {Math.round(value)}
            %
          </text>
        </svg>
      </div>
      <span className="text-[8px] text-[#665E56] mt-0.5 font-medium text-center">
        {label}
      </span>
    </motion.div>
  )
}

function LogStream({ type, logs }) {
  return (
    <motion.div
      className="flex-1 bg-[#EEE8DE]/20 rounded-md p-1.5 border border-[#A3968A] overflow-hidden"
      variants={fadeIn}
      initial="hidden"
      animate="visible"
    >
      <div className="flex items-center justify-between mb-0.5">
        <div className="flex items-center">
          <div className="w-1 h-1 rounded-full bg-[#7DBF9A] mr-1" />
          <span className="text-[9px] font-medium text-[#5C5753]">
            {type.toUpperCase()}
            {' '}
            LOGS
          </span>
        </div>
        <div className="flex items-center space-x-1 text-[9px] text-[#665E56]">
          <Shield size={10} />
          <span>Encrypted</span>
        </div>
      </div>
      <div className="space-y-px max-h-9 overflow-auto text-[#42403E]">
        {logs.map(log => (
          <motion.div
            key={log.id}
            className="text-[9px] font-mono overflow-hidden text-ellipsis whitespace-nowrap"
            variants={fadeIn}
          >
            <span className="text-gray-500">{log.timestamp}</span>
            {' '}
            {/* Using the full local time string */}
            <span
              className={`ml-1 ${
                log.severity === 'ERROR'
                  ? 'text-rose-600'
                  : log.severity === 'WARN'
                    ? 'text-amber-600'
                    : log.severity === 'DEBUG'
                      ? 'text-blue-600'
                      : 'text-emerald-600'
              }`}
            >
              [
              {log.severity}
              ]
            </span>
            <span className="text-indigo-600 ml-1">
              {log.prefix}
              [
              {log.pid}
              ]:
            </span>
            <span className="text-gray-700 ml-1">{log.message}</span>
          </motion.div>
        ))}
      </div>
    </motion.div>
  )
}

const spinAnimation = {
  animate: {
    rotate: 360,
    transition: {
      duration: 3,
      repeat: Infinity,
      ease: 'linear',
    },
  },
}

function ServerFan({ speed = 0.75 * Math.random() }) {
  return (
    <motion.div
      className="flex flex-col items-center"
      variants={fadeIn}
      initial="hidden"
      animate="visible"
    >
      <div className="relative flex items-center justify-center w-5 h-5">
        <motion.div
          className="absolute w-8 h-8 rounded-full bg-green-100 border-2 border-green-400"
          variants={spinAnimation}
          animate="animate"
        />
        <motion.div
          className="absolute z-10"
          variants={spinAnimation}
          animate="animate"
        >
          <Fan className="w-8 bg-gray-900 rounded-full h-8 text-gray-600" />
        </motion.div>
        <div className="z-30 text-2xs text-white font-medium bg-gray-500 rounded ">
          {(speed * 100).toFixed(0)}
          %
        </div>
      </div>
      <span className="text-2xs text-gray-600 mt-0.5">Fan</span>
    </motion.div>
  )
}

function ServerRack({ rate_limit_template }) {
  const { org } = useAuth()
  const isEnterprise = org?.org_type === 'enterprise'
  const [metrics, setMetrics] = useState({
    cpu: 45,
    memory: 60,
    network: 30,
    disk: 55,
    temperature: 35,
  })

  const [logs, setLogs] = useState({
    system: [],
    security: [],
    application: [],
  })

  const [fans, setFans] = useState([0.8, 0.85, 0.9, 0.95])

  // Not used below, but left in place if needed
  const [networkPorts] = useState(
    Array.from({ length: 8 })
      .fill()
      .map(() => ({
        active: Math.random() > 0.3,
        speed: Math.random() > 0.5 ? '10G' : '1G',
      })),
  )

  // Update metrics, clamped to 70% for CPU, memory, network, disk
  useEffect(() => {
    const interval = setInterval(() => {
      setMetrics(prev => ({
        cpu: Math.min(70, Math.max(0, prev.cpu + (Math.random() - 0.5) * 10)),
        memory: Math.min(
          70,
          Math.max(0, prev.memory + (Math.random() - 0.5) * 5),
        ),
        network: Math.min(
          70,
          Math.max(0, prev.network + (Math.random() - 0.5) * 15),
        ),
        disk: Math.min(70, Math.max(0, prev.disk + (Math.random() - 0.5) * 3)),
        temperature: Math.min(
          80,
          Math.max(30, prev.temperature + (Math.random() - 0.5) * 1),
        ),
      }))
    }, 2000)

    return () => clearInterval(interval)
  }, [])

  // Periodically generate logs
  useEffect(() => {
    const interval = setInterval(() => {
      setLogs((prev) => {
        const type = ['system', 'security', 'application'][
          Math.floor(Math.random() * 3)
        ]
        const newLog = generateLogEntry(type)
        return {
          ...prev,
          [type]: [newLog, ...prev[type]].slice(0, 10),
        }
      })
    }, 3000)

    return () => clearInterval(interval)
  }, [])

  // Adjust fan speeds based on temperature
  useEffect(() => {
    setFans(prev =>
      prev.map((speed) => {
        const target
          = metrics.temperature > 60
            ? 1
            : metrics.temperature > 50
              ? 0.9
              : metrics.temperature > 40
                ? 0.8
                : 0.7
        return speed + (target - speed) * 0.1
      }),
    )
  }, [metrics.temperature])

  return (
    // Do not change total height (same as first message: h-3/4)
    <div className="w-full h-auto flex flex-col space-y-1.5">
      {/* Header (No beige background here) */}
      <div className="w-full rounded-md p-1.5 ">
        <h2 className="text-xs font-semibold text-[#42403E] mb-0.5">
          {isEnterprise
            ? '[CONNECTED] Dedicated Infrastructure'
            : 'Shared San Francisco, CA Servers'}
        </h2>
        <div className="flex items-center space-x-1.5 text-2xs text-[#665E56]">
          <div className="w-1 h-1 bg-emerald-500 rounded-full animate-pulse" />
          <span>Connected</span>
          <span className="mx-1.5">•</span>
          <span>4 Baseline Nodes</span>
        </div>
      </div>

      {/* Main container (Beige background here) */}
      <div className="w-full rounded-md border border-[#CABFB7] overflow-hidden relative flex-1">
        <div className="p-2 flex flex-col space-y-2 h-full">
          {/* Upper row: Gauges & fans */}
          <div className="flex flex-col md:flex-row md:space-x-2 md:space-y-0 space-y-2 flex-1">
            {/* Gauges */}
            <div className=" rounded-md p-2 border border-[#A3968A] flex-1 flex flex-col justify-between min-h-[140px]">
              <div className="grid grid-cols-2 sm:grid-cols-4 gap-1.5">
                <MetricGauge value={metrics.cpu} label="CPU" icon={Cpu} />
                <MetricGauge
                  value={metrics.memory}
                  label="Memory"
                  icon={Database}
                />
                <MetricGauge
                  value={metrics.network}
                  label="Network"
                  icon={NetworkIcon}
                />
                <MetricGauge
                  value={metrics.disk}
                  label="Disk"
                  icon={HardDrive}
                />
              </div>
              <div className="grid grid-cols-4 gap-1.5 mt-1.5">
                {fans.map((speed, i) => (
                  <ServerFan speed={speed} key={i} />
                ))}
              </div>
            </div>

            {/* System Status */}
            <div className=" rounded-md p-2 border border-[#A3968A] flex-1 min-h-[140px]">
              <div className="text-xs font-medium text-[#42403E] mb-1.5">
                System Status
              </div>
              <div className="space-y-1.5">
                {[
                  ['Power Supply 1', 'OK'],
                  ['Power Supply 2', 'OK'],
                  ['RAID Status', 'Optimal'],
                  ['System Health', 'Normal'],
                ].map(([label, status]) => (
                  <div
                    key={label}
                    className="flex items-center justify-between"
                  >
                    <span className="text-[10px] text-[#5C5753]">{label}</span>
                    <div className="flex items-center">
                      {/* Pulse the status light */}
                      <motion.div
                        className="w-1 h-1 rounded-full bg-emerald-500 mr-1"
                        variants={pulseVariant}
                        animate="animate"
                      />
                      <span className="text-[10px] text-[#42403E] font-medium">
                        {status}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Lower row: Logs */}
          <div className="flex flex-col md:flex-row md:space-x-2 md:space-y-0 space-y-2 flex-1">
            <LogStream type="system" logs={logs.system} />
            <LogStream type="security" logs={logs.security} />
            <LogStream type="application" logs={logs.application} />
          </div>
        </div>

        {/* Updated overlay logic */}
        {isEnterprise ? (
          <motion.div
            className="absolute inset-0 flex items-center justify-center"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            {/* No visible CTA if enterprise */}
          </motion.div>
        ) : (
          <motion.div
            className="absolute inset-0 bg-white/40 backdrop-blur-sm flex items-center justify-center z-50"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <div className="bg-white/90 border border-[#A3968A] px-2.5 py-2 rounded-sm text-center">
              <h3 className="text-xs font-semibold text-[#42403E] mb-1.5">
                Access Enterprise Infrastructure
              </h3>
              <a
                href="/enterprise"
                className="inline-flex items-center px-2 py-1 bg-[#C084FC] hover:bg-[#A779E9] text-white text-xs font-medium rounded-sm transition-colors duration-200"
              >
                Connect to Bland Enterprise
              </a>
            </div>
          </motion.div>
        )}
      </div>
    </div>
  )
}

export default ServerRack
