import { Button, Chip, DateRangePicker, Form, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Select, SelectItem, useDisclosure } from '@heroui/react'
import { getLocalTimeZone, today } from '@internationalized/date'
import TimezoneSelect from 'components/Hero/TimezoneSelect'
import { useAuth } from 'hooks/useAuth'
import { Send } from 'lucide-react'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { $fetch } from 'utils/fetch'

const ALL_COLUMNS = [
  'c_id',
  'created_at',
  'call_length',
  'to',
  'from',
  'inbound',
  'max_duration',
  'variables',
  'start_time',
  'completed',
  'queue_status',
  'error_message',
  'answered_by',
  'batch_id',
  'started_at',
  'record',
  'recording_url',
  'summary',
  'local_dialing',
  'price',
  'call_ended_by',
  'analysis',
  'analysis_schema',
  'campaign_id',
  'transferred_to',
  'pathway_tags',
  'pathway_id',
  'error_message',
]

export default function ExportCalls({ activeFilters }) {
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { user } = useAuth()

  const onSubmit = async (e) => {
    e.preventDefault()
    setIsSubmitting(true)

    const formData = new FormData(e.currentTarget)
    const data = {
      email: formData.get('email'),
      timezone: formData.get('timezone'),
      // Use getAll for the multi-select field.
      excludedColumns: formData.getAll('excludeColumns'),
      range: {
        start_date: formData.get('startDate'),
        end_date: formData.get('endDate'),
      },
      file_type: 'csv',
      filters: activeFilters,
    }

    try {
      await $fetch(`/exports/calls`, {
        method: 'POST',
        body: data,
      })

      toast.success('You will receive an email shortly with your call logs.')
    }
    catch (error) {
      console.error(error)
      const responseError = error.data?.errors?.[0]?.error
      if (
        responseError === 'EXPORT_CALLS_FAILURE_RATELIMIT'
      ) {
        return toast.error(
          'Rate limit exceeded. Only allowed to make 5 exports per hour.',
        )
      }
      if (
        ['UNAUTHORIZED', 'EXPORT_CALLS_FAILURE_ORG_PERMISSIONS'].includes(responseError)
      ) {
        return toast.error(
          'You are not authorized to export call logs.',
        )
      }
      toast.error('An error occurred while exporting call logs.')
    }
    finally {
      setIsSubmitting(false)
      onClose()
    }
  }

  const exportFilters = activeFilters?.filter(filter => filter.field !== 'created_at')

  return (
    <>
      <Button color="primary" size="sm" variant="bordered" radius="sm" onPress={onOpen} className="shrink-0">
        <Send size={16} />
        Export Calls
      </Button>
      <Modal
        size="3xl"
        isOpen={isOpen}
        radius="sm"
        placement="top-center"
        onOpenChange={onOpenChange}
        classNames={{
          footer: 'ml-auto',
          body: 'flex flex-col gap-5 w-full',
          header: 'border-b border-gray-200 bg-[#18181b] w-full',
        }}
      >
        <ModalContent>
          {onClose => (
            <>
              <Form validationBehavior="native" onSubmit={onSubmit}>
                <ModalHeader className="flex flex-col gap-1">
                  <h2 className="text-2xl font-taurus text-white font-semibold flex items-center gap-3">
                    <Send size={20} />
                    Export Calls
                  </h2>
                  <p className="text-xs font-taurus font-light tracking-wider text-foreground-400">Export call logs with the selected filters to your email.</p>
                </ModalHeader>
                <ModalBody>
                  {exportFilters?.length > 0 && (
                    <span className="flex flex-wrap gap-2 items-center">
                      <p className="text-sm pr-1">Active Filters:</p>
                      {exportFilters.map(filter => (
                        <Chip key={filter.field} color="primary" variant="flat" radius="sm">
                          {filter.field}
                        </Chip>
                      ))}
                    </span>
                  )}
                  <Input
                    isRequired
                    label="Email"
                    labelPlacement="outside"
                    defaultValue={user?.user?.email}
                    variant="bordered"
                    radius="sm"
                    name="email"
                    placeholder="Enter your email"
                    type="email"
                  />
                  <DateRangePicker
                    isRequired
                    label="Date Range"
                    labelPlacement="outside"
                    variant="bordered"
                    radius="sm"
                    placeholder="Select a date range"
                    description="Maximum date range is 180 days."
                    defaultValue={{
                      start: today(getLocalTimeZone()).subtract({ days: 30 }),
                      end: today(getLocalTimeZone()),
                    }}
                    maxValue={today(getLocalTimeZone())}
                    validate={(value) => {
                      if (!value.start || !value.end) {
                        return 'Please select a date range'
                      }
                      const start = value.start.toDate()
                      const end = value.end.toDate()
                      const diffDays = (end - start) / (1000 * 60 * 60 * 24)
                      if (diffDays > 180) {
                        return 'Date range should not exceed 180 days'
                      }
                      return null
                    }}
                    startName="startDate"
                    endName="endDate"
                  />
                  <TimezoneSelect
                    label="Timezone"
                    labelPlacement="outside"
                    name="timezone"
                    placeholder="Select a timezone"
                  />

                  <Select
                    label="Exclude Columns"
                    labelPlacement="outside"
                    variant="bordered"
                    radius="sm"
                    selectionMode="multiple"
                    name="excludeColumns"
                    placeholder="Select columns to exclude"
                    description="Select call fields to exclude from the export."
                  >
                    {ALL_COLUMNS.map(column => (
                      <SelectItem key={column} value={column}>
                        {column}
                      </SelectItem>
                    ))}
                  </Select>
                </ModalBody>
                <ModalFooter>
                  <Button color="danger" variant="flat" onPress={onClose}>
                    Cancel
                  </Button>
                  <Button color="primary" type="submit" isLoading={isSubmitting}>
                    Export
                  </Button>
                </ModalFooter>
              </Form>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  )
}
