import { Switch } from '@headlessui/react'
import { useStatsigClient } from '@statsig/react-bindings'
import { useEffect, useState } from 'react'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'

function ModelOptions({
  modelOptions,
  setModelOptions,
  noSkipUserResponse = false,
  isIVRNode = false,
}) {
  const { client: statsig } = useStatsigClient()
  const isSMSReturnEnabled = statsig.checkGate('sms_pathways')

  const [isOpen, setIsOpen] = useState(false)
  const [skipUserResponse, setSkipUserResponse] = useState(
    modelOptions.skipUserResponse || false,
  )
  const [enableThreshold, setEnableThreshold] = useState(
    modelOptions.interruptionThreshold || false,
  )
  const [blockInterruption, setBlockInterruption] = useState(
    modelOptions.block_interruptions || false,
  )
  const [disableEndCallTool, setDisableEndCallTool] = useState(
    modelOptions?.disableEndCallTool || false,
  )
  const [pressButtonsTool, setPressButtonsTool] = useState(
    modelOptions?.pressButtonsTool || false,
  )

  const [disableSilenceRepeat, setDisableSilenceRepeat] = useState(
    modelOptions?.disableSilenceRepeat || false,
  )
  const [isSMSReturnNode, setIsSMSReturnNode] = useState(
    modelOptions?.isSMSReturnNode || false,
  )

  const [conditionOverridesGlobalPathway, setConditionOverridesGlobalPathway]
    = useState(modelOptions?.conditionOverridesGlobalPathway || false)

  useEffect(() => {
    // handle interruptionThreshold
    if (enableThreshold) {
      setModelOptions({
        ...modelOptions,
        interruptionThreshold: modelOptions.interruptionThreshold,
      })
    }
    else {
      setModelOptions({
        ...modelOptions,
        interruptionThreshold: null,
      })
    }
  }, [enableThreshold, modelOptions.interruptionThreshold])

  useEffect(() => {
    setModelOptions({
      ...modelOptions,
      skipUserResponse,
      block_interruptions: blockInterruption,
      disableSilenceRepeat,
      conditionOverridesGlobalPathway,
      isSMSReturnNode: isSMSReturnEnabled ? isSMSReturnNode : false,
      disableEndCallTool: isIVRNode ? disableEndCallTool : false,
      //   pressButtonsTool,
    })
  }, [
    skipUserResponse,
    blockInterruption,
    disableEndCallTool,
    pressButtonsTool,
    disableSilenceRepeat,
    conditionOverridesGlobalPathway,
    isSMSReturnNode,
    isSMSReturnEnabled,
  ])

  const handleChange = (e) => {
    const { name, value, type } = e.target
    let newValue = type === 'number' ? Number.parseFloat(value) : value

    // Clamp temperature between 0 and 1
    if (name === 'newTemperature') {
      newValue = Math.min(Math.max(newValue, 0), 1)
    }

    setModelOptions({ ...modelOptions, [name]: newValue })
  }

  const toggleDropdown = () => setIsOpen(!isOpen)

  const SwitchOption = ({
    checked,
    onChange,
    label,
    description,
    disabled = false,
  }) => (
    <div className="flex items-center space-x-2.5 py-4 border-b border-gray-200 last:border-b-0">
      <Switch
        checked={checked}
        onChange={onChange}
        className={`${
          checked ? 'bg-indigo-600' : 'bg-gray-200'
        } relative inline-flex h-4 w-7 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 flex-shrink-0 mt-0.5`}
      >
        <span
          className={`${
            checked ? 'translate-x-4' : 'translate-x-0.5'
          } inline-block h-2.5 w-2.5 transform rounded-full bg-white transition`}
        />
      </Switch>
      <div className="flex-grow">
        <label className="text-sm font-medium">{label}</label>
        <p className="text-xs text-gray-500 mt-0.5">{description}</p>
      </div>
    </div>
  )

  return (
    <div className="border p-2.5 rounded shadow-sm">
      <div
        className="flex items-center justify-between cursor-pointer"
        onClick={toggleDropdown}
      >
        <h3 className="text-base">Advanced Options</h3>
        <button className="text-base">
          {isOpen ? <FaChevronUp /> : <FaChevronDown />}
        </button>
      </div>
      <p className="text-sm text-gray-600 mt-0.5">
        Adjust settings to optimize model performance (Temperature, Latency, and
        Model Intelligence)
      </p>
      {isOpen && (
        <div className="mt-2.5">
          <div>
            <label className="block mb-0.5 font-medium">
              Temperature
              {' '}
              <span className="text-xs text-gray-600">
                Between 0.0 to 1.0
              </span>
            </label>
            <input
              type="number"
              name="newTemperature"
              value={modelOptions?.newTemperature ?? modelOptions.temperature}
              onChange={handleChange}
              max="1"
              min="0"
              step="0.1"
              className="w-full p-1.5 border rounded-"
            />
          </div>

          <div className="space-y-1.5">
            <SwitchOption
              checked={enableThreshold}
              onChange={setEnableThreshold}
              label="Dynamic Interruption Threshold"
              description="Update Interruption Threshold from this node onwards."
            />
            {enableThreshold && (
              <div className="ml-2.5">
                <label className="block mb-1.5 font-medium ">
                  Interruption Threshold
                </label>
                <input
                  type="number"
                  name="interruptionThreshold"
                  value={modelOptions.interruptionThreshold}
                  onChange={handleChange}
                  max="500"
                  min="0"
                  step="50"
                  className="w-full p-1.5 border rounded-"
                />
              </div>
            )}
          </div>

          {!noSkipUserResponse && (
            <SwitchOption
              checked={skipUserResponse}
              onChange={setSkipUserResponse}
              label="Skip User's Response"
              description="Continue immediately from this node without waiting for user response."
            />
          )}

          <SwitchOption
            checked={blockInterruption}
            onChange={setBlockInterruption}
            label="Block Interruptions"
            description="Ignore user's interruptions at this node."
          />

          <SwitchOption
            checked={disableSilenceRepeat}
            onChange={setDisableSilenceRepeat}
            label="Disable Repeating the node on Silence"
            description="Whenever there is an extended period of silence, the default behaviour is to repeat the current node. This option disables that, and enables picking up the extended silence as a user response to make a decision on. E.g. You can go to a different node if an extended silence is detected."
          />
          {isSMSReturnEnabled && (
            <SwitchOption
              checked={isSMSReturnNode}
              onChange={setIsSMSReturnNode}
              label="Return to this node when an SMS chat times out"
              description="Whenever an SMS chat ends because it has timed out. We will resume the conversation at this node if the user picks up the chat again"
            />
          )}

          <SwitchOption
            checked={conditionOverridesGlobalPathway}
            onChange={setConditionOverridesGlobalPathway}
            label="Condition Overrides Global Pathway"
            description="Whenever a global node is selected as the pathway label, the default behaviour is to ignore whether the condition is met or not, and go to the global node. This option enables the condition to be checked before going to the global node. E.g. If the condition is not met, the model will not go to the global node."
          />

          {isIVRNode && (
            <SwitchOption
              checked={disableEndCallTool}
              onChange={setDisableEndCallTool}
              label="Disable End Call Tool"
              description="Disable the end call tool at this node"
            />
          )}

          {/* <SwitchOption
            checked={pressButtonsTool}
            onChange={setPressButtonsTool}
            label="Press Buttons Tool"
            description="Agent can decide to Press Buttons at this node."
          /> */}
        </div>
      )}
    </div>
  )
}

export default ModelOptions
