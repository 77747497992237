import { Button } from '@radix-ui/themes'
import { Minus } from 'lucide-react'
import { useEffect } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import styled from 'styled-components'

export function ToolPayload({ fieldName }) {
  const { control, getValues, setValue } = useFormContext()

  const { fields, append, remove } = useFieldArray({
    control,
    name: fieldName,
  })

  const handleChangeInput = (value, index, key) => {
    const updatedObject = [...getValues(fieldName)]
    updatedObject[index][key] = value
    setValue(fieldName, [...updatedObject])
  }

  const getValue = (index, key) => {
    return getValues(`${fieldName}.[${index}].${key}`)
  }

  const add = () => {
    append({
      key: '',
      value: '',
      example_value: '',
      static: false,
      required: true,
    })
  }

  useEffect(() => {
    if ((getValues(fieldName)?.length || 0) === 0)
      add()
  }, [])

  return (
    <div style={{}}>
      <Wrapper style={{ margin: '10px 0px 10px 0px' }}>
        {fields.map((item, index) => (
          <Grid key={index}>
            <Item>
              <input
                placeholder="Key"
                onChange={(ev) => handleChangeInput(ev.target.value, index, 'key')}
                className="w-full h-full p-2.5"
                value={item.key}
              />
            </Item>
            <Item>
              <input
                placeholder={item.static ? 'Value' : 'string, number, boolean'}
                onChange={(ev) => handleChangeInput(ev.target.value, index, 'value')}
                className="w-full h-full p-2.5"
                value={item.value}
              />
            </Item>
            <Item>
              <input
                placeholder="Description (example value)"
                onChange={(ev) => handleChangeInput(ev.target.value, index, 'example_value')}
                disabled={item.static}
                className="w-full h-full p-2.5"
                value={item.static ? '' : item.example_value}
              />
            </Item>
            <Item
              className="h-full"
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              <input
                className="h-full"
                checked={item.required}
                onClick={(ev) =>
                  handleChangeInput(!item.required, index, 'required')
                }
                type="checkbox"
              />
              <p style={{ fontSize: 10, fontWeight: '550', color: '#000' }}>
                Required
              </p>
            </Item>
            <Item onClick={() => remove(index)} className="hover:bg-red-50 h-full w-full">
              <Minus size={14} />
            </Item>
          </Grid>
        ))}
      </Wrapper>

      <Button type="button" variant="soft" className="mt-2.5" onClick={add}>
        Add Row
      </Button>
    </div>
  )
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid #e5e7eb;
  border-right: none;
  border-bottom: none;
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 60px 60px;
  width: 100%:
  grid-gap: 1px;
`

const Item = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border-right: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
  justify-content: center;
  align-items: center;
  justify-items: center;
`
