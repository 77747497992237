import { AxisBottom } from '@visx/axis'
import { localPoint } from '@visx/event'
import { Group } from '@visx/group'
import { scaleBand } from '@visx/scale'
import { defaultStyles, useTooltip } from '@visx/tooltip'
import React, { useEffect, useMemo, useRef, useState } from 'react'

// Constants
const BASE_MARKERS = [0, 100, 500, 1000, 10000, 25000, 50000, 100000]
const COLOR_USED = '#6c5efb'
const COLOR_REMAINING = '#e2e8f0'
const COLOR_LIMIT = '#dc2626'
const COLOR_AXIS = '#6b7280'
const BG_COLOR = '#ffffff'

const tooltipStyles = {
  ...defaultStyles,
  backgroundColor: 'rgba(0, 0, 0, 0.85)',
  padding: '8px 12px',
  border: '1px solid #374151',
  borderRadius: '6px',
}

// Utility functions
function buildDiscreteArray(dailyRateLimit) {
  const limit = Math.max(0, Math.min(dailyRateLimit, 100000))
  return [...new Set([...BASE_MARKERS, limit])].sort((a, b) => a - b)
}

function snapValue(val, discreteArr) {
  for (const discrete of discreteArr) {
    if (discrete >= val)
      return discrete
  }
  return discreteArr[discreteArr.length - 1]
}

const getIndex = (val, discreteArr) => discreteArr.indexOf(val)

function formatDate(dateStr) {
  const date = new Date(dateStr)
  return date.toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
  })
}

function DailyCallsChart({ lastSevenCalls = [], dailyRateLimit = 2000 }) {
  // Transform hourly data to daily totals
  const dailyData = useMemo(() => {
    const dailyGroups = {}

    lastSevenCalls.forEach((item) => {
      if (!item?.hour)
        return

      try {
        // Extract YYYY-MM-DD from the ISO string
        const date = new Date(item.hour).toISOString().split('T')[0]

        if (!dailyGroups[date]) {
          dailyGroups[date] = {
            date,
            callsUsed: 0,
          }
        }

        dailyGroups[date].callsUsed += Number(item.count) || 0
      }
      catch (e) {
        console.warn('Invalid date format:', item.hour)
      }
    })

    // Convert to array and calculate remaining values
    return Object.values(dailyGroups)
      .map(day => ({
        date: day.date,
        callsUsed: day.callsUsed,
        remaining: Math.max(0, dailyRateLimit - day.callsUsed),
      }))
      .sort((a, b) => a.date.localeCompare(b.date))
      .slice(-7)
  }, [lastSevenCalls, dailyRateLimit])

  // Container ref and dimensions state
  const containerRef = useRef(null)
  const [dimensions, setDimensions] = useState({ width: 300, height: 200 })

  useEffect(() => {
    if (!containerRef.current)
      return

    const resizeObserver = new ResizeObserver((entries) => {
      if (entries[0]) {
        const { width } = entries[0].contentRect
        setDimensions({
          width: Math.max(width, 300),
          height: 200,
        })
      }
    })

    resizeObserver.observe(containerRef.current)
    return () => resizeObserver.disconnect()
  }, [])

  const margin = { top: 20, right: 20, bottom: 40, left: 60 }
  const innerWidth = dimensions.width - margin.left - margin.right
  const innerHeight = dimensions.height - margin.top - margin.bottom

  const discreteArr = useMemo(
    () => buildDiscreteArray(dailyRateLimit),
    [dailyRateLimit],
  )

  const chartData = useMemo(() => {
    return dailyData.map((item) => {
      const used = Math.min(item.callsUsed, dailyRateLimit)
      const total = Math.min(used + item.remaining, dailyRateLimit)
      return {
        date: item.date,
        callsUsed: item.callsUsed,
        remaining: item.remaining,
        usedIndex: getIndex(snapValue(used, discreteArr), discreteArr),
        totalIndex: getIndex(snapValue(total, discreteArr), discreteArr),
      }
    })
  }, [dailyData, dailyRateLimit, discreteArr])

  const totalCallsUsed = useMemo(
    () => dailyData.reduce((acc, d) => acc + d.callsUsed, 0),
    [dailyData],
  )

  const peakDailyCalls = useMemo(
    () =>
      dailyData.length ? Math.max(...dailyData.map(d => d.callsUsed)) : 0,
    [dailyData],
  )

  const xScale = useMemo(
    () =>
      scaleBand({
        range: [0, innerWidth],
        domain: chartData.map(d => d.date),
        padding: 0.2,
      }),
    [innerWidth, chartData],
  )

  const yScale = useMemo(
    () =>
      scaleBand({
        range: [innerHeight, 0],
        domain: discreteArr.map((_, i) => i),
        padding: 0.1,
      }),
    [innerHeight, discreteArr],
  )

  const {
    tooltipOpen,
    tooltipData,
    tooltipLeft,
    tooltipTop,
    showTooltip,
    hideTooltip,
  } = useTooltip()

  const limitIdx = discreteArr.indexOf(dailyRateLimit)
  const limitY = limitIdx !== -1 ? yScale(limitIdx) : null

  return (
    <div className="w-full flex flex-col bg-white rounded-md border border-gray-200">
      <div className="px-2.5 py-1.5 border-b border-gray-200">
        <h3 className="text-xs font-semibold text-gray-900">API Usage</h3>
        <p className="text-2xs text-gray-600">
          Total calls:
          {' '}
          {totalCallsUsed.toLocaleString()}
        </p>
      </div>

      <div className="relative w-full h-[200px]" ref={containerRef}>
        <svg
          width={dimensions.width}
          height={dimensions.height}
          className="overflow-visible"
        >
          <rect
            x={0}
            y={0}
            width={dimensions.width}
            height={dimensions.height}
            fill={BG_COLOR}
            rx={4}
          />

          <Group left={margin.left} top={margin.top}>
            {/* Grid lines */}
            {discreteArr.map((_, i) => {
              const y = yScale(i)
              return (
                y && (
                  <line
                    key={`grid-${i}`}
                    x1={0}
                    x2={innerWidth}
                    y1={y}
                    y2={y}
                    stroke="#e5e7eb"
                    strokeDasharray="4,4"
                  />
                )
              )
            })}

            {/* Rate limit line */}
            {limitY && (
              <>
                <line
                  x1={0}
                  x2={innerWidth}
                  y1={limitY}
                  y2={limitY}
                  stroke={COLOR_LIMIT}
                  strokeWidth={1.5}
                  strokeDasharray="4,4"
                />
                <text
                  x={4}
                  y={limitY - 4}
                  fill={COLOR_LIMIT}
                  fontSize={10}
                  fontWeight="500"
                >
                  Daily Limit
                </text>
              </>
            )}

            {/* Bars */}
            {chartData.map((d) => {
              const x = xScale(d.date)
              if (x === undefined || d.callsUsed === 0)
                return null

              const barWidth = xScale.bandwidth()
              const usedY = yScale(d.usedIndex)
              const zeroY = yScale(0)
              const remY = yScale(d.totalIndex)

              if (!usedY || !zeroY || !remY)
                return null

              const usedHeight = zeroY - usedY
              const remHeight = usedY - remY

              return (
                <React.Fragment key={d.date}>
                  <rect
                    x={x}
                    y={usedY}
                    width={barWidth / 2}
                    height={usedHeight}
                    fill={COLOR_USED}
                    rx={2}
                    onMouseMove={(event) => {
                      const coords = localPoint(event)
                      showTooltip({
                        tooltipData: {
                          date: formatDate(d.date),
                          label: 'Used',
                          value: d.callsUsed,
                        },
                        tooltipLeft: coords?.x,
                        tooltipTop: coords?.y,
                      })
                    }}
                    onMouseLeave={hideTooltip}
                  />
                  <rect
                    x={x}
                    y={remY}
                    width={barWidth / 2}
                    height={remHeight}
                    fill={COLOR_REMAINING}
                    rx={2}
                    onMouseMove={(event) => {
                      const coords = localPoint(event)
                      showTooltip({
                        tooltipData: {
                          date: formatDate(d.date),
                          label: 'Remaining',
                          value: d.remaining,
                        },
                        tooltipLeft: coords?.x,
                        tooltipTop: coords?.y,
                      })
                    }}
                    onMouseLeave={hideTooltip}
                  />
                </React.Fragment>
              )
            })}

            {/* Y-axis labels */}
            {discreteArr.map((val, i) => {
              const y = yScale(i)
              if (y === undefined)
                return null
              return (
                <text
                  key={`ylabel-${val}`}
                  x={-12}
                  y={y + yScale.bandwidth() / 4}
                  textAnchor="end"
                  dominantBaseline="middle"
                  fill={COLOR_AXIS}
                  fontSize={10}
                >
                  {val.toLocaleString()}
                </text>
              )
            })}

            {/* X-axis */}
            <AxisBottom
              top={innerHeight}
              scale={xScale}
              stroke={COLOR_AXIS}
              tickFormat={formatDate}
              tickStroke={COLOR_AXIS}
              tickLabelProps={() => ({
                fill: COLOR_AXIS,
                fontSize: 10,
                textAnchor: 'middle',
                dy: '0.25em',
              })}
            />
          </Group>
        </svg>

        {tooltipOpen && tooltipData && (
          <div
            style={{
              ...tooltipStyles,
              position: 'absolute',
              left: tooltipLeft,
              top: tooltipTop,
              transform: 'translate(-50%, -100%)',
            }}
          >
            <div className="text-white text-2xs">
              <div className="font-medium">
                {tooltipData.label}
                :
                {tooltipData.value.toLocaleString()}
                {' '}
                calls
              </div>
              <div className="text-gray-300 text-[10px]">
                {tooltipData.date}
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="px-2.5 py-1.5 border-t border-gray-200 text-2xs text-gray-600">
        Peak daily calls:
        {' '}
        {peakDailyCalls.toLocaleString()}
      </div>
    </div>
  )
}

export default DailyCallsChart
