import { cn, ScrollShadow } from '@heroui/react'
import ActionBar from 'components/core/ActionBar'
import Button from 'components/core/Button'
import Modal from 'components/core/Modal'
import { PageTitle } from 'components/core/PageTitle'
import { PageWrapper } from 'components/core/PageWrapper'
import Switch from 'components/core/Switch'
import AccordionForm from 'components/Hero/AccordionForm'
import config from 'config'
import { useAuth } from 'hooks/useAuth'
import { useOnboarding } from 'hooks/useOnboarding'
import SendCallTour from 'pages/Onboarding/SendCallTour'
import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { $fetch } from 'utils/fetch'
import { getAuthToken } from 'utils/funcs/browser/getAuthToken'
import { parseForJson } from 'utils/funcs/send-call/parseForJson'
import CloseIcon from '../../../assets/icons/close-icon-black.svg'
import TrashIcon from '../../../assets/icons/trash-grey-fig.svg'
import ActiveCall from './ActiveCall'
import CodePreview from './CodePreview'
import JSONMode from './JSONMode'
import SendCallCTA from './SendCallCTA'
import SendCallForm from './SendCallForm'
import 'react-toastify/dist/ReactToastify.css'

export default function SendCall() {
  const { user } = useAuth()
  const { state: { run } } = useOnboarding()

  const [callId, setCallId] = useState(null)
  const [loading, setLoading] = useState(false)
  const [collapseCode, setCollapseCode] = useState(run)
  const [savedPrompts, setSavedPrompts] = useState([])
  const [promptName, setPromptName] = useState('')
  const [loadingPrompt, setLoadingPrompt] = useState(false)

  // Initializae the form and its default field values.
  const methods = useForm({
    defaultValues: {
      phone_number: user?.user?.phone_number,
      voice: 'June',
      wait_for_greeting: false,
      record: true,
      answered_by_enabled: true,
      noise_cancellation: false,
      interruption_threshold: 100,
      block_interruptions: false,
      max_duration: 12,
      model: 'base',
      memory_id: null,
      language: 'en',
      background_track: 'none',
      endpoint: config.API_URL,
      voicemail_action: 'hangup',
      prompt_modal: false,
      summary_prompt: null,
      save_prompt_modal: false,
      save_prompt: () => {
        return methods.setValue('save_prompt_modal', true)
      },
    },
  })

  // For conditionally rendering the call transcript.
  const isCallActive = methods.watch('isCallActive')
  const jsonMode = methods.watch('json_mode_enabled')
  const promptModal = methods.watch('prompt_modal')
  const savePromptModal = methods.watch('save_prompt_modal')
  const { formState: { dirtyFields } } = methods

  // Get user's API key for authorization in sending the call.

  const authToken = getAuthToken()

  // Main submit handler for sending a call.
  const onSubmit = async (formData) => {
    const { encrypted_key, ...rest } = formData
    const callConfig = parseForJson(rest, dirtyFields)

    try {
      // Remove the test error
      setLoading(true)
      const { call_id } = await $fetch(
        '/v1/calls',
        {
          baseURL: formData?.endpoint || config.API_URL,
          method: 'POST',
          body: callConfig,
          headers: {
            ...(encrypted_key ? { encrypted_key } : {}),
          },
        },
      )
      if (call_id) {
        toast.success('Call has been dispatched!', {
          position: 'bottom-right',
        })
        setCallId(call_id)
        methods.setValue('isCallActive', true)
        setLoading(false)
      }
    }
    catch (error) {
      console.error('Error submitting send call form:', error)

      let errorMessage = 'An error occurred while submitting the call form.'

      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        errorMessage
          = error.response.data?.errors?.toString()
            || error.response.data?.message?.toString()
            || errorMessage
        console.log('Server responded with error:', error.response.data)
      }
      else if (error.request) {
        // The request was made but no response was received
        errorMessage = 'No response received from server.'
        console.log('No response received:', error.request)
      }
      else {
        // Something happened in setting up the request that triggered an Error
        errorMessage = error.message
        console.log('Error setting up request:', error.message)
      }

      toast(errorMessage, {
        type: 'error',
      })

      setLoading(false)
    }
  }

  const handleDeletePrompt = async (promptId) => {
    const response = await $fetch(`/v1/prompts/${promptId}`, {
      method: 'DELETE',
      ignoreResponseError: true,
    })

    if (!response.errors) {
      toast.success('Prompt deleted successfully.')
      handleGetSavedPrompts()
    }
    else {
      toast.error('Error deleting prompt. Please try again.')
    }
  }

  const handleGetSavedPrompts = async () => {
    const response = await $fetch(`/v1/prompts`, {
      method: 'GET',
    })

    if (!response.errors) {
      const prompts = response.prompts
      if (prompts.length > 0) {
        setSavedPrompts(prompts)
      }
    }
  }

  const handleUpdatePrompt = async () => {
    setLoadingPrompt(true)
    const promptId = methods.getValues('selected_edit_prompt_id')
    const prompt = savedPrompts.find(prompt => prompt.id === promptId)
    try {
      await $fetch(`/v1/prompts/${prompt.id}`, {
        method: 'POST',
        body: {
          prompt: methods.getValues('task'),
        },
      })

      toast.success('Prompt updated successfully.')
      methods.setValue('selected_edit_prompt_id', null)
      methods.setValue('selected_saved_prompt_id', null)
      methods.setValue('save_prompt_modal', false)
      setPromptName('')
      handleGetSavedPrompts()
      setLoadingPrompt(false)
    }
    catch {
      setLoadingPrompt(false)
      toast.error('Error updating prompt. Please try again.')
    }
  }

  const handleCreatePrompt = async () => {
    setLoadingPrompt(true)
    try {
      await $fetch(`/v1/prompts`, {
        method: 'POST',
        body: {
          prompt: methods.getValues('task'),
          name: promptName,
        },
      })

      toast.success('Prompt saved successfully.')
      methods.setValue('selected_edit_prompt_id', null)
      methods.setValue('selected_saved_prompt_id', null)
      methods.setValue('prompt_modal', false)
      methods.setValue('save_prompt_modal', false)
      setPromptName('')
      handleGetSavedPrompts()
      setLoadingPrompt(false)
    }
    catch {
      setLoadingPrompt(false)
      toast.error('Error saving prompt. Please try again.')
    }
  }

  // Analytics
  useEffect(() => {
    handleGetSavedPrompts()
  }, [])

  return (
    <PageWrapper padding="54px 0px 0px 54px">
      <FormProvider {...methods}>
        {isCallActive
          ? (
              <div className="h-[calc(100vh-80px)] overflow-hidden">
                <ActiveCall callId={callId} apiKey={authToken} />
              </div>
            )
          : (
              <Form onSubmit={methods.handleSubmit(onSubmit)} className="h-full">
                <input type="hidden" {...methods.register('isCallActive')} />
                <>
                  <ActionBar top spaceBetween shiftRight>
                    <PageTitle>Send Call</PageTitle>

                    <div className="flex flex-row gap-3 ml-auto">
                      <a
                        href="https://docs.bland.ai/api-v1/post/calls"
                        target="_blank"
                        rel="noreferrer"
                        className="mr-auto"
                      >
                        <Button appearance="outline">
                          Read Docs
                        </Button>
                      </a>
                      {!jsonMode && (
                        <Button
                          appearance="outline"
                          style={{ justifyContent: 'start' }}
                          id="show-code-button"
                          onClick={() => {
                            setCollapseCode(!collapseCode)
                          }}
                        >
                          {collapseCode ? 'Show code' : 'Hide code'}
                        </Button>
                      )}
                      <Switch label="JSON Mode" fieldName="json_mode_enabled" />
                    </div>
                  </ActionBar>
                  {!jsonMode
                    ? (
                        <div className={cn('grid grid-cols-1 h-full max-h-full', { 'grid-cols-[2fr_1fr]': !collapseCode })}>
                          <ScrollShadow className="h-full w-full pb-[54px]" hideScrollBar visibility="none">
                            <AccordionForm formDefinition={SendCallForm} />
                            <SendCallCTA isLoading={loading} />
                          </ScrollShadow>
                          {!collapseCode && (
                            <CodePreview apiKey={authToken} collapseCode={collapseCode} />
                          )}
                        </div>
                      )
                    : (
                        <JSONMode />
                      )}

                </>
              </Form>
            )}

        <SendCallTour />

      </FormProvider>

      <Modal
        open={promptModal}
        onClose={() => {
          methods.setValue('prompt_modal', false)
        }}
      >
        <div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <p
              style={{ margin: 0, padding: 0, fontWeight: '500', fontSize: 18 }}
            >
              Saved Prompts (
              {savedPrompts.length}
              )
            </p>
            <img
              src={CloseIcon}
              onClick={() => {
                methods.setValue('prompt_modal', false)
              }}
              style={{ cursor: 'pointer' }}
            />
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
              marginTop: '25px',
            }}
          >
            {savedPrompts.length > 0
              ? (
                  savedPrompts.map((prompt, index) => (
                    <PromptItem
                      key={index}
                      onClick={() => {
                        methods.setValue('task', prompt.prompt)
                        methods.setValue('prompt_modal', false)
                        methods.setValue('selected_saved_prompt_id', prompt.id)
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          width: '100%',
                        }}
                      >
                        <p style={{ fontSize: 14, fontWeight: '500' }}>
                          {prompt?.name || 'My Prompt'}
                        </p>
                        <img
                          onClick={() => handleDeletePrompt(prompt.id)}
                          height={14}
                          width={14}
                          src={TrashIcon}
                          style={{ cursor: 'pointer' }}
                        />
                      </div>
                      <p
                        style={{
                          fontSize: 12,
                          fontWeight: '400',
                          marginTop: '2.5px',
                          color: '#555',
                        }}
                      >
                        {prompt?.prompt?.length > 400
                          ? `${prompt.prompt.slice(0, 400)}...`
                          : prompt.prompt}
                      </p>
                    </PromptItem>
                  ))
                )
              : (
                  <p>No prompts saved yet.</p>
                )}
          </div>
        </div>
      </Modal>

      <Modal
        open={savePromptModal}
        onClose={() => {
          methods.setValue('save_prompt_modal', false)
        }}
      >
        <div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <p
              style={{ margin: 0, padding: 0, fontWeight: '550', fontSize: 16 }}
            >
              Save Prompt
            </p>
            <img
              src={CloseIcon}
              onClick={() => {
                methods.setValue('save_prompt_modal', false)
              }}
              style={{ cursor: 'pointer' }}
            />
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
              marginTop: '25px',
            }}
          >
            <input
              onFocus={() => {
                methods.setValue('selected_edit_prompt_id', null)
              }}
              type="text"
              placeholder="Enter a name for your prompt"
              onChange={e => setPromptName(e.target.value)}
              style={{
                padding: '10px',
                borderRadius: '5px',
                border: '1px solid #f0f0f0',
              }}
            />

            {savedPrompts.length > 0 && (
              <div>
                <p
                  style={{
                    fontSize: 10,
                    fontWeight: '550',
                    color: 'grey',
                    textAlign: 'center',
                    marginTop: '5px',
                  }}
                >
                  - OR -
                </p>
                <p
                  style={{
                    fontSize: 14,
                    fontWeight: '500',
                    marginTop: '10px',
                    marginBottom: '10px',
                  }}
                >
                  Update a saved prompt:
                </p>
                {savedPrompts.map((prompt, index) => (
                  <div
                    key={index}
                    onClick={() => {
                      if (
                        methods.getValues('selected_edit_prompt_id')
                        === prompt.id
                      ) {
                        methods.setValue('selected_edit_prompt_id', null)
                      }
                      else {
                        methods.setValue('selected_edit_prompt_id', prompt.id)
                      }
                    }}
                    style={{
                      cursor: 'pointer',
                      border:
                        methods.getValues('selected_edit_prompt_id')
                        === prompt.id
                          ? '1px solid blue'
                          : '1px solid #f0f0f0',
                      padding: '10px',
                      borderRadius: '5px',
                      marginBottom: '10px',
                    }}
                  >
                    <p style={{ fontSize: 14, fontWeight: '500' }}>
                      {prompt?.name || 'My Prompt'}
                      {' '}
                      {methods.getValues('selected_edit_prompt_id')
                        === prompt.id && '(Currently Editing)'}
                    </p>
                    <p
                      style={{
                        fontSize: 12,
                        fontWeight: '400',
                        marginTop: '2.5px',
                        color: '#555',
                      }}
                    >
                      {prompt?.prompt?.length > 400
                        ? `${prompt.prompt.slice(0, 400)}...`
                        : prompt.prompt}
                    </p>
                  </div>
                ))}
              </div>
            )}

            <Button
              loading={loadingPrompt}
              onClick={() => {
                if (methods.getValues('selected_edit_prompt_id') !== null) {
                  // Update the prompt
                  console.log('Updating prompt...')
                  handleUpdatePrompt()
                }
                else {
                  handleCreatePrompt()
                }
              }}
              style={{ width: '100%', borderRadius: '5px' }}
            >
              Save Prompt
            </Button>
          </div>
        </div>
      </Modal>

    </PageWrapper>
  )
}

const PromptItem = styled.div`
  cursor: pointer;
  background-color: #fff;
  border-radius: 3px;
  padding: 15px;
  border: 1px solid #f0f0f0;
  &:hover {
    background-color: #eeeeee;
  }
`

const Form = styled.form`
  width: 100%;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
`

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  height: 100%;
  width: 100%;
  position: relative;

  /* Add a faint line between items */
  > *:not(:last-child) {
    position: relative;
    padding-bottom: 15px; /* Adjust as needed */
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1); /* Adjust the color and opacity as needed */
    }
  }
`
