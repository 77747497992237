import { motion } from 'framer-motion'
import { useMemo, useState } from 'react'
import { FiChevronDown, FiChevronUp } from 'react-icons/fi'

function CollapsibleContent({ content, maxLength = 100 }) {
  const [isExpanded, setIsExpanded] = useState(false)

  const stringContent = useMemo(() => {
    if (typeof content === 'string') {
      return content
    }
    else if (typeof content === 'object') {
      return JSON.stringify(content, null, 2)
    }
    else {
      return String(content)
    }
  }, [content])

  const shouldTruncate = stringContent.length > maxLength

  const toggleExpand = () => setIsExpanded(!isExpanded)

  const displayContent = isExpanded
    ? stringContent
    : `${stringContent.slice(0, maxLength)}${shouldTruncate ? '...' : ''}`

  return (
    <div className="relative">
      <pre className="text-gray-700 font-mono bg-gray-100 px-1.5 py-0.5 rounded-sm whitespace-pre-wrap">
        {displayContent}
      </pre>
      {shouldTruncate && (
        <motion.button
          onClick={toggleExpand}
          className="absolute bottom-0.5 right-0.5 bg-white border border-gray-300 rounded-full p-0.5 shadow-sm"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          {isExpanded ? <FiChevronUp size={16} /> : <FiChevronDown size={16} />}
        </motion.button>
      )}
    </div>
  )
}

export default CollapsibleContent
