import axios from 'axios'
import config from 'config'
import { getAuthToken } from 'utils/funcs/browser/getAuthToken'
import { getOrgId } from 'utils/funcs/browser/getOrgId'

export async function updateRechargeAmount(amount, userId, refillAt) {
  const token = getAuthToken()
  const orgId = getOrgId()

  const headers = {
    authorization: `${token}`,
    ...(orgId && { 'x-bland-org-id': orgId }),
  }
  try {
    const response = await axios.post(
      `${config.API_URL}/recharge/update`,
      {
        amount,
        refill_at: refillAt,
        user_id: userId,
      },
      { headers },
    )
    if (response.ok) {
      return response.data
    }
  }
  catch (error) {
    console.log(error)
    return error?.response?.data?.message || 'Unknown error'
  }
}
