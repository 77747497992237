import {
  Badge,
  Button,
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
  Spinner,
  Chip,
} from '@heroui/react'
import { Check, ChevronDown, CircleAlert, MessageSquare, Phone } from 'lucide-react'
import { useState, useEffect } from 'react'
import styled from 'styled-components'
import config from 'config'
import { getApiKey } from 'utils/funcs/browser/getApiKey'
import { getOrgId } from 'utils/funcs/browser/getOrgId'
import { toast } from 'react-toastify'

const DropdownDivItem = styled.div`
  cursor: pointer;

  padding: 5px;
  border-radius: 4px;
  &:hover {
    background-color: #f0f0f0;
  }
`

export default function InboundNumberDropdown({
  inboundNumbers,
  currentInboundNumber,
  pathwayId,
  versionNumber,
  isSmsPathway = false,
  onNumberChanged,
  confirmChangeComponent,
}) {
  const orgId = getOrgId()
  const [loadingChangeNumber, setLoadingChangeNumber] = useState(false)
  const [confirmChangeNumberOpen, setConfirmChangeNumberOpen] = useState({
    open: false,
    number: null,
  })
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)


  // Generate button text based on current state
  const getButtonText = () => {
    // For SMS pathway, check if any inbound number is currently linked to this pathway
    if (isSmsPathway) {
      const currentlyLinkedNumber = inboundNumbers?.find(
        num => num.sms_config && num.sms_config.pathway_id === pathwayId
      )?.phone_number;
      
      return currentlyLinkedNumber
        ? `Linked ${currentlyLinkedNumber} to SMS Pathway`
        : 'Set Inbound Number to SMS Pathway';
    } 
    
    // For regular pathways
    return currentInboundNumber
      ? `Linked ${currentInboundNumber} to Staging Pathway`
      : 'Link Inbound Number to Staging Pathway';
  }

  // Handle linking the number
  const handleChangeNumber = async (phoneNumber) => {
    try {
      setLoadingChangeNumber(true)
      
      // Determine which endpoint to use based on whether it's an SMS pathway
      const endpoint = isSmsPathway 
        ? `${config.API_URL}/v1/sms/number/update` 
        : `${config.API_URL}/v1/inbound/${phoneNumber}`
      
      // Prepare the request body based on the type
      const requestBody = isSmsPathway
        ? {
            phone_number: phoneNumber,
            pathway_id: pathwayId,
            pathway_version: versionNumber,
          }
        : {
            prompt: '',
            pathway_id: pathwayId,
            pathway_version: versionNumber,
          }

      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': getApiKey(),
          ...(orgId && { 'x-bland-org-id': orgId }),
        },
        body: JSON.stringify(requestBody),
      })

      if (response.ok) {
        toast.success(
          isSmsPathway
            ? 'Inbound number linked to SMS pathway successfully'
            : 'Inbound number linked to staging pathway successfully'
        )
        if (onNumberChanged) {
          onNumberChanged(phoneNumber)
        }
        setConfirmChangeNumberOpen({ open: false, number: null })
      } else {
        toast.error(
          isSmsPathway
            ? 'Failed to link inbound number to SMS pathway'
            : 'Failed to link inbound number to staging pathway'
        )
      }
    } catch (error) {
      console.error('Error linking inbound number:', error)
      toast.error('Failed to link inbound number')
    } finally {
      setLoadingChangeNumber(false)
    }
  }

  useEffect(() => {
    if (
      !confirmChangeComponent && 
      confirmChangeNumberOpen.open && 
      confirmChangeNumberOpen.number
    ) {
      handleChangeNumber(confirmChangeNumberOpen.number);
    }
  }, [confirmChangeNumberOpen.open, confirmChangeNumberOpen.number]);

  return (
    <Dropdown>
      <DropdownTrigger>
        <Button
        color="default"
          variant="bordered"
          radius='sm'
          className='h-8'        >
          {isSmsPathway ? <MessageSquare size={12} color="#000000" /> : <Phone size={12} color="#000000" />}
          <span
            style={{
              fontSize: 12,
              fontWeight: '500',
              marginLeft: 2.5,
              marginRight: 5,
            }}
          >
            {getButtonText()}
          </span>
          <ChevronDown size={12} color="#000000" />
        </Button>
      </DropdownTrigger>
      <DropdownMenu
        aria-label="Inbound Numbers"
        style={{
          width: "300px",
          maxHeight: "200px",
          overflowY: "auto",
          borderRadius: '0'
        }}
      >
        {inboundNumbers?.map((number, index) => {
          // Check if this number is currently linked to the pathway
          const isCurrentlySet = isSmsPathway 
            ? number?.sms_config?.pathway_id === pathwayId
            : currentInboundNumber === number?.phone_number;

          return (
            <DropdownItem
              key={index}
              textValue={number?.phone_number}
              onPress={() => {
                setConfirmChangeNumberOpen({
                  open: true,
                  number: number.phone_number,
                })
              }}
              style={{
                borderBottom:
                  inboundNumbers.length - 1 === index
                    ? 'none'
                    : '1px solid #e0e0e0',
                padding: '4px 8px',
              }}
            >
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div>
                  <p
                    style={{
                      fontSize: 10,
                      fontWeight: '550',
                      color: 'GrayText',
                      margin: '2px 0',
                    }}
                  >
                    {number?.name?.toUpperCase() || 'NO NAME'}
                  </p>
                  <p
                    style={{
                      fontSize: 12,
                      color: '#000',
                      fontWeight: 500,
                      margin: '2px 0',
                    }}
                  >
                    {number?.phone_number}
                  </p>

                  <div className='flex flex-row gap-1 my-1'>
                    {number?.prompt && number?.prompt !== '' && !isSmsPathway && (
                      <Chip size="sm" radius='none' variant='bordered' color="default" style={{ padding: '0 4px', height: '16px' }}>
                        <span style={{ fontSize: 10 }}>
                          Has Prompt Set
                        </span>
                      </Chip>
                    )}

                    {isSmsPathway && number?.sms_config !== null && (
                      <Chip color="default" size="sm" variant='bordered' radius='sm' style={{ padding: '0 4px', height: '16px' }}>
                        <span style={{ fontSize: 10 }}>
                          Has SMS Config
                        </span>
                      </Chip>
                    )}

                    {isCurrentlySet && (
                      <Chip color="success" size="sm" radius='sm' variant='flat' style={{ padding: '0 4px', height: '16px' }}>
                        <span style={{ fontSize: 10 }}>
                          Currently Set
                        </span>
                      </Chip>
                    )}

                    {!isSmsPathway && number?.pathway_id !== null
                      && number?.pathway_id !== ''
                      && number?.pathway_id !== pathwayId && (
                      <Chip color="warning" size="sm" style={{ padding: '0 4px', height: '16px' }}>
                        <CircleAlert size={10} />
                        <span style={{ fontSize: 10 }}>
                          Has Pathway Set
                        </span>
                      </Chip>
                    )}
                  </div>
                </div>
                {isCurrentlySet && <Check size={16} color="#000" />}
              </div>
              
              {/* Render custom confirmation component if provided */}
              {confirmChangeComponent && confirmChangeNumberOpen.open && 
              confirmChangeNumberOpen.number === number.phone_number && 
              confirmChangeComponent({
                loading: loadingChangeNumber,
                open: true,
                onClose: () => setConfirmChangeNumberOpen({ open: false, number: null }),
                handleChangeNumber: () => handleChangeNumber(number.phone_number)
              })}
            </DropdownItem>
          );
        })}
      </DropdownMenu>
    </Dropdown>
  )
} 