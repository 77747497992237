import Tooltip from '@mui/material/Tooltip'
import { TooltipText } from '_components/Text'
import Switch from 'components/core/Switch'

export default function AnsweredBy({ fieldName }) {
  const longText = (
    <span>
      If this is set to
      {' '}
      <strong>true</strong>
      , we process the audio from the
      start of the call to determine if it was answered by a human or a
      voicemail.
      {' '}
      <br />
      <br />
      In the call details or webhook response, you’ll see the
      {' '}
      <strong>answered_by</strong>
      {' '}
      field with the value
      <strong>human</strong>
      ,
      {' '}
      <strong>unknown</strong>
      {' '}
      or
      <strong>voicemail</strong>
      .
    </span>
  )
  return (
    <Tooltip>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <TooltipText tooltip={longText} tooltipClassName="w-[300px]">
          Answered By
        </TooltipText>
        <Switch sx={{ m: 5 }} fieldName={fieldName} />
      </div>
    </Tooltip>
  )
}
