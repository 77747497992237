import { useMutation } from '@tanstack/react-query'
import Button from 'components/core/Button'
import { Loading } from 'components/core/Loading'
import Textarea from 'components/core/Textarea'
import FileUploader from 'components/Dashboard/ConvoPathways/Components/uploadJson'
import { Mp3ToPathway } from 'components/Dashboard/ConvoPathways/Mp3ToPathway'
import { CurlyBraces } from 'lucide-react'
import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { FaClipboardList, FaFileAlt, FaFileAudio, FaPencilAlt } from 'react-icons/fa'
import { toast } from 'react-toastify'
import styled, { keyframes } from 'styled-components'
import { $fetch } from 'utils/fetch'

export default function GeneratePathway() {
  const [selectedOption, setSelectedOption] = useState(null)
  const methods = useForm({
    defaultValues: {
      prompt: '',
    },
  })
  const [loading, setLoading] = useState(false)
  const [showForm, setShowForm] = useState(false)
  const [showLoadingScreen, setShowLoadingScreen] = useState(false)
  const [progress, setProgress] = useState(0)
  const [currentStatus, setCurrentStatus] = useState(0)
  const [jsonContent, setJsonContent] = useState(null)

  const samplePrompts = {
    'Interview Flow': `Create an interview flow, where you first ask the user for their name, and their date of birth. Once done, create 4-5 nodes asking various interview questions about the user, and then check if the user has anymore questions. If no, end the call.`,
    'Customer Support': `Create a customer support flow for a tech company. Start by greeting the customer and asking for their issue. Include paths for common problems like login issues, billing questions, and technical difficulties. Add nodes for escalation to a supervisor if needed, and end with a satisfaction check.`,
    'Sales Call': `Design a sales call pathway for a software product. Begin with introduction and qualification questions. Include nodes for product demonstration, pricing discussion, handling objections, and closing techniques. Add branches for both interested and uninterested prospects, with appropriate follow-up actions.`,
  }

  const handleSamplePrompt = (promptText) => {
    methods.setValue('prompt', promptText)
  }

  const createPathwayMutation = useMutation({
    mutationFn: async (data) => {
      if (selectedOption === 'json') {
        return await $fetch('v1/convo_pathway/create', {
          method: 'POST',
          body: {
            name: 'New Pathway',
            description: 'A new pathway created from JSON',
            nodes: jsonContent?.nodes,
            edges: jsonContent?.edges,
          },
        })
      }
      else if (selectedOption === 'scratch') {
        return await $fetch('v1/convo_pathway/create', {
          method: 'POST',
          body: {
            name: 'New Pathway',
            description: 'From scratch',
          },
        })
      }
      else {
        const { data: submissionData } = await $fetch('v1/pathway/generate', {
          method: 'POST',
          body: {
            input_prompt: data.prompt,
            input_name: 'New Pathway',
          },
        })

        const { jobId } = submissionData
        if (!jobId) {
          throw new Error('No job ID returned from server')
        }

        return await pollStatus(jobId)
      }
    },
    onSuccess: (result) => {
      if (progress < 99) {
        setProgress(99)
      }

      const pathway_id = result?.data?.pathway_id ?? result?.pathway_id
      if (pathway_id) {
        toast.success('Pathway generated successfully!')
        setTimeout(() => {
          setProgress(100)
          const redirectUrl = `dashboard/convo-pathways?id=${pathway_id}&format=true`
          setTimeout(() => {
            window.location.href = redirectUrl
          }, 500)
        }, 150)
      }
    },
    onError: (error) => {
      if (error?.data?.errors) {
        toast.error(error.data.errors[0]?.error)
      }
      else {
        const errorMessage = error.message || 'An error occurred while generating the pathway'
        toast.error(errorMessage)
      }
      setLoading(false)
      setShowLoadingScreen(false)
    },
  })

  const handleOptionClick = async (option) => {
    setSelectedOption(option)
    if (option === 'scratch') {
      setLoading(true)
      setShowLoadingScreen(true)
      setProgress(10)
      createPathwayMutation.mutate({ prompt: '' })
    }
    else {
      setTimeout(() => {
        setShowForm(true)
      }, 500)
    }
  }

  const [progressIndex, setProgressIndex] = useState(0)

  const generateProgressArray = () => {
    let currentProgress = 0

    const initial = Array.from({ length: 30 }).fill().map((_, i) => {
      currentProgress = Math.max(currentProgress, Math.min(15, i * 0.5))
      return currentProgress
    })

    const middle = Array.from({ length: 50 }).fill().map((_, i) => {
      const randomJump = Math.random() > 0.8 ? Math.random() * 5 : 0
      currentProgress = Math.max(currentProgress, Math.min(70, 15 + (i * 1.1) + randomJump))
      return currentProgress
    })

    const final = Array.from({ length: 40 }).fill().map((_, i) => {
      const variableRate = 0.5 + (Math.random() * 0.8)
      currentProgress = Math.max(currentProgress, Math.min(99, 70 + (i * variableRate)))
      return currentProgress
    })

    return [...initial, ...middle, ...final]
  }

  const [progressArray] = useState(generateProgressArray())

  const incrementProgress = () => {
    setProgressIndex((prevIndex) => {
      const newIndex = prevIndex + 1
      if (newIndex < progressArray.length) {
        setProgress(prevValue => Math.max(prevValue, progressArray[newIndex]))
        return newIndex
      }
      return prevIndex
    })
  }

  useEffect(() => {
    let interval
    if (showLoadingScreen) {
      if (progress < 80) {
        setProgress(0)
        setProgressIndex(0)
      }

      const updateProgress = () => {
        incrementProgress()
        const randomDelay = 600 + Math.random() * 600
        interval = setTimeout(updateProgress, randomDelay)
      }

      interval = setTimeout(updateProgress, 800)
    }
    return () => clearTimeout(interval)
  }, [showLoadingScreen])

  async function pollStatus(jobId) {
    const maxAttempts = 60
    const pollInterval = 5000

    for (let attempt = 0; attempt < maxAttempts; attempt++) {
      try {
        const response = await $fetch(`v1/pathway/generate/status/${jobId}`)

        if (response.data.ready) {
          if (response.data.error) {
            throw new Error(response.data.error)
          }
          return response.data
        }

        await new Promise(resolve => setTimeout(resolve, pollInterval))
      }
      catch (err) {
        console.error('Status check failed:', err)
        if (err.message === 'Failed to parse .bland file') {
          throw new Error('Failed to generate valid pathway. Please try again or use a different prompt.')
        }
        throw err
      }
    }
    throw new Error('Processing timed out. Please try again.')
  }

  const onSubmit = async (data) => {
    setLoading(true)
    setShowLoadingScreen(true)
    setProgress(10)

    try {
      createPathwayMutation.mutate(data)
    }
    catch (error) {
      setLoading(false)
      setShowLoadingScreen(false)
    }
  }

  const loadingStatuses = [
    'Analyzing your requirements...',
    'Designing conversation flow...',
    'Optimizing user interactions...',
    'Generating natural language patterns...',
    'Finalizing pathway structure...',
  ]

  useEffect(() => {
    if (showLoadingScreen && progress > 0) {
      const statusCount = loadingStatuses.length
      const progressPerStatus = 100 / statusCount
      const currentStatusIndex = Math.min(
        Math.floor(progress / progressPerStatus),
        statusCount - 1,
      )
      setCurrentStatus(currentStatusIndex)
    }
  }, [progress, showLoadingScreen])

  return (
    <GradientBackground className={showLoadingScreen ? 'show-loading' : ''}>
      <PageWrapper className={showLoadingScreen ? 'fade-out' : 'fade-in'}>
        {!showForm
          ? (
              <>
                <HeaderText>
                  How do you want to start building your pathway?
                </HeaderText>
                <OptionsContainer>
                  <OptionCard onClick={() => handleOptionClick('use-case')}>
                    <FaClipboardList size={48} className="icon" />
                    <OptionText>Use Case</OptionText>
                  </OptionCard>
                  <OptionCard onClick={() => handleOptionClick('script')}>
                    <FaFileAlt size={48} className="icon" />
                    <OptionText>Script</OptionText>
                  </OptionCard>
                  <OptionCard onClick={() => handleOptionClick('scratch')}>
                    <FaPencilAlt size={48} className="icon" />
                    <OptionText>From Scratch</OptionText>
                  </OptionCard>
                  <OptionCard onClick={() => handleOptionClick('mp3')}>
                    <FaFileAudio size={48} className="icon" />
                    <OptionText>From Audio</OptionText>
                  </OptionCard>

                  <OptionCard onClick={() => handleOptionClick('json')}>
                    <CurlyBraces size={48} className="icon" />
                    <OptionText>From JSON</OptionText>
                  </OptionCard>

                </OptionsContainer>
              </>
            )
          : selectedOption === 'mp3'
            ? (
                <div className="w-[500px]">
                  <Mp3ToPathway />
                </div>
              )
            : (
                <FormProvider {...methods}>
                  <FormWrapper onSubmit={methods.handleSubmit(onSubmit)}>
                    {(selectedOption === 'use-case' || selectedOption === 'script') && (
                      <SamplePromptsContainer>
                        <SamplePromptHeader>Sample Templates:</SamplePromptHeader>
                        <SampleButtonsWrapper>
                          {Object.entries(samplePrompts).map(([title, prompt]) => (
                            <SampleButton
                              key={title}
                              type="button"
                              onClick={() => handleSamplePrompt(prompt)}
                            >
                              {title}
                            </SampleButton>
                          ))}
                        </SampleButtonsWrapper>
                      </SamplePromptsContainer>
                    )}
                    {selectedOption === 'use-case' && (
                      <StyledTextarea
                        label="Describe your use case"
                        autoResize
                        fieldName="prompt"
                        rows={15}
                        placeholder="Enter a description of the conversation you want to create, including key actions and responses. Describe specific scenarios, such as handling a reservation call, customer support interaction, or any specific situation where the AI needs to guide the user through a series of steps."
                      />
                    )}
                    {selectedOption === 'script' && (
                      <StyledTextarea
                        label="Paste your script"
                        autoResize
                        fieldName="prompt"
                        rows={15}
                        placeholder="Paste your script here. Ensure that the script includes detailed dialogues, actions, and responses. Clearly outline the flow of the conversation and any specific instructions or key points that the AI needs to follow."
                      />
                    )}
                    {selectedOption === 'scratch' && (
                      <StyledTextarea
                        label="Start from scratch"
                        autoResize
                        fieldName="prompt"
                        rows={15}
                        placeholder="Begin crafting your pathway from scratch. You can start by outlining the main steps or objectives of your conversation, then gradually add more details, potential user responses, and branching paths."
                      />
                    )}
                    {selectedOption === 'json' && (
                      <div className="w-[500px]">
                        <h3 className="mb-6" style={{ color: '#000' }}>
                          Upload Conversational Pathway JSON
                        </h3>
                        <FileUploader
                          onDataLoaded={() => toast.success('File uploaded...')}
                          jsonContent={jsonContent}
                          setJsonContent={setJsonContent}
                        />
                      </div>
                    )}

                    {selectedOption !== 'json'
                      ? (
                          <StyledButton loading={loading} submit>
                            ✨Generate✨
                          </StyledButton>
                        )
                      : (
                          <Button
                            className="mt-6"
                            loading={loading}
                            onClick={() => methods.handleSubmit(onSubmit)()}
                            type="button"
                          >
                            Create pathway
                          </Button>
                        )}
                  </FormWrapper>
                </FormProvider>
              )}
      </PageWrapper>
      {showLoadingScreen && (
        <LoadingScreen>
          <LoadingText>Creating Your Pathway</LoadingText>
          <LoadingSubText>This process typically takes about a minute.</LoadingSubText>
          <LoadingIcon>
            <Loading />
          </LoadingIcon>
          <ProgressBar>
            <Progress style={{ width: `${progress}%` }} />
          </ProgressBar>
          <LoadingText>
            {Math.round(progress)}
            %
          </LoadingText>
          <StatusContainer>
            {loadingStatuses.map((status, index) => (
              <StatusText key={index} style={{ opacity: index === currentStatus ? 1 : 0.5 }}>
                {index === currentStatus ? '→ ' : '✓ '}
                {status}
              </StatusText>
            ))}
          </StatusContainer>
        </LoadingScreen>
      )}
    </GradientBackground>
  )
}

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`

const gradientAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`

const GradientBackground = styled.div`
  width: 100vw;
  height: 100vh;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1.25rem;

  &.show-loading {
    background: linear-gradient(
      145deg,
      #ffafbd,
      #ffc3a0,
      #ff9a9e,
      #fad0c4,
      #fbc2eb
    );
    background-size: 200% 200%;
    animation: ${gradientAnimation} 4s ease infinite;
  }
`

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  transition: opacity 0.5s ease-in-out;

  &.fade-out {
    animation: ${fadeOut} 0.5s forwards;
  }

  &.fade-in {
    animation: ${fadeIn} 0.5s forwards;
  }
`

const FormWrapper = styled.form`
  animation: ${fadeIn} 0.5s ease-in-out;
  width: 100%; /* Make the form take full width */
  max-width: 800px; /* Limit the form's width */
`

const HeaderText = styled.h1`
  color: #4c51bf;
  font-size: 1.56rem;
  margin-bottom: 20px;
`

const OptionsContainer = styled.div`
  display: flex;
  gap: 20px;
  animation: ${fadeIn} 0.5s ease-in-out;
`

const OptionCard = styled.div`
  background: white;
  border: 1px solid #ddd;
  padding: 40px;
  border-radius: 8px;
  width: 150px;
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  cursor: pointer;
  transition:
    transform 0.3s ease,
    box-shadow 0.3s ease;
  position: relative;
  overflow: hidden;

  .icon {
    color: #4c51bf;
    transition: color 0.3s ease;
  }

  &:hover {
    background: linear-gradient(
      145deg,
      #ffafbd,
      #ffc3a0,
      #ff9a9e,
      #fad0c4,
      #fbc2eb
    );
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    transform: scale(1.05);

    .icon {
      color: white;
    }

    h3 {
      color: white;
    }
  }
`

const OptionText = styled.h3`
  color: #4c51bf;
  font-size: 0.94rem;
  margin-top: 10px;
  line-height: 1.2;
`

const StyledTextarea = styled(Textarea)`
  font-size: 0.94rem;
  width: 100%;
  max-width: 800px;
  height: 400px;
`

const StyledButton = styled(Button)`
  background: white;
  border: 1px solid #ddd;
  display: flex;
  margin-top: 10px;
  width: 600px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  text-align: center;
  cursor: pointer;
  transition:
    transform 0.3s ease,
    box-shadow 0.3s ease;
  position: relative;
  overflow: hidden;
  color: black;

  &:hover {
    background: linear-gradient(
      145deg,
      #ffafbd,
      #ffc3a0,
      #ff9a9e,
      #fad0c4,
      #fbc2eb
    );
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    transform: scale(1.05);
    color: white;
  }
`

const LoadingScreen = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: white;
  z-index: 100;
  padding: 20px;
`

const LoadingText = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 10px;
  text-align: center;
`

const LoadingSubText = styled.p`
  font-size: 1rem;
  margin-bottom: 30px;
  text-align: center;
  opacity: 0.9;
`

const LoadingIcon = styled.div`
  margin-bottom: 20px;
`

const ProgressBar = styled.div`
  width: 80%;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  overflow: hidden;
  margin-top: 20px;
`

const glowing = keyframes`
  0% {
    box-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
  }
  50% {
    box-shadow: 0 0 20px rgba(255, 255, 255, 0.8);
  }
  100% {
    box-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
  }
`

const Progress = styled.div`
  height: 10px;
  background: white;
  border-radius: 5px;
  transition: width 0.5s ease-in-out;
  animation: ${glowing} 2s infinite;
  box-shadow: 0 0 10px white;
`

const SamplePromptsContainer = styled.div`
  margin-bottom: 20px;
  width: 100%;
  max-width: 800px;
  background: rgba(255, 255, 255, 0.9);
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
`

const SamplePromptHeader = styled.h3`
  color: #4c51bf;
  margin-bottom: 10px;
  font-size: 0.75rem;
  font-weight: 500;
  text-align: left;
`

const SampleButtonsWrapper = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
`

const SampleButton = styled.button`
  flex: 1;
  padding: 12px 16px;
  background: white;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 0.59rem;
  color: #4c51bf;
  font-weight: 500;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 1.2;
  white-space: nowrap;

  &:hover {
    background: linear-gradient(145deg, #ffafbd, #ffc3a0, #ff9a9e, #fad0c4, #fbc2eb);
    color: white;
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }

  &:active {
    transform: translateY(0);
  }
`

const StatusContainer = styled.div`
  margin: 20px 0;
  padding: 15px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  width: 100%;
  max-width: 500px;
`

const StatusText = styled.p`
  font-size: 0.9rem;
  margin: 8px 0;
  opacity: 0.8;
  text-align: left;
`
