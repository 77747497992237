import { Tab } from '@headlessui/react'
import { useContext, useState } from 'react'
import FlowContext from '../contextFlow'

function PressButtonModal({ id, data, setIsOpen }) {
  const { triggerUpdate, elements } = useContext(FlowContext)
  const [name, setName] = useState(data.name || '')
  const [selectedTab, setSelectedTab] = useState(
    data.digitPrompt ? 2 : data.sequence ? 1 : 0,
  )
  const [digit, setDigit] = useState(data.digit || null)
  const [sequence, setSequence] = useState(data.sequence || '')
  const [digitPrompt, setDigitPrompt] = useState(data.digitPrompt || '')

  const numpadOptions = [
    ...Array.from({ length: 10 }).keys(),
    { key: '* (star)', value: '*' },
    { key: '# (pound)', value: '#' },
  ]

  const handleSave = () => {
    const updatedNodes = elements.nodes.map((el) => {
      if (el.id === id) {
        const updatedData = {
          name,
          ...(selectedTab === 0 && digit ? { digit } : {}),
          ...(selectedTab === 1 && sequence ? { sequence } : {}),
          ...(selectedTab === 2 && digitPrompt ? { digitPrompt } : {}),
          isStart: data.isStart ?? false,
        }

        return {
          ...el,
          data: updatedData,
          type: 'Press Button',
        }
      }
      return el
    })

    setIsOpen(false)
    triggerUpdate({ nodes: updatedNodes }, false)
  }

  return (
    <div className="space-y-4">
      <div>
        <label className="block font-medium text-gray-700 mb-1.5">Name:</label>
        <input
          type="text"
          value={name}
          onChange={e => setName(e.target.value)}
          className="border border-gray-300 p-1.5 rounded w-full"
        />
      </div>

      <Tab.Group selectedIndex={selectedTab} onChange={setSelectedTab}>
        <Tab.List className="flex space-x-0.5 rounded-lg bg-indigo-100 p-0.5">
          <Tab
            className={({ selected }) =>
              selected
                ? 'bg-indigo-500 text-white rounded-md py-1.5 px-3'
                : 'text-gray-900 rounded-md py-1.5 px-3'}
          >
            Single Digit
          </Tab>
          <Tab
            className={({ selected }) =>
              selected
                ? 'bg-indigo-500 text-white rounded-md py-1.5 px-3'
                : 'text-gray-900 rounded-md py-1.5 px-3'}
          >
            Sequence of Digits
          </Tab>
          <Tab
            className={({ selected }) =>
              selected
                ? 'bg-indigo-500 text-white rounded-md py-1.5 px-3'
                : 'text-gray-900 rounded-md py-1.5 px-3'}
          >
            Agent Decision
          </Tab>
        </Tab.List>
        <Tab.Panels>
          <Tab.Panel className="space-y-1.5">
            <label className="block text-[12px] font-medium text-gray-950">
              Button Digit to Press:
            </label>
            <select
              value={digit}
              onChange={e => setDigit(e.target.value)}
              className="border border-gray-300 text-gray-900 rounded-md focus:ring-teal-500 focus:border-teal-500 block w-full p-1.5"
            >
              <option value="">Select a Digit</option>
              {numpadOptions.map(option => (
                <option
                  key={option.key || option}
                  value={option.value || option}
                >
                  {option.key || option}
                </option>
              ))}
            </select>
            <label className="block text-gray-700 text-xs mt-1.5">
              Specify a single digit to be pressed automatically.
            </label>
          </Tab.Panel>
          <Tab.Panel className="space-y-1.5">
            <label className="block text-[12px] font-medium text-gray-950">
              Sequence of Digits:
            </label>
            <input
              type="text"
              value={sequence}
              onChange={e => setSequence(e.target.value)}
              className="border border-gray-300 p-1.5 rounded w-full"
            />
            <label className="block text-gray-700 text-xs mt-1.5">
              Specify a sequence of digits to be pressed in order.
            </label>
          </Tab.Panel>
          <Tab.Panel className="space-y-1.5">
            <label className="block text-[12px] font-medium text-gray-950">
              Agent Decision:
            </label>
            <textarea
              value={digitPrompt}
              onChange={e => setDigitPrompt(e.target.value)}
              className="border border-gray-300 p-1.5 rounded w-full"
              rows="4"
            >
            </textarea>
            <label className="block text-gray-700 text-xs mt-1.5">
              Provide a prompt for the agent to decide the action based on the
              current conversation context.
            </label>
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>

      <button
        onClick={handleSave}
        className="bg-indigo-500 text-white py-1.5 px-2.5 rounded mt-2.5"
      >
        Save
      </button>
    </div>
  )
}

export default PressButtonModal
