import { $fetch } from 'utils/fetch'
import { queryClient } from 'utils/queryClient'
import { prefetchRecording } from './handleRecording'

// based on https://tanstack.com/query/latest/docs/framework/react/guides/prefetching#dependent-queries--code-splitting

export async function fetchCallLogs({ pageParam, queryKey }) {
  const [, take, filters, sortBy, sortDir] = queryKey
  const { data } = await $fetch('/user/logs', {
    query: {
      page: pageParam,
      take,
      sortBy,
      sortDir,
      filters,
    },
  })

  for (const call of data.calls) {
    if (call.recording_url) {
      prefetchRecording(call.c_id)
    }

    if (call?.transferred_call) {
      queryClient.setQueryData([
        'callDetails',
        call.transferred_call.c_id,
      ], call.transferred_call)
    }

    queryClient.setQueryData(['callDetails', call.c_id], call)
  }

  return data
}

export function getPreviousPageParam(firstPage, allPages, firstPageParam) {
  if (firstPageParam <= 1) {
    return undefined
  }
  return firstPageParam - 1
}

export function getNextPageParam(lastPage, allPages, lastPageParam) {
  if (lastPageParam === allPages[0].pagination.totalPages) {
    return undefined
  }
  return lastPageParam + 1
}

export const initialPageParam = 1

export async function prefetchCallLogs({ take, pages }) {
  await queryClient.prefetchInfiniteQuery({
    queryKey: ['callLogs', take, [], 'created_at', 'desc'],
    queryFn: fetchCallLogs,
    getNextPageParam,
    getPreviousPageParam,
    initialPageParam,
    pages,
  })
}
