import { createContext, useContext } from 'react'
import { getApiKey } from 'utils/funcs/browser/getApiKey'
import SimulationsApi from '../hooks/simulationsApi'
import { useSimulations } from '../hooks/useSimulation'

const SimulationsContext = createContext({
  isCreationModalOpen: null,
  selectedCallId: null,
  simulations: [],
  recentRuns: [],
  loading: false,
  error: null,
  runningCalls: new Set(),
  openCreationModal: () => {},
  closeModal: () => {},
  runSimulation: async () => '',
  createSimulation: async () => {},
  fetchData: async () => {},
  handleAddToSimulation: (param) => {},
  getStatus: (param) => {},
  currentReplaySimulationCallID: null,
  setCurrentReplaySimulationCallID: () => {},
  updateSimulation: async () => {},
  deleteSimulation: async () => {},
})

export function SimulationsProvider({ children }) {
  const simulationsApi = new SimulationsApi(getApiKey())
  const simulationState = useSimulations(simulationsApi)

  return (
    <SimulationsContext.Provider value={simulationState}>
      {children}
    </SimulationsContext.Provider>
  )
}

export function useSimulationsContext() {
  const context = useContext(SimulationsContext)
  if (context === undefined) {
    throw new Error(
      'useSimulationsContext must be used within a SimulationsProvider',
    )
  }
  return context
}
