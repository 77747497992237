import ActionBar from 'components/core/ActionBar'
import { PageTitle } from 'components/core/PageTitle'
import styled from 'styled-components'
import { Divider } from '../../core/Divider'
import { PageWrapper } from '../../core/PageWrapper'
import AutoRecharge from './AutoRecharge'
import BillingAndPayments from './PaymentMethod'

export default function Billing() {
  return (
    <PageWrapper>
      <Content>
        <ActionBar top>
          <PageTitle>Billing Details</PageTitle>
        </ActionBar>
        <Section>
          <BillingAndPayments />
        </Section>
        <Divider />
        <Section>
          <AutoRecharge />
        </Section>
      </Content>
    </PageWrapper>
  )
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`
