import { format } from 'date-fns'
import { useAuth } from 'hooks/useAuth'
import { Copy, Edit, Eye, User } from 'lucide-react'
import { useEffect, useState } from 'react'
import { AiFillStar, AiOutlineStar } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { $fetch } from 'utils/fetch'
import { getAuthToken } from 'utils/funcs/browser/getAuthToken'

const tagColors = {
  'Customer Service': 'bg-indigo-100 text-indigo-800 border-indigo-300',
  'AI': 'bg-blue-100 text-blue-800 border-blue-300',
  'Support': 'bg-purple-100 text-purple-800 border-purple-300',
  'Sales': 'bg-yellow-100 text-yellow-800 border-yellow-300',
  'Booking': 'bg-pink-100 text-pink-800 border-pink-300',
  'FAQ': 'bg-indigo-200 text-indigo-900 border-indigo-400',
  'Knowledge Base': 'bg-gray-100 text-gray-800 border-gray-300',
  'Lead Qualification': 'bg-cyan-100 text-cyan-800 border-cyan-300',
}

function PathwayCard({
  id,
  title,
  description,
  author,
  stars,
  tags,
  clones,
  onPreview,
  starred,
  isOwner,
  onEdit,
  created_at,
}) {
  const [totalStars, setTotalStars] = useState(stars)
  const [totalClones, setTotalClones] = useState(clones)
  const [star, setStar] = useState(false)
  const navigate = useNavigate()
  const { user } = useAuth()

  const formattedDate = format(new Date(created_at), 'PPP')
  useEffect(() => {
    setStar(starred)
  }, [starred])

  const onClone = async (id) => {
    if (!user) {
      toast.warn(
        'Please login to clone the pathway. Redirecting to login page...',
        {
          autoClose: 3000,
        },
      )
      setTimeout(() => {
        navigate('/login')
      }, 3000)
    }
    else {
      try {
        const response = await $fetch(`/showcase/pathway/clone`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'authorization': getAuthToken(),
          },
          body: JSON.stringify({ showcase_id: id }),
          ignoreResponseError: true,
        })

        if (!response.errors) {
          toast.success(`Pathway cloned successfully`)
          setTotalClones(totalClones + 1)
        }
        else {
          const errorData = response.data
          toast.error(`Failed to clone the pathway: ${errorData.message}`)
        }
      }
      catch (error) {
        console.error('Error cloning pathway:', error)
        toast.error('An error occurred while cloning the pathway')
      }
    }
  }

  const onStar = async (id) => {
    if (!user) {
      toast.warn(
        'Please login to star the pathway. Redirecting to login page...',
        {
          autoClose: 3000,
        },
      )
      setTimeout(() => {
        navigate('/login')
      }, 3000)
    }
    else {
      try {
        const response = await $fetch(`/showcase/pathway/star`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ showcase_id: id, star: !star }),
          ignoreResponseError: true,
        })

        if (!response.errors) {
          toast.success(
            `Pathway ${!star ? 'starred' : 'unstarred'} successfully`,
          )
          setStar(!star)
          setTotalStars(!star ? totalStars + 1 : totalStars - 1)
        }
        else {
          const errorData = response.data
          toast.error(
            `Failed to star/unstar the pathway: ${errorData.message}`,
          )
        }
      }
      catch (error) {
        console.error('Error starring pathway:', error)
        toast.error('An error occurred while starring the pathway')
      }
    }
  }

  return (
    <div
      onClick={onPreview}
      className="border-l-2 border-t-2 border-b-4 rounded-sm p-4 shadow-sm hover:shadow-md hover:border-r-[3.5px] hover:border-b-[3.5px] transition transition-all duration-500 cursor-pointer transition-shadow flex flex-col h-full relative border-purple-900 border-r-4"
    >
      <div className="flex justify-between items-center mb-2">
        <h3 className="font-bold text-[20px]">{title}</h3>
        <div className="flex items-center space-x-2.5">
          <button
            onClick={() => onStar(id)}
            className="flex items-center space-x-0.5 text-yellow-500 hover:text-yellow-600"
          >
            {star
              ? (
                  <AiFillStar className="text-yellow-500" />
                )
              : (
                  <AiOutlineStar />
                )}
            <span className="text-[12px]">{totalStars}</span>
          </button>
          <div className="flex items-center space-x-0.5 text-gray-600 hover:text-gray-800">
            <Copy size={16} />
            <span className="text-[12px]">{totalClones}</span>
          </div>
        </div>
      </div>
      <div className="text-[12px] text-gray-500 mb-2">{formattedDate}</div>
      <p className="text-[12px] text-gray-600 mb-2.5 flex-grow line-clamp-3">
        {description}
      </p>
      <div className="flex flex-wrap mb-2.5">
        {tags.slice(0, 3).map((tag, index) => (
          <span
            key={index}
            className={`text-[10px] px-1.5 py-0.5 rounded-full border mr-1.5 mb-1.5 ${tagColors[tag] || 'bg-gray-200 text-gray-700 border-gray-300'}`}
          >
            {tag}
          </span>
        ))}
      </div>
      <div className="flex items-center justify-between mt-auto">
        <div className="flex items-center space-x-1.5">
          <User size={16} className="text-gray-400" />
          <span className="text-[12px] text-gray-600">{author}</span>
        </div>
        <div className="flex space-x-1.5">
          <button
            onClick={onPreview}
            className="flex items-center space-x-0.5 text-indigo-500 hover:text-indigo-600 border border-indigo-500 px-2 py-1.5 rounded hover:bg-indigo-500 hover:text-white duration-300"
          >
            <Eye size={16} />
            <span className="text-[12px]">View Pathway</span>
          </button>
          {isOwner && (
            <button
              onClick={() => onEdit(id)}
              className="flex items-center space-x-0.5 text-green-500 hover:text-green-600 border border-green-500 px-2 py-1.5 rounded-"
            >
              <Edit size={16} />
              <span className="text-[12px]">Edit</span>
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

export default PathwayCard
