import { Button } from '@/components/ui/button'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { Plus } from 'lucide-react'
import { useContext, useEffect, useState } from 'react'
import FlowContext from '../contextFlow'
import AddSimulationForm from './components/addSimulationModal'
import SimulationDetailsHeader from './components/detailsHeader'
import { SimulationsOverview } from './components/overview'
import RecentRunsTable from './components/recentlyRunTable'
import SimulationsTable from './components/simulationsTable'
import { useSimulationsContext } from './context/SimulationContext'

function SimulationsTab({
  pathwayId,
  handleReplayCall,
  isReplayLoading,
  pageSize: initialPageSize = 25,
  searchTerm,
}) {
  const [selectedSimulation, setSelectedSimulation] = useState(null)
  const [activeTab, setActiveTab] = useState('simulations')
  const [isEditMode, setIsEditMode] = useState(false)
  const { callId, selectedVersion } = useContext(FlowContext)

  const {
    isCreationModalOpen,
    closeModal,
    openCreationModal,
    simulations,
    recentRuns,
    loading,
    error,
    updateSimulation,
    runSimulation,
    fetchData,
    createSimulation,
    setCurrentReplaySimulationCallID,
    loadingSimulations,
    deleteSimulation,
  } = useSimulationsContext()

  useEffect(() => {
    fetchData({
      pathwayId,
      pageSize: initialPageSize,
      page: 1,
      search: searchTerm,
    })
  }, [searchTerm, initialPageSize, fetchData, pathwayId])

  const [isAddSimulationLoading, setIsAddSimulationLoading] = useState(false)

  const handleAddSimulation = async (data) => {
    setIsAddSimulationLoading(true)
    try {
      await createSimulation({
        pathwayId,
        name: data.name,
        callId,
        endNode: data.endNode,
        startNodeId: data.startNodeID,
        requestData: data.requestData,
        inputMessages: data.inputMessages,
        pathwayVersion: data.pathwayVersion,
      })
      closeModal()
      setActiveTab('simulations')
    }
    finally {
      setIsAddSimulationLoading(false)
    }
  }

  const handleEditSimulation = async (data) => {
    setIsAddSimulationLoading(true)
    try {
      console.log({
        data,
        inputMessages: data.inputMessages,
      })

      // Assuming you have an updateSimulation function in your context
      await updateSimulation({
        simulationId: selectedSimulation.id,
        updateData: {
          pathway_id: pathwayId,
          name: data.name,
          success_criteria: {
            end_node: data.endNode,
          },
          start_node_id: data.startNodeID,
          request_data: data.requestData,
          input_messages: data.inputMessages,
          pathway_version: data.pathwayVersion,
        },
      })
      setIsEditMode(false)
      setSelectedSimulation(null)
      await fetchData({
        pathwayId,
        pageSize: initialPageSize,
        page: 1,
        search: searchTerm,
      })
    }
    finally {
      setIsAddSimulationLoading(false)
    }
  }

  const handleStartEdit = (simulation) => {
    setSelectedSimulation(simulation)
    setIsEditMode(true)
  }

  if (isEditMode && selectedSimulation) {
    console.log('selectedSimulation', selectedSimulation)
    return (
      <AddSimulationForm
        onSubmit={handleEditSimulation}
        onCancel={() => {
          setIsEditMode(false)
          setSelectedSimulation(null)
          closeModal()
        }}
        isLoading={isAddSimulationLoading}
        initialValues={{
          name: selectedSimulation.name,
          endNode: selectedSimulation.success_criteria.end_node,
          startNodeID: selectedSimulation.start_node_id,
          requestData: selectedSimulation.request_data,
          inputMessages: selectedSimulation.input_messages.join('\n'),
          pathwayVersion: selectedSimulation.pathway_version,
        }}
        isEditing
      />
    )
  }

  if (isCreationModalOpen) {
    return (
      <AddSimulationForm
        onSubmit={handleAddSimulation}
        onCancel={closeModal}
        isLoading={isAddSimulationLoading}
      />
    )
  }

  if (selectedSimulation && !isEditMode) {
    return (
      <div className="flex flex-col h-full">
        <SimulationDetailsHeader
          simulation={selectedSimulation}
          onBack={() => setSelectedSimulation(null)}
          onEdit={handleStartEdit}
        />
      </div>
    )
  }

  const handleRunSimulation = async (id) => {
    const callId = await runSimulation(
      id,
      pathwayId,
      selectedVersion?.version_number ?? 'staging',
    )
    if (callId) {
      setActiveTab('recent')
    }
  }

  return (
    <div className="flex flex-col h-full gap-2.5 p-2.5">
      <div className="flex justify-between items-center">
        <SimulationsOverview />
      </div>
      <div className="h-full">
        <Tabs
          value={activeTab}
          onValueChange={setActiveTab}
          className="flex-1 h-full"
        >
          <TabsList className="gap-5 p-0 h-auto bg-transparent border-b border-gray-200 rounded-none flex justify-between w-full">
            <div className="flex gap-5">
              <TabsTrigger
                value="simulations"
                className="tracking-[0.2rem] px-0.5 py-1.5 font-geist-mono text-2xs font-bold data-[state=active]:border-b-2 data-[state=active]:border-gray-900
                       data-[state=active]:shadow-none data-[state=active]:bg-transparent data-[state=active]:text-gray-900
                       data-[state=active]:font-bold
                       text-gray-500 hover:text-gray-700 border-0 rounded-none"
              >
                TEST CASES
              </TabsTrigger>
              <TabsTrigger
                value="recent"
                className="px-0.5 py-1.5 tracking-[0.2rem] font-geist-mono font-bold text-2xs data-[state=active]:border-b-2 data-[state=active]:border-gray-900
                       data-[state=active]:shadow-none data-[state=active]:bg-transparent data-[state=active]:text-gray-900
                       text-gray-500 hover:text-gray-700 border-0 rounded-none"
              >
                HISTORY
              </TabsTrigger>
            </div>
            <Button
              onClick={openCreationModal}
              className="flex items-center gap-1.5"
              variant="outline"
            >
              <Plus size={16} />
              Add Test Case
            </Button>
          </TabsList>

          <TabsContent value="simulations" className="flex-1 h-full">
            <SimulationsTable
              simulations={simulations}
              loading={loading}
              onRunSimulation={handleRunSimulation}
              onRowClick={setSelectedSimulation}
              loadingSimulations={loadingSimulations}
              onDeleteSimulation={deleteSimulation}
            />
          </TabsContent>

          <TabsContent value="recent" className="flex-1 h-full">
            <RecentRunsTable
              recentRuns={recentRuns}
              loading={loading}
              onReplayCall={(id) => {
                setCurrentReplaySimulationCallID(id)
                handleReplayCall(id)
              }}
              isReplayLoading={isReplayLoading}
              onRefresh={() =>
                fetchData({
                  pathwayId,
                  pageSize: initialPageSize,
                  page: 1,
                  search: searchTerm,
                })}
            />
          </TabsContent>
        </Tabs>
      </div>

      {error && (
        <div className="p-2.5 text-red-600 text-center bg-red-50 rounded-md">
          {error}
        </div>
      )}
    </div>
  )
}

export default SimulationsTab
