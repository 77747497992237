import axios from 'axios'
import config from 'config'
import { getAuthToken } from 'utils/funcs/browser/getAuthToken'
import { getOrgId } from 'utils/funcs/browser/getOrgId'

export async function insertInbound(encrypted_key, numbers) {
  const authToken = getAuthToken()
  const orgId = getOrgId()
  const headers = {
    authorization: authToken,
    encrypted_key,
    ...(orgId && { 'x-bland-org-id': orgId }),
  }
  try {
    const { data } = await axios.post(
      `${config.API_URL}/v1/inbound/insert`,
      { numbers },
      { headers },
    )
    return data
  }
  catch (error) {
    console.log('upload inbound', error)
    return error.message
  }
}
