import { Select, SelectItem } from '@heroui/react'
import { styled } from '@mui/material/styles'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { ParamDescription, ParamTitle } from 'components/core/ParamLabels'
import { useFormContext, useWatch } from 'react-hook-form'

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))

const actions = [
  {
    label: 'Hangup',
    value: 'hangup',
  },
  {
    label: 'Ignore',
    value: 'ignore',
  },
  {
    label: 'Leave Message',
    value: 'leave_message',
  },
  {
    label: 'Leave SMS',
    value: 'leave_sms',
  },
]

export default function VoicemailActionSelect({ fieldName }) {
  const { setValue } = useFormContext()
  const currentAction = useWatch({
    name: fieldName,
    control: useFormContext().control,
  })

  const handleChange = (e) => {
    setValue(fieldName, e.target.value)
  }

  const longText = (
    <span>
      This is processed separately from the AI’s decision making, and overrides
      it. Options:
      <strong>hangup</strong>
      <strong>leave_message</strong>
      ignore Examples: Call is answered by a voicemail (specifically with a beep
      or tone): If voicemail_message is set, that message will be left and then
      the call will end. Otherwise, the call immediately ends (regardless of
      ivr_mode) Call is answered by an IVR system or phone tree: If
      ivr_mode is set to true, the AI will navigate the system and
      continue as normal. If voicemail_action is set to ignore, the AI will
      ignore the IVR and continue as normal. Otherwise, if voicemail_message is
      set then it’ll leave that message and end the call. Finally, if none of
      those conditions are met, the call will end immediately. Note: If
      voicemail_message is set, then the AI will leave the message regardless of
      the voicemail_action.
      the call will end immediately. Note: If voicemail_message is set, then the
      AI will leave the message regardless of the voicemail_action.
    </span>
  )


  return (
    <div style={{ width: '100%' }}>
      <div style={{ marginBottom: 10 }}>
        <ParamTitle>Voicemail Action</ParamTitle>
        <ParamDescription>
          This is processed separately from the AI's decision making, and
          overrides it.
        </ParamDescription>
      </div>

      <Select
        name={fieldName}
        onChange={handleChange}
        selectedKeys={[currentAction]}
        radius="sm"
        variant="bordered"
        aria-label="Voicemail Action"
        size="sm"
      >
        {actions.map(action => (
          <SelectItem
            key={action.value}
            value={action.value}
          >
            {action.label}
          </SelectItem>
        ))}
      </Select>
    </div>
  )
}

{
  /* <option value={"hangup"}>{"Hangup"}</option>
<option value={"ignore"}>{"Ignore"}</option>
<option value={"leave_message"}>{"Leave Message"}</option>
<option value={"leave_sms"}>{"Leave SMS"}</option> */
}
