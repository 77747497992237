import { addToast, Button, ButtonGroup, Input } from '@heroui/react'
import { useMutation } from '@tanstack/react-query'
import { Check, RadioTower, X } from 'lucide-react'
import { useState } from 'react'
import { $fetch } from 'utils/fetch'

export default function WebhookInput({ value, ...props }) {
  const [state, setState] = useState('idle')
  const color = props.isSMS ? 'secondary' : 'primary'

  const testData = {
    call_id: '9ae89a18-729a-4df9-ae54-42754182e32f',
    transcripts: [
      {
        id: 45789,
        created_at: '2023-08-22T02:23:05.256341+00:00',
        text: 'Hey, is this Bobs Donuts.',
        user: 'user',
        c_id: '9ae89a18-729a-4df9-ae54-42754182e32f',
      },
      {
        id: 45790,
        created_at: '2023-08-22T02:23:08.227252+00:00',
        text: 'Hi how can I help you!',
        user: 'assistant',
        c_id: '9ae89a18-729a-4df9-ae54-42754182e32f',
      },
    ],
  }

  const { mutate: handleTest, isLoading } = useMutation({
    mutationFn: () => $fetch('/tools/run', {
      method: 'POST',
      body: {
        url: value,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: testData,
      },
    }),
    onSuccess: () => {
      setState('success')
      addToast({
        title: 'Webhook test sent!',
        color: 'success',
      })
    },
    onError: () => {
      setState('error')
      addToast({
        title: 'Webhook test failed!',
        color: 'danger',
      })
    },
    onSettled: () => {
      setTimeout(() => {
        setState('idle')
      }, 3000)
    },
  })

  return (
    <div className="flex flex-col w-full gap-1">
      <label className="text-small font-medium text-foreground">Webhook URL</label>
      <p className="text-tiny text-foreground-400 mb-2">
        When the call ends, we’ll send the call details in a POST request to the
        URL you specify here.
      </p>
      <ButtonGroup>
        <Input
          placeholder="https://example.com/webhook"
          {...props}
          value={value}
          variant="bordered"
          radius="sm"
          classNames={{ inputWrapper: 'rounded-r-none' }}
        />
        <Button
          className="shrink-0 text-white"
          onPress={handleTest}
          color={state === 'success' ? 'success' : state === 'error' ? 'danger' : color}
          isLoading={isLoading}
          startContent={state === 'success' ? <Check size={16} /> : state === 'error' ? <X size={16} /> : <RadioTower size={16} />}
        >
          {state === 'success' ? 'Success' : state === 'error' ? 'Failed' : 'Test Webhook'}
        </Button>
      </ButtonGroup>
    </div>
  )
}
