import { useState } from 'react'
// import ReactJson from 'react-json-view';
import SyntaxHighlighter from 'react-syntax-highlighter/dist/esm/default-highlight'
import { monokaiSublime } from 'react-syntax-highlighter/dist/esm/styles/hljs'

function JsonViewer({ data }) {
  const [focusedPath, setFocusedPath] = useState(null)

  const handleClick = (path, key, value) => {
    console.log({
      path,
      key,
      value,
    })
    setFocusedPath(path)

    const keyValue = {
      key,
      value,
    }

    const keyValueString = JSON.stringify(keyValue)
    navigator.clipboard
      .writeText(keyValueString)
      .then(() => {
        alert(`Copied to clipboard: ${keyValueString}`)
      })
      .catch((err) => {
        console.error('Could not copy text: ', err)
      })
  }

  const renderHighlightedValue = (value, path) => {
    const isFocused = JSON.stringify(path) === JSON.stringify(focusedPath)
    return (
      <span
        style={{
          backgroundColor: isFocused ? 'yellow' : 'transparent',
          cursor: 'pointer',
        }}
        onClick={() => handleClick(path, null, value)}
      >
        {value}
      </span>
    )
  }

  const renderHighlightedKey = (key, path) => {
    const isFocused = JSON.stringify(path) === JSON.stringify(focusedPath)
    return (
      <span
        style={{
          backgroundColor: isFocused ? 'lightblue' : 'transparent',
          cursor: 'pointer',
        }}
        onClick={() => handleClick(path, key, null)}
      >
        {key}
      </span>
    )
  }

  return (
    <div style={{ overflowY: 'scroll', height: '100%' }}>
      {/* <ReactJson
                src={data}
                theme="monokai:inverted"
                style={{ width: '100%', padding: 10, paddingLeft: 0, background:"#fff", paddingBottom:155}}
                enableClipboard={false}
                displayDataTypes={true}
                onEdit={false}
                onAdd={false}
                onDelete={false}
                displayObjectSize={false}
                collapsed={false}
                name={false}
                // shouldCollapse={({ src, namespace }) => namespace.length === 1}
                iconStyle="square"
                indentWidth={4}
                groupArraysAfterLength={5}
                quotesOnKeys={true}
                sortKeys={true}
                components={{
                    // Custom renderer for keys
                    keyRenderer: (props) => {
                        const { value, namespace } = props;
                        return renderHighlightedKey(value, namespace);
                    },
                    // Custom renderer for values
                    valueRenderer: (props) => {
                        const { value, namespace } = props;
                        return renderHighlightedValue(value, namespace);
                    }
                }}
            /> */}

      {/* <Editor
                height={"100%"}
                width={"100%"}
                value={JSON.stringify({...data} || {}, null, 2)}
                language='json'
                theme="monokai"
            /> */}
      <SyntaxHighlighter
        wrapLongLines
        theme={monokaiSublime}
        language="json"
        customStyle={{ borderRadius: 3, fontSize: 12, padding: 20 }}
      >
        {JSON.stringify({ ...data } || {}, null, 2)}
      </SyntaxHighlighter>
    </div>
  )
}

export default JsonViewer
