import { Card, CardContent } from '@/components/ui/card'
import { Input } from '@/components/ui/input'
import PathwayVersion from 'components/Dashboard/Parameters/PathwayVersion'
import ObjectInput from 'components/Hero/ObjectInput'
import {
  CheckCircle2,
  ChevronDown,
  ChevronUp,
  Maximize,
  Minimize,
  XCircle,
} from 'lucide-react'
import { useContext, useEffect, useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { FaPlay } from 'react-icons/fa'
import { toast } from 'react-toastify'
import PathwayTest from '../../../../../assets/icons/pathway-test.svg'
import NodeSelect from '../../Components/NodeSelect'
import FlowContext from '../../contextFlow'
import MessageList from './MessageList'

function AddSimulationForm({
  onSubmit,
  onCancel,
  isLoading,
  initialValues,
  isEditing = false,
}) {
  const { elements, callLogs, id: pathwayId, selectedVersion } = useContext(FlowContext)
  const [showAdvanced, setShowAdvanced] = useState(false)
  const [selectedNode, setSelectedNode] = useState(null)
  const [useManualInput, setUseManualInput] = useState(false)
  const [useManualStartInput, setUseManualStartInput] = useState(false)
  const [showHowTo, setShowHowTo] = useState(true)

  const initializeDefaultValues = () => {
    if (initialValues) {
      return {
        name: initialValues.name || '',
        endNode: initialValues.endNode || '',
        startNodeID: initialValues.startNodeID || '',
        requestData: initialValues.requestData || '',
        inputMessages: initialValues.inputMessages || '',
        pathwayVersion: initialValues.pathwayVersion || selectedVersion?.version_number || 0,
      }
    }

    if (!callLogs?.length) {
      return {
        name: '',
        endNode: '',
        startNodeID: '',
        requestData: '',
        inputMessages: '',
        pathwayVersion: 0,
      }
    }

    let defaultEndNode = ''
    let defaultStartNode = ''
    let defaultRequestData = ''
    const defaultMessages = []

    // Find end node (last chosen node)
    for (let i = callLogs.length - 1; i >= 0; i--) {
      if (callLogs[i].chosen_node_id) {
        defaultEndNode = callLogs[i].chosen_node_id
        break
      }
    }

    // Find start node (first chosen node)
    for (let i = 0; i < callLogs.length; i++) {
      if (callLogs[i].chosen_node_id) {
        defaultStartNode = callLogs[i].chosen_node_id
        break
      }
    }

    // Get request data
    for (let i = 0; i < callLogs.length; i++) {
      if (callLogs[i].pathway_info) {
        try {
          const pathwayInfo = JSON.parse(callLogs[i].pathway_info)
          if (pathwayInfo['Request Data']) {
            defaultRequestData = JSON.stringify(
              pathwayInfo['Request Data'],
              null,
              2,
            )
          }
        }
        catch (error) {
          console.error('Error parsing request data:', error)
        }
        break
      }
    }

    // Get input messages
    for (let i = 0; i < callLogs.length; i++) {
      if (callLogs[i]?.role === 'user') {
        defaultMessages.push(callLogs[i].text)
      }
    }

    console.warn('defaultRequestData', defaultRequestData)

    let parsedRequestData = []

    // Parse the defaultRequestData if it exists
    if (defaultRequestData) {
      try {
        const requestDataObj = JSON.parse(defaultRequestData)
        // Convert the object to an array of key-value pairs for ObjectInput
        parsedRequestData = Object.entries(requestDataObj).map(([key, value]) => ({
          key,
          value: typeof value === 'object' ? JSON.stringify(value) : String(value),
        }))
      }
      catch (error) {
        console.warn('Error parsing defaultRequestData', error)
      }
    }

    console.warn('parsedRequestData', parsedRequestData)

    return {
      name: '',
      endNode: defaultEndNode,
      startNodeID: defaultStartNode,
      requestData: parsedRequestData.length > 0 ? parsedRequestData : [{ key: '', value: '' }],
      inputMessages: defaultMessages.join('\n'),
      pathwayVersion: selectedVersion?.version_number || 0,
    }
  }

  const methods = useForm({
    defaultValues: initializeDefaultValues(),
  })

  const { watch } = methods
  const watchEndNode = watch('endNode')

  useEffect(() => {
    if (!useManualInput) {
      const node = elements.nodes.find(n => n.id === watchEndNode)
      if (watchEndNode && !node) {
        setUseManualInput(true)
      }
      setSelectedNode(node)
    }
  }, [watchEndNode, elements.nodes, useManualInput])

  // Set advanced section visibility based on initialValues
  useEffect(() => {
    if (
      initialValues
      && (initialValues.startNodeID || initialValues.requestData)
    ) {
      setShowAdvanced(true)
    }
  }, [initialValues])

  const onFormSubmit = (data) => {
    if (!data.name) {
      toast.error('Please enter a unique name for the test case')
      return
    }

    const inputMessagesArray = data.inputMessages.split('\n')

    // Convert the requestData array of key-value pairs back to an object
    const requestDataObj = {}
    if (data.requestData && Array.isArray(data.requestData)) {
      data.requestData.forEach((item) => {
        if (item.key && item.key.trim() !== '') {
          try {
            // Try to parse the value as JSON if it looks like JSON
            if (item.value
              && (item.value.startsWith('{')
                || item.value.startsWith('['))
              && (item.value.endsWith('}')
                || item.value.endsWith(']'))) {
              requestDataObj[item.key] = JSON.parse(item.value)
            }
            else {
              requestDataObj[item.key] = item.value
            }
          }
          catch {
            // If parsing fails, use the raw value (removed unused parameter)
            requestDataObj[item.key] = item.value
          }
        }
      })
    }

    // Format the data for submission
    onSubmit({
      ...data,
      requestData: Object.keys(requestDataObj).length > 0 ? JSON.stringify(requestDataObj) : null,
      inputMessages: inputMessagesArray,
      pathwayVersion: data.pathwayVersion || selectedVersion?.version_number || 0,
    })
  }

  return (
    <FormProvider {...methods}>
      <div className="w-full bg-white flex flex-col h-[calc(100vh-3.75rem)]">
        <div className="bg-gray-100 p-5 rounded-t-lg border border-gray-100">
          <div className="flex justify-end">
            <button onClick={() => setShowHowTo(!showHowTo)}>
              {showHowTo ? <Minimize size={20} /> : <Maximize size={20} />}
            </button>
          </div>
          <div className="flex justify-center items-center mb-1.5">
            <img src={PathwayTest} alt="pathway-test" />
          </div>
          <h2 className="text-sm justify-center flex items-center font-semibold font-geist-mono mb-1.5">
            {isEditing ? 'EDIT TEST CASE' : 'TEST CASES'}
          </h2>
          {!isEditing && showHowTo && (
            <>
              <p className="text-xs flex justify-center items-center text-gray-600 mb-2.5">
                Test cases helps automate testing of your pathway, across
                different scenarios.
                {' '}
                <br />
                Make changes to your pathway and re-run these tests to ensure
                your pathway is working as expected.
              </p>
              <div className="flex justify-center">
                <button
                  className="text-2xs text-gray-700 px-2.5 py-1.5 rounded bg-white hover:bg-gray-200 border border-gray-200 font-geist-mono gap-1.5 flex items-center font-bold tracking-wider"
                  onClick={() =>
                    window.open(
                      'https://www.loom.com/share/77bf5dd948134fa7990eeaa7a42d9cdd?sid=12bef6f0-3147-4393-b058-f815017917ba',
                      '_blank',
                    )}
                >
                  <FaPlay size={11} />
                  HOW TO USE TEST CASES
                </button>
              </div>
            </>
          )}
        </div>

        <div className="overflow-y-auto flex-grow">
          <form
            onSubmit={methods.handleSubmit(onFormSubmit)}
            className="space-y-4 p-4"
          >
            <div>
              <label className="block text-xs font-medium text-gray-600 mb-1.5">
                Test Case Name
                {' '}
                <span className="text-gray-400 text-2xs">(must be unique)</span>
              </label>
              <Input
                {...methods.register('name')}
                placeholder="Happy Path/ Error Path (User asks question about cats)"
                className="w-full border border-gray-300 rounded-"
              />
            </div>

            <div className="space-y-2.5">
              <label className="block text-xs font-medium text-gray-600">
                Success Criteria
              </label>
              <Card className="border-2 border-gray-200">
                <CardContent className="p-4">
                  <div className="space-y-2.5">
                    <div className="flex items-center justify-between mb-1.5">
                      <p className="text-xs text-gray-600">
                        Select the target node that determines test success:
                      </p>
                      <button
                        type="button"
                        onClick={() => setUseManualInput(!useManualInput)}
                        className="text-2xs text-indigo-600 hover:text-indigo-800"
                      >
                        {useManualInput
                          ? 'Use Dropdown'
                          : 'Enter Node ID Manually'}
                      </button>
                    </div>

                    <Controller
                      name="endNode"
                      control={methods.control}
                      render={({ field }) =>
                        useManualInput
                          ? (
                              <Input
                                {...field}
                                placeholder="Enter node ID"
                                className="w-full border border-gray-300"
                              />
                            )
                          : (
                              <NodeSelect
                                nodes={elements.nodes}
                                value={field.value}
                                onChange={field.onChange}
                                placeholder="Select target node"
                              />
                            )}
                    />

                    {(selectedNode || useManualInput) && (
                      <div className="grid grid-cols-2 gap-2.5 mt-2.5">
                        <div className="p-2.5 bg-green-50 rounded-md border border-green-200">
                          <div className="flex items-center gap-1.5 mb-1.5">
                            <CheckCircle2 className="text-green-600 w-3 h-3" />
                            <h3 className="font-medium text-green-800">
                              Pass Test Case
                            </h3>
                          </div>
                          <p className="text-xs text-green-700">
                            Test will pass if the conversation reaches
                            {' '}
                            {useManualInput
                              ? 'the specified node'
                              : `the node: "${selectedNode?.data.name}"`}
                          </p>
                        </div>
                        <div className="p-2.5 bg-red-50 rounded-md border border-red-200">
                          <div className="flex items-center gap-1.5 mb-1.5">
                            <XCircle className="text-red-600 w-3 h-3" />
                            <h3 className="font-medium text-red-800">
                              Fail Test Case
                            </h3>
                          </div>
                          <p className="text-xs text-red-700">
                            Test will fail if the conversation:
                            <ul className="list-disc ml-2.5 mt-0.5">
                              <li>Ends at any other node</li>
                              <li>Times out</li>
                              <li>Encounters an error</li>
                            </ul>
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </CardContent>
              </Card>
            </div>

            <div>
              <label className="block text-xs font-medium text-gray-600 mb-1.5">
                Input Messages
                <span className="text-gray-400 text-2xs ml-1.5">
                  (Simulate user messages in conversation order)
                </span>
              </label>
              <Controller
                name="inputMessages"
                control={methods.control}
                defaultValue=""
                render={({ field }) => {
                  const messages = field.value ? field.value.split('\n') : []

                  const handleAdd = () => {
                    field.onChange([...messages, ''].join('\n'))
                  }

                  const handleUpdate = (index, value) => {
                    const newMessages = [...messages]
                    newMessages[index] = value
                    field.onChange(newMessages.join('\n'))
                  }

                  const handleDelete = (index) => {
                    const newMessages = messages.filter((_, i) => i !== index)
                    field.onChange(newMessages.join('\n'))
                  }

                  return (
                    <MessageList
                      messages={messages}
                      onUpdate={handleUpdate}
                      onDelete={handleDelete}
                      onAdd={handleAdd}
                    />
                  )
                }}
              />
            </div>

            <div className="space-y-2.5">
              <button
                type="button"
                onClick={() => setShowAdvanced(!showAdvanced)}
                className="flex items-center gap-1.5 text-xs font-medium text-gray-600 hover:text-gray-800"
              >
                {showAdvanced
                  ? (
                      <ChevronUp size={16} />
                    )
                  : (
                      <ChevronDown size={16} />
                    )}
                Advanced Options
              </button>

              {showAdvanced && (
                <Card>
                  <CardContent className="px-4 pt-4 space-y-5 flex flex-col">
                    <div>
                      <PathwayVersion
                        pathwayId={pathwayId}
                        fieldName="pathwayVersion"

                      />
                    </div>
                    <div>
                      <div className="flex items-center justify-between mb-1.5">
                        <label className="block text-xs font-medium text-gray-600">
                          Start Node ID
                        </label>
                        <button
                          type="button"
                          onClick={() =>
                            setUseManualStartInput(!useManualStartInput)}
                          className="text-xs text-blue-600 hover:text-blue-800"
                        >
                          {useManualStartInput
                            ? 'Use Dropdown'
                            : 'Enter Node ID Manually'}
                        </button>
                      </div>
                      <Controller
                        name="startNodeID"
                        control={methods.control}
                        render={({ field }) =>
                          useManualStartInput
                            ? (
                                <Input
                                  {...field}
                                  placeholder="Enter start node ID"
                                  className="w-full border border-gray-300"
                                />
                              )
                            : (
                                <NodeSelect
                                  nodes={elements.nodes}
                                  value={field.value}
                                  onChange={field.onChange}
                                  placeholder="Select start node"
                                />
                              )}
                      />
                    </div>

                    <div className="">
                      <Controller
                        name="requestData"
                        control={methods.control}
                        render={({ field }) => (
                          <ObjectInput
                            name="requestData"
                            label="Request Data"
                            description="Add any additional data you want to provide to the pathway when running this test."
                            className="w-full"
                            value={field.value}
                            onChange={field.onChange}
                          />
                        )}
                      />
                    </div>
                  </CardContent>
                </Card>
              )}
            </div>
          </form>
        </div>

        <div className="border-t border-gray-200 p-2.5 mb-2.5 bg-white flex justify-start gap-4 flex-shrink-0">
          <button
            type="button"
            onClick={onCancel}
            className="px-2.5 py-1.5 text-xs font-medium text-gray-600 hover:text-gray-800"
          >
            CANCEL
          </button>
          <button
            disabled={isLoading}
            type="submit"
            onClick={methods.handleSubmit(onFormSubmit)}
            className="px-2.5 py-1.5 text-xs font-medium text-white bg-black rounded hover:bg-gray-800"
          >
            {isLoading
              ? 'SAVING...'
              : isEditing
                ? 'SAVE CHANGES'
                : 'ADD TEST CASE'}
          </button>
        </div>
      </div>
    </FormProvider>
  )
}

export default AddSimulationForm
