// /pages/Home.jsx

import { Card } from '@/components/ui/card'
import {
  Box,
  Button,
  Flex,
  Grid,
  Heading,
  Inset,
  Separator,
  Spinner,
  Text,
} from '@radix-ui/themes'
import ActionBar from 'components/core/ActionBar'

import { PageTitle } from 'components/core/PageTitle'
import { PageWrapper } from 'components/core/PageWrapper'
import GradientLoadingAnimation from 'components/Reusables/GradientLoadingAnimation'
import { useAuth } from 'hooks/useAuth'
import {
  AlertTriangle,
  ChevronRight,
  ChevronRightIcon,
  GraduationCap,
} from 'lucide-react'
import { useCallback, useEffect, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary' // <-- ADDED
import { BsTelephoneOutbound } from 'react-icons/bs'
import { FiCopy } from 'react-icons/fi'

import { TiFlowSwitch } from 'react-icons/ti'
import { useNavigate } from 'react-router-dom'
import { $fetch } from 'utils/fetch'
import { fetchCalls } from 'utils/funcs/call-logs/fetchCalls'

import { actionItems } from '../../../utils/data/menus/homeItems'
import CallMap from './CallMap'
// Our extracted chart + map components
import CallsChart from './CallsChart'

// ============ 1) Error Boundary Fallback UI  ============
function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <div
      role="alert"
      style={{
        padding: '0.63rem',
        margin: '0.63rem',
        borderRadius: '5px',
        backgroundColor: '#ffe8e8',
        color: '#a00',
      }}
    >
      <h2>Something went wrong!</h2>
      <p>{error?.message ?? 'Unknown error'}</p>
      <button
        style={{
          border: '1px solid #ccc',
          background: 'white',
          padding: '0.31rem',
          cursor: 'pointer',
          marginTop: '0.63rem',
        }}
        onClick={resetErrorBoundary}
      >
        Try Again
      </button>
    </div>
  )
}

// ============ 2) The core "HomeContent" component ============
// (We moved your entire Home code into "HomeContent".)
function HomeContent() {
  const { user } = useAuth()
  const navigate = useNavigate()
  const { org } = useAuth()

  const [dailyData, setDailyData] = useState([])
  const [areaCodeData, setAreaCodeData] = useState([])
  const [error, setError] = useState({ calls: null, map: null })
  const [loading, setLoading] = useState(true)
  const [chartType, setChartType] = useState('line')
  const [callLogData, setCallLogData] = useState([])
  const [refreshing, setRefreshing] = useState(false)

  // ============= Reusable "ErrorDisplay" =============
  const ErrorDisplay = ({ message }) => (
    <div className="flex flex-col items-center justify-center h-full">
      <div className="mb-2.5 p-2 rounded bg-gray-50">
        <AlertTriangle className="w-3 h-3 text-orange-300 flex-1" />
      </div>
      <p className="text-xs text-gray-400">{message}</p>
    </div>
  )

  // 1) Fetch analytics data on mount (chart & map)
  useEffect(() => {
    if (
      org?.id === '034b4ad8-2ff7-4216-97d8-058b2315ec73'
      || user?.user?.placement_group === 'planswell'
    ) {
      return setLoading(false)
    }

    const fetchData = async () => {
      try {
        // Turned cache to false on the map & calls
        const [callsData, mapData] = await Promise.all([
          $fetch(`/analytics/last_seven_calls`, {}, false),
          $fetch(`/analytics/last_seven_map`, {}, false),
        ])

        // 1a) Process calls for charts
        if (callsData.data) {
          const now = new Date()
          const processedData = callsData.data
            .map(item => ({
              hour: new Date(item.hour),
              count: Number.parseInt(item.count, 10) || 0,
            }))
            .filter(item => item.hour <= now)
            .sort((a, b) => a.hour - b.hour)
          setDailyData(processedData)
        }
        else {
          setError(prev => ({
            ...prev,
            calls: 'Failed to fetch calls data',
          }))
        }

        // 1b) area code data for the map
        if (mapData.data) {
          setAreaCodeData(mapData.data)
        }
        else {
          setError(prev => ({
            ...prev,
            map: 'Failed to fetch area code data',
          }))
        }
      }
      catch (err) {
        console.error('Error fetching data:', err)
        setError({
          calls: 'An unexpected error occurred',
          map: 'An unexpected error occurred',
        })
      }
      finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [])

  // 2) Fetch last 5 calls => for the "logs" section
  const fetchCallLogs = useCallback(async () => {
    setRefreshing(true)
    try {
      const { success, calls } = await fetchCalls(1, 5)
      if (success) {
        // 2a) Update the call logs table
        setCallLogData((prevData) => {
          if (JSON.stringify(prevData) !== JSON.stringify(calls)) {
            return calls
          }
          return prevData
        })

        // 2b) Update the areaCodeData ONLY for brand-new calls => do not re-draw arcs every time
        setAreaCodeData((prevMapData) => {
          // Convert existing array into a dictionary by areaCode
          const mapDict = {}
          for (const rec of prevMapData) {
            mapDict[rec.areaCode] = {
              ...rec,
              // calls: [...whatever was in rec]
              calls: [...(rec.calls || [])],
            }
          }

          // For each new call in your new fetch:
          for (const call of calls) {
            // Decide if we need inbound vs outbound logic:
            // e.g. const phoneStr = call.inbound ? call.from : call.to;
            const phoneStr = call.from
            if (!phoneStr)
              continue

            // Extract area code (same logic you already have)
            let areaCode = ''
            if (phoneStr.startsWith('+1')) {
              areaCode = phoneStr.substring(2, 5)
            }
            else {
              areaCode = phoneStr.substring(1, 4)
            }
            if (!areaCode)
              continue

            // Create a new record if missing
            if (!mapDict[areaCode]) {
              mapDict[areaCode] = {
                areaCode,
                count: 0,
                calls: [],
              }
            }

            // Check if we’ve already recorded this exact call
            const alreadyExists = mapDict[areaCode].calls.some(
              c => c.c_id === call.c_id,
            )
            if (alreadyExists)
              continue

            // If truly new, push it
            mapDict[areaCode].calls.push({
              ...call,
              justAdded: true,
            })
            mapDict[areaCode].count++
          }

          return Object.values(mapDict)
        })
      }
    }
    catch (error) {
      console.error('Error fetching call logs:', error)
    }
    finally {
      setRefreshing(false)
    }
  }, [])

  // 3) Periodic fetch of call logs => every 20s
  useEffect(() => {
    fetchCallLogs()
    const interval = setInterval(() => {
      fetchCallLogs()
    }, 20000)
    return () => clearInterval(interval)
  }, [fetchCallLogs])

  // ================== CALL LOG TABLE ==================
  const renderCallLog = () => {
    if (callLogData.length === 0) {
      return (
        <div className="text-center text-gray-500 py-4 text-xs">
          No calls to display.
        </div>
      )
    }

    const copyToClipboard = (text) => {
      navigator.clipboard.writeText(text)
      alert('Call ID copied to clipboard!')
    }

    return (
      <div className="overflow-x-auto font-mono border border-gray-100 rounded-md">
        <table className="min-w-full text-left">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-2.5 py-1.5 text-2xs font-semibold text-gray-600 uppercase tracking-wider">
                ID
              </th>
              <th className="px-2.5 py-1.5 text-2xs font-semibold text-gray-600 uppercase tracking-wider">
                Timestamp
              </th>
              <th className="px-2.5 py-1.5 text-2xs font-semibold text-gray-600 uppercase tracking-wider">
                Duration
              </th>
              <th className="px-2.5 py-1.5 text-2xs font-semibold text-gray-600 uppercase tracking-wider">
                To
              </th>
              <th className="px-2.5 py-1.5 text-2xs font-semibold text-gray-600 uppercase tracking-wider">
                Price
              </th>
            </tr>
          </thead>
          <tbody>
            {callLogData.map((call, index) => (
              <tr
                key={index}
                className={`${
                  index % 2 === 0 ? 'bg-white' : 'bg-gray-50'
                } border-b hover:bg-gray-100 transition`}
              >
                <td className="px-2.5 py-2 text-xs text-gray-800 flex items-center space-x-1.5">
                  <span title={call.c_id}>
                    {call.c_id?.slice(0, 8) || 'unknown'}
                    ...
                  </span>
                  <FiCopy
                    className="cursor-pointer text-gray-500 hover:text-gray-800"
                    onClick={() => copyToClipboard(call.c_id)}
                  />
                  {call.queue_status === 'started' && (
                    <span className="flex items-center space-x-0.5">
                      <div className="flex relative">
                        <span className="w-1.5 h-1.5 bg-green-500 rounded-full mx-auto my-auto" />
                        <span className="w-1.5 h-1.5 bg-green-500 rounded-full animate-lessslowping absolute" />
                      </div>
                      <span className="text-2xs text-green-600 font-semibold">
                        Live
                      </span>
                    </span>
                  )}
                </td>
                <td className="px-2.5 py-2 text-xs text-gray-600">
                  {new Date(call.created_at).toLocaleString('en-US', {
                    dateStyle: 'medium',
                    timeStyle: 'short',
                  })}
                </td>
                <td className="px-2.5 py-2 text-xs text-gray-600">
                  {call?.call_length?.toFixed(2)}
                  {' '}
                  min
                </td>
                <td className="px-2.5 py-2 text-xs text-gray-600">
                  {call.to || 'N/A'}
                </td>
                <td className="px-2.5 py-2 text-xs text-gray-600">
                  {call.price ? `$${call.price.toFixed(2)}` : 'N/A'}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
  }

  // ================== LOADING STATE ==================
  if (loading) {
    return (
      <GradientLoadingAnimation
        rounded="full"
        width="24"
        message="Loading."
        duration={1}
        variant="default"
      />
    )
  }

  // ================== MAIN RENDER ==================
  return (
    <PageWrapper padding="54px 30px 0px 84px">
      <ActionBar top>
        <div className="flex items-center justify-between w-full">
          <PageTitle className="text-base font-semibold text-gray-800">
            Home
          </PageTitle>
          <div className="flex space-x-2 text-xs">
            <Button
              color="gray"
              highContrast
              style={{ cursor: 'pointer' }}
              type="button"
              size="2"
              variant="solid"
              onClick={() => {
                navigate('/dashboard/send-call')
              }}
            >
              Send Call
              <BsTelephoneOutbound className="ml-1.5" size={12} />
            </Button>
            <Button
              color="iris"
              style={{ cursor: 'pointer' }}
              type="button"
              size="2"
              variant="solid"
              onClick={() => {
                navigate('/dashboard/convo-pathways')
              }}
            >
              Build Pathway
              <TiFlowSwitch className="ml-1.5" size={14} />
            </Button>
          </div>
        </div>
      </ActionBar>

      <div className="flex w-full h-full">
        {/* LEFT CONTENT AREA */}
        <div className="flex-1 pr-5 pb-4 space-y-2.5 pt-8">
          {/* Greeting */}
          <div className="pt-2.5">
            <Heading as="h1" size="7" className="mb-1.5 text-gray-900">
              Hi
              {' '}
              {user?.user?.first_name}
              !
            </Heading>
            <Text className="text-gray-600" size="2">
              Here's everything you need to know about your calls.
            </Text>
          </div>

          {/* Call Distribution Map (colored by call density) */}
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Card className="w-full">
              <Flex direction="column" className="h-full">
                <Heading as="h2" size="3" className="p-2 border-b">
                  Call Distribution by Region
                </Heading>
                <Grid columns="6" gap="3" className="p-2">
                  <Box className="col-span-full p-2">
                    {/* Our extracted "CallMap" component */}
                    <CallMap
                      areaCodeData={areaCodeData}
                      navigate={navigate}
                      ErrorDisplay={ErrorDisplay}
                      Button={Button}
                      ChevronRight={ChevronRight}
                    />
                  </Box>
                </Grid>
              </Flex>
            </Card>
          </div>

          {/* Call Logs & Chart */}
          <Card size="2">
            <Inset>
              <Flex direction="column" className="min-w-[200px]">
                <Heading
                  as="h2"
                  size="3"
                  className="p-2 border-b flex items-center"
                >
                  Call Logs
                  {refreshing && <Spinner size="1" className="ml-1.5" />}
                </Heading>
                {callLogData && callLogData.length > 0 ? (
                  <Grid columns="5" gap="2" className="p-2">
                    <Box className="col-span-3">{renderCallLog()}</Box>
                    <Box className="col-span-2">
                      {/* Our extracted "CallsChart" component */}
                      <CallsChart dailyData={dailyData} chartType="line" />
                    </Box>
                  </Grid>
                ) : (
                  <div
                    style={{
                      height: '250px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: '10px',
                      }}
                    >
                      <ErrorDisplay message="No call logs yet! Send your first call to see metrics here." />
                      <Button
                        type="button"
                        onClick={() => navigate('/dashboard/send-call')}
                        variant="ghost"
                        size="2"
                        color="gray"
                      >
                        Send a Call to Get Started
                        <ChevronRight size={14} />
                      </Button>
                    </div>
                  </div>
                )}
              </Flex>
            </Inset>
          </Card>
        </div>

        {/* RIGHT SIDEBAR */}
        <div className="w-1/4 max-w-sm pl-4 h-full bg-white border-l hidden xl:block">
          <Flex direction="column" className="space-y-2.5 w-full pl-3">
            <Text
              size="4"
              weight="bold"
              style={{ marginTop: 32, marginBottom: -8 }}
            >
              Quick Actions
            </Text>
            <p style={{ color: 'GrayText', marginBottom: 10, fontSize: 13 }}>
              One-click shortcuts to your essential tools.
            </p>

            <Flex direction="column" gap="2" className="w-full">
              {actionItems.map(action => (
                <Card
                  key={action.title}
                  size="2"
                  className="p-2 w-full cursor-pointer hover:bg-gray-100 transition-colors"
                  onClick={() => navigate(action.link)}
                >
                  <Flex align="center" gap="3">
                    {action.icon && (
                      <Box className="text-sm text-gray-600 mr-2.5">
                        {action.icon}
                      </Box>
                    )}
                    <Flex direction="column" gap="1" className="flex-1">
                      <Text weight="medium" size="2">
                        {action.title}
                      </Text>
                      <Text size="1" color="gray">
                        {action.description}
                      </Text>
                    </Flex>
                    <ChevronRightIcon />
                  </Flex>
                </Card>
              ))}
            </Flex>

            <Separator
              size="3"
              style={{ width: '100%', background: '#f7f7f7' }}
            />

            {/* Bland University link */}
            <Card
              size="2"
              className="p-2 bg-blue-50 cursor-pointer hover:bg-blue-100 transition-colors mb-4"
              onClick={() =>
                window.open('https://university.bland.ai', '_blank')}
            >
              <Flex align="center" gap="3">
                <GraduationCap size={16} color="blue" className="mr-2.5" />
                <Flex direction="column" gap="1" className="flex-1">
                  <Text weight="medium" size="2">
                    Bland University
                  </Text>
                  <Text size="1" color="gray">
                    Learn Bland From Top To Bottom With Our Comprehensive Guide
                  </Text>
                </Flex>
                <ChevronRightIcon color="blue" />
              </Flex>
            </Card>
          </Flex>
        </div>
      </div>
    </PageWrapper>
  )
}

// ============ 3) Wrap "HomeContent" in an ErrorBoundary & export ============
//    This ensures even if something *really* unexpected blows up,
//    your UI won't fully crash — you'll see the fallback instead.
export default function Home() {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <HomeContent />
    </ErrorBoundary>
  )
}
