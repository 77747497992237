import { Spinner } from '@radix-ui/themes'
import ActionBar from 'components/core/ActionBar'
import { PageTitle } from 'components/core/PageTitle'
import { PageWrapper } from 'components/core/PageWrapper'
import { ArrowLeftRight, Send } from 'lucide-react'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { $fetch } from 'utils/fetch'

function SMSHumanChat() {
  const [newMessage, setNewMessage] = useState('')
  const [selectedConversation, setSelectedConversation] = useState(null)
  const [chatHistory, setChatHistory] = useState([])
  const [searchParams] = useSearchParams()
  const sms_id = searchParams.get('id')
  const [sendingMessage, setSendingMessage] = useState(false)
  const [pathwayId, setPathwayId] = useState(null)
  const [loadingFetch, setLoadingFetch] = useState(false)

  const fetchSingleConversation = async () => {
    setLoadingFetch(true)
    const response = await $fetch(`/sms/pathway/get_conversations/${sms_id}`, {
      method: 'GET',
    })

    const data = response.data

    if (data.chat_history) {
      setChatHistory(data.chat_history)
      const formattedConversation = formatSingleConversation({
        chat_history: data.chat_history,
        id: data.id,
        from_number: data.from_number,
        to_number: data.to_number,
        created_at: data.created_at,
      })
      setPathwayId(data.pathway_id)
      setSelectedConversation(formattedConversation)
    }

    setLoadingFetch(false)
  }

  useEffect(() => {
    if (sms_id) {
      fetchSingleConversation()
      // Set up polling every 10 seconds
      const pollInterval = setInterval(() => {
        fetchSingleConversation()
      }, 10000)

      // Cleanup function to clear the interval when component unmounts
      return () => {
        clearInterval(pollInterval)
      }
    }
  }, [sms_id])

  const formatSingleConversation = (conversationData) => {
    const { chat_history, id, from_number, to_number, created_at }
      = conversationData

    if (!chat_history || chat_history.length === 0) {
      return null
    }

    // Map the chat history to the desired format
    const formattedMessages = chat_history.map((msg, index) => ({
      id: `${id}-${index}`, // Create unique message IDs
      message: msg.content,
      // Now assistant messages are from the from_number, user messages from to_number
      from: msg.role === 'assistant' ? to_number : from_number,
      to: msg.role === 'assistant' ? from_number : to_number,
      sender: msg.role === 'assistant' ? 'AGENT' : 'USER',
      created_at, // Using conversation created_at as message timestamp
    }))

    // Create the conversation object
    const lastMessage = formattedMessages[formattedMessages.length - 1]

    return {
      id,
      from: from_number,
      to: to_number,
      messages: formattedMessages,
      lastMessage: lastMessage.message,
      timestamp: created_at,
      messageCount: formattedMessages.length,
    }
  }

  const handleSendSMS = async () => {
    if (!newMessage.trim() || !selectedConversation)
      return

    setSendingMessage(true)
    try {
      await $fetch(`/sms/pathway/send_sms`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          from: selectedConversation.from, // The number we're sending from
          to: selectedConversation.to, // The number we're sending to
          message: newMessage,
          pathway_id: pathwayId,
          chat_history: chatHistory,
          conversation_id: sms_id,
        }),
      })

      // Add the new message to the conversation
      const newMsg = {
        id: `${selectedConversation.id}-${selectedConversation.messages.length}`,
        message: newMessage,
        from: selectedConversation.from,
        to: selectedConversation.to,
        sender: 'AGENT',
        created_at: new Date().toISOString(),
      }

      const updatedConversation = {
        ...selectedConversation,
        messages: [...selectedConversation.messages, newMsg],
        lastMessage: newMessage,
      }

      setSelectedConversation(updatedConversation)
      setNewMessage('')
      toast.success('Message sent successfully')
    }
    catch (error) {
      console.error('Error sending message:', error)
      toast.error('Failed to send message')
    }
    finally {
      setSendingMessage(false)
    }
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault()
      handleSendSMS()
    }
  }

  return (
    <PageWrapper>
      <ActionBar top>
        <PageTitle>SMS</PageTitle>
      </ActionBar>

      <div className="h-screen flex flex-col bg-gray-50">
        <main className="flex-grow">
          <div className="mx-auto py-4 sm:px-4 lg:px-20">
            {loadingFetch
              ? (
                  <div className="flex justify-center items-center h-40">
                    <Spinner size="3" />
                  </div>
                )
              : (
                  selectedConversation && (
                    <div className="bg-white rounded-lg shadow-sm flex flex-col h-[calc(100vh-160px)]">
                      <div className="p-2.5 border-b border-gray-200">
                        <h3 className="font-semibold text-gray-900">
                          Conversation
                        </h3>
                        <div className="flex items-center text-2xs text-gray-500 mt-0.5">
                          <ArrowLeftRight className="h-2.5 w-2.5 mr-0.5" />
                          <span>
                            {selectedConversation.from}
                            {' '}
                            ↔
                            {selectedConversation.to}
                          </span>
                        </div>
                      </div>
                      <div className="flex-grow overflow-auto p-2.5 space-y-2.5">
                        {selectedConversation.messages.map(msg => (
                          <div
                            key={msg.id}
                            className={`max-w-xs mx-1.5 p-2 rounded-md ${
                              msg.sender === 'USER'
                                ? 'bg-gray-100 text-gray-800 ml-auto'
                                : 'bg-indigo-500 text-white'
                            }`}
                          >
                            <p className="text-[12px]">{msg.message}</p>
                            <p
                              className={`text-[11px] mt-0.5 ${
                                msg.sender === 'USER'
                                  ? 'text-gray-500'
                                  : 'text-indigo-100'
                              }`}
                            >
                              {new Date(msg.created_at).toLocaleString()}
                            </p>
                          </div>
                        ))}
                      </div>
                      <div className="p-2.5 border-t border-gray-200">
                        <div className="flex items-center gap-1.5">
                          <textarea
                            className="flex-grow px-2 py-1.5 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-indigo-500 transition-colors resize-none"
                            placeholder="Type a message..."
                            rows="1"
                            value={newMessage}
                            onChange={e => setNewMessage(e.target.value)}
                            onKeyPress={handleKeyPress}
                          />
                          <button
                            className="px-2.5 py-1.5 bg-indigo-600 text-white rounded hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 transition-colors disabled:bg-gray-400"
                            onClick={handleSendSMS}
                            disabled={sendingMessage || !newMessage.trim()}
                          >
                            {sendingMessage
                              ? (
                                  <Spinner className="w-3 h-3" />
                                )
                              : (
                                  <Send className="w-3 h-3" />
                                )}
                          </button>
                        </div>
                      </div>
                    </div>
                  )
                )}
          </div>
        </main>
      </div>
    </PageWrapper>
  )
}

export default SMSHumanChat
