import { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'

const POLLING_INTERVAL = 5000

export function useSimulations(simulationsApi) {
  const [simulations, setSimulations] = useState([])
  const [recentRuns, setRecentRuns] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [runningCalls, setRunningCalls] = useState(new Set())

  const [selectedCallId, setSelectedCallId] = useState(null)
  const [isCreationModalOpen, setIsCreationModalOpen] = useState(null)
  const [creationLoading, setCreationLoading] = useState(false)

  const [currentReplaySimulationCallID, setCurrentReplaySimulationCallID]
    = useState(null)

  const [loadingSimulations, setLoadingSimulations] = useState(new Set())

  // Existing fetch functions remain the same
  const fetchData = useCallback(async (params) => {
    setLoading(true)
    try {
      const [simulationsResponse, runsResponse] = await Promise.all([
        simulationsApi.fetchSimulations(params),
        simulationsApi.fetchRecentRuns(params),
      ])

      const [simulationsData, runsData] = await Promise.all([
        simulationsResponse,
        runsResponse,
      ])

      setSimulations(simulationsData)

      setRecentRuns(runsData)
    }
    catch (err) {
      setError('An error occurred while fetching data')
      console.error(err)
    }
    finally {
      setLoading(false)
    }
  }, [])

  // Existing polling functions remain the same
  const pollCallStatus = useCallback(
    async (callId, pathwayId) => {
      try {
        const data = await simulationsApi.checkSimulationStatus(callId)

        const status = data.status

        if (status === 'pass' || status === 'failed') {
          setRunningCalls((prev) => {
            const newSet = new Set(prev)
            newSet.delete(callId)
            return newSet
          })

          await fetchData({
            pathwayId,
          })

          toast.success(
            status === 'pass'
              ? `Test run ${callId} completed successfully`
              : `Test run ${callId} failed`,
          )

          return true
        }

        return false
      }
      catch (error) {
        console.error('Error polling simulation status:', error)
        setError('Error checking simulation status')
        return true
      }
    },
    [simulationsApi, fetchData],
  )

  const startPolling = useCallback(
    (callId, pathwayId) => {
      const poll = async () => {
        const shouldStop = await pollCallStatus(callId, pathwayId)
        if (!shouldStop) {
          setTimeout(() => poll(), POLLING_INTERVAL)
        }
      }

      setRunningCalls(prev => new Set(prev).add(callId))
      poll()
    },
    [pollCallStatus],
  )

  // Existing run simulation function remains the same
  const runSimulation = useCallback(
    async (simulationId, pathwayId, versionNumber) => {
      setLoadingSimulations(prev => new Set(prev).add(simulationId))
      try {
        const data = await simulationsApi.runSimulation(
          simulationId,
          versionNumber,
        )

        await fetchData({ pathwayId })
        startPolling(data.call_id, pathwayId)

        toast.success('Test run started successfully')
        return data.call_id
      }
      catch (error) {
        toast.error('Failed to start test run')
        setError('Failed to start test run')
        console.error(error)
      }
      finally {
        setLoadingSimulations((prev) => {
          const newSet = new Set(prev)
          newSet.delete(simulationId)
          return newSet
        })
      }
    },
    [simulationsApi, fetchData],
  )

  const updateSimulation = useCallback(
    async ({ simulationId, updateData }) => {
      try {
        const res = await simulationsApi.updateSimulation(
          simulationId,
          updateData,
        )

        // UPDATE SIMUALTIONS
        setSimulations(prev =>
          prev.map(simulation =>
            simulation.id === simulationId
              ? { ...simulation, ...updateData }
              : simulation,
          ),
        )
        toast.success('Simulation updated successfully')
      }
      catch (e) {
        console.error('Error updating simulation', e)
        toast.error('Error updating simulation')
      }
    },
    [simulationsApi],
  )

  const deleteSimulation = useCallback(
    async (simulationId) => {
      try {
        await simulationsApi.deleteSimulation(simulationId)

        // UPDATE SIMULATIONS
        setSimulations(prev =>
          prev.filter(simulation => simulation.id !== simulationId),
        )

        toast.success('Simulation deleted successfully')
      }
      catch (e) {
        console.error('Error deleting simulation', e)
        toast.error('Error deleting simulation')
      }
    },
    [simulationsApi],
  )

  const getStatus = useCallback(
    (callId) => {
      console.log('reuns', recentRuns)
      console.log('call id', callId)

      const res = recentRuns.find(run => run.call_id === callId)
      // evlauation
      const evaluation = res?.evaluation
      console.log('evaluation', evaluation)
      return {
        status: res?.status,
        evaluation,
      }
    },
    [recentRuns],
  )

  // New functions for simulation creation
  const handleAddToSimulation = useCallback((callId) => {
    setSelectedCallId(callId)
    setIsCreationModalOpen(true)
  }, [])

  const createSimulation = async ({
    name,
    callId,
    endNode,
    pathwayId,
    startNodeId,
    requestData,
    inputMessages,
    pathwayVersion,
  }) => {
    setCreationLoading(true)
    try {
      const result = await simulationsApi.createSimulation({
        pathway_id: pathwayId,
        call_id: callId,
        name,
        request_data: requestData,
        start_node_id: startNodeId,
        success_criteria: {
          end_node: endNode,
        },
        input_messages: inputMessages,
        pathway_version: pathwayVersion,
      })

      await fetchData({
        pathwayId,
      })

      toast.success('Test Case created successfully')
      return result.simulation_id
    }
    catch (error) {
      const errorMessage = error.message || 'Failed to create test case'
      toast.error(errorMessage)
    }
    finally {
      setCreationLoading(false)
    }
  }

  const closeModal = useCallback(() => {
    setIsCreationModalOpen(false)
    setSelectedCallId(null)
  }, [])

  const openCreationModal = useCallback(() => {
    setIsCreationModalOpen(true)
  }, [])

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      setRunningCalls(new Set())
    }
  }, [])

  return {
    simulations,
    recentRuns,

    loading,
    error,
    runningCalls,
    runSimulation,
    fetchData,
    // New returns for simulation creation
    isCreationModalOpen,
    setIsCreationModalOpen,
    selectedCallId,
    creationLoading,
    handleAddToSimulation,
    deleteSimulation,
    createSimulation,
    updateSimulation,
    closeModal,
    getStatus,
    openCreationModal,
    currentReplaySimulationCallID,
    setCurrentReplaySimulationCallID,
    loadingSimulations,
  }
}
