import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import styled, { css, keyframes } from 'styled-components'

export default function Switch({
  fieldName,
  label,
  disabled = false,
  ...props
}) {
  const { setValue, getValues } = useFormContext()
  const [bool, setBool] = useState(getValues(fieldName))
  const [isPressed, setIsPressed] = useState(false)

  useEffect(() => {
    setBool(getValues(fieldName))
  }, [getValues, fieldName])

  const toggleSwitch = () => {
    if (fieldName && !disabled) {
      const newValue = !bool
      setBool(newValue)
      setValue(fieldName, newValue)
    }
  }

  return (
    <Wrapper
      disabled={disabled}
      onMouseDown={() => setIsPressed(true)}
      onMouseUp={() => setIsPressed(false)}
      onMouseLeave={() => setIsPressed(false)}
      {...props}
    >
      <SwitchBody
        type="button"
        on={bool}
        onClick={toggleSwitch}
        isPressed={isPressed}
        disabled={disabled}
        tabIndex={disabled ? -1 : 0}
      >
        <SwitchBackground on={bool} disabled={disabled} />
        <ThumbContainer on={bool}>
          <Thumb isPressed={isPressed} disabled={disabled}>
            <Ripple on={bool} />
          </Thumb>
        </ThumbContainer>
      </SwitchBody>
      {label && <>{label}</>}
    </Wrapper>
  )
}

const rippleEffect = keyframes`
  0% {
    transform: scale(0);
    opacity: 0.4;
  }
  100% {
    transform: scale(2.5);
    opacity: 0;
  }
`

const Wrapper = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: fit-content;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`

const SwitchBody = styled.button`
  position: relative;
  height: 28px;
  width: 50px;
  border-radius: 25%;
  padding: 0;
  border: none;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  transform: scale(${({ isPressed }) => (isPressed ? 0.95 : 1)});
  transition: transform 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  background: transparent;
  outline: none;

  &:hover {
    ${({ disabled }) =>
      !disabled
      && css`
        transform: scale(1.02);
      `}
  }

  &:focus-visible {
    box-shadow:
      0 0 0 2px white,
      0 0 0 4px #433dff;
  }
`

const SwitchBackground = styled.div`
  position: absolute;
  inset: 0;
  border-radius: 100px;
  background-color: ${({ on }) => (on ? '#433DFF' : '#f0f0ed')};
  transition: background-color 0.25s cubic-bezier(0.4, 0, 0.2, 1);

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: inherit;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
    opacity: ${({ on }) => (on ? 0 : 1)};
    transition: opacity 0.2s;
  }
`

const ThumbContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding: 5px;
  transition: transform 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  transform: ${({ on }) => (on ? 'translateX(20px)' : 'translateX(0)')};
`

const Thumb = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: white;
  position: relative;
  transform: scale(${({ isPressed }) => (isPressed ? 0.9 : 1)});
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

  ${({ disabled }) =>
    !disabled
    && css`
      ${SwitchBody}:hover & {
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
      }
    `}
`

const Ripple = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: ${({ on }) => (on ? '#ffffff' : '#00000015')};
  transform-origin: center;
  pointer-events: none;

  ${SwitchBody}:active & {
    animation: ${rippleEffect} 0.5s cubic-bezier(0.4, 0, 0.2, 1) forwards;
  }
`
