import { Button, Form, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Select, SelectItem, useDisclosure } from '@heroui/react'
import { Button as RadixButton } from '@radix-ui/themes'
import PhoneInput from 'components/Hero/PhoneInput'
import { useAuth } from 'hooks/useAuth'
import { useState } from 'react'
import { FlagImage } from 'react-international-phone'
import { toast } from 'react-toastify'
import { $fetch } from 'utils/fetch'

const TAX_ID_TYPES = [
  { type: 'ad_nrt', iso2: 'ad', name: 'Andorra NRT' },
  { type: 'ae_trn', iso2: 'ae', name: 'United Arab Emirates TRN' },
  { type: 'al_tin', iso2: 'al', name: 'Albania Tax ID' },
  { type: 'am_tin', iso2: 'am', name: 'Armenia Tax ID' },
  { type: 'ao_tin', iso2: 'ao', name: 'Angola Tax ID' },
  { type: 'ar_cuit', iso2: 'ar', name: 'Argentina CUIT' },
  { type: 'au_abn', iso2: 'au', name: 'Australia ABN' },
  { type: 'au_arn', iso2: 'au', name: 'Australia ARN' },
  { type: 'ba_tin', iso2: 'ba', name: 'Bosnia and Herzegovina Tax ID' },
  { type: 'bb_tin', iso2: 'bb', name: 'Barbados Tax ID' },
  { type: 'bg_uic', iso2: 'bg', name: 'Bulgaria UIC' },
  { type: 'bh_vat', iso2: 'bh', name: 'Bahrain VAT' },
  { type: 'bo_tin', iso2: 'bo', name: 'Bolivia Tax ID' },
  { type: 'br_cnpj', iso2: 'br', name: 'Brazil CNPJ' },
  { type: 'br_cpf', iso2: 'br', name: 'Brazil CPF' },
  { type: 'bs_tin', iso2: 'bs', name: 'Bahamas Tax ID' },
  { type: 'by_tin', iso2: 'by', name: 'Belarus Tax ID' },
  { type: 'ca_bn', iso2: 'ca', name: 'Canada BN' },
  { type: 'ca_gst_hst', iso2: 'ca', name: 'Canada GST/HST' },
  { type: 'ca_pst_bc', iso2: 'ca', name: 'British Columbia PST' },
  { type: 'ca_pst_mb', iso2: 'ca', name: 'Manitoba PST' },
  { type: 'ca_pst_sk', iso2: 'ca', name: 'Saskatchewan PST' },
  { type: 'ca_qst', iso2: 'ca', name: 'Quebec QST' },
  { type: 'cd_nif', iso2: 'cd', name: 'Democratic Republic of Congo NIF' },
  { type: 'ch_uid', iso2: 'ch', name: 'Switzerland UID' },
  { type: 'ch_vat', iso2: 'ch', name: 'Switzerland VAT' },
  { type: 'cl_tin', iso2: 'cl', name: 'Chile Tax ID' },
  { type: 'cn_tin', iso2: 'cn', name: 'China Tax ID' },
  { type: 'co_nit', iso2: 'co', name: 'Colombia NIT' },
  { type: 'cr_tin', iso2: 'cr', name: 'Costa Rica Tax ID' },
  { type: 'de_stn', iso2: 'de', name: 'Germany Tax Number' },
  { type: 'do_rcn', iso2: 'do', name: 'Dominican Republic RCN' },
  { type: 'ec_ruc', iso2: 'ec', name: 'Ecuador RUC' },
  { type: 'eg_tin', iso2: 'eg', name: 'Egypt Tax ID' },
  { type: 'es_cif', iso2: 'es', name: 'Spain CIF' },
  { type: 'eu_oss_vat', iso2: null, name: 'EU OSS VAT' },
  { type: 'eu_vat', iso2: null, name: 'EU VAT' },
  { type: 'gb_vat', iso2: 'gb', name: 'United Kingdom VAT' },
  { type: 'ge_vat', iso2: 'ge', name: 'Georgia VAT' },
  { type: 'gn_nif', iso2: 'gn', name: 'Guinea NIF' },
  { type: 'hk_br', iso2: 'hk', name: 'Hong Kong BR' },
  { type: 'hr_oib', iso2: 'hr', name: 'Croatia OIB' },
  { type: 'hu_tin', iso2: 'hu', name: 'Hungary Tax ID' },
  { type: 'id_npwp', iso2: 'id', name: 'Indonesia NPWP' },
  { type: 'il_vat', iso2: 'il', name: 'Israel VAT' },
  { type: 'in_gst', iso2: 'in', name: 'India GST' },
  { type: 'is_vat', iso2: 'is', name: 'Iceland VAT' },
  { type: 'jp_cn', iso2: 'jp', name: 'Japan Corporate Number' },
  { type: 'jp_rn', iso2: 'jp', name: 'Japan Registration Number' },
  { type: 'jp_trn', iso2: 'jp', name: 'Japan Tax Registration Number' },
  { type: 'ke_pin', iso2: 'ke', name: 'Kenya PIN' },
  { type: 'kh_tin', iso2: 'kh', name: 'Cambodia Tax ID' },
  { type: 'kr_brn', iso2: 'kr', name: 'South Korea BRN' },
  { type: 'kz_bin', iso2: 'kz', name: 'Kazakhstan BIN' },
  { type: 'li_uid', iso2: 'li', name: 'Liechtenstein UID' },
  { type: 'li_vat', iso2: 'li', name: 'Liechtenstein VAT' },
  { type: 'ma_vat', iso2: 'ma', name: 'Morocco VAT' },
  { type: 'md_vat', iso2: 'md', name: 'Moldova VAT' },
  { type: 'me_pib', iso2: 'me', name: 'Montenegro PIB' },
  { type: 'mk_vat', iso2: 'mk', name: 'North Macedonia VAT' },
  { type: 'mr_nif', iso2: 'mr', name: 'Mauritania NIF' },
  { type: 'mx_rfc', iso2: 'mx', name: 'Mexico RFC' },
  { type: 'my_frp', iso2: 'my', name: 'Malaysia FRP' },
  { type: 'my_itn', iso2: 'my', name: 'Malaysia ITN' },
  { type: 'my_sst', iso2: 'my', name: 'Malaysia SST' },
  { type: 'ng_tin', iso2: 'ng', name: 'Nigeria Tax ID' },
  { type: 'no_vat', iso2: 'no', name: 'Norway VAT' },
  { type: 'no_voec', iso2: 'no', name: 'Norway VOEC' },
  { type: 'np_pan', iso2: 'np', name: 'Nepal PAN' },
  { type: 'nz_gst', iso2: 'nz', name: 'New Zealand GST' },
  { type: 'om_vat', iso2: 'om', name: 'Oman VAT' },
  { type: 'pe_ruc', iso2: 'pe', name: 'Peru RUC' },
  { type: 'ph_tin', iso2: 'ph', name: 'Philippines Tax ID' },
  { type: 'ro_tin', iso2: 'ro', name: 'Romania Tax ID' },
  { type: 'rs_pib', iso2: 'rs', name: 'Serbia PIB' },
  { type: 'ru_inn', iso2: 'ru', name: 'Russia INN' },
  { type: 'ru_kpp', iso2: 'ru', name: 'Russia KPP' },
  { type: 'sa_vat', iso2: 'sa', name: 'Saudi Arabia VAT' },
  { type: 'sg_gst', iso2: 'sg', name: 'Singapore GST' },
  { type: 'sg_uen', iso2: 'sg', name: 'Singapore UEN' },
  { type: 'si_tin', iso2: 'si', name: 'Slovenia Tax ID' },
  { type: 'sn_ninea', iso2: 'sn', name: 'Senegal NINEA' },
  { type: 'sr_fin', iso2: 'sr', name: 'Suriname Tax ID' },
  { type: 'sv_nit', iso2: 'sv', name: 'El Salvador NIT' },
  { type: 'th_vat', iso2: 'th', name: 'Thailand VAT' },
  { type: 'tj_tin', iso2: 'tj', name: 'Tajikistan TIN' },
  { type: 'tr_tin', iso2: 'tr', name: 'Turkey Tax ID' },
  { type: 'tw_vat', iso2: 'tw', name: 'Taiwan VAT' },
  { type: 'tz_vat', iso2: 'tz', name: 'Tanzania VAT' },
  { type: 'ua_vat', iso2: 'ua', name: 'Ukraine VAT' },
  { type: 'ug_tin', iso2: 'ug', name: 'Uganda Tax ID' },
  { type: 'us_ein', iso2: 'us', name: 'United States EIN' },
  { type: 'uy_ruc', iso2: 'uy', name: 'Uruguay RUC' },
  { type: 'uz_tin', iso2: 'uz', name: 'Uzbekistan TIN' },
  { type: 'uz_vat', iso2: 'uz', name: 'Uzbekistan VAT' },
  { type: 've_rif', iso2: 've', name: 'Venezuela RIF' },
  { type: 'vn_tin', iso2: 'vn', name: 'Vietnam Tax ID' },
  { type: 'za_vat', iso2: 'za', name: 'South Africa VAT' },
  { type: 'zm_tin', iso2: 'zm', name: 'Zambia Tax ID' },
  { type: 'zw_tin', iso2: 'zw', name: 'Zimbabwe Tax ID' },
]

export default function EditBillingInfoModal({ stripeCustomer, onSuccess }) {
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [phone, setPhone] = useState('')
  const { user } = useAuth()

  const onSubmit = async (e) => {
    e.preventDefault()
    setIsSubmitting(true)

    const formData = new FormData(e.currentTarget)
    const data = Object.fromEntries(formData)

    // Prepare the billing info object
    const billing_info = {
      name: data.name,
      email: data.email,
      address: {
        line1: data.line1,
        line2: data.line2,
        city: data.city,
        state: data.state,
        postal_code: data.postal_code,
        country: data.country,
      },
    }

    if (phone?.length > 8) {
      billing_info.phone = phone
    }

    // Add tax_id if type and value exist
    if (data['tax_id.type'] && data['tax_id.value']) {
      billing_info.taxId = {
        type: data['tax_id.type'],
        value: data['tax_id.value'],
      }
    }

    try {
      await $fetch(`/billing/customer`, {
        method: 'PATCH',
        body: {
          billing_info,
        },
      })

      toast.success('Your billing info has been updated.')
      onSuccess()
      onClose()
    }
    catch (error) {
      console.error(error)
      toast.error('An error occurred while updating your billing info.')
    }
    finally {
      setIsSubmitting(false)
    }
  }
  return (
    <>
      <RadixButton
        variant="outline"
        color="gray"
        radius="large"
        type="button"
        onClick={onOpen}
      >
        <span className="text-black">Edit Information</span>
      </RadixButton>
      <Modal
        isOpen={isOpen}
        placement="top-center"
        onOpenChange={onOpenChange}
        classNames={{
          footer: 'w-full border-t border-gray-200',
          header: 'border-b border-gray-200 bg-[#18181b]',
          body: 'flex flex-col gap-5 w-full py-6',
        }}
        radius="sm"
        size="2xl"
      >
        <ModalContent>
          <Form validationBehavior="native" onSubmit={onSubmit} className="!gap-0">
            <ModalHeader className="flex flex-col gap-1">
              <h2 className="text-2xl font-taurus text-white font-semibold flex items-center gap-3">
                Edit Billing Information
              </h2>
              <p className="text-xs font-taurus font-light tracking-wider text-foreground-400">Your billing information will be displayed on your invoices from Bland (for credit top ups) and from Stripe (for subscription payments).</p>
            </ModalHeader>
            <ModalBody>
              <Input
                isRequired
                label="Company Name"
                placeholder="Bland AI"
                defaultValue={stripeCustomer?.name}
                variant="bordered"
                name="name"
                labelPlacement="outside"
                radius="sm"
              />
              <Input
                label="Email"
                placeholder={user?.user?.email}
                defaultValue={stripeCustomer?.email}
                variant="bordered"
                name="email"
                labelPlacement="outside"
                radius="sm"
                type="email"
              />

              <PhoneInput
                label="Phone Number"
                fullWidth
                value={phone}
                onChange={setPhone}
              />

              <Input
                label="Street Address"
                placeholder="292 Ivy St"
                defaultValue={stripeCustomer?.address?.line1}
                variant="bordered"
                name="line1"
                labelPlacement="outside"
                radius="sm"
              />
              <Input
                label="Address Line 2"
                placeholder="Optional"
                defaultValue={stripeCustomer?.address?.line2}
                variant="bordered"
                name="line2"
                labelPlacement="outside"
                radius="sm"
              />
              <span className="flex gap-4 justify-between">
                <Input
                  label="City"
                  placeholder="San Francisco"
                  defaultValue={stripeCustomer?.address?.city}
                  variant="bordered"
                  name="city"
                  labelPlacement="outside"
                  radius="sm"
                />
                <Input
                  label="State"
                  placeholder="CA"
                  defaultValue={stripeCustomer?.address?.state}
                  variant="bordered"
                  name="state"
                  labelPlacement="outside"
                  radius="sm"
                />
              </span>

              <span className="flex gap-4 justify-between">
                <Input
                  label="Zip Code"
                  placeholder="94102"
                  defaultValue={stripeCustomer?.address?.postal_code}
                  variant="bordered"
                  name="postal_code"
                  labelPlacement="outside"
                  radius="sm"
                />
                <Input
                  label="Country"
                  placeholder="US"
                  defaultValue={stripeCustomer?.address?.country}
                  variant="bordered"
                  name="country"
                  labelPlacement="outside"
                  radius="sm"
                />
              </span>

              <span className="flex gap-4 justify-between">
                <Select
                  label="Tax ID Type"
                  labelPlacement="outside"
                  variant="bordered"
                  radius="sm"
                  defaultSelectedKeys={stripeCustomer?.tax_id?.type ? [stripeCustomer?.tax_id?.type] : ['us_ein']}
                  name="tax_id.type"
                  placeholder="Select a tax ID type"
                >
                  {TAX_ID_TYPES.map(taxIdType => (
                    <SelectItem
                      key={taxIdType.type}
                      startContent={taxIdType.iso2 && <FlagImage iso2={taxIdType.iso2} />}
                      endContent={<p className="text-xs text-gray-500">{taxIdType.type}</p>}
                    >
                      {taxIdType.name}
                    </SelectItem>
                  ))}
                </Select>

                <Input
                  label="Tax ID"
                  placeholder="12-3456789"
                  defaultValue={stripeCustomer?.tax_id?.value}
                  variant="bordered"
                  name="tax_id.value"
                  labelPlacement="outside"
                  radius="sm"
                />
              </span>

            </ModalBody>
            <ModalFooter>
              <Button radius="sm" fullWidth className="w-full bg-[#18181b] text-white" type="submit" isLoading={isSubmitting}>
                Update
              </Button>
            </ModalFooter>
          </Form>
        </ModalContent>
      </Modal>
    </>
  )
}
