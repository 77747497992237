import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import Button from 'components/core/Button'
import Input from 'components/core/Input'
import config from 'config'
import { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { getOrgId } from 'utils/funcs/browser/getOrgId'
import LightTooltip from './ToolTipDesc'

export default function CustomDeployment({ fieldName, isPathway }) {
  const orgId = getOrgId()
  const { getValues, watch } = useFormContext()
  const [loading, setLoading] = useState()
  const url = watch(fieldName)

  const onTest = async () => {
    const url = getValues(fieldName)
    setLoading(true)
    try {
      const response = await fetch(`${url}/v1/log`, {
        method: 'GET',
        headers: {
          ...(orgId && { 'x-bland-org-id': orgId }),
        },
      })
      const success = response.ok
      if (success) {
        console.log({ response })
        toast.success('Connection Healthy!')
      }
      setLoading(false)
    }
    catch (err) {
      setLoading(false)
      console.error('Error connecting to custom deployment')
      toast(
        'Error testing connection to custom deployment. Please try again.',
        {
          type: 'error',
        },
      )
    }
  }
  const description = (
    <span>
      If you have dedicated infrastructure, you can change the url here to point
      to your own deployment.
    </span>
  )

  return (
    <Wrapper>
      <Input
        value={url}
        fieldName={fieldName}
        label={(
          <span>
            Custom Deployment
            {' '}
            <span className="italic text-[10px]">(For Enterprise)</span>
            <LightTooltip title={description} placement="right-start" arrow>
              <HelpOutlineIcon color="primary" style={{ paddingLeft: '4px' }} />
            </LightTooltip>
          </span>
        )}
        placeholder={`${config.API_URL}`}
      />
      {!isPathway && (
        <Button onClick={onTest} loading={loading}>
          Test Connection
        </Button>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 5px;
  align-items: end;
`
