import { CardTitle } from '@/components/ui/card'
import { DateRangePicker, Select, SelectItem, Tab, Tabs } from '@heroui/react'
import { getLocalTimeZone, today } from '@internationalized/date'
import { DataGrid } from '@mui/x-data-grid'
import { useDateFormatter } from '@react-aria/i18n'
import { Graticule, Mercator } from '@visx/geo'
import ActionBar from 'components/core/ActionBar'
import { PageTitle } from 'components/core/PageTitle'
import { PageWrapper } from 'components/core/PageWrapper'
import GradientLoadingAnimation from 'components/Reusables/GradientLoadingAnimation'
import config from 'config'
import { useAuth } from 'hooks/useAuth'
import { Database } from 'lucide-react'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  Bar,
  BarChart,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts'
import styled from 'styled-components'
import * as topojson from 'topojson-client'
import { $fetch } from 'utils/fetch'
import { getApiKey } from 'utils/funcs/browser/getApiKey'

import { getOrgId } from 'utils/funcs/browser/getOrgId'
import AnalysisPresetConfigurator from '../Analysis/AnalysisPresetConfigurator'

import PathwayHeatmap from '../ConvoPathways/HeatMap'
import RetoolDashboard from '../ConvoPathways/Hertz'
import areaCodeToLocation from '../Home/areaCodeData.json'
import usaTopology from '../Home/usa-topo.json'
import ReportPage from '../Report/MainReportPage'
import TotalCallsComponent from './BlandChart'
import { ErrorLogTable } from './components/ErrorLogsTable'
import { convertTimestampMMDDYYYY, getStartAndEndDate } from './utils/utils'

const width = 517
const height = 199

function Map(locationName) {
  const scale = (width + height) / 1.55
  const feats = []
  const inbound = []
  const latMin = 24.396308 // Southernmost point in the US
  const latMax = 49.384358 // Northernmost point in the US
  const longMin = -125.0 // Westernmost point in the US
  const longMax = -66.93457 // Easternmost point in the US

  // Function to generate random coordinates within the US bounding box
  const generateRandomUSCoordinates = () => {
    const latitude = Math.random() * (latMax - latMin) + latMin
    const longitude = Math.random() * (longMax - longMin) + longMin

    return { latitude, longitude }
  }

  // Convert latitude and longitude to x, y coordinates
  const convertLatLongToXY = (latitude, longitude) => {
    // Adjusted conversion to account for scale and translation
    const x = ((longitude - longMin) / (longMax - longMin)) * width
    const y = ((latMax - latitude) / (latMax - latMin)) * height
    return { x, y }
  }

  // Special data center at New York
  const nyCoordinates = convertLatLongToXY(32.7128, -102.006)
  const enterpriseCoordinates = convertLatLongToXY(35.7128, -95.006)

  return (
    <svg width={width} height={height}>
      <Mercator
        scale={width} // Zoom in by increasing the scale
        size={[width, height]} // Width and height of the SVG
        clipExtent={[
          [0, 0],
          [width, height],
        ]} // Clip all features to the width and height of the SVG
        translate={[width / 2 + 1050, height / 2 + 400]} // Shift map to right and bottom
        data={topojson
          .feature(usaTopology, usaTopology.objects.states)
          .features
          .filter(
            feature =>
              feature.id !== '02'
              && feature.id !== '15'
              && feature.id !== '60'
              && feature.id !== '66'
              && feature.id !== '72'
              && feature.id !== '69'
              && feature.id !== '78',
          )}
      >
        {mercator => (
          <g>
            {mercator.features.map(({ feature, path }, i) => (
              <path
                key={`map-feature-${i}`}
                d={path}
                fill="#DDD"
                stroke="#000"
                strokeWidth={0.5}
              />
            ))}
            <Graticule stroke="#E4E4E4" />

            <g style={{ zIndex: -1 }}>
              <rect
                x={nyCoordinates.x - 25}
                y={nyCoordinates.y - 25}
                width="65"
                height="12"
                fill="#FFF"
                stroke="#000"
                strokeWidth="0.5"
              />
              <text
                x={nyCoordinates.x - 20}
                y={nyCoordinates.y - 15}
                fontSize="10"
                fill="#000"
              >
                us-
                {locationName?.locationName ?? 'bland'}
                -1
              </text>
              <Database
                x={nyCoordinates.x - 10}
                y={nyCoordinates.y - 10}
                width={20}
                height={20}
                stroke="#000"
                fill="#FFF"
                strokeWidth={1}
              />
            </g>
            {/*
            {Array.from({ length: 5 }).map((_, i) => {
              const { latitude, longitude } = generateRandomUSCoordinates();
              const { x, y } = convertLatLongToXY(latitude, longitude);
              const radius = Math.random() * 5 + 2;
              feats.push({ x, y, radius });
              return (
                <circle
                  key={`marker-${i}`}
                  cx={x}
                  cy={y}
                  r={radius}
                  fill="rgb(96, 165, 250)"
                  style={{ zIndex: 1 }}
                />
              );
            })}

            {Array.from({ length: 2 }).map((_, i) => {
              const { latitude, longitude } = generateRandomUSCoordinates();
              const { x, y } = convertLatLongToXY(latitude, longitude);
              const radius = Math.random() * 5 + 2;
              inbound.push({ x, y, radius });
              return (
                <circle
                  key={`marker-${i}`}
                  cx={x}
                  cy={y}
                  r={radius}
                  fill="rgb(34, 197, 94)"
                />
              );
            })} */}

            {feats.map((feature) => {
              const endX = nyCoordinates.x
              const endY = nyCoordinates.y
              const startX = feature.x
              const startY = feature.y
              return (
                <path
                  key={`arc-${startX}-${endX}-${startY}-${endY}`}
                  d={`M${startX},${startY} Q${(startX + endX) / 2},${(startY + endY) / 2 - 50} ${endX},${endY}`}
                  fill="none"
                  stroke={`url(#fade-blue-${startX}-${endX}-${startY}-${endY})`}
                  strokeWidth={1}
                />
              )
            })}

            {inbound.map((feature) => {
              const endX = nyCoordinates.x
              const endY = nyCoordinates.y
              const startX = feature.x
              const startY = feature.y
              return (
                <path
                  key={`arc-${startX}-${endX}-${startY}-${endY}`}
                  d={`M${startX},${startY} Q${(startX + endX) / 2},${(startY + endY) / 2 - 50} ${endX},${endY}`}
                  fill="none"
                  stroke={`url(#fade-green-${startX}-${endX}-${startY}-${endY})`}
                  strokeWidth={1}
                />
              )
            })}

            <defs>
              {feats.map((feature) => {
                const endX = nyCoordinates.x
                const endY = nyCoordinates.y
                const startX = feature.x
                const startY = feature.y
                return (
                  <linearGradient
                    id={`fade-blue-${startX}-${endX}-${startY}-${endY}`}
                    key={`fade-blue-${startX}-${endX}-${startY}-${endY}`}
                  >
                    <stop offset="0%" stopColor="rgba(96, 165, 250, 0)" />
                    <stop offset="100%" stopColor="rgba(96, 165, 250, 0.5)" />
                  </linearGradient>
                )
              })}
              {inbound.map((feature) => {
                const endX = nyCoordinates.x
                const endY = nyCoordinates.y
                const startX = feature.x
                const startY = feature.y
                return (
                  <linearGradient
                    id={`fade-green-${startX}-${endX}-${startY}-${endY}`}
                    key={`fade-green-${startX}-${endX}-${startY}-${endY}`}
                  >
                    <stop offset="0%" stopColor="rgba(34, 197, 94, 0)" />
                    <stop offset="100%" stopColor="rgba(34, 197, 94, 0.5)" />
                  </linearGradient>
                )
              })}
            </defs>
          </g>
        )}
      </Mercator>
    </svg>
  )
}

// Enough with the imports—let's do some analytics.
const ANALYTICS_URI = `${config.API_URL}/api/analytics`

/**
 * Formats a float representation of minutes into 'mm:ss' or just 'ss' if < 1 minute.
 * Because nobody wants to do that mental math themselves.
 */
function formatMinutesToMMSS(minutes) {
  const totalSeconds = Math.floor(minutes * 60)
  const mins = Math.floor(totalSeconds / 60)
  const secs = totalSeconds % 60
  const formattedSecs = secs.toString().padStart(2, '0')
  return mins === 0 ? `${formattedSecs}s` : `${mins}m ${formattedSecs}s`
}

const GROUPINGS = [
  { label: 'Hour', value: 'hour' },
  { label: 'Day', value: 'day' },
  { label: 'Week', value: 'week' },
  { label: 'Month', value: 'month' },
]

/**
 * This helper hook plucks query params out of the URL like a polite rummager.
 */
function useQuery() {
  return new URLSearchParams(useLocation().search)
}

function CustomLabel(props) {
  const { viewBox } = props
  return (
    <foreignObject
      className="label-wrapper"
      x={viewBox.width - viewBox.x * 4} // Adjust x position to move to the right
      y={viewBox.y - 8} // Adjust y position to move to the top
      width={27}
      height={16}
    >
      <div
        xmlns="http://www.w3.org/1999/xhtml"
        style={{
          background: '#E5E7EB',
          borderRadius: '2px',
          color: '#111',
          fontSize: '10px',
          width: '27px',
          height: '16px',
          textAlign: 'center',
        }}
      >
        AVG
      </div>
    </foreignObject>
  )
}

export default function Analytics() {
  /**
   * State management: a veritable rainbow of booleans, arrays, and objects.
   */
  const [mainLoading, setMainLoading] = useState(true)
  const [placementGroup, setPlacementGroup] = useState('')
  const { org } = useAuth()
  const [retoolValue, setRetoolValue] = useState(null)
  const [SIDETABS, setSIDETABS] = useState([
    { label: 'Calls', value: 'calls' },
    { label: 'Pathways', value: 'pathway_analytics' },
    { label: 'Presets', value: 'analysis_presets' },
    { label: 'Report', value: 'report' },
  ])
  const [selected, setSelected] = useState(SIDETABS[0])

  const refreshUserData = async () => {
    const newPlacementGroup = org?.placement_group
    setPlacementGroup(newPlacementGroup)
    const avaliableDashboards = await $fetch(`/dashboards/list`)
    setSIDETABS(prev => [
      ...[
        { label: 'Calls', value: 'calls' },
        { label: 'Pathways', value: 'pathway_analytics' },
        { label: 'Presets', value: 'analysis_presets' },
        { label: 'Report', value: 'report' },
      ],
      ...(avaliableDashboards?.data || []).map(doc => ({
        label: doc.label,
        value: `${doc.value}`,
      })),
    ])
  }

  useEffect(() => {
    refreshUserData()
  }, [])
  const [tableData, setTableData] = useState([])
  const [rows, setRows] = useState([])

  useEffect(() => {
    const updatedRows = tableData.map((doc, ind) => {
      if (doc.Analysis) {
        doc.Analysis = JSON.stringify(doc.Analysis)
      }
      return { ...doc, id: ind + 1 }
    })
    setRows(updatedRows)
  }, [tableData])

  const [latency, setLatency] = useState([])
  const [flatMetics, setFlatMetric] = useState({
    unknown_count: null,
    voicemail_count: null,
    transferred_count: null,
    answered_count: null,
    avg_call_durr: null,
    call_cost: null,
  })
  const [areaCodeData, setAreaCodeData] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [mapData] = await Promise.all([
          $fetch(`/analytics/last_seven_map`, {}, true),
        ])

        // calls for charts

        // area code data
        if (mapData.data) {
          setAreaCodeData(mapData.data)
        }
      }
      catch (err) {
        console.error('Error fetching data:', err)
      }
    }
    fetchData()
  }, [])

  const expandCallsIntoCities = (areaCodeData) => {
    const cityCounts = {}

    areaCodeData.forEach(({ areaCode, count }) => {
      const loc = areaCodeToLocation[areaCode]
      if (!loc)
        return

      if (!cityCounts[loc.city]) {
        cityCounts[loc.city] = 0
      }

      cityCounts[loc.city] += count
    })

    return cityCounts
  }

  // Our collection of chart configs. Each one gets data from the same analytics call.
  const [charts, setCharts] = useState([
    { name: 'calls', label: 'Calls', chartData: [] },
    { name: 'cost', label: 'Credit Spend', chartData: [] },
    { name: 'call_length_sum', label: 'Call Duration', chartData: [] },
    { name: 'call_length_avg', label: 'Avg. Call Duration', chartData: [] },
    { name: 'total_latency', label: 'Average Call Duration', chartData: [] },
    {
      name: 'performance_metrics',
      label: 'Performance Metrics',
      chartData: [],
    },
    { name: 'error_details', label: 'Error Logs', chartData: [] },
  ])

  // Because we're all about that dynamic user experience:
  const location = useLocation()
  const query = useQuery()
  const navigate = useNavigate()

  // Grab the call_id if it's in the query params
  const qpCallId = query.get('call_id') || null

  // Let's not forget our credentials for the sweet, sweet data calls
  const apiKey = getApiKey()
  const orgId = getOrgId()

  // For storing combined data across multiple metrics
  const [allChartData, setAllChartData] = useState([])

  const [range, setRange] = useState({
    start: today(getLocalTimeZone()).subtract({ days: 7 }),
    end: today(getLocalTimeZone()),
  })

  const [grouping, setGrouping] = useState('day')

  const formatter = useDateFormatter({ dateStyle: 'long' })

  /**
   * Generate the array of queries sent to the analytics endpoint.
   * It's a puzzle: each item is one metric. Because universal endpoints love arrays.
   */
  const get_queries = () => {
    const { start, end } = {
      start: (range.start?.toDate(getLocalTimeZone()).getTime() / 1000).toString(),
      end: (range.end?.toDate(getLocalTimeZone()).getTime() / 1000 + 86399).toString(),
    }

    // If we need more queries, just add 'em here. No mocking though—per the boss's orders.
    return [
      {
        start_ts: start,
        end_ts: end,
        grouping,
        metric: 'calls',
        count: false,
      },
      {
        start_ts: start,
        end_ts: end,
        grouping,
        metric: 'call_data',
        count: false,
      },
      {
        start_ts: start,
        end_ts: end,
        grouping,
        metric: 'call_length_sum',
        count: false,
      },
      {
        start_ts: start,
        end_ts: end,
        grouping,
        metric: 'cost',
        count: false,
      },
      // {
      //   start_ts: range.start?.toString() || startDate,
      //   end_ts: range.end?.toString() || endDate,
      //   grouping: grouping,
      //   metric: "call_length_avg",
      //   count: false,
      // },
      {
        start_ts: start,
        end_ts: end,
        grouping: 'day',
        metric: 'answered_count',
        count: false,
      },
      {
        start_ts: start,
        end_ts: end,
        grouping: 'day',
        metric: 'transferred_count',
        count: false,
      },
      {
        start_ts: start,
        end_ts: end,
        grouping,
        metric: 'transferred_count_by_day',
        count: false,
      },
      {
        start_ts: start,
        end_ts: end,
        grouping,
        metric: 'voicemail_count_by_day',
        count: false,
      },
      {
        start_ts: start,
        end_ts: end,
        grouping,
        metric: 'unknown_count_by_day',
        count: false,
      },
      {
        start_ts: start,
        end_ts: end,
        grouping,
        metric: 'answered_count_by_day',
        count: false,
      },
      {
        start_ts: start,
        end_ts: end,
        grouping: 'day',
        metric: 'voicemail_count',
        count: false,
      },
      {
        start_ts: start,
        end_ts: end,
        grouping: 'day',
        metric: 'unknown_count',
        count: false,
      },
      {
        start_ts: start,
        end_ts: end,
        grouping: 'day',
        metric: 'avg_call_duration',
        count: false,
      },
      {
        start_ts: start,
        end_ts: end,
        grouping,
        metric: 'performance_metrics',
        count: false,
      },
      {
        start_ts: start,
        end_ts: end,
        grouping: 'day',
        metric: 'error_details',
        count: false,
      },
    ]
  }

  /**
   * Takes the data from our query response, merges it with our charts state,
   * and feeds the final metrics to allChartData, plus the top-level "flatMetics".
   */
  const post_processing = (data) => {
    let updatedCharts = charts
    let allData = []

    // If there's an error, the toast shall ring!
    const failedMetric = data.find(metric => metric.error)
    if (failedMetric) {
      toast.error(failedMetric.error)
    }

    // Temporary aggregator for "flat" metrics: answered_count, etc.
    const fm = {
      ac: null,
      vc: null,
      tc: null,
      cd: null,
      uc: null,
    }

    const calls = []

    // Gobble up each metric to fill out fm or updated charts
    for (const obj of data) {
      switch (obj.metric) {
        case 'answered_count':
          fm.ac = obj?.data?.[0].value || 0
          break
        case 'voicemail_count':
          fm.vc = obj?.data?.[0].value || 0
          break
        case 'transferred_count':
          fm.tc = obj?.data?.[0].value || 0
          break
        case 'avg_call_duration':
          fm.cd = obj?.data?.[0].value || 0
          break
        case 'unknown_count':
          fm.uc = obj?.data?.[0].value || 0
          break
        case 'call_data':
          if (Array.isArray(obj?.data)) {
            for (const item of obj?.data) {
              if (Array.isArray(item?.value)) {
                for (const call of item?.value) {
                  calls.push(call)
                }
              }
            }
          }
        default:
          break
      }

      // Let's nudge the right data into the right chart object
      updatedCharts = updatedCharts.map((chart) => {
        if (chart.name === obj.metric) {
          return {
            ...chart,
            chartData: obj?.data?.map(item => ({
              ...item,
              // Convert to local time zone, because we just do not trust that UTC all day long
              period: new Date(item.period).toLocaleString('en-US', {
                timeZone: 'UTC',
              }),
            })),
          }
        }
        return chart
      })

      // For "calls", "cost", and "call_length_sum", let's unify that data into one object by date/time
      const combinedData = {}
      for (const subObj of data) {
        if (
          [
            'calls',
            'cost',
            'call_length_sum',
            'unknown_count_by_day',
            'voicemail_count_by_day',
            'transferred_count_by_day',
            'answered_count_by_day',
          ].includes(subObj.metric)
        ) {
          for (const item of subObj.data) {
            const period = new Date(item.period).toLocaleString('en-US', {
              timeZone: 'UTC',
            })
            if (!combinedData[period]) {
              combinedData[period] = { period }
            }
            combinedData[period][subObj.metric] = item.value
          }
        }
      }

      // Clear allData each time so we don't double up. The final loop below repopulates it fresh
      allData = []
      for (const period in combinedData) {
        allData.push(combinedData[period])
      }
    }

    console.log(calls)
    setTableData(calls)

    // Store the updated chart data in state
    setCharts(updatedCharts)

    // If we got any combined data, hoard it in allChartData
    if (allData.length > 0) {
      setAllChartData(allData)
      console.log(allData)
    }

    // Store those "flat" metrics that feed the top summary row
    setFlatMetric({
      answered_count: fm.ac,
      voicemail_count: fm.vc,
      transferred_count: fm.tc,
      avg_call_durr: fm.cd,
      unknown_count: fm.uc,
      call_cost: '?',
    })
  }

  /**
   * A quick function for returning the percentage of a value vs total calls.
   * Because everyone loves hearing "X% of calls are Y".
   */
  const getPercentage = (value) => {
    const total = charts
      ?.find(doc => doc.name === 'calls')
      ?.chartData
      ?.reduce((acc, curr) => acc + curr.value, 0)
    if (!total || total === 0)
      return '-'
    return ((value / total) * 100)?.toFixed(0) || '-'
  }

  /**
   * Our primary analytics loader. Hits the analytics endpoint with queries,
   * then hands off the response to our post_processing method.
   */
  const load_analytics = async () => {
    try {
      setMainLoading(true)
      const response = await fetch(ANALYTICS_URI, {
        method: 'POST',
        headers: {
          'authorization': apiKey,
          'content-type': 'application/json',
          ...(orgId && { 'x-bland-org-id': orgId }),
        },
        body: JSON.stringify({ queries: get_queries() ?? [] }),
      })

      if (response.ok && response.status === 200) {
        const json = await response.json()
        const resData = json.data

        if (json?.status === 'success' && resData) {
          post_processing(resData)
        }
      }
    }
    finally {
      setTimeout(() => {
        setMainLoading(false)
      }, 1000)
    }
  }

  /**
   * The "latency" tab's data loader, because we all want speedy calls. Right?
   */
  const load_latency = async () => {
    try {
      const response = await $fetch(`/v1/infra/latency`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      })
      if (!response.errors) {
        const json = await response.data
        if (json?.latency && json?.latency?.length > 0) {
          setLatency(json.latency)
        }
      }
    }
    catch (error) {
      // No error toast for you. Maybe we should be more dramatic?
    }
  }

  // On component load, let's fetch latency data immediately. People love data right away!
  useEffect(() => {
    load_latency()
  }, [])

  // If the user changes the date range, let's go fetch updated analytics.
  // Because stale data is so 2020.
  useEffect(() => {
    load_analytics()
  }, [range.end, range.start, grouping])

  /**
   * Where we decode the "tab" query param and set the relevant state.
   * Because multi-tab apps do so love to store their current state in the URL.
   */
  const queryParamHandler = () => {
    const tab = query.get('tab')
    switch (tab) {
      case 'realtime_logs':
        setSelected({ label: 'Realtime Logs', value: 'realtime_logs' })
        break
      case 'latency':
        setSelected({ label: 'Latency', value: 'latency' })
        break
      case 'calls':
        setSelected({ label: 'Call Analytics', value: 'calls' })
        break
      case 'live_calls':
        setSelected({ label: 'Live Calls', value: 'live_calls' })
        break
      case 'pathway_analytics':
        setSelected({ label: 'Pathway Analytics', value: 'pathway_analytics' })
        break
      case 'analysis_presets':
        setSelected({ label: 'Analysis Presets', value: 'analysis_presets' })
        break
      case 'hertz':
        setSelected({ label: 'Hertz Call Metrics', value: 'hertz' })
        break
      case 'report':
        setSelected({ label: 'Report', value: 'report' })
        break
      default:
    }
  }

  const updateQueryParam = (newTabValue) => {
    if (
      newTabValue === 'calls'
      && charts?.flatMap(doc => doc?.chartData)?.length === 0
    ) {
      load_analytics()
    }

    if (newTabValue.startsWith('dashboard-')) {
      setRetoolValue(newTabValue)
      const params = new URLSearchParams(location.search)
      params.set('tab', 'retool')
      navigate({ search: params.toString() })
      return
    }

    const params = new URLSearchParams(location.search)
    params.set('tab', newTabValue)
    navigate({ search: params.toString() })
  }

  // On route change, decipher the new query params and update our selected tab accordingly
  useEffect(() => {
    queryParamHandler()
  }, [location.search])

  return (
    <PageWrapper>
      <ActionBar top>
        <PageTitle>Analytics</PageTitle>
        <Tabs
          selectedKey={selected.value}
          aria-label="Tabs"
          onSelectionChange={(value) => {
            updateQueryParam(value)
            setSelected(SIDETABS?.find(doc => doc.value === value))
          }}
          classNames={{
            base: 'absolute top-2 inset-x-0 w-full flex justify-center',
          }}
          variant="light"
        >
          {SIDETABS?.map(doc => (
            <Tab
              id={doc.value}
              key={doc.value}
              title={doc.label}
            />
          ))}
        </Tabs>

      </ActionBar>
      {/* <Sidebar
        sections={[
          {
            label: "Built-in Insights",
            options: SIDETABS?.filter((doc) => !doc.value.includes("expanded")),
          },
          {
            label: "Advanced Reporting",
            options: ADVANCED_TABS,
          },
        ]}
        onChange={(value) => {
          const val =
            SIDETABS?.find((doc) => doc.value === value) ||
            ADVANCED_TABS?.find((doc) => doc.value === value);
          updateQueryParam(val.value);
          setSelected(val);
        }}
        selected={selected.value}
      /> */}

      <Wrapper>
        {/* <PageTitle style={{ fontWeight: 550, fontSize: 22 }}>
          {selected.label}
        </PageTitle> */}
        <div
          style={{
            width: '100%',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            gap: '24px',
            display: 'inline-flex',
          }}
        >

          {selected.value === 'calls' && (
            <>
              {mainLoading
                ? (
                    <div className="flex justify-center items-center h-screen w-full pb-24">
                      <GradientLoadingAnimation message="Loading call analytics..." />
                    </div>
                  )
                : (
                    <>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'flex-start',
                          gap: '-1px',
                          alignSelf: 'stretch',
                        }}
                      >
                        <div className="flex gap-2 mb-4">
                          <DateRangePicker
                            label="Date Range"
                            size="sm"
                            placeholder="Select a date range"
                            maxValue={today(getLocalTimeZone())}
                            validate={(value) => {
                              const start = value.start.toDate()
                              const end = value.end.toDate()
                              const diffDays = (end - start) / (1000 * 60 * 60 * 24)
                              if (diffDays > 29) {
                                return 'Date range cannot exceed 30 days'
                              }
                              return null
                            }}
                            value={range}
                            onChange={setRange}
                            variant="underlined"
                          />
                          <Select
                            size="sm"
                            selectedKeys={[grouping]}
                            defaultSelectedKeys={['day']}
                            label="Group By"
                            onChange={(e) => {
                              setGrouping(e.target.value)
                            }}
                            className="shrink-0 w-28"
                            variant="underlined"
                          >
                            {GROUPINGS.map(grouping => <SelectItem key={grouping.value}>{grouping.label}</SelectItem>)}
                          </Select>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            height: '260px',
                            border: '1px solid #E5E7EB',
                            alignItems: 'center',
                            alignSelf: 'stretch',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              padding: '16px',
                              height: '260px',
                              width: '125%',
                              alignItems: 'center',
                              gap: '18px',
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                alignItems: 'flex-end',
                                width: '125%',
                                gap: '70px',
                                height: '100%',
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  // width: "210px",
                                  flexDirection: 'column',
                                  justifyContent: 'space-between',
                                  alignItems: 'flex-start',
                                  alignSelf: 'stretch',
                                }}
                              >
                                <div>
                                  <div>
                                    <div
                                      style={{
                                        fontSize: '10px',
                                        fontStyle: 'normal',
                                        fontWeight: 700,
                                        lineHeight: '20px',
                                        letterSpacing: '0.8px',
                                        textTransform: 'uppercase',
                                      }}
                                    >
                                      Total Calls
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      color: '#4B5563',
                                      fontFamily: '"Geist Mono"',
                                      fontSize: '32px',
                                      fontStyle: 'normal',
                                      fontWeight: 600,
                                      lineHeight: '100%' /* 32px */,
                                    }}
                                  >
                                    {charts
                                      ?.find(doc => doc.name === 'calls')
                                      ?.chartData
                                      ?.reduce(
                                        (acc, curr) => acc + curr.value,
                                        0,
                                      )}
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: 'flex',
                                    width: '148px',
                                    alignItems: 'center',
                                    gap: '7.519px',
                                  }}
                                >
                                  {/* <div>
                          <div
                            style={{
                              color: "var(--Primary-Green, #213555)",
                              fontFamily: '"Geist Mono"',
                              fontSize: "14px",
                              fontStyle: "normal",
                              fontWeight: 600,
                              lineHeight: "15.038px",
                            }}
                          >
                            -%
                          </div>
                        </div> */}
                                  {/* <div
                          style={{
                            color:
                              "var(--Light-Foregrounds-fg-subtle, #4B5563)",
                            fontFamily: '"Geist Mono"',
                            fontSize: "10px",
                            fontStyle: "normal",
                            fontWeight: 700,
                            lineHeight: "20px",
                            letterSpacing: "0.8px",
                            textTransform: "uppercase",
                          }}
                        >
                          vs{" "}
                          {Math.floor(
                            Math.abs(
                              (new Date(range.end) - new Date(range.start)) /
                                (60 * 60 * 24),
                            ),
                          )}{" "}
                          days ago
                        </div> */}
                                </div>
                              </div>
                              <ResponsiveContainer width="100%" height={166}>
                                <BarChart
                                  barGap={14}
                                  barSize={40}
                                  data={allChartData.map(entry => ({
                                    ...entry,
                                    // overage:
                                    //   allChartData.reduce(
                                    //     (acc, curr) => acc + curr.calls,
                                    //     0,
                                    //   ) / allChartData.length,
                                    static:
                            Math.max(
                              ...allChartData.map(entry => entry.calls),
                            ) * 0.1,
                                  }))}
                                  className="font-geist-mono text-[10px]"
                                >
                                  <XAxis
                                    axisLine={{ stroke: '#9ca3af' }}
                                    dataKey="period"
                                    fill="#9ca3af"
                                    stroke="#9ca3af"
                                    tickLine={false}
                                    tickFormatter={(value, index) => {
                                      const date = new Date(value)
                                      if (grouping === 'hour') {
                                        return date.toLocaleString('en-US', {
                                          month: 'short',
                                          day: 'numeric',
                                          hour: 'numeric',
                                        })
                                      }
                                      else {
                                        return date.toLocaleDateString('en-US', {
                                          month: 'short',
                                          day: 'numeric',
                                        })
                                      }
                                    }}
                                    interval="preserveStartEnd" // Ensures ticks are responsive and do not overlap
                                    ticks={[
                                      ...new Set(
                                        allChartData?.flatMap(item => item.period),
                                      ),
                                    ]} // Ensure ticks are unique and reset on rerenders
                                  />
                                  <YAxis
                                    fill="#9ca3af"
                                    orientation="right"
                                    axisLine={false}
                                    stroke="#9ca3af"
                                    tickLine={false}
                                    tickFormatter={value =>
                                      value > 1000 ? `${value / 1000}k` : value}
                                  />
                                  <Bar
                                    dataKey="static"
                                    fill="transparent"
                                    stackId="a"
                                    isAnimationActive={false}
                                  />
                                  <Bar
                                    dataKey="calls"
                                    fill="#3E5879"
                                    stackId="a"
                                    style={{ paddingBottom: '10px' }}
                                  />

                                  <Bar
                                    dataKey="overage"
                                    fill="#A4B8CC"
                                    stackId="a"
                                    style={{ paddingBottom: '10px' }}
                                  />
                                  <ReferenceLine
                                    strokeDasharray="5 5"
                                    strokeOpacity={1}
                                    alwaysShow
                                    y={
                                      allChartData.reduce(
                                        (acc, curr) => acc + curr.calls,
                                        0,
                                      ) / allChartData.length
                                    }
                                    label={CustomLabel}
                                    stroke="#000"
                                  />
                                </BarChart>
                              </ResponsiveContainer>
                            </div>
                          </div>

                          <div
                            style={{
                              position: 'relative',
                              width: '100%',
                              height: '100%',
                              // marginTop: "30px",
                              // marginBottom: "30px",
                            }}
                          >
                            <div
                              className="border"
                              style={{
                                width: '100%',
                                height: '100%',
                              }}
                            >
                              <Map locationName={placementGroup} />
                            </div>
                            <div
                              style={{
                                position: 'absolute',
                                bottom: 0,
                                left: 0,
                                width: '100%',
                                height: '60px',
                                border: '1px solid #E5E7EB',
                                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  width: '100%',
                                  padding: '6px',
                                  flexDirection: 'column',
                                  alignItems: 'flex-start',
                                  gap: '6px',
                                }}
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    alignSelf: 'stretch',
                                  }}
                                >
                                  <p>TOP LOCATIONS</p>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                  >
                                    <path
                                      d="M13.3385 5.35396L9.34628 1.36169H4.02326C3.67032 1.36169 3.33184 1.5019 3.08227 1.75146C2.83271 2.00103 2.6925 2.33951 2.6925 2.69245V13.3385C2.6925 13.6914 2.83271 14.0299 3.08227 14.2795C3.33184 14.529 3.67032 14.6692 4.02326 14.6692H12.0078C12.3607 14.6692 12.6992 14.529 12.9488 14.2795C13.1983 14.0299 13.3385 13.6914 13.3385 13.3385V5.35396ZM6.01939 12.6731H4.68864V6.68471H6.01939V12.6731ZM8.6809 12.6731H7.35015V8.68084H8.6809V12.6731ZM11.3424 12.6731H10.0117V10.677H11.3424V12.6731ZM9.34628 6.01933H8.6809V2.69245L12.0078 6.01933H9.34628Z"
                                      fill="#9CA3AF"
                                    />
                                  </svg>
                                </div>
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'flex-start',
                                    gap: '11.977px',
                                    alignSelf: 'stretch',
                                  }}
                                >
                                  {Object.entries(expandCallsIntoCities(areaCodeData))
                                    .sort((a, b) => b[1] - a[1])
                                    .slice(0, 3)
                                    .map(([location, count], index) => {
                                      const percentage = Math.round(
                                        (count
                                          / areaCodeData.reduce(
                                            (acc, curr) => acc + curr.count,
                                            0,
                                          ))
                                          * 100,
                                      )
                                      const colors = ['#60A5FA', '#FA9360', '#60C7FA']
                                      return (
                                        <div
                                          key={index}
                                          style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'flex-start',
                                            width: '100%',
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: 'flex',
                                              justifyContent: 'space-between',
                                              alignItems: 'center',
                                              width: '100%',
                                            }}
                                          >
                                            <p style={{ margin: 0 }}>{location}</p>
                                            <p style={{ margin: 0 }}>
                                              {percentage}
                                              %
                                            </p>
                                          </div>
                                          <div
                                            style={{
                                              width: '100%',
                                              height: '4px',
                                              backgroundColor: '#E5E7EB',
                                              borderRadius: '4px',
                                            }}
                                          >
                                            <div
                                              style={{
                                                width: `${percentage}%`,
                                                height: '100%',
                                                backgroundColor:
                                        colors[index % colors.length],
                                              }}
                                            >
                                            </div>
                                          </div>
                                        </div>
                                      )
                                    })}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div background="grey"></div>
                        </div>

                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            gap: '0px',
                            alignSelf: 'stretch',
                          }}
                        >
                          <TotalCallsComponent
                            totalCalls={flatMetics?.answered_count}
                            type="pie"
                            data={[
                              {
                                name: 'Answered',
                                calls: flatMetics?.answered_count,
                              },
                              {
                                name: 'Voicemail',
                                calls: flatMetics?.voicemail_count,
                              },
                              {
                                name: 'Unknown',
                                calls: flatMetics?.unknown_count,
                              },
                            ]}
                            title="Call Pickups"
                          />
                          <TotalCallsComponent
                            totalCalls={
                              flatMetics?.voicemail_count + flatMetics?.unknown_count
                            }
                            color="secondary"
                            data={allChartData.map(entry => ({
                              ...entry,
                              calls: entry.unknown_count_by_day,
                              voicemail: entry.voicemail_count_by_day,
                              name:
                      grouping === 'hour'
                        ? new Date(entry.period).toLocaleString('en-US', {
                            month: 'short',
                            day: 'numeric',
                            hour: 'numeric',
                          })
                        : new Date(entry.period).toLocaleDateString('en-US', {
                            month: 'short',
                            day: 'numeric',
                          }),
                              static:
                      Math.max(
                        ...allChartData.map(
                          entry =>
                            entry.voicemail_count_by_day
                            + entry.unknown_count_by_day,
                        ),
                      ) * 0.1,
                            }))}
                            title="Voicemail and Unknown"
                          />
                          <TotalCallsComponent
                            totalCalls={flatMetics?.answered_count}
                            color="primary"
                            data={allChartData.map(entry => ({
                              ...entry,
                              calls: entry.answered_count_by_day,
                              name:
                      grouping === 'hour'
                        ? new Date(entry.period).toLocaleString('en-US', {
                            month: 'short',
                            day: 'numeric',
                            hour: 'numeric',
                          })
                        : new Date(entry.period).toLocaleDateString('en-US', {
                            month: 'short',
                            day: 'numeric',
                          }),
                              static:
                      Math.max(
                        ...allChartData.map(
                          entry => entry.answered_count_by_day,
                        ),
                      ) * 0.1,
                            }))}
                            title="Answered"
                          />
                        </div>

                        {/* seocnd section */}

                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            gap: '0px',
                            alignSelf: 'stretch',
                          }}
                        >
                          <TotalCallsComponent
                            totalCalls={flatMetics?.transferred_count}
                            data={allChartData.map(entry => ({
                              ...entry,
                              calls: entry.transferred_count_by_day,
                              name:
                      grouping === 'hour'
                        ? new Date(entry.period).toLocaleString('en-US', {
                            month: 'short',
                            day: 'numeric',
                            hour: 'numeric',
                          })
                        : new Date(entry.period).toLocaleDateString('en-US', {
                            month: 'short',
                            day: 'numeric',
                          }),
                              static:
                      Math.max(
                        ...allChartData.map(
                          entry => entry.transferred_count_by_day,
                        ),
                      ) * 0.1,
                            }))}
                            title="Transferred"
                          />
                          <TotalCallsComponent
                            totalCalls={
                              flatMetics?.avg_call_durr
                                ? `${Math.floor(flatMetics.avg_call_durr)}m ${Math.round(
                                  (flatMetics.avg_call_durr % 1) * 60,
                                )}s`
                                : null
                            }
                            color="secondary"
                            data={allChartData.map(entry => ({
                              ...entry,
                              calls: entry.call_length_sum?.toFixed(2),
                              name:
                      grouping === 'hour'
                        ? new Date(entry.period).toLocaleString('en-US', {
                            month: 'short',
                            day: 'numeric',
                            hour: 'numeric',
                          })
                        : new Date(entry.period).toLocaleDateString('en-US', {
                            month: 'short',
                            day: 'numeric',
                          }),
                              static:
                      Math.max(
                        ...allChartData.map(entry => entry.call_length_sum),
                      ) * 0.1,
                            }))}
                            title="Avg Call Duration"
                          />
                          <TotalCallsComponent
                            totalCalls={allChartData.reduce(
                              (acc, entry) => acc + (entry.cost || 0),
                              0,
                            )}
                            type="line"
                            blur={allChartData.length === 1}
                            data={allChartData.map((entry, index) => {
                              const previousEntry = allChartData[index - 1] || {}
                              const rateOfChange = previousEntry.cost
                                ? Math.min(
                                    (entry.cost - previousEntry.cost)
                                    / previousEntry.cost,
                                    1,
                                  )
                                : 0
                              return {
                                ...entry,
                                callPrice: entry.cost?.toFixed(2),
                                averagePrice: (entry.cost / entry.calls)?.toFixed(2),
                                rateOfChange: rateOfChange?.toFixed(2),
                                name:
                        grouping === 'hour'
                          ? new Date(entry.period).toLocaleString('en-US', {
                              month: 'short',
                              day: 'numeric',
                              hour: 'numeric',
                            })
                          : new Date(entry.period).toLocaleDateString('en-US', {
                              month: 'short',
                              day: 'numeric',
                            }),
                              }
                            })}
                            title="Call Cost"
                          />
                        </div>
                      </div>

                      <DataGrid
                        style={{
                          borderTopLeftRadius: 0,
                          borderTopRightRadius: 0,
                          width: '100%',
                        }}
                        rows={rows}
                        columns={[
                          {
                            field: 'Call ID',
                            headerName: 'Call ID',
                            sortable: true,
                            filter: false,
                            width: 230,
                            disableColumnMenu: true,
                          },
                          {
                            field: 'To',
                            headerName: 'To',
                            sortable: false,
                            filter: true,
                            width: 130,
                            disableColumnMenu: true,
                          },
                          {
                            field: 'From',
                            headerName: 'From',
                            sortable: false,
                            filter: true,
                            width: 130,
                            disableColumnMenu: true,
                          },
                          {
                            field: 'Duration',
                            headerName: 'Call Length',
                            sortable: true,
                            filter: false,
                            width: 95,
                            disableColumnMenu: true,
                          },
                          {
                            field: 'Cost',
                            headerName: 'Cost',
                            sortable: true,
                            filter: false,
                            width: 80,
                            disableColumnMenu: true,
                          },
                          {
                            field: 'Created At',
                            headerName: 'Created At',
                            sortable: true,
                            filter: true,
                            width: 140,
                            disableColumnMenu: true,
                            renderCell: (params) => {
                              return new Date(params.value).toLocaleString('en-US', {
                                month: 'short',
                                day: 'numeric',
                                year: 'numeric',
                                hour: 'numeric',
                                minute: 'numeric',
                              })
                            },
                          },
                          {
                            field: 'Completed',
                            headerName: 'Completed',
                            sortable: true,
                            filter: true,
                            width: 120,
                            disableColumnMenu: true,
                            renderCell: (params) => {
                              return params.value ? 'True' : 'False'
                            },
                          },
                        ]}
                        sx={{
                          '& .MuiCheckbox-root svg': {
                            width: 12,
                            height: 12,
                            backgroundColor: 'transparent',
                            border: `1px solid #d9d9d9`,
                            borderRadius: 0.75,
                          },
                          '& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus':
                    {
                      outline: 'none !important',
                    },
                          '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus':
                    {
                      outline: 'none !important',
                      background: 'transparent !important',
                    },
                          '& .MuiCheckbox-root svg path': {
                            display: 'none',
                          },
                          '& .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg':
                    {
                      backgroundColor: '#1890ff',
                      borderColor: '#1890ff',
                    },
                          '& .MuiCheckbox-root.Mui-checked .MuiIconButton-label:after':
                    {
                      position: 'absolute',
                      display: 'table',
                      border: '2px solid #fff',
                      borderTop: 0,
                      borderLeft: 0,
                      transform: 'rotate(45deg) translate(-50%,-50%)',
                      opacity: 1,
                      transition: 'all .2s cubic-bezier(.12,.4,.29,1.46) .1s',
                      content: '""',
                      top: '50%',
                      left: '39%',
                      width: 5.71428571,
                      height: 9.14285714,
                    },
                          '& .MuiCheckbox-root.MuiCheckbox-indeterminate .MuiIconButton-label:after':
                    {
                      width: 8,
                      height: 8,
                      backgroundColor: '#1890ff',
                      transform: 'none',
                      top: '39%',
                      border: 0,
                    },
                          'letterSpacing': 'normal',
                          '& .MuiDataGrid-columnsContainer': {
                            backgroundColor: '#fafafa',
                          },
                          '& .MuiDataGrid-iconSeparator': {
                            display: 'none',
                          },
                          '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
                            borderRight: `1px solid #f0f0f0`,
                          },
                          '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
                            borderBottom: `1px solid #f0f0f0`,
                          },
                          '& .MuiPaginationItem-root': {
                            borderRadius: 0,
                          },
                          '& .MuiDataGrid-columnHeaderTitle': {
                            fontWeight: 600,
                            fontSize: '10pt',
                          },
                          '.MuiDataGrid-columnSeparator': {
                            display: 'none',
                          },
                          '& .MuiDataGrid-cell': {
                            fontSize: '10px',
                            fontWeight: '250',
                            color: 'rgba(0,0,0,.85)',
                          },
                        }}
                        headerHeight={35}
                        rowHeight={42}
                        checkboxSelection={false}
                        autoHeight
                        pageSize={10}
                        onCellClick={(val) => {
                          if (val.field?.toLowerCase() === 'call id') {
                            window.navigator.clipboard.writeText(val.formattedValue)
                            toast.success('Copied Call ID to clipboard.')
                          }
                        }}
                      />
                      {charts?.find(item => item.name === 'error_details')?.chartData?.length > 0 && (
                        <ErrorLogTable
                          chartRange={range.start && range.end
                            ? formatter.formatRange(
                                range.start.toDate(getLocalTimeZone()),
                                range.end.toDate(getLocalTimeZone()),
                              )
                            : '--'}
                          logs={
                            charts.find(item => item.name === 'error_details')
                              ?.chartData
                          }
                          logsPerPage={10}
                        />
                      )}
                    </>
                  )}
            </>
          )}
        </div>

        {selected.value === 'pathway_analytics' && (
          <PathwayHeatmap callId={qpCallId} />
        )}
        {selected.value === 'analysis_presets' && (
          <AnalysisPresetConfigurator />
        )}
        {selected.value === 'report' && (
          <div className="w-full h-full mt-6">
            <ReportPage />
          </div>
        )}
        {selected.value.startsWith('dashboard-') && (
          <RetoolDashboard appID={retoolValue} />
        )}
      </Wrapper>
    </PageWrapper>
  )
}

/**
 * A super-minimal helper to return a textual "Last X days" or "Today" label for the top of the cards.
 */
function dateRangeLabel(range) {
  const difference
    = new Date(range.end * 1000).getDate()
      - new Date(range.start * 1000).getDate()
  return difference === 0 ? 'Today' : `Last ${difference} days`
}

/**
 * A simpler subcomponent to unify how we show the icon + title + average number in the latency cards.
 */
function LatencyTitle({ icon, title, avg }) {
  return (
    <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
      <IconContainer>{icon}</IconContainer>
      <div style={{ marginLeft: 15 }}>
        <PageTitle style={{ fontSize: 16, fontWeight: '450' }}>
          {title}
        </PageTitle>
        <p style={{ color: 'grey', fontWeight: '450', fontSize: 12 }}>
          {avg * 1000}
          {' '}
          ms on avg
        </p>
      </div>
    </div>
  )
}

/**
 * Another subcomponent, because reusing code is cheaper than buying it new.
 * Renders the small "start date vs end date" text under the bar graphs.
 */
function BarGraphLabel({ latency }) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        marginTop: 10,
      }}
    >
      <p style={{ fontSize: 10, color: 'grey', fontWeight: '500' }}>
        {latency?.[0]?.day}
      </p>
      <p style={{ fontSize: 10, color: 'grey', fontWeight: '500' }}>
        {latency?.[latency?.length - 1]?.day}
      </p>
    </div>
  )
}

/**
 * Divider line for between the latency charts.
 * Because each chart wants its personal space.
 */
function Divider() {
  return (
    <div
      style={{
        background: '#eeeeee',
        width: '100%',
        height: 1,
        marginTop: 15,
      }}
    />
  )
}

/**
 * A quick label that pairs a color circle with a card heading.
 * Because color-coding is basically mandatory in data viz.
 */
function LegendLabel({ color, label }) {
  return (
    <div
      style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
    >
      <div
        style={{
          height: 10,
          width: 10,
          borderRadius: 25,
          background: color,
          marginRight: 10,
        }}
      />
      <CardTitle style={{ fontSize: 14 }}>{label}</CardTitle>
    </div>
  )
}

// Enough with the main body; let's talk layout styles.
const Wrapper = styled.div`
  height: calc(100% - 54px);
  margin-top: 50px;
  top: 0px;
  margin-left: 70px;
  margin-right: 90px;
  overflow-y: scroll;
  position: absolute;
  width: calc(100% - 240px);
  padding: 20px;

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`

const IconContainer = styled.div`
  border: 1px solid #e5e7eb;
  border-radius: 5px;
  padding: 5px;
  color: #e5e7eb;
`
