import config from 'config'
// hooks/useReportAnalytics.js
import { useEffect, useState } from 'react'
import { $fetch } from 'utils/fetch'
import { getApiKey } from 'utils/funcs/browser/getApiKey'
import { getOrgId } from 'utils/funcs/browser/getOrgId'
// import { getStartAndEndDate } from "../utils/utils";
import { getStartAndEndDate } from '../Analytics/utils/utils'
import areaCodeToLocation from '../Home/areaCodeData.json'

export function useReportAnalytics() {
  const [reportData, setReportData] = useState({
    totalCalls: 0,
    callDuration: {
      average: '0:00',
      total: '0:00',
      daily: [],
    },
    locations: {
      total: 0,
      topLocations: [],
      calls: 0,
      areaCodeData: [],
    },
    voicemailCount: 0,
    loading: true,
    error: null,
  })

  // Add new state for calls
  const [calls, setCalls] = useState([])
  const [currentPage, setCurrentPage] = useState(1)

  useEffect(() => {
    const fetchReportData = async () => {
      try {
        let { startDate, endDate } = getStartAndEndDate('week', 1)
        // stringifiy
        startDate = startDate.toString()
        endDate = endDate.toString()

        // Fetch analytics data
        const analyticsResponse = await fetch(
          `${config.API_URL}/api/analytics`,
          {
            method: 'POST',
            headers: {
              'authorization': getApiKey(),
              'content-type': 'application/json',
              ...(getOrgId() && { 'x-bland-org-id': getOrgId() }),
            },
            body: JSON.stringify({
              queries: [
                {
                  start_ts: startDate,
                  end_ts: endDate,
                  grouping: 'day',
                  metric: 'calls',
                  count: false,
                },
                {
                  start_ts: startDate,
                  end_ts: endDate,
                  grouping: 'day',
                  metric: 'call_length_sum',
                  count: false,
                },
                {
                  start_ts: startDate,
                  end_ts: endDate,
                  grouping: 'day',
                  metric: 'call_length_avg',
                  count: false,
                },
                {
                  start_ts: startDate,
                  end_ts: endDate,
                  grouping: 'day',
                  metric: 'voicemail_count',
                  count: false,
                },
              ],
            }),
          },
        )

        // Fetch location data
        const locationData = await $fetch(
          `/analytics/last_seven_map`,
          {},
          true,
        )

        // Add new calls fetch
        const callsData = await $fetch(
          `/v1/calls?page=${currentPage}&pageSize=10`,
        )

        setCalls(callsData.calls.slice(0, 50))

        if (!analyticsResponse.ok) {
          throw new Error('Failed to fetch analytics data')
        }

        const { data } = await analyticsResponse.json()

        // Process analytics data
        const totalCalls = data
          .find(d => d.metric === 'calls')
          ?.data
          .reduce((acc, curr) => acc + (curr.value || 0), 0)

        // Calculate average duration excluding null values
        const avgCallData
          = data.find(d => d.metric === 'call_length_avg')?.data || []
        const validAvgCalls = avgCallData.filter(d => d.value !== null)
        const avgDuration
          = validAvgCalls.length > 0
            ? validAvgCalls.reduce((acc, curr) => acc + curr.value, 0)
            / validAvgCalls.length
            : 0

        // Calculate total duration excluding null values
        const totalDurationData
          = data.find(d => d.metric === 'call_length_sum')?.data || []
        const totalDuration = totalDurationData.reduce(
          (acc, curr) => acc + (curr.value || 0),
          0,
        )

        // Process location data
        const expandCallsIntoCities = (areaCodeData) => {
          const cityCounts = {}
          areaCodeData.forEach(({ areaCode, count }) => {
            const loc = areaCodeToLocation[areaCode]
            if (!loc)
              return
            if (!cityCounts[loc.city]) {
              cityCounts[loc.city] = 0
            }
            cityCounts[loc.city] += count
          })
          return cityCounts
        }

        const cityData = locationData?.data
          ? expandCallsIntoCities(locationData.data)
          : {}
        const sortedCities = Object.entries(cityData)
          .sort(([, a], [, b]) => b - a)
          .map(([city]) => city)

        setReportData({
          totalCalls,
          callDuration: {
            average: formatDuration(avgDuration),
            total: formatDuration(totalDuration),
            daily: data.find(d => d.metric === 'call_length_avg')?.data || [],
          },
          locations: {
            total: Object.keys(cityData).length,
            topLocations: sortedCities.slice(0, 5),
            calls: Object.values(cityData)[0] || 0,
            areaCodeData: locationData?.data || [],
          },
          voicemailCount:
            data.find(d => d.metric === 'voicemail_count')?.data[0]?.value
            || 0,
          loading: false,
          error: null,
        })
      }
      catch (error) {
        console.error('Error fetching report data:', error)
        setReportData(prev => ({
          ...prev,
          loading: false,
          error: error.message,
        }))
        setCalls([]) // Reset calls on error
      }
    }

    fetchReportData()
  }, [currentPage]) // Add currentPage to dependency array

  return { ...reportData, calls, currentPage, setCurrentPage }
}

// Helper function to format duration
function formatDuration(minutes) {
  if (!minutes)
    return '0:00'
  const mins = Math.floor(minutes)
  const secs = Math.round((minutes - mins) * 60)
  return `${mins}:${secs.toString().padStart(2, '0')}`
}
