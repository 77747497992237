import { Loading } from 'components/core/Loading'
import PathwayCard from 'components/Showcase/card'
import ShowcaseCreationForm from 'components/Showcase/creation_form'
import PathwayModal from 'components/Showcase/modal'
import config from 'config'
import { useAuth } from 'hooks/useAuth'
import { Clock, Copy, Search, ThumbsUp } from 'lucide-react'
import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { $fetch } from 'utils/fetch'
import { getAuthToken } from 'utils/funcs/browser/getAuthToken'
import { getOrgId } from 'utils/funcs/browser/getOrgId'
import blandfont from '../assets/brand/bland-font.svg'
import blandlogo from '../assets/brand/logo-infinity.svg'

function PathwayShowcase() {
  const { user } = useAuth()
  const [searchTerm, setSearchTerm] = useState('')
  const [filter, setFilter] = useState('All')
  const [modalPathway, setModalPathway] = useState(null)
  const navigate = useNavigate()
  const [showCreateForm, setShowCreateForm] = useState(false)
  const [filteredPathways, setFilteredPathways] = useState([])
  const [editingPathway, setEditingPathway] = useState(null)
  const [sortBy, setSortBy] = useState('liked')
  const orgId = getOrgId()
  const [pathways, setPathways] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    // Apply search and filter whenever searchTerm, filter, or pathways change
    const filtered = pathways.filter((pathway) => {
      const matchesSearch = pathway.title
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
      const matchesFilter = filter === 'All' || pathway.tags.includes(filter)
      return matchesSearch && matchesFilter
    })

    filtered.sort((a, b) => {
      switch (sortBy) {
        case 'liked':
          return b.stars - a.stars
        case 'cloned':
          return b.clones - a.clones
        case 'recent':
          return new Date(b.created_at) - new Date(a.created_at)
        default:
          return 0
      }
    })
    setFilteredPathways(filtered)
  }, [searchTerm, filter, sortBy, pathways])

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value)
  }

  const handleFilterChange = (category) => {
    setFilter(category)
  }

  const handleSortChange = (sortOption) => {
    setSortBy(sortOption)
  }

  const defaults = [
    {
      id: '227d82bd-0e69-4b96-bc12-7b93b7c4fb90',
      title: 'Customer Support Bot',
      description:
        'An intelligent conversational agent designed to handle common customer inquiries efficiently. This pathway includes modules for product information, order tracking, and issue resolution.',
      author: 'Alice',
      stars: 22,
      tags: ['Customer Service', 'AI', 'Support'],
    },
    {
      id: '227d82bd-0e69-4b96-bc12-7b93b7c4fb90',
      title: 'Product Recommendation Engine',
      description:
        'A sophisticated AI-driven system that analyzes user preferences and behavior to suggest relevant products. Incorporates natural language processing for personalized interactions.',
      author: 'Bob',
      stars: 18,
      tags: ['Sales', 'AI'],
    },
    {
      id: '227d82bd-0e69-4b96-bc12-7b93b7c4fb90',
      title: 'Appointment Scheduler',
      description:
        'Streamline your booking process with this intuitive conversation flow. Handles time zone differences, availability checks, and sends confirmation messages.',
      author: 'Charlie',
      stars: 15,
      tags: ['Booking'],
    },
    {
      id: '227d82bd-0e69-4b96-bc12-7b93b7c4fb90',
      title: 'FAQ Assistant',
      description:
        'A comprehensive knowledge base wrapped in a conversational interface. Quickly answers frequently asked questions and provides relevant resources.',
      author: 'Diana',
      stars: 12,
      tags: ['FAQ', 'Knowledge Base', 'Support'],
    },
    {
      id: '227d82bd-0e69-4b96-bc12-7b93b7c4fb90',
      title: 'Lead Qualification Bot',
      description:
        'Engage potential customers and qualify leads through a series of targeted questions. Integrates with CRM systems for seamless lead management.',
      author: 'Ethan',
      stars: 20,
      tags: ['Sales', 'Lead Qualification', 'CRM'],
    },
    {
      id: '227d82bd-0e69-4b96-bc12-7b93b7c4fb90',
      title: 'Customer Feedback Collector',
      description:
        'Gather valuable insights from your users with this interactive feedback collection pathway. Includes sentiment analysis and categorization of responses.',
      author: 'Fiona',
      stars: 17,
      tags: ['Customer Service'],
    },
  ]

  const createShowcase = () => {
    if (!user) {
      toast.warn('Please login to create a showcase.', {
        autoClose: 3000,
      })
    }
    else {
      setShowCreateForm(true)
    }
  }

  const handleCreateSubmit = async (showcaseData) => {
    try {
      const url = editingPathway
        ? `${config.API_URL}/showcase/pathway/update`
        : `${config.API_URL}/showcase/pathway/create`

      const body = editingPathway
        ? { ...showcaseData, showcase_id: editingPathway.id }
        : showcaseData

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'authorization': getAuthToken(),
        },
        body: JSON.stringify(body),
      })

      if (response.ok) {
        toast.success(
          editingPathway
            ? 'Showcase updated successfully'
            : 'Showcase created successfully',
        )
        setShowCreateForm(false)
        setEditingPathway(null)
        fetchPathways()
      }
      else {
        const errorData = await response.json()
        toast.error(
          `Failed to ${editingPathway ? 'update' : 'create'} showcase: ${errorData.message}`,
        )
      }
    }
    catch (error) {
      console.error(
        `Error ${editingPathway ? 'updating' : 'creating'} showcase:`,
        error,
      )
      toast.error(
        `An error occurred while ${editingPathway ? 'updating' : 'creating'} the showcase`,
      )
    }
  }

  const handleEdit = (pathwayId) => {
    const pathwayToEdit = pathways.find(p => p.id === pathwayId)
    if (pathwayToEdit) {
      setEditingPathway(pathwayToEdit)
      setShowCreateForm(true)
    }
  }

  async function fetchPathways() {
    const pathwayUrl = user
      ? '/showcase/pathway/get'
      : '/showcase/public/pathways'
    setLoading(true)
    const res = await $fetch(pathwayUrl)

    if (res.data) {
      setPathways(res.data)
    }
    else {
      console.error('Failed to fetch pathways')
    }

    setLoading(false)

    if (user) {
      // fetch user's starred pathways
      const starred_pathways_res = await fetch(
        `${config.API_URL}/showcase/pathway/get_stars`,
        {
          method: 'GET',
          headers: {
            authorization: getAuthToken(),
            ...(orgId && { 'x-bland-org-id': orgId }),
          },
        },
      )

      if (starred_pathways_res.ok) {
        const starred_pathways_json = await starred_pathways_res.json()

        const starred_pathway_ids = starred_pathways_json.data.map(
          pathway => pathway.showcase_id,
        )

        setPathways((pathways) => {
          return pathways.map((pathway) => {
            return {
              ...pathway,
              starred: starred_pathway_ids.includes(pathway.id),
            }
          })
        })
      }
    }
  }

  useEffect(() => {
    // fetch all pathways for the showcase, then render them. then if there is a user logged in, fetch their starred pathways, and check which pathways they have already cloned.

    // fetch all pathways
    fetchPathways()
  }, [])

  const handlePreview = (pathway) => {
    setModalPathway(pathway)
  }

  const handleCloseModal = () => {
    setModalPathway(null)
  }

  return (
    <div className="min-h-screen bg-white">
      <header className="border-b border-gray-200 py-2.5">
        <div className="container mx-auto flex justify-between items-center">
          <a
            className="flex items-center gap-2.5"
            href="https://bland.ai"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={blandlogo} alt="logo" className="w-[27px] h-[30px]" />
            <img src={blandfont} alt="BlandAI" />
          </a>
          <nav className="flex justify-center">
            <ul className="flex space-x-8">
              <li className="hover:text-indigo-500 transition-colors duration-300">
                <a
                  href="https://app.bland.ai"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Product
                </a>
              </li>
              <li className="border-b-2 border-indigo-500 hover:border-indigo-700 transition-colors duration-300">
                <button disabled>Marketplace</button>
              </li>
              <li className="hover:text-indigo-500 transition-colors duration-300">
                <a
                  href="https://docs.bland.ai"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Resources
                </a>
              </li>
            </ul>
          </nav>

          {user
            ? (
                <div className="flex items-center gap-2.5">
                  <p className="text-gray-600 text-[14px] w-[117px] text-right">
                    Hi,
                    {' '}
                    {user.user.first_name}
                  </p>
                </div>
              )
            : (
                <button
                  className="bg-indigo-600 text-white px-2.5 py-1.5 rounded-sm"
                  onClick={() => navigate('/signup')}
                >
                  Sign Up
                </button>
              )}
        </div>
      </header>
      <div className="container mx-auto px-2.5 py-8">
        <h1 className="text-[32px] font-semibold text-center mb-2">
          Pathways Showcase
        </h1>
        <p className="text-center text-gray-600 mb-6 max-w-2xl mx-auto text-[14px]">
          Explore, clone, and customize conversation pathways crafted by the
          BlandAI community. Find inspiration and accelerate your development
          process.
        </p>

        <div className="mb-5">
          <div className="relative max-w-md mx-auto">
            <input
              type="text"
              placeholder="Search pathways..."
              className="w-full px-2.5 py-1.5 border rounded-full shadow-sm focus:ring-2 focus:ring-indigo-300 focus:border-indigo-500 outline-none transition"
              value={searchTerm}
              onChange={handleSearchChange}
            />
            <Search
              className="absolute right-2 top-1.5 text-gray-400"
              size={20}
            />
          </div>
        </div>

        <div className="flex flex-wrap justify-center gap-1.5 mb-6">
          {['All', 'Customer Service', 'Sales', 'Booking', 'FAQ'].map(
            category => (
              <button
                key={category}
                className={`px-2.5 py-1.5 rounded-full text-[12px] font-medium transition-colors ${
                  filter === category
                    ? 'bg-indigo-500 text-white'
                    : 'bg-gray-100 text-gray-800 hover:bg-gray-200'
                }`}
                onClick={() => handleFilterChange(category)}
              >
                {category}
              </button>
            ),
          )}
        </div>

        <div className="flex justify-center gap-2.5 mb-5">
          <button
            className={`flex items-center px-2.5 py-1.5 rounded text-[10px] font-medium transition-colors ${
              sortBy === 'liked'
                ? 'bg-indigo-500 text-white'
                : 'bg-gray-100 text-gray-800 hover:bg-gray-200'
            }`}
            onClick={() => handleSortChange('liked')}
          >
            <ThumbsUp size={16} className="mr-1.5" />
            {' '}
            Most Liked
          </button>
          <button
            className={`flex items-center px-2.5 py-1.5 rounded text-[10px] font-medium transition-colors ${
              sortBy === 'recent'
                ? 'bg-indigo-500 text-white'
                : 'bg-gray-100 text-gray-800 hover:bg-gray-200'
            }`}
            onClick={() => handleSortChange('recent')}
          >
            <Clock size={16} className="mr-1.5" />
            {' '}
            Most Recent
          </button>
          <button
            className={`flex items-center px-2.5 py-1.5 rounded text-[10px] font-medium transition-colors ${
              sortBy === 'cloned'
                ? 'bg-indigo-500 text-white'
                : 'bg-gray-100 text-gray-800 hover:bg-gray-200'
            }`}
            onClick={() => handleSortChange('cloned')}
          >
            <Copy size={16} className="mr-1.5" />
            {' '}
            Most Cloned
          </button>
        </div>

        {loading
          ? (
              <Loading loading={loading} />
            )
          : (
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {filteredPathways.map(pathway => (
                  <PathwayCard
                    key={pathway.id}
                    {...pathway}
                    onPreview={() => handlePreview(pathway)}
                    onEdit={user ? handleEdit : undefined}
                  />
                ))}
              </div>
            )}

        <div className="mt-8 text-center">
          {user
            ? (
                <button
                  className="bg-indigo-500 text-white px-5 py-2 rounded-full text-[16px] font-semibold hover:bg-indigo-600 transition-colors shadow-md hover:shadow-lg"
                  onClick={createShowcase}
                >
                  Showcase Your Pathway
                </button>
              )
            : (
                <div>
                  <p className="mb-1.5">Login or sign up to showcase your pathway</p>
                  <Link
                    to="/login"
                    className="mr-2.5 text-indigo-600 hover:underline"
                  >
                    Login
                  </Link>
                  <Link to="/signup" className="text-indigo-600 hover:underline">
                    Sign Up
                  </Link>
                </div>
              )}
        </div>
      </div>

      {modalPathway && (
        <PathwayModal
          show={Boolean(modalPathway)}
          onClose={handleCloseModal}
          pathway={modalPathway}
        />
      )}

      {showCreateForm && (
        <ShowcaseCreationForm
          isOpen={showCreateForm}
          onClose={() => {
            setShowCreateForm(false)
            setEditingPathway(null)
          }}
          onSubmit={handleCreateSubmit}
          initialData={editingPathway}
        />
      )}
    </div>
  )
}

export default PathwayShowcase
