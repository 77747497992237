import { HeroUIProvider, ToastProvider } from '@heroui/react'
import { Theme } from '@radix-ui/themes'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { HelmetProvider } from 'react-helmet-async'
import App from './App'
import './datadog'
import './index.css'
import '@radix-ui/themes/styles.css'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <HeroUIProvider>
        <ToastProvider placement="top-right" />
        <Theme appearance="light" scaling="90%">
          <App />
        </Theme>
      </HeroUIProvider>
    </HelmetProvider>
  </React.StrictMode>,
)
