import { QueryCache, QueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import { $fetch } from 'utils/fetch'

async function defaultQueryFn({ queryKey }) {
  const { data } = await $fetch(queryKey[0])
  return data
}

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: defaultQueryFn,
      retry: false,
    },
  },
  queryCache: new QueryCache({
    onError: (error, query) => {
      if (query.meta?.errorMessage) {
        toast.error(query.meta.errorMessage)
      }
      console.error('Error in query', error)
    },
    onSuccess: (data, query) => {
      if (query.meta?.successMessage) {
        toast.success(query.meta.successMessage)
      }
    },
  }),
})
