import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog'
import { Button } from '@/components/ui/button'
import { ChevronDown, ChevronUp, Pencil, Trash2 } from 'lucide-react'
import { useContext, useEffect, useState } from 'react'
import { $fetch } from 'utils/fetch'
import LibrarySidebarContext from '../contexts/librarySidebarContext'
import AddToLibraryForm from './AddToLibraryForm'
import NodePreviewCard from './NodePreviewCard'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'

// const categories = [
//   { id: "all", name: "All" },
//   { id: "customer-service", name: "Customer Service" },
//   { id: "sales", name: "Sales" },
//   { id: "scheduling", name: "Scheduling" },
//   { id: "workflows", name: "Workflows" },
// ];

function PreConfiguredNodesTab({nodesLibrary, isLoading, searchTerm, onSelectNode }) {
  const { currentOpenNodeID, setCurrentOpenNodeID } = useContext(
    LibrarySidebarContext,
  )
  const queryClient = useQueryClient()

  const [selectedCategory, setSelectedCategory] = useState('all')
  const [showLibraryForm, setShowLibraryForm] = useState(false)
  const [sectionsCollapsed, setSectionsCollapsed] = useState({
    yourNodes: false,
    publicNodes: false,
  })
  const [editingNode, setEditingNode] = useState(null)

  const deleteNodeMutation = useMutation({
    mutationFn: (nodeId) => {
      return $fetch(`/v1/pathway/library/nodes/${nodeId}`, {
        method: 'DELETE',
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['nodesLibrary'] })
      toast({
        title: "Node deleted",
        description: "The node has been successfully removed from your library.",
      })
    },
    onError: (error) => {
      console.error('Failed to delete node:', error)
      toast({
        title: "Error deleting node",
        description: "There was a problem deleting the node. Please try again.",
        variant: "destructive",
      })
    }
  })

  useEffect(() => {
    if (currentOpenNodeID) {
      setShowLibraryForm(true)
    }
  }, [currentOpenNodeID])

  const handleDeleteNode = (nodeId) => {
    deleteNodeMutation.mutate(nodeId)
  }

  const filterNodes = (nodes, isPublic) => {
    return nodes.filter((node) => {
      const matchesSearch = node.name
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
      const matchesCategory
        = selectedCategory === 'all' || node.tags.includes(selectedCategory)
      const matchesType = isPublic
        ? node.public && node.approved
        : node.ownsNode
      return matchesSearch && matchesCategory && matchesType
    })
  }

  const publicNodes = filterNodes(nodesLibrary, true)
  const userNodes = filterNodes(nodesLibrary, false)

  const toggleSection = (section) => {
    setSectionsCollapsed(prev => ({
      ...prev,
      [section]: !prev[section],
    }))
  }

  const handleEditNode = (node) => {
    setEditingNode(node)
    setShowLibraryForm(true)
  }

  if (showLibraryForm) {
    return (
      <div className="space-y-5 px-4 pb-4 w-full">
        <div className="border-b pb-2.5">
          <h3 className="text-sm font-semibold text-gray-900 mb-2">
            {editingNode ? 'Edit Node' : 'Add to Node Library'}
          </h3>
          <p className="text-xs text-gray-500">
            {editingNode
              ? 'Update your node settings. Changes will be subject to review before being published.'
              : 'Share your optimized node with the community! All nodes will be subject to review before being published to the node\'s library.'}
          </p>
        </div>

        <div className="w-full mt-4">
          <AddToLibraryForm
            open={showLibraryForm}
            editingNode={editingNode}
            onClose={() => {
              setShowLibraryForm(false)
              setEditingNode(null)
              queryClient.invalidateQueries({ queryKey: ['nodesLibrary'] })
              setCurrentOpenNodeID(null)
            }}
            openNodeID={currentOpenNodeID}
          />
        </div>
      </div>
    )
  }

  return (
    <div className="space-y-4 p-2.5 w-full h-[calc(100vh-200px)] overflow-y-auto">
      {/* Category Filter */}
      <div className="flex justify-between items-center gap-1.5">
        <h2 className="text-base font-bold text-gray-900">Node Library</h2>

        {/* <div className="flex gap-1.5 overflow-x-auto">
          {categories.map((category) => (
            <button
              key={category.id}
              onClick={() => setSelectedCategory(category.id)}
              className={`px-2 py-1 rounded-full text-2xs font-medium whitespace-nowrap
                ${
                  selectedCategory === category.id
                    ? "bg-indigo-100 text-indigo-700"
                    : "bg-gray-100 text-gray-600 hover:bg-gray-200"
                }`}
            >
              {category.name}
            </button>
          ))}
        </div> */}

        <Button onClick={() => setShowLibraryForm(true)} className="px-2.5 py-1.5">
          + Add Node
        </Button>
      </div>

      <p className="text-xs text-gray-500">
        The node library allows you to reuse commonly used nodes in multiple
        pathways, or use optimized nodes shared by the community for certain use
        cases.
      </p>

      {/* Show empty state if no nodes are visible at all */}
      {!isLoading && userNodes.length === 0 && publicNodes.length === 0 ? (
        <div className="text-center py-8">
          <h3 className="text-sm font-semibold text-gray-900 mb-1.5">
            No nodes found
          </h3>
          <p className="text-gray-500 mb-4">
            {searchTerm
              ? 'No nodes match your search criteria. Try adjusting your search.'
              : 'Get started by adding your first node to the library!'}
          </p>
          <Button
            onClick={() => setShowLibraryForm(true)}
            className="px-2.5 py-1.5"
          >
            + Add Your First Node
          </Button>
        </div>
      ) : (
        <>
          {/* User's Nodes Section */}
          <div className="space-y-2.5">
            <div
              className="flex justify-between items-center cursor-pointer"
              onClick={() => toggleSection('yourNodes')}
            >
              <h3 className="text-sm font-semibold text-gray-900">
                Your Nodes
              </h3>
              {sectionsCollapsed.yourNodes
                ? (
                    <ChevronDown className="h-3 w-3 text-gray-500" />
                  )
                : (
                    <ChevronUp className="h-3 w-3 text-gray-500" />
                  )}
            </div>

            {!sectionsCollapsed.yourNodes && (
              <div className="grid grid-cols-2 gap-2.5 max-w-4xl mx-auto">
                {isLoading
                  ? (
                      <>
                        <div className="h-20 bg-gray-100 rounded-md animate-pulse"></div>
                        <div className="h-20 bg-gray-100 rounded-md animate-pulse"></div>
                        <div className="h-20 bg-gray-100 rounded-md animate-pulse"></div>
                        <div className="h-20 bg-gray-100 rounded-md animate-pulse"></div>
                      </>
                    )
                  : userNodes.length > 0
                    ? (
                        userNodes.map(node => (
                          <div
                            key={node.node_data.type}
                            className="relative w-full border-2 border-transparent hover:border-indigo-500 rounded-md transition-colors duration-200"
                          >
                            <NodePreviewCard node={node} onInsert={onSelectNode} />
                            <div className="absolute bottom-2.5 right-2.5 flex items-center gap-1.5">
                              <Button
                                variant="ghost"
                                size="icon"
                                onClick={() => handleEditNode(node)}
                                className="hover:bg-blue-50 hover:text-blue-600 transition-colors"
                              >
                                <Pencil className="h-2.5 w-2.5 stroke-[1.5px]" />
                              </Button>
                              <AlertDialog>
                                <AlertDialogTrigger asChild>
                                  <Button
                                    variant="ghost"
                                    size="icon"
                                    className="hover:bg-red-50 hover:text-red-600 transition-colors"
                                  >
                                    <Trash2 className="h-2.5 w-2.5 stroke-[1.5px]" />
                                  </Button>
                                </AlertDialogTrigger>
                                <AlertDialogContent>
                                  <AlertDialogHeader>
                                    <AlertDialogTitle>Delete Node</AlertDialogTitle>
                                    <AlertDialogDescription>
                                      Are you sure you want to delete this node? This
                                      action cannot be undone.
                                    </AlertDialogDescription>
                                  </AlertDialogHeader>
                                  <AlertDialogFooter>
                                    <AlertDialogCancel>Cancel</AlertDialogCancel>
                                    <AlertDialogAction
                                      onClick={() => handleDeleteNode(node.id)}
                                      disabled={deleteNodeMutation.isPending}
                                    >
                                      {deleteNodeMutation.isPending ? "Deleting..." : "Delete"}
                                    </AlertDialogAction>
                                  </AlertDialogFooter>
                                </AlertDialogContent>
                              </AlertDialog>
                            </div>
                          </div>
                        ))
                      )
                    : (
                        <div className="col-span-2 text-center py-5 bg-gray-50 rounded-md">
                          <p className="text-gray-500 mb-2.5">
                            You haven't added any nodes to your library yet .
                          </p>
                          <Button
                            onClick={() => setShowLibraryForm(true)}
                            variant="outline"
                            className="px-2.5 py-1.5"
                          >
                            Add Your First Node
                          </Button>
                        </div>
                      )}
              </div>
            )}
          </div>

          {/* Public Nodes Section */}
          <div className="space-y-2.5 mt-5">
            <div
              className="flex justify-between items-center cursor-pointer"
              onClick={() => toggleSection('publicNodes')}
            >
              <h3 className="text-sm font-semibold text-gray-900">
                Public Library
              </h3>
              {sectionsCollapsed.publicNodes
                ? (
                    <ChevronDown className="h-3 w-3 text-gray-500" />
                  )
                : (
                    <ChevronUp className="h-3 w-3 text-gray-500" />
                  )}
            </div>

            {!sectionsCollapsed.publicNodes && (
              <div className="grid grid-cols-2 gap-2.5 max-w-4xl mx-auto">
                {isLoading
                  ? (
                      <>
                        <div className="h-20 bg-gray-100 rounded-md animate-pulse"></div>
                        <div className="h-20 bg-gray-100 rounded-md animate-pulse"></div>
                        <div className="h-20 bg-gray-100 rounded-md animate-pulse"></div>
                        <div className="h-20 bg-gray-100 rounded-md animate-pulse"></div>
                      </>
                    )
                  : publicNodes.length > 0
                    ? (
                        publicNodes.map(node => (
                          <div
                            key={node.node_data.type}
                            className="relative w-full border-2 border-transparent hover:border-indigo-500 rounded-md transition-colors duration-200"
                          >
                            <NodePreviewCard node={node} onInsert={onSelectNode} />
                          </div>
                        ))
                      )
                    : (
                        <p className="col-span-2 text-center text-gray-500">
                          No public nodes found.
                        </p>
                      )}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default PreConfiguredNodesTab
