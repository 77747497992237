import { forwardRef, Select, SelectItem } from '@heroui/react'
import { useQuery } from '@tanstack/react-query'
import { $fetch } from 'utils/fetch'

const PathwaySelect = forwardRef(({ label, value, ...props }, ref) => {
  const { data: pathways, isLoading: pathwaysLoading } = useQuery({
    queryKey: ['pathways'],
    queryFn: () => $fetch('/v1/pathway'),
    staleTime: 1000 * 60 * 10,
  })

  return (
    <Select
      isLoading={pathwaysLoading}
      labelPlacement="outside"
      isVirtualized
      variant="bordered"
      radius="sm"
      placeholder="Select a pathway"
      selectedKeys={value && [value]}
      label={label}
      {...props}
      ref={ref}
    >
      {pathways?.map(pathway => (
        <SelectItem key={pathway.id} endContent={<p className="text-xs text-gray-500 truncate font-geist-mono">{pathway.id}</p>}>
          {pathway.name ?? 'Untitled Pathway'}
        </SelectItem>
      ))}
    </Select>
  )
})

export default PathwaySelect
