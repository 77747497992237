import VoiceGradientReusable from 'components/Dashboard/SendCall/VoiceGradientReusable'
import styled from 'styled-components'

export default function Avatar({ org, pageTitle, size = 35 }) {
  return (
    <>
      <Wrapper>
        <h1>{pageTitle}</h1>
        {org?.org_image_url
          ? (
              <img
                src={org?.org_image_url}
                alt="org"
                className="w-[35px] h-[35px] rounded-full cursor-pointer"
              />
            )
          : (
              <VoiceGradientReusable name={org?.org_id ?? org?.id} size={size} />
            )}
      </Wrapper>
    </>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 100;
  position: relative;
`

const AvatarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 12px;
  align-items: center;
  cursor: pointer;
`

const Initials = styled.div`
  display: flex;
  height: 35px;
  width: 100%;
  max-width: 35px;
  min-width: 35px;
  border-radius: 100%;
  border: 1px solid #eeeeee;
  align-items: center;
  justify-content: center;
  color: #686767;
  background-color: #fbfbfa;
`
