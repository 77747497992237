import { Zap } from 'lucide-react'
import { useNavigate } from 'react-router-dom'

function GeneratePathwayCard() {
  const navigate = useNavigate()

  const handleGenerateClick = () => {
    navigate('/generate-pathway')
  }

  return (
    <div
      className="group relative bg-white border border-gray-200 rounded p-4 hover:shadow-md transition-all duration-200 cursor-pointer overflow-hidden"
      onClick={handleGenerateClick}
    >
      <div className="absolute inset-0 bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 opacity-0 group-hover:opacity-10 transition-opacity duration-200" />

      <div className="relative z-10">
        <div className="flex items-center justify-between mb-2.5">
          <h3 className="text-sm font-semibold text-gray-900 group-hover:text-indigo-600 transition-colors duration-200">
            Generate Pathway
          </h3>
          <Zap className="w-3 h-3 text-gray-400 group-hover:text-indigo-500 transition-colors duration-200" />
        </div>

        <p className="text-xs text-gray-500 mb-4">
          Generate a pathway from a use case or script.
        </p>

        <button
          className="mt-1.5 inline-flex items-center px-2 py-1.5 border border-transparent text-xs leading-2.5 font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-colors duration-200"
          onClick={(e) => {
            e.stopPropagation()
            handleGenerateClick()
          }}
        >
          Generate
        </button>
      </div>
    </div>
  )
}

export default GeneratePathwayCard
