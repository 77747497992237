import { Button, Card, CardBody, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader } from '@heroui/react'
import { Icon } from '@iconify/react'
import { ConfirmationModal } from 'components/Reusables/ConfirmationModal'
import { formatDistanceToNow } from 'date-fns'
import { useState } from 'react'
import { $fetch } from 'utils/fetch'

// interface MemoryStoreCardProps {
//   memoryStore: {
//     memory_id: string;
//     name: string;
//     user_count: number;
//     created_at: string;
//     description: string;
//   };
//   onClick: () => void;
// }

export function MemoryStoreCard({ memoryStore, onClick, onUpdate }) {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [newName, setNewName] = useState(memoryStore.name)
  const [isLoading, setIsLoading] = useState(false)

  const handleEdit = async (e) => {
    setIsLoading(true)
    try {
      await $fetch(`/v1/memory/${memoryStore.id}/update`, {
        method: 'POST',
        body: JSON.stringify({ name: newName }),
      })
      onUpdate()
      setIsEditModalOpen(false)
    }
    catch (error) {
      console.error('Failed to update memory store:', error)
    }
    finally {
      setIsLoading(false)
    }
  }

  const handleDelete = async () => {
    setIsLoading(true)
    try {
      await $fetch(`/v1/memory/${memoryStore.id}/delete`, {
        method: 'POST',
      })
      onUpdate()
    }
    catch (error) {
      console.error('Failed to delete memory store:', error)
    }
    finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <Card
        isPressable
        onPress={onClick}
        className="hover:shadow-md transition-all border overflow-hidden m-4"
      >
        <CardBody className="p-4">
          <div className="flex items-center justify-between mb-2">
            <div className="flex items-center">
              <div className="bg-default-100 p-2 rounded-full">
                <Icon icon="lucide:brain" size={20} className="text-default-600" />
              </div>
              <div className="ml-3">
                <h3 className="font-medium">{memoryStore.name}</h3>
                <div className="text-xs text-default-500">
                  Created
                  {' '}
                  {formatDistanceToNow(new Date(memoryStore.created_at), { addSuffix: true })}
                </div>
              </div>
            </div>
            <div className="flex items-center gap-2">
              <Button
                isIconOnly
                size="sm"
                variant="light"
                title="Edit Memory"
                onPress={(e) => {
                  setIsEditModalOpen(true)
                }}
              >
                <Icon icon="lucide:edit" size={16} className="text-default-600" />
              </Button>
              <Button
                isIconOnly
                size="sm"
                variant="light"
                color="danger"
                title="Delete Memory"
                onPress={(e) => {
                  setIsDeleteModalOpen(true)
                }}
              >
                <Icon icon="lucide:trash" size={16} className="text-default-600" />
              </Button>
            </div>
            {/* <span className="text-xs px-2 py-1 rounded-full bg-default-100 text-default-800">
              {memoryStore.user_count}
              {' '}
              users
            </span> */}
          </div>

          <p className="text-sm text-default-700 mb-3">
            Memory Store for
            {' '}
            {memoryStore.name}
          </p>

          <div className="flex items-center text-xs text-default-500">
            <div className="font-mono">
              Memory ID:
              {memoryStore.id}
            </div>
            <div className="ml-auto flex items-center">
              <Icon icon="lucide:eye" size={14} className="mr-1" />
              <span>View details</span>
            </div>
          </div>
        </CardBody>
      </Card>

      {/* Edit Modal */}
      <Modal isOpen={isEditModalOpen} onOpenChange={setIsEditModalOpen}>
        <ModalContent>
          <ModalHeader>Edit Memory Store</ModalHeader>
          <ModalBody>
            <Input
              label="Memory Store Name"
              value={newName}
              onValueChange={setNewName}
              placeholder="Enter new name"
            />
          </ModalBody>
          <ModalFooter>
            <Button variant="light" onPress={() => setIsEditModalOpen(false)}>
              Cancel
            </Button>
            <Button
              color="primary"
              onPress={handleEdit}
              isLoading={isLoading}
            >
              Save Changes
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Delete Confirmation Modal */}
      <ConfirmationModal
        isOpen={isDeleteModalOpen}
        onOpenChange={setIsDeleteModalOpen}
        onConfirm={handleDelete}
        title="Delete Memory Store"
        message="Are you sure you want to delete this memory store? This action cannot be undone."
        confirmText="Delete"
        confirmColor="danger"
      />
    </>
  )
}
