import { addToast, Chip, Form, Button as HeroButton, Tab, Tabs } from '@heroui/react'
import { Button as RButton } from '@radix-ui/themes'
import TwilioCircle from 'assets/images/TwilioCircle.png'
import axios from 'axios'
import ActionBar from 'components/core/ActionBar'
import Button from 'components/core/Button'
import { Loading } from 'components/core/Loading'
import { PageTitle } from 'components/core/PageTitle'
import Switch from 'components/core/Switch'
import config from 'config'
import { useAuth } from 'hooks/useAuth'
import usePhoneNumbers from 'hooks/usePhoneNumbers'
import { ExternalLink, MessageSquare, Phone } from 'lucide-react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { AiOutlineEnter } from 'react-icons/ai'
import { FiChevronRight, FiEdit2, FiRefreshCw, FiSearch, FiSettings } from 'react-icons/fi'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { $fetch } from 'utils/fetch'
import { getAuthToken } from 'utils/funcs/browser/getAuthToken'
import { getOrgId } from 'utils/funcs/browser/getOrgId'
import { parseForJson } from 'utils/funcs/send-call/parseForJson'
import { v4 as uuidv4 } from 'uuid'
import { PageWrapper } from '../../core/PageWrapper'
import Inbound from './Inbound/Inbound'
import JSONMode from './Inbound/JSONMode'
import PurchaseNumber from './PurchaseNumber'

function USFlagSVG() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 912 600"
      className="w-2.5 h-2 mr-1.5"
    >
      <rect width="912" height="600" fill="#bf0a30" />
      <rect y="46.15" width="912" height="46.15" fill="#fff" />
      <rect y="138.45" width="912" height="46.15" fill="#fff" />
      <rect y="230.75" width="912" height="46.15" fill="#fff" />
      <rect y="323.05" width="912" height="46.15" fill="#fff" />
      <rect y="415.35" width="912" height="46.15" fill="#fff" />
      <rect y="507.65" width="912" height="46.15" fill="#fff" />
      <rect width="364.8" height="323.1" fill="#002868" />
      <g fill="#fff">
        {[...Array.from({ length: 50 })].map((_, i) => {
          const x = 30.4 * (i % 6) + 15.2
          const y = 28 * Math.floor(i / 6) + 14
          return <circle key={i} cx={x} cy={y} r="10" />
        })}
      </g>
    </svg>
  )
}

function PhoneCard({ number, location, price, style }) {
  return (
    <div
      className="bg-white rounded-md shadow-sm p-2 flex items-center justify-between"
      style={style}
    >
      <div className="flex items-center">
        <USFlagSVG />
        <span className="text-gray-800 text-xs font-semibold">{number}</span>
      </div>
      <div className="flex items-center">
        <span className="text-gray-600 text-2xs bg-gray-100 px-1.5 py-px rounded-sm mr-1.5">
          {location}
        </span>
        <span className="text-gray-400 text-2xs">{price}</span>
      </div>
    </div>
  )
}

function PhoneNumberComponent() {
  const backgroundNumbers = [
    { number: '(415) 968-1851', opacity: 0.7, top: '10%', left: '10%' },
    { number: '(628) 555-0113', opacity: 0.3, top: '20%', left: '30%' },
    { number: '(510) 555-0198', opacity: 0.4, top: '30%', left: '50%' },
    { number: '(650) 555-0123', opacity: 0.7, top: '40%', left: '70%' },
    { number: '(925) 555-0147', opacity: 0.3, top: '50%', left: '20%' },
    { number: '(707) 555-0176', opacity: 0.5, top: '60%', left: '40%' },
    { number: '(408) 555-0152', opacity: 0.3, top: '70%', left: '60%' },
    { number: '(669) 555-0194', opacity: 0.8, top: '80%', left: '80%' },
    { number: '(831) 555-0183', opacity: 0.5, top: '90%', left: '40%' },
    { number: '(209) 555-0121', opacity: 0.8, top: '30%', left: '80%' },
    { number: '(916) 555-0110', opacity: 0.4, top: '50%', left: '50%' },
    { number: '(415) 555-0178', opacity: 0.7, top: '10%', left: '70%' },
    { number: '(510) 555-0167', opacity: 0.8, top: '75%', left: '15%' },
  ]

  return (
    <div className="relative w-full h-full  p-5 font-sans overflow-hidden">
      {/* Background Layer */}
      <div className="absolute inset-0">
        {backgroundNumbers.map((item, index) => (
          <div
            key={index}
            className="text-gray-300 text-xs absolute"
            style={{
              opacity: item.opacity,
              top: item.top,
              left: item.left,
              transform: 'translate(-50%, -50%)',
              whiteSpace: 'nowrap',
            }}
          >
            {item.number}
          </div>
        ))}
      </div>

      {/* Foreground Phone Cards */}
      <div className="relative flex flex-col items-center">
        <PhoneCard
          number="(415) 968-1851"
          location="Mill Valley, CA"
          price="$15/month"
          style={{ marginBottom: '-20px', zIndex: 3 }}
        />
        <PhoneCard
          number="(628) 555-0113"
          location="Oakland, CA"
          price="$15/month"
          style={{ marginBottom: '-20px', marginLeft: '12px', zIndex: 2 }}
        />
        <PhoneCard
          number="(510) 555-0198"
          location="Oakland, CA"
          price="$18/month"
          style={{ zIndex: 1, marginLeft: '18px' }}
        />
      </div>
    </div>
  )
}

const MAX_LABEL_WIDTH = 300

function EditableLabel({ number, onUpdate, setLoading }) {
  const [isEditing, setIsEditing] = useState(false)
  const [label, setLabel] = useState(number.label)
  const [originalLabel, setOriginalLabel] = useState(number.label)

  const handleLabelChange = (e) => {
    setLabel(e.target.value)
  }

  const handleSubmit = async () => {
    setIsEditing(false)
    setLoading(true)
    if (label !== originalLabel) {
      try {
        const token = getAuthToken() // Adjust this based on how you store the token
        const orgId = getOrgId()
        const response = await axios.post(
          `${config.API_URL}/inbound/update_label`,
          {
            phoneNumber: number.phone_number,
            newLabel: label,
          },
          {
            headers: {
              Authorization: token,
              ...(orgId && { 'x-bland-org-id': orgId }),
            },
          },
        )

        if (response.status === 200) {
          onUpdate(number.phone_number, label)
          toast.success('Label updated successfully')
          setIsEditing(false)
          setLoading(false)
          setOriginalLabel(label)
        }
        else {
          throw new Error(response.data.error || 'Failed to update label')
        }
      }
      catch (error) {
        setLoading(false)
        console.error('Error updating label:', error)
        toast.error(error.response?.data?.error || 'Failed to update label')
        setLabel(originalLabel) // Revert to original label on error
      }
    }
    else {
      setLoading(false)
      setIsEditing(false)
    }
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSubmit()
    }
  }

  return isEditing
    ? (
        <div
          className="flex items-center"
          style={{ width: `${MAX_LABEL_WIDTH}px` }}
        >
          <input
            type="text"
            value={label}
            onChange={handleLabelChange}
            onKeyDown={handleKeyDown}
            style={{ width: `${MAX_LABEL_WIDTH}px` }}
            className="px-1.5 py-0.5 border rounded-sm mr-1.5"
            autoFocus
          />
          <AiOutlineEnter
            onClick={handleSubmit}
            className="cursor-pointer text-blue-500 hover:text-blue-700"
          />
        </div>
      )
    : (
        <div className="flex items-center">
          <div
            onClick={() => setIsEditing(true)}
            className="cursor-pointer hover:bg-gray-100 px-1.5 py-0.5 rounded-sm flex items-center"
            style={{ maxWidth: `${MAX_LABEL_WIDTH}px` }}
          >
            {label
              ? (
                  <span
                    className="mr-1.5 truncate"
                    style={{ maxWidth: `${MAX_LABEL_WIDTH - 30}px` }}
                    title={label}
                  >
                    {label}
                  </span>
                )
              : (
                  <span className="mr-1.5 text-gray-400" fontStyle="italic">
                    Add a label
                  </span>
                )}

            <FiEdit2
              className="text-gray-400 hover:text-blue-500 flex-shrink-0"
              size={16}
            />
          </div>
        </div>
      )
}

const ITEMS_PER_PAGE = 10

export default function PhoneNumber() {
  const JSONmethods = useForm()
  const inboundMethods = useForm({
    defaultValues: {
      prompt: '',
      voice: 'nat',
      pathway_id: null,
      pathway_version: null,
      interruption_threshold: 100,
      transfer_list: [],
      transfer_phone_number: null,
      first_sentence: null,
      max_duration: 30,
      model: 'base',
      record: false,
      ignore_button_press: false,
      temperature: null,
      dynamic_data: null,
      request_data: [],
      memory_id: null,
      webhook: null,
      pronunciation_guide: null,
      language: 'en-US',
      analysis_schema: [],
      summary_prompt: null,
      webhook_events: null,
      keywords: null,
      noise_cancellation: false,
    },
  })

  const { formState: { dirtyFields }, handleSubmit } = inboundMethods

  const jsonMode = JSONmethods.watch('json_mode_enabled')
  const { org, user } = useAuth()

  const isEnterprise = org?.org_type === 'enterprise'

  const [paywall, setPaywall] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [configJustSaved, setConfigJustSaved] = useState(false)

  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const selectedNumber = searchParams.get('number')?.trimStart()
  const activeTab = searchParams.get('tab') || 'call'

  const { inboundNumbers, twilioNumbers, loading, setLoading, refreshNumbers }
    = usePhoneNumbers()

  const [initialLoading, setInitialLoading] = useState(true)
  const [showingPurchaseScreen, setShowingPurchaseScreen] = useState(false)

  const [userNumbers, setUserNumbers] = useState([])
  const [filteredNumbers, setFilteredNumbers] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [currentPage, setCurrentPage] = useState(1)

  // Redirect non-enterprise users if they try to access SMS tab via URL
  useEffect(() => {
    if (activeTab === 'sms' && !isEnterprise && selectedNumber) {
      // Create new searchParams to preserve the number parameter
      const newParams = new URLSearchParams(searchParams)
      newParams.set('tab', 'call')
      setSearchParams(newParams)
    }
  }, [activeTab, isEnterprise, selectedNumber, searchParams, setSearchParams])

  const handleBuyNewNumber = () => {
    setPaywall(true)
    setShowingPurchaseScreen(true)
  }

  const handleBackToListing = () => {
    setPaywall(false)
    setShowingPurchaseScreen(false)
  }

  const handleTabChange = (key) => {
    searchParams.set('tab', key)
    setSearchParams(searchParams)
  }

  const hasInboundNumber
    = inboundNumbers?.length > 0 || twilioNumbers?.length > 0

  useEffect(() => {
    const inboundNumbersMarked = inboundNumbers.map(num => ({
      ...num,
      isBYOT: false,
      id: uuidv4(),
    }))
    const twilioNumbersMarked = twilioNumbers.map(num => ({
      ...num,
      isBYOT: true,
      id: uuidv4(),
    }))
    setUserNumbers([
      ...(inboundNumbersMarked || []),
      ...(twilioNumbersMarked || []),
    ])
  }, [inboundNumbers, twilioNumbers])

  const filterNumbers = useCallback((numbersToFilter, term) => {
    return numbersToFilter.filter(
      number =>
        number.phone_number?.toLowerCase().includes(term.toLowerCase())
        || number.location?.toLowerCase().includes(term.toLowerCase())
        || (number.label
          && number.label.toLowerCase().includes(term.toLowerCase())),
    )
  }, [])

  useEffect(() => {
    const filtered = filterNumbers(userNumbers, searchTerm)
    setFilteredNumbers(filtered)
  }, [userNumbers, searchTerm, filterNumbers])

  const handleLabelUpdate = useCallback((id, newLabel) => {
    setUserNumbers((prevNumbers) => {
      const updatedNumbers = prevNumbers.map(num =>
        num.id === id ? { ...num, label: newLabel } : num,
      )
      return updatedNumbers
    })
  }, [])

  const [billingUrl, setBillingUrl] = useState(null)
  const getBillingUrl = async () => {
    try {
      const response = await $fetch(`/orgs/${org?.id}/billing/session`)

      if (response?.data?.redirect_url) {
        return setBillingUrl(response?.data?.redirect_url)
      }
    }
    catch (error) {}
  }

  useEffect(() => {
    const initializeData = async () => {
      await refreshNumbers()
      await getBillingUrl()
      setInitialLoading(false)
    }
    initializeData()
  }, [refreshNumbers])

  const onSubmit = async (formData) => {
    setSubmitting(true)

    if (activeTab === 'sms') {
      // Handle SMS configuration submission using the new endpoint
      const { sms_config, encrypted_key, pathway_id, pathway_version } = formData

      // Add validation for BYOT numbers that require messaging service SID
      if (encrypted_key && !sms_config?.messaging_service_sid) {
        toast.error('BYOT numbers require a Messaging Service SID for SMS functionality.')
        setSubmitting(false)
        return
      }

      try {
        await $fetch(`/v1/sms/number/update`, {
          method: 'POST',
          body: {
            phone_number: selectedNumber,
            ...sms_config,
          },
          headers: {
            ...(encrypted_key ? { encrypted_key } : {}),
          },
        })
        setConfigJustSaved(true)
        setTimeout(() => {
          setConfigJustSaved(false)
        }, 7000)
      }
      catch (error) {
        console.error(error)
        toast.error('Error updating SMS configuration')
      }
      finally {
        setSubmitting(false)
      }
    }
    else {
      // Handle call configuration submission (existing code)
      const { task, encrypted_key, sms_config, ...rest } = formData

      try {
        if (rest.memory_id === true) {
          try {
            const { data } = await $fetch('/v1/memory/inbound/enable', {
              method: 'POST',
              body: { phone_number: selectedNumber },
            })

            if (data?.memory_id) {
              rest.memory_id = data.memory_id
              addToast({
                title: 'Memory enabled successfully',
                color: 'success',
              })
            }
            else {
              rest.memory_id = null
              addToast({
                title: 'Failed to enable memory',
                description: 'No memory ID returned',
                color: 'error',
              })
            }
          }
          catch (error) {
            addToast({
              title: 'Failed to enable memory',
              description: error.message || 'Unknown error',
              color: 'error',
            })
            rest.memory_id = null
          }
        }
        else if (rest.memory_id === false || rest.memory_id === null) {
          rest.memory_id = null
        }

        const data = parseForJson({ ...rest, prompt: task }, dirtyFields)

        await $fetch(`/v1/inbound/${selectedNumber}`, {
          method: 'POST',
          body: data,
          headers: {
            ...(encrypted_key ? { encrypted_key } : {}),
          },
        })
        addToast({
          title: 'Success updating inbound number details!',
          color: 'success',
        })
      }
      catch (error) {
        addToast({
          title: 'Error updating configuration',
          description: error.message || 'Unknown error',
          color: 'error',
        })
      }
      finally {
        setSubmitting(false)
      }
    }
  }

  const paginatedNumbers = useMemo(() => {
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE
    const endIndex = startIndex + ITEMS_PER_PAGE
    return filteredNumbers.slice(startIndex, endIndex)
  }, [filteredNumbers, currentPage])

  const hasSmsConfig = (number) => {
    return !!number.sms_config && (
      number.sms_config.objective
      || number.sms_config.pathway_id
    )
  }

  const totalPages = useMemo(
    () => Math.ceil(filteredNumbers.length / ITEMS_PER_PAGE),
    [filteredNumbers],
  )

  if (initialLoading) {
    return (
      <PageWrapper>
        <div className="flex justify-center items-center h-screen">
          <Loading loading />
        </div>
      </PageWrapper>
    )
  }

  return (
    <PageWrapper padding="54px 0px 0px 54px">
      <ActionBar top spaceBetween shiftRight>
        <PageTitle>
          Phone Numbers
          {selectedNumber && (
            <span className="text-xs text-gray-500 ml-2.5">{selectedNumber}</span>
          )}
        </PageTitle>
        {selectedNumber && (
          <Tabs variant="underlined" selectedKey={activeTab} onSelectionChange={handleTabChange}>
            <Tab
              key="call"
              title={(
                <div className="flex items-center space-x-2 pb-1">
                  <Phone size={16} />
                  <span>Voice Settings</span>
                </div>
              )}
            />
            <Tab
              key="sms"
              isDisabled={!isEnterprise}
              title={(
                <div className="flex items-center space-x-2 pb-1">
                  <MessageSquare size={16} />
                  <span>SMS Settings</span>
                  <Chip color="primary" variant="flat" size="sm">New</Chip>
                  {!isEnterprise && (
                    <Chip color="warning" variant="flat" size="sm">Enterprise</Chip>
                  )}
                </div>
              )}
            />
          </Tabs>
        )}
        <div className="flex flex-row items-center gap-3">
          <FormProvider {...JSONmethods}>
            <form>
              <Switch fieldName="json_mode_enabled" label="JSON Mode" />
            </form>
          </FormProvider>
          {!showingPurchaseScreen && !selectedNumber && (
            <HeroButton size="sm" variant="solid" color="primary" onPress={handleBuyNewNumber}>Buy New Number</HeroButton>
          )}
        </div>
      </ActionBar>
      <div className="h-full max-h-full w-full relative">
        {paywall && (
          <div>
            <div className="flex justify-between items-center mb-2.5">
              <Button onClick={handleBackToListing}>Back</Button>
            </div>
            <PurchaseNumber either setPaywall={setPaywall} />
          </div>
        )}
        <div className="w-full h-full flex">
          {!hasInboundNumber
            ? (
                <PurchaseNumber either />
              )
            : (
                <>
                  {!selectedNumber
                    ? (
                        <div className="w-5/6 pt-12 mx-auto max-h-full overflow-y-auto pb-[54px] py-5">
                          <div className="flex flex-row w-full pb-8">
                            <div className="w-1/2">
                              <h1 className="text-xl font-bold text-gray-900 mb-1.5">
                                Phone Numbers
                              </h1>
                              <p className="text-xs text-gray-500 mb-4 w-1/2">
                                Purchase and configure a new inbound phone number.
                                ($15/mo. subscription using your stored payment method).
                              </p>
                              <Button
                                onClick={handleBuyNewNumber}
                                style={{ width: '35%' }}
                                className="bg-indigo-20 text-white px-4 py-1.5 w-1/4 rounded-sm text-xs font-semibold mb-5 hover:bg-indigo-900 transition-colors inline-flex items-center"
                              >
                                PURCHASE NEW NUMBER
                                <FiChevronRight className="my-auto" />
                              </Button>
                            </div>
                            <div className="w-1/2">
                              <PhoneNumberComponent />
                            </div>
                          </div>

                          <div className="bg-white rounded-sm  font-mono">
                            <div className="flex justify-between items-center p-2.5 border-b border-gray-200 pb-5">
                              <div className="flex justify-left gap-3 items-center">
                                <div className="relative">
                                  <FiSearch className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400" />
                                  <input
                                    type="text"
                                    placeholder="SEARCH NUMBER"
                                    value={searchTerm}
                                    onChange={e => setSearchTerm(e.target.value)}
                                    className="pl-6 pr-2.5 py-1.5 w-40 bg-gray-100 rounded-sm text-xs focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                  />
                                </div>
                                <button onClick={refreshNumbers}>
                                  <FiRefreshCw className="mr-1.5" />
                                </button>
                                <Loading loading={loading} />
                              </div>
                              <div className="flex space-x-1.5">
                                <span className="text-base">🇺🇸</span>
                                <span className="text-base">🇨🇦</span>
                              </div>
                            </div>
                            <table className="w-full">
                              <thead>
                                <tr className="bg-gray-50 text-left text-2xs font-semibold text-gray-500 uppercase tracking-wider">
                                  <th className="px-4 py-2">Number</th>
                                  <th className="px-4 py-2">Label</th>
                                  <th className="px-4 py-2">State</th>
                                  <th className="px-4 py-2">Status</th>
                                  <th className="px-4 py-2">Purchased Date</th>
                                  <th className="px-4 py-2">SMS</th>
                                  <th className="px-4 py-2">Configuration</th>
                                  <th className="px-4 py-2">Billing</th>
                                </tr>
                              </thead>
                              <tbody className="divide-y divide-gray-200">
                                {paginatedNumbers.map(number => (
                                  <tr key={number.id} className="hover:bg-gray-50">
                                    <td className="px-4 py-2.5 whitespace-nowrap text-xs font-medium text-gray-900">
                                      {number.phone_number}
                                    </td>
                                    <td className="px-4 py-2.5 whitespace-nowrap text-xs font-medium text-gray-900">
                                      <EditableLabel
                                        number={number}
                                        onUpdate={handleLabelUpdate}
                                        setLoading={setLoading}
                                      />
                                      {number.isBYOT && (
                                        <img
                                          title="Twilio (BYOT Number)"
                                          alt="Twilio"
                                          src={TwilioCircle}
                                          style={{
                                            width: 20,
                                            height: 20,
                                          }}
                                        />
                                      )}
                                    </td>
                                    <td className="px-4 py-2.5 whitespace-nowrap text-xs text-gray-500">
                                      {number.location}
                                    </td>
                                    <td className="px-4 py-2.5 whitespace-nowrap">
                                      <span className="px-1.5 py-1.5 inline-flex text-2xs leading-3 font-semibold bg-green-100 text-green-800">
                                        ACTIVE
                                      </span>
                                    </td>
                                    <td className="px-4 py-2.5 whitespace-nowrap text-xs text-gray-500">
                                      {new Date(number.created_at).toLocaleDateString(
                                        'en-US',
                                        {
                                          year: 'numeric',
                                          month: 'long',
                                          day: 'numeric',
                                        },
                                      )}
                                    </td>
                                    <td className="px-4 py-2.5 whitespace-nowrap">
                                      {hasSmsConfig(number)
                                        ? (
                                            <span className="px-1.5 py-1.5 inline-flex text-2xs leading-3 font-semibold bg-purple-100 text-purple-800">
                                              CONFIGURED
                                            </span>
                                          )
                                        : (
                                            <span className="px-1.5 py-1.5 inline-flex text-2xs leading-3 font-semibold bg-gray-100 text-gray-600">
                                              NOT SET
                                            </span>
                                          )}
                                    </td>
                                    <td className="px-4 py-2.5 whitespace-nowrap text-xs">
                                      <div className="flex space-x-1.5">
                                        <button
                                          onClick={() =>
                                            navigate(
                                              `/dashboard/phone-numbers?number=${encodeURIComponent(number.phone_number)}&tab=call`,
                                            )}
                                          className={`px-2 py-0.5 rounded-sm text-2xs font-medium ${
                                            number.isBYOT
                                              ? 'text-indigo-600 hover:bg-indigo-50'
                                              : 'text-blue-600 hover:bg-blue-50'
                                          } border border-gray-300 inline-flex items-center`}
                                          title="Configure voice settings for this number"
                                        >
                                          <FiSettings size={10} className="mr-0.5" />
                                          SETUP CALL
                                        </button>

                                        {/* SMS configuration button */}
                                        <button
                                          onClick={() =>
                                            isEnterprise
                                              ? navigate(
                                                  `/dashboard/phone-numbers?number=${encodeURIComponent(number.phone_number)}&tab=sms`,
                                                )
                                              : null}
                                          className={`px-2 py-0.5 rounded-sm text-2xs font-medium
                                            text-purple-600 hover:bg-purple-50
                                            border border-purple-300 inline-flex items-center
                                            ${!isEnterprise ? 'opacity-50 cursor-not-allowed' : ''}`}
                                          title={!isEnterprise ? 'Enterprise feature' : 'Configure SMS settings for this number'}
                                          disabled={!isEnterprise}
                                        >
                                          <FiSettings size={10} className="mr-0.5" />
                                          SETUP SMS
                                          {!isEnterprise && (
                                            <span className="ml-1 text-2xs">✧</span>
                                          )}
                                        </button>
                                      </div>
                                    </td>
                                    <td className="px-4 py-2.5 whitespace-nowrap text-xs">
                                      {number.isBYOT
                                        ? (
                                            <p>-</p>
                                          )
                                        : (
                                            <button
                                              style={{ cursor: 'pointer', zIndex: 2000 }}
                                              type="button"
                                              className="px-2 py-0.5 rounded-sm text-2xs font-medium text-blue-600 hover:bg-blue-50 border border-gray-300 inline-flex items-center"
                                              onClick={(event) => {
                                                if (!billingUrl) {
                                                  return toast.error(
                                                    'No billing found on the selected account.',
                                                  )
                                                }
                                                event.stopPropagation()
                                                window.open(billingUrl, '_blank')
                                              }}
                                            >
                                              BILLING
                                              <ExternalLink size={10} className="ml-1.5" />
                                            </button>
                                          )}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      )
                    : (
                        <FormProvider {...inboundMethods}>
                          <Form
                            onSubmit={handleSubmit(onSubmit)}
                            className="h-full max-h-full w-full relative"
                          >
                            {jsonMode
                              ? (
                                  <JSONMode submitting={submitting} />
                                )
                              : (
                                  <Inbound
                                    selectedNumber={selectedNumber}
                                    apiKey={user?.user?.api_key}
                                    hasInbound={hasInboundNumber}
                                    submitting={submitting}
                                    configJustSaved={configJustSaved}
                                  />
                                )}
                          </Form>
                        </FormProvider>
                      )}
                </>
              )}
        </div>
      </div>
      {!selectedNumber && (
        <ActionBar bottom>
          <div className="flex items-center pl-7 w-full justify-between">
            <span className="text-xs text-gray-700">
              Showing
              {' '}
              {(currentPage - 1) * ITEMS_PER_PAGE + 1}
              {' '}
              to
              {' '}
              {Math.min(currentPage * ITEMS_PER_PAGE, filteredNumbers.length)}
              {' '}
              of
              {' '}
              {filteredNumbers.length}
              {' '}
              results
            </span>
          </div>
          <div className="flex items-center space-x-1.5">
            <RButton
              variant="soft"
              disabled={currentPage === 1}
              onClick={() => setCurrentPage(prev => Math.max(1, prev - 1))}
            >
              Previous
            </RButton>
            <span className="px-2.5 py-1.5 text-xs shrink-0">
              Page
              {' '}
              {currentPage}
              {' '}
              of
              {' '}
              {totalPages}
            </span>
            <RButton
              variant="soft"
              disabled={currentPage >= totalPages}
              onClick={() =>
                setCurrentPage(prev => Math.min(totalPages, prev + 1))}
            >
              Next
            </RButton>
          </div>
        </ActionBar>
      )}
    </PageWrapper>
  )
}
