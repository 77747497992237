// components/Settings/GeneralSettings/WebhookSettings.js
import { Button, Skeleton, TextField } from '@radix-ui/themes'
import WebhookSigningSecret from 'components/AccountSupport/Settings/WebhookSigningSecret'

/**
 * Handles showing & updating the webhook URL
 * Props:
 *   - webhookUrl
 *   - setWebhookUrl
 *   - savedWebhookUrl
 *   - gettingWebhook
 *   - updatingWebhook
 *   - handleUpdateWebhookUrl()
 */
export default function WebhookSettings({
  webhookUrl,
  setWebhookUrl,
  savedWebhookUrl,
  gettingWebhook,
  updatingWebhook,
  handleUpdateWebhookUrl,
}) {
  const isDirty = savedWebhookUrl !== webhookUrl

  return (
    <div style={{ marginTop: 25 }}>
      <p style={{ fontSize: 14, fontWeight: 400 }}>Webhook Delivery URL</p>
      <p style={{ marginBottom: 10, color: 'GrayText', fontSize: 12 }}>
        New webhook events will be delivered to this address. Only HTTPS
        webhooks may be used.
      </p>

      {gettingWebhook
        ? (
            <Skeleton>
              <TextField.Root
                style={{ marginTop: 7, borderRadius: 2 }}
                placeholder="https://example.com/webhook"
                value={webhookUrl}
                onChange={e => setWebhookUrl(e.target.value)}
                size="3"
              />
            </Skeleton>
          )
        : (
            <TextField.Root
              style={{
                marginTop: 7,
                borderRadius: 2,
              }}
              placeholder="https://example.com/webhook"
              value={webhookUrl}
              onChange={e => setWebhookUrl(e.target.value)}
              size="3"
            />
          )}

      {isDirty && (
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 10 }}>
          <Button
            style={{ borderRadius: 4, cursor: 'pointer' }}
            variant="solid"
            color="iris"
            onClick={handleUpdateWebhookUrl}
            disabled={updatingWebhook}
            loading={updatingWebhook ? 1 : undefined}
          >
            Save
          </Button>
        </div>
      )}

      <div style={{ marginTop: 25 }}>
        <WebhookSigningSecret />
      </div>
    </div>
  )
}
