import { $fetch } from 'utils/fetch'

async function fetchRecording(recordingUrl) {
  try {
    const res = await fetch(recordingUrl)

    if (res.status === 200) {
      const audioBlob = await res.blob()
      return URL.createObjectURL(audioBlob)
    }
    else if (res.status === 404) {
      return 'Not complete'
    }
    else {
      console.error('Error fetching recording:', res.status)
      return null
    }
  }
  catch (error) {
    console.error('Error fetching recording:', error)
    return null
  }
}

async function fetchRecordingSrc(callId) {
  try {
    const res = await $fetch(`/v1/recordings/${callId}?RequestedChannels=2`, {
      responseType: 'blob',
    })

    return res
  }
  catch (error) {
    return null
  }
}

export async function fetchAudioRecording(recordingUrl) {
  let audioUrl = null
  let recUrl = null

  if (recordingUrl) {
    recUrl = `${recordingUrl?.replace('.mp3', '.wav')}?RequestedChannels=2`

    audioUrl = await fetchRecording(recUrl)

    if (audioUrl === 'Not complete') {
      toast.error(
        'Recording not available, the call is either not complete, or does not exist.',
      )
    }
    else if (!audioUrl) {
      recUrl = recordingUrl?.replace('.mp3', '.wav')
      audioUrl = await fetchRecording(recUrl)
    }
  }

  return audioUrl
}
