import GradientLoadingAnimation from 'components/Reusables/GradientLoadingAnimation'
import config from 'config'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { getAuthToken } from 'utils/funcs/browser/getAuthToken'
import { getOrgId } from 'utils/funcs/browser/getOrgId'

function IVRModal({
  id,
  data,
  setIsOpen,
  IVRActiveTab: activeTab,
  setIVRActiveTab: setActiveTab,
  setPrompt,
  setCondition,
  setUseStaticText,
  setIsConditionChecked,
  onSurveyUpdate,
}) {
  const [surveyResponses, setSurveyResponses] = useState(
    data.IVRSurvey || {
      purpose: '',
      companyName: '',
      accountInfo: '',
      department: '',
      specificActions: '',
      timeConstraints: '',
      menuOptions: '',
      sensitiveInfo: '',
      phrases: '',
      desiredOutcome: '',
    },
  )

  const [previewContent, setPreviewContent] = useState({
    prompt: '',
    condition: '',
  })

  const [isGenerating, setIsGenerating] = useState(false)

  const questions = [
    { id: 'purpose', label: 'What is the main purpose of this call?' },
    {
      id: 'companyName',
      label: 'What is the name of the company or organization you\'re calling?',
    },
    {
      id: 'accountInfo',
      label:
        'Do you have any account numbers, policy numbers, or other identification information required?',
    },
    {
      id: 'department',
      label: 'What specific department or service do you need to reach?',
    },
    {
      id: 'specificActions',
      label:
        'Are there any specific questions you need answered or actions you need completed?',
    },
    {
      id: 'timeConstraints',
      label: 'Do you have any time constraints for this call?',
    },
    {
      id: 'menuOptions',
      label: 'Are there any known menu options or extensions you\'re aware of?',
    },
    {
      id: 'sensitiveInfo',
      label:
        'Is there any sensitive information that needs to be handled carefully?',
    },
    {
      id: 'phrases',
      label:
        'Are there any specific phrases or keywords you want the AI to use or avoid?',
    },
    {
      id: 'desiredOutcome',
      label: 'What is your preferred outcome for this call?',
    },
  ]

  const handleInputChange = (id, value) => {
    setSurveyResponses(prev => ({
      ...prev,
      [id]: value,
    }))
  }

  const generatePreview = async (responses) => {
    setIsGenerating(true)

    try {
      const token = getAuthToken()
      const orgId = getOrgId()
      const formattedResponses = Object.entries(responses)
        .filter(([, answer]) => answer.trim() !== '')
        .map(([question, answer]) => ({ question, answer }))

      //   console.log(formattedResponses);

      const response = await fetch(
        `${config.API_URL}/v1/pathway/generate_ivr_prompt`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`,
            ...(orgId && { 'x-bland-org-id': orgId }),
          },
          body: JSON.stringify({
            IVRSurvey: formattedResponses,
          }),
        },
      )

      if (!response.ok) {
        throw new Error('Failed to generate IVR prompt')
      }

      const data = await response.json()
      const { prompt, loop_condition } = data.data

      await new Promise(resolve => setTimeout(resolve, 2000))
      setPrompt(prompt)
      setCondition(loop_condition)
      setUseStaticText(false)
      setIsConditionChecked(true)

      onSurveyUpdate(responses)

      setActiveTab('preview')
    }
    catch (error) {
      console.error('Generation failed:', error)
      toast.error('Generation failed')
    }
    finally {
      setIsGenerating(false)
    }
  }

  if (isGenerating) {
    return (
      <div className="flex flex-col items-center justify-center h-[80vh] bg-gray-50">
        <GradientLoadingAnimation
          message="Cerating optimal IVR Navigation Prompt..."
          variant="arcticAurora"
          duration={2}
          width={32}
          rounded="full"
        />
      </div>
    )
  }

  if (activeTab === 'questions') {
    return (
      <div className="flex flex-col h-[80vh] bg-gray-50">
        <div className="p-4 bg-white border-b">
          <h2 className="text-lg font-bold text-gray-900">
            IVR Navigation Setup Builder
          </h2>
          <p className="mt-1.5 text-xs text-gray-600">
            Help the agent navigate automated phone systems by providing details
            about your call. These questions will generate specific instructions
            for menu navigation, account verification, and reaching the right
            department. Answer the relevant questions.
          </p>

          <button
            onClick={() => setActiveTab('preview')}
            className="bg-gray-100 hover:bg-gray-200 rounded p-1.5 mt-1.5"
          >
            Switch to Prompt
          </button>
        </div>

        <div className="flex-1 overflow-hidden">
          <div className="h-full overflow-y-auto px-4 py-4">
            <div className="max-w-4xl space-y-4">
              {questions.map(({ id, label }) => (
                <div key={id} className="space-y-1.5">
                  <label className="block text-xs font-medium text-gray-900">
                    {label}
                  </label>
                  <textarea
                    value={surveyResponses[id]}
                    onChange={e => handleInputChange(id, e.target.value)}
                    className="w-full min-h-[80px] p-2 border border-gray-200 rounded-md shadow-sm
                             focus:ring-2 focus:ring-indigo-500 focus:border-transparent
                             text-xs text-gray-900 bg-white"
                    placeholder="Enter your response..."
                  />
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="sticky bottom-0 flex justify-end gap-2 px-4 py-2.5 pb-5 bg-white border-t">
          <button
            onClick={() => setIsOpen(false)}
            className="px-2.5 py-1.5 text-xs font-medium text-gray-700 bg-white border border-gray-300
                     rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2
                     focus:ring-indigo-500 focus:ring-offset-2"
          >
            Cancel
          </button>
          <button
            onClick={() => generatePreview(surveyResponses)}
            className="px-2.5 py-1.5 text-xs font-medium text-white bg-indigo-600
                     rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2
                     focus:ring-indigo-500 focus:ring-offset-2"
          >
            Generate Navigation
          </button>
        </div>
      </div>
    )
  }

  return null
}

export default IVRModal
