import { Button, Chip, Drawer, DrawerBody, DrawerContent, DrawerFooter, DrawerHeader, Select, SelectItem, SelectSection } from '@heroui/react'
import { useQuery } from '@tanstack/react-query'
import { ChevronDown, Clock, Eye, FileText, MemoryStick, Phone, Plus } from 'lucide-react'
import { forwardRef, useState } from 'react'
import { $fetch } from 'utils/fetch'
import CreateMemoryForm from './CreateMemoryForm'
import MemoryDetailsView from './MemoryDetailsView'
import { useNavigate } from 'react-router-dom'

const MemorySelect = forwardRef(({ onChange, value, ...props }, ref) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [sheetMode, setSheetMode] = useState('view')
  const [selectedMemoryId, setSelectedMemoryId] = useState(null)
  const [selectedMemoryName, setSelectedMemoryName] = useState('')
  const [isChangingMemory, setIsChangingMemory] = useState(false)
  const navigate = useNavigate()

  const { data, refetch } = useQuery({
    queryKey: ['memories'],
    queryFn: async () => {
      const { data } = await $fetch('/v1/memory')
      return data?.memories || []
    },
  })

  // Ensure memories is always an array, even when data is undefined
  const memories = data || []

  const handleSpecialActions = (action) => {
    if (action === 'create-new') {
      // Open in new tab with a query parameter to trigger modal
      window.open('/dashboard/memory?create=true', '_blank')
    }
  }

  const handleViewMemory = (memoryId, memoryName) => {
    // Open in new tab
    window.open(`/dashboard/memory/${memoryId}`, '_blank')
  }

  const handleMemoryCreated = (newMemory) => {
    setIsDrawerOpen(false)
    onChange(newMemory.id)
    refetch()
  }

  return (
    <div className="w-full">
      <div className="space-y-1">
        {memories.length > 0 ? (
          <>
            <div className="flex justify-between items-center">
              <label htmlFor="memory-select" className="text-small font-medium text-foreground">
                Memory
              </label>
              <a 
                href="/dashboard/memory" 
                className="text-xs text-indigo-600 hover:text-indigo-800 dark:text-indigo-400 dark:hover:text-indigo-300"
                onClick={(e) => {
                  e.preventDefault()
                  navigate('/dashboard/memory', { 
                    state: { returnTo: '/dashboard/send-call' } 
                  })
                }}
              >
                View all memory stores
              </a>
            </div>
            <p className="text-tiny text-foreground-400 pb-2">
              Personalize your calls by enabling a memory store. Your agent will remember past conversations and non-personal user information.
            </p>
            
            {value && !isChangingMemory ? (
              // Show this UI when a memory is selected and not changing
              <div className="border rounded-md p-3 bg-indigo-50 dark:bg-indigo-900/20">
                <div className="flex items-center justify-between">
                  <div className="flex items-center space-x-2">
                    <div className="w-8 h-8 bg-indigo-100 dark:bg-indigo-800 rounded-lg flex items-center justify-center">
                      <MemoryStick className="h-4 w-4 text-indigo-600 dark:text-indigo-400" />
                    </div>
                    <div>
                      <div className="flex items-center space-x-2">
                        <h5 className="font-medium text-gray-900 dark:text-white">
                          {memories.find(m => m.id === value)?.name || 'Memory enabled'}
                        </h5>
                        <Chip color="primary" variant="flat" size="sm" className="h-5">Enabled</Chip>
                      </div>
                      <p className="text-xs font-geist-mono text-gray-500 dark:text-gray-400">{value}</p>
                    </div>
                  </div>
                  <div className="flex items-center space-x-2">
                    <Button 
                      size="sm" 
                      variant="flat" 
                      color="default"
                      onPress={() => onChange(null)}
                      className="h-8"
                    >
                      Disable
                    </Button>
                    <Button
                      size="sm"
                      variant="flat"
                      color="primary"
                      onPress={() => window.open(`/dashboard/memory/${value}`, '_blank')}
                      startContent={<Eye className="h-3.5 w-3.5" />}
                      className="h-8"
                    >
                      View Memory
                    </Button>
                    <Button
                      size="sm"
                      variant="flat"
                      color="primary"
                      startContent={<ChevronDown className="h-3.5 w-3.5" />}
                      className="h-8"
                      onClick={() => setIsChangingMemory(true)}
                    >
                      Change
                    </Button>
                  </div>
                </div>
                <p className="text-xs text-gray-600 dark:text-gray-300 mt-2 pl-10">
                    Calls to this phone number will be remembered, and will be referenced for future calls to the same phone number.
                </p>
              </div>
            ) : (
              // Show the dropdown when no memory is selected or changing memory
              <div>
                <Select
                  id="memory-select"
                  placeholder="Select a memory"
                  value={value || ""}
                  onChange={(e) => {
                    const val = e.target.value;
                    if (val === 'create-new') {
                      handleSpecialActions(val);
                      return;
                    }
                    onChange(val === "" ? null : val);
                    setIsChangingMemory(false);
                  }}
                  variant="bordered"
                  radius="sm"
                  labelPlacement="outside"
                  className="w-full"
                  ref={ref}
                  {...props}
                >
                  <SelectSection title="Memory Stores" showDivider>
                    {memories.map(memory => (
                      <SelectItem
                        key={memory.id}
                        value={memory.id}
                        textValue={memory.name}
                        endContent={(
                          <div className="flex items-center space-x-2">
                            <p className="text-xs font-geist-mono text-muted-foreground">{memory.id}</p>
                          </div>
                        )}
                      >
                        {memory.name}
                      </SelectItem>
                    ))}
                  </SelectSection>
                  <SelectSection>
                    <SelectItem key="create-new" value="create-new" textValue="Create New Memory">
                      <div className="flex items-center text-indigo-600">
                        <Plus className="mr-2 h-4 w-4" />
                        Create New Memory
                      </div>
                    </SelectItem>
                  </SelectSection>
                </Select>
                {isChangingMemory && (
                  <div className="mt-2 flex justify-end">
                    <Button
                      size="sm"
                      variant="flat"
                      color="default"
                      onClick={() => setIsChangingMemory(false)}
                    >
                      Cancel
                    </Button>
                  </div>
                )}
              </div>
            )}
          </>
        ) : (
          <div className="border border-dashed border-gray-300 dark:border-gray-700 rounded-lg p-6 text-center">
            <div className="w-12 h-12 bg-indigo-100 dark:bg-indigo-900/40 rounded-full flex items-center justify-center mx-auto mb-4">
              <MemoryStick className="h-6 w-6 text-indigo-600 dark:text-indigo-400" />
            </div>
            <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-2">No memory stores configured</h3>
            <p className="text-sm text-gray-500 dark:text-gray-400 mb-4">
              Memory stores allows your agent to remember past conversations and user information across sessions.
            </p>
            <Button 
              color="primary" 
              variant="solid" 
              className="mx-auto"
              onPress={() => window.open('/dashboard/memory?create=true', '_blank')}
              startContent={<Plus className="h-4 w-4" />}
            >
              Create Your First Memory
            </Button>
          </div>
        )}
      </div>

      <Drawer
        isOpen={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        size="md"
        radius="none"
        placement="right"
        className="bg-white dark:bg-zinc-950"
      >
        <DrawerContent>
          <DrawerHeader className="border-b px-6 py-4 bg-white dark:bg-gray-800 shadow-sm">
            <div className="flex items-center">
              <div className="w-8 h-8 bg-indigo-100 dark:bg-indigo-900/40 rounded-lg flex items-center justify-center mr-3">
                <MemoryStick className="h-4 w-4 text-indigo-600 dark:text-indigo-400" />
              </div>
              <div>
                <h2 className="text-xl font-semibold text-gray-800 dark:text-white">
                  {sheetMode === 'view' ? `Memory: ${selectedMemoryName}` : 'Create Memory'}
                </h2>
                <p className="text-sm text-gray-500 dark:text-gray-400">
                  {sheetMode === 'view' ? `Details for memory ID: ${selectedMemoryId}` : 'Set up a new memory instance'}
                </p>
              </div>
            </div>
          </DrawerHeader>

          <DrawerBody className="bg-zinc-50 dark:bg-zinc-900">
            <div className="h-full overflow-y-auto p-6">
              {sheetMode === 'view' && (
                <MemoryDetailsView memoryId={selectedMemoryId} />
              )}

              {sheetMode === 'create' && (
                <CreateMemoryForm
                  onSuccess={handleMemoryCreated}
                  onCancel={() => setIsDrawerOpen(false)}
                />
              )}
            </div>
          </DrawerBody>

          <DrawerFooter className="border-t bg-gradient-to-r from-indigo-50 to-indigo-100 dark:from-indigo-950/30 dark:to-indigo-900/20">
            <Button
              variant="bordered"
              onClick={() => setIsDrawerOpen(false)}
              fullWidth
              className="border-indigo-200 dark:border-indigo-800 hover:bg-indigo-100 dark:hover:bg-indigo-900/30"
            >
              Close
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </div>
  )
})

export default MemorySelect
