import { Loading } from 'components/core/Loading'
import { motion } from 'framer-motion'
import {
  FastForward,
  Pause,
  Play,
  RefreshCw,
  Rewind,
  RotateCcw,
  X,
} from 'lucide-react'
import styled from 'styled-components'

const ControlsWrapper = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 45%;
  transform: translateX(-50%);
  background-color: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 50;
  padding: 0.63rem;
  border-bottom-left-radius: 0.31rem;
  border-bottom-right-radius: 0.31rem;
  min-width: 300px;
`

function ReplayControls({
  isPlaying,
  togglePlay,
  reset,
  replaySpeed,
  handleSpeedChange,
  jumpToMessage,
  replayIndex,
  totalSteps,
  onClose,
  loading,
  error,
  onRetry,
  noData,
}) {
  return (
    <ControlsWrapper
      initial={{ y: -100 }}
      animate={{ y: 0 }}
      transition={{ type: 'spring', stiffness: 300, damping: 30 }}
    >
      {loading
        ? (
            <div className="flex justify-center items-center h-12">
              <Loading loading={loading} />
            </div>
          )
        : error
          ? (
              <div className="flex flex-col items-center justify-center h-12">
                <p className="text-red-500 mb-1.5">{error}</p>
                <button
                  onClick={onRetry}
                  className="p-1.5 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors flex items-center"
                >
                  <RefreshCw size={16} className="mr-1.5" />
                  Retry
                </button>
              </div>
            )
          : noData
            ? (
                <div className="flex justify-center items-center h-12">
                  <p className="text-gray-500">
                    No pathway data available for this call
                  </p>
                </div>
              )
            : (
                <>
                  <div className="flex items-center space-x-2.5">
                    <button
                      onClick={togglePlay}
                      className="p-1.5 bg-black text-white rounded hover:bg-gray-800 transition-colors"
                    >
                      {isPlaying ? <Pause size={20} /> : <Play size={20} />}
                    </button>
                    <button
                      onClick={reset}
                      className="p-1.5 bg-gray-200 rounded hover:bg-gray-300 transition-colors"
                    >
                      <RotateCcw size={20} />
                    </button>
                    <select
                      value={replaySpeed}
                      onChange={handleSpeedChange}
                      className="p-1.5 border rounded-"
                    >
                      <option value={2000}>Slow</option>
                      <option value={1000}>Normal</option>
                      <option value={500}>Fast</option>
                    </select>
                    <button
                      onClick={() => jumpToMessage(replayIndex - 1)}
                      className="p-1.5 bg-gray-200 rounded hover:bg-gray-300 transition-colors"
                    >
                      <Rewind size={20} />
                    </button>
                    <button
                      onClick={() => jumpToMessage(replayIndex + 1)}
                      className="p-1.5 bg-gray-200 rounded hover:bg-gray-300 transition-colors"
                    >
                      <FastForward size={20} />
                    </button>
                    <button
                      onClick={onClose}
                      className="p-1.5 bg-gray-200 rounded hover:bg-gray-300 transition-colors ml-auto"
                    >
                      <X size={20} />
                    </button>
                  </div>
                  <div className="mt-1.5 text-xs text-gray-600">
                    {`Replaying step ${replayIndex + 1} of ${totalSteps}`}
                  </div>
                </>
              )}
    </ControlsWrapper>
  )
}

export default ReplayControls
