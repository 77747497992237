import React from 'react';

/**
 * Class for resolving conflicts between pathways
 */
class ConflictResolver {
  /**
   * Generate the final merged pathway based on user resolutions
   * @param {Object} productionPathway - The main production pathway
   * @param {Object} smsPathway - The SMS pathway
   * @param {Array} conflicts - The array of conflicts
   * @param {Object} resolutions - The resolutions object
   * @returns {Object} - The merged pathway
   */
  static generateFinalMergedPathway(productionPathway, smsPathway, conflicts, resolutions) {
    // Create a deep copy of the production pathway as the base
    const mergedPathway = JSON.parse(JSON.stringify(productionPathway));
    
    // Separate conflicts by type to ensure proper processing order
    const nodeConflicts = conflicts.filter(conflict => conflict.type === 'node');
    const edgeConflicts = conflicts.filter(conflict => conflict.type === 'edge');
    const globalPromptConflicts = conflicts.filter(conflict => conflict.type === 'globalPrompt');
    
    // First, resolve node conflicts
    nodeConflicts.forEach(conflict => {
      const resolution = resolutions[conflict.id];
      if (!resolution) return; // Skip if no resolution provided
      this.resolveNodeConflict(mergedPathway, conflict, resolution, resolutions);
    });
    
    // Next, resolve edge conflicts
    edgeConflicts.forEach(conflict => {
      const resolution = resolutions[conflict.id];
      if (!resolution) return; // Skip if no resolution provided
      this.resolveEdgeConflict(mergedPathway, conflict, resolution, resolutions);
    });
    
    // Finally, resolve global prompt conflicts (last to avoid overrides)
    globalPromptConflicts.forEach(conflict => {
      const resolution = resolutions[conflict.id];
      if (!resolution) return; // Skip if no resolution provided
      this.resolveGlobalPromptConflict(mergedPathway, conflict, resolution, resolutions);
    });
    
    return mergedPathway;
  }
  
  /**
   * Resolve a node conflict
   * @param {Object} mergedPathway - The merged pathway being built
   * @param {Object} conflict - The conflict object
   * @param {String} resolution - The resolution type ('production', 'sms', 'custom')
   * @param {Object} resolutions - The complete resolutions object (for field-level resolutions)
   */
  static resolveNodeConflict(mergedPathway, conflict, resolution, resolutions) {
    const nodeIndex = mergedPathway.nodes.findIndex(node => node.id === conflict.productionVersion?.id);
    
    if (resolution === 'production') {
      // Keep production version - nothing to do
      return;
    } else if (resolution === 'sms') {
      // Use SMS version
      if (nodeIndex >= 0) {
        if (conflict.smsVersion) {
          mergedPathway.nodes[nodeIndex] = JSON.parse(JSON.stringify(conflict.smsVersion));
        } else {
          // SMS version doesn't exist, remove from production
          mergedPathway.nodes.splice(nodeIndex, 1);
        }
      } else if (conflict.smsVersion) {
        // Node doesn't exist in production but exists in SMS, add it
        mergedPathway.nodes.push(JSON.parse(JSON.stringify(conflict.smsVersion)));
      }
    } else if (resolution === 'custom') {
      // Use custom version
      const customData = resolutions[`${conflict.id}_custom`] || {};
      
      if (nodeIndex >= 0) {
        // For existing node in production
        const targetNode = mergedPathway.nodes[nodeIndex];
        
        // Apply type if specified
        if (customData.type !== undefined) {
          targetNode.type = customData.type;
        }
        
        // Apply data if specified
        if (customData.data !== undefined) {
          targetNode.data = JSON.parse(JSON.stringify(customData.data));
        }
      } else {
        // Create new node with custom data
        const baseNode = conflict.productionVersion || conflict.smsVersion || {};
        const newNode = JSON.parse(JSON.stringify(baseNode));
        
        // Apply custom overrides
        if (customData.type !== undefined) {
          newNode.type = customData.type;
        }
        
        if (customData.data !== undefined) {
          newNode.data = JSON.parse(JSON.stringify(customData.data));
        }
        
        mergedPathway.nodes.push(newNode);
      }
    }
  }
  
  /**
   * Resolve an edge conflict
   * @param {Object} mergedPathway - The merged pathway being built
   * @param {Object} conflict - The conflict object
   * @param {String} resolution - The resolution type ('production', 'sms', 'custom')
   * @param {Object} resolutions - The complete resolutions object (for field-level resolutions)
   */
  static resolveEdgeConflict(mergedPathway, conflict, resolution, resolutions) {
    // Find the edge in the merged pathway by source-target combination
    const edgeIndex = mergedPathway.edges ? mergedPathway.edges.findIndex(edge => 
      edge.source === conflict.productionVersion?.source && 
      edge.target === conflict.productionVersion?.target
    ) : -1;
    
    if (resolution === 'production') {
      // Keep production version - nothing to do
      return;
    } else if (resolution === 'sms') {
      // Use SMS version
      if (edgeIndex >= 0) {
        if (conflict.smsVersion) {
          // Replace with SMS version
          mergedPathway.edges[edgeIndex] = JSON.parse(JSON.stringify(conflict.smsVersion));
        } else {
          // SMS version doesn't exist, remove from production
          mergedPathway.edges.splice(edgeIndex, 1);
        }
      } else if (conflict.smsVersion) {
        // Edge doesn't exist in production but exists in SMS, add it
        if (!mergedPathway.edges) {
          mergedPathway.edges = [];
        }
        mergedPathway.edges.push(JSON.parse(JSON.stringify(conflict.smsVersion)));
      }
    } else if (resolution === 'custom') {
      // Use custom version
      const customData = resolutions[`${conflict.id}_custom`] || {};
      
      if (edgeIndex >= 0) {
        // For existing edge in production
        const targetEdge = mergedPathway.edges[edgeIndex];
        
        // Apply type if specified
        if (customData.type !== undefined) {
          targetEdge.type = customData.type;
        }
        
        // Apply source if specified
        if (customData.source !== undefined) {
          targetEdge.source = customData.source;
        }
        
        // Apply target if specified
        if (customData.target !== undefined) {
          targetEdge.target = customData.target;
        }
        
        // Apply condition if specified
        if (customData.condition !== undefined) {
          targetEdge.condition = customData.condition;
        }
        
        // Apply data if specified
        if (customData.data !== undefined) {
          targetEdge.data = JSON.parse(JSON.stringify(customData.data));
        }
      } else {
        // Create new edge with custom data
        const baseEdge = conflict.productionVersion || conflict.smsVersion || {};
        const newEdge = JSON.parse(JSON.stringify(baseEdge));
        
        // Apply custom overrides
        if (customData.type !== undefined) {
          newEdge.type = customData.type;
        }
        
        if (customData.source !== undefined) {
          newEdge.source = customData.source;
        }
        
        if (customData.target !== undefined) {
          newEdge.target = customData.target;
        }
        
        if (customData.condition !== undefined) {
          newEdge.condition = customData.condition;
        }
        
        if (customData.data !== undefined) {
          newEdge.data = JSON.parse(JSON.stringify(customData.data));
        }
        
        // Ensure edges array exists
        if (!mergedPathway.edges) {
          mergedPathway.edges = [];
        }
        
        mergedPathway.edges.push(newEdge);
      }
    }
  }

  
  /**
   * Resolve a global prompt conflict
   * @param {Object} mergedPathway - The merged pathway being built
   * @param {Object} conflict - The conflict object
   * @param {String} resolution - The resolution type ('production', 'sms', 'custom')
   * @param {Object} resolutions - The complete resolutions object
   */
  static resolveGlobalPromptConflict(mergedPathway, conflict, resolution, resolutions) {
    const prodGlobalPrompt = conflict.productionVersion?.globalPrompt;
    const smsGlobalPrompt = conflict.smsVersion?.globalPrompt;
    
    // Determine which global prompt to use
    let finalGlobalPrompt = null;
    
    if (resolution === 'production') {
      finalGlobalPrompt = prodGlobalPrompt;
    } else if (resolution === 'sms') {
      finalGlobalPrompt = smsGlobalPrompt;
    } else if (resolution === 'custom') {
      // Get custom global prompt from resolutions
      const customData = resolutions[`${conflict.id}_custom`] || {};
      finalGlobalPrompt = customData.globalPrompt;
    }
    
    // Apply the global prompt to the merged pathway
    if (finalGlobalPrompt !== null) {
      // Check if we should store in globalConfig or in nodes
      if (mergedPathway.globalConfig) {
        // Store in globalConfig
        if (!mergedPathway.globalConfig) {
          mergedPathway.globalConfig = {};
        }
        mergedPathway.globalConfig.globalPrompt = finalGlobalPrompt;
      } else {
        // Store in all nodes
        if (mergedPathway.nodes && mergedPathway.nodes.length > 0) {
          mergedPathway.nodes.forEach(node => {
            node.data.globalPrompt = finalGlobalPrompt;
          });
        }
      }
    }
  }
}

export default ConflictResolver; 