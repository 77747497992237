import { addToast, Button, ButtonGroup, Input } from '@heroui/react'
import { useMutation } from '@tanstack/react-query'
import config from 'config'
import { Check, RadioTower, X } from 'lucide-react'
import { ofetch } from 'ofetch'
import { useState } from 'react'

export default function CustomDeployment({ value, ...props }) {
  const [state, setState] = useState('idle')

  const { mutate: handleTest, isLoading } = useMutation({
    mutationFn: () => ofetch(`${value}/v1/log`),
    onSuccess: () => {
      setState('success')
      addToast({
        title: 'Connection healthy!',
        color: 'success',
      })
    },
    onError: () => {
      setState('error')
      addToast({
        title: 'Connection failed!',
        color: 'danger',
      })
    },
    onSettled: () => {
      setTimeout(() => {
        setState('idle')
      }, 3000)
    },
  })

  return (
    <div className="flex flex-col gap-1 w-full">
      <label className="text-small font-medium text-foreground">Custom Deployment</label>
      <p className="text-tiny text-foreground-400 mb-2">If you have dedicated infrastructure, you can change the url here to point to your own deployment.</p>
      <ButtonGroup>
        <Input
          placeholder={`${config.API_URL}`}
          {...props}
          value={value}
          variant="bordered"
          radius="sm"
          classNames={{ inputWrapper: 'rounded-r-none' }}
        />
        <Button
          className="shrink-0 text-white"
          onPress={handleTest}
          color={state === 'success' ? 'success' : state === 'error' ? 'danger' : 'primary'}
          isLoading={isLoading}
          startContent={state === 'success' ? <Check size={16} /> : state === 'error' ? <X size={16} /> : <RadioTower size={16} />}
        >
          {state === 'success' ? 'Success' : state === 'error' ? 'Failed' : 'Test Connection'}
        </Button>
      </ButtonGroup>
    </div>
  )
}
