import axios from 'axios'
import config from 'config'
import { getOrgId } from 'utils/funcs/browser/getOrgId'
import { getAuthToken } from '../browser/getAuthToken'

export async function purchaseNumber(phone_number, user_id, type, preselected, area_code, country_code) {
  const token = getAuthToken()
  const orgId = getOrgId()
  const headers = {
    authorization: `${token}`,
    ...(orgId && { 'x-bland-org-id': orgId }),
  }
  try {
    const response = await axios.post(
      `${config.API_URL}/numbers/purchase`,
      { phone_number, user_id, type, preselected, area_code, country_code },
      { headers, validateStatus: false },
    )

    if (response.status === 200) {
      return response.data
    }
    else {
      return {
        status: 'error',
        message:
          response.data.errors?.[0]?.message
          || 'Unexpected error while purchasing phone number. Please contact support: hello@bland.ai',
      }
    }
  }
  catch (error) {
    console.log('33 PMENT', error)
    return { status: 'error', message: error }
  }
}
