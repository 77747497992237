import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
import { Button } from '@radix-ui/themes'
import LoadSpinner from 'components/core/LoadSpinner'
import { motion } from 'framer-motion'
import { useCallback, useEffect, useState } from 'react'
import SlideOut from './SlideOut'
import { KeyValue, KeyValues } from './Styles'

function Variables({
  isOpen,
  onClose,
  callIds,
  initialCallId,
  setFocusedCId,
  focusedCall,
  loading = false,
  modalOpen = false,
  notesOpen = false,
}) {
  const [currentIndex, setCurrentIndex] = useState(0)

  const handleNavigation = useCallback(
    (direction) => {
      setCurrentIndex((prevIndex) => {
        const newIndex
          = direction === 'next'
            ? Math.min(prevIndex + 1, callIds?.length - 1)
            : Math.max(prevIndex - 1, 0)
        const newCallId = callIds[newIndex]
        setFocusedCId(newCallId)
        return newIndex
      })
    },
    [callIds, setFocusedCId],
  )

  // Set initial state when the modal is opened
  useEffect(() => {
    if (isOpen && initialCallId) {
      const index = callIds?.indexOf(initialCallId)
      if (index !== -1) {
        setCurrentIndex(index)
      }
    }
  }, [isOpen, initialCallId, callIds])

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'j') {
        handleNavigation('next')
      }
      else if (event.key === 'k') {
        handleNavigation('prev')
      }
    }

    if (!modalOpen && !notesOpen) {
      window.addEventListener('keydown', handleKeyPress)
      return () => {
        window.removeEventListener('keydown', handleKeyPress)
      }
    }
  }, [handleNavigation, modalOpen, notesOpen])

  const focusedVariables = focusedCall?.variables

  const renderVariables = () => {
    if (focusedVariables == null) {
      return <p>No variables to display</p>
    }

    let parsedVariables
    if (typeof focusedVariables === 'string') {
      try {
        parsedVariables = JSON.parse(focusedVariables)
      }
      catch (error) {
        console.error('Error parsing focusedVariables as string:', error)
        return <p>Error: Unable to parse variables</p>
      }
    }
    else if (
      typeof focusedVariables === 'object'
      && focusedVariables !== null
    ) {
      parsedVariables = focusedVariables
    }
    else {
      return <p>Error: Invalid variable format</p>
    }

    if (
      parsedVariables
      && typeof parsedVariables.parsedVariables === 'string'
    ) {
      try {
        parsedVariables = JSON.parse(parsedVariables.parsedVariables)
      }
      catch (error) {
        console.error('Error parsing parsedVariables.parsedVariables:', error)
        return <p>Error: Unable to parse nested variables</p>
      }
    }

    if (typeof parsedVariables !== 'object' || parsedVariables === null) {
      return <p>Error: Invalid parsed variables</p>
    }

    return (
      <KeyValues>
        {Object.entries(parsedVariables).map(([key, value]) => (
          <KeyValue key={key}>
            <p>
              {key}
              :
            </p>
            <p>
              {value === null
                ? 'null'
                : typeof value === 'object'
                  ? JSON.stringify(value)
                  : String(value)}
            </p>
          </KeyValue>
        ))}
      </KeyValues>
    )
  }

  return (
    <SlideOut
      isOpen={isOpen}
      onClose={onClose}
      title={`Variables ${currentIndex + 1} of ${callIds?.length}`}
      subtitle={initialCallId ? `Call ID: ${initialCallId}` : null}
      footer={(
        <>
          <div className="flex justify-between mb-2.5">
            <div>
              <button
                onClick={() => handleNavigation('prev')}
                disabled={currentIndex === 0}
                className={`flex items-center px-2 py-1.5 bg-gray-100 text-xs text-gray-700 rounded-sm ${
                  currentIndex === 0
                    ? 'opacity-50 cursor-not-allowed'
                    : 'hover:bg-gray-200'
                }`}
              >
                <ChevronLeftIcon className="h-2.5 w-2.5 mr-0.5" />
                Previous
              </button>
            </div>
            <div>
              <button
                onClick={() => handleNavigation('next')}
                disabled={currentIndex === callIds?.length - 1}
                style={{ marginLeft: 'auto' }}
                className={`flex items-center text-xs px-2 py-1.5 bg-gray-100 text-gray-700 rounded-sm ${
                  currentIndex === callIds?.length - 1
                    ? 'opacity-50 cursor-not-allowed'
                    : 'hover:bg-gray-200'
                }`}
              >
                Next
                <ChevronRightIcon className="h-2.5 w-2.5 ml-0.5" />
              </button>
            </div>
          </div>
          <Button
            type="button"
            style={{ width: '100%' }}
            color="lightblue"
            variant="solid"
            onClick={() =>
              window.open('https://app.bland.ai/enterprise', '_blank')}
            className="w-full bg-blue-500 hover:bg-blue-600 text-white text-xs font-medium py-1.5 px-2.5 rounded-sm transition duration-150 ease-in-out"
          >
            <p style={{ fontSize: 13 }}>Interested in Fine-Tuning?</p>
          </Button>
          <p style={{ fontSize: 10 }} className="mt-1.5 text-2xs text-gray-500">
            We're constantly improving. For a fine-tuned model tailored to your
            needs, reach out to our team.
          </p>
        </>
      )}
    >
      {loading
        ? (
            <LoadSpinner loading={loading} />
          )
        : (
            <motion.p
              className="text-gray-800"
              style={{ fontSize: 18 }}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              <div>{renderVariables()}</div>
            </motion.p>
          )}
    </SlideOut>
  )
}

export default Variables
