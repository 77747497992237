import { Badge } from '@radix-ui/themes'
import { useQuery } from '@tanstack/react-query'
import VoiceGradientReusable from 'components/Dashboard/SendCall/VoiceGradientReusable'
import { AlertTriangle, MoveDownRight, MoveUpRight } from 'lucide-react'
import { $fetch } from 'utils/fetch'
import { convertToMinutesAndSeconds } from 'utils/formatting/secsToMins.js'
import { parsePathwayTags } from 'utils/funcs/call-logs/parsePathwayTags'

export default function CallMetadata({ call }) {
  const variables = call.variables ?? {}

  const consecutiveWaitingTwice = call.transcript?.some((entry, index) => {
    const currentEntry = entry.text.toLowerCase().includes('[waiting]')
    const nextEntry = call.transcript[index + 1]?.text.toLowerCase().includes('[waiting]')
    return currentEntry && nextEntry
  })

  const { data: voice } = useQuery({
    queryKey: ['voice', call.c_id],
    queryFn: async () => {
      const { voice } = await $fetch(`/v1/calls/${call.c_id}/voice`, {
        retry: false,
      })
      return voice
    },
    enabled: !!call.c_id,
    staleTime: Infinity,
    retry: false,
  })

  const callFields = [
    {
      label: 'From',
      value: call.from,
    },
    {
      label: 'To',
      value: call.to,
    },
    {
      label: 'Call Type',
      value: (
        <Badge size="1" color={call.inbound ? 'green' : 'blue'}>
          {call.inbound ? 'Incoming' : 'Outgoing'}
          {call.inbound
            ? (
                <MoveDownRight size={15} color="#4CAF50" />
              )
            : (
                <MoveUpRight size={15} color="#2196F3" />
              )}
        </Badge>
      ),
    },
    {
      label: 'Call Duration',
      value: call.call_length
        ? convertToMinutesAndSeconds(call.call_length)
        : null,
    },
    {
      label: 'Cost',
      value: `$${call.price ? Number.parseFloat(call.price).toFixed(2) : '0.00'}`,
    },
    {
      label: 'Caller Location',
      value: variables.state
        ? `${variables.city ?? '-'}, ${variables.state}`
        : null,
    },
    {
      label: 'Language',
      value: variables.language,
    },
    {
      label: 'Ended By',
      value: call.call_ended_by,
    },
    {
      label: 'Status',
      value: call.status,
    },
    {
      label: 'Pathway Name',
      value: call.pathway_name
        ? (
            <button
              onClick={(e) => {
                e.preventDefault()
                window.open(
                  `${window.location.origin}/dashboard/convo-pathways?id=${call.pathway_id}`,
                  '_blank',
                )
              }}
              disabled={!call.pathway_id}
              className="text-xs col-span-2 disabled:no-underline disabled:hover:no-underline truncate max-w-full"
              style={{
                cursor: call.pathway_id ? 'pointer' : 'default',
                textDecoration: call.pathway_id ? 'underline' : 'none',
              }}
            >
              {call.pathway_name || '-'}
            </button>
          )
        : null,
    },
    {
      label: 'Transferred To',
      value: call.transferred_to,
    },
    {
      label: 'Voice',
      value: voice
        ? (
            <div className="flex items-center gap-1.5">
              <VoiceGradientReusable name={voice.id} size={12} />
              <p className="text-xs font-semibold text-gray-500 tracking-tight">
                {voice.name}
              </p>
            </div>
          )
        : null,
    },
  ]

  const tags = parsePathwayTags(call.pathway_tags)

  return (
    <div className="flex flex-col gap-0.5 w-full">
      <div className="grid grid-cols-1 gap-2">
        {callFields
          .filter(({ value }) => value !== null && value !== undefined)
          .map(({ label, value }) => (
            <span key={label} className="flex w-full justify-between">
              <p className="text-xs font-semibold text-gray-500 tracking-tight">
                {label}
              </p>
              <p className="text-xs capitalize text-gray-500 truncate">
                {value}
              </p>
            </span>
          ))}
      </div>
      {tags?.length > 0 && (
        <div className="flex items-center flex-wrap gap-1.5 mt-1.5">
          <p className="text-xs font-semibold text-gray-500 tracking-tight pr-1.5">
            Pathway Tags:
          </p>
          {tags?.map(tag => (
            <Badge key={tag.name} size={1} color="gray" radius="large">
              <div
                style={{
                  height: 10,
                  width: 10,
                  borderRadius: '50%',
                  background: tag.color || '#000',
                }}
              />
              <p style={{ fontSize: 12, fontWeight: 500 }}>{tag.name}</p>
            </Badge>
          ))}
        </div>
      )}
      {call.error_message && (
        <div className="flex items-center gap-1.5 mt-3 bg-red-50 border border-red-200 rounded p-2">
          <p className="text-xs text-red-600 tracking-tight">
            {call.error_message}
          </p>
        </div>
      )}
      {consecutiveWaitingTwice && (
        <div className="flex items-center gap-1.5 mt-3 bg-yellow-50 border border-yellow-200 rounded p-2">
          <AlertTriangle size={16} className="text-yellow-600" />
          <p className="text-xs text-yellow-600 tracking-tight">
            Long periods of silence due to agent waiting. Update the prompt to specify when the agent should wait.
          </p>
        </div>
      )}
    </div>
  )
}
