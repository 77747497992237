import { XMarkIcon } from '@heroicons/react/24/outline'
import { AnimatePresence, motion } from 'framer-motion'
import { useEffect, useRef } from 'react'

function SlideOut({
  isOpen,
  onClose,
  title,
  subtitle,
  children,
  footer,
  extraWide = false,
}) {
  const slideOutRef = useRef(null)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        slideOutRef.current
        && !slideOutRef.current.contains(event.target)
        && isOpen
      ) {
        onClose()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isOpen, onClose])

  const slideOutVariants = {
    hidden: { x: '100%' },
    visible: { x: 0 },
  }

  const overlayVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  }

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          className="fixed inset-0 bg-black bg-opacity-30 z-[9999999]"
          initial="hidden"
          animate="visible"
          exit="hidden"
          variants={overlayVariants}
          transition={{ duration: 0.3 }}
        >
          <motion.div
            ref={slideOutRef}
            className={`fixed inset-y-0 right-0 max-w-${
              extraWide ? '7xl' : '4xl w-1/3'
            } bg-white shadow-lg flex flex-col`}
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={slideOutVariants}
            transition={{ type: 'tween', duration: 0.3 }}
          >
            <div
              className="flex justify-between items-center px-4 py-2.5 border-b border-gray-200"
              style={{ height: 54 }}
            >
              <div className="flex flex-col">
                <h2 className="text-sm font-semibold text-gray-800">{title}</h2>
                {subtitle && (
                  <p className="text-2xs text-gray-500">{subtitle}</p>
                )}
              </div>
              <button
                onClick={onClose}
                className="text-gray-400 hover:text-gray-600 transition duration-150 ease-in-out"
              >
                <XMarkIcon className="h-3 w-3" />
              </button>
            </div>

            <motion.div
              className="flex-grow overflow-y-auto px-4 py-2.5"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.2 }}
            >
              {children}
            </motion.div>

            {footer && (
              <motion.div
                className="px-4 py-2.5 border-t border-gray-200"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.3 }}
              >
                {footer}
              </motion.div>
            )}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default SlideOut
