import { Button } from '@/components/ui/button'
import { Card, CardContent } from '@/components/ui/card'

import GradientLoadingAnimation from 'components/Reusables/GradientLoadingAnimation'
import { AnimatePresence, motion } from 'framer-motion'
import {
  Check,
  Edit,
  Minus,
  Play,
  Plus,
  Settings,
  Share2,
  Square,
  Trash,
} from 'lucide-react'
import { useEffect, useState } from 'react'
import { FiCopy } from 'react-icons/fi'
import { toast } from 'sonner'
import { fetchAudioRecording } from 'utils/funcs/call-logs/fetchAudioRecording'
import reportLocation from '../../../assets/images/reportLocation.png'
import { ShareReportDialog } from './components/ShareReportDialog'
import { useReportAnalytics } from './useReportAnalytics'

export default function ReportPage({
  liveReport = false,
  liveReportData = null,
}) {
  const reportAnalytics = useReportAnalytics()
  const [currentPage, setCurrentPage] = useState(1)
  const [globalFilter, setGlobalFilter] = useState('')
  const [topPerformingCalls, setTopPerformingCalls] = useState([])
  const [isSelectingTopPerformingCalls, setIsSelectingTopPerformingCalls]
    = useState(false)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [customOrgName, setCustomOrgName] = useState('your organization')
  const [reportData, setReportData] = useState({})
  const [isEditMode, setIsEditMode] = useState(false)
  const [currentlyPlaying, setCurrentlyPlaying] = useState(null)
  const [audioElement, setAudioElement] = useState(null)
  const [updateAnalysis, setUpdateAnalysis] = useState(false)
  const [isLoadingRecording, setIsLoadingRecording] = useState(null)
  const rowsPerPage = 10

  let totalCalls,
    callDuration,
    voicemailCount,
    error,
    loading,
    locations,
    calls

  if (liveReport && liveReportData) {
    ({ totalCalls, callDuration, voicemailCount, locations, calls }
      = liveReportData.analytics)
    loading = false
    error = null
  }
  else {
    ({
      totalCalls,
      callDuration,
      voicemailCount,
      error,
      loading,
      locations,
      calls,
    } = reportAnalytics)
  }

  useEffect(() => {
    if (totalCalls === 0 || !totalCalls)
      return
    setReportData(prevData => ({
      ...prevData,
      customOrgName,
      analytics: {
        totalCalls,
        callDuration,
        locations,
        voicemailCount,
        calls,
      },
      callAnalysis: prevData.callAnalysis || defaultCallAnalysisTemplate,
      dailyBreakdown: prevData.dailyBreakdown || {},
      topPerformingCalls,
    }))
  }, [
    customOrgName,
    totalCalls,
    callDuration,
    locations,
    voicemailCount,
    calls,
    topPerformingCalls,
  ])

  useEffect(() => {
    if (liveReport && liveReportData) {
      setTopPerformingCalls(liveReportData.topPerformingCalls)
      setCustomOrgName(liveReportData.customOrgName)
    }
  }, [liveReport, liveReportData])

  const handleAddTopCall = (call) => {
    if (
      topPerformingCalls.length < 5
      && !topPerformingCalls.find(c => c.c_id === call.c_id)
    ) {
      const newTopCalls = [...topPerformingCalls, call]
      setTopPerformingCalls(newTopCalls)
      setReportData(prev => ({ ...prev, topPerformingCalls: newTopCalls }))
    }
  }

  const handleRemoveTopCall = (callId) => {
    const newTopCalls = topPerformingCalls.filter(c => c.c_id !== callId)
    setTopPerformingCalls(newTopCalls)
    setReportData(prev => ({ ...prev, topPerformingCalls: newTopCalls }))
  }

  useEffect(() => {
    console.log('reportData', reportData)
  }, [reportData])

  const formatDuration = (duration) => {
    if (!duration)
      return '0 seconds'

    if (typeof duration === 'string' && duration.includes(':')) {
      const [mins, secs] = duration.split(':').map(Number)
      const minutes = mins + secs / 60
      if (!minutes)
        return '0 sec'
      if (minutes < 1)
        return `${Math.round(minutes * 60)} sec`
      const wholeMinutes = Math.floor(minutes)
      const seconds = Math.round((minutes % 1) * 60)
      return `${wholeMinutes} min${wholeMinutes !== 1 ? 's' : ''} ${seconds} sec${seconds !== 1 ? 's' : ''}`
    }
    else {
      const minutes = Number.parseFloat(duration)
      if (!minutes)
        return '0 sec'
      if (minutes < 1)
        return `${Math.round(minutes * 60)} sec`
      const wholeMinutes = Math.floor(minutes)
      const seconds = Math.round((minutes % 1) * 60)
      return `${wholeMinutes} min${wholeMinutes !== 1 ? 's' : ''} ${seconds} sec${seconds !== 1 ? 's' : ''}`
    }
  }

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
    alert('Call ID copied to clipboard!')
  }

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.2,
      },
    },
  }

  const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: 'spring',
        stiffness: 100,
      },
    },
  }

  const defaultCallAnalysisTemplate = [
    {
      id: 'answerRate',
      label: 'Answer Rate',
      value: totalCalls
        ? Math.round(((totalCalls - (voicemailCount || 0)) / totalCalls) * 100)
        : 0,
      description: 'of total calls',
    },
    {
      id: 'voicemails',
      label: 'Total Voicemails',
      value: voicemailCount || 0,
      description: '',
    },
    {
      id: 'answeredCalls',
      label: 'Answered Calls',
      value: (totalCalls || 0) - (voicemailCount || 0),
      description: '',
    },
  ]

  const callAnalysisTemplate
    = reportData.callAnalysis || defaultCallAnalysisTemplate

  const handlePlayRecording = async (call) => {
    try {
      if (currentlyPlaying === call.c_id) {
        audioElement?.pause()
        audioElement?.remove()
        setAudioElement(null)
        setCurrentlyPlaying(null)
        return
      }

      setIsLoadingRecording(call.c_id)

      if (audioElement) {
        audioElement.pause()
        audioElement.remove()
      }

      const audioUrl = await fetchAudioRecording(call.recording_url)

      if (audioUrl) {
        const audio = new Audio(audioUrl)
        audio.addEventListener('ended', () => {
          setCurrentlyPlaying(null)
          setAudioElement(null)
          audio.remove()
        })

        setAudioElement(audio)
        setCurrentlyPlaying(call.c_id)
        await audio.play()

        toast.success('Playing recording')
      }
      else {
        toast.error('Failed to load recording')
      }
    }
    catch (error) {
      console.error('Error playing recording:', error)
      toast.error('Error playing recording')
    }
    finally {
      setIsLoadingRecording(null)
    }
  }

  const totalPages = Math.ceil((calls?.length || 0) / rowsPerPage)
  const paginatedCalls
    = calls?.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage)
      || []

  if (loading)
    return <GradientLoadingAnimation message="Loading report data..." />

  return (
    <motion.div
      className="min-h-screen bg-white pb-12"
      initial="hidden"
      animate="visible"
      variants={containerVariants}
    >
      <main className="space-y-4">
        <motion.div
          className="flex justify-between items-center"
          variants={cardVariants}
        >
          <div className="text-lg leading-relaxed">
            <div className="flex flex-wrap items-center gap-1.5">
              <h1 className="inline">This week,</h1>
              {!liveReport
                ? (
                    <input
                      type="text"
                      value={customOrgName}
                      onChange={e => setCustomOrgName(e.target.value)}
                      className="font-bold bg-transparent border-b border-gray-300 focus:border-blue-500 outline-none px-0.5"
                      style={{ width: `${customOrgName.length + 1}ch` }}
                    />
                  )
                : (
                    <span className="font-bold">{customOrgName}</span>
                  )}
              <span className="text-gray-500">made</span>
              <span className="font-bold">
                {totalCalls?.toString()}
                {' '}
                calls
              </span>
              <span className="text-gray-500">in</span>
              <span className="font-bold">
                {locations?.total}
                {' '}
                locations
              </span>
              <span className="text-gray-500">
                with an average call duration of
              </span>
              <span className="font-bold">
                {formatDuration(callDuration?.average)}
              </span>
              <span className="text-gray-500">
                and a total call duration of
              </span>
              <span className="font-bold">
                {formatDuration(callDuration?.total)}
              </span>
            </div>
          </div>
          {!liveReport ? (
            <>
              <button
                onClick={() => setIsDialogOpen(true)}
                className="flex items-center gap-1.5 px-2.5 py-2.5 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors disabled:opacity-50 whitespace-nowrap"
              >
                <Share2 className="w-2.5 h-2.5" />
                <span>Share Report</span>
              </button>
              <ShareReportDialog
                open={isDialogOpen}
                onOpenChange={setIsDialogOpen}
                reportData={reportData}
              />
            </>
          ) : (
            <div className="flex items-center gap-1.5">
              {/* live report content */}
            </div>
          )}
        </motion.div>

        <motion.div
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2.5"
          variants={containerVariants}
        >
          <motion.div
            variants={cardVariants}
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            className="md:col-span-1"
          >
            <Card className="bg-gradient-to-br from-[#9333EA] to-[#7928CA] text-white hover:-translate-y-0.5 transition-transform shadow-lg h-full">
              <CardContent className="p-5 flex flex-col h-full">
                <div>
                  <h2 className="text-base font-medium mb-2.5">Total Calls</h2>
                  <div className="flex gap-0.5 mb-2.5">
                    {totalCalls
                      ?.toString()
                      .split('')
                      .map((digit, i) => (
                        <div
                          key={i}
                          className={`${
                            digit === ','
                              ? 'w-2.5 flex items-end'
                              : 'bg-white/10 rounded-md w-8 h-10 flex items-center justify-center text-xl font-bold'
                          }`}
                        >
                          {digit}
                        </div>
                      ))}
                  </div>
                  <p className="text-xs mb-5">Total calls for this period</p>
                </div>
                <div className="h-44 flex items-end gap-1.5 mt-auto">
                  {[20, 40, 60, 100, 70, 50, 30, 60, 100, 70, 50, 40].map(
                    (height, i) => (
                      <div
                        key={i}
                        className="bg-white/20 w-full"
                        style={{ height: `${height}%` }}
                      />
                    ),
                  )}
                </div>
              </CardContent>
            </Card>
          </motion.div>

          <motion.div
            variants={cardVariants}
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            className="md:col-span-1"
          >
            <Card className="bg-gradient-to-br from-[#059669] to-[#047857] text-white hover:-translate-y-0.5 transition-transform shadow-lg h-full">
              <CardContent className="p-5 h-full flex flex-col">
                <div className="flex justify-between items-center mb-2.5">
                  <h2 className="text-base font-medium">
                    {isEditMode ? 'Customize Call Analysis' : 'Call Analysis'}
                  </h2>
                  {!liveReport && (
                    <button
                      onClick={() => setIsEditMode(!isEditMode)}
                      className="p-2.5 hover:bg-white/10 rounded-full transition-colors"
                      title={isEditMode ? 'Save Changes' : 'Edit Metrics'}
                    >
                      {isEditMode
                        ? (
                            <Check className="w-4 h-4" />
                          )
                        : (
                            <Settings className="w-4 h-4" />
                          )}
                    </button>
                  )}
                </div>

                <div className="space-y-5 flex-1">
                  {callAnalysisTemplate.map(metric =>
                    metric.id === 'answerRate'
                      ? (
                          <div
                            key={metric.id}
                            className="flex items-center gap-4 mb-5"
                          >
                            <div className="text-3xl font-bold">
                              {isEditMode
                                ? (
                                    <input
                                      type="number"
                                      value={metric.value}
                                      onChange={(e) => {
                                        const updatedTemplate
                                  = reportData.callAnalysis.map(m =>
                                    m.id === metric.id
                                      ? {
                                          ...m,
                                          value: Number.parseInt(e.target.value) || 0,
                                        }
                                      : m,
                                  )
                                        setReportData(prev => ({
                                          ...prev,
                                          callAnalysis: updatedTemplate,
                                        }))
                                      }}
                                      className="w-32 bg-white/10 rounded-sm px-1.5 py-0.5"
                                    />
                                  )
                                : (
                                    <>
                                      {metric.value}
                                      {typeof metric.value === 'number' && '%'}
                                    </>
                                  )}
                            </div>
                            <div className="text-sm opacity-90">
                              {isEditMode
                                ? (
                                    <div className="space-y-1.5">
                                      <input
                                        type="text"
                                        value={metric.label}
                                        onChange={(e) => {
                                          const updatedTemplate
                                    = reportData.callAnalysis.map(m =>
                                      m.id === metric.id
                                        ? { ...m, label: e.target.value }
                                        : m,
                                    )
                                          setReportData(prev => ({
                                            ...prev,
                                            callAnalysis: updatedTemplate,
                                          }))
                                        }}
                                        className="w-full bg-white/10 rounded-sm px-1.5 py-0.5"
                                      />
                                      <input
                                        type="text"
                                        value={metric.description}
                                        onChange={(e) => {
                                          const updatedTemplate
                                    = reportData.callAnalysis.map(m =>
                                      m.id === metric.id
                                        ? { ...m, description: e.target.value }
                                        : m,
                                    )
                                          setReportData(prev => ({
                                            ...prev,
                                            callAnalysis: updatedTemplate,
                                          }))
                                        }}
                                        className="w-full bg-white/10 rounded-sm px-1.5 py-0.5"
                                        placeholder="Description"
                                      />
                                    </div>
                                  )
                                : (
                                    <>
                                      <div className="font-medium">{metric.label}</div>
                                      {metric.description && (
                                        <div>{metric.description}</div>
                                      )}
                                    </>
                                  )}
                            </div>
                          </div>
                        )
                      : null,
                  )}
                  <div className="flex gap-2.5">
                    {callAnalysisTemplate.map(metric =>
                      metric.id !== 'answerRate'
                        ? (
                            <div
                              key={metric.id}
                              className="flex-1 bg-white/10 rounded-md p-2.5"
                            >
                              {isEditMode
                                ? (
                                    <div className="space-y-1.5">
                                      <input
                                        type="text"
                                        value={metric.label}
                                        onChange={(e) => {
                                          const updatedTemplate
                                    = reportData.callAnalysis.map(m =>
                                      m.id === metric.id
                                        ? { ...m, label: e.target.value }
                                        : m,
                                    )
                                          setReportData(prev => ({
                                            ...prev,
                                            callAnalysis: updatedTemplate,
                                          }))
                                        }}
                                        className="w-full bg-white/10 rounded-sm px-1.5 py-0.5"
                                      />
                                      <input
                                        type="number"
                                        value={metric.value}
                                        onChange={(e) => {
                                          const updatedTemplate
                                    = reportData.callAnalysis.map(m =>
                                      m.id === metric.id
                                        ? {
                                            ...m,
                                            value: Number.parseInt(e.target.value),
                                          }
                                        : m,
                                    )
                                          setReportData(prev => ({
                                            ...prev,
                                            callAnalysis: updatedTemplate,
                                          }))
                                        }}
                                        className="w-24 bg-white/10 rounded-sm px-1.5 py-0.5"
                                        min="0"
                                        max="100"
                                      />
                                    </div>
                                  )
                                : (
                                    <>
                                      <div className="text-xs opacity-80">
                                        {metric.label}
                                      </div>
                                      <div className="text-lg font-bold">
                                        {metric.value}
                                      </div>
                                    </>
                                  )}
                            </div>
                          )
                        : null,
                    )}
                  </div>
                </div>

                <div className="border-t border-white/10 pt-2.5 mt-auto">
                  <div className="text-xs font-medium mb-2">
                    Daily Breakdown
                  </div>
                  <div className="space-y-1.5">
                    {[...Array.from({ length: 5 })].map((_, i) => {
                      const dailyCalls = isEditMode
                        ? reportData.dailyBreakdown?.[i]?.calls
                        || Math.floor((totalCalls || 0) / 5)
                        + (i < (totalCalls || 0) % 5 ? 1 : 0)
                        : Math.floor((totalCalls || 0) / 5)
                          + (i < (totalCalls || 0) % 5 ? 1 : 0)
                      const percentage = (dailyCalls / (totalCalls || 1)) * 100
                      return (
                        <div
                          key={i}
                          className="flex items-center justify-between text-xs group"
                        >
                          <span>{['Mon', 'Tue', 'Wed', 'Thu', 'Fri'][i]}</span>
                          <div className="flex items-center gap-2">
                            {isEditMode
                              ? (
                                  <input
                                    type="number"
                                    value={dailyCalls}
                                    onChange={(e) => {
                                      const newValue
                                    = Number.parseInt(e.target.value) || 0
                                      setReportData(prev => ({
                                        ...prev,
                                        dailyBreakdown: {
                                          ...(prev.dailyBreakdown || {}),
                                          [i]: { calls: newValue },
                                        },
                                      }))
                                    }}
                                    className="w-12 bg-white/10 rounded-sm px-1.5 py-0.5 text-right"
                                    min="0"
                                  />
                                )
                              : (
                                  <span>{dailyCalls}</span>
                                )}
                            <div className="w-12 h-0.5 bg-white/20 rounded-full overflow-hidden">
                              <div
                                className="h-full bg-white rounded-full transition-all duration-300"
                                style={{ width: `${percentage}%` }}
                              />
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </CardContent>
            </Card>
          </motion.div>

          <motion.div
            variants={cardVariants}
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            className="md:col-span-2 lg:col-span-1"
          >
            <Card className="bg-[#0A1A3B] text-white h-full">
              <CardContent className="p-5">
                <div className="space-y-4">
                  <div>
                    <h2 className="text-lg font-medium mb-4">Top Location</h2>
                    <h3 className="text-2xl font-semibold mb-2.5">
                      {locations?.topLocations?.[0] || 'N/A'}
                    </h3>
                    <p className="text-sm opacity-90">
                      Your calls reached customers in
                      {' '}
                      {locations?.total || 0}
                      {' '}
                      locations
                    </p>
                  </div>
                  <div className="relative">
                    <div className="rounded-lg overflow-hidden">
                      <div className="h-[200px] relative flex items-center justify-center px-6">
                        <img
                          src={reportLocation}
                          alt="Map showing call locations in California"
                          className="w-full h-full object-cover rounded-"
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <h4 className="text-base mb-2.5">Top 5 Locations</h4>
                    <div className="flex flex-wrap gap-1.5">
                      {locations?.topLocations?.map(location => (
                        <div
                          key={location}
                          className="bg-white/10 px-2.5 py-1.5 text-xs font-medium"
                        >
                          {location}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>
          </motion.div>
        </motion.div>

        <motion.div
          className="grid grid-cols-1 lg:grid-cols-4 gap-2.5 h-full"
          variants={containerVariants}
        >
          <motion.div
            variants={cardVariants}
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            className="h-full"
          >
            <Card className="bg-gradient-to-br from-[#F97316] to-[#EA580C] text-white hover:-translate-y-0.5 transition-transform shadow-lg lg:row-span-2 h-full">
              <CardContent className="p-5 h-full flex flex-col">
                <div className="flex-1">
                  <h2 className="text-base font-medium mb-2.5">Average Duration</h2>
                  <div className="text-2xl font-bold mb-2.5">
                    {formatDuration(callDuration?.average)}
                  </div>
                  <p className="text-xs mb-5">
                    Average call duration for this period
                  </p>
                </div>
                <div className="flex-1">
                  <div className="space-y-2.5 border-t border-white/10 pt-2.5">
                    {callDuration?.daily?.length > 0
                    && callDuration.daily.map((day, index) => (
                      <div
                        key={index}
                        className="flex justify-between items-center group hover:bg-white/5 transition-all px-1.5 py-0.5 rounded-sm"
                      >
                        <div className="flex items-center gap-2">
                          <div className="text-xs font-medium">
                            {new Date(day.period).toLocaleDateString(
                              'en-US',
                              { weekday: 'long' },
                            )}
                          </div>
                        </div>
                        <div className="flex items-center gap-1.5">
                          <div className="text-xs font-mono">
                            {formatDuration(day.value)}
                          </div>
                          <div
                            className="h-0.5 w-12 bg-white/20 rounded-full overflow-hidden"
                            title={`${((day.value / Math.max(...callDuration.daily.map(d => d.value))) * 100).toFixed(1)}%`}
                          >
                            <div
                              className="h-full bg-white rounded-full"
                              style={{
                                width: `${(day.value / Math.max(...callDuration.daily.map(d => d.value))) * 100}%`,
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </CardContent>
            </Card>
          </motion.div>

          <motion.div
            className="lg:col-span-3 h-full"
            variants={cardVariants}
            whileHover={{ scale: 1.02 }}
          >
            <Card className="bg-blue-900 text-white lg:row-span-2 h-full">
              <CardContent className="p-5 h-full flex flex-col">
                <div className="flex-1">
                  <div className="flex justify-between items-center mb-2.5">
                    <h2 className="text-base font-medium">
                      Top Performing Calls
                    </h2>
                    {!liveReport && (
                      <div className="flex items-center gap-1.5 text-xs text-white/70">
                        <Button
                          onClick={() =>
                            setIsSelectingTopPerformingCalls(
                              !isSelectingTopPerformingCalls,
                            )}
                          variant="ghost"
                          className="p-0.5 rounded-sm transition-colors flex items-center gap-1.5 hover:bg-white/10 hover:text-white/90 px-1.5"
                          title={
                            isSelectingTopPerformingCalls
                              ? 'Exit Edit Mode'
                              : 'Enter Edit Mode'
                          }
                        >
                          {isSelectingTopPerformingCalls
                            ? (
                                <Check className="w-3 h-3" />
                              )
                            : (
                                <Settings className="w-3 h-3" />
                              )}
                          <span className="cursor-pointer">
                            {isSelectingTopPerformingCalls
                              ? 'Finish Selection'
                              : 'Select Top Calls'}
                          </span>
                        </Button>
                      </div>
                    )}
                  </div>
                  <p className="text-xs mb-4">
                    Your most successful calls based on customer outcomes and
                    duration.
                  </p>
                  <div className="overflow-hidden flex-1">
                    <table className="w-full h-full">
                      <thead>
                        <tr className="text-left border-b border-white/10">
                          <th className="pb-1.5 font-medium">From</th>
                          <th className="pb-1.5 font-medium">To</th>
                          <th className="pb-1.5 font-medium">Timestamp</th>
                          <th className="pb-1.5 font-medium">Duration</th>
                          <th className="pb-1.5 font-medium w-16">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {topPerformingCalls.length > 0
                          ? (
                              topPerformingCalls.map(call => (
                                <tr
                                  key={call.id}
                                  className="border-b border-white/5"
                                >
                                  <td className="py-2">{call.from}</td>
                                  <td className="py-2">{call.to}</td>
                                  <td className="py-2">
                                    {new Date(
                                      call.created_at || call.timestamp,
                                    ).toLocaleString('en-US', {
                                      dateStyle: 'medium',
                                      timeStyle: 'short',
                                    })}
                                  </td>
                                  <td className="py-2">
                                    {formatDuration(call.call_length)}
                                  </td>
                                  <td className="py-2">
                                    <div
                                      className={`flex ${!isSelectingTopPerformingCalls ? 'justify-center' : 'gap-1.5'}`}
                                    >
                                      <button
                                        className="p-0.5 hover:bg-white/10 rounded-sm"
                                        onClick={() => handlePlayRecording(call)}
                                        title={
                                          currentlyPlaying === call.c_id
                                            ? 'Stop'
                                            : 'Play'
                                        }
                                        disabled={isLoadingRecording === call.c_id}
                                      >
                                        {isLoadingRecording === call.c_id
                                          ? (
                                              <div className="w-3 h-3 animate-spin rounded-full border-2 border-white border-t-transparent" />
                                            )
                                          : currentlyPlaying === call.c_id
                                            ? (
                                                <Square className="w-3 h-3" />
                                              )
                                            : (
                                                <Play className="w-3 h-3" />
                                              )}
                                      </button>
                                      {isSelectingTopPerformingCalls && (
                                        <button
                                          onClick={() =>
                                            handleRemoveTopCall(call.c_id)}
                                          className="p-0.5 hover:bg-white/10 rounded-sm text-red-300"
                                        >
                                          <Trash className="w-3 h-3" />
                                        </button>
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              ))
                            )
                          : (
                              <tr>
                                <td colSpan="5" className="py-5">
                                  <div className="flex flex-col items-center justify-center text-center space-y-2.5 text-white/70">
                                    <div className="w-10 h-10 rounded-full bg-white/10 flex items-center justify-center">
                                      <Play className="w-5 h-5" />
                                    </div>
                                    {!liveReport && (
                                      <>
                                        <div>
                                          <p className="text-sm font-medium mb-1.5">
                                            No Top Performing Calls Selected
                                          </p>
                                          <p className="text-xs">
                                            Click the edit button below to select up
                                            to 5 of your best performing calls from
                                            the call logs below, to showcase in your
                                            report.
                                          </p>
                                        </div>
                                        <button
                                          onClick={() =>
                                            setIsSelectingTopPerformingCalls(
                                              prev => !prev,
                                            )}
                                          className="px-2.5 py-1.5 bg-white/10 hover:bg-white/20 rounded-md transition-colors flex items-center gap-1.5"
                                        >
                                          <Edit className="w-2.5 h-2.5" />
                                          {isSelectingTopPerformingCalls
                                            ? 'Cancel'
                                            : 'Select Calls'}
                                        </button>
                                      </>
                                    )}
                                  </div>
                                </td>
                              </tr>
                            )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </CardContent>
            </Card>
          </motion.div>
        </motion.div>

        <motion.div
          variants={cardVariants}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.6 }}
        >
          <Card>
            <CardContent className="p-4">
              <div className="overflow-x-auto font-mono border border-gray-100 rounded-md">
                <table className="min-w-full text-left">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-4 py-2 text-2xs font-semibold text-gray-600 uppercase tracking-wider">
                        ID
                      </th>
                      <th className="px-4 py-2 text-2xs font-semibold text-gray-600 uppercase tracking-wider">
                        From
                      </th>
                      <th className="px-4 py-2 text-2xs font-semibold text-gray-600 uppercase tracking-wider">
                        To
                      </th>
                      <th className="px-4 py-2 text-2xs font-semibold text-gray-600 uppercase tracking-wider">
                        Timestamp
                      </th>
                      <th className="px-4 py-2 text-2xs font-semibold text-gray-600 uppercase tracking-wider">
                        Duration
                      </th>
                      <th className="px-4 py-2 text-2xs font-semibold text-gray-600 uppercase tracking-wider">
                        Price
                      </th>
                      <th className="px-4 py-2 text-2xs font-semibold text-gray-600 uppercase tracking-wider">
                        {/* Recording */}
                      </th>
                      {isSelectingTopPerformingCalls && (
                        <th className="px-4 py-2 text-2xs font-semibold text-gray-600 uppercase tracking-wider">
                          Add to Top Calls
                        </th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {paginatedCalls.map((call, index) => (
                      <tr
                        key={call.id || index}
                        className={`${
                          index % 2 === 0 ? 'bg-white' : 'bg-gray-50'
                        } border-b hover:bg-gray-100 transition`}
                      >
                        <td className="px-2.5 py-2 text-xs text-gray-800 flex items-center space-x-1.5">
                          <span title={call.c_id}>
                            {call.c_id?.slice(0, 8) || 'unknown'}
                            ...
                          </span>
                          <FiCopy
                            className="cursor-pointer text-gray-500 hover:text-gray-800"
                            onClick={() => copyToClipboard(call.c_id)}
                          />
                          {call.queue_status === 'started' && (
                            <span className="flex items-center space-x-0.5">
                              <div className="flex relative">
                                <span className="w-1.5 h-1.5 bg-green-500 rounded-full mx-auto my-auto" />
                                <span className="w-1.5 h-1.5 bg-green-500 rounded-full animate-lessslowping absolute" />
                              </div>
                              <span className="text-2xs text-green-600 font-semibold">
                                Live
                              </span>
                            </span>
                          )}
                        </td>
                        <td className="px-2.5 py-2 text-xs text-gray-600">
                          {call.from || 'N/A'}
                        </td>
                        <td className="px-2.5 py-2 text-xs text-gray-600">
                          {call.to || 'N/A'}
                        </td>
                        <td className="px-2.5 py-2 text-xs text-gray-600">
                          {new Date(
                            call.created_at || call.timestamp,
                          ).toLocaleString('en-US', {
                            dateStyle: 'medium',
                            timeStyle: 'short',
                          })}
                        </td>
                        <td className="px-2.5 py-2 text-xs text-gray-600">
                          {formatDuration(call.call_length)}
                        </td>
                        <td className="px-2.5 py-2 text-xs text-gray-600">
                          {call.price ? `$${call.price.toFixed(2)}` : 'N/A'}
                        </td>
                        <td className="px-2.5 py-2">
                          <button
                            onClick={() => handlePlayRecording(call)}
                            className="p-0.5 hover:bg-gray-200 rounded-sm transition-colors"
                            title={
                              currentlyPlaying === call.c_id
                                ? 'Stop'
                                : 'Play Recording'
                            }
                            disabled={isLoadingRecording === call.c_id}
                          >
                            {isLoadingRecording === call.c_id
                              ? (
                                  <div className="w-3 h-3 animate-spin rounded-full border-2 border-gray-600 border-t-transparent" />
                                )
                              : currentlyPlaying === call.c_id
                                ? (
                                    <Square className="w-3 h-3 text-gray-600" />
                                  )
                                : (
                                    <Play className="w-3 h-3 text-gray-600" />
                                  )}
                          </button>
                        </td>
                        {isSelectingTopPerformingCalls && (
                          <td className="px-2.5 py-2">
                            {topPerformingCalls.some(
                              c => c.c_id === call.c_id,
                            )
                              ? (
                                  <button
                                    onClick={() => handleRemoveTopCall(call.c_id)}
                                    className="flex items-center gap-1 px-2 py-1 text-xs font-medium text-red-600 bg-red-50 hover:bg-red-100 transition-colors"
                                    title="Remove from top calls"
                                  >
                                    <Minus className="w-2 h-2" />
                                    <span>Remove</span>
                                  </button>
                                )
                              : (
                                  <button
                                    onClick={() => handleAddTopCall(call)}
                                    disabled={topPerformingCalls.length >= 5}
                                    className={`ml-5 flex items-center gap-1 px-2 py-1 text-xs font-medium transition-colors ${
                                      topPerformingCalls.length >= 5
                                        ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                                        : 'text-blue-600 bg-blue-50 hover:bg-blue-100'
                                    }`}
                                    title={
                                      topPerformingCalls.length >= 5
                                        ? 'Maximum 5 calls can be selected'
                                        : 'Add to top calls'
                                    }
                                  >
                                    <Plus className="w-2 h-2" />
                                    <span>
                                      {topPerformingCalls.length >= 5
                                        ? 'Max Selected'
                                        : 'Add'}
                                    </span>
                                  </button>
                                )}
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>

                <div className="flex items-center justify-between px-4 py-2.5 bg-white border-t">
                  <div className="text-xs text-gray-600">
                    Showing
                    {' '}
                    {Math.min(
                      (currentPage - 1) * rowsPerPage + 1,
                      calls?.length || 0,
                    )}
                    {' '}
                    to
                    {' '}
                    {Math.min(currentPage * rowsPerPage, calls?.length || 0)}
                    {' '}
                    of
                    {' '}
                    {calls?.length || 0}
                    {' '}
                    entries
                  </div>
                  <div className="flex items-center gap-1.5">
                    <button
                      onClick={() =>
                        setCurrentPage(prev => Math.max(prev - 1, 1))}
                      disabled={currentPage === 1}
                      className="px-2 py-0.5 text-xs font-medium text-gray-600 bg-white border rounded-sm hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                      Previous
                    </button>
                    {[...new Array(totalPages)].map((_, i) => (
                      <button
                        key={i + 1}
                        onClick={() => setCurrentPage(i + 1)}
                        className={`px-2 py-0.5 text-xs font-medium rounded-sm ${
                          currentPage === i + 1
                            ? 'bg-blue-600 text-white'
                            : 'text-gray-600 bg-white border hover:bg-gray-50'
                        }`}
                      >
                        {i + 1}
                      </button>
                    ))}
                    <button
                      onClick={() =>
                        setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                      disabled={currentPage === totalPages}
                      className="px-2 py-0.5 text-xs font-medium text-gray-600 bg-white border rounded-sm hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        </motion.div>

        <AnimatePresence>
          {isSelectingTopPerformingCalls && (
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 50 }}
              className="fixed bottom-2.5 right-2.5 bg-blue-600 text-white px-4 py-2 rounded-md shadow-lg"
            >
              Select up to 5 top performing calls from the 'Add to Top Calls'
              column in the table below.
            </motion.div>
          )}
        </AnimatePresence>
      </main>
    </motion.div>
  )
}
