import { RefreshCw } from 'lucide-react'

function MultipleResponsesResults({
  responses = [],
  onRerun,
  onCancel,
  generationCount,
  onGenerationCountChange,
  isLoading,
}) {
  const totalResponses = responses.length

  // Group responses and count occurrences
  const responseGroups = responses.reduce((acc, response) => {
    acc[response] = (acc[response] || 0) + 1
    return acc
  }, {})

  // Sort responses by count in descending order
  const sortedResponses = Object.entries(responseGroups).sort(
    (a, b) => b[1] - a[1],
  )

  const uniqueCount = Object.keys(responseGroups).length
  const varietyPercentage = Math.round((uniqueCount / totalResponses) * 100)

  const generationOptions = [10, 30, 50]

  return (
    <div className="mt-5 space-y-4">
      {/* Generation Count Selector */}
      <div className="flex justify-between items-center">
        <span className="text-2xs text-gray-500 font-geist-mono">
          NUMBER OF RESPONSES
        </span>
        <div className="flex gap-1.5">
          {generationOptions.map(count => (
            <button
              key={count}
              onClick={() => onGenerationCountChange(count)}
              className={`px-2 py-1 text-2xs font-geist-mono rounded transition-colors
                ${
            generationCount === count
              ? 'bg-black text-white'
              : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
            }`}
            >
              {count}
            </button>
          ))}
        </div>
      </div>

      {/* Stats Cards */}
      <div className="grid grid-cols-2 gap-2.5">
        <div className="p-2.5 border border-gray-200 rounded-md">
          <div className="text-2xs text-gray-500 font-geist-mono mb-0.5">
            UNIQUE RESPONSES
          </div>
          <div className="flex items-baseline gap-1.5">
            <span className="text-xl font-semibold">{uniqueCount}</span>
            <span className="text-xs text-gray-500">
              out of
              {' '}
              {totalResponses}
              {' '}
              total responses
            </span>
          </div>
        </div>
        <div className="p-2.5 border border-gray-200 rounded-md">
          <div className="text-2xs text-gray-500 font-geist-mono mb-0.5">
            VARIETY PERCENTAGE
          </div>
          <div className="space-y-1.5">
            <span className="text-xl font-semibold">
              {varietyPercentage}
              %
            </span>
            <div className="w-full bg-gray-200 rounded-full h-1.5">
              <div
                className="bg-black rounded-full h-1.5 transition-all duration-500"
                style={{ width: `${varietyPercentage}%` }}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Grouped Responses List */}
      <div className="border border-gray-200 rounded-md max-h-[400px] overflow-hidden flex flex-col">
        <div className="p-2.5 border-b border-gray-200 bg-white sticky top-0">
          <h3 className="text-2xs font-geist-mono text-gray-500">
            UNIQUE RESPONSES
          </h3>
        </div>
        <div className="divide-y divide-gray-200 overflow-y-auto">
          {sortedResponses.map(([response, count], index) => (
            <div key={index} className="p-2.5 text-xs">
              <div className="flex justify-between items-start gap-2.5">
                <div className="text-gray-700">{response}</div>
                <div className="text-2xs font-geist-mono text-gray-500 whitespace-nowrap">
                  {count}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Action Buttons */}
      <div className="flex justify-between pt-2.5 sticky bottom-0 pb-1.5 bg-white">
        <button
          onClick={onCancel}
          className="px-2.5 py-1.5 text-xs font-geist-mono text-gray-700 border border-gray-300 rounded-sm hover:bg-gray-50"
        >
          CANCEL
        </button>
        <button
          onClick={onRerun}
          disabled={isLoading}
          className="px-2.5 py-1.5 text-xs font-geist-mono text-white bg-black rounded-sm hover:bg-gray-800"
        >
          {isLoading
            ? (
                <RefreshCw className="mr-1.5 h-2.5 w-2.5 animate-spin" />
              )
            : (
                'RE-RUN'
              )}
        </button>
      </div>
    </div>
  )
}

export default MultipleResponsesResults
