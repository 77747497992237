import { useDrag } from 'react-dnd'
import PathwayCard from './pathway_card'

function DraggablePathwayCard({
  id,
  name,
  description,
  setCurrId,
  setOpenDelete,
  setIsDuplicate,
  setOpen,
  folder,
  onMovePathway,
  setRefreshPathways,
}) {
  const ItemTypes = {
    PATHWAY: 'pathway',
    FOLDER: 'folder',
  }

  const [{ isDragging }, drag, preview] = useDrag(() => ({
    type: ItemTypes.PATHWAY,
    item: { id, name, type: ItemTypes.PATHWAY },
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
    }),
    previewOptions: {
      captureDraggingState: true,
    },
  }))

  return (
    //         isDragging ? (
    //             <div className="bg-white border border-gray-200 rounded-md shadow-md p-1.5 w-20">
    //     <h3 className="text-2xs font-semibold text-gray-700 truncate">{name}</h3>
    //   </div>
    //         ) :
    <div
      ref={drag}
      style={{ opacity: isDragging ? 0.5 : 1 }}
      className="h-full"
    >
      <PathwayCard
        id={id}
        name={name}
        description={description}
        setCurrId={setCurrId}
        setOpenDelete={setOpenDelete}
        setIsDuplicate={setIsDuplicate}
        setOpen={setOpen}
        folder={folder}
        onMovePathway={onMovePathway}
        setRefreshPathways={setRefreshPathways}
      />
    </div>
  )
}

export default DraggablePathwayCard
