export function sanitizePhone(phoneNumber) {
  // Directly replace any character that is not a digit.
  const sanitizedPhone = phoneNumber?.replace(/\D/g, '')
  // Log if the original phone number was changed
  if (phoneNumber !== sanitizedPhone) {
    console.log(`Sanitized ${phoneNumber} to ${sanitizedPhone}`)
    return sanitizedPhone
  }
  else {
    console.log(`${phoneNumber} is already sanitized!`)
    return phoneNumber
  }
}

export function addPlus(phoneNumber) {
  if (phoneNumber?.length >= 9 && phoneNumber?.charAt(0) !== '+') {
    const newPhoneNumber = `+${phoneNumber}`
    console.log('Appended "+":', newPhoneNumber)
    return newPhoneNumber
  }
  else {
    console.error(
      `No need to append "+" for ${phoneNumber}. It's likely already present.`,
    )
    return phoneNumber
  }
}
