import { useEffect, useState } from 'react'
import styled from 'styled-components'
import ActionBar from './ActionBar'
import { PageTitle } from './PageTitle'

export default function Tabs({
  tabs,
  fixed,
  relative,
  side,
  title,
  activeTab,
  onTabChange,
}) {
  // Tabs are rendered by mapping over the tabs prop,
  // which is expected to be an array of objects... each containing item and panel properties.
  // Example:
  //  [
  //    {item: 'Inbound', panel: <Inbound/>},
  //    {item: 'Outbound', panel: <Outbound/>}
  //  ]

  const [currTabIndex, setCurrTabIndex] = useState(activeTab || 0)

  useEffect(() => {
    if (activeTab !== undefined) {
      setCurrTabIndex(activeTab)
    }
  }, [activeTab])

  const selectTab = (index) => {
    if (onTabChange) {
      // If onTabChange is provided, call it
      onTabChange(index)
    }
    else {
      // Otherwise, update local state
      setCurrTabIndex(index)
    }
  }

  return (
    <>
      {side && (
        <ActionBar top fixed sideMenu>
          <PageTitle>{title}</PageTitle>
        </ActionBar>
      )}
      <TabList fixed={fixed?.toString()} side={side} relative={relative}>
        {tabs.map((tab, index) => {
          return (
            <TabItem
              key={index}
              active={index === currTabIndex}
              onClick={() => selectTab(index)}
              side={side}
            >
              <TabText active={index === currTabIndex}>{tab.item}</TabText>
            </TabItem>
          )
        })}
      </TabList>
      <TabPanel>{tabs[currTabIndex].panel}</TabPanel>
    </>
  )
}

const TabList = styled.div`
  display: flex;
  flex-direction: ${({ side }) => (side ? 'column' : 'row')};
  row-gap: ${({ side }) => (side ? '5px' : '0px')};
  height: ${({ fixed, side }) => (fixed ? '54px' : side ? '100%' : '50px')};
  align-items: center;
  border-right: ${({ side }) => (side ? '1px solid #eeeeee' : 'none')};
  border-bottom: ${({ fixed }) => (fixed ? '1px solid #eeeeee' : 'none')};
  position: ${({ fixed }) => (fixed ? 'fixed' : 'relative')};
  top: ${({ fixed, side }) => (fixed ? '54px' : side ? '54px' : '0px')};
  background-color: white;
  z-index: 1500;
  width: 100%;
  max-width: ${({ side }) => (side ? '250px' : '100%')};
  margin-left: ${({ fixed, relative }) =>
    fixed ? '-30px' : relative ? '0px' : '54px'};
  padding: ${({ side }) => (side ? '9px' : '0px')};
`

const TabItem = styled.div`
  display: flex;
  align-items: center;
  height: ${({ side }) => (side ? '40px' : '100%')};
  width: ${({ side }) => (side ? '100%' : 'auto')};
  padding: 0px 20px;
  border-radius: ${({ side }) => (side ? '4px' : '0px')};
  border-bottom: 2px solid
    ${({ active, side }) =>
      side ? 'transparent' : !side && active ? '#433DFF' : 'transparent'};
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
  color: ${({ active, side }) =>
    side && active ? 'black' : !side && active ? '#433DFF' : '#686767'};
  background-color: ${({ active, side }) =>
    side && active
      ? '#fbfbfa'
      : !side && active
          ? 'transparent'
          : 'transparent'};
  &:hover {
    background-color: #fbfbfa;
    color: ${({ active, side }) =>
      side && active ? 'black' : !side && active ? '#433DFF' : 'black'};
  }
`

const TabText = styled.p`
  &:hover {
    background-color: #fbfbfa;
  }
`

const TabPanel = styled.div`
  width: 100%;
`
