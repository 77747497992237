import { forwardRef, Select, SelectItem } from '@heroui/react'

const trackOptions = [
  {
    label: 'None',
    key: 'none',
    description: 'Minimizes background noise',
  },
  {
    label: 'Office',
    key: 'office',
    description: 'Office-style soundscape. Includes faint typing, chatter, clicks, and other office sounds.',
  },
  {
    label: 'Cafe',
    key: 'cafe',
    description: 'Cafe-like soundscape. Includes faint talking, clinking, and other cafe sounds.',
  },
  {
    label: 'Restaurant',
    key: 'restaurant',
    description: 'Similar to cafe, but more subtle.',
  },
]

const BackgroundSelect = forwardRef(({ value, ...props }, ref) => {
  return (
    <Select
      label="Background Audio"
      placeholder="Select a background"
      labelPlacement="outside"
      variant="bordered"
      selectedKeys={value && [value]}
      description={(
        <p>
          Select an audio track that you’d like to play in the background during the
          call. The audio will play continuously when the agent isn’t speaking, and
          is incorporated into it’s speech as well. Use this to provide a more
          natural, seamless, engaging experience for the conversation.
        </p>
      )}
      radius="sm"
      {...props}
      ref={ref}
    >
      {trackOptions.map(option => (
        <SelectItem key={option.key} endContent={<p className="text-tiny text-foreground-400">{option.description}</p>}>
          {option.label}
        </SelectItem>
      ))}
    </Select>
  )
})

export default BackgroundSelect
