import { cn, Switch } from '@heroui/react'
import { forwardRef } from 'react'

const SwitchInput = forwardRef(({ label, description, className, value, ...props }, ref) => {
  return (
    <div className={cn('flex justify-end items-center gap-4', className)}>
      <span className="flex flex-col gap-1">
        <label className="text-small font-medium text-foreground">{label}</label>
        {description && <p className="text-tiny text-foreground-400">{description}</p>}
      </span>
      <Switch {...props} ref={ref} isSelected={value} />
    </div>
  )
})

export default SwitchInput
