import { forwardRef, Slider } from '@heroui/react'

const TemperatureRange = forwardRef((props, ref) => {
  return (
    <div className="flex flex-col gap-2">
      <Slider
        defaultValue={0.5}
        label="Temperature"
        maxValue={1}
        minValue={0}
        step={0.1}
        {...props}
        ref={ref}
        classNames={{
          label: 'text-small font-medium text-foreground',
        }}
      />
      <p className="text-tiny text-foreground-400">
        A value between 0 and 1 that controls the randomness of the LLM. 0 will
        follow rules better, while 1 will be more creative.
      </p>
    </div>
  )
})

export default TemperatureRange
