import { ErrorMessage } from '@hookform/error-message'
import { useEffect, useRef } from 'react'
import { useFormContext } from 'react-hook-form'
import styled from 'styled-components'
import { ErrorText } from './ErrorText'

export default function RangeInput({
  fieldName,
  label,
  controller,
  sliderValue,
  id,
  disabled = false,
  ...props
}) {
  const {
    register,
    formState: { errors },
  } = useFormContext()

  const sliderRef = useRef()

  useEffect(() => {
    const handleProgress = () => {
      const slider = document.querySelector(`#${id}`)
      if (slider) {
        const totalRange = slider.max - slider.min
        const adjustedValue = sliderValue - slider.min
        const progress = (adjustedValue / totalRange) * 100
        slider.style.background = `linear-gradient(to right, #433DFF ${progress}%, #F0F0ED ${progress}%)`
      }
    }
    handleProgress()
  }, [id, sliderValue])

  return (
    <div className="flex flex-col gap-2.5 w-full">
      {label && <>{label}</>}
      {!controller
        ? (
            <StyledRange
              disabled={disabled}
              ref={sliderRef}
              {...register(fieldName)}
              type="range"
              id={id}
              {...props}
            />
          )
        : (
            <StyledRange disabled={disabled} ref={sliderRef} id={id} {...props} />
          )}
      <ErrorMessage
        errors={errors}
        name={fieldName}
        render={({ message }) => <ErrorText message={message} />}
      />
    </div>
  )
}

const StyledRange = styled.input`
  /* removing default appearance */
  -webkit-appearance: none;
  appearance: none;
  /* creating a custom design */
  width: 100%;
  cursor: pointer;
  outline: none;
  border-radius: 15px;
  height: 7.6px;
  /* Thumb: webkit */
  &::-webkit-slider-thumb {
    /* removing default appearance */
    -webkit-appearance: none;
    appearance: none;
    /* creating a custom design */
    height: 20px;
    width: 20px;
    background-color: white;
    border-radius: 50%;
    border: 1px solid #a49e97;
    transition: 0.2s ease-in-out;
  }

  /* Thumb: Firefox */
  &::-moz-range-thumb {
    height: 15px;
    width: 15px;
    background-color: white;
    border-radius: 50%;
    border: none;
    transition: 0.2s ease-in-out;
  }

  /* Hover, active & focus Thumb: Webkit */
  &::-webkit-slider-thumb:hover {
    box-shadow: 0 0 0 10px rgba(236, 235, 255, 0.1);
  }
  &:active::-webkit-slider-thumb {
    box-shadow: 0 0 0 13px rgba(236, 235, 255, 0.2);
  }
  &:focus::-webkit-slider-thumb {
    box-shadow: 0 0 0 13px rgba(236, 235, 255, 0.2);
  }

  /* Hover, active & focus Thumb: Firfox */
  &::-moz-range-thumb:hover {
    box-shadow: 0 0 0 10px rgba(255, 85, 0, 0.1);
  }
  &:active::-moz-range-thumb {
    box-shadow: 0 0 0 13px rgba(255, 85, 0, 0.2);
  }
  &:focus::-moz-range-thumb {
    box-shadow: 0 0 0 13px rgba(255, 85, 0, 0.2);
  }

  &:focus {
    outline: none;
  }
  &:active {
    cursor: grabbing !important;
  }
  &:hover {
    cursor: grab;
  }
`
