import { forwardRef, Input } from '@heroui/react'

const VoicemailSMSInput = forwardRef((props, ref) => {
  return (
    <Input
      {...props}
      ref={ref}
      label="Voicemail SMS Message"
      labelPlacement="outside"
      variant="bordered"
      radius="sm"
      placeholder="Hey there, I'm from BlandAI, and just gave you a ring. Let me know when you're free to chat!"
      description="When the AI encounters a voicemail, it will send a SMS to the user with the message below, after the beep."
    />
  )
})

export default VoicemailSMSInput
