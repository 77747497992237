// components/Settings/GeneralSettings/RecordingPreferences.js
import { Button, TextField } from '@radix-ui/themes'

/**
 * Controls the "use bland url" checkbox,
 * the "infinite vs. custom" lifespan logic,
 * and triggers a confirmation when saving.
 *
 * Props:
 *   - useBlandUrl, setUseBlandUrl
 *   - lifespanMode, setLifespanMode ("infinite" or "custom")
 *   - customDays, setCustomDays
 *   - setShowRecordingModal (to open the modal)
 */
export default function RecordingPreferences({
  useBlandUrl,
  setUseBlandUrl,
  lifespanMode,
  setLifespanMode,
  customDays,
  setCustomDays,
  setShowRecordingModal,
}) {
  return (
    <div
      style={{
        marginTop: 30,
        border: '1px solid #e9e9e9',
        borderRadius: 4,
        padding: 20,
      }}
    >
      <p style={{ fontSize: 14, fontWeight: 500, marginBottom: 6 }}>
        Recording Preferences
      </p>
      <p style={{ marginBottom: 10, color: 'GrayText', fontSize: 12 }}>
        These settings affect the entire organization. They control how long the
        returned recording link remains valid, and whether to wrap the Twilio
        URL in a “bland” link for branding/obfuscation.
      </p>

      {/* useBlandUrl */}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: 15,
          gap: 10,
        }}
      >
        <label
          htmlFor="useBlandUrlCheckbox"
          style={{ fontSize: 14, fontWeight: 400 }}
        >
          Use Bland URL:
        </label>
        <input
          id="useBlandUrlCheckbox"
          type="checkbox"
          checked={useBlandUrl}
          onChange={e => setUseBlandUrl(!useBlandUrl)}
          style={{ cursor: 'pointer' }}
        />
      </div>

      {/* Lifespan dropdown */}
      <div style={{ marginBottom: 15 }}>
        <label
          htmlFor="lifespanModeSelect"
          style={{ fontSize: 14, fontWeight: 400, display: 'block' }}
        >
          Recording Lifespan:
        </label>
        <p style={{ color: 'GrayText', fontSize: 12, marginTop: 4 }}>
          (Choose “Infinite” or specify a custom number of days from 1–1825)
        </p>

        <select
          id="lifespanModeSelect"
          value={lifespanMode}
          onChange={e => setLifespanMode(e.target.value)}
          style={{
            marginTop: 6,
            padding: '4px 6px',
            borderRadius: 2,
            border: '1px solid #e9e9e9',
            cursor: 'pointer',
            fontSize: 14,
          }}
        >
          <option value="infinite">Infinite</option>
          <option value="custom">Custom</option>
        </select>
      </div>

      {/* Custom days input */}
      {lifespanMode === 'custom' && (
        <div
          style={{
            marginTop: 10,
            display: 'flex',
            flexDirection: 'column',
            maxWidth: 200,
          }}
        >
          <label
            htmlFor="customDaysInput"
            style={{ fontSize: 14, fontWeight: 400 }}
          >
            Enter number of days:
          </label>
          <TextField.Root
            id="customDaysInput"
            style={{ marginTop: 5 }}
            placeholder="e.g. 3"
            value={customDays}
            onChange={e => setCustomDays(e.target.value)}
            size="3"
          />
        </div>
      )}

      {/* Save button => triggers confirmation modal */}
      <div
        style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20 }}
      >
        <Button
          variant="solid"
          color="iris"
          style={{ borderRadius: 4, cursor: 'pointer' }}
          onClick={() => setShowRecordingModal(true)}
        >
          Update Preferences
        </Button>
      </div>
    </div>
  )
}
