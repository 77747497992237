import {
    Avatar,
    Button,
    Chip,
    Drawer,
    DrawerBody,
    DrawerContent,
    DrawerHeader,
    Input,
    Spinner,
    Tooltip,
    cn,
    addToast,
    Popover,
    PopoverTrigger,
    PopoverContent,
    Textarea
  } from "@heroui/react"
  import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
  import { 
    CalendarDays, 
    Clock, 
    ExternalLink, 
    Phone, 
    Check, 
    Copy, 
    ChevronDown,
    MoveDownRight,
    MoveUpRight,
    X,
    MoreVertical,
    Trash2
  } from "lucide-react"
  import { useState, useEffect } from "react"
  import { $fetch } from "utils/fetch"
  import GradientLoadingAnimation from "components/Reusables/GradientLoadingAnimation"
import VoiceGradientAvatar from "../SendCall/VoiceGradientReusable"
import { toast } from "react-toastify"
  
  function CopyCallID({ id, maxLength = 20 }) {
    const [copied, setCopied] = useState(false)

    if (!id) return null
  
    const displayId = id.length > maxLength ? `${id.substring(0, maxLength)}...` : id
  
    const copyToClipboard = () => {
      navigator.clipboard.writeText(id)
      setCopied(true)
      setTimeout(() => setCopied(false), 2000)
    }
  
    return (
      <div className="flex items-center">
        <code className="text-xs font-mono bg-default-50 dark:bg-default-900/30 px-2.5 py-1 rounded-lg text-default-700 dark:text-default-300 mr-2">
          {displayId}
        </code>
        <Tooltip content={copied ? "Copied!" : "Copy Call ID"}>
          <Button
            size="sm"
            variant="light"
            isIconOnly
            onPress={copyToClipboard}
            className="h-7 w-7 min-w-0 bg-default-100 dark:bg-default-800 hover:bg-primary-100 dark:hover:bg-primary-900/30"
          >
            {copied ? <Check className="h-3.5 w-3.5 text-success-500" /> : <Copy className="h-3.5 w-3.5" />}
          </Button>
        </Tooltip>
      </div>
    )
  }
  
  export default function MemoryDetails({ memoryId, onClose, isOpen = true }) {
    const [selectedPhoneNumber, setSelectedPhoneNumber] = useState(null)
    const [memoryName, setMemoryName] = useState("")
    const [editableSummary, setEditableSummary] = useState("")
    const [editableMetadata, setEditableMetadata] = useState("")
    const [isEditingSummary, setIsEditingSummary] = useState(false)
    const [isEditingMetadata, setIsEditingMetadata] = useState(false)
    const queryClient = useQueryClient()
  
    const { data, isLoading, error } = useQuery({
      queryKey: ["memoryDetails", memoryId],
      queryFn: async () => {
        const response = await $fetch(`/v1/memory/${memoryId}`)
        console.log(response)
        return response.data
      },
      enabled: !!memoryId,
    })

    const { data: userDetails, isLoading: isLoadingUserDetails } = useQuery({
      queryKey: ["userDetails", memoryId, selectedPhoneNumber],
      queryFn: async () => {
        const response = await $fetch(`/v1/memory/${memoryId}/user`, {
          method: "POST",
          body: {
            phone_number: selectedPhoneNumber
          }
        })

        console.log(response)
        return response.data
      },
      enabled: !!memoryId && !!selectedPhoneNumber,
    })
  
    // Update memory name when data changes
    useEffect(() => {
      if (data?.name) {
        setMemoryName(data.name)
      }
    }, [data])
  
    // Reset selected phone number when drawer closes
    useEffect(() => {
      if (!isOpen) {
        setSelectedPhoneNumber(null)
      }
    }, [isOpen])
  
    const formatDate = (dateString) => {
      const date = new Date(dateString)
      return {
        month: date.toLocaleString('default', { month: 'short' }),
        day: date.getDate(),
        time: date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
        fullDate: `${date.toLocaleString('default', { month: 'short' })} ${date.getDate()} ${date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`
      }
    }
  
    const viewTranscript = (callId, e) => {
      e.stopPropagation()
      window.open(`/dashboard/call-logs/${callId}`, "_blank")
    }
  
    const handleUserClick = (phoneNumber) => {
      setSelectedPhoneNumber(phoneNumber)
    }

    // Calculate total calls in this memory
    const getTotalCalls = () => {
      if (!data?.users) return 0
      return data.users.reduce((total, user) => total + (user.call_count || 0), 0)
    }

    // Add the update user details mutation
    const updateUserDetailsMutation = useMutation({
      mutationFn: ({phone_number, metadata, summary}) => $fetch(`/v1/memory/${memoryId}/user/update`, {
        method: 'POST',
        body: JSON.stringify({
          phone_number, 
          metadata,
          summary,
        }),
      }),
      onSuccess: () => {
        toast.success('Memory Details for phone number updated', {
            position: "top-left"
        })
        // Using refetchQueries instead of invalidateQueries to prevent sidebar closure
        queryClient.refetchQueries({ queryKey: ['memoryDetails', memoryId] })
        queryClient.refetchQueries({ queryKey: ['userDetails', memoryId, selectedPhoneNumber] })
      },
      onError: (error) => {
        toast.error(error.message || 'An error occurred while updating user details', {
            position: "top-left"
        })
      }
    })

    // Add the delete call mutation
    const deleteCallMutation = useMutation({
      mutationFn: (callId) => $fetch(`/v1/memory/${memoryId}/call/${callId}/delete`, {
        method: 'POST',
        body: JSON.stringify({ phone_number: selectedPhoneNumber }),
      }),
      onSuccess: () => {
        toast.success('Call deleted successfully', {
            position: "top-left"
        })
        
        queryClient.refetchQueries({ queryKey: ['userDetails', memoryId, selectedPhoneNumber] })
        queryClient.refetchQueries({ queryKey: ['memoryDetails', memoryId] })
      },
      onError: (error) => {
        toast.error('Error deleting call, please try again', {
            position: "top-left"
        })
      },
    })

    // Handle saving user summary
    const handleSaveSummary = () => {
      if (selectedPhoneNumber) {
        updateUserDetailsMutation.mutate({
          phone_number: selectedPhoneNumber,
          summary: editableSummary,
          metadata: userDetails?.metadata || ''
        });
      }
      setIsEditingSummary(false);
    }

    // Handle saving user metadata
    const handleSaveMetadata = () => {
      if (selectedPhoneNumber) {
        try {
          updateUserDetailsMutation.mutate({
            phone_number: selectedPhoneNumber,
            metadata: editableMetadata,
            summary: userDetails?.summary || ''
          });
        } catch (error) {
          addToast({
            title: 'Error updating metadata',
            description: "Error updating metadata, please try again",
            color: 'error',
          });
          return;
        }
      }
      setIsEditingMetadata(false);
    }

    // Initialize editable fields when user details change
    useEffect(() => {
      if (userDetails) {
        setEditableSummary(userDetails.summary || '');
        setEditableMetadata(userDetails.metadata || '');
      }
    }, [userDetails]);

    // Render call history list for a user
    const renderCallHistory = () => {
      if (!userDetails?.calls || userDetails.calls.length === 0) {
        return (
          <div className="p-4 border border-default-100 rounded-md text-center text-default-500">
            No call history available for this user.
          </div>
        )
      }

      return (
        <div className="border rounded-md overflow-hidden">
          <table className="w-full border-collapse">
            <tbody>
              {userDetails.calls.map((call) => {
                const dateInfo = call.created_at ? formatDate(call.created_at) : null;
                const duration = call.call_length ? 
                  `${Math.floor(call.call_length)}:${String(Math.round((call.call_length % 1) * 60)).padStart(2, '0')}` : 
                  'N/A';
                
                return (
                  <tr 
                    key={call.call_id || call.c_id}
                    className="border-b border-default-100 hover:bg-default-50"
                  >
                    <td className="py-3 pl-3 whitespace-nowrap">
                      <CopyCallID id={call.c_id} maxLength={10} />
                    </td>
                    <td className="py-3 px-2 w-10 text-center">
                      {call.inbound ? 
                        <MoveDownRight size={16} color="#4CAF50" /> : 
                        <MoveUpRight size={16} color="#2196F3" />
                      }
                    </td>
                    <td className="py-3 px-2 whitespace-nowrap text-sm text-default-600">
                      {dateInfo ? `${dateInfo.month} ${dateInfo.day} ${dateInfo.time}` : 'N/A'}
                    </td>
                    <td className="py-3 px-2 whitespace-nowrap text-sm text-default-600">
                      {duration}
                    </td>
                    <td className="py-3 pr-3 w-10 text-right">
                      <Popover placement="bottom-end">
                        <PopoverTrigger>
                          <button 
                            onClick={(e) => e.stopPropagation()}
                            className="text-default-400 hover:text-default-600"
                          >
                            <MoreVertical size={14} />
                          </button>
                        </PopoverTrigger>
                        <PopoverContent className="w-48">
                          <div className="py-1">
                            <button 
                              onClick={() => viewTranscript(call.c_id, new Event('click'))}
                              className="flex w-full items-center px-3 py-2 text-sm text-default-700 hover:bg-default-100"
                            >
                              <ExternalLink size={14} className="mr-2" />
                              View Transcript
                            </button>
                            <button 
                              onClick={() => deleteCallMutation.mutate(call.c_id)}
                              className="flex w-full items-center px-3 py-2 text-sm text-danger-600 hover:bg-danger-50"
                            >
                              <Trash2 size={14} className="mr-2" />
                              Delete Call
                            </button>
                          </div>
                        </PopoverContent>
                      </Popover>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      )
    }
  
    const renderUsersList = () => {
      if (isLoading) {
        return (
          <div className="flex justify-center items-center h-64">
            <GradientLoadingAnimation message="Loading memory details..."/>
          </div>
        )
      }
  
      if (error) {
        return (
          <div className="p-5 m-3 border border-danger-200 bg-danger-50 dark:bg-danger-900/20 dark:border-danger-800 rounded-lg text-center">
            <p className="text-danger-600 dark:text-danger-400 font-medium">Error loading memory details</p>
            <p className="text-xs text-danger-500 dark:text-danger-300 mt-1">{error.message}</p>
          </div>
        )
      }
  
      if (!data || !data.users || data.users.length === 0) {
        return (
          <div className="p-5 m-3 border border-primary-200 bg-primary-50 dark:bg-primary-900/20 dark:border-primary-800 rounded-lg text-center">
            <p className="text-primary-600 dark:text-primary-400 font-medium">No users available</p>
            <p className="text-xs text-primary-500 dark:text-primary-300 mt-1">
              This memory doesn't have any users yet.
            </p>
          </div>
        )
      }
  
      return (
        <>
          <div className="flex justify-between items-center mb-2">
            <div className="text-base font-medium">Users</div>
            <div className="text-sm text-default-600">Total Calls: {getTotalCalls()}</div>
          </div>
          
          <div className="border rounded-md overflow-hidden">
            <table className="w-full border-collapse">
              <thead>
                <tr className="bg-default-50 border-b border-default-100">
                  <th className="py-2 px-3 text-left text-sm font-medium text-default-500">Phone Number</th>
                  <th className="py-2 px-3 text-left text-sm font-medium text-default-500">Call Count</th>
                  <th className="py-2 px-3 text-left text-sm font-medium text-default-500">Last Call</th>
                  <th className="py-2 px-3 w-12"></th>
                </tr>
              </thead>
              <tbody>
                {data.users.map((user) => {
                  const dateInfo = user.last_call_at ? formatDate(user.last_call_at) : null;
                  
                  return (
                    <tr 
                      key={user.phone_number}
                      className="border-b border-default-100 cursor-pointer hover:bg-default-50"
                      onClick={() => handleUserClick(user.phone_number)}
                    >
                      <td className="py-3">
                        <a href="#" className="pl-3 text-primary-600 font-medium" onClick={(e) => e.preventDefault()}>
                          {user.phone_number}
                        </a>
                      </td>
                      <td className="py-3 pl-3 text-sm text-default-600">
                        {user.call_count || 0}
                      </td>
                      <td className="py-3 text-sm text-default-600">
                        {dateInfo ? `${dateInfo.month} ${dateInfo.day} ${dateInfo.time}` : 'N/A'}
                      </td>
                      <td className="py-3 pr-3 w-12 text-right">
                        <button 
                          onClick={(e) => e.stopPropagation()}
                          className="text-default-500 hover:text-default-700"
                        >
                          <MoreVertical size={18} />
                        </button>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </>
      )
    }
  
    const renderContent = () => {
      const memoryView = (
        <div className="space-y-6">
          <div className="mb-6">
            <label className="block text-sm text-default-600 mb-2">Memory Name</label>
            <Input
              value={memoryName}
              isDisabled
              onChange={(e) => setMemoryName(e.target.value)}
              className="w-full"
            />
          </div>

          {data?.created_at && (
            <div className="mb-6">
              <label className="block text-sm text-default-600 mb-2">Created</label>
              <div className="flex items-center text-sm text-default-600">
                <CalendarDays className="h-4 w-4 mr-2 text-default-400" />
                {formatDate(data.created_at).fullDate}
              </div>
            </div>
          )}
          
          {renderUsersList()}
        </div>
      );
      
      // If a phone number is selected, show both views with a divider
      if (selectedPhoneNumber && data?.users) {
        const userInfo = data.users.find(user => user.phone_number === selectedPhoneNumber);
        
        if (!userInfo) {
          return (
            <div className="grid grid-cols-2 gap-0">
              <div className="pr-6">
                {memoryView}
              </div>
              <div className="pl-6 border-l border-default-200">
                <div className="p-5 border border-primary-200 bg-primary-50 rounded-lg text-center">
                  <p className="text-primary-600 font-medium">No details available for this user</p>
                </div>
              </div>
            </div>
          );
        }

        if (isLoadingUserDetails) {
          return (
            <div className="grid grid-cols-2 gap-0">
              <div className="pr-6">
                {memoryView}
              </div>
              <div className="pl-6 border-l border-default-200">
                <div className="flex justify-center items-center h-64">
                    <GradientLoadingAnimation message="Loading user details..."/>
                </div>
              </div>
            </div>
          );
        }
        
        return (
          <div className="grid grid-cols-2 gap-0">
            <div className="pr-6">
              {memoryView}
            </div>
            <div className="pl-6 border-l border-default-200">
              <div className="flex items-center mb-4">
                <div className="h-10 w-10 rounded-full flex items-center justify-center text-white mr-3">
                  <VoiceGradientAvatar size={30} />
                </div>
                <h3 className="text-lg font-semibold text-default-900">{userInfo.phone_number}</h3>
              </div>
              
              <div className="space-y-4 mb-6">
                <div className="flex justify-between">
                  <div className="text-sm text-default-500">Total Calls</div>
                  <div className="text-sm font-medium">
                    {userInfo.call_count || 0} Calls
                  </div>
                </div>
                <div className="flex justify-between border-t border-default-100 pt-3">
                  <div className="text-sm text-default-500">Incoming Calls</div>
                  <div className="text-sm font-medium">
                    {userDetails?.calls?.filter(call => call.inbound).length || 0} Calls
                  </div>
                </div>
                <div className="flex justify-between border-t border-default-100 pt-3">
                  <div className="text-sm text-default-500">Outgoing Calls</div>
                  <div className="text-sm font-medium">
                    {userDetails?.calls?.filter(call => !call.inbound).length || 0} Calls
                  </div>
                </div>
                {userInfo.last_call_at && (
                  <div className="flex justify-between border-t border-default-100 pt-3">
                    <div className="text-sm text-default-500">Last Call</div>
                    <div className="text-sm font-medium">
                      {formatDate(userInfo.last_call_at).fullDate}
                    </div>
                  </div>
                )}
              </div>
              
              {userDetails && (
                <>
                  <div className="mb-6 border-t border-default-200 pt-4">
                    <div className="flex justify-between items-center mb-3">
                      <div className="text-base font-medium">Summary</div>
                      <Button 
                        size="sm" 
                        variant="light" 
                        className="text-primary-600"
                        onPress={() => setIsEditingSummary(!isEditingSummary)}
                      >
                        {isEditingSummary ? "Cancel" : "Update"}
                      </Button>
                    </div>
                    <div className="p-3 bg-default-50 rounded-md text-sm">
                      {!isEditingSummary ? (
                        <p className="whitespace-pre-wrap">
                          {userDetails.summary ? userDetails.summary : 
                            (userDetails.metadata && userDetails.metadata.description) ? 
                            userDetails.metadata.description : 
                            <span className="text-default-500 italic">(Auto generated)</span>}
                        </p>
                      ) : (
                        <div className="space-y-3">
                          <Textarea
                            className="w-full min-h-[100px] text-sm"
                            value={editableSummary}
                            onChange={(e) => setEditableSummary(e.target.value)}
                            placeholder="Enter summary..."
                          />
                          <div className="flex justify-end gap-2">
                            <Button
                              size="sm"
                              variant="flat"
                              color="default"
                              onPress={() => setIsEditingSummary(false)}
                            >
                              Cancel
                            </Button>
                            <Button
                              size="sm"
                              color="primary"
                              onPress={handleSaveSummary}
                            >
                              Save Changes
                            </Button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  
                  <div className="mb-6">
                    <div className="flex justify-between items-center mb-3">
                      <div className="text-base font-medium">Metadata</div>
                      <Button 
                        size="sm" 
                        variant="light" 
                        className="text-primary-600"
                        onPress={() => setIsEditingMetadata(!isEditingMetadata)}
                      >
                        {isEditingMetadata ? "Cancel" : (userDetails.metadata ? "Update" : "Add")}
                      </Button>
                    </div>
                    {!isEditingMetadata ? (
                      userDetails.metadata ? (
                        <div className="p-3 bg-default-50 rounded-md text-sm font-mono">
                          <p className="whitespace-pre-wrap">{userDetails.metadata}</p>
                        </div>
                      ) : (
                        <div className="text-default-500 text-sm">No metadata available</div>
                      )
                    ) : (
                      <div className="space-y-3">
                        <Textarea
                          className="w-full min-h-[150px] font-mono text-sm"
                          value={editableMetadata}
                          onChange={(e) => setEditableMetadata(e.target.value)}
                          placeholder="Enter metadata JSON..."
                        />
                        <div className="flex justify-end gap-2">
                          <Button
                            size="sm"
                            variant="flat"
                            color="default"
                            onPress={() => setIsEditingMetadata(false)}
                          >
                            Cancel
                          </Button>
                          <Button
                            size="sm"
                            color="primary"
                            onPress={handleSaveMetadata}
                          >
                            Save Changes
                          </Button>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="mb-6">
                    <div className="flex justify-between items-center mb-3">
                      <div className="text-base font-medium">Call History</div>
                      <Button size="sm" variant="ghost" className="min-w-0 p-1">
                        <ChevronDown size={18} />
                      </Button>
                    </div>
                    
                    {renderCallHistory()}
                  </div>
                </>
              )}
            </div>
          </div>
        );
      }
      
      // If no phone number is selected, just show the memory view
      return memoryView;
    }
  
    return (
      <Drawer
        isOpen={isOpen}
        onClose={onClose}
        placement="right"
        size="xl"
        radius="none"
        classNames={{
          base: cn("transition-all duration-300 z-[100]", selectedPhoneNumber ? "max-w-5xl" : "max-w-2xl") ,
          header: "border-b border-default-200",
          body: "px-6 py-6 z-[100]",

        }}
      >
        <DrawerContent>
          <DrawerHeader className="py-4">
            <div className="flex justify-between items-center">
              <h2 className="text-lg font-semibold text-default-900">Memory Details</h2>
            </div>
          </DrawerHeader>
          <DrawerBody>
            {renderContent()}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    )
  }