import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { getApiKey } from 'utils/funcs/browser/getApiKey'
import { generateEncryptedKey } from 'utils/funcs/byot/generateEncryptedKey'
import AccountSid from '../../../../Parameters/AccountSid'
import AuthToken from '../../../../Parameters/AuthToken'
import 'react-toastify/dist/ReactToastify.css'

function GenerateKey() {
  const [submitting, setSubmitting] = useState(false)
  const [encryptedKey, setEncryptedKey] = useState('')
  const apiKey = getApiKey()
  const methods = useForm()

  const onSubmit = async (formData) => {
    setSubmitting(true)
    const { account_sid, auth_token } = formData
    try {
      const response = await generateEncryptedKey(
        apiKey,
        account_sid,
        auth_token,
      )
      if (response.status === 'success') {
        toast.success('Encrypted key generated successfully!')
        setEncryptedKey(response.encrypted_key)
      }
      else {
        toast.error('Failed to generate key')
      }
    }
    catch (error) {
      console.error(error)
      toast.error('An error occurred while generating the key')
    }
    setSubmitting(false)
  }

  useEffect(() => {
    methods.setValue('encrypted_key', encryptedKey)
  }, [encryptedKey, methods])

  return (
    <div className="max-w-2xl mx-auto p-4 bg-white rounded-md shadow-md">
      <h2 className="text-lg font-semibold mb-4">Generate Encrypted Key</h2>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} className="space-y-4">
          {!encryptedKey
            ? (
                <>
                  <p className="text-gray-600 mb-2.5">
                    Enter your Twilio "Account SID" and "Auth Token." We'll generate
                    an Encrypted Key that you can use in your API requests.
                  </p>
                  <div className="space-y-2.5">
                    <AccountSid fieldName="account_sid" />
                    <div className="text-2xs text-gray-500">
                      Hint: Account SID typically starts with "AC" and is 34
                      characters long.
                    </div>
                    <AuthToken fieldName="auth_token" />
                    <div className="text-2xs text-gray-500">
                      Hint: Auth Token is typically 32 characters long.
                    </div>
                  </div>
                  <div className="bg-blue-50 border-l-4 border-blue-400 p-2.5 mb-2.5">
                    <p className="text-xs text-blue-700">
                      The Encrypted Key will only be displayed to you for security
                      and cannot be retrieved later without regenerating.
                    </p>
                  </div>
                  <button
                    type="submit"
                    disabled={submitting}
                    className={`w-full py-1.5 px-2.5 border border-transparent rounded shadow-sm text-xs font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${
                      submitting ? 'opacity-50 cursor-not-allowed' : ''
                    }`}
                  >
                    {submitting ? 'Generating...' : 'Generate Key'}
                  </button>
                </>
              )
            : (
                <>
                  <div>
                    <label
                      htmlFor="encrypted_key"
                      className="block text-xs font-medium text-gray-700"
                    >
                      Encrypted Key
                    </label>
                    <input
                      type="text"
                      id="encrypted_key"
                      {...methods.register('encrypted_key')}
                      readOnly
                      className="mt-0.5 block w-full border border-gray-300 rounded shadow-sm py-1.5 px-2 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-xs"
                    />
                  </div>
                  <div className="bg-yellow-50 border-l-4 border-yellow-400 p-2.5">
                    <p className="text-xs text-yellow-700">
                      Save this! The Encrypted Key will only be displayed to you for
                      security and cannot be retrieved later without regenerating.
                    </p>
                  </div>
                </>
              )}
        </form>
      </FormProvider>
    </div>
  )
}

export default GenerateKey
