import { Button } from '@radix-ui/themes'
// components/Settings/GeneralSettings/ConfirmationModal.js

/**
 * A simple overlay confirmation modal.
 * Props:
 *   - showRecordingModal
 *   - setShowRecordingModal
 *   - updatingRecordingPref
 *   - handleConfirmRecordingUpdate()
 */
export default function ConfirmationModal({
  showRecordingModal,
  setShowRecordingModal,
  updatingRecordingPref,
  handleConfirmRecordingUpdate,
}) {
  if (!showRecordingModal)
    return null

  return (
    <div
      style={{
        position: 'fixed',
        inset: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        zIndex: 9999,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      onClick={() => setShowRecordingModal(false)}
    >
      <div
        style={{
          background: '#fff',
          padding: 20,
          borderRadius: 6,
          minWidth: 300,
          boxShadow: '0 4px 10px rgba(0,0,0,0.1)',
        }}
        onClick={e => e.stopPropagation()}
      >
        <h3 style={{ marginTop: 0, marginBottom: 10, fontSize: 16 }}>
          Confirm Changes
        </h3>
        <p style={{ fontSize: 14 }}>
          These changes will update the recording preferences for the entire
          organization. Proceed?
        </p>

        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: 10,
            marginTop: 20,
          }}
        >
          <Button
            variant="soft"
            color="gray"
            onClick={() => setShowRecordingModal(false)}
            disabled={updatingRecordingPref}
            style={{ borderRadius: 4, cursor: 'pointer' }}
          >
            Cancel
          </Button>
          <Button
            variant="solid"
            color="iris"
            onClick={handleConfirmRecordingUpdate}
            disabled={updatingRecordingPref}
            loading={updatingRecordingPref ? 1 : undefined}
            style={{ borderRadius: 4, cursor: 'pointer' }}
          >
            Confirm
          </Button>
        </div>
      </div>
    </div>
  )
}
