import React from 'react'
import {
  IconButton,
  DropdownMenu,
  Tooltip,
} from '@radix-ui/themes'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { Menu } from '@headlessui/react'
import {
  ChevronLeft as ChevronLeftIcon,
  MoreVertical,
  Download,
  Share2 as LuShare2,
  Grid,
  CheckCheck as CheckCheckIcon,
  Beaker as BeakerIcon,
  Braces as CurlyBraces,
  Badge,
  MessageSquare,
} from 'lucide-react'
import { PiSpinnerGapLight } from 'react-icons/pi'
import { FaCaretDown } from 'react-icons/fa'
import { Chip } from '@heroui/react'

const PathwayHeader = ({
  inputLabel,
  embedMode,
  graphID,
  isVersionLoading,
  loading,
  selectedVersion,
  versions,
  isRootUser,
  clonePathway,
  isSaving,
  exportFlow,
  isOpen,
  setIsOpen,
  setShowCallLogsModal,
  setCallLogsActiveTab,
  isWindowTooSmall,
  showPathwayLogs,
  setCollapsePathwayLogs,
  setShowShareModal,
  processDownload,
  setShowPublishConfirm,
  isSmsPathway = false,
  children,
}) => {
  const navigate = useNavigate()

  console.log('isSmsPathway', isSmsPathway)

  return (
    <header
      style={{ height: 56, paddingLeft: 56 + 15, top: 0, right: 0 }}
      className="flex justify-between items-center bg-white border border-b-[1px] py-1.5"
    >
      <div className="flex items-center gap-2.5">
        {!embedMode && (
          <IconButton
            style={{ cursor: 'pointer' }}
            color="gray"
            variant="ghost"
            size="2"
            radius="full"
            onClick={() => {
              navigate(
                `/dashboard/convo-pathways?id=${graphID}&view=splash`,
              )
            }}
          >
            <ChevronLeftIcon size={14} />
          </IconButton>
        )}
        <div className="ml-0.5 flex items-center gap-2">
          <p style={{ fontSize: 13, fontWeight: '600' }}>
            {inputLabel}
          </p>
          <p className="text-gray-700 text-[11px]">
            {isSmsPathway ? 'SMS Pathway' : 'Conversational Pathway'}
          </p>
        </div>

        {!embedMode && (
          <div className="flex items-center flex-wrap gap-1.5 ml-2.5">
            <Menu as="div" className="relative">
              <Menu.Button className="flex items-center px-2 py-1.5 text-xs font-medium text-gray-700 bg-gray-100 rounded hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ml-2.5">
                <span className="mr-1.5">
                  {isVersionLoading || loading
                    ? (
                        <PiSpinnerGapLight className=" animate-spin w-3 h-3 rounded-md" />
                      )
                    : selectedVersion
                      ? (
                          selectedVersion.name
                          || `Version ${selectedVersion.version_number}`
                        )
                      : (
                          'Select Version'
                        )}
                </span>

                {!isVersionLoading
                  && !loading
                  && selectedVersion
                  && selectedVersion.is_staging && (
                  <span className="mr-1.5 px-1.5 py-0.5 bg-green-100 text-green-800 text-2xs rounded-full">
                    Staging
                  </span>
                )}

                {!isVersionLoading
                  && !loading
                  && selectedVersion
                  && selectedVersion.version_number === 0 && (
                  <span className="mr-1.5 px-1.5 py-0.5 bg-blue-100 text-blue-800 text-2xs rounded-full">
                    Production (Cannot be edited)
                  </span>
                )}

                {!isVersionLoading
                  && !loading
                  && selectedVersion
                  && selectedVersion.version_number < 0 && (
                  <span className="mr-1.5 px-1.5 py-0.5 bg-blue-100 text-blue-800 text-2xs rounded-full">
                    Prev Published (Cannot be edited)
                  </span>
                )}

                <FaCaretDown className="w-2.5 h-2.5" />
              </Menu.Button>

              <Menu.Items
                style={{ marginTop: 15 }}
                className="absolute right-0 w-[300px] origin-top-right bg-white divide-y divide-gray-100 rounded shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none z-[100] max-h-[400px] overflow-y-auto"
              >
                {versions?.length > 0
                  && versions?.map((version, index) => (
                    <Menu.Item key={index}>
                      {({ active }) => (
                        <div
                          className={`${
                            active ? 'bg-indigo-50' : 'bg-white'
                          } ${
                            selectedVersion.version_number
                            === version.version_number
                              ? !'bg-indigo-100'
                              : ''
                          } group flex justify-between items-center w-full px-2.5 py-2 text-xs cursor-pointer`}
                          onClick={() => {
                            window.location.href = `/dashboard?page=convo-pathways&id=${graphID}&version=${version.version_number}`
                          }}
                        >
                          <div className="flex flex-col">
                            <span className="font-medium">
                              {version.name
                                || `Version ${version.version_number}`}
                            </span>
                            <span className="text-2xs text-gray-500">
                              {version.created_at
                                && new Date(
                                  version.created_at,
                                ).toLocaleString('en-US', {
                                  month: 'short',
                                  day: '2-digit',
                                  year: 'numeric',
                                  hour: '2-digit',
                                  minute: '2-digit',
                                  hour12: true,
                                })}
                              {version.version_number === 0
                                && 'View Live Pathway'}
                            </span>
                          </div>
                          <div className="flex items-center">
                            {version.is_staging && (
                              <span className="mr-1.5 px-1.5 py-0.5 bg-green-100 text-green-800 text-2xs rounded-full">
                                Staging
                              </span>
                            )}
                            {version.version_number === 0 && (
                              <span className="mr-1.5 px-1.5 py-0.5 bg-blue-100 text-blue-800 text-2xs rounded-full">
                                Production
                              </span>
                            )}
                          </div>
                        </div>
                      )}
                    </Menu.Item>
                  ))}
              </Menu.Items>
            </Menu>
          </div>
        )}

        <button
          className="bg-indigo-400 hover:bg-indigo-600 text-white font-medium px-2.5 py-0.5 rounded-full float-right cursor-pointer shadow-md ml-2.5 lg:flex hidden items-center gap-1.5 text-2xs"
          onClick={() => {
            navigator.clipboard.writeText(graphID)
            toast.success('ID copied to clipboard', {
              position: 'bottom-right',
            })
          }}
        >
          Copy ID
        </button>
      </div>

      <div className="flex flex-wrap items-center gap-2.5 px-4 ml-auto">
        {!isRootUser && (
          <div className="flex items-center gap-2.5">
            <button
              className="flex items-center text-[12px] justify-center space-x-1.5 text-gray-800 py-1.5 px-2.5 border border-gray-300 bg-indigo-100 rounded-md"
              onClick={clonePathway}
            >
              Clone Pathway
            </button>
          </div>
        )}

        <div className="flex flex-wrap justify-end gap-2.5">
          <button
            className="flex items-center justify-center space-x-1.5 text-gray-800 py-1.5 px-2.5 hover:bg-gray-100 rounded-md"
            onClick={exportFlow}
          >
            {isSaving
              ? (
                  <>
                    <PiSpinnerGapLight className=" animate-spin rounded-full w-3 h-3 text-indigo-500" />
                    <span className="font-medium text-xs">Saving...</span>
                  </>
                )
              : (
                  <>
                    <CheckCheckIcon color="#6366f1" size={16} />
                    <span className="font-medium text-xs">Saved</span>
                  </>
                )}
          </button>

          {!isWindowTooSmall && (
            <>
              <Tooltip content="Variables">
                <button
                  onClick={() => setIsOpen(!isOpen)}
                  className="lg:flex hidden items-center justify-center space-x-0 text-gray-800 py-1.5 px-1.5 hover:bg-gray-100 rounded-md"
                  style={{
                    marginRight: -5,
                    backgroundColor: isOpen ? '#6366f1' : '',
                  }}
                >
                  <CurlyBraces
                    color={isOpen ? '#fff' : '#6366f1'}
                    size={16}
                  />
                </button>
              </Tooltip>

              <Tooltip content="Test Cases">
                <button
                  onClick={() => {
                    setShowCallLogsModal(true)
                    setCallLogsActiveTab('simulations')
                  }}
                  className="flex items-center justify-center space-x-0 text-indigo-800 py-1.5 px-1.5 hover:bg-indigo-100 rounded-md"
                >
                  <BeakerIcon color="#6366f1" size={16} />
                </button>
              </Tooltip>

              <DropdownMenu.Root>
                <DropdownMenu.Trigger asChild>
                  <button className="flex items-center justify-center space-x-0 text-gray-800 py-1.5 px-1.5 hover:bg-gray-100 rounded-md">
                    <MoreVertical size={16} color="#6366f1" />
                  </button>
                </DropdownMenu.Trigger>
                <DropdownMenu.Content
                  align="end"
                  className="w-44 text-indigo-800"
                >
                  {!embedMode && (
                    <DropdownMenu.Item
                      onClick={() => setShowShareModal(true)}
                    >
                      <LuShare2
                        className="mr-1.5 h-2.5 w-2.5"
                        color="#6366f1"
                      />
                      Share Pathway
                    </DropdownMenu.Item>
                  )}
                  <DropdownMenu.Item
                    onClick={() => {
                      if (showPathwayLogs) {
                        setCollapsePathwayLogs(true)
                      }
                      setShowCallLogsModal(true)
                      setCallLogsActiveTab('calls')
                    }}
                  >
                    <Grid className="mr-1.5 h-2.5 w-2.5" color="#6366f1" />
                    Call Logs
                  </DropdownMenu.Item>
                  <DropdownMenu.Item onClick={processDownload}>
                    <Download
                      className="mr-1.5 h-2.5 w-2.5"
                      color="#6366f1"
                    />
                    Export Pathway
                  </DropdownMenu.Item>
                </DropdownMenu.Content>
              </DropdownMenu.Root>
            </>
          )}
        </div>

        {selectedVersion?.is_staging && (
          <button
            type="button"
            className={`flex items-center text-[12px] justify-center space-x-1.5 text-gray-800 py-1.5 px-2.5 border border-gray-300 rounded-md ${
              isSaving ? 'bg-gray-100 cursor-not-allowed opacity-70' : 'bg-indigo-100 cursor-pointer'
            }`}
            onClick={() => !isSaving && setShowPublishConfirm(true)}
            disabled={isSaving}
          >
            {isSaving
              ? (
                  <>
                    <PiSpinnerGapLight className="animate-spin w-3 h-3 mr-1.5" />
                    <span>Promote to Production</span>
                  </>
                )
              : (
                  'Promote to Production'
                )}
          </button>
        )}

        {children}
      </div>
    </header>
  )
}

export default PathwayHeader 