import { Button, Pagination, Progress, ScrollShadow, Select, SelectItem, cn } from '@heroui/react'
import { flexRender, getCoreRowModel, getExpandedRowModel, useReactTable } from '@tanstack/react-table'
import { Link as RouterLink } from 'react-router-dom'
import { ArrowDown, ArrowUp, MessageSquare, Settings } from 'lucide-react'
import { getConversationsColumns } from '../utils/tableColumns'

/**
 * A component that displays the SMS conversations table
 * 
 * @param {Object} props - Component props
 * @param {Array} props.data - Array of conversation data
 * @param {Object} props.pagination - Pagination information
 * @param {boolean} props.isLoading - Whether data is loading
 * @param {boolean} props.isFetching - Whether data is being fetched
 * @param {Function} props.handlePaginationChange - Function to handle pagination changes
 * @param {Function} props.handleSortChange - Function to handle sort changes
 * @param {string} props.sortBy - Field to sort by
 * @param {string} props.sortDir - Sort direction
 * @param {number} props.pageSize - Number of items per page
 * @param {Function} props.onPageSizeChange - Function to handle page size changes
 * @param {Function} props.toggleRowClick - Function to toggle row selection
 * @param {string} props.selectedId - Currently selected conversation ID
 * @param {Function} props.handleDeleteConversation - Function to handle conversation deletion
 */
function ConversationsTable({
  data,
  pagination,
  isLoading,
  isFetching,
  handlePaginationChange,
  handleSortChange,
  sortBy,
  sortDir,
  pageSize,
  onPageSizeChange,
  toggleRowClick,
  selectedId,
  handleDeleteConversation,
}) {
  // Setup TanStack Table for conversations
  const columns = getConversationsColumns({ toggleRowClick, handleDeleteConversation })
  
  const conversationsTable = useReactTable({
    data: data || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      pagination: {
        pageIndex: pagination?.currentPage - 1 || 0,
        pageSize,
      },
      sorting: [{ id: sortBy, desc: sortDir === 'desc' }],
    },
    getExpandedRowModel: getExpandedRowModel(),
    columnResizeMode: 'onChange',
    manualPagination: true,
    pageCount: pagination?.totalPages || 0,
    onPaginationChange: handlePaginationChange,
    manualSorting: true,
    sortDescFirst: true,
    onSortingChange: handleSortChange,
  })

  if (isLoading) {
    return null // Loading state is handled by the parent component
  }

  if (!data || data.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center h-full gap-4 p-8 text-center">
        <div className="bg-gray-100 p-6 rounded-full">
          <MessageSquare size={40} className="text-gray-400" />
        </div>
        <h3 className="text-xl font-medium text-gray-800">No SMS Conversations Found</h3>
        <p className="text-gray-500 max-w-md">
          You don't have any SMS conversations yet. Configure a phone number with SMS capabilities to get started.
        </p>
        <Button 
          as={RouterLink} 
          to="/dashboard/phone-numbers" 
          color="secondary" 
          className="mt-2"
          startContent={<Settings size={16} />}
        >
          Configure SMS for Phone Numbers
        </Button>
      </div>
    )
  }

  return (
    <>
      <div className="h-full flex flex-col max-h-full overflow-y-scroll">
        {/* Conversations Table */}
        <ScrollShadow className="w-full px-4 bg-gray-50 h-full mb-[54px]" visibility="bottom" size={20}>
          <table className="divide-y divide-gray-200 border-b w-full">
            <thead className="sticky group top-0 bg-gray-50 z-20 outline outline-2 outline-gray-50 bg-opacity-90 after:content-[''] after:absolute after:left-0 after:right-0 after:bottom-0 after:h-[1px] after:bg-gray-200">
              <tr>
                {conversationsTable.getHeaderGroups().map(headerGroup =>
                  headerGroup.headers.map(header => (
                    <th
                      key={header.id}
                      className="h-8 text-center text-2xs relative font-medium font-taurus text-gray-500 uppercase tracking-wider whitespace-nowrap flex-nowrap"
                      colSpan={header.colSpan}
                      style={{ width: `${header.getSize()}px` }}
                    >
                      {header.isPlaceholder
                        ? null
                        : (
                            <div
                              {...{
                                className: header.column.getCanSort()
                                  ? 'cursor-pointer select-none flex items-center justify-center gap-2'
                                  : '',
                                onClick: header.column.getToggleSortingHandler(),
                              }}
                            >
                              {flexRender(header.column.columnDef.header, header.getContext())}
                              {{
                                asc: <ArrowDown size={12} />,
                                desc: <ArrowUp size={12} />,
                              }[header.column.getIsSorted()] ?? null}
                            </div>
                          )}
                      {header.column.getCanResize() && (
                        <div
                          {...{
                            onMouseDown: header.getResizeHandler(),
                            onTouchStart: header.getResizeHandler(),
                            className: cn(
                              'absolute bottom-0 h-full my-0.5 w-[2px] cursor-col-resize select-none touch-none group-hover:opacity-80 transition-opacity',
                              conversationsTable.options.columnResizeDirection,
                              header.column.getIsResizing() ? 'isResizing' : '',
                              conversationsTable.options.columnResizeDirection === 'ltr' ? 'right-0' : 'left-0',
                              header.column.getIsResizing()
                                ? 'bg-primary-500 opacity-100'
                                : 'bg-black/20 opacity-0',
                              conversationsTable.options.columnResizeMode === 'onEnd'
                              && header.column.getIsResizing()
                                ? `translate-x-[${
                                  (conversationsTable.options.columnResizeDirection === 'rtl' ? -1 : 1)
                                  * (conversationsTable.getState().columnSizingInfo.deltaOffset ?? 0)
                                }px]`
                                : '',
                            ),
                          }}
                        />
                      )}
                    </th>
                  )),
                )}
              </tr>
            </thead>
            <tbody
              className={cn('bg-white border-x divide-y divide-gray-200', {
                'animate-pulse': isFetching,
              })}
            >
              {conversationsTable.getRowModel().rows.map(row => (
                <tr
                  key={row.id}
                  className={cn(
                    'divide-x-1 divide-gray-50 text-center hover:bg-gray-50 transition-colors duration-100 cursor-pointer',
                    {
                      'bg-gray-100': row.original.id === selectedId,
                    },
                  )}
                  onClick={() => toggleRowClick(row.original.id)}
                >
                  {row.getVisibleCells().map(cell => (
                    <td key={cell.id} className="h-11 whitespace-nowrap text-tiny text-gray-500">
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </ScrollShadow>
      </div>

      {/* Footer with pagination */}
      <div className="flex w-full justify-between items-center gap-8">
        {isFetching && (
          <Progress
            isIndeterminate
            aria-label="Loading..."
            className="absolute top-0 left-0"
            classNames={{ base: 'h-0.5', indicator: '!duration-1000' }}
            size="sm"
          />
        )}
        <Select
          label="Page Size"
          size="sm"
          variant="underlined"
          radius="sm"
          labelPlacement="outside-left"
          className="max-w-36"
          selectedKeys={[pageSize.toString()]}
          onChange={e => onPageSizeChange(parseInt(e.target.value, 10))}
        >
          <SelectItem key={10}>10</SelectItem>
          <SelectItem key={25}>25</SelectItem>
          <SelectItem key={50}>50</SelectItem>
          <SelectItem key={100}>100</SelectItem>
        </Select>
        <Pagination
          color="primary"
          className="shrink-0 w-fit"
          size="sm"
          page={pagination?.currentPage || 1}
          radius="none"
          showControls
          total={pagination?.totalPages || 1}
          onChange={page => handlePaginationChange({ pageIndex: page - 1, pageSize })}
        />
      </div>
    </>
  )
}

export default ConversationsTable 