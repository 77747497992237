import { Button, addToast, cn } from '@heroui/react';
import { MessageSquare, ArrowRight } from 'lucide-react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


export default function SMSConfigSavedToast({ 
  configJustSaved = false, 
  onTestSMS, 
  toastDuration = 8000 
}) {
  const navigate = useNavigate();

  useEffect(() => {
    if (configJustSaved) {
      addToast({
        title: "SMS Configuration Saved",
        description: "Your SMS configuration has been updated successfully. If this is the first time you've configured SMS on this number, it might take a few minutes to activate before you can test!",
        icon: <MessageSquare className="text-gray-700 dark:text-gray-300" />,
        classNames: {
          base: cn([
            "bg-white dark:bg-gray-900 shadow-sm",
            "border-1",
            "relative before:content-[''] before:absolute before:z-10",
            "before:left-0 before:top-[-1px] before:bottom-[-1px] before:w-1",
            "rounded-l-none border-l-0",
            "min-w-[350px]",
            "rounded-md",
            "flex flex-col items-start",
            "before:bg-gray-800 dark:before:bg-gray-300 border-gray-200 dark:border-gray-700",
          ]),
          icon: "w-6 h-6 fill-current text-gray-700 dark:text-gray-300",
        },
        endContent: (
          <div className="ms-11 my-2 flex gap-x-2">
            <Button 
              color="default" 
              size="sm" 
              variant="bordered"
              onPress={onTestSMS}
            >
              Test SMS Now
            </Button>
            <Button 
              className="underline-offset-2" 
              color="default" 
              size="sm" 
              variant="light"
              onPress={() => navigate('/dashboard/sms')}
              endContent={<ArrowRight size={14} />}
            >
              SMS Dashboard
            </Button>
          </div>
        ),
        color: "default",
        duration: toastDuration,
        placement: "top-center",
      });
    }
  }, [configJustSaved, onTestSMS, toastDuration, navigate]);

  return null; // This component doesn't render anything directly
} 