import { cn } from '@/lib/utils'

import * as React from 'react'

const Input = React.forwardRef(({ className, type, ...props }, ref) => {
  return (
    (
      <input
        type={type}
        className={cn(
          'flex h-6 w-full rounded border border-input bg-background px-2 py-1.5 text-xs ring-offset-background file:border-0 file:bg-transparent file:text-xs file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
          className,
        )}
        ref={ref}
        {...props}
      />
    )
  )
})
Input.displayName = 'Input'

export { Input }
