import { Button, Card, CardBody } from '@heroui/react'
import { Icon } from '@iconify/react'
import { format } from 'date-fns'
import { addToast } from '@heroui/react'

// interface CallItemProps {
//   callId: string;
//   date?: string;
//   onViewCall: (callId: string) => void;
// }

export function CallItem({ callId, onViewCall, createdAt, onDeleteCall }) {
  const formattedDate = createdAt ? format(new Date(createdAt), 'PPpp') : 'Date not found'

  const handleCopyCallId = (e) => {
    navigator.clipboard.writeText(callId)
    addToast({
      title: 'Call ID copied',
      description: 'Call ID copied successfully',
    })
  }

  const handleDeleteCall = (e) => {
    onDeleteCall(callId)
  }

  return (
    <Card className="mb-3 border shadow-sm hover:shadow-md transition-shadow">
      <CardBody className="p-4">
        <div className="flex flex-col gap-2">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <div className="text-sm">{callId}</div>
              <Button
                isIconOnly
                size="sm"
                variant="light"
                title="Copy Call ID"
                onPress={handleCopyCallId}
                className="ml-1"
              >
                <Icon icon="lucide:copy" size={16} className="text-default-600" />
              </Button>
            </div>
            <div className="flex items-center gap-2">
              <Button
                size="sm"
                color="primary"
                variant="flat"
                startContent={<Icon icon="lucide:external-link" size={16} />}
                onPress={() => onViewCall(callId)}
              >
                View Call
              </Button>
              <Button
                isIconOnly
                size="sm"
                variant="light"
                color="danger"
                title="Delete Call"
                onPress={handleDeleteCall}
              >
                <Icon icon="lucide:trash" size={16} />
              </Button>
            </div>
          </div>
          <div className="text-sm text-default-500">{formattedDate}</div>
        </div>
      </CardBody>
    </Card>
  )
}
