import { Switch } from '@heroui/react'
import { IconButton } from '@radix-ui/themes'
import { Pause, Play, RotateCcw, RotateCw } from 'lucide-react'
import { useEffect, useRef, useState } from 'react'
import { useLocalStorage } from 'usehooks-ts'
import { downloadRecording } from 'utils/funcs/call-logs/handleRecording'
import WaveSurfer from 'wavesurfer.js'

function AudioPlayer({
  recordingUrl,
  audioBuffer = null,
  metrics,
  callLength,
  keyMarkers = [],
  call,
  onMarkerClick,
}) {
  const [autoplay, setAutoplay] = useLocalStorage('autoplay', false)
  const [isPlaying, setIsPlaying] = useState(autoplay)
  const [isLoading, setIsLoading] = useState(false)
  const [currentTime, setCurrentTime] = useState(0)
  const [duration, setDuration] = useState(0)
  const [playbackSpeed, setPlaybackSpeed] = useState(1)
  const waveformRef = useRef(null)
  const waveformId = useRef(`waveform-${Math.random().toString(36).substr(2, 9)}`)

  const startedAt = call?.started_at // the time the call started

  const speedOptions = [
    { label: '1x', value: 1 },
    { label: '1.5x', value: 1.5 },
    { label: '2x', value: 2 },
  ]

  const calculateAirTimePercentages = () => {
    if (!metrics)
      return { user: 0, agent: 0 }
    const total = metrics.audio_user_length + metrics.audio_agent_length
    return {
      user: ((metrics.audio_user_length / total) * 100).toFixed(1),
      agent: ((metrics.audio_agent_length / total) * 100).toFixed(1),
    }
  }

  const interpolateInterruptions = (interruptions) => {
    if (!interruptions?.length)
      return []

    const BUFFER_MS = 50
    const sorted = [...interruptions].sort((a, b) => a.startTime - b.startTime)
    const merged = []
    let current = { ...sorted[0] }

    for (let i = 1; i < sorted.length; i++) {
      const next = sorted[i]
      if (next.startTime - (current.startTime + current.durationSeconds) <= BUFFER_MS / 1000) {
        current.durationSeconds = next.startTime + next.durationSeconds - current.startTime
      }
      else {
        merged.push(current)
        current = { ...next }
      }
    }
    merged.push(current)
    return merged
  }

  const getMarkerColor = (marker) => {
    // Use the marker's color property for pathway tags if available
    if (marker.type === 'pathway_tag' && marker.details?.color) {
      return marker.details.color
    }

    // Default colors for different marker types
    switch (marker.type) {
      case 'variable_extraction':
        return '#8b5cf6' // green-400
      case 'pathway_tag':
        return '#fbbf24' // yellow-400
      case 'decision':
        return '#8b5cf6' // purple-500
      default:
        return '#8b5cf6' // purple-500 - default color
    }
  }

  const drawRoundedRect = (ctx, x, y, width, height, radius) => {
    ctx.beginPath()
    ctx.moveTo(x + radius, y)
    ctx.lineTo(x + width - radius, y)
    ctx.quadraticCurveTo(x + width, y, x + width, y + radius)
    ctx.lineTo(x + width, y + height - radius)
    ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height)
    ctx.lineTo(x + radius, y + height)
    ctx.quadraticCurveTo(x, y + height, x, y + height - radius)
    ctx.lineTo(x, y + radius)
    ctx.quadraticCurveTo(x, y, x + radius, y)
    ctx.closePath()
    ctx.fill()
  }

  const calculateTimestampFromDate = (markerDate) => {
    if (!markerDate)
      return 0

    if (typeof markerDate === 'number')
      return markerDate

    // We don't have access to call.started_at here, so we need to handle timestamps differently
    // If the marker timestamp is a Date object, we'll use it directly
    // This assumes the timestamps in keyMarkers are already relative to the audio start
    return markerDate
  }

  const getSmoothedActivity = (channel, width, scale) => {
    const activityThreshold = 0.1
    const smoothingWindow = 20
    const minSegmentLength = 5
    const activity = []
    let isActive = false
    let activityStart = 0
    let inactiveCount = 0

    for (let x = 0; x < width; x++) {
    //   const index = Math.floor(x * scale)
      let hasActivity = false

      for (let j = 0; j < smoothingWindow && x + j < width; j++) {
        const futureIndex = Math.floor((x + j) * scale)
        if (Math.abs(channel[futureIndex] || 0) > activityThreshold) {
          hasActivity = true
          break
        }
      }

      if (hasActivity && !isActive) {
        isActive = true
        activityStart = x
        inactiveCount = 0
      }
      else if (!hasActivity && isActive) {
        inactiveCount++
        if (inactiveCount > smoothingWindow) {
          isActive = false
          if (x - activityStart > minSegmentLength) {
            activity.push({ start: activityStart, end: x })
          }
        }
      }
    }

    if (isActive && width - activityStart > minSegmentLength) {
      activity.push({ start: activityStart, end: width })
    }

    return activity
  }

  const renderFunction = (channels, ctx) => {
    const { width, height } = ctx.canvas
    const scale = channels[0].length / width
    const mainWaveformHeight = height * 0.72
    const activityBarHeight = height * 0.08
    const padding = height * 0.05
    const barWidth = 4
    const barGap = 4
    const totalBarWidth = barWidth + barGap
    const borderRadius = activityBarHeight / 2

    ctx.clearRect(0, 0, width, height)

    // Main waveform
    ctx.fillStyle = '#6D64EF'
    for (let x = 0; x < width; x += totalBarWidth) {
      const index = Math.floor(x * scale)
      const value = Math.max(
        Math.abs(channels[0][index] || 0),
        Math.abs(channels[1][index] || 0),
      )

      const barHeight = (value * mainWaveformHeight) / 2
      ctx.globalAlpha = 0.7
      ctx.fillRect(
        x,
        mainWaveformHeight / 2 - barHeight,
        barWidth,
        barHeight * 2,
      )
    }

    ctx.globalAlpha = 1.0

    // Activity visualization
    const speaker1Y = mainWaveformHeight + padding
    const speaker2Y = speaker1Y + activityBarHeight + padding

    // User activity
    ctx.fillStyle = 'rgba(74, 222, 128, 0.1)'
    drawRoundedRect(ctx, 0, speaker1Y, width, activityBarHeight, borderRadius)

    ctx.fillStyle = 'rgba(74, 222, 128, 0.4)'
    const speaker1Activity = getSmoothedActivity(channels[0], width, scale)
    speaker1Activity.forEach(({ start, end }) => {
      drawRoundedRect(ctx, start, speaker1Y, end - start, activityBarHeight, borderRadius)
    })

    // Agent activity
    ctx.fillStyle = 'rgba(109, 100, 239, 0.1)'
    drawRoundedRect(ctx, 0, speaker2Y, width, activityBarHeight, borderRadius)

    ctx.fillStyle = 'rgba(109, 100, 239, 0.4)'
    const speaker2Activity = getSmoothedActivity(channels[1], width, scale)
    speaker2Activity.forEach(({ start, end }) => {
      drawRoundedRect(ctx, start, speaker2Y, end - start, activityBarHeight, borderRadius)
    })

    // Interruptions visualization
    if (metrics?.interruptions) {
      const interpolatedInterruptions = interpolateInterruptions(metrics.interruptions)
      ctx.fillStyle = 'rgba(239, 68, 68, 0.1)'
      const totalDurationSeconds = callLength * 60

      interpolatedInterruptions.forEach(({ startTime, durationSeconds }) => {
        const startX = (startTime / totalDurationSeconds) * width
        const windowWidth = (durationSeconds / totalDurationSeconds) * width
        ctx.fillRect(startX, 0, windowWidth, mainWaveformHeight)
      })
    }

    // Draw key markers
    if (keyMarkers?.length > 0 && duration > 0) {
      keyMarkers.forEach((marker) => {
        const timestamp = calculateTimestampFromDate(marker.timestamp)
        const markerX = (timestamp / duration) * width

        // Get color based on marker type
        const markerColor = getMarkerColor(marker)

        // Draw marker line
        ctx.fillStyle = `${markerColor}66` // Add opacity
        ctx.fillRect(markerX, 0, 2, mainWaveformHeight)

        // Draw marker dot
        ctx.beginPath()
        ctx.arc(markerX + 1, mainWaveformHeight - 5, 5, 0, 2 * Math.PI)
        ctx.fillStyle = markerColor
        ctx.fill()
      })
    }
  }

  const initializeWaveform = () => {
    if (waveformRef.current) {
      try {
        waveformRef.current.destroy()
      }
      catch (e) {
        console.warn('Wavesurfer cleanup warning:', e)
      }
      waveformRef.current = null
    }

    try {
      const wavesurfer = WaveSurfer.create({
        container: `#${waveformId.current}`,
        height: 100,
        minPxPerSec: 0,
        cursorWidth: 2,
        cursorColor: '#6D64EF44',
        waveColor: '#6D64EF22',
        progressColor: '#6D64EF44',
        dragToSeek: true,
        responsive: true,
        interact: true,
        fillParent: true,
        renderFunction,
      })

      waveformRef.current = wavesurfer

      if (audioBuffer) {
        wavesurfer.loadBlob(audioBuffer)
      }
      else {
        wavesurfer.load(recordingUrl)
      }

      wavesurfer.setPlaybackRate(playbackSpeed)

      wavesurfer.on('ready', () => {
        setDuration(wavesurfer.getDuration())
        setIsLoading(false)
        if (autoplay)
          wavesurfer.play()
      })

      wavesurfer.on('audioprocess', () => {
        setCurrentTime(wavesurfer.getCurrentTime())
      })

      wavesurfer.on('seek', (progress) => {
        setCurrentTime(progress * wavesurfer.getDuration())
      })

      wavesurfer.on('play', () => setIsPlaying(true))
      wavesurfer.on('pause', () => setIsPlaying(false))
    }
    catch (error) {
      console.error('Wavesurfer initialization error:', error)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    setIsLoading(true)
    initializeWaveform()

    return () => {
      if (waveformRef.current) {
        try {
          waveformRef.current.destroy()
        }
        catch (e) {
          console.warn('Wavesurfer cleanup warning:', e)
        }
        waveformRef.current = null
      }
    }
  }, [recordingUrl])

  const handleSpeedChange = (speed) => {
    setPlaybackSpeed(speed)
    if (waveformRef.current) {
      waveformRef.current.setPlaybackRate(speed)
    }
  }

  const togglePlayPause = () => {
    if (waveformRef.current) {
      if (isPlaying) {
        waveformRef.current.pause()
      }
      else {
        waveformRef.current.play()
      }
      setIsPlaying(!isPlaying)
    }
  }

  const handleSeek = (offsetSeconds) => {
    if (waveformRef.current) {
      const currentTime = waveformRef.current.getCurrentTime()
      const newTime = Math.max(0, Math.min(currentTime + offsetSeconds, duration))
      waveformRef.current.seekTo(newTime / duration)
    }
  }

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60)
    const seconds = Math.floor(time % 60)
    return `${minutes}:${seconds.toString().padStart(2, '0')}`
  }

  const handleDownload = async () => {
    await downloadRecording(call.c_id)
  }

  useEffect(() => {
    const handleKeyDown = (event) => {
      const activeElement = document.activeElement
      const isEditableElement = activeElement.tagName === 'INPUT'
        || activeElement.tagName === 'TEXTAREA'
        || activeElement.contentEditable === 'true'
        || activeElement.closest('[contenteditable="true"]')
        || activeElement.closest('.ignore-audio-player')

      if (isEditableElement)
        return

      if (event.code === 'Space') {
        event.preventDefault()
        togglePlayPause()
      }
      else if (event.code === 'ArrowRight') {
        event.preventDefault()
        handleSeek(1)
      }
      else if (event.code === 'ArrowLeft') {
        event.preventDefault()
        handleSeek(-1)
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    return () => window.removeEventListener('keydown', handleKeyDown)
  }, [duration, togglePlayPause])

  const [hoveredMarker, setHoveredMarker] = useState(null)

  const handleMarkerHover = (marker, isEntering) => {
    if (isEntering) {
      setHoveredMarker(marker)
    }
    else {
      setHoveredMarker(null)
    }
  }

  const handleMarkerClick = (marker) => {
    if (waveformRef.current && duration > 0) {
      const timestamp = calculateTimestampFromDate(marker.timestamp)
      waveformRef.current.seekTo(timestamp / duration)
      setCurrentTime(timestamp)
    }

    if (onMarkerClick) {
      onMarkerClick(marker)
    }
  }

  const renderMarkerIndicators = () => {
    if (!keyMarkers?.length || !duration)
      return null

    return (
      <div className="absolute top-0 left-0 right-0 h-full pointer-events-none">
        {keyMarkers.map((marker, index) => {
          const position = (calculateTimestampFromDate(marker.timestamp) / duration) * 100
          const markerColor = getMarkerColor(marker)

          if (position < 0 || position > 100)
            return null

          return (
            <div
              key={index}
              className="absolute top-0 h-full pointer-events-auto z-10"
              style={{ left: `${position}%` }}
              onMouseEnter={() => handleMarkerHover(marker, true)}
              onMouseLeave={() => handleMarkerHover(marker, false)}
            >
              <div
                className="w-4 h-4 -ml-2 mt-1 rounded-full ring-2 cursor-pointer hover:scale-125 transition-transform duration-200"
                style={{
                  backgroundColor: markerColor,
                  ringColor: `${markerColor}33`, // Lighter version of the color
                }}
                onClick={() => handleMarkerClick(marker)}
              />
            </div>
          )
        })}
      </div>
    )
  }

  useEffect(() => {
    const handleSeekEvent = (event) => {
      const { timestamp } = event.detail
      if (waveformRef.current && duration > 0 && startedAt) {
        // Convert the absolute timestamp to a relative position in the audio
        let seekTime = 0

        if (timestamp instanceof Date || typeof timestamp === 'string') {
          // Calculate seconds since the call started
          const timestampDate = new Date(timestamp)
          const startDate = new Date(startedAt)

          seekTime = (timestampDate - startDate) / 1000 // Convert ms to seconds
        }
        else if (typeof timestamp === 'number') {
          // If it's already a number, use it directly (could be a relative timestamp)
          seekTime = timestamp
        }

        // Offset the seek time by 2 seconds
        // seekTime -= 3

        // Ensure timestamp is within bounds
        const boundedTime = Math.min(Math.max(0, seekTime), duration)
        waveformRef.current.seekTo(boundedTime / duration)
        setCurrentTime(boundedTime)
      }
    }

    const container = document.querySelector('.audio-player-container')
    if (container) {
      container.addEventListener('seek-to-timestamp', handleSeekEvent)
    }

    return () => {
      if (container) {
        container.removeEventListener('seek-to-timestamp', handleSeekEvent)
      }
    }
  }, [duration, startedAt])

  return (
    <div className="flex flex-col justify-around w-full h-full p-4 max-w-full divide-y bg-white/80 backdrop-blur-sm divide-gray-200/30  audio-player-container">
      <div className="flex h-fit items-end gap-4" tabIndex="0">
        <div className="flex flex-col justify-between">
          <div className="flex items-center gap-2 mb-6">
            <IconButton
              onClick={() => handleSeek(-10)}
              disabled={isLoading}
              className="hover:bg-violet-50 transition-colors"
              style={{
                background: 'rgba(109, 100, 239, 0.1)',
                border: '1px solid rgba(109, 100, 239, 0.2)',
                borderRadius: '50%',
                cursor: 'pointer',
              }}
            >
              <RotateCcw size={12} className="text-violet-600" />
            </IconButton>

            <IconButton
              onClick={togglePlayPause}
              disabled={isLoading}
              className="hover:bg-violet-50 transition-colors"
              style={{
                background: 'rgba(109, 100, 239, 0.1)',
                border: '1px solid rgba(109, 100, 239, 0.2)',
                borderRadius: '50%',
                padding: '2px',
                cursor: 'pointer',
              }}
            >
              {isLoading
                ? (
                    <RotateCw size={15} className="text-violet-600 animate-spin" />
                  )
                : isPlaying
                  ? (
                      <Pause size={15} className="text-violet-600" />
                    )
                  : (
                      <Play size={15} className="text-violet-600" />
                    )}
            </IconButton>

            <IconButton
              onClick={() => handleSeek(10)}
              disabled={isLoading}
              className="hover:bg-violet-50 transition-colors"
              style={{
                background: 'rgba(109, 100, 239, 0.1)',
                border: '1px solid rgba(109, 100, 239, 0.2)',
                borderRadius: '50%',
                cursor: 'pointer',
              }}
            >
              <RotateCw size={12} className="text-violet-600" />
            </IconButton>

          </div>
          <div className="flex items-center gap-2 w-full -mt-4 mb-2">

            {/* Playback speed controls */}
            <div className="flex mx-auto">
              {speedOptions.map(option => (
                <button
                  key={option.value}
                  onClick={() => handleSpeedChange(option.value)}
                  className={`px-2 py-1 text-2xs rounded-full transition-colors ${
                    playbackSpeed === option.value
                      ? 'bg-violet-100 text-violet-600'
                      : 'bg-gray-50 text-gray-500 hover:bg-violet-50'
                  }`}
                >
                  {option.label}
                </button>
              ))}
            </div>
          </div>

          <p className="text-2xs leading-tight text-right font-mono text-emerald-500/70">
            Human
          </p>
          <p className="text-2xs leading-tight text-right font-mono text-violet-500/70">
            Assistant
          </p>
        </div>

        <div className="flex-grow relative">
          <div id={waveformId.current} className="rounded-lg overflow-hidden" />
          {renderMarkerIndicators()}

          {/* Marker tooltip - ensure it stays within bounds */}
          {hoveredMarker && (
            <div
              className="absolute z-20 bg-white p-2 rounded-md shadow-lg border border-violet-200 text-xs"
              style={{
                left: `${Math.min(98, Math.max(2, (calculateTimestampFromDate(hoveredMarker.timestamp) / duration) * 100))}%`,
                top: '-40px',
                transform: 'translateX(-50%)',
                pointerEvents: 'none',
              }}
            >
              <div className="font-medium text-violet-700">{hoveredMarker.name}</div>
              <div className="text-violet-500">{formatTime(calculateTimestampFromDate(hoveredMarker.timestamp))}</div>
            </div>
          )}
        </div>

      </div>
      <div className="flex items-center gap-4 text-xs text-gray-400">
        {(metrics?.audio_user_length || metrics?.audio_agent_length) && (
          <>
            <span>
              Agent:
              {calculateAirTimePercentages().agent}
              %
            </span>
            <span>
              User:
              {calculateAirTimePercentages().user}
              %
            </span>
            <span>
              Overlap:
              {metrics?.audio_overlap_seconds?.toFixed(2)}
              s
            </span>
          </>
        )}

        <button
          onClick={handleDownload}
          className="text-violet-500 hover:text-violet-600 transition-colors underline"
        >
          Download
        </button>
        <div className="font-mono text-violet-600/70">
          {formatTime(currentTime)}
          {' '}
          /
          {formatTime(duration)}
        </div>
        <Switch
          size="sm"
          thumbIcon={<Play size={10} stroke="blue" fill="blue" />}
          classNames={{ label: 'text-xs' }}
          className="ml-auto"
          isSelected={autoplay}
          onValueChange={setAutoplay}
        >
          Autoplay Recordings
        </Switch>
      </div>
    </div>
  )
}

export default AudioPlayer
