import { Editor } from '@monaco-editor/react'
import ActionBar from 'components/core/ActionBar'
import Button from 'components/core/Button'
import { useFormContext } from 'react-hook-form'
import styled from 'styled-components'
import { parseForJson } from 'utils/funcs/send-call/parseForJson'
import 'utils/stylesheets/JSONMode.css'

export default function JSONMode({ submitting }) {
  const { watch, reset, formState } = useFormContext()

  const formValues = watch()
  const { dirtyFields } = formState
  const filteredFormState = parseForJson(formValues, dirtyFields)

  // setting new changes to form state in real time
  function handleEditorChange(value) {
    try {
      const newFieldValues = JSON.parse(value)
      const { request_data, ...data } = newFieldValues
      reset({ json_mode_enabled: true, ...data })
    }
    catch (error) {

    }
  }

  // passing curr form state into editor
  const data = `${JSON.stringify(filteredFormState, null, 2)}`

  return (
    <Wrapper>
      <Editor
        height="calc(100vh - 230px)"
        width="100%"
        value={data}
        language="json"
        theme="vs-dark"
        onChange={handleEditorChange}
        className="container"
      />
      <ActionBar bottom>
        <Button submit loading={submitting}>
          Save Changes
        </Button>
      </ActionBar>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #1e1e1e;
  border-radius: 12px;
  width: 100%;
`
