import { Button } from '@/components/ui/button'
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from '@/components/ui/collapsible'
import { Textarea } from '@/components/ui/textarea'
import config from 'config'
import { useAuth } from 'hooks/useAuth'
import { Bot, ChevronDown, ChevronUp, Edit2, MessageSquare, PlusCircle, RefreshCw, RotateCw, Trash2, User, X } from 'lucide-react'
import { useContext, useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import { getApiKey } from 'utils/funcs/browser/getApiKey'
import { getAuthToken } from 'utils/funcs/browser/getAuthToken'
import { getOrgId } from 'utils/funcs/browser/getOrgId'
import MultipleResponseSvg from '../../../../../assets/icons/multiple_response.svg'
import FlowContext from '../../contextFlow'
import MultipleResponsesResults from './MultipleResponsesResult'

function EditableChatMessage({ entry, index, onUpdate, onDelete, isLast }) {
  const [isEditing, setIsEditing] = useState(false)
  const [content, setContent] = useState(entry.content)
  const textareaRef = useRef(null)

  useEffect(() => {
    if (isEditing && textareaRef.current) {
      textareaRef.current.focus()
    }
  }, [isEditing])

  const handleSave = () => {
    onUpdate(index, { ...entry, content })
    setIsEditing(false)
  }

  return (
    <div
      className={`relative group transition-all ${
        entry.role === 'user' ? 'ml-2.5' : 'mr-2.5'
      }`}
    >
      <div
        className={`p-2.5 rounded-md border ${
          entry.role === 'user'
            ? 'bg-gray-50 border-gray-200'
            : 'bg-blue-50 border-blue-200'
        }`}
      >
        <div className="flex items-center gap-1.5 mb-1.5">
          {entry.role === 'user'
            ? (
                <User className="h-2.5 w-2.5 text-gray-600" />
              )
            : (
                <Bot className="h-2.5 w-2.5 text-blue-600" />
              )}
          <span className="text-2xs font-medium text-gray-600">
            {entry.role.toUpperCase()}
          </span>
        </div>

        {isEditing
          ? (
              <div className="space-y-1.5">
                <Textarea
                  ref={textareaRef}
                  value={content}
                  onChange={e => setContent(e.target.value)}
                  className="w-full min-h-[30px] text-xs resize-y"
                  placeholder="Enter message content..."
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' && (e.metaKey || e.ctrlKey)) {
                      handleSave()
                    }
                    if (e.key === 'Escape') {
                      setIsEditing(false)
                      setContent(entry.content)
                    }
                  }}
                />
                <div className="flex justify-end gap-1.5">
                  <Button
                    size="sm"
                    variant="ghost"
                    onClick={() => {
                      setContent(entry.content)
                      setIsEditing(false)
                    }}
                  >
                    Cancel
                  </Button>
                  <Button size="sm" onClick={handleSave}>
                    Save
                  </Button>
                </div>
              </div>
            )
          : (
              <div className="text-xs whitespace-pre-wrap">
                {content}
              </div>
            )}
      </div>

      <div className={`absolute right-1.5 top-1.5 ${isLast || isEditing ? 'opacity-100' : 'opacity-0 group-hover:opacity-100'} transition-opacity flex gap-0.5`}>
        <Button
          size="icon"
          variant="ghost"
          className="h-5 w-5"
          onClick={() => setIsEditing(true)}
        >
          <Edit2 className="h-2.5 w-2.5" />
        </Button>
        <Button
          size="icon"
          variant="ghost"
          className="h-5 w-5 text-red-600 hover:text-red-700 hover:bg-red-50"
          onClick={() => onDelete(index)}
        >
          <Trash2 className="h-2.5 w-2.5" />
        </Button>
      </div>
    </div>
  )
}

function PromptEditSidebar({ isOpen, onClose, decision }) {
  const { user } = useAuth()
  const orgId = getOrgId()
  const [isLoading, setIsLoading] = useState(false)
  const [isGeneratingMultiple, setIsGeneratingMultiple] = useState(false)
  const [currentNodeID, setCurrentNodeID] = useState(
    decision['Current Node ID'] || null,
  )
  const [globalPrompt, setGlobalPrompt] = useState(
    decision?.['Global Prompt'] || '',
  )
  const [nodePrompt, setNodePrompt] = useState(
    decision?.['Current Node Prompt/Text'] || '',
  )
  const [originalNodePrompt, setOriginalNodePrompt] = useState(
    decision?.['Current Node Prompt/Text'] || '',
  )
  const [originalGlobalPrompt, setOriginalGlobalPrompt] = useState(
    decision?.['Global Prompt'] || '',
  )
  const [showUnsavedChangesWarning, setShowUnsavedChangesWarning] = useState(false)
  const [dialogueExample, setDialogueExample] = useState(
    decision?.['Dialogue Example'] || '',
  )
  const [currVariables, setCurrVariables] = useState(
    decision?.['Current Variables'] || {},
  )
  const [generatedResponse, setGeneratedResponse] = useState('')
  const [generatedResponses, setGeneratedResponses] = useState([])
  const [streamProgress, setStreamProgress] = useState({
    current: 0,
    total: 0,
    success: 0,
    failed: 0,
  })
  const [isGlobalPromptOpen, setIsGlobalPromptOpen] = useState(false)
  const [isDialogueExampleOpen, setIsDialogueExampleOpen] = useState(false)
  const [isChatHistoryOpen, setIsChatHistoryOpen] = useState(true)
  const [showMultipleResults, setShowMultipleResults] = useState(false)
  const [generationCount, setGenerationCount] = useState(10)
  const [temperature, setTemperature] = useState(0.2)
  const [isVariablesOpen, setIsVariablesOpen] = useState(false)
  const [chatHistory, setChatHistory] = useState(
    decision?.['Conversation History']?.filter(entry => entry.content !== '<<CALL CONNECTED>>') || [],
  )

  const {
    triggerUpdate,
    elements,
    setChatConversationHistory,
    setChatStartNode,
    setGlobalPrompt: setGlobalPromptContext,
    selectedVersion,
    callId,
  } = useContext(FlowContext)

  const hasUnsavedChanges = () => {
    return nodePrompt !== originalNodePrompt || globalPrompt !== originalGlobalPrompt;
  }

  const handleCloseWithConfirmation = () => {
    if (hasUnsavedChanges()) {
      setShowUnsavedChangesWarning(true);
    } else {
      onClose();
    }
  }

  const handleCloseWithoutSaving = () => {
    setShowUnsavedChangesWarning(false);
    onClose();
  }

  useEffect(() => {
    if (isOpen && decision) {
      setCurrentNodeID(decision['Current Node ID'] || null)

      if (decision['Current Node ID']) {
        const node = elements.nodes.find(
          n => n.id === decision['Current Node ID'],
        )
        const nodePromptValue = node.data.prompt || node.data?.text || '';
        setNodePrompt(nodePromptValue);
        setOriginalNodePrompt(nodePromptValue);
      }
      else {
        const nodePromptValue = decision['Current Node Prompt/Text'] || '';
        setNodePrompt(nodePromptValue);
        setOriginalNodePrompt(nodePromptValue);
      }

      const globalPromptValue = decision['Global Prompt'] || '';
      setGlobalPrompt(globalPromptValue);
      setOriginalGlobalPrompt(globalPromptValue);
      
      setDialogueExample(decision['Dialogue Example'] || '')
      setGeneratedResponse('')
      setTemperature(decision['Current Node Temperature'] || 0.2)
      setShowUnsavedChangesWarning(false);
    }
  }, [isOpen, decision])

  const handleUpdate = async (generations = 1) => {
    setIsLoading(true)
    const isMultiple = generations > 1
    setIsGeneratingMultiple(isMultiple)
    setGeneratedResponses([])
    setStreamProgress({
      current: 0,
      total: generations,
      success: 0,
      failed: 0,
    })

    try {
      const is_looping = decision['Is Looping'] || false
      const formattedChatHistory = chatHistory.map(entry => ({
        role: String(entry.role),
        content: String(entry.content),
      }))

      // add <<CALL_CONNECTED>> to the start of the chat history, after the system prompt
      const systemPromptIndex = formattedChatHistory.findIndex(entry => entry.role === 'system')
      if (systemPromptIndex !== -1) {
        formattedChatHistory.splice(systemPromptIndex + 1, 0, {
          role: 'user',
          content: '<<CALL CONNECTED>>',
        })
      }
      else {
        formattedChatHistory.unshift({
          role: 'user',
          content: '<<CALL CONNECTED>>',
        })
      }

      const payload = {
        globalPrompt: String(globalPrompt),
        dialoguePrompt: String(nodePrompt),
        dialogueExamples: dialogueExample ? String(dialogueExample) : null,
        variables: currVariables,
        chatHistory: formattedChatHistory,
        generations: Number(isMultiple ? generationCount : generations),
        is_looping,
        temperature: Number(temperature),
        call_id: callId,
      }

      if (isMultiple) {
        // Handle streaming response for multiple generations
        const response = await fetch(`${config.API_URL}/v1/pathway/respond`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'authorization': getApiKey(),
            ...(orgId && { 'x-bland-org-id': orgId }),
          },
          body: JSON.stringify(payload),
        })

        const reader = response.body.getReader()
        const decoder = new TextDecoder()

        while (true) {
          const { done, value } = await reader.read()
          if (done)
            break

          const chunk = decoder.decode(value)
          // Split chunk into lines and process each SSE message
          const lines = chunk.split('\n')
          for (const line of lines) {
            if (line.trim() === '')
              continue
            // Handle SSE format by removing "data: " prefix
            const jsonString = line.replace(/^data: /, '')
            try {
              const data = JSON.parse(jsonString)
              switch (data.status) {
                case 'progress':
                  setStreamProgress(prev => ({
                    current: data.currentGeneration,
                    total: data.totalGenerations,
                    success: data.successCount,
                    failed: data.failCount,
                  }))
                  if (data.response) {
                    setGeneratedResponses(prev => [...prev, data.response])
                  }
                  break

                case 'complete':
                  setIsLoading(false)
                  setIsGeneratingMultiple(false)
                  setShowMultipleResults(true)
                  break

                case 'error':
                  toast.error(`Generation failed`)
                  break
              }
            }
            catch (e) {
              console.warn('Failed to parse SSE message:', line)
            }
          }
        }
      }
      else {
        // Handle single generation
        const response = await fetch(`${config.API_URL}/v1/pathway/respond`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'authorization': getAuthToken(),
            ...(orgId && { 'x-bland-org-id': orgId }),
          },
          body: JSON.stringify(payload),
        })

        const data = await response.json()

        if (response.ok) {
          setGeneratedResponse(data.response)
          setGeneratedResponses([])
          setShowMultipleResults(false)
        }
        else {
          toast.error(data.message || 'Failed to generate response')
        }
        setIsLoading(false)
        setIsGeneratingMultiple(false)
      }
    }
    catch (error) {
      console.error('Error details:', error)
      toast.error('Failed to generate response')
      setIsLoading(false)
      setIsGeneratingMultiple(false)
    }
  }

  const handleSave = async () => {
    if (selectedVersion?.version_number === 0) {
      toast.error('You cannot edit the prompt on the production version directly.')
      return
    }

    const curr_node_id = decision['Current Node ID']
    const updatedNodes = elements.nodes.map((node) => {
      if (node.id === curr_node_id) {
        const clonedNode = structuredClone(node)
        return {
          ...clonedNode,
          data: {
            ...clonedNode.data,
            prompt: nodePrompt,
          },
        }
      }
      return node
    })

    triggerUpdate({ nodes: updatedNodes }, false)
    toast.success('Node Prompt Updated Successfully')
    setOriginalNodePrompt(nodePrompt);
  }

  const handleSaveGlobalPrompt = async () => {
    setGlobalPromptContext(globalPrompt)
    const updatedNodes = elements.nodes.map((node) => {
      const clonedNode = structuredClone(node)
      clonedNode.data.globalPrompt = globalPrompt
      return clonedNode
    })
    triggerUpdate({ nodes: updatedNodes }, false)
    toast.success('Global Prompt Updated Successfully')
    setOriginalGlobalPrompt(globalPrompt);
  }

  const handleSaveAsExample = () => {
    const currentNodeId = decision['Current Node ID']
    const updatedNodes = elements.nodes.map((node) => {
      if (node.id === currentNodeId) {
        return {
          ...node,
          data: {
            ...node.data,
            dialogueExamples: [
              ...(node.data.dialogueExamples || []),
              {
                'Conversation History': decision['Conversation History'],
                'Assistant Response': generatedResponse,
              },
            ],
          },
        }
      }
      return node
    })
    triggerUpdate({ nodes: updatedNodes }, false)
    toast.success('Example added to node\'s fine-tuning examples.')
  }

  const resetChat = () => {
    if (hasUnsavedChanges()) {
      // First save any unsaved changes
      if (nodePrompt !== originalNodePrompt) handleSave();
      if (globalPrompt !== originalGlobalPrompt) handleSaveGlobalPrompt();
    }
    
    if (decision['Conversation History']) {
      if (
        decision['Conversation History'].length > 0
        && decision['Conversation History'][0]?.content === '<<CALL CONNECTED>>'
      ) {
        const newHistory = decision['Conversation History'].slice(1)
        setChatConversationHistory(newHistory)
      }
      else {
        setChatConversationHistory(decision['Conversation History'])
      }
      setChatStartNode(decision['Current Node ID'])
    }
    onClose()
  }

  const handleAddMessage = () => {
    const lastMessage = chatHistory[chatHistory.length - 1]
    const newRole = lastMessage?.role === 'user' ? 'assistant' : 'user'

    setChatHistory([
      ...chatHistory,
      { role: newRole, content: '' },
    ])
  }

  const handleUpdateMessage = (index, updatedMessage) => {
    const newHistory = [...chatHistory]
    newHistory[index] = updatedMessage
    setChatHistory(newHistory)
  }

  const handleDeleteMessage = (index) => {
    const newHistory = chatHistory.filter((_, i) => i !== index)
    setChatHistory(newHistory)
  }

  if (!isOpen)
    return null

  return (
    <div className="fixed bottom-0 left-[458px] w-[520px] h-[calc(100vh-64px)] bg-white border-l border-gray-200 overflow-y-auto">
      {/* Header */}
      <div className="flex justify-between items-center p-2.5 border-b border-gray-200">
        <h2 className="text-sm font-semibold font-geist-mono">EDIT PROMPT</h2>
        <button onClick={handleCloseWithConfirmation}>
          <X className="h-4 w-4" />
        </button>
      </div>

      {/* Unsaved Changes Warning */}
      {showUnsavedChangesWarning && (
        <div className="absolute inset-0 bg-black/50 flex items-center justify-center z-10">
          <div className="bg-white rounded-md p-5 max-w-md">
            <h3 className="text-lg font-semibold mb-2">Unsaved Changes</h3>
            <p className="text-sm text-gray-600 mb-4">
              You have unsaved changes to your prompts. Do you want to save them before closing?
            </p>
            <div className="flex justify-end gap-2">
              <Button
                variant="outline"
                onClick={handleCloseWithoutSaving}
              >
                Discard Changes
              </Button>
              {nodePrompt !== originalNodePrompt && (
                <Button
                  variant="secondary"
                  onClick={() => {
                    handleSave();
                    if (globalPrompt !== originalGlobalPrompt) {
                      // If global prompt also has changes, just keep the dialog open
                      setShowUnsavedChangesWarning(false);
                    } else {
                      // Otherwise close completely
                      setShowUnsavedChangesWarning(false);
                      onClose();
                    }
                  }}
                >
                  Save Node Prompt
                </Button>
              )}
              {globalPrompt !== originalGlobalPrompt && (
                <Button
                  variant="secondary"
                  onClick={() => {
                    handleSaveGlobalPrompt();
                    if (nodePrompt !== originalNodePrompt) {
                      // If node prompt also has changes, just keep the dialog open
                      setShowUnsavedChangesWarning(false);
                    } else {
                      // Otherwise close completely
                      setShowUnsavedChangesWarning(false);
                      onClose();
                    }
                  }}
                >
                  Save Global Prompt
                </Button>
              )}
              <Button
                onClick={() => {
                  if (nodePrompt !== originalNodePrompt) handleSave();
                  if (globalPrompt !== originalGlobalPrompt) handleSaveGlobalPrompt();
                  setShowUnsavedChangesWarning(false);
                  onClose();
                }}
              >
                Save All Changes
              </Button>
            </div>
          </div>
        </div>
      )}

      {/* Content */}
      <div className="p-4">
        {/* Node Prompt Section */}
        <div className="mb-4">
          <h3 className="text-xs font-medium text-gray-700 mb-1.5 font-geist-mono">
            PROMPT
          </h3>
          <div className="bg-white border border-gray-200 rounded-md">
            <textarea
              value={nodePrompt}
              onChange={e => setNodePrompt(e.target.value)}
              className="w-full p-2 text-xs text-gray-700 min-h-[100px] rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
              placeholder="Enter prompt..."
            />
          </div>
          <div className="flex justify-end mt-2.5">
            <Button
              variant="secondary"
              size="sm"
              onClick={handleSave}
              className="bg-slate-100 hover:bg-slate-200 text-gray-950 font-geist-mono tracking-[0.8px] text-[10px] px-2.5"
            >
              UPDATE NODE PROMPT
            </Button>
          </div>
        </div>

        {/* Chat History Section */}
        <Collapsible
          open={isChatHistoryOpen}
          onOpenChange={setIsChatHistoryOpen}
          className="mb-4"
        >
          <CollapsibleTrigger className="w-full p-2.5 bg-white border border-gray-200 rounded-md flex justify-between items-center text-xs font-medium text-gray-700 font-geist-mono tracking-[0.8px] hover:bg-gray-50">
            <div className="flex items-center gap-1.5">
              <MessageSquare className="h-2.5 w-2.5" />
              CHAT HISTORY
            </div>
            <ChevronDown className={`h-2.5 w-2.5 transition-transform ${isChatHistoryOpen ? 'rotate-180' : ''}`} />
          </CollapsibleTrigger>

          <CollapsibleContent className="mt-1.5">
            <div className="space-y-2.5 p-2.5 bg-gray-50 rounded-md border border-gray-200">
              {chatHistory.length > 0
                ? (
                    <>
                      {chatHistory.map((entry, index) => (
                        <EditableChatMessage
                          key={index}
                          entry={entry}
                          index={index}
                          onUpdate={handleUpdateMessage}
                          onDelete={handleDeleteMessage}
                          isLast={index === chatHistory.length - 1}
                        />
                      ))}
                      <Button
                        onClick={handleAddMessage}
                        variant="outline"
                        className="w-full mt-2.5 border-dashed"
                      >
                        <PlusCircle className="h-2.5 w-2.5 mr-1.5" />
                        Add
                        {' '}
                        {chatHistory[chatHistory.length - 1]?.role === 'user' ? 'Assistant' : 'User'}
                        {' '}
                        Message
                      </Button>
                    </>
                  )
                : (
                    <div className="text-center py-5">
                      <MessageSquare className="h-5 w-5 text-gray-400 mx-auto mb-1.5" />
                      <p className="text-xs text-gray-500 mb-2.5">No messages in chat history</p>
                      <Button onClick={handleAddMessage} variant="outline">
                        <PlusCircle className="h-2.5 w-2.5 mr-1.5" />
                        Add User Message
                      </Button>
                    </div>
                  )}
            </div>
          </CollapsibleContent>
        </Collapsible>

        {/* Response Section */}
        <div className="mb-4">
          <div className="flex items-center justify-between mb-1.5">
            <h3 className="text-xs font-medium text-gray-700 font-geist-mono">
              RESPONSE
            </h3>
          </div>
          <div className="bg-white border border-gray-200 rounded-md p-2.5">
            {generatedResponse
              ? (
                  <div className="text-xs text-gray-700 min-h-[100px] flex flex-col justify-between">
                    <p className="whitespace-pre-wrap">{generatedResponse}</p>
                    <div className="flex justify-end gap-2 mt-5 pt-2.5 border-t border-gray-100">
                      <Button
                        variant="secondary"
                        size="sm"
                        onClick={() => handleUpdate(1)}
                        disabled={isLoading}
                        className="bg-slate-100 hover:bg-slate-200 text-gray-950 font-geist-mono tracking-[0.8px] text-[10px] px-2.5"
                      >
                        {isLoading
                          ? (
                              <RotateCw className="mr-1.5 h-2.5 w-2.5 animate-spin" />
                            )
                          : (
                              'REGENERATE'
                            )}
                      </Button>
                      <Button
                        variant="secondary"
                        size="sm"
                        onClick={handleSaveAsExample}
                        className="bg-slate-100 hover:bg-slate-200 text-gray-950 font-geist-mono tracking-[0.8px] text-[10px] px-2.5"
                      >
                        SAVE AS EXAMPLE
                      </Button>
                      <Button
                        variant="secondary"
                        size="sm"
                        onClick={resetChat}
                        className="bg-black hover:bg-gray-800 text-white font-geist-mono tracking-[0.8px] text-[10px] px-2.5"
                      >
                        RESTART CHAT FROM HERE
                      </Button>
                    </div>
                  </div>
                )
              : (
                  <div className="flex flex-col items-center justify-center min-h-[100px]">
                    <Button
                      variant="secondary"
                      size="sm"
                      onClick={() => handleUpdate(1)}
                      disabled={isLoading}
                      className="bg-slate-100 hover:bg-slate-200 text-gray-950 font-geist-mono tracking-[0.8px] font-semibold text-[10px] px-2.5 mb-1.5"
                    >
                      {isLoading
                        ? (
                            <RefreshCw className="mr-1.5 h-2.5 w-2.5 animate-spin" />
                          )
                        : (
                            'GENERATE'
                          )}
                    </Button>
                  </div>
                )}
          </div>
        </div>

        {/* Additional Options */}
        <div className="space-y-1.5">
          <button
            className="w-full p-2.5 bg-white border border-gray-200 rounded-md flex justify-between items-center text-xs font-medium text-gray-700 font-geist-mono tracking-[0.8px]"
            onClick={() => setIsGlobalPromptOpen(!isGlobalPromptOpen)}
          >
            GLOBAL PROMPT
            {isGlobalPromptOpen
              ? (
                  <ChevronUp className="h-2.5 w-2.5" />
                )
              : (
                  <ChevronDown className="h-2.5 w-2.5" />
                )}
          </button>
          {isGlobalPromptOpen && (
            <div className="space-y-1.5">
              <Textarea
                value={globalPrompt}
                onChange={e => setGlobalPrompt(e.target.value)}
                className="w-full mt-1.5"
                placeholder="No global prompt provided..."
                rows={3}
              />
              <Button
                variant="secondary"
                size="sm"
                onClick={handleSaveGlobalPrompt}
                className="mt-2.5 bg-slate-100 hover:bg-slate-200 text-gray-950 font-geist-mono tracking-[0.8px] text-[10px] px-2.5"
              >
                UPDATE GLOBAL PROMPT
              </Button>
            </div>
          )}

          <button
            className="w-full p-2.5 bg-white border border-gray-200 rounded-md flex justify-between items-center text-xs font-medium text-gray-700 font-geist-mono tracking-[0.8px]"
            onClick={() => setIsDialogueExampleOpen(!isDialogueExampleOpen)}
          >
            FINE-TUNING EXAMPLE
            {isDialogueExampleOpen
              ? (
                  <ChevronUp className="h-2.5 w-2.5" />
                )
              : (
                  <ChevronDown className="h-2.5 w-2.5" />
                )}
          </button>
          {isDialogueExampleOpen && (
            <Textarea
              value={dialogueExample}
              onChange={e => setDialogueExample(e.target.value)}
              className="w-full mt-1.5"
              placeholder="No dialogue example provided"
              rows={3}
            />
          )}

          <button
            className="w-full p-2.5 bg-white border border-gray-200 rounded-md flex justify-between items-center text-xs font-medium text-gray-700 font-geist-mono tracking-[0.8px]"
            onClick={() => setIsVariablesOpen(!isVariablesOpen)}
          >
            VARIABLES
            {isVariablesOpen
              ? (
                  <ChevronUp className="h-2.5 w-2.5" />
                )
              : (
                  <ChevronDown className="h-2.5 w-2.5" />
                )}
          </button>
          {isVariablesOpen && (
            <div className="mt-1.5 space-y-2.5 p-2.5 bg-gray-50 rounded-md">
              {currVariables && Object.keys(currVariables).length > 0
                ? (
                    <div className="space-y-1.5">
                      {Object.entries(currVariables).map(([key, value], index) => (
                        <div
                          key={index}
                          className="flex justify-between items-center p-1.5 bg-white rounded-"
                        >
                          <div className="flex items-center gap-1.5">
                            <input
                              className="text-xs font-medium text-gray-700 border-none bg-transparent focus:outline-none"
                              value={key}
                              onChange={(e) => {
                                const newVariables = { ...currVariables }
                                const oldValue = newVariables[key]
                                delete newVariables[key]
                                newVariables[e.target.value] = oldValue
                                setCurrVariables(newVariables)
                              }}
                            />
                          </div>
                          <div className="flex items-center gap-1.5">
                            <input
                              className="text-xs text-gray-500 border-none bg-transparent focus:outline-none text-right"
                              value={value}
                              onChange={(e) => {
                                const newVariables = { ...currVariables }
                                newVariables[key] = e.target.value
                                setCurrVariables(newVariables)
                              }}
                            />
                            <Button
                              variant="ghost"
                              size="sm"
                              onClick={() => {
                                const newVariables = { ...currVariables }
                                delete newVariables[key]
                                setCurrVariables(newVariables)
                              }}
                              className="text-gray-400 hover:text-red-500"
                            >
                              <X className="h-2.5 w-2.5" />
                            </Button>
                          </div>
                        </div>
                      ))}
                    </div>
                  )
                : (
                    <p className="text-xs text-gray-500 text-center">
                      No variables defined
                    </p>
                  )}
              <Button
                variant="outline"
                size="sm"
                onClick={() => {
                  const newVariables = { ...currVariables }
                  newVariables[
                    `variable_${Object.keys(currVariables || {}).length + 1}`
                  ] = ''
                  setCurrVariables(newVariables)
                }}
                className="w-full mt-1.5 font-geist-mono tracking-[0.8px] text-[10px] font-semibold px-2.5 py-1.5 border-gray-300 border"
              >
                ADD VARIABLE
              </Button>
            </div>
          )}
        </div>

        <div className="mt-5 pt-5 border-t border-gray-200">
          {!showMultipleResults ? (
            <div className="flex flex-col items-center gap-2.5">
              <img src={MultipleResponseSvg} alt="Multiple Response" />
              <p className="text-xs text-gray-600 w-[250px] text-center">
                Generate multiple responses to see the variety of replies your
                agent will generate for future calls.
              </p>

              {/* Generation Count Selector */}
              <div className="flex justify-between items-center gap-2.5">
                <div className="text-2xs text-gray-500 font-geist-mono">
                  NUMBER OF RESPONSES
                </div>
                <div className="flex gap-1.5">
                  {[10, 30, 50].map(count => (
                    <button
                      className={`px-2 py-1 text-2xs font-geist-mono rounded transition-colors
                      ${
                    generationCount === count
                      ? 'bg-black text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                    }`}
                      key={count}
                      onClick={() => setGenerationCount(count)}
                    >
                      {count}
                    </button>
                  ))}
                </div>
              </div>

              <Button
                variant="outline"
                size="sm"
                onClick={() => handleUpdate(generationCount)}
                disabled={isLoading}
                className="font-geist-mono tracking-[0.8px] text-[10px] mt-2.5 font-semibold px-2.5 py-3 border-gray-300 border"
              >
                {isGeneratingMultiple
                  ? (
                      <>
                        <RefreshCw className="mr-1.5 h-2.5 w-2.5 animate-spin" />
                        {`${streamProgress.current}/${streamProgress.total} (${streamProgress.success} succeeded, ${streamProgress.failed} failed)`}
                      </>
                    )
                  : (
                      'GENERATE RESPONSES'
                    )}
              </Button>
            </div>
          ) : (
            <MultipleResponsesResults
              responses={generatedResponses}
              onRerun={() => handleUpdate(generationCount)}
              onCancel={() => setShowMultipleResults(false)}
              generationCount={generationCount}
              onGenerationCountChange={setGenerationCount}
              isLoading={isLoading}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default PromptEditSidebar
