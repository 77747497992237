import config from 'config'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { $fetch } from 'utils/fetch'
import { getAuthToken } from 'utils/funcs/browser/getAuthToken'
import { getOrgId } from 'utils/funcs/browser/getOrgId'

export function useCallsStream() {
  const authToken = getAuthToken()
  const orgId = getOrgId()
  const [calls, setCalls] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const fetchActiveCalls = async () => {
      try {
        const data = await $fetch('/v1/calls/active')
        setCalls(data.data || [])
      }
      catch (error) {
        console.error('Failed to fetch active calls:', error)
        toast.error('Failed to fetch active calls')
      }
      finally {
        setIsLoading(false)
      }
    }

    fetchActiveCalls()
  }, [])

  useEffect(() => {
    const abortController = new AbortController()
    const processStreamData = async (data) => {
      const { type } = data

      if (type === 'UPDATE') {
        const callInfo = data.data.data
        const { status, call_id } = callInfo

        if (status === 'COMPLETE') {
          setTimeout(() => {
            setCalls(prev => prev.filter(call => call.call_id !== call_id))
          }, 3000)
        }

        setCalls((currentCalls) => {
          const existingCall = currentCalls.find(c => c.call_id === call_id)

          if (existingCall) {
            return currentCalls.map(call =>
              call.call_id === call_id ? { ...call, ...callInfo } : call,
            )
          }

          return [...currentCalls, callInfo]
        })
      }
      else if (type === 'INITIAL_STATE') {
        setCalls(data.data)
      }
    }

    const connectToStream = async () => {
      try {
        // i cannot use $fetch because it does not support SSE
        const response = await fetch(
          `${config.API_URL}/v1/calls/active/stream`,
          {
            signal: abortController.signal,
            headers: {
              Accept: 'text/event-stream',
              authorization: authToken,
              ...(orgId && { 'x-bland-org-id': orgId }),
            },
          },
        )

        if (!response.ok)
          throw new Error('Stream connection failed')

        const reader = response.body.getReader()
        const decoder = new TextDecoder()
        let buffer = ''

        while (true) {
          const { value, done } = await reader.read()
          if (done)
            break

          buffer += decoder.decode(value, { stream: true })
          const lines = buffer.split('\n')
          buffer = lines.pop() || ''

          for (const line of lines) {
            if (line.startsWith('data: ')) {
              const data = JSON.parse(line.slice(6))
              await processStreamData(data)
            }
          }
        }
      }
      catch (error) {
        if (!abortController.signal.aborted) {
          toast.error('Lost connection to call updates. Reconnecting...')
          setTimeout(connectToStream, 5000)
        }
      }
    }

    connectToStream()

    return () => {
      abortController.abort()
    }
  }, [])

  return { calls, isLoading }
}
