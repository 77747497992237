import { cn, addToast } from '@heroui/react'
import { DropdownMenu, Button as RadixButton, Tabs as RadixTabs, Tooltip } from '@radix-ui/themes'
import { keepPreviousData, useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import VoiceGradientReusable from 'components/Dashboard/SendCall/VoiceGradientReusable'
import GradientLoadingAnimation from 'components/Reusables/GradientLoadingAnimation'
import { PathwayLogsContent } from 'components/Dashboard/ConvoPathways/Components/PathwayLogs'
import { AlertCircle, CircleMinus, Copy, MoreVertical, PanelRightClose, Trash2, RefreshCw } from 'lucide-react'
import { useEffect, useRef, useState } from 'react'
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels'
import { $fetch } from 'utils/fetch'

import MessageList from './MessageList'
import RequestData from './SMSRequestData'
import SMSConfigInfo from './SMSConfigInfo'
import ConversationMetadata from './ConversationMetadata'

const animations = {
  fadeIn: 'opacity-0 animate-[fadeIn_0.5s_ease_forwards]',
  messageAppear: 'opacity-0 translate-y-2 animate-[messageAppear_0.5s_ease_forwards]',
}

export default function ConversationDetailsSlideOut({ conversationData, isLoading, onClose }) {
  const queryClient = useQueryClient()
  const [activeTabValue, setActiveTabValue] = useState('messages')
  const [isSelectionMode, setIsSelectionMode] = useState(false)
  const [selectedMessages, setSelectedMessages] = useState([])
  const [contentKey, setContentKey] = useState(Date.now())
  const [refreshing, setRefreshing] = useState(false) 
  const [highlightedLogInfo, setHighlightedLogInfo] = useState(null)
  const pathwayLogsRef = useRef(null)

  const { data, isLoading: isLoadingMessages, isFetching } = useQuery({
    queryKey: ['conversationMessages', conversationData.id],
    queryFn: async () => {
      const { data } = await $fetch(`/v1/sms/conversations/${conversationData.id}`)
      return data
    },
    staleTime: 1000 * 5, // 5 seconds
    placeholderData: keepPreviousData,
    refetchInterval: 4000,
  })
  
  // Add query for pathway logs
  const { data: pathwayLogsData, isLoading: isLoadingPathwayLogs } = useQuery({
    queryKey: ['pathwayLogs', conversationData.id],
    queryFn: async () => {
      const data = await $fetch(`/v1/pathway_calls/${conversationData.id}`)
      return data
    },
    enabled: !!conversationData.id,
    staleTime: 1000 * 60, // 1 minute
    placeholderData: keepPreviousData,
  })

  // Add delete message mutation
  const deleteMessages = useMutation({
    mutationFn: async (messageIds) => {
      return await $fetch(`/v1/sms/conversations/${conversationData.id}/messages`, {
        method: 'DELETE',
        body: { message_ids: messageIds },
      })
    },
    onSuccess: (response) => {
      addToast({
        title: `${response.count || selectedMessages.length} messages deleted successfully`,
        color: 'success',
      })
      queryClient.invalidateQueries({ queryKey: ['conversationMessages', conversationData.id] })
      queryClient.invalidateQueries({ queryKey: ['smsConversations'] })
      setIsSelectionMode(false)
      setSelectedMessages([])
    },
    onError: (error) => {
      addToast({
        title: `Failed to delete messages: ${error.message || 'Unknown error'}`,
        color: 'danger',
      })
    },
  })

  // Add delete conversation mutation
  const deleteConversation = useMutation({
    mutationFn: async () => {
      return await $fetch(`/v1/sms/conversations/${conversationData.id}`, {
        method: 'DELETE',
      })
    },
    onSuccess: () => {
      addToast({
        title: 'Conversation deleted successfully',
        color: 'success',
      })
      queryClient.invalidateQueries({ queryKey: ['smsConversations'] })
      onClose()
    },
    onError: (error) => {
      addToast({
        title: `Failed to delete conversation: ${error.message || 'Unknown error'}`,
        color: 'danger',
      })
    },
  })

  const handleDeleteSelectedMessages = () => {
    if (selectedMessages.length === 0) {
      addToast({
        title: 'Please select at least one message to delete',
        color: 'warning',
      })
      return
    }

    deleteMessages.mutate(selectedMessages)
  }

  const handleDeleteConversation = () => {
    // eslint-disable-next-line no-alert
    if (window.confirm('Are you sure you want to delete this entire conversation? This action cannot be undone.')) {
      deleteConversation.mutate()
    }
  }

  const cancelSelectionMode = () => {
    setIsSelectionMode(false)
    setSelectedMessages([])
  }

  useEffect(() => {
    if (isFetching) {
      setRefreshing(true)
    } else if (refreshing && !isFetching) {
      setContentKey(Date.now())
      setTimeout(() => setRefreshing(false), 500)
    }
  }, [isFetching, refreshing])

  const handleRefreshMessages = () => {
    queryClient.invalidateQueries({ queryKey: ['conversationMessages', conversationData.id] })
  }
  
  const handleRefreshPathwayLogs = () => {
    queryClient.invalidateQueries({ queryKey: ['pathwayLogs', conversationData.id] })
  }

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape' || e.key === 'ArrowRight') {
        if (isSelectionMode) {
          cancelSelectionMode()
        }
        else {
          onClose()
        }
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    return () => window.removeEventListener('keydown', handleKeyDown)
  }, [onClose, isSelectionMode])

  if (isLoading) {
    return (
      <div className="absolute inset-0 z-50 flex items-center justify-center bg-white">
        <div className="flex flex-col items-center justify-center">
          <GradientLoadingAnimation message="Loading conversation details..." />
        </div>
      </div>
    )
  }

  return (
    <PanelGroup direction="vertical" className={cn('z-[100000]', { 'animate-pulse': isLoadingMessages })}>
      <Panel>
        {/* TOP ROW */}
        <PanelGroup direction="horizontal" autoSaveId="persistence">
          {/* LEFT COLUMN */}
          <Panel
            defaultSize={35}
            minSize={30}
            maxSize={60}
            className="flex flex-col gap-5 overflow-auto items-center px-3"
          >
            {/* HEADER */}
            <span className="flex flex-col items-center gap-1.5 mt-4 relative">
              <Tooltip content="Close Conversation Details">
                <PanelRightClose
                  size={20}
                  className="text-gray-500 hover:text-gray-700 cursor-pointer absolute -top-2 left-0"
                  onClick={onClose}
                />
              </Tooltip>

              <VoiceGradientReusable name={conversationData.id} size={50} />
              <span className="flex items-center !gap-2">
                <h3 className="text-xl font-semibold font-taurus">
                  {conversationData?.user_number || 'SMS Conversation'}
                </h3>
                <Tooltip content="Copy Conversation ID">
                  <RadixButton
                    variant="ghost"
                    size="1"
                    color="gray"
                    style={{ cursor: 'pointer' }}
                    onClick={(event) => {
                      event.stopPropagation()
                      navigator.clipboard.writeText(conversationData.id)
                      addToast({
                        title: 'Conversation ID copied to clipboard',
                        color: 'success',
                      })
                    }}
                  >
                    <p style={{ fontSize: 12 }}>
                      {conversationData?.id?.substring(0, 6)}
                      ...
                    </p>
                    <Copy size={12} />
                  </RadixButton>
                </Tooltip>
              </span>
            </span>

            {/* METADATA */}
            <ConversationMetadata conversation={conversationData} />
            
            {/* SMS Configuration */}
            <SMSConfigInfo agentNumber={conversationData?.agent_number} isLoading={isLoading} />
          </Panel>

          <PanelResizeHandle className="bg-gray-100 hover:bg-gray-200 w-1" />

          {/* RIGHT COLUMN */}
          <Panel defaultSize={65} minSize={40}>
            {isSelectionMode && (
              <div className="sticky top-0 bg-white z-50 p-2 shadow-md border-b flex justify-between items-center">
                <div className="flex items-center gap-2">
                  <AlertCircle size={16} className="text-amber-500" />
                  <span className="text-sm">Select messages to delete</span>
                </div>
                <div className="flex gap-2">
                  <RadixButton
                    size="1"
                    variant="soft"
                    color="gray"
                    onClick={cancelSelectionMode}
                  >
                    Cancel
                  </RadixButton>
                  <RadixButton
                    size="1"
                    variant="solid"
                    color="red"
                    onClick={handleDeleteSelectedMessages}
                    disabled={selectedMessages.length === 0}
                  >
                    Delete
                    {' '}
                    {selectedMessages.length > 0 ? `(${selectedMessages.length})` : ''}
                  </RadixButton>
                </div>
              </div>
            )}

            <RadixTabs.Root
              key={`right-column-${conversationData.id}`}
              defaultValue="messages"
              value={activeTabValue}
              onValueChange={setActiveTabValue}
              className="w-full bg-white p-4 pt-0 h-full flex flex-col"
            >
              <RadixTabs.List color="gray" className="sticky top-0 bg-white z-30 pt-2 mb-3 flex justify-between items-center">
                <div className="flex">
                  <RadixTabs.Trigger value="messages" style={{ cursor: 'pointer' }}>
                    Messages
                  </RadixTabs.Trigger>
                  <RadixTabs.Trigger value="request_data" style={{ cursor: 'pointer' }}>
                    Variables
                  </RadixTabs.Trigger>
                  <RadixTabs.Trigger value="pathway_logs" style={{ cursor: 'pointer' }}>
                    Pathway Logs
                  </RadixTabs.Trigger>
                </div>
                
                <div className="ml-auto flex-shrink-0 flex items-center gap-4">
                  {activeTabValue === 'pathway_logs' && (
                    <Tooltip content="Refresh Pathway Logs">
                      <RadixButton 
                        variant="ghost" 
                        size="1" 
                        color="gray" 
                        style={{ cursor: 'pointer' }}
                        onClick={handleRefreshPathwayLogs}
                      >
                        <RefreshCw size={16} className={isFetching ? 'animate-spin' : ''} />
                      </RadixButton>
                    </Tooltip>
                  )}
                  
                  <DropdownMenu.Root>
                    <DropdownMenu.Trigger>
                      <RadixButton variant="ghost" size="1" color="gray" style={{ cursor: 'pointer' }}>
                        <MoreVertical size={16} />
                      </RadixButton>
                    </DropdownMenu.Trigger>
                    <DropdownMenu.Content>
                      <DropdownMenu.Item
                        onClick={() => setIsSelectionMode(true)}
                        disabled={isSelectionMode}
                      >
                        <div className="flex items-center gap-2">
                          <CircleMinus size={16} />
                          {' '}
                          Delete Messages
                        </div>
                      </DropdownMenu.Item>
                      <DropdownMenu.Separator />
                      <DropdownMenu.Item onClick={handleDeleteConversation} color="red">
                        <div className="flex items-center gap-2">
                          <Trash2 size={16} />
                          {' '}
                          Delete Conversation
                        </div>
                      </DropdownMenu.Item>
                    </DropdownMenu.Content>
                  </DropdownMenu.Root>
                </div>
              </RadixTabs.List>

              <div className="flex-1 overflow-hidden">
                <RadixTabs.Content value="messages" className="h-full pb-8 overflow-auto">
                  <div className="transition-all duration-500">
                    <MessageList
                      key={contentKey}
                      messages={data?.messages.filter(message => message.message != '<Call Connected>') || []}
                      isLoading={isLoadingMessages}
                      _onDeleteMessages={deleteMessages.mutate}
                      selectedMessages={selectedMessages}
                      setSelectedMessages={setSelectedMessages}
                      isSelectionMode={isSelectionMode}
                      className={cn(
                        "transition-all duration-500",
                        refreshing && !isFetching && "opacity-0 animate-[fadeIn_0.5s_ease_forwards]"
                      )}
                    />
                  </div>
                </RadixTabs.Content>

                <RadixTabs.Content value="request_data" className="h-full overflow-auto pb-16">
                  <div className="transition-all duration-500">
                    <RequestData 
                      key={contentKey} 
                      requestData={conversationData.variables || {}}
                      className={cn(
                        "transition-all duration-500",
                        refreshing && !isFetching && "opacity-0 animate-[fadeIn_0.5s_ease_forwards]"
                      )}
                    />
                  </div>
                </RadixTabs.Content>
                
                {isLoadingPathwayLogs ? (
                  <RadixTabs.Content value="pathway_logs" className="h-full overflow-auto pb-16">
                    <div className="flex flex-col items-center justify-center h-full">
                      <GradientLoadingAnimation message="Loading pathway logs..." />
                    </div>
                  </RadixTabs.Content>
                ) : pathwayLogsData && pathwayLogsData.length > 0 ? (
                  <RadixTabs.Content value="pathway_logs" className="h-full overflow-auto pb-16">
                    <div className="transition-all duration-500">
                      <PathwayLogsContent
                        key={contentKey}
                        loading={false}
                        initialCallId={conversationData.id}
                        callLogs={pathwayLogsData}
                        setShouldScroll={() => {}}
                        callLogsRef={pathwayLogsRef}
                        elements={null}
                        setChatConversationHistory={null}
                        setChatStartNode={null}
                        enableQA
                        highlightedLogInfo={highlightedLogInfo}
                        className={cn(
                          "transition-all duration-500",
                          refreshing && !isFetching && "opacity-0 animate-[fadeIn_0.5s_ease_forwards]"
                        )}
                      />
                    </div>
                  </RadixTabs.Content>
                ) : (
                  <RadixTabs.Content value="pathway_logs" className="h-full overflow-auto pb-16">
                    <div className="flex flex-col items-center justify-center h-full">
                      <p className="text-gray-500">No pathway logs available for this conversation</p>
                    </div>
                  </RadixTabs.Content>
                )}
              </div>
            </RadixTabs.Root>
          </Panel>
        </PanelGroup>
      </Panel>
    </PanelGroup>
  )
}
