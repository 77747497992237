import { useEffect, useMemo, useState } from 'react'
import ReactFlow, { Background, Controls } from 'reactflow'
import CustomEdge from '../../components/Dashboard/ConvoPathways/Components/label_edge'
import DefaultNode from '../../components/Dashboard/ConvoPathways/Components/nodes/node'

function PathwayFlow({ nodes: pathwayNodes, edges: pathwayEdges }) {
  const [nodes, setNodes] = useState([])
  const [edges, setEdges] = useState([])

  const edgeTypes = useMemo(() => ({ custom: CustomEdge }), [])

  const nodeTypes = useMemo(
    () => ({
      'Transfer Chat': props => <DefaultNode {...props} context="preview" />,
      'Default': props => <DefaultNode {...props} context="preview" />,
      'Knowledge Base': props => <DefaultNode {...props} context="preview" />,
      'Transfer Call': props => <DefaultNode {...props} context="preview" />,
      'End Call': props => <DefaultNode {...props} context="preview" />,
      'Webhook': props => <DefaultNode {...props} context="preview" />,
      'Wait for Response': props => (
        <DefaultNode {...props} context="preview" />
      ),
      'Schedule Meeting': props => (
        <DefaultNode {...props} context="preview" />
      ),
      'Vector DB Knowledge Base': props => (
        <DefaultNode {...props} context="preview" />
      ),
      'Transfer Pathway': props => (
        <DefaultNode {...props} context="preview" />
      ),
      'Custom Tool': props => <DefaultNode {...props} context="preview" />,
      'SMS': props => <DefaultNode {...props} context="preview" />,
      'Press Button': props => <DefaultNode {...props} context="preview" />,
    }),
    [],
  )

  useEffect(() => {
    async function fetchPathwayData() {
      if (pathwayNodes && pathwayEdges) {
        setNodes(pathwayNodes)
        setEdges(pathwayEdges)
      }
      else {
        console.error('Failed to fetch pathway data')
      }
    }
    fetchPathwayData()
  }, [pathwayNodes, pathwayEdges])

  return (
    <div
      className="w-full h-full max-w-full max-h-full m-4"
      style={{ width: '100%', height: '100%' }}
    >
      <ReactFlow
        nodes={nodes}
        edges={edges}
        nodeTypes={nodeTypes}
        fitView
        minZoom={0.1}
        edgeTypes={edgeTypes}
        proOptions={{ hideAttribution: true }}
      >
        <Background variant="dots" gap={12} size={1} />
        <Controls position="bottom-left" />
      </ReactFlow>
    </div>
  )
}

export default PathwayFlow
