import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { DataGrid } from '@mui/x-data-grid'
import { IconButton } from '@radix-ui/themes'
import { useQuery } from '@tanstack/react-query'
import { Loading } from 'components/core/Loading'
import { AnimatePresence, motion } from 'framer-motion'
import { Repeat, Search, X } from 'lucide-react'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { $fetch } from 'utils/fetch'
import { convertToMinutesAndSeconds } from 'utils/formatting/secsToMins'
import { toDtmy } from 'utils/formatting/toDtmy'
import { useSimulationsContext } from '../simulations/context/SimulationContext'
import SimulationsTab from '../simulations/simulationTab'
import CallDetailsSlider from './CallDetailsSlider'

function CallLogsModal({
  isOpen,
  setIsOpen,
  onClose,
  pathwayId,
  handleReplayCall,
  isReplayLoading,
  activeTab,
  setActiveTab,
}) {
  const { isCreationModalOpen, setCurrentReplaySimulationCallID }
    = useSimulationsContext()

  useEffect(() => {
    if (isCreationModalOpen) {
      setIsOpen(true)
      setActiveTab('simulations')
    }
  }, [isCreationModalOpen, setIsOpen, setActiveTab])
  const [callLogs, setCallLogs] = useState([])
  const [totalRecords, setTotalRecords] = useState(0)
  const [selectedCallId, setSelectedCallId] = useState(null)
  const [currPage, setCurrPage] = useState(0)
  const [searchTerm, setSearchTerm] = useState('')

  const pathwayFilter = {
    field: 'pathway_id',
    operator: 'equals',
    value: pathwayId,
  }

  const { data, isLoading: loading } = useQuery({
    queryKey: ['callLogs', currPage, 25, pathwayFilter, 'created_at', 'desc'],
    queryFn: async () => {
      const { data: { calls, pagination } } = await $fetch(`/user/logs`, {
        query: {
          filters: [pathwayFilter],
          page: (currPage + 1).toString(),
          take: '25',
        },
      })
      return { calls, pagination }
    },
    enabled: isOpen && activeTab === 'calls',
    staleTime: Infinity,
  })

  const formatTableData = (data) => {
    return data.map((call, index) => ({
      id: index,
      created_at: toDtmy(new Date(call.created_at)),
      to: call.to,
      from: call.from,
      call_length: convertToMinutesAndSeconds(call.call_length),
      inbound: call.inbound ? 'Inbound' : 'Outbound',
      transferred_to: call.transferred_to || '-',
      cost: `$${call.price ? Number.parseFloat(call.price).toFixed(2) : '0.00'}`,
      status: call.completed ? 'Completed' : 'In Progress',
      c_id: call.c_id,
      pathway_logs: call.pathway_logs
        ? JSON.stringify(call.pathway_logs)
        : 'N/A',
      variables: call.variables,
      metadata: call.metadata ? JSON.stringify(call.metadata) : 'N/A',
      batch_id: call.batch_id || '-',
      pathway_id: call.pathway_id || '-',
      pathway_tags: call.pathway_tags || '-',
      pathway_name: call.pathway_name || '-',
      answered_by: call.answered_by || '-',
      error_message: call.error_message || '-',
      call_ended_by: call.call_ended_by || '-',
    }))
  }

  useEffect(() => {
    if (data) {
      setCallLogs(formatTableData(data.calls))
      if (data.pagination.totalCount) {
        setTotalRecords(data.pagination.totalCount)
      }
    }
  }, [data])

  const columns = [
    {
      field: 'created_at',
      headerName: 'Created On',
      width: 130,
    },
    {
      field: 'replay',
      headerName: 'Replay',
      width: 65,
      renderCell: params => (
        <IconButton
          variant="soft"
          size={1}
          disabled={isReplayLoading}
          onClick={(e) => {
            e.stopPropagation()
            setCurrentReplaySimulationCallID(null)
            handleReplayCall(params.row.c_id)
          }}
          style={{ cursor: 'pointer' }}
        >
          {isReplayLoading
            ? (
                <Loading size={1} loading={isReplayLoading} />
              )
            : (
                <Repeat size={20} />
              )}
        </IconButton>
      ),
    },
    {
      field: 'call_length',
      headerName: 'Call Length',
      width: 90,
    },
    { field: 'to', headerName: 'To', width: 110 },
    { field: 'from', headerName: 'From', width: 110 },
    { field: 'c_id', headerName: 'Call ID', width: 240 },
  ]

  const handleRowClick = (params) => {
    setSelectedCallId(params.id)
  }

  return (
    <ModalWrapper
      initial={{ x: '100%' }}
      animate={{ x: isOpen ? 0 : '100%' }}
      transition={{ type: 'spring', stiffness: 300, damping: 30 }}
    >
      <div className="p-4 border-b border-gray-200">
        <div className="flex justify-between mb-[-16px]">
          <Tabs
            value={activeTab}
            onValueChange={setActiveTab}
            className="w-auto"
          >
            <TabsList className="h-auto bg-transparent border-0 p-0 space-x-2.5 px-1.5 mt-2.5">
              <TabsTrigger
                value="calls"
                className="font-geist-mono uppercase tracking-[0.8px] text-xs
                         data-[state=active]:border-b-2 data-[state=active]:border-black
                         data-[state=active]:font-bold data-[state=active]:bg-transparent
                         data-[state=active]:shadow-none px-0 pb-1.5
                         border-0 rounded-none"
              >
                Call Logs
              </TabsTrigger>
              <TabsTrigger
                value="simulations"
                className="font-geist-mono uppercase tracking-[0.8px] text-xs
                         data-[state=active]:border-b-2 data-[state=active]:border-black
                         data-[state=active]:bg-transparent data-[state=active]:shadow-none
                         px-0 pb-1.5 border-0 rounded-none data-[state=active]:font-bold"
              >
                Test Cases
              </TabsTrigger>
            </TabsList>
          </Tabs>

          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <X size={20} />
          </button>
        </div>
      </div>

      {/*  disabled for simulation and creationmodal */}
      {!isCreationModalOpen && activeTab === 'calls' && (
        <SearchBarWrapper>
          <SearchIconWrapper>
            <Search size={16} />
          </SearchIconWrapper>
          <StyledInput
            type="text"
            placeholder={
              activeTab === 'calls'
                ? 'Search call logs by ID or phone number'
                : 'Search simulations'
            }
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value)
              setCurrPage(0)
            }}
          />
        </SearchBarWrapper>
      )}

      <Wrapper>
        {activeTab === 'calls'
          ? (
              <DataGrid
                columns={columns}
                rows={callLogs}
                pageSize={25}
                rowCount={totalRecords}
                paginationMode="server"
                onPageChange={newPage => setCurrPage(newPage)}
                page={currPage}
                autoHeight={false}
                disableColumnMenu
                disableSelectionOnClick
                loading={loading}
                components={{
                  LoadingOverlay: CustomLoadingOverlay,
                }}
                onRowClick={handleRowClick}
                sx={dataGridStyles}
              />
            )
          : (
              <SimulationsTab
                isOpen={isOpen}
                pathwayId={pathwayId}
                handleReplayCall={handleReplayCall}
                isReplayLoading={isReplayLoading}
                pageSize={25}
                searchTerm={searchTerm}
              />
            )}
      </Wrapper>

      <AnimatePresence>
        {selectedCallId !== null && (
          <CallDetailsSlider
            callData={callLogs[selectedCallId]}
            onClose={() => {
              setSelectedCallId(null)
              setCurrentReplaySimulationCallID(null)
            }}
            handleReplayCall={(id) => {
              setCurrentReplaySimulationCallID(null)
              handleReplayCall(id)
            }}
          />
        )}
      </AnimatePresence>
    </ModalWrapper>
  )
}

const dataGridStyles = {
  'height': '100%',
  'width': '100%',
  '& .MuiDataGrid-main': { flexGrow: 1 },
  '& .MuiDataGrid-columnHeaderTitle': {
    fontWeight: 600,
    fontSize: '12px',
  },
  '& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus':
    {
      outline: 'none !important',
    },
  '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus': {
    outline: 'none !important',
    background: 'transparent !important',
  },
  '& .MuiDataGrid-cell': {
    fontSize: '11px',
  },
  '& .MuiDataGrid-columnHeaders': {
    backgroundColor: '#f5f5f5',
    position: 'sticky',
    top: 0,
    zIndex: 1,
  },
  '& .MuiDataGrid-row:hover': {
    background: '#FAFAFA !important',
    cursor: 'pointer',
  },
  '& .MuiDataGrid-virtualScroller': {
    marginTop: '0!important',
  },
  'borderRadius': '4px',
}

const SearchBarWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  padding-left: 16px;
  border-bottom: 1px solid #e5e7eb;
  background-color: #f9fafb;
`

const SearchIconWrapper = styled.div`
  margin-right: 8px;
  display: flex;
  align-items: center;
`

const StyledInput = styled.input`
  border: none;
  outline: none;
  padding: 8px;
  font-size: 14px;
  width: 100%;
  background-color: transparent;
  color: #111827;

  &::placeholder {
    color: #9ca3af;
  }
`

const ModalWrapper = styled(motion.div)`
  position: fixed;
  top: 0;
  right: 0;
  width: 33%;
  height: 100%;
  background-color: white;
  box-shadow: -2px 0 10px rgba(0, 0, 0, 0.1);
  z-index: 50;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const Wrapper = styled.div`
  flex-grow: 1;
  padding: 16px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

function CustomLoadingOverlay() {
  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
      }}
    >
      <Loading loading />
    </div>
  )
}

export default CallLogsModal
