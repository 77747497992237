import axios from 'axios'
import config from 'config'
import { getOrgId } from 'utils/funcs/browser/getOrgId'

export async function endActiveCall(callId, apiKey) {
  const orgId = getOrgId()
  try {
    const response = await axios.post(
      `${config.API_URL}/v1/calls/${callId}/stop`,
      { call_id: callId },
      {
        headers: {
          'Content-Type': 'application/json',
          'authorization': apiKey,
          ...(orgId && { 'x-bland-org-id': orgId }),
        },
      },
    )
    if (response.data) {
      console.log('Call ended successfully:', response.data)
      return { is_call_ended: true, data: response.data }
    }
    else {
      console.error('HTTP error ending call:', response.data)
      return { is_call_ended: false, error: 'No data in response' }
    }
  }
  catch (error) {
    console.error('Error ending call in endActiveCall():', error)
    return { is_call_ended: false, error: error.message }
  }
}
