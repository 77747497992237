import Tooltip from '@mui/material/Tooltip'
import { TooltipText } from '_components/Text'
import Switch from 'components/core/Switch'

export default function WaitGreeting({ fieldName }) {
  const longText = (
    <span>
      By default, the agent starts talking as soon as the call connects.
      {' '}
      <br />
      <br />
      When
      {' '}
      <strong>wait_for_greeting</strong>
      {' '}
      is set to
      {' '}
      <strong>true</strong>
      ,
      the agent will wait for the call recipient to speak first before
      responding.
    </span>
  )
  return (
    <Tooltip>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <TooltipText tooltip={longText} tooltipClassName="w-[300px]">
          Wait For Greeting
        </TooltipText>
        <Switch sx={{ m: 5 }} fieldName={fieldName} />
      </div>
    </Tooltip>
  )
}
