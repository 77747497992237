import Tooltip from '@mui/material/Tooltip'
import { TooltipText } from '_components/Text'
import Switch from 'components/core/Switch'

export default function NoiseCancellation({ fieldName }) {
  const tooltipContent = (
    <span>
      Enable
      {' '}
      <strong>noise cancellation</strong>
      {' '}
      to reduce background noise
      during your call. This feature uses advanced algorithms to filter out
      unwanted sounds, ensuring clearer communication.
    </span>
  )

  return (
    <Tooltip>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <TooltipText tooltip={tooltipContent} tooltipClassName="w-[300px]">
          Noise Cancellation
        </TooltipText>
        <Switch sx={{ m: 5 }} fieldName={fieldName} />
      </div>
    </Tooltip>
  )
}
