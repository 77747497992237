import { Button, Input, Tooltip } from '@heroui/react'
import { useQueryClient } from '@tanstack/react-query'
import { useAuth } from 'hooks/useAuth'
import { Check } from 'lucide-react'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { $fetch } from 'utils/fetch'

export default function DisplayNameInput() {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const queryClient = useQueryClient()
  const { org, permissions } = useAuth()
  const [orgDisplayName, setOrgDisplayName] = useState(org.org_display_name)

  const hasEditedOrgName = org.org_display_name !== orgDisplayName
  const hasPermissions = permissions.includes('owner')

  const onSubmit = async () => {
    setIsSubmitting(true)

    try {
      await $fetch(`/orgs/${org.id}/properties`, {
        method: 'PATCH',
        body: {
          updates: {
            org_display_name: orgDisplayName,
          },
        },
      })

      toast.success('Organization name updated.')
      queryClient.setQueryData(['orgs'], (orgs) => {
        return orgs.map(el => el.org_id === org.id ? { ...el, org_display_name: orgDisplayName } : el)
      })
      queryClient.setQueryData(['org'], (org) => {
        return { ...org, org_display_name: orgDisplayName }
      })
      org.org_display_name = orgDisplayName
    }
    catch (error) {
      console.error(error)
      const errorMessage = error.data?.errors?.[0]?.message
      toast.error(errorMessage)
    }
    finally {
      setIsSubmitting(false)
    }
  }
  return (
    <Input
      isReadOnly={!hasPermissions}
      label="Organization Name"
      labelPlacement="outside"
      color="primary"
      radius="none"
      type="text"
      placeholder="Enter your organization name"
      defaultValue={orgDisplayName}
      onValueChange={setOrgDisplayName}
      variant="bordered"
      name="org_display_name"
      classNames={{
        label: 'text-black',
      }}
      endContent={(
        <Tooltip content="Save changes">
          <Button isIconOnly radius="none" size="sm" variant="flat" color="primary" onPress={onSubmit} isLoading={isSubmitting} className={`transition-opacity -right-2 duration-300 ease-in-out ${hasEditedOrgName ? 'opacity-100' : 'opacity-0'}`}>
            <Check size={16} />
          </Button>
        </Tooltip>
      )}
    />
  )
}
