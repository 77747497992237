import { CardTitle } from '@/components/ui/card'
import { Button, Flex } from '@radix-ui/themes'
import blandlogo from 'assets/brand/logo-infinity.svg'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { useAuth } from 'hooks/useAuth'

// Define styled components outside the Header component to ensure stable hook order.
const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  flex-direction: row;
  padding: 20px;
`;

const BrandIcon = styled.img`
  width: 27px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export function Header() {
  const { user } = useAuth()
  const navigate = useNavigate()

  const pageTitle = 'Bland Tools'

  const isUserLoggedIn = !!user
  const nav = [
    {
      label: 'My Tools',
      value: 'my_tools',
      variant: 'ghost',
      solid: false,
      color: 'gray',
      disabled: !isUserLoggedIn,
    },
    // { label: user ? "Collection":"Sign up", variant: "ghost", value:user ? "collection":"signup", solid: false, color: "gray" },
    {
      label: 'Create Tool',
      value: 'create_tool',
      variant: 'outline',
      solid: true,
      color: 'gray',
      disabled: !isUserLoggedIn,
    },
  ]

  return (
    <HeaderContainer>
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row',
          columnGap: '20px',
        }}
      >
        <BrandIcon src={blandlogo} />
        <CardTitle style={{ fontSize: 20, fontWeight: '500' }}>
          {pageTitle}
        </CardTitle>
      </div>
      <Flex align="center" direction="row" gap="5">
        {nav
          ?.filter(item => !item.disabled)
          .map((item, index) => (
            <Button
              onClick={() => {
                if (item.value === 'create_tool') {
                  navigate('/dashboard/tools')
                }

                if (item.value === 'my_tools') {
                  navigate('/dashboard/tools')
                }
              }}
              key={index}
              variant={item.variant}
              color={item.color}
              size="2"
              style={{ cursor: 'pointer', fontWeight: '600' }}
            >
              {item.label}
            </Button>
          ))}
      </Flex>
    </HeaderContainer>
  )
}
