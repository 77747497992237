import Button from 'components/core/Button'
import { PageTitle } from 'components/core/PageTitle'
import { useAuth } from 'hooks/useAuth'
import styled from 'styled-components'
import PulsingLight from '../../../../assets/gifs/PulsingLight.gif'
import LockIcon from '../../../../assets/icons/lock.svg'
import ServerIconPriv from '../../../../assets/icons/priv-server-icon.svg'
import ServerIcon from '../../../../assets/icons/server-icon.svg'
import UnlockIcon from '../../../../assets/icons/unlocked.svg'

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  background-color: #fff;
  color: #c5c6c7;
  font-family: "Arial", sans-serif;
`

const Th = styled.th`
  padding: 12px 0px;
  border-bottom: 2px solid #eeeeee;
  text-align: left;
  color: #000;
`

const Td = styled.td`
  border-bottom: 1px solid #eeeeee;
  text-align: left;
  height: 70px;
  color: #000;
`

const Tag = styled.span`
  background-color: #2a9d8f;
  padding: 4px 8px;
  border-radius: 4px;
  color: #ffffff;
  font-size: 12px;
`

const Filter = styled.span`
  background-color: #2a9d8f;
  padding: 4px 8px;
  border-radius: 4px;
  color: #ffffff;
  font-size: 12px;
`

const StateEnabled = styled.span`
  color: #66fcf1;
  font-weight: bold;
`

const StateDisabled = styled.span`
  color: #e63946;
  font-weight: bold;
`

function StaticTable() {
  const { user, org } = useAuth()

  return (
    <Table>
      <thead>
        <tr>
          <Th>
            <PageTitle style={{ fontSize: 10 }}>Servers</PageTitle>
          </Th>
          <Th></Th>
          <Th>
            <PageTitle style={{ fontSize: 10 }}>Location</PageTitle>
          </Th>
          <Th>
            <PageTitle style={{ fontSize: 10 }}>Queue Pool</PageTitle>
          </Th>
          <Th>
            <PageTitle style={{ fontSize: 10 }}>Latency</PageTitle>
          </Th>
          <Th>
            <PageTitle style={{ fontSize: 10 }}>HIPAA</PageTitle>
          </Th>
          <Th>
            <PageTitle style={{ fontSize: 10 }}>SOC-2</PageTitle>
          </Th>
          <Th></Th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <Td style={{}}>
            <div
              id="image-box"
              style={{
                padding: '5px 5px',
                background: '#eeeeee',
                height: 24,
                width: 24,
              }}
            >
              <img src={ServerIcon} style={{ height: 14, width: 14 }} />
            </div>
          </Td>
          <Td style={{}}>
            <PageTitle style={{ fontSize: 10, fontWeight: '450' }}>
              Bland Public Server
            </PageTitle>
          </Td>
          <Td>
            <PageTitle style={{ fontSize: 10, fontWeight: '450' }}>
              us-west-2
            </PageTitle>
          </Td>
          <Td>
            <PageTitle style={{ fontSize: 10, fontWeight: '450' }}>
              Shared
            </PageTitle>
          </Td>
          <Td>
            <PageTitle style={{ fontSize: 10, fontWeight: '450' }}>
              {'>1200ms'}
            </PageTitle>
          </Td>
          <Td>
            <img src={UnlockIcon} style={{ height: 18, width: 18 }} />
          </Td>
          <Td>
            <img src={UnlockIcon} style={{ height: 18, width: 18 }} />
          </Td>
          <Td>
            <img
              src={PulsingLight}
              height={14}
              width={14}
              style={{ marginLeft: 'auto' }}
            />
          </Td>
        </tr>
        <tr>
          <Td style={{}}>
            <div
              id="image-box"
              style={{
                padding: '5px 5px',
                background: '#eeeeee',
                height: 24,
                width: 24,
              }}
            >
              <img src={ServerIconPriv} style={{ height: 14, width: 14 }} />
            </div>
          </Td>
          <Td>
            <PageTitle style={{ fontSize: 10, fontWeight: '450' }}>
              Private Server
            </PageTitle>
          </Td>
          <Td>
            <PageTitle style={{ fontSize: 10, fontWeight: '450' }}>
              Custom
            </PageTitle>
          </Td>
          <Td>
            <PageTitle style={{ fontSize: 10, fontWeight: '450' }}>
              Dedicated
            </PageTitle>
          </Td>
          <Td>
            <PageTitle style={{ fontSize: 10, fontWeight: '450' }}>
              {'>850ms'}
            </PageTitle>
          </Td>
          <Td>
            <img
              src={LockIcon}
              style={{ height: 18, width: 18, marginRight: 'auto' }}
            />
            <p
              style={{
                textAlign: 'left',
                fontSize: 8,
                color: 'grey',
                fontWeight: '550',
                marginTop: 3,
              }}
            >
              HIPAA Compliant
            </p>
          </Td>
          <Td>
            <img
              src={LockIcon}
              style={{ height: 18, width: 18, marginRight: 'auto' }}
            />
            <p
              style={{
                textAlign: 'left',
                fontSize: 8,
                color: 'grey',
                fontWeight: '550',
                marginTop: 3,
              }}
            >
              SOC Compliant
            </p>
          </Td>
          <Td>
            {/* TEMPLATE CODES: 2,3,8 */}
            {org?.org_type === 'enterprise'
              ? (
                  <img
                    src={PulsingLight}
                    height={14}
                    width={14}
                    style={{ marginLeft: 'auto' }}
                  />
                )
              : (
                  <>
                    <Button
                      onClick={() => {
                        window.location.href = '/enterprise'
                      }}
                      style={{
                        borderRadius: 6,
                        height: 30,
                        width: 60,
                        marginLeft: 'auto',
                      }}
                    >
                      <p style={{ fontSize: 13, fontWeight: '600' }}>Enable</p>
                    </Button>
                  </>
                )}
          </Td>
        </tr>
      </tbody>
    </Table>
  )
}

export default StaticTable
