import { Button, cn, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, RadioGroup, ScrollShadow, Snippet, Spinner, useDisclosure, useRadio, VisuallyHidden } from '@heroui/react'
import { useFilter } from '@react-aria/i18n'
import { useQuery } from '@tanstack/react-query'
import { formatDistanceToNow } from 'date-fns'
import { FolderMinus, Import, Search } from 'lucide-react'
import { useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { toast } from 'react-toastify'
import { $fetch } from 'utils/fetch'
import { objectToKeyValue } from 'utils/formatting/objToKeyValues'

function CustomRadio({ code, selected, ...props }) {
  const {
    Component,
    children,
    description,
    getBaseProps,
    getWrapperProps,
    getInputProps,
    getLabelProps,
    getLabelWrapperProps,
    getControlProps,
  } = useRadio(props)

  return (
    <Component
      {...getBaseProps()}
      className={cn(
        'group flex flex-col items-center max-w-full justify-between tap-highlight-transparent',
        'cursor-pointer border border-default data-[selected=true]:shadow-lg rounded-lg gap-2 p-4',
        'data-[selected=true]:border-primary',
      )}
    >
      <div className="inline-flex flex-row-reverse w-full justify-between">
        <VisuallyHidden>
          <input {...getInputProps()} />
        </VisuallyHidden>

        <span {...getWrapperProps()}>
          <span {...getControlProps()} />
        </span>
        <div {...getLabelWrapperProps()}>
          {children && <span {...getLabelProps()}>{children}</span>}
          {description && (
            <span className="text-small text-foreground opacity-70">{description}</span>
          )}
        </div>
      </div>
      <Snippet
        radius="none"
        variant="flat"
        classNames={{
          base: `${selected ? 'max-h-48 overflow-y-scroll shadow-md' : 'max-h-24 overflow-y-hidden'} flex bg-primary-50 border border-default transition-all flex-col w-full`,
          content: 'w-full gap-0.5',
          pre: 'text-wrap truncate font-light text-xs',
        }}
        hideCopyButton
        hideSymbol
      >
        {Object.entries(code).map(([key, value]) => (
          <span key={key} className="space-x-1">
            <span className="text-primary">{key}</span>
            :
            {
              typeof value === 'object'
                ? JSON.stringify(value)
                : <span className="text-success">{String(value)}</span>
            }
            <br />
          </span>
        ))}
      </Snippet>
    </Component>
  )
}

export default function ImportPresetModal({ color }) {
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure()
  const [selectedConfig, setSelectedConfig] = useState(null)
  const [searchQuery, setSearchQuery] = useState('')
  const { reset } = useFormContext()

  const { data: configs, isLoading } = useQuery({
    queryKey: ['configs'],
    queryFn: async () => {
      const { data } = await $fetch('/call_config/read')
      return data
    },
  })

  const handleConfigSelect = (value) => {
    setSelectedConfig(value)
  }

  const { contains } = useFilter({
    sensitivity: 'base', // Ignore case and diacritics
  })

  const filteredConfigs = useMemo(() => {
    if (!configs)
      return []

    const filtered = !searchQuery.trim()
      ? [...configs]
      : configs.filter((config) => {
          if (selectedConfig === config.id)
            return true
          return contains(config.name, searchQuery)
        })

    return filtered.sort((a, b) =>
      new Date(b.created_at) - new Date(a.created_at),
    )
  }, [searchQuery, contains, configs, selectedConfig])

  const handleImport = () => {
    const config = configs.find(config => config.id === selectedConfig)
    const { call_config: { custom_tools, request_data, transfer_list, pathway_ids, analysis_schema, metadata, tools, task, prompt, ...rest } } = config
    reset({
      ...rest,
      request_data: objectToKeyValue(request_data ?? {}),
      transfer_list: objectToKeyValue(transfer_list ?? {}),
      pathway_ids: objectToKeyValue(pathway_ids ?? {}),
      analysis_schema: objectToKeyValue(analysis_schema ?? {}),
      metadata: objectToKeyValue(metadata ?? {}),
      tools: custom_tools?.length > 0 ? custom_tools : tools || [],
      task: prompt ?? task ?? null,
    })
    toast.success('Persona imported successfully')
    onClose()
  }

  return (
    <>
      <Button
        variant="light"
        disableRipple
        radius="none"
        color={color}
        onPress={onOpen}
        startContent={<Import size={16} />}
      >
        Import
      </Button>
      <Modal size="3xl" isOpen={isOpen} onOpenChange={onOpenChange} radius="sm">
        <ModalContent>
          {onClose => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                <h2 className="text-2xl font-semibold flex items-center gap-3">
                  Import Persona
                </h2>
                <p className="text-sm font-light text-foreground-400">Populate Send Call with a persona configuration</p>
              </ModalHeader>
              <ModalBody>
                {isLoading
                  ? (
                      <div className="h-[500px] flex flex-col justify-center">
                        <Spinner />
                      </div>
                    )
                  : filteredConfigs?.length > 0
                    ? (
                        <>
                          <Input
                            autoFocus
                            type="search"
                            radius="sm"
                            variant="bordered"
                            value={searchQuery}
                            onChange={e => setSearchQuery(e.target.value)}
                            placeholder="Search for a configuration"
                            startContent={(<Search size={16} />)}
                            className="w-full"
                          />
                          <ScrollShadow className="h-[500px] w-full" hideScrollBar>
                            <RadioGroup
                              value={selectedConfig}
                              onValueChange={handleConfigSelect}
                              className="flex flex-col gap-4"
                            >
                              {filteredConfigs?.map(config => (
                                <CustomRadio
                                  key={config.id}
                                  value={config.id}
                                  description={formatDistanceToNow(new Date(config.created_at), { addSuffix: true })}
                                  code={config.call_config}
                                  selected={selectedConfig === config.id}
                                >
                                  {config.name}
                                </CustomRadio>
                              ))}
                            </RadioGroup>
                          </ScrollShadow>
                        </>
                      )
                    : (
                        <div className="w-full flex flex-col items-center gap-2 h-[500px] justify-center">
                          <FolderMinus size={45} className="text-gray-800" />
                          <h1 className="scroll-m-12 text-xl tracking-tighter font-semibold">
                            No configurations found.
                          </h1>
                          <p className="text-sm text-gray-600">You haven't saved any call configurations yet.</p>
                        </div>
                      )}
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Close
                </Button>
                <Button isDisabled={!selectedConfig} color="primary" onPress={handleImport}>
                  Import
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  )
}
