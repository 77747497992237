import { useEffect, useState } from 'react'
import { BiCheckCircle, BiCopy } from 'react-icons/bi'
import SyntaxHighlighter from 'react-syntax-highlighter/dist/esm/default-highlight'
import { vs2015 } from 'react-syntax-highlighter/dist/esm/styles/hljs'
import styled from 'styled-components'

export default function TestConsole({ log }) {
  const [copied, setCopied] = useState(false)

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false)
      }, 5000)
    }
  }, [copied])

  return (
    <CodeWrapper>
      <CodeHeader>
        <div
          className="text-white py-1.5 px-2.5 rounded-sm flex items-center hover:cursor-pointer"
          onClick={() => {
            setCopied(true)
          }}
        >
          {!copied
            ? (
                <BiCopy className="mr-1.5" />
              )
            : (
                <BiCheckCircle className="mr-1.5" />
              )}
          {!copied ? <p>Copy Code</p> : <p>Copied!</p>}
        </div>
      </CodeHeader>
      <SyntaxHighlighter
        wrapLongLines
        language="javascript"
        style={vs2015}
        customStyle={customSyntaxStyle}
      >
        {JSON.stringify(log, null, 2)}
      </SyntaxHighlighter>
    </CodeWrapper>
  )
}

const customSyntaxStyle = {
  width: '100%',
  background: 'none',
  backgroundColor: 'none',
  padding: '20px',
  margin: '0px',
  fontSize: '12px',
}

const CodeWrapper = styled.div`
  width: 100%;
  position: sticky;
  top: 0px;
  border-radius: 5px;
  background-color: #0f0f17;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const CodeHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: #1d1d1d;
  height: auto;
  padding: 3px 5px;
`
