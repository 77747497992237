import {
  ArrowRightIcon,
  CheckCircleIcon,
  XCircleIcon,
} from '@heroicons/react/24/solid'
import { Tooltip } from '@radix-ui/themes'
import KeyValues from 'components/Dashboard/PhoneNumbers/Inbound/DynamicData/Nested/KeyValues'
import config from 'config'
import { Loader2, RefreshCw, Trash2 } from 'lucide-react'
import { useContext, useEffect, useState } from 'react'
import Select from 'react-dropdown-select'
import { FormProvider, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { getApiKey } from 'utils/funcs/browser/getApiKey'
import { getOrgId } from 'utils/funcs/browser/getOrgId'
import FlowContext from '../contextFlow'

function getErrorTooltip(state) {
  return state.error || 'Configuration needed'
}

function RedirectTwilioFlowModal({ id, data, setIsOpen }) {
  const [flows, setFlows] = useState([])
  const [selectedFlow, setSelectedFlow] = useState(null)
  const [manualFlowSidEntry, setManualFlowSidEntry] = useState(false)
  const [states, setStates] = useState([])
  const [nodeTag, setNodeTag] = useState(data?.tag || null)
  const [name, setName] = useState(data?.name || '')
  const [showDebugInfo, setShowDebugInfo] = useState(true)
  const [statusLoading, setStatusLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const orgId = getOrgId()
  const methods = useForm({
    defaultValues: {
      flowSid: '',
      selectedState: '',
      redirectVariables: [
        {
          'Twilio Variables': [{ key: '', value: '' }],
        },
      ],
    },
  })

  const { triggerUpdate, elements } = useContext(FlowContext)

  const getFlows = async () => {
    try {
      const response = await fetch(`${config.API_URL}/v1/flows/get_flows`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': getApiKey(),
          ...(orgId && { 'x-bland-org-id': orgId }),
        },
      })

      const data = await response.json()
      if (!response.ok) {
        throw new Error(data.error || 'Failed to fetch flows')
      }

      if (data.flows) {
        console.log('data.flows', data.flows)
        setFlows(data.flows)
      }
    }
    catch (error) {
      console.error('Error fetching flows:', error)
      toast.error(error.message || 'Error fetching flows')
    }
    finally {
    }
  }

  const validateFlowDefinition = (definition) => {
    if (!definition?.states) {
      return {
        valid: false,
        error: 'states',
        message: 'No states found in flow definition',
        fixSteps: [
          '1. Ensure your flow has at least one state',
          '2. If the flow appears empty, try recreating it',
          '3. Contact support if the issue persists',
        ],
      }
    }

    const leaveState = definition.states.find(
      state =>
        state.type === 'add-twiml-redirect' && state.name === 'bland_widget',
    )

    const returnTransition = leaveState?.transitions?.find(
      t => t.event === 'return',
    )

    const returnState = returnTransition?.next
      ? definition.states.find(state => state.name === returnTransition.next)
      : null

    return {
      valid: !!(leaveState && returnTransition?.next && returnState),
      leaveState,
      returnTransition,
      returnState,
      error: !leaveState
        ? {
            label: 'Missing bland_widget Redirect Widget',
            error:
              'Your flow must include a widget named \'bland_widget\' of type \'Add TwiML Redirect\'. This widget handles the handoff to external systems.',
            fixSteps: [
              '1. Add a new widget to your flow',
              '2. Name it exactly \'bland_widget\'',
              '3. Set its type to \'Add TwiML Redirect\'',
            ],
          }
        : !returnTransition
            ? {
                label: 'Missing Return Event in bland_widget',
                error:
                'The bland_widget must have a \'return\' event configured to handle calls returning from the external system.',
                fixSteps: [
                  '1. Open your flow in Twilio Studio',
                  '2. Click on the bland_widget',
                  '3. In the widget configuration, locate the \'return\' event',
                  '4. Enable the return event transition',
                ],
              }
            : !returnTransition.next
                ? {
                    label: 'Return Event Not Connected',
                    error:
                  'The \'return\' event in bland_widget must be connected to another widget to handle the call when it returns from the external system. This connection defines the flow\'s behavior after external processing completes.',
                    fixSteps: [
                      '1. Open your flow in Twilio Studio',
                      '2. Locate the bland_widget widget',
                      '3. Find the \'return\' event transition (red arrow)',
                      '4. Connect it to another widget in your flow',
                      '5. Save your flow changes',
                    ],
                  }
                : !returnState
                    ? {
                        label: 'Invalid Return State',
                        error:
                    'The widget connected to the return event could not be found. The return transition must connect to a valid widget in your flow.',
                        fixSteps: [
                          '1. Open your flow in Twilio Studio',
                          '2. Check the widget connected to bland_widget\'s return event',
                          '3. If missing, reconnect the return event to a valid widget',
                          '4. Save your flow changes',
                        ],
                      }
                    : null,
    }
  }

  const getFlowDefinition = async (flowObject) => {
    if (!flowObject?.sid || !flowObject?.encrypted_key) {
      console.error('Invalid flow object:', flowObject)
      toast.error('Invalid flow configuration')
      return
    }

    try {
      setStatusLoading(true)
      const response = await fetch(
        `${config.API_URL}/v1/flows/get_flow?flowSid=${flowObject.sid}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'encrypted_key': flowObject.encrypted_key,
            'Authorization': getApiKey(),
            ...(orgId && { 'x-bland-org-id': orgId }),
          },
        },
      )

      const data = await response.json()
      if (!response.ok) {
        throw new Error(data.error || 'Failed to fetch flow definition')
      }

      console.log('Flow definition:', data)
      const validation = validateFlowDefinition(data.definition)

      // Modified flow states logic
      const flowStates = [
        // First state - bland_widget check
        validation.leaveState
          ? {
              label: `bland_widget (Leave Point)`,
              value: validation.leaveState.name,
              type: validation.leaveState.type,
              properties: validation.leaveState.properties,
              isValid: true,
            }
          : {
              label: 'Missing bland_widget Redirect Widget',
              isValid: false,
              error:
                'Your flow must include a widget named \'bland_widget\' of type \'Add TwiML Redirect\'. This widget handles the handoff to external systems.',
              fixSteps: [
                '1. Add a new widget to your flow',
                '2. Name it exactly \'bland_widget\'',
                '3. Set its type to \'Add TwiML Redirect\'',
              ],
            },
        // Middle state - always shown
        {
          label: 'Bland Handles Call',
          isValid: true,
          isBland: true,
        },
        // Last state - return transition check
        !validation.leaveState
          ? {
              label: 'Waiting for bland_widget configuration',
              isValid: false,
              error:
                'Configure the bland_widget first to enable return flow setup',
            }
          : !validation.returnTransition
              ? {
                  label: 'Missing Return Event in bland_widget',
                  isValid: false,
                  error:
                  'The bland_widget must have a \'return\' event configured to handle calls returning from the external system.',
                  fixSteps: [
                    '1. Open your flow in Twilio Studio',
                    '2. Click on the bland_widget',
                    '3. In the widget configuration, locate the \'return\' event',
                    '4. Enable the return event transition',
                  ],
                }
              : !validation.returnTransition.next
                  ? {
                      label: 'Return Event Not Connected',
                      isValid: false,
                      error:
                    'The \'return\' event in bland_widget must be connected to another widget to handle the call when it returns from the external system. This connection defines the flow\'s behavior after external processing completes.',
                      fixSteps: [
                        '1. Open your flow in Twilio Studio',
                        '2. Locate the bland_widget widget',
                        '3. Find the \'return\' event transition (red arrow)',
                        '4. Connect it to another widget in your flow',
                        '5. Save your flow changes',
                      ],
                    }
                  : validation.returnState
                    ? {
                        label: `${validation.returnState.name} (Return Point)`,
                        value: validation.returnState.name,
                        type: validation.returnState.type,
                        properties: validation.returnState.properties,
                        isValid: true,
                      }
                    : {
                        label: 'Invalid Return Configuration',
                        isValid: false,
                        error:
                      'The widget connected to the return event could not be found. The return transition must connect to a valid widget in your flow.',
                        fixSteps: [
                          '1. Open your flow in Twilio Studio',
                          '2. Check the widget connected to bland_widget\'s return event',
                          '3. If missing, reconnect the return event to a valid widget',
                          '4. Save your flow changes',
                        ],
                      },
      ]

      setStates(flowStates)
    }
    catch (error) {
      console.error('Error fetching flow definition:', error)
      toast.error(error.message || 'Error fetching flow definition')
    }
    finally {
      setStatusLoading(false)
    }
  }

  const redirectToFlow = () => {
    if (selectedFlow) {
      window.open(
        `https://www.twilio.com/console/studio/flows/${selectedFlow.sid}`,
        '_blank',
      )
    }
  }

  const handleFlowSelect = (values) => {
    const selectedFlow = values[0]
    setSelectedFlow(selectedFlow)
    if (selectedFlow) {
      methods.setValue('flowSid', selectedFlow.sid)
      getFlowDefinition(selectedFlow)
    }
    else {
      methods.setValue('flowSid', '')
    }
  }

  const handleSave = () => {
    const formData = methods.getValues()

    // Clean up the redirect variables structure
    const cleanedRedirectVariables
      = formData.redirectVariables[0]?.['Twilio Variables'] || []

    const redirectConfig = {
      flowSid: formData.flowSid,
      flowName: selectedFlow?.friendly_name || '',
      selectedState: formData.selectedState,
      redirectVariables: cleanedRedirectVariables,
      flowObject: selectedFlow,
    }

    // Update the node data using flowContext
    const updatedNodes = elements.nodes.map((node) => {
      if (node.id === id) {
        const newNode = {
          ...node,
          data: {
            ...node.data,
            name,
            twilioFlowRedirect: redirectConfig,
            tag: nodeTag,
          },
        }
        delete newNode.data.text
        delete newNode.data.prompt
        newNode.type = 'Twilio Flow Redirect'
        return newNode
      }
      return node
    })

    // Use triggerUpdate from flowContext
    triggerUpdate(
      {
        nodes: updatedNodes,
        edges: elements.edges,
      },
      false,
    )
    setIsOpen(false)
  }

  useEffect(() => {
    const initializeData = async () => {
      setIsLoading(true)
      await getFlows()
      if (data?.twilioFlowRedirect?.flowSid) {
        methods.setValue('flowSid', data.twilioFlowRedirect.flowSid)
        if (data.twilioFlowRedirect.flowObject) {
          const flowObj = data.twilioFlowRedirect.flowObject
          setSelectedFlow(flowObj)
          methods.setValue('flowSid', flowObj.sid)
          methods.setValue(
            'selectedState',
            data.twilioFlowRedirect.selectedState,
          )
          methods.setValue('redirectVariables', [
            {
              'Twilio Variables': data.twilioFlowRedirect.redirectVariables || [
                { key: '', value: '' },
              ],
            },
          ])
          await getFlowDefinition(flowObj)
        }
        else {
          // Manually entered Flow SID
          setManualFlowSidEntry(true)
          setSelectedFlow(null)
          methods.setValue('redirectVariables', [
            {
              'Twilio Variables': data.twilioFlowRedirect.redirectVariables || [
                { key: '', value: '' },
              ],
            },
          ])
        }
      }
      setIsLoading(false)
    }

    initializeData()
  }, [data])

  const handleRefresh = () => {
    if (selectedFlow?.sid && selectedFlow?.encrypted_key) {
      getFlowDefinition(selectedFlow)
    }
    else {
      toast.error('No valid flow selected')
    }
  }

  return (
    <FormProvider {...methods}>
      {isLoading ? (
        <div className="flex items-center justify-center p-5">
          <Loader2 className="h-5 w-5 animate-spin text-gray-500" />
        </div>
      ) : (
        <div className="space-y-4">
          <div className="border border-gray-200 p-2.5 rounded-md shadow-sm">
            <h3 className="text-base text-gray-900 mb-2.5">Node Name</h3>
            <input
              className="w-full p-1.5 border border-gray-300 rounded text-xs focus:ring-indigo-500 focus:border-indigo-500"
              value={name}
              onChange={e => setName(e.target.value)}
              placeholder="Enter node name"
            />
          </div>

          {flows.length > 0 && (
            <div className="border border-gray-200 p-2.5 rounded-md shadow-sm">
              <h3 className="text-base text-gray-900 mb-2.5">Available Flows</h3>
              <div className="flex items-center gap-2.5 mb-2.5">
                <label className="flex items-center">
                  <input
                    type="radio"
                    name="flowSelectionMethod"
                    value="select"
                    checked={!manualFlowSidEntry}
                    onChange={() => {
                      setManualFlowSidEntry(false)
                      methods.setValue('flowSid', '')
                    }}
                    className="mr-1.5"
                  />
                  Select from list
                </label>
                <label className="flex items-center">
                  <input
                    type="radio"
                    name="flowSelectionMethod"
                    value="manual"
                    checked={manualFlowSidEntry}
                    onChange={() => {
                      setManualFlowSidEntry(true)
                      setSelectedFlow(null)
                      methods.setValue('flowSid', '')
                    }}
                    className="mr-1.5"
                  />
                  Enter Flow SID manually
                </label>
              </div>

              {!manualFlowSidEntry
                ? (
                    <div className="flex items-center gap-2.5">
                      <Select
                        options={flows}
                        values={selectedFlow ? [selectedFlow] : []}
                        labelField="friendly_name"
                        valueField="sid"
                        onChange={handleFlowSelect}
                        placeholder="Select a flow"
                        className="min-w-[200px] relative z-50"
                        itemRenderer={({ item, itemIndex, methods }) => (
                          <div
                            key={itemIndex}
                            onClick={() => methods.addItem(item)}
                            className="p-1.5 cursor-pointer hover:bg-gray-100"
                          >
                            <span>{item.friendly_name}</span>
                            {item.encrypted_key && (
                              <span className="text-gray-500 text-xs ml-1.5">
                                (
                                {item.encrypted_key.slice(0, 4)}
                                ...)
                              </span>
                            )}
                          </div>
                        )}
                        contentRenderer={({ props, state }) => (
                          <div className="p-1.5">
                            {state.values[0]?.friendly_name}
                            {state.values[0]?.encrypted_key && (
                              <span className="text-gray-500 text-xs ml-1.5">
                                (
                                {state.values[0].encrypted_key.slice(0, 4)}
                                ...)
                              </span>
                            )}
                          </div>
                        )}
                      />

                      {selectedFlow && (
                        <button
                          onClick={redirectToFlow}
                          className="text-indigo-600 hover:text-indigo-800 text-xs whitespace-nowrap flex items-center gap-0.5"
                        >
                          Open in Twilio
                          <ArrowRightIcon className="h-2.5 w-2.5" />
                        </button>
                      )}
                    </div>
                  )
                : (
                    <div className="flex items-center gap-2.5">
                      <input
                        type="text"
                        {...methods.register('flowSid')}
                        className="w-full p-1.5 border border-gray-300 rounded text-xs focus:ring-indigo-500 focus:border-indigo-500"
                        placeholder="Enter Flow SID"
                        onChange={() => {
                          setSelectedFlow(null)
                        }}
                      />
                    </div>
                  )}
            </div>
          )}

          {/* Display diagram and validation only when a flow is selected from the list */}
          {!manualFlowSidEntry && selectedFlow && states.length > 0 && (
            <div className="border border-gray-200 p-2.5 rounded-md shadow-sm">
              <div className="flex justify-between items-center mb-2.5">
                <h3 className="text-base text-gray-900">Flow Compatibility</h3>
                {selectedFlow && (
                  <button
                    onClick={handleRefresh}
                    className="flex items-center gap-1.5 text-xs text-gray-600 hover:text-gray-900"
                    disabled={statusLoading}
                  >
                    <RefreshCw
                      className={`h-2.5 w-2.5 ${
                        statusLoading ? 'animate-spin' : ''
                      }`}
                    />
                    {statusLoading ? 'Refreshing...' : 'Refresh Status'}
                  </button>
                )}
              </div>

              {statusLoading
                ? (
                    <div className="flex items-center justify-center p-2.5">
                      <Loader2 className="h-4 w-4 animate-spin text-gray-500" />
                    </div>
                  )
                : (
                    <>
                      <div
                        className="flex items-center gap-1.5 mb-2.5 cursor-pointer"
                        onClick={() =>
                          states.every(state => state.isValid)
                          && setShowDebugInfo(!showDebugInfo)}
                      >
                        {states.every(state => state.isValid)
                          ? (
                              <>
                                <CheckCircleIcon className="h-3 w-3 text-green-500" />
                                <span className="text-sm text-green-600">
                                  Flow is compatible with redirect configuration
                                </span>
                              </>
                            )
                          : (
                              <>
                                <XCircleIcon className="h-3 w-3 text-red-500" />
                                <span className="text-sm text-red-600">
                                  Flow configuration needs attention
                                </span>
                              </>
                            )}
                      </div>
                    </>
                  )}

              {(!states.every(state => state.isValid)
                || (showDebugInfo && !states.every(state => state.isValid))) && (
                <div className="bg-gray-50 border border-gray-200 rounded-md p-2.5 mb-2.5">
                  <h4 className="font-medium text-sm mb-2">
                    Configuration Issues
                  </h4>
                  {states.map(
                    (state, index) =>
                      !state.isValid && (
                        <div key={index} className="mb-2.5 last:mb-0">
                          <div className="font-medium text-red-600 mb-1.5">
                            {state.label}
                          </div>
                          <div className="text-gray-700 mb-1.5">
                            {state.error}
                          </div>
                          {state.fixSteps && (
                            <div className="ml-2.5">
                              <div className="font-medium text-gray-700 mb-0.5">
                                Steps to fix:
                              </div>
                              <ul className="list-disc list-inside space-y-0.5">
                                {state.fixSteps.map((step, stepIndex) => (
                                  <li
                                    key={stepIndex}
                                    className="text-gray-600 text-xs"
                                  >
                                    {step}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          )}
                        </div>
                      ),
                  )}
                </div>
              )}

              <div className="flex items-center gap-2.5 mb-2.5">
                <Tooltip
                  content={
                    states[0].isValid
                      ? 'This widget handles the initial redirect to external systems'
                      : getErrorTooltip(states[0])
                  }
                  side="bottom"
                  style={{ zIndex: 1000000 }}
                >
                  <div className="p-2.5 border rounded bg-gray-50 flex-1 cursor-help">
                    <h4 className="font-medium text-sm">Redirect State</h4>
                    <p className="text-gray-600 mt-0.5">{states[0].label}</p>
                  </div>
                </Tooltip>

                <ArrowRightIcon className="h-3 w-3 text-gray-400" />

                <Tooltip
                  content="External system processes the call"
                  side="bottom"
                  style={{ zIndex: 1000000 }}
                >
                  <div className="p-2.5 border rounded bg-gray-50 border-blue-200 flex-1 cursor-help">
                    <h4 className="font-medium text-sm text-blue-600">
                      Bland Handles Call
                    </h4>
                  </div>
                </Tooltip>

                <ArrowRightIcon className="h-3 w-3 text-gray-400" />

                <Tooltip
                  content={
                    states[2].isValid
                      ? 'This state handles the call when it returns from the external system'
                      : getErrorTooltip(states[2])
                  }
                  side="bottom"
                  style={{ zIndex: 1000000 }}
                >
                  <div className="p-2.5 border rounded bg-gray-50 flex-1 cursor-help">
                    <h4 className="font-medium text-sm">Return To</h4>
                    <p className="text-gray-600 mt-0.5">{states[2].label}</p>
                  </div>
                </Tooltip>
              </div>
            </div>
          )}

          <div className="border border-gray-200 p-2.5 rounded-md shadow-sm">
            <h3 className="text-base text-gray-900 mb-2.5">Redirect Variables</h3>
            <p className="text-gray-600 mb-2.5">
              Variables passed to the flow when redirecting. These will be
              available in your flow's context. The value can contain variables
              of the format &#123;&#123;variable&#125;&#125;, or straight
              values.
            </p>
            <KeyValues
              fieldName="redirectVariables"
              requestIndex={0}
              nestedFieldName="Twilio Variables"
            />
          </div>

          <div className="border border-gray-200 p-2.5 rounded-md shadow-sm overflow-hidden">
            <div className="flex flex-row items-center justify-between">
              <h3 className="text-lg text-gray-900">Node Tag</h3>
              {nodeTag && (
                <div
                  className="px-2 py-0.5 rounded-sm bg-gray-100 flex flex-row items-center gap-1.5 cursor-pointer"
                  onClick={() => {
                    setNodeTag(null)
                  }}
                >
                  <p className="text-2xs font-medium text-gray-600">
                    Remove Tag
                  </p>
                  <Trash2 size={12} className="text-gray-600" />
                </div>
              )}
            </div>

            <div className="mt-2">
              <p>Tag Name</p>
              <input
                className="w-full mt-0.5 p-1.5 border border-gray-300 rounded text-xs focus:ring-indigo-500 focus:border-indigo-500 mb-2"
                value={nodeTag?.name || ''}
                onChange={(event) => {
                  if (nodeTag === null) {
                    setNodeTag({
                      name: event.target.value,
                      color: '#000000',
                    })
                  }
                  else {
                    setNodeTag({
                      ...nodeTag,
                      name: event.target.value,
                    })
                  }
                }}
              />
            </div>

            <div className="mt-1.5">
              <p>Tag Color</p>
              <div className="mt-1.5 flex flex-row items-center justify-between w-full">
                <input
                  className="w-full mr-1.5 mt-0 p-1.5 border border-gray-300 rounded text-xs focus:ring-indigo-500 focus:border-indigo-500 mb-2"
                  value={nodeTag?.color || '#000000'}
                  placeholder="#000000"
                  onChange={(event) => {
                    setNodeTag({
                      ...nodeTag,
                      color: event.target.value,
                    })
                  }}
                />

                <input
                  style={{
                    border: 'none',
                    padding: 0,
                    margin: 0,
                    height: 37,
                    width: 37,
                    background: 'transparent',
                    borderRadius: 0,
                    outline: 'none',
                    cursor: 'pointer',
                    marginTop: -7,
                  }}
                  type="color"
                  value={nodeTag?.color}
                  onChange={(event) => {
                    setNodeTag({
                      ...nodeTag,
                      color: event.target.value,
                    })
                  }}
                />
              </div>
            </div>
          </div>

          <div className="mt-4">
            <button
              onClick={handleSave}
              className="bg-indigo-500 hover:bg-indigo-600 text-white py-1.5 px-2.5 rounded transition-colors"
            >
              Save Configuration
            </button>
          </div>
        </div>
      )}
    </FormProvider>
  )
}

export default RedirectTwilioFlowModal
