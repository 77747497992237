import {
  Pagination,
  PaginationContent,
  PaginationNext,
  PaginationPrevious,
} from '@/components/ui/pagination'
import { Button, Chip, Progress, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow, Tooltip } from '@heroui/react'
import { keepPreviousData, useMutation, useQuery } from '@tanstack/react-query'
import ActionBar from 'components/core/ActionBar'
import { PageTitle } from 'components/core/PageTitle.js'
import { PageWrapper } from 'components/core/PageWrapper.js'
import VoiceGradientReusable from 'components/Dashboard/SendCall/VoiceGradientReusable'
import GradientLoadingAnimation from 'components/Reusables/GradientLoadingAnimation'
import { formatDistanceToNow } from 'date-fns'
import { Check, Copy, ListMinus, OctagonX, Plus, Rows4 } from 'lucide-react'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { $fetch } from 'utils/fetch'

const columns = [
  { name: 'LABEL', uid: 'description' },
  { name: 'BATCH ID', uid: 'id' },
  { name: 'CREATED', uid: 'created_at' },
  { name: 'STATUS', uid: 'batch_status' },
  { name: 'ACTIONS', uid: 'actions' },
]

function CopyIDButton({ batchId }) {
  const [copied, setCopied] = React.useState(false)

  const copyToClipboard = (batchId) => {
    navigator.clipboard.writeText(batchId)
    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    }, 2000)
    toast.success('Batch ID copied to clipboard')
  }
  return (
    <div className="flex gap-2">
      <p className="font-geist-mono text-default-400 truncate">{batchId}</p>
      {copied
        ? (
            <Check size={16} className="text-green-500" />
          )
        : (
            <Copy
              size={16}
              className="text-default-400 cursor-pointer active:opacity-50"
              onClick={(e) => {
                e.preventDefault()
                copyToClipboard(batchId)
              }}
            />
          )}
    </div>
  )
}

const statusMap = {
  initializing: { label: 'Initializing', color: 'default' },
  validating: { label: 'Validating', color: 'warning' },
  dispatching: { label: 'Dispatching', color: 'secondary' },
  in_progress: { label: 'In Progress', color: 'primary' },
  completed: { label: 'Completed', color: 'success' },
  completed_partial: { label: 'Completed Partial', color: 'success' },
  failed: { label: 'Failed', color: 'danger' },
}

export default function Batches() {
  const navigate = useNavigate()
  const [page, setPage] = React.useState(1)
  const rowsPerPage = 15

  const { data: batches, isLoading, refetch } = useQuery({
    queryKey: ['batches'],
    queryFn: async () => {
      const { data } = await $fetch('/v2/batches/list')
      return data
    },
    refetchInterval: 10000,
    placeholderData: keepPreviousData,
  })

  const { data: rateLimits, isLoading: isLoadingRateLimits } = useQuery({
    queryKey: ['rateLimits'],
    queryFn: async () => {
      const { data } = await $fetch(`/user/rateLimits`)
      return data
    },
    refetchInterval: 10000,
  })

  const pages = Math.ceil(batches?.length / rowsPerPage)

  const items = React.useMemo(() => {
    const start = (page - 1) * rowsPerPage
    const end = start + rowsPerPage

    return batches?.slice(start, end)
  }, [page, batches])

  const stopBatch = useMutation({
    mutationFn: batchId => $fetch(`/v2/batches/${batchId}/stop`, {
      method: 'POST',
    }),
    onSuccess: (batchResponse) => {
      toast.success(`Successfully stopped ${batchResponse.num_calls} calls`)
      refetch()
    },
    onError: (error) => {
      console.error(error)
      toast.error('Failed to stop batch')
    },
  })

  const viewCalls = (batchId) => {
    navigate(`/dashboard/call-logs?filter:batch_id:equals=${batchId}`)
  }

  const renderCell = React.useCallback((batch, columnKey) => {
    const cellValue = batch[columnKey]

    switch (columnKey) {
      case 'description':
        return (
          <span className="flex items-center gap-2">
            <VoiceGradientReusable
              name={batch.id}
              size={26}
            />
            <p className="text-sm capitalize font-taurus font-medium">{cellValue ?? 'Untitled Batch'}</p>
          </span>
        )
      case 'id':
        return (
          <CopyIDButton batchId={cellValue} />
        )
      case 'created_at':
        return (
          <div className="flex flex-col">
            <p className="text-bold text-sm capitalize text-default-400">{formatDistanceToNow(new Date(cellValue), { addSuffix: true })}</p>
          </div>
        )
      case 'batch_status': {
        const status = statusMap[cellValue]
        return (
          <Chip color={status?.color} variant="dot" radius="sm" size="sm">
            {status?.label ?? 'Unknown'}
          </Chip>
        )
      }
      case 'actions':
        return (
          <div className="relative flex items-center gap-4 justify-center">
            <Tooltip content="View Call Logs">
              <span className="text-lg text-default-400 cursor-pointer active:opacity-50" onClick={() => viewCalls(batch.id)}>
                <Rows4 size={16} />
              </span>
            </Tooltip>
            <Tooltip color="danger" content="Stop batch">
              <span className="text-lg text-danger cursor-pointer active:opacity-50" onClick={() => stopBatch.mutate(batch.id)}>
                <OctagonX size={16} />
              </span>
            </Tooltip>
          </div>
        )
      default:
        return cellValue
    }
  }, [])

  return (
    <PageWrapper className="bg-gray-50">
      <ActionBar top>
        <div className="flex justify-between items-center w-full">
          <PageTitle>Batch Calls</PageTitle>
          <Button size="sm" variant="solid" radius="none" color="primary" onPress={() => navigate('/dashboard/create-batch')}>
            <Plus size={16} />
            Create Batch Call
          </Button>
        </div>
      </ActionBar>

      <div className="w-full gap-2 flex flex-col mb-12 px-4">
        <h3 className=" text-lg font-medium font-taurus">Rate Limits</h3>
        <div className="lg:flex-row lg:justify-evenly flex flex-col justify-start items-center gap-12">
          <Progress
            label="Hourly limit"
            valueLabel={rateLimits && (<p className="text-xs font-geist-mono">{`${rateLimits?.hourlyCount} / ${rateLimits?.template?.hourly_limit}`}</p>)}
            maxValue={rateLimits?.template?.hourly_limit}
            showValueLabel
            isIndeterminate={isLoadingRateLimits}
            value={rateLimits?.hourlyCount}
            classNames={{
              label: 'font-geist-mono text-xs ',
              indicator: '!duration-1000 bg-gradient-to-r from-green-400 to-red-700',
              track: 'h-2',
            }}
          />
          <Progress
            label="Daily limit"
            valueLabel={rateLimits && (<p className="text-xs font-geist-mono">{`${rateLimits?.dailyCount} / ${rateLimits?.template?.daily_limit}`}</p>)}
            maxValue={rateLimits?.template?.daily_limit}
            showValueLabel
            isIndeterminate={isLoadingRateLimits}
            value={rateLimits?.dailyCount}
            classNames={{
              label: 'font-geist-mono text-xs ',
              indicator: '!duration-1000 bg-gradient-to-r from-green-400 to-red-700',
              track: 'h-2',
            }}
          />
        </div>
      </div>

      {isLoading
        ? (
            <GradientLoadingAnimation
              message="Loading Batches"
              variant="roseGarden"
              rounded="full"
            />
          )
        : !batches?.length
            ? (
                <div className="w-full h-full flex flex-col items-center gap-5 mt-40">
                  <ListMinus size={64} className="text-gray-800" />
                  <h1 className="scroll-m-12 text-3xl tracking-tighter font-semibold">
                    No batches yet.
                  </h1>
                  <p className="text-sm text-gray-600">
                    Create a batch call to get started.
                  </p>
                </div>
              )
            : (
                <div className="flex flex-col gap-4">
                  <Table
                    isStriped
                    radius="none"
                    hideHeader
                    selectionBehavior="replace"
                    selectionMode="multiple"
                    classNames={{
                      tr: 'cursor-pointer hover:bg-primary-50 transition-all duration-100',
                    }}
                    onRowAction={viewCalls}
                  >
                    <TableHeader columns={columns}>
                      {column => (
                        <TableColumn key={column.uid} align={column.uid === 'metrics' ? 'center' : 'start'}>
                          {column.name}
                        </TableColumn>
                      )}
                    </TableHeader>
                    <TableBody items={items}>
                      {item => (
                        <TableRow key={item.id}>
                          {columnKey => <TableCell>{renderCell(item, columnKey)}</TableCell>}
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                  {pages > 1 && (
                    <div className="flex w-full justify-end">
                      <Pagination className="w-fit !mx-0">
                        <PaginationContent>
                          {page > 1 && (
                            <PaginationPrevious
                              onClick={() => setPage(page - 1)}
                              disabled={page === 1}
                              className="cursor-pointer"
                            />
                          )}
                          <p className="text-xs text-gray-500">
                            {(page - 1) * rowsPerPage + 1}
                            {' '}
                            -
                            {' '}
                            {(page - 1) * rowsPerPage + items?.length}
                            {' '}
                            of
                            {' '}
                            {batches?.length}
                          </p>
                          <PaginationNext
                            className="cursor-pointer"
                            onClick={() =>
                              setPage(
                                Math.min(pages, page + 1),
                              )}
                            disabled={page === pages}
                          />
                        </PaginationContent>
                      </Pagination>
                    </div>
                  )}
                </div>
              )}
    </PageWrapper>
  )
}
