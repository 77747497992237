import { ErrorMessage } from '@hookform/error-message'
import { useEffect, useRef, useState } from 'react'
import { useController, useFormContext, useWatch } from 'react-hook-form'
import {
  FiAlertCircle,
  FiCheck,
  FiChevronDown,
  FiSearch,
  FiX,
} from 'react-icons/fi'
import styled from 'styled-components'
import { ErrorText } from './ErrorText'
import Label from './Label'

export default function PhoneNumberSelector({
  fieldName,
  label = 'From Number',
  hideLabel = false,
  description = 'Select a phone number from your purchased or BYOT numbers. If you don\'t select one, a random number will be used.',
  required = false,
  userNumbers = [],
  byotNumbers = [],
  loading = false,
  error = null,
}) {
  const {
    control,
    formState: { errors },
  } = useFormContext()
  const { field } = useController({
    name: fieldName,
    control,
    rules: { required: required ? 'Phone number is required' : false },
  })
  const currentValue = useWatch({
    name: fieldName,
    control,
    defaultValue: '',
  })

  const [open, setOpen] = useState(false)
  const [allNumbers, setAllNumbers] = useState([])
  const [searchTerm, setSearchTerm] = useState('')

  const wrapperRef = useRef(null)

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setOpen(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [wrapperRef])

  useEffect(() => {
    if (!loading && !error) {
      let combinedNumbers = [
        ...userNumbers.map(num => ({
          value: num.phone_number,
          label: `${formatPhoneNumber(num.phone_number)} (Purchased)`,
        })),
        ...byotNumbers.map(num => ({
          value: num.phone_number,
          label: `${formatPhoneNumber(num.phone_number)} (Twilio BYOT)`,
        })),
      ]
      combinedNumbers = combinedNumbers.filter(num => num.value)
      setAllNumbers(combinedNumbers)
    }
  }, [userNumbers, byotNumbers, loading, error])

  const filteredNumbers = allNumbers.filter(number =>
    number.label.toLowerCase().includes(searchTerm.toLowerCase()),
  )

  const handleSearch = (e) => {
    setSearchTerm(e.target.value)
  }

  const clearSearch = () => {
    setSearchTerm('')
  }

  const clearSelection = (e) => {
    e.stopPropagation()
    field.onChange('')
  }

  return (
    <div className="flex flex-col gap-1.5 w-full" ref={wrapperRef}>
      {!hideLabel && <Label>{label}</Label>}
      {description && (
        <p className="text-2xs text-gray-500 font-normal">{description}</p>
      )}
      <SelectWrapper>
        <StyledSelect
          onClick={() => !loading && !error && setOpen(!open)}
          disabled={loading || error}
        >
          {loading
            ? (
                <>
                  <LoadingSpinner />
                  <span>Loading phone numbers...</span>
                </>
              )
            : error
              ? (
                  <ErrorContainer>
                    <FiAlertCircle style={{ marginRight: '8px' }} />
                    <span>Error loading phone numbers</span>
                  </ErrorContainer>
                )
              : (
                  <>
                    <span>
                      {currentValue
                        ? allNumbers.find(number => number.value === currentValue)?.label
                        : 'Select phone number...'}
                    </span>
                    {currentValue
                      ? (
                          <ClearSelectionButton onClick={clearSelection}>
                            <FiX />
                          </ClearSelectionButton>
                        )
                      : (
                          <FiChevronDown />
                        )}
                  </>
                )}
        </StyledSelect>
        {open && !loading && !error && (
          <DropdownList>
            <SearchContainer>
              <SearchIcon />
              <SearchInput
                type="text"
                placeholder="Search phone numbers..."
                value={searchTerm}
                onChange={handleSearch}
              />
              {searchTerm && <ClearIcon onClick={clearSearch} />}
            </SearchContainer>
            <DropdownItemList>
              {filteredNumbers.length === 0
                ? (
                    <DropdownItem>No phone numbers found</DropdownItem>
                  )
                : (
                    filteredNumbers.map(number => (
                      <DropdownItem
                        key={number.value}
                        onClick={() => {
                          field.onChange(number.value)
                          setOpen(false)
                        }}
                      >
                        {currentValue === number.value && (
                          <FiCheck style={{ marginRight: '8px' }} />
                        )}
                        {number.label}
                      </DropdownItem>
                    ))
                  )}
            </DropdownItemList>
          </DropdownList>
        )}
      </SelectWrapper>
      <ErrorMessage
        errors={errors}
        name={fieldName}
        render={({ message }) => <ErrorText message={message} />}
      />
    </div>
  )
}

const SelectWrapper = styled.div`
  position: relative;
  width: 100%;
`

const StyledSelect = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 35px;
  padding: 0 12px;
  border: 1px solid #eeeeee;
  border-radius: 4px;
  background-color: #fbfbfa;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  opacity: ${props => (props.disabled ? 0.6 : 1)};
  &:hover {
    border-color: ${props => (props.disabled ? '#eeeeee' : '#433dff')};
  }
`

const DropdownList = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: white;
  border: 1px solid #eeeeee;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 10;
`

const SearchContainer = styled.div`
  position: relative;
  padding: 8px;
`

const SearchInput = styled.input`
  width: 100%;
  padding: 8px 32px;
  border: 1px solid #eeeeee;
  border-radius: 4px;
  font-size: 14px;
  &:focus {
    outline: none;
    border-color: #433dff;
    box-shadow: 0 0 0 2px rgba(67, 61, 255, 0.2);
  }
`

const SearchIcon = styled(FiSearch)`
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
  color: #888;
`

const ClearIcon = styled(FiX)`
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  color: #888;
  cursor: pointer;
`

const DropdownItemList = styled.ul`
  max-height: 200px;
  overflow-y: auto;
`

const DropdownItem = styled.li`
  padding: 8px 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  &:hover {
    background-color: #f0f0f0;
  }
`

const LoadingSpinner = styled.div`
  border: 2px solid #f3f3f3;
  border-top: 2px solid #433dff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
  margin-right: 10px;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

const ErrorContainer = styled.div`
  display: flex;
  align-items: center;
  color: #ff4d4f;
  padding: 8px 12px;
`

const ClearSelectionButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  color: #888;

  &:hover {
    color: #433dff;
  }
`

// Function to format phone numbers
function formatPhoneNumber(phoneNumber) {
  const cleaned = (`${phoneNumber}`).replace(/\D/g, '')
  const match = cleaned.match(/^(1)?(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    const intlCode = match[1] ? '+1 ' : ''
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
  }
  return phoneNumber // Return original number if it doesn't match the format
}
