import { Card } from '@/components/ui/card'
import { Progress } from '@heroui/react'
import { Tabs } from '@radix-ui/themes'
import { useQuery } from '@tanstack/react-query'
import { motion } from 'framer-motion'
import {
  Angry,
  ClipboardCopy,
  Clock,
  Frown,
  Ghost,
  Meh,
  Smile,
  Sparkles,
} from 'lucide-react'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { $fetch } from 'utils/fetch'
import KeyMarkers from './KeyMarkers'

export default function AnalysisTabs({ call, onKeyMarkersChange, onMarkerClick }) {
  const { data: emotion, isFetching: loadingEmotion } = useQuery({
    queryKey: ['emotion', call?.c_id],
    queryFn: async () => {
      const { data } = await $fetch(`/v1/intelligence/emotions`, {
        method: 'POST',
        body: { callId: call?.c_id },
      })

      return data?.data?.emotion
    },
    enabled: !!call?.recording_url,
    staleTime: Infinity,
  })

  const { data: transcriptAnalysis }
    = useQuery({
      queryKey: ['transcriptAnalysis', call?.c_id],
      queryFn: async () => {
        const mappedTranscript = call.transcript.map(item => ({
          role: item?.user,
          content: item?.text,
        }))

        const b64EncodedTranscripts = window.btoa(
          unescape(encodeURIComponent(JSON.stringify(mappedTranscript))),
        )

        const { analysis } = await $fetch(`/analysis/call`, {
          method: 'POST',
          body: { transcripts: b64EncodedTranscripts },
        })

        return analysis ?? null
      },
      enabled: !!call?.transcript,
      staleTime: Infinity,
    })

  const getHeaderConfig = () => {
    const defaultConfig = {
      title: 'Quick Analysis',
      color: 'black',
      icon: <Sparkles size={16} />,
    }

    if (!emotion)
      return defaultConfig

    const configs = {
      happy: {
        title: 'Positive Conversation',
        color: '#22c55e', // green
        icon: <Smile size={16} />,
      },
      sad: {
        title: 'Negative Conversation',
        color: '#ef4444', // red
        icon: <Frown size={16} />,
      },
      angry: {
        title: 'Heated Conversation',
        color: '#dc2626', // darker red
        icon: <Angry size={16} />,
      },
      neutral: {
        title: 'Neutral Conversation',
        color: '#6b7280', // gray
        icon: <Meh size={16} />,
      },
      fear: {
        title: 'Fearful Conversation',
        color: '#f59e0b', // orange
        icon: <Ghost size={16} />,
      },
    }

    return configs[emotion] || defaultConfig
  }

  const headerConfig = getHeaderConfig()

  // We'll keep track of keyMarkers state here to pass to the KeyMarkers component
  const [keyMarkers, setKeyMarkers] = useState([])

  // Update parent component with key markers
  useEffect(() => {
    if (onKeyMarkersChange && keyMarkers) {
      onKeyMarkersChange(keyMarkers)
    }
  }, [keyMarkers, onKeyMarkersChange])

  const formatTime = (time) => {
    console.log('time', time)
    const minutes = Math.floor(time / 60)
    const seconds = Math.floor(time % 60)
    return `${minutes}:${seconds.toString().padStart(2, '0')}`
  }

  const calculateTimestampFromDate = (markerDate) => {
    if (!call?.started_at || !markerDate)
      return 0

    // Convert both dates to UTC to avoid timezone issues
    const startTime = new Date(call.started_at).getTime()
    const markerTime = new Date(markerDate).getTime()

    const diffInSeconds = (markerTime - startTime) / 1000
    return Math.max(0, Math.abs(diffInSeconds))
  }

  return (
    <Tabs.Root
      defaultValue={call?.pathway_logs ? 'markers' : 'sentiment'}
      className="w-full h-full overflow-y-auto max-h-full"
    >
      <Tabs.List color="gray" className="mb-2 sticky top-0 bg-white !z-50">
        <Tabs.Trigger value="sentiment" style={{ cursor: 'pointer' }}>
          Sentiment
        </Tabs.Trigger>

        {call?.pathway_logs && (
          <Tabs.Trigger value="markers" style={{ cursor: 'pointer' }}>
            Key Markers
          </Tabs.Trigger>
        )}

        {call?.analysis && (
          <Tabs.Trigger value="analysis" style={{ cursor: 'pointer' }}>
            Analysis
          </Tabs.Trigger>
        )}

        {call?.variables && (
          <Tabs.Trigger value="variables" style={{ cursor: 'pointer' }}>
            Variables
          </Tabs.Trigger>
        )}

        {Object.keys(call?.request_data || {}).length > 0 && (
          <Tabs.Trigger value="request" style={{ cursor: 'pointer' }}>
            Request Data
          </Tabs.Trigger>
        )}

        <Tabs.Trigger value="Summary" style={{ cursor: 'pointer' }}>
          Summary
        </Tabs.Trigger>
      </Tabs.List>

      <Tabs.Content value="sentiment">
        <Card className="w-[full] p-4 grid gap-4">
          <div className="flex items-center gap-2.5">
            {loadingEmotion
              ? (
                  <Progress
                    isIndeterminate
                    aria-label="Loading..."
                    color="secondary"
                    size="sm"
                    label="Analyzing Sentiment..."
                    classNames={{
                      indicator: '!duration-1000',
                      label: 'font-bold',
                    }}
                  />
                )
              : (
                  <>
                    <div
                      className="rounded p-2 flex items-center justify-center text-white"
                      style={{
                        backgroundColor: headerConfig?.color,
                      }}
                    >
                      {headerConfig?.icon}
                    </div>

                    <p style={{ fontSize: 14, fontWeight: 'bold' }}>
                      {headerConfig?.title}
                    </p>
                  </>
                )}
          </div>

          <div className="grid gap-2.5">
            <div>
              <p style={{ fontSize: 12, fontWeight: '450' }}>
                Topics Discussed
              </p>
              <ul className="mt-1.5 space-y-0.5 text-xs text-muted-foreground">
                {transcriptAnalysis?.topics?.length > 0
                  ? (
                      transcriptAnalysis?.topics?.map((topic, index) => (
                        <li key={index}>{topic}</li>
                      ))
                    )
                  : (
                      <li>No topics discussed</li>
                    )}
              </ul>
            </div>
            <div>
              <p style={{ fontSize: 12, fontWeight: '450' }}>Issues Raised</p>
              <ul className="mt-1.5 space-y-0.5 text-xs text-muted-foreground">
                {transcriptAnalysis?.caller_issues?.length > 0
                  ? (
                      transcriptAnalysis?.caller_issues?.map((issue, index) => (
                        <li key={index}>{issue}</li>
                      ))
                    )
                  : (
                      <li>No issues raised</li>
                    )}
              </ul>
            </div>
          </div>
        </Card>
      </Tabs.Content>

      {call?.analysis && (
        <Tabs.Content value="analysis">
          {Object.keys(call?.analysis)?.map((key, index) => (
            <div
              key={index}
              style={{
                display: 'flex',
                flexDirection: 'row',
                columnGap: '10px',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
                padding: '10px',
                borderBottom: '1px solid #f3f3f3',
              }}
            >
              <p style={{ fontWeight: '450', fontSize: '11px' }}>{key}</p>
              <p style={{ fontWeight: '400', fontSize: '11px' }}>
                {typeof call?.analysis?.[key] === 'object'
                  ? JSON.stringify(call?.analysis?.[key])
                  : typeof call?.analysis?.[key] === 'boolean'
                    ? call?.analysis?.[key].toString()
                    : call?.analysis?.[key]}
              </p>
            </div>
          ))}
        </Tabs.Content>
      )}

      {call?.variables && (
        <Tabs.Content value="variables">
          {Object.keys(call?.variables ?? {})?.map(
            (key, index) => (
              <div
                key={index}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  columnGap: '10px',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                  padding: '10px',
                  borderBottom: '1px solid #f3f3f3',
                }}
              >
                <p style={{ fontWeight: '450', fontSize: '11px' }}>{key}</p>
                <p style={{ fontWeight: '400', fontSize: '11px' }}>
                  {JSON.stringify((call?.variables)?.[key])}
                </p>
              </div>
            ),
          )}
        </Tabs.Content>
      )}
      {Object.keys(call?.request_data || {}).length > 0 && (
        <Tabs.Content value="request">
          {Object.entries(JSON.parse(call?.request_data || '{}')).map(
            ([key, value]) => (
              <div
                key={key}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                  padding: '10px',
                  borderBottom: '1px solid #f3f3f3',
                }}
              >
                <p style={{ fontWeight: '450', fontSize: '11px' }}>{key}</p>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    columnGap: '5px',
                  }}
                >
                  <p
                    style={{
                      fontWeight: '400',
                      fontSize: '11px',
                      textAlign: 'right',
                    }}
                  >
                    {value
                      ? typeof value === 'string'
                        ? value
                        : JSON.stringify(value)
                      : '-'}
                  </p>
                  {value && (
                    <ClipboardCopy
                      onClick={() => {
                        navigator.clipboard.writeText(
                          typeof value === 'string'
                            ? value
                            : JSON.stringify(value),
                        )
                        toast.success('Copied to clipboard')
                      }}
                      size={10}
                      style={{ cursor: 'pointer' }}
                    />
                  )}
                </div>
              </div>
            ),
          )}
        </Tabs.Content>
      )}

      <Tabs.Content value="markers">
        <KeyMarkers
          call={call}
          onKeyMarkersChange={setKeyMarkers}
          keyMarkers={keyMarkers}
          onMarkerClick={onMarkerClick}
        />
      </Tabs.Content>

      <Tabs.Content value="Summary">
        <motion.p
          className="text-gray-800"
          style={{ fontSize: 18 }}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <p className="text-xs text-gray-800" style={{ fontSize: 16 }}>
            {call?.summary ? call?.summary : 'No summary available.'}
          </p>
        </motion.p>
      </Tabs.Content>
    </Tabs.Root>
  )
}
