import { Dialog, Tab, Transition } from '@headlessui/react'
import { Button } from '@radix-ui/themes'
import axios from 'axios'
import config from 'config'
import { Fragment, useContext, useEffect, useState } from 'react'
import { FiAlertCircle, FiGlobe, FiRefreshCw } from 'react-icons/fi'
import { getAuthToken } from 'utils/funcs/browser/getAuthToken'
import { getOrgId } from 'utils/funcs/browser/getOrgId'
import FlowContext from '../contextFlow'

function GlobalPrompt({ globalPrompt, setGlobalPrompt }) {
  const { exportFlow } = useContext(FlowContext)
  const [isOpen, setIsOpen] = useState(false)
  const [tempPrompt, setTempPrompt] = useState(globalPrompt || '')
  //   const [prompt, setPrompt] = useState(globalPrompt || "");
  const [logs, setLogs] = useState([])
  const [isCompressing, setIsCompressing] = useState(false)
  const [compressionResult, setCompressionResult] = useState(null)
  const [isSaving, setIsSaving] = useState(false)
  const orgId = getOrgId()
  const getPromptStatus = (length) => {
    if (length <= 500)
      return { color: 'bg-green-500', message: 'Good' }
    if (length <= 1000)
      return { color: 'bg-yellow-500', message: 'Warning' }
    return { color: 'bg-red-500', message: 'Critical' }
  }

  const handleCancel = () => {
    setTempPrompt(globalPrompt) // Reset to the original value
    setIsOpen(false)
  }

  useEffect(() => {
    setTempPrompt(globalPrompt)
  }, [globalPrompt])

  const promptStatus = getPromptStatus(prompt.length)

  useEffect(() => {
    if (!isSaving)
      return
    exportFlow()
    setIsSaving(false)
  }, [isSaving])

  const handleSave = async () => {
    setGlobalPrompt(tempPrompt)
    setIsSaving(true)
    setIsOpen(false)
    setLogs([
      ...logs,
      { timestamp: new Date(), action: 'Global prompt updated' },
    ])
  }

  const compressPrompt = async () => {
    setIsCompressing(true)
    try {
      const response = await axios.post(
        `${config.API_URL}/convo_pathway/compress_prompt`,
        { prompt: tempPrompt },
        {
          headers: {
            Authorization: getAuthToken(),
            ...(orgId && { 'x-bland-org-id': orgId }),
          },
        },
      )
      setCompressionResult(response.data.data)
      setTempPrompt(response.data.data.compressed_prompt)
      setLogs([
        ...logs,
        { timestamp: new Date(), action: 'Global prompt compressed' },
      ])
    }
    catch (error) {
      console.error('Error compressing prompt:', error)
      setLogs([
        ...logs,
        { timestamp: new Date(), action: 'Error compressing prompt' },
      ])
    }
    setIsCompressing(false)
  }

  return (
    <>
      <Button
        variant="surface"
        color="blue"
        size="1"
        // className="w-full bg-indigo-600 hover:bg-indigo-700 text-white  py-1.5 px-2.5 rounded-sm shadow transition duration-150 ease-in-out flex items-center justify-center"
        onClick={() => setIsOpen(true)}
        style={{
          cursor: 'pointer',
        }}
      >
        <FiGlobe size={14} />
        <p style={{ fontSize: 14 }}>Global Prompt</p>
      </Button>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={() => setIsOpen(false)}
        >
          <div className="min-h-screen px-2.5 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-6xl p-4 my-5 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-xl">
                <Dialog.Title
                  as="h3"
                  className="text-sm font-medium leading-4 text-gray-900"
                >
                  Global Prompt and Logs
                </Dialog.Title>
                <div className="mt-2.5">
                  <Tab.Group>
                    <Tab.List className="flex p-0.5 space-x-0.5 bg-indigo-900/20 rounded-lg">
                      <Tab
                        className={({ selected }) =>
                          `w-full py-1.5 text-xs leading-3 font-medium text-indigo-700 rounded-md
                          focus:outline-none focus:ring-2 ring-offset-2 ring-offset-indigo-400 ring-white ring-opacity-60
                          ${
    selected
      ? 'bg-white shadow'
      : 'text-indigo-100 hover:bg-white/[0.12] hover:text-white'
    }`}
                      >
                        Global Prompt
                      </Tab>
                      {/* <Tab
                        className={({ selected }) =>
                          `w-full py-1.5 text-xs leading-3 font-medium text-indigo-700 rounded-md
                          focus:outline-none focus:ring-2 ring-offset-2 ring-offset-indigo-400 ring-white ring-opacity-60
                          ${selected
                            ? "bg-white shadow"
                            : "text-indigo-100 hover:bg-white/[0.12] hover:text-white"
                          }`
                        }
                      >
                        Logs
                      </Tab> */}
                    </Tab.List>
                    <Tab.Panels className="mt-1.5">
                      <Tab.Panel>
                        <div className="relative">
                          <textarea
                            value={tempPrompt}
                            onChange={e => setTempPrompt(e.target.value)}
                            className="w-full h-44 p-1.5 border rounded focus:ring-indigo-500 focus:border-indigo-500 resize-y"
                            placeholder="Enter your global prompt here..."
                          />
                          <div className="absolute bottom-0 left-0 right-0 h-1.5 bg-gray-200 rounded-b-md">
                            <div
                              className={`h-full ${promptStatus.color} rounded-b-md transition-all duration-300 ease-in-out`}
                              style={{
                                width: `${Math.min((tempPrompt.length / 1500) * 100, 100)}%`,
                              }}
                            />
                          </div>
                        </div>
                        <div className="mt-1.5 flex items-center justify-between">
                          <div className="flex items-center">
                            <span
                              className={`inline-block w-2 h-2 rounded-full ${promptStatus.color} mr-1.5`}
                            >
                            </span>
                            <span className="text-xs font-medium">
                              {promptStatus.message}
                            </span>
                            <div className="relative ml-1.5 group">
                              <FiAlertCircle className="text-gray-400" />
                              <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 p-1.5 bg-gray-800 text-white text-2xs rounded-sm shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-200 whitespace-nowrap">
                                Having too much content can affect the pathway's
                                ability to follow instructions.
                              </div>
                            </div>
                          </div>
                          <button
                            onClick={compressPrompt}
                            disabled={isCompressing}
                            className={`px-2 py-0.5 text-xs font-medium text-indigo-600 border border-indigo-600 rounded hover:bg-indigo-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${isCompressing ? 'opacity-50 cursor-not-allowed' : ''}`}
                          >
                            {isCompressing
                              ? (
                                  <>
                                    <FiRefreshCw className="inline-block mr-0.5 animate-spin" />
                                    Compressing...
                                  </>
                                )
                              : (
                                  'Compress Prompt'
                                )}
                          </button>
                        </div>
                        {compressionResult && (
                          <div className="mt-2.5 p-2.5 bg-gray-100 rounded-">
                            <h4 className="text-xs font-medium text-gray-900">
                              Compression Result:
                            </h4>
                            <p className="mt-0.5 text-xs text-gray-600">
                              Compression Ratio:
                              {' '}
                              {compressionResult.compression_ratio.toFixed(2)}
                            </p>
                            <p className="mt-0.5 text-xs text-gray-600">
                              Key Elements Preserved:
                            </p>
                            <ul className="list-disc list-inside text-xs text-gray-600">
                              {compressionResult.key_elements_preserved.map(
                                (element, index) => (
                                  <li key={index}>{element}</li>
                                ),
                              )}
                            </ul>
                          </div>
                        )}
                      </Tab.Panel>
                      <Tab.Panel>
                        <div className="h-40 overflow-y-auto">
                          {logs.map((log, index) => (
                            <div
                              key={index}
                              className="mb-1.5 p-1.5 bg-gray-100 rounded-"
                            >
                              <span className="text-xs text-gray-500">
                                {log.timestamp.toLocaleString()}
                              </span>
                              <p className="text-xs">{log.action}</p>
                            </div>
                          ))}
                        </div>
                      </Tab.Panel>
                    </Tab.Panels>
                  </Tab.Group>
                </div>

                <div className="mt-4 flex justify-end space-x-2">
                  <button
                    type="button"
                    className="inline-flex justify-center px-2.5 py-1.5 text-xs font-medium text-gray-700 bg-white border border-gray-300 rounded hover:bg-gray-50 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-indigo-500"
                    onClick={handleCancel}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="inline-flex justify-center px-2.5 py-1.5 text-xs font-medium text-white bg-indigo-600 border border-transparent rounded hover:bg-indigo-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-indigo-500"
                    onClick={handleSave}
                  >
                    Save
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default GlobalPrompt
