import { parsePhoneNumberWithError } from 'libphonenumber-js'
import { Copy, Eye, MessageSquare, MoreHorizontal, Trash2 } from 'lucide-react'
import { Link as RouterLink } from 'react-router-dom'
import { toDtmy } from 'utils/formatting/toDtmy'
import { addToast, Button, ButtonGroup, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger } from '@heroui/react'

/**
 * Creates and returns the columns definition for the SMS conversations table
 * 
 * @param {Object} options - Options for customizing the columns
 * @param {Function} options.toggleRowClick - Function to toggle row selection
 * @param {Function} options.handleDeleteConversation - Function to handle conversation deletion
 * @returns {Array} Array of column definitions for TanStack Table
 */
export function getConversationsColumns({ toggleRowClick, handleDeleteConversation }) {
  return [
    {
      accessorKey: 'user_number',
      header: 'User Number',
      enableSorting: true,
      cell: ({ getValue }) => {
        const value = getValue()
        if (!value || typeof value !== 'string')
          return value ?? '-'
        try {
          return parsePhoneNumberWithError(value)?.formatNational()
        }
        catch (error) {
          console.error(error)
          return value ?? '-'
        }
      },
    },
    {
      accessorKey: 'agent_number',
      header: 'Agent Number',
      enableSorting: true,
      cell: ({ getValue }) => {
        const value = getValue()
        if (!value || typeof value !== 'string')
          return value ?? '-'
        try {
          const formattedNumber = parsePhoneNumberWithError(value)?.formatNational()
          return (
            <RouterLink
              to="/dashboard/sms?tab=numbers"
              className="text-tiny text-gray-500 hover:text-blue-600 underline"
              onClick={e => e.stopPropagation()}
            >
              {formattedNumber}
            </RouterLink>
          )
        }
        catch (error) {
          console.error(error)
          return value ?? '-'
        }
      },
    },
    {
      accessorKey: 'last_message',
      header: 'Last Message',
      enableSorting: false,
      size: 300,
      cell: ({ getValue }) => {
        const value = getValue()
        return (
          <div className="flex items-center gap-2">
            <div className="truncate max-w-md">{value || '-'}</div>
          </div>
        )
      },
    },
    {
      accessorKey: 'message_count',
      header: 'Messages',
      enableSorting: true,
      size: 80,
      cell: ({ getValue }) => {
        return (
          <div className="flex items-center gap-2 text-blue-500 ml-8">
            <p>{getValue() || '0'}</p>
            <MessageSquare size={12} />
          </div>
        )
      },
    },
    {
      accessorKey: 'created_at',
      header: 'Last Updated',
      enableSorting: true,
      cell: ({ getValue }) => {
        const date = getValue()
        return date ? toDtmy(new Date(date)) : '-'
      },
    },
    {
      accessorKey: 'id',
      header: 'Conversation ID',
      size: 120,
      enableSorting: false,
      cell: ({ getValue }) => {
        const conversationId = getValue()
        return conversationId
          ? (
            <Button
      variant="light"
      color="primary"
      disableRipple
      size="sm"
      endContent={<Copy size={12} />}
      onPress={() => {
        navigator.clipboard.writeText(conversationId)
        addToast({
            title: 'Conversation ID copied to clipboard',
            variant: 'success',
        })
      }}
    >
      {conversationId.substring(0, 6)}...
    </Button>
  )
          : '-'
      },
    },
    {
      id: 'actions',
      header: 'Actions',
      size: 100,
      enableSorting: false,
      cell: ({ row }) => {
        const id = row.original.id
        return (
          <ButtonGroup>
            <Button
              variant="light"
              size="sm"
              radius="sm"
              color="secondary"
              isIconOnly
              onPress={(e) => {
                e.stopPropagation()
                toggleRowClick(id)
              }}
            >
              <Eye size={14} className="text-indigo-600" />
            </Button>
            
            <Dropdown>
              <DropdownTrigger>
                <Button
                  variant="light"
                  size="sm"
                  radius="sm"
                  color="default"
                  isIconOnly
                  onPress={(e) => {
                    e.stopPropagation()
                  }}
                >
                  <MoreHorizontal size={14} />
                </Button>
              </DropdownTrigger>
              <DropdownMenu 
                aria-label="Conversation actions"
                onAction={(key) => {
                  if (key === "delete") {
                    handleDeleteConversation(id, { stopPropagation: () => {} })
                  }
                }}
              >
                <DropdownItem 
                  key="delete" 
                  className="text-danger"
                  color="danger"
                  startContent={<Trash2 size={14} />}
                >
                  Delete Conversation
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </ButtonGroup>
        )
      },
    },
  ]
} 