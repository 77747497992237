import { TooltipText } from '_components/Text'
import { CircleCheck, Rocket, Send } from 'lucide-react'
import { useFormContext, useWatch } from 'react-hook-form'

function Badge({ children, variant }) {
  const variants = {
    recommended: 'bg-indigo-100 text-indigo-800 border-indigo-200',
    speed: 'bg-amber-100 text-amber-800 border-amber-200',
  }

  return (
    <span className={`px-1.5 py-px text-2xs font-medium rounded-full border ${variants[variant]}`}>
      {children}
    </span>
  )
}

const modelInfo = [
  {
    name: 'CORE',
    value: 'base',
    icon: <Send className="w-4 h-4" />,
    badge: <Badge variant="recommended">Recommended</Badge>,
    description: 'Best choice for most use cases. Reliable instruction-following, full feature support, and fast.',
  },
  {
    name: 'TURBO',
    value: 'turbo',
    icon: <Rocket className="w-4 h-4" />,
    badge: <Badge variant="speed">Speed-optimized</Badge>,
    description: '3x faster responses but less reliable for complex tasks. Use only when speed is critical.',
  },
]

const longText = (
  <span>
    Select a model to use for your call.
    {' '}
    <br />
    <br />
    <strong>CORE (Recommended)</strong>
    <br />
    • Most reliable for following instructions and procedures
    <br />
    • Full feature support including Custom Tools
    <br />
    • Good balance of speed and accuracy
    <br />
    <br />
    <strong>TURBO (Speed-focused)</strong>
    <br />
    • 3-4x faster response times
    <br />
    • Less reliable for complex tasks
    <br />
    • Limited feature support (no Custom Tools)
    <br />
    • Best used only when speed is the top priority
  </span>
)

export default function Model({ fieldName }) {
  const { setValue } = useFormContext()
  const selectedModel = useWatch({
    name: fieldName,
    control: useFormContext().control,
  })

  const handleModelSelect = (value) => {
    setValue(fieldName, value)
  }

  return (
    <div className="w-full">
      <div className="flex items-center mb-2">
        <TooltipText tooltipClassName="w-[300px] h-full" tooltip={longText}>
          SELECT MODEL
        </TooltipText>
      </div>
      <div className="space-y-2">
        {modelInfo.map(model => (
          <div
            key={model.value}
            className={`
              p-2.5 border-2 rounded-md cursor-pointer transition-all duration-200 flex items-center
              ${
          selectedModel === model.value
            ? 'border-[#433DFF] bg-[#433DFF]/5 shadow-md'
            : 'border-gray-200 hover:border-[#433DFF]/50 hover:bg-[#433DFF]/1'
          }
            `}
            onClick={() => handleModelSelect(model.value)}
          >
            <div
              className={`
                text-[#433DFF] mr-4 transition-opacity duration-200
                ${selectedModel === model.value ? 'opacity-100' : 'opacity-50 group-hover:opacity-75'}
              `}
            >
              {model.icon}
            </div>
            <div className="flex-grow">
              <div className="flex items-center gap-1.5">
                <h3
                  className={`
                    text-xs font-semibold transition-colors duration-200
                    ${selectedModel === model.value ? 'text-[#433DFF]' : 'text-gray-800'}
                  `}
                  style={{ fontSize: 15 }}
                >
                  {model.name}
                </h3>
                {model.badge}
              </div>
              <p className="text-xs text-gray-600 mt-0.5">{model.description}</p>
            </div>
            {selectedModel === model.value && (
              <div className="ml-2 animate-fadeIn">
                <CircleCheck className="w-3 h-3 text-[#433DFF]" />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}
