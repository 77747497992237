import React from 'react';

/**
 * Utility class for comparing pathways and identifying conflicts
 */
class PathwayComparisonEngine {
  /**
   * Compare two pathways and identify conflicts
   * @param {Object} mainPathway - The main production pathway
   * @param {Object} smsPathway - The SMS pathway
   * @returns {Array} - Array of conflict objects
   */
  static comparePathways(mainPathway, smsPathway) {
    if (!mainPathway || !smsPathway) {
      return [];
    }

    const conflicts = [];
    
    // Compare global prompts first
    this.compareGlobalPrompts(mainPathway, smsPathway, conflicts);
    
    // Then, compare nodes
    this.compareNodes(mainPathway, smsPathway, conflicts);
    
    // Then, compare edges
    this.compareEdges(mainPathway, smsPathway, conflicts);
    
    return conflicts;
  }
  
  /**
   * Compare global prompts between two pathways
   * @param {Object} mainPathway - The main production pathway
   * @param {Object} smsPathway - The SMS pathway
   * @param {Array} conflicts - The conflicts array to append to
   */
  static compareGlobalPrompts(mainPathway, smsPathway, conflicts) {
    const mainGlobalPrompt = this.extractGlobalPrompt(mainPathway);
    const smsGlobalPrompt = this.extractGlobalPrompt(smsPathway);
    
    // Only add a conflict if both global prompts exist and they're different
    if (mainGlobalPrompt !== null && smsGlobalPrompt !== null && mainGlobalPrompt !== smsGlobalPrompt) {
      conflicts.push({
        id: 'global-prompt',
        type: 'globalPrompt',
        name: 'Global Prompt',
        status: 'conflict',
        productionVersion: { 
          globalPrompt: mainGlobalPrompt,
          globalConfig: mainPathway.globalConfig,
          nodes: mainPathway.nodes 
        },
        smsVersion: { 
          globalPrompt: smsGlobalPrompt,
          globalConfig: smsPathway.globalConfig,
          nodes: smsPathway.nodes
        }
      });
    } 
    // Or if one has a global prompt and the other doesn't
    else if ((mainGlobalPrompt === null && smsGlobalPrompt !== null) || 
             (mainGlobalPrompt !== null && smsGlobalPrompt === null)) {
      conflicts.push({
        id: 'global-prompt',
        type: 'globalPrompt',
        name: 'Global Prompt',
        status: 'conflict',
        productionVersion: { 
          globalPrompt: mainGlobalPrompt,
          globalConfig: mainPathway.globalConfig,
          nodes: mainPathway.nodes 
        },
        smsVersion: { 
          globalPrompt: smsGlobalPrompt,
          globalConfig: smsPathway.globalConfig,
          nodes: smsPathway.nodes
        }
      });
    }
  }
  
  /**
   * Extract the global prompt from a pathway
   * @param {Object} pathway - The pathway to extract from
   * @returns {String|null} - The global prompt or null if not found
   */
  static extractGlobalPrompt(pathway) {
    // Method 1: Check if pathway has globalConfig.globalPrompt
    if (pathway.globalConfig && typeof pathway.globalConfig.globalPrompt === 'string') {
      return pathway.globalConfig.globalPrompt;
    }
    
    // Method 2: Get from the first node's data.globalPrompt
    const nodes = pathway.nodes || [];
    if (nodes.length > 0) {
      // Check each node for data.globalPrompt
      for (const node of nodes) {
        if (node.data && typeof node.data.globalPrompt === 'string') {
          return node.data.globalPrompt;
        }
        
        // Check if this node has globalConfig.globalPrompt (e.g., a node dedicated to global config)
        if (node.globalConfig && typeof node.globalConfig.globalPrompt === 'string') {
          return node.globalConfig.globalPrompt;
        }
      }
    }
    
    // Not found
    return null;
  }
  
  /**
   * Compare nodes between two pathways
   * @param {Object} mainPathway - The main production pathway
   * @param {Object} smsPathway - The SMS pathway
   * @param {Array} conflicts - The conflicts array to append to
   */
  static compareNodes(mainPathway, smsPathway, conflicts) {
    // Filter out nodes that only contain globalConfig
    const filterValidNodes = (nodes) => {
      return nodes.filter(node => {
        // Skip nodes that only contain globalConfig and no other properties
        if (node.globalConfig && 
            Object.keys(node).length === 1 && 
            Object.keys(node)[0] === 'globalConfig') {
          return false;
        }
        return true;
      });
    };
    
    const mainNodes = filterValidNodes(mainPathway.nodes || []);
    const smsNodes = filterValidNodes(smsPathway.nodes || []);
    
    // Create lookup maps for faster access
    const mainNodesMap = this.createIdMap(mainNodes);
    const smsNodesMap = this.createIdMap(smsNodes);
    
    // Check for nodes that exist in both pathways (potential conflicts)
    for (const nodeId in mainNodesMap) {
      const mainNode = mainNodesMap[nodeId];
      const smsNode = smsNodesMap[nodeId];
      
      if (smsNode) {
        // Node exists in both pathways - check for conflicts
        const hasConflict = this.hasNodeConflict(mainNode, smsNode);
        
        conflicts.push({
          id: `node-${nodeId}`,
          type: 'node',
          name: mainNode.name || smsNode.name || nodeId,
          status: hasConflict ? 'conflict' : 'matched',
          productionVersion: mainNode,
          smsVersion: smsNode
        });
        
        // Remove from sms map to track what's left
        delete smsNodesMap[nodeId];
      } else {
        // Node exists only in main pathway
        conflicts.push({
          id: `node-${nodeId}`,
          type: 'node',
          name: mainNode.name || nodeId,
          status: 'production-only',
          productionVersion: mainNode,
          smsVersion: null
        });
      }
    }
    
    // Add remaining SMS-only nodes
    for (const nodeId in smsNodesMap) {
      const smsNode = smsNodesMap[nodeId];
      
      conflicts.push({
        id: `node-${nodeId}`,
        type: 'node',
        name: smsNode.name || nodeId,
        status: 'sms-only',
        productionVersion: null,
        smsVersion: smsNode
      });
    }
  }
  
  /**
   * Compare edges between two pathways
   * @param {Object} mainPathway - The main production pathway
   * @param {Object} smsPathway - The SMS pathway
   * @param {Array} conflicts - The conflicts array to append to
   */
  static compareEdges(mainPathway, smsPathway, conflicts) {
    const mainEdges = mainPathway.edges || [];
    const smsEdges = smsPathway.edges || [];
    
    // Create lookup maps for faster access
    const mainEdgesMap = this.createEdgeMap(mainEdges);
    const smsEdgesMap = this.createEdgeMap(smsEdges);
    
    // Check for edges that exist in both pathways (potential conflicts)
    for (const edgeKey in mainEdgesMap) {
      const mainEdge = mainEdgesMap[edgeKey];
      const smsEdge = smsEdgesMap[edgeKey];
      
      if (smsEdge) {
        // Edge exists in both pathways - check for conflicts
        const hasConflict = this.hasEdgeConflict(mainEdge, smsEdge);
        
        conflicts.push({
          id: `edge-${mainEdge.id || `${mainEdge.source}-${mainEdge.target}`}`,
          type: 'edge',
          name: `${mainEdge.source} → ${mainEdge.target}`,
          status: hasConflict ? 'conflict' : 'matched',
          productionVersion: mainEdge,
          smsVersion: smsEdge
        });
        
        // Remove from sms map to track what's left
        delete smsEdgesMap[edgeKey];
      } else {
        // Edge exists only in main pathway
        conflicts.push({
          id: `edge-${mainEdge.id || `${mainEdge.source}-${mainEdge.target}`}`,
          type: 'edge',
          name: `${mainEdge.source} → ${mainEdge.target}`,
          status: 'production-only',
          productionVersion: mainEdge,
          smsVersion: null
        });
      }
    }
    
    // Add remaining SMS-only edges
    for (const edgeKey in smsEdgesMap) {
      const smsEdge = smsEdgesMap[edgeKey];
      
      conflicts.push({
        id: `edge-${smsEdge.id || `${smsEdge.source}-${smsEdge.target}`}`,
        type: 'edge',
        name: `${smsEdge.source} → ${smsEdge.target}`,
        status: 'sms-only',
        productionVersion: null,
        smsVersion: smsEdge
      });
    }
  }
  
  /**
   * Check if two nodes have conflicts in their content
   * @param {Object} mainNode - Node from main pathway
   * @param {Object} smsNode - Node from SMS pathway
   * @returns {Boolean} - True if there's a conflict
   */
  static hasNodeConflict(mainNode, smsNode) {
    // Check type field first
    if (mainNode.type !== smsNode.type) {
      return true;
    }
    
    // Check if either node has a data object
    const mainHasData = mainNode.data && typeof mainNode.data === 'object';
    const smsHasData = smsNode.data && typeof smsNode.data === 'object';
    
    // If only one has data, it's a conflict
    if (mainHasData !== smsHasData) {
      return true;
    }
    
    // If neither has data, no conflict
    if (!mainHasData && !smsHasData) {
      return false;
    }
    
    // Get all unique keys from both data objects, excluding globalPrompt
    const mainKeys = Object.keys(mainNode.data || {}).filter(key => key !== 'globalPrompt');
    const smsKeys = Object.keys(smsNode.data || {}).filter(key => key !== 'globalPrompt');
    const allKeys = new Set([...mainKeys, ...smsKeys]);
    
    // Check each key in the data objects for differences
    for (const key of allKeys) {
      const mainValue = mainNode.data[key];
      const smsValue = smsNode.data[key];
      
      // If key exists in only one object, it's a conflict
      if (!mainNode.data.hasOwnProperty(key) || !smsNode.data.hasOwnProperty(key)) {
        return true;
      }
      
      // Handle nested objects recursively
      if (typeof mainValue === 'object' && mainValue !== null && 
          typeof smsValue === 'object' && smsValue !== null) {
        // Skip array comparison for now - arrays are considered different
        if (!Array.isArray(mainValue) && !Array.isArray(smsValue)) {
          const nestedKeysConflict = this.hasNestedObjectConflict(mainValue, smsValue);
          if (nestedKeysConflict) {
            return true;
          }
          continue;
        }
      }
      
      // Direct comparison for primitive values
      if (JSON.stringify(mainValue) !== JSON.stringify(smsValue)) {
        return true;
      }
    }
    
    return false;
  }
  
  /**
   * Helper method to check if nested objects have conflicts
   * @param {Object} obj1 - First object
   * @param {Object} obj2 - Second object
   * @returns {Boolean} - True if there's a conflict
   */
  static hasNestedObjectConflict(obj1, obj2) {
    // Filter out globalPrompt keys from both objects
    const obj1Keys = Object.keys(obj1).filter(key => key !== 'globalPrompt');
    const obj2Keys = Object.keys(obj2).filter(key => key !== 'globalPrompt');
    const nestedKeys = new Set([...obj1Keys, ...obj2Keys]);
    
    for (const nestedKey of nestedKeys) {
      if (!obj1.hasOwnProperty(nestedKey) || !obj2.hasOwnProperty(nestedKey)) {
        return true;
      }
      
      const val1 = obj1[nestedKey];
      const val2 = obj2[nestedKey];
      
      // Recursive check for nested objects
      if (typeof val1 === 'object' && val1 !== null && 
          typeof val2 === 'object' && val2 !== null &&
          !Array.isArray(val1) && !Array.isArray(val2)) {
        if (this.hasNestedObjectConflict(val1, val2)) {
          return true;
        }
      } else if (JSON.stringify(val1) !== JSON.stringify(val2)) {
        return true;
      }
    }
    
    return false;
  }
  
  /**
   * Check if two edges have conflicts in their content
   * @param {Object} mainEdge - Edge from main pathway
   * @param {Object} smsEdge - Edge from SMS pathway
   * @returns {Boolean} - True if there's a conflict
   */
  static hasEdgeConflict(mainEdge, smsEdge) {
    // Compare type first
    if (mainEdge.type !== smsEdge.type) {
      return true;
    }
    
    // Compare source and target
    if (mainEdge.source !== smsEdge.source || mainEdge.target !== smsEdge.target) {
      return true;
    }
    
    // Compare condition if present
    if (mainEdge.condition !== smsEdge.condition) {
      return true;
    }
    
    // Check if either edge has a data object
    const mainHasData = mainEdge.data && typeof mainEdge.data === 'object';
    const smsHasData = smsEdge.data && typeof smsEdge.data === 'object';
    
    // If only one has data, it's a conflict
    if (mainHasData !== smsHasData) {
      return true;
    }
    
    // If both have data, compare data properties
    if (mainHasData && smsHasData) {
      // Get all unique keys from both data objects
      const mainKeys = Object.keys(mainEdge.data || {});
      const smsKeys = Object.keys(smsEdge.data || {});
      const allKeys = new Set([...mainKeys, ...smsKeys]);
      
      // Check each key in the data objects for differences
      for (const key of allKeys) {
        const mainValue = mainEdge.data[key];
        const smsValue = smsEdge.data[key];
        
        // If key exists in only one object, it's a conflict
        if (!mainEdge.data.hasOwnProperty(key) || !smsEdge.data.hasOwnProperty(key)) {
          return true;
        }
        
        // Handle nested objects recursively
        if (typeof mainValue === 'object' && mainValue !== null && 
            typeof smsValue === 'object' && smsValue !== null) {
          // Skip array comparison for now - arrays are considered different
          if (!Array.isArray(mainValue) && !Array.isArray(smsValue)) {
            const nestedKeysConflict = this.hasNestedObjectConflict(mainValue, smsValue);
            if (nestedKeysConflict) {
              return true;
            }
            continue;
          }
        }
        
        // Direct comparison for primitive values
        if (JSON.stringify(mainValue) !== JSON.stringify(smsValue)) {
          return true;
        }
      }
    }
    
    return false;
  }
  
  /**
   * Create a map of objects by ID for faster lookup
   * @param {Array} items - Array of objects with id property
   * @returns {Object} - Map of objects keyed by id
   */
  static createIdMap(items) {
    const map = {};
    items.forEach(item => {
      // Skip items without an id
      if (!item.id) return;
      map[item.id] = item;
    });
    return map;
  }
  
  /**
   * Create a map of edges by source-target for faster lookup
   * @param {Array} edges - Array of edge objects
   * @returns {Object} - Map of edges keyed by source-target
   */
  static createEdgeMap(edges) {
    const map = {};
    edges.forEach(edge => {
      const key = `${edge.source}-${edge.target}`;
      map[key] = edge;
    });
    return map;
  }
}

export default PathwayComparisonEngine; 