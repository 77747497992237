import html2canvas from 'html2canvas'
import { jsPDF } from 'jspdf'
import { $fetch } from 'utils/fetch'

export async function purchaseCredits(amount, saveDetails) {
  try {
    const response = await $fetch(`/pay/payment`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        option: amount,
        saveDetails: saveDetails || true,
      }),
    })

    return {
      needsCard: !response?.data?.paymentMethodId,
      clientSecret: response?.data?.clientSecret,
    }
  }
  catch (error) {
    throw error
  }
}

export async function getCreditPurchases() {
  try {
    const response = await $fetch(`/pay/purchases`, {
      method: 'POST',
    })

    return (
      response?.creditPurchases?.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at),
      ) ?? null
    )
  }
  catch (err) {
    console.error('ERROR TRYING TO GET CREDIT PURCHASES')
    return null
  }
}

export async function generateInvoicePDF(purchase, customer) {
  const content = `
    <html id="invoice">
       <head>
          <meta charset="utf-8" />
          <title>BLAND INVOICE</title>
    
          <style>
             .invoice-box {
                width: 794px;
                margin: auto;
                padding: 30px;
                border: 1px solid #eee;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
                font-size: 16px;
                line-height: 24px;
                font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
                color: #555;
             }
    
             .invoice-box table {
                width: 100%;
                line-height: inherit;
                text-align: left;
             }
    
             .invoice-box table td {
                padding: 5px;
                vertical-align: top;
             }
    
             .invoice-box table tr td:nth-child(2) {
                text-align: right;
             }
    
             .invoice-box table tr.top table td {
                padding-bottom: 20px;
             }
    
             .invoice-box table tr.top table td.title {
                font-size: 45px;
                line-height: 45px;
                color: #333;
             }
    
             .invoice-box table tr.information table td {
                padding-bottom: 40px;
             }
    
             .invoice-box table tr.heading td {
                background: #eee;
                border-bottom: 1px solid #ddd;
                font-weight: bold;
             }
    
             .invoice-box table tr.details td {
                padding-bottom: 20px;
             }
    
             .invoice-box table tr.item td {
                border-bottom: 1px solid #eee;
             }
    
             .invoice-box table tr.item.last td {
                border-bottom: none;
             }
    
             .invoice-box table tr.total td:nth-child(2) {
                border-top: 2px solid #eee;
                font-weight: bold;
             }
 
    
             /** RTL **/
             .invoice-box.rtl {
                direction: rtl;
                font-family: Tahoma, 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
             }
    
             .invoice-box.rtl table {
                text-align: right;
             }
    
             .invoice-box.rtl table tr td:nth-child(2) {
                text-align: left;
             }
          </style>
       </head>
    
       <body>
          <div class="invoice-box">
             <table cellpadding="0" cellspacing="0">
                <tr class="top">
                   <td colspan="2">
                      <table>
                         <tr>      
                            <td>
                               <h1><b>Credit Invoice: #${purchase?.id}</b></h1><br />
                               Created: ${new Date(purchase?.created_at)?.toDateString()}<br />
                               Status: Paid
                            </td>
                         </tr>
                      </table>
                   </td>
                </tr>
    
                <tr class="information">
                   <td colspan="2">
                      <table>
                         <tr>
                            <td>
                               <b>From</b><br />
                               Bland AI<br />
                               292 Ivy St,<br />
                               San Francisco, CA 94102
                            </td>
    
                            <td>
                               <b>Bill to</b><br />
                               ${customer?.name}<br />
                               ${customer?.email ? `${customer?.email}<br />` : ''}
                               ${customer?.phone ? `${customer?.phone}<br />` : ''}
                               ${customer?.address?.line1 ? `${customer?.address?.line1}<br />` : ''}
                               ${customer?.address?.line1 && customer?.address?.line2 ? `${customer?.address?.line2}<br />` : ''}
                               ${customer?.address?.line1 ? `${customer?.address?.city}, ${customer?.address?.state} ${customer?.address?.postal_code}<br />` : ''}
                               ${customer?.address?.line1 ? `${customer?.address?.country}` : ''}
                               ${customer?.tax_id?.value ? `Tax ID: ${customer?.tax_id?.value}` : ''}
                            </td>
                         </tr>
                      </table>
                   </td>
                </tr>
    
                <tr class="heading">
                   <td>Item</td>
    
                   <td>Price</td>
                </tr>
    
                <tr class="item last">
                   <td>Credit Top Up (${purchase?.amount?.toFixed(0)} credits)</td>
    
                   <td>${purchase?.amount?.toFixed(2)}</td>
                </tr>
    
                <tr class="total">
                   <td></td>
    
                   <td>Total: $${purchase?.amount?.toFixed(2)}</td>
                </tr>
             </table>
          </div>
       </body>
    </html>`

  const iframe = document.createElement('iframe')
  document.body.appendChild(iframe)
  const iframeDocument = iframe.contentWindow.document

  iframeDocument.open()
  iframeDocument.write(content)
  iframeDocument.close()

  // // Wait for iframe content to fully load
  // await new Promise(resolve => iframe.onload = resolve);

  // Use html2canvas to capture the HTML content inside the iframe
  const canvas = await html2canvas(iframeDocument.body)

  const imgData = canvas.toDataURL('image/png')
  const pdf = new jsPDF()

  // Adjust the height and width of the PDF to match the captured content
  const pdfWidth = pdf.internal.pageSize.getWidth()
  const pdfHeight = (canvas.height * pdfWidth) / canvas.width

  pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight)
  pdf.save('invoice.pdf')

  // Remove the iframe
  document.body.removeChild(iframe)
}
