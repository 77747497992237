import { useEffect, useState } from 'react'
import { formatDuration } from '../utils/formatCallDuration'

export function CallRow({ call, onCopy, onTranscriptView, isActive }) {
  const [currentDuration, setCurrentDuration] = useState(
    call.status === 'IN_PROGRESS' ? formatDuration(call.timestamp) : '0:00',
  )

  useEffect(() => {
    let intervalId

    if (call.status === 'IN_PROGRESS') {
      intervalId = setInterval(() => {
        setCurrentDuration(formatDuration(call.timestamp))
      }, 1000)
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId)
      }
    }
  }, [call.status, call.timestamp])

  return (
    <tr
      className={`border-b border-gray-100 transition-colors duration-200 
      ${isActive ? 'bg-indigo-50/80' : 'hover:bg-gray-50'}`}
    >
      <td className="px-4 py-2.5 text-xs font-medium text-gray-800 flex items-center space-x-1.5">
        <span title={call.call_id}>{`${call.call_id.slice(0, 8)}...`}</span>
        <button
          onClick={() => onCopy(call.call_id)}
          className="text-gray-500 hover:text-gray-800"
        >
          <svg
            className="w-2.5 h-2.5"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"
            />
          </svg>
        </button>
      </td>
      <td className="px-4 py-2.5 text-xs">
        <span
          className={`px-1.5 py-0.5 rounded-full text-2xs font-semibold ${
            call.status === 'IN_PROGRESS'
              ? 'bg-indigo-100 text-indigo-800'
              : call.status === 'QUEUED'
                ? 'bg-yellow-100 text-yellow-800'
                : call.status === 'COMPLETE'
                  ? 'bg-green-100 text-green-800'
                  : 'bg-gray-100 text-gray-800'
          }`}
        >
          {call.status}
        </span>
      </td>
      <td className="px-4 py-2.5 text-xs text-gray-600">{currentDuration}</td>
      <td className="px-4 py-2.5 text-xs text-gray-600">{call.from}</td>
      <td className="px-4 py-2.5 text-xs text-gray-600">{call.to}</td>
      <td className="px-4 py-2.5 text-xs text-gray-600">
        {call.objective
          ? (
              <span title={call.objective}>
                {call.objective.length > 50
                  ? `${call.objective.slice(0, 50)}...`
                  : call.objective}
              </span>
            )
          : (
              'N/A'
            )}
      </td>
      <td className="px-4 py-2.5 text-xs">
        <button
          onClick={() => onTranscriptView(call.call_id)}
          className="text-blue-600 hover:text-blue-800"
        >
          View Transcript
        </button>
      </td>
    </tr>
  )
}

CallRow.displayName = 'CallRow'
