import { ButtonGroup, Input } from '@heroui/react'
import { Controller } from 'react-hook-form'
import MultiInput from './MultiInput'

const DEFAULT_VALUE_COMPONENT = {
  component: Input,
  props: {
    variant: 'bordered',
    radius: 'sm',
    placeholder: 'Value',
  },
}

const DEFAULT_KEY_COMPONENT = {
  component: Input,
  props: {
    variant: 'bordered',
    radius: 'sm',
    placeholder: 'Key',
  },
}

const DEFAULT_VALUE = {
  key: '',
  value: '',
}

export default function ObjectInput({
  name,
  valueComponent = DEFAULT_VALUE_COMPONENT,
  keyComponent = DEFAULT_KEY_COMPONENT,
  defaultValue = DEFAULT_VALUE,
  ...props
}) {
  const KeyComponent = keyComponent.component
  const ValueComponent = valueComponent.component

  return (
    <MultiInput
      name={name}
      defaultValue={defaultValue}
      {...props}
    >
      {({ index }) => (
        <ButtonGroup className="items-end gap-2 w-full">
          {/* Key input */}
          <Controller
            name={`${name}.${index}.key`}
            render={({ field: keyField }) => (
              <KeyComponent
                {...keyField}
                {...keyComponent.props}
              />
            )}
          />

          {/* Value input */}
          <Controller
            name={`${name}.${index}.value`}
            render={({ field: valueField }) => (
              <ValueComponent
                {...valueField}
                {...valueComponent.props}
              />
            )}
          />
        </ButtonGroup>
      )}
    </MultiInput>
  )
}
