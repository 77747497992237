import { CardDescription, CardTitle } from '@/components/ui/card'
import { Card } from '@radix-ui/themes'
import {
  AlertCircle,
  AlertTriangle,
  ArrowRight,
  ChevronLeft,
  ChevronRight,
} from 'lucide-react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

export function ErrorLogTable({ logs = [], logsPerPage = 5, chartRange }) {
  const [currentPage, setCurrentPage] = useState(1)
  const navigate = useNavigate()
  const totalPages = Math.ceil(logs.length / logsPerPage)
  const getStatusColor = (level) => {
    switch (level.toLowerCase()) {
      case 'error':
        return 'text-red-500'
      case 'warn':
        return 'text-yellow-500'
      default:
        return 'text-gray-500'
    }
  }

  const getStatusIcon = (level) => {
    switch (level.toLowerCase()) {
      case 'error':
        return <AlertCircle className="w-2.5 h-2.5" />
      case 'warn':
        return <AlertTriangle className="w-2.5 h-2.5" />
      default:
        return null
    }
  }

  const paginatedLogs = logs.slice(
    (currentPage - 1) * logsPerPage,
    currentPage * logsPerPage,
  )

  return (
    <Card size="1" className="w-full">
      <CardTitle style={{}}>Error Log</CardTitle>
      <CardDescription style={{ marginTop: 5, fontSize: 12 }}>
        {chartRange}
      </CardDescription>

      <div className="overflow-x-auto mt-6">
        <table className="min-w-full">
          <thead>
            <tr className="text-left text-gray-500 text-xs">
              <th className="py-1.5 px-2">Call ID</th>
              <th className="py-1.5 px-2">Level</th>
              <th className="py-1.5 px-2">Time</th>
              <th className="py-1.5 px-2">Message</th>
              <th className="py-1.5 px-2"></th>
            </tr>
          </thead>
          <tbody className="text-xs">
            {paginatedLogs.map(log => (
              <tr key={log.entity_id} className="border-t border-gray-100">
                <td className="py-1.5 px-2 font-medium">
                  {log?.entity_id || '-'}
                </td>
                <td className="py-1.5 px-2">
                  <span
                    className={`flex items-center ${getStatusColor(log.log_level)}`}
                  >
                    {getStatusIcon(log.log_level)}
                    <span className="ml-0.5 capitalize">{log.log_level}</span>
                  </span>
                </td>
                <td className="py-1.5 px-2 text-gray-500">
                  {new Date(log.created_at).toLocaleString('en-us')}
                </td>
                <td
                  className="py-1.5 px-2 text-gray-700"
                  style={{ fontSize: 12 }}
                >
                  {log.message}
                </td>
                <td className="py-1.5 px-2">
                  <button
                    onClick={() =>
                      console.log(`Viewing details for: ${log.entity_id}`)}
                    className="text-blue-500 hover:text-blue-700"
                  >
                    <ArrowRight
                      onClick={() => {
                        navigate(
                          `//call-logs&currPage=1&ref_c_id=${log.entity_id}`,
                        )
                      }}
                      className="w-2.5 h-2.5"
                    />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="mt-2.5 flex items-center justify-between">
        <span className="text-xs text-gray-700">
          Showing
          {' '}
          {(currentPage - 1) * logsPerPage + 1}
          {' '}
          to
          {' '}
          {Math.min(currentPage * logsPerPage, logs.length)}
          {' '}
          of
          {logs.length}
          {' '}
          entries
        </span>
        <div className="flex items-center space-x-1.5">
          <button
            onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
            className="px-2 py-0.5 bg-gray-200 text-gray-700 rounded disabled:opacity-50"
          >
            <ChevronLeft className="w-2.5 h-2.5" />
          </button>
          <span className="text-xs text-gray-700">
            Page
            {' '}
            {currentPage}
            {' '}
            of
            {' '}
            {totalPages}
          </span>
          <button
            onClick={() =>
              setCurrentPage(prev => Math.min(prev + 1, totalPages))}
            disabled={currentPage === totalPages}
            className="px-2 py-0.5 bg-gray-200 text-gray-700 rounded disabled:opacity-50"
          >
            <ChevronRight className="w-2.5 h-2.5" />
          </button>
        </div>
      </div>
    </Card>
  )
}
