import Drawer from '@mui/material/Drawer'
import { useCallback, useRef } from 'react'
import { useLocalStorage } from 'usehooks-ts'

const defaultDrawerWidth = 750
const minDrawerWidth = 500
const maxDrawerWidth = 2000

export default function ResizableDrawer({ children }) {
  const [drawerWidth, setDrawerWidth] = useLocalStorage('drawerWidth', defaultDrawerWidth)
  const resizingRef = useRef(false)

  const handleMouseMove = useCallback((e) => {
    if (resizingRef.current) {
      e.preventDefault()
      const newWidth = window.innerWidth - e.clientX
      if (newWidth > minDrawerWidth && newWidth < maxDrawerWidth) {
        setDrawerWidth(newWidth)
      }
    }
  }, [])

  const handleMouseUp = useCallback(() => {
    resizingRef.current = false
    document.removeEventListener('mouseup', handleMouseUp, true)
    document.removeEventListener('mousemove', handleMouseMove, true)
    // Re-enable text selection
    document.body.style.userSelect = ''
    document.body.style.WebkitUserSelect = ''
  }, [handleMouseMove])

  const handleMouseDown = useCallback((e) => {
    e.preventDefault()
    resizingRef.current = true
    // Disable text selection during resize
    document.body.style.userSelect = 'none'
    document.body.style.WebkitUserSelect = 'none'
    document.addEventListener('mouseup', handleMouseUp, true)
    document.addEventListener('mousemove', handleMouseMove, true)
  }, [handleMouseMove, handleMouseUp])

  return (
    <Drawer
      variant="permanent"
      anchor="right"
      PaperProps={{
        style: {
          width: drawerWidth,
          boxShadow: '-2px 0 5px rgba(0, 0, 0, 0.1)',
        },
      }}
      className=""
    >
      <div className="min-h-[54px]" />
      <div
        onMouseDown={handleMouseDown}
        className="w-[5px] cursor-ew-resize pt-1 border-t border-gray-300 hover:bg-gray-200 transition-colors absolute top-0 left-0 bottom-0 z-[9999] bg-gray-100"
      />
      {children}
    </Drawer>
  )
}
