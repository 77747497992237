export function ParamTitle({ children }) {
  return (
    <p style={{ fontSize: 14, fontWeight: '450', color: '#000000' }}>
      {children}
    </p>
  )
}

export function ParamDescription({ children }) {
  return (
    <p style={{ fontSize: 12, fontWeight: '400', color: 'GrayText' }}>
      {children}
    </p>
  )
}
