import Tooltip from '@mui/material/Tooltip'
import { TooltipText } from '_components/Text'
import Switch from 'components/core/Switch'

export default function Record({ fieldName }) {
  const longText = (
    <span>
      To record your phone call, set
      {' '}
      <strong>record</strong>
      {' '}
      to
      {' '}
      <strong>true</strong>
      . When your call completes, you can access through
      the
      <strong>recording_url</strong>
      {' '}
      field in the call details or your
      webhook.
    </span>
  )
  return (
    <Tooltip>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <TooltipText tooltip={longText} tooltipClassName="w-[300px]">
          Record
        </TooltipText>
        <Switch sx={{ m: 5 }} fieldName={fieldName} />
      </div>
    </Tooltip>
  )
}
