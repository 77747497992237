import { cn } from '@heroui/react'
import { Button, Tabs as RadixTabs, Tooltip } from '@radix-ui/themes'
import { keepPreviousData, useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Loading } from 'components/core/Loading'
import { PathwayLogsContent } from 'components/Dashboard/ConvoPathways/Components/PathwayLogs'
import VoiceGradientReusable from 'components/Dashboard/SendCall/VoiceGradientReusable'
import GradientLoadingAnimation from 'components/Reusables/GradientLoadingAnimation'
import { Copy, History, PanelRightClose } from 'lucide-react'
import { useEffect, useRef, useState } from 'react'
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels'
import { useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useReadLocalStorage } from 'usehooks-ts'
import { $fetch } from 'utils/fetch'
import AnalysisTabs from './AnalysisTabs'
import AudioPlayer from './AudioPlayer'
import CallMemoryView from './CallMemoryView'
import CallMetadata from './CallMetadata'
import CallTranscript from './CallTranscript'
import { NoteEditor } from './NoteEditor'
import PostcallWebhook from './PostcallWebhook'

export default function CallDetailsSlideOut({ callId, onClose }) {
  const queryClient = useQueryClient()
  const [searchParams] = useSearchParams()
  const autoplay = useReadLocalStorage('autoplay')

  const [activeTabValue, setActiveTabValue] = useState('transcript')
  const [highlightedLogInfo, setHighlightedLogInfo] = useState(null)
  const [keyMarkers, setKeyMarkers] = useState([])
  const [isPreviousCallsOpen, setIsPreviousCallsOpen] = useState(false)
  const pathwayLogsRef = useRef(null)

  const { data: call, isLoading, isFetching, error } = useQuery({
    queryKey: ['callDetails', callId],
    queryFn: async () => {
      const { data: { calls } } = await $fetch(`/user/logs`, {
        query: {
          filters: [{
            field: 'c_id',
            operator: 'equals',
            value: callId,
          }],
          take: 1,
        },
      })
      return calls?.[0]
    },
    enabled: !!callId,
    placeholderData: keepPreviousData,
    staleTime: Infinity,
  })

  const { data: audioBuffer, isFetching: loadingRecording } = useQuery({
    queryKey: ['recording', call?.c_id],
    queryFn: () =>
      $fetch(`/v1/recordings/${call?.c_id}?RequestedChannels=2`, {
        responseType: 'blob',
      }),
    enabled: !!call?.recording_url,
    staleTime: Infinity,
  })

  const { data: analytics } = useQuery({
    queryKey: ['analytics', callId],
    queryFn: () => $fetch(`/user/lookup/${callId}`),
    enabled: !!callId,
    staleTime: Infinity,
  })

  const { data: recordingMetrics } = useQuery({
    queryKey: ['recordingMetrics', call?.c_id],
    queryFn: async () => {
      const { data } = await $fetch('/v1/calls/metrics', {
        params: { call_id: call?.c_id },
      })
      return data
    },
    enabled: !!call?.recording_url,
    staleTime: Infinity,
    retry: false,
  })

  const updateNotes = useMutation({
    mutationFn: async (newNotes) => {
      await $fetch(`/v1/notes/update`, {
        method: 'POST',
        body: { call_id: callId, comment: newNotes },
      })
    },
    onError: () => {
      toast.error('Failed to update note')
    },
  })

  const showAudioPlayer = !!audioBuffer || loadingRecording

  const handleMarkerClick = (marker) => {
    setActiveTabValue('pathway')
    setHighlightedLogInfo({
      type: marker.type,
      timestamp: marker.rawTimestamp,
      details: marker.details,
    })
  }

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape' || e.key === 'ArrowRight') {
        onClose()
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    return () => window.removeEventListener('keydown', handleKeyDown)
  }, [])

  if (isLoading) {
    return (
      <div className="absolute inset-0 z-50 flex items-center justify-center bg-white">
        <div className="flex flex-col items-center justify-center">
          <GradientLoadingAnimation message="Loading call details..." />
        </div>
      </div>
    )
  }

  if (!call || error) {
    return <div>Error loading call data</div>
  }

  return (
    // MAIN CONTAINER
    <PanelGroup direction="vertical" className={cn({ 'animate-pulse': isFetching })}>
      <Panel>
        {/* TOP ROW */}
        <PanelGroup direction="horizontal" autoSaveId="persistence">
          {/* LEFT COLUMN */}
          <Panel defaultSize={35} minSize={30} maxSize={60} className="flex flex-col gap-5 overflow-auto items-center px-3">
            <PanelGroup direction="vertical">
              <Panel>
                {/* HEADER */}
                <span className="flex flex-col items-center gap-1.5 mt-4 relative">

                  <Tooltip content="Close Call Details">
                    <PanelRightClose size={20} className="text-gray-500 hover:text-gray-700 cursor-pointer absolute -top-2 left-0" onClick={onClose} />
                  </Tooltip>

                  <VoiceGradientReusable name={call.c_id} size={50} />
                  <span className="flex items-center !gap-2">
                    <h3 className="text-xl font-semibold font-taurus">
                      {call.inbound ? call.to : call.from}
                    </h3>
                    <Tooltip content="Copy Call ID">
                      <Button
                        variant="ghost"
                        size="1"
                        color="gray"
                        className="dont-close-note"
                        style={{ cursor: 'pointer' }}
                        onClick={(event) => {
                          event.stopPropagation()
                          navigator.clipboard.writeText(call.c_id)
                          toast.success('Call ID copied to clipboard')
                        }}
                      >
                        <p style={{ fontSize: 12 }} className="dont-close-note">
                          {call?.c_id?.substring(0, 6)}
                          ...
                        </p>
                        <Copy size={12} />
                      </Button>
                    </Tooltip>
                  </span>

                  {call.memory_id
                    ? (
                        <Button
                          variant="soft"
                          size="1"
                          color="indigo"
                          className="!w-full !cursor-pointer"
                          onClick={() => {
                            window.open(`/dashboard/memory/${call.memory_id}`, '_blank')
                          }}
                        >
                          <History size={12} />
                          <p>View Memory</p>
                        </Button>
                      )
                    : (
                        <Button
                          variant="soft"
                          size="1"
                          color="gray"
                          className="!w-full !cursor-pointer"
                          onClick={() => {
                            setIsPreviousCallsOpen(true)
                          }}
                        >
                          <History size={12} />
                          <p>Previous Calls</p>
                        </Button>
                      )}
                </span>
                {/* METADATA */}
                <CallMetadata call={call} />
              </Panel>

              <PanelResizeHandle className="bg-gray-100 h-0.5 hover:bg-gray-200" />

              <Panel>

                {/* ANALYSIS TABS */}
                <AnalysisTabs
                  call={call}
                  onKeyMarkersChange={setKeyMarkers}
                  onMarkerClick={handleMarkerClick}
                />

              </Panel>
            </PanelGroup>
          </Panel>
          <PanelResizeHandle className="bg-gray-100 hover:bg-gray-200 w-1" />
          <Panel defaultSize={30} minSize={20}>

            {/* RIGHT COLUMN */}
            <RadixTabs.Root
              key={`right-column-${callId}`}
              defaultValue="transcript"
              value={activeTabValue}
              onValueChange={setActiveTabValue}
              className="w-full bg-white  p-4 pt-0 max-h-full h-full overflow-auto"
            >
              <RadixTabs.List
                color="gray"
                className="sticky top-0 bg-white z-30 pt-2 mb-3"
              >
                <RadixTabs.Trigger
                  value="transcript"
                  style={{ cursor: 'pointer' }}
                >
                  Transcript
                </RadixTabs.Trigger>
                {call?.pathway_logs && (
                  <RadixTabs.Trigger
                    value="pathway"
                    style={{ cursor: 'pointer' }}
                  >
                    Pathway Logs
                  </RadixTabs.Trigger>
                )}
                <RadixTabs.Trigger
                  value="postcall_webhook"
                  style={{ cursor: 'pointer' }}
                >
                  Post Call Webhook
                </RadixTabs.Trigger>
                {call?.memory_id && (
                  <RadixTabs.Trigger
                    value="memory"
                    style={{ cursor: 'pointer' }}
                  >
                    Memory
                  </RadixTabs.Trigger>
                )}
                <RadixTabs.Trigger
                  value="notes"
                  style={{ cursor: 'pointer' }}
                >
                  Notes
                </RadixTabs.Trigger>

              </RadixTabs.List>
              <RadixTabs.Content value="transcript">
                <CallTranscript
                  key={`transcript-${call.c_id}`}
                  callId={call.c_id}
                  initialTranscripts={call.transcript}
                />
              </RadixTabs.Content>
              {call?.pathway_logs && (
                <RadixTabs.Content value="pathway">
                  <PathwayLogsContent
                    loading={false}
                    initialCallId={call?.c_id}
                    callLogs={call?.pathway_logs}
                    setShouldScroll={() => {}}
                    callLogsRef={pathwayLogsRef}
                    elements={null}
                    setChatConversationHistory={null}
                    setChatStartNode={null}
                    enableQA
                    graphID={call?.pathway_id}
                    highlightedLogInfo={highlightedLogInfo}
                    onSeekToTimestamp={(logTimestamp) => {
                      if (logTimestamp) {
                        const firstPathwayLogTranscript = call?.pathway_logs?.find(log => log.role && log.text && !(log.role === 'user' && log.text.includes('extended silence')))
                        const firstPathwayLogTime = new Date(firstPathwayLogTranscript?.created_at).getTime()
                        const logTime = new Date(logTimestamp).getTime()
                        const secondsFromStart = (logTime - firstPathwayLogTime) / 1000
                        const adjustedTimestamp = Math.max(0, secondsFromStart)
                        const audioPlayerElement = document.querySelector('.audio-player-container')
                        if (audioPlayerElement) {
                          const seekEvent = new CustomEvent('seek-to-timestamp', {
                            detail: { timestamp: adjustedTimestamp },
                          })
                          audioPlayerElement.dispatchEvent(seekEvent)
                        }
                      }
                    }}
                  />
                </RadixTabs.Content>
              )}
              <RadixTabs.Content value="postcall_webhook">
                <PostcallWebhook call={call} />
              </RadixTabs.Content>

              <RadixTabs.Content value="memory">
                {call?.memory_id && (
                  <CallMemoryView
                    memoryId={call.memory_id}
                    phoneNumber={call.inbound ? call.from : call.to}
                    callId={call.c_id}
                  />
                )}
              </RadixTabs.Content>
              <RadixTabs.Content value="notes">
                <NoteEditor
                  key={`notes-${callId}`}
                  initialValue={call.call_notes?.[0]?.comment}
                  callId={callId}
                  setNotes={updateNotes.mutate}
                />
              </RadixTabs.Content>
            </RadixTabs.Root>
          </Panel>
        </PanelGroup>
      </Panel>

      {/* AUDIO PLAYER */}
      {showAudioPlayer && (
        <>
          <Panel defaultSize={20} className="pl-2 border-t border-gray-200">
            {loadingRecording
              ? (
                  <Loading loading={loadingRecording} />
                )
              : (
                  <AudioPlayer
                    key={`audio-${call.c_id}`}
                    recordingUrl={call.recording_url}
                    autoplay={autoplay}
                    latency={analytics?.perfLatency}
                    metrics={recordingMetrics}
                    callLength={call.call_length}
                    audioBuffer={audioBuffer}
                    keyMarkers={keyMarkers}
                    call={call}
                    onMarkerClick={handleMarkerClick}
                  />
                )}
          </Panel>
        </>
      )}
    </PanelGroup>
  )
}
