import React from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';

/**
 * Navigation component for moving between conflicts
 */
const ConflictNavigation = ({
  currentIndex,
  totalConflicts,
  goToPrevious,
  goToNext,
  selectedVersion,
  canContinue,
  onApplyAndContinue,
  conflicts
}) => {
  const isFirstConflict = currentIndex === 0;
  const isLastConflict = currentIndex === totalConflicts - 1;
  const currentConflict = conflicts[currentIndex];
  
  const getNextButtonText = () => {
    if (isLastConflict) return 'Review & Finish';
    return 'Next';
  };
  
  const getConflictStatus = () => {
    if (currentConflict.status === 'conflict') {
      return (
        <div className="text-amber-600 text-sm flex items-center">
          <div className="h-2 w-2 rounded-full bg-amber-500 mr-1.5"></div>
          Conflict: Select a version to continue
        </div>
      );
    }
    
    if (currentConflict.status === 'matched') {
      return (
        <div className="text-green-600 text-sm flex items-center">
          <div className="h-2 w-2 rounded-full bg-green-500 mr-1.5"></div>
          Matched: Identical in both versions
        </div>
      );
    }
    
    if (currentConflict.status === 'production-only') {
      return (
        <div className="text-blue-600 text-sm flex items-center">
          <div className="h-2 w-2 rounded-full bg-blue-500 mr-1.5"></div>
          Production Only: Will be kept in merged result
        </div>
      );
    }
    
    if (currentConflict.status === 'sms-only') {
      return (
        <div className="text-indigo-600 text-sm flex items-center">
          <div className="h-2 w-2 rounded-full bg-indigo-500 mr-1.5"></div>
          SMS Only: Will be added to merged result
        </div>
      );
    }
    
    return null;
  };
  
  return (
    <div className="border-t border-gray-200 p-4">
      <div className="flex justify-between items-center">
        <button
          onClick={goToPrevious}
          disabled={isFirstConflict}
          className={`inline-flex items-center px-3 py-2 rounded-md ${
            isFirstConflict 
              ? 'text-gray-400 cursor-not-allowed' 
              : 'text-gray-700 hover:bg-gray-100'
          }`}
        >
          <ChevronLeft className="h-4 w-4 mr-1" />
          Previous
        </button>
        
        <div className="flex-1 mx-4">
          {getConflictStatus()}
        </div>
        
        <button
          onClick={currentConflict.status === 'conflict' ? onApplyAndContinue : goToNext}
          disabled={!canContinue}
          className={`inline-flex items-center px-4 py-2 rounded-md ${
            canContinue 
              ? 'bg-indigo-600 hover:bg-indigo-700 text-white' 
              : 'bg-gray-200 text-gray-500 cursor-not-allowed'
          }`}
        >
          {currentConflict.status === 'conflict' ? 'Apply & Continue' : getNextButtonText()}
          <ChevronRight className="h-4 w-4 ml-1" />
        </button>
      </div>
    </div>
  );
};

/**
 * Progress bar component to display progress through conflicts
 */
export const ProgressBar = ({ currentIndex, totalConflicts }) => {
  const progress = totalConflicts > 0 ? (currentIndex / (totalConflicts - 1)) * 100 : 0;
  
  return (
    <div className="h-1 w-full bg-gray-200">
      <div 
        className="h-full bg-indigo-600 transition-all duration-300 ease-in-out"
        style={{ width: `${progress}%` }}
      />
    </div>
  );
};

export default ConflictNavigation; 