import check from 'assets/icons/check-icon.svg'
import BillingAndPayments from 'components/AccountSupport/Billing/PaymentMethod'
import ActionBar from 'components/core/ActionBar'
import Button from 'components/core/Button'
import Checkbox from 'components/core/Checkbox'
import Input from 'components/core/Input'
import Modal from 'components/core/Modal'
import { PageTitle } from 'components/core/PageTitle'
import { PageWrapper } from 'components/core/PageWrapper'
import Checkout from 'components/StripeElements/CheckoutForm/Checkout'
import { useAuth } from 'hooks/useAuth'
import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import {
  getCreditPurchases,
  purchaseCredits,
} from 'utils/funcs/purchase-credits/purchaseCredits'
import 'react-toastify/dist/ReactToastify.css'

export default function PurchaseCredits() {
  const { credits, permissions, fetchCredits, user } = useAuth()
  const navigate = useNavigate()
  const [clickedIndex, setClickedIndex] = useState()
  const [loading, setLoading] = useState(false)
  const [creditPurchases, setCreditPurchases] = useState(null)
  const [showCheckout, setShowCheckout] = useState(false)
  const [clientSecret, setClientSecret] = useState(null)

  const methods = useForm({
    defaultValues: {
      amount: 50,
      save_details: true,
    },
  })

  const currAmount = methods.watch('amount')

  const options = [
    {
      amount: 10,
      description:
        '110+ minutes of call time. Priority queue. Reduced latency.',
    },
    {
      amount: 20,
      description:
        '220+ minutes of call time. Priority queue. Reduced latency.',
    },
    {
      amount: 50,
      description:
        'Almost 9 hours of call time. Priority queue. Reduced latency.',
    },
  ]

  const onSubmit = async (formData) => {
    setLoading(true)

    if (permissions?.includes('owner') || permissions?.includes('admin')) {
      try {
        const res = await purchaseCredits(
          formData.amount,
          formData.save_details,
        )

        if (res.needsCard) {
          setClientSecret(res.clientSecret)
          setShowCheckout(true)
        }
        else {
          await handleGetCreditPurchases()
          await fetchCredits()
          toast.success('Credits purchased successfully')
        }
      }
      catch (error) {
        toast.error('Error purchasing credits')
      }
    }
    else {
      toast.error('You don\'t have permission to purchase credits.')
    }
    setLoading(false)
  }

  const handleGetCreditPurchases = async () => {
    try {
      const purchases = await getCreditPurchases()
      if (purchases && Array.isArray(purchases)) {
        purchases.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at),
        )
        return setCreditPurchases(purchases)
      }
    }
    catch (e) {}
  }

  // useEffect(() => {
  //   handleGetCreditPurchases();
  // }, []);

  return (
    <PageWrapper style={{ height: '100%', backgroundColor: '#fbfbfa' }}>
      <ActionBar top>
        <PageTitle>Purchase Credits</PageTitle>
      </ActionBar>
      <FormProvider {...methods}>
        <FormWrapper>
          <Form onSubmit={methods.handleSubmit(onSubmit)}>
            <Header>
              <h1 className="font-bold">
                Add more API credits to your account.
                {' '}
              </h1>
              <p className="text-muted-foreground">
                Bland bills at $0.09/minute, pro-rated to the exact second, and
                only for call time (dial time excluded). Your current balance:
                <span className="font-bold">
                  {` ${Number.parseFloat(credits).toFixed(2)}`}
                  {' '}
                  credits
                </span>
              </p>
              <p className="text-muted-foreground italic">
                *Purchasing credits will attempt to use your default payment
                method. If your default payment method is not a card, you will
                be prompted to enter card details on purchase.
              </p>
            </Header>
            {showCheckout && (
              <Modal
                height="auto"
                open={showCheckout}
                onClose={() => setShowCheckout(false)}
              >
                <Confirm>
                  <Checkout
                    amount={currAmount}
                    clientSecret={clientSecret}
                    onSuccess={() => {
                      setShowCheckout(false)
                      toast.success('Credits purchased successfully')
                      fetchCredits()
                    }}
                  />
                </Confirm>
              </Modal>
            )}
            {currAmount && currAmount > 0 && (
              <Value>
                <Check>
                  <img
                    src={check}
                    alt="check-point-icon"
                    width={15}
                    height={20}
                  />
                  <p>Send multiple calls at once</p>
                </Check>
                <Check>
                  <img
                    src={check}
                    alt="check-point-icon"
                    width={15}
                    height={20}
                  />
                  <p>Extended context window (5000 characters)</p>
                </Check>
                <Check>
                  <img
                    src={check}
                    alt="check-point-icon"
                    width={15}
                    height={20}
                  />
                  <p>Access to beta features on release</p>
                </Check>
              </Value>
            )}
            <Amount>
              <Options>
                {options.map((amt, index) => (
                  <>
                    <Option
                      onClick={() => methods.setValue('amount', amt.amount)}
                      key={index}
                    >
                      <div>
                        <h3 className="font-bold">
                          $
                          {amt.amount}
                        </h3>
                        <p className="text-muted-foreground">{amt.description}</p>
                      </div>
                      <Button
                        submit
                        loading={loading && clickedIndex === index}
                        onClick={() => {
                          setClickedIndex(index)
                        }}
                      >
                        Purchase
                      </Button>
                    </Option>
                  </>
                ))}
              </Options>
              <Buy>
                <CustomPurchase>
                  <Input
                    fieldName="amount"
                    type="number"
                    min="1"
                    max="10000"
                    label="Custom Amount ($)"
                    style={{ backgroundColor: 'white' }}
                  />
                  <Button
                    submit
                    loading={loading && clickedIndex === 'custom'}
                    onClick={() => {
                      setClickedIndex('custom')
                    }}
                    disabled={currAmount < 1}
                  >
                    Purchase
                    {' '}
                    {currAmount}
                    {' '}
                    credits ($
                    {currAmount}
                    )
                  </Button>
                </CustomPurchase>
              </Buy>
              <Checkbox
                fieldName="save_details"
                label="Save payment method after checkout"
              />
            </Amount>
          </Form>

          <PurchaseLog>
            {/* <p
                className="font-bold"
                style={{ fontSize: 20, fontWeight: "550" }}
              >
                Purchase History
              </p>
              <p
                style={{
                  marginTop: "-10px",
                  color: "grey",
                  marginBottom: "10px",
                }}
              >
                View and download invoices for all your credit top ups.
              </p>

              {creditPurchases.map((purchase, key) => (
                <div
                  key={key}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <p style={{ fontWeight: "450", fontSize: 15 }}>
                      Credit Top up
                    </p>
                    <p
                      style={{
                        fontWeight: "350",
                        fontSize: 12,
                        marginTop: 2.5,
                      }}
                    >
                      ${purchase.amount?.toFixed(2)} •{" "}
                      {purchase.amount?.toFixed(0)} Credits
                    </p>
                  </div>

                  <div
                    onClick={() => generateInvoicePDF(purchase, user)}
                    style={{
                      marginLeft: "auto",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <img
                      src={DownloadIcon}
                      alt=""
                      style={{ alignSelf: "center" }}
                    />
                    <p
                      style={{
                        textAlign: "right",
                        fontWeight: "350",
                        fontSize: 12,
                        color: "#3B36DF",
                      }}
                    >
                      Invoice
                    </p>
                  </div>
                </div>
              ))} */}
            <BillingAndPayments embedded />
          </PurchaseLog>
        </FormWrapper>
      </FormProvider>
    </PageWrapper>
  )
}

const FormWrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 20px;
`

const PurchaseLog = styled.div`
  padding: 15px 25px 25px 25px;
  border-radius: 5px;
  width: 45%;
  border: 1px solid #eeeeee;
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-height: 650px;
  overflow: scroll;
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  align-items: center;
  justify-content: center;
  height: 100%;
  max-width: 650px;
  margin: auto;
`

const Amount = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  width: 100%;
`

const Options = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 12px;
`

const Option = styled.div`
  border: 1px solid #eeeeee;
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  padding: 30px 20px;
  border-radius: 5px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: white;
`

const Value = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  row-gap: 6px;
  width: 100%;
`

const Buy = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
`

const Confirm = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  row-gap: 20px;
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  width: 100%;
`

const CustomPurchase = styled.div`
  display: flex;
  flex-direction: row;
  align-items: end;
  column-gap: 9px;
`

const Check = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 12px;
`
