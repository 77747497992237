import {
  Box,
  Button,
  Callout,
  Dialog,
  DropdownMenu,
  Flex,
  Spinner,
  Text,
  TextField,
} from '@radix-ui/themes'
import { useAuth } from 'hooks/useAuth'
import {
  AlertTriangle,
  Check,
  CircleMinus,
  Copy,
  Download,
  EllipsisIcon,
  Key,
  KeyRound,
  ListFilter,
  Plus,
  Search,
  X,
} from 'lucide-react'
import { useEffect, useState } from 'react'
import { ThreeDots } from 'react-loader-spinner'
import { toast } from 'react-toastify'
import { $fetch } from 'utils/fetch'

function APIKeysNullState({ onCreateNewKey }) {
  return (
    <Box
      className="p-5 text-center bg-white shadow-sm"
      style={{ width: '100%' }}
    >
      <Flex direction="column" align="center" gap="3">
        <Box className="bg-gray-50 p-4 rounded-full">
          <Key size={22} className="text-gray-500" />
        </Box>

        <p style={{ fontSize: 18, fontWeight: 400 }}>No API Keys Yet</p>

        <p className="text-gray-600 max-w-[70%]">
          API keys allow you to authenticate and make requests to our API.
          Create your first key to get started with integrating our services.
        </p>

        {/* <Text size="2" className="text-gray-500 max-w-sm">
            Need help? Check out our
            <a href="#" className="text-blue-500 hover:underline ml-0.5">
              documentation
            </a>{" "}
            or
            <a href="#" className="text-blue-500 hover:underline ml-0.5">
              contact support
            </a>
            .
          </Text> */}
      </Flex>
    </Box>
  )
}

function KeyCreatedDialog({ isOpen, onClose, apiKey, isLegacy }) {
  const [isCopied, setIsCopied] = useState(false)
  const [timeLeft, setTimeLeft] = useState(300) // 5 minutes in seconds

  useEffect(() => {
    if (isOpen && !isLegacy) {
      const timer = setInterval(() => {
        setTimeLeft(prevTime => (prevTime > 0 ? prevTime - 1 : 0))
      }, 1000)
      return () => clearInterval(timer)
    }
  }, [isOpen, isLegacy])

  const handleCopy = () => {
    navigator.clipboard.writeText(apiKey)
    setIsCopied(true)
    setTimeout(() => setIsCopied(false), 1000)
    toast.success('API key copied to clipboard')
  }

  const handleDownload = () => {
    const element = document.createElement('a')
    const file = new Blob([apiKey], { type: 'text/plain' })
    element.href = URL.createObjectURL(file)
    element.download = 'api_key.txt'
    document.body.appendChild(element)
    element.click()
    document.body.removeChild(element)
    toast.success('API key downloaded')
  }

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60)
    const remainingSeconds = seconds % 60
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`
  }

  return (
    <Dialog.Root open={isOpen} onOpenChange={onClose}>
      <Dialog.Content
        style={{ maxWidth: 480, padding: 0, backgroundColor: '#f8f9fa' }}
      >
        <Flex direction="column">
          <Flex
            direction="row"
            justify="between"
            align="center"
            style={{
              padding: 15,
              borderBottom: '1px solid #dee2e6',
              background: '#ffffff',
            }}
          >
            <p style={{ fontSize: 13, fontWeight: '550' }}>Save API Key</p>

            <X
              size={14}
              style={{ cursor: 'pointer', color: '#6c757d' }}
              onClick={onClose}
            />
          </Flex>

          {!isLegacy && (
            <Box style={{ padding: '15px 15px 0px 15px' }}>
              <Callout.Root color="yellow" variant="surface">
                <Callout.Icon>
                  <AlertTriangle size={16} />
                </Callout.Icon>
                <Callout.Text style={{ color: '#664d03', fontWeight: 500 }}>
                  This key will only be shown once. Save it securely.
                </Callout.Text>
              </Callout.Root>
            </Box>
          )}

          <Box style={{ padding: '15px' }}>
            <Text
              weight="medium"
              style={{ marginBottom: 15, color: '#495057', fontSize: 13 }}
            >
              Your New API Key
            </Text>
            <TextField.Root
              defaultValue={apiKey}
              readOnly
              onMouseEnter={(event) => {
                event.currentTarget.style.cursor = 'pointer'
              }}
              style={{
                fontSize: 14,
                color: '#000',
                border: '1px solid #ced4da',
                borderRadius: 6,
                backgroundColor: '#e9ecef',
                marginTop: 5,
              }}
              onClick={() => {
                navigator.clipboard.writeText(apiKey)
                toast.success('API key copied to clipboard')
              }}
            />
          </Box>

          <Flex
            gap="3"
            justify="between"
            align="center"
            style={{ padding: '0 15px 15px 15px' }}
          >
            <Flex gap="3">
              <Button
                onClick={handleCopy}
                variant="soft"
                color={isCopied ? 'green' : 'blue'}
                style={{
                  cursor: 'pointer',
                  fontSize: 13,
                  fontWeight: '450',
                  borderRadius: 6,
                  padding: '10px 15px',
                }}
              >
                {isCopied ? <Check size={13} /> : <Copy size={13} />}
                {isCopied ? 'Copied!' : 'Copy to clipboard'}
              </Button>
              <Button
                onClick={handleDownload}
                variant="soft"
                color="gray"
                style={{
                  cursor: 'pointer',
                  fontSize: 13,
                  fontWeight: '450',
                  borderRadius: 6,
                  padding: '10px 15px',
                }}
              >
                <Download size={13} />
                Download
              </Button>
            </Flex>
            {!isLegacy && (
              <Text size="2" style={{ color: '#dc3545', fontWeight: 500 }}>
                Time left:
                {' '}
                {formatTime(timeLeft)}
              </Text>
            )}
          </Flex>

          <Flex
            justify="end"
            style={{
              padding: 15,
              borderTop: '1px solid #dee2e6',
              backgroundColor: '#ffffff',
            }}
          >
            <Dialog.Close>
              <Button
                variant="soft"
                color="gray"
                style={{
                  cursor: 'pointer',
                  borderRadius: 6,
                  padding: '10px 20px',
                  fontSize: 14,
                  fontWeight: '500',
                }}
              >
                Close
              </Button>
            </Dialog.Close>
          </Flex>
        </Flex>
      </Dialog.Content>
    </Dialog.Root>
  )
}

export default function Keys() {
  const { org } = useAuth()
  const [keys, setKeys] = useState(null)
  const [loading, setLoading] = useState(true)
  const [loadingNewKey, setLoadingNewKey] = useState(false)
  const [isKeyDialogOpen, setIsKeyDialogOpen] = useState(false)
  const [newApiKey, setNewApiKey] = useState('')

  const deleteKey = async (key_prefix) => {
    try {
      const headers = {
        'Content-Type': 'application/json',
      }

      const response = await $fetch(`/keys/delete`, {
        method: 'DELETE',
        headers,
        body: JSON.stringify({
          key: key_prefix,
        }),
      })

      if (!response?.errors?.length) {
        setKeys(keys.filter(k => k.key !== key_prefix))
        return toast.success(`Delete API key: ${key_prefix}`)
      }
    }
    catch (error) {}
  }

  const createNewKey = async () => {
    try {
      setLoadingNewKey(true)

      const response = await $fetch(`/keys/create`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          type: 'org',
          target_id: org?.id,
        }),
        ignoreResponseError: true,
      })

      if (response?.data) {
        if (!response?.data?.errors) {
          await getAllKeys()
          setNewApiKey(response?.data?.key)
          setIsKeyDialogOpen(true)
          return toast.success('Successfully created new API key.')
        }
        else if (response?.data?.errors) {
          return toast.error('Error creating new API key.')
        }
      }
    }
    catch (error) {
    }
    finally {
      setLoadingNewKey(false)
    }
  }

  const getAllKeys = async () => {
    try {
      setLoading(true)

      const response = await $fetch(`/keys/list`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          type: 'org',
          target_id: org?.id,
        }),
      })

      if (response.error || !response?.data?.keys)
        return

      setKeys(response?.data?.keys)
    }
    catch (error) {
      console.error('Error fetching keys:', error)
    }
    finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setKeys(null)

    getAllKeys()
  }, [org])

  return (
    <div>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginTop: 25,
        }}
      >
        <p
          style={{
            fontSize: 18,
            fontWeight: '450',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            columnGap: 8,
          }}
        >
          All keys .
          <p style={{ fontSize: 18, fontWeight: '450', color: 'GrayText' }}>
            {loading ? <Spinner size="2" /> : keys?.length || 0}
            {' '}
            . The API key
            you see is truncated and cannot be accessed again after creating it.
          </p>
        </p>
        <div
          style={{
            marginLeft: 'auto',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            flexWrap: 'wrap',
            columnGap: 10,
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Search
              color="gray"
              size={14}
              style={{ position: 'relative', left: 25, zIndex: 200 }}
            />
            <TextField.Root
              style={{
                padding: '0px 7px 0px 27px', // Adjusted padding to make space for the icon
                borderRadius: 6,
                position: 'relative',
                fontSize: 14,
              }}
              placeholder="Search by email..."
            />
          </div>

          {/* <TextField.Root color="gray" /> */}

          <Button
            size="2"
            variant="outline"
            color="gray"
            style={{ cursor: 'pointer', borderRadius: 6 }}
          >
            <ListFilter size={16} />
            <p style={{ fontSize: 14, fontWeight: '500', color: '#000' }}>
              Filters
            </p>
          </Button>
          <Button
            size="2"
            variant="solid"
            // color="#343a40"
            style={{
              cursor: 'pointer',
              background: '#343a40',
              borderRadius: 6,
            }}
            highContrast
            disabled={loadingNewKey}
            onClick={() => {
              createNewKey()
            }}

            /// onClick={() => setInvModalOpen(true)}
          >
            {loadingNewKey
              ? (
                  <ThreeDots color="#fff" height={16} width={16} />
                )
              : (
                  <>
                    <Plus size={16} />
                    <p style={{ fontSize: 14, fontWeight: '500' }}>New API Key</p>
                  </>
                )}
          </Button>
        </div>
      </div>

      <div
        className="w-full"
        style={{
          border: '1px solid #eeeeee',
          background: '#fafafa',
          marginTop: 25,
        }}
      >
        <div className="flex flex-col">
          <div className="grid grid-cols-12 px-4 py-3 text-xs font-medium text-gray-500">
            <div
              className="col-span-3"
              style={{ fontSize: 14, fontWeight: '500' }}
            >
              <p style={{ fontSize: 13, fontWeight: '400' }}>Key</p>
            </div>
            <div
              className="col-span-4"
              style={{ fontSize: 14, fontWeight: '500' }}
            >
              <p style={{ fontSize: 13, fontWeight: '400' }}>Owner</p>
            </div>
            <div
              className="col-span-4"
              style={{ fontSize: 14, fontWeight: '500' }}
            >
              <p style={{ fontSize: 13, fontWeight: '400' }}>Status</p>
            </div>
            <div className="col-span-1 text-right">
              <p style={{ fontSize: 13, fontWeight: '400' }}></p>
            </div>
          </div>
        </div>
      </div>

      <div
        className="flex flex-col transition-all duration-300 ease-in-out"
        style={{
          borderLeft: '1px solid #eeeeee',
          borderRight: '1px solid #eeeeee',
          borderBottom: '1px solid #eeeeee',
        }}
      >
        {!loading
          && keys !== null
          && keys?.length > 0
          && keys.map((key, index) => (
            <div
              key={index}
              className="grid grid-cols-12 px-4 py-4 items-center transition-all duration-300 ease-in-out"
              style={{
                borderBottom:
                  keys.length === 1 || keys.length - 1 === index
                    ? 'none'
                    : '1px solid #e9e9e9',
              }}
            >
              <div className="col-span-3 flex items-center gap-2.5">
                <div
                  className="transition-all duration-300 ease-in-out"
                  style={{
                    padding: 4,
                    background: '#f9f9f9',
                    marginRight: 4,
                    borderRadius: 4,
                  }}
                >
                  <KeyRound
                    className="w-4 h-4 transition-all duration-300 ease-in-out"
                    color="#e2e2e2"
                    strokeWidth={2}
                  />
                </div>
                <div>
                  <div
                    className="transition-all duration-300 ease-in-out"
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <p
                      className="transition-all duration-300 ease-in-out"
                      style={{ fontWeight: '400', fontSize: 13 }}
                    >
                      {key.key}
                      ...
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-span-4">
                <p
                  className="transition-all duration-300 ease-in-out"
                  style={{
                    fontSize: 13,
                    fontWeight: '400',
                    color: 'GrayText',
                  }}
                >
                  {key.owner}
                </p>
              </div>
              <div className="col-span-4">
                <p
                  style={{ fontSize: 13, fontWeight: '400', color: 'GrayText' }}
                >
                  Active
                </p>
              </div>
              <div className="col-span-1 text-right flex items-center justify-end mr-4">
                <DropdownMenu.Root dir="rtl">
                  <DropdownMenu.Trigger>
                    <Button
                      size="2"
                      variant="ghost"
                      color="gray"
                      radius="full"
                      className="transition-all duration-300 ease-in-out"
                      style={{ cursor: 'pointer' }}
                    >
                      <EllipsisIcon
                        className="w-4 h-6 transition-all duration-300 ease-in-out"
                        color="gray"
                        strokeWidth={1}
                      />
                    </Button>
                  </DropdownMenu.Trigger>
                  <DropdownMenu.Content
                    side="bottom"
                    variant="soft"
                    size="1"
                    className="transition-all duration-300 ease-in-out"
                    style={{
                      borderRadius: 6,
                      cursor: 'pointer',
                      padding: 4,
                      width: 180,
                    }}
                  >
                    <DropdownMenu.Item
                      color="crimson"
                      className="transition-all duration-300 ease-in-out"
                      style={{
                        cursor: 'pointer',
                        justifyContent: 'space-between',
                      }}
                      onClick={(event) => {
                        deleteKey(key.key)
                      }}
                    >
                      <p
                        className="transition-all duration-300 ease-in-out"
                        style={{ fontSize: 12, fontWeight: '450' }}
                      >
                        Delete API Key
                      </p>
                      <CircleMinus
                        size={12}
                        className="transition-all duration-300 ease-in-out"
                      />
                    </DropdownMenu.Item>
                  </DropdownMenu.Content>
                </DropdownMenu.Root>
              </div>
            </div>
          ))}

        {loading && (
          <div
            className="flex items-center justify-center"
            style={{ height: 300 }}
          >
            <ThreeDots color="#eeeeee" height={40} width={40} />
          </div>
        )}

        {!loading && (keys === null || (keys?.length || 0) === 0) && (
          <APIKeysNullState
            onCreateNewKey={() => {
              createNewKey()
            }}
          />
        )}
      </div>

      <KeyCreatedDialog
        isOpen={isKeyDialogOpen}
        onClose={() => setIsKeyDialogOpen(false)}
        apiKey={newApiKey}
        isLegacy={false}
      />
    </div>
  )
}
