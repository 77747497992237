import { useEffect, useState } from 'react'

/**
 * PingGauge:
 *  - Pings "https://api.bland.ai/ping" => measures in ms
 *  - Renders a ~270° arc that starts top-left, sweeps downward, ends bottom-left,
 *    leaving open space on the right side.
 *  - 0 ms => no fill; >= 200 ms => fully filled arc.
 */
export default function PingGauge() {
  const [pingMs, setPingMs] = useState(null)

  useEffect(() => {
    measurePing()
    // If desired, re-measure periodically:
    // const interval = setInterval(() => measurePing(), 60000);
    // return () => clearInterval(interval);
  }, [])

  async function measurePing() {
    try {
      const start = performance.now()
      await fetch('https://api.bland.ai/ping')
      const end = performance.now()
      const ms = Math.round(end - start)
      console.log('Ping:', ms, 'ms')
      setPingMs(ms)
    }
    catch (err) {
      console.error('Ping failed:', err)
      setPingMs(null)
    }
  }

  // --------------------------------------------------------
  // Gauge math (270° arc)
  // --------------------------------------------------------
  const size = 150 // "virtual" diameter (SVG coordinates)
  const strokeWidth = 12
  const radius = (size - strokeWidth) / 2 // e.g. 69
  const fullCircum = 2 * Math.PI * radius // circumference of the circle
  const arcFraction = 270 / 360 // 270° / 360° => 0.75
  const arcLength = fullCircum * arcFraction

  // We'll say ping >= 200 ms => fill entire 270° arc
  let fraction = 0
  if (pingMs != null) {
    fraction = pingMs / 200
    if (fraction > 1)
      fraction = 1
  }

  // strokeDashoffset to reveal/hide the arc
  // fraction=0 => offset= arcLength => no visible fill
  // fraction=1 => offset=0 => fully filled
  const dashOffset = arcLength * (1 - fraction)

  const gaugeColor = '#4BA796' // green
  const bgColor = '#e2e2e2' // light gray

  return (
    // Occupies half the parent container width, with some border & styling
    <div className="w-full  p-1.5 font-sans text-center mx-auto h-1/2">
      <h3 className="mb-1.5 text-xs font-semibold">Latency</h3>

      {/*
        Responsive container with a 1:1 aspect ratio => a perfect square.
        Inside it, we place an absolutely-positioned SVG set to 100% width/height.
      */}
      <div className="relative w-full" style={{ paddingBottom: '75%' }}>
        {/* The SVG uses a fixed coordinate system (150×150) */}
        <svg
          className="absolute top-0 left-0 w-full h-full"
          viewBox={`0 0 ${size} ${size}`}
          preserveAspectRatio="xMidYMid meet"
          style={{
            // rotate so that the open gap is on the right => start top-left
            transform: 'rotate(135deg)',
            overflow: 'visible',
          }}
        >
          {/* Background arc (light gray), 270° */}
          <circle
            cx={size / 2}
            cy={size / 2}
            r={radius}
            fill="none"
            stroke={bgColor}
            strokeWidth={strokeWidth}
            strokeDasharray={arcLength}
            strokeDashoffset={0}
          />

          {/* Filled arc (green) */}
          <circle
            cx={size / 2}
            cy={size / 2}
            r={radius}
            fill="none"
            stroke={gaugeColor}
            strokeWidth={strokeWidth}
            strokeDasharray={arcLength}
            strokeDashoffset={dashOffset}
            strokeLinecap="round"
            style={{
              transition: 'stroke-dashoffset 0.5s ease',
            }}
          />
        </svg>

        {/* Center text remains upright => separate absolute div */}
        <div className="absolute top-0 left-0 flex items-center justify-center w-full h-full">
          {pingMs == null
            ? (
                <span className="text-xs text-gray-500">--</span>
              )
            : (
                <div>
                  <div className="text-lg font-semibold">{pingMs}</div>
                  <div className="-mt-0.5 text-2xs text-gray-600">ms</div>
                </div>
              )}
        </div>
      </div>

      {/* Additional text below */}
      <p className="mt-1.5 text-2xs text-gray-600">Current Ping</p>
      <p className="m-0 text-2xs text-gray-700 leading-snug">
        Experience seamless communication with a low-latency network.
        Reduce latency further with
        {' '}
        <b>enterprise-grade optimization</b>
        .
      </p>
    </div>
  )
}
