import React, { useState } from 'react';
import { Check, AlertTriangle, X, ArrowRight, ChevronDown, ChevronRight } from 'lucide-react';

// Import specialized conflict view components
import NodeConflictView from './NodeConflictView';
import EdgeConflictView from './EdgeConflictView';
import GlobalPromptConflictView from './GlobalPromptConflictView';

/**
 * Component for displaying merge confirmation and summary
 */
const MergeConfirmation = ({ 
  conflicts, 
  resolutions, 
  onConfirm, 
  onCancel,
  mainPathwayData,
  smsPathwayData
}) => {
    console.log("Main data and SMS data:", mainPathwayData, smsPathwayData);
    
  // State to track expanded conflict items
  const [expandedItems, setExpandedItems] = useState({});
  
  // State for editable content in custom resolutions
  const [editableContents, setEditableContents] = useState({});
  
  // State for expanded details within conflict view components
  const [expandedDetails, setExpandedDetails] = useState({});
  
  // Toggle expanded state for a conflict item
  const toggleExpand = (id) => {
    setExpandedItems(prev => ({
      ...prev,
      [id]: !prev[id]
    }));
    
    // Initialize expanded details for this conflict
    if (!expandedDetails[id]) {
      setExpandedDetails(prev => ({
        ...prev,
        [id]: {
          prodPrompt: false,
          prodCondition: false,
          smsPrompt: false,
          smsCondition: false
        }
      }));
    }
  };
  
  // Count conflicts by status and resolution type
  const conflictStats = conflicts.reduce((stats, conflict) => {
    // Count by status
    stats.byStatus[conflict.status] = (stats.byStatus[conflict.status] || 0) + 1;
    
    // If it's a conflict, count by resolution type
    if (conflict.status === 'conflict') {
      const resolution = resolutions[conflict.id];
      stats.byResolution[resolution] = (stats.byResolution[resolution] || 0) + 1;
    }
    
    return stats;
  }, { 
    byStatus: {}, 
    byResolution: {}
  });

  // Sort conflicts to show conflict status first
  const sortedConflicts = [...conflicts].sort((a, b) => {
    if (a.status === 'conflict' && b.status !== 'conflict') return -1;
    if (a.status !== 'conflict' && b.status === 'conflict') return 1;
    return 0;
  });

  console.log("Sorted conflicts:", sortedConflicts);
  
  // Helper function to get correct type label
  const getTypeLabel = (conflict) => {
    if (conflict.type === 'node') return 'Node';
    if (conflict.type === 'globalPrompt') return 'Global Prompt';
    return 'Pathway Label';
  };
  
  // Helper function to get appropriate name
  const getItemName = (conflict) => {
    // For nodes, try to get the node name from several possible locations
    if (conflict.type === 'node') {
      // First check if nodeData exists with a name
      if (conflict.nodeData?.data?.name) {
        return conflict.nodeData.data.name;
      }
      
      // Then check production data
      if (conflict.productionData?.data?.name || conflict.productionVersion?.data?.name) {
        return (conflict.productionData?.data?.name || conflict.productionVersion?.data?.name);
      }
      
      // Finally check SMS data
      if (conflict.smsData?.data?.name || conflict.smsVersion?.data?.name) {
        return (conflict.smsData?.data?.name || conflict.smsVersion?.data?.name);
      }
    }
    
    // For edges, show source -> target
    if (conflict.type === 'edge') {
      const source = conflict.productionData?.source || conflict.smsData?.source || 
                    conflict.productionVersion?.source || conflict.smsVersion?.source || 'Unknown';
      const target = conflict.productionData?.target || conflict.smsData?.target || 
                    conflict.productionVersion?.target || conflict.smsVersion?.target || 'Unknown';
      return `${source} → ${target}`;
    }
    
    // Fallback to the basic name property
    return conflict.name || 'Unnamed Item';
  };
  
  // Handle selection of resolution version for a conflict
  const handleSelectVersion = (conflictId, version) => {
    // This would update the resolution in the parent component if implemented
    // For now we'll just log it
    console.log(`Selected ${version} for conflict ${conflictId}`);
  };
  
  // Handle field-level resolution 
  const handleFieldResolution = (conflictId, fieldPath, version) => {
    console.log(`Field resolution: ${conflictId}, ${fieldPath}, ${version}`);
  };
  
  // Handle updates to custom editable content
  const handleEditableContentChange = (conflictId, content) => {
    setEditableContents(prev => ({
      ...prev,
      [conflictId]: content
    }));
  };
  
  // Ensure conflict object has correct structure expected by view components
  const normalizeConflict = (conflict) => {
    // Normalize properties to match what the view components expect
    const normalized = {
      ...conflict,
      // Ensure these properties exist with fallbacks
      productionVersion: conflict.productionVersion || conflict.productionData || {},
      smsVersion: conflict.smsVersion || conflict.smsData || {},
    };
    
    // Log the normalized conflict to help debug
    console.log("Normalized conflict for component:", normalized);
    
    return normalized;
  };
    
  // Render the appropriate conflict view component based on conflict type
  const renderConflictViewComponent = (conflict) => {
    // Prepare conflict data in the format expected by view components
    const normalizedConflict = normalizeConflict(conflict);
    
    // Create editable content for this conflict if not exists
    if (!editableContents[conflict.id]) {
      const prodData = normalizedConflict.productionVersion;
      const smsData = normalizedConflict.smsVersion;
      
      let initialContent = {};
      
      if (conflict.type === 'globalPrompt') {
        initialContent = {
          globalPrompt: prodData?.globalPrompt || smsData?.globalPrompt || ''
        };
      } else {
        initialContent = {
          name: prodData?.name || smsData?.name || '',
          text: prodData?.text || smsData?.text || '',
          prompt: prodData?.prompt || smsData?.prompt || '',
          type: prodData?.type || smsData?.type || '',
          data: JSON.stringify(prodData?.data || smsData?.data || {}, null, 2)
        };
      }
      
      setEditableContents(prev => ({
        ...prev,
        [conflict.id]: initialContent
      }));
    }
    
    // Get conflict-specific details
    const conflictDetails = expandedDetails[conflict.id] || {
      prodPrompt: false,
      prodCondition: false, 
      smsPrompt: false,
      smsCondition: false
    };
    
    // Prepare common props for all conflict views
    const commonProps = {
      conflict: normalizedConflict,
      selectedVersion: resolutions[conflict.id] || 'production',
      onSelectVersion: (version) => handleSelectVersion(conflict.id, version),
      expandedDetails: conflictDetails,
      setExpandedDetails: (details) => {
        setExpandedDetails(prev => ({
          ...prev,
          [conflict.id]: details
        }));
      },
      editableContent: editableContents[conflict.id] || {},
      setEditableContent: (content) => handleEditableContentChange(conflict.id, content),
      onSelectField: (fieldPath, version) => handleFieldResolution(conflict.id, fieldPath, version)
    };
    
    // Render component based on conflict type
    switch (conflict.type) {
      case 'node':
        return <NodeConflictView {...commonProps} />;
      case 'edge':
        return <EdgeConflictView {...commonProps} />;
      case 'globalPrompt':
        return <GlobalPromptConflictView {...commonProps} />;
      default:
        return <NodeConflictView {...commonProps} />;
    }
  };
  
  return (
    <div className="p-6">
      <div className="mb-6 border-b pb-4">
        <h2 className="text-xl font-bold mb-2">Confirm Merge</h2>
        <p className="text-gray-600">
          Please review your selections before finalizing the merge.
        </p>
      </div>
      
      <div className="mb-6">
        <div className="flex items-center mb-4">
          <h3 className="text-lg font-semibold">Merging:</h3>
        </div>
        
        <div className="flex items-center justify-center gap-24 mb-6">
          <div className="bg-gray-50 p-4 rounded-lg">
            <h4 className="font-medium mb-2">Production Pathway</h4>
            <div className="text-sm text-gray-700">
              <p>
                <span className="font-medium">Pathway Id:</span> {mainPathwayData.id}
              </p>
              <p>
                <span className="font-medium">Version:</span> {mainPathwayData.version === '0' ? 'Production' : mainPathwayData.version}
              </p>
            </div>
          </div>

          <ArrowRight className="h-6 w-6 text-gray-500" />
          
          <div className="bg-gray-50 p-4 rounded-lg">
            <h4 className="font-medium mb-2">SMS Pathway</h4>
            <div className="text-sm text-gray-700">
              <p>
                <span className="font-medium">Pathway Id:</span> {smsPathwayData.id}
              </p>
              <p>
                <span className="font-medium">Version:</span> {smsPathwayData.version === '0' ? 'Production' : smsPathwayData.version}
              </p>
            </div>
          </div>
        </div>
      </div>
      
      <div className="mb-6">
        <h3 className="text-lg font-semibold mb-4">Conflict Summary</h3>
        
        <div className="bg-gray-50 p-4 rounded-lg mb-4">
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
            <div className="bg-white p-3 rounded border text-center">
              <div className="text-2xl font-bold">{conflicts.length}</div>
              <div className="text-sm text-gray-600">Total Items</div>
            </div>
            
            <div className="bg-white p-3 rounded border text-center">
              <div className="text-2xl font-bold text-amber-500">
                {conflictStats.byStatus.conflict || 0}
              </div>
              <div className="text-sm text-gray-600">Conflicts</div>
            </div>
            
            <div className="bg-white p-3 rounded border text-center">
              <div className="text-2xl font-bold text-green-500">
                {conflictStats.byStatus.matched || 0}
              </div>
              <div className="text-sm text-gray-600">Matched</div>
            </div>
            
            <div className="bg-white p-3 rounded border text-center">
              <div className="text-2xl font-bold text-blue-500">
                {(conflictStats.byStatus['production-only'] || 0) + 
                 (conflictStats.byStatus['sms-only'] || 0)}
              </div>
              <div className="text-sm text-gray-600">Unique Items</div>
            </div>
          </div>
        </div>
        
        {conflictStats.byStatus.conflict > 0 && (
          <div className="bg-gray-50 p-4 rounded-lg">
            <h4 className="font-medium mb-3">Resolution Choices</h4>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div className="bg-white p-3 rounded border text-center">
                <div className="text-xl font-bold text-blue-600">
                  {conflictStats.byResolution.production || 0}
                </div>
                <div className="text-sm text-gray-600">Production Version</div>
              </div>
              
              <div className="bg-white p-3 rounded border text-center">
                <div className="text-xl font-bold text-indigo-600">
                  {conflictStats.byResolution.sms || 0}
                </div>
                <div className="text-sm text-gray-600">SMS Version</div>
              </div>
              
              <div className="bg-white p-3 rounded border text-center">
                <div className="text-xl font-bold text-green-600">
                  {conflictStats.byResolution.custom || 0}
                </div>
                <div className="text-sm text-gray-600">Custom Edits</div>
              </div>
            </div>
          </div>
        )}
      </div>
      
      <div className="mb-6">
        <h3 className="text-lg font-semibold mb-4">Conflict Details</h3>
        
        <div className="border rounded-lg overflow-hidden max-h-96 overflow-y-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50 sticky top-0 z-10">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Name
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Type
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Status
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Resolution
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Details
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {sortedConflicts.map((conflict) => (
                <React.Fragment key={conflict.id}>
                  <tr 
                    className={`${conflict.status === 'conflict' ? 'bg-amber-50' : ''} 
                              ${conflict.status === 'conflict' ? 'cursor-pointer hover:bg-amber-100' : ''}`}
                    onClick={() => conflict.status === 'conflict' && toggleExpand(conflict.id)}
                  >
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {getItemName(conflict)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {getTypeLabel(conflict)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      <div className="flex items-center">
                        {conflict.status === 'conflict' && (
                          <AlertTriangle className="h-4 w-4 text-amber-500 mr-1" />
                        )}
                        {conflict.status === 'matched' && (
                          <Check className="h-4 w-4 text-green-500 mr-1" />
                        )}
                        {conflict.status === 'production-only' && 'Production Only'}
                        {conflict.status === 'sms-only' && 'SMS Only'}
                        {conflict.status === 'conflict' && 'Conflict'}
                        {conflict.status === 'matched' && 'Matched'}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {conflict.status === 'conflict' && (
                        <span
                          className={`px-2 py-1 rounded-full text-xs font-medium ${
                            resolutions[conflict.id] === 'production'
                              ? 'bg-blue-100 text-blue-800'
                              : resolutions[conflict.id] === 'sms'
                              ? 'bg-indigo-100 text-indigo-800'
                              : 'bg-green-100 text-green-800'
                          }`}
                        >
                          {resolutions[conflict.id] === 'production'
                            ? 'Production'
                            : resolutions[conflict.id] === 'sms'
                            ? 'SMS'
                            : 'Custom'}
                        </span>
                      )}
                      {conflict.status === 'matched' && 'No change needed'}
                      {conflict.status === 'production-only' && 'Keep in merged pathway'}
                      {conflict.status === 'sms-only' && 'Add to merged pathway'}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {conflict.status === 'conflict' && (
                        <button 
                          onClick={(e) => {
                            e.stopPropagation();
                            toggleExpand(conflict.id);
                          }}
                          className="text-indigo-600 hover:text-indigo-900 focus:outline-none"
                        >
                          {expandedItems[conflict.id] ? (
                            <ChevronDown className="h-5 w-5" />
                          ) : (
                            <ChevronRight className="h-5 w-5" />
                          )}
                        </button>
                      )}
                    </td>
                  </tr>
                  
                  {/* Expandable conflict view section */}
                  {expandedItems[conflict.id] && conflict.status === 'conflict' && (
                    <tr>
                      <td colSpan="5" className="px-6 py-4 bg-gray-50">
                        <div className="rounded border overflow-hidden">
                          <div className="bg-gray-100 px-4 py-2 font-medium flex justify-between items-center">
                            <span>Differences</span>
                            <span className={`px-2 py-0.5 rounded-full text-xs font-medium ${
                              resolutions[conflict.id] === 'production'
                                ? 'bg-blue-100 text-blue-800'
                                : resolutions[conflict.id] === 'sms'
                                ? 'bg-indigo-100 text-indigo-800'
                                : 'bg-green-100 text-green-800'
                            }`}>
                              {resolutions[conflict.id] === 'production'
                                ? 'Using Production Version'
                                : resolutions[conflict.id] === 'sms'
                                ? 'Using SMS Version'
                                : 'Using Custom Edits'}
                            </span>
                          </div>
                          
                          {/* Use specialized conflict view components based on type */}
                          <div className="p-4">
                            {renderConflictViewComponent(conflict)}
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      
      <div className="flex items-center justify-between border-t pt-6">
        <button
          onClick={onCancel}
          className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50 focus:outline-none"
        >
          <div className="flex items-center">
            <X className="h-4 w-4 mr-2" />
            Cancel
          </div>
        </button>
        
        <button
          onClick={onConfirm}
          className="px-6 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 focus:outline-none"
        >
          <div className="flex items-center">
            <Check className="h-4 w-4 mr-2" />
            Confirm and Merge
          </div>
        </button>
      </div>
    </div>
  );
};

export default MergeConfirmation; 