import { ErrorMessage } from '@hookform/error-message'
import { useFormContext } from 'react-hook-form'
import styled from 'styled-components'
import AlertBox from './AlertBox'
import { ErrorText } from './ErrorText'
import { ParamDescription, ParamTitle } from './ParamLabels'

export default function Input({
  fieldName,
  label,
  description,
  controller,
  alertType,
  alertText,
  ...props
}) {
  const {
    formState: { errors },
    register,
  } = useFormContext()

  return (
    <div className="flex flex-col w-full">
      {label && <ParamTitle>{label}</ParamTitle>}
      {description && <ParamDescription>{description}</ParamDescription>}
      {!controller
        ? (
            <StyledInput {...register(fieldName)} {...props} />
          )
        : (
            <StyledInput {...props} />
          )}
      {alertType && <AlertBox type={alertType} text={alertText} />}
      <ErrorMessage
        errors={errors}
        name={fieldName}
        render={({ message }) => <ErrorText message={message} />}
      />
    </div>
  )
}

const StyledInput = styled.input`
  text-align: left;
  border: 1px solid #eeeeee;
  border-radius: 4px;
  width: 100%;
  margin-top: 10px;
  padding: 0px 12px;
  background-color: #fbfbfa;
  height: 35px !important;
  cursor: pointer;
  &::placeholder {
    color: #a8a8a1;
  }
  &:focus {
    outline: none;
  }
  &:hover {
    cursor: text;
  }
  &:disabled {
    opacity: 45%;
    cursor: not-allowed;
  }
`
