import { createContext } from 'react'

const onboardingState = {
  run: false,
  stepIndex: 0,
  steps: [],
  tourActive: false,
  sideMenuOpen: false,
  personaId: null,
}

export const OnboardingContext = createContext({
  state: onboardingState,
  setState: () => undefined,
})
OnboardingContext.displayName = 'OnboardingContext'
