import { Input } from '@/components/ui/input'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { ArrowDownAZ, ArrowDownUp } from 'lucide-react'
import { useMemo, useState } from 'react'

function NodeSelect({
  nodes,
  value,
  onChange,
  placeholder = 'Select a node',
}) {
  const [searchTerm, setSearchTerm] = useState('')
  const [sortMode, setSortMode] = useState('flow') // 'flow' or 'alpha'

  const filteredAndSortedNodes = useMemo(() => {
    const filtered = nodes.filter(node =>
      node.data.name.toLowerCase().includes(searchTerm.toLowerCase()),
    )

    if (sortMode === 'alpha') {
      return filtered.sort((a, b) => a.data.name.localeCompare(b.data.name))
    }
    // For 'flow' mode, we keep the original order
    return filtered
  }, [nodes, searchTerm, sortMode])

  return (
    <div className="space-y-1.5">
      <Select onValueChange={onChange} value={value}>
        <SelectTrigger className="w-full border border-gray-300 py-4">
          <SelectValue placeholder={placeholder}>
            {value && (
              <div className="flex flex-col">
                <span className="font-medium">
                  {nodes.find(n => n.id === value)?.data.name}
                </span>
              </div>
            )}
          </SelectValue>
        </SelectTrigger>
        <SelectContent className="w-[var(--radix-select-trigger-width)] max-h-[320px]">
          <div className="flex flex-col h-full">
            <div className="sticky -top-4 bg-white z-50 p-2.5 space-y-1.5 border-b shadow-sm">
              <Input
                placeholder="Search nodes..."
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
                onKeyDown={e => e.stopPropagation()}
                className="w-full"
              />
              <div className="flex justify-end space-x-1.5">
                <button
                  onClick={() => setSortMode('flow')}
                  className={`flex items-center gap-0.5 text-2xs px-1.5 py-0.5 rounded transition-colors ${
                    sortMode === 'flow'
                      ? 'bg-gray-200 text-gray-900'
                      : 'text-gray-500 hover:text-gray-900'
                  }`}
                >
                  <ArrowDownUp size={14} />
                  <span>Flow Order</span>
                </button>
                <button
                  onClick={() => setSortMode('alpha')}
                  className={`flex items-center gap-0.5 text-2xs px-1.5 py-0.5 rounded transition-colors ${
                    sortMode === 'alpha'
                      ? 'bg-gray-200 text-gray-900'
                      : 'text-gray-500 hover:text-gray-900'
                  }`}
                >
                  <ArrowDownAZ size={14} />
                  <span>A-Z</span>
                </button>
              </div>
            </div>
            <div className="overflow-y-auto">
              {filteredAndSortedNodes.length === 0
                ? (
                    <div className="p-1.5 text-xs text-gray-500 text-center">
                      No nodes found
                    </div>
                  )
                : (
                    filteredAndSortedNodes.map(node => (
                      <SelectItem
                        key={node.id}
                        value={node.id}
                        className="py-1.5 px-2.5 cursor-pointer"
                      >
                        <div className="flex flex-col ml-2.5">
                          <span className="font-medium">{node.data.name}</span>
                          <span className="text-2xs text-gray-500">
                            ID:
                            {' '}
                            {node.id}
                          </span>
                        </div>
                      </SelectItem>
                    ))
                  )}
            </div>
          </div>
        </SelectContent>
      </Select>
    </div>
  )
}

export default NodeSelect
