import AlertBox from 'components/core/AlertBox'
import { Loading } from 'components/core/Loading'
import { useAuth } from 'hooks/useAuth'
import { useContext, useEffect, useState } from 'react'
import { $fetch } from 'utils/fetch'
import { getAuthToken } from 'utils/funcs/browser/getAuthToken'
import FlowContext from '../contextFlow'

function SMSNodeModal({ id, data, setIsOpen }) {
  const [name, setName] = useState(data.name || '')
  const [message, setMessage] = useState(data.message || '')
  const [fromNumber, setFromNumber] = useState(data.fromNumber || '')
  const [hasAccess, setHasAccess] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  const maxCharCount = 306

  const { user } = useAuth()
  const { triggerUpdate, elements } = useContext(FlowContext)

  const authToken = getAuthToken()

  useEffect(() => {
    const checkAccess = async () => {
      try {
        const response = await $fetch(`/convo_pathway/sms_access`, {
          method: 'GET',
          headers: {
            authorization: getAuthToken(),
          },
          ignoreResponseError: true,
        })

        if (response.errors) {
          console.error(
            'Failed to check enterprise access:',
            response.statusText,
          )
          return
        }
        const access = response.data
        console.log('access', access)
        setHasAccess(access.enabled)
      }
      catch (error) {
        console.error('Failed to check enterprise access:', error)
      }
      finally {
        setIsLoading(false)
      }
    }

    checkAccess()
  }, [authToken])

  const handleSave = () => {
    const updatedNodes = elements.nodes.map((el) => {
      if (el.id === id) {
        return {
          ...el,
          data: { name, message, fromNumber, isStart: data.isStart ?? false },
          type: 'SMS',
        }
      }
      return el
    })

    setIsOpen(false)
    triggerUpdate({ nodes: updatedNodes }, false)
  }

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-24">
        <Loading loading={isLoading} />
      </div>
    )
  }

  if (!hasAccess) {
    return (
      <div className="space-y-2.5">
        <AlertBox
          type="warning"
          text="This feature is currently for enterprise users only. Contact us here to get on our enterprise plan"
        />
        <button
          className="px-2.5 py-2 bg-indigo-500 text-white hover:bg-indigo-800 rounded-"
          onClick={() =>
            window.open('https://app.bland.ai/enterprise', '_blank')}
        >
          Contact Us
        </button>
      </div>
    )
  }

  return (
    <div className="space-y-2.5">
      <div className="space-y-1.5">
        <label
          htmlFor="name"
          className="block text-[12px] font-medium text-gray-700"
        >
          Name
        </label>
        <input
          type="text"
          id="name"
          value={name}
          onChange={e => setName(e.target.value)}
          className="w-full px-1.5 py-2 border border-gray-300 rounded shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-[12px]"
          placeholder="Enter node name"
        />
      </div>

      <div className="space-y-1.5">
        <label
          htmlFor="fromNumber"
          className="block text-[12px] font-medium text-gray-700"
        >
          From Number (Optional)
        </label>
        <input
          type="text"
          id="fromNumber"
          value={fromNumber}
          onChange={e => setFromNumber(e.target.value)}
          className="w-full px-1.5 py-2 border border-gray-300 rounded shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-[12px]"
          placeholder="Enter sender's phone number (optional)"
        />
      </div>

      <div className="space-y-1.5">
        <label
          htmlFor="message"
          className="block text-[12px] font-medium text-gray-700"
        >
          SMS Message
        </label>
        <textarea
          id="message"
          value={message}
          onChange={e => setMessage(e.target.value.slice(0, maxCharCount))}
          className="w-full px-2 py-1.5 border border-gray-300 rounded shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
          rows={4}
          placeholder="Enter your SMS message here"
        >
        </textarea>
        <div className="text-right text-xs text-gray-500">
          {message.length}
          /
          {maxCharCount}
          {' '}
          characters
        </div>
      </div>

      <button
        onClick={handleSave}
        className="bg-indigo-500 text-white py-1.5 px-2.5 rounded mt-2.5"
      >
        Save
      </button>
    </div>
  )
}

export default SMSNodeModal
