import axios from 'axios'
import config from 'config'
import { getOrgId } from 'utils/funcs/browser/getOrgId'

export async function getActivePathwayCallLogs(callId, apiKey) {
  const orgId = getOrgId()
  const headers = {
    authorization: `${apiKey}`,
    ...(orgId && { 'x-bland-org-id': orgId }),
  }
  try {
    const { data } = await axios.get(
      `${config.API_URL}/v1/pathway_calls/${callId}`,
      { headers },
    )

    return data
  }
  catch (error) {
    console.error(
      'Error fetching call transcript from getActivePathwayCallLogs()',
      error,
    )
  }
}
// Compare this snippet from src/utils/funcs/byot/getOutbound.js:
