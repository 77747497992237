import React from 'react'

const FlowContext = React.createContext({
  isEditingEdge: null,
  setIsEditingEdge: () => {},
  elements: {},
  triggerUpdate: () => {},
  setElements: () => {},
  deleteElement: () => {},
  duplicateNode: () => {},
  exportFlow: () => {}, // Initial placeholder function
  setNodes: () => {}, // You might still want to pass these if needed elsewhere
  setEdges: () => {},
  variables: {},
  setVariables: () => {},
  selectedVersion: {},
  setSelectedVersion: () => {},
  embedMode: false,
  id: null,
  setCollapsePathwayLogs: () => {},
  callLogs: [],
  chatConversationHistory: [],
  setChatConversationHistory: () => {},
  chatStartNode: null,
  setChatStartNode: () => {},
  callId: null,
  setGlobalPrompt: () => {},
  selectionStateRef: null,
  setShowEdgeSidebar: () => {},
  undo: () => {},
  isCopilotSelectionMode: false,
  setIsCopilotSelectionMode: () => {},
  selectedCopilotElements: [],
  setSelectedCopilotElements: () => {},
  isNodeModalOpen: false,
  setIsNodeModalOpen: () => {},
})

export default FlowContext
