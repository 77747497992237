import { addToast, Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Tab, Tabs, Textarea } from '@heroui/react'
import { Icon } from '@iconify/react'
import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import PhoneInput from 'components/Hero/PhoneInput'
import { useMutation } from '@tanstack/react-query'
import { $fetch } from 'utils/fetch'

// interface BulkInsertModalProps {
//   isOpen: boolean;
//   onClose: () => void;
//   memoryId?: string;
// }

export function BulkInsertModal({ isOpen, onClose, memoryId, onSuccess }) {
  const [csvFile, setCsvFile] = useState(null)
  const [isUploading, setIsUploading] = useState(false)
  const [insertMode, setInsertMode] = useState('single')

  const methods = useForm({
    defaultValues: {
      phone_number: '',
      metadata: '',
      summary: '',
    },
  })

  const { handleSubmit, watch, setValue } = methods
  const phoneNumber = watch('phone_number')

  // log phone number value
  console.log('phoneNumber', phoneNumber)

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setCsvFile(e.target.files[0])
    }
  }

  const handleBulkSubmit = (e) => {
    e.preventDefault()
    if (!csvFile)
      return

    setIsUploading(true)
    // Simulate upload process
    setTimeout(() => {
      setIsUploading(false)
      onClose()
      // Add success notification here
    }, 1500)
  }

  const addUser = useMutation({
    mutationFn: (data) => {
      return $fetch(`/v1/memory/${memoryId}/add-user`, {
        method: 'POST',
        body: JSON.stringify(data),
      })
    },
    onSuccess: () => {
      addToast({
        title: 'User added successfully',
        description: 'The user has been added to the memory store',
        variant: 'success',
      })
      onSuccess()
    },
    onError: () => {
      addToast({
        title: 'Failed to add user',
        description: 'Please try again',
        variant: 'error',
      })
    },
  })

  const onSingleUserSubmit = (data) => {
    setIsUploading(true)
    // Simulate single user insertion
    addUser.mutate(data)
    setIsUploading(false)
    onClose()
  }

  const handleMetadataChange = (e) => {
    setValue('metadata', e.target.value)
  }

  const handleSummaryChange = (e) => {
    setValue('summary', e.target.value)
  }

  return (
    <Modal isOpen={isOpen} onOpenChange={onClose} size="2xl">
      <ModalContent>
        {onClose => (
          <>
            <ModalHeader className="flex flex-col gap-1">Insert Users</ModalHeader>
            <ModalBody>
              <div className="mb-4">
                <Tabs 
                  selectedKey={insertMode}
                  onSelectionChange={setInsertMode}
                  aria-label="Insert mode options"
                  className="mb-6"
                >
                  <Tab key="single" title="Single User">
                    <div className="pt-4">
                      <FormProvider {...methods}>
                        <form onSubmit={handleSubmit(onSingleUserSubmit)} className="space-y-4">
                          <div>
                            <label className="block text-sm font-medium text-default-700 mb-2">
                              Phone Number *
                            </label>
                            <PhoneInput
                              value={phoneNumber}
                              onChange={(e) => setValue('phone_number', e)}
                              key={"phone_number"}
                              name={"phone_number"}
                              control={methods.control}
                            />
                          </div>

                          <div>
                            <label className="block text-sm font-medium text-default-700 mb-2">
                              Metadata (Any generic info you want the agent to know about the user)
                            </label>
                            <Textarea
                              value={methods.watch('metadata')}
                              onChange={handleMetadataChange}
                              placeholder='21 year old male from New York'
                              rows={4}
                            />
                          </div>

                          <div>
                            <label className="block text-sm font-medium text-default-700 mb-2">
                              Previous Calls Summary (optional)
                            </label>
                            <Textarea
                              value={methods.watch('summary')}
                              onChange={handleSummaryChange}
                              placeholder="Summary of previous conversations..."
                              rows={4}
                            />
                          </div>
                        </form>
                      </FormProvider>
                    </div>
                  </Tab>
                  <Tab key="bulk" title="Bulk CSV Upload" isDisabled={true}>
                    <div className="pt-4">
                      <div className="bg-blue-50 border-l-4 border-blue-500 p-4 text-sm">
                        <div className="flex">
                          <div className="flex-shrink-0">
                            <Icon icon="lucide:info" className="h-5 w-5 text-blue-500" />
                          </div>
                          <div className="ml-3">
                            <p className="text-blue-700">
                              Upload a CSV file with the following columns:
                            </p>
                            <ul className="list-disc ml-5 mt-1 text-blue-700">
                              <li>phone_number (required)</li>
                              <li>metadata (any information about the user)</li>
                              <li>summary (optional previous conversations summary)</li>
                            </ul>
                          </div>
                        </div>
                      </div>

                      <form onSubmit={handleBulkSubmit}>
                        <div className="mb-4">
                          <label className="block text-sm font-medium text-default-700 mb-2">
                            Upload CSV File
                          </label>
                          <div className="border-2 border-dashed border-default-300 rounded-md p-6 text-center">
                            {csvFile
                              ? (
                                  <div className="text-sm text-default-900">
                                    <p className="font-medium">{csvFile.name}</p>
                                    <p className="text-default-500">
                                      {(csvFile.size / 1024).toFixed(2)}
                                      {' '}
                                      KB
                                    </p>
                                  </div>
                                )
                              : (
                                  <div className="space-y-2">
                                    <div className="flex justify-center">
                                      <Icon icon="lucide:upload" className="h-8 w-8 text-default-400" />
                                    </div>
                                    <p className="text-sm text-default-500">
                                      Drag and drop your CSV file here, or
                                    </p>
                                    <label className="inline-flex items-center px-4 py-2 text-sm font-medium text-blue-700 bg-blue-50 border border-blue-200 rounded-md hover:bg-blue-100 cursor-pointer">
                                      Browse Files
                                      <input
                                        type="file"
                                        accept=".csv"
                                        className="sr-only"
                                        onChange={handleFileChange}
                                      />
                                    </label>
                                  </div>
                                )}
                          </div>
                        </div>
                      </form>
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="danger" variant="light" onPress={onClose}>
                Cancel
              </Button>
              <Button
                color="primary"
                isDisabled={(insertMode === 'bulk' && !csvFile) || 
                           isUploading}
                isLoading={isUploading}
                onPress={insertMode === 'single' 
                  ? methods.handleSubmit(onSingleUserSubmit)
                  : handleBulkSubmit}
              >
                {isUploading ? 'Inserting...' : 'Insert User(s)'}
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  )
}
