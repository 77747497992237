import { AiOutlineLoading } from 'react-icons/ai'

// Passes loading state from parent component into this.
export function Loading({ loading, color }) {
  return loading
    && (color === 'white'
      ? (
          <div className="flex flex-col h-full w-full">
            <AiOutlineLoading className="mx-auto text-white animate-spin my-auto text-lg" />
          </div>
        )
      : (
          <div className="flex flex-col h-full w-full">
            <AiOutlineLoading className="mx-auto text-gray-800 animate-spin my-auto text-xl" />
          </div>
        ))
}
