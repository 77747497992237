import { Accordion, AccordionItem, cn } from '@heroui/react'
import { useFieldRegistration } from 'hooks/useFieldRegistration'

export default function AccordionForm({ formDefinition, className }) {
  const { registerField } = useFieldRegistration()

  return (
    <Accordion
      defaultExpandedKeys={formDefinition.defaultExpandedKeys}
      selectionMode="multiple"
      className={cn('px-0', className)}
      itemClasses={{
        heading: 'data-[open=true]:sticky data-[open=true]:top-0 px-5 data-[open=true]:shadow-sm bg-white opacity-90 z-10 border-b border-gray-200',
        content: 'flex flex-col gap-5 bg-gray-50 bg-opacity-10 pb-5 pt-5 px-5',
        title: 'font-taurus font-medium',
      }}
      keepContentMounted
    >
      {formDefinition.sections.map((section) => {
        const { fields, ...accordionProps } = section
        return (
          <AccordionItem id={section.key} key={section.key} {...accordionProps}>
            {fields.map((field) => {
              return registerField(field)
            })}
          </AccordionItem>
        )
      })}
    </Accordion>
  )
}
