import { cn } from '@/lib/utils' // Assume you have a utility for class name merging
import { Select as UiSelect } from '@radix-ui/themes'
import { cva } from 'class-variance-authority'
import {
  Check,
  ChevronsUpDown,
} from 'lucide-react'
import React, { useEffect, useRef, useState } from 'react'
import { Description, Label } from './Text'

const selectTriggerVariants = cva(
  'flex w-full items-center text-[16.5px]justify-between rounded-[6px] px-3 py-1.5 text-[16px] leading-none focus:outline-none',
  {
    variants: {
      variant: {
        default: 'bg-[#f6f8fa]',
        outline: 'border border-solid',
      },
      state: {
        default: '',
        error: 'border border-[#e63946] shadow-[0_0_5px_rgba(230,57,70,0.3)]',
        success:
          'border border-[#3bceac] shadow-[0_0_5px_rgba(59,206,172,0.3)]',
      },
    },
    defaultVariants: {
      variant: 'default',
      state: 'default',
    },
  },
)

const SelectItem = React.forwardRef(
  ({ children, className, ...props }, forwardedRef) => {
    return (
      <UiSelect.Item
        // className={cn(
        //   "relative flex h-[35px] select-none items-center pl-4 pr-5 text-[14px] leading-none data-[disabled]:pointer-events-none data-[highlighted]:bg-[#f6f8fa] data-[highlighted]:outline-none w-full",
        //   className,
        // )}
        {...props}
        style={{ cursor: 'pointer', fontSize: 16.5 }}
        ref={forwardedRef}
      >
        <p>{children}</p>
      </UiSelect.Item>
    )
  },
)

export const BasicSelect = React.forwardRef(
  (
    {
      options,
      placeholder = 'Select an option',
      label,
      description,
      variant = 'default',
      state = 'default',
      icon: Icon,
      className,
      width = '100%',
      maxHeight = '100%',
      value,
      onValueChange,
      ...props
    },
    ref,
  ) => {
    return (
      <div className="relative" style={{ width }}>
        {(label || description) && (
          <div className="flex flex-col mb-2">
            {label && <Label>{label}</Label>}
            {description && <Description>{description}</Description>}
          </div>
        )}

        <UiSelect.Root
          {...props}
          value={value || 'Select an option'}
          onValueChange={onValueChange}
        >
          <UiSelect.Trigger
            // placeholder={placeholder}
            // </UiSelect.Root>value={value}
            className={cn(
              selectTriggerVariants({ variant, state }),
              Icon ? 'pl-8' : 'pl-3',
              className,
            )}
            ref={ref}
            style={{
              width,
              height: 36,
              borderRadius: 6,
              cursor: 'pointer',
              fontSize: 16.5,
              ...props.style,
            }}
          >
            {Icon && (
              <div className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400">
                {React.cloneElement(Icon, {
                  size: 14,
                  className: 'stroke-[2px]',
                  style: { marginTop: 1 },
                })}
              </div>
            )}
            <p style={{ marginLeft: Icon ? 20 : 0, fontSize: 16.5 }}>
              {value || placeholder}
            </p>
          </UiSelect.Trigger>
          <UiSelect.Content
            variant="soft"
            color="gray"
            className="overflow-hidden rounded bg-white shadow-lg p-0"
            position="popper"
            sideOffset={5}
            style={{ width, maxHeight }}
          >
            {options.map(option => (
              <SelectItem key={option.value} value={option.value}>
                {option.key}
              </SelectItem>
            ))}
          </UiSelect.Content>
        </UiSelect.Root>
      </div>
    )
  },
)

export function Dropdown({
  options = [],
  value,
  onChange,
  onOpenStateChange = () => {},
  placeholder = 'Select an option...',
  className = '',
  disabled = false,
  icon: IconComponent = null,
  dropdownClass = '',
}) {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState(value)
  const dropdownRef = useRef(null)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [])

  const handleSelect = (option) => {
    setSelectedOption(option)
    onChange?.(option)
    setIsOpen(false)
  }

  const getDisplayValue = () => {
    if (!selectedOption)
      return placeholder
    return typeof selectedOption === 'object'
      ? selectedOption.key
      : selectedOption
  }

  useEffect(() => {
    onOpenStateChange(!isOpen)
  }, [isOpen])

  return (
    <div ref={dropdownRef} className={`relative w-full ${className}`}>
      <button
        type="button"
        onClick={() => !disabled && setIsOpen(!isOpen)}
        className={`
          w-full px-3 py-1.5 text-left bg-white rounded-md shadow-sm
          border border-gray-200 outline-none
          transition-all duration-200 ease-in-out
          ${disabled ? 'cursor-not-allowed bg-gray-50 text-gray-500' : 'hover:bg-[#fafbfc]'}
        `}
      >
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-1.5">
            {IconComponent && (
              <IconComponent className="w-3 h-3 text-gray-500" />
            )}
            <span
              className={selectedOption ? 'text-gray-900' : 'text-gray-500'}
              style={{ fontSize: 16.5, fontWeight: 400 }}
            >
              {getDisplayValue()}
            </span>
          </div>
          <ChevronsUpDown
            className={`w-4 h-4 text-gray-400 transition-transform duration-200
            ${isOpen ? 'transform rotate-180' : ''}`}
          />
        </div>
      </button>

      {isOpen && (
        <div
          className={`
            absolute z-10 w-full mt-0.5 bg-white rounded-md shadow-lg
            border border-gray-200 overflow-auto
            animate-in fade-in slide-in-from-top-1.5 duration-200
            ${dropdownClass}
          `}
        >
          <ul className="py-0.5">
            {options.map((option) => {
              const isSelected = selectedOption?.value === option.value
              return (
                <li
                  key={option.value}
                  onClick={() => handleSelect(option)}
                  className={`
                    px-3 py-1.5 cursor-pointer text-gray-900
                    transition-colors duration-150 ease-in-out
                    hover:bg-blue-50 group
                    ${isSelected ? 'bg-blue-50 text-blue-600' : ''}
                  `}
                >
                  <div className="flex items-center justify-between">
                    <span style={{ fontSize: 16, fontWeight: 400 }}>
                      {option.key}
                    </span>
                    {isSelected && (
                      <Check className="w-4 h-4 text-blue-600 animate-in fade-in duration-200" />
                    )}
                  </div>
                </li>
              )
            })}
          </ul>
        </div>
      )}
    </div>
  )
}
