import { TooltipText } from '_components/Text'
import RangeInput from 'components/core/RangeInput'
import { useFormContext, useWatch } from 'react-hook-form'

export default function InterruptionThresh({ fieldName }) {
  const currentThreshold = useWatch({
    name: fieldName,
    control: useFormContext().control,
  })

  const description = (
    <span>
      Adjusts how patient the AI is when waiting for the user to finish
      speaking.
      <br />
      <br />
      Lower values mean the AI will respond more quickly, while higher values
      mean the AI will wait longer before responding.
      <br />
      <br />
      Recommended range: 50-200
      <br />
      <br />
      <b>50</b>
      : Extremely quick, back and forth conversation
      <br />
      <b>100</b>
      : Balanced to respond at a natural pace
      <br />
      <b>200</b>
      : Very patient, allows for long pauses and interruptions. Ideal
      for collecting detailed information.
      <br />
      <br />
      Try to start with 100 and make small adjustments in increments of ~10 as
      needed for your use case.
    </span>
  )

  return (
    <RangeInput
      label={(
        <TooltipText
          tooltip={description}
          tooltipClassName="w-[300px] h-[600px]"
        >
          {`Interruption Threshold: ${currentThreshold} ms`}
        </TooltipText>
      )}
      fieldName={fieldName}
      min="50"
      max="200" // this needs to stay a low value so we aren't giving people the ability to utterly ruin the call experience. IMO 500 is still crazy and 250 ish is the highest that will work well.
      step="10"
      sliderValue={currentThreshold}
      value={currentThreshold}
      id="threshSlider"
      type="range"
    />
  )
}
