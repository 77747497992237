import { Button } from '@/components/ui/button'
import { Card } from '@/components/ui/card'
import { Checkbox } from '@/components/ui/checkbox'
import { Label } from '@/components/ui/label'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'
import { ClipboardCopy, Clock, Filter, Meh, Smile, Sparkles } from 'lucide-react'
import { useEffect, useState } from 'react'

export default function KeyMarkers({ call, onKeyMarkersChange, keyMarkers, onMarkerClick }) {
  // State for marker filters
  const [markerFilters, setMarkerFilters] = useState({
    variableExtraction: true,
    pathwayTag: true,
    decision: false,
  })


  // Extract markers from pathway logs
  useEffect(() => {
    if (!call?.pathway_logs) {
      // If no pathway logs, use empty array
      onKeyMarkersChange([])
      return
    }

    try {
      const markers = []
      const firstPathwayLogTranscript = call.pathway_logs?.find(
        log => log.role && log.text && !(log.role === 'user' && log.text.includes('extended silence')),
      )
      const firstPathwayLogTime = firstPathwayLogTranscript
        ? new Date(firstPathwayLogTranscript.created_at).getTime()
        : null


      // Extract variable extraction logs
      if (markerFilters.variableExtraction) {
        const variableExtractionLogs = call.pathway_logs.filter(
          log => log.pathway_info && log.pathway_info.includes('Variables being extracted'),
        )


        variableExtractionLogs.forEach((log) => {
          // Calculate relative timestamp from first log
          const logTime = new Date(log.created_at).getTime()
          const relativeTime = firstPathwayLogTime ? (logTime - firstPathwayLogTime) / 1000 : 0

          // Try to extract variable names from the log
          let variableName = 'Variables'
          try {
            if (typeof log.pathway_info === 'string') {
              const info = JSON.parse(log.pathway_info)
              if (info && typeof info === 'object' && info['Variables being extracted']) {
                variableName = Object.keys(info['Variables being extracted']).join(', ')
              }
            }
          }
          catch (e) {
            // If parsing fails, use default name
            console.log('Failed to parse variable extraction info:', e)
          }

          markers.push({
            name: `Variable Extraction: ${variableName}`,
            timestamp: Math.max(0, relativeTime),
            type: 'variable_extraction',
            details: log.pathway_info,
            rawTimestamp: log.created_at,
          })
        })
      }

      // Extract pathway tags
      if (markerFilters.pathwayTag) {
        const pathwayTags = call.pathway_logs.filter(log => log.tag !== null && log.tag !== undefined)

        pathwayTags.forEach((log) => {
          if (!log.tag || !log.tag.name) return // Skip if tag or tag.name is missing
          
          const logTime = new Date(log.created_at).getTime()
          const relativeTime = firstPathwayLogTime ? (logTime - firstPathwayLogTime) / 1000 : 0

          markers.push({
            name: `Pathway Tag: ${log.tag.name}`,
            timestamp: Math.max(0, relativeTime),
            type: 'pathway_tag',
            details: log.tag,
            rawTimestamp: log.created_at,
          })
        })
      }

      // Extract decisions
      if (markerFilters.decision) {
        const decisionLogs = call.pathway_logs.filter(log => log.decision)

        decisionLogs.forEach((log) => {
          const logTime = new Date(log.created_at).getTime()
          const relativeTime = firstPathwayLogTime ? (logTime - firstPathwayLogTime) / 1000 : 0

          let decisionName = 'Decision'
          try {
            if (typeof log.decision === 'string') {
              const decision = JSON.parse(log.decision)

              if (decision && decision['Current Node Name'] && decision['Previous Node Name']) {
                decisionName = `(${decision['Previous Node Name']} -> ${decision['Current Node Name']})`
              }
            }
          }
          catch (e) {
            // If parsing fails, use default name
            console.log('Failed to parse decision info:', e)
          }

          markers.push({
            name: `Decision: ${decisionName}`,
            timestamp: Math.max(0, relativeTime),
            type: 'decision',
            details: log.decision,
            rawTimestamp: log.created_at,
          })
        })
      }

      // Sort markers by timestamp
      markers.sort((a, b) => a.timestamp - b.timestamp)

      // Share markers with parent component
      onKeyMarkersChange(markers)
    } catch (error) {
      console.error("Error processing markers:", error)
      onKeyMarkersChange([]) // Provide empty array on error
    }
  }, [call?.pathway_logs, markerFilters, onKeyMarkersChange])

  // Format time for display
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60)
    const seconds = Math.floor(time % 60)
    return `${minutes}:${seconds.toString().padStart(2, '0')}`
  }

  // Calculate timestamp from date or number
  const calculateTimestampFromDate = (markerDate) => {
    if (!markerDate)
      return 0

    if (typeof markerDate === 'number')
      return markerDate

    // If the marker timestamp is already relative to the audio start
    return markerDate
  }

  // Get icon for marker type
  const getMarkerTypeIcon = (type, role) => {
    switch (type) {
      case 'variable_extraction':
        return <ClipboardCopy size={12} className="text-emerald-500" />
      case 'pathway_tag':
        return <Sparkles size={12} className="text-amber-500" />
      case 'decision':
        return <Clock size={12} className="text-violet-500" />

      default:
        return <Clock size={12} className="text-gray-400" />
    }
  }

  // Format variable names as badges
  const formatVariableNames = (name) => {
    if (!name.startsWith('Variable Extraction:')) return name;
    
    const prefix = 'Variable Extraction:';
    const variablesText = name.substring(prefix.length).trim();
    
    if (!variablesText) return name;
    
    const variables = variablesText.split(',').map(v => v.trim()).filter(v => v);
    
    return (
      <div className="flex flex-col gap-1">
        <span className="font-medium text-gray-800">Variable Extraction</span>
        <div className="flex flex-wrap items-center justify-center gap-1 mt-1">
          {variables.length > 0 ? variables.map((variable, i) => (
            <span key={i} className="px-2 py-0.5 bg-emerald-50 text-emerald-700 text-xs rounded-full border border-emerald-200">
              {variable}
            </span>
          )) : 
          <span className="text-xs text-gray-500">No variables specified</span>}
        </div>
      </div>
    );
  }

  // Format pathway tag with color indicator
  const formatPathwayTag = (marker) => {
    const tagName = marker.details?.name || 'Unknown Tag';
    const tagColor = marker.details?.color || '#6B7280'; // Default gray if no color
    
    return (
      <div className="flex flex-col gap-1">
        <span className="font-medium text-gray-800">Pathway Tag</span>
        <div className="flex items-center justify-center gap-2 mt-1">
          <div 
            className="w-3 h-3 rounded-full" 
            style={{ backgroundColor: tagColor }}
          />
          <span className="text-sm text-gray-700">
            {tagName}
          </span>
        </div>
      </div>
    );
  }

  // Format decision with node transition
  const formatDecision = (name) => {
    if (!name.startsWith('Decision:')) return name;
    
    // Extract node names from format "Decision: (PreviousNode -> CurrentNode)"
    // Using a more robust pattern that handles commas within node names
    const match = name.match(/Decision:\s*\(([^->]+)\s*->\s*([^)]+)\)/);
    
    if (!match) return (
      <div className="flex flex-col gap-1">
        <span className="font-medium text-gray-800">Decision</span>
        <span className="text-xs text-gray-500">{name.replace('Decision:', '').trim()}</span>
      </div>
    );
    
    const prevNode = match[1].trim();
    const currNode = match[2].trim();
    
    return (
      <div className="flex flex-col gap-1">
        <span className="font-medium text-gray-800">Decision</span>
        <div className="flex items-center gap-1 mt-1 text-xs">
          <span className="px-2 py-0.5 bg-gray-100 text-gray-700 rounded">
            {prevNode}
          </span>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 3L14 8L8 13M14 8H2" stroke="#8B5CF6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
          <span className="px-2 py-0.5 bg-violet-100 text-violet-700 rounded">
            {currNode}
          </span>
        </div>
      </div>
    );
  }

  // Render marker content based on type
  const renderMarkerContent = (marker) => {
    switch (marker.type) {
      case 'variable_extraction':
        return formatVariableNames(marker.name);
      case 'pathway_tag':
        return formatPathwayTag(marker);
      case 'decision':
        return formatDecision(marker.name);
      default:
        return marker.name;
    }
  }

  return (
    <Card className="w-full p-6">
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center gap-2">
          <Clock size={16} className="text-violet-600" />
          <p className="text-sm font-medium text-gray-600">Call Timeline Markers</p>
        </div>
      </div>
      
      {/* Scrollable filter chips container with visual indicators */}
      <div className="relative mb-4">
        {/* Left shadow gradient when scrolled */}
        <div className="absolute left-0 top-0 bottom-0 w-8 bg-gradient-to-r from-white to-transparent z-10 pointer-events-none opacity-0 transition-opacity duration-300" 
             id="leftScrollIndicator">
        </div>
        
        {/* Right shadow gradient to indicate more content */}
        <div className="absolute right-0 top-0 bottom-0 w-8 bg-gradient-to-l from-white to-transparent z-10 pointer-events-none">
        </div>
        
        {/* Scrollable container with visible scrollbar */}
        <div 
          className="flex items-center gap-2 overflow-x-auto pb-2 pr-4 border-b border-gray-200 scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100"
          style={{ scrollbarWidth: 'thin' }} // For Firefox
          onScroll={(e) => {
            // Show/hide left indicator based on scroll position
            const leftIndicator = document.getElementById('leftScrollIndicator');
            if (leftIndicator) {
              leftIndicator.style.opacity = e.target.scrollLeft > 20 ? '1' : '0';
            }
          }}
        >
          {/* Filter buttons with small scroll indicator */}
          <div className="flex items-center mr-2 text-gray-400">
            <Filter size={14} />
            <span className="text-xs ml-1"> →</span>
          </div>
          
          <button
            onClick={() => setMarkerFilters({ ...markerFilters, variableExtraction: !markerFilters.variableExtraction })}
            className={`inline-flex items-center gap-1.5 px-3 py-1 rounded-full text-sm font-medium transition-all whitespace-nowrap ${
              markerFilters.variableExtraction 
                ? 'bg-emerald-100 text-emerald-800 border border-emerald-200' 
                : 'bg-gray-100 text-gray-600 border border-gray-200 opacity-70'
            }`}
          >
            <ClipboardCopy size={14} />
            <span>Variables</span>
            <span className="inline-flex items-center justify-center w-5 h-5 ml-1 text-xs font-semibold rounded-full bg-white text-emerald-800">
              {call?.pathway_logs?.filter(log => log.pathway_info && log.pathway_info.includes('Variables being extracted')).length || 0}
            </span>
          </button>
          
          <button
            onClick={() => setMarkerFilters({ ...markerFilters, pathwayTag: !markerFilters.pathwayTag })}
            className={`inline-flex items-center gap-1.5 px-3 py-1 rounded-full text-sm font-medium transition-all whitespace-nowrap ${
              markerFilters.pathwayTag 
                ? 'bg-amber-100 text-amber-800 border border-amber-200' 
                : 'bg-gray-100 text-gray-600 border border-gray-200 opacity-70'
            }`}
          >
            <Sparkles size={14} />
            <span>Tags</span>
            <span className="inline-flex items-center justify-center w-5 h-5 ml-1 text-xs font-semibold rounded-full bg-white text-amber-800">
              {call?.pathway_logs?.filter(log => log.tag !== null && log.tag !== undefined).length || 0}
            </span>
          </button>
          
          <button
            onClick={() => setMarkerFilters({ ...markerFilters, decision: !markerFilters.decision })}
            className={`inline-flex items-center gap-1.5 px-3 py-1 rounded-full text-sm font-medium transition-all whitespace-nowrap ${
              markerFilters.decision 
                ? 'bg-violet-100 text-violet-800 border border-violet-200' 
                : 'bg-gray-100 text-gray-600 border border-gray-200 opacity-70'
            }`}
          >
            <Clock size={14} />
            <span>Decisions</span>
            <span className="inline-flex items-center justify-center w-5 h-5 ml-1 text-xs font-semibold rounded-full bg-white text-violet-800">
              {call?.pathway_logs?.filter(log => log.decision).length || 0}
            </span>
          </button>
          
          {/* Add some dummy spacer at the end to ensure the right gradient is visible */}
          <div className="w-4 flex-shrink-0"></div>
        </div>
      </div>

      {/* Display markers */}
      <div className="flex flex-wrap gap-4">
        {keyMarkers && keyMarkers.length > 0
          ? (
              keyMarkers.map((marker, index) => (
                <button
                  type="button"
                  key={index}
                  onClick={() => {
                    // Seek to timestamp in audio player
                    const audioPlayerElement = document.querySelector('.audio-player-container')
                    if (audioPlayerElement) {
                      const event = new CustomEvent('seek-to-timestamp', {
                        detail: { timestamp: calculateTimestampFromDate(marker.timestamp) },
                      })
                      audioPlayerElement.dispatchEvent(event)
                    }

                    // Also notify parent component to switch tabs and highlight this marker
                    if (onMarkerClick) {
                      onMarkerClick(marker)
                    }
                  }}
                  className="flex-1 min-w-[200px] p-4 rounded-lg border border-gray-200
                hover:border-violet-200 bg-white hover:bg-violet-50
                transition-all group shadow-sm hover:shadow-md"
                >
                  <div className="flex flex-col gap-2">
                    {renderMarkerContent(marker)}
                    <div className="flex items-center gap-2 mt-1">
                      {getMarkerTypeIcon(marker.type, marker.role)}
                      <span className="text-xs text-gray-500 group-hover:text-violet-500">
                        {formatTime(calculateTimestampFromDate(marker.timestamp))}
                      </span>
                    </div>
                  </div>
                </button>
              ))
            )
          : (
              <p className="text-sm text-gray-500 w-full text-center py-4">
                No markers available with current filters
              </p>
            )}
      </div>
    </Card>
  )
}
