import { Dialog } from '@headlessui/react'
import { FolderIcon } from 'lucide-react'

function MovePathwayDialog({
  movingPathwayId,
  isOpen,
  onClose,
  folders,
  onMove,
}) {
  const handleOnMove = (folderId) => {
    const pathwayId = movingPathwayId
    onClose()
    onMove(pathwayId, folderId)
  }

  const renderFolders = (folderList, level = 0) => {
    return folderList.map(folder => (
      <div key={folder.id} className={`ml-${level * 4}`}>
        <button
          onClick={() => handleOnMove(folder.id)}
          className="flex items-center w-full text-left p-1.5 hover:bg-gray-100 rounded-sm"
        >
          <FolderIcon className="mr-1.5" size={16} />
          <span className="truncate">{folder.name}</span>
        </button>
        {folder.children
        && Array.isArray(folder.children)
        && renderFolders(
          folder.children.filter(child => child.type === 'folder'),
          level + 1,
        )}
      </div>
    ))
  }

  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-50">
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      <div className="fixed inset-0 flex items-center justify-center p-2.5">
        <Dialog.Panel className="w-full max-w-sm rounded-sm bg-white p-4 shadow-xl">
          <Dialog.Title className="text-sm font-medium mb-2.5">
            Move Pathway
          </Dialog.Title>
          <div className="max-h-36 overflow-y-auto">
            <button
              onClick={() => handleOnMove(null)}
              className="flex items-center w-full text-left p-1.5 hover:bg-gray-100 rounded-sm mb-1.5"
            >
              <FolderIcon className="mr-1.5" size={16} />
              <span className="truncate">Home</span>
            </button>
            {renderFolders(folders)}
          </div>
          <button
            type="button"
            className="inline-flex justify-center mt-2.5 px-2.5 py-1.5 text-xs font-medium text-gray-700 bg-white border border-gray-300 rounded hover:bg-gray-50 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-indigo-500"
            onClick={onClose}
          >
            Cancel
          </button>
        </Dialog.Panel>
      </div>
    </Dialog>
  )
}

export default MovePathwayDialog
