import { $fetch } from 'utils/fetch'

export async function testWebhook(url) {
  const testData = {
    call_id: '9ae89a18-729a-4df9-ae54-42754182e32f',
    transcripts: [
      {
        id: 45789,
        created_at: '2023-08-22T02:23:05.256341+00:00',
        text: 'Hey, is this Bobs Donuts.',
        user: 'user',
        c_id: '9ae89a18-729a-4df9-ae54-42754182e32f',
      },
      {
        id: 45790,
        created_at: '2023-08-22T02:23:08.227252+00:00',
        text: 'Hi how can I help you!',
        user: 'assistant',
        c_id: '9ae89a18-729a-4df9-ae54-42754182e32f',
      },
    ],
  }
  try {
    const response = await $fetch('/tools/run', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        url,
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        body: testData,
      }),
    })

    if (response.data) {
      return { success: true, res: response }
    }
  }
  catch (error) {
    console.error('Error webhook forwarding:', error.message)
    return { success: false, error: error.message }
  }
}
