import { forwardRef, Select, SelectItem } from '@heroui/react'
import { getLocalTimeZone } from '@internationalized/date'

const timezones = [
  { offset: '-12:00', identifier: 'Pacific/Wake' },
  { offset: '-11:00', identifier: 'Pacific/Samoa' },
  { offset: '-10:00', identifier: 'Pacific/Honolulu' },
  { offset: '-09:00', identifier: 'America/Anchorage' },
  { offset: '-08:00', identifier: 'America/Los_Angeles' },
  { offset: '-07:00', identifier: 'America/Denver' },
  { offset: '-06:00', identifier: 'America/Chicago' },
  { offset: '-05:00', identifier: 'America/New_York' },
  { offset: '-04:00', identifier: 'America/Halifax' },
  { offset: '-03:00', identifier: 'America/Sao_Paulo' },
  { offset: '-02:00', identifier: 'Atlantic/South_Georgia' },
  { offset: '-01:00', identifier: 'Atlantic/Azores' },
  { offset: '+00:00', identifier: 'Europe/London' },
  { offset: '+01:00', identifier: 'Europe/Paris' },
  { offset: '+02:00', identifier: 'Europe/Kyiv' },
  { offset: '+03:00', identifier: 'Europe/Moscow' },
  { offset: '+04:00', identifier: 'Asia/Dubai' },
  { offset: '+05:00', identifier: 'Asia/Karachi' },
  { offset: '+05:30', identifier: 'Asia/Kolkata' },
  { offset: '+06:00', identifier: 'Asia/Dhaka' },
  { offset: '+07:00', identifier: 'Asia/Bangkok' },
  { offset: '+08:00', identifier: 'Asia/Shanghai' },
  { offset: '+09:00', identifier: 'Asia/Tokyo' },
  { offset: '+10:00', identifier: 'Australia/Sydney' },
  { offset: '+11:00', identifier: 'Pacific/Noumea' },
  { offset: '+12:00', identifier: 'Pacific/Auckland' },
]

const TimezoneSelect = forwardRef(({ value, ...props }, ref) => {
  return (
    <Select
      label="Timezone"
      labelPlacement="outside"
      variant="bordered"
      radius="sm"
      defaultSelectedKeys={[getLocalTimeZone()]}
      selectedKeys={value && [value]}
      name="timezone"
      placeholder="Select a timezone"
      {...props}
      ref={ref}
    >
      {timezones.map(timezone => (
        <SelectItem
          key={timezone.identifier}
          value={timezone.identifier}
          startContent={
            <p className="text-xs font-geist-mono text-muted-foreground">{timezone.offset}</p>
          }
        >
          {timezone.identifier}
        </SelectItem>
      ))}
    </Select>
  )
})

export default TimezoneSelect
