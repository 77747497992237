import { forwardRef, Select, SelectItem } from '@heroui/react'
import { useQuery } from '@tanstack/react-query'
import { $fetch } from 'utils/fetch'

const AnalysisPresetSelect = forwardRef(({ value, ...props }, ref) => {
  const { data: analysisPresets, isLoading: analysisPresetsLoading } = useQuery({
    queryKey: ['analysisPresets'],
    queryFn: async () => {
      const { data } = await $fetch('/analysis_presets')
      return data
    },
  })

  return (
    <Select
      isLoading={analysisPresetsLoading}
      labelPlacement="outside"
      variant="bordered"
      radius="sm"
      isVirtualized
      placeholder="Select a preset"
      description="Select an Analysis Preset to use for this call. Analysis Presets define the structure for extracting specific information from the call."
      label="Analysis Preset"
      selectedKeys={value && [value]}
      {...props}
      ref={ref}
    >
      {analysisPresets?.map(analysisPreset => (
        <SelectItem key={analysisPreset.id}>
          {analysisPreset.name ?? 'Untitled Analysis Preset'}
        </SelectItem>
      ))}
    </Select>
  )
})

export default AnalysisPresetSelect
