function convertFlowToApiFormat(reactFlowNodes, reactFlowEdges, globalPrompt) {
  // Helper to safely remove function references before cloning
  const sanitizeForClone = (obj) => {
    if (!obj || typeof obj !== 'object')
      return obj

    // Special handling for data.setIsSideBarOpen
    if (obj.data && obj.data.setIsSideBarOpen) {
      const dataCopy = { ...obj.data }
      delete dataCopy.setIsSideBarOpen
      return { ...obj, data: dataCopy }
    }
    return obj
  }

  const exportNodes = reactFlowNodes.map((node) => {
    const cleanNode = structuredClone(node)
    const propsToRemove = [
      'selected',
      'width',
      'height',
      'dragging',
      'positionAbsolute',
      'x',
      'y',
    ]

    propsToRemove.forEach(prop => delete cleanNode[prop])

    if (cleanNode.data) {
      const dataPropsToRemove = ['globalPrompt', 'selected', 'active']
      dataPropsToRemove.forEach(prop => delete cleanNode.data[prop])
    }

    return cleanNode
  })

  exportNodes.push({
    globalConfig: {
      globalPrompt,
    },
  })

  const exportEdges = reactFlowEdges
    .filter(edge => edge.source || edge.target)
    .map((edge) => {
      // Sanitize the edge by removing function references before cloning
      const sanitizedEdge = sanitizeForClone(edge)
      const cleanEdge = structuredClone(sanitizedEdge)
      const propsToRemove = [
        'animated',
        'selected',
        'sourceHandle',
        'targetHandle',
      ]

      propsToRemove.forEach(prop => delete cleanEdge[prop])

      if (cleanEdge.data) {
        delete cleanEdge.data.setIsSideBarOpen
      }

      return cleanEdge
    })

  return {
    nodes: exportNodes,
    edges: exportEdges,
  }
}

function convertApiToFlowFormat(backendNodes, backendEdges) {
  // Filter out globalConfig and map remaining nodes
  const reactFlowNodes = backendNodes
    .filter(node => !node.globalConfig)
    .map((node) => {
      const flowNode = structuredClone(node)

      // Add default UI properties for React Flow nodes
      flowNode.selected = false
      flowNode.dragging = false

      if (!flowNode.position) {
        flowNode.position = {
          x: flowNode.x || 0,
          y: flowNode.y || 0,
        }
      }
      else {
        flowNode.position = {
          ...flowNode.position,
          x: (typeof flowNode.position.x === 'number') ? flowNode.position.x : (flowNode.x || 0),
          y: (typeof flowNode.position.y === 'number') ? flowNode.position.y : (flowNode.y || 0),
        }
      }

      return flowNode
    })

  const reactFlowEdges = backendEdges.map((edge) => {
    // const flowEdge = { ...edge };
    const flowEdge = structuredClone(edge)

    // Add default UI properties for React Flow edges
    flowEdge.animated = true
    flowEdge.selected = false
    flowEdge.sourceHandle = null
    flowEdge.targetHandle = null

    return flowEdge
  })

  const globalPrompt
      = backendNodes.find(node => node.globalConfig)?.globalConfig?.globalPrompt || backendNodes[0]?.data?.globalPrompt

  return {
    nodes: reactFlowNodes,
    edges: reactFlowEdges,
    globalPrompt,
  }
}

export { convertApiToFlowFormat, convertFlowToApiFormat }
