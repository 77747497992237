import { motion } from 'framer-motion'
import React, { useEffect, useMemo, useRef } from 'react'

/**
 * A loading animation component that displays a fluid, watercolor-like animation
 * with wave patterns that undulate and flow like ocean waves.
 *
 * @component
 * @param {object} props - Component props
 * @param {string} [props.message] - Text message displayed below the animation
 * @param {('default'|'lavenderDusk'|'mintForest'|'desertGlow'|'roseGarden'|'sunsetCoral'|'arcticAurora')} [props.variant] - Color theme variant
 * @param {number} [props.duration] - A base duration in seconds that influences overall wave speed
 * @param {number} [props.width] - Width in Tailwind units (e.g. 12 = 3rem)
 * @param {('none'|'xs'|'sm'|'md'|'lg'|'xl'|'2xl'|'3xl'|'full')} [props.rounded] - Border radius style
 * @param {boolean} [props.gray] - Whether to use a gray background overlay
 *
 * @returns {JSX.Element} A fluid, water-like animation with optional message
 */
function GradientLoadingAnimation({
  message = 'Loading...',
  variant = 'default',
  duration = 2,
  width = 24,
  rounded = 'full',
  gray = false,
}) {
  const canvasRef = useRef(null)
  const startTimeRef = useRef(performance.now())
  const animationIntensityRef = useRef(0.6) // Initial animation intensity
  const animationFrameIdRef = useRef(null)

  // Map variant names to shader variant codes
  const variantMap = useMemo(() => ({
    default: 0, // Pastel blue
    lavenderDusk: 6, // Lavender
    mintForest: 7, // Mint
    desertGlow: 8, // Golden
    roseGarden: 9, // Coral
    sunsetCoral: 3, // Volcano
    arcticAurora: 5, // Mediterranean
  }), [])

  // Set up WebGL shader for water animation
  useEffect(() => {
    const canvas = canvasRef.current
    if (!canvas)
      return

    const gl = canvas.getContext('webgl')
    if (!gl) {
      console.error('WebGL not supported.')
      return
    }

    // Speed factor based on duration prop (inverse relationship)
    const speedFactor = 2.0 / duration

    // ─── VERTEX SHADER ─────────────────────────────────────────────
    const vertexShaderSource = `
      attribute vec4 aPosition;
      void main() {
        gl_Position = aPosition;
      }
    `

    // ─── FRAGMENT SHADER ───────────────────────────────────────────
    const fragmentShaderSource = `
      precision highp float;
      uniform float uTime;
      uniform vec2 uResolution;
      uniform int uVariant;
      uniform float uIntensity;
      
      // Simple hash for randomness
      float hash(vec2 p) {
          p = fract(p * vec2(123.34, 456.21));
          p += dot(p, p + 40.32);
          return fract(p.x * p.y);
      }
      
      // Fast hash for grain effect
      float fastHash(vec2 p) {
          return fract(sin(dot(p, vec2(12.9898, 78.233))) * 43758.5453);
      }
      
      // Grain noise - high frequency noise for texture
      float grain(vec2 p, float time, float intensity) {
          vec2 noisePos = p * 250.0; // High frequency
          noisePos += vec2(time * 10.0, 0.0); // Subtle animation
          
          float noise1 = fastHash(floor(noisePos));
          float noise2 = fastHash(floor(noisePos + vec2(1.0, 0.0)));
          
          // Mix noise samples for less grid-like appearance
          float mixFactor = fract(noisePos.x);
          float finalNoise = mix(noise1, noise2, mixFactor);
          
          // Adjust intensity
          return finalNoise * intensity;
      }
      
      // 2D noise function
      float noise(vec2 p) {
          vec2 i = floor(p);
          vec2 f = fract(p);
          float a = hash(i);
          float b = hash(i + vec2(1.0, 0.0));
          float c = hash(i + vec2(0.0, 1.0));
          float d = hash(i + vec2(1.0, 1.0));
          vec2 u = f * f * (3.0 - 2.0 * f);
          return mix(a, b, u.x) 
               + (c - a) * u.y * (1.0 - u.x) 
               + (d - b) * u.x * u.y;
      }
      
      // Fractal Brownian Motion (fbm)
      float fbm(vec2 p) {
          float total = 0.0;
          float amplitude = 0.8;
          for (int i = 0; i < 3; i++) {
              total += amplitude * noise(p);
              p *= 8.0;
              amplitude *= 0.1;
          }
          return total;
      }
      
      // Wave function
      float wave(float x, float freq, float speed, float amp, float time) {
          return sin(x * freq + time * speed) * amp;
      }
      
      void main() {
          // Normalize coordinates to [0,1] and center them ([-0.5, 0.5])
          vec2 uv = gl_FragCoord.xy / uResolution;
          vec2 centered = uv - 0.5;
          centered.x *= uResolution.x / uResolution.y;
          
          // Calculate distance from center for circular masking
          float dist = length(centered);
          
          // Base time value with adjustable speed
          float time = uTime * (2.0 + uIntensity * 0.5);
          
          // Create multiple horizontal wave patterns that move across the screen
          float wave1 = wave(centered.y, 8.0, 0.8, 0.12, time);
          float wave2 = wave(centered.y, 5.0, -0.5, 0.08, time);
          float wave3 = wave(centered.y * 0.8 + centered.x * 0.2, 12.0, 1.2, 0.05, time);
          float wave4 = wave(centered.y * 0.7 - centered.x * 0.3, 7.0, -0.9, 0.06, time);
          
          // Create wave interference patterns
          float waveX = centered.x + wave1 + wave2 + wave3 + wave4;
          float waveY = centered.y + wave2 * 0.3 + wave3 * 0.2;
          
          // Create additional waves that move specifically along x-axis
          float horizontalWave1 = wave(centered.x, 3.0, 0.7, 0.1, time);
          float horizontalWave2 = wave(centered.x, 7.0, -0.5, 0.07, time);
          
          // Add vertical displacement from horizontal waves
          waveY += horizontalWave1 * 0.5 + horizontalWave2 * 0.3;
          
          // Create vertical streaks for watercolor effect, but make them wave-like
          float verticalStreak = sin(waveX * 8.0 + time * 0.2) * 0.5 + 0.5;
          verticalStreak *= sin(waveX * 3.0 - time * 0.15) * 0.5 + 0.5;
          
          // Add slow-moving wave distortion
          float waveDistortion = fbm(vec2(waveX * 3.0, time * 0.1)) * 0.5;
          verticalStreak += waveDistortion;
          
          // Base gradient position using wavy coordinates
          float gradientPos = waveY + sin(waveX * 2.0 + time * 0.3) * 0.15;
          
          // Add breathing motion - rising and falling like ocean waves
          float breathing = sin(time * 0.2) * 0.08;
          gradientPos += breathing * (1.0 - dist * 1.5); // More effect in center, less at edges
          
          // Add soft noise for organic texture
          float noisePattern = fbm(vec2(waveX * 4.0, waveY * 4.0 + time * 0.1)) * 0.15;
          gradientPos += noisePattern;
          
          // Create watercolor texture effect with wave-like movement
          float watercolorTexture = fbm(vec2(waveX * 5.0, waveY * 2.0 + time * 0.08)) * 0.08;
          watercolorTexture += fbm(vec2(waveX * 10.0 + time * 0.05, waveY * 8.0)) * 0.04;
          
          // Normalize position for color mapping
          float normalizedPos = (gradientPos + 0.5) * 0.9;
          
          // Define color palettes based on variant
          vec3 colorTop, colorUpperMid, colorMid, colorLowerMid, colorBottom;
          
          if (uVariant == 0) { // Pastel blue (default)
              colorTop = vec3(1.0, 1.0, 1.0);         // Very light blue-white at top
              colorUpperMid = vec3(1.0, 0.9, 0.9);    // Light blue
              colorMid = vec3(0.2, 0.5, 0.95);        // Medium blue
              colorLowerMid = vec3(0.2, 0.6, 0.95);   // Deeper medium blue
              colorBottom = vec3(0.3, 0.6, 0.9);      // Deep blue at bottom
          }
          else if (uVariant == 1) { // Tropical
              colorTop = vec3(0.95, 1.0, 0.95);       // Light cyan-white
              colorUpperMid = vec3(0.7, 0.95, 0.9);   // Pale turquoise
              colorMid = vec3(0.3, 0.85, 0.8);        // Turquoise
              colorLowerMid = vec3(0.2, 0.7, 0.65);   // Darker turquoise
              colorBottom = vec3(0.1, 0.5, 0.6);      // Deep turquoise
          }
          else if (uVariant == 2) { // Sunset
              colorTop = vec3(1.0, 0.95, 0.8);        // Pale yellow
              colorUpperMid = vec3(1.0, 0.8, 0.6);    // Light orange
              colorMid = vec3(0.95, 0.6, 0.4);        // Orange
              colorLowerMid = vec3(0.8, 0.4, 0.4);    // Red-orange
              colorBottom = vec3(0.6, 0.2, 0.4);      // Deep red
          }
          else if (uVariant == 3) { // Volcano
              colorTop = vec3(1.0, 0.9, 0.7);         // Pale yellow
              colorUpperMid = vec3(0.9, 0.6, 0.3);    // Light orange
              colorMid = vec3(0.8, 0.3, 0.2);         // Red-orange
              colorLowerMid = vec3(0.6, 0.15, 0.1);   // Deep red
              colorBottom = vec3(0.4, 0.1, 0.05);     // Dark red-brown
          }
          else if (uVariant == 4) { // Stormy
              colorTop = vec3(0.9, 0.9, 0.95);        // Light gray
              colorUpperMid = vec3(0.7, 0.75, 0.8);   // Medium gray
              colorMid = vec3(0.5, 0.55, 0.65);       // Steel blue-gray
              colorLowerMid = vec3(0.3, 0.35, 0.45);  // Dark blue-gray
              colorBottom = vec3(0.15, 0.2, 0.3);     // Very dark blue-gray
          }
          else if (uVariant == 5) { // Mediterranean
              colorTop = vec3(0.9, 0.95, 1.0);        // Pale sky blue
              colorUpperMid = vec3(0.6, 0.8, 0.95);   // Sky blue
              colorMid = vec3(0.3, 0.65, 0.9);        // Azure
              colorLowerMid = vec3(0.15, 0.4, 0.8);   // Deep blue
              colorBottom = vec3(0.05, 0.25, 0.65);   // Navy blue
          }
          else if (uVariant == 6) { // Lavender
              colorTop = vec3(0.98, 0.95, 1.0);       // White with hint of purple
              colorUpperMid = vec3(0.9, 0.85, 0.98);  // Very light lavender
              colorMid = vec3(0.8, 0.7, 0.95);        // Lavender
              colorLowerMid = vec3(0.65, 0.5, 0.85);  // Medium purple
              colorBottom = vec3(0.5, 0.3, 0.7);      // Deep purple
          }
          else if (uVariant == 7) { // Mint
              colorTop = vec3(0.95, 1.0, 0.98);       // White with hint of green
              colorUpperMid = vec3(0.85, 0.98, 0.9);  // Very light mint
              colorMid = vec3(0.65, 0.92, 0.8);       // Mint green
              colorLowerMid = vec3(0.4, 0.8, 0.65);   // Medium mint
              colorBottom = vec3(0.2, 0.6, 0.5);      // Deep mint
          }
          else if (uVariant == 8) { // Golden
              colorTop = vec3(1.0, 0.98, 0.9);        // Cream
              colorUpperMid = vec3(1.0, 0.9, 0.7);    // Pale gold
              colorMid = vec3(0.95, 0.8, 0.4);        // Gold
              colorLowerMid = vec3(0.8, 0.6, 0.2);    // Deep gold
              colorBottom = vec3(0.6, 0.4, 0.1);      // Amber
          }
          else if (uVariant == 9) { // Coral
              colorTop = vec3(1.0, 0.95, 0.95);       // White with hint of pink
              colorUpperMid = vec3(1.0, 0.85, 0.8);   // Very light coral
              colorMid = vec3(0.98, 0.7, 0.65);       // Coral
              colorLowerMid = vec3(0.9, 0.5, 0.5);    // Medium coral
              colorBottom = vec3(0.7, 0.3, 0.4);      // Deep coral
          }
          else if (uVariant == 10) { // Midnight
              colorTop = vec3(0.6, 0.7, 0.8);         // Medium blue-gray
              colorUpperMid = vec3(0.3, 0.4, 0.6);    // Deep blue-gray
              colorMid = vec3(0.15, 0.2, 0.45);       // Navy blue
              colorLowerMid = vec3(0.05, 0.1, 0.3);   // Deep navy
              colorBottom = vec3(0.02, 0.05, 0.2);    // Almost black-blue
          }
          else { // Fallback to default
              colorTop = vec3(1.0, 1.0, 1.0);
              colorUpperMid = vec3(1.0, 0.9, 0.9);
              colorMid = vec3(0.2, 0.5, 0.95);
              colorLowerMid = vec3(0.2, 0.6, 0.95);
              colorBottom = vec3(0.3, 0.6, 0.9);
          }
          
          // Create smooth vertical gradient with multiple color steps
          vec3 color;
          if (normalizedPos < 0.2) {
              color = mix(colorBottom, colorLowerMid, normalizedPos * 5.0);
          } else if (normalizedPos < 0.4) {
              color = mix(colorLowerMid, colorMid, (normalizedPos - 0.2) * 5.0);
          } else if (normalizedPos < 0.7) {
              color = mix(colorMid, colorUpperMid, (normalizedPos - 0.4) * 3.33);
          } else {
              color = mix(colorUpperMid, colorTop, (normalizedPos - 0.7) * 3.33);
          }
          
          // Create wave peaks effect - lighter at crests, darker at troughs
          float waveCrest = (sin(waveY * 10.0 + time * 1.5) * 0.5 + 0.5) * (1.0 - dist * 2.0);
          waveCrest = max(0.0, waveCrest); // Ensure it's not negative
          color = mix(color, vec3(1.0, 1.0, 1.0), waveCrest * 0.15); // Lighten crests
          
          // Create wave troughs - slightly darker
          float waveTrough = (sin(waveY * 10.0 + time * 1.5 + 3.14) * 0.5 + 0.5) * (1.0 - dist * 2.0);
          waveTrough = max(0.0, waveTrough); // Ensure it's not negative
          color = mix(color, colorMid * 0.9, waveTrough * 0.1); // Darken troughs
          
          // Add vertical watercolor streaks with wave influence
          vec3 streakColor = mix(colorLowerMid, colorUpperMid, verticalStreak);
          color = mix(color, streakColor, verticalStreak * 0.15);
          
          // Add soft texture details influenced by waves
          color = mix(color, vec3(0.85, 0.92, 0.98), watercolorTexture * (1.0 + waveCrest * 0.5));
          
          // Apply noise grain texture
          float grainIntensity = 0.03 + uIntensity * 0.02; // Base grain + extra based on intensity
          float grainNoise = grain(uv, time, grainIntensity);
          vec3 grainColor = color * (1.0 - grainIntensity) + vec3(grainNoise);
          
          // Add paper-like subtle noise variation
          float paperGrain = noise(uv * 30.0) * 0.03;
          grainColor = mix(grainColor, grainColor * (1.0 + paperGrain), 0.7);
          
          // Apply subtle vignette effect
          float vignette = smoothstep(0.7, 0.5, dist);
          vec3 finalColor = mix(grainColor * 0.95, grainColor, vignette);
          
          // Radial mask for circular shape with smooth edge
          float mask = smoothstep(0.9, 0.48, dist);
          gl_FragColor = vec4(finalColor, mask);
      }
    `

    function compileShader(gl, source, type) {
      const shader = gl.createShader(type)
      if (!shader) {
        console.error('Unable to create shader.')
        return null
      }
      gl.shaderSource(shader, source)
      gl.compileShader(shader)
      if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
        console.error('Shader compile error:', gl.getShaderInfoLog(shader))
        gl.deleteShader(shader)
        return null
      }
      return shader
    }

    const vertexShader = compileShader(gl, vertexShaderSource, gl.VERTEX_SHADER)
    const fragmentShader = compileShader(gl, fragmentShaderSource, gl.FRAGMENT_SHADER)
    if (!vertexShader || !fragmentShader)
      return

    const program = gl.createProgram()
    if (!program)
      return
    gl.attachShader(program, vertexShader)
    gl.attachShader(program, fragmentShader)
    gl.linkProgram(program)

    if (!gl.getProgramParameter(program, gl.LINK_STATUS)) {
      console.error('Program linking error:', gl.getProgramInfoLog(program))
      return
    }

    const positionLocation = gl.getAttribLocation(program, 'aPosition')
    const timeLocation = gl.getUniformLocation(program, 'uTime')
    const resolutionLocation = gl.getUniformLocation(program, 'uResolution')
    const variantLocation = gl.getUniformLocation(program, 'uVariant')
    const intensityLocation = gl.getUniformLocation(program, 'uIntensity')

    // Get variant code from map or default to 0
    const variantCode = variantMap[variant] || 0

    // Full-screen quad vertices
    const vertices = new Float32Array([
      -1,
      -1,
      1,
      -1,
      -1,
      1,
      1,
      1,
    ])

    const buffer = gl.createBuffer()
    gl.bindBuffer(gl.ARRAY_BUFFER, buffer)
    gl.bufferData(gl.ARRAY_BUFFER, vertices, gl.STATIC_DRAW)

    function render() {
      const now = performance.now()
      const elapsed = (now - startTimeRef.current) / 1000

      // Animation intensity varies with a slow pulse
      const baseIntensity = 0.6
      const pulseIntensity = 0.2 * Math.sin(elapsed * 0.5)
      const targetIntensity = baseIntensity + pulseIntensity

      // Smooth transitions for intensity changes
      animationIntensityRef.current += (targetIntensity - animationIntensityRef.current) * 0.1

      // Resize canvas if needed
      if (canvas.width !== canvas.clientWidth || canvas.height !== canvas.clientHeight) {
        canvas.width = canvas.clientWidth
        canvas.height = canvas.clientHeight
      }

      gl.viewport(0, 0, canvas.width, canvas.height)
      gl.clearColor(0, 0, 0, 0)
      gl.clear(gl.COLOR_BUFFER_BIT)
      gl.useProgram(program)

      // Set uniforms
      gl.uniform1f(timeLocation, elapsed * speedFactor)
      gl.uniform2f(resolutionLocation, canvas.width, canvas.height)
      gl.uniform1i(variantLocation, variantCode)
      gl.uniform1f(intensityLocation, animationIntensityRef.current)

      gl.bindBuffer(gl.ARRAY_BUFFER, buffer)
      gl.enableVertexAttribArray(positionLocation)
      gl.vertexAttribPointer(positionLocation, 2, gl.FLOAT, false, 0, 0)

      gl.drawArrays(gl.TRIANGLE_STRIP, 0, 4)
      animationFrameIdRef.current = requestAnimationFrame(render)
    }

    render()

    return () => {
      if (animationFrameIdRef.current) {
        cancelAnimationFrame(animationFrameIdRef.current)
      }
      if (gl) {
        gl.deleteProgram(program)
        gl.deleteShader(vertexShader)
        gl.deleteShader(fragmentShader)
        gl.deleteBuffer(buffer)
      }
    }
  }, [variant, duration, variantMap])

  // Convert Tailwind width class to actual pixel size
  const sizeMap = {
    8: '4rem',
    10: '8rem',
    12: '3rem',
    16: '4rem',
    20: '5rem',
    24: '6rem',
    32: '8rem',
    40: '10rem',
    48: '12rem',
  }

  const actualSize = sizeMap[width] || '3rem'

  // Convert Tailwind rounded class to actual pixel value
  const roundedMap = {
    'none': '0',
    'xs': '0.125rem',
    'sm': '0.25rem',
    'md': '0.375rem',
    'lg': '0.5rem',
    'xl': '0.75rem',
    '2xl': '1rem',
    '3xl': '1.5rem',
    'full': '9999px',
  }

  const borderRadius = roundedMap[rounded] || '100%'

  return (
    <div
      className={`h-full w-full inset-0 ${
        gray ? 'bg-gray-50' : 'bg-white/90'
      } backdrop-blur-sm flex flex-col items-center justify-center z-50`}
    >
      {/* Container for the water wave animation */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className={`w-${width} aspect-square`}

        style={{ width: actualSize, height: actualSize }}

      >
        <div className="relative w-full h-full">
          {/* The mask container */}
          <div

            className="absolute inset-0 shadow-sm border border-[0.5px] overflow-hidden"

            style={{ borderRadius }}
          >
            <canvas
              ref={canvasRef}
              style={{
                width: '100%',
                height: '100%',
                display: 'block',
              }}
            />
          </div>
        </div>
      </motion.div>

      {/* Loading Message */}
      <motion.div
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        className="mt-12 text-gray-600 text-2xs font-light text-center w-52 font-sans tracking-wider uppercase select-none"
      >
        {message}
      </motion.div>

      {/* Simple animated progress bar under the text */}
      <motion.div
        className="mt-1.5 h-px w-5 bg-gray-400/50 origin-left"
        initial={{ scaleX: 0 }}
        animate={{ scaleX: [0, 1, 0.5, 1], opacity: [0, 1, 1, 1] }}
        transition={{ duration: 2, repeat: Infinity, ease: 'easeInOut' }}
      />
    </div>
  )
}

export default GradientLoadingAnimation
