import { BlandWebClient } from 'bland-client-js-sdk'
import Button from 'components/core/Button'
import BlockInterruptions from 'components/Dashboard/Parameters/BlockInterruptions'
import Language from 'components/Dashboard/Parameters/Language'
import Pronunciation from 'components/Dashboard/Parameters/Pronunciation'
import RequestData from 'components/Dashboard/Parameters/RequestData'
import Voice from 'components/Dashboard/Parameters/Voice'
import { PhoneOff, X } from 'lucide-react'
import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { $fetch } from 'utils/fetch'

function WebClientSlideOut({
  isOpen,
  onClose,
  pathwayId,
  version,
  setCallID,
  setShowChat,
  setShowChatInterface,
}) {
  const [isCallActive, setIsCallActive] = useState(false)
  const [blandClient, setBlandClient] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const methods = useForm({
    defaultValues: {
      voice: 'june',
      language: 'en',
      request_data: null,
      block_interruptions: false,
      pronunciation_guide: null,
    },
  })

  useEffect(() => {
    const handleBeforeUnload = () => {
      if (blandClient) {
        blandClient.stopConversation()
      }
    }

    window.addEventListener('beforeunload', handleBeforeUnload)
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
      if (blandClient) {
        blandClient.stopConversation()
      }
    }
  }, [blandClient])

  const handleStartCall = async (formData) => {
    setIsLoading(true)
    try {
      const body = {
        pathway_id: pathwayId,
        pathway_version: version.version_number,
        voice: formData.voice,
        language: formData.language || 'en',
        request_data: formData.request_data?.reduce((acc, item) => {
          acc[item.key] = item.value
          return acc
        }, {}),
        block_interruptions: formData.block_interruptions,
        pronunciation_guide: formData.pronunciation_guide,
      }

      const headers = {
        'Content-Type': 'application/json',
      }

      const response = await $fetch(`/v1/pathway/test_agent/init`, {
        method: 'POST',
        body: JSON.stringify(body),
        headers,
      })

      if (response.errors) {
        throw new Error('Failed to initialize call')
      }

      const client = new BlandWebClient(pathwayId, response.data.id)
      await client.initConversation({
        sampleRate: 44100,
      })

      // Get the most recent call, we have to pause for a certain amount of time so that the call can be populated
      // This is a hacky solution, but it works for now
      await new Promise(resolve => setTimeout(resolve, 2000))
      const call = await $fetch(`/v1/pathway/test_agent/get_call_id`, {
        method: 'POST',
        body: JSON.stringify({
          pathway_id: pathwayId,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      })
      const mostRecentCall = call?.data

      setCallID(mostRecentCall?.c_id)
      setShowChatInterface(true)
      setShowChat(true)

      setBlandClient(client)
      setIsCallActive(true)
    }
    catch (error) {
      toast.error('Failed to start call')
      if (blandClient) {
        blandClient.stopConversation()
      }
    }
    finally {
      setIsLoading(false)
    }
  }

  const handleEndCall = () => {
    if (blandClient) {
      blandClient.stopConversation()
    }
    setIsCallActive(false)
    onClose()
  }

  return (
    <div className="relative inline-block text-left">
      {isOpen && (
        <div className="p-1.5 origin-top-right absolute max-h-[calc(100vh-100px)] overflow-y-scroll overflow-x-clip right-0 mt-8 w-[300px] rounded shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-[1000]">
          <div className="p-0.5">
            <div
              style={{
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center',
                display: 'flex',
                borderBottom: '1px solid #e0e0e0',
                paddingBottom: 8,
              }}
            >
              <p style={{ fontSize: 16, fontWeight: '550' }}>Web Call</p>
              <X
                style={{ height: 16, width: 16, cursor: 'pointer' }}
                onClick={onClose}
              />
            </div>

            <FormProvider {...methods}>
              <form
                onSubmit={methods.handleSubmit(handleStartCall)}
                className="space-y-2.5 mt-2.5"
              >
                <Voice className="overflow-visible" fieldName="voice" />
                <Language fieldName="language" />
                <RequestData fieldName="request_data" />
                <BlockInterruptions fieldName="block_interruptions" />
                <Pronunciation fieldName="pronunciation_guide" />

                <div className="mt-2.5">
                  {!isCallActive
                    ? (
                        <Button submit loading={isLoading} className="w-full">
                          Start Call
                        </Button>
                      )
                    : (
                        <Button
                          onClick={handleEndCall}
                          appearance="danger"
                          size="sm"
                        >
                          <PhoneOff size={16} />
                        </Button>
                      )}
                </div>
              </form>
            </FormProvider>
          </div>
        </div>
      )}
    </div>
  )
}

export default WebClientSlideOut
