import Button from 'components/core/Button'
import { useEffect, useRef, useState } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import styled from 'styled-components'
import CloseIcon from '../../../../assets/icons/close-icon-black.svg'
import CodeIcon from '../../../../assets/icons/CodeIcon.svg'

export default function KeyValueDropdown({ keypairs, fieldname, label }) {
  const { control, getValues, setValue, watch } = useFormContext()
  const dropdownRef = useRef(null)
  const [isKeyOpen, setIsKeyOpen] = useState(
    Array.from({ length: keypairs.length }).fill(false),
  ) // State array for each key dropdown

  const { fields, append, remove } = useFieldArray({
    control,
    name: fieldname,
  })

  const onAdd = () => {
    append({ key: '', value: '', using_key: false })
    setIsKeyOpen(prevState => [...prevState, false]) // Add new dropdown state
  }

  const handleFocus = (index, isKey) => {
    if (isKey) {
      setIsKeyOpen(prevState =>
        prevState.map((state, i) => (i === index ? true : state)),
      )
    }
  }

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsKeyOpen(prevState => prevState.map(() => false))
    }
  }

  const handleOnInputChange = (e, fname, index, isKeyField = false) => {
    setValue(fname, e.target.value)
    if (!isKeyField) {
      setValue(`${fieldname}[${index}].using_key`, false)
    }

    setIsKeyOpen(prevState =>
      prevState.map((state, i) => (i === index ? false : state)),
    )
  }

  const handleOptionClick = (index, optionValue) => {
    setValue(`${fieldname}[${index}].value`, optionValue)
    setValue(`${fieldname}[${index}].using_key`, true)
    setIsKeyOpen(prevState =>
      prevState.map((state, i) => (i === index ? false : state)),
    )
  }

  const handleClose = (index) => {
    setIsKeyOpen(prevState =>
      prevState.map((state, i) => (i === index ? false : state)),
    )
  }

  const handleOptionClear = (index) => {
    setValue(`${fieldname}[${index}].value`, '')
    setValue(`${fieldname}[${index}].using_key`, false)
    handleClose(index)
  }

  // useEffect(() => {
  //     const currRequestData = getValues(fieldname);
  //     setValue(fieldname, currRequestData);
  //  }, [`fieldname`, getValues, setValue, watch]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [dropdownRef])

  return (
    <DropdownContainer ref={dropdownRef}>
      <Label>{label}</Label>
      <KVWrapper style={{ marginTop: 10 }}>
        {fields?.length > 0 ? (
          <>
            <KeyValueWrapper>
              {fields.map((param, index) => (
                <KeyValueGrid key={param.id}>
                  <KeyValueItem>
                    <StyledInput
                      onChange={e =>
                        handleOnInputChange(
                          e,
                          `${fieldname}[${index}].key`,
                          index,
                          true,
                        )}
                      value={getValues(`${fieldname}[${index}].key`)}
                      placeholder="Key"
                      style={{ borderRadius: '0px', border: 'none' }}
                      onFocus={() => handleFocus(index, false)} // Open specific dropdown on key input focus
                    />
                  </KeyValueItem>
                  <KeyValueItem>
                    {getValues(`${fieldname}[${index}].using_key`) ? (
                      <StyledOption
                        onClick={() => handleClose(index)}
                        style={{ border: '1px solid #eeeeee' }}
                      >
                        <IconContainer>
                          <img
                            src={CodeIcon}
                            style={{ height: 16, width: 16 }}
                            alt=""
                          />
                        </IconContainer>

                        <div>
                          <p style={{ fontWeight: '300', fontSize: 10 }}>Key</p>
                          <p style={{ fontWeight: '550', fontSize: 13 }}>
                            {getValues(`${fieldname}[${index}].value`)}
                          </p>
                        </div>

                        <div
                          onClick={() => handleOptionClear(index)}
                          style={{ marginLeft: 'auto' }}
                        >
                          <img src={CloseIcon} alt="close" />
                        </div>
                      </StyledOption>
                    ) : (
                      <StyledInput
                        onChange={e =>
                          handleOnInputChange(
                            e,
                            `${fieldname}[${index}].value`,
                            index,
                          )}
                        value={getValues(`${fieldname}[${index}].value`)}
                        placeholder="Value"
                        style={{
                          borderRadius: '0px',
                          border: 'none',
                          width: '100%',
                        }}
                        onFocus={() => handleFocus(index, true)} // Do not open dropdown for value input
                      />
                    )}

                    {isKeyOpen[index] && keypairs?.length > 0 && (
                      <DropdownContent>
                        {keypairs.map((doc, ind) => (
                          <StyledOption
                            key={ind}
                            style={{
                              borderBottomLeftRadius:
                                ind === keypairs.length - 1 ? 5 : 0,
                              borderBottomRightRadius:
                                ind === keypairs.length - 1 ? 5 : 0,
                              borderBottom:
                                ind === keypairs.length - 1
                                  ? '1px solid #eeeeee'
                                  : 'none',
                            }}
                            onMouseDown={() =>
                              handleOptionClick(index, Object.keys(doc)[0])} // Set input value on option click
                          >
                            <IconContainer>
                              <img
                                src={CodeIcon}
                                style={{ height: 16, width: 16 }}
                                alt=""
                              />
                            </IconContainer>

                            <div>
                              <p style={{ fontWeight: '550', fontSize: 14 }}>
                                {Object.keys(doc)[0]}
                              </p>
                              <p style={{}}>
                                {typeof Object.values(doc)[0] === 'boolean'
                                  ? Object.values(doc)[0]
                                    ? 'true'
                                    : 'false'
                                  : typeof Object.values(doc)[0] === 'number'
                                    ? Object.values(doc)[0]
                                    : Object.values(doc)[0]?.substring(
                                      0,
                                      100,
                                    )}
                                {' '}
                                {Object.values(doc)[0]?.length >= 100 && '...'}
                              </p>
                            </div>
                          </StyledOption>
                        ))}
                      </DropdownContent>
                    )}
                  </KeyValueItem>
                  <KeyValueItem>
                    <Button
                      onClick={() => {
                        remove(index)
                        setIsKeyOpen(prevState =>
                          prevState.filter((_, i) => i !== index),
                        ) // Remove dropdown state
                      }}
                      appearance="outline"
                      style={{
                        border: 'none',
                        borderRadius: '0px',
                        width: '100%',
                        height: '100%',
                      }}
                    >
                      -
                    </Button>
                  </KeyValueItem>
                </KeyValueGrid>
              ))}
            </KeyValueWrapper>
            <Button
              onClick={onAdd}
              appearance="outline"
              style={{ borderRadius: 6 }}
            >
              + Key/Value
            </Button>
          </>
        ) : (
          <Button
            onClick={onAdd}
            appearance="outline"
            style={{ borderRadius: 6 }}
          >
            + Key/Value
          </Button>
        )}
      </KVWrapper>
    </DropdownContainer>
  )
}

const StyledOption = styled.div`
  border-top: 1px solid #eeeeee;
  border-left: 1px solid #eeeeee;
  border-right: 1px solid #eeeeee;
  cursor: pointer;
  padding: 2.5px 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 25px;
  border-top-left-radius: ${({ ind }) => (ind === 0 ? 5 : 0)}px;
  border-top-right-radius: ${({ ind }) => (ind === 0 ? 5 : 0)}px;
  &:hover {
    background-color: #f0f0f0; /* Change this to your desired hover background color */
  }
`

const IconContainer = styled.div`
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  padding: 5px;
`

const DropdownContent = styled.div`
  width: 100%;
  background-color: white;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  max-height: 200px;
  overflow-y: auto;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  margin-top: 5px; /* Add a little space between the input and dropdown */
  margin-bottom: 10px;
`

const InputContainer = styled.div`
  padding: 15px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  margin-bottom: 10px;
`

const StyledInput = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  font-size: 14px;
`

const Label = styled.p`
  margin: 0px;
  padding: 0px;
  color: #000;
  font-size: 13px;
  font-weight: 500;
`

const DropdownContainer = styled.div`
  width: 100%;
  padding: 15px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  margin-bottom: 10px;
`

const KVWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  align-items: start;
`

const KeyValueWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid #eeeeee;
  gap: 1px;
`

const KeyValueGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 45px;
  background-color: #fbfbfa;
  grid-gap: 1px;
  width: 100%;
`

const KeyValueItem = styled.div`
  width: 100%;
  height: 100%;
  align-items: center;
  box-sizing: border-box;
  outline: 1px solid #eeeeee;
`
