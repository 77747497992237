import { Alert, Select, SelectItem, Skeleton } from '@heroui/react'
import { useMutation, useQuery } from '@tanstack/react-query'
import DragDropUpload from 'components/core/DragDropUpload'
import config from 'config'
import Papa from 'papaparse'
import { useEffect, useState } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { toast } from 'react-toastify'
import { $fetch } from 'utils/fetch'

export default function RecipientsUpload() {
  const { setValue } = useFormContext()
  const [uploadedFile, setUploadedFile] = useState(null)
  const [availableHeaders, setAvailableHeaders] = useState([])

  const columnMapping = useWatch({
    name: 'column_mapping',
  })

  const fileId = useWatch({
    name: 'file_id',
  })

  const resetFile = () => {
    setValue('file_id', null)
    setValue('column_mapping', {})
    setValue('recipients_count', 0)
    setUploadedFile(null)
  }

  useEffect(() => {
    if (!fileId) {
      resetFile()
    }
  }, [fileId])

  const { data: validHeaders, isLoading } = useQuery({
    queryKey: ['validHeaders'],
    staleTime: Infinity,
    queryFn: async () => {
      const { data } = await $fetch('/v2/batches/schema/files')
      return Object.keys(data)
    },
  })

  const { mutate: uploadFile } = useMutation({
    mutationFn: (file) => {
      const formData = new FormData()
      formData.append('file', file)
      formData.append('usage', 'batches')
      formData.append('filename', file.name ?? `${new Date().toISOString()}.csv`)
      return $fetch('/v1/files/attach', {
        method: 'POST',
        body: formData,
        baseURL: config.FILE_UPLOAD_URL,
      })
    },
    onSuccess: async ({ data }) => {
      setValue('file_id', data.id)
    },
    onError: (error) => {
      console.error(error)
      const errorMessage = error.data?.errors?.[0]?.message
      toast.error(errorMessage ?? 'Failed to upload file')
      resetFile()
    },
  })

  const countCSVRows = (file) => {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: (results) => {
        setValue('recipients_count', results.data.length)
      },
      error: (error) => {
        console.error('Error counting CSV rows:', error)
      },
    })
  }

  const parseCSVHeaders = (file) => {
    return new Promise((resolve) => {
      Papa.parse(file, {
        header: true,
        preview: 1,
        skipEmptyLines: true,
        complete: (results) => {
          const headers = results.meta.fields || []

          // Check for unknown headers
          const unknownHeaders = headers.filter(header =>
            !validHeaders.includes(header),
          )

          setValue('column_mapping', Object.fromEntries(unknownHeaders.map(header => [header, null])))

          const mappableHeaders = validHeaders.filter(header =>
            !unknownHeaders.includes(header),
          )

          setAvailableHeaders(mappableHeaders)

          // Now count all rows in a separate parse operation
          countCSVRows(file)

          resolve({
            unknownHeaders,
          })
        },
        error: (error) => {
          console.error('Error parsing CSV:', error)
          toast.error('Failed to parse CSV file')
          resolve({
            unknownHeaders: [],
          })
        },
      })
    })
  }

  const handleFileChange = async (files) => {
    if (!files?.length) {
      resetFile()
    }
    else {
      const file = files[0]
      setUploadedFile(file)

      await parseCSVHeaders(file)

      uploadFile(file)
    }
  }

  return (
    <div className="flex flex-col gap-3 w-full">
      <span className="flex flex-col gap-1">
        <label className="text-small font-medium text-foreground">Upload Recipients</label>
        <p className="text-tiny text-foreground-400">
          Upload a CSV file with the recipients you'd like to call. A
          {' '}
          <strong>phone_number</strong>
          {' '}
          column is required.
        </p>
      </span>

      {isLoading
        ? (
            <Skeleton className="w-full h-36 rounded-md" />
          )
        : (
            <DragDropUpload
              onFilesAdded={handleFileChange}
              uploadedFiles={uploadedFile ? [uploadedFile] : []}
              multiple={false}
              accept={{ 'text/csv': [] }}
            />
          )}

      {Object.keys(columnMapping ?? {}).length > 0 && (
        <>
          <Alert
            variant="flat"
            color="warning"
            radius="sm"
            title="We couldn't map some of your columns."
            description="You may optionally map these columns to Send Call parameters below, which will override the global settings you've set."
          />
          {Object.keys(columnMapping ?? {}).map(header => (
            <div key={header} className="flex justify-between gap-4 p-2 bg-white rounded-md border border-gray-200 w-full items-center">
              <span className="flex flex-col items-start">
                <label className="text-small font-medium text-foreground">
                  {header}
                </label>
                <p className="text-2xs text-gray-500">From your CSV</p>
              </span>
              <Select
                size="sm"
                radius="sm"
                placeholder="Select a parameter"
                variant="bordered"
                value={[columnMapping[header]]}
                className="max-w-md"
                onChange={(e) => {
                  setValue('column_mapping', { ...columnMapping, [header]: e.target.value })
                }}
              >
                {availableHeaders.map(header => (
                  <SelectItem key={header}>
                    {header}
                  </SelectItem>
                ))}
              </Select>
            </div>
          ))}
        </>
      )}
    </div>
  )
}
