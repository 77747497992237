import VoiceGradientAvatar from 'components/Dashboard/SendCall/VoiceGradientReusable'
import { useAuth } from 'hooks/useAuth'
import DisplayNameInput from 'pages/Orgs/DisplayNameInput'
// components/Settings/GeneralSettings/GeneralSettings.js
import { useEffect, useState } from 'react'

import { toast } from 'react-toastify'
import { $fetch } from 'utils/fetch'
import ConfirmationModal from './ConfirmationModal'
import PersonalInfo from './PersonalInfo'
import RecordingPreferences from './RecordingPreferences'
import WebhookSettings from './WebHookSettings'
/**
 * The main container that orchestrates all the states, logic, and sub-components.
 *
 * Folder structure:
 *  components/Settings/GeneralSettings/
 *    ├── GeneralSettings.js
 *    ├── PersonalInfo.js
 *    ├── WebhookSettings.js
 *    ├── RecordingPreferences.js
 *    └── ConfirmationModal.js
 */

export default function SettingsPage() {
  // Organization data
  const [loading, setLoading] = useState(false)

  // Webhook states
  const [webhookUrl, setWebhookUrl] = useState('')
  const [savedWebhookUrl, setSavedWebhookUrl] = useState('')
  const [updatingWebhook, setUpdatingWebhook] = useState(false)
  const [gettingWebhook, setGettingWebhook] = useState(false)

  // Org Preference, more sustainable as one object.
  const [orgPreferences, setOrgPreferences] = useState({
    useBlandUrl: undefined,
    lifespanMode: undefined,
    customDays: undefined,
  })

  // Confirmation modal
  const [showRecordingModal, setShowRecordingModal] = useState(false)
  const [updatingRecordingPref, setUpdatingRecordingPref] = useState(false)

  // Hover states for copy
  const [p1, setP1] = useState(false)
  const [p2, setP2] = useState(false)

  const { org, user } = useAuth()

  /**
   * Fetch current webhook
   */
  const getWebhookUrl = async () => {
    try {
      if (gettingWebhook)
        return
      setGettingWebhook(true)
      const response = await $fetch('/user/getCurrentWebhook')

      if (response.webhook_url) {
        setWebhookUrl(response.webhook_url ?? '')
        setSavedWebhookUrl(response.webhook_url ?? '')
      }
    }
    catch (error) {
      console.error('Error fetching webhook URL:', error)
      return null
    }
    finally {
      setGettingWebhook(false)
    }
  }

  /**
   * Handle updating the current webhook
   */
  const handleUpdateWebhookUrl = async () => {
    try {
      if (
        !webhookUrl
        || webhookUrl === ''
        || webhookUrl === savedWebhookUrl
        || webhookUrl.includes(' ')
        || !webhookUrl.includes('https')
      ) {
        return toast.error('Invalid webhook URL')
      }
      setUpdatingWebhook(true)

      const response = await $fetch('/user/updateCurrentWebhook', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ webhook_url: webhookUrl }),
      })

      if (response.data) {
        setSavedWebhookUrl(response.data?.webhook_url ?? '')
        toast.success('Webhook URL updated successfully')
      }
    }
    catch (error) {
      console.error('Error updating webhook URL:', error)
      return null
    }
    finally {
      setUpdatingWebhook(false)
    }
  }

  /**
   * Get profile picture
   */
  const getProfilePicture = () => {
    // fallback
    return (
      <VoiceGradientAvatar
        name={`${user?.user?.first_name ?? 'John'} ${user?.user?.last_name ?? 'Bland'}`}
      />
    )
  }

  /**
   * Confirm the update to /v1/recordings/preferences
   * Convert "infinite" => -1, or any integer 1..1825
   */
  const handleConfirmRecordingUpdate = async () => {
    setShowRecordingModal(false)
    setUpdatingRecordingPref(true)

    let finalLifespan = -1 // "infinite" => -1
    if (orgPreferences.lifespanMode === 'custom') {
      const parsed = Number.parseInt(orgPreferences.customDays, 10)
      if (Number.isNaN(parsed) || parsed < 1 || parsed > 1825) {
        toast.error('Invalid lifespan. Must be between 1–1825 or \'infinite\'.')
        setUpdatingRecordingPref(false)
        return
      }
      finalLifespan = parsed
    }

    try {
      const bodyData = {
        use_bland_url: orgPreferences.useBlandUrl,
        recording_lifespan_days: finalLifespan,
      }
      await $fetch('/v1/recordings/preferences', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(bodyData),
      })

      toast.success('Recording preferences updated for the entire org.')
    }
    catch (error) {
      console.error('Error updating recording preferences:', error)
      toast.error('Could not update recording preferences. Please try again.')
    }
    finally {
      setUpdatingRecordingPref(false)
    }
  }

  useEffect(() => {
    setOrgPreferences({
      useBlandUrl: org?.preferences?.use_bland_url,
      lifespanMode:
        org?.preferences?.recording_lifespan_days === -1
          ? 'infinite'
          : 'custom',
      customDays: org?.preferences?.recording_lifespan_days,
    })
    getWebhookUrl()
  }, [org])

  if (loading)
    return <div />

  return (
    <div style={{}}>
      <PersonalInfo
        getProfilePicture={getProfilePicture}
        copyHoverStates={{ p1, setP1, p2, setP2 }}
      />

      {/* Organization/Account Settings Header */}
      <div
        style={{
          marginTop: 25,
          borderBottom: '1px solid #e9e9e9',
          paddingBottom: 25,
          marginBottom: 50,
        }}
      >
        <p
          style={{
            fontSize: 18,
            fontWeight: 500,
            marginBottom: 10,
          }}
        >
          Organization Settings
        </p>
        <p style={{ color: 'GrayText', fontWeight: 400, fontSize: 14 }}>
          Update account information and details here.
        </p>
      </div>

      <DisplayNameInput />

      <WebhookSettings
        webhookUrl={webhookUrl}
        setWebhookUrl={setWebhookUrl}
        savedWebhookUrl={savedWebhookUrl}
        gettingWebhook={gettingWebhook}
        updatingWebhook={updatingWebhook}
        handleUpdateWebhookUrl={handleUpdateWebhookUrl}
      />

      <RecordingPreferences
        useBlandUrl={orgPreferences.useBlandUrl ?? false}
        setUseBlandUrl={(value) => {
          setOrgPreferences({ ...orgPreferences, useBlandUrl: value })
        }}
        lifespanMode={orgPreferences.lifespanMode ?? 'infinite'}
        setLifespanMode={(value) => {
          setOrgPreferences({ ...orgPreferences, lifespanMode: value })
        }}
        customDays={orgPreferences.customDays ?? '3'}
        setCustomDays={(value) => {
          setOrgPreferences({ ...orgPreferences, customDays: value })
        }}
        setShowRecordingModal={setShowRecordingModal}
      />

      <ConfirmationModal
        showRecordingModal={showRecordingModal}
        setShowRecordingModal={setShowRecordingModal}
        updatingRecordingPref={updatingRecordingPref}
        handleConfirmRecordingUpdate={handleConfirmRecordingUpdate}
      />
    </div>
  )
}
