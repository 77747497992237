import { Avatar, Chip, Button, Card, CardBody, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger } from '@heroui/react'
import { Icon } from '@iconify/react'
import { formatDistanceToNow } from 'date-fns'
import { useMutation } from '@tanstack/react-query'
import { $fetch } from 'utils/fetch'
import { ConfirmationModal } from 'components/Reusables/ConfirmationModal'
import { useState } from 'react'
import { addToast } from '@heroui/react'

export function UserCard({ user, isSelected, onClick, memoryId, onDeleteSuccess }) {
  console.log('user', user)
  const callCount = user.call_count || 0

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  const deleteUser = useMutation({
    mutationFn: (data) => {
      return $fetch(`/v1/memory/${memoryId}/user/delete`, {
        method: 'POST',
        body: JSON.stringify(data),
      })
    },
    onSuccess: () => {
      addToast({
        title: 'User deleted successfully',
        description: 'The user has been deleted from the memory store',
      })
      onDeleteSuccess()
    },
    onError: () => {
      addToast({
        title: 'Failed to delete user',
        description: 'Please try again',
      })
    },
  })
  const handleDeleteUser = () => {
    deleteUser.mutate({
      phone_number: user.phone_number,
    })
  }

  return (
    <>
    <Card
      isPressable
      onPress={onClick}
      className={`w-full mb-3 border transition-all ${
        isSelected ? 'border-primary shadow-md' : 'border-default-200 hover:shadow-sm'
      }`}
    >
      <CardBody className="p-5">
        <div className="flex items-start justify-between gap-4">
          <div className="flex items-start flex-1">
            <Avatar
              name={"US"}
              size="md"
              className="bg-default-200 text-default-600"
            />
            <div className="ml-4 flex-1">
              <div className="flex items-center justify-between mb-1">
                <span className="font-semibold text-default-900">{user.phone_number}</span>
                {callCount > 0 ? (
                  <Chip color="primary" variant="flat" className="ml-2 ">
                    {`${callCount} ${callCount === 1 ? 'call' : 'calls'}`}
                  </Chip>
                ) : (
                  <Chip variant="flat" className="text-default-400 bg-default-100 ml-2">
                    0 calls
                  </Chip>
                )}
              </div>
              <div className="text-sm text-default-500">
                Last contact:{' '}
                {user.last_call_at 
                  ? formatDistanceToNow(new Date(user.last_call_at), { addSuffix: true })
                  : 'No contact yet'
                }
              </div>
            </div>
          </div>

          <Dropdown>
            <DropdownTrigger>
              <Button 
                isIconOnly 
                size="sm" 
                variant="light" 
                className="hover:bg-default-100"
              >
                <Icon icon="lucide:more-vertical" className="text-default-600" />
              </Button>
            </DropdownTrigger>
            <DropdownMenu aria-label="User actions">
              <DropdownItem 
                key="delete" 
                className="text-danger" 
                color="danger" 
                startContent={<Icon icon="lucide:trash" size={14} />}
                onPress={() => setIsDeleteModalOpen(true)}
              >
                Delete User
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>

        <div className="text-sm text-default-700 mt-4 mb-3 line-clamp-2">
          {user?.summary || 'No summary available'}
        </div>

        <div className="flex items-center justify-end text-sm text-default-500 hover:text-default-700 transition-colors cursor-pointer">
          <Icon icon="lucide:eye" size={16} className="mr-1.5" />
          <span>View details</span>
        </div>
      </CardBody>
    </Card>

    <ConfirmationModal
      isOpen={isDeleteModalOpen}
      onOpenChange={setIsDeleteModalOpen}
      onConfirm={handleDeleteUser}
      title="Delete User"
      message="Are you sure you want to delete this user?"
      confirmText="Delete"
      confirmColor="danger"
    />
    </>
  )
}
