import { Button, Modal, ModalContent, ModalFooter } from '@heroui/react'
import {
  Flex,
} from '@radix-ui/themes'
import PhoneInput from 'components/Hero/PhoneInput'
import {
  Crown,
  EyeIcon,
  Shield,
  X,
} from 'lucide-react'
import { useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'

export const roles = [
  {
    id: 1,
    name: 'Admin',
    description: 'Manage settings, and has operator and viewer permissions.',
    img: <Crown size={14} />,
    indicator: 'bg-blue-500',
    box: 'border-blue-500 bg-blue-50/50',
    color: 'blue',
  },
  {
    id: 2,
    name: 'Operator',
    description: 'Read/Write permissions.',
    img: <Shield size={14} />,
    indicator: 'bg-emerald-500',
    box: 'border-emerald-500 bg-emerald-50/50',
    color: 'emerald',
  },
  {
    id: 3,
    name: 'Viewer',
    description: 'Read-only permissions.',
    img: <EyeIcon size={14} />,
    indicator: 'bg-gray-400',
    box: 'border-gray-500 bg-gray-50/50',
    color: 'gray',
  },
]

export function AddUserModal({
  button,
  open,
  setOpen,
  onClick,
  onClickLoading,
}) {
  // const roles = ["admin", "operator", "viewer"];
  const [selected, setSelected] = useState(null)
  const formMethods = useForm()

  return (
    <>
      {button}
      <Modal isOpen={open} onOpenChange={setOpen}>

        <ModalContent>
          {onClose => (
            <FormProvider {...formMethods}>
              <div
                style={{
                  padding: 15,
                  borderBottom: '1px solid #e9e9e9',
                  background: '#ffffff',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <p style={{ fontSize: 13, fontWeight: '550' }}>Invite new user</p>
              </div>

              <Flex
                direction="column"
                gap="3"
                style={{ padding: 15, background: '#f4f4f4' }}
              >

                <Controller
                  name="phone"
                  render={({ field }) => (
                    <PhoneInput
                      isRequired
                      label="Phone number"
                      fullWidth
                      {...field}
                    />
                  )}
                />

                <div className="space-y-1.5" style={{ marginTop: 6 }}>
                  <p
                    style={{
                      fontSize: 13,
                      fontWeight: '450',
                      color: '#000',
                      marginBottom: 6,
                    }}
                  >
                    User permissions
                  </p>
                  {roles.map((role, index) => {
                    // const Icon = role.icon;
                    const isSelected = selected && selected.id === role.id
                    return (
                      <button
                        type="button"
                        key={role?.id}
                        onClick={() => setSelected(role)}
                        className={`w-full p-2.5 flex items-center justify-between rounded-md border transition-all duration-200 ${
                          isSelected
                            ? `${role.box}`
                            : 'border-transparent hover:bg-[#eeeeee]'
                        }`}
                      >
                        <div className="flex items-center space-x-2.5">
                          <div
                            className={`p-1.5 rounded ${isSelected ? `` : 'bg-gray-100'}`}
                          >
                            {/* <Icon
                          className={`h-3 w-3 ${isSelected ? "text-blue-500" : "text-gray-500"}`}
                        /> */}
                            {role.img}
                          </div>
                          <div
                            className="flex flex-col items-start"
                            style={{ marginLeft: 15 }}
                          >
                            <span
                              style={{ textAlign: 'left' }}
                              className="text-xs font-semibold text-gray-900"
                            >
                              {role.name}
                            </span>
                            <span
                              style={{ textAlign: 'left' }}
                              className="text-2xs text-gray-500"
                            >
                              {role.description}
                            </span>
                          </div>
                        </div>

                        <div className="flex items-center space-x-1.5">
                          <div
                            className={`h-1.5 w-1.5 rounded-full ${role.indicator}`}
                          />
                          <div
                            className={`h-2.5 w-2.5 rounded-full border-2 transition-all ${
                              isSelected
                                ? `${role.indicator} bg-blue-500`
                                : 'border-gray-300'
                            }`}
                          >
                            {isSelected && (
                              <div className="h-full w-full rounded-full bg-white transform scale-[0.4]" />
                            )}
                          </div>
                        </div>
                      </button>
                    )
                  })}
                </div>
              </Flex>

              <ModalFooter>
                <Button
                  size="sm"
                  onPress={onClose}
                  variant="light"
                >
                  Cancel
                </Button>

                <Button
                  isLoading={onClickLoading}
                  size="sm"
                  isDisabled={!formMethods.getValues().phone || !selected}
                  onPress={() => {
                    onClick('add', formMethods.getValues().phone, selected ? selected.name : null)
                    onClose()
                  }}
                  color="primary"
                >
                  Add user
                </Button>

              </ModalFooter>
            </FormProvider>
          )}
        </ModalContent>
      </Modal>
    </>
  )
}
