import { Dialog } from '@headlessui/react'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { $fetch } from 'utils/fetch'
import { getOrgId } from 'utils/funcs/browser/getOrgId'

export default function DuplicatePathwayDialog({
  open,
  onClose,
  isDuplicate,
  isTemplateDuplicate,
  currId,
  setRefreshPathways,
  isSms = false,
}) {
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const navigate = useNavigate()
  const orgId = getOrgId()

  useEffect(() => {
    // If it's an SMS pathway, prefix the name
    if (open && isSms) {
      $fetch(`/v1/pathway/${currId}`).then((response) => {
        if (response && response.data) {
          const sourceName = response.data.name || ''
          setName(`[SMS] ${sourceName}`.trim())
          setDescription(response.data.description || '')
        }
      }).catch((error) => {
        console.error('Error fetching pathway details:', error)
      })
    }
    else if (open) {
      // Reset name and description when dialog opens
      setName('')
      setDescription('')
    }
  }, [open, currId, isSms])

  const handleCreateOrDuplicate = async () => {
    try {
      const url = isTemplateDuplicate
        ? `/convo_pathway/duplicate_template`
        : `/v1/convo_pathway/duplicate`
      let response

      if (isDuplicate || isTemplateDuplicate) {
        response = await $fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            name,
            description,
            pathwayID: currId,
          }),
        })
      }
      else {
        response = await $fetch('/convo_pathway/create', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            name,
            description,
          }),
        })
      }

      if (!response.errors && response.data) {
        const { data } = response
        toast.success(
          isDuplicate || isTemplateDuplicate
            ? 'Pathway duplicated successfully'
            : 'Pathway created successfully',
          { position: 'bottom-right' },
        )

        if (isSms && data.id) {
          try {
            await $fetch(`/v1/pathway/${currId}/sms`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                sms_pathway_id: data.id,
              }),
            })
            toast.success('SMS pathway linked successfully', { position: 'bottom-right' })
          }
          catch (error) {
            console.error('Error linking SMS pathway:', error)
            toast.error('Failed to link SMS pathway', { position: 'bottom-right' })
          }
        }

        onClose()
        if (setRefreshPathways) {
          setRefreshPathways(prev => !prev)
        }

        // Navigate to the new pathway if it's not an SMS pathway
        if (!isSms) {
          navigate(`/dashboard/convo-pathways?id=${data.id}`)
        }
      }
      else {
        toast.error('Error creating pathway')
      }
    }
    catch (error) {
      console.error('Error creating or duplicating pathway:', error)
      toast.error('Error creating or duplicating pathway')
    }
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 border border-gray-300 bg-white p-5 rounded-md shadow-lg w-[500px] space-y-2.5">
        <h1 className="text-lg text-indigo-500 font-bold mb-2.5 text-center">
          {isSms
            ? 'Create SMS Pathway'
            : isDuplicate
              ? 'Duplicate Pathway'
              : isTemplateDuplicate
                ? 'Duplicate Template Pathway'
                : 'New Pathway'}
        </h1>
        <div className="space-y-1.5">
          <label>Name:</label>
          <input
            value={name}
            onChange={e => setName(e.target.value)}
            className="border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-indigo-500 focus:border-indigo-500 block w-full p-1.5"
            placeholder="Pathway Name"
          />
        </div>
        <div className="space-y-1.5">
          <label>Description:</label>
          <textarea
            value={description}
            onChange={e => setDescription(e.target.value)}
            className="border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-indigo-500 focus:border-indigo-500 block w-full p-1.5"
            placeholder="Pathway Description"
          />
        </div>
        <div className="pt-1.5">
          <button
            className="bg-indigo-500 text-white font-bold py-1.5 px-2.5 rounded-sm focus:outline-none focus:shadow-outline"
            onClick={handleCreateOrDuplicate}
          >
            {isSms
              ? 'Create SMS Pathway'
              : isDuplicate || isTemplateDuplicate
                ? 'Duplicate Pathway'
                : 'Create pathway'}
          </button>
        </div>
      </div>
    </Dialog>
  )
}
