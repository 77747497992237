import {
  Button,
  cn,
  DateInput,
  Input,
  Select,
  SelectItem,
} from '@heroui/react'
import { getLocalTimeZone, parseDate, today } from '@internationalized/date'
import { createFormHook } from '@tanstack/react-form'
import { useQuery } from '@tanstack/react-query'
import PathwaySelect from 'components/Hero/PathwaySelect'
import PhoneInput from 'components/Hero/PhoneInput'
import { fieldContext, formContext } from 'contexts/formContext'
import { Check, ListFilterPlus, Minus, Plus, X } from 'lucide-react'
import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { $fetch } from 'utils/fetch'
import { parsePathwayTags } from 'utils/funcs/call-logs/parsePathwayTags'
import { LoadFilterModal, SaveFilterModal } from './FilterManagement'

function DirectionSelect({ value, ...props }) {
  return (
    <Select selectedKeys={[value]} {...props}>
      <SelectItem key={true}>Inbound</SelectItem>
      <SelectItem key={false}>Outbound</SelectItem>
    </Select>
  )
}

function StatusSelect({ value, ...props }) {
  return (
    <Select selectedKeys={[value]} {...props}>
      <SelectItem key="completed">Completed</SelectItem>
      <SelectItem key="failed">Failed</SelectItem>
      <SelectItem key="pending">Pending</SelectItem>
      <SelectItem key="skipped">Skipped</SelectItem>
      <SelectItem key="unknown">Unknown</SelectItem>
    </Select>
  )
}

function TagsSelect({ value, pathwayId, ...props }) {
  const { data: pathways } = useQuery({
    queryKey: ['pathways'],
    queryFn: () => $fetch('/v1/pathway'),
    staleTime: 1000 * 60 * 10,
  })

  const pathway = useMemo(() => pathways?.find(p => p.id === pathwayId), [pathways, pathwayId])

  const { data } = useQuery({
    queryKey: ['productionVersion', pathwayId],
    queryFn: () => $fetch(`/v1/pathway/${pathwayId}/version/${pathway?.production_version_number}`),
    enabled: !!pathway,
    staleTime: Infinity,
  })

  const availableTags = useMemo(() => {
    if (!data)
      return []

    // Extract tags from nodes, flatten the array, filter out falsy values
    const allTags = data.nodes.map(node => node.data?.tag).flat().filter(Boolean)

    return parsePathwayTags(allTags)
  }, [data])

  return (
    <Select
      aria-label="Tags"
      isDisabled={!pathwayId}
      placeholder={!pathwayId ? 'First add a Pathway filter' : !availableTags?.length ? 'No tags found' : 'Select tags'}
      selectedKeys={[value]}
      renderValue={(selectedKeys) => {
        return selectedKeys[0].rendered
      }}
      {...props}
    >
      {availableTags?.map(tag => (
        <SelectItem key={JSON.stringify(tag)}>
          <div className="flex flex-row gap-2 items-center">
            <div className="size-3 rounded-full" style={{ backgroundColor: tag.color ?? 'gray' }} />
            <span>{tag.name}</span>
          </div>
        </SelectItem>
      ))}
    </Select>
  )
}

function KVInput({ value, onChange }) {
  let currentValue = value

  while (typeof currentValue === 'string') {
    currentValue = JSON.parse(currentValue)
  }

  const currentEntries = Object.entries(currentValue)
  const currentKey = currentEntries.length > 0 ? currentEntries[0][0] : ''
  const currentVal = currentEntries.length > 0 ? currentEntries[0][1] : ''

  return (
    <div className="flex flex-row gap-2 w-full">
      <Input
        placeholder="Key"
        value={currentKey}
        variant="bordered"
        size="sm"
        fullWidth
        className="flex-1"
        onChange={(e) => {
          const newKey = e.target.value
          const newValue = {}

          // If we have a new key and there was a previous value
          if (newKey && currentVal) {
            newValue[newKey] = currentVal
          }
          // If just setting a new key
          else if (newKey) {
            newValue[newKey] = ''
          }

          onChange(newValue)
        }}
      />
      <Input
        placeholder="Value"
        value={currentVal}
        variant="bordered"
        size="sm"
        fullWidth
        className="flex-1"
        onChange={(e) => {
          const newVal = e.target.value
          const newValue = {}

          // If we have a key, update its value
          if (currentKey) {
            newValue[currentKey] = newVal
            onChange(newValue)
          }
        }}
      />
    </div>
  )
}

const operators = {
  equals: '=',
  not: 'is not',
  in: 'in',
  notIn: 'not in',
  lt: '<',
  lte: '<=',
  gt: '>',
  gte: '>=',
  contains: 'contain',
  startsWith: 'startsWith',
  endsWith: 'endsWith',
  isNotEmpty: 'exist',
  isEmpty: 'do not exist',
  hasSome: 'includes',
  none: 'does not include',
}

const booleanOperators = ['isNotEmpty', 'isEmpty']

const filterableFields = {
  inbound: { label: 'Direction', operators: ['equals'], inputComponent: DirectionSelect },
  c_id: { label: 'Call ID', operators: ['equals', 'not'] },
  to: { label: 'To', operators: ['equals', 'not'], inputComponent: PhoneInput },
  from: { label: 'From', operators: ['equals', 'not'], inputComponent: PhoneInput },
  call_length: { label: 'Duration', operators: ['equals', 'gt', 'lt', 'gte', 'lte'], inputProps: { endContent: 'minutes', type: 'number' } },
  created_at: { label: 'Created', operators: ['equals', 'gt', 'lt', 'gte', 'lte'], inputComponent: DateInput, inputProps: { defaultValue: today(getLocalTimeZone()) } },
  status: { label: 'Status', operators: ['equals', 'not'], inputComponent: StatusSelect },
  pathway_id: { label: 'Pathway', operators: ['equals', 'not'], inputComponent: PathwaySelect },
  batch_id: { label: 'Batch ID', operators: ['equals', 'not'] },
  call_notes: { label: 'Notes', operators: ['contains', 'isNotEmpty', 'isEmpty'] },
  pathway_tags: { label: 'Tags', operators: ['hasSome', 'none'], inputComponent: TagsSelect },
  analysis: { label: 'Analysis', operators: ['hasSome', 'none'], inputComponent: KVInput },
}

export default function FilterInput({ appliedFilters }) {
  const [searchParams, setSearchParams] = useSearchParams()

  const { useAppForm } = createFormHook({
    fieldContext,
    formContext,
    formComponents: {
      SaveFilterModal,
      LoadFilterModal,
    },
  })

  const handleApplyFilters = ({ filters }) => {
    const newSearchParams = new URLSearchParams()
    filters.forEach((filter) => {
      const paramKey = `filter:${filter.field}:${filter.operator}`
      const paramValue = filter.field === 'analysis' && typeof filter.value != 'string' ? JSON.stringify(filter.value) : filter.value
      newSearchParams.set(paramKey, paramValue)
    })
    setSearchParams(newSearchParams, { replace: true })
  }

  const handleClearFilters = () => {
    const keysToDelete = Array.from(searchParams.keys()).filter(key => key.startsWith('filter:'))

    keysToDelete.forEach((key) => {
      searchParams.delete(key)
    })

    setSearchParams(searchParams, { replace: true })
  }

  const form = useAppForm({
    defaultValues: {
      filters: appliedFilters,
    },
    onSubmit({ value }) {
      handleApplyFilters(value)
    },
  })

  return (
    <>
      <form.AppForm>
        <form.Field name="filters" mode="array">
          {(filters) => {
            const hasFilters = filters.state.value?.length > 0
            const hasAppliedFilters = appliedFilters?.length > 0
            const unappliedFilters = filters.state.value.filter(filter => !appliedFilters?.some(f => f.field === filter.field))
            return (
              <div className={cn('flex flex-col gap-2 shadow-inner w-full', { 'p-2 border-b border-gray-200': hasFilters })}>
                {filters.state.value.map((_, i) => {
                  return (
                    <div key={i} className="flex flex-row gap-2">
                      <form.Field
                        name={`filters[${i}].field`}
                        listeners={{
                          onChange: ({ value }) => {
                            const newField = filterableFields[value]
                            if (!newField)
                              return

                            if (newField.inputComponent === DateInput) {
                              form.setFieldValue(`filters[${i}].value`, today(getLocalTimeZone()))
                            }
                            else if (newField.inputComponent === KVInput) {
                              form.setFieldValue(`filters[${i}].value`, {})
                            }
                            else {
                              form.setFieldValue(`filters[${i}].value`, '')
                            }
                            form.setFieldValue(`filters[${i}].operator`, newField.operators[0])
                          },
                        }}
                      >
                        {(subField) => {
                          return (
                            <Select
                              selectedKeys={[subField.state.value]}
                              onChange={e => subField.handleChange(e.target.value)}
                              variant="bordered"
                              size="sm"
                              className="max-w-xl"
                            >
                              {Object.entries(filterableFields).map(([key, field]) => (
                                <SelectItem key={key}>{field.label}</SelectItem>
                              ))}
                            </Select>
                          )
                        }}
                      </form.Field>
                      <form.Subscribe
                        selector={state => state.values.filters[i]?.field}
                        children={field => (
                          <form.Field
                            name={`filters[${i}].operator`}
                            listeners={{
                              onChange: ({ value }) => {
                              // Set value to empty string when operator is isNotEmpty
                                if (value === 'isNotEmpty') {
                                  form.setFieldValue(`filters[${i}].value`, '')
                                }
                              },
                            }}
                          >
                            {(subField) => {
                              const operatorKeys = filterableFields[field]?.operators ?? []
                              return (
                                <Select
                                  variant="bordered"
                                  size="sm"
                                  selectedKeys={[subField.state.value]}
                                  onChange={e => subField.handleChange(e.target.value)}
                                  classNames={{ base: 'w-fit min-w-32 shrink-0', popoverContent: '!w-64', innerWrapper: 'w-full' }}
                                >
                                  {operatorKeys.map(key => (
                                    <SelectItem key={key}>{operators[key]}</SelectItem>
                                  ))}
                                </Select>
                              )
                            }}
                          </form.Field>
                        )}
                      />
                      <form.Subscribe
                        selector={state => ({
                          field: state.values.filters[i]?.field,
                          operator: state.values.filters[i]?.operator,
                          pathwayId: state.values.filters.find(filter => filter.field === 'pathway_id')?.value,
                        })}
                        children={({ field, operator, pathwayId }) => (
                          <>
                            {!booleanOperators.includes(operator) && (
                              <form.Field name={`filters[${i}].value`}>
                                {(subField) => {
                                  const Component = filterableFields[field]?.inputComponent ?? Input
                                  const inputProps = filterableFields[field]?.inputProps ?? {}
                                  return (
                                    <Component
                                      onChange={e => [DateInput, PhoneInput, KVInput].includes(Component) ? subField.handleChange(e) : subField.handleChange(e.target.value)}
                                      value={Component === DateInput && typeof subField.state.value === 'string' ? parseDate(subField.state.value) : subField.state.value}
                                      variant="bordered"
                                      size="sm"
                                      pathwayId={pathwayId}
                                      className="flex-1"
                                      {...inputProps}
                                    />
                                  )
                                }}
                              </form.Field>
                            )}
                          </>
                        )}
                      />
                      {unappliedFilters?.some(filter => filter.field === filters.state.value[i]?.field) && (
                        <Button size="sm" variant="bordered" isIconOnly onPress={() => filters.removeValue(i)}>
                          <Minus size={12} className="text-gray-500" />
                        </Button>
                      )}
                    </div>
                  )
                })}

                <div className={cn('w-full', { 'flex flex-row  justify-between': hasFilters })}>
                  {hasFilters && (
                    <div className="flex flex-row gap-2">
                      <form.LoadFilterModal />
                      {hasAppliedFilters && (
                        <form.SaveFilterModal />
                      )}
                    </div>
                  )}

                  <div className="flex flex-row gap-2">
                    {hasAppliedFilters && (
                      <Button
                        variant="bordered"
                        size="sm"
                        onPress={handleClearFilters}
                      >
                        <X size={16} />
                        Clear
                      </Button>
                    )}

                    {hasFilters && (
                      <Button
                        variant="bordered"
                        onPress={form.handleSubmit}
                        size="sm"
                        radius="sm"
                      >
                        <Check size={16} />
                        Apply all
                      </Button>
                    )}

                    <Button
                      variant={hasFilters ? 'bordered' : 'flat'}
                      size="sm"
                      onPress={() => filters.pushValue({ field: 'c_id', operator: 'equals', value: '' })}
                      disableRipple
                      radius={hasFilters ? 'sm' : 'none'}
                      fullWidth={!hasFilters}
                    >
                      {hasFilters
                        ? (
                            <>
                              <Plus size={16} />
                              Add filter
                            </>

                          )
                        : (
                            <>
                              <ListFilterPlus size={16} />
                              Filters
                            </>
                          )}
                    </Button>
                  </div>
                </div>
              </div>
            )
          }}
        </form.Field>
      </form.AppForm>
    </>
  )
}
