import { useEffect, useMemo, useRef, useState } from 'react'

/**
 * Utility to hash a string into a numeric seed
 */
function hashCode(str) {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i)
    hash = (hash << 5) - hash + char
    // Convert to 32bit integer
    hash |= 0
  }
  return Math.abs(hash)
}

/**
 * Utility to get a pseudo-random function based on seed
 */
function pseudoRandom(seed) {
  let x = seed
  return () => {
    x = (x * 166452125 + 1013904223) & 0xFFFFFFFF
    return x >>> 0
  }
}

/**
 * The heart of the gradient generation logic.
 */
function generateGradientFromName(name, time = 0) {
  const seed = hashCode(name || '')
  const rand = pseudoRandom(seed)

  // Below is the same `colorSets` array you had in your original code
  const colorSets = [
    {
      name: 'ocean',
      colors: [
        { h: 207, s: 57, l: 26 },
        { h: 207, s: 56, l: 48 },
        { h: 204, s: 38, l: 56 },
        { h: 208, s: 43, l: 77 },
      ],
      neutral: { h: 24, s: 58, l: 72 },
    },
    {
      name: 'volcanic',
      colors: [
        { h: 357, s: 83, l: 35 },
        { h: 350, s: 75, l: 45 },
        { h: 345, s: 65, l: 30 },
        { h: 355, s: 70, l: 40 },
      ],
      neutral: { h: 350, s: 25, l: 65 },
    },
    {
      name: 'arctic',
      colors: [
        { h: 200, s: 30, l: 85 },
        { h: 210, s: 40, l: 75 },
        { h: 220, s: 50, l: 65 },
        { h: 230, s: 60, l: 55 },
      ],
      neutral: { h: 220, s: 15, l: 70 },
    },
    {
      name: 'glacier',
      colors: [
        { h: 190, s: 40, l: 90 },
        { h: 200, s: 50, l: 80 },
        { h: 210, s: 60, l: 70 },
        { h: 220, s: 70, l: 60 },
      ],
      neutral: { h: 200, s: 20, l: 75 },
    },
    {
      name: 'iceberg',
      colors: [
        { h: 200, s: 45, l: 85 },
        { h: 210, s: 50, l: 75 },
        { h: 220, s: 55, l: 65 },
        { h: 230, s: 60, l: 55 },
      ],
      neutral: { h: 210, s: 20, l: 70 },
    },
    {
      name: 'polar_night',
      colors: [
        { h: 220, s: 30, l: 80 },
        { h: 230, s: 40, l: 70 },
        { h: 240, s: 50, l: 60 },
        { h: 250, s: 60, l: 50 },
      ],
      neutral: { h: 220, s: 20, l: 65 },
    },
    {
      name: 'autumn_glow',
      colors: [
        { h: 25, s: 60, l: 60 },
        { h: 15, s: 50, l: 50 },
        { h: 10, s: 40, l: 40 },
        { h: 5, s: 70, l: 30 },
      ],
      neutral: { h: 20, s: 30, l: 50 },
    },
    {
      name: 'stone_haven',
      colors: [
        { h: 30, s: 20, l: 70 },
        { h: 40, s: 25, l: 60 },
        { h: 50, s: 30, l: 50 },
        { h: 60, s: 35, l: 40 },
      ],
      neutral: { h: 45, s: 20, l: 65 },
    },
    {
      name: 'sunset_dunes',
      colors: [
        { h: 35, s: 50, l: 70 },
        { h: 25, s: 60, l: 60 },
        { h: 15, s: 70, l: 50 },
        { h: 5, s: 80, l: 40 },
      ],
      neutral: { h: 30, s: 25, l: 65 },
    },
    {
      name: 'nebula_meadow',
      colors: [
        { h: 290, s: 50, l: 60 },
        { h: 280, s: 40, l: 70 },
        { h: 320, s: 30, l: 55 },
        { h: 300, s: 60, l: 45 },
      ],
      neutral: { h: 270, s: 20, l: 65 },
    },
    {
      name: 'stellar_forest',
      colors: [
        { h: 150, s: 40, l: 50 },
        { h: 100, s: 30, l: 60 },
        { h: 160, s: 50, l: 40 },
        { h: 170, s: 60, l: 30 },
      ],
      neutral: { h: 160, s: 25, l: 55 },
    },
    {
      name: 'desert_rose',
      colors: [
        { h: 15, s: 60, l: 65 },
        { h: 10, s: 50, l: 55 },
        { h: 5, s: 40, l: 45 },
        { h: 20, s: 50, l: 75 },
      ],
      neutral: { h: 10, s: 30, l: 50 },
    },
    {
      name: 'forest_shade',
      colors: [
        { h: 120, s: 40, l: 30 },
        { h: 130, s: 50, l: 40 },
        { h: 110, s: 30, l: 50 },
        { h: 100, s: 40, l: 35 },
      ],
      neutral: { h: 120, s: 20, l: 45 },
    },
    {
      name: 'celestial_horizon',
      colors: [
        { h: 200, s: 70, l: 50 },
        { h: 220, s: 60, l: 60 },
        { h: 180, s: 50, l: 40 },
        { h: 210, s: 40, l: 45 },
      ],
      neutral: { h: 190, s: 30, l: 55 },
    },
    {
      name: 'sandstone_valley',
      colors: [
        { h: 30, s: 40, l: 70 },
        { h: 20, s: 30, l: 60 },
        { h: 25, s: 35, l: 50 },
        { h: 15, s: 25, l: 40 },
      ],
      neutral: { h: 25, s: 20, l: 55 },
    },
    {
      name: 'spring_meadow',
      colors: [
        { h: 90, s: 60, l: 70 },
        { h: 80, s: 50, l: 60 },
        { h: 100, s: 40, l: 50 },
        { h: 110, s: 50, l: 65 },
      ],
      neutral: { h: 95, s: 30, l: 55 },
    },
    {
      name: 'rainy_day',
      colors: [
        { h: 200, s: 20, l: 70 },
        { h: 210, s: 30, l: 60 },
        { h: 220, s: 40, l: 50 },
        { h: 230, s: 20, l: 40 },
      ],
      neutral: { h: 210, s: 15, l: 55 },
    },
    {
      name: 'orchard_blossom',
      colors: [
        { h: 320, s: 40, l: 75 },
        { h: 330, s: 50, l: 65 },
        { h: 340, s: 30, l: 55 },
        { h: 350, s: 60, l: 70 },
      ],
      neutral: { h: 330, s: 25, l: 60 },
    },
    {
      name: 'red_cliff',
      colors: [
        { h: 15, s: 60, l: 50 }, // Deep sandstone red
        { h: 20, s: 50, l: 60 }, // Burnt orange
        { h: 10, s: 40, l: 40 }, // Desert clay
        { h: 25, s: 30, l: 70 }, // Weathered peach
      ],
      neutral: { h: 20, s: 25, l: 55 }, // Soft terracotta beige
    },
    {
      name: 'moonstone_glow',
      colors: [
        { h: 230, s: 15, l: 80 }, // Pale bluish silver
        { h: 210, s: 25, l: 70 }, // Soft icy blue
        { h: 240, s: 10, l: 60 }, // Smoky gray-blue
        { h: 220, s: 20, l: 50 }, // Deep lunar gray
      ],
      neutral: { h: 220, s: 15, l: 65 }, // Subtle stony gray
    },
    {
      name: 'canyon_dusk',
      colors: [
        { h: 30, s: 40, l: 55 }, // Golden sandstone
        { h: 40, s: 50, l: 65 }, // Desert gold
        { h: 35, s: 30, l: 45 }, // Rocky ochre
        { h: 20, s: 20, l: 40 }, // Shadowed stone
      ],
      neutral: { h: 30, s: 25, l: 50 }, // Warm muted beige
    },
    {
      name: 'obsidian_shade',
      colors: [
        { h: 240, s: 20, l: 25 }, // Deep obsidian black
        { h: 250, s: 15, l: 35 }, // Midnight blue-gray
        { h: 260, s: 10, l: 45 }, // Smoky slate
        { h: 230, s: 20, l: 50 }, // Cool reflective gray
      ],
      neutral: { h: 240, s: 10, l: 40 }, // Charcoal gray
    },
    {
      name: 'desert_onyx',
      colors: [
        { h: 35, s: 50, l: 35 }, // Dark desert brown
        { h: 30, s: 40, l: 45 }, // Warm rust
        { h: 20, s: 30, l: 50 }, // Weathered tan
        { h: 15, s: 20, l: 60 }, // Sunlit beige
      ],
      neutral: { h: 25, s: 15, l: 40 }, // Subdued earthy gray
    },
    {
      name: 'granite_peaks',
      colors: [
        { h: 220, s: 15, l: 60 }, // Cool gray stone
        { h: 210, s: 20, l: 50 }, // Medium granite gray
        { h: 230, s: 10, l: 40 }, // Shadowed granite
        { h: 200, s: 25, l: 70 }, // Polished light gray
      ],
      neutral: { h: 220, s: 10, l: 55 }, // Smooth stony gray
    },
    {
      name: 'opal_reflection',
      colors: [
        { h: 180, s: 40, l: 65 }, // Aqua shimmer
        { h: 200, s: 30, l: 70 }, // Pale bluish-white
        { h: 220, s: 20, l: 60 }, // Soft reflective gray-blue
        { h: 170, s: 25, l: 50 }, // Greenish opal tone
      ],
      neutral: { h: 190, s: 15, l: 65 }, // Muted white-blue
    },
    {
      name: 'basalt_flow',
      colors: [
        { h: 240, s: 30, l: 20 }, // Volcanic black
        { h: 220, s: 20, l: 30 }, // Lava-cool gray
        { h: 210, s: 15, l: 40 }, // Dark basalt gray
        { h: 200, s: 10, l: 50 }, // Weathered stone
      ],
      neutral: { h: 230, s: 10, l: 35 }, // Cool volcanic gray
    },
    {
      name: 'sunbaked_terrace',
      colors: [
        { h: 20, s: 50, l: 50 }, // Warm terracotta
        { h: 25, s: 60, l: 65 }, // Sunlit clay
        { h: 15, s: 40, l: 45 }, // Dry earth
        { h: 30, s: 30, l: 35 }, // Shaded stone
      ],
      neutral: { h: 25, s: 20, l: 55 }, // Soft baked beige
    },
  ]
  // Pick a set from colorSets based on our seeded index
  const selectedSet = colorSets[seed % colorSets.length]
  const { colors, neutral } = selectedSet

  const density = 60
  const points = []

  // Some interesting randomization variables
  const baseFreq = 0.2 + (seed % 1000) / 800
  const complexity = 0.25 + (seed % 1000) / 600
  const waveScale = 0.3 + (seed % 1000) / 2000 + Math.sin(time) * 0.1
  const turbulence = 0.9 + (seed % 1000) / 4000 + Math.cos(time * 1.5) * 0.1
  const direction = ((seed % 1000) / 1000) * Math.PI * 2 + time
  const phaseShift = (seed % 1000) / 200 + time * 3

  const primaryPattern = seed % 2
  const secondaryPattern = (seed >> 8) % 8
  const mixRatio = 0.8 + (seed % 1000) / 2500 + Math.sin(time * 0.5) * 0.15

  for (let y = 0; y < density; y++) {
    for (let x = 0; x < density; x++) {
      const normalizedX = x / density - 0.5
      const normalizedY = y / density - 0.5

      const animX = normalizedX + Math.sin(time + normalizedY * Math.PI) * 0.03
      const animY = normalizedY + Math.cos(time + normalizedX * Math.PI) * 0.04

      const angle = Math.atan2(animY, animX)
      const dist = Math.sqrt(animX * animX + animY * animY)

      let pattern1
      switch (primaryPattern) {
        case 0:
          pattern1 = Math.sin(
            animX * baseFreq * Math.PI
            + animY * complexity * Math.PI
            + phaseShift,
          )
          break
        case 1:
        default:
          pattern1 = Math.sin(
            dist * baseFreq * Math.PI * 2.5
            + angle * complexity
            + phaseShift,
          )
          break
      }

      let pattern2
      switch (secondaryPattern) {
        case 0:
          pattern2 = Math.cos(animX * complexity + animY * baseFreq + time)
          break
        case 1:
          pattern2 = Math.sin(angle * complexity + dist * baseFreq + time)
          break
        default:
          pattern2 = Math.cos(dist * complexity * Math.PI + angle * baseFreq + time)
          break
      }

      const basePattern = pattern1 * mixRatio + pattern2 * (1 - mixRatio)
      const turbulentPattern = Math.sin(
        basePattern * Math.PI + turbulence * Math.sin(angle * 4 + time),
      )

      const patternValue = (turbulentPattern + 1) / 2
      const intersectionStrength = Math.abs(pattern1 * pattern2)

      let hue, saturation, lightness, alpha

      // Decide whether to use neutral or one of the colors
      if (intersectionStrength > 0.7) {
        const color = neutral
        hue = color.h
        saturation = color.s
        lightness = color.l
        alpha = 0.4 + intersectionStrength * 0.15
      }
      else {
        const colorIndex = Math.floor(patternValue * 4)
        const color = colors[colorIndex]
        hue = color.h
        saturation = color.s
        lightness = color.l
        alpha = 0.4 + Math.abs(turbulentPattern) * 0.15
      }

      // Each point is a tiny radial gradient
      const size = 8
      const xOffset = (animX + 0.5 + turbulentPattern * 0.8) * 100
      const yOffset = (animY + 0.5 + turbulentPattern * 0.3) * 100

      points.push(`
        radial-gradient(circle at center,
          hsla(${hue}, ${saturation}%, ${lightness}%, ${alpha}) 0%,
          hsla(${hue}, ${saturation}%, ${lightness}%, ${alpha * 0.5}) 50%,
          transparent 100%)
          ${xOffset}% ${yOffset}% / ${size}px ${size}px no-repeat
      `)
    }
  }

  // Combine all the points into a single background definition
  return points.join(', ')
}

/**
 * A reusable component that visualizes the "voice gradient" for any given name.
 * Pass `name`, and optionally `isAnimating` (boolean) to animate on hover or always.
 */
export default function VoiceGradientAvatar({
  name,
  size = 34,
  borderRadius = '50%',
  isAnimating = false,
  hoverAnimation = true,
}) {
  const [time, setTime] = useState(0)
  const [gradientBase, setGradientBase] = useState(null)
  const containerRef = useRef(null)
  const animationRef = useRef(null)
  const [activeAnimation, setActiveAnimation] = useState(false)

  // Lazy-load the gradient when in the viewport
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          // Generate static gradient first
          if (!gradientBase) {
            setGradientBase(generateGradientFromName(name, 0))
          }
          // Start animating if isAnimating is true
          if (isAnimating) {
            setActiveAnimation(true)
          }
          observer.disconnect()
        }
      },
      { threshold: 0.1 },
    )

    if (containerRef.current)
      observer.observe(containerRef.current)

    return () => {
      observer.disconnect()
    }
  }, [name, gradientBase, isAnimating])

  // If we are actively animating, keep incrementing time
  useEffect(() => {
    if (activeAnimation) {
      const start = Date.now()
      const animate = () => {
        const now = Date.now()
        const elapsed = (now - start) / 2500 // speed factor
        setTime(elapsed)
        animationRef.current = requestAnimationFrame(animate)
      }
      animationRef.current = requestAnimationFrame(animate)

      return () => {
        if (animationRef.current)
          cancelAnimationFrame(animationRef.current)
      }
    }
    // If we stop animating, reset time to 0
    else {
      setTime(0)
      if (animationRef.current)
        cancelAnimationFrame(animationRef.current)
    }
  }, [activeAnimation])

  // Compute final background from the gradient function
  const avatarStyle = useMemo(() => {
    if (!gradientBase) {
      // If gradient is not set yet, show a placeholder color
      return {
        width: size,
        height: size,
        borderRadius,
        background: '#f3f3f3',
        overflow: 'hidden',
        position: 'relative',
      }
    }
    // If base gradient is set, possibly animate
    return {
      width: size,
      height: size,
      borderRadius,
      background: generateGradientFromName(name, time),
      overflow: 'hidden',
      position: 'relative',
    }
  }, [name, time, gradientBase, size])

  return (
    <div
      ref={containerRef}
      style={avatarStyle}
      onMouseEnter={() => {
        if (hoverAnimation)
          setActiveAnimation(true)
      }}
      onMouseLeave={() => {
        if (hoverAnimation && !isAnimating)
          setActiveAnimation(false)
      }}
    >
      {/* Display a spinner while we haven't generated the gradientBase yet */}
      {!gradientBase && (
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div
            className="loading-spinner"
            style={{
              width: 14,
              height: 14,
              border: '2px solid #ccc',
              borderTopColor: '#555',
              borderRadius,
              animation: 'spin 1s linear infinite',
            }}
          />
        </div>
      )}
      <style>
        {`
        @keyframes spin {
          0% { transform: rotate(0); }
          100% { transform: rotate(360deg); }
        }
      `}
      </style>
    </div>
  )
}
