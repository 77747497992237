import * as Icons from '@radix-ui/react-icons'
import { IconButton, Slider } from '@radix-ui/themes'
import { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import styled from 'styled-components'

function AudioComponent({ recordingUrl }) {
  const [isPlaying, setIsPlaying] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [currentTime, setCurrentTime] = useState(0)
  const [duration, setDuration] = useState(0)
  const audioRef = useRef(new Audio())

  useEffect(() => {
    const audio = audioRef.current
    audio.addEventListener('loadedmetadata', handleLoadedMetadata)
    audio.addEventListener('timeupdate', handleTimeUpdate)
    audio.addEventListener('ended', handleEnded)
    audio.addEventListener('canplay', () => setIsLoading(false))

    return () => {
      audio.removeEventListener('loadedmetadata', handleLoadedMetadata)
      audio.removeEventListener('timeupdate', handleTimeUpdate)
      audio.removeEventListener('ended', handleEnded)
      audio.removeEventListener('canplay', () => setIsLoading(false))
      URL.revokeObjectURL(audio.src)
    }
  }, [])

  const handleLoadedMetadata = () => {
    setDuration(audioRef.current.duration)
  }

  const handleTimeUpdate = () => {
    setCurrentTime(audioRef.current.currentTime)
  }

  const handleEnded = () => {
    setIsPlaying(false)
    setCurrentTime(0)
  }

  const handlePlayPause = async () => {
    if (!audioRef.current.src) {
      setIsLoading(true)
      try {
        const response = await fetch(recordingUrl)

        if (response.ok) {
          const blob = await response.blob()
          const url = URL.createObjectURL(blob)
          audioRef.current.src = url
          await audioRef.current.play()
          setIsPlaying(true)
        }
        else if (response.status === 404) {
          const data = await response.json()
          toast.error(data.error)
          setIsLoading(false)
        }
        else {
          console.error('Failed to fetch the audio file')
          setIsLoading(false)
        }
      }
      catch (error) {
        console.error('Error fetching recording:', error)
        setIsLoading(false)
      }
    }
    else {
      if (isPlaying) {
        audioRef.current.pause()
      }
      else {
        audioRef.current.play()
      }
      setIsPlaying(!isPlaying)
    }
  }

  const handleSliderChange = (value) => {
    const newTime = value[0]
    audioRef.current.currentTime = newTime
    setCurrentTime(newTime)
  }

  const handleDownload = async () => {
    try {
      const response = await fetch(recordingUrl)

      if (response.ok) {
        const blob = await response.blob()
        const url = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = `recording_${Date.now()}.wav`
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
        URL.revokeObjectURL(url)
      }
      else if (response.status === 404) {
        const data = await response.json()
        toast.error(data.error)
      }
      else {
        console.error('Failed to download the audio file')
      }
    }
    catch (error) {
      console.error('Error downloading recording:', error)
    }
  }

  return (
    <AudioControlsWrapper
      onClick={e => e.stopPropagation()}
      className="audio-controls"
    >
      <IconButton
        onClick={handlePlayPause}
        size="1"
        variant="surface"
        color="green"
        style={{ cursor: 'pointer' }}
        disabled={isLoading}
      >
        {isLoading
          ? (
              <SpinningIcon />
            )
          : isPlaying
            ? (
                <Icons.PauseIcon />
              )
            : (
                <Icons.PlayIcon />
              )}
      </IconButton>
      <SliderWrapper>
        <Slider
          value={[currentTime]}
          max={duration || 100}
          step={0.1}
          onValueChange={handleSliderChange}
        />
      </SliderWrapper>
      <IconButton
        onClick={handleDownload}
        size="1"
        variant="surface"
        color="blue"
        style={{ cursor: 'pointer' }}
      >
        <Icons.DownloadIcon />
      </IconButton>
    </AudioControlsWrapper>
  )
}

const AudioControlsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
`

const SliderWrapper = styled.div`
  flex-grow: 1;
  margin: 0 10px;
`

const SpinningIcon = styled(Icons.ReloadIcon)`
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  animation: spin 1s linear infinite;
`

export default AudioComponent
