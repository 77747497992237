// Import the question mark icon
import { Dropdown } from '_components/Select'
import { TooltipText } from '_components/Text'
// import Select from "components/core/Select";
import { useFormContext } from 'react-hook-form'

const languageMap = {
  'en': 'English',
  'babel': 'Babel (Experimental, All Languages)',
  'auto': 'Auto Detect (English & Spanish)',
  'en-US': 'English (US)',
  'en-GB': 'English (UK)',
  'en-AU': 'English (Australia)',
  'en-NZ': 'English (New Zealand)',
  'en-IN': 'English (India)',
  'es': 'Spanish',
  'es-419': 'Spanish (Latin America)',
  'fr': 'French',
  'fr-CA': 'French (Canada)',
  'de': 'German',
  'el': 'Greek',
  'hi': 'Hindi',
  'hi-Latn': 'Hindi (Latin script)',
  'ja': 'Japanese',
  'ko': 'Korean',
  'ko-KR': 'Korean (Korea)',
  'pt': 'Portuguese',
  'pt-BR': 'Portuguese (Brazil)',
  'zh': 'Chinese (Mandarin, Simplified)',
  'zh-CN': 'Chinese (Mandarin, Simplified, China)',
  'zh-Hans': 'Chinese (Mandarin, Simplified, Hans)',
  'zh-TW': 'Chinese (Mandarin, Traditional)',
  'zh-Hant': 'Chinese (Mandarin, Traditional, Hant)',
  'it': 'Italian',
  'nl': 'Dutch',
  'pl': 'Polish',
  'ru': 'Russian',
  'sv': 'Swedish',
  'sv-SE': 'Swedish (Sweden)',
  'da': 'Danish',
  'da-DK': 'Danish (Denmark)',
  'fi': 'Finnish',
  'id': 'Indonesian',
  'ms': 'Malay',
  'tr': 'Turkish',
  'uk': 'Ukrainian',
  'bg': 'Bulgarian',
  'cs': 'Czech',
  'ro': 'Romanian',
  'sk': 'Slovak',
}

export default function Language({ fieldName }) {
  const { watch, setValue } = useFormContext()
  const currentLanguage = watch(fieldName)
  const longText = (
    <span>
      Select a supported language of your choice.
      <br />
      {' '}
      Optimizes every part of our API for that language - transcription,
      speech, and other inner workings.
      <br />
    </span>
  )

  return (
    <div style={{}}>
      <div style={{ marginBottom: 5 }}>
        <TooltipText tooltip={longText} tooltipClassName="w-[300px] h-[full]">
          Language
        </TooltipText>
      </div>

      <Dropdown
        options={Object.keys(languageMap)?.map(item => ({
          key: languageMap[item],
          value: item,
        }))}
        value={currentLanguage}
        onChange={(value) => {
          console.log(value)
          setValue(fieldName, value.value)
        }}
        placeholder="Select a language..."
        dropdownClass="max-h-[400px]"
      />

      {/* <Select.Root
        value={currentLanguage}
        // fieldName={fieldName}
        onValueChange={(value) => {
          setValue("language", value);
        }}
        size={"3"}
        style={{ width: "100%" }}
      >
        <Select.Trigger
          placeholder="Select a language"
          style={{ width: "100%" }}
        >
          {languageMap[currentLanguage]}
        </Select.Trigger>
        <Select.Content
          position="popper"
          sideOffset={5}
          style={{ width: "100%", maxHeight: 340 }}
          variant="soft"
        >
          {Object.keys(languageMap).map((key) => (
            <Select.Item key={key} value={key}>
              {languageMap[key]}
            </Select.Item>
          ))}
        </Select.Content>
      </Select.Root> */}
    </div>
  )
}
