import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectSeparator,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline' // Import the question mark icon
import Label from 'components/core/Label'
import { Loader2 } from 'lucide-react'
import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { $fetch } from 'utils/fetch'
import LightTooltip from './ToolTipDesc'

export default function PathwayVersion({
  pathwayId,
  fieldName = 'pathway_version',
}) {
  const { setValue, watch } = useFormContext()
  const [versions, setVersions] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const [isHistoricalExpanded, setIsHistoricalExpanded] = useState(false)

  useEffect(() => {
    if (!watch(fieldName) && pathwayId) {
      setValue(fieldName, '0')
    }
  }, [watch, fieldName, pathwayId, setValue])

  useEffect(() => {
    const fetchVersions = async () => {
      if (!pathwayId)
        return

      setIsLoading(true)
      setError(null)
      try {
        const data = await $fetch(`/v1/pathway/${pathwayId}/versions`)

        setVersions(data || [])
        // Set default version to production (0) if not already set
        if (!watch(fieldName)) {
          setValue(fieldName, '0')
        }
      }
      catch (err) {
        setError('Failed to load pathway versions')
        console.error('Error fetching pathway versions:', err)
      }
      finally {
        setIsLoading(false)
      }
    }

    fetchVersions()
  }, [pathwayId, fieldName, setValue, watch])

  const handleValueChange = (value) => {
    setValue(fieldName, value)
  }

  // Group versions by their type
  const stagingVersion = versions.find(v => v.is_staging)
  const devVersions = versions.filter(
    v => !v.is_staging && !v.is_prev_published,
  )
  const historicalVersions = versions.filter(v => v.is_prev_published)

  const getSelectedVersionDisplay = () => {
    const currentVersion = watch(fieldName)
    if (!currentVersion)
      return null

    // Check historical versions first
    const historicalVersion = historicalVersions.find(
      v => v.version_number.toString() === currentVersion,
    )
    if (historicalVersion) {
      return `${historicalVersion.name || `Version ${historicalVersion.version_number}`} (${new Date(historicalVersion.created_at).toLocaleDateString()})`
    }

    // Check other versions
    return currentVersion === '0'
      ? 'Production'
      : stagingVersion?.version_number.toString() === currentVersion
        ? stagingVersion.name
          ? `Staging (${stagingVersion.name})`
          : 'Staging'
        : devVersions.find(
          v => v.version_number.toString() === currentVersion,
        )?.name || `Version ${currentVersion}`
  }

  return (
    <div className="flex flex-col gap-1.5 w-full">
      <div className="flex flex-col gap-1.5">
        <span className="flex items-center">
          <Label htmlFor={fieldName}>Pathway Version</Label>
          <LightTooltip
            title="Select the version of the pathway to use. Default is production."
            placement="right-start"
            arrow
          >
            <HelpOutlineIcon color="primary" style={{ paddingLeft: '4px' }} />
          </LightTooltip>
        </span>
        <Select
          onValueChange={handleValueChange}
          value={watch(fieldName)}
          disabled={isLoading}
        >
          <SelectTrigger className="w-full h-10 relative">
            <SelectValue placeholder="Select version">
              {getSelectedVersionDisplay()}
            </SelectValue>
            {isLoading && (
              <div className="absolute right-5">
                <Loader2 className="h-2.5 w-2.5 animate-spin" />
              </div>
            )}
          </SelectTrigger>
          <SelectContent className="max-h-60 overflow-y-auto">
            <SelectGroup>
              <SelectLabel>Current</SelectLabel>
              <SelectItem
                value="0"
                className="flex items-center justify-between"
              >
                Production
              </SelectItem>
              {stagingVersion && (
                <SelectItem
                  value={stagingVersion.version_number.toString()}
                  className="flex items-center justify-between"
                >
                  Staging
                  {' '}
                  {stagingVersion.name ? `(${stagingVersion.name})` : ''}
                </SelectItem>
              )}
            </SelectGroup>

            {devVersions.length > 0 && (
              <>
                <SelectSeparator />
                <SelectGroup>
                  <SelectLabel>Development</SelectLabel>
                  {devVersions.map(version => (
                    <SelectItem
                      key={version.version_number}
                      value={version.version_number.toString()}
                      className="flex items-center justify-between"
                    >
                      {version.name || `Version ${version.version_number}`}
                    </SelectItem>
                  ))}
                </SelectGroup>
              </>
            )}

            {historicalVersions.length > 0 && (
              <>
                <SelectSeparator />
                <SelectGroup>
                  <SelectLabel
                    className="flex items-center justify-between cursor-pointer hover:bg-accent hover:text-accent-foreground p-1.5 rounded-sm"
                    onClick={(e) => {
                      e.preventDefault()
                      setIsHistoricalExpanded(!isHistoricalExpanded)
                    }}
                  >
                    Historical (
                    {historicalVersions.length}
                    )
                    <span
                      className={`transform transition-transform ${isHistoricalExpanded ? 'rotate-180' : ''}`}
                    >
                      ▼
                    </span>
                  </SelectLabel>
                  {isHistoricalExpanded
                  && historicalVersions.map(version => (
                    <SelectItem
                      key={version.version_number}
                      value={version.version_number.toString()}
                      className="flex items-center justify-between"
                    >
                      {version.name || `Version ${version.version_number}`}
                      {' '}
                      (
                      {new Date(version.created_at).toLocaleDateString()}
                      )
                    </SelectItem>
                  ))}
                </SelectGroup>
              </>
            )}
          </SelectContent>
        </Select>
      </div>

      {error && <p className="text-xs text-red-500">{error}</p>}
    </div>
  )
}
