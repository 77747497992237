import { Card } from '@/components/ui/card'

import { Tooltip } from '@radix-ui/themes'
import {
  AlertTriangle,
  BarChart2,
  CheckCircle2,
  Filter,
  Loader2,
  Plus,
  RotateCcw,
  XCircle,
} from 'lucide-react'
import React, { useContext, useEffect, useRef, useState } from 'react'
import {
  MdOutlineCheck,
  MdOutlineCloseFullscreen,
  MdOutlineContentCopy,
  MdOutlineOpenInFull,
} from 'react-icons/md'
import { RxCross2 } from 'react-icons/rx'
import {
  Line,
  LineChart,
  Tooltip as RechartsTooltip,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts'
import FlowContext from '../../contextFlow'
import { useSimulationsContext } from '../../simulations/context/SimulationContext'
import ChatMessage from './chatMessage'
import { Link } from 'react-router-dom'

function ChatUI({
  messages = [],
  onSendMessage,
  onClose,
  callID,
  currentNodeName,
  isLoading,
  onEditMessage,
  isReplayMode = false,
  replayIndex,
  onReplayIndexChange,
  nodes = {},
  callPath = [],
  onRestart, // New prop for restart
  version,
  isSmsChatMode = false, // New prop to indicate this is an SMS chat
}) {

  // Add a console log to check messages
  console.log("ChatUI received messages:", messages);
  console.log("ChatUI callID:", callID);
  console.log("ChatUI isSmsChatMode:", isSmsChatMode);

  const { handleAddToSimulation, getStatus, currentReplaySimulationCallID }
    = useSimulationsContext()

  const { setCollapsePathwayLogs } = useContext(FlowContext)

  let simulationReplayStatus
  let simulationEvaluation

  if (currentReplaySimulationCallID) {
    const res = getStatus(currentReplaySimulationCallID)
    simulationReplayStatus = res.status === 'pass'
    simulationEvaluation = res.evaluation
  }
  else {
    simulationReplayStatus = null
  }

  const [inputMessage, setInputMessage] = useState('')
  const [isExpanded, setIsExpanded] = useState(false) // Default to expanded
  const [copied, setCopied] = useState(false)
  const chatContainerRef = useRef(null)
  const [showAnalytics, setShowAnalytics] = useState(false)
  const [filter, setFilter] = useState('all')
  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 7
  const isCallEnded = messages.some(msg =>
    (msg.content || msg.text).startsWith('<Action Executed'),
  )

  const [activeMessageId, setActiveMessageId] = useState(null)

  const handleMessageAction = (messageId) => {
    setActiveMessageId(messageId === activeMessageId ? null : messageId)
  }

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [messages, currentPage]);

  // Add an additional effect to scroll when messages change
  useEffect(() => {
    if (messages && messages.length > 0 && chatContainerRef.current) {
      setTimeout(() => {
        if (chatContainerRef.current) {
          chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
      }, 100);
    }
  }, [messages]);

  const filteredMessages = React.useMemo(() => {
    console.log("before filtered messages:", messages);

    const initialFilterMessages = messages
      ? messages.filter(
          message => !message?.text?.includes('<<CALL CONNECTED>>') && 
                    (message.content || message.text) !== '<Call Connected>',
        )
      : []
    if (filter === 'all') {
      console.log("Initial filter messages:", initialFilterMessages);
      return initialFilterMessages
    }
    return initialFilterMessages.filter(message => message.role === filter)
  }, [messages, filter])

  const analyticsData = React.useMemo(() => {
    if (!isReplayMode)
      return []

    return messages.reduce((acc, message, index) => {
      const minute = Math.floor(index / 2)
      if (!acc[minute]) {
        acc[minute] = { minute, userWords: 0, aiWords: 0 }
      }
      const wordCount = (message.content || message.text).split(' ').length
      if (message.role === 'user') {
        acc[minute].userWords += wordCount
      }
      else {
        acc[minute].aiWords += wordCount
      }
      return acc
    }, {})
  }, [messages, isReplayMode])

  const handleSend = () => {
    if (!isSmsChatMode && inputMessage.trim() && onSendMessage) {
      onSendMessage(inputMessage);
      setInputMessage('');
    }
  }

  const handleCopyConversation = () => {
    const conversationHistory
      = `Call ID: ${callID}\n${
        messages
          .map(msg => `${msg.role}: ${msg.content || msg.text}`)
          .join('\n')}`
    navigator.clipboard.writeText(conversationHistory)
    setCopied(true)
    setTimeout(() => setCopied(false), 2000)
  }

  return (
    <div
      className={`fixed bottom-0 left-[58px] bg-white z-50 shadow-lg rounded-t-lg overflow-hidden transition-all duration-300 ease-in-out ${
        isExpanded ? 'h-[calc(100vh-64px)] w-[400px]' : 'h-[500px] w-[360px]'
      } flex flex-col`}
    >
      {/* Header */}
      <div className="flex flex-col border-b bg-white">
        {/* Main header row */}
        <div className="flex items-center justify-between px-2.5 pt-2.5">
          <h2 className="text-base font-semibold min-w-fit">
            {isReplayMode ? 'Call Replay' : 'Pathway Chat'}
          </h2>
          <div className="flex items-center space-x-1">
            {!isReplayMode && (
              <Tooltip content="Save this conversation as an automated test case to validate your pathway's functionality when making future updates">
                <button
                  onClick={() => {
                    setCollapsePathwayLogs(true)
                    handleAddToSimulation(callID)
                  }}
                  className="flex items-center gap-1.5 px-2 py-1 mr-1.5 bg-indigo-600 hover:bg-indigo-700 text-white rounded transition-colors relative"
                >
                  <Plus className="h-2.5 w-2.5" />
                  <span className="font-medium text-xs">Save Test</span>
                  <span className="absolute -top-0.5 -right-0.5 flex h-2 w-2">
                    <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-indigo-400 opacity-75"></span>
                    <span className="relative inline-flex rounded-full h-2 w-2 bg-indigo-500"></span>
                  </span>
                </button>
              </Tooltip>
            )}

            {/* Keep primary actions visible */}
            {isReplayMode && (
              <>
                <button
                  onClick={() => setShowAnalytics(!showAnalytics)}
                  className="p-1.5 bg-gray-100 rounded hover:bg-gray-200"
                >
                  <BarChart2 size={16} />
                </button>
                <button
                  onClick={() => {
                    setFilter(
                      filter === 'all'
                        ? 'user'
                        : filter === 'user'
                          ? 'assistant'
                          : 'all',
                    )
                    setCurrentPage(1)
                  }}
                  className="p-1.5 bg-gray-100 rounded hover:bg-gray-200"
                >
                  <Filter size={16} />
                </button>
              </>
            )}

            {onRestart && (
              <Tooltip content="Restart Chat">
                <button
                  onClick={onRestart}
                  className="p-1.5 bg-gray-100 rounded hover:bg-gray-200"
                >
                  <RotateCcw size={16} />
                </button>
              </Tooltip>
            )}

            <Tooltip content="Copy Conversation">
              <button
                onClick={handleCopyConversation}
                className="p-1.5 bg-gray-100 rounded hover:bg-gray-200"
              >
                {copied
                  ? (
                      <MdOutlineCheck size={16} />
                    )
                  : (
                      <MdOutlineContentCopy size={16} />
                    )}
              </button>
            </Tooltip>

            <button
              onClick={() => setIsExpanded(!isExpanded)}
              className="p-1.5 bg-gray-100 rounded hover:bg-gray-200"
            >
              {isExpanded
                ? (
                    <MdOutlineCloseFullscreen size={16} />
                  )
                : (
                    <MdOutlineOpenInFull size={16} />
                  )}
            </button>
            <button
              onClick={onClose}
              className="p-1.5 bg-gray-100 rounded hover:bg-gray-200"
            >
              <RxCross2 size={20} />
            </button>
          </div>
        </div>

        <div className="flex justify-between px-2.5 pb-2 items-baseline">
          {version?.toString() && (
            <p className="text-xs text-gray-500">
              {version === 0
                ? 'Production'
                : `Version ${version}`}
            </p>
          )}
          {/* Node name row - only render if there's a current node */}
          {currentNodeName && (
            <div className="flex items-center px-2 py-1 bg-blue-50 border border-blue-200 rounded w-fit max-w-full">
              <span className="text-xs text-blue-700 font-medium truncate">
                {currentNodeName}
              </span>
            </div>
          )}
        </div>
      </div>

      {/* Analytics Section */}
      {showAnalytics && isReplayMode && (
        <div className="mb-4 p-2.5 bg-gray-50">
          <h4 className="text-sm font-semibold mb-2.5">Conversation Analytics</h4>
          <ResponsiveContainer width="100%" height={200}>
            <LineChart data={Object.values(analyticsData)}>
              <XAxis dataKey="minute" />
              <YAxis />
              <RechartsTooltip />
              <Line
                type="monotone"
                dataKey="userWords"
                stroke="#3b82f6"
                name="User Words"
              />
              <Line
                type="monotone"
                dataKey="aiWords"
                stroke="#10b981"
                name="AI Words"
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      )}

      {/* Messages Container */}
      <div
        ref={chatContainerRef}
        className="flex-1 overflow-y-auto p-2.5 bg-white"
        style={{
          height: showAnalytics
            ? 'calc(100% - 240px)'
            : isCallEnded || isReplayMode
              ? 'calc(100% - 180px)'
              : 'calc(100% - 128px)',
        }}
      >
        {filteredMessages.map((message, index) => {
          const globalIndex = (currentPage - 1) * itemsPerPage + index
          const currNodeId
            = callPath?.[Math.min(globalIndex, callPath.length - 1)]
          const nodeInfo = nodes?.[currNodeId]?.data

          return (
            <ChatMessage
              key={`${currentPage}-${index}`}
              messageId={`${currentPage}-${index}`}
              message={message}
              isHighlighted={isReplayMode && globalIndex === replayIndex}
              isActive={!isReplayMode || globalIndex <= replayIndex}
              onMessageClick={() =>
                isReplayMode && onReplayIndexChange?.(globalIndex)}
              setIsExpanded={setIsExpanded}
              onEditMessage={onEditMessage}
              isReplayMode={isReplayMode}
              nodeInfo={nodeInfo}
              activeMessageId={activeMessageId}
              onActionClick={handleMessageAction}
            />
          )
        })}

        {isLoading && (
          <div className="absolute inset-0 flex items-center justify-center bg-white/80">
            <div className="flex items-center space-x-1.5">
              <Loader2 className="w-3 h-3 animate-spin text-gray-500" />
              <span className="text-xs text-gray-500">Connecting...</span>
            </div>
          </div>
        )}
      </div>

      {/* Footer */}
      <div className="p-2.5 border-t bg-white">
        {isSmsChatMode ? (
          <div className="flex items-center justify-center p-2 bg-blue-50 border border-blue-100 rounded-md">
            <span className="text-blue-700 text-sm font-medium">
              Send an SMS from your phone to continue the conversation, or <Link to={`/dashboard/sms`} className='text-blue-700 underline'> view SMS Dashboard</Link>
            </span>
          </div>
        ) : !isCallEnded && !isReplayMode ? (
          <div className="relative">
            <input
              type="text"
              value={inputMessage}
              onChange={e => setInputMessage(e.target.value)}
              onKeyDown={e => e.key === 'Enter' && !isLoading && handleSend()}
              className="w-full px-2.5 pr-12 py-2 bg-gray-50 rounded-md text-gray-800 placeholder-gray-500 focus:outline-none"
              placeholder="Your Message"
              disabled={isLoading}
            />
            <button
              onClick={handleSend}
              disabled={isLoading}
              className={`absolute right-2.5 top-1/2 -translate-y-1/2 text-gray-900 font-medium focus:outline-none ${
                isLoading
                  ? 'opacity-50 cursor-not-allowed'
                  : 'hover:text-gray-700'
              }`}
            >
              {isLoading
                ? (
                    <Loader2 className="w-2.5 h-2.5 animate-spin" />
                  )
                : (
                    'SEND'
                  )}
            </button>
          </div>
        ) : currentReplaySimulationCallID
          ? (
              <div className="border-t bg-white">
                <div className="max-w-3xl mx-auto p-2.5">
                  <Card
                    className={`p-2.5 ${
                      simulationReplayStatus
                        ? 'bg-green-50 border-green-100'
                        : 'bg-red-50 border-red-100'
                    }`}
                  >
                    <div className="flex flex-col space-y-2.5">
                      <div className="flex items-center space-x-2">
                        {simulationReplayStatus
                          ? (
                              <div className="rounded-full bg-green-100 p-0.5">
                                <CheckCircle2 className="w-3 h-3 text-green-600" />
                              </div>
                            )
                          : (
                              <div className="rounded-full bg-red-100 p-0.5">
                                <XCircle className="w-3 h-3 text-red-600" />
                              </div>
                            )}
                        <h3
                          className={`font-medium ${
                            simulationReplayStatus
                              ? 'text-green-900'
                              : 'text-red-900'
                          }`}
                        >
                          Test Case
                          {' '}
                          {simulationReplayStatus ? 'Passed' : 'Failed'}
                        </h3>
                      </div>

                      {!simulationReplayStatus && simulationEvaluation && (
                        <div className="w-full bg-red-100 rounded p-2">
                          <p className="whitespace-pre-wrap font-mono text-2xs text-red-800">
                            {simulationEvaluation}
                          </p>
                        </div>
                      )}
                    </div>
                  </Card>
                </div>
              </div>
            )
          : (
              <div className="flex flex-col gap-2.5 p-2.5 bg-blue-50 border border-blue-100">
                <div className="flex items-start gap-2">
                  <AlertTriangle className="w-3 h-3 text-blue-600 mt-px" />
                  <div>
                    <h4 className="font-medium text-blue-900 mb-0.5">
                      Recommended Action
                    </h4>
                    <p className="text-indigo-800 text-xs leading-relaxed">
                      Save this conversation as an automated test case to ensure
                      your pathway continues to handle this scenario correctly when
                      making future updates.
                    </p>
                  </div>
                </div>
                <button
                  onClick={() => {
                    setCollapsePathwayLogs(true)
                    handleAddToSimulation(callID)
                  }}
                  className="w-full flex items-center justify-center gap-1.5 text-xs bg-indigo-600 hover:bg-indigo-700 text-white py-1.5 px-2.5 rounded transition-colors font-medium"
                >
                  <Plus size={16} />
                  SAVE AS TEST CASE
                </button>
              </div>
            )}
      </div>
    </div>
  )
}

export default ChatUI
