export function formatMessageDate(dateString) {
  const date = new Date(dateString)
  const today = new Date()
  const yesterday = new Date()
  yesterday.setDate(yesterday.getDate() - 1)

  const dateWithoutTime = new Date(date.getFullYear(), date.getMonth(), date.getDate())
  const todayWithoutTime = new Date(today.getFullYear(), today.getMonth(), today.getDate())
  const yesterdayWithoutTime = new Date(yesterday.getFullYear(), yesterday.getMonth(), yesterday.getDate())

  if (dateWithoutTime.getTime() === todayWithoutTime.getTime()) {
    return 'Today'
  }
  else if (dateWithoutTime.getTime() === yesterdayWithoutTime.getTime()) {
    return 'Yesterday'
  }
  else {
    // Format as "Monday, January 1" or similar
    return date.toLocaleDateString(undefined, {
      weekday: 'long',
      month: 'long',
      day: 'numeric',
    })
  }
}

export function isDifferentDay(date1, date2) {
  if (!date1 || !date2)
    return true

  const d1 = new Date(date1)
  const d2 = new Date(date2)

  return d1.getFullYear() !== d2.getFullYear()
    || d1.getMonth() !== d2.getMonth()
    || d1.getDate() !== d2.getDate()
} 