import {
  Badge,
  Button,
  DropdownMenu,
  IconButton,
  Spinner,
  Tooltip,
} from '@radix-ui/themes'
import { Button as HeroUIButton } from '@heroui/react'
import {
  ChevronRight,
  CircleAlert,
  Copy,
  Ellipsis,
  ExternalLink,
  GitBranch,
  Link as LinkIcon,
  MessageSquare,
  Pencil,
  Phone,
  Plus,
  RefreshCw,
} from 'lucide-react'
import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import config from 'config'
import { getApiKey } from 'utils/funcs/browser/getApiKey'
import { getOrgId } from 'utils/funcs/browser/getOrgId'
import { getAuthToken } from 'utils/funcs/browser/getAuthToken'
import { $fetch } from 'utils/fetch'
import { Loading } from 'components/core/Loading'
import Pathway from 'components/Dashboard/Parameters/Pathway'
import { FormProvider, useForm } from 'react-hook-form'
import DuplicatePathwayDialog from '../duplicatePathwayDialog'
import { toDtmy } from 'utils/formatting/toDtmy'
import { useNavigate } from 'react-router-dom'
import InboundNumberDropdown from '../shared/InboundNumberDropdown'
import PathwayComparisonSelector from "../PathwayComparisonSelector"
import SlideoutPanel from "../shared/SlideoutPanel"
import SMSPathwayPreview from "./SMSPathwayPreview"

const PublishedBox = styled.div`
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 30px;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: row;
  grid-template-columns: 1fr 1fr;
  column-gap: 5%;
  row-gap: 10px;
  max-width: 100%;
  align-items: center;

  @media (min-width: 998px) {
    max-height: 500px;
  }

  @media (max-width: 998px) {
    flex-direction: column;
  }

  > * {
    flex: 1;

    @media (max-width: 998px) {
      width: 100%;
    }
  }
`

const VersionBox = styled.div`
  border: 1px solid #e0e0e0;
  border-radius: 4px;
`

const VersionItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid #e0e0e0;
  background-color: white;
`

const PathwayInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`

const PathwayActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin-left: auto;
`

const StyledBadge = styled(Badge)`
  font-size: 10px;
  line-height: 1;
  padding: 2px 6px;
`

const DropdownDivItem = styled.div`
  cursor: pointer;
  padding: 5px;
  border-radius: 4px;
  &:hover {
    background-color: #f0f0f0;
  }
`

export default function SMSPathway({
  id,
  graphData,
  setGraphData,
  isLoading,
  setIsSmsPathway,
  getVersions,
  handleCreateVersion
}) {

    console.log("id", id)
  const orgId = getOrgId();
  const [loadingChangeNumber, setLoadingChangeNumber] = useState(false);
  const [smsPathwayId, setSmsPathwayId] = useState(null);
  const [smsPathwayDetails, setSmsPathwayDetails] = useState(null);
  const [isLoadingSmsPathway, setIsLoadingSmsPathway] = useState(false);
  const [duplicateDialogOpen, setDuplicateDialogOpen] = useState(false);
  const [refreshPathways, setRefreshPathways] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isExistingSmsPathway, setIsExistingSmsPathway] = useState(false);
  const [rootPathwayId, setRootPathwayId] = useState(null);
  const [rootPathwayDetails, setRootPathwayDetails] = useState(null);
  const [creatingClone, setCreatingClone] = useState(false);
  const [showComparisonSelector, setShowComparisonSelector] = useState(false);
  const [hasMergeConflicts, setHasMergeConflicts] = useState(false);
  const [showSmsPreviewPanel, setShowSmsPreviewPanel] = useState(false);

  const methods = useForm({
    defaultValues: {
      pathway_id: '',
    },
  });

  const navigate = useNavigate();

  // Fetch the current SMS pathway ID
  useEffect(() => {
    const fetchSmsPathwayId = async () => {
      setIsLoadingSmsPathway(true);
      try {
        console.log("fetching sms pathway id", id)
        const response = await $fetch(`/v1/pathway/${id}/sms`)
        
        

        console.log("response from sms pathway", response)
        if (!response.errors || response.errors.length === 0) {
          // Check if this is an existing SMS pathway
          if (response.data.is_existing_sms_pathway) {
            setIsExistingSmsPathway(true);
            // Notify parent this is an SMS pathway
            if (setIsSmsPathway) setIsSmsPathway(true);
            // The root pathway ID is actually the ID of the parent pathway
            // which we get from the response
            setRootPathwayId(response.data.root_pathway_id);
            setSmsPathwayId(null);
            
            // Fetch the parent pathway details
            if (response.data.root_pathway_id) {
              try {
                const parentPathwayDetails = await $fetch(`/v1/pathway/${response.data.root_pathway_id}`);

                if (parentPathwayDetails && parentPathwayDetails.data) {
                  setRootPathwayDetails(parentPathwayDetails.data);
                }
              } catch (error) {
                console.error('Error fetching parent pathway details:', error);
              }
            }
          } else {
            setIsExistingSmsPathway(false);
            setSmsPathwayId(response.data.sms_pathway_id);
            methods.reset({ pathway_id: response.data.sms_pathway_id || '' });
            
            // If we have an SMS pathway ID, fetch its details
            if (response.data.sms_pathway_id) {
              try {
                const pathwayDetails = await $fetch(`/v1/pathway/${response.data.sms_pathway_id}`);
                if (pathwayDetails && pathwayDetails.data) {
                  setSmsPathwayDetails(pathwayDetails.data);
                }
              } catch (error) {
                console.error('Error fetching SMS pathway details:', error);
              }
            }
          }
        }
      } catch (error) {
        console.error('Error fetching SMS pathway ID:', error);
        toast.error('Failed to load SMS pathway details');
      } finally {
        setIsLoadingSmsPathway(false);
      }
    };

    if (id) {
      fetchSmsPathwayId();
    }
  }, [id, refreshPathways, setIsSmsPathway]);

  const handleCloneCurrentPathway = async () => {
    setCreatingClone(true);
    try {
      // Get the current pathway name - ensure we have access to the correct data
      const pathwayName = graphData?.pathway?.name;
      
      // Check if we have the required fields
      if (!id || !pathwayName) {
        console.error('Missing required fields for duplicating pathway', { id, pathwayName });
        toast.error('Missing required information to create SMS pathway');
        return;
      }
      
      const newName = `[SMS] ${pathwayName}`;
      const description = `Linked SMS Pathway from '${pathwayName}'`;
      
      console.log('Creating SMS pathway with:', {
        sourceId: id,
        name: newName,
        description: description
      });
      
      const {data} = await $fetch(`/v1/convo_pathway/duplicate`, {
        method: 'POST',
        body: JSON.stringify({
          pathwayID: id,
          name: newName,
          description: description,
        }),
      });

      
      
      if (data && data.id) {
        // Update SMS pathway
        const newPathwayId = data.id;
        
        // Link the new SMS pathway to the current pathway
        const linkResponse = await $fetch(`/v1/pathway/${id}/sms`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            sms_pathway_id: newPathwayId,
          }),
        });
        
        if (!linkResponse.errors || linkResponse.errors.length === 0) {
          setSmsPathwayId(newPathwayId);
          
          // Fetch the new pathway details
          try {
            const pathwayDetails = await $fetch(`/v1/pathway/${newPathwayId}`);
            if (pathwayDetails && pathwayDetails.data) {
              setSmsPathwayDetails(pathwayDetails.data);
            }
          } catch (error) {
            console.error('Error fetching SMS pathway details:', error);
          }
          
          toast.success('SMS pathway created and linked successfully');
          setRefreshPathways(prev => !prev);
        } else {
          toast.error('Failed to link the new SMS pathway');
        }
      } else {
        toast.error('Failed to create SMS pathway - no pathway ID returned');
      }
    } catch (error) {
      console.error('Error creating SMS pathway:', error);
      toast.error('Error creating SMS pathway');
    } finally {
      setCreatingClone(false);
    }
  };

  const navigateToPathway = (pathwayId) => {
    // Using window.location.href will trigger a full page reload
    window.location.href = `/dashboard/convo-pathways?id=${pathwayId}&view=splash`;
  };


  // Add a useEffect to update when relevant data changes
  useEffect(() => {
    // Force re-render when these values change
    if (graphData?.inbound_numbers) {
      console.log("Inbound numbers updated:", graphData.inbound_numbers);
    }
  }, [graphData?.inbound_numbers, isExistingSmsPathway, id, smsPathwayId]);


  // Function to open the pathway comparison selector
  const handleSyncPathways = () => {
    // Determine the pathway IDs based on the current state
    const mainId = isExistingSmsPathway ? rootPathwayId : id;
    const smsId = isExistingSmsPathway ? id : smsPathwayId;
    
    if (!mainId || !smsId) {
      toast.error('Missing pathway information for comparison');
      return;
    }
    
    // Show the comparison selector modal
    setShowComparisonSelector(true);
  };

  return (
    <div id="sms-pathway" className="mt-10" style={{ margin: '30px 10px' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          gap: 10,
        }}
      >
        <div>
          <p style={{ fontSize: 20, fontWeight: '550', lineHeight: '28px' }}>
            SMS Pathway
          </p>
          <p
            className="mt-1"
            style={{ fontSize: 14, fontWeight: '450', color: 'GrayText' }}
          >
            {isExistingSmsPathway 
              ? "The current SMS pathway is linked to the pathway shown below." 
              : smsPathwayId 
                ? (
                  <>
                    This pathway is linked to the SMS pathway shown below.{' '}
                    <a 
                      href="/dashboard/sms" 
                      style={{ color: '#6e56cf', textDecoration: 'underline' }}
                      target="_blank" 
                      rel="noopener noreferrer"
                    >
                      View SMS Dashboard
                    </a>
                  </>
                )
                : "Create an SMS-optimized clone of this pathway to handle SMS conversations."}
          </p>
        </div>
      </div>

      <PublishedBox
        className="mt-5"
        style={{ padding: '0px 16px 16px 16px' }}
      >
        <VersionBox className="mt-5 w-full">
          {isLoadingSmsPathway ? (
            <div className="flex items-center justify-center p-8">
              <Loading loading={true} />
            </div>
          ) : isExistingSmsPathway ? (
            <VersionItem>
              <div
                style={{
                  border: '1px solid #e0e0e0',
                  padding: 8,
                  borderRadius: '50%',
                  marginRight: 24,
                  backgroundColor: '#f0f0ff',
                }}
              >
                <Phone size={16} color="#6e56cf" />
              </div>
              
              <PathwayInfo>
                <code style={{ fontSize: 14, fontWeight: '550', color: '#6e56cf' }}>
                  {rootPathwayDetails?.name || `Pathway ${rootPathwayId}`}
                </code>
                
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    columnGap: 12,
                    gap: 5,
                  }}
                >
                  {rootPathwayId && (
                    <div style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
                      <p style={{ fontSize: 12, color: 'GrayText' }}>
                        This pathway handles SMS for the linked pathway
                      </p>
                    </div>
                  )}
                  
                  <Badge variant="solid" color="iris" style={{ fontSize: 10 }}>
                    SMS HANDLER
                  </Badge>
                  
                  {rootPathwayDetails?.created_at && (
                    <p style={{ fontSize: 12, color: 'GrayText' }}>
                      Created on {toDtmy(new Date(rootPathwayDetails.created_at))}
                    </p>
                  )}
                </div>
              </PathwayInfo>
              
              <PathwayActions>
                {rootPathwayId && (
                  <>
                    <Tooltip content="Sync changes between pathways and resolve conflicts">
                      <Button
                        size="3"
                        variant="soft"
                        color="amber"
                        style={{ cursor: 'pointer', position: 'relative' }}
                        onClick={handleSyncPathways}
                        className='h-10'
                      >
                        <RefreshCw size={14} />
                        Sync
                      </Button>
                    </Tooltip>
                    <Tooltip content="Go back to the pathway that uses this SMS pathway">
                      <Button
                        size="2"
                        variant="solid"
                        color="iris"
                        style={{ cursor: 'pointer' }}
                        onClick={() => navigateToPathway(rootPathwayId)}
                      >
                        <Phone size={14} />
                        View Linked Pathway
                        <ExternalLink size={14} />
                      </Button>
                    </Tooltip>
                  </>
                )}
              </PathwayActions>
            </VersionItem>
          ) : smsPathwayId && !isEditing ? (
            <VersionItem>
              <div
                style={{
                  border: '1px solid #e0e0e0',
                  padding: 8,
                  borderRadius: '50%',
                  marginRight: 24,
                }}
              >
                <MessageSquare size={16} />
              </div>
              
              <PathwayInfo>
                <code style={{ fontSize: 14, fontWeight: '550' }}>
                  SMS Pathway
                </code>
                
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    columnGap: 12,
                    gap: 5,
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
                    <LinkIcon size={10} color="GrayText" />
                    <p style={{ fontSize: 12, color: 'GrayText' }}>
                      Linked to: {smsPathwayId}
                    </p>
                  </div>
                  
                  {smsPathwayDetails?.created_at && (
                    <p style={{ fontSize: 12, color: 'GrayText' }}>
                      Created on {toDtmy(new Date(smsPathwayDetails.created_at))}
                    </p>
                  )}
                  
                  <Badge variant="solid" color="iris" style={{ fontSize: 10 }}>
                    SMS
                  </Badge>
                </div>
              </PathwayInfo>
              
              <PathwayActions>
                {graphData?.inbound_numbers && graphData.inbound_numbers.length > 0 && (
                  <InboundNumberDropdown
                    inboundNumbers={graphData?.inbound_numbers}
                    currentInboundNumber={
                      // Find which number is currently set for this SMS pathway
                      graphData?.inbound_numbers?.find(
                        num => num.sms_config && num.sms_config.pathway_id === (isExistingSmsPathway ? id : smsPathwayId)
                      )?.phone_number
                    }
                    pathwayId={isExistingSmsPathway ? id : smsPathwayId}
                    versionNumber={
                      isExistingSmsPathway 
                        ? rootPathwayDetails?.staging_version_number 
                        : smsPathwayDetails?.staging_version_number
                    }
                    isSmsPathway={true}
                    onNumberChanged={(phoneNumber) => {
                      // Update the inbound numbers in graphData
                      setGraphData(prev => ({
                        ...prev,
                        inbound_numbers: prev.inbound_numbers.map(num => 
                          num.phone_number === phoneNumber 
                            ? { 
                                ...num, 
                                sms_config: {
                                  ...num.sms_config,
                                  pathway_id: isExistingSmsPathway ? id : smsPathwayId
                                }
                              } 
                            : num
                        )
                      }));
                    }}
                  />
                )}

                <Tooltip content="Sync changes between pathways and resolve conflicts">
                  <Button
                    size="2"
                    variant="soft"
                    color="amber"
                    style={{ cursor: 'pointer', position: 'relative' }}
                    onClick={handleSyncPathways}
                    className='h-8'
                  >
                    <RefreshCw size={14} />
                    Sync
                    {hasMergeConflicts && (
                      <div 
                        style={{ 
                          position: 'absolute', 
                          top: '-4px', 
                          right: '-4px', 
                          width: '10px', 
                          height: '10px', 
                          backgroundColor: '#ef4444', 
                          borderRadius: '50%',
                          border: '1px solid white'
                        }}
                      />
                    )}
                  </Button>
                </Tooltip>

                <DropdownMenu.Root dir="rtl">
                  <DropdownMenu.Trigger>
                    <IconButton
                      style={{ cursor: 'pointer' }}
                      type="button"
                      radius="full"
                      variant="ghost"
                      color="gray"
                      size="2"
                    >
                      <Ellipsis size={16} />
                    </IconButton>
                  </DropdownMenu.Trigger>
                  <DropdownMenu.Content
                    variant="soft"
                    size="2"
                    style={{ marginTop: 2.5, width: 'calc(100% + 20px)' }}
                  >
                    <DropdownMenu.Item
                      onClick={() => {
                        const url = new URL(window.location.href)
                        url.searchParams.delete('view')
                        url.searchParams.set('version', 'staging')
                        url.searchParams.set('id', smsPathwayId)
                        url.searchParams.set('isSmsPathway', 'true')
                        window.location.href = url.toString()
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      <p>Edit Staging SMS Pathway</p>
                      <Pencil
                        size={16}
                        style={{ marginLeft: 'auto' }}
                      />
                    </DropdownMenu.Item>
                    
                    <DropdownMenu.Item
                      onClick={() => setShowSmsPreviewPanel(true)}
                      style={{ cursor: 'pointer' }}
                    >
                      <p>View SMS Pathway Dashboard</p>
                      <ExternalLink
                        size={16}
                        style={{ marginLeft: 'auto' }}
                      />
                    </DropdownMenu.Item>
                  </DropdownMenu.Content>
                </DropdownMenu.Root>
              </PathwayActions>
            </VersionItem>
          ) : (
            <VersionItem style={{ background: 'white', padding: '20px' }}>
              <div
                style={{
                  border: '1px solid #e0e0e0',
                  padding: 8,
                  borderRadius: '50%',
                  marginRight: 24,
                  backgroundColor: '#f8f5ff',
                }}
              >
                <Copy size={16} color="#6e56cf" />
              </div>
              
              <PathwayInfo>
                <code style={{ fontSize: 14, fontWeight: '550', color: '#6e56cf' }}>
                  You don't have an SMS pathway linked yet.
                </code>
                
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    columnGap: 12,
                    gap: 5,
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
                    <p style={{ fontSize: 12, color: 'GrayText' }}>
                      Create a clone of the current production pathway to use for SMS conversations. You can then link a number to the pathway and start sending SMSes.
                    </p>
                  </div>
                </div>
            
              </PathwayInfo>
              
              <PathwayActions>
                <Button
                  size="2"
                  variant="solid"
                  color="iris"
                  style={{ cursor: 'pointer' }}
                  onClick={handleCloneCurrentPathway}
                  disabled={creatingClone || !graphData?.pathway?.name}
                  title={!graphData?.pathway?.name ? "Pathway information not available" : ""}
                >
                  {creatingClone ? <Spinner size="1" /> : (
                    <>
                      <Copy size={14} />
                      Clone Current Pathway
                    </>
                  )}
                </Button>
              </PathwayActions>
            </VersionItem>
          )}
        </VersionBox>
      </PublishedBox>

      {/* Hidden duplicate dialog for fallback purposes */}
      <DuplicatePathwayDialog
        open={duplicateDialogOpen}
        onClose={() => setDuplicateDialogOpen(false)}
        isDuplicate={true}
        isTemplateDuplicate={false}
        currId={id}
        setRefreshPathways={setRefreshPathways}
        isSms={true}
      />

      {/* Pathway Comparison Selector Modal */}
      {showComparisonSelector && (
        <PathwayComparisonSelector
          onClose={() => setShowComparisonSelector(false)}
          initialMainPathwayId={isExistingSmsPathway ? rootPathwayId : id}
          initialSmsPathwayId={isExistingSmsPathway ? id : smsPathwayId}
        />
      )}

      {/* SMS Pathway Preview Slideout Panel */}
      <SlideoutPanel
        isOpen={showSmsPreviewPanel}
        onClose={() => setShowSmsPreviewPanel(false)}
        title="SMS Pathway Dashboard"
        width="65%"
      >
        <SMSPathwayPreview pathwayId={smsPathwayId} />
      </SlideoutPanel>
    </div>
  )
} 