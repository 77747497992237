import ViewPathway from 'components/Dashboard/ConvoPathways/ViewPathway'
import config from 'config'
import { useAuth } from 'hooks/useAuth'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

function EmbeddedPathway() {
  const { user, authToken, loading, handleAuthResponse } = useAuth()
  const [apiKey, setApiKey] = useState(null)
  const [orgId, setOrgId] = useState(null)
  const { id } = useParams()

  const handleMessage = (event) => {
    if (event.data.type === 'setApiKey') {
      setApiKey(event.data.apiKey)
    }

    if (event.data.type === 'setOrgId') {
      setOrgId(event.data.orgId)
    }
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const paramApiKey = urlParams.get('api_key')
    const paramOrgId = urlParams.get('org_id')
    if (paramApiKey && paramOrgId) {
      fetchAuthToken(paramApiKey).then((jwt) => {
        handleAuthResponse({ jwt, org_id: paramOrgId })
      })
    }

    // Optional: Listen for postMessage events if you also want to support that method
    window.addEventListener('message', handleMessage)
    return () => window.removeEventListener('message', handleMessage)
  }, [])

  useEffect(() => {
    if (orgId && apiKey) {
      fetchAuthToken(apiKey).then((jwt) => {
        handleAuthResponse({ jwt, org_id: orgId })
      })
    }
  }, [apiKey, orgId])

  if (loading) {
    return <div>Loading...</div>
  }

  if (!authToken) {
    return (
      <div className="w-screen h-screen overflow-hidden flex flex-col items-center justify-center gap-5">
        <h1 className="text-5xl font-bold">Whoops!</h1>
        <p className="text-gray-500">
          An unexpected error occurred. Please try again later.
        </p>
      </div>
    )
  }

  return (
    <div className="w-screen h-screen overflow-hidden">
      <ViewPathway embedMode embedUser={user} pathwayId={id} />
    </div>
  )
}

async function fetchAuthToken(apiKey) {
  try {
    const response = await fetch(`${config.API_URL}/user/getUserId`, {
      method: 'POST',
      headers: {
        Authorization: apiKey,
      },
    })

    const data = await response.json()
    return data.thisJwt
  }
  catch (error) {
    console.error('Error fetching auth token:', error)
    return null
  }
}

export default EmbeddedPathway
