import { Loading } from 'components/core/Loading'
import { useAuth } from 'hooks/useAuth'
import { useCallLogsPagination } from 'hooks/useCallsPagination'
import { useEffect } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { prefetchCallLogs } from 'utils/funcs/call-logs/callLogsQueries'
import SideMenu from '../components/SideMenu/SideMenu'

export default function Dashboard() {
  const { loading, user } = useAuth()
  const location = useLocation()
  const { take } = useCallLogsPagination()

  useEffect(() => {
    if (user && !location.pathname.includes('/dashboard/call-logs')) {
      prefetchCallLogs({ take, pages: 1 })
    }
  }, [user])

  if (loading) {
    return <Loading loading={loading} />
  }

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />
  }

  return (
    <Wrapper>
      <SideMenu />
      <ContentWrapper>
        <Outlet />
      </ContentWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
`
