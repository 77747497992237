/**
 * WaveLoadingAnimation Component
 *
 * @param {object} props
 * @param {string} [props.colorBorder] - The border color classes
 * @param {string} [props.colorBg] - The main background color classes
 * @param {string} [props.colorLayer1] - Wave layer 1 color classes
 * @param {string} [props.colorLayer2] - Wave layer 2 color classes
 * @param {string} [props.colorLayer3] - Wave layer 3 color classes
 * @param {string} [props.colorLayer4] - Wave layer 4 color classes
 * @param {string} [props.colorLayer5] - Wave layer 5 color classes
 */
function WaveLoadingAnimation({
  colorBorder = 'border-sky-200',
  colorBg = 'bg-sky-500/90',
  colorLayer1 = 'bg-sky-400/70',
  colorLayer2 = 'bg-emerald-600',
  colorLayer3 = 'bg-sky-400/70',
  colorLayer4 = 'bg-sky-500/70',
  colorLayer5 = 'bg-emerald-100/90',
}) {
  return (
    <div
      className={`relative w-52 h-52 mx-auto
        rounded-full overflow-hidden 
        border ${colorBorder}
        ${colorBg} backdrop-blur-xl 
        shadow-water
   
      `}
    >
      {/* Wave layer 1 */}
      <div
        className={`
          absolute w-52 h-52 top-[-50px]
          ${colorLayer1}
          rounded-[45%]
          animate-wave
          transform rotate-0 scale-100
        `}
      />

      {/* Additional glass overlay (optional) */}
      <div
        className={`
          absolute w-[500px] h-52 top-[-0px] left-[0px]
          backdrop-blur-sm
bg-noise
          bg-white/5
          rounded-[0%]
          z-50
        `}
      />

      {/* Wave layer 2 */}
      <div
        className={`
          absolute w-52 h-32 top-[-90px] 
          ${colorLayer2}
          rounded-[35%]
          z-40
    
          animate-wave-fast
          animate-all
          transform rotate-30 scale-150
        `}
      />

      {/* Wave layer 3 */}
      <div
        className={`
          absolute w-52 h-60 top-[-110px] 
          ${colorLayer3}
          rounded-[35%]
          z-40
          
          animate-wave-slower
          animate-all
          animate-pulse
          transform rotate-180 scale-150
        `}
      />

      {/* Wave layer 4 (Additional rotating layer) */}
      <div
        className={`
          absolute w-52 h-52 top-[-120px] 
          ${colorLayer4}
          rounded-[30%]

          animate-wave-ultra-slow
          animate-all
          transform rotate-20 scale-105
          z-40
        `}
      />

      {/* Wave layer 5 */}
      <div
        className={`
          absolute w-52 h-52 top-[-60px]
          bg-noise
          ${colorLayer5}

               
          animate-pulse
          rounded-[35%]
          animate-wave-ultra-slow
          transform rotate-90 scale-110
        `}
      />
    </div>
  )
}

export default WaveLoadingAnimation
