export const curatedTools = [
  {
    id: '18204beb-af6c-4bc5-9765-264726c219f0',
    slug: 'get-hubspot-contact',
    metaTitle: 'Get HubSpot Contact | Retrieve CRM Data with Bland AI',
    metaDescription: 'Fetch HubSpot contact details effortlessly with Bland AI. Access relevant variables and streamline CRM automation.',
  },
  {
    id: '2641c3e3-bad9-4b2a-8348-5f950adc3d77',
    slug: 'cancel-cal-meeting',
    metaTitle: 'Cancel Cal.com Meetings | Seamless Scheduling with Bland AI',
    metaDescription: 'Effortlessly cancel Cal.com meetings with Bland AI. Sync with HubSpot & Google Calendar, send notifications, and keep your workflow uninterrupted.',
  },
  {
    id: '792f825b-de1d-4e1e-b1ed-484dc25bf34f',
    slug: 'scrape-website-respell',
    metaTitle: 'Scrape Websites with Respell | AI Automation by Bland AI',
    metaDescription: 'Extract website data effortlessly with Bland AI using Respell. Automate web scraping for insights, research, and workflows.',
  },
  {
    id: 'f938285d-7b77-4b48-b096-22caebfe282b',
    slug: 'getgohighlevel-contact',
    metaTitle: 'Get GoHighLevel Contacts | AI-Powered CRM Integration by Bland AI',
    metaDescription: 'Retrieve GoHighLevel contacts in real-time with Bland AI. Instantly access customer details during live calls to enhance efficiency and personalization.',
  },
  {
    id: 'e563c2d7-2d14-453d-9ce1-59848e6e8992',
    slug: 'send-sms',
    metaTitle: 'Send SMS Instantly | AI-Powered Messaging by Bland AI',
    metaDescription: 'Send SMS seamlessly with Bland AI. Automate text messaging for notifications, customer outreach, and real-time updates.',
  },
  {
    id: '37c7d24f-2d53-40df-b476-26f2ed816bac',
    slug: 'social-chat-line',
    metaTitle: 'Social Chat Line | AI-Powered Messaging by Bland AI',
    metaDescription: 'Engage in real-time conversations with Bland AI\'s Social Chat Line. Automate messaging and connect seamlessly across platforms.',
  },
  {
    id: '10380f09-26bf-4af5-b62d-b65fce39cbf7',
    slug: 'create-hubspot-deal',
    metaTitle: 'Create HubSpot Deals | Automate Sales with Bland AI',
    metaDescription: 'Streamline sales with Bland AI by automating HubSpot deal creation. Sync data, track pipeline progress, and enhance CRM workflows effortlessly.',
  },
  {
    id: '617390c1-5a19-4f1b-994a-aa7facbfec04',
    slug: 'log-hubspot-deal-activity',
    metaTitle: 'Log HubSpot Email Activity | Automate CRM Tracking with Bland AI',
    metaDescription: 'Automatically log email activity in HubSpot with Bland AI. Track conversations, sync CRM data, and enhance customer engagement effortlessly.',
  },
  {
    id: '2cec173e-34a0-4b10-b1c2-196350e13af8',
    slug: 'update-opportunity-stage-salesforce',
    metaTitle: 'Update Salesforce Opportunity Stage | Automate Sales with Bland AI',
    metaDescription: 'Easily update Salesforce opportunity stages with Bland AI. Automate CRM updates, track sales progress, and improve workflow efficiency.',
  },
  {
    id: '2761c073-fe1e-45ef-b379-1ead423e7c05',
    slug: 'create-support-salesforce-case',
    metaTitle: 'Create Salesforce Support Cases | Automate Helpdesk with Bland AI',
    metaDescription: 'Effortlessly create Salesforce support cases with Bland AI. Automate issue tracking, sync CRM data, and enhance customer support efficiency.',
  },
  {
    id: '3c4dd868-145d-4e2f-b91c-0a9d29681d01',
    slug: 'update-hubspot-deal',
    metaTitle: 'Update HubSpot Deals | Automate CRM Management with Bland AI',
    metaDescription: 'Effortlessly update HubSpot deals with Bland AI. Sync CRM data, adjust deal stages, and automate workflows for seamless sales tracking.',
  },
  {
    id: 'cff7b9f0-fbf3-4111-9b59-064d02ae01cd',
    slug: 'create-hubspot-support-ticket',
    metaTitle: 'Create HubSpot Support Tickets | Automate Helpdesk with Bland AI',
    metaDescription: 'Streamline customer support with Bland AI by creating HubSpot tickets effortlessly. Track inquiries, automate workflows, and improve response times.',
  },
  {
    id: '2b4f9eec-e6c5-4e0f-9686-4383d6b8c6b6',
    slug: 'create-salesforce-opportunity',
    metaTitle: 'Create Salesforce Opportunities | Streamline CRM with Bland AI',
    metaDescription: 'Generate Salesforce opportunities seamlessly within Bland AI. Manage sales leads, sync CRM data, and enhance workflow efficiency with integrated tools.',
  },
  {
    id: 'cce2e14a-1151-4d79-8645-e1069f86dac7',
    slug: 'creating-hubspot-contact',
    metaTitle: 'Create HubSpot Contacts | Automate CRM with Bland AI',
    metaDescription: 'Seamlessly add new contacts to HubSpot CRM with Bland AI. Automate contact creation, integrate with key tools, and enhance data integrity for efficient CRM management.',
  },
  {
    id: 'cafd9395-5567-4e44-972b-ddf822dabbb1',
    slug: 'search-lead-by-email-salesforce',
    metaTitle: 'Search Salesforce Leads by Email | CRM Automation with Bland AI',
    metaDescription: 'Quickly find Salesforce leads using email with Bland AI. Automate lead searches, access real-time data, and streamline sales workflows.',
  },
  {
    id: '6d98e306-e7bc-42cb-b6ce-bd2ed6f7d898',
    slug: 'create-hubspot-task',
    metaTitle: 'Create HubSpot Tasks | Streamline Task Management with Bland AI',
    metaDescription: 'Enhance productivity with Bland AI\'s HubSpot integration. Create, assign, and manage tasks seamlessly. Customize setups, access contextual data, and monitor all tasks in a unified dashboard.',
  },
  {
    id: '0fc15f09-bbba-4e17-affb-521596666eae',
    slug: 'create-hubspot-contact',
    metaTitle: 'Create Salesforce Contacts | Optimize CRM with Bland AI',
    metaDescription: 'Streamline Salesforce contact management with Bland AI. Automate data entry, integrate with key tools, and enhance customer engagement with tailored communication and insights.',
  },
  {
    id: '8d935654-33f1-49b0-b19e-5d2800dcbf55',
    slug: 'update-salesforce-case-status',
    metaTitle: 'Update Salesforce Case Status | Automate Support with Bland AI',
    metaDescription: 'Easily update Salesforce case statuses with Bland AI. Automate support workflows, sync CRM data, and enhance team collaboration for faster resolutions.',
  },
  {
    id: 'd1112f6d-c08d-46aa-b5cd-d1981a55c09e',
    slug: 'assign-task-to-user-salesforce',
    metaTitle: 'Assign Salesforce Tasks | Automate Workflow with Bland AI',
    metaDescription: 'Easily assign tasks in Salesforce with Bland AI. Automate delegation, track progress, and enhance team collaboration for seamless task management.',
  },
  {
    id: '7570f67a-66fa-4468-99f6-9212f133f05d',
    slug: 'search-available-time-slots-google-calendar',
    metaTitle: 'Find Available Google Calendar Slots | Automate Scheduling with Bland AI',
    metaDescription: 'Easily find open time slots in Google Calendar with Bland AI. Automate scheduling, sync real-time availability, and streamline meetings effortlessly.',
  },
  {
    id: 'eb4b2320-f86c-4d96-8a0f-f12038ca53b5',
    slug: 'update-cal-meeting',
    metaTitle: 'Update Cal.com Meetings | Automate Scheduling with Bland AI',
    metaDescription: 'Easily update Cal.com meetings with Bland AI. Modify times, sync calendars, and send real-time notifications for seamless scheduling.',
  },
  {
    id: 'afa7166c-bd6c-4d0b-85c4-f135aa6176ee',
    slug: 'invite-person-to-google-calendar-event',
    metaTitle: 'Invite to Google Calendar Event | Automate Scheduling with Bland AI',
    metaDescription: 'Easily invite attendees to Google Calendar events with Bland AI. Check availability, send automated invites, and sync schedules seamlessly.',
  },
  {
    id: '9ebe4c06-22dd-410e-aa5c-e422ffafb9ff',
    slug: 'get-availability-cal',
    metaTitle: 'Get Availability Tool for Cal.com: Using Bland AI Voice Calling',
    metaDescription: 'The Get Availability tool for Cal.com in Bland AI retrieves real-time calendar availability, streamlining scheduling and enhancing customer booking experiences.',
  },
  {
    id: 'e528bf1c-8342-4222-8f96-554b32ec3a35',
    slug: 'cancel-event-google-calendar',
    metaTitle: 'Cancel Event Tool for Google Calendar: Using Bland AI Voice Calling',
    metaDescription: 'The Cancel Event tool for Google Calendar in Bland AI streamlines scheduling, ensuring real-time updates and improved customer coordination for seamless time management.',
  },
  {
    id: '3f729d7d-0968-47e6-8f5e-fbfa9fcd8161',
    slug: 'update-jira-issue-status',
    metaTitle: 'Update Jira Issue Status Tool: Using Bland AI Voice Calling',
    metaDescription: 'The Update Jira Issue Status tool in Bland AI streamlines project management, enabling real-time updates, better collaboration, and improved workflow efficiency.',
  },
  {
    id: '20b24f15-f8dc-41bc-8089-7c0db9cc6e4f',
    slug: 'log-work-on-jira-issue',
    metaTitle: 'Log Work on Jira Issue Tool: Using Bland AI Voice Calling',
    metaDescription: 'The Log Work on Jira Issue tool in Bland AI simplifies time tracking, enhancing productivity, project oversight, and collaboration with seamless Jira integration.',
  },
  {
    id: '4d25188f-472e-48e8-bc16-0b58f5a22406',
    slug: 'assign-jira-issue-to-user',
    metaTitle: 'Assign Jira Issue Tool for Teams: Using Bland AI Voice Calling',
    metaDescription: 'The Assign Jira Issue tool in Bland AI streamlines project workflows, enhancing collaboration and accountability with intelligent task delegation and real-time updates.',
  },
  {
    id: '3c63a2ae-5a9e-4f10-a991-e85585c3f997',
    slug: 'search-jira-issues-by-status-or-priority',
    metaTitle: 'Search Jira Issues by Status Tool: Using Bland AI Voice Calling',
    metaDescription: 'The Search Jira Issues by Status tool in Bland AI streamlines project tracking, enhancing team productivity with real-time issue filtering and seamless integration.',
  },
  {
    id: '9ea7db27-1e3a-4314-a717-651ad0c081b9',
    slug: 'create-zendesk-ticket',
    metaTitle: 'Create Zendesk Ticket Tool for Customer Support: Using Bland AI Voice Calling',
    metaDescription: 'The Create Zendesk Ticket tool in Bland AI streamlines customer support, automating ticket creation and enhancing response efficiency for improved customer satisfaction.',
  },
  {
    id: '05121a58-fb47-464d-a3fa-469195a85bd9',
    slug: 'comment-on-jira-issue',
    metaTitle: 'Comment on Jira Issue Tool for Collaboration: Using Bland AI Voice Calling',
    metaDescription: 'The Comment on Jira Issue tool in Bland AI enhances team collaboration, streamlining project communication with real-time updates and seamless Jira integration.',
  },
  {
    id: 'e9ca9983-601f-40a5-9c8c-0abe4e08381e',
    slug: 'sending-sms',
    metaTitle: 'Send SMS Tool for Customer Engagement: Using Bland AI Voice Calling',
    metaDescription: 'The Send SMS tool in Bland AI streamlines communication, enabling automated, real-time messaging to enhance customer interactions.',
  },
  {
    id: '06ac5db7-3bcd-49e2-8349-fa02b1628462',
    slug: 'log-customer-interaction-zendesk',
    metaTitle: 'Log Customer Interaction Tool for Zendesk: Using Bland AI Voice Calling',
    metaDescription: 'The Log Customer Interaction tool for Zendesk in Bland AI streamlines support workflows, ensuring accurate records and improved customer service efficiency.',
  },
  {
    id: 'b9c7c84a-79b6-4807-b20b-29bc918df4b5',
    slug: 'assign-zendesk-ticket-to-agent',
    metaTitle: 'Assign Zendesk Ticket Tool for Agents: Using Bland AI Voice Calling',
    metaDescription: 'The Assign Zendesk Ticket tool in Bland AI automates ticket routing, ensuring efficient support by assigning cases to the right agents for faster customer resolution.',
  },
  {
    id: '9b4fce79-11da-4c94-a09a-8f9c4086b2af',
    slug: 'log-hubspot-call',
    metaTitle: 'Log a HubSpot Call Tool for Customer Tracking: Using Bland AI Voice Calling',
    metaDescription: 'The Log a HubSpot Call tool in Bland AI automates call logging, enhancing customer tracking, collaboration, and workflow efficiency within HubSpot\'s CRM.',
  },
  {
    id: 'dc9cc0c2-6c3a-494a-8053-4a5c5cf02b8a',
    slug: 'update-hubspot-contact',
    metaTitle: 'Update HubSpot Contact Tool for CRM Management: Using Bland AI Voice Calling',
    metaDescription: 'The Update HubSpot Contact tool in Bland AI automates contact management, ensuring real-time updates and seamless CRM integration for better customer relationships.',
  },
  {
    id: '8e19d8e3-0c0b-407c-9795-df70f8b6205e',
    slug: 'create-zendesk-support-article',
    metaTitle: 'Create Zendesk Support Article Tool: Using Bland AI Voice Calling',
    metaDescription: 'The Create Zendesk Support Article tool in Bland AI streamlines knowledge management, enabling seamless article creation for improved customer self-service.',
  },
  {
    id: '5b65ce86-5c33-4ca3-9e63-01741620dfee',
    slug: 'create-google-calendar-event',
    metaTitle: 'Create Google Calendar Event Tool: Using Bland AI Voice Calling',
    metaDescription: 'The Create Google Calendar Event tool in Bland AI streamlines scheduling, enabling seamless event creation, reminders, and CRM integration for better time management.',
  },
  {
    id: '2fbf2985-431f-4a90-a32a-24d2d96ef50c',
    slug: 'search-company-profile-zoomfino',
    metaTitle: 'Search Company Profile Tool for ZoomInfo: Using Bland AI Voice Calling',
    metaDescription: 'The Search Company Profile tool for ZoomInfo in Bland AI provides real-time company insights, streamlining lead generation and customer engagement workflows.',
  },
  {
    id: 'a8657fc7-5621-40a5-992f-2fc55b010bb7',
    slug: 'update-gohighlevel-contact',
    metaTitle: 'Update GoHighLevel Contact Tool: Using Bland AI Voice Calling',
    metaDescription: 'The Update GoHighLevel Contact tool in Bland AI streamlines CRM management, ensuring real-time updates and seamless integration for improved customer engagement.',
  },
  {
    id: 'a7ee90ab-b7aa-4780-bf80-1b519d823aad',
    slug: 'update-task-gethealthie',
    metaTitle: 'Update Task Tool for GetHealthie: Using Bland AI Voice Calling',
    metaDescription: 'The Update Task tool for GetHealthie in Bland AI streamlines task management, ensuring real-time updates and better coordination for improved patient care.',
  },
  {
    id: 'dc5b17a2-52ab-4d75-ba31-72ed5d967e9d',
    slug: 'add-contact-to-campaign-gohighlevel',
    metaTitle: 'Add Contact to Campaign Tool for GoHighLevel: Using Bland AI Voice Calling',
    metaDescription: 'The Add Contact to Campaign tool for GoHighLevel in Bland AI automates lead management, ensuring seamless campaign integration and improved customer engagement.',
  },
  {
    id: '78c7d16e-db3a-4ef6-8d69-c60b50e686b1',
    slug: 'create-appointment-gohighlevel',
    metaTitle: 'Create Appointment Tool for GoHighLevel: Using Bland AI Voice Calling',
    metaDescription: 'The Create Appointment tool for GoHighLevel in Bland AI streamlines scheduling, automates reminders, and enhances customer engagement with seamless CRM integration.',
  },
  {
    id: 'd44fcf41-3ab2-4fc4-a19b-624d88d0f995',
    slug: 'update-task-status-gohighlevel',
    metaTitle: 'Update Task Status Tool for GoHighLevel: Using Bland AI Voice Calling',
    metaDescription: 'The Update Task Status tool for GoHighLevel in Bland AI streamlines task management, providing real-time updates and improving team collaboration and productivity.',
  },
  {
    id: '58d449e7-1d04-4c7e-b54f-f071310f2a63',
    slug: 'create-task-gohighlevel',
    metaTitle: 'Create Task Tool for GoHighLevel: Using Bland AI Voice Calling',
    metaDescription: 'The Create Task tool for GoHighLevel in Bland AI automates task management, enhancing workflow efficiency, team collaboration, and project tracking in real time.',
  },
  {
    id: 'f5fd7d79-43b8-4479-98a3-2a8a3b8fd5b8',
    slug: 'create-patient-record-gethealthie',
    metaTitle: 'Create Patient Record Tool for GetHealthie: Using Bland AI Voice Calling',
    metaDescription: 'The Create Patient Record tool for GetHealthie in Bland AI streamlines patient data management, enhancing workflow efficiency and improving healthcare coordination.',
  },
  {
    id: '7eb4863b-32ec-4716-9b91-9087ff9cb953',
    slug: 'cancel-appointment-gohighlevel',
    metaTitle: 'Cancel Appointment Tool for GoHighLevel: Using Bland AI Voice Calling',
    metaDescription: 'The Cancel Appointment tool for GoHighLevel in Bland AI automates cancellations, ensuring real-time updates and seamless scheduling management for improved efficiency.',
  },
  {
    id: 'e3019702-faf8-4d9b-9b77-4ccc3eb8ef49',
    slug: 'create-task-gethealthie',
    metaTitle: 'Create Task Tool for GetHealthie: Using Bland AI Voice Calling',
    metaDescription: 'The Create Task tool for GetHealthie in Bland AI streamlines task management, enabling seamless scheduling, tracking, and client engagement for healthcare professionals.',
  },
  {
    id: '5a7b2975-95b0-407f-a62b-b7835b977c0c',
    slug: 'search-zendesk-tickets-by-users',
    metaTitle: 'Search Zendesk Tickets by User Tool: Using Bland AI Voice Calling',
    metaDescription: 'The Search Zendesk Tickets by User tool in Bland AI streamlines support workflows, enabling quick access to customer tickets for faster issue resolution.',
  },
  {
    id: '9b8634e3-ab7c-47a0-878b-249800c21e2f',
    slug: 'mark-gmail-email-read-unread',
    metaTitle: 'Mark Gmail Email as Read/Unread Tool: Using Bland AI Voice Calling',
    metaDescription: 'The Mark Gmail Email as Read/Unread tool in Bland AI streamlines email management, improving workflow organization and ensuring real-time status updates.',
  },
  {
    id: '3fe3cd48-b06a-4cec-b91f-da22f7ec9b07',
    slug: 'create-email-draft-gmail',
    metaTitle: 'Create Draft Email Tool for Gmail: Using Bland AI Voice Calling',
    metaDescription: 'The Create Draft Email tool for Gmail in Bland AI streamlines email composition, enabling seamless drafting, automation, and integration for efficient communication.',
  },
  {
    id: '056bd038-846a-4ffa-b184-7f536c4197eb',
    slug: 'delete-email-gmail',
    metaTitle: 'Delete Gmail Email Tool: Using Bland AI Voice Calling',
    metaDescription: 'The Delete Gmail Email tool in Bland AI automates inbox cleanup with secure REST API integration, streamlining email management and boosting productivity.',
  },
  {
    id: 'f10f7b7f-1e3b-4aeb-8d62-cd5ede03b6ca',
    slug: 'search-hubspot-contact-by-email',
    metaTitle: 'Search HubSpot Contact by Email Tool: Using Bland AI Voice Calling',
    metaDescription: 'The Search HubSpot Contact by Email tool in Bland AI enables quick contact retrieval, improving workflow efficiency and customer interactions within HubSpot.',
  },
  {
    id: '8426a4ee-a1c1-43a2-82d2-382f1f239aac',
    slug: 'get-prescription-gethealthie',
    metaTitle: 'Get Prescription Tool for GetHealthie: Using Bland AI Voice Calling',
    metaDescription: 'The Get Prescription tool for GetHealthie in Bland AI streamlines patient care by providing real-time access to prescription records, enhancing workflow efficiency.',
  },
  {
    id: 'c81c1b27-d53a-40fd-9f70-209511520de0',
    slug: 'create-appointment-getheathie',
    metaTitle: 'Create Appointment Tool for GetHealthie: Using Bland AI Voice Calling',
    metaDescription: 'The Create Appointment tool for GetHealthie in Bland AI simplifies scheduling, automates patient bookings, and enhances workflow efficiency for healthcare providers.',
  },
  {
    id: 'c8a1e51c-44da-470d-8fe3-cfc7c9b6e399',
    slug: 'log-salesforce-call',
    metaTitle: 'Log a Call Tool for Salesforce: Using Bland AI Voice Calling',
    metaDescription: 'The Log a Call tool for Salesforce in Bland AI automates call tracking, ensuring accurate records, follow-ups, and seamless CRM integration for improved customer engagement.',
  },
  {
    id: 'b8e6d78c-8bb5-404e-adda-918128643d0f',
    slug: 'update-appointment-details-getheathie',
    metaTitle: 'Update Appointment Details Tool for GetHealthie: Using Bland AI Voice Calling',
    metaDescription: 'The Update Appointment Details tool for GetHealthie in Bland AI streamlines scheduling, ensuring real-time updates and seamless client management for healthcare providers.',
  },
  {
    id: 'c152e961-e24f-48fb-95a5-c48d65e0082c',
    slug: 'perplexity-request',
    metaTitle: 'Perplexity Request Tool for Data Retrieval: Using Bland AI Voice Calling',
    metaDescription: 'The Perplexity Request tool in Bland AI automates data gathering, integrating multiple sources like Salesforce and HubSpot for seamless, real-time information retrieval.',
  },
  {
    id: 'd3c4ce3c-7775-4a05-b076-560d02352fb5',
    slug: 'create-contact-gohighlevel',
    metaTitle: 'Create Contact Tool for GoHighLevel: Using Bland AI Voice Calling',
    metaDescription: 'The Create Contact tool for GoHighLevel in Bland AI automates contact management, ensuring seamless CRM integration and real-time data synchronization.',
  },
  {
    id: '6c6102a8-02ac-4e96-a38b-592f1de9a9a7',
    slug: 'retreive-emails-from-specific-contact-gmail',
    metaTitle: 'Retrieve Emails from Contact Tool for Gmail: Using Bland AI Voice Calling',
    metaDescription: 'The Retrieve Emails from Contact tool for Gmail in Bland AI streamlines email management, enabling quick access to conversations for better workflow efficiency.',
  },
  {
    id: 'e6b21abb-53a3-4406-91e5-f9283f20e678',
    slug: 'retrieve-contact-details-zoominfo',
    metaTitle: 'Retrieve Contact Details Tool for ZoomInfo: Using Bland AI Voice Calling',
    metaDescription: 'The Retrieve Contact Details tool for ZoomInfo in Bland AI provides instant access to accurate contact data, streamlining CRM updates and sales outreach.',
  },
  {
    id: '1e953b0c-5dec-42f2-89f6-3ae4f99dc968',
    slug: 'retreive-appointment-details-gethealthie',
    metaTitle: 'Retrieve Appointment Details Tool for GetHealthie: Using Bland AI Voice Calling',
    metaDescription: 'The Retrieve Appointment Details tool for GetHealthie in Bland AI provides real-time access to patient appointments, enhancing workflow efficiency and patient care.',
  },
  {
    id: '4a229270-b948-4009-a9cd-5af510d1198b',
    slug: 'update-contact-salesforce',
    metaTitle: 'Update Salesforce Contact Tool: Using Bland AI Voice Calling',
    metaDescription: 'The Update Salesforce Contact tool in Bland AI ensures seamless CRM data synchronization, streamlining contact management and improving workflow efficiency.',
  },
  {
    id: 'b4d0175f-ad00-4775-a612-eb11d2bb5a83',
    slug: 'update-ticket-task-zendesk',
    metaTitle: 'Update Zendesk Ticket Status Tool: Using Bland AI Voice Calling',
    metaDescription: 'The Update Zendesk Ticket Status tool in Bland AI streamlines customer support, enabling real-time status updates for faster resolution and improved workflow efficiency.',
  },
  {
    id: '0bfad25c-7cd0-435e-a03f-f4e585c5486d',
    slug: 'schedule-meeting-cal',
    metaTitle: 'Schedule Cal Meeting Tool: Using Bland AI Voice Calling',
    metaDescription: 'The Schedule Cal Meeting tool in Bland AI automates meeting scheduling, syncing with Cal.com and Google Calendar for seamless, conflict-free appointment management.',
  },
  {
    id: '11ffc86d-f9fd-48f1-9479-f0ba1dea62f6',
    slug: 'send-email-outlook',
    metaTitle: 'Send Email Tool for Outlook: Using Bland AI Voice Calling',
    metaDescription: 'The Send Email tool for Outlook in Bland AI streamlines communication, enabling seamless email sending, tracking, and scheduling to enhance customer interactions.',
  },
  {
    id: '838a828e-8b37-4cd6-8c6c-bfb89d09f11e',
    slug: 'retreive-email-from-contact-outlook',
    metaTitle: 'Retrieve Emails from Contact Tool for Outlook: Using Bland AI Voice Calling',
    metaDescription: 'The Retrieve Emails from Contact tool for Outlook in Bland AI streamlines email searches, improving customer interactions and enhancing workflow efficiency.',
  },
  {
    id: '6342c8a8-9bc3-472d-843a-0be039c6a3db',
    slug: 'search-emails-by-subject-outlook',
    metaTitle: 'Search Emails by Subject Tool for Outlook: Using Bland AI Voice Calling',
    metaDescription: 'The Search Emails by Subject tool for Outlook in Bland AI enhances email management, enabling quick retrieval of important messages to improve customer response time.',
  },
  {
    id: 'd65f6a15-c49e-4184-9d40-fff7af7ab91b',
    slug: 'mark-email-read-unread-outlook',
    metaTitle: 'Mark Email as Read/Unread Tool for Outlook: Using Bland AI Voice Calling',
    metaDescription: 'The Mark Email as Read/Unread tool for Outlook in Bland AI streamlines inbox management, improving customer response times and enhancing workflow efficiency.',
  },
  {
    id: 'c90a9616-83d2-4ac7-a14d-59d56e1aa195',
    slug: 'create-draft-email-outlook',
    metaTitle: 'Create Draft Email Tool for Outlook: Using Bland AI Voice Calling',
    metaDescription: 'The Create Draft Email tool for Outlook in Bland AI streamlines email preparation, enhancing customer communication with automated drafting and seamless integration.',
  },
  {
    id: 'b9c7c84a-79b6-4807-b20b-29bc918df4b5',
    slug: 'set-ooo-response-outlook',
    metaTitle: 'Set Out of Office Response Tool for Outlook: Using Bland AI Voice Calling',
    metaDescription: 'The Set Out of Office Response tool for Outlook in Bland AI automates email replies, ensuring seamless communication and improving customer experience during absences.',
  },
  {
    id: '31833261-4d08-4bc9-896e-023dd5f18a8d',
    slug: 'schedule-outlook-calendar-event',
    metaTitle: 'Schedule Event Tool for Outlook: Using Bland AI Voice Calling',
    metaDescription: 'The Schedule Event tool for Outlook in Bland AI streamlines calendar management, enhancing customer interactions with automated scheduling and seamless integration.',
  },
  {
    id: 'b9c51e7c-3a36-4063-a359-2c1591b6a4f7',
    slug: 'create-stripe-customer',
    metaTitle: 'Create Customer Tool for Stripe: Using Bland AI Voice Calling',
    metaDescription: 'The Create Customer tool for Stripe in Bland AI streamlines customer onboarding, ensuring accurate data entry and seamless integration for better customer management.',
  },
  {
    id: '1fc55441-00a1-46bc-8a46-0ac9611caf15',
    slug: 'update-stripe-customer-details',
    metaTitle: 'Update Customer Details Tool for Stripe: Using Bland AI Voice Calling',
    metaDescription: 'The Update Customer Details tool for Stripe in Bland AI ensures accurate, real-time data synchronization, enhancing customer management and support efficiency.',
  },
  {
    id: 'efdf3a57-20ea-4f46-90d3-c4c1af191b18',
    slug: 'create-payment-intent-stripe',
    metaTitle: 'Create Payment Intent Tool for Stripe: Using Bland AI Voice Calling',
    metaDescription: 'The Create Payment Intent tool for Stripe in Bland AI automates secure payment processing, enhancing customer transactions with flexibility, accuracy, and efficiency.',
  },
  {
    id: 'c8e7e600-a019-444a-ad9d-2835d6d60369',
    slug: 'send-email-gmail',
    metaTitle: 'Send Email Tool for Gmail: Using Bland AI Voice Calling',
    metaDescription: 'The Send Email tool for Gmail in Bland AI streamlines communication, enabling secure, automated, and scheduled emails to enhance customer engagement and workflow efficiency.',
  },
  {
    id: 'a3a945b9-0ae6-4d4d-8edc-3038b637c44c',
    slug: 'create-invoice-stripe',
    metaTitle: 'Create Invoice Tool for Customers on Stripe: Using Bland AI Voice Calling',
    metaDescription: 'The Create Invoice tool for Stripe in Bland AI streamlines billing with automated, accurate invoicing, improving customer service and financial workflow efficiency.',
  },
  {
    id: 'ad8fc233-5b17-41ac-89b3-2ec944c428cb',
    slug: 'send-invoice-to-customer-stripe',
    metaTitle: 'Send Invoice Tool for Customers on Stripe: Using Bland AI Voice Calling',
    metaDescription: 'The Send Invoice tool for Stripe in Bland AI automates billing, ensuring accurate, seamless invoicing while enhancing customer service with real-time tracking.',
  },
  {
    id: '82653d76-b764-492f-8204-ade420e044e7',
    slug: 'retreive-available-time-slots-for-booking-cal',
    metaTitle: 'Retrieve Available Time Slots Tool for Booking: Using Bland AI Voice Calling',
    metaDescription: 'The Retrieve Available Time Slots tool in Bland AI syncs with Cal.com to streamline scheduling, reducing conflicts and improving customer booking experiences.',
  },
  {
    id: 'a8be6c0a-569f-45cd-a86a-5e2def25d0f6',
    slug: 'create-jira-issue',
    metaTitle: 'Create Jira Issue Tool for Task Management: Using Bland AI Voice Calling',
    metaDescription: 'The Create Jira Issue tool in Bland AI streamlines task, bug, and feature tracking, improving team collaboration and customer service with seamless Jira integration.',
  },
  {
    id: 'be7724b1-0ab9-40e5-9164-ee024d2bce1d',
    slug: 'create-payment-link-stripe',
    metaTitle: 'Create Payment Link Tool for Stripe: Using Bland AI Voice Calling',
    metaDescription: 'The Create Payment Link tool for Stripe in Bland AI simplifies payment collection, enhancing customer service with secure, customizable payment links and automated workflows.',
  },
  {
    id: 'dcc4cf2e-8fb6-43e5-b5ea-6020952d6a7f',
    slug: 'retreive-refund-status-stripe',
    metaTitle: 'Retrieve Refund Status Tool for Stripe: Using Bland AI Voice Calling',
    metaDescription: 'The Retrieve Refund Status tool for Stripe in Bland AI provides real-time refund tracking, improving customer support and ensuring seamless financial transaction management.',
  },
  {
    id: '711b79c4-3ad0-46c1-ac67-5a29da974640',
    slug: 'retreive-customer-payment-history-stripe',
    metaTitle: 'Retrieve Customer Payment History Tool for Stripe: Using Bland AI Voice Calling',
    metaDescription: 'The Retrieve Customer Payment History tool for Stripe in Bland AI streamlines access to past transactions, improving customer support and financial record-keeping efficiency.',
  },
  {
    id: 'cc46bf45-1366-4586-b7bf-41ea384df444',
    slug: 'retreive-checkout-session-status-stripe',
    metaTitle: 'Retrieve Checkout Status Tool for Stripe: Using Bland AI Voice Calling',
    metaDescription: 'The Retrieve Checkout Status tool for Stripe in Bland AI provides real-time payment insights, helping teams enhance customer support and streamline financial operations.',
  },
  {
    id: 'a0a1c7e8-0928-42d5-ac63-a7cd18c4e9b6',
    slug: 'create-checkout-session-stripe',
    metaTitle: 'Create Checkout Session Tool for Stripe: Using Bland AI Voice Calling',
    metaDescription: 'The Create Checkout Session tool for Stripe in Bland AI streamlines payments with secure, customizable checkouts, improving customer satisfaction and reducing failed transactions.',
  },
  {
    id: '82c9d1cc-1a8d-445b-b930-1d5097a42119',
    slug: 'update-stripe-product',
    metaTitle: 'Update Product Tool for Stripe: Using Bland AI Voice Calling',
    metaDescription: 'The Update Product tool for Stripe in Bland AI streamlines product updates, ensuring accurate pricing and metadata changes while improving customer service with seamless automation.',
  },
  {
    id: '0da82a3c-744b-4682-992c-fd95cf580114',
    slug: 'cancel-stripe-subscription',
    metaTitle: 'Cancel Subscription Tool for Stripe: Using Bland AI Voice Calling',
    metaDescription: 'The Cancel Subscription tool for Stripe in Bland AI automates cancellations, streamlining customer support with real-time updates and seamless Stripe integration.',
  },
  {
    id: '32db6733-1fb2-429e-a6e8-013397ab95bb',
    slug: 'create-subscription-stripe',
    metaTitle: 'Create Stripe Products | Automate AI Voice Service Billing with Bland AI',
    metaDescription: 'Easily add new products to Stripe while integrating AI voice agents with existing tools. Automate pricing, subscriptions, and billing workflows with Bland AI.',
  },
  {
    id: 'd526bd0d-1ff6-42c4-a9a3-7a9fc55caf59',
    slug: 'cancel-invoice-stripe',
    metaTitle: 'Create Stripe Subscriptions | Power AI Voice Services with Bland AI',
    metaDescription: 'Automate subscription billing with Bland AI. Use AI voice services to set up recurring payments, manage plans, and streamline customer billing seamlessly.',
  },
  {
    id: '8fedbc5c-5a94-40e5-b02c-5d08a7842887',
    slug: 'sending-invoice-to-customer-stripe',
    metaTitle: 'Cancel Stripe Invoices | Automate AI Voice Billing with Bland AI',
    metaDescription: 'Seamlessly cancel Stripe invoices with Bland AI. Automate billing adjustments, manage payments, and keep financial records accurate.',
  },
  {
    id: 'e2483b86-52b7-48ec-b246-d3541be1201a',
    slug: 'update-subscription-stripe',
    metaTitle: 'Send Stripe Invoices | Automate AI Voice Billing with Bland AI',
    metaDescription: 'Generate and send Stripe invoices effortlessly with Bland AI. Automate billing using AI voice services, track payments, and improve cash flow with ease.',
  },
  {
    id: 'b91a48a4-01e7-4e1b-a0a5-3bbfa0fa8a37',
    slug: 'update-payment-intent-stripe',
    metaTitle: 'Bland AI: Update Stripe Subscription for Customer Service',
    metaDescription: 'Streamline customer interactions with Bland AI\'s Update Subscription via Stripe. Instantly adjust plans, cycles, and quantities for an enhanced customer experience',
  },
  {
    id: '8393045e-eac1-493e-b400-ec969b049d30',
    slug: 'retrieve-product-details-stripe',
    metaTitle: 'Bland AI: Update Stripe Payment Intent for Customers',
    metaDescription: 'Enhance customer service with Bland AI\'s Update Payment Intent tool. Modify Stripe payments in real time and keep billing details accurate.',
  },
  {
    id: '17ed4987-9d0e-481e-9304-a59a72ccd8c4',
    slug: 'retrieve-subscription-details-stripe',
    metaTitle: 'Stripe Product Info for Customer Service via Bland AI',
    metaDescription: 'Streamline customer support by fetching Stripe product details via Bland AI\'s voice agents. Access pricing, descriptions, and statuses quickly for better service.',
  },
  {
    id: 'cbc5b9f5-9573-4391-9c4c-78926cbbe900',
    slug: 'refund-payment-stripe',
    metaTitle: 'Refund Customer Stripe Payments with Bland AI Voice Agents',
    metaDescription: 'Seamlessly process Stripe refunds within Bland AI\'s voice agents. Enjoy real-time updates, automated workflows, and a smoother customer refund experience.',
  },
  {
    id: 'f4796186-afd7-4298-b130-ac5d27e3d4de',
    slug: 'retreive-payment-link-details-stripe',
    metaTitle: 'Retrieve Customer Stripe Subscription Details - Bland AI Voice Agents',
    metaDescription: 'Instantly retrieve real-time Stripe subscription details within Bland AI\'s voice agents. Streamline billing management and empower customer service.',
  },
]
