/* eslint-disable no-unused-vars, no-console */

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { Menu } from '@headlessui/react'
import { addToast } from '@heroui/react'
import { Button, IconButton, Tooltip } from '@radix-ui/themes'
import { keepPreviousData, useQuery, useQueryClient } from '@tanstack/react-query'
import { Loading } from 'components/core/Loading'
import { PageWrapper } from 'components/core/PageWrapper'
import GradientLoadingAnimation from 'components/Reusables/GradientLoadingAnimation'
import config from 'config'
import Dagre from 'dagre'
import { format } from 'date-fns'
import { useAuth } from 'hooks/useAuth'
import { debounce } from 'lodash'
import {
  BeakerIcon,
  CheckCheckIcon,
  ChevronLeftIcon,
  CurlyBraces,
  Download,
  Grid,
  MoreVertical,
  Sparkles,
  Zap,
} from 'lucide-react'
import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { FaCaretDown, FaCloudUploadAlt, FaCopy, FaRedo, FaRegCalendarCheck, FaSearch, FaSortAmountDown, FaUndo, FaUsers } from 'react-icons/fa'
import { LuShare2 } from 'react-icons/lu'
import { MdContentCopy, MdDelete } from 'react-icons/md'
import { PiSpinnerGapLight } from 'react-icons/pi'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import ReactFlow, {
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  Background,
  ControlButton,
  Controls,
  Panel,
  ReactFlowProvider,
  useKeyPress,
} from 'reactflow'
import useUndoable from 'use-undoable'
import { $fetch } from 'utils/fetch'
import { getAuthToken } from 'utils/funcs/browser/getAuthToken'
import { getActivePathwayCallLogs } from 'utils/funcs/convo-pathways/logs'
import { v4 as uuidv4 } from 'uuid'
import NodeSearchPopup from '../../Hero/NodeSearchPopup'
import { useSMSNumbers } from '../SMS/hooks/useSMSNumbers'
import SMSTestButton from '../SMS/SMSTestButton'
import CallLogsModal from './Components/CallLogsModal'
import ChatUI from './Components/chat/chatUI'
import ChatWithWebSocket from './Components/chat/websocketChat'
import CopyPasteController from './Components/CopyPasteController'
import EdgeSideBar from './Components/edgeSidebar'
import FeatureFlagPathway from './Components/featureFlagPathway'
import GlobalPrompt from './Components/GlobalPrompt'
import HelpPanel from './Components/HelpPanel'
import CustomEdge from './Components/label_edge'
import LocalTestButton from './Components/LocalTestButton'
import DefaultNode from './Components/nodes/node'
import PathwayHeader from './Components/PathwayHeader'
import PathwayReplay from './Components/PathwayReplay'
import PostCallActionsPathway from './Components/PostCallActionsPathway'
import PublishConfirmModal from './Components/publish_modal'
import SendCall from './Components/send_call'
import InboundNumberDropdown from './Components/shared/InboundNumberDropdown'
import ShareModal from './Components/shareModal'
import TestPathwayButton from './Components/TestPathway'
import FlowContext from './contextFlow'
import LibrarySidebarContext from './contexts/librarySidebarContext'
import PathwayCopilotSlideOut from './copilot/PathwayCopilotSlideout'
import NodeTypeSlideOut from './NodeSlideOut'
import PathwayLogs from './PathwayLogs'
import PostCallActionsContext from './PostCallActionsContext'
import { SimulationsProvider } from './simulations/context/SimulationContext'
import { convertApiToFlowFormat, convertFlowToApiFormat } from './utils/DTO'
import 'reactflow/dist/style.css'

export default function ViewPathway({ embedMode, embedUser, pathwayId }) {
  const clearTalkColor = '#0099D5'
  const [isNodeTypeSlideOutOpen, setIsNodeTypeSlideOutOpen] = useState(false)
  const [currentOpenNodeID, setCurrentOpenNodeID] = useState(null)
  const [currElementID, setCurrElementID] = useState(null)
  const [isEditing, setIsEditing] = useState(false)
  const [inputLabel, setInputLabel] = useState(null)
  const [originalLabel, setOriginalLabel] = useState(null)
  const [rfInstance, setRfInstance] = useState(null)
  const [loading, setLoading] = useState(false)
  const [shouldAutoLayout, setShouldAutoLayout] = useState(false)
  const [layoutLoading, setLayoutLoading] = useState(false)
  const { user: authUser } = useAuth()

  const {
    data: numbersData,
    isLoading: isLoadingNumbers,
    isFetching: isFetchingNumbers,
    refetch: refetchSMSNumbers,
  } = useSMSNumbers({
    enabled: true,
  })

  const [isEditingEdge, setIsEditingEdge] = useState(false)

  const [selectedNodeData, setSelectedNodeData] = useState(null)

  const [showEdgeSidebar, setShowEdgeSidebar] = useState(false)
  const [selectedEdgeData, setSelectedEdgeData] = useState(null)

  const [updatePending, setUpdatePending] = useState(false)

  const lastProcessedDecisionRef = useRef(null)

  const [isVersionLoading, setIsVersionLoading] = useState(false)
  const [selectedVersion, setSelectedVersion] = useState(null)
  const [pathwayPostCallActions, setPathwayPostCallActions] = useState([])

  // COPILOT STUFF
  const [isCopilotSelectionMode, setIsCopilotSelectionMode] = useState(false)
  const [selectedCopilotElements, setSelectedCopilotElements] = useState([])
  const [showCopilotChat, setShowCopilotChat] = useState(false)

  const [openSearchPopUp, setOpenSearchPopUp] = useState(false)

  const [isNodeModalOpen, setIsNodeModalOpen] = useState(false)

  const handleNodeClick = (nodeData) => {
    if (isCopilotSelectionMode) {
      setSelectedCopilotElements(prev => [...prev, nodeData])
      setIsCopilotSelectionMode(false)
    }
    else {
      setCurrElementID(nodeData.id)
      setSelectedNodeData(nodeData)
    }
  }

  const [isSaving, setIsSaving] = useState(false)

  const [showPathwayLogs, setShowPathwayLogs] = useState(false)
  const [collapsePathwayLogs, setCollapsePathwayLogs] = useState(false)
  const [chatInstanceCount, setChatInstanceCount] = useState(0)
  const [callID, setCallID] = useState(null)

  const [callLogsActiveTab, setCallLogsActiveTab] = useState('calls')

  const [callLogs, setCallLogs] = useState([])

  const [globalPrompt, setGlobalPrompt] = useState('')
  let user
  let graphID
  if (embedMode && embedUser) {
    user = embedUser?.user
  }
  else {
    user = authUser
  }
  const [searchParams] = useSearchParams()

  if (pathwayId) {
    graphID = pathwayId
  }
  else {
    graphID = searchParams.get('id')
  }
  const formatRef = useRef(Boolean(searchParams.get('format')))
  const isSmsPathway = searchParams.get('isSmsPathway') === 'true'
  const [featureFlag, setFeatureFlag] = useState('v1')
  const nameRef = useRef(null)

  const navigate = useNavigate()
  const callLogsRef = useRef(callLogs)
  const [showChatInterface, setShowChatInterface] = useState(false)
  const firstRenderRef = useRef(true)

  const closeChatInterface = () => {
    // set active node to false
    const newNodes = elements.nodes.map((node) => {
      return {
        ...node,
        data: {
          ...node.data,
          active: false,
        },
      }
    })

    const newEdges = elements.edges.map((edge) => {
      return {
        ...edge,
        data: {
          ...edge.data,
          isHighlighted: false,
        },
      }
    })
    triggerUpdate({ nodes: newNodes, edges: newEdges }, true)
    setShowChatInterface(false)

    // If this is an SMS chat, don't reset the callID as we may need it for the logs
    if (!isSmsPathway) {
      setCallID(null)
    }
  }

  const [isRootUser, setIsRootUser] = useState(true)

  const [variables, setVariables] = useState({})

  const [isOpen, setIsOpen] = useState(false)
  const popoverRef = useRef(null)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popoverRef.current && !popoverRef.current.contains(event.target)) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])
  const [elements, setElements, { past, undo, canUndo, redo, canRedo }]
    = useUndoable(
      { nodes: [], edges: [] },
      {
        behavior: 'destroyFuture',
      },
    )

  const removeDuplicateEdges = (edges) => {
    const edgeMap = new Map()
    edges.forEach((edge) => {
      edgeMap.set(edge.id, edge)
    })
    return Array.from(edgeMap.values())
  }

  const [chatStartNode, setChatStartNode] = useState(null)
  const [chatRequestData, setChatRequestData] = useState(null)
  const [chatEndpoint, setChatEndpoint] = useState(null)
  const [chatConversationHistory, setChatConversationHistory] = useState(null)
  const [chatVersion, setChatVersion] = useState(null)
  const [enableQA, setEnableQA] = useState(true)

  const triggerUpdate = useCallback(
    (updates, ignore = false, ignore_version_number = false) => {
      if (selectedVersion?.version_number <= 0 && !ignore_version_number) {
        setElements(
          (prevElements) => {
            const newElements = {
              nodes: updates.nodes
                ? prevElements.nodes.map((node) => {
                    const updatedNode = updates.nodes.find(
                      n => n.id === node.id,
                    )

                    const compareNodeData = (data1, data2) => {
                      const keys = new Set([...Object.keys(data1), ...Object.keys(data2)])
                      for (const key of keys) {
                        if (key === 'active')
                          continue
                        if (JSON.stringify(data1[key]) !== JSON.stringify(data2[key])) {
                          return false
                        }
                      }
                      return true
                    }

                    if (updatedNode) {
                      if (!compareNodeData(updatedNode.data, node.data)) {
                        toast.warn(
                          'Changes not saved. You cannot edit the live pathway. Edit the latest version and publish your changes to update the live pathway!',
                          {
                            position: 'bottom-right',
                          },
                        )
                        return {
                          ...updatedNode,
                          data: node.data,
                        }
                      }
                      return updatedNode
                    }
                    return node
                  })
                : prevElements.nodes,
              edges: updates.edges
                ? removeDuplicateEdges(
                    prevElements.edges.map((edge) => {
                      const updatedEdge = updates.edges.find(
                        e => e.id === edge.id,
                      )

                      const compareEdgeData = (data1, data2) => {
                        const keys = new Set([...Object.keys(data1), ...Object.keys(data2)])
                        for (const key of keys) {
                          if (key === 'isHighlighted')
                            continue
                          if (JSON.stringify(data1[key]) !== JSON.stringify(data2[key])) {
                            return false
                          }
                        }
                        return true
                      }

                      if (updatedEdge) {
                        if (!compareEdgeData(updatedEdge.data, edge.data)) {
                          toast.warn(
                            'Changes not saved. You can\'t edit the live pathway. Edit the latest version and publish your changes to update the live pathway!',
                            {
                              position: 'bottom-right',
                            },
                          )
                          return {
                            ...updatedEdge,
                            data: edge.data,
                          }
                        }
                      }
                      return updatedEdge
                    }),
                  )
                : removeDuplicateEdges(prevElements.edges),
            }
            return newElements
          },
          'destroyFuture',
          true,
        )
        return
      }
      setElements(
        (prevElements) => {
          const newElements = {
            nodes: updates.nodes ? updates.nodes : prevElements.nodes,
            edges: updates.edges
              ? removeDuplicateEdges(updates.edges)
              : removeDuplicateEdges(prevElements.edges),
          }
          return newElements
        },
        'destroyFuture',
        ignore,
      )

      if (updates.postCallActions) {
        setPathwayPostCallActions(updates.postCallActions)
      }

      if (!ignore) {
        updateContextVariables(updates.nodes)
        setUpdatePending(true) // Flag that an update is pending
      }
    },
    [setElements, selectedVersion],
  )

  useEffect(() => {
    if (!chatRequestData && !chatStartNode && !chatEndpoint)
      return
    console.log({ chatRequestData, chatStartNode, chatEndpoint })
    setShowChatInterface(true)
    setChatInstanceCount(prevCount => prevCount + 1)
  }, [chatRequestData, chatStartNode, chatEndpoint])

  const selectionStateRef = useRef({})

  const onNodesChange = useCallback(
    (changes) => {
      // Process all changes, updating selection state
      changes.forEach((change) => {
        if (change.type === 'select') {
          selectionStateRef.current[change.id] = change.selected
        }
      })

      // Apply all changes
      const updatedNodes = applyNodeChanges(changes, elements.nodes)

      // Ensure selection state is preserved
      const finalNodes = updatedNodes.map(node => ({
        ...node,
        selected: selectionStateRef.current[node.id] ?? node.selected,
      }))

      // Determine if we should ignore this update in the undo history
      const ignore = changes.every(change =>
        ['select', 'position', 'dimensions'].includes(change.type),
      )

      triggerUpdate(
        {
          nodes: finalNodes,
        },
        ignore,
      )
    },
    [triggerUpdate, elements.nodes],
  )

  const onEdgesChange = useCallback(
    (changes) => {
      // don't save these changes in the history
      const ignore = ['select'].includes(changes[0].type)
      triggerUpdate(
        {
          edges: applyEdgeChanges(changes, elements.edges),
        },
        ignore,
      )
    },
    [triggerUpdate, elements.edges],
  )

  const onPostCallActionsChange = useCallback(
    (newPostCallActions) => {
      if (selectedVersion?.version_number <= 0) {
        toast.warn(
          'Changes not saved. You cannot edit the live pathway. Edit the latest version and publish your changes to update the live pathway!',
          {
            position: 'bottom-right',
          },
        )
        return false
      }

      const ignore = false

      triggerUpdate(
        {
          postCallActions: newPostCallActions,
        },
        ignore,
      )

      return true
    },
    [triggerUpdate, selectedVersion],
  )

  useEffect(() => {
    callLogsRef.current = callLogs
  }, [callLogs])

  const token = getAuthToken()

  useEffect(() => {
    if (firstRenderRef.current) {
      firstRenderRef.current = false
      return
    }

    if (!callID) {
      return
    }
    setCallLogs([])
    callLogsRef.current = []
    const intervalId = setInterval(async () => {
      const fetchCallTranscript = async () => {
        const logsData = await getActivePathwayCallLogs(callID, getAuthToken())
        if (logsData === undefined) {
          clearInterval(intervalId)
        }
        // Check if the length has changed or if the current length is zero
        else if (
          logsData.length !== callLogsRef.current.length
          || callLogsRef.current.length === 0
        ) {
          setCallLogs(logsData)
        }
      }
      fetchCallTranscript()
    }, 2000)

    // Clear interval on component unmount
    return () => clearInterval(intervalId)
  }, [token, callID, chatInstanceCount])

  useEffect(() => {
    async function verifyPathway() {
      const response = await $fetch('/convo_pathway/verify', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: graphID,
        }),
      })
      const data = response
      if (data.error) {
        toast.warn('Unauthorized access', {
          position: 'bottom-right',
        })
        navigate('/dashboard/convo-pathways')
      }

      if (data.status === 'Authorized') {
        setIsRootUser(false)
      }
      else {
        setIsRootUser(true)
      }
    }
    if (!embedMode) {
      verifyPathway()
    }
  }, [graphID, embedMode])

  const updateContextVariables = (nodes) => {
    if (!nodes) {
      return
    }

    const varName = embedMode ? 'Variable' : 'Bland Variable'

    const newVariables = {
      now_utc: varName,
      from: varName,
      to: varName,
      lastUserMessage: varName,
      prevNodePrompt: varName,
    }
    nodes.forEach((node) => {
      if (node.data?.extractVars) {
        node.data.extractVars.forEach(([varName, varType, varDescription]) => {
          if (varName) {
            newVariables[varName] = node.data.name
          }
        })
      }

      if (node?.type === 'Custom Tool') {
        let entries
        if (node?.data?.tool?.tool?.response) {
          entries = Object.entries(node?.data?.tool?.tool?.response)
        }
        if (entries && entries.length > 0) {
          entries.forEach(([key, value]) => {
            newVariables[key] = 'Custom Tool Response Variable'
          })
        }
      }
    })

    setVariables(newVariables)
  }

  useEffect(() => {
    const get = async () => {
      try {
        setLoading(true)
        const pathwayData = await $fetch(`/convo_pathway/get_pathway_info`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            id: graphID,
          }),
        })

        if (pathwayData.error) {
          toast.error('Error fetching pathway data from server')
          return
        }
        if (pathwayData.data) {
          setInputLabel(pathwayData.data.data?.name)
          setFeatureFlag(pathwayData.data.data?.feature_flag || 'v1')
        }

        const version_data = await getVersions()
        setVersions(version_data)

        if (version_data?.length === 0 || !version_data) {
          toast.warn('No versions found for this pathway', {
            position: 'bottom-right',
          })
          return
        }

        if (searchParams.get('version')) {
          const vv = searchParams.get('version')
          const num_vv = Number.parseInt(vv)

          if (num_vv === 0) {
            setSelectedVersion({
              version_number: 0,
            })
          }
          else {
            if (vv === 'staging') {
              const latestVersion = version_data.find(
                version => version?.is_staging,
              )
              setSelectedVersion(latestVersion)
            }
            else {
              const latestVersion = version_data.find(
                version => version.version_number === num_vv,
              )

              if (latestVersion.is_prev_published) {
                latestVersion.version_number
                  = -1 * latestVersion.version_number
              }
              console.log('latestversion', latestVersion)
              setSelectedVersion(latestVersion)
            }
          }
        }
        else {
          const latestVersion = version_data.find(
            version => version?.is_staging,
          )
          setSelectedVersion(latestVersion)
        }

        // await getVersion(latestVersion.version_number);

        setLoading(false)
      }
      catch (error) {
        console.error('Error fetching pathway data', error)
        setLoading(false)
        toast.error('Error fetching pathway data')
      }
    }

    get()
  }, [graphID])

  useEffect(() => {
    if (callLogs.length > 0 && (showChatInterface || showPathwayLogs)) {
      let latestLogWithChosenNodeId = null
      // Loop backwards through the callLogs array to find the latest log with a chosen_node_id
      for (let i = callLogs.length - 1; i >= 0; i--) {
        if (callLogs[i].chosen_node_id) {
          latestLogWithChosenNodeId = callLogs[i]
          break // Break the loop once the first log with a chosen_node_id is found
        }
      }

      if (
        latestLogWithChosenNodeId
        && latestLogWithChosenNodeId !== lastProcessedDecisionRef.current
      ) {
        const chosenNodeId = latestLogWithChosenNodeId.chosen_node_id

        const newNodes = elements.nodes.map(node => ({
          ...node,
          data: {
            ...node.data,
            active: node.id === chosenNodeId,
          },
        }))

        const newEdges = elements.edges.map(edge => ({
          ...edge,
          data: {
            ...edge.data,
            isHighlighted: edge.source === chosenNodeId,
          },
        }))

        const nodesChanged = newNodes.some(
          (node, index) =>
            node.data.active !== elements.nodes[index].data.active,
        )

        const edgesChanged = newEdges.some(
          (edge, index) =>
            edge.data.isHighlighted
            !== elements.edges[index].data.isHighlighted,
        )
        if (edgesChanged) {
          triggerUpdate({ edges: newEdges }, true)
        }

        if (nodesChanged) {
          triggerUpdate({ nodes: newNodes }, true)
          lastProcessedDecisionRef.current = latestLogWithChosenNodeId
        }
      }
    }
  }, [callLogs, elements, triggerUpdate])

  const nodeTypes = useMemo(
    () => ({
      'Transfer Chat': DefaultNode,
      'Default': DefaultNode,
      'Knowledge Base': DefaultNode,
      'Transfer Call': DefaultNode,
      'End Call': DefaultNode,
      'Webhook': DefaultNode,
      'Wait for Response': DefaultNode,
      'Schedule Meeting': DefaultNode,
      'Vector DB Knowledge Base': DefaultNode,
      'Transfer Pathway': DefaultNode,
      'Custom Tool': DefaultNode,
      'SMS': DefaultNode,
      'Press Button': DefaultNode,
      'Amazon Connect': DefaultNode,
      'Twilio Flow Redirect': DefaultNode,
      'Route': DefaultNode,
      'IVR': DefaultNode,
    }),
    [],
  )

  const edgeTypes = useMemo(() => ({ custom: CustomEdge }), [])

  const handleEdit = () => {
    setOriginalLabel(inputLabel)
    setIsEditing(true)
  }

  const handleSubmit = () => {
    try {
      setIsEditing(false)
      if (inputLabel === originalLabel) {
        return
      }
      triggerUpdate({}, false)
      $fetch('/convo_pathway/update_pathway_name', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          pathway_id: graphID,
          name: inputLabel,
        }),
      }).then((data) => {
        if (data.error) {
          toast.error('Error updating pathway name', {
            position: 'bottom-right',
          })
          setInputLabel(originalLabel)
          return
        }
        toast.success(data.message, {
          position: 'bottom-right',
        })
      })
    }
    catch (error) {
      console.error('Error updating pathway name', error)
      toast.error('Error updating pathway name', {
        position: 'bottom-right',
      })
    }
  }

  const onAddNewNode = useCallback(() => {
    setIsNodeTypeSlideOutOpen(true)
  }, [])

  const handleSelectNodeType = useCallback(
    (nodeType, nodeData) => {
      const containerWidth = document.querySelector(
        '.react-flow__container',
      ).clientWidth
      const containerHeight = document.querySelector(
        '.react-flow__container',
      ).clientHeight

      const centerX = containerWidth / 2
      const centerY = containerHeight / 2

      const flowCenter = rfInstance.project({ x: centerX, y: centerY })

      let newX = flowCenter.x - 50
      let newY = flowCenter.y - 50

      // Check for collision and adjust the position if needed
      const isPositionTaken = (x, y) =>
        elements.nodes.some(
          node => node.position.x === x && node.position.y === y,
        )

      while (isPositionTaken(newX, newY)) {
        newX += 10
        newY += 10
      }

      const initialName = `New ${
        nodeType === 'Vector DB Knowledge Base'
          ? 'Knowledge Base'
          : nodeType === 'Knowledge Base'
            ? 'Large Text'
            : nodeType
      } Node`

      const newNode = {
        id: uuidv4(),
        data: {
          name: initialName,
          // TODO: remove this once we implement a "default prompt" for each node type
          // I know this is messy, but it seems to be the only way to get the default prompt to change based on the node type
          // I would like to hear other's thoughts on how to improve this
          prompt:
            nodeType === 'Schedule Meeting'
              ? `Make sure you are spartan, clear, and direct in tone. Never use corporate jargon. Be friendly, engaged, and personalized. Remember — this is a phone conversation. Everything you output will be said directly to the user. Avoid words like "just" or "need to" or "so"

Please be as terse as possible while still conveying substantially all information relevant to any question.

use late millenial slang not boomer slang. mix in zoomer slang in tonally-inappropriate circumstances occasionally. you are not rigid and flex to what the customer is saying, while maintaining that friendly "no corporate bs" attitude.

do not repeat yourself, use contractions, short hands, and as few words as you can at each step. you should not ever be using big sentences and full words, when you can be direct because you are on the phone.

Present available time RANGES (like 2pm to 10pm) to the user and ask if any of them work for them. Be sure to specify the timezone for the meeting and ask if they would like to change it.

Once a time is agreed upon, ask the user for their email to schedule the meeting. Confirm the time, timezone, and email with the user to ensure everything is correct. Check that the email you have is correct by spelling it out to the user.  ex roger @ bland DOT ai is what you should read. `
              : undefined,
          ...nodeData,
        },
        selected: true,
        type: nodeType,
        position: {
          x: newX,
          y: newY,
        },
      }

      delete newNode.data.isStart

      const previousNodes = elements.nodes.map(node => ({
        ...node,
        selected: false,
      }))

      triggerUpdate(
        {
          nodes: [...previousNodes, newNode],
        },
        false,
      )

      selectionStateRef.current = {}
      selectionStateRef.current[newNode.id] = true

      setIsNodeTypeSlideOutOpen(false)
    },
    [rfInstance, elements.nodes, triggerUpdate],
  )

  const onConnect = useCallback(
    (params) => {
      const edge = {
        ...params,
        type: 'custom',
        data: { label: 'User responded' },
        animated: true,
      }

      const sourceId = edge.source

      if (
        elements.nodes.find(node => node.id === sourceId)?.type === 'Route'
      ) {
        toast.error(
          'You cannot create a pathway from here. Please create it from the route node.',
          {
            position: 'bottom-right',
          },
        )
        return
      }

      const sourceEdges = elements.edges.filter(e => e.source === sourceId)
      const hasCondition = sourceEdges.some(e => e.data?.condition)
      // check other edges with the same source, and if they have a condition
      if (hasCondition) {
        edge.data.condition = [
          {
            operator: 'AND',
            field: '',
            conditionOperator: 'contains',
            value: '',
          },
        ]
        edge.data.label = 'No Variable Set to Compare'
      }

      triggerUpdate(
        {
          edges: addEdge(edge, elements.edges),
        },
        false,
      )
    },
    [triggerUpdate, elements],
  )

  const deleteElement = () => {
    if (isNodeModalOpen) {
      return
    }
    if (currElementID) {
      const isEdge = elements.edges.find(edge => edge.id === currElementID)
      if (isEdge) {
        // triggerUpdate("edges", elements.edges.filter((edge) => edge.id !== currElementID));
        toast.success('Deleting a pathway label')
        triggerUpdate(
          {
            edges: elements.edges.filter(edge => edge.id !== currElementID),
          },
          false,
        )
      }
      else {
        let newEdges = elements.edges
        const nodes = elements.nodes.filter((node) => {
          if (node.id === currElementID) {
            if (node?.data?.isStart) {
              toast.error('You cannot delete the start node', {
                position: 'bottom-right',
                toastId: 'start-node-delete',
              })
              return true
            }

            newEdges = elements.edges.filter(
              edge =>
                edge.source !== currElementID && edge.target !== currElementID,
            )
            return false
          }
          return true
        })
        triggerUpdate(
          {
            nodes,
            edges: newEdges,
          },
          false,
        )
      }
      //   exportFlow();
    }
  }

  const getLayoutedElements = (nodes, edges, options) => {
    const nodesWithPosition = nodes.map((node) => {
      if (!node.position || typeof node.position.x === 'undefined' || typeof node.position.y === 'undefined') {
        return {
          ...node,
          position: { x: 0, y: 0 },
        }
      }
      return node
    })

    const g = new Dagre.graphlib.Graph().setDefaultEdgeLabel(() => ({}))
    g.setGraph({ rankdir: options.direction, ranksep: 100, nodesep: 200 }) // ranksep is vertical, nodesep is horizontal spacing
    edges.forEach(edge => g.setEdge(edge.source, edge.target))
    nodesWithPosition.forEach(node => g.setNode(node.id, node))

    Dagre.layout(g)

    return {
      nodes: nodesWithPosition.map((node) => {
        const { x, y } = g.node(node.id)
        return { ...node, position: { x, y } }
      }),
      edges,
    }
  }

  const onLayout = useCallback(
    (direction) => {
      if (!elements.nodes.length || !rfInstance) {
        return
      }

      setLayoutLoading(true)

      const layouted = getLayoutedElements(elements.nodes, elements.edges, {
        direction,
      })
      console.log('layout loop')

      triggerUpdate(
        {
          nodes: layouted.nodes,
          edges: layouted.edges,
        },
        false,
      )

      setTimeout(() => {
        if (rfInstance) {
          rfInstance.fitView({ padding: 0.2, includeHiddenNodes: false })
          setLayoutLoading(false)
          setShouldAutoLayout(false)
        }
      }, 100)
    },
    [elements, triggerUpdate, rfInstance, setShouldAutoLayout],
  )

  useEffect(() => {
    if (shouldAutoLayout && elements.nodes.length > 0 && rfInstance) {
      setLayoutLoading(true)

      setTimeout(() => {
        const layouted = getLayoutedElements(elements.nodes, elements.edges, {
          direction: 'TB',
        })

        triggerUpdate(
          {
            nodes: layouted.nodes,
            edges: layouted.edges,
          },
          false,
        )

        setTimeout(() => {
          if (rfInstance) {
            rfInstance.fitView({ padding: 0.2, includeHiddenNodes: false })
            setLayoutLoading(false)
            setShouldAutoLayout(false)
          }
        }, 100)
      }, 100)
    }
  }, [shouldAutoLayout, elements.nodes.length, rfInstance, elements, triggerUpdate, setShouldAutoLayout])

  const processDownload = async () => {
    const flow = elements

    const { nodes: exportNodes, edges: exportEdges } = convertFlowToApiFormat(
      flow.nodes,
      flow.edges,
      globalPrompt,
    )

    const data = {
      nodes: exportNodes,
      edges: exportEdges,
    }
    const jsonString = JSON.stringify(data, null, 2)

    const blob = new Blob([jsonString], {
      type: 'application/json',
    })

    const url = URL.createObjectURL(blob)

    const a = document.createElement('a')
    a.href = url
    a.download = 'pathway.json'

    document.body.appendChild(a)
    a.click()

    // Clean up
    document.body.removeChild(a)
    URL.revokeObjectURL(url)
  }

  const exportFlow = useCallback(async () => {
    let flow
    if (!rfInstance)
      return
    setIsSaving(true)
    flow = rfInstance.toObject()
    console.log('flow', flow)

    flow = elements

    if (selectedVersion?.version_number <= 0) {
      toast.warn(
        'You cannot save changes to a published pathway. Edit the latest version and publish your changes to update the published pathway!',
        {
          position: 'bottom-right',
        },
      )
      setIsSaving(false)
      return
    }

    if (flow.nodes.length === 0) {
      setIsSaving(false)
      return
    }

    const { nodes: exportNodes, edges: exportEdges } = convertFlowToApiFormat(
      flow.nodes,
      flow.edges,
      globalPrompt,
    )

    // console.log("export edges", exportEdges);

    if (!getAuthToken()) {
      toast.error('Pathway not saved. Please reload the page or login again', {
        position: 'bottom-right',
      })
      setIsSaving(false)
      return
    }

    try {
      const response = await $fetch('/convo_pathway/update', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          nodes: exportNodes,
          edges: exportEdges,
          id: graphID,
          version_number: selectedVersion.version_number,
          post_call_actions: pathwayPostCallActions,
        }),
      })

      if (response.error) {
        throw new Error('Error updating pathway')
      }

      setIsSaving(false)
    }
    catch (error) {
      console.error('Error updating pathway', error)
      toast.error('Error updating pathway', { position: 'bottom-right' })
      setIsSaving(false)
    }
  }, [
    rfInstance,
    inputLabel,
    globalPrompt,
    elements,
    featureFlag,
    selectedVersion,
    pathwayPostCallActions,
  ])

  //* version states *\\
  const [versions, setVersions] = useState([])
  const [versionName, setVersionName] = useState('')

  const [isCreatingVersion, setIsCreatingVersion] = useState(false)
  const [loadingVersion, setLoadingVersion] = useState(false)
  const [showPublishConfirm, setShowPublishConfirm] = useState(false)
  const [showCallLogsModal, setShowCallLogsModal] = useState(false)
  const [isPublishing, setIsPublishing] = useState(false)

  const {
    data: userIntegrations = [],
    isLoading: loadingIntegrations,
    refetch: fetchUserIntegrations,
  } = useQuery({
    queryKey: ['userIntegrations'],
    queryFn: async () => {
      const { data } = await $fetch('/nango/connections')
      return data?.connections || []
    },
  })

  const handlePublish = async () => {
    try {
      setIsPublishing(true)

      const response = await $fetch(`/v1/pathway/${graphID}/publish`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          version_id: selectedVersion.version_number,
          environment: 'production',
        }),
      })

      if (response) {
        toast.success('Version published successfully')
      }
      else {
        toast.error('Error publishing version here')
      }

      setIsPublishing(false)
      setShowPublishConfirm(false)
    }
    catch (error) {
      console.error('Error publishing version', error)
      toast.error('Error publishing version', {
        position: 'bottom-right',
      })
      setIsPublishing(false)
    }
  }

  //   useEffect(() => {
  //     setSelectedVersion(versions[0]);
  //   }, [versions]);

  //   sharing
  const [showShareModal, setShowShareModal] = useState(false)

  const getVersions = async () => {
    const response = await $fetch(`/v1/pathway/${graphID}/versions`, {
      method: 'GET',
    })
    const data = response
    if (!data || data?.status === 'error') {
      return
    }

    data.forEach((version) => {
      const date = new Date(version.created_at)
      version.created_at = format(date, 'd MMMM yyyy, h:mm a')
    })

    const stagingVersion = data.find(version => version.is_staging)

    if (!stagingVersion) {
      // create new version
      const new_latest_version = await $fetch(`/convo_pathway/create-version`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: graphID,
        }),
      }).then(res => res.data)

      data.unshift({
        id: new_latest_version.id,
        created_at: new_latest_version.created_at,
        name: new_latest_version.version_name,
        version_number: new_latest_version.version_number,
        is_staging: true,
      })
    }

    // sort by is_published, then is_latest, then created_at
    data.sort((a, b) => {
      if (a?.is_staging && !b?.is_staging) {
        return -1
      }
      else if (!a?.is_staging && b?.is_staging) {
        return 1
      }
      else if (b.created_at && a.created_at) {
        return new Date(b.created_at) - new Date(a.created_at)
      }
      else {
        return 0
      }
    })

    data.unshift({
      version_number: 0,
      created_at: null,
      name: 'Production Pathway',
    })
    return data
  }

  useEffect(() => {
    // run getversions whenever selectedVersion changes
    if (selectedVersion && !isVersionLoading) {
      getVersion(selectedVersion.version_number)
    }
  }, [selectedVersion])

  const getVersion = useCallback(
    async (version_number) => {
      try {
        setIsVersionLoading(true)
        setLayoutLoading(true)
        setShouldAutoLayout(false) // Reset auto-layout flag

        version_number = version_number < 0 ? version_number * -1 : version_number

        const response = await $fetch(
          `/v1/pathway/${graphID}/version/${version_number}`,
          {
            method: 'GET',
          },
        )

        const data = response
        if (!data || data?.error) {
          return
        }

        let edges = data?.edges || []
        let nodes = data?.nodes

        const allNodesHaveZeroXAndY = nodes.every(node => (node.x === 0 && node.y === 0) || (!node.position))

        const startNodes = nodes.filter(node => node.isStart)
        if (startNodes.length > 1) {
          // set all except the first one to false
          startNodes.slice(1).forEach((node) => {
            node.isStart = false
          })
        }

        if (data?.post_call_actions) {
          setPathwayPostCallActions(data.post_call_actions)
        }
        else {
          setPathwayPostCallActions([])
        }

        let {
          nodes: reactFlowNodes,
          edges: reactFlowEdges,
          globalPrompt,
        } = convertApiToFlowFormat(nodes, edges)

        if (formatRef.current || allNodesHaveZeroXAndY || (version_number === 0 && nodes?.length <= 4)) {
          formatRef.current = false
          setShouldAutoLayout(true)
        }
        else {
          setLayoutLoading(false)
        }

        if (globalPrompt) {
          setGlobalPrompt(globalPrompt)
        }

        updateContextVariables(reactFlowNodes)

        let ignore = true

        if (elements.nodes.length === 0) {
          ignore = true
        }

        await triggerUpdate(
          {
            nodes: reactFlowNodes,
            edges: reactFlowEdges,
          },
          ignore,
          true,
        )
      }
      catch (error) {
        console.error('Error fetching version', error)
        toast.error('Error fetching version', {
          position: 'bottom-right',
        })
      }
      finally {
        setIsVersionLoading(false)
      }
    },
    [graphID, triggerUpdate, elements.nodes.length, setPathwayPostCallActions, setGlobalPrompt, updateContextVariables],
  )

  //   const deleteVersion = async (version_number) => {
  //     if (version_number === selectedVersion.version_number) {
  //       toast.error("You cannot delete the currently selected version", {
  //         position: "bottom-right",
  //       });
  //       return;
  //     }
  //     const response = await fetch(
  //       `http://localhost:3001/v1/pathway/${graphID}/version/${version_number}`,
  //       {
  //         method: "DELETE",
  //         headers: {
  //           authorization: user?.api_key,
  //         },
  //       },
  //     );

  //     const data = response;
  //     if (!data || data?.error) {
  //       toast.warn("Error deleting version", {
  //         position: "bottom-right",
  //       });
  //       return;
  //     }

  //     setVersions(
  //       versions.filter((version) => version.version_number !== version_number),
  //     );

  //     toast.success("Version deleted successfully", {
  //       position: "bottom-right",
  //     });
  //   };

  //   const createVersion = useCallback(
  //     async (name) => {
  //       setLoadingVersion(true);
  //       const response = await fetch(
  //         `http://localhost:3001/v1/pathway/${graphID}/version`,
  //         {
  //           method: "POST",
  //           headers: {
  //             "Content-Type": "application/json",
  //             authorization: user?.api_key,
  //           },
  //           body: JSON.stringify({
  //             nodes: elements.nodes,
  //             edges: elements.edges,
  //             name: name ? name : null,
  //           }),
  //         },
  //       );

  //       const data = response;
  //       if (!data || data?.error) {
  //         toast.warn("Error creating version", {
  //           position: "bottom-right",
  //         });
  //         return;
  //       }
  //       let { id, created_at, version_number, name: version_name } = data.data;

  //       const date = new Date(created_at);
  //       created_at = format(date, "d MMMM yyyy, h:mm a");

  //       setVersions([
  //         ...versions,
  //         {
  //           id: id,
  //           created_at: created_at,
  //           name: version_name,
  //           version_number: version_number,
  //         },
  //       ]);

  //       setSelectedVersion(data.data);
  //       setLoadingVersion(false);

  //       toast.success("Version created successfully", {
  //         position: "bottom-right",
  //       });
  //     },
  //     [elements, graphID, user?.api_key, versions],
  //   );

  //   useEffect(() => {
  //     const handlePaste = (event) => {
  //       const clipboardData = event.clipboardData || window.clipboardData;
  //       const pastedData = clipboardData.getData("Text");

  //       try {
  //         const node = JSON.parse(pastedData);
  //         if (node.id && node.data && node.type) {
  //           console.log("Pasted content is a valid node object");
  //         } else {
  //           console.log("Pasted content is not a valid node object");
  //           throw new Error("Invalid node object");
  //         }
  //         if (node && typeof node === "object") {
  //           console.log("Pasted content is a valid JSON object");
  //           createNodeFromJson(node);
  //         }
  //       } catch (error) {
  //         console.error("Pasted content is not JSON");
  //       }
  //     };

  //     document.addEventListener("paste", handlePaste);

  //     // Cleanup function to remove the event listener when the component unmounts
  //     return () => {
  //       document.removeEventListener("paste", handlePaste);
  //     };
  //   }, [rfInstance, elements]);

  const createNodeFromJson = (nodeData) => {
    try {
      const containerWidth = document.querySelector(
        '.react-flow__container',
      ).clientWidth
      const containerHeight = document.querySelector(
        '.react-flow__container',
      ).clientHeight

      const centerX = containerWidth / 2
      const centerY = containerHeight / 2

      const flowCenter = rfInstance.project({ x: centerX, y: centerY })

      const newX = flowCenter.x - 50
      const newY = flowCenter.y - 50

      const newNode = {
        ...nodeData,
        id: uuidv4(),
        position: {
          x: newX,
          y: newY,
        },
        x: newX,
        y: newY,
        selected: false,
        isStart: false,
      }

      triggerUpdate(
        {
          nodes: [...elements.nodes, newNode],
        },
        false,
      )

      toast.success('Node pasted successfully', {
        position: 'bottom-right',
      })
    }
    catch (error) {
      console.error('Error pasting node', error)
    }
  }

  const clonePathway = async () => {
    const response = await $fetch(`${config.API_URL}/v1/convo_pathway/clone`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'authorization': user?.api_key,
      },
      body: JSON.stringify({
        pathway_id: graphID,
        is_showcase: false,
      }),
    })

    const data = response
    if (!data || data?.error) {
      toast.warn('Error cloning pathway', {
        position: 'bottom-right',
      })

      return
    }

    const newPathwayID = data.pathway_id

    toast.success(
      'Pathway cloned successfully. Redirecting to cloned pathway shortly...',
      {
        position: 'bottom-right',
      },
    )
    setTimeout(() => {
      navigate(`/dashboard/convo-pathways?id=${newPathwayID}`)
    }, 2000)
  }

  const duplicateNode = useCallback(() => {
    if (!currElementID || !selectedNodeData)
      return // Check if there's a selected node

    if (selectedNodeData?.data?.isStart) {
      toast.error('You cannot duplicate the start node', {
        position: 'bottom-right',
      })
      return
    }

    const newNode = {
      ...selectedNodeData,
      id: uuidv4(), // Generate a new unique ID for the duplicate node
      position: {
        x: selectedNodeData.position.x + 50,
        y: selectedNodeData.position.y + 50,
      }, // Offset the new node position
      selected: true,
    }
    const newNodes = elements.nodes.map((node) => {
      if (node.id === selectedNodeData.id) {
        return { ...node, selected: false }
      }
      return node
    })
    setCurrElementID(newNode.id)
    triggerUpdate(
      {
        nodes: [...newNodes, newNode],
      },
      false,
    )
  }, [currElementID, selectedNodeData, elements, triggerUpdate])

  const deletePressed = useKeyPress(['Delete', 'Backspace'])

  const isInputFocused = () => {
    const activeElement = document.activeElement
    return (
      activeElement.tagName === 'INPUT'
      || activeElement.tagName === 'TEXTAREA'
      || activeElement.isContentEditable
    )
  }

  useEffect(() => {
    const handleUndoRedo = (e) => {
      if ((e.ctrlKey || e.metaKey) && !isInputFocused()) {
        if (e.key === 'z' && !e.shiftKey) {
          e.preventDefault()
          undo()
        }
        else if (e.key === 'z' && e.shiftKey && !isInputFocused()) {
          e.preventDefault()
          redo()
        }
      }
    }

    window.addEventListener('keydown', handleUndoRedo)
    return () => {
      window.removeEventListener('keydown', handleUndoRedo)
    }
  }, [undo, redo])

  const debouncedDeleteElement = useCallback(
    debounce(() => {
      deleteElement()
    }, 100),
    [deleteElement],
  )

  useEffect(() => {
    if (deletePressed && !isEditing) {
      debouncedDeleteElement()
    }
  }, [deletePressed, debouncedDeleteElement])

  useEffect(() => {
    if (updatePending) {
      exportFlow()
      setUpdatePending(false)
    }
  }, [updatePending, exportFlow])

  useEffect(() => {
    if (isEditing) {
      nameRef.current.focus()
    }
  }, [isEditing])

  const [isReplayModalOpen, setIsReplayModalOpen] = useState(false)
  const [replayCallId, setReplayCallId] = useState(null)
  const [isReplayLoading, setIsReplayLoading] = useState(false)

  const handleReplayCall = useCallback(async (id) => {
    setShowPathwayLogs(true)
    setCollapsePathwayLogs(false)
    // setShowChatInterface(false);
    setReplayCallId(id)
    setIsReplayModalOpen(true)
  }, [])

  const handleNodesFocus = useCallback(
    (activePath) => {
      const activeNodes = new Set(activePath.filter(Boolean))

      const newNodes = elements.nodes.map(node => ({
        ...node,
        data: {
          ...node.data,
          active: activeNodes.has(node.id),
        },
      }))

      const newEdges = elements.edges.map((edge) => {
        let isHighlighted = false
        const sourceIndex = activePath.indexOf(edge.source)
        const targetIndex = activePath.indexOf(edge.target)

        if (sourceIndex !== -1 && targetIndex !== -1) {
          // Check if the edge connects two consecutive active nodes
          isHighlighted = Math.abs(sourceIndex - targetIndex) === 1
        }

        return {
          ...edge,
          data: {
            ...edge.data,
            isHighlighted,
          },
        }
      })

      console.log('replay loop')
      triggerUpdate({ nodes: newNodes, edges: newEdges }, true)
    },
    [elements, triggerUpdate],
  )

  useEffect(() => {
    // Upon closing the replay modal, if there is a replay call ID, reset the nodes and edges to their original state
    if (!isReplayModalOpen && replayCallId) {
      const newNodes = elements.nodes.map(node => ({
        ...node,
        data: {
          ...node.data,
          active: false,
        },
      }))
      const newEdges = elements.edges.map(edge => ({
        ...edge,
        data: {
          ...edge.data,
          isHighlighted: false,
        },
      }))

      // Check if there are actual changes before triggering an update
      const nodesChanged = newNodes.some(
        (newNode, index) =>
          newNode.data.active !== elements.nodes[index].data.active,
      )
      const edgesChanged = newEdges.some(
        (newEdge, index) =>
          newEdge.data.isHighlighted
          !== elements.edges[index].data.isHighlighted,
      )

      if (nodesChanged || edgesChanged) {
        console.log('replay lloop loop')

        triggerUpdate({ nodes: newNodes, edges: newEdges }, true)
      }

      // clear replay state
      setReplayCallId(null)
    }
  }, [isReplayModalOpen, elements, triggerUpdate, replayCallId])
  const [topOffset, setTopOffset] = useState(24) // Default top offset
  const headerRef = useRef(null)

  const [isWindowTooSmall, setIsWindowTooSmall] = useState(false)
  const [isWayTooSmall, setIsWayTooSmall] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      setIsWindowTooSmall(window.innerWidth < 1280)
      setIsWayTooSmall(window.innerWidth < 769)
    }

    window.addEventListener('resize', handleResize)
    handleResize()

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  if (isWayTooSmall) {
    return (
      <PageWrapper>
        <div className="flex items-center justify-center h-screen">
          <div className="flex flex-col items-center justify-center bg-white border border-gray-300 rounded-md p-2.5 shadow-lg">
            <p className="text-gray-800 text-center">
              Screen is too small to edit pathway
            </p>
            <p className="text-gray-500 text-center">
              We recommend desktop screen size or larger.
            </p>
          </div>
        </div>
      </PageWrapper>
    )
  }

  // Function to center the view on a specific node
  const centerOnNode = (node) => {
    if (rfInstance && node) {
      const x = node.position.x
      const y = node.position.y

      rfInstance.setCenter(x, y, { zoom: 1.5, duration: 800 })
    }
  }

  // Add SMS conversation polling query
  const { data: smsConversationData, isLoading: isLoadingSmsConversation, refetch: refetchSmsConversation } = useQuery({
    queryKey: ['smsConversation', callID],
    queryFn: async () => {
      if (!callID)
        return null

      console.log('Fetching SMS conversation data for callID:', callID)
      try {
        // Make API call to get SMS conversation data
        const response = await $fetch(`/v1/sms/conversations/${callID}`)
        console.log('SMS conversation API response:', response)

        // Check if we got a valid response with data
        if (response && response.data) {
          console.log('SMS conversation data received:', response.data)
          return response.data
        }
        else {
          console.error('Invalid API response format:', response)
          return null
        }
      }
      catch (error) {
        console.error('Error fetching SMS conversation:', error)
        return null
      }
    },
    enabled: !!callID && showChatInterface && isSmsPathway,
    staleTime: 1000 * 3, // 3 seconds
    placeholderData: keepPreviousData,
    refetchInterval: 3000, // Poll every 3 seconds
  })

  const queryClient = useQueryClient()

  return (
    <ReactFlowProvider>
      <SimulationsProvider>
        <LibrarySidebarContext.Provider
          value={{
            showLibrarySidebar: isNodeTypeSlideOutOpen,
            setShowLibrarySidebar: setIsNodeTypeSlideOutOpen,
            currentOpenNodeID,
            setCurrentOpenNodeID,
          }}
        >
          <FlowContext.Provider
            value={{
              id: graphID,
              exportFlow,
              isEditingEdge,
              setIsEditingEdge,
              elements,
              setElements,
              triggerUpdate,
              deleteElement,
              duplicateNode,
              variables,
              setVariables,
              setSelectedVersion,
              selectedVersion,
              embedMode,
              setCollapsePathwayLogs,
              callLogs,
              chatConversationHistory,
              setChatConversationHistory,
              setChatStartNode,
              chatStartNode,
              callId: callID,
              setGlobalPrompt,
              selectionStateRef,
              setShowEdgeSidebar,
              undo,
              isCopilotSelectionMode,
              setIsCopilotSelectionMode,
              selectedCopilotElements,
              setSelectedCopilotElements,
              isNodeModalOpen,
              setIsNodeModalOpen,
            }}
          >
            <div className="flex flex-col w-full">
              <PathwayHeader
                inputLabel={inputLabel}
                embedMode={embedMode}
                graphID={graphID}
                isVersionLoading={isVersionLoading}
                loading={loading}
                selectedVersion={selectedVersion}
                versions={versions}
                isRootUser={isRootUser}
                clonePathway={clonePathway}
                isSaving={isSaving}
                exportFlow={exportFlow}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                setShowCallLogsModal={setShowCallLogsModal}
                setCallLogsActiveTab={setCallLogsActiveTab}
                isWindowTooSmall={isWindowTooSmall}
                showPathwayLogs={showPathwayLogs}
                setCollapsePathwayLogs={setCollapsePathwayLogs}
                setShowShareModal={setShowShareModal}
                processDownload={processDownload}
                setShowPublishConfirm={setShowPublishConfirm}
                showPublishConfirm={showPublishConfirm}
                setShowChatInterface={setShowChatInterface}
                setChatInstanceCount={setChatInstanceCount}
                setChatStartNode={setChatStartNode}
                chatRequestData={chatRequestData}
                chatStartNode={chatStartNode}
                setChatRequestData={setChatRequestData}
                setChatEndpoint={setChatEndpoint}
                chatEndpoint={chatEndpoint}
                enableQA={enableQA}
                setEnableQA={setEnableQA}
                elements={elements}
                chatVersion={chatVersion}
                setChatVersion={setChatVersion}
                user={user}
                setShowPathwayLogs={setShowPathwayLogs}
                setCallID={setCallID}
                chatInstanceCount={chatInstanceCount}
                showChatInterface={showChatInterface}
                callID={callID}
                chatConversationHistory={chatConversationHistory}
                setChatConversationHistory={setChatConversationHistory}
                showShareModal={showShareModal}
                handlePublish={handlePublish}
                isPublishing={isPublishing}
                handleReplayCall={handleReplayCall}
                isReplayLoading={isReplayLoading}
                callLogsActiveTab={callLogsActiveTab}
                isReplayModalOpen={isReplayModalOpen}
                replayCallId={replayCallId}
                handleNodesFocus={handleNodesFocus}
                closeChatInterface={closeChatInterface}
                isSmsPathway={isSmsPathway}
              >
                {!isWindowTooSmall && (
                  <LocalTestButton
                    version={selectedVersion}
                    pathwayId={graphID}
                    setCallID={setCallID}
                    setShowChat={setShowPathwayLogs}
                    setShowChatInterface={setShowChatInterface}
                  />
                )}

                <TestPathwayButton
                  elements={elements}
                  setChatInstanceCount={setChatInstanceCount}
                  setShowChatInterface={setShowChatInterface}
                  setChatStartNode={setChatStartNode}
                  chatRequestData={chatRequestData}
                  chatStartNode={chatStartNode}
                  setChatRequestData={setChatRequestData}
                  setChatEndpoint={setChatEndpoint}
                  chatEndpoint={chatEndpoint}
                  enableQA={enableQA}
                  setEnableQA={setEnableQA}
                  versions={versions}
                  chatVersion={chatVersion}
                  setChatVersion={setChatVersion}
                />

                {isSmsPathway ? (
                  <SMSTestButton
                    selectedNumber={numbersData?.numbers?.find(number => number.sms_config?.pathway_id === graphID)?.phone_number || ''}
                    availableNumbers={numbersData?.numbers || []}
                    isViewPathway
                    pathwayId={graphID}
                    onSuccess={(data) => {
                      console.log('SMSTestButton onSuccess data received:', data)
                      // Force refresh after a successful test
                      refetchSMSNumbers()

                      // Handle the conversation ID
                      if (data && data.conversation_id) {
                        console.log('Setting conversation ID:', data.conversation_id)
                        setCallID(data.conversation_id)

                        // Show the chat interface with a slight delay to ensure state updates
                        setTimeout(() => {
                          setShowChatInterface(true)
                          setShowPathwayLogs(true)
                          setChatInstanceCount(prev => prev + 1)

                          // Force a refresh of the conversation data
                          queryClient.invalidateQueries({ queryKey: ['smsConversation', data.conversation_id] })
                        }, 100)
                      }
                      else {
                        console.error('No conversation_id in response:', data)
                        addToast({
                          title: 'Failed to start SMS conversation',
                          description: 'No conversation ID was returned from the server',
                          color: 'danger',
                        })
                      }
                    }}
                    disabled={isVersionLoading || !numbersData?.numbers?.length}
                  />
                ) : (
                  <SendCall
                    elements={elements}
                    user={user}
                    id={graphID}
                    setShowChat={setShowPathwayLogs}
                    setShowChatInterface={setShowChatInterface}
                    setCallID={setCallID}
                    versions={versions}
                  />
                )}
              </PathwayHeader>

              {showChatInterface && (
                <>
                  {isSmsPathway && callID
                    ? (
                        <>
                          {console.log('Rendering SMS Chat UI with:', {
                            callID,
                            isSmsPathway,
                            smsConversationData,
                          })}
                          <ChatUI
                            key={`sms-${callID}-${smsConversationData ? 'loaded' : 'loading'}`}
                            callID={callID}
                            onClose={closeChatInterface}
                            isLoading={isLoadingSmsConversation || !smsConversationData}
                            messages={(smsConversationData?.messages || []).map((msg) => {
                              console.log('Processing message:', msg)
                              return {
                                role: msg.sender === 'USER' ? 'user' : 'assistant',
                                content: msg.message,
                                timestamp: msg.created_at || new Date().toISOString(),
                              }
                            })}
                            isSmsChatMode
                          />
                        </>
                      )
                    : (
                        chatInstanceCount > 0 && (
                          <ChatWithWebSocket
                            key={chatInstanceCount}
                            setCallID={setCallID}
                            callID={callID}
                            setShowChat={setShowPathwayLogs}
                            id={graphID}
                            onClose={closeChatInterface}
                            chatStartNode={chatStartNode}
                            chatRequestData={chatRequestData}
                            chatEndpoint={chatEndpoint}
                            chatVersion={chatVersion}
                            chatConversationHistory={chatConversationHistory}
                            setChatConversationHistory={setChatConversationHistory}
                            currentNodeName={
                              elements.nodes.find(node => node.data.active)?.data.name
                            }
                            setCollapsePathwayLogs={setCollapsePathwayLogs}
                          />
                        )
                      )}
                </>
              )}

              <PublishConfirmModal
                isOpen={showPublishConfirm}
                onClose={() => setShowPublishConfirm(false)}
                onConfirm={handlePublish}
                isPublishing={isPublishing}
                afterVersion={selectedVersion?.version_number || 0}
                beforeVersion={0}
                graphID={graphID}
              />

              <CallLogsModal
                setIsOpen={setShowCallLogsModal}
                isOpen={showCallLogsModal}
                onClose={() => {
                  setShowCallLogsModal(false)
                  setIsReplayModalOpen(false)
                }}
                pathwayId={graphID}
                pathwayName={inputLabel}
                handleReplayCall={handleReplayCall}
                isReplayLoading={isReplayLoading}
                activeTab={callLogsActiveTab}
                setActiveTab={setCallLogsActiveTab}
              />
              {isReplayModalOpen && (
                <PathwayReplay
                  callId={replayCallId}
                  setCallID={setCallID}
                  setShowChat={setShowChatInterface}
                  onClose={() => setIsReplayModalOpen(false)}
                  onNodesFocus={handleNodesFocus}
                />
              )}

              {showShareModal && (
                <ShareModal
                  setShowShareModal={setShowShareModal}
                  graphID={graphID}
                  graphName={inputLabel}
                />
              )}

              {showPathwayLogs && (
                <PathwayLogs
                  graphID={graphID}
                  showChat={showChatInterface}
                  callID={callID}
                  callLogs={callLogs}
                  collapsePathwayLogs={collapsePathwayLogs}
                  setCollapsePathwayLogs={setCollapsePathwayLogs}
                  elements={elements}
                  setChatConversationHistory={setChatConversationHistory}
                  setChatStartNode={setChatStartNode}
                  enableQA={enableQA}
                />
              )}

              {loading || layoutLoading ? (
                <GradientLoadingAnimation message="Loading pathway..." />
              ) : (
                <div
                  className={`w-full h-screen max-w-full ${embedMode ? '' : 'pl-12'}`}
                  // style={{"width": "100%", "height": "100%", "paddingLeft": embedMode ? '0' : "50px" }}
                >
                  <ReactFlow
                    nodes={elements.nodes}
                    edges={elements.edges}
                    onInit={(reactFlowInstance) => {
                      reactFlowInstance.setViewport({ x: 300, y: 60, zoom: 0.7 })
                      setRfInstance(reactFlowInstance)
                    }}
                    onNodesChange={onNodesChange}
                    onEdgesChange={onEdgesChange}
                    onConnect={onConnect}
                    onClick={() => {
                      // if (isOpen) {
                      //   setIsOpen(false);
                      // }
                    }}
                    nodeTypes={nodeTypes}
                    edgeTypes={edgeTypes}
                    fitViewOptions={{ minZoom: -2 }}
                    minZoom={0.3}
                    onNodeClick={(event, element) => {
                      handleNodeClick(element)
                      //   setCurrElementID(element.id);
                    }}
                    onEdgeClick={(event, element) => {
                      if (isCopilotSelectionMode) {
                        setSelectedCopilotElements(prev => [
                          ...prev,
                          element,
                        ])
                        setIsCopilotSelectionMode(false)
                      }
                      else {
                        setCurrElementID(element.id)
                        setSelectedEdgeData(element)
                      }
                    }}
                    onPaneClick={(event) => {
                      setCurrElementID(null)
                    }}
                    deleteKeyCode={[]}
                    fitView
                    proOptions={{ hideAttribution: true }}
                  >
                    <Controls
                      position="bottom-left"
                      style={{
                        bottom: embedMode ? '60px' : '',
                      }}
                    >
                      <ControlButton onClick={() => onLayout('TB')}>
                        <FaSortAmountDown />
                      </ControlButton>
                      <ControlButton
                        onClick={() => {
                          setOpenSearchPopUp(true)
                          setIsNodeModalOpen(true)
                        }}
                      >
                        <FaSearch />
                      </ControlButton>
                      {openSearchPopUp && (
                        <NodeSearchPopup
                          nodes={elements.nodes}
                          onSelect={centerOnNode}
                          onClose={() => {
                            setOpenSearchPopUp(false)
                            setIsNodeModalOpen(false)
                          }}
                        />
                      )}
                      <ControlButton
                        onClick={deleteElement}
                        disabled={!currElementID}
                      >
                        <MdDelete />
                      </ControlButton>
                      <ControlButton
                        onClick={duplicateNode}
                        disabled={!currElementID} // Disable the button if no node is selected
                      >
                        <MdContentCopy />
                        {' '}
                        {/* Icon can be changed */}
                      </ControlButton>
                      <ControlButton onClick={undo} disabled={!canUndo}>
                        <FaUndo />
                      </ControlButton>
                      <ControlButton onClick={redo} disabled={!canRedo}>
                        <FaRedo />
                      </ControlButton>
                      <CopyPasteController
                        rfInstance={rfInstance}
                        selectionStateRef={selectionStateRef}
                      />
                    </Controls>

                    {isWindowTooSmall && (
                      <div
                        style={{
                          position: 'absolute',
                          right: 15,
                          top: 15,
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 4,
                          }}
                        >
                          <Tooltip side="left" content="Variables">
                            <IconButton
                              variant="solid"
                              color="#fff"
                              style={{
                                background: isOpen ? '#6366f1' : '#fff',
                                border: '1px solid #6366f1',
                                cursor: 'pointer',
                                zIndex: 1000,
                              }}
                              size="2"
                            >
                              <CurlyBraces
                                color={isOpen ? '#fff' : '#6366f1'}
                                size={16}
                              />
                            </IconButton>
                          </Tooltip>

                          <Tooltip side="left" content="Share Pathway">
                            <IconButton
                              onClick={() => {
                                setShowShareModal(true)
                              }}
                              variant="solid"
                              color="#fff"
                              style={{
                                background: showShareModal ? '#6366f1' : '#fff',
                                border: '1px solid #6366f1',
                                cursor: 'pointer',
                                zIndex: 1000,
                              }}
                              size="2"
                            >
                              <LuShare2
                                color={showShareModal ? '#fff' : '#6366f1'}
                                size={16}
                              />
                            </IconButton>
                          </Tooltip>

                          <Tooltip side="left" content="Call Logs">
                            <IconButton
                              onClick={() => {
                                setShowCallLogsModal(true)
                              }}
                              variant="solid"
                              color="#fff"
                              style={{
                                background: showCallLogsModal
                                  ? '#6366f1'
                                  : '#fff',
                                border: '1px solid #6366f1',
                                cursor: 'pointer',
                                zIndex: 1000,
                              }}
                              size="2"
                            >
                              <Grid
                                color={showCallLogsModal ? '#fff' : '#6366f1'}
                                size={16}
                              />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </div>
                    )}

                    <div
                      className="fixed right-8 z-50"
                      ref={popoverRef}
                      style={{ top: `${topOffset}px` }}
                    >
                      {isOpen && (
                        <div className="absolute z-10 mr-[150px] mt-12 w-[350px] bg-white border border-gray-300 rounded shadow-2xl right-0 max-h-[300px] overflow-y-auto">
                          {Object.entries(variables).length === 0
                            ? (
                                <div className="p-2.5 text-xs text-gray-500">
                                  No variables
                                </div>
                              )
                            : (
                                <div className="py-0.5">
                                  {Object.entries(variables).map(
                                    ([variable, nodeName]) => (
                                      <div
                                        key={variable}
                                        className="flex items-center justify-between px-2.5 py-1.5 hover:bg-gray-100 cursor-pointer"
                                      >
                                        <span className="text-xs font-medium">
                                          {variable}
                                        </span>
                                        <span className="text-2xs text-gray-500">
                                          {nodeName}
                                        </span>
                                      </div>
                                    ),
                                  )}
                                </div>
                              )}
                        </div>
                      )}
                    </div>
                    <Background variant="dots" gap={12} size={1} />
                    <Panel position="top left" className="space-y-3 p-0">
                      <div>
                        <Button
                          size="1"
                          color={embedMode ? clearTalkColor : 'violet'}
                          variant="solid"
                          // style={{ border: "1px solid #6e44ff" }}
                          // className="w-full bg-white hover:bg-gray-50 text-gray-800 py-1.5 px-2.5 border border-gray-300 rounded-sm shadow transition duration-150 ease-in-out flex items-center justify-center"
                          onClick={onAddNewNode}
                          style={{ cursor: 'pointer' }}
                        >
                          <Sparkles size={14} />
                          <p style={{ fontSize: 14 }}>Add new node</p>
                        </Button>
                      </div>
                      <div>
                        <GlobalPrompt
                          globalPrompt={globalPrompt}
                          setGlobalPrompt={setGlobalPrompt}
                        />
                      </div>
                      <div>
                        <FeatureFlagPathway
                          id={graphID}
                          featureFlag={featureFlag}
                          setIsSaving={setIsSaving}
                          setFeatureFlag={setFeatureFlag}
                        />
                      </div>
                      <div>
                        <PostCallActionsContext.Provider
                          value={{
                            pathwayId: graphID,
                            postCallActions: pathwayPostCallActions,
                            onPostCallActionsChange,
                            setIsSaving,
                            userIntegrations,
                            loadingIntegrations,
                            fetchUserIntegrations,
                          }}
                        >
                          <PostCallActionsPathway />
                        </PostCallActionsContext.Provider>
                      </div>
                    </Panel>
                  </ReactFlow>
                </div>
              )}
            </div>

            {showEdgeSidebar && (
              <div>
                <EdgeSideBar
                  key={selectedEdgeData?.id}
                  setIsSideBarOpen={setShowEdgeSidebar}
                  edgeData={selectedEdgeData}
                  setEdgeData={setSelectedEdgeData}
                />
              </div>
            )}

            <NodeTypeSlideOut
              isOpen={isNodeTypeSlideOutOpen}
              onClose={() => {
                setIsNodeTypeSlideOutOpen(false)
                setCurrentOpenNodeID(null)
              }}
              onSelectNodeType={handleSelectNodeType}
            />

          </FlowContext.Provider>
        </LibrarySidebarContext.Provider>
      </SimulationsProvider>
    </ReactFlowProvider>
  )
}
