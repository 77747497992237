import { Button } from '@/components/ui/button'
import { ErrorMessage } from '@hookform/error-message'
import { useAuth } from 'hooks/useAuth'
import { UserIcon } from 'lucide-react'
import { useEffect, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import PhoneInput from 'react-phone-input-2'
import { ErrorText } from './ErrorText'
import Label from './Label'
import 'react-phone-input-2/lib/style.css'

export default function ReactPhoneInput({
  fieldName,
  label = null,
  hideLabel = false,
  description = null,
  hideAutoFormatToggle = false,
  required = true,
  showAutoFill = true,
  dark = false,
}) {
  const { user } = useAuth()
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext()

  const [formatInput, setFormatInput] = useState(true)
  const [storedPhoneNumber, setStoredPhoneNumber] = useState(null)

  useEffect(() => {
    const storedNumber = user?.user?.phone_number
    if (storedNumber) {
      setStoredPhoneNumber(storedNumber)
    }
  }, [user])

  const handleAutoFill = () => {
    if (storedPhoneNumber) {
      // remove + if it contains a +
      const phoneNumber = storedPhoneNumber.replace('+', '')
      setValue(fieldName, phoneNumber)
    }
  }

  return (
    <div className="flex flex-col gap-1.5 w-full">
      {!hideLabel && (
        <Label className="text-xs font-medium w-full">
          {label || 'Phone number'}
        </Label>
      )}
      {description && (
        <p className="text-2xs text-gray-500 font-normal">{description}</p>
      )}
      <Controller
        control={control}
        name={fieldName}
        render={({ field: { onChange, value } }) =>
          formatInput
            ? (
                <PhoneInput
                  value={typeof value === 'number' ? value.toString() : value}
                  country="us"
                  onChange={onChange}
                  inputProps={{
                    required,
                  }}
                  inputStyle={{
                    width: '100%',
                    height: '35px',
                    border: '1px solid #eeeeee',
                    backgroundColor: dark ? '#E0E0DD' : '#fbfbfa',
                  }}
                />
              )
            : (
                <input
                  type="text"
                  value={typeof value === 'number' ? value.toString() : value}
                  onChange={onChange}
                  className={`w-full h-6 border border-gray-300 rounded shadow-sm ${dark ? 'focus:ring-black focus:border-black' : 'focus:ring-blue-500 focus:border-blue-500'} p-1.5`}
                />
              )}
      />
      {showAutoFill && storedPhoneNumber && (
        <Button
          onClick={handleAutoFill}
          type="button"
          variant="outline"
          className="mt-1.5 text-xs"
        >
          <UserIcon className="w-2.5 h-2.5 mr-1.5" />
          Use my phone number
        </Button>
      )}
      {!hideAutoFormatToggle && (
        <button
          type="button"
          onClick={() => setFormatInput(prev => !prev)}
          className={`text-xs ${dark ? 'text-black font-taurus' : 'text-blue-500'} mt-1.5`}
        >
          Toggle auto-formatting
        </button>
      )}
      <ErrorMessage
        errors={errors}
        name={fieldName}
        render={({ message }) => <ErrorText message={message} />}
      />
    </div>
  )
}
