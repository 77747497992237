import { useEffect, useState } from 'react'
import { ThemeProvider } from 'styled-components'

const radii = {
  lg: '20px',
  md: '12px',
  sm: '4px',
  xs: '2.5px',
}

const colors = {
  blurple: '#5A36EC',
  black100: '#232323',
  black200: '#1E1E1E',
  grey100: '#686767',
  grey200: '#676775', // label
  grey300: '#464646',
  beige100: '#FBFBFA',
  beige200: '#F0F0EE',
  beige300: '#EEEEEE',
  beige400: '#f0f0ed',
  beige500: '#a8a8a1',
  blue100: '#DAEBFF',
  blue200: '#B9DEFF',
  blue300: '#146DD6',
  yellow100: '#FFFBEB',
  yellow200: '#FFECA2',
  yellow300: '#B08C00',
  red100: '#FFD8D8',
  red200: '#FFAAAA',
  red300: '#D11919',
}

const light = {
  colors: {
    info: {
      bg: colors.blue100,
      outline: colors.blue200,
      text: colors.blue300,
    },
    warning: {
      bg: colors.yellow100,
      outline: colors.yellow200,
      text: colors.yellow300,
    },
    error: {
      bg: colors.red100,
      outline: colors.red200,
      text: colors.red300,
    },
    label: colors.grey200,
    text: colors.black100,
    heading: colors.black100,
    subheading: colors.grey100,
    input: {
      bg: colors.beige100,
      border: colors.beige300,
      value: colors.black100,
      placeholder: colors.beige500,
    },
    switch: {
      on: colors.blurple,
      off: colors.beige400,
    },
    button: {
      outline: {
        bg: 'transparent',
        outline: 'none',
        text: 'white',
      },
      solid: {
        bg: colors.blurple,
        outline: 'none',
        text: 'white',
      },
    },
    background: colors.beige100,
  }, 
  radii,
}

const dark = {  
  radii,
}

export function ThemeWrapper({ children }) {
  const [mode, setMode] = useState('light')

  useEffect(() => {
    const storedTheme = localStorage.getItem('theme')
    if (storedTheme) {
      setMode(storedTheme)
    }
  }, [])

  const theme = mode === 'light' ? light : dark

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}
