// /components/analytics/CallsChart.jsx

import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

/**
 * You can keep this config local to the chart
 * or export it from a separate config file.
 */
const chartConfig = {
  calls: {
    label: 'Calls',
    color: 'rgb(37, 99, 235)', // Vercel blue
    gradient: ['rgb(239 246 255)', 'rgb(37, 99, 235)'],
  },
}

function CustomTooltip({ active, payload, label }) {
  if (active && payload && payload.length) {
    const date = new Date(label)
    return (
      <div className="bg-white border border-gray-200 rounded-sm p-1.5 shadow-lg">
        <p className="font-semibold text-gray-800">
          {date.toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
          })}
        </p>
        <p className="text-blue-600">
          {chartConfig.calls.label}
          :
          {payload[0].value}
        </p>
      </div>
    )
  }
  return null
}

function formatXAxis(tickItem) {
  const date = new Date(tickItem)
  return date.toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
  })
}

/**
 * If you prefer, you can export these smaller
 * chart functions individually.
 */
function renderLineChart(data) {
  // We condense data into 4-hour intervals to avoid overcrowding
  const condenseDataToFourHourIntervals = (data) => {
    const result = []
    const interval = 4
    const sortedData = [...data].sort((a, b) => a.hour - b.hour)

    for (let i = 0; i < sortedData.length; i += interval) {
      const slice = sortedData.slice(i, i + interval)
      if (slice.length > 0) {
        const aggregatedCount = slice.reduce((sum, item) => sum + item.count, 0)
        result.push({
          hour: slice[0].hour,
          count: aggregatedCount,
        })
      }
    }
    return result
  }
  const condensedData = condenseDataToFourHourIntervals(data)

  return (
    <AreaChart
      data={condensedData}
      margin={{ top: 10, right: 30, left: 10, bottom: 0 }}
    >
      <defs>
        <linearGradient id="colorCalls" x1="0" y1="0" x2="0" y2="1">
          <stop
            offset="5%"
            stopColor={chartConfig.calls.gradient[1]}
            stopOpacity={0.8}
          />
          <stop
            offset="95%"
            stopColor={chartConfig.calls.gradient[0]}
            stopOpacity={0.2}
          />
        </linearGradient>
      </defs>
      <XAxis
        dataKey="hour"
        padding={{ left: 20, right: 0 }}
        axisLine={{ stroke: '#f8f8f8', strokeWidth: 1 }}
        tickLine={false}
        tick={{ fill: '#6B7280', fontSize: 9, fontWeight: 500, dy: 10 }}
        tickFormatter={(value, index) => {
          const isStart = index === 0
          const isEnd = index === condensedData.length - 1
          const isMidpoint = index === Math.floor(condensedData.length / 2)
          if (isStart || isEnd || isMidpoint) {
            return new Date(value).toLocaleString('en-US', {
              month: 'short',
              day: 'numeric',
              hour: 'numeric',
              hour12: true,
            })
          }
          return ''
        }}
        interval={0}
      />
      <YAxis
        axisLine={{ stroke: '#f8f8f8', strokeWidth: 1 }}
        tickLine={false}
        tick={{ fill: '#6B7280', fontSize: 9, fontWeight: 500, dx: -10 }}
        tickCount={5}
        domain={['auto', dataMax => dataMax * 1.1]}
        width={40}
        label={{
          value: 'Calls',
          angle: -90,
          position: 'insideLeft',
          style: { textAnchor: 'middle', fill: '#6B7280', fontSize: 10 },
          offset: 8,
          dx: -6,
        }}
      />
      <Tooltip
        content={<CustomTooltip />}
        cursor={{ stroke: '#4b0082', strokeWidth: 1, strokeOpacity: 0.2 }}
      />
      <Area
        type="monotone"
        dataKey="count"
        stroke={chartConfig.calls.color}
        strokeWidth={2}
        fillOpacity={1}
        fill="url(#colorCalls)"
      />
    </AreaChart>
  )
}

function renderBarChart(data) {
  return (
    <BarChart
      data={data}
      margin={{ top: 10, right: 10, left: 10, bottom: 0 }}
    >
      <XAxis
        dataKey="hour"
        axisLine={{ stroke: '#E5E7EB' }}
        tickLine={false}
        tick={{ fill: '#4B5563', fontSize: 10 }}
        tickFormatter={formatXAxis}
        interval="preserveStart"
      />
      <YAxis
        axisLine={{ stroke: '#E5E7EB' }}
        tickLine={false}
        tick={{ fill: '#4B5563', fontSize: 10 }}
        width={30}
      />
      <Tooltip content={<CustomTooltip />} />
      <Bar dataKey="count" fill={chartConfig.calls.color} />
    </BarChart>
  )
}

function renderDensityChart() {
  return (
    <div className="flex items-center justify-center h-full text-gray-400 text-xs font-semibold">
      No density chart implemented
    </div>
  )
}

/**
 * Parent chart component that decides which type to render.
 */
export default function CallsChart({ dailyData = [], chartType = 'line' }) {
  return (
    <ResponsiveContainer width="100%" height="100%">
      {chartType === 'line' && renderLineChart(dailyData)}
      {chartType === 'bar' && renderBarChart(dailyData)}
      {chartType === 'density' && renderDensityChart()}
    </ResponsiveContainer>
  )
}
