import Tooltip from '@mui/material/Tooltip'
import { TooltipText } from '_components/Text'
import Switch from 'components/core/Switch'

export default function IgnoreButtonPress({ fieldName }) {
  const longText = (
    <span>
      To ignore button presses on your phone call, set
      {' '}
      <strong>ignore_button_press</strong>
      {' '}
      to
      <strong>true</strong>
      . This will
      not apply on 'Amazon Connect' nodes.
    </span>
  )
  return (
    <Tooltip>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <TooltipText tooltip={longText} tooltipClassName="w-[300px]">
          Ignore Button Press
        </TooltipText>
        <Switch sx={{ m: 5 }} fieldName={fieldName} />
      </div>
    </Tooltip>
  )
}
