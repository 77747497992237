import { addToast, Button, Form, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, toast } from '@heroui/react'
import { Icon } from '@iconify/react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { $fetch } from 'utils/fetch'

export default function CreateMemoryStoreModal({ isOpen, onOpenChange, onSuccess }) {
  const [isSubmitting, setIsSubmitting] = useState(false)

  const queryClient = useQueryClient()

  const createMemoryMutation = useMutation({
    mutationFn: async (memoryName) => {
      const response = await $fetch('/v1/memory/create', {
        method: 'POST',
        body: JSON.stringify({ name: memoryName }),
      })
      return response.data
    },
    onSuccess: (data) => {
      console.log("Memory creation response:", data);
      queryClient.invalidateQueries(['memories'])
      addToast({
        title: 'Memory created successfully',
      })
      onOpenChange(false)
      if (data && data.memory) {
        console.log("Calling onSuccess with memory:", data.memory);
        onSuccess?.(data.memory)
      } else {
        console.warn("Memory data structure not as expected:", data);
        // Try to find the memory object in the response
        const memoryData = data?.memory || data || { id: "unknown", name: "New Memory" };
        onSuccess?.(memoryData)
      }
    },
    onError: (error) => {
      console.error(error)
    },
  })

  const onSubmit = async (e) => {
    e.preventDefault()
    setIsSubmitting(true)

    const formData = Object.fromEntries(new FormData(e.currentTarget))

    try {
      createMemoryMutation.mutate(formData.name)
    }
    catch (error) {
      console.error(error)
    }
    finally {
      setIsSubmitting(false)
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      placement="top-center"
      classNames={{
        base: 'max-w-xl',
        body: 'flex flex-col gap-5 w-full',
      }}
      radius="sm"
    >
      <ModalContent>
        <Form onSubmit={onSubmit}>
          <ModalHeader className="flex flex-col gap-1">
            <h3 className="text-xl font-semibold">Create New Memory Store</h3>
            <p className="text-default-500 text-sm">
              Memory stores help maintain context across conversations and interactions
            </p>
          </ModalHeader>

          <ModalBody>
            <div className="space-y-4">
              <Input
                isRequired
                name="name"
                label="Memory Store Name"
                placeholder="e.g., Customer Support, Sales Team, Technical Support"
                variant="bordered"
                startContent={<Icon icon="lucide:brain" className="text-default-400" />}
              />

              {/* <Textarea
                isRequired
                name="description"
                label="Description"
                placeholder="Describe what this memory store will be used for..."
                variant="bordered"
                minRows={3}
                startContent={<Icon icon="lucide:file-text" className="text-default-400 mt-2" />}
              /> */}

              <div className="bg-default-50 p-4 rounded-lg space-y-3">
                <h4 className="text-sm font-medium flex items-center gap-2">
                  <Icon icon="lucide:info" className="text-default-500" />
                  About Memory Stores
                </h4>
                <ul className="text-sm text-default-500 space-y-2">
                  <li>• Memory stores maintain context across multiple conversations</li>
                  <li>• They help AI assistants recall past interactions and information</li>
                  <li>• Perfect for team-specific knowledge and customer history</li>
                  <li>• Supports multiple users and concurrent access</li>
                  <li>• Memory stores are not shared between users</li>
                </ul>
              </div>
            </div>
          </ModalBody>

          <ModalFooter>
            <Button
              variant="bordered"
              onPress={() => onOpenChange(false)}
              disabled={isSubmitting}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              type="submit"
              isLoading={isSubmitting}
              startContent={!isSubmitting && <Icon icon="lucide:plus" />}
            >
              Create Memory Store
            </Button>
          </ModalFooter>
        </Form>
      </ModalContent>
    </Modal>
  )
}
