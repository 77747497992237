import { ErrorMessage } from '@hookform/error-message'
import { Check, ChevronDown, ChevronUp, Search, X } from 'lucide-react'
import { useEffect, useRef, useState } from 'react'
import { useFormContext } from 'react-hook-form'

function MultiSelect({
  fieldName,
  label,
  options,
  description,
  placeholder = 'Select webhook events...',
  ...props
}) {
  const {
    register,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext()

  const [isOpen, setIsOpen] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const dropdownRef = useRef(null)
  const selectedValues = watch(fieldName) || []

  const filteredOptions = options.filter(option =>
    option.label.toLowerCase().includes(searchTerm.toLowerCase()),
  )

  const toggleOption = (value) => {
    const updatedValues = selectedValues.includes(value)
      ? selectedValues.filter(v => v !== value)
      : [...selectedValues, value]
    setValue(fieldName, updatedValues)
  }

  const removeOption = (value, event) => {
    event.stopPropagation()
    const updatedValues = selectedValues.filter(v => v !== value)
    setValue(fieldName, updatedValues)
  }

  const toggleDropdown = () => setIsOpen(!isOpen)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <div className="flex flex-col gap-1.5 relative w-full" ref={dropdownRef}>
      {label && (
        <label className="text-xs font-medium text-gray-700">{label}</label>
      )}

      <p className="text-xs text-gray-500">{description}</p>
      <div
        className="flex flex-wrap gap-1.5 p-2.5 border rounded bg-white w-full min-h-[32px] items-center cursor-pointer relative"
        onClick={toggleDropdown}
      >
        <div className="flex-grow flex flex-wrap gap-1.5">
          {selectedValues.map(value => (
            <div
              key={value}
              className="flex items-center bg-gray-100 rounded-full px-2 py-0.5 text-xs"
              onClick={e => e.stopPropagation()}
            >
              {options.find(opt => opt.value === value)?.label}
              <button onClick={e => removeOption(value, e)} className="ml-1.5">
                <X size={14} />
              </button>
            </div>
          ))}
          {selectedValues.length === 0 && (
            <span className="text-gray-400 text-xs">{placeholder}</span>
          )}
        </div>
        <button
          type="button"
          className="absolute right-1.5 top-1/2 transform -translate-y-1/2 text-gray-400"
        >
          {isOpen ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
        </button>
      </div>
      {isOpen && (
        <div className="absolute top-[calc(90%+4px)] z-10 w-full bg-white border rounded shadow-lg max-h-36 overflow-auto">
          <div className="sticky top-0 bg-white p-1.5 border-b">
            <div className="relative flex gap-1.5">
              <Search
                className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400"
                size={18}
              />
              <input
                type="text"
                placeholder="Search..."
                className="w-full pl-6 pr-2.5 py-1.5 border rounded text-xs"
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
                onClick={e => e.stopPropagation()}
              />
            </div>
          </div>
          {filteredOptions.map(option => (
            <div
              key={option.value}
              className="flex items-center px-2.5 py-1.5 hover:bg-gray-100 cursor-pointer"
              onClick={() => toggleOption(option.value)}
            >
              <div className="w-3 h-3 border border-gray-300 rounded-sm mr-2 flex items-center justify-center">
                {selectedValues.includes(option.value) && (
                  <Check size={16} className="text-blue-500" />
                )}
              </div>
              <span>{option.label}</span>
            </div>
          ))}
        </div>
      )}
      <input type="hidden" {...register(fieldName)} value={selectedValues} />
      <ErrorMessage
        errors={errors}
        name={fieldName}
        render={({ message }) => (
          <p className="text-red-500 text-xs">{message}</p>
        )}
      />
    </div>
  )
}

export default MultiSelect
