import { Button, ButtonGroup, Link, Tooltip } from '@heroui/react'
import { ChevronLeft, MessageSquare, Phone, Send } from 'lucide-react'
import ImportPresetModal from './ImportPresetModal'
import SavePresetModal from './SavePresetModal'

export default function SendCallCTA({
  type = 'call',
  ...props
}) {
  const isInbound = type === 'inbound'
  const isBatch = type === 'batch'
  const isCall = type === 'call'
  const isSMS = type === 'sms'
  const color = 'primary'

  const getButtonLabel = () => {
    if (isInbound)
      return 'Save Inbound Call Changes'
    if (isSMS)
      return 'Save SMS Changes'
    if (isBatch)
      return 'Send Batch'
    return 'Send Call'
  }

  return (
    <div className="flex fixed bottom-0 pr-20 shrink-0 h-[54px] px-2 w-full bg-white items-center justify-between border-t border-gray-200 z-20">
      <ButtonGroup className="preset-group">
        {!isCall && (
          <Tooltip content={isInbound ? 'Back to Phone Numbers' : 'Back to Batches'}>
            <Button
              as={Link}
              isIconOnly
              color={color}
              variant="light"
              href={isInbound || isSMS ? '/dashboard/phone-numbers' : '/dashboard/batches'}
              radius="none"
            >
              <ChevronLeft />
            </Button>
          </Tooltip>
        )}
        <ImportPresetModal color={color} />
        <SavePresetModal color={color} />
      </ButtonGroup>

      <div className="flex items-center gap-4">
        {props.children}

        <div className="relative inline-block" id="rainbow-button-wrapper">
          <Button
            type="submit"
            radius="none"
            color={color}
            disableRipple
            className="rainbow-button !h-9"
            {...props}
          >
            {getButtonLabel()}
            {isCall && <Send size={16} />}
            {isSMS && <MessageSquare size={16} />}
            {isInbound && <Phone size={16} />}
          </Button>
          <style jsx>
            {`#rainbow-button-wrapper {
                position: relative;
              }
              
              #rainbow-button-wrapper::before,
              #rainbow-button-wrapper::after,
              #rainbow-button-wrapper .before-elem,
              #rainbow-button-wrapper .after-elem {
                content: '';
                position: absolute;
                inset: 0;
                transition: all 0.3s ease-out;
                opacity: 0;
              }
              
              #rainbow-button-wrapper::before {
                background-color: #f59e0b; /* Yellow */
                z-index: 1;
              }
              
              #rainbow-button-wrapper::after {
                background-color: #f97316; /* Orange */
                z-index: 2;
                transition-delay: 0.075s;
              }
              
              #rainbow-button-wrapper .rainbow-button {
                position: relative;
                z-index: 5;
              }
              
              #rainbow-button-wrapper:hover::before {
                opacity: 1;
                transform: translate(-8px, 8px);
              }
              
              #rainbow-button-wrapper:hover::after {
                opacity: 1;
                transform: translate(-6px, 6px);
              }
              
              #rainbow-button-wrapper::before {
                content: '';
                position: absolute;
                inset: 0;
                background-color: #3b82f6; /* Blue */
                z-index: 3;
                transition-delay: 0.15s;
                opacity: 0;
              }
              
              #rainbow-button-wrapper:hover::before {
                opacity: 1;
                transform: translate(-4px, 4px);
              }
            `}
          </style>
        </div>
      </div>
    </div>
  )
}
