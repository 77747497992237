import { Button } from '@/components/ui/button'
import { Dialog, Transition } from '@headlessui/react'
import React from 'react'
import { PiSpinnerGapLight } from 'react-icons/pi'
import VersionCompare from '../copilot/VersionCompare'

function PromoteConfirmModal({
  isOpen,
  onClose,
  onConfirm,
  isPublishing,
  beforeVersion,
  afterVersion,
  graphID,
}) {
  const [showDiff, setShowDiff] = React.useState(true)

  return (
    <Transition appear show={isOpen} as={React.Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={onClose}
      >
        <div className="min-h-screen px-2.5 text-center">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-4xl p-4 my-5 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-xl">
              <Dialog.Title
                as="h3"
                className="text-sm font-medium leading-4 text-gray-900 flex items-center gap-1.5"
              >
                <span>Promote to Production</span>
                <span className="text-xs font-normal text-gray-500">
                  v
                  {afterVersion}
                  {' '}
                  →
                  {' '}
                  {beforeVersion === 0 ? 'Production' : `v${beforeVersion}`}
                </span>
              </Dialog.Title>

              <div className="mt-2.5 space-y-2.5">
                <p className="text-xs text-gray-600">
                  Are you sure you want to promote your staging pathway to
                  production?
                </p>
                <p className="text-xs text-gray-500">
                  Your staging environment will remain available for further
                  changes.
                </p>
                <div className="p-2 bg-gray-50 rounded-">
                  <p className="text-2xs text-gray-600">
                    The current production version will be saved under
                    'Previously Published Pathways'
                  </p>
                </div>

                <div className="flex items-center gap-1.5">
                  <Button
                    variant="outline"
                    onClick={() => setShowDiff(!showDiff)}
                    className="w-full"
                  >
                    {showDiff ? 'Hide Changes' : 'View Changes'}
                  </Button>
                </div>

                {showDiff && (
                  <VersionCompare
                    beforeVersion={beforeVersion}
                    afterVersion={afterVersion}
                    graphID={graphID}
                    className="h-[400px]"
                  />
                )}
              </div>

              <div className="mt-4 flex justify-end space-x-2">
                <button
                  type="button"
                  className="inline-flex justify-center px-2.5 py-1.5 text-xs font-medium text-gray-700 bg-gray-100 border border-transparent rounded hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-gray-500"
                  onClick={onClose}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="inline-flex items-center justify-center px-2.5 py-1.5 text-xs font-medium text-white bg-indigo-600 border border-transparent rounded hover:bg-indigo-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-indigo-500"
                  onClick={onConfirm}
                  disabled={isPublishing}
                >
                  {isPublishing
                    ? (
                        <>
                          <PiSpinnerGapLight className="animate-spin w-3 h-3 mr-1.5" />
                          Promoting...
                        </>
                      )
                    : (
                        'Promote to Production'
                      )}
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}

export default PromoteConfirmModal
