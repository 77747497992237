import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { Switch } from '@headlessui/react'
import { useAuth } from 'hooks/useAuth'
import { useCallback, useEffect, useState } from 'react'

export default function WarmTransferOptions({
  warmTransferFields,
  setWarmTransferFields,
}) {
  const [isWarmTransferDisabled, setIsWarmTransferDisabled] = useState(false)
  const { org } = useAuth()

  const updateField = useCallback(
    (field, value) => {
      setWarmTransferFields(prev => ({ ...prev, [field]: value }))
    },
    [setWarmTransferFields],
  )

  useEffect(() => {
    const fetchUserData = async () => {
      if (org?.org_type !== 'enterprise') {
        setIsWarmTransferDisabled(true)
        updateField('isEnabled', false)
      }
      else {
        if (warmTransferFields.optimizeForIVR === undefined) {
          updateField('optimizeForIVR', true)
        }
        if (warmTransferFields.useVoicemailMessage === undefined) {
          updateField('useVoicemailMessage', true)
          updateField(
            'voicemailMessage',
            'Hey, the agent we tried to dial is not available at the moment. Please try calling us back again later. I\'ll be ending the call now, thank you!',
          )
        }
      }
    }

    fetchUserData()
  }, [updateField])

  return (
    <div className="border p-2.5 rounded shadow-sm">
      <div className="flex items-center justify-between">
        <h3 className="text-base">Warm Transfer</h3>
        <Switch
          checked={warmTransferFields.isEnabled}
          onChange={value => updateField('isEnabled', value)}
          disabled={isWarmTransferDisabled}
          className={`${
            warmTransferFields.isEnabled ? 'bg-indigo-600' : 'bg-gray-200'
          } relative inline-flex h-4 w-7 items-center rounded-full ${
            isWarmTransferDisabled ? 'opacity-50 cursor-not-allowed' : ''
          }`}
        >
          <span className="sr-only">Enable Warm Transfer</span>
          <span
            className={`${
              warmTransferFields.isEnabled ? 'translate-x-4' : 'translate-x-0.5'
            } inline-block h-2.5 w-2.5 transform rounded-full bg-white transition`}
          />
        </Switch>
      </div>
      <p className="text-xs text-gray-600 mt-1.5">
        Enable warm transfer options to configure how the call is transferred to
        a live agent.
      </p>
      {isWarmTransferDisabled && (
        <p className="text-xs text-red-500 mt-1.5">
          Warm transfers are not available for your account type.
        </p>
      )}
      {warmTransferFields.isEnabled && !isWarmTransferDisabled && (
        <p className="text-xs text-gray-600 mt-1.5">
          When a customer requests a transfer, a proxy agent (a second AI agent)
          calls the transfer number. The live agent can talk to the customer,
          ask questions about the call, and then merge the calls. The agent
          merges the calls and introduces the two parties to each other.
        </p>
      )}
      {warmTransferFields.isEnabled && !isWarmTransferDisabled && (
        <div className="mt-4 space-y-4">
          <div>
            <div className="flex items-center justify-between">
              <Label
                htmlFor="use-custom-proxy-number"
                className="text-xs font-medium"
              >
                Proxy Agent Number
              </Label>
              <Switch
                checked={warmTransferFields.useCustomFromNumber}
                onChange={value => updateField('useCustomFromNumber', value)}
                className={`${
                  warmTransferFields.useCustomFromNumber
                    ? 'bg-indigo-600'
                    : 'bg-gray-200'
                } relative inline-flex h-4 w-7 items-center rounded-full`}
              >
                <span className="sr-only">Use Custom Proxy Number</span>
                <span
                  className={`${
                    warmTransferFields.useCustomFromNumber
                      ? 'translate-x-4'
                      : 'translate-x-0.5'
                  } inline-block h-2.5 w-2.5 transform rounded-full bg-white transition`}
                />
              </Switch>
            </div>
            <p className="text-xs text-gray-500 mt-0.5">
              The phone number that the proxy agent will call from. A default
              number is used if not enabled.
            </p>
            {warmTransferFields.useCustomFromNumber && (
              <Input
                id="proxy-number"
                value={warmTransferFields.fromNumber}
                onChange={e => updateField('fromNumber', e.target.value)}
                placeholder="Enter the proxy agent's phone number"
                className="mt-1.5"
              />
            )}
          </div>
          <div>
            <div className="flex items-center justify-between">
              <Label htmlFor="agent-prompt" className="text-xs font-medium">
                Live Agent Briefing Prompt
              </Label>
              <div className="flex items-center space-x-1.5">
                <Switch
                  checked={warmTransferFields.isAgentPromptStatic}
                  onChange={value =>
                    updateField('isAgentPromptStatic', value)}
                  className={`${
                    warmTransferFields.isAgentPromptStatic
                      ? 'bg-indigo-600'
                      : 'bg-gray-200'
                  } relative inline-flex h-4 w-7 items-center rounded-full`}
                >
                  <span className="sr-only">Use Static Text</span>
                  <span
                    className={`${
                      warmTransferFields.isAgentPromptStatic
                        ? 'translate-x-4'
                        : 'translate-x-0.5'
                    } inline-block h-2.5 w-2.5 transform rounded-full bg-white transition`}
                  />
                </Switch>
                <Label htmlFor="agent-prompt-static" className="text-xs">
                  Static Text
                </Label>
              </div>
            </div>
            <p className="text-xs text-gray-500 mt-0.5">
              This prompt is used to brief the live agent about the call before
              merging.
            </p>
            <textarea
              id="agent-prompt"
              value={warmTransferFields.agentPrompt}
              onChange={e => updateField('agentPrompt', e.target.value)}
              placeholder={
                warmTransferFields.isAgentPromptStatic
                  ? 'Enter the exact text to brief the live agent'
                  : 'Enter the prompt to brief the live agent'
              }
              className="border border-gray-300 p-1.5 rounded w-full mt-0.5 min-h-[80px]"
            />
          </div>

          <div>
            <div className="flex items-center justify-between">
              <Label htmlFor="use-hold-music" className="text-xs font-medium">
                Hold Music
              </Label>
              <Switch
                checked={warmTransferFields.useCustomHoldMusic}
                onChange={value => updateField('useCustomHoldMusic', value)}
                className={`${
                  warmTransferFields.useCustomHoldMusic
                    ? 'bg-indigo-600'
                    : 'bg-gray-200'
                } relative inline-flex h-4 w-7 items-center rounded-full`}
              >
                <span className="sr-only">Use Hold Music</span>
                <span
                  className={`${
                    warmTransferFields.useCustomHoldMusic
                      ? 'translate-x-4'
                      : 'translate-x-0.5'
                  } inline-block h-2.5 w-2.5 transform rounded-full bg-white transition`}
                />
              </Switch>
            </div>
            <p className="text-xs text-gray-500 mt-0.5">
              When enabled, the user will hear hold music while the proxy agent
              briefs the live agent and waits for them to be ready.
            </p>
            {warmTransferFields.useCustomHoldMusic && (
              <Input
                id="hold-music-url"
                value={warmTransferFields.holdMusicUrl}
                onChange={e => updateField('holdMusicUrl', e.target.value)}
                placeholder="Enter URL for hold music"
                className="mt-1.5"
              />
            )}
          </div>
          <div>
            <div className="flex items-center justify-between">
              <Label
                htmlFor="merge-call-prompt"
                className="text-xs font-medium"
              >
                Merge Call Prompt
              </Label>
              <div className="flex items-center space-x-1.5">
                <Switch
                  checked={warmTransferFields.isMergeCallPromptStatic}
                  onChange={value =>
                    updateField('isMergeCallPromptStatic', value)}
                  className={`${
                    warmTransferFields.isMergeCallPromptStatic
                      ? 'bg-indigo-600'
                      : 'bg-gray-200'
                  } relative inline-flex h-4 w-7 items-center rounded-full`}
                >
                  <span className="sr-only">Use Static Text</span>
                  <span
                    className={`${
                      warmTransferFields.isMergeCallPromptStatic
                        ? 'translate-x-4'
                        : 'translate-x-0.5'
                    } inline-block h-2.5 w-2.5 transform rounded-full bg-white transition`}
                  />
                </Switch>
                <Label htmlFor="merge-call-prompt-static" className="text-xs">
                  Static Text
                </Label>
              </div>
            </div>
            <p className="text-xs text-gray-500 mt-0.5">
              This prompt generates the message said to both the user and the
              live agent when merging the call. If set as static text, the exact
              sentence will be used.
            </p>
            <textarea
              id="merge-call-prompt"
              value={warmTransferFields.mergeCallPrompt}
              onChange={e => updateField('mergeCallPrompt', e.target.value)}
              placeholder={
                warmTransferFields.isMergeCallPromptStatic
                  ? 'Enter the exact text to be said when merging the call'
                  : 'Enter the prompt to be said when merging the call'
              }
              className="border border-gray-300 p-1.5 rounded w-full mt-0.5 min-h-[80px]"
            />
          </div>

          <div>
            <div className="flex items-center justify-between">
              <Label htmlFor="optimize-ivr" className="text-xs font-medium">
                Optimize for IVR/Queue Systems
              </Label>
              <Switch
                checked={warmTransferFields.optimizeForIVR}
                onChange={value => updateField('optimizeForIVR', value)}
                className={`${
                  warmTransferFields.optimizeForIVR
                    ? 'bg-indigo-600'
                    : 'bg-gray-200'
                } relative inline-flex h-4 w-7 items-center rounded-full`}
              >
                <span className="sr-only">Optimize for IVR/Queue Systems</span>
                <span
                  className={`${
                    warmTransferFields.optimizeForIVR
                      ? 'translate-x-4'
                      : 'translate-x-0.5'
                  } inline-block h-2.5 w-2.5 transform rounded-full bg-white transition`}
                />
              </Switch>
            </div>
            <p className="text-xs text-gray-500 mt-0.5">
              Enables wait_for_greeting, ivr_mode, and
              blocks interruptions when transferring to IVR systems or if the
              agent has to wait in a queue.
            </p>
          </div>

          <div>
            <div className="flex items-center justify-between">
              <Label
                htmlFor="voicemail-message"
                className="text-xs font-medium"
              >
                Voicemail Message
              </Label>
              <Switch
                checked={warmTransferFields.useVoicemailMessage}
                onChange={value => updateField('useVoicemailMessage', value)}
                className={`${
                  warmTransferFields.useVoicemailMessage
                    ? 'bg-indigo-600'
                    : 'bg-gray-200'
                } relative inline-flex h-4 w-7 items-center rounded-full`}
              >
                <span className="sr-only">Use Voicemail Message</span>
                <span
                  className={`${
                    warmTransferFields.useVoicemailMessage
                      ? 'translate-x-4'
                      : 'translate-x-0.5'
                  } inline-block h-2.5 w-2.5 transform rounded-full bg-white transition`}
                />
              </Switch>
            </div>
            <p className="text-xs text-gray-500 mt-0.5">
              Message to be played before hanging up if the transfer to the
              agent fails.
              {' '}
              {!warmTransferFields.useVoicemailMessage
              && 'Using default message.'}
            </p>
            {warmTransferFields.useVoicemailMessage && (
              <textarea
                id="voicemail-message"
                value={warmTransferFields.voicemailMessage}
                onChange={e =>
                  updateField('voicemailMessage', e.target.value)}
                placeholder="Hey, the agent we tried to dial is not available at the moment. Please try calling us back again later. I'll be ending the call now, thank you!"
                className="border border-gray-300 p-1.5 rounded w-full mt-0.5 min-h-[80px]"
              />
            )}
          </div>

          <p className="text-xs text-gray-500 mt-2.5">
            Note: You can reference variables from any call in these prompts.
          </p>
        </div>
      )}
    </div>
  )
}
