import { AnimatePresence, motion } from 'framer-motion'
import {
  Clipboard,
  Copy,
  Edit2,
  Folder,
  MoreVertical,
  Trash2,
} from 'lucide-react'
import { useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import EditPathwayDetailsDialog from './EditPathwayDetailsDialog'

function PathwayCard({
  id,
  name,
  description,
  setCurrId,
  setOpenDelete,
  setIsDuplicate,
  setOpen,
  folder,
  onMovePathway,
  setRefreshPathways,
}) {
  const navigate = useNavigate()
  const [isIdExpanded, setIsIdExpanded] = useState(false)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const popoverRef = useRef(null)
  const moreButtonRef = useRef(null)

  const [editPathwayDetails, setEditPathwayDetails] = useState(false)

  const handleEditPathway = () => {
    navigate(`/dashboard/convo-pathways?id=${id}&view=splash`)
  }

  const handleOpenMore = (e) => {
    e.stopPropagation()
    setIsDropdownOpen(!isDropdownOpen)
  }

  const handleCopyId = (e) => {
    e.stopPropagation()
    navigator.clipboard.writeText(id)
    toast.success('ID copied to clipboard! Use in API as `pathway_id`', {
      position: 'bottom-right',
    })
  }

  const handleEditPathwayDetails = (e) => {
    e.stopPropagation()
    setIsDropdownOpen(false)
    setEditPathwayDetails(true)
  }

  const handleMovePathway = (e) => {
    e.stopPropagation()
    setIsDropdownOpen(false)
    onMovePathway(id)
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popoverRef.current && !popoverRef.current.contains(event.target)) {
        // if user clicks on popover button, don't close the popover
        if (event.target.closest('.group'))
          return

        setIsDropdownOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [popoverRef, moreButtonRef])

  const Dropdown = () => {
    const [position, setPosition] = useState({ top: 0, left: 0 })

    useEffect(() => {
      if (moreButtonRef.current) {
        const rect = moreButtonRef.current.getBoundingClientRect()
        setPosition({
          top: rect.bottom + window.scrollY,
          left: rect.left + window.scrollX, // Adjust this value to position the dropdown correctly
        })
      }
    }, [])

    if (!isDropdownOpen)
      return null

    return ReactDOM.createPortal(
      <div
        ref={popoverRef}
        style={{
          position: 'absolute',
          top: `${position.top}px`,
          left: `${position.left}px`,
          zIndex: 10000,
        }}
        className="w-32 rounded shadow-lg bg-white ring-1 ring-black ring-opacity-5"
      >
        <div className="py-0.5">
          <button
            onClick={handleMovePathway}
            className="block px-2.5 py-1.5 text-xs text-gray-700 hover:bg-gray-100 w-full text-left"
          >
            Move
          </button>
          <button
            onClick={handleEditPathwayDetails}
            className="block px-2.5 py-1.5 text-xs text-gray-700 hover:bg-gray-100 w-full text-left"
          >
            Edit
          </button>
        </div>
      </div>,
      document.body,
    )
  }

  return (
    <motion.div
      className="bg-white border h-full border-gray-200 rounded overflow-hidden hover:shadow-md transition-all duration-300 ease-in-out"
      whileHover={{ y: -2 }}
    >
      <div
        className="p-2.5 flex flex-col h-full text-md"
        onClick={handleEditPathway}
      >
        <div className="flex justify-between items-start mb-1.5">
          <h3
            className="text-xs font-medium text-gray-900 truncate"
            title={name}
          >
            {name}
          </h3>
          {folder && (
            <div className="flex items-center text-2xs text-gray-500 mt-0.5">
              <Folder className="w-2 h-2 mr-0.5" />
              <span title={folder.name}>{folder.name}</span>
            </div>
          )}
          <motion.div
            className="flex items-center space-x-0.5"
            onHoverStart={() => setIsIdExpanded(true)}
            onHoverEnd={() => setIsIdExpanded(false)}
          >
            <motion.div
              className="bg-gray-100 rounded-sm px-1.5 py-0.5 overflow-hidden flex items-center"
              initial={{ width: '72px' }}
              animate={{ width: isIdExpanded ? 'auto' : '72px' }}
              transition={{ duration: 0.3, ease: 'easeInOut' }}
            >
              <AnimatePresence mode="wait">
                {isIdExpanded
                  ? (
                      <motion.span
                        key="full"
                        className="text-2xs font-mono text-gray-600 whitespace-nowrap"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.2 }}
                      >
                        {id}
                      </motion.span>
                    )
                  : (
                      <motion.span
                        key="short"
                        className="text-2xs font-mono text-gray-600"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.2 }}
                      >
                        {id?.slice(0, 8)}
                        ...
                      </motion.span>
                    )}
              </AnimatePresence>
            </motion.div>
            <button
              onClick={handleCopyId}
              className="text-blue-500 hover:text-blue-600 transition-colors duration-200"
              title="Copy ID"
            >
              <Clipboard className="w-2.5 h-2.5" />
            </button>
          </motion.div>
        </div>
        <p
          className="text-xs text-gray-600 mb-2.5 flex-grow line-clamp-2"
          title={description}
        >
          {description}
        </p>
        <div className="flex justify-between items-center pt-1.5 border-t border-gray-100">
          <div className="flex space-x-1.5">
            <button
              onClick={(e) => {
                e.stopPropagation()
                setCurrId(id)
                setOpenDelete(true)
              }}
              className="text-red-500 hover:text-red-600 transition-colors duration-200"
              title="Delete"
            >
              <Trash2 className="w-2.5 h-2.5" />
            </button>
            <button
              onClick={(e) => {
                e.stopPropagation()
                setCurrId(id)
                setIsDuplicate(true)
                setOpen(true)
              }}
              className="text-green-500 hover:text-green-600 transition-colors duration-200"
              title="Duplicate"
            >
              <Copy className="w-2.5 h-2.5" />
            </button>
          </div>
          <div className="flex justify-end items-center">
            <button
              onClick={handleEditPathway}
              className="inline-flex items-center px-2 py-0.5 text-2xs font-medium text-white bg-indigo-600 rounded-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-colors duration-200"
            >
              <Edit2 className="w-2 h-2 mr-0.5" />
              Open
            </button>
            <div className="relative">
              <button
                ref={moreButtonRef}
                onClick={handleOpenMore}
                className="p-0.5 hover:bg-gray-200 rounded-sm ml-1.5 flex-shrink-0 text-gray-500"
                aria-label="More options"
              >
                <MoreVertical size={16} />
              </button>
            </div>
          </div>
        </div>
      </div>
      <Dropdown />
      {editPathwayDetails && (
        <EditPathwayDetailsDialog
          open={editPathwayDetails}
          onClose={() => setEditPathwayDetails(false)}
          pathwayId={id}
          pathwayName={name}
          pathwayDescription={description}
          setRefreshPathways={setRefreshPathways}
        />
      )}
    </motion.div>
  )
}

export default PathwayCard
