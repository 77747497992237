import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { IconButton } from '@radix-ui/themes';
import { X } from 'lucide-react';
import styled from 'styled-components';

const PanelOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: flex-end;
  opacity: ${props => (props.isOpen ? 1 : 0)};
  pointer-events: ${props => (props.isOpen ? 'auto' : 'none')};
  transition: opacity 0.3s ease;
`;

const PanelContainer = styled.div`
  position: relative;
  width: ${props => props.width || '50%'};
  max-width: 800px;
  height: 100%;
  background-color: white;
  box-shadow: -4px 0 10px rgba(0, 0, 0, 0.1);
  transform: translateX(${props => (props.isOpen ? 0 : '100%')});
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const PanelHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 20px;
  border-bottom: 1px solid #e0e0e0;
  background-color: #f9f9f9;
`;

const PanelTitle = styled.h2`
  margin: 0;
  font-size: 18px;
  font-weight: 600;
`;

const PanelContent = styled.div`
  flex: 1;
  padding: 20px;
  overflow-y: auto;
`;

const SlideoutPanel = ({ 
  isOpen, 
  onClose, 
  title, 
  children, 
  width 
}) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
    
    // Add body overflow hidden when panel is open
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    }
    
    return () => {
      document.body.style.overflow = '';
    };
  }, [isOpen]);

  if (!mounted) return null;

  const panel = (
    <PanelOverlay isOpen={isOpen} onClick={onClose}>
      <PanelContainer 
        isOpen={isOpen} 
        width={width}
        onClick={e => e.stopPropagation()}
      >
        <PanelHeader>
          <PanelTitle>{title}</PanelTitle>
          <IconButton 
            size="2" 
            variant="ghost" 
            color="gray" 
            onClick={onClose}
          >
            <X size={18} />
          </IconButton>
        </PanelHeader>
        <PanelContent>
          {children}
        </PanelContent>
      </PanelContainer>
    </PanelOverlay>
  );

  return createPortal(panel, document.body);
};

export default SlideoutPanel; 