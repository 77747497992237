import ActionBar from 'components/core/ActionBar'
import Button from 'components/core/Button'
import { PageTitle } from 'components/core/PageTitle'
import { PageWrapper } from 'components/core/PageWrapper'
import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import styled from 'styled-components'
import ActivityBarGraph from '../CallLogs/ActivityBarGraph'
import { ServerAndLimits } from './components/server_and_limits'
import { Sidebar } from './components/sidebar'

export default function Infrastructure() {
  const [selectedTab, setSelectedTab] = useState('manage_server')
  const [health, setHealth] = useState({
    historical_latency: [],
    server_status: 'healthy',
  })

  const methods = useForm({
    defaultValues: {
      daily_rate_limit: '500',
      hourly_rate_limit: '100',
    },
  })

  const getHistoricalLatency = async () => {
    try {
      const response = await $fetch(`/v1/infra/latency`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (!response.errors) {
        const json = await response.data
        if (json?.latency && json?.latency?.length > 0) {
          setHealth(prevState => ({
            ...prevState,
            historical_latency: json?.latency,
          }))
        }
      }
    }
    catch (error) {}
  }

  useEffect(() => {
    getHistoricalLatency()
  }, [])

  return (
    <PageWrapper>
      <FormProvider {...methods}>
        <ActionBar top spaceBetween>
          <PageTitle>Infrastructure</PageTitle>

          <Button
            onClick={() => {
              window.location.href = '/enterprise'
            }}
            style={{ height: 30, borderRadius: 6 }}
          >
            <p>Enterprise </p>
          </Button>
        </ActionBar>
        <Sidebar
          label="Infrastructure"
          options={[
            { label: 'Manage Server', value: 'manage_server' },
            // { label: "Latency & Status", value: "latency_status" },
          ]}
          onChange={value => setSelectedTab(value)}
          selected={selectedTab}
        />

        <Wrapper style={{ marginTop: 54 }}>
          {selectedTab === 'manage_server' && <ServerAndLimits />}

          {selectedTab === 'latency_status' && (
            <div style={{ marginTop: 0 }}>
              <PageTitle style={{ fontSize: 16, fontWeight: '550' }}>
                Server Status
              </PageTitle>

              <div
                style={{
                  padding: '40px',
                  borderRadius: 2,
                  border: '2px solid #e5e5e5',
                  background: '#fbfafb',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  width: '100%',
                  justifyContent: 'space-between',
                  marginBottom: 15,
                  marginTop: 15,
                }}
              >
                <div>
                  <p
                    style={{
                      fontWeight: '700',
                      fontSize: 16,
                      color: '#343a40',
                    }}
                  >
                    All Systems Operational
                  </p>
                  <p
                    style={{
                      fontWeight: '400',
                      fontSize: 14,
                      color: '#343a40',
                      marginTop: 0,
                    }}
                  >
                    us.api.bland.ai
                  </p>
                </div>
                <p
                  style={{
                    color: '#343a40',
                    fontWeight: 500,
                    whiteSpace: 'pre-line',
                    textAlign: 'right',
                  }}
                >
                  Last Updated
                  {' '}
                  {'\n'}
                  {new Date().toLocaleTimeString()}
                </p>
              </div>

              <PageTitle
                style={{ fontSize: 16, fontWeight: '550', marginTop: 35 }}
              >
                Latency
              </PageTitle>
              <ChartBox id="total-latency" style={{ marginTop: 15 }}>
                <PageTitle style={{ fontSize: 12 }}>
                  Median - Total Latency
                </PageTitle>
                <p style={{ marginBottom: 10 }}>
                  Current Latency:
                  {' '}
                  {health?.historical_latency?.[
                    health?.historical_latency?.length - 1
                  ]?.median_total * 1000}
                  {' '}
                  ms
                  <span style={{ fontSize: 10, color: 'grey', marginLeft: 5 }}>
                    (
                    {
                      health?.historical_latency?.[
                        health?.historical_latency?.length - 1
                      ]?.day
                    }
                    )
                  </span>
                </p>
                <ActivityBarGraph
                  toolTipLabel="Latency (ms)"
                  showxLabel={false}
                  data={health?.historical_latency?.map(doc => ({
                    time: doc?.day,
                    count: (doc?.median_total * 1000)?.toFixed(0),
                  }))}
                  CustomLabel={(
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%',
                        marginTop: 10,
                      }}
                    >
                      <p
                        style={{
                          fontSize: 10,
                          color: 'grey',
                          fontWeight: '500',
                        }}
                      >
                        {health?.historical_latency?.[0]?.day}
                      </p>
                      <p
                        style={{
                          fontSize: 10,
                          color: 'grey',
                          fontWeight: '500',
                        }}
                      >
                        {
                          health?.historical_latency?.[
                            health?.historical_latency?.length - 1
                          ]?.day
                        }
                      </p>
                    </div>
                  )}
                />
              </ChartBox>

              <ChartBox style={{ marginTop: 15 }}>
                <PageTitle style={{ fontSize: 12 }}>
                  Median - Audio Latency (TTS)
                </PageTitle>
                <p style={{ marginBottom: 10 }}>
                  Current Latency:
                  {' '}
                  {health?.historical_latency?.[
                    health?.historical_latency?.length - 1
                  ]?.median_audio * 1000}
                  {' '}
                  ms
                  <span style={{ fontSize: 10, color: 'grey', marginLeft: 5 }}>
                    (
                    {
                      health?.historical_latency?.[
                        health?.historical_latency?.length - 1
                      ]?.day
                    }
                    )
                  </span>
                </p>
                <ActivityBarGraph
                  toolTipLabel="Latency (ms)"
                  showxLabel={false}
                  data={health?.historical_latency?.map(doc => ({
                    time: doc?.day,
                    count: (doc?.median_audio * 1000)?.toFixed(0),
                  }))}
                  CustomLabel={(
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%',
                        marginTop: 10,
                      }}
                    >
                      <p
                        style={{
                          fontSize: 10,
                          color: 'grey',
                          fontWeight: '500',
                        }}
                      >
                        {health?.historical_latency?.[0]?.day}
                      </p>
                      <p
                        style={{
                          fontSize: 10,
                          color: 'grey',
                          fontWeight: '500',
                        }}
                      >
                        {
                          health?.historical_latency?.[
                            health?.historical_latency?.length - 1
                          ]?.day
                        }
                      </p>
                    </div>
                  )}
                />
              </ChartBox>

              <ChartBox style={{ marginTop: 15 }}>
                <PageTitle style={{ fontSize: 12 }}>
                  Median - Text Latency (Inference)
                </PageTitle>
                <p style={{ marginBottom: 10 }}>
                  Current Latency:
                  {' '}
                  {health?.historical_latency?.[
                    health?.historical_latency?.length - 1
                  ]?.median_text * 1000}
                  {' '}
                  ms
                  <span style={{ fontSize: 10, color: 'grey', marginLeft: 5 }}>
                    (
                    {
                      health?.historical_latency?.[
                        health?.historical_latency?.length - 1
                      ]?.day
                    }
                    )
                  </span>
                </p>
                <ActivityBarGraph
                  toolTipLabel="Latency (ms)"
                  showxLabel={false}
                  data={health?.historical_latency?.map(doc => ({
                    time: doc?.day,
                    count: (doc?.median_text * 1000)?.toFixed(0),
                  }))}
                  CustomLabel={(
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%',
                        marginTop: 10,
                      }}
                    >
                      <p
                        style={{
                          fontSize: 10,
                          color: 'grey',
                          fontWeight: '500',
                        }}
                      >
                        {health?.historical_latency?.[0]?.day}
                      </p>
                      <p
                        style={{
                          fontSize: 10,
                          color: 'grey',
                          fontWeight: '500',
                        }}
                      >
                        {
                          health?.historical_latency?.[
                            health?.historical_latency?.length - 1
                          ]?.day
                        }
                      </p>
                    </div>
                  )}
                />
              </ChartBox>
            </div>
          )}
        </Wrapper>
      </FormProvider>
    </PageWrapper>
  )
}

const ChartBox = styled.div`
  width: 100%;
  padding: 20px;
  border: 2px solid #eeeeee;
  border-radius: 1px;
  background: #fbfafb;
`

/*
                <Wrapper style={{ marginTop: 0 }}>
                    <Tabs
                        fixed
                        tabs={[
                            {
                                item: "Manage Server",
                                panel: <div style={{ marginTop: "50px" }}>
                                    <ServerAndLimits />
                                </div>
                            },
                            {
                                item: "Latency & Status",
                                panel: <div style={{ marginTop: "50px" }}>
                                    <div style={{ padding: "40px", borderRadius: 6, border: '2px solid #e5e5e5', background: '#eeeeee', display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', justifyContent: 'space-between', marginBottom: 15 }}>
                                        <div>
                                            <p style={{ fontWeight: '700', fontSize: 16, color: "#343a40" }}>All Systems Operational</p>
                                            <p style={{ fontWeight: '400', fontSize: 14, color: "#343a40", marginTop: 0 }}>api.bland.ai</p>
                                        </div>
                                        <p style={{ color: "#343a40", fontWeight:500, whiteSpace: 'pre-line', textAlign: 'right' }}>Last Updated {
                                            "\n"
                                        }{new Date().toLocaleTimeString()}</p>
                                    </div>
                                    <ServiceStatus
                                        historical_latency={health.historical_latency}
                                    />
                                </div>
                            }
                        ]}
                    />
                </Wrapper>
*/

const Wrapper = styled.div`
  height: calc(100% - 54px);
  top: 0px;
  left: 360px;
  overflow-y: scroll;
  position: absolute;
  width: calc(100% - 395px);
  padding: 20px;

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`

const Container = styled.div`
  display: flex;
  padding: 20px 20px;
  background-color: #f0f0f0;
`

const Card = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;
`
