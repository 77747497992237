import {
  Button,
  ButtonGroup,
  Chip,
  cn,
  ScrollShadow,
  Tooltip,
} from '@heroui/react'
import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table'
import { parsePhoneNumberWithError } from 'libphonenumber-js'
import { MessageSquare, Plus, Settings } from 'lucide-react'
import { Fragment, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import SMSTestButton from '../SMSTestButton'

function SMSNumbersTable({ data = [], _isLoading = false, isFetching = false }) {
  const navigate = useNavigate()
  const [expandedRow, setExpandedRow] = useState(null)

  // Define columns for the SMS numbers table
  const numbersColumns = useMemo(
    () => [
      {
        accessorKey: 'phone_number',
        header: 'Phone Number',
        enableSorting: true,
        cell: ({ getValue }) => {
          const value = getValue()
          if (!value || typeof value !== 'string')
            return value ?? '-'
          try {
            return parsePhoneNumberWithError(value)?.formatNational()
          }
          catch (error) {
            console.error(error)
            return value ?? '-'
          }
        },
      },
      {
        accessorKey: 'type',
        header: 'Type',
        enableSorting: true,
        cell: ({ row }) => {
          // Determine type based on data
          const hasObjective = !!row.original?.sms_config?.objective
          const hasPathway = !!row.original?.sms_config?.pathway_id

          let type = 'unknown'
          let color = 'default'

          if (hasObjective) {
            type = 'prompt'
            color = 'primary'
          }
          else if (hasPathway) {
            type = 'pathway'
            color = 'success'
          }

          return (
            <Chip variant="flat" radius="sm" size="sm" color={color}>
              {type}
            </Chip>
          )
        },
      },
      {
        accessorKey: 'sms_config.request_data',
        header: 'Request Data',
        enableSorting: false,
        size: 150,
        cell: ({ row }) => {
          const requestData = row.original?.sms_config?.request_data

          if (!requestData || !requestData.length)
            return '-'

          return (
            <Button
              variant="light"
              size="sm"
              color="primary"
              onPress={() => setExpandedRow(expandedRow === row.id ? null : row.id)}
            >
              {requestData.length}
              {' '}
              {requestData.length === 1 ? 'item' : 'items'}
            </Button>
          )
        },
      },
      {
        accessorKey: 'sms_config.tools',
        header: 'Tools',
        enableSorting: false,
        size: 150,
        cell: ({ row }) => {
          const tools = row.original?.sms_config?.tools

          if (!tools || !tools.length)
            return '-'

          return (
            <Button
              variant="light"
              size="sm"
              color="primary"
              onPress={() => setExpandedRow(expandedRow === row.id ? null : row.id)}
            >
              {tools.length}
              {' '}
              {tools.length === 1 ? 'tool' : 'tools'}
            </Button>
          )
        },
      },
      {
        accessorKey: 'sms_config.webhook',
        header: 'Webhook',
        enableSorting: false,
        size: 200,
        cell: ({ row }) => {
          const value = row.original?.sms_config?.webhook
          return value && value.length > 0
            ? (
                <Tooltip content={value}>
                  <div className="truncate max-w-xs">{value}</div>
                </Tooltip>
              )
            : '-'
        },
      },
      {
        header: 'Conversations',
        enableSorting: true,
        size: 120,
        cell: ({ row }) => {
          const conversationCount = row.original?.conversation_count
          const phoneNumber = row.original?.phone_number

          return (
            <Button
              variant="light"
              size="sm"
              color="secondary"
              startContent={<MessageSquare size={14} />}
              onPress={() => navigate(`/dashboard/sms?phone=${encodeURIComponent(phoneNumber)}&tab=conversations`)}
            >
              {conversationCount}
            </Button>
          )
        },
      },
      {
        accessorKey: 'phone_number',
        header: 'Actions',
        size: 100,
        enableSorting: false,
        cell: ({ getValue }) => {
          const phoneNumber = getValue()
          return (
            <ButtonGroup className="flex items-center gap-2">
              <SMSTestButton 
                selectedNumber={phoneNumber} 
                onSuccess={() => {}}
                highlight={false}
              />
              <Button
                variant="light"
                size="sm"
                radius="sm"
                color="primary"
                onPress={() => navigate(`/dashboard/phone-numbers?number=${encodeURIComponent(phoneNumber)}&tab=sms`)}
              >
                <span className="flex items-center gap-2">
                  <Settings size={14} />
                  Configure
                </span>
              </Button>
            </ButtonGroup>
          )
        },
      },
    ],
    [navigate, expandedRow],
  )

  // Setup TanStack Table for SMS numbers
  const numbersTable = useReactTable({
    data: data || [],
    columns: numbersColumns,
    getCoreRowModel: getCoreRowModel(),
    columnResizeMode: 'onChange',
  })

  // Function to render expanded row details
  const renderExpandedRow = (rowId) => {
    // Find the row data based on the row ID
    const rowData = numbersTable.getRowModel().rowsById[rowId]?.original
    
    if (!rowData) {
      return null
    }

    return (
      <tr>
        <td colSpan={7} className="p-4 bg-gray-50">
          <div className="grid grid-cols-2 gap-4">
            {rowData.sms_config?.request_data && rowData.sms_config.request_data.length > 0 && (
              <div>
                <h4 className="font-medium text-sm mb-2">Request Data</h4>
                <div className="bg-white p-2 rounded border border-gray-200 max-h-40 overflow-y-auto">
                  <table className="w-full text-left text-xs">
                    <thead>
                      <tr className="border-b">
                        <th className="p-1 font-medium">Key</th>
                        <th className="p-1 font-medium">Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      {rowData.sms_config.request_data.map((item, idx) => (
                        <tr key={idx} className="border-b border-gray-100">
                          <td className="p-1">{item.key}</td>
                          <td className="p-1 break-all">{item.value}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}

            {rowData.sms_config?.tools && rowData.sms_config.tools.length > 0 && (
              <div>
                <h4 className="font-medium text-sm mb-2">Tools</h4>
                <div className="bg-white p-2 rounded border border-gray-200 max-h-40 overflow-y-auto">
                  <pre className="text-xs">{JSON.stringify(rowData.sms_config.tools, null, 2)}</pre>
                </div>
              </div>
            )}
          </div>
        </td>
      </tr>
    )
  }

  return (
    <div className="h-full flex flex-col max-h-full overflow-y-scroll">
      {/* SMS Numbers Table */}
      <ScrollShadow className="w-full px-4 bg-gray-50 h-full mb-[54px]" visibility="bottom" size={20}>
        <table className="divide-y divide-gray-200 border-b w-full">
          <thead className="sticky group top-0 bg-gray-50 z-20 outline outline-2 outline-gray-50 bg-opacity-90 after:content-[''] after:absolute after:left-0 after:right-0 after:bottom-0 after:h-[1px] after:bg-gray-200">
            <tr>
              {numbersTable.getHeaderGroups().map(headerGroup =>
                headerGroup.headers.map(header => (
                  <th
                    key={header.id}
                    className="h-8 text-center text-2xs relative font-medium font-taurus text-gray-500 uppercase tracking-wider whitespace-nowrap flex-nowrap"
                    colSpan={header.colSpan}
                    style={{ width: `${header.getSize()}px` }}
                  >
                    {header.isPlaceholder
                      ? null
                      : (
                          <div className="flex items-center justify-center gap-2">
                            {flexRender(header.column.columnDef.header, header.getContext())}
                          </div>
                        )}
                    {header.column.getCanResize() && (
                      <div
                        {...{
                          onMouseDown: header.getResizeHandler(),
                          onTouchStart: header.getResizeHandler(),
                          className: cn(
                            'absolute bottom-0 h-full my-0.5 w-[2px] cursor-col-resize select-none touch-none group-hover:opacity-80 transition-opacity',
                            numbersTable.options.columnResizeDirection,
                            header.column.getIsResizing() ? 'isResizing' : '',
                            numbersTable.options.columnResizeDirection === 'ltr' ? 'right-0' : 'left-0',
                            header.column.getIsResizing() ? 'bg-primary-500 opacity-100' : 'bg-black/20 opacity-0',
                          ),
                        }}
                      />
                    )}
                  </th>
                )),
              )}
            </tr>
          </thead>
          <tbody
            className={cn('bg-white border-x divide-y divide-gray-200', { 'animate-pulse': isFetching })}
          >
            {numbersTable.getRowModel().rows.map(row => (
              <Fragment key={`row-fragment-${row.id}`}>
                <tr
                  key={row.id}
                  className={cn(
                    'divide-x-1 divide-gray-50 text-center hover:bg-gray-50 transition-colors duration-100',
                    { 'bg-gray-100': expandedRow === row.id },
                  )}
                >
                  {row.getVisibleCells().map(cell => (
                    <td key={cell.id} className="h-11 whitespace-nowrap text-tiny text-gray-500">
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </tr>
                {expandedRow === row.id && renderExpandedRow(row.id)}
              </Fragment>
            ))}
          </tbody>
        </table>
      </ScrollShadow>
    </div>
  )
}

export default SMSNumbersTable
