import { Button, forwardRef, Link, Textarea } from '@heroui/react'
import { Binoculars, BriefcaseBusiness, Headset, HeartPulse, Save } from 'lucide-react'
import { useFormContext } from 'react-hook-form'
import { salesTask } from 'utils/data/prompts/salesTask'
import { smallBusinessTask } from 'utils/data/prompts/smallBusinessTask'
import { stadiumTask } from 'utils/data/prompts/stadiumTask'
import { telehealthTask } from 'utils/data/prompts/telehealthTask'

const PromptInput = forwardRef((props, ref) => {
  const { setValue } = useFormContext()

  const handleOpenPromptModal = () => {
    setValue('prompt_modal', true)
  }

  const handleExampleClick = (task) => {
    setValue('task', task)
  }

  const promptPresets = [
    {
      label: 'Saved Prompts',
      onClick: handleOpenPromptModal,
      icon: <Save size={16} />,
    },
    {
      label: 'Telehealth',
      onClick: () => handleExampleClick(telehealthTask),
      icon: <HeartPulse size={16} />,
    },
    {
      label: 'Small business',
      onClick: () => handleExampleClick(smallBusinessTask),
      icon: <BriefcaseBusiness size={16} />,
    },
    {
      label: 'Stadium venues',
      onClick: () => handleExampleClick(stadiumTask),
      icon: <Binoculars size={16} />,
    },
    {
      label: 'Inbound sales',
      onClick: () => handleExampleClick(salesTask),
      icon: <Headset size={16} />,
    },
  ]
  return (
    <div className="flex flex-col gap-2 w-full">
      <div className="flex gap-2">
        {promptPresets.map(preset => (
          <Button startContent={preset.icon} size="sm" variant="bordered" key={preset.label} onPress={preset.onClick}>
            {preset.label}
          </Button>
        ))}
      </div>
      <Textarea
        ref={ref}
        variant="bordered"
        radius="sm"
        placeholder="Enter a prompt for the call"
        labelPlacement="outside"
        minRows={10}
        maxRows={20}
        classNames={{
          input: 'resize-y',
        }}
        {...props}
      />
      <Link size="sm" isExternal showAnchorIcon href="https://www.bland.ai/blogs/prompting-guide-ai-phone-calls">
        Prompting Guide
      </Link>
    </div>
  )
})

export default PromptInput
