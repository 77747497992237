import { X } from 'lucide-react'

function MessageList({
  messages = [],
  onUpdate,
  onDelete,
  onAdd,
  readOnly = false,
}) {
  return (
    <div className="space-y-1.5">
      {(messages.length ? messages : ['']).map((message, index) => (
        <div key={index} className="flex gap-1.5">
          <div className="flex-grow flex items-center gap-1.5 bg-white rounded border border-gray-300">
            <span className="px-2 py-1.5 text-gray-500 bg-gray-50 border-r border-gray-300">
              {index + 1}
            </span>
            {readOnly
              ? (
                  <div className="flex-grow px-2 py-1.5">{message}</div>
                )
              : (
                  <input
                    type="text"
                    value={message}
                    onChange={e => onUpdate?.(index, e.target.value)}
                    placeholder="Enter user message"
                    className="flex-grow px-2 py-1.5 focus:outline-none"
                  />
                )}
          </div>
          {!readOnly && messages.length > 1 && onDelete && (
            <button
              type="button"
              onClick={() => onDelete(index)}
              className="p-1.5 text-gray-500 hover:text-red-600"
            >
              <X size={20} />
            </button>
          )}
        </div>
      ))}
      {!readOnly
      && onAdd
      && messages.length > 0
      && messages[messages.length - 1].trim() !== '' && (
        <button
          type="button"
          onClick={onAdd}
          className="w-full py-1.5 px-2.5 border-2 border-dashed border-gray-300 rounded text-gray-600 hover:border-gray-400 hover:text-gray-700 flex items-center justify-center gap-1.5"
        >
          <span>Add Message</span>
        </button>
      )}
    </div>
  )
}

export default MessageList
