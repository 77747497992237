import { addToast, Avatar, Button, Card, CardBody, Input, Tab, Tabs, Textarea } from '@heroui/react'
import { Icon } from '@iconify/react'
import { formatDistanceToNow } from 'date-fns'
import { useState, useEffect } from 'react'
import AddCallModal from './AddCallModal'
import { CallItem } from './CallItem'
import { UserCard } from './UserCard'
import { useQuery, useMutation } from '@tanstack/react-query'
import { $fetch } from 'utils/fetch'
import { BulkInsertModal } from './BulkInsertModal'

// interface MemoryStoreDetailProps {
//   memoryStore: {
//     memory_id: string;
//     name: string;
//     user_count: number;
//     created_at: string;
//     description: string;
//   };
//   onViewCall: (callId: string) => void;
//   onBulkInsert: () => void;
// }

export function MemoryStoreDetail({ memoryStore, onViewCall }) {
  const [selectedUser, setSelectedUser] = useState(null)
  const [activeTab, setActiveTab] = useState('summary')
//   const users = mockUsers[memoryStore.memory_id] || []
  const [isEditingSummary, setIsEditingSummary] = useState(false)
  const [isEditingMetadata, setIsEditingMetadata] = useState(false)
  const [editedSummary, setEditedSummary] = useState(memoryStore.summary || '')
  const [editedMetadata, setEditedMetadata] = useState(memoryStore.metadata || '')
  const [isAddCallOpen, setIsAddCallOpen] = useState(false)
  const [isBulkInsertOpen, setIsBulkInsertOpen] = useState(false)
  const [userCalls, setUserCalls] = useState([])

  const searchUserCallsMutation = useMutation({
    mutationFn: (phoneNumber) => $fetch(`/v1/memory/${memoryStore.id}/user/search`, {
      method: 'POST',
      body: JSON.stringify({ phone_number: phoneNumber }),
    }),
    onSuccess: (response) => {
      if (response && response.data) {
        // Handle the new response format where calls are in data array
        setUserCalls(response.data || [])
      } else {
        setUserCalls([])
      }
    },
    onError: (error) => {
      console.error('Error fetching user calls:', error)
      setUserCalls([])
    }
  })

  const handleUserClick = (user) => {
    setSelectedUser(user)

    console.log('user', user)
    
    if (user && user.phone_number) {
      searchUserCallsMutation.mutate(user.phone_number)
    }
    
    // Set the edited values when a user is selected
    setEditedSummary(user.summary || '')
    setEditedMetadata(user.metadata || '')
  }

  const { data: usersData = { data: { users: [] } }, refetch: refetchUsers } = useQuery({
    queryKey: ['users', memoryStore.id],
    queryFn: () => $fetch(`/v1/memory/${memoryStore.id}`),
  })

  // Update selectedUser when users data changes
  useEffect(() => {
    if (selectedUser && users.length > 0) {
      const updatedUser = users.find(user => user.phone_number === selectedUser.phone_number);
      if (updatedUser) {
        setSelectedUser(updatedUser);
        setEditedSummary(updatedUser.summary || '');
        setEditedMetadata(updatedUser.metadata || '');
      } else {
        // If the user was deleted, clear the selection
        setSelectedUser(null);
      }
    }
  }, [usersData]);

  // update user details in memory entries (metadata and summary)
  // POST /v1/:memory_id/user  with phone_number, metadata and summary in body

  const updateUserDetailsMutation = useMutation({
    mutationFn: ({phone_number, metadata, summary}) => $fetch(`/v1/memory/${memoryStore.id}/user/update`, {
      method: 'POST',
      body: JSON.stringify({
        phone_number, 
        metadata,
        summary,
      }),
    }),
    onSuccess: () => {
      addToast({
        title: 'Memory Details for phone number updated',
        color: 'success',
      })
      refetchUsers()
      
      // Re-run the search user calls mutation to refresh call data
      if (selectedUser && selectedUser.phone_number) {
        searchUserCallsMutation.mutate(selectedUser.phone_number)
      }
    },
    onError: (error) => {
      console.error('Error updating user details:', error)
      addToast({
        title: 'Error updating user details',
        description: error.message,
        color: 'error',
      })
    }
  })


  const deleteCallMutation = useMutation({
    mutationFn: (callId) => $fetch(`/v1/memory/${memoryStore.id}/call/${callId}/delete`, {
      method: 'POST',
      body: JSON.stringify({ phone_number: selectedUser.phone_number }),
    }),
    onSuccess: () => {
      addToast({
        title: 'Call deleted',
        description: 'Call deleted successfully',
      })
      if (selectedUser && selectedUser.phone_number) {
        searchUserCallsMutation.mutate(selectedUser.phone_number)
      }
      refetchUsers()
    },
    onError: (error) => {
      console.error('Error deleting call:', error)
      addToast({
        title: 'Error deleting call',
        description: 'Error deleting call, please try again',
      })
    },
  })

  const users = usersData.data?.users || []

  console.log(users)



  const handleSaveSummary = () => {
    if (selectedUser && selectedUser.phone_number) {
      updateUserDetailsMutation.mutate({
        phone_number: selectedUser.phone_number,
        summary: editedSummary,
        metadata: selectedUser.metadata || ''
      });
    }
    setIsEditingSummary(false);
  }

  const handleSaveMetadata = () => {
    if (selectedUser && selectedUser.phone_number) {
      try {
          
        updateUserDetailsMutation.mutate({
          phone_number: selectedUser.phone_number,
          metadata: editedMetadata,
          summary: selectedUser.summary || ''
        });
      } catch (error) {
        addToast({
          title: 'Error updating metadata',
          description: "Error updating metadata, please try again",
          color: 'error',
        });
        return;
      }
    }
    setIsEditingMetadata(false);
  }

  const openAddCallModal = () => {
    setIsAddCallOpen(true)
  }

  const closeAddCallModal = () => {
    setIsAddCallOpen(false)
  }

  // Add these functions to update the edited values when entering edit mode
  const startEditingSummary = () => {
    setEditedSummary(selectedUser?.summary || '');
    setIsEditingSummary(true);
  }
  
  const startEditingMetadata = () => {
    setEditedMetadata(selectedUser?.metadata || '');
    setIsEditingMetadata(true);
  }

  const callDetails = selectedUser ? {
    metadata: selectedUser.metadata ? selectedUser.metadata : "",
    summary: selectedUser.summary || '',
    call_ids: userCalls.map(call => call.c_id) || [],
    last_contact: selectedUser.last_call_at || selectedUser.created_at
  } : null

  const handleBulkInsertClick = () => {
    setIsBulkInsertOpen(true)
  }

  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 h-screen">
        {/* Left column - Users list */}
        <div className="md:col-span-1 overflow-y-auto" style={{ maxHeight: 'calc(100vh - 300px)' }}>
          <div className="mb-4 flex justify-between items-center">
            <div>
              <h3 className="text-lg font-semibold mb-1">Users</h3>
              <p className="text-sm text-default-500">
                {users.length}
                {' '}
                users in this memory store
              </p>
            </div>
            <Button
              size="sm"
              color="primary"
              startContent={<Icon icon="lucide:upload" size={16} />}
              onPress={handleBulkInsertClick}
            >
              Insert
            </Button>
          </div>

          <div className="mb-3 relative">
            <Input
              placeholder="Search users..."
              startContent={<Icon icon="lucide:search" className="text-default-400" />}
              size="sm"
              className="w-full"
            />
          </div>

          <div className="space-y-3">
            {users.map(user => (
              <UserCard
                key={user.phone_number}
                user={user}
                isSelected={selectedUser?.phone_number === user.phone_number}
                onClick={() => handleUserClick(user)}
                memoryId={memoryStore.id}
                onDeleteSuccess={refetchUsers}
              />
            ))}
          </div>
        </div>

        {/* Right column - Selected user details */}
        <div className="md:col-span-2 overflow-y-auto" style={{ maxHeight: 'calc(100vh - 300px)' }}>
          {selectedUser
            ? (
                <div>
                  <div className="flex items-center justify-between mb-4">
                    <div className="flex items-center">
                      <Avatar
                        name={"US"}
                        className="bg-default-200 text-default-600"
                      />
                      <div className="ml-3">
                        <h3 className="text-lg font-semibold">{selectedUser.phone_number}</h3>
                        <p className="text-sm text-default-500">
                          Last contact:
                          {' '}
                          {selectedUser.last_call_at !== null ? formatDistanceToNow(selectedUser.last_call_at, { addSuffix: true }) : 'No contact yet'}
                        </p>
                      </div>
                    </div>
                  </div>

                  <Tabs
                    aria-label="User details tabs"
                    selectedKey={activeTab}
                    onSelectionChange={setActiveTab}
                    className="mb-4"
                  >
                    <Tab key="summary" title="Summary">
                      <div className="space-y-4 pt-2">
                        <Card className="relative">
                          <CardBody className="p-4">
                            <div className="flex items-center justify-between mb-3">
                              <h4 className="text-sm font-medium text-default-600">Summary</h4>
                              <Button
                                size="sm"
                                variant="light"
                                color="primary"
                                isIconOnly
                                onPress={startEditingSummary}
                              >
                                <Icon icon="lucide:edit-2" size={16} />
                              </Button>
                            </div>
                            <div className="relative">
                              {!isEditingSummary
                                ? (
                                    <p className="text-sm text-default-700 leading-relaxed">
                                      {selectedUser.summary || 'No summary available'}
                                    </p>
                                  )
                                : (
                                    <div className="space-y-3">
                                      <Textarea
                                        className="w-full min-h-[100px] text-sm"
                                        value={editedSummary}
                                        onValueChange={setEditedSummary}
                                        placeholder="Enter summary..."
                                      />
                                      <div className="flex justify-end gap-2">
                                        <Button
                                          size="sm"
                                          variant="flat"
                                          color="default"
                                          onPress={() => setIsEditingSummary(false)}
                                        >
                                          Cancel
                                        </Button>
                                        <Button
                                          size="sm"
                                          color="primary"
                                          onPress={handleSaveSummary}
                                        >
                                          Save Changes
                                        </Button>
                                      </div>
                                    </div>
                                  )}
                            </div>
                          </CardBody>
                        </Card>

                        <Card className="relative">
                          <CardBody className="p-4">
                            <div className="flex items-center justify-between mb-3">
                              <h4 className="text-sm font-medium text-default-600">Metadata</h4>
                              <Button
                                size="sm"
                                variant="light"
                                color="primary"
                                isIconOnly
                                onPress={startEditingMetadata}
                              >
                                <Icon icon="lucide:edit-2" size={16} />
                              </Button>
                            </div>
                            <div className="relative">
                              {!isEditingMetadata
                                ? (
                                    <pre className="text-sm font-mono bg-default-50 p-3 rounded-lg overflow-auto">
                                      {selectedUser.metadata || ''}
                                    </pre>
                                  )
                                : (
                                    <div className="space-y-3">
                                      <Textarea
                                        className="w-full min-h-[150px] font-mono text-sm"
                                        value={editedMetadata}
                                        onValueChange={(value) => {
                                            setEditedMetadata(value)
                                        }}
                                        placeholder="Enter metadata..."
                                      />
                                      <div className="flex justify-end gap-2">
                                        <Button
                                          size="sm"
                                          variant="flat"
                                          color="default"
                                          onPress={() => setIsEditingMetadata(false)}
                                        >
                                          Cancel
                                        </Button>
                                        <Button
                                          size="sm"
                                          color="primary"
                                          onPress={handleSaveMetadata}
                                        >
                                          Save Changes
                                        </Button>
                                      </div>
                                    </div>
                                  )}
                            </div>
                          </CardBody>
                        </Card>

                        <div className="flex justify-between items-center mb-4 mr-4">
                          <h4 className="text-sm font-medium text-default-600">
                            Call History (
                            {callDetails?.call_ids?.length || 0}
                            )
                          </h4>
                          <Button
                            type="button"
                            size="sm"
                            color="primary"
                            startContent={<Icon icon="lucide:plus-circle" size={16} />}
                            onPress={openAddCallModal}
                          >
                            Add Call
                          </Button>
                        </div>

                        <div className="space-y-2">
                          {searchUserCallsMutation.isPending ? (
                            <div className="flex justify-center p-4">
                              <div className="animate-spin h-6 w-6 border-2 border-primary rounded-full border-t-transparent"></div>
                            </div>
                          ) : (
                            userCalls.map(call => (
                                console.log(JSON.stringify(call)),
                              <CallItem
                                key={call.c_id}
                                callId={call.c_id}
                                createdAt={call.created_at}
                                onViewCall={() => onViewCall(call.c_id)}
                                onDeleteCall={(call_id) => deleteCallMutation.mutate(call_id)}
                              />
                            ))
                          )}
                          {!searchUserCallsMutation.isPending && userCalls.length === 0 && (
                            <p className="text-sm text-default-500 text-center p-4">No calls found for this user</p>
                          )}
                        </div>
                      </div>
                    </Tab>

                    <Tab key="calls" title="Calls">
                      <div className="space-y-2 pt-2">
                        {searchUserCallsMutation.isPending ? (
                          <div className="flex justify-center p-4">
                            <div className="animate-spin h-6 w-6 border-2 border-primary rounded-full border-t-transparent"></div>
                          </div>
                        ) : (
                          userCalls.map(call => (
                            <CallItem
                              key={call.c_id}
                              callId={call.c_id}
                              createdAt={call.created_at}
                              onViewCall={() => onViewCall(call.c_id)}
                              onDeleteCall={(call_id) => deleteCallMutation.mutate(call_id)}
                            />
                          ))
                        )}
                        {!searchUserCallsMutation.isPending && userCalls.length === 0 && (
                          <p className="text-sm text-default-500 text-center p-4">No calls found for this user</p>
                        )}
                      </div>
                    </Tab>

                    <Tab key="metadata" title="Metadata">
                      <Card className="mt-2">
                        <CardBody className="p-4">
                          {Object.keys(callDetails?.metadata || {}).length > 0
                            ? (
                                <pre className="text-xs whitespace-pre-wrap">
                                  {callDetails.metadata}
                                </pre>
                              )
                            : (
                                <p className="text-sm text-default-500">No metadata available</p>
                              )}
                        </CardBody>
                      </Card>
                    </Tab>
                  </Tabs>
                </div>
              )
            : (
                <div className="h-full flex flex-col items-center justify-center text-center p-8">
                  <div className="bg-default-100 p-4 rounded-full mb-4">
                    <Icon icon="lucide:user" size={32} className="text-default-400" />
                  </div>
                  <h3 className="text-lg font-medium mb-2">No user selected</h3>
                  <p className="text-sm text-default-500 max-w-md">
                    Select a user from the list to view their details, call history, and metadata.
                  </p>
                </div>
              )}
        </div>
      </div>

      <AddCallModal
        isOpen={isAddCallOpen}
        onClose={closeAddCallModal}
        phoneNumber={selectedUser?.phone_number}
        memoryId={memoryStore.id}
      />
      <BulkInsertModal
        isOpen={isBulkInsertOpen}
        onClose={() => setIsBulkInsertOpen(false)}
        memoryId={memoryStore.id}
        onSuccess={refetchUsers}
      />
    </>
  )
}
