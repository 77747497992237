import styled from 'styled-components'
import FirstSetence from '../../Parameters/FirstSentence'
import InterruptionThresh from '../../Parameters/InterruptionThresh'
import Language from '../../Parameters/Language'
import Model from '../../Parameters/Model'
import RequestData from '../../Parameters/RequestData'
import Temperature from '../../Parameters/Temperature'
import Voice from '../../Parameters/Voice'
import WaitGreeting from '../../Parameters/WaitGreeting'
// export default function Settings() {
//    return (
//       <Wrapper>
//          <Model fieldName="model" />
//          <Temperature fieldName="temperature" />
//          <Language fieldName="language" />
//          <Prompt fieldName="task" />
//       </Wrapper>
//    );
// }

export default function Settings({ apiKey = null }) {
  return (
    <Wrapper>
      <Model fieldName="model" />
      <InterruptionThresh fieldName="interruption_threshold" />
      <Temperature fieldName="temperature" />
      <Language fieldName="language" />
      <Voice fieldName="voice" apiKey={apiKey} />
      <FirstSetence fieldName="first_sentence" />
      <WaitGreeting fieldName="wait_for_greeting" />
      <RequestData fieldName="request_data" />
    </Wrapper>
  )
}

/*
         <Button style={{ background: '#dee2e6', color: "#000", borderRadius: "6px" }} loading={isLoading} onClick={fetchTTSAudio}>
            <RxSpeakerLoud
               className="text-lg"
               size={15}
               color={"#3730a3"}
            />
         </Button>
*/

const Wrapper = styled.div`
  position: absolute;
  top: 70px;
  right: 0px;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  padding: 20px;
  width: 328px;
  height: 100%;
  overflow: scroll;
  background-color: white;
  border: 1px solid #eeeeee;
  border-top: none;
`
