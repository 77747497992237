import { useQuery } from '@tanstack/react-query'
import { $fetch } from 'utils/fetch'

/**
 * Custom hook to fetch conversation details with messages
 * 
 * @param {Object} options - Query options
 * @param {string} options.conversationId - The ID of the conversation to fetch
 * @param {boolean} options.enabled - Whether to enable the query
 * @returns {Object} Query result object
 */
export function useConversationDetails({ conversationId, enabled = true }) {
  return useQuery({
    queryKey: ['smsConversationDetails', conversationId],
    queryFn: async () => {
      if (!conversationId)
        return null

      // Call the API endpoint for conversation details
      const response = await $fetch(`/v1/sms/conversations/${conversationId}`)
      return response.data // This includes conversation with messages
    },
    enabled: enabled && !!conversationId,
    staleTime: 1000 * 60, // 1 minute
  })
} 