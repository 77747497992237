import { ErrorMessage } from '@hookform/error-message'
import { useFormContext } from 'react-hook-form'
import { ErrorText } from './ErrorText'
import Label from './Label'

export default function Checkbox({ fieldName, label }) {
  const {
    formState: { errors },
    register,
  } = useFormContext()

  return (
    <div className="flex flex-col gap-1.5">
      <div className="flex w-full items-center gap-1.5">
        <input {...register(fieldName)} type="checkbox" id="tos-checkbox" />
        <Label htmlFor="tos-checkbox" className="font-medium w-full">
          {label}
        </Label>
      </div>
      <ErrorMessage
        errors={errors}
        name={fieldName}
        render={({ message }) => <ErrorText message={message} />}
      />
    </div>
  )
}
