import styled from 'styled-components'
import { Loading } from './Loading'

export default function Button({
  onClick,
  children,
  disabled,
  iconSrc,
  loading,
  appearance,
  submit,
  style,
  ...props
}) {
  return (
    <>
      {submit
        ? (
            <StyledButton
              onClick={onClick}
              disabled={disabled}
              outline={appearance === 'outline'}
              white={appearance === 'white'}
              style={style}
              type="submit"
              {...props}
            >
              {loading
                ? (
                    <Loading loading={loading} color="white" />
                  )
                : (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        columnGap: '6px',
                        width: '100%',
                        justifyContent: 'center',
                      }}
                    >
                      {iconSrc && <img src={iconSrc} alt="icon" className="w-3" />}
                      <span className="flex items-center">{children}</span>
                    </div>
                  )}
            </StyledButton>
          )
        : (
            <StyledDiv
              onClick={onClick}
              disabled={disabled}
              outline={appearance === 'outline'}
              white={appearance === 'white'}
              style={style}
              {...props}
            >
              {loading
                ? (
                    <Loading loading={loading} color="white" />
                  )
                : (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        columnGap: '6px',
                        width: '100%',
                        justifyContent: 'center',
                      }}
                    >
                      {iconSrc && <img src={iconSrc} alt="icon" className="w-3" />}
                      {children}
                    </div>
                  )}
            </StyledDiv>
          )}
    </>
  )
}

const StyledButton = styled.button`
  border: ${props => (props.outline ? `1px solid #eeeeee` : 'none')};
  border-radius: 0.16rem;
  padding: 9px 15px;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  column-gap: 12px;
  align-items: center;
  justify-content: center;
  color: ${props => (props.outline ? `black` : 'white')};
  background-color: ${props =>
    props.outline ? 'transparent' : props.white ? '#FFFFFF' : '#433DFF'};
  text-align: center;
  width: auto;
  height: 35px;
  cursor: pointer;
  white-space: nowrap;
  user-select: none;
  &:hover {
    background-color: ${props =>
      props.outline ? '#0000000D' : props.white ? 'white' : '#3B36DF'};
  }
  &:disabled {
    opacity: 50%;
    cursor: not-allowed;
  }
`

const StyledDiv = styled.div`
  border: ${props => (props.outline ? `1px solid #eeeeee` : 'none')};
  border-radius: 0.16rem;
  padding: 9px 15px;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  column-gap: 12px;
  align-items: center;
  justify-content: center;
  color: ${props => (props.outline || props.white ? `black` : 'white')};
  background-color: ${props =>
    props.outline ? 'transparent' : props.white ? '#FFFFFF' : '#433DFF'};
  text-align: center;
  width: auto;
  height: 35px;
  white-space: nowrap;
  user-select: none;
  opacity: ${props => (props.disabled ? `50%` : '100%')};
  cursor: ${props => (props.disabled ? `not-allowed` : 'pointer')};
  pointer-events: ${props => (props.disabled ? `none` : 'auto')};
  &:hover {
    background-color: ${props =>
      props.outline ? '#0000000D' : props.white ? 'white' : '#3B36DF'};
  }
`
