import { Button, Input } from '@heroui/react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Brain, Clock, MessageSquare, Phone } from 'lucide-react'
import { useState } from 'react'
import { toast } from 'sonner'
import { $fetch } from 'utils/fetch'

export default function CreateMemoryForm({ onSuccess, onCancel }) {
  const [name, setName] = useState('')
  const queryClient = useQueryClient()

  const createMemoryMutation = useMutation({
    mutationFn: async (memoryName) => {
      const response = await $fetch('/v1/memory/create', {
        method: 'POST',
        body: JSON.stringify({ name: memoryName }),
      })
      return response.data
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(['memories'])
      toast.success('Memory created successfully')
      setName('')
      onSuccess?.(data)
    },
    onError: (error) => {
      toast.error(error.message || 'Failed to create memory')
    },
  })

  const handleSubmit = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (!name.trim()) {
      toast.error('Memory name is required')
      return
    }
    createMemoryMutation.mutate(name)
  }

  const isSubmitting = createMemoryMutation.isPending

  return (
    <div className="max-w-md mx-auto space-y-6">
      <div className="bg-white dark:bg-gray-800 rounded-xl shadow-md p-6 border border-gray-100 dark:border-gray-700">
        <div className="flex items-center mb-5 pb-3 border-b border-gray-100 dark:border-gray-700">
          <Brain className="h-5 w-5 text-purple-500 dark:text-purple-400 mr-2" />
          <h3 className="text-xl font-bold bg-gradient-to-r from-gray-800 to-gray-600 dark:from-gray-200 dark:to-gray-400 bg-clip-text text-transparent">New Memory</h3>
        </div>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <p className="text-sm text-gray-600 dark:text-gray-300 mb-4 italic">
              All new calls that reference this memory name will retain the memory of conversations that referenced this same memory.
            </p>
            <label htmlFor="memory-name" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
              Memory Name
            </label>

            <Input
              id="memory-name"
              value={name}
              onChange={e => setName(e.target.value)}
              placeholder="Enter a descriptive name"
              required
              className="w-full focus:ring-2 focus:ring-purple-500"
              disabled={isSubmitting}
            />
          </div>

          <div className="flex gap-3 pt-2">
            <Button
              type="submit"
              className="w-full bg-gradient-to-r from-purple-600 to-indigo-600 hover:from-purple-700 hover:to-indigo-700 text-white font-medium py-2 px-4 rounded-lg transition-all shadow-sm hover:shadow"
              isLoading={isSubmitting}
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Creating...' : 'Create Memory'}
            </Button>
            <Button
              type="button"
              className="w-full bg-transparent border border-gray-300 dark:border-gray-600 text-gray-700 dark:text-gray-300 font-medium py-2 px-4 rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700 transition-colors"
              onPress={onCancel}
              disabled={isSubmitting}
            >
              Cancel
            </Button>
          </div>
        </form>
      </div>

      <div className="bg-blue-50 dark:bg-gray-800/50 rounded-xl p-5 border border-blue-100 dark:border-gray-700">
        <h4 className="font-medium text-blue-700 dark:text-blue-400 mb-2 text-sm">About Memories</h4>
        <p className="text-sm text-gray-600 dark:text-gray-300 mb-4">
          Memories allow your application to recall past conversations and interactions.
          They help provide continuity and context for AI assistants across sessions.
        </p>

        {/* Memory Preview Section */}
        <div className="mt-5 border border-blue-200 dark:border-blue-800 rounded-lg overflow-hidden">
          <div className="bg-blue-100 dark:bg-blue-900/30 px-4 py-2 border-b border-blue-200 dark:border-blue-800">
            <h5 className="text-sm font-medium text-blue-800 dark:text-blue-300">Memory Preview</h5>
          </div>

          <div className="bg-white dark:bg-gray-800 p-3">
            {/* Example Memory Items */}
            <div className="space-y-3">
              {/* Conversation Item */}
              <div className="flex items-start p-2 border border-gray-100 dark:border-gray-700 rounded-md bg-gray-50 dark:bg-gray-850 hover:bg-gray-100 dark:hover:bg-gray-750 transition-colors">
                <div className="h-8 w-8 rounded-full bg-indigo-100 dark:bg-indigo-900/50 flex items-center justify-center mr-3 mt-0.5 flex-shrink-0">
                  <MessageSquare className="h-4 w-4 text-indigo-600 dark:text-indigo-400" />
                </div>
                <div className="flex-1">
                  <div className="flex justify-between items-start">
                    <p className="text-xs font-medium text-gray-900 dark:text-gray-200">Online Chat with John</p>
                    <span className="text-xs text-gray-500 dark:text-gray-400">2 days ago</span>
                  </div>
                  <p className="text-xs text-gray-600 dark:text-gray-300 mt-1">
                    "He wants to upgrade his subscription plan to the premium tier."
                  </p>
                </div>
              </div>

              {/* Phone Call Item */}
              <div className="flex items-start p-2 border border-gray-100 dark:border-gray-700 rounded-md bg-gray-50 dark:bg-gray-850 hover:bg-gray-100 dark:hover:bg-gray-750 transition-colors">
                <div className="h-8 w-8 rounded-full bg-green-100 dark:bg-green-900/50 flex items-center justify-center mr-3 mt-0.5 flex-shrink-0">
                  <Phone className="h-4 w-4 text-green-600 dark:text-green-400" />
                </div>
                <div className="flex-1">
                  <div className="flex justify-between items-start">
                    <p className="text-xs font-medium text-gray-900 dark:text-gray-200">Call with +1 (555) 123-4567</p>
                    <span className="text-xs text-gray-500 dark:text-gray-400">Yesterday</span>
                  </div>
                  <p className="text-xs text-gray-600 dark:text-gray-300 mt-1">
                    "Customer inquired about billing cycle and payment options"
                  </p>
                </div>
              </div>

              {/* SMS Item - replacing Document Item */}
              <div className="flex items-start p-2 border border-gray-100 dark:border-gray-700 rounded-md bg-gray-50 dark:bg-gray-850 hover:bg-gray-100 dark:hover:bg-gray-750 transition-colors">
                <div className="h-8 w-8 rounded-full bg-purple-100 dark:bg-purple-900/50 flex items-center justify-center mr-3 mt-0.5 flex-shrink-0">
                  <MessageSquare className="h-4 w-4 text-purple-600 dark:text-purple-400" />
                </div>
                <div className="flex-1">
                  <div className="flex justify-between items-start">
                    <p className="text-xs font-medium text-gray-900 dark:text-gray-200">
                      SMS to
                      <br />
                      {' '}
                      +1 (555) 987-6543
                    </p>
                    <span className="text-xs text-gray-500 dark:text-gray-400">5 hours ago</span>
                  </div>
                  <p className="text-xs text-gray-600 dark:text-gray-300 mt-1">
                    "Refund request processed."
                  </p>
                </div>
              </div>
            </div>

            <div className="mt-3 text-center">
              <p className="text-xs italic text-gray-500 dark:text-gray-400 flex items-center justify-center">
                <Clock className="h-3 w-3 mr-1" />
                Memory retains context across customer interactions
              </p>
              <p className="text-xs text-purple-500 dark:text-purple-400 mt-2 font-medium">
                Multi-platform memory integration coming soon
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
