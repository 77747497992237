import { Input } from '@heroui/react'
import PromptOrPathway from 'components/Dashboard/SendCall/PromptOrPathway'
import ObjectInput from 'components/Hero/ObjectInput'
import TemperatureRange from 'components/Hero/TemperatureRange'
import WebhookInput from 'components/Hero/WebhookInput'

const SMSForm = {
  fields: [
    {
      name: 'sms_config.messaging_service_sid',
      component: Input,
      condition: {
        watch: 'encrypted_key',
        evaluate: value => !!value,
      },
      props: {
        label: 'Messaging Service SID',
        type: 'text',
        placeholder: 'Enter your Twilio Messaging Service SID',
        description: 'Required for BYOT numbers. Must be in format: MGxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx',
        size: 'md',
        variant: 'bordered',
        radius: 'sm',
        isRequired: true,
      },
    },
    {
      name: 'sms_config.prompt_or_pathway',
      component: PromptOrPathway,
      props: {
        isInbound: true,
        isSMS: true,
      },
    },
    {
      name: 'sms_config.temperature',
      component: TemperatureRange,
      condition: {
        watch: 'sms_config.pathway_id',
        evaluate: value => !value,
      },
    },
    {
      name: 'sms_config.time_out',
      component: Input,
      props: {
        label: 'Time Out (seconds)',
        type: 'number',
        min: 0,
        placeholder: 'Enter timeout in seconds',
        description: 'Maximum time to wait for a response. Default is no timeout.',
        size: 'md',
        labelPlacement: 'outside',
        variant: 'bordered',
        radius: 'sm',
      },
    },

    // {
    //   name: 'sms_config.tools',
    //   component: ToolSelect,
    //   controlled: true,
    //   condition: {
    //     watch: 'sms_config.pathway_id',
    //     evaluate: value => !value,
    //   },
    // },

    {
      name: 'sms_config.webhook',
      component: WebhookInput,
    },

    {
      name: 'sms_config.request_data',
      component: ObjectInput,
      props: {
        name: 'sms_config.request_data',
        label: 'Request Data',
        description: 'When you want your AI to "know" specific facts. The AI will be aware of both the key names and their corresponding values.',
      },
    },
  ],
}

export default SMSForm
