import { Info } from 'lucide-react'
import { useState } from 'react'

export function Label({ children, className }) {
  return (
    <p style={{ fontSize: 15, padding: 0, margin: 0, fontWeight: 550 }}>
      {children}
    </p>
  )
}

export function Description({ children, className }) {
  return (
    <p
      style={{
        fontSize: 12,
        padding: 0,
        margin: 0,
        fontWeight: 400,
        color: '#666666',
      }}
    >
      {children}
    </p>
  )
}

export function Title({ children, className }) {
  return (
    <h1 style={{ fontSize: 24, padding: 0, margin: 0, fontWeight: 450 }}>
      {children}
    </h1>
  )
}

export function TooltipText({
  children,
  tooltip,
  className = '',
  labelClassName = '',
  tooltipClassName = '',
  iconClassName = '',
  icon: Icon = Info,
  position = 'right',
  showIcon = true,
  iconSize = 12,
  delay = 150,
}) {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false)

  const getInitialTransform = () => {
    const transforms = {
      top: 'translateY(4px)',
      bottom: 'translateY(-4px)',
      left: 'translateX(4px)',
      right: 'translateX(-4px)',
    }
    return transforms[position]
  }

  const getTooltipPosition = () => {
    const positions = {
      top: 'bottom-full mb-2.5 left-1/2 -translate-x-1/2',
      bottom: 'top-full mt-2.5 left-1/2 -translate-x-1/2',
      left: 'right-full mr-2.5 top-1/2 -translate-y-1/2',
      right: 'left-full ml-2.5 top-1/2 -translate-y-1/2',
    }
    return positions[position] || positions.top
  }

  const getArrowPosition = () => {
    const positions = {
      top: 'top-full left-1/2 -translate-x-1/2 border-t-gray-800',
      bottom: 'bottom-full left-1/2 -translate-x-1/2 border-b-gray-800',
      left: 'left-full top-0.5 -translate-y-1/2 border-l-gray-800 mt-[1px]',
      right: 'right-full top-0.5 -translate-y-1/2 border-r-gray-800 mt-[1px]',
    }
    return positions[position]
  }

  return (
    <div
      className={`relative inline-flex items-center gap-1 group ${className}`}
    >
      <p
        style={{ fontSize: 15, fontWeight: 550, padding: 0, margin: 0 }}
        className={labelClassName}
      >
        {children}
      </p>

      {showIcon && (
        <span
          className={`inline-flex items-center transition-colors duration-150 ${iconClassName} ml-1.5`}
          onMouseEnter={() => setIsTooltipVisible(true)}
          onMouseLeave={() => setIsTooltipVisible(false)}
          onFocus={() => setIsTooltipVisible(true)}
          onBlur={() => setIsTooltipVisible(false)}
          tabIndex={0}
          role="button"
          aria-label={`Show tooltip: ${tooltip}`}
        >
          <Icon
            size={iconSize}
            className="text-gray-400 hover:text-gray-600 dark:text-gray-500 dark:hover:text-gray-300 transition-colors duration-150"
          />
        </span>
      )}

      <div
        className={`absolute ${getTooltipPosition()} z-50 pointer-events-none transition-all duration-200 ease-out ${
          isTooltipVisible ? 'opacity-100' : 'opacity-0'
        }`}
        style={{
          transform: isTooltipVisible
            ? 'translate(0, 0)'
            : getInitialTransform(),
          transitionDelay: isTooltipVisible ? `${delay}ms` : '0ms',
          marginTop: position === 'left' || position === 'right' ? -4 : 0,
        }}
        role="tooltip"
        aria-hidden={!isTooltipVisible}
      >
        <div
          className={`relative px-2 py-1.5 text-xs text-gray-50 bg-gray-800 rounded shadow-lg border border-gray-700 backdrop-blur-sm whitespace-pre-line min-w-[140px] break-words ${tooltipClassName}`}
        >
          {tooltip}
          <div className={`absolute w-0 h-0 border-4 ${getArrowPosition()}`} />
        </div>
      </div>
    </div>
  )
}
