import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from '@/components/ui/resizable'
import GradientLoadingAnimation from 'components/Reusables/GradientLoadingAnimation'
import { useAuth } from 'hooks/useAuth'
import { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { $fetch } from 'utils/fetch'
import { useTranscriptStream } from '../TranscriptStream/hooks/useTranscriptStream'
import { CallRow } from './components/CallRow'
import { TranscriptPanel } from './components/TranscriptPanel'
import { useCallsStream } from './hooks/useCallsStream'

function ActiveCallsView({
  handleViewDetails,
  selectedCallId,
  setSelectedCallId,
}) {
  const { user } = useAuth()
  const { calls, isLoading: isCallsLoading } = useCallsStream()
  const [isTranscriptPanelOpen, setIsTranscriptPanelOpen] = useState(false)

  useEffect(() => {
    if (selectedCallId) {
      setIsTranscriptPanelOpen(true)
    }
  }, [])

  const {
    transcripts,
    isLoading: isTranscriptsLoading,
    isCallEnded,
  } = useTranscriptStream(selectedCallId)

  const handleCopy = useCallback((text) => {
    navigator.clipboard.writeText(text)
    toast.success('Call ID copied to clipboard')
  }, [])

  const handleTranscriptToggle = useCallback(
    (call_id) => {
      // If clicking the same call ID, just toggle the panel
      if (selectedCallId === call_id) {
        setIsTranscriptPanelOpen(prev => !prev)
        return
      }
      setIsTranscriptPanelOpen(true)
      setSelectedCallId(call_id)
    },
    [selectedCallId],
  )

  const handleCloseTranscript = useCallback(() => {
    setIsTranscriptPanelOpen(false)
    setSelectedCallId(null)
  }, [])

  const triggerCall = async () => {
    const call = await $fetch('/v1/calls', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        phone_number: user?.user?.phone_number,
        task: 'You are demoing the realtime call monitoring feature of Bland AI. Talk to the user and tell them how they can click on the \'View Transcript\' button to view the transcripts live. If they exit the page, they can revisit the page to view the transcripts again.',
      }),
    })

    if (call.status === 'success') {
      handleTranscriptToggle(call.call_id)
    }
  }

  if (isCallsLoading) {
    return (
      <GradientLoadingAnimation
        message="Loading active calls..."
        variant="default"
        duration={2}
        rounded="lg"
      />
    )
  }

  return (
    <ResizablePanelGroup direction="horizontal" className="h-screen">
      <ResizablePanel defaultSize={70} minSize={30}>
        <div className="h-full flex flex-col">
          <div className="overflow-auto flex-1 border border-gray-100 rounded-md">
            <table className="min-w-full text-left">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-4 py-2 text-2xs font-semibold text-gray-600 uppercase tracking-wider">
                    ID
                  </th>
                  <th className="px-4 py-2 text-2xs font-semibold text-gray-600 uppercase tracking-wider">
                    Status
                  </th>
                  <th className="px-4 py-2 text-2xs font-semibold text-gray-600 uppercase tracking-wider">
                    Duration
                  </th>
                  <th className="px-4 py-2 text-2xs font-semibold text-gray-600 uppercase tracking-wider">
                    From
                  </th>
                  <th className="px-4 py-2 text-2xs font-semibold text-gray-600 uppercase tracking-wider">
                    To
                  </th>
                  <th className="px-4 py-2 text-2xs font-semibold text-gray-600 uppercase tracking-wider">
                    Objective
                  </th>
                  <th className="px-4 py-2 text-2xs font-semibold text-gray-600 uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {calls.length > 0
                  ? (
                      calls.map(call => (
                        <CallRow
                          key={call.call_id}
                          call={call}
                          onCopy={handleCopy}
                          onTranscriptView={handleTranscriptToggle}
                          isActive={
                            selectedCallId === call.call_id && isTranscriptPanelOpen
                          }
                        />
                      ))
                    )
                  : (
                      <tr>
                        <td colSpan="7" className="px-5 py-12 text-center">
                          <div className="flex flex-col items-center justify-center space-y-4">
                            <h3 className="text-base font-semibold text-gray-800">
                              No Active Calls
                            </h3>
                            <p className="text-gray-600 max-w-lg">
                              There are currently no active calls. Start a new call
                              to begin monitoring conversations in real-time.
                            </p>
                            <div className="flex gap-2.5">
                              <Link
                                to="/dashboard/send-call"
                                className="inline-block px-5 py-2.5 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 transition-colors duration-200"
                              >
                                Create New Call
                              </Link>
                              <button
                                onClick={() => {
                                  triggerCall()
                                }}
                                className="inline-block px-5 py-2.5 bg-gray-100 text-indigo-600 rounded-md hover:bg-gray-200 transition-colors duration-200"
                              >
                                Trigger Test Call to Yourself
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
              </tbody>
            </table>
          </div>
        </div>
      </ResizablePanel>

      {isTranscriptPanelOpen && (
        <>
          <ResizableHandle />
          <ResizablePanel defaultSize={30} minSize={20}>
            <TranscriptPanel
              onClose={handleCloseTranscript}
              transcripts={transcripts}
              selectedCallId={selectedCallId}
              isLoading={isTranscriptsLoading}
              onNavigateToCallLogs={handleViewDetails}
              isCallEnded={isCallEnded}
            />
          </ResizablePanel>
        </>
      )}
    </ResizablePanelGroup>
  )
}

export default ActiveCallsView
