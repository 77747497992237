import { Switch } from '@headlessui/react'

function SwitchOption({ checked, onChange, label, description }) {
  return (
    <div className="flex items-center space-x-2.5 py-2.5 border-b border-gray-200 last:border-b-0">
      <Switch
        checked={checked}
        onChange={onChange}
        className={`${
          checked ? 'bg-indigo-600' : 'bg-gray-200'
        } relative inline-flex h-4 w-8 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
      >
        <span
          className={`${
            checked ? 'translate-x-4' : 'translate-x-0.5'
          } inline-block h-2.5 w-2.5 transform rounded-full bg-white transition`}
        />
      </Switch>
      <div>
        <label className="text-xs font-medium">{label}</label>
        <p className="text-xs text-gray-500">{description}</p>
      </div>
    </div>
  )
}

export default SwitchOption
