import { useQuery } from '@tanstack/react-query'
import { useOnboarding } from 'hooks/useOnboarding'
import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride'
import { useMount } from 'react-use'
import { $fetch } from 'utils/fetch'

function StepText({ label, description }) {
  return (
    <div className="flex flex-col gap-2">
      <h3 className="font-semibold text-2xl font-taurus text-black">{label}</h3>
      <p className="text-sm font-taurus text-gray-500">{description}</p>
    </div>
  )
}

export default function SendCallTour() {
  const { setValue } = useFormContext()

  const {
    setState,
    state: { run, stepIndex, steps, personaId },
  } = useOnboarding()

  const { data: persona } = useQuery({
    queryKey: ['persona', personaId],
    queryFn: async () => {
      const { data } = await $fetch(`/call_config/read/${personaId}`)
      return data
    },
    enabled: !!personaId && run,
  })

  useMount(() => {
    setState({
      steps: [
        {
          target: 'body',
          content: <StepText label="Welcome to Send Call!" description="Now that you've configured your agent, let's send your first call." />,
          placement: 'center',
        },
        {
          target: '#basic',
          content: <StepText label="Basic Configuration" description="The agent you just configured has already been imported, and the call will be dispatched to your number." />,
          placement: 'top',
        },
        {
          target: '#model',
          content: <StepText label="Advanced Settings" description="There are plenty of other settings you can configure to fine-tune your call." />,
          placement: 'top',
        },
        {
          target: '.preset-group',
          content: <StepText label="Saved Personas" description="When you'd like to reuse a configuration, you can save and import them here." />,
          placement: 'top',
        },
        {
          target: '#show-code-button',
          content: <StepText label="Code Preview" description="If you're a developer, you can view the code for this configuration here." />,
        },
        {
          target: '#rainbow-button-wrapper',
          content: <StepText label="Send Call" description="Once you're happy with your configuration, click send to dispatch your call." />,
        },
        {
          target: '.call-logs',
          content: <StepText label="Call Logs" description="Once your call has finished, you can review the transcript and analysis from the Call Logs page." />,
          placement: 'right',
        },
        {
          target: '#side-menu',
          content: <StepText label="Inbound, Batches, Pathways, oh my!" description="Be sure to check out all the other features offered once you're done!" />,
          placement: 'right',
        },
      ],
    })
  })

  useEffect(() => {
    if (persona) {
      setValue('voice', persona.call_config.voice)
      setValue('task', persona.call_config.task)
    }
  }, [persona])

  const handleJoyrideCallback = (data) => {
    const { action, index, status, type } = data

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // Update state to advance the tour
      setState({ stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) })
      if (action === ACTIONS.NEXT && index === 4) {
        setState({ sideMenuOpen: true })
      }
    }
    else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setState({ run: false, stepIndex: 0, sideMenuOpen: false })
    }
  }

  return (
    <Joyride
      continuous
      disableScrolling
      stepIndex={stepIndex}
      steps={steps}
      showSkipButton
      locale={{
        last: 'Get Started',
      }}
      run={run}
      callback={handleJoyrideCallback}
      styles={{
        options: {
          primaryColor: '#000',
        },
      }}
    />
  )
}
