import Avatar from 'components/Avatar/Avatar'
import { Loading } from 'components/core/Loading'
import { useAuth } from 'hooks/useAuth'
import { IoReturnDownBackOutline } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

export default function AccountSelect() {
  const { orgs, selectOrg, loading } = useAuth()
  const navigate = useNavigate()
  return (
    <Wrapper className="border border-gray-300 max-w-xl">
      <h3 className="text-xl tracking-tight font-taurus font-bold">
        Select an Account
      </h3>
      <Accounts>
        {loading
          ? (
              <Loading loading={loading} />
            )
          : (
              orgs?.map(org => (
                <Account
                  key={org.org_id}
                  onClick={() => {
                    selectOrg(org.org_id)
                    navigate('/dashboard')
                  }}
                >
                  <Avatar org={org} />
                  <p className="font-taurus font-medium text-base mx-3">
                    {org.org_display_name}
                  </p>
                  <IoReturnDownBackOutline className="text-gray-500 ml-auto" />
                </Account>
              ))
            )}
      </Accounts>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  padding: 30px;
  border-radius: 12px;
  background-color: #ffffff;
  height: fit-content;
  max-height: 500px;
  overflow-y: scroll;
  width: 100%;
`

const Accounts = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 3px;
  justify-content: start;
`
const Account = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 9px 12px;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #fbfbfa;
  }
`
