import { Editor } from '@monaco-editor/react'
import { useFormContext } from 'react-hook-form'
import styled from 'styled-components'
import { parseForJson } from 'utils/funcs/send-call/parseForJson'
import 'utils/stylesheets/JSONMode.css'

export default function JSONMode() {
  const { watch, reset } = useFormContext()

  // remove fields we don't want for json editor
  const formState = watch()
  const filteredFormState = parseForJson(formState)

  function handleEditorChange(value) {
    try {
      const newFieldValues = JSON.parse(value)
      const { ...data } = newFieldValues
      // modify requestData into request_data
      reset({ json_mode_enabled: true, ...data })
    }
    catch (err) {
      // Silent error handling
    }
  }

  const data = JSON.stringify(filteredFormState, null, 2)

  return (
    <Wrapper>
      <Editor
        height="calc(100vh - 213px)"
        value={data}
        language="json"
        theme="vs-dark"
        onChange={handleEditorChange}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #1e1e1e;
  padding: 20px 0px;
  border-radius: 12px;
`
