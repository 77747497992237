import { datadogRum } from '@datadog/browser-rum'
import { useStatsigUser } from '@statsig/react-bindings'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { AuthContext } from 'contexts/authContext'
import {
  useCallback,
  useEffect,
  useMemo,
} from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useLocalStorage } from 'usehooks-ts'
import { $fetch } from 'utils/fetch'

export function AuthProvider({ children }) {
  const [orgId, selectOrg, removeOrgId] = useLocalStorage('orgId', null)
  const [authToken, setAuthToken, removeAuthToken] = useLocalStorage('authToken', null)

  const navigate = useNavigate()
  const location = useLocation()
  const queryClient = useQueryClient()
  const { user: statsigUser, updateUserSync } = useStatsigUser()

  const handleAuthResponse = useCallback(async (response) => {
    setAuthToken(response.jwt)
    if (response.org_id) {
      selectOrg(response.org_id)
    }
  }, [selectOrg, setAuthToken])

  // Sign-out clears tokens and directs to login.
  const signOut = useCallback(() => {
    removeAuthToken()
    removeOrgId()
    queryClient.clear()
    navigate('/login')
  }, [navigate, queryClient, removeAuthToken, removeOrgId])

  // Query to fetch user data using the authToken
  const {
    data: user,
    isLoading: userLoading,
    error: userError,
    refetch: refetchUser,
  } = useQuery({
    queryKey: ['user'],
    queryFn: () => $fetch('/user/get'),
    enabled: !!authToken,
    staleTime: Infinity,
  })

  useEffect(() => {
    if (userError) {
      signOut()
    }
    else if (user) {
      updateUserSync({
        userID: user?.user.id,
      })
      datadogRum.setUser({
        id: user?.user.id,
        name: `${user?.user.first_name} ${user?.user.last_name}`,
        email: user?.user.email,
        phone_number: user?.user.phone_number,
        placement_group: user?.user.placement_group,
      })
    }
  }, [userError, user])

  // Query to fetch organizations (orgs)
  const {
    data: orgs,
    isLoading: orgsLoading,
    refetch: refetchOrgs,
  } = useQuery({
    queryKey: ['orgs'],
    queryFn: async () => {
      const { data } = await $fetch(`/orgs/self/memberships`)
      return data
    },
    enabled: !!user,
    staleTime: Infinity,
  })

  const { data: org, isLoading: orgLoading, error: orgError } = useQuery({
    queryKey: ['org', orgId],
    queryFn: async () => {
      const { data } = await $fetch(`/orgs/${orgId}`)
      return data
    },
    enabled: !!user && !!orgId,
    staleTime: Infinity,
  })

  useEffect(() => {
    if (orgError && orgs) {
      const defaultOrg = orgs?.find(org => org.is_owner === true)
      if (defaultOrg) {
        selectOrg(defaultOrg.org_id)
      }
      else {
        signOut()
      }
    }
    else if (org) {
      updateUserSync({
        ...statsigUser,
        customIDs: {
          orgID: org?.id,
        },
      })
      datadogRum.setUserProperty('org_id', org?.id)
    }
  }, [orgError, org])

  // Query to fetch credits
  const {
    data: credits,
    refetch: refetchCredits,
  } = useQuery({
    queryKey: ['credits'],
    queryFn: async () => {
      const { current_balance } = await $fetch(`/user/getCredits`)
      return current_balance
    },
    enabled: !!user && !!orgId,
  })

  const loading = userLoading || orgsLoading || orgLoading
  const permissions = orgs?.find(org => org.org_id === orgId)?.permissions

  // Handle initial authentication flow.
  useEffect(() => {
    const isAuthRoute = ['/login', '/signup', '/auth'].includes(location.pathname)
    const isEmbedPathway = location.pathname.startsWith('/embed-pathway')

    // If no authToken, try legacy token or redirect to login.
    if (!user && !userLoading) {
      const legacyAuth = localStorage.getItem('auth')
      if (legacyAuth) {
        localStorage.removeItem('auth')
        setAuthToken(legacyAuth)
      }
      else {
        const isPublicRoute = location.pathname.startsWith('/showcase/tools')
          || location.pathname.startsWith('/embed-pathway')
          || location.pathname.startsWith('/showcase/pathway')
          || location.pathname.startsWith('/analytics/report')

        const isProtectedRoute = !isAuthRoute && !isPublicRoute

        if (isProtectedRoute && !isEmbedPathway)
          navigate('/login')
      }
    }
    else if (location.pathname === '/login' && org && !isEmbedPathway) {
      navigate('/dashboard')
    }
  }, [user, userLoading, org, location.pathname, navigate, setAuthToken])

  // Expose our auth data and functions in the context.
  const value = useMemo(
    () => ({
      authToken,
      user,
      orgs,
      credits,
      loading,
      handleAuthResponse,
      selectOrg,
      refetchUser,
      refetchOrgs,
      refetchCredits,
      signOut,
      org,
      permissions,
    }),
    [
      authToken,
      user,
      orgs,
      credits,
      loading,
      handleAuthResponse,
      selectOrg,
      refetchUser,
      refetchOrgs,
      refetchCredits,
      signOut,
      org,
      permissions,
    ],
  )

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
