import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert'
import { ShieldAlert } from 'lucide-react'

function InsufficientPermissions() {
  return (
    <div className="h-full bg-gray-50 flex items-center justify-center p-2.5">
      <div className="max-w-2xl w-full space-y-8">
        <div className="text-center w-full">
          <ShieldAlert className="mx-auto h-16 w-16 text-blue-400" />
          <p style={{ fontSize: 44, fontWeight: '450', marginTop: 20 }}>
            Limited Access
          </p>
          <p style={{ fontSize: 16, fontWeight: '450', marginTop: 10 }}>
            It looks like you might need additional permissions to view this
            page.
          </p>
        </div>

        <Alert className="bg-white border-l-4 border-yellow-400 p-2.5 shadow-md mt-10">
          <AlertTitle className="text-yellow-700 font-semibold">
            Insufficient Permissions
          </AlertTitle>
          <AlertDescription className="mt-1.5 text-xs text-gray-600">
            Your current role doesn't grant you access to this resource. If you
            believe this is an error, please contact your system administrator.
          </AlertDescription>
        </Alert>

        {/* <div className="mt-5 w-full">
          <Button
            onClick={() => window.history.back()}
            style={{ width: "100%", cursor: "pointer" }}
            size="3"
            color="indigo"
            variant="solid"
          >
            Go Back
          </Button>
        </div> */}
      </div>
    </div>
  )
}

export default InsufficientPermissions
