import { Badge, Button, Tabs } from '@radix-ui/themes'
import blandLogo from 'assets/brand/logo-infinity.svg'
import config from 'config'
import { useAuth } from 'hooks/useAuth'
import {
  ArrowLeft,
  Book,
  Calendar,
  ChevronDown,
  ChevronUp,
  Code,
  Globe,
  LinkIcon,
  Settings,
  User,
} from 'lucide-react'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { $fetch } from 'utils/fetch'
import { getApiKey } from 'utils/funcs/browser/getApiKey'
import { getOrgId } from 'utils/funcs/browser/getOrgId'

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 50px;

  @media (max-width: 912px) {
    grid-template-columns: 1fr;
  }
`

const AppContainer = styled.div`
  font-family: "Lato", sans-serif;
  max-width: 1000px;
  margin: 0 auto;
  padding: 0px 20px;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`

const AppDetails = styled.div``

const AppIcon = styled.img`
  width: 200px;
  height: 200px;
  aspect-ratio: 1/1;
  border-radius: 8px;
  object-fit: contain;
`

const AppName = styled.h1`
  margin: 0;
  padding: 0;
  font-size: 24px;
  font-weight: 600;
`

const Section = styled.div`
  margin-bottom: 0px;
`

const SectionTitle = styled.h2`
  font-size: 16px;
  margin-bottom: 5px;
  font-weight: 600;
`

const Link = styled.a`
  color: gray;
  text-decoration: none;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`

const CategoryTag = styled.span`
  background-color: #f4f4f4;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 10px;
  font-weight: 500;
`

const AppDetailsSidebar = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media (min-width: 912px) {
    width: 280px;
  }
`

const CategoryContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  width: auto;
`

function convertDateTimeToLocal(dateTimeString) {
  const date = new Date(dateTimeString)
  return `${date.toLocaleDateString()}`
}

function AppDetailsComponent({ app }) {
  const [isMobile, setIsMobile] = useState(false)
  const { user } = useAuth()
  const navigate = useNavigate()
  const orgId = getOrgId()
  const [addLoading, setAddLoading] = useState(false)

  const handleAddTool = async () => {
    try {
      setAddLoading(true)
      const response = await fetch(`${config.API_URL}/v1/tools`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': getApiKey(),
          ...(orgId && { 'x-bland-org-id': orgId }),
        },
        body: JSON.stringify({
          ...app.tool,
          public: false,
        }),
      })

      if (response.ok && response.status === 200) {
        const data = await response.json()
        if (data.status === 'success') {
          try {
            await $fetch(`/tools/clone?id=${app?.id}`, {
              method: 'POST',
              headers: {
                Authorization: getApiKey(),
                ...(orgId && { 'x-bland-org-id': orgId }),
              },
            })
          }
          catch (error) {
            // literally do nothing
          }

          setAddLoading(false)

          /// qp req handling //

          const setQpRequirements = []
          app?.settings?.forEach((req) => {
            if (req.is_sensitive) {
              setQpRequirements.push({
                hasValue: !!req.value,
                value: req.value || null,
                key: req.key,
              })
            }
          })
          let navUrl = `/dashboard/tool/${data?.tool_id}`
          const encodedRequirements
            = encodeURIComponent(JSON.stringify(setQpRequirements)) || null

          if (encodedRequirements) {
            navUrl += `&set_qp_requirements=${encodedRequirements}`
          }
          ////////

          navigate(navUrl)
          return toast.success('Tool added successfully')
        }
      }
    }
    catch (error) {}
  }

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 912)
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 912)
    handleResize() // Check on initial render
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <AppContainer>
      <Header>
        <Button
          onClick={() => (window.location.href = '/showcase/tools')}
          variant="ghost"
          color="gray"
          size="2"
          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
        >
          <ArrowLeft size={14} style={{ marginRight: '7px' }} />
          <p style={{ fontSize: 14, fontWeight: 'bold' }}>Browse All Tools</p>
        </Button>
      </Header>

      <Grid>
        <AppDetailsSidebar style={{}}>
          {isMobile && <AppName>{app.title}</AppName>}
          <AppDetails style={{ display: 'flex', justifyContent: 'center' }}>
            <AppIcon
              src={app.image_url || blandLogo}
              alt={`${app.title} icon`}
            />
          </AppDetails>
          {app.user_id === user?.user?.id
            ? (
                <Button
                  onClick={() => {
                    navigate(`/dashboard/tool/TL-${app.id}`)
                  }}
                  size="3"
                  variant="solid"
                  style={{ cursor: 'pointer' }}
                >
                  <p style={{ fontSize: 14, fontWeight: '600' }}>Edit Tool</p>
                </Button>
              )
            : user
              ? (
                  <Button
                    loading={addLoading}
                    onClick={() => handleAddTool()}
                    size="3"
                    variant="solid"
                    style={{ cursor: 'pointer' }}
                  >
                    <p style={{ fontSize: 14, fontWeight: '600' }}>Add Tool</p>
                  </Button>
                )
              : (
                  <Button
                    onClick={() => {
                      navigate(`/signup`)
                    }}
                    size="3"
                    variant="solid"
                    style={{ cursor: 'pointer' }}
                  >
                    <p style={{ fontSize: 14, fontWeight: '600' }}>
                      Sign Up to Add Tool
                    </p>
                  </Button>
                )}

          {isMobile && <AppDataComponent app={app} />}

          <Section>
            <SectionTitle>Pricing</SectionTitle>
            <p style={{ fontSize: 14, fontWeight: '400', color: '#000' }}>
              Free
            </p>
          </Section>

          <Section>
            <SectionTitle>Learn more & Support</SectionTitle>
            <Link target="_blank" href="https://docs.bland.ai/">
              <Globe size={16} style={{ marginRight: '5px' }} />
              {' '}
              Support Docs
            </Link>
            <Link>
              <User size={16} style={{ marginRight: '5px' }} />
              {' '}
              {app?.creator}
            </Link>
            <Link>
              <Calendar size={16} style={{ marginRight: '5px' }} />
              {' '}
              {convertDateTimeToLocal(app.created_at)}
            </Link>
          </Section>
        </AppDetailsSidebar>

        {!isMobile && <AppDataComponent app={app} showAppName />}
      </Grid>
    </AppContainer>
  )
}

function Accordion({ title, children, icon: Icon }) {
  const [isOpen, setIsOpen] = useState(true)

  return (
    <div className="border rounded-md overflow-hidden mb-2.5 shadow-sm transition-all duration-300 ease-in-out hover:shadow-md">
      <button
        className={`flex items-center justify-between w-full p-2.5 text-left bg-gray-50 hover:bg-gray-100 transition-colors duration-200 ${
          isOpen ? 'border-b' : ''
        }`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div
          className="flex items-center justify-center space-x-1.5"
          style={{ display: 'flex', alignItems: 'center', columnGap: 5 }}
        >
          <Icon className="w-3 h-3 text-blue-500" />
          <p
            style={{
              fontSize: 14,
              fontWeight: '600',
              color: '#000',
              marginBottom: '0',
            }}
          >
            {title}
          </p>
        </div>
        {isOpen
          ? (
              <ChevronUp className="w-3 h-3" />
            )
          : (
              <ChevronDown className="w-3 h-3" />
            )}
      </button>
      {isOpen && (
        <div className="p-2.5 bg-white">
          <div className="flex flex-col items-center gap-2.5">{children}</div>
        </div>
      )}
    </div>
  )
}

function AppDataComponent({ app, showAppName = false }) {
  return (
    <AppDetails>
      {showAppName && (
        <AppName style={{ marginBottom: '20px' }}>{app.title}</AppName>
      )}
      <Tabs.Root defaultValue="tool">
        <Tabs.List size="3">
          <Tabs.Trigger value="tool">Tool</Tabs.Trigger>
          <Tabs.Trigger value="description">Description</Tabs.Trigger>
        </Tabs.List>

        <Tabs.Content
          value="tool"
          style={{
            padding: '20px 0',
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
          }}
        >
          {app?.features?.length > 0 && (
            <div>
              <SectionTitle>Features</SectionTitle>
              <CategoryContainer>
                {app.features.map(feature => (
                  <CategoryTag key={feature}>{feature}</CategoryTag>
                ))}
              </CategoryContainer>
            </div>
          )}

          <Accordion title="Setup" icon={Book}>
            <div
              className="w-full"
              style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}
            >
              {[
                'Add the tool to your account.',
                'Setup the tool and add any required settings. This will typically include the API key.',
                'Save the tool.',
                'Include the tool or tool id in your calls.',
                'Reference available variables in your prompt or pathway.',
              ].map((step, key) => (
                <div
                  className="w-full"
                  key={key}
                  style={{ display: 'flex', alignItems: 'center', gap: '10px' }}
                >
                  <span className="flex items-center justify-center w-4 h-4 rounded-full bg-[#e9e9e9] text-gray text-xs leading-4">
                    {key + 1}
                  </span>
                  <p
                    style={{
                      fontSize: 14,
                      fontWeight: '400',
                      color: '#000',
                      margin: 0,
                      padding: 0,
                    }}
                  >
                    {step}
                  </p>
                </div>
              ))}
            </div>
          </Accordion>

          {app?.settings?.filter(setting => setting.is_sensitive === true)
            ?.length > 0 && (
            <Accordion title="Required to use" icon={Settings}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '10px',
                  width: '100%',
                }}
              >
                {app?.settings
                  ?.filter(setting => setting.is_sensitive === true)
                  ?.map((setting, key) => (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                        gap: '10px',
                      }}
                    >
                      <Badge size="2">{setting.key}</Badge>
                      <p
                        style={{
                          fontSize: 10,
                          fontWeight: '400',
                          color: 'grayText',
                          textAlign: 'left',
                        }}
                      >
                        Required to use the tool.
                      </p>
                      <LinkIcon size={16} style={{ marginLeft: 'auto' }} />
                    </div>
                  ))}
              </div>
            </Accordion>
          )}

          <div style={{}}>
            <Accordion title="Response Variables" icon={Code}>
              <p
                className="text-left text-xs text-gray-500"
                style={{ textAlign: 'left', width: '100%' }}
              >
                These variables can be referenced in your prompt or pathway.
              </p>
              {Object.keys(app.tool.response).map((reference, key) => (
                <div
                  key={key}
                  className="bg-gray-50 p-2.5 rounded border border-gray-200 w-full"
                >
                  <p style={{ fontSize: 12, fontWeight: '600', color: '#000' }}>
                    {reference}
                  </p>
                  <p style={{ fontSize: 10, fontWeight: '400', color: '#000' }}>
                    Response Path:
                    {' '}
                    {app.tool.response[reference]}
                  </p>
                  <div className="mt-2 p-2 bg-white rounded-">
                    <code style={{ fontSize: 11 }} className="text-black-400">
                      Let the user know that the example thing is
                      {' '}
                      {reference}
                      ...
                    </code>
                  </div>
                </div>
              ))}
            </Accordion>
          </div>
        </Tabs.Content>

        <Tabs.Content value="description" style={{ padding: '0px 0px' }}>
          <p
            style={{
              fontSize: 14,
              fontWeight: '400',
              color: '#000',
              margin: 0,
              padding: 0,
              whiteSpace: 'pre-wrap',
            }}
          >
            {app.description}
          </p>
        </Tabs.Content>
      </Tabs.Root>
    </AppDetails>
  )
}

export default AppDetailsComponent
