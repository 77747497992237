import FlowContext from 'components/Dashboard/ConvoPathways/contextFlow'
import React, { useCallback, useContext, useEffect, useRef } from 'react'
import { getConnectedEdges, useReactFlow } from 'reactflow'
import { v4 as uuidv4 } from 'uuid'

function CopyPasteController({ rfInstance, selectionStateRef }) {
  const { elements, triggerUpdate, isEditingEdge } = useContext(FlowContext)
  const flowContainerRef = useRef(null)
  const { project } = useReactFlow()
  const [mousePosition, setMousePosition] = React.useState({ x: 0, y: 0 })

  const isFlowFocused = useCallback(() => {
    const activeElement = document.activeElement
    return (
      flowContainerRef.current
      && (flowContainerRef.current.contains(activeElement)
        || activeElement === document.body)
      && !isEditingEdge
    )
  }, [isEditingEdge])

  const copySelectedNodes = useCallback(() => {
    if (!isFlowFocused())
      return

    const selectedNodes = elements.nodes.filter(node => node.selected)
    const nodeIds = selectedNodes.map(node => node.id)
    const relevantEdges = getConnectedEdges(
      selectedNodes,
      elements.edges,
    ).filter(
      edge => nodeIds.includes(edge.source) && nodeIds.includes(edge.target),
    )

    const copiedData = {
      nodes: selectedNodes,
      edges: relevantEdges,
    }

    localStorage.setItem('copiedFlowElements', JSON.stringify(copiedData))
  }, [elements, isFlowFocused])

  const cutSelectedNodes = useCallback(() => {
    if (!isFlowFocused())
      return

    copySelectedNodes() // This now uses our updated copySelectedNodes function

    const selectedNodeIds = elements.nodes
      .filter(node => node.selected)
      .map(node => node.id)

    const remainingNodes = elements.nodes.filter(node => !node.selected)
    const remainingEdges = elements.edges.filter(
      edge =>
        !selectedNodeIds.includes(edge.source)
        && !selectedNodeIds.includes(edge.target),
    )

    triggerUpdate(
      {
        nodes: remainingNodes,
        edges: remainingEdges,
      },
      false,
    )
  }, [elements, isFlowFocused, triggerUpdate, copySelectedNodes])

  const getPastePosition = () => {
    if (
      flowContainerRef.current
      && flowContainerRef.current.contains(
        document.elementFromPoint(mousePosition.x, mousePosition.y),
      )
    ) {
      return project({ x: mousePosition.x, y: mousePosition.y })
    }
    else {
      const containerWidth = flowContainerRef.current.clientWidth
      const containerHeight = flowContainerRef.current.clientHeight
      const centerX = containerWidth / 2
      const centerY = containerHeight / 2
      return project({ x: centerX, y: centerY })
    }
  }

  const pasteNodes = useCallback(() => {
    if (!isFlowFocused())
      return

    const copiedData = localStorage.getItem('copiedFlowElements')
    if (!copiedData)
      return

    const { nodes: copiedNodes, edges: copiedEdges } = JSON.parse(copiedData)

    const pastePosition = getPastePosition() // Implement this helper function based on your existing logic

    const offsetX = pastePosition.x - copiedNodes[0].position.x
    const offsetY = pastePosition.y - copiedNodes[0].position.y

    const oldToNewIdMap = new Map()

    const newNodes = copiedNodes.map((node) => {
      const newId = uuidv4()
      oldToNewIdMap.set(node.id, newId)

      node.data.isStart = false

      return {
        ...node,
        id: newId,
        position: {
          x: node.position.x + offsetX,
          y: node.position.y + offsetY,
        },
        selected: false,
      }
    })

    const newEdges = copiedEdges.map(edge => ({
      ...edge,
      id: uuidv4(),
      source: oldToNewIdMap.get(edge.source),
      target: oldToNewIdMap.get(edge.target),
    }))

    triggerUpdate(
      {
        nodes: [...elements.nodes, ...newNodes],
        edges: [...elements.edges, ...newEdges],
      },
      false,
    )
  }, [elements, isFlowFocused, triggerUpdate])

  const selectAllNodes = useCallback(
    (event) => {
      if (!isFlowFocused())
        return

      event.preventDefault()

      const updatedNodes = elements.nodes.map((node) => {
        selectionStateRef.current[node.id] = true
        return { ...node, selected: true }
      })

      triggerUpdate(
        {
          nodes: updatedNodes,
          edges: elements.edges,
        },
        true,
      )
    },
    [elements, triggerUpdate, isFlowFocused, selectionStateRef],
  )
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.ctrlKey || event.metaKey) {
        if (!event.shiftKey && event.key === 'c') {
          copySelectedNodes()
        }
        else if (!event.shiftKey && event.key === 'v') {
          pasteNodes()
        }
        else if (!event.shiftKey && event.key === 'x') {
          cutSelectedNodes()
        }
        else if (event.key === 'a') {
          selectAllNodes(event)
        }
      }
    }

    const handleMouseMove = (event) => {
      setMousePosition({ x: event.clientX, y: event.clientY })
    }

    document.addEventListener('keydown', handleKeyDown)
    document.addEventListener('mousemove', handleMouseMove)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
      document.removeEventListener('mousemove', handleMouseMove)
    }
  }, [copySelectedNodes, pasteNodes, cutSelectedNodes, selectAllNodes])

  useEffect(() => {
    flowContainerRef.current = document.querySelector('.react-flow')
  }, [])

  return <></>
}

export default CopyPasteController
