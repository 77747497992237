import { useQuery } from '@tanstack/react-query'
import { Helmet } from 'react-helmet-async'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { $fetch } from 'utils/fetch'
import { curatedTools } from 'utils/sitemap/curatedTools'
import { Header } from './components/Header'
import AppDetailsComponent from './SlugPage'

export default function ToolSlug() {
  const { id } = useParams()
  const navigate = useNavigate()

  // Determine if the id is a curated tool slug
  const curatedToolRedirect = curatedTools.find(tool => tool.slug === id)
  const curatedTool = curatedTools.find(tool => tool.id === id)

  // Always call useQuery but disable it if we've got a redirect.
  const { data: tool, isFetching: isLoadingTool } = useQuery({
    queryKey: ['tool', id],
    queryFn: async () => {
      const { data: toolData } = await $fetch(`/showcase/public/tools/${id}`)
      return toolData?.data
    },
    enabled: !!id && !curatedToolRedirect,
    staleTime: Infinity,
    meta: {
      errorMessage: 'Failed to load tool',
    },
  })

  if (curatedToolRedirect) {
    return (
      <Navigate
        to={`/showcase/tools/${curatedToolRedirect.id}`}
        replace
      />
    )
  }

  if (isLoadingTool) {
    return <div>Loading...</div>
  }

  if (!tool) {
    return navigate('/showcase/tools')
  }

  return (
    <Container>
      {curatedTool && (
        <Helmet>
          <link
            rel="canonical"
            href={`https://app.bland.ai/showcase/tools/${curatedTool.slug}`}
          />
          <title>{curatedTool.metaTitle}</title>
          <meta name="description" content={curatedTool.metaDescription} />
        </Helmet>
      )}
      <Header />
      <div style={{ padding: '20px 70px' }}>
        <AppDetailsComponent app={tool} />
      </div>
    </Container>
  )
}

const Container = styled.div`
  margin: 0 auto;
`
