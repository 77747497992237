import { $fetch } from 'utils/fetch'

class SimulationsApi {
  constructor(apiKey) {
    this.baseUrl = '/v1/pathway/test-cases'
  }

  // Fetch all simulations for a pathway
  async fetchSimulations(params) {
    const { pathwayId } = params
    return $fetch(`/v1/pathway/test-cases/${pathwayId}`, {
      method: 'GET',
    })
  }

  // Fetch recent runs for a simulation
  async fetchRecentRuns(params) {
    const { pathwayId } = params
    return $fetch(`/v1/pathway/test-cases/${pathwayId}/calls`, {
      method: 'GET',
    })
  }

  // Create a new simulation
  async createSimulation({
    pathway_id,
    call_id,
    name,
    pathway_version,
    request_data,
    start_node_id,
    success_criteria,
    input_messages,
  }) {
    return $fetch(`/v1/pathway/test-cases/add`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        pathway_id,
        call_id,
        name,
        request_data,
        start_node_id,
        success_criteria,
        input_messages,
        pathway_version,
      }),
    })
  }

  // Run a simulation
  async runSimulation(simulationId, versionNumber) {
    return $fetch(`/v1/pathway/test-cases/${simulationId}/run`, {
      method: 'POST',
      body: JSON.stringify({
        version_number: versionNumber,
      }),
      headers: {
        'content-type': 'application/json',
      },
    })
  }

  // Check simulation status
  async checkSimulationStatus(callId) {
    return $fetch(`/v1/pathway/test-cases/calls/${callId}`, {
      method: 'GET',
    })
  }

  // Update a simulation
  async updateSimulation(simulationId, updateData) {
    return $fetch(`/v1/pathway/test-cases/${simulationId}`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify(updateData),
    })
  }

  async deleteSimulation(simulationId) {
    return $fetch(`/v1/pathway/test-cases/${simulationId}`, {
      method: 'DELETE',
    })
  }

  async getPathwayLogs(call_id) {
    return $fetch(`/v1/pathway/test-cases/${call_id}/logs`, {
      method: 'GET',
    })
  }
}

export default SimulationsApi
