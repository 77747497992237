import { useMemo } from 'react'
import { useWindowSize } from 'usehooks-ts'

const TOPBAR_HEIGHT = 86
const ROW_HEIGHT = 44
const TAKE_EXTRA_ROWS = 10

export function useCallLogsPagination() {
  const { height: windowHeight } = useWindowSize()
  
  const take = useMemo(
    () => Math.floor((windowHeight - TOPBAR_HEIGHT) / ROW_HEIGHT) + TAKE_EXTRA_ROWS,
    [windowHeight],
  )

  return { take, TAKE_EXTRA_ROWS, ROW_HEIGHT }
}
