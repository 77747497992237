import { Controller, useFormContext } from 'react-hook-form'
import { useAuth } from './useAuth'

export function useFieldRegistration() {
  const methods = useFormContext()
  const { user, org } = useAuth()

  const registerField = (field) => {
    const Component = field.component
    const condition = field.condition
    const disabled = field.disabled
    const uncontrolled = field.uncontrolled
    let isDisabled = false

    const getWatchValue = (watchSource, defaultWatchFn) => {
      switch (watchSource) {
        case 'user':
          return user
        case 'org':
          return org
        default:
          return defaultWatchFn(watchSource)
      }
    }

    if (condition) {
      const { watch, evaluate } = condition
      const value = getWatchValue(watch, methods.watch)

      if (!evaluate(value)) {
        return null
      }
    }

    if (disabled) {
      const { watch, evaluate } = disabled
      const value = getWatchValue(watch, methods.watch)

      if (evaluate(value)) {
        isDisabled = true
      }
    }

    const props = {
      ...field.props,
      isDisabled,
      isInvalid: isDisabled,
      errorMessage: isDisabled ? field.disabled.message : undefined,
    }

    if (uncontrolled) {
      return (
        <Component {...props} />
      )
    }

    return (
      <Controller
        key={field.name}
        name={field.name}
        control={methods.control}
        render={({ field: controller }) => <Component {...controller} {...props} />}
      />
    )
  }

  return { registerField }
}
