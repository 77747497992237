import { Button } from '@radix-ui/themes'
// import Button from "components/core/Button";
import ActionBar from 'components/core/ActionBar'
import { PageTitle } from 'components/core/PageTitle'
import ActiveCallsView from 'components/Dashboard/CallLogs/ActiveCalls/ActiveCallsView.jsx'
import {
  CheckCircle,
  ChevronRight,
} from 'lucide-react'
import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { endActiveCall } from 'utils/funcs/send-call/endActiveCall'

export default function ActiveCall({
  callId = '77d5dc71-f488-4c22-90ad-eef040e8ca24',
  apiKey,
}) {
  const { setValue } = useFormContext()
  const [isEnding, setIsEnding] = useState(false)
  const [isEnded, setIsEnded] = useState(false)
  const [elipses, setElipses] = useState(0)
  const [selectedCallId, setSelectedCallId] = useState(callId)
  const navigate = useNavigate()

  // Simple animated ellipses
  useEffect(() => {
    const intervalId = setInterval(() => {
      setElipses(prev => (prev + 1) % 4)
    }, 500)
    return () => clearInterval(intervalId)
  }, [])

  const handleEnd = async () => {
    setIsEnding(true)
    try {
      const { is_call_ended } = await endActiveCall(selectedCallId, apiKey)
      if (is_call_ended) {
        setIsEnded(true)
      }
      else {
        toast.error('Failed to end the call. Please try again.')
      }
    }
    catch (error) {
      console.error('Error ending call:', error)
      toast.error('An error occurred while ending the call.')
    }
    finally {
      setIsEnding(false)
    }
  }

  const handleClose = () => {
    setValue('isCallActive', false)
  }

  const handleViewCallLog = () => {
    navigate(`/dashboard/call-logs/${selectedCallId}`)
  }

  if (isEnded) {
    return (
      <div className="flex flex-col items-center justify-center h-full">
        <div
          style={{
            border: '1px solid #e9e9e9',
            borderRadius: 8,
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
          }}
          className="mb-2.5 p-2.5"
        >
          <CheckCircle className="text-green-500" />
        </div>
        <h2 style={{ fontSize: 24, fontWeight: '450' }}>
          Call Ended Successfully
        </h2>
        <div className="flex space-x-2.5 mt-5">
          <Button
            type="button"
            size="3"
            onClick={handleClose}
            variant="outline"
            color="gray"
            style={{ cursor: 'pointer' }}
          >
            Close
          </Button>
          <Button
            type="button"
            size="3"
            onClick={handleViewCallLog}
            variant="outline"
            color="indigo"
            style={{ cursor: 'pointer' }}
          >
            <div className="flex items-center gap-1.5">
              View Call Logs
              <ChevronRight size={14} />
            </div>
          </Button>
        </div>
      </div>
    )
  }

  return (
    <div className="flex flex-col h-full overflow-hidden">
      <ActionBar top spaceBetween shiftRight>
        <PageTitle>Transcript</PageTitle>
        <div className="flex flex-row gap-2">
          <Button
            type="button"
            size="3"
            color="gray"
            variant="outline"
            onClick={handleClose}
            style={{ color: 'black', cursor: 'pointer' }}
            // className="bg-dark font-medium text-white px-2.5 py-1.5 rounded-sm border border-black shadow-sm transition-all hover:bg-black hover:shadow-lg self-center"
          >
            Close
          </Button>
          <Button
            size="3"
            color="indigo"
            style={{ cursor: 'pointer' }}
            type="button"
            onClick={handleEnd}
            disabled={isEnding}
            // className="bg-dark font-medium text-white px-2.5 py-1.5 rounded-sm border border-black shadow-sm transition-all hover:bg-black hover:shadow-lg self-center"
          >
            End Call
          </Button>
        </div>
      </ActionBar>

      <div className="flex-1 overflow-auto">
        <ActiveCallsView
          handleViewDetails={handleViewCallLog}
          selectedCallId={selectedCallId}
          setSelectedCallId={setSelectedCallId}
        />
      </div>
    </div>
  )
}
