import { Button } from '@/components/ui/button'
import { Switch } from '@headlessui/react'
import { useContext, useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import { $fetch } from 'utils/fetch'
import FlowContext from '../contextFlow'
import { TextAreaWithSuggestion } from './promptImprovementTextArea'

function AmazonConnectModal({ id, data, setIsOpen }) {
  const [name, setName] = useState(data.name || '')
  const [webhookUrl, setWebhookUrl] = useState(data.webhookUrl || '')
  const [text, setText] = useState(data.text || '')
  const [prompt, setPrompt] = useState(data.prompt || '')
  const [useStaticText, setUseStaticText] = useState(!data.prompt)

  const textareaRef = useRef(null)
  const { triggerUpdate, elements } = useContext(FlowContext)

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto'
      const newHeight = Math.min(textareaRef.current.scrollHeight, 200)
      textareaRef.current.style.height = `${newHeight}px`
    }
  })

  const handleSave = () => {
    const updatedNodes = elements.nodes.map((el) => {
      if (el.id === id) {
        return {
          ...el,
          data: {
            name,
            webhookUrl,
            text: useStaticText ? text : null,
            prompt: useStaticText ? null : prompt,
            isStart: data.isStart ?? false,
          },
          type: 'Amazon Connect',
        }
      }
      return el
    })

    setIsOpen(false)
    triggerUpdate({ nodes: updatedNodes }, false)
  }

  const handleTestWebhook = async () => {
    try {
      const response = await $fetch(`/tools/run`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          body: { dtmf: '12345', call_id: '12345' },
          url: webhookUrl,
          method: 'POST',
        }),
        ignoreResponseError: true,
      })

      if (!response.errors) {
        toast('Test request sent successfully!')
      }
      else {
        toast.warn(
          'Failed to send test request. Please check the URL and try again. Make sure the URL is accessible from the internet (CORS handled).',
        )
      }
    }
    catch (error) {
      toast.error(`Error sending test request: ${error.message}`)
    }
  }

  const amazonButtonStyle
    = 'bg-gradient-to-r from-[#FF9900] to-[#FFC300] text-white py-1.5 px-2.5 rounded hover:from-[#FFA500] hover:to-[#FFD700]'
  const amazonFocusStyle
    = 'focus:border-[#FF9900] focus:ring-[#FF9900] focus:ring-1 focus:outline-none'

  return (
    <div className="space-y-4">
      {/* Node Name Section */}
      <section className="space-y-1.5">
        <label
          htmlFor="name"
          className="block text-xs font-medium text-gray-700"
        >
          Node Name
        </label>
        <input
          type="text"
          id="name"
          value={name}
          onChange={e => setName(e.target.value)}
          className={`w-full px-2 py-1.5 border border-gray-300 rounded shadow-sm text-xs ${amazonFocusStyle}`}
          placeholder="Enter node name"
        />
      </section>

      {/* Dialogue Section */}
      <section className="space-y-2.5">
        <div className="flex justify-between items-center">
          <h3 className="text-sm font-medium text-gray-900">Agent Speech</h3>
          <div className="flex items-center space-x-1.5">
            <Switch
              checked={useStaticText}
              onChange={setUseStaticText}
              className={`${
                useStaticText
                  ? 'bg-gradient-to-r from-[#FF9900] to-[#FFC300]'
                  : 'bg-gray-200'
              } relative inline-flex h-4 w-7 items-center rounded-full transition-colors duration-200 ease-in-out`}
            >
              <span
                className={`${
                  useStaticText ? 'translate-x-4' : 'translate-x-0.5'
                } inline-block h-2.5 w-2.5 transform rounded-full bg-white transition-transform duration-200 ease-in-out`}
              />
            </Switch>
            <span className="text-xs font-medium text-gray-700">
              {useStaticText ? 'Static Text' : 'AI Generated'}
            </span>
          </div>
        </div>
        <p className="text-xs text-gray-600">
          The dialogue below will be spoken by the agent while collecting DTMF
          tones sent by Amazon Connect. The user's speech will be ignored until
          all DTMF tones have been collected.
          {' '}
          <br />
          Example: "Welcome to Bland. Please hold while I look up your
          information..."
        </p>
        {useStaticText
          ? (
              <textarea
                placeholder="Exact text to be spoken by the agent"
                value={text}
                onChange={e => setText(e.target.value)}
                className={`w-full px-2 py-1.5 border border-gray-300 rounded shadow-sm text-xs min-h-[100px] ${amazonFocusStyle}`}
              />
            )
          : (
              <TextAreaWithSuggestion
                ref={textareaRef}
                placeholder="Provide a short goal/prompt for what the agent needs to do - e.g. Ask for the customer's name"
                value={prompt}
                onChange={val => setPrompt(val)}
                className={`w-full px-2 py-1.5 border border-gray-300 rounded shadow-sm text-xs min-h-[100px] ${amazonFocusStyle}`}
              />
            )}
      </section>

      {/* Webhook URL Section */}
      <section className="space-y-2.5">
        <h3 className="text-sm font-medium text-gray-900">Webhook URL</h3>
        <p className="text-xs text-gray-600">
          This URL will be called after collecting all DTMF tones from Amazon
          Connect. A POST request will be sent with 'dtmf' as the key and the
          collected tones as the value. It will also be saved as a 'dtmf'
          variable in the conversation context.
        </p>
        <div className="flex space-x-1.5">
          <input
            type="text"
            id="webhookUrl"
            value={webhookUrl}
            onChange={e => setWebhookUrl(e.target.value)}
            className={`flex-grow px-2 py-1.5 border border-gray-300 rounded shadow-sm text-xs ${amazonFocusStyle}`}
            placeholder="Enter webhook URL for DTMF buffer"
          />
          <Button
            onClick={handleTestWebhook}
            className={`${amazonButtonStyle} !py-1.5 !px-2.5`}
          >
            Test API
          </Button>
        </div>
      </section>

      {/* Save Button */}
      <Button
        onClick={handleSave}
        className={`${amazonButtonStyle} !py-4 !px-4`}
      >
        Save
      </Button>
    </div>
  )
}

export default AmazonConnectModal
