import { forwardRef, Input } from '@heroui/react'

const VoicemailInput = forwardRef((props, ref) => {
  return (
    <Input
      {...props}
      ref={ref}
      variant="bordered"
      radius="sm"
      label="Voicemail Message"
      labelPlacement="outside"
      placeholder="Hey, just checking in!"
      description={(
        <p>
          When the AI encounters a voicemail, it will leave this message after the
          beep and then immediately end the call.
          <br />
          <strong>Warning:</strong>
          {' '}
          If
          {' '}
          <strong>ivr_mode</strong>
          {' '}
          is set to
          {' '}
          <strong>true</strong>
          {' '}
          or voicemail_action is set to
          ignore, then this will still work for voicemails, but it will not hang up
          for IVR systems.
        </p>
      )}
    />
  )
})

export default VoicemailInput
