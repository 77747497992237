import { Copy, GitFork, Hash, Layers, PhoneOutgoing, Tag } from 'lucide-react'

const iconStyle = 'bg-none h-4 w-4 flex-1'
export const actionItems = [
  {
    highlight: true,
    title: 'Conversational Pathways',
    description: 'Design infinitely complex, branching conversational flows',
    link: '/dashboard/convo-pathways',
    icon: <GitFork size={16} />,
  },
  {
    highlight: true,
    title: 'Send Phone Call',
    description: 'Use our visual editor to dispatch phone calls',
    link: '/dashboard/send-call',
    icon: <PhoneOutgoing size={16} />,
  },

  {
    title: 'Send Bulk Calls',
    description:
      'Upload a CSV or JSON file to send out a large number of calls all at once',
    beta: false,
    link: '/dashboard/batch-call',
    icon: <Layers size={16} />,
  },
  {
    title: 'Buy Phone Number',
    description:
      'Instantly purchase and configure a phone number for your business',
    beta: false,
    link: '/dashboard/phone-numbers',
    icon: <Hash size={16} />,
  },
  {
    title: 'Voices & Voice Cloning',
    description: 'View the voices you have access to or clone a new voice.',
    beta: false,
    link: '/dashboard/voices',
    icon: <Copy size={16} />,
  },
  {
    title: 'Purchase Credits',
    description:
      'Purchase more credits to send out more calls and support our platform.',
    beta: false,
    link: '/dashboard/pay',
    icon: <Tag size={16} />,
  },
]
