import { Button } from '@radix-ui/themes'
import { AlertTriangle, ChevronLeft } from 'lucide-react'

export default function NotFound() {
  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 to-gray-100 flex items-center justify-center p-2.5">
      <div className="w-full max-w-3xl">
        <div className="bg-white rounded-md shadow-xl overflow-hidden">
          <div className="p-5 sm:p-8">
            <div className="flex items-center justify-between mb-5">
              <AlertTriangle className="text-gray-200 w-8 h-8" />
              <div className="text-right">
                <h1 className="text-3xl font-bold text-gray-900">404</h1>
                <p className="text-base text-gray-600">Page Not Found</p>
              </div>
            </div>
            <div className="space-y-6">
              <div className="h-1.5 bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 rounded-full" />
              <p className="text-gray-700 text-sm">
                The page you're looking for doesn't exist or has been moved.
              </p>
              <div className="flex flex-col sm:flex-row gap-2.5">
                <Button
                  color="iris"
                  variant="ghost"
                  style={{ cursor: 'pointer' }}
                  onClick={() => window.history.back()}
                >
                  <ChevronLeft size={13} />
                  Go back
                </Button>
              </div>
            </div>
          </div>
          <div className="bg-gray-50 px-5 py-4 sm:px-8 sm:py-5">
            <div className="flex flex-wrap justify-between items-center gap-2.5">
              <div className="flex items-center space-x-2.5">
                <div className="w-2 h-2 bg-green-500 rounded-full animate-pulse" />
                <span className="text-xs text-gray-600">
                  System Operational
                </span>
              </div>
              <div className="flex space-x-2.5">
                <a
                  href="https://docs.bland.ai"
                  className="text-xs text-gray-600 hover:text-gray-900 transition-colors"
                >
                  Help Center
                </a>
                <a
                  href="https://status.bland.ai"
                  className="text-xs text-gray-600 hover:text-gray-900 transition-colors"
                >
                  Status
                </a>
                <a
                  href="mailto:hello@bland.ai"
                  className="text-xs text-gray-600 hover:text-gray-900 transition-colors"
                >
                  Contact
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-5 text-center">
          <p className="text-xs text-gray-500">
            © 2024 Bland AI. All rights reserved.
          </p>
        </div>
      </div>
    </div>
  )
}
