import Tooltip from '@mui/material/Tooltip'
import { TooltipText } from '_components/Text'
import Switch from 'components/core/Switch'

export default function BlockInterruptions({ fieldName }) {
  const longText = (
    <span>
      Enabling this feature will
      {' '}
      <strong>not</strong>
      {' '}
      allow the user to
      interrupt the agent, while the agent is speaking.
      <br />
      This leads to a more controlled, but less natural conversation, as the
      user will have to wait for the agent to finish speaking before they can
      speak.
    </span>
  )
  return (
    <Tooltip>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <TooltipText tooltip={longText} tooltipClassName="w-[300px]">
          Block Interruptions
        </TooltipText>
        <Switch sx={{ m: 5 }} fieldName={fieldName} />
      </div>
    </Tooltip>
  )
}
