import {
  Button,
  ButtonGroup,
  cn,
  Input,
  Link,
  Select,
  SelectItem,
  Tooltip,
} from '@heroui/react'
import { useFilter } from '@react-aria/i18n'
import { useQuery } from '@tanstack/react-query'
import { isValidPhoneNumber } from 'libphonenumber-js'
import { AlertCircle, Search } from 'lucide-react'
import React, { useMemo, useRef, useState } from 'react'
import { MdFormatSize, MdFormatStrikethrough } from 'react-icons/md'
import {
  defaultCountries,
  FlagImage,
  parseCountry,
  usePhoneInput,
} from 'react-international-phone'
import { useToggle } from 'usehooks-ts'
import { $fetch } from 'utils/fetch'
import 'react-international-phone/style.css'

export default function PhoneInput({
  value,
  onChange,
  description,
  fullWidth = false,
  label,
  size = 'md',
  className,
  hideBannedOutbound = false,
  ...props
}) {
  const [searchQuery, setSearchQuery] = useState('')
  const focusRef = useRef(null)
  const [isFormatted, toggleFormatting] = useToggle(true)
  const isLogin = window.location.pathname === '/login'

  const { data: { data: bannedCountries } = {} } = useQuery({
    queryKey: ['banned-countries'],
    queryFn: () => $fetch('/user/banned-countries'),
    staleTime: Infinity,
    gcTime: Infinity,
    enabled: hideBannedOutbound,
  })

  const availableCountries = useMemo(() => {
    return bannedCountries
      ? defaultCountries.filter(country => !bannedCountries.includes(country[1]?.toUpperCase()))
      : defaultCountries
  }, [bannedCountries])

  const { contains } = useFilter({
    sensitivity: 'base', // Ignore case and diacritics
  })

  const { inputValue, handlePhoneValueChange, inputRef, country, setCountry }
    = usePhoneInput({
      defaultCountry: 'us',
      value: value ?? '',
      countries: availableCountries,
      onChange: (data) => {
        if (onChange) {
          // Pass the unformatted number to react-hook-form
          onChange(data.phone)
        }
      },
      disableDialCodeAndPrefix: isFormatted,
      disableFormatting: !isFormatted,
    })

  const selectedCountry = useMemo(() => {
    if (!availableCountries.length || !country) {
      return null
    }

    return availableCountries.find(
      countryItem => parseCountry(countryItem).iso2 === country.iso2,
    )
  }, [availableCountries, country])

  // Filter countries based on search query
  const filteredCountries = useMemo(() => {
    // Filter countries based on search query
    let filtered = !searchQuery.trim()
      ? [...availableCountries]
      : availableCountries.filter((countryItem) => {
          const parsedCountry = parseCountry(countryItem)
          return contains(parsedCountry.name, searchQuery)
        })

    // Remove the selected country from the filtered list if it exists
    if (country) {
      filtered = filtered.filter(
        countryItem => parseCountry(countryItem).iso2 !== country.iso2,
      )
    }

    // Add the selected country at the beginning of the array
    return selectedCountry
      ? [selectedCountry, ...filtered]
      : filtered
  }, [searchQuery, country, availableCountries])

  const searchFilter = React.useMemo(() => {
    return (
      <Input
        autoFocus
        size="sm"
        type="search"
        variant="flat"
        value={searchQuery}
        onChange={e => setSearchQuery(e.target.value)}
        startContent={(<Search size={16} />)}
        placeholder="Search countries..."
      />
    )
  }, [searchQuery])

  const handleFormatToggle = () => {
    toggleFormatting()

    if (onChange) {
      onChange('')
    }

    setTimeout(() => {
      if (focusRef.current) {
        focusRef.current.focus()
      }
    }, 0)
  }

  const formatButton = () => {
    return (
      <Tooltip content={isFormatted ? 'Disable formatting' : 'Enable formatting'}>
        <Button isIconOnly variant="light" size="sm" onPress={handleFormatToggle}>
          {isFormatted ? <MdFormatStrikethrough className="text-gray-500" /> : <MdFormatSize className="text-gray-500" />}
        </Button>
      </Tooltip>
    )
  }

  const renderValue = () => {
    return (
      <div className={cn('flex items-center gap-1.5', { 'pr-1': size === 'sm', 'pr-2': size === 'md', 'pr-3': size === 'lg' })}>
        <FlagImage iso2={country.iso2} />
        <span>
          +
          {country.dialCode}
        </span>
      </div>
    )
  }

  return (
    <div className={cn('flex flex-col shrink-0', fullWidth && 'w-full', className)}>
      {label && (
        <div className="flex flex-col gap-1 mb-2">
          <label className="text-small font-medium text-foreground">{label}</label>
          {description && <p className="text-tiny text-foreground-400 mb-2">{description}</p>}
        </div>
      )}

      <ButtonGroup className="w-full items-start">
        {isFormatted && (
          <Select
            size={size}
            color="primary"
            variant="bordered"
            items={filteredCountries}
            isVirtualized
            radius="sm"
            selectedKeys={[country.iso2]}
            maxListboxHeight={300}
            onChange={(e) => {
              setCountry(e.target.value)
              setTimeout(() => {
                if (focusRef.current) {
                  focusRef.current.focus()
                }
              }, 200)
            }}
            classNames={{
              trigger: 'rounded-r-none',
              base: 'min-w-[80px] w-fit shrink-0',
              popoverContent: '!w-64 rounded-sm',
              innerWrapper: 'w-full',
              selectorIcon: cn('end-0.5', { 'end-1': size === 'lg' }),
            }}
            renderValue={renderValue}
            listboxProps={{
              classNames: {
                base: 'max-h-[300px] overflow-scroll p-0 scrollbar-hide',
                list: 'max-h-[200px] shrink',
              },
              topContent: searchFilter,
              bottomContent: hideBannedOutbound && (
                <div className="text-tiny items-center gap-1 flex border border-100 bg-gray-50 p-1 text-foreground-400">
                  <AlertCircle size={14} />
                  Showing available countries to call.
                </div>
              ),
            }}
            aria-label="Country code selector"
          >
            {(countryItem) => {
              const country = parseCountry(countryItem)
              return (
                <SelectItem
                  key={country.iso2}
                  startContent={(
                    <span className="flex items-center gap-2">
                      <FlagImage
                        iso2={country.iso2}
                      />
                      <p className="text-gray-500 font-geist-mono">
                        +
                        {country.dialCode}
                      </p>
                    </span>
                  )}
                >
                  {country.name}
                </SelectItem>
              )
            }}
          </Select>
        )}
        <Input
          color="primary"
          variant="bordered"
          placeholder="Phone number"
          type="tel"
          radius="sm"
          value={inputValue}
          onChange={handlePhoneValueChange}
          size={size}
          ref={focusRef}
          baseRef={inputRef}
          classNames={{ inputWrapper: cn('rounded-l-none', { 'pr-0.5': size === 'sm', 'pr-1': size === 'md', 'pr-2': size === 'lg' }) }}
          endContent={formatButton()}
          validate={(value) => {
            if (!value?.length) {
              return null
            }

            if (!isValidPhoneNumber(value, country.iso2.toUpperCase())) {
              return 'Please enter a valid phone number'
            }

            return null
          }}
          {...props}
        />
      </ButtonGroup>

      {isLogin && country.iso2 === 'au' && (
        <div className="text-tiny text-foreground-400 mt-2">
          Hi Australia! We migrated your international phone number to the correct format. Try logging in without the mobile 0 prefix. If you continue to experience issues, please contact support at
          {' '}
          <a href="mailto:hello@bland.ai" className="text-primary">hello@bland.ai</a>
          .
        </div>
      )}
    </div>
  )
}
