// CONVERTS:

// {
// "Hello" : "Blandy",
// "Voice" : "Agent"
// }

// TO

// [
//    {
//       "key": "Hello",
//       "value": "Blandy"
//     },
//     {
//       "key": "Voice",
//       "value": "Agent"
//     }
//  ]

export function objectToKeyValue(object) {
  // Check if the input is already in the desired format
  if (
    Array.isArray(object)
    && object.every(
      item =>
        typeof item === 'object'
        && item.hasOwnProperty('key')
        && item.hasOwnProperty('value'),
    )
  ) {
    return object
  }

  // Convert the object into the desired format if it wasn't already
  return Object.entries(object || {}).map(([key, value]) => ({
    key, // You might want to transform the key here if necessary
    value,
  }))
}
