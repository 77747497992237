import { cn } from '@heroui/react'
import { useFieldRegistration } from 'hooks/useFieldRegistration'

export default function LinearForm({ formDefinition, className, isNested = false }) {
  const { registerField } = useFieldRegistration()

  return (
    <div className={cn(
      'flex flex-col gap-7 w-full',
      isNested && 'pl-4 border-l border-gray-200 ml-4 bg-gray-50 bg-opacity-20',
      className,
    )}
    >
      {formDefinition.fields.map((field) => {
        return registerField(field)
      })}
    </div>
  )
}
