import { Alert, Button, Input, Tooltip } from '@heroui/react'
import { Plus, Trash } from 'lucide-react'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'

const DEFAULT_COMPONENT = {
  component: Input,
  props: {
    variant: 'bordered',
    radius: 'sm',
    placeholder: 'Enter a value',
  },
}

const DEFAULT_LABELS = {
  add: 'Add item',
  remove: 'Remove',
}

export default function MultiInput({
  name,
  label,
  component = DEFAULT_COMPONENT,
  description,
  labels = DEFAULT_LABELS,
  minInputs = 0,
  maxInputs = Infinity,
  className = '',
  defaultValue = '',
  errorMessage,
  isDisabled,
  children,
  ...rest
}) {
  const { watch } = useFormContext()
  const { fields, append, remove } = useFieldArray({
    name,
  })
  const watchFieldArray = watch(name)
  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index],
    }
  })

  // Extract the actual component to use
  const InputComponent = component.component

  return (
    <div className={`flex flex-col gap-2 ${className}`} {...rest}>
      <span className="flex flex-col gap-1">
        {label && <label className="text-small font-medium text-foreground">{label}</label>}
        {description && <p className="text-tiny text-foreground-400 mb-2">{description}</p>}
        {errorMessage && <Alert radius="sm" color="warning" variant='faded'>{errorMessage}</Alert>}
      </span>
      {controlledFields?.map((field, index) => (
        <div key={field.id} className="flex items-center gap-2">

          <Controller
            name={`${name}.${index}`}
            render={({ field: controllerField }) => InputComponent && !children
              ? (
                  <InputComponent
                    name={`${name}.${index}`}
                    {...component.props}
                    {...controllerField}
                  />
                )
              : (
                  children({
                    index,
                  })
                )}
          />

          {/* Remove button */}
          {fields.length > minInputs && (
            <Tooltip content={labels.remove}>
              <Button
                isIconOnly
                variant="light"
                color="danger"
                aria-label={labels.remove}
                onPress={() => remove(index)}
              >
                <Trash className="h-4 w-4" />
              </Button>
            </Tooltip>
          )}
        </div>
      ))}

      {/* Add button */}
      {fields.length < maxInputs && (
        <Button
          variant="flat"
          color="primary"
          isDisabled={isDisabled}
          radius="sm"
          size="sm"
          startContent={<Plus className="h-4 w-4" />}
          onPress={() => append(defaultValue)}
          className="mt-2 mr-auto"
        >
          {labels.add}
        </Button>
      )}
    </div>
  )
}
