import { Button } from '@radix-ui/themes'
import { Phone } from 'lucide-react'
import { useState } from 'react'
import WebClientDropDown from './WebClientDropDown'

function LocalTestButton({
  version,
  pathwayId,
  setCallID,
  setShowChat,
  setShowChatInterface,
}) {
  const [isSlideOutOpen, setIsSlideOutOpen] = useState(false)

  return (
    <div className="relative inline-block text-left">
      <Button
        size="2"
        variant="soft"
        style={{ cursor: 'pointer' }}
        onClick={() => setIsSlideOutOpen(true)}
      >
        Web Client
        <Phone size="1em" />
      </Button>

      <WebClientDropDown
        isOpen={isSlideOutOpen}
        onClose={() => setIsSlideOutOpen(false)}
        pathwayId={pathwayId}
        version={version}
        setCallID={setCallID}
        setShowChat={setShowChat}
        setShowChatInterface={setShowChatInterface}
      />
    </div>
  )
}

export default LocalTestButton
