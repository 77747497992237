import { Chip, cn, forwardRef, Radio, RadioGroup } from '@heroui/react'
import { Rocket, Sparkle } from 'lucide-react'
import logo from '../../assets/brand/new-bland-logo.svg'

function BrandingFooter() {
  return (
    <span className="flex absolute scale-75 invisible xl:visible font-taurus uppercase tracking-widest group-data-[selected=true]:text-primary gap-1 items-center right-0 text-2xs -bottom-2 opacity-30 group-data-[selected=true]:opacity-50 transition-opacity duration-200">
      Built by
      <img alt="logo" src={logo} className="size-10  group-data-[selected=true]:[filter:brightness(0)_saturate(100%)_invert(42%)_sepia(99%)_saturate(1000%)_hue-rotate(190deg)_brightness(119%)_contrast(101%)]" />
    </span>
  )
}

// Model option component with standardized structure
function ModelOption({ icon: Icon, label, chipText, chipColor, description, value }) {
  return (
    <ModelRadio description={description} value={value}>
      <span className="flex items-center gap-2">
        <Icon className="group-data-[selected=true]:fill-primary-500" size={20} />
        {label}
        <Chip className="ml-2" radius="sm" variant="flat" color={chipColor} size="sm">{chipText}</Chip>
      </span>
      <BrandingFooter />
    </ModelRadio>
  )
}

function ModelRadio(props) {
  const { children, ...otherProps } = props

  return (
    <Radio
      {...otherProps}
      classNames={{
        base: cn(
          'm-0 bg-content hover:bg-content2 items-center justify-between',
          'flex-row-reverse w-full max-w-full cursor-pointer rounded-lg gap-4 p-4 border-2 border-transparent',
          'data-[selected=true]:border-primary border-gray-200 data-[selected=true]:bg-primary-50',
        ),
        label: cn(
          'font-medium',
          'group-data-[selected=true]:text-primary static',
        ),
        description: cn(
          'group-data-[selected=true]:text-primary-400',
        ),
      }}
    >
      {children}
    </Radio>
  )
}

// Model data for easy configuration and extension
const MODEL_OPTIONS = [
  {
    icon: Sparkle,
    label: 'CORE',
    chipText: 'Recommended',
    chipColor: 'primary',
    description: 'Best choice for most use cases. Reliable instruction-following, full feature support, and fast.',
    value: 'base',
  },
  {
    icon: Rocket,
    label: 'TURBO',
    chipText: 'Fast',
    chipColor: 'warning',
    description: '3x faster responses but less reliable for complex tasks. Use only when speed is critical.',
    value: 'turbo',
  },
]

const ModelSelect = forwardRef((props, ref) => {
  return (
    <RadioGroup
      ref={ref}
      orientation="horizontal"
      classNames={{
        wrapper: 'flex flex-row flex-nowrap',
        label: 'text-black',
      }}
      {...props}
    >
      {MODEL_OPTIONS.map(modelOption => (
        <ModelOption key={modelOption.value} {...modelOption} />
      ))}
    </RadioGroup>
  )
})

export default ModelSelect
