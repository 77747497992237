import { Switch } from '@headlessui/react'
import { useContext, useState } from 'react'
import { toast } from 'sonner'
import FlowContext from '../../../contextFlow'
import { TextAreaWithSuggestion } from '../../promptImprovementTextArea'

function ScheduleMeetingModal({ id, data, setIsOpen }) {
  const [name, setName] = useState(data.name || '')

  const [meetingLink, setMeetingLink] = useState(data.meetingLink || '')

  const [timezoneOption, setTimezoneOption] = useState(
    data.timezoneOption || 'ask',
  )
  const [selectedTimezone, setSelectedTimezone] = useState(
    data.selectedTimezone || 'America/New_York',
  )
  const [assumeTimezone, setAssumeTimezone] = useState(
    data.assumeTimezone || true,
  )
  const [prompt, setPrompt] = useState(data.prompt ?? '')

  const calendlyTimezones = {
    'America/Los_Angeles': 'Pacific Time - US & Canada',
    'America/Denver': 'Mountain Time - US & Canada',
    'America/Chicago': 'Central Time - US & Canada',
    'America/New_York': 'Eastern Time - US & Canada',
    'America/Anchorage': 'Alaska Time',
    'America/Phoenix': 'Arizona, Yukon Time',
    'America/St_Johns': 'Newfoundland Time',
    'Pacific/Honolulu': 'Hawaii Time',
    'America/Adak': 'America/Adak',
    'America/Argentina/Buenos_Aires': 'Buenos Aires Time',
    'America/Asuncion': 'Asuncion Time',
    'America/Bogota': 'Bogota, Jamaica, Lima Time',
    'America/Campo_Grande': 'America/Campo Grande',
    'America/Caracas': 'Caracas Time',
    'America/Godthab': 'America/Godthab',
    'America/Goose_Bay': 'Atlantic Time',
    'America/Guatemala': 'Saskatchewan, Guatemala, Costa Rica Time',
    'America/Havana': 'America/Havana',
    'America/Mazatlan': 'America/Mazatlan',
    'America/Mexico_City': 'Mexico City Time',
    'America/Montevideo': 'Montevideo Time',
    'America/Miquelon': 'America/Miquelon',
    'America/Noronha': 'America/Noronha',
    'America/Santiago': 'Santiago Time',
    'America/Santa_Isabel': 'America/Santa Isabel',
    'America/Santo_Domingo': 'Atlantic Standard Time',
    'America/Sao_Paulo': 'Brasilia Time',
    'Africa/Casablanca': 'Morocco Time',
    'Africa/Cairo': 'Africa/Cairo',
    'Africa/Johannesburg': 'Central Africa Time',
    'Africa/Lagos': 'West Africa Time',
    'Africa/Windhoek': 'Africa/Windhoek',
    'Asia/Amman': 'Jordan Time',
    'Asia/Baghdad': 'Baghdad, East Africa Time',
    'Asia/Baku': 'Asia/Baku',
    'Asia/Beirut': 'Lebanon Time',
    'Asia/Damascus': 'Syria Time',
    'Asia/Dhaka': 'Asia/Dhaka',
    'Asia/Dubai': 'Dubai Time',
    'Asia/Gaza': 'Asia/Gaza',
    'Asia/Irkutsk': 'Asia/Irkutsk',
    'Asia/Jakarta': 'Indochina Time',
    'Asia/Jerusalem': 'Israel Time',
    'Asia/Kabul': 'Kabul Time',
    'Asia/Kamchatka': 'Pacific/Majuro',
    'Asia/Karachi': 'Pakistan, Maldives Time',
    'Asia/Kathmandu': 'Kathmandu Time',
    'Asia/Kolkata': 'India, Sri Lanka Time',
    'Asia/Krasnoyarsk': 'Krasnoyarsk Time',
    'Asia/Omsk': 'Asia/Omsk',
    'Asia/Rangoon': 'Asia/Rangoon',
    'Asia/Shanghai': 'China, Singapore, Perth',
    'Asia/Tehran': 'Tehran Time',
    'Asia/Tokyo': 'Japan, Korea Time',
    'Asia/Vladivostok': 'Asia/Vladivostok',
    'Asia/Yakutsk': 'Asia/Yakutsk',
    'Asia/Yekaterinburg': 'Yekaterinburg Time',
    'Asia/Yerevan': 'Asia/Yerevan',
    'Atlantic/Azores': 'Azores Time',
    'Atlantic/Cape_Verde': 'Cape Verde Time',
    'Australia/Adelaide': 'Adelaide Time',
    'Australia/Brisbane': 'Brisbane Time',
    'Australia/Darwin': 'Australia/Darwin',
    'Australia/Eucla': 'Australia/Eucla',
    'Australia/Lord_Howe': 'Australia/Lord Howe',
    'Australia/Perth': 'Australia/Perth',
    'Australia/Sydney': 'Sydney, Melbourne Time',
    'UTC': 'UTC Time',
    'Europe/Berlin': 'Central European Time',
    'Europe/Helsinki': 'Eastern European Time',
    'Europe/London': 'UK, Ireland, Lisbon Time',
    'Europe/Minsk': 'Minsk Time',
    'Europe/Moscow': 'Moscow Time',
    'Europe/Chisinau': 'Moldova Time',
    'Europe/Istanbul': 'Turkey Time',
    'Pacific/Apia': 'Pacific/Apia',
    'Pacific/Auckland': 'Auckland Time',
    'Pacific/Chatham': 'Pacific/Chatham',
    'Pacific/Easter': 'Pacific/Easter',
    'Pacific/Fiji': 'Pacific/Fiji',
    'Pacific/Gambier': 'Pacific/Gambier',
    'Pacific/Kiritimati': 'Pacific/Kiritimati',
    'Pacific/Majuro': 'Pacific/Majuro',
    'Pacific/Marquesas': 'Pacific/Marquesas',
    'Pacific/Norfolk': 'Pacific/Norfolk',
    'Pacific/Noumea': 'Pacific/Noumea',
    'Pacific/Pago_Pago': 'Pacific/Pago Pago',
    'Pacific/Pitcairn': 'Pacific/Pitcairn',
    'Pacific/Tarawa': 'Pacific/Tarawa',
    'Pacific/Tongatapu': 'Pacific/Tongatapu',
    'Africa/Nairobi': 'East Africa Time',
    'America/Argentina/San_Luis': 'Western Argentina Time',
    'America/Cayenne': 'French Guiana Time',
    'America/Eirunepe': 'Acre Time',
    'America/Guayaquil': 'Ecuador Time',
    'America/Guyana': 'Guyana Time',
    'America/La_Paz': 'Bolivia Time',
    'America/Lima': 'Peru Time',
    'America/Paramaribo': 'Suriname Time',
    'America/Scoresbysund': 'East Greenland Time',
    'Antarctica/Davis': 'Davis Time',
    'Antarctica/DumontDUrville': 'Dumont-d\'Urville Time',
    'Antarctica/Macquarie': 'Macquarie Island Time',
    'Antarctica/Mawson': 'Mawson Time',
    'Antarctica/Rothera': 'Rothera Time',
    'Antarctica/Syowa': 'Syowa Time',
    'Antarctica/Vostok': 'Vostok Time',
    'Asia/Almaty': 'Kazakhstan Time',
    'Asia/Anadyr': 'Anadyr Time',
    'Asia/Ashgabat': 'Turkmenistan Time',
    'Asia/Bangkok': 'Indochina Time',
    'Asia/Bishkek': 'Kyrgyzstan Time',
    'Asia/Brunei': 'Brunei Darussalam Time',
    'Asia/Calcutta': 'India Standard Time',
    'Asia/Choibalsan': 'Choibalsan Time',
    'Asia/Dili': 'East Timor Time',
    'Asia/Dushanbe': 'Tajikistan Time',
    'Asia/Hong_Kong': 'Hong Kong Time',
    'Asia/Hovd': 'Hovd Time',
    'Asia/Jayapura': 'Eastern Indonesia Time',
    'Asia/Katmandu': 'Nepal Time',
    'Asia/Kuala_Lumpur': 'Malaysia Time',
    'Asia/Magadan': 'Magadan Time',
    'Asia/Makassar': 'Central Indonesia Time',
    'Asia/Manila': 'Philippine Time',
    'Asia/Novosibirsk': 'Novosibirsk Time',
    'Asia/Sakhalin': 'Sakhalin Time',
    'Asia/Singapore': 'Singapore Standard Time',
    'Asia/Taipei': 'Taipei Time',
    'Asia/Tashkent': 'Uzbekistan Time',
    'Asia/Tbilisi': 'Georgia Time',
    'Asia/Thimphu': 'Bhutan Time',
    'Asia/Ulaanbaatar': 'Ulaanbaatar Time',
    'Atlantic/South_Georgia': 'South Georgia Time',
    'Atlantic/Stanley': 'Falkland Islands Time',
    'Europe/Samara': 'Samara Time',
    'Indian/Chagos': 'Indian Ocean Time',
    'Indian/Christmas': 'Christmas Island Time',
    'Indian/Cocos': 'Cocos Islands Time',
    'Indian/Kerguelen': 'French Southern & Antarctic Time',
    'Indian/Mahe': 'Seychelles Time',
    'Indian/Maldives': 'Maldives Time',
    'Indian/Mauritius': 'Mauritius Time',
    'Indian/Reunion': 'Réunion Time',
    'Pacific/Port_Moresby': 'Papua New Guinea Time',
    'Pacific/Bougainville': 'Papua New Guinea (Bougainville) Time',
    'Pacific/Efate': 'Vanuatu Time',
    'Pacific/Enderbury': 'Phoenix Islands Time',
    'Pacific/Fakaofo': 'Tokelau Time',
    'Pacific/Funafuti': 'Tuvalu Time',
    'Pacific/Galapagos': 'Galapagos Time',
    'Pacific/Guadalcanal': 'Solomon Islands Time',
    'Pacific/Guam': 'Chamorro Standard Time',
    'Pacific/Kosrae': 'Kosrae Time',
    'Pacific/Nauru': 'Nauru Time',
    'Pacific/Niue': 'Niue Time',
    'Pacific/Palau': 'Palau Time',
    'Pacific/Ponape': 'Ponape Time',
    'Pacific/Rarotonga': 'Cook Islands Time',
    'Pacific/Tahiti': 'Tahiti Time',
    'Pacific/Truk': 'Chuuk Time',
    'Pacific/Wake': 'Wake Island Time',
    'Pacific/Wallis': 'Wallis & Futuna Time',
  }

  const calendlyRegex = /^https?:\/\/(?:www\.)?calendly\.com\/[^/]+\/[^/]+$/

  const { triggerUpdate, elements } = useContext(FlowContext)

  const handleSave = () => {
    if (!calendlyRegex.test(meetingLink)) {
      toast.error('Please enter a valid Calendly link. The link must include https:// or http://')
      return
    }

    const updatedNodes = elements.nodes.map((el) => {
      if (el.id === id) {
        return {
          ...el,
          data: {
            name,
            meetingLink,
            prompt,
            isStart: data.isStart ?? false,
            timezoneOption,
            selectedTimezone,
            assumeTimezone,
          },
          type: 'Schedule Meeting',
        }
      }
      return el
    })

    setIsOpen(false)
    triggerUpdate({ nodes: updatedNodes }, false)
  }

  return (
    <div className="space-y-2.5">
      <div className="space-y-1.5">
        <p className="text-[12px] text-gray-600 mb-2.5">
          This node allows you to schedule meetings using Calendly. You can set
          up a Calendly link, specify how to handle timezones, and customize the
          node's name. The AI will use this information to assist users in
          scheduling meetings through your conversation flow.
        </p>
        <label
          htmlFor="name"
          className="block text-[12px] font-medium text-gray-700"
        >
          Name
        </label>
        <input
          type="text"
          id="name"
          value={name}
          onChange={e => setName(e.target.value)}
          className="w-full px-1.5 py-2 border border-gray-300 rounded shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-[12px]"
          placeholder="Enter node name"
        />
      </div>

      {/* Dialogue Section */}
      <section className="space-y-2.5">
        <div className="flex justify-between items-center">
          <h3 className="text-sm font-medium text-gray-900">Agent Speech</h3>
        </div>
        <p className="text-xs text-gray-600">
          The dialogue below will be spoken by the agent to guide the user
          through scheduling a meeting. It also details the instructions on how
          the agent will gather meeting information from the user.
        </p>
        <TextAreaWithSuggestion
          placeholder="Provide a short goal/prompt for what the agent needs to do - e.g. Ask for the customer's name"
          value={prompt}
          onChange={val => setPrompt(val)}
          className="w-full px-2 py-1.5 border border-gray-300 rounded shadow-sm text-xs min-h-[100px]"
        />
      </section>

      <div className="space-y-1.5">
        <label
          htmlFor="meetingLink"
          className="block text-[12px] font-medium text-gray-700"
        >
          Calendly Meeting Link
        </label>
        <input
          type="text"
          id="meetingLink"
          value={meetingLink}
          style={{
            borderColor:
              meetingLink && !calendlyRegex.test(meetingLink) ? 'red' : '',
          }}
          onChange={e => setMeetingLink(e.target.value)}
          className="w-full px-1.5 py-2 border border-gray-300 rounded shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-[12px]"
          placeholder="Enter a Calendly link"
        />
      </div>

      <div className="space-y-1.5">
        <label className="block text-[12px] font-medium text-gray-700">
          Timezone Handling
        </label>
        <p className="text-[11px] text-gray-500 mb-1.5">
          Choose how you want to handle timezone selection for scheduling
          meetings.
        </p>
        <select
          value={timezoneOption}
          onChange={e => setTimezoneOption(e.target.value)}
          className="w-full px-1.5 py-2 border border-gray-300 rounded shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-[12px]"
        >
          <option value="ask">Ask User</option>
          {/* <option value="dropdown">Static Timezone</option> */}
        </select>
      </div>

      {timezoneOption === 'ask' && (
        <div className="bg-yellow-100 border-l-4 border-yellow-500 p-2.5 mb-2.5">
          <p className="text-[12px] text-yellow-700 font-medium">
            Agent Asks Timezone
          </p>
          <p className="text-[11px] text-yellow-600 mt-0.5 mb-1.5">
            When this node is activated, the AI will explicitly ask the caller
            to specify their timezone. This ensures accurate scheduling across
            different regions.
          </p>
          <div className="flex items-center">
            <Switch
              checked={assumeTimezone}
              onChange={setAssumeTimezone}
              className={`${
                assumeTimezone ? 'bg-indigo-600' : 'bg-gray-200'
              } relative inline-flex h-4 w-7 items-center rounded-full`}
            >
              <span className="sr-only">Assume timezone if not specified</span>
              <span
                className={`${
                  assumeTimezone ? 'translate-x-4' : 'translate-x-0.5'
                } inline-block h-2.5 w-2.5 transform rounded-full bg-white transition`}
              />
            </Switch>
            <span className="ml-2 font-medium text-[12px] text-yellow-700">
              Assume timezone if not specified
            </span>
          </div>
          {assumeTimezone && (
            <div>
              <p className="text-[12px] text-yellow-700 font-medium">
                Assume Timezone
              </p>

              <select
                id="timezone"
                value={selectedTimezone}
                onChange={e => setSelectedTimezone(e.target.value)}
                className="w-full px-1.5 py-2 border border-gray-300 rounded shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-[12px]"
              >
                {Object.entries(calendlyTimezones).map(([key, value]) => (
                  <option key={key} value={key}>
                    {value}
                  </option>
                ))}
              </select>
            </div>
          )}
        </div>
      )}

      <button
        onClick={handleSave}
        className="bg-indigo-500 text-white py-1.5 px-2.5 rounded mt-2.5"
      >
        Save
      </button>
    </div>
  )
}

export default ScheduleMeetingModal
