import config from 'config'
import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { BiCheckCircle, BiCopy } from 'react-icons/bi'
import SyntaxHighlighter from 'react-syntax-highlighter/dist/esm/default-highlight'
import { vs2015 } from 'react-syntax-highlighter/dist/esm/styles/hljs'
import styled from 'styled-components'
import { parseForJson } from 'utils/funcs/send-call/parseForJson'

export default function CodePreview({ collapseCode }) {
  const [copied, setCopied] = useState(false)
  const [language, setLanguage] = useState('javascript')

  const { watch } = useFormContext()
  const formState = watch()

  const filteredFormState = parseForJson(formState)

  const data = JSON.stringify(
    filteredFormState,
    null,
    2,
  )

  const customSyntaxStyle = {
    width: '100%',
    background: 'none',
    backgroundColor: 'none',
    padding: '0px',
    margin: '0px',
    fontSize: '12px',
    paddingBottom: '20px',
  }

  const codeStrings = {
    bash: `curl -X POST '${config.API_URL}/v1/calls'
-H 'Content-Type: application/json' 
-H 'Authorization: 'API_KEY'
-d '${data}'
      `,

    javascript: `// Headers  
const headers = {
   'Authorization': 'API_KEY', ${formState.encrypted_key
      ? `
   'encrypted_key': '${formState.encrypted_key}'`
      : ''}
};

// Data
const data = ${data}

// API request
await axios.post('https://api.bland.ai/v1/calls', data, {headers});
`,

    python: `# Headers
headers = {
   'Authorization': 'API_KEY',
}

# Data
data = ${data}

# API request 
requests.post('https://api.bland.ai/v1/calls', json=data, headers=headers)
      `,
  }

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false)
      }, 5000)
    }
  }, [copied])

  return (
    <Wrapper collapseCode={collapseCode}>
      {!collapseCode && (
        <CodeWrapper collapseCode={collapseCode}>
          <CodeHeader>
            <CodeSelector
              label="Language"
              value={language}
              onChange={e => setLanguage(e.target.value)}
            >
              <option value="javascript">JavaScript</option>
              <option value="python">Python</option>
              <option value="bash">cURL</option>
            </CodeSelector>
            <div
              className="text-white py-1.5 px-2.5 rounded-sm flex items-center hover:bg-gray-800 cursor-pointer"
              onClick={() => {
                setCopied(true)
              }}
            >
              {!copied
                ? (
                    <BiCopy className="mr-1.5" />
                  )
                : (
                    <BiCheckCircle className="mr-1.5" />
                  )}
              {!copied ? 'Copy Code' : 'Copied!'}
            </div>
          </CodeHeader>
          <SyntaxHighlighter
            wrapLongLines
            language={language}
            style={vs2015}
            customStyle={customSyntaxStyle}
          >
            {codeStrings[language].toString()}
          </SyntaxHighlighter>
        </CodeWrapper>
      )}
    </Wrapper>
  )
}
const Wrapper = styled.div`
  position: relative;
  overflow: scroll;
  max-height: 100%;
  scrollbar-width: none;
  width: 100%;    
  padding: 10px;
  height: 100%;
  min-width: ${({ collapseCode }) =>
    collapseCode ? '0px' : '500px'}; /* Adjusted */  
  ::selection {
    color: lightblue;
  }
`

const CodeWrapper = styled.div`
  padding: 20px 30px 0px 30px;
  background-color: #1e1e1e;
  display: flex;
  border-radius: 20px;
  height: 100%;
  flex-direction: column;  
`

const CodeSelector = styled.select`
  background-color: transparent;
  color: white;
  outline: none;
  &:hover {
    cursor: pointer;
  }
`

const CodeHeader = styled.div`
  width: 100%;
  display: flex;
  position: sticky;
  top: 0;
  background-color: #1e1e1e;
  margin-bottom: 10px;
  align-items: center;
  justify-content: space-between;
`
