import { Loading } from 'components/core/Loading'
import { motion } from 'framer-motion'
import { ThumbsDown, ThumbsUp, AlertCircle } from 'lucide-react'
import { useEffect, useState } from 'react'
import { fetchAndUpdateTranscriptStatus } from '../../../utils/funcs/call-logs/fetchTranscript'
import { Tooltip } from '@heroui/react'

export default function CallTranscript({
  initialTranscripts,
  loadingTranscript,
  callId,
}) {
  const [transcripts, setTranscripts] = useState([])

  useEffect(() => {
    setTranscripts(initialTranscripts)
  }, [initialTranscripts])

  const handleSetFeedbackStatus = async (txId, status) => {
    setTranscripts((prevTranscript) => {
      return prevTranscript.map((item) => {
        if (item.id === txId) {
          return { ...item, status: item.status === status ? null : status }
        }
        else {
          return item
        }
      })
    })

    if (status !== 'THUMB_UP' && status !== 'THUMB_DOWN')
      return

    const s
      = transcripts.find(doc => doc.id === txId).status === status
        ? null
        : status

    await fetchAndUpdateTranscriptStatus(callId, txId, s)
  }

  const processMessageText = (text) => {
    const interruptionPattern = /<block interruptions enabled. this message was ignored>/i;
    
    const hasInterruption = interruptionPattern.test(text);
    
    const cleanedText = hasInterruption 
      ? text.replace(/<block interruptions enabled. this message was ignored>/gi, "").trim() 
      : text;
    
    return { cleanedText, hasInterruption };
  }

  return (
    <>
      {loadingTranscript
        ? (
            <Loading loading={loadingTranscript} />
          )
        : transcripts?.length > 0
          ? (
              <div className="relative">
                <motion.div
                  className="space-y-2.5 overflow-auto transition-[height] duration-300 h-auto"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.5 }}
                >
                  {transcripts.map((entry, index) => {
                    const { cleanedText, hasInterruption } = processMessageText(entry.text);
                    
                    return (
                      <motion.div
                        key={entry.id}
                        className={`flex ${
                          entry.user.toLowerCase() === 'user'
                            ? 'justify-start'
                            : 'justify-end'
                        }`}
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: index * 0.05 }}
                      >
                        <div
                          className={`max-w-[85%] ${
                            entry.user.toLowerCase() === 'user'
                              ? 'bg-gray-100'
                              : 'bg-blue-50'
                          } rounded-md p-2 shadow-sm`}
                        >
                          {entry.user.toLowerCase() !== 'user' && (
                            <div className="flex items-center mb-0.5">
                              <span className="bg-blue-100 text-blue-800 text-xs font-medium px-1.5 py-px rounded-sm">
                                Bland
                              </span>
                            </div>
                          )}
                          <div className="relative">
                            {hasInterruption && (
                              <Tooltip 
                                content="This message was skipped due to 'Block Interruptions' being enabled"
                                color="default"
                                placement="bottom"
                                delay={0}
                                closeDelay={100}
                                className="mb-1.5"
                              >
                                <div className="flex items-center gap-1 text-xs text-gray-500 cursor-default">
                                  <AlertCircle size={12} className="text-gray-400" />
                                  <span>Skipped</span>
                                </div>
                              </Tooltip>
                            )}
                            <p className="text-xs text-gray-800" style={{ fontSize: 13 }}>
                              {cleanedText}
                            </p>
                          </div>
                          {entry.user.toLowerCase() !== 'user' && (
                            <div className="flex items-center mt-1.5 space-x-1.5">
                              <button
                                onClick={() =>
                                  handleSetFeedbackStatus(entry.id, 'THUMB_UP')}
                                className="focus:outline-none"
                              >
                                <ThumbsUp
                                  size={14}
                                  color={
                                    entry.status === 'THUMB_UP' ? '#3b82f6' : '#9ca3af'
                                  }
                                />
                              </button>
                              <button
                                onClick={() =>
                                  handleSetFeedbackStatus(entry.id, 'THUMB_DOWN')}
                                className="focus:outline-none"
                              >
                                <ThumbsDown
                                  size={14}
                                  color={
                                    entry.status === 'THUMB_DOWN'
                                      ? '#ef4444'
                                      : '#9ca3af'
                                  }
                                />
                              </button>
                            </div>
                          )}
                        </div>
                      </motion.div>
                    );
                  })}
                </motion.div>
              </div>
            )
          : (
              <div className="flex items-center justify-center p-2.5 bg-gray-50 rounded-md">
                <p className="text-xs text-gray-500">No transcript available</p>
              </div>
            )}
    </>
  )
}
