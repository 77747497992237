import { forwardRef, Slider } from '@heroui/react'

const InterruptionRange = forwardRef((props, ref) => {
  return (
    <div className="flex flex-col gap-2">
      <Slider
        defaultValue={100}
        label="Interruption Threshold"
        maxValue={200}
        formatOptions={{ style: 'unit', unit: 'millisecond' }}
        minValue={50}
        step={10}
        {...props}
        ref={ref}
        classNames={{
          label: 'text-small font-medium text-foreground',
        }}
      />
      <p className="text-tiny text-foreground-400">
        Adjusts how patient the AI is when waiting for the user to finish
        speaking. Lower values mean the AI will respond more quickly, while higher values
        mean the AI will wait longer before responding.
      </p>
    </div>
  )
})

export default InterruptionRange
