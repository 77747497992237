export function formatDuration(startTimeMs) {
  if (!startTimeMs)
    return '0:00'

  const diff = Math.floor((Date.now() - startTimeMs) / 1000)
  const minutes = Math.floor(diff / 60)
  const seconds = diff % 60

  return `${minutes}:${seconds.toString().padStart(2, '0')}`
}
