// File: AllTools.jsx

import { Dialog, Transition } from '@headlessui/react'
import { Button, Flex, Spinner } from '@radix-ui/themes'
import ActionBar from 'components/core/ActionBar'
import { PageTitle } from 'components/core/PageTitle'
import { PageWrapper } from 'components/core/PageWrapper'
import GradientLoadingAnimation from 'components/Reusables/GradientLoadingAnimation'
import config from 'config'
import Nango from '@nangohq/frontend';
import { useMutation } from '@tanstack/react-query';
import { AnimatePresence, motion } from 'framer-motion'
import {
  AlertCircle,
  Code,
  Copy,
  Edit2,
  ExternalLink,
  Globe,
  Key,
  Plus,
  Settings,
  Store,
  Trash2,
  Zap,
} from 'lucide-react'
import { $fetch } from 'utils/fetch'
import { Fragment, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getApiKey } from 'utils/funcs/browser/getApiKey'
import { getOrgId } from 'utils/funcs/browser/getOrgId'
import { useQuery } from '@tanstack/react-query'

import NoToolsPopup from './NoToolPopUp'

// If true, forcibly show the popup even if user has tools.
const forceNoToolsPopup = false
// Duration for the rainbow shift animation in the NoToolsPopup
const rainbowAnimationDuration = 48


/* -------------------------------------------------------------------------- */
/* --------------------------- Helper Functions ------------------------------ */
function truncateDesc(description) {
  if (!description) return ''
  if (description.length > 100) {
    return `${description.substring(0, 100)}...`
  }
  return description
}

function getRandomSubset(arr, size) {
  if (!Array.isArray(arr)) return []
  const shuffled = [...arr].sort(() => 0.5 - Math.random())
  return shuffled.slice(0, size)
}

/* -------------------------------------------------------------------------- */
/* ----------------------------- Loading Skeleton ---------------------------- */
function LoadingFeaturedCard() {
  return (
    <div className="w-60 h-52 border border-gray-300 rounded-md p-3 mr-4 flex-shrink-0 animate-pulse">
      <div className="w-full h-20 bg-gray-200 mb-2" />
      <div className="h-4 bg-gray-300 w-2/3 mb-2" />
      <div className="h-3 bg-gray-300 w-1/2" />
    </div>
  )
}

function LoadingToolsRow() {
  return (
    <div className="border-b border-gray-300 py-3 px-2 flex items-center justify-between animate-pulse">
      <div className="flex flex-col space-y-1 w-2/3">
        <div className="w-1/2 h-4 bg-gray-200" />
        <div className="w-2/3 h-3 bg-gray-200" />
      </div>
      <div className="flex gap-2">
        <div className="w-5 h-5 bg-gray-200 rounded-md" />
        <div className="w-5 h-5 bg-gray-200 rounded-md" />
        <div className="w-5 h-5 bg-gray-200 rounded-md" />
      </div>
    </div>
  )
}

/* -------------------------------------------------------------------------- */
/* ------------------------ Reusable Sub-Components -------------------------- */
function MetadataItem({ icon: Icon, label, value }) {
  return (
    <div className="flex items-center gap-1 text-xs text-gray-600">
      <Icon size={14} />
      <span className="font-medium">
        {label}
        :
      </span>
      <span className="truncate max-w-[150px]" title={value}>
        {value}
      </span>
    </div>
  )
}

function ActionIconButton({ onClick, danger, children }) {
  return (
    <motion.button
      className={`p-1.5 rounded-md transition-colors duration-200 text-gray-500
        ${
          danger
            ? 'hover:bg-red-50 hover:text-red-600'
            : 'hover:bg-gray-100 hover:text-indigo-600'
        }
      `}
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      onClick={onClick}
    >
      {children}
    </motion.button>
  )
}

function PublicBadge({ onClick }) {
  return (
    <div
      onClick={onClick}
      className="inline-flex items-center gap-1 px-1.5 py-0.5 text-2xs font-medium text-black
        bg-indigo-50 border border-indigo-200 rounded-md cursor-pointer hover:bg-indigo-100"
    >
      Public
      <ExternalLink size={12} />
    </div>
  )
}

/* -------------------------------------------------------------------------- */
/* ------------- Horizontal "Featured" Tools Section (top) ------------------ */
function FeaturedToolsRow({ tools }) {
  return (
    <div className="mb-8 relative">
      <h2 className="text-xl font-bold text-gray-800 mb-3">
        Featured from the Showcase
      </h2>
      <div className="overflow-x-auto flex flex-row items-stretch">
        {tools.map(tool => (
          <motion.div
            key={tool.tool_id}
            whileHover={{ scale: 0.98 }}
            whileTap={{ scale: 0.99 }}
            className="w-60 h-52 flex-shrink-0 bg-white border border-gray-300 rounded-md p-3 mr-4
              hover:shadow-md transition-shadow"
            style={{ transition: 'transform 0.2s ease-out' }}
          >
            {tool.image_url ? (
              <img
                src={tool.image_url}
                alt={tool.title}
                className="w-full h-20 object-cover mb-2 rounded-sm"
              />
            ) : (
              <div className="w-full h-20 bg-gray-200 mb-2 flex items-center justify-center rounded-sm">
                <span className="text-xs text-gray-500 font-bold">No Image</span>
              </div>
            )}
            <h3
              className="text-sm font-bold text-gray-900 line-clamp-1 mb-1 truncate"
              title={tool.title}
            >
              {tool.title}
            </h3>
            <p className="text-xs text-gray-600 line-clamp-2">
              {truncateDesc(tool.description)}
            </p>
            <Button
              variant="surface"
              color="iris"
              size="2"
              style={{ cursor: 'pointer' }}
              className="mt-2"
              onClick={() => window.open(`/showcase/tools/${tool.tool_id}`, '_blank')}
            >
              Open
            </Button>
          </motion.div>
        ))}
      </div>
    </div>
  )
}

/* -------------------------------------------------------------------------- */
/* --------------------- User Tools: Table-Like Section ---------------------- */
function ToolRow({ tool, isDeleting, onCopy, onDelete, onClick }) {
  return (
    <motion.div
      className="border-b border-gray-200 py-3 px-2 flex items-center justify-between
        transition-colors cursor-pointer"
      whileHover={{
        scale: 0.99,
        boxShadow: '0 0 0 1px #dcdcdc'
      }}
      style={{ borderRadius: '4px', transition: 'all 0.2s ease-out' }}
      onClick={onClick}
    >
      <div className="flex flex-col space-y-1 w-[70%]">
        <div className="flex items-center gap-2">
          {tool.public && (
            <PublicBadge
              onClick={(e) => {
                e.stopPropagation()
                const rawId = tool.id.split('TL-')[1] || tool.id
                window.open(`/showcase/tools/${rawId}`, '_blank')
              }}
            />
          )}
          <h3
            className="text-sm font-bold text-gray-900 line-clamp-1 truncate"
            title={tool.name}
          >
            {tool.name}
          </h3>
        </div>
        <p className="text-xs text-gray-600 line-clamp-2 truncate" title={tool.description}>
          {truncateDesc(tool.description)}
        </p>
        <div className="flex flex-wrap gap-3 mt-1">
          <MetadataItem icon={Globe} label="URL" value={tool.url || 'No URL'} />
          <MetadataItem icon={Code} label="Method" value={tool.method || 'No Method'} />
        </div>
      </div>

      <div className="flex items-center gap-2">
        <ActionIconButton
          onClick={(e) => {
            e.stopPropagation()
            onCopy()
          }}
        >
          <Copy size={16} />
        </ActionIconButton>
        <ActionIconButton>
          <Edit2 size={16} />
        </ActionIconButton>
        <ActionIconButton
          danger
          onClick={(e) => {
            e.stopPropagation()
            onDelete()
          }}
        >
          {isDeleting ? <Spinner className="w-2.5 h-2.5" /> : <Trash2 size={16} />}
        </ActionIconButton>
      </div>
    </motion.div>
  )
}

function ToolsTable({ tools, loadingDelete, onCopy, onDelete, onRowClick }) {
  return (
    <div className="border border-gray-300 rounded-md bg-white overflow-hidden">
      {tools.map(tool => {
        const isDeleting = loadingDelete.id === tool.id && loadingDelete.loading
        return (
          <ToolRow
            key={tool.id}
            tool={tool}
            isDeleting={isDeleting}
            onCopy={() => onCopy(tool)}
            onDelete={() => onDelete(tool)}
            onClick={() => onRowClick(tool)}
          />
        )
      })}
    </div>
  )
}

/* -------------------------------------------------------------------------- */
/* ---------------------------- MAIN COMPONENT ------------------------------- */
export default function AllTools() {
  const [tools, setTools] = useState([])
  const [showcaseTools, setShowcaseTools] = useState([])
  const [loading, setLoading] = useState(true)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [toolToDelete, setToolToDelete] = useState(null)
  const [loadingDelete, setLoadingDelete] = useState({
    id: null,
    loading: false
  })

  const navigate = useNavigate()
  const orgId = getOrgId()

  // We define local state to handle "No Tools" popup visibility.
  // Start 'true' if user has no tools or forced, else 'false'.
  // But we update after fetching.
  const [noToolsOpen, setNoToolsOpen] = useState(false)
  const [showToolsModal, setShowToolsModal] = useState(false)
  const [selectedIntegration, setSelectedIntegration] = useState(null)
  const [availableIntegrationTools, setAvailableIntegrationTools] = useState([])
  const [loadingIntegrationTools, setLoadingIntegrationTools] = useState(false)
  const [creatingTool, setCreatingTool] = useState(false)



  const { 
    data: userIntegrations = [], 
    isLoading: loadingIntegrations,
    refetch: fetchUserIntegrations 
  } = useQuery({
    queryKey: ['userIntegrations'],
    queryFn: async () => {
      const { data } = await $fetch('/nango/connections');
      return data?.connections || [];
    },
  });

  // forcibly show if no tools or 'forceNoToolsPopup'
  // We'll update after we load the tools
  useEffect(() => {
    if (!loading && (tools.length === 0 || forceNoToolsPopup)) {
      setNoToolsOpen(true)
    } else {
      setNoToolsOpen(false)
    }
  }, [loading, tools])

  // DELETION
  const deleteToolHandler = async (id) => {
    try {
      if (tools.find(t => t.id === id)?.public) {
        toast.error('Cannot delete public tool', { position: 'bottom-right' })
        setShowDeleteModal(false)
        setToolToDelete(null)
        return
      }
      setLoadingDelete({ id, loading: true })
      const toolIdWithoutPrefix = id.replace(/^TL-/, '')
      const response = await fetch(
        `${config.API_URL}/v1/tools/${toolIdWithoutPrefix}`,
        {
          method: 'DELETE',
          headers: {
            Authorization: getApiKey(),
            ...(orgId && { 'x-bland-org-id': orgId })
          }
        }
      )
      if (response.ok && response.status === 200) {
        setTools(prev => prev.filter(tool => tool.id !== id))
        toast.success('Tool deleted successfully', { position: 'bottom-right' })
      } else {
        toast.error('Failed to delete tool', { position: 'bottom-right' })
      }
    } catch (error) {
      toast.error('Failed to delete tool', { position: 'bottom-right' })
    } finally {
      setLoadingDelete({ id: null, loading: false })
      setShowDeleteModal(false)
      setToolToDelete(null)
    }
  }

  function openNangoConnectUI(integrationId) {
    const nango = new Nango();
    
    const connect = nango.openConnectUI({
      onEvent: async (event) => {
        const res = await $fetch('/nango/connect-session', {
          method: 'POST',
          body: JSON.stringify({
            allowed_integrations: integrationId
          })
        });
        const data = res
        connect.setSessionToken(data.data.data.token);
        fetchUserIntegrations();
        
      },
    });
  }

  // FETCH user tools
  const getAllTools = async () => {
    try {
      const res = await fetch(`${config.API_URL}/v1/tools`, {
        method: 'GET',
        headers: {
          Authorization: getApiKey(),
          ...(orgId && { 'x-bland-org-id': orgId })
        }
      })
      if (res.ok && res.status === 200) {
        const data = await res.json()
        const mapped = data?.tools?.map(t => ({
          id: t.tool_id,
          ...t.tool,
          public: t.public
        }))
        setTools(mapped)
      }
    } catch (error) {
      console.error('Error fetching tools:', error)
    } finally {
      setLoading(false)
    }
  }

  // FETCH showcase tools
  const getShowcaseTools = async () => {
    try {
      const resp = await fetch(`${config.API_URL}/showcase/public/tools`)
      if (!resp.ok) return
      const data = await resp.json()
      if (data.status !== 'error') {
        const randomSubset = getRandomSubset(data?.data?.data || [], 4)
        setShowcaseTools(randomSubset)
      }
    } catch (err) {
      console.error('Error fetching showcase tools:', err)
    }
  }

  useEffect(() => {
    getAllTools()
    getShowcaseTools()
  }, [])


  const availableIntegrations = [
    {
      id: "slack", // nango expects this id
      name: "Slack",
      description: "Send messages and notifications to Slack channels and workspaces",
      imageUrl: "https://a.slack-edge.com/80588/marketing/img/meta/slack_hash_256.png"
    },
    {
      id: "hubspot",
      name: "HubSpot",
      description: "Manage your marketing, sales, and service in one platform",
      imageUrl: "https://cdn2.hubspot.net/hubfs/53/hubspot-logo.png"
    }
  ];

  const openToolsModal = async (integration) => {
    setSelectedIntegration(integration)
    setShowToolsModal(true)
    setAvailableIntegrationTools([])
    
    await fetchIntegrationTools(integration.id)
  }
  
  const fetchIntegrationTools = async (provider) => {
    setLoadingIntegrationTools(true)
    try {
      const { templates } = await $fetch('/v1/tools/integration_templates', {
        method: 'POST',
        body: JSON.stringify({ provider })
      })
      
      setAvailableIntegrationTools(templates || [])
    } catch (error) {
      console.error('Error fetching integration tools:', error)
      toast.error('Failed to fetch available tools', { position: 'bottom-right' })
    } finally {
      setLoadingIntegrationTools(false)
    }
  }
  
  const addToolMutation = useMutation({
    mutationFn: async (tool) => {
      const { data } = await $fetch('/v1/tools/create_integration_tool', {
        method: 'POST',
        body: JSON.stringify({ 
          tool: tool.tool,
          provider: tool.provider
        })
      });
      return { data, tool };
    },
    onSuccess: (result) => {
      toast.success(`${result.tool.name} added successfully`, { position: 'bottom-right' });
      setShowToolsModal(false);
      getAllTools();
    },
    onError: (error) => {
      toast.error('Failed to add tool', { position: 'bottom-right' });
    },
    onSettled: () => {
    }
  });

  const deleteConnection = async (connectionId, providerConfigKey) => {
    setLoadingDelete({ id: connectionId, loading: true })
    try {
      await $fetch(`/nango/connection/${connectionId}?provider_config_key=${providerConfigKey}`, {
        method: 'DELETE',
      })
      
      toast.success('Integration deleted successfully', { position: 'bottom-right' })
      fetchUserIntegrations()
    } catch (error) {
      console.error('Error deleting connection:', error)
      toast.error('Failed to delete integration', { position: 'bottom-right' })
    } finally {
      setShowDeleteModal(false)
      setLoadingDelete({ id: null, loading: false })
    }
  }

  const openDeleteModal = (connection) => {
    setLoadingDelete({ id: connection.connection_id, loading: false })
    setToolToDelete(null)
    setShowDeleteModal(true)
  }

  return (
    <PageWrapper className="w-full h-full flex flex-col relative">
      <ActionBar top spaceBetween>
        <PageTitle>All Tools</PageTitle>
        <Flex direction="row" gap="3">
          <Button variant="surface" color="iris" onClick={() => navigate('/showcase/tools')}>
            Tools Showcase
            <Store size={14} />
          </Button>
          <Button variant="solid" color="violet" onClick={() => navigate('/dashboard/secrets')}>
            Secrets
            <Key size={14} />
          </Button>
          <Button variant="solid" color="iris" onClick={() => navigate('/dashboard/tool')}>
            New Tool
            <Zap size={14} />
          </Button>
        </Flex>
      </ActionBar>

      <div className="max-w-7xl mx-auto w-full px-4 py-6 flex-1">
        {loading ? (
          <div className="mt-4">
            <GradientLoadingAnimation
              message="Loading Tools..."
              variant="sunsetCoral"
            />
          </div>
        ) : (
          <>
            {/* FEATURED TOOLS ROW */}
            {showcaseTools.length > 0 && (
              <FeaturedToolsRow tools={showcaseTools} />
            )}

              {/* AVAILABLE INTEGRATIONS */}
              <div className="mt-8 mb-8 relative">
              <h2 className="text-xl font-bold text-gray-800 mb-3">
                Available Integrations
              </h2>
              <div className="flex flex-wrap gap-4">
                {availableIntegrations.map(integration => {
                  const isEnabled = userIntegrations.some(
                    connection => connection.provider_config_key === integration.id
                  );
                  
                  const connectionObj = isEnabled ? 
                    userIntegrations.find(conn => conn.provider_config_key === integration.id) : null;
                  
                  return (
                  <motion.div
                    key={integration.id}
                    whileHover={{ scale: 0.98 }}
                    whileTap={{ scale: 0.99 }}
                    className="w-60 flex-shrink-0 bg-white border border-gray-300 rounded-md p-3 
                      hover:shadow-md transition-shadow"
                    style={{ transition: 'transform 0.2s ease-out' }}
                  >
                    <img
                      src={integration.imageUrl}
                      alt={integration.name}
                      className="w-full h-20 object-contain mb-2 rounded-sm"
                    />
                    <h3 className="text-sm font-bold text-gray-900 line-clamp-1 mb-1">
                      {integration.name}
                    </h3>
                    <p className="text-xs text-gray-600 line-clamp-2">
                      {integration.description}
                    </p>
                    <div className="flex gap-2 mt-2" >
                      {isEnabled ? (
                        <>
                          <Button
                            variant="surface" 
                            color="red"
                            size="2"
                            style={{ cursor: 'pointer' }}
                            onClick={() => openDeleteModal(connectionObj)}
                          >
                            Delete
                            <Trash2 size={14} />
                          </Button>
                          <Button
                            variant="surface"
                            color="green"
                            size="2"
                            style={{ cursor: 'pointer' }}
                            onClick={() => openToolsModal(integration)}
                          >
                            Add Tool
                            <Plus size={14} />
                          </Button>
                        </>
                      ) : (
                        <Button
                          variant="surface"
                          color="iris"
                          size="2"
                          style={{ cursor: 'pointer' }}
                          onClick={() => openNangoConnectUI(integration.id)}
                        >
                          Connect
                        </Button>
                      )}
                    </div>
                  </motion.div>
                )})}
              </div>
            </div>

            {/* USER'S TOOLS SECTION */}
            <h2 className="text-xl font-bold text-gray-800 mb-3">
              Your Tools
            </h2>
            {tools.length > 0 ? (
              <ToolsTable
                tools={tools}
                loadingDelete={loadingDelete}
                onCopy={(tool) => {
                  navigator.clipboard.writeText(tool.id)
                  toast.success('Copied Tool ID to clipboard', {
                    position: 'bottom-right'
                  })
                }}
                onDelete={(tool) => {
                  setToolToDelete(tool)
                  setLoadingDelete({ id: tool.id, loading: false })
                  setShowDeleteModal(true)
                }}
                onRowClick={tool => navigate(`/dashboard/tool/${tool.id}`)}
              />
            ) : (
              <p className="text-sm text-gray-500 mb-4">
                No user tools found.
              </p>
            )}
          </>
        )}
      </div>

      {/* "No Tools" Popup => open if noToolsOpen is true */}
      <NoToolsPopup
        show={noToolsOpen}
        onClose={() => setNoToolsOpen(false)}
        title="Make your first tool!"
        body2="Explore our public 'Tool Showcase' to install a featured integration, or create your own from scratch!"
        animationDuration={64}
        onExplore={() => navigate('/showcase/tools')}
        onCreate={() => navigate('/dashboard/tool')}
      />

      {/* Confirm Delete Modal */}
      <Transition appear show={showDeleteModal} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 overflow-y-auto"
          onClose={() => setShowDeleteModal(false)}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500/75 transition-opacity" />
            </Transition.Child>

            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title className="text-lg font-semibold text-gray-900">
                  {toolToDelete ? 'Delete Tool' : 'Delete Integration'}
                </Dialog.Title>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    Are you sure you want to delete this {toolToDelete ? 'tool' : 'integration'}? This action cannot be undone.
                  </p>
                </div>

                <div className="mt-4 flex justify-end space-x-3">
                  <button
                    type="button"
                    className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 border border-transparent rounded-md hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-gray-500"
                    onClick={() => !loadingDelete.loading && setShowDeleteModal(false)}
                    disabled={loadingDelete.loading}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-red-600 border border-transparent rounded-md hover:bg-red-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-red-500"
                    onClick={() => {
                      if (toolToDelete) {
                        deleteToolHandler(toolToDelete.id);
                      } else {
                        deleteConnection(
                          loadingDelete.id, 
                          userIntegrations.find(c => c.connection_id === loadingDelete.id)?.provider_config_key
                        );
                      }
                    }}
                    disabled={loadingDelete.loading}
                  >
                    {loadingDelete.loading ? 'Deleting...' : 'Delete'}
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>

      {/* Tools Modal */}
      <Transition appear show={showToolsModal} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 overflow-y-auto"
          onClose={() => setShowToolsModal(false)}
        >
          <div className="min-h-screen px-2.5 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500/75 transition-opacity" />
            </Transition.Child>

            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>

            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div
                className="inline-block w-full max-w-lg p-6 my-8 overflow-hidden
                  text-left align-middle transition-all transform bg-white shadow-xl
                  rounded-md"
              >
                <div className="flex items-center justify-between mb-4">
                  <div className="flex items-center gap-2.5">
                    <Dialog.Title className="text-lg font-semibold text-gray-900">
                      {selectedIntegration?.name} Tools
                    </Dialog.Title>
                  </div>
                  <Button
                    variant="ghost"
                    size="4"
                    color="gray"
                    style={{ cursor: 'pointer' }}
                    onClick={() => setShowToolsModal(false)}
                  >
                    ×
                  </Button>
                </div>

                <div className="mt-2">
                  <p className="text-sm text-gray-500 mb-4">
                    Select a tool to add to your workspace:
                  </p>
                  
                  <div className="space-y-3 max-h-[400px] overflow-y-auto pr-2">
                    {loadingIntegrationTools ? (
                      <div className="flex justify-center items-center py-10">
                        <div className="flex flex-col items-center space-y-3">
                          <Spinner size="large" />
                          <p className="text-sm text-gray-500">
                            Fetching available tools for {selectedIntegration?.name}...
                          </p>
                        </div>
                      </div>
                    ) : (
                      <>
                        {availableIntegrationTools.length > 0 ? (
                          availableIntegrationTools.map(tool => (
                            <div 
                              key={tool.id}
                              className="border border-gray-200 rounded-md p-3 hover:bg-gray-50 transition-colors 
                                cursor-pointer active:bg-gray-100"
                              onClick={() => !creatingTool && addToolMutation.mutate(tool)}
                            >
                              <div className="flex items-center justify-between">
                                <div>
                                  <h3 className="text-sm font-medium text-gray-900">{tool.name}</h3>
                                  <p className="text-xs text-gray-500 mt-1">{tool.description}</p>
                                </div>
                                {creatingTool && tool.id === availableIntegrationTools.find(t => t.id === tool.id)?.id && (
                                  <Spinner size="small" />
                                )}
                              </div>
                            </div>
                          ))
                        ) : (
                          <p className="text-sm text-gray-500 text-center py-4">
                            No tools available for this integration yet.
                          </p>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </PageWrapper>
  )
}
