import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { Switch } from '@headlessui/react'
import NodeTag from 'components/Dashboard/ConvoPathways/Components/NodeTag'
import config from 'config'
import { SquareDot } from 'lucide-react'
import { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { getAuthToken } from 'utils/funcs/browser/getAuthToken'
import { getOrgId } from 'utils/funcs/browser/getOrgId'
import FlowContext from '../contextFlow'
import GlobalNodeOptions from './globalSelect'
import ModelOptions from './modelOptions'
import PathwayResponse from './WebhookResponsePathway'

function CustomToolModal({ id, data, setIsOpen }) {
  const { embedMode } = useContext(FlowContext)
  const orgId = getOrgId()
  const [tools, setTools] = useState([])
  const [selectedTool, setSelectedTool] = useState(data?.tool || {})
  const [toolId, setToolId] = useState(data?.tool?.tool_id || '')
  const [responseVars, setResponseVars] = useState([])
  const [name, setName] = useState(data.name || '')
  const [isStaging, setIsStaging] = useState(data?.is_staging || false)
  const [isLoading, setIsLoading] = useState(false)

  const [responsePathways, setResponsePathways] = useState(
    data.responsePathways || [
      ['Default/Webhook Completion', '', '', { id: '', name: '' }],
    ],
  )

  const [enableGlobalAutoReturn, setEnableGlobalAutoReturn] = useState(
    data.enableGlobalAutoReturn !== false,
  )

  const [prevNodePathwayLabel, setPrevNodePathwayLabel] = useState(
    data.prevNodePathwayLabel || '',
  )

  const [prevNodePathwayDescription, setPrevNodePathwayDescription] = useState(
    data.prevNodePathwayDescription || '',
  )

  const [modelOptions, setModelOptions] = useState(
    data.modelOptions || {
      modelType: 'smart',
      temperature: 0.2,
    },
  )

  const [isGlobal, setIsGlobal] = useState(data.isGlobal)
  const [globalPathwayLabel, setGlobalPathwayLabel] = useState(
    data.globalLabel || '',
  )

  const [globalPathwayDescription, setGlobalPathwayDescription] = useState(
    data.globalDescription || '',
  )

  const [tags, setTags] = useState(data.tags || null)

  const generateUniqueEdgeId = (source, target, edgeData) => {
    const baseId = `reactflow__edge-${source}-${target}`
    const dataHash = JSON.stringify(edgeData)
    return `${baseId}-${dataHash}`
  }

  const [isForwardingEnabled, setIsForwardingEnabled] = useState(false)
  const [forwardingNode, setForwardingNode] = useState('')

  const getTools = async () => {
    if (embedMode)
      return // Don't fetch tools in embedMode

    setIsLoading(true)
    console.log('fetching tools')
    const response = await fetch(`${config.API_URL}/v1/tools`, {
      method: 'GET',
      headers: {
        authorization: getAuthToken(),
        ...(orgId && { 'x-bland-org-id': orgId }),
      },
    })

    if (response.ok) {
      const data = await response.json()
      setTools(data.tools)

      setSelectedTool(
        data.tools.find(el => el.tool_id === selectedTool?.tool_id),
      )

      parseResponseVars(selectedTool?.tool?.response)
    }
    else {
      console.error('Failed to fetch tools data')
    }
    setIsLoading(false)
  }

  const handleToolSelection = (value) => {
    const selectedToolData = JSON.parse(value)
    setSelectedTool(selectedToolData)
    setIsStaging(false)

    parseResponseVars(selectedToolData.tool?.response)
  }

  const parseResponseVars = (vars) => {
    const mappedResponses = vars
      ? Object.keys(vars).map(el => ({ name: el }))
      : []
    setResponseVars(mappedResponses)
  }

  const handleToolIdInput = (e) => {
    const id = e.target.value
    setToolId(id)
    setSelectedTool({ tool_id: id })
  }

  const handleStagingToggle = () => {
    if (selectedTool?.staging_tool) {
      setIsStaging(!isStaging)
      const toolToUse = isStaging
        ? selectedTool.tool
        : selectedTool?.staging_tool
      const responses = toolToUse?.response
      const mappedResponses = responses
        ? Object.keys(responses).map(el => ({ name: el }))
        : []
      setResponseVars(mappedResponses)
    }
  }

  useEffect(() => {
    if (!embedMode) {
      getTools()
    }
  }, [id, embedMode])

  const { exportFlow, triggerUpdate, elements } = useContext(FlowContext)

  return (
    <div className="space-y-2.5">
      <>
        <div className="space-y-2.5">
          <div>Name:</div>
          <input
            type="text"
            value={name}
            onChange={e => setName(e.target.value)}
            className="border border-gray-300 p-1.5 rounded w-full"
          />

          <label className="block text-[12px] font-medium text-gray-700 mt-2.5">
            Custom Tool
            {' '}
            <span className="text-gray-400 text-[11px]">
              (Invoke a Custom Tool at this node)
            </span>
          </label>

          {embedMode
            ? (
                <input
                  type="text"
                  value={toolId}
                  onChange={handleToolIdInput}
                  placeholder="Enter Tool ID"
                  className="block w-full p-2 border border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 rounded text-[12px]"
                />
              )
            : (
                <Select
                  value={JSON.stringify(selectedTool)}
                  onValueChange={handleToolSelection}
                >
                  <SelectTrigger className="w-full h-8">
                    <SelectValue placeholder="Select Custom Tool" />
                  </SelectTrigger>
                  <SelectContent>
                    {isLoading
                      ? (
                          <SelectItem value="loading" disabled>
                            Loading...
                          </SelectItem>
                        )
                      : (
                          <SelectItem value="placeholder" disabled>
                            Select Custom Tool
                          </SelectItem>
                        )}
                    {tools.map(el => (
                      <SelectItem key={el?.tool_id} value={JSON.stringify(el)}>
                        {el?.tool?.name}
                        {' '}
                        -
                        {' '}
                        {isStaging ? el?.staging_tool?.url : el.tool.url}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              )}

          {selectedTool?.tool && !embedMode && (
            <div
              className="mt-2.5 flex items-center justify-between"
              style={{ marginBottom: 20 }}
            >
              <div className="flex items-center gap-1.5">
                <SquareDot className="w-2.5 h-2.5" />
                <span className="text-xs font-medium text-gray-700">
                  Use Staging Tool
                </span>
              </div>

              <div className="flex items-center gap-2.5">
                <Switch
                  checked={isStaging}
                  onChange={handleStagingToggle}
                  disabled={!selectedTool?.staging_tool}
                  className={`${
                    isStaging ? 'bg-indigo-600' : 'bg-gray-200'
                  } relative inline-flex h-4 w-7 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
                >
                  <span
                    className={`${
                      isStaging ? 'translate-x-4' : 'translate-x-0.5'
                    } inline-block h-2.5 w-2.5 transform rounded-full bg-white transition-transform`}
                  />
                </Switch>
                {!selectedTool?.staging_tool && (
                  <span className="text-xs italic text-gray-600">
                    (Staging tool not created on this tool. Toggle disabled)
                  </span>
                )}
              </div>
            </div>
          )}

          {(selectedTool?.tool || embedMode) && (
            <div className="mt-2.5">
              <PathwayResponse
                name="tool"
                responseVariables={responseVars}
                responsePathways={responsePathways}
                setResponsePathways={setResponsePathways}
              />
            </div>
          )}

          <ModelOptions
            modelOptions={modelOptions}
            setModelOptions={setModelOptions}
          />

          <GlobalNodeOptions
            isGlobal={isGlobal}
            setIsGlobal={setIsGlobal}
            globalPathwayLabel={globalPathwayLabel}
            setGlobalPathwayLabel={setGlobalPathwayLabel}
            globalPathwayDescription={globalPathwayDescription}
            setGlobalPathwayDescription={setGlobalPathwayDescription}
            isForwardingEnabled={isForwardingEnabled}
            setIsForwardingEnabled={setIsForwardingEnabled}
            forwardingNode={forwardingNode}
            setForwardingNode={setForwardingNode}
            enableGlobalAutoReturn={enableGlobalAutoReturn}
            setEnableGlobalAutoReturn={setEnableGlobalAutoReturn}
            prevNodePathwayLabel={prevNodePathwayLabel}
            setPrevNodePathwayLabel={setPrevNodePathwayLabel}
            prevNodePathwayDescription={prevNodePathwayDescription}
            setPrevNodePathwayDescription={setPrevNodePathwayDescription}
          />
        </div>

        <NodeTag nodeTag={tags} setNodeTag={setTags} />

        <button
          onClick={() => {
            const node = elements.nodes.find(el => el.id === id)
            console.log('node', node)

            const data = {
              name,
              tool: embedMode ? { tool_id: toolId } : selectedTool,
              modelOptions,
              isGlobal,
              globalLabel: globalPathwayLabel,
              globalDescription: globalPathwayDescription,
              isForwardingEnabled,
              forwardingNode,
              enableGlobalAutoReturn,
              prevNodePathwayLabel,
              prevNodePathwayDescription,
              is_staging: isStaging,
            }

            if (tags || tags === null) {
              data.tags = tags
            }

            if (!isGlobal) {
              delete data.globalLabel
              delete data.globalDescription
              delete data.isForwardingEnabled
              delete data.forwardingNode
              delete data.enableGlobalAutoReturn
              delete data.prevNodePathwayLabel
              delete data.prevNodePathwayDescription
            }

            if (responsePathways.length > 0 && responsePathways[0][0] !== '') {
              data.responsePathways = responsePathways
            }
            else {
              delete data.responsePathways
            }

            if (modelOptions) {
              if (modelOptions.newTemperature) {
                modelOptions.newTemperature = Number.parseFloat(
                  modelOptions.newTemperature.toFixed(1),
                )
                delete modelOptions.temperature
              }
              else {
                delete modelOptions.newTemperature
              }
              if (modelOptions.temperature) {
                modelOptions.temperature = Number.parseFloat(
                  modelOptions.temperature.toFixed(1),
                )
              }
              if (modelOptions.interruptionThreshold) {
                modelOptions.interruptionThreshold = Number.parseInt(
                  modelOptions.interruptionThreshold,
                )
              }
              data.modelOptions = modelOptions
            }
            else {
              delete data.modelOptions
            }

            let isWarnToast = false
            let newEdges = []

            const res_nodes = elements.nodes.map((el) => {
              if (el.id === id) {
                const node = structuredClone(el)
                for (const responsePathway of responsePathways) {
                  console.log('Response pathway', responsePathway)
                  if (responsePathway[0] === 'Default/Webhook Completion') {
                    if (
                      responsePathway[3].id === ''
                      || responsePathway[3].name === ''
                    ) {
                      toast.warn(
                        'Please select the default node to path to after webhook completion.',
                      )
                      isWarnToast = true
                      return node
                    }
                    const edge = {
                      id: `reactflow__edge-${id}-${responsePathway[3].id}`,
                      animated: true,
                      sourceHandle: null,
                      targetHandle: null,
                      type: 'custom',
                      source: id,
                      target: responsePathway[3].id,
                      data: {
                        label: `${responsePathway[0]}`,
                      },
                    }
                    newEdges.push(edge)
                    continue
                  }
                  if (
                    !responsePathway[1]
                    || !responsePathway[2]
                    || !responsePathway[3].id
                    || !responsePathway[3].name
                  ) {
                    toast.warn(
                      'Fields in \'Pathway after Webhook Response\' not filled out. Please fill in all fields.',
                    )
                    isWarnToast = true
                    break
                  }

                  const edge = {
                    animated: true,
                    sourceHandle: null,
                    targetHandle: null,
                    type: 'custom',
                    source: id,
                    target: responsePathway[3].id,
                    data: {
                      label: `${responsePathway[0]} ${responsePathway[1]} ${responsePathway[2]}`,
                    },
                  }
                  edge.id = generateUniqueEdgeId(
                    id,
                    responsePathway[3].id,
                    edge.data,
                  )

                  newEdges.push(edge)
                }

                const currentEdges = elements.edges
                const filteredEdges = currentEdges.filter(
                  edge => edge.source !== id,
                )
                const edgeMap = new Map()
                filteredEdges.forEach(edge => edgeMap.set(edge.id, edge))
                newEdges.forEach((edge) => {
                  if (!edgeMap.has(edge.id)) {
                    edgeMap.set(edge.id, edge)
                  }
                })
                newEdges = Array.from(edgeMap.values())

                return {
                  ...el,
                  data,
                  type: 'Custom Tool',
                }
              }
              else {
                return el
              }
            })

            setIsOpen(false)
            if (isWarnToast) {
              return
            }

            triggerUpdate(
              {
                nodes: res_nodes,
                edges: newEdges,
              },
              false,
            )
          }}
          className="bg-indigo-500 text-white py-1.5 px-2.5 rounded mt-2.5"
        >
          Save
        </button>
      </>
    </div>
  )
}

export default CustomToolModal
