import { cn } from '@/lib/utils'
import { cva } from 'class-variance-authority'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { Description, Label } from './Text'

const inputVariants = cva(
  'flex w-full h-[36px] text-[16.5px] rounded-[6px] px-3 py-1.5 text-[16px] file:border-0 file:bg-transparent file:text-xs file:font-medium placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50 focus:ring-0 focus:ring-offset-0 focus:outline-none',
  {
    variants: {
      variant: {
        default: 'bg-[#f6f8fa] border border-solid border-[#e0e0e0]',
        outline: 'border border-solid border-[#e0e0e0]',
      },
      state: {
        error: 'border border-[#e63946] shadow-[0_0_5px_rgba(230,57,70,0.3)]',
        success:
          'border border-[#3bceac] shadow-[0_0_5px_rgba(59,206,172,0.3)]',
      },
    },
    compoundVariants: [
      {
        variant: ['default', 'outline'],
        state: ['error', 'success'],
        className: 'bg-transparent',
      },
    ],
    defaultVariants: {
      variant: 'default',
      size: 'md',
      state: 'default',
    },
  },
)

export default function TextInput({
  fieldName,
  className,
  variant,
  size,
  state,
  icon: Icon,
  label,
  description,
  error,
  type = 'text',
  validation,
  width,
  ...props
}) {
  const {
    formState: { errors },
    register,
    watch,
  } = useFormContext()

  return (
    <div className="relative" style={{ width }}>
      {(label || description) && (
        <div className="flex flex-col mb-[5px]">
          {label && <Label htmlFor={fieldName}>{label}</Label>}
          {description && <Description>{description}</Description>}
        </div>
      )}
      <div className="relative">
        {Icon && (
          <div className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400">
            {React.cloneElement(Icon, {
              size: 14,
              className: 'stroke-[2px]',
              style: { marginTop: 1 },
            })}
          </div>
        )}
        <input
          id={fieldName}
          {...register(fieldName)}
          placeholder="Placeholder"
          className={cn(
            inputVariants({ variant, state }),
            Icon ? 'pl-8' : 'pl-3',
            className,
          )}
          style={{ width: '100%' }}
          {...props}
        />
      </div>
      {error && <p className="text-[#e63946] text-[12px] mt-1.5">{error}</p>}
    </div>
  )
}
