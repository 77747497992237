import { Dialog, DialogContent } from '@/components/ui/dialog'
import React, { useState } from 'react'
import { ConditionBuilderContent } from './conditionBuilderContent'

function ConditionSummary({ conditions }) {
  return (
    <div className="bg-white rounded-md border border-slate-200 p-2.5 space-y-2">
      {conditions.length === 0
        ? (
            <div className="text-xs text-slate-500">No conditions set</div>
          )
        : (
            conditions.map((condition, index) => (
              <React.Fragment key={index}>
                <div className="px-2.5 py-0.5 bg-slate-950 text-white text-xs rounded w-fit">
                  {index === 0 ? 'If' : 'Or'}
                </div>
                <div className="rounded-md border border-slate-200 p-2">
                  {condition.isGroup
                    ? (
                        <>
                          {condition.conditions.map((groupCondition, groupIndex) => (
                            <div key={groupIndex} className="mb-1.5 last:mb-0">
                              <div className="rounded-md bg-gray-50 border border-gray-100 p-2">
                                <div className="flex items-center gap-1.5 mb-1.5">
                                  <span className="px-1.5 py-0.5 bg-gray-200 text-slate-600 text-xs rounded-">
                                    If variable
                                  </span>
                                  <span className="px-1.5 py-0.5 bg-blue-100 text-blue-700 text-xs rounded-">
                                    {groupCondition.field}
                                  </span>
                                </div>
                                <div className="flex items-center gap-1.5">
                                  <span className="text-xs text-slate-600">
                                    {groupCondition.operator}
                                  </span>
                                  {groupCondition.value && (
                                    <span className="px-1.5 py-0.5 bg-violet-100 text-violet-700 text-xs rounded-">
                                      {groupCondition.value}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          ))}
                        </>
                      )
                    : (
                        <div className="rounded-md bg-gray-50 border border-gray-100 p-2">
                          <div className="flex items-center gap-1.5 mb-1.5">
                            <span className="px-1.5 py-0.5 bg-gray-200 text-slate-600 text-xs rounded-">
                              If variable
                            </span>
                            <span className="px-1.5 py-0.5 bg-blue-100 text-blue-700 text-xs rounded-">
                              {condition.field}
                            </span>
                          </div>
                          <div className="flex items-center gap-1.5">
                            <span className="text-xs text-slate-600 bg-gray-100 px-1.5 py-0.5 rounded border border-slate-200">
                              {condition.operator}
                            </span>
                            {condition.value && (
                              <span className="px-1.5 py-0.5 bg-violet-100 text-violet-700 text-xs rounded-">
                                {condition.value}
                              </span>
                            )}
                          </div>
                        </div>
                      )}
                </div>
              </React.Fragment>
            ))
          )}
    </div>
  )
}

function ConditionBuilder({ conditions, onChange }) {
  const [isEditing, setIsEditing] = useState(false)

  return (
    <>
      <div className="space-y-2.5">
        <ConditionSummary conditions={conditions} />
        {/* <Button
          variant="outline"
          size="sm"
          onClick={() => setIsEditing(true)}
          className="text-indigo-600 hover:text-indigo-700"
        >
          {conditions.length > 0 ? "Edit conditions" : "Add conditions"}
        </Button> */}
      </div>

      <Dialog open={isEditing} onOpenChange={open => setIsEditing(open)}>
        <DialogContent className="sm:max-w-[850px] overflow-y-auto max-h-[90vh]">
          <ConditionBuilderContent
            conditions={conditions}
            onChange={onChange}
            onClose={() => setIsEditing(false)}
            isRouteModal={false}
          />
        </DialogContent>
      </Dialog>
    </>
  )
}

export default ConditionBuilder
