// import { Button } from '@/components/ui/button'
import { Card } from '@/components/ui/card'
import { Checkbox } from '@/components/ui/checkbox'
import { Button } from '@heroui/react'
import { CheckCircle2, ChevronRight, Play, Trash2 } from 'lucide-react'
import { useState } from 'react'

function SimulationsTable({
  simulations = [],
  loading = false,
  onRunSimulation,
  onRowClick,
  loadingSimulations = new Set(),
  onDeleteSimulation,
}) {
  const [selectedSimulations, setSelectedSimulations] = useState(new Set())

  const handleSelectAll = () => {
    if (selectedSimulations.size === simulations.length) {
      setSelectedSimulations(new Set())
    }
    else {
      setSelectedSimulations(new Set(simulations.map(sim => sim.id)))
    }
  }

  const handleSelect = (id) => {
    const newSelected = new Set(selectedSimulations)
    if (newSelected.has(id)) {
      newSelected.delete(id)
    }
    else {
      newSelected.add(id)
    }
    setSelectedSimulations(newSelected)
  }

  const runSelected = () => {
    selectedSimulations.forEach((id) => {
      onRunSimulation(id)
    })
    setSelectedSimulations(new Set()) // Clear selection after running
  }

  if (loading) {
    return (
      <div className="w-full h-40 flex items-center justify-center">
        <div className="animate-pulse flex space-x-2.5">
          <div className="h-2 w-16 bg-gray-200 rounded-sm"></div>
          <div className="h-2 w-20 bg-gray-200 rounded-sm"></div>
          <div className="h-2 w-16 bg-gray-200 rounded-sm"></div>
        </div>
      </div>
    )
  }

  if (simulations.length === 0) {
    return (
      <Card className="w-full">
        <div className="flex flex-col items-center justify-center py-8 px-2.5">
          <CheckCircle2 className="w-8 h-8 text-gray-300 mb-2.5" />
          <h3 className="text-sm font-medium text-gray-900 mb-0.5">
            No test cases yet
          </h3>
          <p className="text-xs text-gray-500">
            Create a new test case to get started.
          </p>
        </div>
      </Card>
    )
  }

  return (
    <div className="space-y-2.5">
      {/* Header with actions */}
      <div className="flex items-center justify-between px-2.5">
        <div className="flex items-center space-x-2.5">
          <Checkbox
            checked={selectedSimulations.size === simulations.length}
            onCheckedChange={handleSelectAll}
            aria-label="Select all test cases"
          />
          <span className="text-xs text-gray-500">
            {selectedSimulations.size}
            {' '}
            selected
          </span>
        </div>

        {selectedSimulations.size > 0 && (
          <Button
            className="inline-flex items-center px-2 py-1 text-xs font-medium text-white bg-indigo-600 hover:bg-indigo-700 rounded-"
            onPress={runSelected}
          >
            <Play className="w-2 h-2 mr-0.5" />
            Run Selected (
            {selectedSimulations.size}
            )
          </Button>
        )}
      </div>

      {/* Table */}
      <Card className="w-full overflow-hidden">
        <div className="divide-y divide-gray-100">
          {simulations.map(simulation => (
            <div
              key={simulation.id}
              className={`group transition-colors duration-150 ${
                selectedSimulations.has(simulation.id)
                  ? 'bg-indigo-50'
                  : 'hover:bg-gray-50'
              }`}
            >
              <div className="flex items-center justify-between p-2.5">
                <div className="flex items-center space-x-2.5 flex-1">
                  <Checkbox
                    checked={selectedSimulations.has(simulation.id)}
                    onCheckedChange={() => handleSelect(simulation.id)}
                    onClick={e => e.stopPropagation()}
                    aria-label={`Select ${simulation.name}`}
                  />

                  <div className="flex-1">
                    <div className="flex items-center space-x-1.5">
                      <h3 className="text-xs font-medium text-gray-900">
                        {simulation.name}
                      </h3>
                      {loadingSimulations.has(simulation.id) && (
                        <div className="w-2.5 h-2.5 border-2 border-indigo-300 border-t-indigo-600 rounded-full animate-spin" />
                      )}
                    </div>
                    <p className="text-2xs text-gray-500">
                      Created
                      {' '}
                      {new Date(simulation.created_at).toLocaleString()}
                    </p>
                  </div>

                  <div className="flex items-center space-x-1.5">
                    <Button
                      className="inline-flex items-center px-2 py-1 text-xs font-medium text-gray-700 bg-white border border-gray-300 hover:bg-gray-50 rounded-"
                      onPress={() => {
                        onRunSimulation(simulation.id)
                      }}
                      variant="light"
                      size="sm"
                      radius="sm"
                      isDisabled={loadingSimulations.has(simulation.id)}
                    >
                      {loadingSimulations.has(simulation.id)
                        ? (
                            <div className="w-2.5 h-2.5 border-2 border-gray-300 border-t-gray-600 rounded-full animate-spin" />
                          )
                        : (
                            <>
                              <Play className="w-2 h-2 mr-0.5" />
                              Run
                            </>
                          )}
                    </Button>

                    <Button
                      className="inline-flex items-center p-1 text-xs text-gray-500 hover:text-red-700 hover:bg-gray-100 rounded-"
                      variant="light"
                      size="sm"
                      onPress={() => {
                        onDeleteSimulation(simulation.id)
                      }}
                      isIconOnly
                    >
                      <Trash2 className="w-2.5 h-2.5" />
                    </Button>

                    <Button
                      variant="light"
                      size="sm"
                      className="inline-flex items-center p-1 text-xs text-gray-500 hover:text-gray-700 hover:bg-gray-100 rounded-"
                      onPress={() => onRowClick(simulation)}
                      isIconOnly
                    >
                      <ChevronRight className="w-2.5 h-2.5" />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </Card>
    </div>
  )
}

export default SimulationsTable
