import { Button } from '@/components/ui/button'
import { Plus, X } from 'lucide-react'
// ConditionBuilderContent.jsx
import { useState } from 'react'
import { toast } from 'react-toastify'
import { areConditionsValid, Condition } from './components/conditions'

export function ConditionBuilderContent({
  conditions,
  onClose,
  onChange,
  isRouteModal = false,
}) {
  const [localConditions, setLocalConditions] = useState(
    conditions.length > 0
      ? conditions
      : [
          {
            field: '',
            operator: '',
            value: '',
          },
        ],
  )
  const [showValidation, setShowValidation] = useState(false)

  const addCondition = () => {
    setLocalConditions([
      ...localConditions,
      { field: '', operator: '', value: '' },
    ])
  }

  const addGroup = () => {
    setLocalConditions([
      ...localConditions,
      {
        isGroup: true,
        conditions: [
          { field: '', operator: '', value: '' },
          { field: '', operator: '', value: '' },
        ],
      },
    ])
  }

  const handleSave = () => {
    setShowValidation(true)

    const { valid, reason } = areConditionsValid(localConditions)

    if (!valid) {
      toast.error(reason)
      return
    }

    onChange(localConditions)
    setShowValidation(false)

    // If we're in route modal, just close this view
    if (isRouteModal) {
      onClose()
    }
  }

  return (
    <div className="space-y-4">
      {!isRouteModal && (
        <>
          <div className="mx-2.5 flex items-center justify-between w-full">
            <h2 className="text-lg font-semibold">Advanced condition</h2>
            <Button variant="ghost" size="icon" onClick={onClose}>
              <X className="w-4 h-4" />
            </Button>
          </div>
          <div className="h-px bg-slate-200" />
        </>
      )}

      <div className="space-y-5 relative">
        {localConditions.map((condition, index) => (
          <div className="relative z-[150]">
            <Condition
              key={index}
              condition={condition}
              isFirst={index === 0}
              onUpdate={(updated) => {
                const newConditions = [...localConditions]
                newConditions[index] = updated
                setLocalConditions(newConditions)
              }}
              onDelete={() => {
                setLocalConditions(
                  localConditions.filter((_, i) => i !== index),
                )
              }}
              onGroupCondition={() => {
                const newConditions = [...localConditions]
                newConditions[index] = {
                  isGroup: true,
                  conditions: [
                    condition,
                    { field: '', operator: '', value: '' },
                  ],
                }
                setLocalConditions(newConditions)
              }}
              showValidation={showValidation}
            />
          </div>
        ))}
      </div>

      <div className="h-px bg-slate-200" />

      <div className="flex items-center justify-between pt-2.5 z-[200]">
        <div className="flex items-center gap-4">
          <div className="space-x-2.5">
            <Button variant="outline" size="sm" onClick={addCondition}>
              <Plus className="w-2.5 h-2.5 mr-1.5" />
              {' '}
              Add condition
            </Button>
            <Button variant="outline" size="sm" onClick={addGroup}>
              <Plus className="w-2.5 h-2.5 mr-1.5" />
              {' '}
              Add group
            </Button>
          </div>

          <Button
            variant="ghost"
            size="sm"
            onClick={() => {
              setLocalConditions([
                {
                  field: '',
                  operator: '',
                  value: '',
                },
              ])
              setShowValidation(false)
            }}
            className="text-slate-600 hover:text-slate-800"
          >
            Remove all conditions
          </Button>
        </div>

        <Button onClick={handleSave} size="lg">
          Save condition changes
        </Button>
      </div>
    </div>
  )
}
