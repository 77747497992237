import { useEffect, useRef } from 'react'
import { Check } from 'lucide-react'
import { formatMessageDate, isDifferentDay } from '../utils/dateUtils'
import { ScrollShadow } from '@heroui/react'

function MessageList({ messages, isLoading, _onDeleteMessages, selectedMessages, setSelectedMessages, isSelectionMode }) {
  const messagesEndRef = useRef(null)

  useEffect(() => {
    // Scroll to the bottom when messages change
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
  }, [messages])

  const toggleMessageSelection = (messageId) => {
    if (selectedMessages.includes(messageId)) {
      setSelectedMessages(selectedMessages.filter(id => id !== messageId))
    }
    else {
      setSelectedMessages([...selectedMessages, messageId])
    }
  }

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-full">
        <div className="flex space-x-1">
          <div className="w-2 h-2 bg-gray-300 rounded-full animate-bounce" style={{ animationDelay: '0ms' }}></div>
          <div className="w-2 h-2 bg-gray-300 rounded-full animate-bounce" style={{ animationDelay: '150ms' }}></div>
          <div className="w-2 h-2 bg-gray-300 rounded-full animate-bounce" style={{ animationDelay: '300ms' }}></div>
        </div>
      </div>
    )
  }

  return (
    <ScrollShadow className="h-full p-4 space-y-3 overflow-y-auto">
      {messages.map((message, index) => {
        // Handle both old and new message formats
        const isInbound = message.sender === 'USER' || message.direction === 'inbound'
        const messageContent = message.message || message.body
        const messageDate = message.created_at
        const prevMessage = index > 0 ? messages[index - 1] : null
        const prevMessageDate = prevMessage?.created_at
        const prevIsInbound = prevMessage ? prevMessage.sender === 'USER' || prevMessage.direction === 'inbound' : null
        const isFirstInGroup = index === 0 || isInbound !== prevIsInbound
        const showDateHeader = isDifferentDay(messageDate, prevMessageDate)
        const isSelected = selectedMessages.includes(message.id)

        return (
          <div key={message.id || index}>
            {/* Date header */}
            {showDateHeader && (
              <div className="flex justify-center my-4">
                <div className="bg-gray-100 px-3 py-1 rounded-full text-xs text-gray-600 font-medium">
                  {formatMessageDate(messageDate)}
                </div>
              </div>
            )}

            {/* Message bubble */}
            <div className={`flex ${isInbound ? 'justify-start' : 'justify-end'} relative group`}>
              {isSelectionMode && (
                <div
                  className={`absolute left-0 top-1/2 transform -translate-y-1/2 z-10`}
                  onClick={(e) => {
                    e.stopPropagation()
                    toggleMessageSelection(message.id)
                  }}
                >
                  <div className={`w-5 h-5 border rounded-full ${isSelected ? 'bg-blue-500 border-blue-500' : 'bg-white border-gray-300'} flex items-center justify-center cursor-pointer`}>
                    {isSelected && <Check size={12} className="text-white" />}
                  </div>
                </div>
              )}
              <div
                className={`max-w-[80%] px-3 py-2 ${isInbound ? 'bg-gray-200 text-gray-900' : 'bg-blue-500 text-white'} ${
                  isFirstInGroup
                    ? isInbound
                      ? 'rounded-t-2xl rounded-r-2xl rounded-bl-lg'
                      : 'rounded-t-2xl rounded-l-2xl rounded-br-lg'
                    : isInbound
                      ? 'rounded-r-2xl rounded-bl-lg'
                      : 'rounded-l-2xl rounded-br-lg'
                } ${isSelectionMode ? 'ml-8' : ''} ${isSelected ? 'ring-2 ring-blue-500' : ''}`}
              >
                <p className="text-sm">{messageContent}</p>
                <div className={`flex justify-end items-center mt-1 ${isInbound ? 'text-gray-600' : 'text-blue-100'}`}>
                  <span className="text-[10px]">
                    {new Date(message.created_at).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                  </span>
                </div>
              </div>
            </div>
          </div>
        )
      })}
      <div ref={messagesEndRef} />
    </ScrollShadow>
  )
}

export default MessageList 