import { CampaignIcon } from 'assets/icons/navigationIcons'
import ActionBar from 'components/core/ActionBar'
import { PageTitle } from 'components/core/PageTitle'
import { PageWrapper } from 'components/core/PageWrapper'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import BlandVector from '../../../assets/brand/bland-vector.svg'
import RightArrow from '../../../assets/icons/right-arrow.svg'
import Dots from '../../../assets/images/Dots.png'
import PgImg from '../../../assets/images/PgImg.png'
import ToolsImg from '../../../assets/images/ToolsImg.png'

const options = [
  {
    name: 'Chat',
    value: 'chat',
    image: <CampaignIcon />,
    description: 'Build and Test Prompts, and Dynamic data.',
    path: 'playground-chat',
  },
  {
    name: 'Tools',
    value: 'tools',
    image: <CampaignIcon />,
    description: 'Build and test your tools.',
    path: 'playground-tools',
  },
  // {
  //     name: "Prompt",
  //     value: "prompt",
  //     image: <CampaignIcon />,
  //     description: "Create, edit, and manage your prompts.",
  //     path: "playground-prompt"
  // }
]

export default function PlaygroundHub() {
  const navigation = useNavigate()

  return (
    <PageWrapper>
      <ActionBar top spaceBetween>
        <PageTitle>Playground Hub</PageTitle>
      </ActionBar>

      <Wrapper>
        <BlandBoxWrapper>
          <BlandBox id="bland-box">
            <img src={BlandVector} height={38} width={38} />
          </BlandBox>

          <p
            style={{
              fontWeight: '500',
              fontSize: 24,
              lineHeight: 3.2,
              letterSpacing: '-1%',
              margin: 0,
              padding: 0,
              color: '#0F161A',
            }}
          >
            Welcome to Bland Playground
          </p>
        </BlandBoxWrapper>

        <BoxWrapper>
          <Box onClick={() => navigation('/dashboard/playground-chat')}>
            <TopSection>
              <img
                src={PgImg}
                style={{ height: '80%', width: '80%', objectFit: 'contain' }}
              />
            </TopSection>

            <BottomSection>
              <p
                style={{
                  fontSize: 18,
                  lineHeight: 2.4,
                  fontWeight: '500',
                  color: '#030712',
                }}
              >
                Chat
              </p>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <p>Build and test your prompts.</p>
                <img src={RightArrow} />
              </div>
            </BottomSection>
          </Box>

          <Box
            onClick={() => navigation('/dashboard/playground-tools')}
            style={{ border: '1px solid #655FFF' }}
          >
            <TopSection
              id="top-section"
              style={{ backgroundColor: 'rgba(101,95,255,0.1)' }}
            >
              <img
                src={ToolsImg}
                style={{
                  height: '100%',
                  width: '100%',
                  objectFit: 'contain',
                  opacity: 1,
                  zIndex: 1000,
                }}
              />
            </TopSection>
            <BottomSection
              style={{
                backgroundColor: 'rgba(101,95,255,0.1)',
                borderBottomLeftRadius: 10,
                borderBottomRightRadius: 10,
              }}
            >
              <p
                style={{
                  fontSize: 18,
                  lineHeight: 2.4,
                  fontWeight: '500',
                  color: '#030712',
                }}
              >
                Tools
              </p>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <p>Build and test your tools.</p>
                <img src={RightArrow} />
              </div>
            </BottomSection>
          </Box>
        </BoxWrapper>
      </Wrapper>
    </PageWrapper>
  )
}

const TopSection = styled.div`
  height: 70%;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  background-image: url(${Dots});
  background-repeat: repeat;
  background-size: 100px 100px;
  background-color: rgba(255, 255, 255, 0.8);
  background-blend-mode: lighten;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
`

const BottomSection = styled.div`
  background-blend-mode: lighten;
  padding: 20px 20px;
`

const Box = styled.div`
  max-width: 300px;
  max-height: 340px;
  height: 100%;
  width: 60%;
  border-radius: 10px;
  justify-items: center;
  display: flex;
  flex-direction: column;
  border: 1px solid #e5e7eb;
  cursor: pointer;
`

const BoxWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-items: center;
  justify-content: center;
  align-items: center;
`

const BlandBoxWrapper = styled.div`
  width: 100%;
  align-items: center;
  justify-content: center;
  justify-items: center;
  display: flex;
  flex-direction: column;
`

const BlandBox = styled.div`
  height: 54px;
  width: 54px;
  border-radius: 9.6px;
  border: 0.24px solid #d1d5db;
  padding: 12px 12px 12px 12px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
`

const Option = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px;
  gap: 10px;
  border-radius: 10px;
  border: 1px solid #e5e5e5;
  width: 31.5%;
  min-height: 140px;
  max-width: 300px;
  cursor: pointer;
`

const Options = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 20px;
  margin-top: 20px;
`
