import Button from '_components/Button'
import { TooltipText } from '_components/Text'
import TextInput from '_components/TextInput'
// import Button from "components/core/Button";
import { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { testWebhook } from 'utils/funcs/phone-numbers/inbound/testWebhook'

export default function Webhook({ fieldName }) {
  const { getValues } = useFormContext()
  const [loading, setLoading] = useState()

  const onTest = async () => {
    const url = getValues(fieldName)
    setLoading(true)
    try {
      const { success, res } = await testWebhook(url)
      if (success) {
        console.log({ res })
        toast.success('Webhook test sent!')
      }
      setLoading(false)
    }
    catch (err) {
      setLoading(false)
      console.error('Error sending webhook test')
      toast('Error sending webhook test. Please try again.', {
        type: 'error',
      })
    }
  }
  const description = (
    <span>
      When the call ends, we’ll send the call details in a POST request to the
      URL you specify here.
    </span>
  )

  return (
    <Wrapper>
      <TextInput
        id="webhook-input"
        width="100%"
        fieldName={fieldName}
        label={(
          <TooltipText tooltip={description} tooltipClassName="w-[300px]">
            Webhook URL
          </TooltipText>
        )}
        placeholder="https://mywebhook.com/abc123"
      />
      <Button variant="soft" onClick={onTest} isLoading={loading}>
        Test Webhook
      </Button>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  align-items: end;
`
