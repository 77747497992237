import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from '@/components/ui/collapsible'
import { ScrollArea } from '@/components/ui/scroll-area'
import GradientLoadingAnimation from 'components/Reusables/GradientLoadingAnimation'
import { diffLines } from 'diff'
import { ChevronRight, ChevronsUpDown, Minus, Plus } from 'lucide-react'
import React from 'react'
import { $fetch } from 'utils/fetch'

function VersionCompare({
  beforeVersion,
  afterVersion,
  graphID,
  className,
}) {
  const [versions, setVersions] = React.useState({
    before: null,
    after: null,
  })
  const [isLoadingVersions, setIsLoadingVersions] = React.useState(false)

  React.useEffect(() => {
    const fetchVersions = async () => {
      setIsLoadingVersions(true)
      try {
        const [beforeData, afterData] = await Promise.all([
          $fetch(`/v1/pathway/${graphID}/version/${Math.abs(beforeVersion)}`, {
            method: 'GET',
          }),
          $fetch(`/v1/pathway/${graphID}/version/${Math.abs(afterVersion)}`, {
            method: 'GET',
          }),
        ])

        if (beforeData?.error || afterData?.error)
          return

        setVersions({
          before: beforeData,
          after: afterData,
        })
      }
      catch (error) {
        console.error('Error fetching versions:', error)
      }
      finally {
        setIsLoadingVersions(false)
      }
    }

    fetchVersions()
  }, [beforeVersion, afterVersion, graphID])

  // Function to find added, removed, and modified nodes
  const compareNodes = (before, after) => {
    const added = after.filter(
      node => !before.find(n => n.id === node.id),
    )
    const removed = before.filter(
      node => !after.find(n => n.id === node.id),
    )
    const modified = after.filter((node) => {
      const beforeNode = before.find(n => n.id === node.id)
      if (!beforeNode)
        return false

      // Create copies without the 'active' attribute for comparison
      const attributesToIgnore = ['active', 'isHighlighted', 'globalPrompt']
      const beforeCopy = { ...beforeNode }
      const afterCopy = { ...node }
      attributesToIgnore.forEach((attr) => {
        if (beforeCopy.data)
          delete beforeCopy.data[attr]
        if (afterCopy.data)
          delete afterCopy.data[attr]
      })

      return JSON.stringify(beforeCopy) !== JSON.stringify(afterCopy)
    })

    return { added, removed, modified }
  }

  // Function to find added, removed, and modified edges
  const compareEdges = (before, after) => {
    const added = after.filter(
      edge => !before.find(e => e.id === edge.id),
    )
    const removed = before.filter(
      edge => !after.find(e => e.id === edge.id),
    )
    const modified = after.filter((edge) => {
      const beforeEdge = before.find(e => e.id === edge.id)
      return beforeEdge && JSON.stringify(beforeEdge) !== JSON.stringify(edge)
    })

    return { added, removed, modified }
  }

  // Only show diffs when both versions are loaded
  const nodeDiffs = versions.before && versions.after
    ? compareNodes(versions.before.nodes, versions.after.nodes)
    : { added: [], removed: [], modified: [] }

  const edgeDiffs = versions.before && versions.after
    ? compareEdges(versions.before.edges, versions.after.edges)
    : { added: [], removed: [], modified: [] }

  const getAttributeDiffs = (before, after) => {
    const diffs = {}
    const allKeys = new Set([...Object.keys(before), ...Object.keys(after)])

    const attributesToIgnore = ['active', 'isHighlighted', 'globalPrompt']

    allKeys.forEach((key) => {
      if (JSON.stringify(before[key]) !== JSON.stringify(after[key])) {
        if (!attributesToIgnore.includes(key)) {
          diffs[key] = {
            before: before[key],
            after: after[key],
          }
        }
      }
    })

    return diffs
  }

  const AttributeDiff = ({ name, beforeValue, afterValue }) => {
  // Helper function to format value for diffing
    const formatValue = (value) => {
      if (typeof value === 'string')
        return value
      return JSON.stringify(value, null, 2)
    }

    // Get formatted values
    const beforeFormatted = beforeValue !== undefined ? formatValue(beforeValue) : ''
    const afterFormatted = afterValue !== undefined ? formatValue(afterValue) : ''

    // Generate diff
    const diff = diffLines(beforeFormatted, afterFormatted)

    return (
      <div className="pl-2.5 py-1.5 text-xs border-l-2 border-gray-200">
        <div className="font-medium text-gray-700 break-words">{name}</div>
        <div className="mt-0.5 font-mono text-2xs whitespace-pre-wrap">
          {diff.map((part, index) => {
            const color = part.added
              ? 'bg-green-50 text-green-700'
              : part.removed
                ? 'bg-red-50 text-red-700'
                : 'text-gray-600'
            const prefix = part.added
              ? '+ '
              : part.removed ? '- ' : '  '
            return (
              <div
                key={index}
                className={`${color} ${part.added || part.removed ? 'px-1.5 py-0.5' : ''}`}
              >
                {part.value.split('\n').map((line, i) =>
                  line && (
                    <div key={i}>
                      {prefix}
                      {line}
                    </div>
                  ),
                )}
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  const ItemDiff = ({ item, type, beforeItem, nodes }) => {
    const [isOpen, setIsOpen] = React.useState(false)
    const [showAdvanced, setShowAdvanced] = React.useState(false)

    const getNodeName = (nodeId) => {
      const node = nodes?.find(n => n.id === nodeId)
      return node?.data?.name || 'Unknown Node'
    }

    const itemName = item.type === 'custom'
      ? `${getNodeName(item?.source)} → ${getNodeName(item?.target)}`
      : (item.data?.name || 'Unnamed')

    const getBackgroundColor = () => {
      switch (type) {
        case 'added': return 'border-green-200 bg-green-50'
        case 'removed': return 'border-red-200 bg-red-50'
        default: return 'hover:bg-gray-50'
      }
    }

    const getItemData = () => {
      if (type === 'modified') {
        const diffs = getAttributeDiffs(beforeItem?.data || {}, item?.data || {})
        const modelOptionsDiffs = diffs.modelOptions
          ? getAttributeDiffs(diffs.modelOptions.before || {}, diffs.modelOptions.after || {})
          : {}
        const regularDiffs = { ...diffs }
        delete regularDiffs.modelOptions
        return { regularDiffs, modelOptionsDiffs, isModified: true }
      }
      else {
        const itemData = { ...item?.data } || {}
        const modelOptions = itemData.modelOptions || {}
        delete itemData.modelOptions
        return { regularDiffs: itemData, modelOptionsDiffs: modelOptions, isModified: false }
      }
    }

    const { regularDiffs, modelOptionsDiffs, isModified } = getItemData()
    const shouldShowAsRemoved = type === 'removed'

    return (
      <Collapsible open={isOpen} onOpenChange={setIsOpen}>
        <CollapsibleTrigger className="w-full">
          <div className={`flex items-center gap-1.5 p-2 rounded border ${getBackgroundColor()}`}>
            <ChevronsUpDown className="h-2.5 w-2.5 flex-shrink-0 text-gray-500" />
            <div className="flex-1 text-left min-w-0">
              <div className="flex flex-wrap items-center gap-1.5">
                {type === 'added' && <Plus className="h-2.5 w-2.5 flex-shrink-0 text-green-600" />}
                {type === 'removed' && <Minus className="h-2.5 w-2.5 flex-shrink-0 text-red-600" />}
                <span className="font-medium break-words">{itemName}</span>
                {isModified && (
                  <span className="text-2xs text-gray-500 flex-shrink-0">
                    {Object.keys(regularDiffs).length}
                    {' '}
                    changes
                  </span>
                )}
                {item.type !== 'custom' && (
                  <span className="text-2xs px-1.5 py-px rounded-full bg-gray-200 text-gray-700 flex-shrink-0">
                    {item.type}
                  </span>
                )}
              </div>
            </div>
          </div>
        </CollapsibleTrigger>
        <CollapsibleContent>
          <div className="mt-1.5 space-y-2">
            {isModified
              ? (
                  Object.entries(regularDiffs).map(([key, values]) => (
                    <AttributeDiff
                      key={key}
                      name={key}
                      beforeValue={values.before}
                      afterValue={values.after}
                    />
                  ))
                )
              : (
                  Object.entries(regularDiffs).map(([key, value]) => (
                    <AttributeDiff
                      key={key}
                      name={key}
                      beforeValue={shouldShowAsRemoved ? value : undefined}
                      afterValue={!shouldShowAsRemoved ? value : undefined}
                    />
                  ))
                )}

            {Object.keys(modelOptionsDiffs).length > 0 && (
              <Collapsible open={showAdvanced} onOpenChange={setShowAdvanced}>
                <CollapsibleTrigger className="w-full">
                  <div className="flex items-center gap-1.5 p-1.5 hover:bg-gray-50 rounded border mt-1.5">
                    <ChevronsUpDown className="h-2.5 w-2.5 text-gray-500" />
                    <span className="font-medium text-xs">Advanced Options</span>
                  </div>
                </CollapsibleTrigger>
                <CollapsibleContent>
                  {isModified
                    ? (
                        Object.entries(modelOptionsDiffs).map(([key, values]) => (
                          <AttributeDiff
                            key={key}
                            name={key}
                            beforeValue={values.before}
                            afterValue={values.after}
                          />
                        ))
                      )
                    : (
                        Object.entries(modelOptionsDiffs).map(([key, value]) => (
                          <AttributeDiff
                            key={key}
                            name={key}
                            beforeValue={shouldShowAsRemoved ? value : undefined}
                            afterValue={!shouldShowAsRemoved ? value : undefined}
                          />
                        ))
                      )}
                </CollapsibleContent>
              </Collapsible>
            )}
          </div>
        </CollapsibleContent>
      </Collapsible>
    )
  }

  const DiffSection = ({ title, items, type, beforeItems, nodes }) => {
    if (items.length === 0)
      return null

    return (
      <div className="mb-4">
        <h3 className="text-xs font-medium text-gray-700 mb-2 flex items-center gap-1.5">
          <ChevronRight className="h-2.5 w-2.5" />
          {title}
          {' '}
          (
          {items.length}
          )
        </h3>
        <div className="space-y-1.5">
          {items.map(item => (
            <ItemDiff
              key={item.id}
              item={item}
              type={type}
              beforeItem={type === 'modified' ? beforeItems?.find(b => b.id === item.id) : null}
              nodes={nodes}
            />
          ))}
        </div>
      </div>
    )
  }

  if (isLoadingVersions) {
    return (
      <div className="h-[400px] flex items-center justify-center">
        <GradientLoadingAnimation
          message="Loading version comparison..."
          variant="default"
          width={16}
          duration={2}
          gray
        />
      </div>
    )
  }

  const hasNoChanges
    = nodeDiffs.added.length === 0
      && nodeDiffs.removed.length === 0
      && nodeDiffs.modified.length === 0
      && edgeDiffs.added.length === 0
      && edgeDiffs.removed.length === 0
      && edgeDiffs.modified.length === 0

  if (hasNoChanges) {
    return (
      <div className="h-[400px] flex items-center justify-center">
        <div className="text-center text-gray-500">
          <p className="text-sm font-medium">No Changes</p>
          <p className="text-xs">These versions are identical</p>
        </div>
      </div>
    )
  }

  return (
    <ScrollArea className={className || 'h-[400px]'}>
      <div className="space-y-5">
        <div>
          <h2 className="text-sm font-semibold mb-2.5">Nodes</h2>
          <DiffSection
            title="Added"
            items={nodeDiffs.added}
            type="added"
            beforeItems={versions.before?.nodes}
            nodes={versions.after?.nodes}
          />
          <DiffSection
            title="Removed"
            items={nodeDiffs.removed}
            type="removed"
            beforeItems={versions.before?.nodes}
            nodes={versions.after?.nodes}
          />
          <DiffSection
            title="Modified"
            items={nodeDiffs.modified}
            type="modified"
            beforeItems={versions.before?.nodes}
            nodes={versions.after?.nodes}
          />
        </div>

        <div>
          <h2 className="text-sm font-semibold mb-2.5">Pathway Labels</h2>
          <DiffSection
            title="Added"
            items={edgeDiffs.added}
            type="added"
            beforeItems={versions.before?.edges}
            nodes={versions.after?.nodes}
          />
          <DiffSection
            title="Removed"
            items={edgeDiffs.removed}
            type="removed"
            beforeItems={versions.before?.edges}
            nodes={versions.after?.nodes}
          />
          <DiffSection
            title="Modified"
            items={edgeDiffs.modified}
            type="modified"
            beforeItems={versions.before?.edges}
            nodes={versions.after?.nodes}
          />
        </div>
      </div>
    </ScrollArea>
  )
}

export default VersionCompare
