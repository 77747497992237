import { Alert, AlertDescription } from '@/components/ui/alert'
import {
  AlertCircle,
  CheckCircle2,
  Copy,
} from 'lucide-react'
import { useEffect, useRef, useState } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import styled from 'styled-components'

function JsonEditor({ fieldName, inputSuggestions = [] }) {
  const { setValue, getValues } = useFormContext()

  const jsonInput = useWatch({
    name: fieldName,
    control: useFormContext().control,
  })

  const setJsonInput = text => setValue(fieldName, text)
  //   const [jsonInput, setJsonInput] = useState(
  //     '{\n  "example": "Type {{input."\n}',
  //   );
  const [isValid, setIsValid] = useState(true)
  const [errorMessage, setErrorMessage] = useState('')
  const [formattedJson, setFormattedJson] = useState('')
  const [dropdownPosition, setDropdownPosition] = useState(null)
  const [suggestions, setSuggestions] = useState([])
  const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(0)
  const textareaRef = useRef(null)
  const dropdownRef = useRef(null)

  //   // Available suggestions for {{input.
  //   const inputSuggestions = [
  //     {
  //       label: "name",
  //       value: "name",
  //       description: "{{input.name}} • string",
  //     },
  //     {
  //       label: "email",
  //       value: "email",
  //       description: "{{input.email}} • string",
  //     },
  //   ];

  const validateAndFormatJson = (input) => {
    try {
      if (!input.trim()) {
        setIsValid(false)
        setErrorMessage('JSON cannot be empty')
        return
      }
      const parsed = JSON.parse(input)
      const formatted = JSON.stringify(parsed, null, 2)
      setFormattedJson(formatted)
      setIsValid(true)
      setErrorMessage('')
    }
    catch (e) {
      setIsValid(false)
      setErrorMessage(e.message)
    }
  }

  useEffect(() => {
    const body = getValues(fieldName)

    if (Array.isArray(body)) {
      setValue(fieldName, '')
    }
  }, [])

  useEffect(() => {
    validateAndFormatJson(jsonInput)
  }, [jsonInput])

  // Handle click outside dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownPosition(null)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [])

  const handleKeyDown = (e) => {
    const textarea = textareaRef.current
    const { selectionStart, selectionEnd } = textarea

    // Handle dropdown navigation and selection
    if (dropdownPosition && suggestions.length > 0) {
      if (e.key === 'ArrowDown') {
        e.preventDefault()
        setSelectedSuggestionIndex(prev =>
          prev < suggestions.length - 1 ? prev + 1 : prev,
        )
        return
      }
      if (e.key === 'ArrowUp') {
        e.preventDefault()
        setSelectedSuggestionIndex(prev => (prev > 0 ? prev - 1 : prev))
        return
      }
      if (e.key === 'Enter' || e.key === 'Tab') {
        e.preventDefault()
        insertSuggestion(suggestions[selectedSuggestionIndex])
        return
      }
      if (e.key === 'Escape') {
        e.preventDefault()
        setDropdownPosition(null)
        return
      }
    }

    // Handle Tab key
    if (e.key === 'Tab') {
      e.preventDefault()

      // If there's selected text, indent/unindent block
      if (selectionStart !== selectionEnd) {
        const selectedText = jsonInput.substring(selectionStart, selectionEnd)
        const lines = selectedText.split('\n')

        const newLines = lines.map(line =>
          e.shiftKey ? line.replace(/^ {2}/, '') : `  ${line}`,
        )

        const newText
          = jsonInput.substring(0, selectionStart)
            + newLines.join('\n')
            + jsonInput.substring(selectionEnd)

        setJsonInput(newText)

        // Maintain selection
        setTimeout(() => {
          const newSelStart = selectionStart + (!e.shiftKey ? 2 : -2)
          const newSelEnd
            = selectionEnd + (!e.shiftKey ? 2 * lines.length : -2 * lines.length)
          textarea.setSelectionRange(newSelStart, newSelEnd)
        }, 0)
      }
      else {
        // No selection - just insert spaces
        const newText
          = `${jsonInput.substring(0, selectionStart)
          }  ${
            jsonInput.substring(selectionEnd)}`

        setJsonInput(newText)

        // Move cursor after inserted spaces
        setTimeout(() => {
          textarea.setSelectionRange(selectionStart + 2, selectionStart + 2)
        }, 0)
      }
      return
    }

    // Auto-closing pairs
    const pairs = {
      '{': '}',
      '[': ']',
      '"': '"',
    }

    if (pairs[e.key]) {
      e.preventDefault()
      const selectedText = jsonInput.substring(selectionStart, selectionEnd)
      const pair = pairs[e.key]

      const newText
        = jsonInput.substring(0, selectionStart)
          + e.key
          + selectedText
          + pair
          + jsonInput.substring(selectionEnd)

      setJsonInput(newText)

      setTimeout(() => {
        const newPosition = selectionStart + 1 + selectedText.length
        textarea.setSelectionRange(newPosition, newPosition)
      }, 0)
      return
    }

    // Auto-indentation after pressing Enter
    if (e.key === 'Enter') {
      e.preventDefault()
      const beforeCursor = jsonInput.substring(0, selectionStart)
      const afterCursor = jsonInput.substring(selectionStart)
      const lastLine = beforeCursor.split('\n').pop()
      const indent = lastLine.match(/^\s*/)[0]

      const extraIndent = /[{[]$/.test(lastLine.trim()) ? '  ' : ''

      const newText = `${beforeCursor}\n${indent}${extraIndent}${afterCursor}`
      setJsonInput(newText)

      setTimeout(() => {
        const newPosition
          = selectionStart + 1 + indent.length + extraIndent.length
        textarea.setSelectionRange(newPosition, newPosition)
      }, 0)
    }

    // Handle backspace to remove pairs together
    if (e.key === 'Backspace' && selectionStart === selectionEnd) {
      const char = jsonInput[selectionStart - 1]
      const nextChar = jsonInput[selectionStart]
      if (pairs[char] === nextChar) {
        e.preventDefault()
        const newText
          = jsonInput.substring(0, selectionStart - 1)
            + jsonInput.substring(selectionStart + 1)
        setJsonInput(newText)
        setTimeout(() => {
          textarea.setSelectionRange(selectionStart - 1, selectionStart - 1)
        }, 0)
      }
    }
  }

  const handleInput = (e) => {
    const newValue = e.target.value
    setJsonInput(newValue)

    const textarea = textareaRef.current
    const { selectionStart } = textarea

    // Get the current line and character position
    const currentLineStart = newValue.lastIndexOf('\n', selectionStart - 1) + 1
    const currentLine = newValue.substring(currentLineStart, selectionStart)

    // Check if we're typing {{input.
    if (currentLine.match(/\{\{input\.$/)) {
      const rect = textarea.getBoundingClientRect()
      const lineHeight = Number.parseInt(getComputedStyle(textarea).lineHeight)
      const currentLineNumber = newValue
        .substring(0, selectionStart)
        .split('\n')
        .length

      // Calculate dropdown position
      const top = rect.top + currentLineNumber * lineHeight
      const left = rect.left + currentLine.length * 8 // Approximate character width

      setDropdownPosition({ top, left })
      setSuggestions(inputSuggestions)
      setSelectedSuggestionIndex(0)
    }
    else if (!currentLine.match(/\{\{input\.[a-zA-Z]*$/)) {
      setDropdownPosition(null)
    }
    else if (currentLine.match(/\{\{input\.[a-zA-Z]*$/)) {
      // Filter suggestions based on what's typed after {{input.
      const search = currentLine
        .match(/\{\{input\.([a-zA-Z]*)$/)[1]
        .toLowerCase()
      const filtered = inputSuggestions.filter(s =>
        s.value.toLowerCase().startsWith(search),
      )
      setSuggestions(filtered)
      setSelectedSuggestionIndex(0)

      if (filtered.length > 0) {
        const rect = textarea.getBoundingClientRect()
        const lineHeight = Number.parseInt(getComputedStyle(textarea).lineHeight)
        const currentLineNumber = newValue
          .substring(0, selectionStart)
          .split('\n')
          .length

        const top = rect.top + currentLineNumber * lineHeight
        const left = rect.left + currentLine.length * 8

        setDropdownPosition({ top, left })
      }
      else {
        setDropdownPosition(null)
      }
    }
  }
  const insertSuggestion = (suggestion) => {
    const textarea = textareaRef.current
    const { selectionStart } = textarea
    const text = jsonInput

    // Find the start of {{input.
    const inputStart = text.lastIndexOf('{{input.', selectionStart)
    if (inputStart === -1)
      return

    // Check if there's already a trailing }} in the text
    const trailingBraces = text.substring(selectionStart).startsWith('}}')
    const suggestionText = trailingBraces
      ? `{{input.${suggestion.value}`
      : `{{input.${suggestion.value}}}`

    // Insert the suggestion and close the variable
    const newText
      = text.substring(0, inputStart)
        + suggestionText
        + text.substring(selectionStart)

    setJsonInput(newText)
    setDropdownPosition(null)

    // Move cursor after the inserted suggestion
    setTimeout(() => {
      const newPosition = inputStart + suggestionText.length
      textarea.setSelectionRange(newPosition, newPosition)
    }, 0)
  }

  const handlePaste = (e) => {
    e.preventDefault()
    const pastedText = e.clipboardData.getData('text')

    try {
      const parsed = JSON.parse(pastedText)
      const formatted = JSON.stringify(parsed, null, 2)

      const textarea = textareaRef.current
      const { selectionStart, selectionEnd } = textarea

      const newText
        = jsonInput.substring(0, selectionStart)
          + formatted
          + jsonInput.substring(selectionEnd)

      setJsonInput(newText)
    }
    catch {
      const textarea = textareaRef.current
      const { selectionStart, selectionEnd } = textarea

      const newText
        = jsonInput.substring(0, selectionStart)
          + pastedText
          + jsonInput.substring(selectionEnd)

      setJsonInput(newText)
    }
  }

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(jsonInput)
    }
    catch (err) {
      console.error('Failed to copy text: ', err)
    }
  }

  const handleFileUpload = (e) => {
    const file = e.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.onload = (e) => {
        try {
          const content = e.target.result
          const parsed = JSON.parse(content)
          setJsonInput(JSON.stringify(parsed, null, 2))
        }
        catch (error) {
          setErrorMessage('Invalid JSON file')
        }
      }
      reader.readAsText(file)
    }
  }

  const downloadJson = () => {
    if (!isValid)
      return

    const blob = new Blob([jsonInput], { type: 'application/json' })
    const url = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = 'data.json'
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    URL.revokeObjectURL(url)
  }

  return (
    <div className="w-full mx-auto p-0 space-y-2.5">
      <div className="flex items-center justify-between">
        <Label className="max-w-[20%]">Body</Label>
        <div className="flex items-center gap-1.5">
          {isValid
            ? (
                <CheckCircle2 className="text-green-500 w-3 h-3" />
              )
            : (
                <AlertCircle className="text-red-500 w-3 h-3" />
              )}
          <span className={isValid ? 'text-green-500' : 'text-red-500'}>
            {isValid ? 'Valid JSON' : 'Invalid JSON'}
          </span>
        </div>
      </div>

      <div className="relative">
        <textarea
          ref={textareaRef}
          value={jsonInput}
          onChange={handleInput}
          onKeyDown={handleKeyDown}
          onPaste={handlePaste}
          className={`w-full h-60 p-2.5 font-mono text-xs rounded-md border-2 focus:outline-none focus:ring-2 focus:ring-offset-2 
            ${
    isValid
      ? 'border-green-200 focus:border-green-300 focus:ring-green-200'
      : 'border-red-200 focus:border-red-300 focus:ring-red-200'
    }`}
          placeholder="Enter your JSON here..."
          spellCheck="false"
          style={{ fontSize: 12.5, fontWeight: '500' }}
        />

        {dropdownPosition && (
          <div
            ref={dropdownRef}
            className="sticky bg-white shadow-lg rounded border border-gray-200 max-h-148 overflow-y-auto z-50"
            style={{
              // top: `${dropdownPosition.top}px`,
              left: `${dropdownPosition.left}px`,
            }}
          >
            {suggestions.map((suggestion, index) => (
              <div
                key={index}
                className={`px-2.5 py-1.5 cursor-pointer flex flex-col
                  ${index === selectedSuggestionIndex ? 'bg-blue-50' : 'hover:bg-blue-50'}`}
                onClick={() => insertSuggestion(suggestion)}
              >
                <span className="font-medium">{suggestion.label}</span>
                <span className="text-xs text-gray-500">
                  {suggestion.description}
                </span>
              </div>
            ))}
          </div>
        )}
      </div>

      {!isValid && (
        <Alert variant="destructive" className="border-red-200 bg-red-50">
          <AlertCircle className="h-2.5 w-2.5" />
          <AlertDescription className="text-red-800">
            {errorMessage}
          </AlertDescription>
        </Alert>
      )}

      <div className="flex gap-2.5 justify-end">
        {/* <input
          type="file"
          accept=".json"
          onChange={handleFileUpload}
          className="hidden"
          id="json-upload"
        />
        <label
          htmlFor="json-upload"
          className="px-2.5 py-1.5 text-xs rounded bg-gray-100 hover:bg-gray-200 transition-colors cursor-pointer flex items-center gap-1.5"
        >
          <Upload className="w-2.5 h-2.5" />
          Import
        </label>

        <button
          onClick={downloadJson}
          disabled={!isValid}
          className={`px-2.5 py-1.5 text-xs rounded transition-colors flex items-center gap-1.5
            ${
              isValid
                ? "bg-gray-100 hover:bg-gray-200"
                : "bg-gray-300 cursor-not-allowed text-gray-500"
            }`}
        >
          <Download className="w-2.5 h-2.5" />
          Export
        </button> */}

        <button
          type="button"
          onClick={() => setJsonInput('{\n  \n}')}
          className="px-2.5 py-1.5 text-xs rounded bg-gray-100 hover:bg-gray-200 transition-colors"
        >
          Clear
        </button>

        <button
          type="button"
          onClick={copyToClipboard}
          className="px-2.5 py-1.5 text-xs rounded bg-gray-100 hover:bg-gray-200 transition-colors flex items-center gap-1.5"
        >
          <Copy className="w-2.5 h-2.5" />
          Copy
        </button>

        <button
          type="button"
          onClick={() => {
            if (isValid) {
              setJsonInput(formattedJson)
            }
          }}
          disabled={!isValid}
          className={`px-2.5 py-1.5 text-xs rounded transition-colors
            ${
    isValid
      ? 'bg-blue-500 hover:bg-blue-600 text-white'
      : 'bg-gray-300 cursor-not-allowed text-gray-500'
    }`}
        >
          Format JSON
        </button>
      </div>
    </div>
  )
}

const Label = styled.p`
  margin: 0px;
  padding: 0px;
  color: #000;
  font-size: 13px;
  font-weight: 500;
  width: 100%;
`

export default JsonEditor
