import { Button, Card, CardBody, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader } from '@heroui/react'
import { Icon } from '@iconify/react'
import { useQuery } from '@tanstack/react-query'
import CallDetailsSlideOut from 'components/Dashboard/CallLogs/CallDetailsSlideOut'
import GradientLoadingAnimation from 'components/Reusables/GradientLoadingAnimation'
import { formatDistanceToNow } from 'date-fns'
import { Book } from 'lucide-react'
import React from 'react'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { $fetch } from 'utils/fetch'
import CreateMemoryStoreModal from './CreateMemoryStoreModal'
import { MemoryStoreCard } from './MemoryStoreCard'
import { MemoryStoreDetail } from './MemoryStoreDetails'

export default function MemoryStores() {
  const [selectedMemoryStore, setSelectedMemoryStore] = React.useState(null)
  const [searchTerm, setSearchTerm] = React.useState('')
  const [selectedCallId, setSelectedCallId] = React.useState(null)
  const [isCallDetailsOpen, setIsCallDetailsOpen] = React.useState(false)
  const [isCreateModalOpen, setIsCreateModalOpen] = React.useState(false)
  const [showSuccessModal, setShowSuccessModal] = React.useState(false)
  const [newlyCreatedMemory, setNewlyCreatedMemory] = React.useState(null)

  // Use a ref to remember we came from create=true, even after URL changes
  const wasCreateMode = React.useRef(false)

  const params = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const memoryIdFromUrl = params.memory_id

  const shouldCreateMemory = searchParams.get('create') === 'true'

  const callDetailsRef = React.useRef(null)

  const handleCloseCallDetails = () => {
    setIsCallDetailsOpen(false)
    setSelectedCallId(null)
  }

  React.useEffect(() => {
    function handleClickOutside(event) {
      if (callDetailsRef.current && !callDetailsRef.current.contains(event.target)) {
        handleCloseCallDetails()
      }
    }

    if (isCallDetailsOpen) {
      document.addEventListener('mousedown', handleClickOutside)
    }
    else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isCallDetailsOpen])

  const { data: memoryStores = [], isLoading: isMemoryStoresLoading, refetch } = useQuery({
    queryKey: ['memoryStores'],
    queryFn: () => $fetch('/v1/memory'),
    select: data => Array.isArray(data.data.memories) ? data.data.memories : [],
  })

  // Set selected memory store based on URL parameter
  React.useEffect(() => {
    if (memoryIdFromUrl && memoryStores.length > 0) {
      const foundMemory = memoryStores.find(store => store.id === memoryIdFromUrl)
      if (foundMemory) {
        setSelectedMemoryStore(foundMemory)
      }
    }
  }, [memoryIdFromUrl, memoryStores])

  // Open modal automatically if the create parameter is in the URL
  React.useEffect(() => {
    if (shouldCreateMemory) {
      setIsCreateModalOpen(true)
      // Set our ref so we remember this was a create operation
      wasCreateMode.current = true

      // Remove the create parameter from the URL to avoid reopening the modal on refresh
      if (searchParams.has('create')) {
        const newSearchParams = new URLSearchParams(searchParams)
        newSearchParams.delete('create')
        navigate({ search: newSearchParams.toString() }, { replace: true })
      }
    }
  }, [shouldCreateMemory, searchParams, navigate])

  const filteredMemoryStores = (Array.isArray(memoryStores) ? memoryStores : [])?.filter(
    store =>
      store.name.toLowerCase().includes(searchTerm.toLowerCase())
      || store.id.toLowerCase().includes(searchTerm.toLowerCase()),
  )

  const handleSearch = (value) => {
    setSearchTerm(value)
  }

  const handleBackClick = () => {
    setSelectedMemoryStore(null)
    // Update URL when going back to list view
    navigate('/dashboard/memory')
  }

  const handleViewCall = (callId) => {
    setSelectedCallId(callId)
    setIsCallDetailsOpen(true)
  }

  const handleMemoryStoreClick = (memoryStore) => {
    setSelectedMemoryStore(memoryStore)
    // Update URL when selecting a memory store
    navigate(`/dashboard/memory/${memoryStore.id}`)
  }

  // Modified function to handle successful memory creation
  const handleMemoryCreated = (newMemoryStore) => {
    refetch()

    // If the memory was created from create mode (using our ref instead of URL param)
    if (wasCreateMode.current) {
      console.log('Memory created in create mode, showing success modal', newMemoryStore)
      setNewlyCreatedMemory(newMemoryStore)
      setShowSuccessModal(true)
    }
  }

  return (
    <div className="relative p-6 max-w-[1200px] mx-auto overflow-hidden h-screen">
      <div className="max-w-7xl h-full overflow-hidden">
        {/* Main Content */}
        <div className="col-span-2">
          <div className="flex items-center justify-between mb-6">
            <div>
              <h1 className="text-2xl font-bold">Memory Stores</h1>
              <p className="text-default-500">View and manage memory stores and their associated users</p>
            </div>
            <div className="flex items-center gap-2">
              {!selectedMemoryStore && (
                <Button
                  color="primary"
                  startContent={<Icon icon="lucide:plus-circle" size={16} />}
                  onPress={() => setIsCreateModalOpen(true)}
                >
                  Create New Memory
                </Button>
              )}
              {selectedMemoryStore && (
                <Button
                  variant="bordered"
                  startContent={<Icon icon="lucide:chevron-left" size={16} />}
                  onPress={handleBackClick}
                >
                  Back to Memory Stores
                </Button>
              )}
            </div>
          </div>

          {isMemoryStoresLoading
            ? (
                <div className="w-full h-screen flex items-center justify-center">
                  <GradientLoadingAnimation
                    message="Loading Memory Store"
                    variant="roseGarden"
                    rounded="full"
                  />
                </div>
              )
            : selectedMemoryStore
              ? (
                  <div className="flex-1 overflow-auto w-full">
                    <Card className="mb-6">
                      <CardBody className="p-4">
                        <div className="flex items-center">
                          <div className="bg-primary-100 p-2 rounded-full">
                            <Icon icon="lucide:brain" size={24} className="text-primary" />
                          </div>
                          <div className="ml-3">
                            <h2 className="text-xl font-semibold">{selectedMemoryStore.name}</h2>
                            <div className="text-sm text-default-500 flex items-center gap-3">
                              <span>
                                ID:
                                {' '}
                                <span className="font-mono">{selectedMemoryStore.id}</span>
                              </span>
                              <span>•</span>
                              <span>
                                Created
                                {' '}
                                {formatDistanceToNow(new Date(selectedMemoryStore.created_at), { addSuffix: true })}
                              </span>
                            </div>
                          </div>
                        </div>
                        <p className="mt-2 text-default-700">{selectedMemoryStore.description}</p>
                      </CardBody>
                    </Card>

                    <MemoryStoreDetail
                      memoryStore={selectedMemoryStore}
                      onViewCall={handleViewCall}
                    />
                  </div>
                )
              : (
                  <div className="flex-1 overflow-hidden">
                    <div className="mb-6 relative">
                      <Input
                        placeholder="Search memory stores..."
                        value={searchTerm}
                        onValueChange={handleSearch}
                        startContent={<Icon icon="lucide:search" className="text-default-400" />}
                        className="max-w-md"
                      />
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 overflow-y-auto" style={{ maxHeight: 'calc(100vh - 200px)' }}>
                      {filteredMemoryStores.map(memoryStore => (
                        <MemoryStoreCard
                          key={memoryStore.id}
                          memoryStore={memoryStore}
                          onClick={() => handleMemoryStoreClick(memoryStore)}
                          onUpdate={refetch}
                        />
                      ))}
                    </div>

                    {filteredMemoryStores.length === 0 && (
                      <div className="text-center py-12">
                        <div className="bg-default-100 inline-flex p-4 rounded-full mb-4">
                          <Icon icon="lucide:brain" size={32} className="text-default-400" />
                        </div>
                        <h3 className="text-lg font-medium mb-2">No memory stores found</h3>
                        <p className="text-default-500">Try adjusting your search or create a new memory store.</p>
                      </div>
                    )}
                  </div>
                )}
        </div>

        {/* Modals */}
        <CreateMemoryStoreModal
          isOpen={isCreateModalOpen}
          onOpenChange={setIsCreateModalOpen}
          onSuccess={handleMemoryCreated}
        />

        {selectedCallId && isCallDetailsOpen && (
          <div
            className="absolute bottom-0 right-0 h-[100%] w-[80%] z-50 bg-white shadow-xl overflow-hidden border-l p-0 m-0"
            ref={callDetailsRef}
          >
            <div className="grid grid-rows-5 grid-cols-[5rem_minmax(15rem,1fr)_2fr] h-[calc(100vh-60px)]">
              {/* Minimal empty column to maintain grid structure */}
              <div className="col-start-1 row-span-full"></div>

              <CallDetailsSlideOut
                callId={selectedCallId}
                onClose={handleCloseCallDetails}
              />
            </div>
          </div>
        )}

        {/* Success Modal */}
        {(() => {
          console.log('Success modal state:', {
            showSuccessModal,
            newlyCreatedMemory,
            wasCreateMode: wasCreateMode.current,
          })
          return (
            <Modal
              isOpen={showSuccessModal}
              onOpenChange={setShowSuccessModal}
              placement="top-center"
              classNames={{
                base: 'max-w-xl',
                body: 'flex flex-col gap-5 w-full',
              }}
              radius="sm"
            >
              <ModalContent>
                <ModalHeader className="flex flex-col gap-1">
                  <div className="flex items-center gap-2">
                    <div className="bg-success/10 p-2 rounded-full">
                      <Icon icon="lucide:check-circle" className="text-success h-6 w-6" />
                    </div>
                    <h3 className="text-xl font-semibold">Memory Store Created Successfully</h3>
                  </div>
                </ModalHeader>

                <ModalBody>
                  <div className="space-y-4">
                    <h4>
                      Your memory store
                      {' '}
                      <span className="font-semibold">{newlyCreatedMemory?.name}</span>
                      {' '}
                      has been created successfully.
                    </h4>

                    <div className="bg-primary-50 p-4 rounded-lg space-y-3">
                      <h4 className="text-sm font-medium flex items-center gap-2">
                        <Icon icon="lucide:info" className="text-primary" />
                        Next Steps
                      </h4>
                      <ul className="text-sm text-default-600 space-y-2">
                        <li className="flex items-start gap-2">
                          <Icon icon="lucide:arrow-left" className="text-primary mt-0.5 flex-shrink-0" />
                          <span>Select this memory store from the dropdown, and trigger a call!</span>
                        </li>
                        <li className="flex items-start gap-2">
                          <Icon icon="lucide:phone" className="text-primary mt-0.5 flex-shrink-0" />
                          <span>Any calls using this memory store will remember users based on their phone number. Call again using the same memory store to see the magic!</span>
                        </li>
                        <li className="flex items-start gap-2">
                          <Icon icon="lucide:repeat" className="text-primary mt-0.5 flex-shrink-0" />
                          <span>This works across both inbound and outbound calls</span>
                        </li>
                        <li className="flex items-start gap-2">
                          <Book className="text-primary mt-0.5 flex-shrink-0" />
                          <span>
                            Get insights into the memory stored for users calls by clicking the
                            <br />
                            <a onClick={() => window.location.href = '/dashboard/memory'} className="text-xs text-indigo-600 hover:text-indigo-800 dark:text-indigo-400 px-2 dark:hover:text-indigo-300 cursor-pointer">View Memory</a>
                            {' '}
                            button or going to Knowledge Bases &gt;
                            {' '}
                            <a className="text-xs text-indigo-600 hover:text-indigo-800 dark:text-indigo-400 px-2 dark:hover:text-indigo-300 cursor-pointer" onClick={() => window.location.href = '/dashboard/memory'}>Memory Stores</a>
                          </span>
                        </li>
                      </ul>
                    </div>

                    <div className="bg-default-50 p-4 rounded-lg">
                      <p className="text-sm text-default-600 flex items-center gap-2">
                        <Icon icon="lucide:clipboard-copy" className="text-default-500 flex-shrink-0" />
                        <span>
                          Memory ID:
                          <span className="font-mono text-xs bg-default-100 px-1.5 py-0.5 rounded">{newlyCreatedMemory?.id}</span>
                        </span>
                      </p>
                    </div>
                  </div>
                </ModalBody>

                <ModalFooter className="flex gap-2">
                  <Button
                    variant="bordered"
                    onPress={() => {
                      setShowSuccessModal(false)
                    }}
                  >
                    Stay on this page
                  </Button>
                  <Button
                    color="primary"
                    onPress={() => {
                      setShowSuccessModal(false)
                      // Close the window/tab when the user clicks confirm
                      window.close()
                    }}
                    startContent={<Icon icon="lucide:check" />}
                  >
                    Got it, send a call!
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          )
        })()}
      </div>
    </div>
  )
}
