import { Input } from '@heroui/react'
import MemorySelect from 'components/Dashboard/Parameters/Memory'
import PromptOrPathway from 'components/Dashboard/SendCall/PromptOrPathway'
import PronounciationGuide from 'components/Dashboard/SendCall/PronounciationGuide'
import AnalysisPresetSelect from 'components/Hero/AnalysisPresetSelect'
import BackgroundSelect from 'components/Hero/BackgroundSelect'
import CustomDeployment from 'components/Hero/CustomDeployment'
import DurationRange from 'components/Hero/DurationRange'
import InterruptionRange from 'components/Hero/InterruptionRange'
import LanguageSelect from 'components/Hero/LanguageSelect'
import ModelSelect from 'components/Hero/ModelSelect'
import ObjectInput from 'components/Hero/ObjectInput'
import PathwaySelect from 'components/Hero/PathwaySelect'
import PhoneInput from 'components/Hero/PhoneInput'
import PhoneSelect from 'components/Hero/PhoneSelect'
import SMSPromptInput from 'components/Hero/SMSPromptInput'
import SummaryInput from 'components/Hero/SummaryInput'
import SwitchInput from 'components/Hero/SwitchInput'
import TemperatureRange from 'components/Hero/TemperatureRange'
import TimezoneSelect from 'components/Hero/TimezoneSelect'
import ToolSelect from 'components/Hero/ToolSelect'
import VoicemailInput from 'components/Hero/VoicemailInput'
import VoicemailSelect from 'components/Hero/VoicemailSelect'
import VoicemailSMSInput from 'components/Hero/VoicemailSmsInput'
import VoiceSelect from 'components/Hero/VoiceSelect'
import WebhookInput from 'components/Hero/WebhookInput'
import WebhookSelect from 'components/Hero/WebhookSelect'
import { AudioLines, Cog, GitBranch, Headset, PhoneForwarded, PieChart, Send, Voicemail } from 'lucide-react'

const SendCallForm = {
  defaultExpandedKeys: ['basic'],
  sections: [
    {
      title: 'Basic',
      key: 'basic',
      subtitle: 'Enter a phone number and a prompt to get started',
      indicator: <Send />,
      fields: [
        {
          name: 'phone_number',
          component: PhoneInput,
          props: {
            hideBannedOutbound: true,
            isRequired: true,
            label: 'Phone Number',
          },
        },
        {
          name: 'voice',
          component: VoiceSelect,
          props: {
            label: 'Voice',
          },
        },
        {
          name: 'prompt_or_pathway',
          component: PromptOrPathway,
          uncontrolled: true,
        },
      ],
    },
    {
      title: 'Model Settings',
      key: 'model',
      subtitle: 'Tune the model and language settings',
      indicator: <Cog />,
      fields: [
        {
          name: 'model',
          component: ModelSelect,
        },
        {
          name: 'language',
          component: LanguageSelect,
        },
        {
          name: 'wait_for_greeting',
          component: SwitchInput,
          props: {
            label: 'Wait for Greeting',
            className: 'justify-between',
            description: 'If enabled, the agent will wait for the call recipient to speak first before responding. Note: This is processed separately from the AI\'s decision making, and overrides it.',
          },
        },
        {
          component: PronounciationGuide,
          uncontrolled: true,
          name: 'pronounciation_guide',
        },
        {
          name: 'temperature',
          component: TemperatureRange,

        },
        {
          name: 'interruption_threshold',
          component: InterruptionRange,
        },
      ],
    },
    {
      title: 'Dispatch Settings',
      key: 'dispatch',
      subtitle: 'Add transfer numbers and set duration',
      indicator: <Headset />,
      fields: [
        {
          name: 'from',
          component: PhoneSelect,
        },
        {
          name: 'timezone',
          component: TimezoneSelect,
        },
        {
          name: 'transfer_phone_number',
          component: PhoneInput,
          props: {
            hideBannedOutbound: true,
            label: 'Transfer Number',
            size: 'md',
            description: 'A phone number that the agent can transfer to under specific conditions - such as being asked to speak to a human or supervisor.',
          },
        },
        {
          name: 'transfer_list',
          component: ObjectInput,
          uncontrolled: true,
          props: {
            name: 'transfer_list',
            label: 'Transfer List',
            description: 'Give your agent the ability to transfer calls to a set of phone numbers. Overrides transfer_phone_number if a transfer_list.default is specified. Will default to transfer_list.default, or the chosen phone number.',
            valueComponent: {
              component: PhoneInput,
              props: {
                hideBannedOutbound: true,
              },
            },
            className: 'gap-2',
            labels: {
              add: 'Add Number',
              remove: 'Remove',
            },
          },
        },
        {
          name: 'max_duration',
          component: DurationRange,
        },
      ],
    },
    {
      title: 'Knowledge',
      key: 'knowledge',
      subtitle: 'Add tools and pathways',
      indicator: <GitBranch />,
      fields: [
        {
          name: 'tools',
          component: ToolSelect,
          disabled: {
            watch: 'pathway_id',
            evaluate: value => !!value,
            message: 'Tool selection is only available for prompt-based calls.',
          },
        },
        {
          name: 'memory_id',
          component: MemorySelect,
        },
        {
          name: 'pathway_ids',
          component: ObjectInput,
          uncontrolled: true,
          disabled: {
            watch: 'pathway_id',
            evaluate: value => !!value,
            message: 'Router Pathways are only available for prompt-based calls.',
          },
          props: {
            name: 'pathway_ids',
            label: 'Router Prompt with Pathways',
            description: 'Use the above Prompt input as a router which is capable of routing to multiple pathways. Add the pathway IDs below and explain when to use each one. For example, "Use this pathway when asked to book an appointment" or "Switch to this pathway for technical support."',
            className: 'w-full',
            keyComponent: {
              component: PathwaySelect,
            },
            valueComponent: {
              component: Input,
              props: {
                size: 'md',
                variant: 'bordered',
                radius: 'sm',
                placeholder: 'When to use this pathway',
              },
            },
            labels: {
              add: 'Add Pathway',
              remove: 'Remove',
            },
          },
        },
      ],
    },
    {
      title: 'Audio',
      key: 'audio',
      subtitle: 'Fine tune the audio of the call',
      indicator: <AudioLines />,
      fields: [
        {
          name: 'background_track',
          component: BackgroundSelect,
        },
        {
          name: 'noise_cancellation',
          component: SwitchInput,

          props: {
            label: 'Noise Cancellation',
            description: 'Enable noise cancellation to reduce background noise during your call. This feature uses advanced algorithms to filter out unwanted sounds, ensuring clearer communication.',
            className: 'justify-between',
          },
        },
        {
          name: 'block_interruptions',
          component: SwitchInput,
          props: {
            label: 'Block Interruptions',
            description: 'Enabling this feature will not allow the user to interrupt the agent, while the agent is speaking. This leads to a more controlled, but less natural conversation, as the user will have to wait for the agent to finish speaking before they can speak.',
            className: 'justify-between',
          },
        },
        {
          name: 'record',
          component: SwitchInput,
          props: {
            label: 'Record',
            description: 'To record your phone call, set to true. When your call completes, you can access through the recording_url field in the call details or your webhook.',
            className: 'justify-between',
          },
        },
      ],
    },
    {
      title: 'Voicemail Behavior',
      key: 'voicemail',
      subtitle: 'Enable voicemail behavior for the call',
      indicator: <Voicemail />,
      fields: [
        {
          name: 'voicemail_action',
          component: VoicemailSelect,
        },
        {
          name: 'voicemail_message',
          component: VoicemailInput,
        },
        {
          name: 'voicemail_sms',
          component: VoicemailSMSInput,
          condition: {
            watch: 'user',
            evaluate: value => !!value?.user?.sms_enabled,
          },
        },
        {
          name: 'sms',
          component: SMSPromptInput,
          condition: {
            watch: 'user',
            evaluate: value => !!value?.user?.sms_enabled,
          },
        },
        {
          name: 'answered_by_enabled',
          component: SwitchInput,
          props: {
            label: 'Answered By',
            description: 'If this is set to true, we process the audio from the start of the call to determine if it was answered by a human or a voicemail. In the call details or webhook response, you\'ll see the field with the value human, unknown or voicemail.',
            className: 'justify-between',
          },
        },
      ],
    },
    {
      title: 'Analysis',
      key: 'analysis',
      subtitle: 'Specify the data you want to extract from the call',
      indicator: <PieChart />,
      fields: [
        {
          name: 'analysis_schema',
          component: ObjectInput,
          uncontrolled: true,
          props: {
            name: 'analysis_schema',
            label: 'Analysis Schema',
            description: 'The analysis schema is a feature that allows you to define a JSON schema for extracting specific information from a call. This schema helps in retrieving structured data such as email addresses, names, appointment times, or any other custom data you need from the call.',
            className: 'w-full',
          },
        },
        {
          name: 'analysis_preset',
          component: AnalysisPresetSelect,

        },
      ],
    },
    {
      title: 'Post Call',
      key: 'post_call',
      subtitle: 'Configure post call summaries and events',
      indicator: <PhoneForwarded />,
      fields: [
        {
          name: 'webhook',
          component: WebhookInput,
        },
        {
          name: 'summary_prompt',
          component: SummaryInput,
        },
      ],
    },
    {
      title: 'Advanced',
      key: 'advanced',
      subtitle: 'Configure advanced settings for the call',
      indicator: <Cog />,
      fields: [
        {
          name: 'request_data',
          component: ObjectInput,
          uncontrolled: true,
          props: {
            name: 'request_data',
            label: 'Request Data',
            description: 'When you want your AI to "know" a specific fact - like the caller\'s name or other relevant context. The AI agent will be aware of both the key names as well as their corresponding values.',
            className: 'w-full',
          },
        },
        {
          name: 'metadata',
          component: ObjectInput,
          uncontrolled: true,
          props: {
            name: 'metadata',
            label: 'Metadata',
            description: 'Add any additional information you want to associate with the call. This can be useful for tracking or categorizing calls. Anything that you put here will be returned in your webhook or in the call details under metadata.',
            className: 'w-full',
          },
        },
        {
          name: 'endpoint',
          component: CustomDeployment,
        },
        {
          name: 'webhook_events',
          component: WebhookSelect,
        },
      ],
    },
  ],
}

export default SendCallForm
