import { Switch } from '@/components/ui/switch'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip'
import { HelpCircle, Plus, X } from 'lucide-react'
import { useContext, useState } from 'react'
import { toast } from 'react-toastify'
import { useReactFlow } from 'reactflow'
import FlowContext from '../contextFlow'
import ConditionBuilder from './conditionBuilder/conditionBuilder'

function EdgeSideBar({ edgeData, setIsSideBarOpen, setEdgeData }) {
  const { setEdges } = useReactFlow()
  const { exportFlow, setIsEditingEdge, triggerUpdate, elements, embedMode }
    = useContext(FlowContext)

  const sourceNode = edgeData?.source

  const isRouteNode
    = elements.nodes.find(node => node.id === sourceNode)?.type === 'Route'

  const maxChars = 100
  const [pathwayLabel, setPathwayLabel] = useState(edgeData?.data?.label || '')
  const [description, setDescription] = useState(
    edgeData?.data?.description || '',
  )
  const [mode, setMode] = useState(
    edgeData?.data?.condition ? 'condition' : 'label',
  )
  const [conditions, setConditions] = useState(() => {
    const oldConditions = edgeData?.data?.condition
    // return oldConditions?.[0]?.conditionOperator
    //   ? transformOldConditions(oldConditions)
    //   : oldConditions || [];
    return oldConditions || []
  })

  const [alwaysPick, setAlwaysPick] = useState(
    edgeData?.data?.alwaysPick || false,
  )

  const onSubmit = () => {
    let isWarnToast = false

    const source_node_id = edgeData.source

    const source_node = elements.nodes.find(
      node => node.id === source_node_id,
    )

    if (source_node.type === 'Route') {
      isWarnToast = true
      toast.warn(
        'You cannot edit the conditions here. Please edit the conditions in the Route node instead.',
      )
      return
    }

    const updatedEdges = elements.edges.map((element_edge) => {
      if (element_edge.id === edgeData.id) {
        const edge = { ...element_edge }
        const sourceId = edge.source
        const sourceEdges = elements.edges.filter(e => e.source === sourceId)
        const hasCondition = sourceEdges.some(e => e.data?.condition)
        const doesNotHaveCondition = sourceEdges.some(
          e => !e.data?.condition,
        )

        if (mode === 'label') {
          if (!pathwayLabel || pathwayLabel.trim() === '') {
            isWarnToast = true
            toast.warn('Please enter a pathway label')
            return element_edge
          }

          if (hasCondition) {
            isWarnToast = true
            toast.warn(
              'You cannot have pathways with both the \'Variable Comparison\' mode and \'Pathway Label\' from the same node. Please remove the pathways using the \'Variable Comparison\' from the source node first, before adding a new pathway with \'Pathway Label\'.',
            )
            return element_edge
          }

          if (pathwayLabel.length > maxChars) {
            isWarnToast = true
            toast.warn(
              `Pathway label must be less than ${maxChars} characters`,
            )
            return element_edge
          }
          edge.data.label = pathwayLabel.trim()
          edge.data.description = description
          if (description) {
            edge.data.description = description.trim()
          }
          if (alwaysPick) {
            edge.data.alwaysPick = true
          }
          else {
            delete edge.data.alwaysPick
          }
          delete edge.data.condition
        }
        else if (mode === 'condition') {
          if (doesNotHaveCondition && sourceEdges.length > 1) {
            isWarnToast = true
            toast.warn(
              'You cannot have pathways with both the \'Variable Comparison\' mode and \'Pathway Label\' from the same node. Please remove the pathways using the \'Pathway Label\' from the source node first, before adding a new pathway with \'Variable Comparison\' mode.',
            )
            return element_edge
          }

          const validConditions = conditions.every(
            cond => cond.field && cond.value,
          )
          if (!validConditions) {
            isWarnToast = true
            toast.warn('Please complete all condition fields')
            return element_edge
          }

          edge.data.condition = conditions

          edge.data.label = isRouteNode
            ? conditions
                .map((cond) => {
                  if (cond.isGroup) {
                    return `(${cond.conditions
                      .map(
                        (groupCond, i) =>
                          `${i > 0 ? 'And ' : ''}${groupCond.field} ${groupCond.operator} ${groupCond?.value}`,
                      )
                      .join(' ')})`
                  }
                  return `${cond.field} ${cond.operator} ${cond?.value}`
                })
                .join(' Or ')
            : conditions
                .map(
                  (cond, index) =>
                    `${index > 0 ? `${cond.operator} ` : ''}${cond.field} ${cond.conditionOperator} ${cond.value}`,
                )
                .join(' ')
          delete edge.data.description
        }
        return edge
      }
      return element_edge
    })

    if (isWarnToast) {
      return
    }

    if (alwaysPick) {
      updatedEdges.forEach((edge) => {
        if (edge.source === edgeData.source && edge.id !== edgeData.id) {
          delete edge.data.alwaysPick
        }
        if (edge.id === edgeData.id) {
          edge.data.alwaysPick = true
        }
      })
    }

    triggerUpdate({ edges: updatedEdges })
    setIsEditingEdge(null)
    setIsSideBarOpen(false)
  }

  const deleteCondition = (index) => {
    setConditions(conditions.filter((_, i) => i !== index))
  }

  const handlePathwayLabelChange = (event) => {
    if (event.target.value.length <= maxChars) {
      setPathwayLabel(event.target.value)
    }
  }

  const handleConditionChange = (index, field, value) => {
    const newConditions = [...conditions]
    newConditions[index][field] = value
    
    // Clear the value when operator changes to 'is'
    if (field === 'conditionOperator' && value === 'is') {
      console.log('is', newConditions[index].value)
      newConditions[index].value = 'null'
    }
    
    setConditions(newConditions)
  }

  const addCondition = () => {
    setConditions([
      ...conditions,
      { operator: 'AND', field: '', conditionOperator: 'contains', value: '' },
    ])
  }

  return (
    <div
      className={`fixed ${embedMode ? 'top-0' : ''} right-0 w-1/4 h-screen bg-slate-100 shadow-lg z-50 overflow-y-auto border-l border-slate-200`}
    >
      <div className="p-5 space-y-5">
        <div className="flex justify-between items-center">
          <h2 className="text-lg font-bold text-slate-800">Edit Pathway</h2>
          <button
            onClick={() => {
              setIsSideBarOpen(false)
              setIsEditingEdge(null)
            }}
            className="text-slate-500 hover:text-slate-700 transition-colors"
          >
            <X className="w-4 h-4" />
          </button>
        </div>

        <div className="space-y-2.5">
          <label className="block text-sm font-semibold text-slate-700">
            How should the agent decide?
          </label>
          <div className="flex text-xs text-slate-600 items-center">
            Choose how the agent should decide which pathway to take.
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <HelpCircle className="w-3 h-3 ml-1.5 text-slate-400" />
                </TooltipTrigger>
                <TooltipContent className="bg-white p-2.5 rounded shadow-lg max-w-xs">
                  <p className="text-xs mb-1.5">
                    <strong>Pathway label:</strong>
                    {' '}
                    Uses natural language to
                    decide (e.g. User said yes)
                  </p>
                  <p className="text-xs">
                    <strong>Variable Comparison:</strong>
                    {' '}
                    Uses Mathematical
                    expressions / logic operators to proceed to this pathway if
                    the variable values meets a certain criteria (e.g. variable
                    &gt; 5)
                  </p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
          <div className="flex bg-white rounded-md shadow-sm">
            <button
              className={`flex-1 py-2 px-4 text-xs font-medium rounded-l-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${
                mode === 'label'
                  ? 'bg-indigo-100 text-indigo-700 border-indigo-200'
                  : 'text-slate-600 hover:text-slate-800 hover:bg-slate-50'
              }`}
              onClick={() => setMode('label')}
            >
              Pathway Label
            </button>
            <button
              className={`flex-1 py-2 px-4 text-xs font-medium rounded-r-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${
                mode === 'condition'
                  ? 'bg-indigo-100 text-indigo-700 border-indigo-200'
                  : 'text-slate-600 hover:text-slate-800 hover:bg-slate-50'
              }`}
              onClick={() => setMode('condition')}
            >
              Advanced Settings
            </button>
          </div>
        </div>

        {mode === 'condition' && !isRouteNode && (
          <div className="space-y-4">
            <div className="flex justify-between items-center">
              <h3 className="text-base font-semibold text-slate-800">
                Value Comparison
              </h3>
              <button
                onClick={addCondition}
                className="text-indigo-600 hover:text-indigo-800 text-xs font-medium flex items-center transition-colors"
              >
                <Plus className="w-3 h-3 mr-1.5" />
                {' '}
                Add Condition
              </button>
            </div>
            <p className="text-xs text-slate-600">
              Compare the value of existing variables. If it meets the criteria
              (evaluates to true), the agent will proceed to this pathway.
            </p>
            {conditions.map((condition, index) => (
              <div
                key={index}
                className="bg-white p-4 rounded-md shadow-sm space-y-2.5"
              >
                {index > 0 && (
                  <div className="flex items-center space-x-2">
                    <label className="text-xs font-medium text-slate-700">
                      Logic operator:
                    </label>
                    <select
                      className="mt-0.5 block w-full pl-2 pr-6 py-1.5 text-xs border-slate-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 rounded-"
                      value={condition.operator}
                      onChange={e =>
                        handleConditionChange(index, 'operator', e.target.value)}
                    >
                      <option value="AND">AND</option>
                      <option value="OR">OR</option>
                    </select>
                  </div>
                )}
                <div className="flex items-center space-x-2">
                  <input
                    type="text"
                    placeholder="Variable to compare e.g. age"
                    className="mt-0.5 block w-full px-2.5 py-2 bg-white border border-slate-300 rounded text-xs shadow-sm placeholder-slate-400
                      focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                    value={condition.field}
                    onChange={e =>
                      handleConditionChange(index, 'field', e.target.value)}
                  />
                  <button
                    onClick={() => deleteCondition(index)}
                    className="text-slate-400 hover:text-slate-600 transition-colors"
                  >
                    <X className="w-4 h-4" />
                  </button>
                </div>
                <select
                  className="mt-0.5 block w-full pl-2 pr-6 py-2 text-xs border-slate-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 rounded-"
                  value={condition.conditionOperator || 'contains'}
                  onChange={e =>
                    handleConditionChange(
                      index,
                      'conditionOperator',
                      e.target.value,
                    )}
                >
                  <option value="" disabled>Select operator</option>
                  <option value="contains">contains</option>
                  <option value="does not contain">does not contain</option>
                  <option value="equals">equals</option>
                  <option value="not equals">does not equal</option>
                  <option value="greater than">is greater than</option>
                  <option value="less than">is less than</option>
                  <option value="is">is</option>
                </select>
                {condition.conditionOperator === 'is'
                  ? (
                      <select
                        className="mt-0.5 block w-full pl-2 pr-6 py-2 text-xs border-slate-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 rounded-"
                        value={condition.value || 'null'}
                        onChange={e =>
                          handleConditionChange(index, 'value', e.target.value)}
                      >
                        <option value="" disabled>Select value</option>
                        <option
                          value="null"
                          className="text-slate-700 font-medium uppercase tracking-wide"
                        >
                          NULL
                        </option>
                        <option
                          value="not null"
                          className="text-slate-700 font-medium uppercase tracking-wide"
                        >
                          !NULL
                        </option>
                        <option
                          value="true"
                          className="text-slate-700 font-medium uppercase tracking-wide"
                        >
                          TRUE
                        </option>
                        <option
                          value="false"
                          className="text-slate-700 font-medium uppercase tracking-wide"
                        >
                          FALSE
                        </option>
                        <option
                          value="undefined"
                          className="text-slate-700 font-medium uppercase tracking-wide"
                        >
                          UNDEFINED
                        </option>
                        <option
                          value="not undefined"
                          className="text-slate-700 font-medium uppercase tracking-wide"
                        >
                          !UNDEFINED
                        </option>
                      </select>
                    )
                  : (
                      <input
                        type="text"
                        placeholder="Value"
                        className="mt-0.5 block w-full px-2.5 py-2 bg-white border border-slate-300 rounded text-xs shadow-sm placeholder-slate-400
                      focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                        value={condition.value}
                        onChange={e =>
                          handleConditionChange(index, 'value', e.target.value)}
                      />
                    )}
              </div>
            ))}
          </div>
        )}

        {mode === 'condition' && isRouteNode && (
          <div className="space-y-4">
            <div className="flex justify-between items-center">
              <h3 className="text-base font-semibold text-slate-800">
                Value Comparison
              </h3>
            </div>
            <p className="text-xs text-slate-600">
              Compare the value of existing variables. If it meets the criteria
              (evaluates to true), the agent will proceed to this pathway.
            </p>
            <ConditionBuilder
              conditions={conditions}
              onChange={setConditions}
            />
          </div>
        )}

        {mode === 'label' && (
          <div className="space-y-4">
            <div>
              <label
                htmlFor="label"
                className="block text-sm font-semibold text-slate-700"
              >
                Pathway Label
              </label>
              <p className="mt-1.5 text-xs text-slate-600">
                Enter a label that describes when this pathway should be chosen.
                Keep it short and succinct e.g. user said yes
              </p>
              <textarea
                id="label"
                className="mt-1.5 block w-full px-2.5 py-2 bg-white border border-slate-300 rounded text-xs shadow-sm placeholder-slate-400
                  focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                rows={3}
                value={pathwayLabel}
                onChange={handlePathwayLabelChange}
                maxLength={maxChars}
                placeholder="Enter pathway label"
              />
              <p className="mt-1.5 text-xs text-slate-500">
                {pathwayLabel.length}
                /
                {maxChars}
              </p>
            </div>

            <div>
              <label
                htmlFor="description"
                className="block text-sm font-semibold text-slate-700"
              >
                Description
              </label>
              <p className="mt-1.5 text-xs text-slate-600">
                Provide more information and describe when this pathway should
                be chosen. This is optional but gives the agent more context
                about when to choose this pathway.
              </p>
              <textarea
                id="description"
                className="mt-1.5 block w-full px-2.5 py-2 bg-white border border-slate-300 rounded text-xs shadow-sm placeholder-slate-400
                  focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                rows={4}
                value={description}
                onChange={e => setDescription(e.target.value)}
                placeholder="Describe when this pathway should be chosen..."
              />
            </div>

            <div className="flex items-center space-x-1.5">
              <Switch
                // make the enable color be indigo-600
                className="bg-indigo-600"
                id="always-pick"
                checked={alwaysPick}
                onCheckedChange={checked => setAlwaysPick(checked)}
              />
              <label
                htmlFor="always-pick"
                className="text-xs font-medium text-slate-700"
              >
                Always pick this pathway
              </label>
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger>
                    <HelpCircle className="w-3 h-3 text-slate-400" />
                  </TooltipTrigger>
                  <TooltipContent className="bg-white p-2.5 rounded shadow-lg max-w-xs">
                    <p className="text-xs">
                      When enabled, the agent will always choose this pathway,
                      ignoring other options. Only one pathway from a source
                      node can have this option enabled.
                    </p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
          </div>
        )}

        <div className="pt-4">
          <div className="flex justify-end space-x-2.5">
            <button
              type="button"
              className="bg-white py-2 px-4 border border-slate-300 rounded shadow-sm text-xs font-medium text-slate-700 hover:bg-slate-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={() => {
                setIsSideBarOpen(false)
                setIsEditingEdge(null)
              }}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-xs font-medium rounded text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={onSubmit}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EdgeSideBar
