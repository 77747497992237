export function parsePathwayTags(tags) {
  if (!tags || tags.length === 0)
    return null

  const normalizedTags = tags.map(tag => ({
    name: tag.name ?? (typeof tag === 'object' ? 'Unknown' : tag),
    color: tag.color,
  }))

  const uniqueTags = []
  const seenTags = new Set()

  normalizedTags.forEach((tag) => {
    if (!seenTags.has(tag.name)) {
      uniqueTags.push(tag)
      seenTags.add(tag.name)
    }
  })

  return uniqueTags?.reverse()
}
