import ActionBar from 'components/core/ActionBar'
import Button from 'components/core/Button'
import Label from 'components/core/Label'
import Modal from 'components/core/Modal'
import { PageTitle } from 'components/core/PageTitle'
import { PageWrapper } from 'components/core/PageWrapper'
import GradientLoadingAnimation from 'components/Reusables/GradientLoadingAnimation'
import config from 'config'
import { AnimatePresence, motion } from 'framer-motion'
import { SquareUser } from 'lucide-react'
import { useEffect, useState } from 'react'
import {
  FiCheckCircle,
  FiClock,
  FiCode,
  FiCopy,
  FiEdit2,
  FiLink,
  FiPlus,
  FiTrash2,
  FiX,
  FiZap,
} from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useLocalStorage } from 'usehooks-ts'
import { getApiKey } from 'utils/funcs/browser/getApiKey'
import { getOrgId } from 'utils/funcs/browser/getOrgId'
import PersonaSolutionModal from './components/PersonaSolutionModal'

const EVENT_BASE_URL = `${config.API_URL}/api/events`

// System pulse animation component
function SystemPulse() {
  return (
    <div className="absolute inset-0 pointer-events-none">
      <motion.div
        className="absolute inset-0 bg-gradient-to-tr from-green-500/5 to-blue-500/5"
        initial={{ opacity: 0 }}
        animate={{
          opacity: [0, 1, 0],
          scale: [0.95, 1, 0.95],
        }}
        transition={{
          duration: 4,
          repeat: Infinity,
          ease: 'easeInOut',
        }}
      />
    </div>
  )
}

// Status indicator component
function StatusIndicator({ active = true }) {
  return (
    <div className="relative">
      <div className={`w-1.5 h-1.5 rounded-full ${active ? 'bg-green-500 animate-pulse' : 'bg-gray-300'}`} />
      {/* <motion.div
      className={`absolute inset-0 rounded-full ${active ? ' bg-green-500' : 'bg-gray-300'}`}
      initial={{ opacity: 0.5, scale: 1 }}
      animate={{ opacity: 0, scale: 2 }}
      transition={{ duration: 2, repeat: Infinity }}
    /> */}
    </div>
  )
}

// Event endpoint preview
function EndpointPreview({ endpoint }) {
  const [showCopied, setShowCopied] = useState(false)

  const handleCopy = () => {
    navigator.clipboard.writeText(endpoint)
    setShowCopied(true)
    setTimeout(() => setShowCopied(false), 2000)
  }

  return (
    <motion.div
      className="font-mono text-xs bg-gray-900 text-gray-300 p-2 rounded-md cursor-pointer group z-50"
      whileHover={{ scale: 1.01 }}
      whileTap={{ scale: 0.99 }}
      onClick={handleCopy}
    >
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-1.5">
          <FiLink className="text-gray-500" />
          <span>{endpoint}</span>
        </div>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: showCopied ? 1 : 0 }}
          className="text-green-400"
        >
          <FiCheckCircle />
        </motion.div>
      </div>
    </motion.div>
  )
}

export default function EventTriggerSystem() {
  const navigate = useNavigate()
  const apiKey = getApiKey()
  const orgId = getOrgId()
  const [events, setEvents] = useState([])
  const [createNewModal, setCreateNewModal] = useState(false)
  const [event_name, setEvent_name] = useState('')
  const [loading, setLoading] = useState(false)
  const [loadingEvents, setLoadingEvents] = useState(false)
  const [searchInput, setSearchInput] = useState('')
  const [deleteEventId, setDeleteEventId] = useState(null)
  const [ripples, setRipples] = useState([])
  const [selectedEventId, setSelectedEventId] = useState(null)
  const [showPersonaModal, setShowPersonaModal] = useLocalStorage('showPersonaModal', true)

  // Existing API handlers remain unchanged
  const handleDeleteEvent = async (event_id) => {
    try {
      const response = await fetch(`${EVENT_BASE_URL}/${event_id}/delete`, {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          'authorization': apiKey,
          ...(orgId && { 'x-bland-org-id': orgId }),
        },
      })

      if (response.ok && response.status === 200) {
        await handleGetAllEvents()
        toast.success('Event removed successfully.')
      }
    }
    catch (error) {
      toast.error('Error removing event.')
    }
  }

  const handleCreateNewEvent = async () => {
    try {
      setLoading(true)
      const response = await fetch(EVENT_BASE_URL, {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          'authorization': apiKey,
          ...(orgId && { 'x-bland-org-id': orgId }),
        },
        body: JSON.stringify({
          event_name,
        }),
      })

      if (response.ok && response.status === 200) {
        const data = await response.json()
        setLoading(false)
        setCreateNewModal(false)
        navigate(`/dashboard/events/${data?.event?.[0]?.id}`)
      }
      else {
        setLoading(false)
        setCreateNewModal(false)
        toast.error('Failed to create new event.')
      }
    }
    catch (error) {
      setLoading(false)
      setCreateNewModal(false)
      toast.error('Failed to create new event.')
    }
  }

  const handleGetAllEvents = async () => {
    try {
      setLoadingEvents(true)
      const response = await fetch(EVENT_BASE_URL, {
        method: 'GET',
        headers: {
          authorization: apiKey,
          ...(orgId && { 'x-bland-org-id': orgId }),
        },
      })

      if (response.ok && response.status === 200) {
        const data = await response.json()
        if (data && data?.events?.length > 0) {
          setEvents(data.events)
        }
        else {
          setEvents([])
        }
      }
    }
    catch (error) {
      setEvents([])
    }
    finally {
      setLoadingEvents(false)
    }
  }

  useEffect(() => {
    handleGetAllEvents()
  }, [])

  const filteredEvents = () => {
    return events.filter(event =>
      event?.event_name?.toLowerCase().includes(searchInput.toLowerCase()),
    )
  }

  const addRipple = (x, y) => {
    const id = Date.now()
    setRipples(prevRipples => [...prevRipples, { id, x, y }])
  }

  const removeRipple = (id) => {
    setRipples(prevRipples => prevRipples.filter(ripple => ripple.id !== id))
  }

  if (loadingEvents) {
    return (
      <GradientLoadingAnimation variant="sunsetCoral" message="Loading event triggers" />
    )
  }

  return (
    <PageWrapper>
      <div className="min-h-screen w-full">

        <div className="relative">
          <ActionBar top spaceBetween>
            <motion.div
              className="flex items-center "
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
            >
              <PageTitle>
                <span className="flex items-center space-x-2.5">
                  <FiZap className="text-yellow-500" />
                  <span>Event Triggers</span>
                </span>
              </PageTitle>
              {events.length > 0 && (
                <div className="flex items-center space-x-1.5 text-xs text-gray-500 mx-1.5">
                  <StatusIndicator />
                  <span>System Active</span>
                </div>
              )}
            </motion.div>

            <span
              className="flex items-center space-x-2"
            >
              <Button
                onClick={() => setShowPersonaModal(true)}
                className="bg-gray-900 text-white hover:bg-gray-800 transition-colors items-center flex"
              >
                <SquareUser className="mt-1 size-4" />
                Try Personas
              </Button>
              <Button
                onClick={() => setCreateNewModal(true)}
                className="bg-gray-900 text-white hover:bg-gray-800 transition-colors items-center"
              >
                <FiPlus className="mt-0.5 size-5" />
                New Trigger
              </Button>
            </span>
          </ActionBar>

          <div className="mt-5  w-full">
            <AnimatePresence mode="popLayout">
              {filteredEvents().length > 0 ? (
                <motion.div
                  className="space-y-2.5"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                >
                  {filteredEvents().map((event, index) => (
                    <motion.div
                      key={event.id}
                      initial={{ opacity: 0, x: -20 }}
                      animate={{
                        opacity: 1,
                        x: 0,
                        transition: { delay: index * 0.1 },
                      }}
                      exit={{ opacity: 0, x: -20 }}
                      whileHover={{ scale: 1.002 }}
                      className="relative group bg-white hover:bg-gray-50/50 border border-gray-200/80 rounded-md transition-all duration-200 cursor-pointer"
                      onClick={() => navigate(`/dashboard/events/${event.id}`)}
                    >
                      {/* Multi-color gradient line */}
                      <div className="absolute left-0 top-0 bottom-0 w-[3px] ">
                        <div
                          className="absolute inset-0 bg-gradient-to-b from-stone-200/60 via-purple-300/60 via-indigo-600/60 to-neutral-500/60 rounded-tl-lg rounded-r-lg"
                          style={{ mixBlendMode: 'multiply' }}
                        />
                      </div>

                      <div className="px-2.5 py-5 relative flex flex-col w-full mx-1.5  ">

                        <div className="flex items-center justify-between">
                          {/* Main info */}
                          <div className="flex items-center space-x-2 ">

                            <div className="truncate">
                              <div className="flex items-center space-x-1.5 mb-1.5">
                                <span className="font-medium text-gray-900">{event.event_name}</span>
                                {' '}
                                <StatusIndicator />
                                <span className="text-gray-400 text-2xs font-mono tracking-tight">
                                  #
                                  {event.id.slice(0, 8)}
                                </span>
                              </div>

                              <div className="flex items-center space-x-2">
                                <div className="text-2xs text-gray-500 font-mono bg-gray-50 rounded px-2 py-1 border border-gray-100 flex items-center justify-between group/endpoint">
                                  <span className="mr-2">
                                    {event.endpoint}
                                    /events/
                                    {event.id}
                                  </span>
                                  <motion.button
                                    whileHover={{ scale: 1.1 }}
                                    whileTap={{ scale: 0.9 }}
                                    onClick={(e) => {
                                      e.stopPropagation()
                                      navigator.clipboard.writeText(`${event.endpoint}/events/${event.id}`)
                                      toast.success('Endpoint URL copied')
                                    }}
                                    className="text-gray-400 hover:text-gray-600 p-px rounded-sm hidden group-hover/endpoint:block"
                                  >
                                    <FiCopy className="w-2 h-2" />
                                  </motion.button>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* Actions */}
                          <div className="flex items-center space-x-1.5 ml-2.5">
                            <motion.button
                              whileHover={{ scale: 1.05 }}
                              whileTap={{ scale: 0.95 }}
                              onClick={(e) => {
                                e.stopPropagation()
                                navigate(`/dashboard/events/${event.id}`)
                              }}
                              className="p-1.5 hover:bg-gray-100 rounded text-gray-400 hover:text-gray-600 transition-colors"
                            >
                              <FiEdit2 className="w-4 h-4" />
                            </motion.button>
                            <motion.button
                              whileHover={{ scale: 1.05 }}
                              whileTap={{ scale: 0.95 }}
                              onClick={(e) => {
                                e.stopPropagation()
                                setDeleteEventId(event.id)
                              }}
                              className="p-1.5 hover:bg-red-50 rounded text-gray-400 hover:text-red-500 transition-colors"
                            >
                              <FiTrash2 className="w-4 h-4" />
                            </motion.button>
                          </div>
                        </div>

                        <div className="flex items-center space-x-1.5 mt-2 text-2xs">
                          <motion.span
                            className="flex items-center space-x-1 px-1.5 py-0.5 bg-gray-50 border border-gray-100 text-gray-600 rounded-"
                            whileHover={{ scale: 1.02 }}
                          >
                            <FiCode className="w-2 h-2" />
                            <span>API v1</span>
                          </motion.span>
                          <motion.span
                            className="flex items-center space-x-1 px-1.5 py-0.5 bg-green-50 border border-green-100 text-green-700 rounded-"
                            whileHover={{ scale: 1.02 }}
                          >
                            <span className="w-1 h-1 rounded-full bg-green-500" />
                            <span>Live</span>
                          </motion.span>
                          <span className="text-gray-400 flex items-center space-x-1 px-1.5 py-0.5">
                            <FiClock className="w-2 h-2" />
                            <span>{new Date(event.created_at).toLocaleDateString()}</span>
                          </span>
                        </div>
                      </div>
                    </motion.div>
                  ))}
                </motion.div>
              ) : events.length === 0
                ? (
                    <motion.div
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      className="text-center mt-8"
                    >
                      <div className="max-w-md mx-auto">
                        <motion.div
                          className="w-10 h-10 mx-auto mb-4 rounded-full bg-gray-900
                               flex items-center justify-center"
                          whileHover={{ scale: 1.1 }}
                        >
                          <FiZap className="w-5 h-5 text-yellow-500" />
                        </motion.div>
                        <h3 className="text-base font-medium text-gray-900 mb-1.5">
                          Create your first event trigger
                        </h3>
                        <p className="text-gray-500 mb-5">
                          Set up an endpoint to trigger automated phone calls via API requests
                        </p>
                        <motion.div
                          whileHover={{ scale: 1.02 }}
                          whileTap={{ scale: 0.98 }}
                        >
                          <Button
                            onClick={() => setCreateNewModal(true)}
                            className="bg-gray-900 text-white px-5 py-2"
                          >
                            <FiPlus className="mr-1.5" />
                            Create First Trigger
                          </Button>
                        </motion.div>
                      </div>
                    </motion.div>
                  )
                : (
                    <motion.p
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      className="text-center text-gray-500"
                    >
                      No triggers match your search
                    </motion.p>
                  )}
            </AnimatePresence>
          </div>
        </div>

        {/* Create New Event Modal */}
        <Modal
          open={createNewModal}
          onClose={() => setCreateNewModal(false)}
          height="auto"
        >
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
            className="p-4"
          >
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center space-x-2">
                <div className="w-5 h-5 bg-gray-900 rounded-full flex items-center justify-center">
                  <FiZap className="text-yellow-500" />
                </div>
                <h3 className="text-base font-medium">New Event Trigger</h3>
              </div>
              <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                onClick={() => setCreateNewModal(false)}
              >
                <FiX className="w-4 h-4" />
              </motion.button>
            </div>

            <div className="space-y-2.5">
              <div>
                <Label>Trigger Name</Label>
                <input
                  type="text"
                  value={event_name}
                  onChange={e => setEvent_name(e.target.value)}
                  className="mt-0.5 w-full px-2.5 py-1.5 bg-gray-50 border border-gray-200
                           rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent
                           transition-all duration-200"
                  placeholder="e.g., 'Customer Support Callback'"
                />
              </div>

              <motion.div
                whileHover={{ scale: 1.01 }}
                whileTap={{ scale: 0.99 }}
              >
                <Button
                  loading={loading}
                  onClick={handleCreateNewEvent}
                  disabled={!event_name.trim()}
                  className="w-full bg-gray-900 text-white hover:bg-gray-800
                           disabled:bg-gray-200 disabled:cursor-not-allowed"
                >
                  {loading ? 'Creating...' : 'Create Trigger'}
                </Button>
              </motion.div>
            </div>
          </motion.div>
        </Modal>

        {/* Delete Confirmation Modal */}
        <Modal
          open={deleteEventId !== null}
          onClose={() => setDeleteEventId(null)}
          height="auto"
        >
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
            className="p-4"
          >
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center space-x-2">
                <div className="w-5 h-5 bg-red-50 rounded-full flex items-center justify-center">
                  <FiTrash2 className="text-red-500" />
                </div>
                <h3 className="text-base font-medium">Delete Trigger</h3>
              </div>
              <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                onClick={() => setDeleteEventId(null)}
              >
                <FiX className="w-4 h-4" />
              </motion.button>
            </div>

            <p className="text-gray-600 mb-4">
              Are you sure you want to delete this trigger? All associated automations will be disabled.
            </p>

            <div className="flex justify-end space-x-2">
              <motion.div whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.98 }}>
                <Button
                  onClick={() => setDeleteEventId(null)}
                  className="px-2.5 py-1.5 bg-gray-100 text-gray-600 hover:bg-gray-200"
                >
                  Cancel
                </Button>
              </motion.div>
              <motion.div whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.98 }}>
                <Button
                  onClick={() => {
                    handleDeleteEvent(deleteEventId)
                    setDeleteEventId(null)
                  }}
                  className="px-2.5 py-1.5 bg-red-500 text-white hover:bg-red-600"
                >
                  <FiTrash2 className="mr-1.5" />
                  Delete Trigger
                </Button>
              </motion.div>
            </div>
          </motion.div>
        </Modal>
      </div>
      <PersonaSolutionModal
        isOpen={showPersonaModal}
        onOpenChange={setShowPersonaModal}
      />
    </PageWrapper>
  )
}
