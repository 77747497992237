import { Alert, AlertDescription } from '@/components/ui/alert'
import { AlertCircle, Upload, X } from 'lucide-react'
import { useCallback, useState } from 'react'

function FileUploader({ onDataLoaded, jsonContent, setJsonContent }) {
  const [isDragging, setIsDragging] = useState(false)
  const [file, setFile] = useState(null)
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)

  const onDragOver = useCallback((e) => {
    e.preventDefault()
    setIsDragging(true)
  }, [])

  const onDragLeave = useCallback((e) => {
    e.preventDefault()
    setIsDragging(false)
  }, [])

  const validateFile = (file) => {
    if (!file.name.endsWith('.json')) {
      return 'Please upload a JSON file'
    }
    if (file.size > 5 * 1024 * 1024) {
      return 'File size should be less than 5MB'
    }
    return ''
  }

  const processFile = useCallback(
    async (file) => {
      setIsLoading(true)
      setError('')
      setJsonContent(null)

      const validationError = validateFile(file)
      if (validationError) {
        setError(validationError)
        setIsLoading(false)
        return
      }

      try {
        const text = await new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.onload = () => resolve(reader.result)
          reader.onerror = () => reject(reader.error)
          reader.readAsText(file)
        })

        const parsedContent = JSON.parse(text)
        setJsonContent(parsedContent)
        setFile(file)
        setIsSuccess(true)
        if (onDataLoaded) {
          onDataLoaded(parsedContent)
        }
      }
      catch (err) {
        setError(
          err.message === 'Unexpected token'
            ? 'Invalid JSON format'
            : 'Error processing file',
        )
      }
      finally {
        setIsLoading(false)
      }
    },
    [onDataLoaded, setJsonContent],
  )

  const onDrop = useCallback(
    (e) => {
      e.preventDefault()
      setIsDragging(false)

      const droppedFile = e.dataTransfer.files[0]
      if (droppedFile) {
        processFile(droppedFile)
      }
    },
    [processFile],
  )

  const onFileSelect = useCallback(
    (e) => {
      const selectedFile = e.target.files[0]
      if (selectedFile) {
        processFile(selectedFile)
      }
    },
    [processFile],
  )

  const removeFile = useCallback(
    (e) => {
      e.stopPropagation()
      setFile(null)
      setIsSuccess(false)
      setError('')
      setJsonContent(null)
      if (onDataLoaded) {
        onDataLoaded(null)
      }
    },
    [onDataLoaded, setJsonContent],
  )

  return (
    <div className="w-full mx-auto p-2.5">
      <div
        className={`relative rounded-md border-2 border-solid transition-all duration-200 ease-in-out p-5 ${
          isDragging
            ? 'border-blue-500 bg-blue-50'
            : error
              ? 'border-red-300 bg-red-50'
              : isSuccess
                ? 'border-[#06d6a0] bg-gray-50'
                : 'border-gray-300 hover:border-gray-400 bg-white'
        }`}
        onDragOver={onDragOver}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
      >
        {!file && (
          <input
            type="file"
            className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
            onChange={onFileSelect}
            accept=".json"
            disabled={isLoading}
          />
        )}

        <div className="text-center">
          {isLoading
            ? (
                <div className="space-y-2.5">
                  <div className="w-8 h-8 border-4 border-blue-200 border-t-blue-500 rounded-full animate-spin mx-auto" />
                  <p className="text-xs text-gray-500">Processing file...</p>
                </div>
              )
            : file
              ? (
                  <div className="flex items-center justify-between bg-gray-50 rounded-sm px-2.5 py-1.5">
                    <div className="flex items-center space-x-1.5">
                      <div className="w-1.5 h-1.5 bg-green-500 rounded-full" />
                      <span className="text-xs text-gray-600">{file.name}</span>
                      <span className="text-2xs text-gray-400">
                        {(file.size / 1024).toFixed(2)}
                        {' '}
                        KB
                      </span>
                    </div>
                    <button
                      onClick={removeFile}
                      className="p-0.5 hover:bg-gray-200 rounded-full transition-colors"
                      type="button"
                    >
                      <X className="w-2.5 h-2.5 text-gray-500" />
                    </button>
                  </div>
                )
              : (
                  <div className="space-y-2.5">
                    <div className="mx-auto w-8 h-8 rounded-full bg-gray-100 flex items-center justify-center">
                      <Upload className="w-4 h-4 text-gray-600" />
                    </div>
                    <div>
                      <p className="text-xs font-medium text-gray-900">
                        Drop your JSON file here, or
                        {' '}
                        <span className="text-blue-500 hover:text-blue-600 transition-colors">
                          browse
                        </span>
                      </p>
                      <p className="text-xs text-gray-500 mt-0.5">
                        JSON files up to 5MB
                      </p>
                    </div>
                  </div>
                )}
        </div>
      </div>

      {error && (
        <Alert variant="destructive" className="mt-2.5">
          <AlertCircle className="w-2.5 h-2.5" />
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}
    </div>
  )
}

export default FileUploader
