import { Button } from '@/components/ui/button'
import { Card } from '@/components/ui/card'
import { ScrollArea } from '@/components/ui/scroll-area'
import CopyCallID from 'components/Reusables/CopyCallID'
import {
  CheckCircle2,
  ChevronLeft,
  Edit2,
  GitBranch,
  MessageSquare,
  PlayCircle,
} from 'lucide-react'
import { useContext } from 'react'
import FlowContext from '../../contextFlow'
import MessageList from './MessageList'

function SimulationDetailsHeader({ simulation, onBack, onEdit }) {
  const { elements } = useContext(FlowContext)

  const formatJSON = (data) => {
    try {
      if (typeof data === 'object') {
        return JSON.stringify(data, null, 2)
      }
      return JSON.stringify(JSON.parse(data), null, 2)
    }
    catch {
      return String(data)
    }
  }

  const getNodeName = (nodeId) => {
    const node = elements.nodes?.find(element => element.id === nodeId)
    return node?.data?.name || nodeId
  }

  return (
    <div className="flex flex-col h-full bg-gray-50">
      <div className="bg-white border-b border-gray-200 sticky top-0 z-10">
        <div className="flex items-start p-2.5 max-w-5xl mx-auto">
          <Button
            variant="ghost"
            size="sm"
            onClick={onBack}
            className="mr-2.5 text-gray-500"
          >
            <ChevronLeft className="w-2.5 h-2.5 mr-0.5" />
            Back
          </Button>

          <div className="flex-1 flex-col">
            <div className="flex items-center justify-between">
              <h1 className="text-sm font-semibold text-gray-900">
                {simulation.name}
              </h1>
              <Button
                variant="outline"
                size="sm"
                onClick={() => onEdit(simulation)}
                className="ml-2.5"
              >
                <Edit2 className="w-2.5 h-2.5 mr-1.5" />
                Edit Test Case
              </Button>
            </div>
            <div className="mt-1.5 flex items-center space-x-2.5">
              <div className="flex items-center space-x-1.5">
                <div className="text-2xs text-gray-500">Call ID:</div>
                {simulation.call_id
                  ? (
                      <CopyCallID id={simulation.call_id} />
                    )
                  : (
                      <span className="text-2xs text-gray-500">
                        No call ID available
                      </span>
                    )}
              </div>
            </div>
            <div className="flex items-center space-x-1.5">
              <div className="text-2xs text-gray-500">Version:</div>
              <span className="text-2xs text-gray-700">
                {!simulation.pathway_version
                || simulation.pathway_version?.toString() === '0'
                  ? 'Production'
                  : `${simulation.pathway_version}`}
              </span>
            </div>
          </div>
        </div>
      </div>

      <ScrollArea className="flex-1 p-2.5">
        <div className="max-w-5xl mx-auto space-y-4">
          <Card className="p-4">
            <h2 className="text-xs font-semibold text-gray-900 mb-2.5 flex items-center">
              <GitBranch className="w-2.5 h-2.5 mr-1.5" />
              Flow Configuration
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="text-2xs font-medium text-gray-500 block mb-0.5">
                  Start Node
                </label>
                <div className="text-xs text-gray-900">
                  {getNodeName(simulation.start_node_id)}
                  <span className="text-2xs text-gray-500 ml-1.5">
                    (
                    {simulation.start_node_id}
                    )
                  </span>
                </div>
              </div>
              <div>
                <label className="text-2xs flex items-center font-medium text-gray-500 mb-0.5">
                  <span className="flex items-center gap-1.5">
                    Success Criteria
                    <CheckCircle2 className="w-2.5 h-2.5 mr-1.5 text-green-500" />
                  </span>
                </label>
                <div className="text-xs text-gray-900 flex items-center">
                  End Node:
                  <span className="text-2xs text-gray-500 ml-1.5">
                    {getNodeName(simulation.success_criteria.end_node)}
                  </span>
                </div>
              </div>
            </div>
          </Card>

          <Card className="p-4">
            <h2 className="text-xs font-semibold text-gray-900 mb-2.5 flex items-center">
              <PlayCircle className="w-2.5 h-2.5 mr-1.5" />
              Request Data
            </h2>
            <div className="bg-gray-50 rounded-md p-2.5 overflow-auto">
              <pre className="text-2xs text-gray-700 font-mono">
                {formatJSON(simulation.request_data)}
              </pre>
            </div>
          </Card>

          <Card className="p-4">
            <h2 className="text-xs font-semibold text-gray-900 mb-2.5 flex items-center">
              <MessageSquare className="w-2.5 h-2.5 mr-1.5" />
              Input Messages
            </h2>
            <div className="bg-gray-50 rounded-md p-2.5 border border-gray-100">
              <MessageList
                messages={simulation.input_messages}
                readOnly
              />
            </div>
          </Card>
        </div>
      </ScrollArea>
    </div>
  )
}

export default SimulationDetailsHeader
