import styled from 'styled-components'

function Label({ children }) {
  return (
    <StyledLabel className="font-medium font-taurus text-[#676775]">
      {children}
    </StyledLabel>
  )
}

export default Label

const StyledLabel = styled.label`
  font-size: 0.75rem;
`
