import { useCallback, useEffect, useRef, useState } from 'react'
import { $fetch } from 'utils/fetch'
import FineTuneModal from '../fine-tune/FineTuneModal'
import ChatUI from './chatUI'

function ChatWithWebSocket({
  onClose,
  id,
  callID,
  setCallID,
  setShowChat,
  chatRequestData,
  chatStartNode,
  chatEndpoint,
  chatConversationHistory,
  setChatConversationHistory,
  currentNodeName,
  chatVersion,
}) {
  const [socket, setSocket] = useState(null)
  const [messages, setMessages] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const isAssistantMessageCompleteRef = useRef(true)
  const [editingMessage, setEditingMessage] = useState(null)
  const heartbeatIntervalRef = useRef(null)

  const connectWebSocket = useCallback(async () => {
    const PATHWAY_CHAT_PG = 'blandpathwaychat'
    let webhookURL = `wss://stream.aws.dc5.bland.ai/ws/connect/${PATHWAY_CHAT_PG}?pathway_chat=${id}`
    setIsLoading(true)

    try {
      const result = await $fetch(`/placement/current`, {
        method: 'GET',
      })

      let placementGroup = result.data?.placement_group

      if (!placementGroup || placementGroup === 'bland' || placementGroup === 'blandshared') {
        placementGroup = PATHWAY_CHAT_PG
      }

      if (chatEndpoint && chatEndpoint.startsWith('http://localhost')) {
        webhookURL = `ws://${chatEndpoint.split('http://')[1]}/pathway_chat/${id}`
      }
      else if (chatEndpoint === 'https://staging.api.bland.ai') {
        webhookURL = `wss://stream.aws.dc5.bland.ai/ws/connect/staging?pathway_chat=${id}`
      }
      else if (placementGroup.includes('dev')) {
        webhookURL = `wss://stream.aws.dc5.bland.ai/ws/connect/${PATHWAY_CHAT_PG}?pathway_chat=${id}`
      }
      else {
        webhookURL = `wss://stream.aws.dc5.bland.ai/ws/connect/${placementGroup}?pathway_chat=${id}`
      }
    }
    catch (error) {
      console.error('Failed to find group', error)
    }

    const newSocket = new WebSocket(webhookURL)

    newSocket.addEventListener('open', () => {
      heartbeatIntervalRef.current = setInterval(() => {
        if (newSocket.readyState === WebSocket.OPEN) {
          newSocket.send(new Uint8Array([1]))
        }
      }, 10000)

      newSocket.send(
        JSON.stringify({
          type: 'setup',
          payload: {
            requestData: chatRequestData,
            startNode: chatStartNode,
            chat_history: chatConversationHistory,
            chatVersion,
          },
        }),
      )

      if (chatConversationHistory && chatConversationHistory.length > 0) {
        setMessages(chatConversationHistory)
      }

      setChatConversationHistory(null)
    })

    newSocket.addEventListener('message', (event) => {
      const message = JSON.parse(event.data)
      if (message.type === 'callID') {
        setCallID(message.payload)
        setShowChat(true)
      }
      else if (message.type === 'text') {
        if (isLoading) {
          setIsLoading(false)
        }
        if (isAssistantMessageCompleteRef.current) {
          setMessages(prevMessages => [
            ...prevMessages,
            {
              role: 'assistant',
              content: message.payload,
              promptInfo: message.promptInfo,
            },
          ])
          isAssistantMessageCompleteRef.current = false
        }
        else {
          setMessages((prevMessages) => {
            const updatedMessages = [...prevMessages]
            const lastMessageIndex = updatedMessages.length - 1
            updatedMessages[lastMessageIndex].content += message.payload
            updatedMessages[lastMessageIndex].promptInfo = message.promptInfo
            return updatedMessages
          })
        }
      }
      else if (message.type === 'done') {
        isAssistantMessageCompleteRef.current = true
        setIsLoading(false)
      }
    })

    newSocket.addEventListener('close', () => {
      console.log('WebSocket closed')
    })

    setSocket(newSocket)
  }, [
    id,
    chatEndpoint,
    chatRequestData,
    chatStartNode,
    chatConversationHistory,
    chatVersion,
    setChatConversationHistory,
    setCallID,
    setShowChat,
    isLoading,
  ])

  useEffect(() => {
    connectWebSocket()

    return () => {
      if (socket) {
        socket.close()
      }
      if (heartbeatIntervalRef.current) {
        clearInterval(heartbeatIntervalRef.current)
      }
    }
  }, [])

  const handleSendMessage = (message) => {
    if (socket && message.trim()) {
      socket.send(JSON.stringify({ type: 'text', payload: message }))
      setMessages(prevMessages => [
        ...prevMessages,
        { role: 'user', content: message },
      ])
    }
  }

  const handleRestart = () => {
    if (socket) {
      socket.close()
    }
    setMessages([])
    setCallID(null)
    setShowChat(false)
    setIsLoading(true)
    isAssistantMessageCompleteRef.current = true

    connectWebSocket()
    setShowChat(true)
  }

  return (
    <>
      <ChatUI
        messages={messages}
        onSendMessage={handleSendMessage}
        onClose={onClose}
        callID={callID}
        currentNodeName={currentNodeName}
        isLoading={isLoading}
        onEditMessage={message => setEditingMessage(message)}
        onRestart={handleRestart}
        version={chatVersion}
      />
      {editingMessage && (
        <FineTuneModal
          isOpen={!!editingMessage}
          onClose={() => setEditingMessage(null)}
          message={editingMessage}
          graphID={id}
          callID={callID}
        />
      )}
    </>
  )
}

export default ChatWithWebSocket
