import axios from 'axios'
import config from 'config'
import { getOrgId } from 'utils/funcs/browser/getOrgId'
import { getAuthToken } from '../browser/getAuthToken'

async function fetchTranscript(callId) {
  try {
    const token = getAuthToken()
    const orgId = getOrgId()
    const headers = {
      authorization: `${token}`,
      ...(orgId && { 'x-bland-org-id': orgId }),
    }
    const { data } = await axios.post(
      `${config.API_URL}/user/transcript`,
      { call_id: callId },
      { headers },
    )
    if (data) {
      return data
    }
  }
  catch (err) {
    console.error('Error fetching transcript', err)
  }
}

export async function fetchAndUpdateTranscriptStatus(callId, txId, status) {
  try {
    const token = getAuthToken()
    const orgId = getOrgId()
    const headers = {
      authorization: `${token}`,
      ...(orgId && { 'x-bland-org-id': orgId }),
    }

    const { data } = await axios.post(
      `${config.API_URL}/user/transcript_status`,
      { callId, txId, status },
      { headers },
    )

    if (data)
      return data
  }
  catch (error) {
    console.error('')
  }
}
