import { TrashIcon } from 'assets/icons/navigationIcons'
import Button from 'components/core/Button'
import { Divider } from 'components/core/Divider'
import Input from 'components/core/Input'
import Label from 'components/core/Label'
import Switch from 'components/core/Switch'
import { useEffect } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import styled from 'styled-components'

export default function Pronunciation({ fieldName }) {
  const { control, getValues, setValue, watch } = useFormContext()

  const { fields, append, remove } = useFieldArray({
    control,
    name: fieldName,
  })

  useEffect(() => {
    const currData = getValues(fieldName)
    setValue(fieldName, currData)
  }, [fieldName, getValues, setValue, watch])

  const onAdd = () => {
    append({
      word: '',
      pronunciation: '',
      case_sensitive: false,
      spaced: false,
    })
  }

  return (
    <Wrapper>
      <Label>Pronunciation Guide</Label>
      <p className="text-muted-foreground mb-1.5">
        The pronunciation guide is an array of objects that guides the LLM on
        how to say specific words. This is great for situations with complicated
        terms or names.
      </p>
      {fields.length > 0
        ? (
            <>
              {fields.map((param, index) => (
                <Guide>
                  <Header>
                    <h6 className="font-bold">{`Word ${index + 1}`}</h6>
                    <Button onClick={() => remove(index)} appearance="outline">
                      <TrashIcon />
                    </Button>
                  </Header>
                  <Divider />
                  <Fields key={param.id}>
                    <Field>
                      <Input
                        fieldName={`${fieldName}[${index}].word`}
                        placeholder="Example"
                        label="Word"
                      />
                    </Field>
                    <Field>
                      <Input
                        fieldName={`${fieldName}[${index}].pronunciation`}
                        placeholder="Ex-am-ple"
                        label="Pronunciation"
                      />
                    </Field>
                    <Divider />
                    <Field>
                      <Switch
                        fieldName={`${fieldName}[${index}].case_sensitive`}
                        label="Case Sensitive"
                      />
                    </Field>
                    <Field>
                      <Switch
                        fieldName={`${fieldName}[${index}].spaced`}
                        label="Spaced"
                      />
                    </Field>
                  </Fields>
                </Guide>
              ))}
              <Button
                onClick={onAdd}
                appearance="outline"
                style={{ width: 'auto !important' }}
              >
                + Word
              </Button>
            </>
          )
        : (
            <Button
              onClick={onAdd}
              appearance="outline"
              style={{ width: 'auto !important' }}
            >
              + Word
            </Button>
          )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  align-items: start;
`

const Guide = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid #eeeeee;
  gap: 1px;
  position: relative;
`

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 12px 15px;
`

const Fields = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  width: 100%;
  padding: 20px;
`

const Field = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  column-gap: 12px;
  width: 100%;
`
