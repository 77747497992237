import AuthButton from 'components/Hero/AuthButton'
import { Link } from '@heroui/react'

export function Promotion({ type }) {
  return (
    <div
      className="hidden xl:block w-full h-screen relative bg-cover"
      style={{
        backgroundImage: 'url(\'/pixels.png\')',
      }}
    >
      <div className="flex flex-col gap-4 max-w-3xl w-[35vw] shrink-0 top-1/4 left-1/3 -translate-x-12 -translate-y-16 absolute">
        <h1 className="text-3xl font-semibold font-taurus leading-tight tracking-tightest">
          {type === 'login'
            ? `Welcome back! You're building the future`
            : 'Enterprise-grade solutions for AI phone calls'}
        </h1>

        <p className="text-black font-taurus font-medium tracking-tight text-sm">
          Work with a dedicated Bland AI machine learning engineer to create a
          custom phone agent and automate all your organization's phone calls.
        </p>
        
          <AuthButton href="https://bland.com/enterprise" as={Link}>
            Enterprise inquiry
          </AuthButton>
        
      </div>
    </div>
  )
}
