import { forwardRef, Textarea } from '@heroui/react'

const SMSPromptInput = forwardRef((props, ref) => {
  return (
    <Textarea
      ref={ref}
      label="SMS Tool"
      description="Agent will use this tool when told to do so in the prompt, and send the below message to the user."
      placeholder="Enter the SMS message to send..."
      labelPlacement="outside"
      minRows={20}
      variant="bordered"
      radius="sm"
      {...props}
    />
  )
})

export default SMSPromptInput
