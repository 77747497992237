import { Button } from '@radix-ui/themes'
import { useEffect, useRef, useState } from 'react'
import { FaCheck, FaCopy } from 'react-icons/fa'
import styled from 'styled-components'
import { $fetch } from 'utils/fetch'

export default function WebhookSigningSecret({}) {
  const [hidden, setHidden] = useState(true)
  const [copied, setCopied] = useState(false)
  const [generating, setGenerating] = useState(false)
  const textRef = useRef()
  const [hiddenWebhookSigningSecret, setHiddenWebhookSigningSecret] = useState(
    '*'.repeat(64),
  )

  const handleRevealClick = () => setHidden(!hidden)
  const handleCopyClick = () => {
    navigator.clipboard
      .writeText(hiddenWebhookSigningSecret)
      .then(() => setCopied(true))
  }

  const GenerateNewWebhookSigningSecret = async () => {
    setGenerating(true)
    const headers = {}

    try {
      const data = await $fetch('/user/newWebhookSecret', {
        method: 'POST',
      })

      if (data?.webhook_signing_secret) {
        setHiddenWebhookSigningSecret(data.webhook_signing_secret)
        setHidden(false)
        setCopied(false)
        setGenerating(false)
      }
    }
    catch (error) {
      console.error(error)
      setGenerating(false)
    }
  }

  useEffect(() => {
    if (hidden === true) {
      setHiddenWebhookSigningSecret('*'.repeat(64))
    }
  }, [hidden])

  return (
    <div className="flex flex-col space-y-4">
      <div className="flex flex-col">
        <div style={{ marginBottom: 17 }}>
          <p style={{ fontSize: 14, fontWeight: 400 }}>
            Webhook Signing Secret
          </p>
          <p style={{ color: 'GrayText', fontSize: 12 }}>
            Never share this publicly. Warning: Once generated, the secret will
            only be viewable once and cannot be retrieved later without
            replacing it.
          </p>
        </div>
        <div className="flex justify-between items-center p-2 bg-gray-50 border border-gray-200 rounded-">
          <div ref={textRef} className="text-black opacity-50 cursor-default">
            {hiddenWebhookSigningSecret}
          </div>
          <div className="flex space-x-1.5 text-black opacity-60">
            {/* <button onClick={handleRevealClick} className="p-0.5">
              {hidden ? <FaEye /> : <FaEyeSlash />}
            </button> */}
            <button onClick={handleCopyClick} className="p-0.5">
              {copied ? <FaCheck /> : <FaCopy />}
            </button>
          </div>
        </div>

        <div
          style={{
            marginTop: 10,
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
          }}
        >
          {/* <p className="text-2xs text-gray-500">
            Never share this publicly. Warning: Once generated, the secret will
            only be viewable once and cannot be retrieved later without
            replacing it.
          </p> */}
          {/* <button
            onClick={GenerateNewWebhookSigningSecret}
            className="text-black font-medium text-2xs hover:underline"
          >
            {generating ? "Replacing..." : "Replace Secret"}
          </button> */}
          {/* <TipReplace>
            <Link
              onClick={() => {
                GenerateNewWebhookSigningSecret();
              }}
            >
              {generating ? "Replacing..." : "Replace Secret"}
            </Link>
          </TipReplace> */}

          <Button
            type="button"
            style={{ marginLeft: 'auto', cursor: 'pointer' }}
            loading={generating}
            onClick={() => GenerateNewWebhookSigningSecret()}
            color="iris"
            variant="soft"
          >
            Replace Secret
          </Button>
        </div>
      </div>
    </div>
  )
}

const Link = styled.div`
  color: black;
  font-weight: 500;
  font-size: 12px;
  cursor: pointer;
  &:hover {
  }
`

const TipReplace = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`
