import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
import { Button } from '@radix-ui/themes'
import LoadSpinner from 'components/core/LoadSpinner'
import SlideOut from 'components/Dashboard/CallLogs/SlideOut'
import { AnimatePresence, motion } from 'framer-motion'
import { useCallback, useEffect, useRef, useState } from 'react'
import { FiCheck, FiCopy } from 'react-icons/fi'
import DecisionLogs from './decisionLogs'
import CollapsibleContent from './pathway_logs/collapsibleContent'

export function PathwayLogsContent({
  loading,
  initialCallId,
  callLogs,
  setShouldScroll,
  callLogsRef: externalCallLogsRef,
  elements,
  setChatConversationHistory,
  setChatStartNode,
  graphID,
  enableQA,
  onSeekToTimestamp,
  highlightedLogInfo,
}) {


  // Create a local ref if an external one isn't provided
  const internalCallLogsRef = useRef(null)
  const callLogsRef = externalCallLogsRef || internalCallLogsRef

  // Add state for shouldScroll
  const [shouldScroll, setShouldScrollInternal] = useState(true)

  // Use the provided setShouldScroll or the internal one
  const handleSetShouldScroll = useCallback((value) => {
    if (setShouldScroll) {
      setShouldScroll(value)
    } else {
      setShouldScrollInternal(value)
    }
  }, [setShouldScroll])

  const handleScroll = () => {
    if (!setShouldScroll && !setShouldScrollInternal)
      return
    
    const { scrollTop, scrollHeight, clientHeight } = callLogsRef?.current
    // Use a small threshold to account for rounding errors
    const isScrolledToBottom = Math.abs(scrollHeight - (scrollTop + clientHeight)) < 5
    handleSetShouldScroll(isScrolledToBottom)
  }

  const [copied, setCopied] = useState(false)

  const copyID = () => {
    navigator.clipboard.writeText(graphID ?? initialCallId)
    setCopied(true)
    setTimeout(() => setCopied(false), 2000)
  }

  const formatTimeOnly = (dateString) => {
    if (!dateString)
      return ''
    const date = new Date(dateString)
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' })
  }



  // Estimate speaking duration based on text length
  const estimateSpeakingDuration = (text, role) => {
    // Different speaking rates for assistant vs user (chars per second)
    if (role === 'user') {
      return Math.max(1, text.length / 12) // assuming 12 chars per second for user
    }
    else {
      // this is so fucked because in pathway logs, the assistant message is inserted when the LLM is done generating it, so lets assume it took 1 second to generate everything
    }
    return 1
  }

  const renderContent = (content) => {
    if (typeof content === 'string') {
      try {
        const jsonContent = JSON.parse(content)
        return Object.entries(jsonContent).map(([key, value]) => (
          <div key={key} className="flex items-center space-x-1.5 text-xs mt-1.5">
            <span className="font-medium text-gray-600 min-w-[100px]">
              {key}
              :
            </span>
            <CollapsibleContent
              content={
                typeof value === 'object'
                  ? JSON.stringify(value, null, 2)
                  : value
              }
            />
          </div>
        ))
      }
      catch (e) {
        return <CollapsibleContent content={content} />
      }
    }
    else if (typeof content === 'object') {
      return Object.entries(content).map(([key, value]) => (
        <div key={key} className="flex items-center space-x-1.5 text-xs mt-1.5">
          <span className="font-medium text-gray-600 min-w-[100px]">
            {key}
            :
          </span>
          <CollapsibleContent
            content={
              typeof value === 'object'
                ? JSON.stringify(value, null, 2)
                : String(value)
            }
          />
        </div>
      ))
    }
    else {
      return <CollapsibleContent content={String(content)} />
    }
  }

  // For highlighted logs, we need to prevent auto-scrolling to bottom
  useEffect(() => {
    if (highlightedLogInfo && callLogsRef?.current) {
      // Find the highlighted element by ID or other unique attribute
      const highlightedElement = callLogsRef.current.querySelector(`[data-highlighted="true"]`);
      
      if (highlightedElement) {
        setTimeout(() => {
          highlightedElement.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        }, 100);
      }
    }
  }, [highlightedLogInfo, callLogs]);

  // Simple effect for scrolling down that worked before
  useEffect(() => {
    if (callLogsRef?.current && shouldScroll && !highlightedLogInfo) {
      callLogsRef.current.scrollTop = callLogsRef.current.scrollHeight
    }
  }, [callLogs, shouldScroll, highlightedLogInfo]);

  // Function to check if a log entry matches the highlighted info
  const isHighlightedLog = (log) => {
    if (!highlightedLogInfo)
      return false

    // Match based on log type and timestamp
    if (highlightedLogInfo.type === 'variable_extraction'
      && log.pathway_info
      && log.pathway_info.includes('Variables being extracted')
      && log.created_at === highlightedLogInfo.timestamp) {
      return true
    }

    if (highlightedLogInfo.type === 'pathway_tag'
      && log.tag !== null
      && log.created_at === highlightedLogInfo.timestamp) {
      return true
    }

    if (highlightedLogInfo.type === 'decision'
      && log.decision
      && log.created_at === highlightedLogInfo.timestamp) {
      return true
    }

    if (highlightedLogInfo.type === 'conversation'
      && log.role
      && log.text
      && log.created_at === highlightedLogInfo.timestamp) {
      return true
    }

    return false
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2 }}
      className="bg-white rounded-md overflow-hidden"
      style={{ fontFamily: 'Inter, sans-serif' }}
    >
      <div className="bg-gray-50 px-2.5 py-2 border-b border-gray-200 flex justify-between items-center text-xs">
        <div className="flex space-x-2.5 items-center">
          <span className="font-medium text-gray-500">
            {graphID ? 'PATHWAY ID' : 'CALL ID'}
          </span>
          <span className="font-mono text-gray-700">
            {graphID ?? initialCallId}
          </span>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={copyID}
            className="ml-1.5 text-gray-400 hover:text-gray-600 transition-colors duration-200"
          >
            {copied
              ? (
                  <FiCheck className="w-2.5 h-2.5" />
                )
              : (
                  <FiCopy className="w-2.5 h-2.5" />
                )}
          </motion.button>
        </div>
      </div>
      <div
        className="h-full overflow-y-auto bg-white"
        ref={callLogsRef}
        onScroll={handleScroll}
        style={{ maxHeight: '100%' }}
      >
        <AnimatePresence>
          {loading
            ? (
                <LoadSpinner loading={loading} />
              )
            : callLogs?.length === 0
              ? (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className="flex flex-col items-center justify-center h-full text-gray-400"
                  >
                    <svg
                      className="animate-spin h-4 w-4 mb-2"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      >
                      </circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      >
                      </path>
                    </svg>
                    <p className="text-xs font-medium">Waiting for messages...</p>
                  </motion.div>
                )
              : (
                  callLogs?.map((log, index) => (
                    <motion.div
                      key={log.id}
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.15 }}
                      className={`py-2 px-2.5 border-b border-gray-100 ${
                        index % 2 === 0 ? 'bg-gray-50' : 'bg-white'
                      } ${isHighlightedLog(log) ? 'border-2 border-violet-400 shadow-lg' : ''}`}
                      data-highlighted={isHighlightedLog(log) ? "true" : "false"}
                    >
                      {log.text && log.text !== '<Call Connected>'
                        ? (
                            <motion.div
                              className="flex items-start justify-between"
                              whileHover={{ x: 0 }}
                              transition={{ type: 'spring', stiffness: 300 }}
                            >
                              <div className="flex items-start flex-grow overflow-hidden mr-2">
                                <span
                                  className={`px-1.5 py-0.5 text-xs font-semibold rounded mr-2 flex-shrink-0 ${
                                    log.role === 'assistant'
                                      ? 'bg-blue-100 text-blue-700'
                                      : 'bg-green-100 text-green-700'
                                  }`}
                                >
                                  {log.role.toUpperCase()}
                                </span>
                                <p className="text-md text-gray-700 overflow-hidden text-ellipsis">
                                  {log.text}
                                </p>
                              </div>
                              {log.created_at && onSeekToTimestamp && (
                                <span
                                  className="text-[10px] text-violet-600 cursor-pointer hover:underline hover:text-violet-800 font-mono bg-violet-50 px-1 py-0.5 rounded flex-shrink-0"
                                  onClick={() => {
                                    if (log.role && log.text) {
                                      const durationInSeconds = estimateSpeakingDuration(log.text, log.role)
                                      const startTimestamp = new Date(log.created_at).getTime() - (durationInSeconds * 1000)
                                      onSeekToTimestamp(new Date(startTimestamp).toISOString())
                                    }
                                    else {
                                      onSeekToTimestamp(log.created_at)
                                    }
                                  }}
                                  title="Jump to this point in the recording"
                                >
                                  {formatTimeOnly(log.created_at)}
                                </span>
                              )}
                            </motion.div>
                          )
                        : log.pathway_info
                          ? (
                              <motion.div
                                className="flex items-start justify-between"
                                whileHover={{ scale: 1.0 }}
                                transition={{ type: 'spring', stiffness: 900 }}
                              >
                                <div className="flex-grow overflow-hidden mr-2">
                                  <span className="px-1.5 py-0.5 text-xs font-semibold bg-purple-100 text-purple-700 rounded mb-1.5 inline-block">
                                    PATHWAY INFO
                                  </span>
                                  {renderContent(log.pathway_info)}
                                </div>
                                {log.created_at && onSeekToTimestamp && (
                                  <span
                                    className="text-[10px] text-violet-600 cursor-pointer hover:underline hover:text-violet-800 font-mono bg-violet-50 px-1 py-0.5 rounded flex-shrink-0"
                                    onClick={() => onSeekToTimestamp(log.created_at)}
                                    title="Jump to this point in the recording"
                                  >
                                    {formatTimeOnly(log.created_at)}
                                  </span>
                                )}
                              </motion.div>
                            )
                          : (
                              log.decision && (
                                <div className="flex items-start justify-between">
                                  <div className="flex-grow overflow-hidden mr-2">
                                    <DecisionLogs
                                      decision={JSON.parse(log.decision)}
                                      disableFineTune
                                      edges={elements?.edges}
                                      nodes={elements?.nodes}
                                      setChatConversationHistory={setChatConversationHistory}
                                      setChatStartNode={setChatStartNode}
                                      callID={initialCallId}
                                      graphID={graphID}
                                      enableQA={enableQA}
                                      created_at={log.created_at}
                                      onSeekToTimestamp={onSeekToTimestamp}
                                    />
                                  </div>
                                </div>
                              )
                            )}
                    </motion.div>
                  ))
                )}
        </AnimatePresence>
      </div>
    </motion.div>
  )
}

function PathwayLogsSlideout({
  isOpen,
  onClose,
  callIds,
  initialCallId,
  setFocusedCId,
  focusedCall,
  loading = false,
  modalOpen = false,
  notesOpen = false,
  enableQA,
  graphID,
  showChat,
  elements,
  setChatConversationHistory,
  setChatStartNode,
}) {
  const [currentIndex, setCurrentIndex] = useState(0)

  const [shouldScroll, setShouldScroll] = useState(true)
  const callLogsRef = useRef(null)

  const handleNavigation = useCallback(
    (direction) => {
      setCurrentIndex((prevIndex) => {
        const newIndex
          = direction === 'next'
            ? Math.min(prevIndex + 1, callIds?.length - 1)
            : Math.max(prevIndex - 1, 0)
        const newCallId = callIds[newIndex]
        setFocusedCId(newCallId)
        return newIndex
      })
    },
    [callIds, setFocusedCId],
  )

  useEffect(() => {
    if (isOpen && initialCallId) {
      const index = callIds?.indexOf(initialCallId)
      if (index !== -1) {
        setCurrentIndex(index)
      }
    }
  }, [isOpen, initialCallId, callIds])

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'j') {
        handleNavigation('next')
      }
      else if (event.key === 'k') {
        handleNavigation('prev')
      }
    }

    if (!modalOpen && !notesOpen) {
      window.addEventListener('keydown', handleKeyPress)
      return () => {
        window.removeEventListener('keydown', handleKeyPress)
      }
    }
  }, [handleNavigation, modalOpen, notesOpen])

  useEffect(() => {
    if (callLogsRef?.current && shouldScroll) {
      callLogsRef.current.scrollTop = callLogsRef.current.scrollHeight
    }
  }, [focusedCall, shouldScroll])

  if (!showChat)
    return null

  return (
    <SlideOut
      isOpen={isOpen}
      onClose={onClose}
      title={`Pathway Logs ${currentIndex + 1} of ${callIds?.length}`}
      subtitle={initialCallId ? `Call ID: ${initialCallId}` : null}
      footer={(
        <>
          <div className="flex justify-between mb-2.5">
            <div>
              <button
                type="button"
                onClick={() => handleNavigation('prev')}
                disabled={currentIndex === 0}
                className={`flex items-center px-2 py-1.5 bg-gray-100 text-xs text-gray-700 rounded-sm ${
                  currentIndex === 0
                    ? 'opacity-50 cursor-not-allowed'
                    : 'hover:bg-gray-200'
                }`}
              >
                <ChevronLeftIcon className="h-2.5 w-2.5 mr-0.5" />
                Previous
              </button>
            </div>
            <div>
              <button
                type="button"
                onClick={() => handleNavigation('next')}
                disabled={currentIndex === callIds?.length - 1}
                style={{ marginLeft: 'auto' }}
                className={`flex items-center text-xs px-2 py-1.5 bg-gray-100 text-gray-700 rounded-sm ${
                  currentIndex === callIds?.length - 1
                    ? 'opacity-50 cursor-not-allowed'
                    : 'hover:bg-gray-200'
                }`}
              >
                Next
                <ChevronRightIcon className="h-2.5 w-2.5 ml-0.5" />
              </button>
            </div>
          </div>
          <Button
            type="button"
            style={{ width: '100%' }}
            color="lightblue"
            variant="solid"
            onClick={() =>
              window.open('https://app.bland.ai/enterprise', '_blank')}
            className="w-full bg-blue-500 hover:bg-blue-600 text-white text-xs font-medium py-1.5 px-2.5 rounded-sm transition duration-150 ease-in-out"
          >
            <p style={{ fontSize: 13 }}>Interested in Fine-Tuning?</p>
          </Button>
          <p style={{ fontSize: 10 }} className="mt-1.5 text-2xs text-gray-500">
            We're constantly improving. For a fine-tuned model tailored to your
            needs, reach out to our team.
          </p>
        </>
      )}
    >
      <PathwayLogsContent
        loading={loading}
        initialCallId={initialCallId}
        callLogs={focusedCall?.pathway_logs}
        setShouldScroll={setShouldScroll}
        callLogsRef={callLogsRef}
        elements={elements}
        setChatConversationHistory={setChatConversationHistory}
        setChatStartNode={setChatStartNode}
        graphID={graphID}
        enableQA={enableQA}
      />
    </SlideOut>
  )
}

