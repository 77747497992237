import { Button, Card, CardBody, Form, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Snippet, useDisclosure } from '@heroui/react'
import { useQueryClient } from '@tanstack/react-query'
import { Save, SquareUser } from 'lucide-react'
import { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { toast } from 'react-toastify'
import { $fetch } from 'utils/fetch'
import { parseForJson } from 'utils/funcs/send-call/parseForJson'

export default function SavePresetModal({ color }) {
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [name, setName] = useState('')
  const { getValues } = useFormContext()
  const queryClient = useQueryClient()

  const onSubmit = async () => {
    setIsSubmitting(true)
    try {
      const formValues = parseForJson(getValues())
      const { file_id, column_mapping, recipients_count, ...call_config } = formValues
      await $fetch('/call_config', {
        method: 'POST',
        body: {
          name,
          call_config,
        },
      })
      toast.success('Persona saved successfully')
      setIsSubmitting(false)
      queryClient.invalidateQueries({ queryKey: ['configs'] })
      onClose()
    }
    catch (error) {
      console.error('Error saving persona:', error)
      toast.error('Failed to save persona')
    }
    setIsSubmitting(false)
  }

  return (
    <>
      <Button
        variant="light"
        disableRipple
        radius="none"
        color={color}
        onPress={onOpen}
        startContent={<SquareUser size={16} />}
      >
        Save as Persona
      </Button>
      <Modal
        size="3xl"
        isOpen={isOpen}
        radius="sm"
        placement="top-center"
        onOpenChange={onOpenChange}
        classNames={{
          footer: 'ml-auto',
          body: 'w-full',
        }}
      >
        <ModalContent>
          {onClose => (
            <>
              <Form validationBehavior="native" onSubmit={onSubmit}>
                <ModalHeader className="flex flex-col gap-1">
                  <h2 className="text-2xl font-semibold flex items-center gap-3">
                    Save as Persona
                  </h2>
                  <p className="text-sm font-light text-foreground-400">Save the current call configuration as a persona to reuse across Bland.</p>
                </ModalHeader>
                <ModalBody>
                  <Card className="w-full p-2" radius="sm">
                    <CardBody className="flex flex-col gap-5 w-full">
                      <Input
                        isRequired
                        label="Name"
                        variant="bordered"
                        radius="sm"
                        labelPlacement="outside"
                        placeholder="Enter a name for your new persona"
                        value={name}
                        onChange={e => setName(e.target.value)}
                      />

                      <Snippet
                        radius="none"
                        variant="flat"
                        classNames={{
                          base: 'flex bg-primary-50 border border-default shadow-sm transition-all flex-col w-full max-h-64 overflow-y-scroll',
                          content: 'w-full gap-0.5',
                          pre: 'text-wrap truncate font-light text-xs',
                        }}
                        hideCopyButton
                        hideSymbol
                      >
                        {Object.entries(getValues()).map(([key, value]) => (
                          <span key={key} className="space-x-1">
                            <span className="text-primary">{key}</span>
                            :
                            {
                              typeof value === 'object'
                                ? JSON.stringify(value)
                                : <span className="text-success">{String(value)}</span>
                            }
                            <br />
                          </span>
                        ))}
                      </Snippet>
                    </CardBody>
                  </Card>

                </ModalBody>
                <ModalFooter>
                  <Button color="danger" variant="flat" onPress={onClose}>
                    Cancel
                  </Button>
                  <Button color={color} isDisabled={!name?.length} onPress={onSubmit} isLoading={isSubmitting}>
                    Save
                  </Button>
                </ModalFooter>
              </Form>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  )
}
