import { Button, Dialog, Flex, Select, Text } from '@radix-ui/themes'
import config from 'config'
import { useEffect, useState } from 'react'
import PhoneInput from 'react-phone-input-2'
import { toast } from 'react-toastify'
import { $fetch } from 'utils/fetch'
import { getAuthToken } from 'utils/funcs/browser/getAuthToken'
import { getOrgId } from 'utils/funcs/browser/getOrgId'

function RateLimitsModal({ pathwayId, triggerButton }) {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [rateLimit, setRateLimit] = useState('')
  const [timePeriod, setTimePeriod] = useState('minute')
  const [fallbackNumber, setFallbackNumber] = useState('')
  const [webhook, setWebhook] = useState('')
  const [fetchingLimits, setFetchingLimits] = useState(false)
  const [testingWebhook, setTestingWebhook] = useState(false)

  const testWebhook = async () => {
    if (!webhook) {
      toast.error('Please enter a webhook URL first')
      return
    }

    setTestingWebhook(true)
    try {
      const samplePayload = {
        event: 'rate_limit_exceeded',
        pathway_id: pathwayId,
        timestamp: new Date().toISOString(),
        details: {
          rate_limit: Number.parseInt(rateLimit),
          frequency: timePeriod,
          fallback_number: fallbackNumber,
        },
      }

      const response = await $fetch('/tools/run', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': getAuthToken(),
        },
        body: JSON.stringify({
          url: webhook,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: samplePayload,
        }),
      })

      if (!response.errors) {
        toast.success('Webhook test successful')
      }
      else {
        throw new Error('Webhook test failed')
      }
    }
    catch (error) {
      console.error('Error testing webhook:', error)
      toast.error('Request to webhook failed')
    }
    finally {
      setTestingWebhook(false)
    }
  }

  useEffect(() => {
    if (open) {
      fetchCurrentRateLimits()
    }
  }, [open])

  const fetchCurrentRateLimits = async () => {
    const orgId = getOrgId()
    try {
      setFetchingLimits(true)
      const response = await fetch(
        `${config.API_URL}/convo_pathway/rate_limit?pathway_id=${pathwayId}`,
        {
          method: 'POST',
          headers: {
            'Authorization': getAuthToken(),
            'Content-Type': 'application/json',
            ...(orgId && { 'x-bland-org-id': orgId }),
          },
        },
      )
      if (response.ok) {
        const { data } = await response.json()
        setRateLimit(data.rate_limit)
        setTimePeriod(data.frequency)
        setFallbackNumber(data.fallback_number)
        setWebhook(data.webhook)
      }

      setFetchingLimits(false)
    }
    catch (error) {
      console.error('Error fetching rate limits:', error)
      setFetchingLimits(false)
    }
  }

  const handleSave = async () => {
    try {
      setLoading(true)

      //   if (!rateLimit || !timePeriod) {
      //     throw new Error("Rate limit and time period are required");
      //   }

      try {
        const rateLimitInt = Number.parseInt(rateLimit)
        if (isNaN(rateLimitInt) || rateLimitInt <= 0) {
          throw new Error('Rate limit must be a positive number')
        }
      }
      catch (error) {
        throw new Error('Rate limit must be a positive number')
      }

      let fallback_num = fallbackNumber

      // check if fallback number has + sign in front, if it doesn't add. it . if it does, do nothing
      if (fallbackNumber && !fallbackNumber.startsWith('+')) {
        fallback_num = `+${fallbackNumber}`
      }
      const response = await $fetch(`/convo_pathway/set_rate_limit`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': getAuthToken(),
        },
        body: JSON.stringify({
          pathway_id: pathwayId,
          rate_limit: Number.parseInt(rateLimit),
          frequency: timePeriod,
          fallback_number: fallback_num,
          webhook,
        }),
      })

      if (!response.errors) {
        toast.success('Rate limits updated successfully')
        // setOpen(false);
      }
      else {
        throw new Error('Failed to update rate limits')
      }
    }
    catch (error) {
      console.error('Error updating rate limits:', error)
      toast.error('Failed to update rate limits')
    }
    finally {
      setLoading(false)
    }
  }

  return (
    <>
      <span onClick={() => setOpen(true)}>{triggerButton}</span>

      <Dialog.Root open={open} onOpenChange={setOpen}>
        <Dialog.Content style={{ maxWidth: 550 }}>
          <Dialog.Title>Rate Limits</Dialog.Title>
          <Dialog.Description size="2" mb="4">
            Set rate limits for inbound calls using this number. When the rate
            limit is exceeded, calls will be redirected to the fallback number.
          </Dialog.Description>
          {fetchingLimits ? (
            <Flex justify="center" align="center" style={{ height: '200px' }}>
              <Text>Loading current rate limits...</Text>
            </Flex>
          ) : (
            <Flex direction="column" gap="3">
              <label>
                <Text as="div" size="2" mb="1" weight="bold">
                  Rate Limit
                </Text>

                <Flex gap="3" align="center">
                  {/* <TextField.Root> */}
                  {/* <TextField.Slot */}
                  <input
                    className="w-24 h-7 border border-gray-300 rounded px-2"
                    value={rateLimit}
                    onChange={e => setRateLimit(e.target.value)}
                    placeholder="e.g., 100"
                  />
                  {/* </TextField.Root> */}
                  <Select.Root value={timePeriod} onValueChange={setTimePeriod}>
                    <Select.Trigger />
                    <Select.Content>
                      <Select.Item value="minute">Per Minute</Select.Item>
                      <Select.Item value="hour">Per Hour</Select.Item>
                      <Select.Item value="day">Per Day</Select.Item>
                    </Select.Content>
                  </Select.Root>
                </Flex>
              </label>

              <label>
                <Text as="div" size="2" mb="1" weight="bold">
                  Fallback Number
                </Text>
                {/* <TextField.Root> */}
                <PhoneInput
                  value={fallbackNumber}
                  country="us"
                  onChange={number => setFallbackNumber(number)}
                  inputProps={{
                    required: false,
                  }}
                  inputStyle={{
                    width: '100%',
                    height: '35px',
                    border: '1px solid #eeeeee',
                    backgroundColor: '#fbfbfa',
                  }}
                />
                {/* <TextField.Slot
                  value={fallbackNumber}
                  onChange={(e) => setFallbackNumber(e.target.value)}
                  placeholder="e.g., +1234567890"
                /> */}
                {/* </TextField.Root> */}
              </label>

              <label>
                <Text as="div" size="2" mb="1" weight="bold">
                  Webhook URL
                </Text>
                <Flex gap="2" align="center">
                  <input
                    className="flex-grow h-6 border border-gray-300 rounded px-2 py-4"
                    value={webhook}
                    onChange={e => setWebhook(e.target.value)}
                    placeholder="e.g., https://your-webhook-url.com"
                  />
                  <Button
                    onClick={testWebhook}
                    disabled={testingWebhook || !webhook}
                    size="2"
                  >
                    {testingWebhook ? 'Testing...' : 'Test'}
                  </Button>
                </Flex>

                <Text as="div" size="1" color="gray">
                  We'll send a POST request to this URL when the rate limit is
                  exceeded.
                </Text>
              </label>
            </Flex>
          )}
          <Flex gap="3" mt="4" justify="end">
            <Dialog.Close>
              <Button variant="soft" color="gray">
                Cancel
              </Button>
            </Dialog.Close>
            <Button onClick={handleSave} disabled={loading}>
              {loading ? 'Saving...' : 'Save'}
            </Button>
          </Flex>
        </Dialog.Content>
      </Dialog.Root>
    </>
  )
}

export default RateLimitsModal
