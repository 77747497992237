import { Alert, Button, Chip, Link, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Select, SelectItem, Snippet } from '@heroui/react'
import { useQuery } from '@tanstack/react-query'
import config from 'config'
import { ArrowLeft, Zap } from 'lucide-react'
import { useState } from 'react'
import { $fetch } from 'utils/fetch'

export default function PersonaSolutionModal({ isOpen, onOpenChange }) {
  const [selectedPersona, setSelectedPersona] = useState(null)
  const { data: personas, isLoading } = useQuery({
    queryKey: ['personas'],
    queryFn: async () => {
      const { data } = await $fetch('/call_config/read')
      return data
    },
  })

  const handleSelectionChange = (e) => {
    setSelectedPersona(e.target.value)
  }

  const personaId = selectedPersona?.length > 0 ? selectedPersona : '{PERSONA_ID}'
  const curlCommand = `-d '{"persona_id": "${personaId}"}'`

  return (
    <Modal size="3xl" isOpen={isOpen} onOpenChange={onOpenChange} radius="sm">
      <ModalContent>
        {onClose => (
          <>
            <ModalHeader className="flex flex-col gap-1">
              <h2 className="text-2xl font-semibold flex items-center gap-3">
                Trigger with Personas
                <Chip color="primary" variant="flat" radius="sm" size="sm">NEW</Chip>
              </h2>
              <p className="text-sm font-light text-foreground-400">Don't worry, we're not getting rid of Events anytime soon... but we think you'll like sending API calls with Personas to keep your triggered calls consistent. Give it a try!</p>
            </ModalHeader>
            <ModalBody className="gap-4">

              <Select
                isLoading={isLoading}
                isVirtualized
                description="Create personas from the Send Call page"
                variant="bordered"
                radius="sm"
                placeholder={!personas?.length ? 'No personas yet' : 'Select a persona'}
                selectedKeys={[selectedPersona]}
                onChange={handleSelectionChange}
                hideEmptyContent
              >
                {personas?.map(persona => (
                  <SelectItem key={persona.id} endContent={<p className="text-xs text-gray-500 truncate font-geist-mono">{persona.id}</p>}>
                    {persona.name ?? 'Untitled Persona'}
                  </SelectItem>
                ))}
              </Select>

              <Snippet
                radius="sm"
                className="border border-default shadow-md hover:shadow-lg bg-gradient-to-r from-black to-primary-500 from-60% hover:opacity-90 hover:scale-[1.01] transition-all p-5 text-white"
              >
                <span>{`curl -X POST '${config.API_URL}/v1/calls' \\`}</span>
                <span>-H 'Content-Type: application/json' \</span>
                <span>{`-H 'Authorization: {API_KEY}' \\`}</span>
                <span>{curlCommand}</span>
              </Snippet>
              <Alert color="default" title="Include additional fields in the body of the call request to override the persona's configuration." />
            </ModalBody>
            <ModalFooter className="flex justify-between">
              <Button disableRipple variant="light" radius="sm" color="default" onPress={onClose} as={Link} href="/dashboard/send-call" startContent={<ArrowLeft size={16} />}>
                Build a new persona
              </Button>
              <Button disableRipple variant="solid" radius="sm" color="primary" onPress={onClose} startContent={<Zap size={16} />}>
                Continue to Events
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  )
}
