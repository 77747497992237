const ENV = import.meta.env.VITE_VERCEL_TARGET_ENV ?? 'development'

const API_URL = (() => {
  switch (ENV) {
    case 'preview':
      return 'https://staging.api.bland.ai'
    case 'production':
      return 'https://api.bland.ai'
    case 'canada':
      return 'https://canada.api.bland.ai'
    default:
      return 'http://localhost:3000'
  }
})()

const FILE_UPLOAD_URL = ENV === 'development' ? 'http://localhost:8787' : API_URL

const WS_URL = (() => {
  switch (ENV) {
    case 'development':
      return 'ws://localhost:3000'
    case 'staging':
      return 'wss://api.staging.bland.ai'
    default:
      return undefined
  }
})()

const BILLING_ENDPOINT = `${API_URL}/billing`

const config = {
  API_URL,
  WS_URL,
  BILLING_ENDPOINT,
  FILE_UPLOAD_URL,
}

export default config
