import { Alert, AlertDescription } from '@/components/ui/alert'
import { Button } from '@/components/ui/button'
import config from 'config'
import { Lightbulb, Loader2 } from 'lucide-react'
import { forwardRef, useEffect, useRef, useState } from 'react'
import { HighlightWithinTextarea } from 'react-highlight-within-textarea'
import { getAuthToken } from 'utils/funcs/browser/getAuthToken'
import { getOrgId } from 'utils/funcs/browser/getOrgId'

function withSuggestion(WrappedComponent) {
  const orgId = getOrgId()
  return forwardRef((props, ref) => {
    const [suggestion, setSuggestion] = useState(null)
    const [value, setValue] = useState(props.value || '')
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(null)
    const containerRef = useRef(null)

    useEffect(() => {
      setValue(props.value || '')
    }, [props.value])

    const getSuggestion = async () => {
      setIsLoading(true)
      setError(null)
      try {
        const response = await fetch(
          `${config.API_URL}/v1/pathway/improvePrompt`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'authorization': getAuthToken(),
              ...(orgId && { 'x-bland-org-id': orgId }),
            },
            body: JSON.stringify({
              prompt: value,
            }),
          },
        )

        const data = await response.json()

        if (data.status === 'success') {
          setSuggestion({
            original: value,
            improved: data.response,
          })
        }
        else {
          throw new Error('Failed to get suggestion')
        }
      }
      catch (err) {
        setError('Failed to fetch suggestion. Please try again.')
      }
      finally {
        setIsLoading(false)
      }
    }

    const applySuggestion = () => {
      const newValue = suggestion.improved
      setValue(newValue)
      setSuggestion(null)
      if (props.onChange) {
        props.onChange(newValue)
      }
    }

    const highlightRegex = /\{\{.*?\}\}/g

    const handleChange = (newValue) => {
      setValue(newValue)
      if (props.onChange) {
        props.onChange(newValue)
      }
    }

    const textareaRef = useRef(null)

    // enable entire div to be clickable to focus textarea
    const handleDivClick = () => {
      if (textareaRef.current) {
        textareaRef.current.focus()

        console.log('textareaRef.current', textareaRef.current)
        if (textareaRef.current.setToLastBlock) {
          textareaRef.current.setToLastBlock()
        }
      }
    }

    return (
      <div className="space-y-1.5">
        <div className="flex justify-between mb-1.5">
          <div>Prompt:</div>
        </div>
        <div
          onClick={handleDivClick}
          ref={containerRef}
          className={`relative border border-gray-300 rounded ${props.className}`}
          style={{
            minHeight: '150px',
            maxHeight: '300px',
            overflow: 'auto',
            resize: 'vertical',
            padding: '0.31rem',
            cursor: 'text',
          }}
        >
          <HighlightWithinTextarea
            ref={textareaRef}
            value={typeof value === 'string' ? value : ''}
            onChange={handleChange}
            highlight={[
              {
                highlight: highlightRegex,
                className: 'bg-blue-100',
              },
            ]}
            placeholder={props.placeholder}
          />
        </div>

        {error && (
          <Alert variant="destructive">
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}

        {suggestion && (
          <div className="bg-white p-2.5 rounded mt-1.5 border border-gray-200 shadow-sm">
            <h4 className="text-xs font-semibold mb-1.5 text-gray-700">
              Improved Prompt
            </h4>
            <div
              className="max-h-24 overflow-y-auto"
              style={{
                fontSize: '0.63rem',
                lineHeight: '0.78rem',
                whiteSpace: 'pre-wrap',
                wordBreak: 'break-word',
                fontFamily: 'inherit',
                border: '1px solid #e2e8f0',
                borderRadius: '0.23rem',
                padding: '0.31rem',
              }}
            >
              <HighlightWithinTextarea
                value={suggestion.improved}
                highlight={[
                  {
                    highlight: highlightRegex,
                    className: 'bg-blue-100',
                  },
                ]}
                readOnly
              />
            </div>
            <div className="flex justify-end space-x-1.5 mt-2.5">
              <Button
                onClick={() => setSuggestion(null)}
                size="sm"
                variant="ghost"
                className="text-gray-500 text-2xs"
              >
                Dismiss
              </Button>
              <Button
                onClick={applySuggestion}
                size="sm"
                variant="default"
                className="text-2xs bg-indigo-600 hover:bg-indigo-700"
              >
                Apply
              </Button>
            </div>
          </div>
        )}
      </div>
    )
  })
}

const TextAreaWithSuggestion = withSuggestion(HighlightWithinTextarea)

export { TextAreaWithSuggestion,  }
