import React, { useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import DynamicFormField from './DynamicFormField';

/**
 * A form component for rendering and handling action schemas
 * Uses react-hook-form to manage form state and validation
 * Updates parent state as fields change, no submit button needed
 */
const ActionSchemaForm = ({ 
  actionSchema, 
  initialValues = {}, 
  onUpdate
}) => {
  const methods = useForm({
    defaultValues: initialValues
  });
  
  useEffect(() => {
    const subscription = methods.watch((data) => {
      onUpdate(data);
    });
    
    return () => subscription.unsubscribe();
  }, [methods, onUpdate]);

  return (
    <FormProvider {...methods}>
      <div className="w-full">
        {actionSchema.title && (
          <div className="mb-4">
            <h3 className="text-md font-medium">{actionSchema.title || actionSchema.name}</h3>
            {actionSchema.description && (
              <p className="text-sm text-foreground-400">{actionSchema.description}</p>
            )}
          </div>
        )}
        
        <div className="flex flex-col gap-3">
          {actionSchema.fields?.map((field) => (
            <Controller
              key={field.id}
              name={field.path}
              control={methods.control}
              defaultValue={field.defaultValue || ''}
              rules={{ required: field.required }}
              render={({ field: controllerField }) => (
                <DynamicFormField
                  field={field}
                  value={controllerField.value}
                  onChange={(value) => controllerField.onChange(value)}
                />
              )}
            />
          ))}
        </div>
      </div>
    </FormProvider>
  );
};

export default ActionSchemaForm; 