import { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`

html {
  scroll-behavior: smooth;
}

/* custom stuff */
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust color and opacity as needed */
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.grid-row:hover .overlay {
  opacity: 1;
  visibility: visible;
}

.grid-row:hover .overlay * {
  opacity: 1;
  visibility: visible;
}

.dots {
  background-image: url(
    "data:image/svg+xml,%3Csvg width='5' height='5' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='10' cy='10' r='2' fill='%23C4C4C4'/%3E%3C/svg%3E%0A");
}

/* iOS Switch */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #c2c2c2;
  -webkit-transition: .1s;
  transition: .1s;
  border-radius: 34px;
  padding: 0px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  left: 1px;
  bottom: 1px;
  background-color: white;
  -webkit-transition: .1s;
  transition: .1s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #d4d000;
}

input:checked + .slider:before {
  -webkit-transform: translateX(24px);
  -ms-transform: translateX(24px);
  transform: translateX(24px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

::selection {
  background: rgba(67, 61, 255, 0.17);
}
::-moz-selection {
  background: rgba(67, 61, 255, 0.17);
}

  
`
