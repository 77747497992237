// CONVERTS:

// [
//    {
//       "key": "Hello",
//       "value": "Blandy"
//     },
//     {
//       "key": "Voice",
//       "value": "Agent"
//     }
//  ]

// TO

// {
// "Hello" : "Blandy",
// "Voice" : "Agent"
// }

export function AllValuesToObj(keyValues) {
  const parseValue = (val) => {
    if (!val)
      return {}

    try {
      if (typeof val === 'object' && val !== null) {
        return val
      }
      else if (typeof val === 'string') {
        try {
          console.log('val', val)
          return JSON.parse(val)
        }
        catch (error) {}
      }

      let cleaned = val
        .replace(/[^\x20-\x7E]/g, '')
        .replace(/\s+/g, '')
        .replace(/":"/g, '":"')
        .replace(/":\{/g, '":{')
        .replace(/\}"/g, '"}')

      if (
        (cleaned.startsWith('"') && cleaned.endsWith('"'))
        || (cleaned.startsWith('\'') && cleaned.endsWith('\''))
      ) {
        cleaned = cleaned.slice(1, -1)
      }

      if (!cleaned.startsWith('{')) {
        cleaned = `{${cleaned}}`
      }

      const jsonReady = cleaned
        .replace(/(['"])?(\w+)(['"])?:/g, '"$2":') // Ensure proper key quotes
        .replace(/'/g, '"') // Replace single quotes with double quotes

      return JSON.parse(jsonReady)
    }
    catch (error) {
      console.error('Failed to parse value:', val, error)
      return val
    }
  }

  if (!Array.isArray(keyValues) && typeof keyValues !== 'object') {
    // console.error("keyValues is not an array", keyValues);
    return {}
  }
  // if keyValues is an object already, leave it as is
  if (!Array.isArray(keyValues)) {
    // means its an object or null perhaps
    return keyValues
  }
  return keyValues.reduce((acc, param) => {
    // Handle the value parsing
    if (param.val !== undefined) {
      acc[param.key] = parseValue(param.val)
    }
    else {
      acc[param.key] = param.value
    }
    return acc
  }, {})
}

export function KeyValuesToObj(keyValues) {
  if (!Array.isArray(keyValues) && typeof keyValues !== 'object') {
    // console.error("keyValues is not an array", keyValues);
    return {}
  }
  // if keyValues is an object already, leave it as is
  if (!Array.isArray(keyValues)) {
    // means its an object or null perhaps
    return keyValues
  }
  return keyValues.reduce((acc, param) => {
    acc[param.key] = param.value
    return acc
  }, {})
}
