import { Card, CardContent } from '@/components/ui/card'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { Dialog, Transition } from '@headlessui/react'
import ReactPhoneInput from 'components/core/PhoneInput'
import config from 'config'
import { useAuth } from 'hooks/useAuth'
import { Building2, Link as LinkIcon, User, UserPlus } from 'lucide-react'
import { Fragment, useContext, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { CiSquareRemove } from 'react-icons/ci'
import { toast } from 'react-toastify'
import { getAuthToken } from 'utils/funcs/browser/getAuthToken'
import { getOrgId } from 'utils/funcs/browser/getOrgId'
import FlowContext from '../contextFlow'

function ShareModal({ graphID, setShowShareModal, graphName }) {
  const { orgs } = useAuth()

  const { selectedVersion } = useContext(FlowContext)

  const { user } = useAuth()
  const orgId = getOrgId()
  const [copied, setCopied] = useState(false)
  const [link, setLink] = useState('')
  const [isOpen, setIsOpen] = useState(true)
  const formMethods = useForm()
  const [accessUsers, setAccessUsers] = useState([])
  const [organizations, setOrganizations] = useState([])
  const [selectedOrg, setSelectedOrg] = useState('')

  useEffect(() => {
    const fetchAccessUsers = async () => {
      const response = await fetch(
        `${config.API_URL}/v1/pathway/${graphID}/access`,
        {
          method: 'GET',
          headers: {
            Authorization: getAuthToken(),
            ...(orgId && { 'x-bland-org-id': orgId }),
          },
        },
      )
      const data = await response.json()

      if (data.status === 'success') {
        setAccessUsers(data.data)
      }
    }

    fetchAccessUsers()

    setOrganizations(orgs)
  }, [graphID])

  useEffect(() => {
    if (graphID) {
      setLink(
        `${window.location.origin}/dashboard/convo-pathways?id=${graphID}`,
      )
    }
  }, [graphID])

  const deleteAccess = async (phone_number) => {
    const response = await fetch(
      `${config.API_URL}/v1/pathway/${graphID}/access`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': getAuthToken(),
          ...(orgId && { 'x-bland-org-id': orgId }),
        },
        body: JSON.stringify({
          phone_number,
        }),
      },
    )
    const data = await response.json()

    if (data.status === 'success') {
      toast.success(data.message, {
        position: 'bottom-right',
      })
      setAccessUsers(
        accessUsers.filter(u => u.phone_number !== phone_number),
      )
    }
    else {
      toast.error('Failed to remove user', {
        position: 'bottom-right',
      })
    }
  }

  const shareToOrganization = async () => {
    if (!selectedOrg) {
      toast.warn('Please select an organization', {
        position: 'bottom-right',
      })
      return
    }

    const response = await fetch(
      `${config.API_URL}/v1/pathway/${graphID}/share`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': getAuthToken(),
          ...(orgId && { 'x-bland-org-id': orgId }),
        },
        body: JSON.stringify({
          org_id: selectedOrg,
          pathway_version: selectedVersion?.version_number ?? 'production',
        }),
      },
    )

    if (response.status === 200) {
      toast.success('Pathway shared successfully with organization', {
        position: 'bottom-right',
      })
      setSelectedOrg('')
    }
    else {
      toast.error('Failed to share pathway with organization', {
        position: 'bottom-right',
      })
    }
  }

  const copyLink = () => {
    navigator.clipboard.writeText(link)
    setCopied(true)
    setTimeout(() => setCopied(false), 2000)
  }

  return (
    <FormProvider {...formMethods}>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setIsOpen(false)
            setShowShareModal(false)
            formMethods.reset()
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/30 backdrop-blur-sm" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-2.5">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-3xl transform overflow-hidden rounded-xl bg-white p-4 shadow-xl transition-all">
                  <Dialog.Title className="text-base font-semibold leading-4 text-gray-900 mb-4">
                    Share Pathway -
                    {' '}
                    {graphName}
                  </Dialog.Title>

                  <Tabs defaultValue="organizations" className="w-full">
                    <TabsList className="grid w-full grid-cols-2 mb-4">
                      <TabsTrigger
                        value="organizations"
                        className="flex items-center gap-1.5"
                      >
                        <Building2 size={16} />
                        Copy to organization
                      </TabsTrigger>
                      <TabsTrigger
                        value="users"
                        className="flex items-center gap-1.5"
                      >
                        <UserPlus size={16} />
                        Collaborate on this pathway
                      </TabsTrigger>
                    </TabsList>

                    <TabsContent value="users">
                      <Card>
                        <CardContent className="space-y-2.5 pt-4">
                          <div className="bg-blue-50 p-2.5 rounded-md">
                            <p className="text-xs text-blue-700">
                              Invite users to collaborate on this pathway. They
                              will be able to view and edit this exact pathway
                              with you in real-time.
                            </p>
                          </div>
                          <div className="flex gap-2 items-center">
                            <div className="flex-grow">
                              <ReactPhoneInput fieldName="phone" />
                            </div>

                            <button
                              onClick={() => {
                                const sharePathway = async () => {
                                  if (!formMethods.getValues().phone) {
                                    toast.warn(
                                      'Please enter a valid phone number',
                                    )
                                    return
                                  }
                                  const response = await fetch(
                                    `${config.API_URL}/v1/pathway/${graphID}/access`,
                                    {
                                      method: 'POST',
                                      headers: {
                                        'Content-Type': 'application/json',
                                        'Authorization': getAuthToken(),
                                        ...(orgId && {
                                          'x-bland-org-id': orgId,
                                        }),
                                      },
                                      body: JSON.stringify({
                                        phone_number:
                                          `+${formMethods.getValues().phone}`,
                                      }),
                                    },
                                  )
                                  const data = await response.json()
                                  if (data.status === 'success') {
                                    toast.success(data.message)
                                    formMethods.reset()
                                    setAccessUsers([
                                      ...accessUsers,
                                      {
                                        name: data.data.name,
                                        phone_number: data.data.phone_number,
                                      },
                                    ])
                                  }
                                  else {
                                    toast.error('Failed to share pathway')
                                  }
                                }
                                sharePathway()
                              }}
                              className="flex items-center justify-center px-4 py-1.5 h-[40px] mt-[-36px] bg-indigo-600 text-white rounded hover:bg-indigo-700 transition-colors"
                            >
                              Add
                            </button>
                          </div>

                          <div className="mt-4">
                            <h4 className="text-xs font-medium text-gray-900 mb-2">
                              Current collaborators
                            </h4>
                            <div className="space-y-1.5">
                              {accessUsers.map(user => (
                                <div
                                  key={user.phone_number}
                                  className="flex items-center justify-between py-1.5 px-2 bg-gray-50 rounded-md"
                                >
                                  <div className="flex items-center gap-1.5">
                                    <User size={16} className="text-gray-400" />
                                    <span className="text-xs">{user.name}</span>
                                  </div>
                                  <button
                                    onClick={() =>
                                      deleteAccess(user.phone_number)}
                                    className="text-gray-400 hover:text-red-500 transition-colors"
                                  >
                                    <CiSquareRemove size={20} />
                                  </button>
                                </div>
                              ))}
                              <div className="flex items-center gap-1.5 py-1.5 px-2 bg-gray-50 rounded-md">
                                <User size={16} className="text-gray-400" />
                                <span className="text-xs">
                                  {user.user.first_name}
                                  {' '}
                                  {user.user.last_name}
                                  {' '}
                                  (you)
                                </span>
                              </div>
                            </div>
                          </div>
                        </CardContent>
                      </Card>
                    </TabsContent>

                    <TabsContent value="organizations">
                      <Card>
                        <CardContent className="space-y-2.5 pt-4">
                          <div className="bg-blue-50 p-2.5 rounded-md">
                            <p className="text-xs text-blue-700">
                              Create a copy of this pathway in another
                              organization's account. This will be a separate
                              pathway that can be modified independently of the
                              original.
                            </p>
                          </div>

                          <div className="space-y-2.5">
                            <div className="space-y-1.5">
                              <label className="text-xs font-medium text-gray-700">
                                Select Destination Organization
                              </label>
                              <Select
                                value={selectedOrg}
                                onValueChange={setSelectedOrg}
                              >
                                <SelectTrigger className="py-4">
                                  <SelectValue placeholder="Select an organization" />
                                </SelectTrigger>
                                <SelectContent className="py-2.5">
                                  {organizations.map(org => (
                                    <SelectItem
                                      key={org.org_id}
                                      value={org.org_id}
                                      disabled={org.org_id === orgId}
                                    >
                                      {org.org_display_name}
                                    </SelectItem>
                                  ))}
                                </SelectContent>
                              </Select>
                            </div>

                            <button
                              onClick={shareToOrganization}
                              className="w-full px-2.5 py-1.5 text-xs font-medium text-indigo-600 bg-indigo-50 rounded hover:bg-indigo-100 transition-colors"
                            >
                              Copy to Organization
                            </button>
                          </div>
                        </CardContent>
                      </Card>
                    </TabsContent>
                  </Tabs>

                  <div className="flex justify-between items-center mt-4">
                    <button
                      onClick={copyLink}
                      className="flex items-center gap-1.5 px-2.5 py-1.5 text-xs font-medium text-gray-700 bg-gray-100 rounded hover:bg-gray-200 transition-colors"
                    >
                      <LinkIcon size={16} />
                      {copied ? 'Link Copied!' : 'Copy Link'}
                    </button>

                    <button
                      onClick={() => setIsOpen(false)}
                      className="px-2.5 py-1.5 text-xs font-medium text-gray-600 hover:text-gray-800 transition-colors border border-gray-200 rounded hover:bg-gray-100"
                    >
                      Done
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </FormProvider>
  )
}

export default ShareModal
