import { Card } from '@/components/ui/card'
import { Input } from '@/components/ui/input'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip'
import { cn } from '@/lib/utils'
import { AnimatePresence, motion } from 'framer-motion'
import { Loader, RotateCcw, SendHorizonal, X, Zap } from 'lucide-react'
import { useContext, useRef, useState } from 'react'
import { useKeyPress } from 'reactflow'
import { $fetch } from 'utils/fetch'
import FlowContext from '../contextFlow'

function DiffView({ oldText, newText }) {
  const [isPreviousExpanded, setIsPreviousExpanded] = useState(false)
  const [isSuggestedExpanded, setIsSuggestedExpanded] = useState(false)

  const truncateText = (text, maxLength = 250) => {
    if (text.length <= maxLength)
      return text
    return `${text.slice(0, maxLength)}...`
  }
  return (
    <div className="space-y-4 font-mono text-sm">
      <div className="space-y-1">
        <div
          className="text-xs font-medium text-gray-500 uppercase tracking-wider flex items-center justify-between cursor-pointer hover:text-gray-700"
          onClick={() => setIsPreviousExpanded(!isPreviousExpanded)}
        >
          <span>Previous</span>
          <span className="text-xs">{isPreviousExpanded ? '▼' : '▶'}</span>
        </div>
        <div className="p-3 bg-red-50/50 border border-red-100 rounded-md">
          <span className="text-gray-800/75">
            {isPreviousExpanded ? oldText : truncateText(oldText)}
          </span>
        </div>
      </div>

      <div className="relative">
        <div className="absolute left-1/2 -translate-x-1/2 -translate-y-1/2 w-6 h-6 bg-white rounded-full border border-gray-200 flex items-center justify-center">
          <span className="text-gray-400">→</span>
        </div>
      </div>

      <div className="space-y-1">
        <div
          className="text-xs font-medium text-gray-500 uppercase tracking-wider flex items-center justify-between cursor-pointer hover:text-gray-700"
          onClick={() => setIsSuggestedExpanded(!isSuggestedExpanded)}
        >
          <span>Suggested</span>
          <span className="text-xs">{isSuggestedExpanded ? '▼' : '▶'}</span>
        </div>
        <div className="p-3 bg-emerald-50/50 border border-emerald-100 rounded-md">
          <span className="text-gray-800">
            {isSuggestedExpanded ? newText : truncateText(newText)}
          </span>
        </div>
      </div>
    </div>
  )
}

function InlineEditor({
  type,
  textAreaValue,
  setTextAreaValue,
  triggerRef,
  buttonClassName = 'absolute top-2 right-2',
  popupClassName = 'absolute -top-[100px] left-0 w-[450px]',
}) {
  const [prompt, setPrompt] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [suggestedText, setSuggestedText] = useState('')
  const [showDiff, setShowDiff] = useState(false)
  const [chatHistory, setChatHistory] = useState([])
  const [hasCopilotBeenUsed, setHasCopilotBeenUsed] = useState(false)
  const previousValueRef = useRef(textAreaValue)
  const { id: pathwayId, selectedVersion } = useContext(FlowContext)

  const onSubmit = async () => {
    setIsLoading(true)
    try {
      const data = await $fetch('/v1/pathway/copilot/inline', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          prompt,
          pathway_id: pathwayId,
          pathway_version: selectedVersion.version_number,
          chat_history: chatHistory,
          type,
          textAreaValue,
        }),
      })

      if (data.errors) {
        throw new Error(data.errors)
      }

      setChatHistory([
        ...chatHistory,
        { role: 'user', content: prompt },
        { role: 'assistant', content: data.data },
      ])
      setSuggestedText(data.data)
      setShowDiff(true)
    }
    catch (error) {
      console.error('Error:', error)
    }
    finally {
      setIsLoading(false)
    }
  }

  const handleAccept = () => {
    previousValueRef.current = textAreaValue
    setTextAreaValue(suggestedText)
    setHasCopilotBeenUsed(true)
    setIsOpen(false)
    setShowDiff(false)
  }

  const handleReject = () => {
    setShowDiff(false)
    setSuggestedText(null)
  }

  const handleUndo = () => {
    setTextAreaValue(previousValueRef.current)
    setIsOpen(false)
  }

  useKeyPress('cmd+k', (e) => {
    e.preventDefault()
    setIsOpen(true)
  })

  const handleSubmit = async () => {
    if (!prompt.trim())
      return
    await onSubmit()
    setPrompt('')
  }
  if (!isOpen) {
    return (
      <div className={cn('flex gap-2', buttonClassName)}>
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <button
                type="button"
                onClick={(e) => {
                  e.preventDefault()
                  setIsOpen(true)
                }}
                ref={triggerRef}
                className="p-2 rounded-full bg-gradient-to-r from-violet-500 via-indigo-600 to-violet-900 text-white shadow-lg hover:shadow-xl transform hover:scale-105 transition-all duration-200 group"
              >
                <Zap className="w-5 h-5 group-hover:rotate-12 transition-transform duration-200" />
              </button>
            </TooltipTrigger>
            <TooltipContent className="bg-slate-900 text-white z-[600]">
              <div className="flex items-center gap-2">
                <span>Copilot</span>
              </div>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>

        {hasCopilotBeenUsed && previousValueRef.current !== textAreaValue && (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <button
                  type="button"
                  onClick={handleUndo}
                  className="p-2 rounded-full bg-gray-100 hover:bg-gray-200 transition-colors"
                >
                  <RotateCcw className="w-4 h-4" />
                </button>
              </TooltipTrigger>
              <TooltipContent className="bg-slate-900 text-white z-[600]">
                <span>Undo Copilot </span>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        )}
      </div>
    )
  }
  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        transition={{ duration: 0.2 }}
        className="relative z-[10000000000000]"
      >
        <Card
          className={cn(
            'p-3 z-50 bg-white/90 backdrop-blur-sm shadow-2xl border border-gray-200/50 rounded-xl resize overflow-auto',
            popupClassName,
            showDiff ? 'w-[800px]' : '',
          )}
          style={{
            minWidth: showDiff ? '500px' : '250px',
            maxWidth: showDiff ? '800px' : '500px',
            minHeight: showDiff ? '150px' : 'auto',
            height: 'auto',
          }}
        >
          <div className="flex items-center justify-between mb-2">
            <div className="flex items-center gap-2">
              <div className="p-1.5 bg-gradient-to-r from-violet-500 via-indigo-600 to-violet-900 rounded-full">
                {isLoading
                  ? (
                      <Loader className="w-4 h-4 text-white animate-spin" />
                    )
                  : (
                      <Zap className="w-4 h-4 text-white" />
                    )}
              </div>
              <span className="text-sm font-medium text-gray-700">
                {type}
                {' '}
                Copilot
                <span className="text-xs text-gray-500 ml-2 italic">
                  (Fine-tuned on
                  {' '}
                  {type}
                  {' '}
                  examples)
                </span>
              </span>
            </div>
            <button
              type="button"
              onClick={() => setIsOpen(false)}
              className="p-1.5 hover:bg-gray-100 rounded-full transition-colors duration-200"
            >
              <X className="w-4 h-4 text-gray-500" />
            </button>
          </div>

          {!showDiff
            ? (
                <div className="flex gap-2 items-center justify-center">
                  <div className="relative flex-1">
                    <Input
                      value={prompt}
                      onChange={e => setPrompt(e.target.value)}
                      placeholder="Ask about your prompt or make changes... (Press Enter to submit)"
                      className="border-2 focus:ring-2 focus:ring-purple-500 focus:border-transparent transition-all duration-200 pr-8"
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          e.preventDefault()
                          handleSubmit()
                        }
                        if (e.key === 'Escape') {
                          setIsOpen(false)
                        }
                      }}
                      disabled={isLoading}
                      autoFocus
                    />
                    {prompt && (
                      <button
                        type="button"
                        onClick={() => setPrompt('')}
                        className="absolute right-2 top-1/2 -translate-y-1/2 p-1 text-gray-400 hover:text-gray-600"
                      >
                        <X className="w-4 h-4" />
                      </button>
                    )}
                  </div>
                  <button
                    type="button"
                    onClick={handleSubmit}
                    disabled={isLoading}
                    className="p-2 text-violet-600 hover:bg-violet-50 rounded-md transition-colors duration-150 disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    <SendHorizonal className="w-4 h-4" />
                  </button>
                </div>
              )
            : (
                <div className="mt-4 space-y-4">
                  <DiffView oldText={textAreaValue} newText={suggestedText} />
                  <div className="flex items-center justify-center gap-2">
                    <label className="text-gray-500 italic">Follow-up instructions:</label>
                    <div className="relative flex-1">
                      <Input
                        value={prompt}
                        onChange={e => setPrompt(e.target.value)}
                        placeholder="Enter follow-up instructions... (Press Enter to submit)"
                        className="border-2 focus:ring-2 focus:ring-purple-500 focus:border-transparent transition-all duration-200 h-8 pr-8"
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            e.preventDefault()
                            handleSubmit()
                          }
                          if (e.key === 'Escape') {
                            setIsOpen(false)
                          }
                        }}
                        disabled={isLoading}
                      />
                      {prompt && (
                        <button
                          type="button"
                          onClick={() => setPrompt('')}
                          className="absolute right-2 top-1/2 -translate-y-1/2 p-1 text-gray-400 hover:text-gray-600"
                        >
                          <X className="w-4 h-4" />
                        </button>
                      )}
                    </div>
                    <button
                      type="button"
                      onClick={handleSubmit}
                      disabled={isLoading}
                      className="p-2 text-violet-600 hover:bg-violet-50 rounded-md transition-colors duration-150 disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                      <SendHorizonal className="w-4 h-4" />
                    </button>
                  </div>
                  <div className="flex items-center justify-end gap-2 pt-2">
                    <button
                      type="button"
                      onClick={handleReject}
                      className="px-3 py-1.5 text-sm font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-100 rounded-md transition-colors duration-150"
                    >
                      Discard
                    </button>
                    <button
                      type="button"
                      onClick={handleAccept}
                      className="px-3 py-1.5 text-sm font-medium text-white bg-violet-600 hover:bg-violet-700 rounded-md transition-colors duration-150"
                    >
                      Apply changes
                    </button>
                  </div>
                </div>
              )}
        </Card>
      </motion.div>
    </AnimatePresence>
  )
}

export default InlineEditor
