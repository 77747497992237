import { FaMobileScreenButton, FaPhoneVolume } from 'react-icons/fa6'
import { FcEndCall } from 'react-icons/fc'
import { LuCalendar, LuMegaphone } from 'react-icons/lu'
import { MdCallSplit, MdOutlineTextsms, MdOutlineWebhook } from 'react-icons/md'
import { SiKnowledgebase } from 'react-icons/si'
import { VscDebugStart } from 'react-icons/vsc'

function NodeIcon({ type, isStart, isGlobal }) {
  const typeIconMap = {
    'Start': <VscDebugStart size={12} />,
    'Default': <FaPhoneVolume size={12} />,
    'KnowledgeBase': <SiKnowledgebase size={12} />,
    'Vector DB Knowledge Base': <SiKnowledgebase size={12} />,
    'Transfer Call': <MdCallSplit size={12} />,
    'Webhook': <MdOutlineWebhook size={12} />,
    'End Call': <FcEndCall size={14} />,
    'SMS': <MdOutlineTextsms size={12} />,
    'Press Button': <FaMobileScreenButton size={12} />,
    'Schedule Meeting': <LuCalendar size={12} />,
  }

  let icon = <LuMegaphone size={12} />

  icon = isStart ? typeIconMap.Start : typeIconMap[type] || icon

  return (
    <div
      className={` border border-slate-900 w-[24px] h-[24px] shadow-inner shadow-sm shadow-slate-300 flex items-center justify-center ${isGlobal ? 'bg-yellow-200' : ''}`}
    >
      {icon}
    </div>
  )
}

export default NodeIcon
