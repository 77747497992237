import { BookIcon } from 'assets/icons/navigationIcons'
import ActionBar from 'components/core/ActionBar'
import Button from 'components/core/Button'
import { PageTitle } from 'components/core/PageTitle'
import { PageWrapper } from 'components/core/PageWrapper'
import config from 'config'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { getApiKey } from 'utils/funcs/browser/getApiKey'
import { getOrgId } from 'utils/funcs/browser/getOrgId'
import CloseIcon from '../../../assets/icons/close-icon-black.svg'
import CodeIcon from '../../../assets/icons/code-icon.svg'
import CopyIcon from '../../../assets/icons/copy-icon.svg'
import ToolIcon from '../../../assets/icons/tool-icon.svg'
import ToolDefaultIcon from '../../../assets/images/tool-default.png'
import ModalInput from './components/Input'
import KeyValues from './components/KeyValues'
import RevisedCodeEditor from './components/RevisedCodeEditor'

const initTool = {
  created_at: null,
  tool_id: '',
  name: '',
  description: '',
  speech: '',
  url: '',
  method: 'GET', // default
  headers: [
    {
      key: 'Content-Type',
      value: 'application/json',
    },
  ],
  body: [{ key: 'Name', value: '{{input.name}}' }],
  query: [{ '': '' }],
  input_schema: {},
  response: {},
  example: [
    {
      '': '',
    },
  ],
  properties: [
    {
      '': {
        type: 'string',
        example: '',
      },
    },
  ],
  timeout: 0,
}

export default function RevisedTools() {
  // const [anchorEl, setAnchorEl] = useState(null);
  // const open = Boolean(anchorEl);
  // const id = open ? 'simple-popover' : undefined;
  const navigation = useNavigate()
  const apiKey = getApiKey()
  const orgId = getOrgId()
  const [focusedTool, setFocusedTool] = useState(initTool)
  const [tools, setTools] = useState([])
  const [timeout, setTimeout] = useState(false)
  const [queryParams, setQueryParams] = useState(false)
  const [testResults, setTestResults] = useState(null)
  const [showCodeEditor, setShowCodeEditor] = useState(true)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [loadingTesting, setLoadingTesting] = useState(false)
  const [loadingCreating, setLoadingCreating] = useState(false)
  const [showAllTools, setShowAllTools] = useState(false)

  const handleGetTools = async () => {
    try {
      const response = await fetch(`${config.API_URL}/v1/tools`, {
        method: 'GET',
        headers: {
          cache: 'no-cache',
          authorization: apiKey,
          ...(orgId && { 'x-bland-org-id': orgId }),
        },
      })
      if (response.status === 200) {
        const data = await response.json()
        if (data?.tools?.length > 0) {
          // setFocusedTool(data.tools[0]);
          data.tools.sort(
            (a, b) => new Date(b.created_at) - new Date(a.created_at),
          )
          setTools(data.tools)
        }
      }
    }
    catch (error) {}
  }

  const updateKeyOnFocusedTool = (key, value) => {
    setFocusedTool(prev => ({
      ...prev,
      [key]: value,
    }))
  }

  const mapPropertiesToBody = (properties) => {
    if (focusedTool.method === 'GET')
      return []
    const obj = []
    properties?.forEach((property) => {
      obj.push({
        key: property.key,
        value: `{{input.${property.key}}}`,
      })
    })

    return obj
  }

  const mapPropertiesToExample = (properties) => {
    const obj = []
    properties?.forEach((property) => {
      obj.push({
        key: property.key,
        value: property.example,
      })
    })

    return obj
  }

  const appendQueryValuesToUrl = () => {
    if (!queryParams)
      return focusedTool.url
    if (focusedTool.query.length === 0)
      return focusedTool.url
    if (
      focusedTool.query.length === 1
      && focusedTool.query[0].key === ''
      && focusedTool.query[0].value === ''
    ) {
      return focusedTool.url
    }

    let queryPath = ''
    focusedTool.query?.forEach((query, index) => {
      if (query.key === '' || query.value === '')
        return
      queryPath += `${index === 0 ? '?' : '&'}${query.key}=${query.value}`
    })

    return focusedTool.url + (queryPath ? `${queryPath}` : '')
  }

  const arrayToObject = (array) => {
    const obj = {}
    array?.forEach((item, index) => {
      obj[item.key] = item.value
    })

    return { ...obj }
  }

  const objectToArray = (obj) => {
    const array = []
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        array.push({ key, value: obj[key] })
      }
    }
    return array
  }

  const getFormattedDateTime = (date = null) => {
    const formattedDate = date ? new Date(date) : new Date()
    return formattedDate.toLocaleString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    })
  }

  const validateToolName = (inputText) => {
    const invalidToolNames = [
      'input',
      'speak',
      'transfer',
      'switch',
      'wait',
      'finish',
      'press',
      'button',
      'say',
      'pause',
      'record',
      'play',
      'dial',
      'hang',
    ]

    return invalidToolNames.includes(inputText)
  }

  const handleFormatToolData = () => {
    const object = {
      name: focusedTool.name,
      description: focusedTool.description,
      url: focusedTool.url,
      method: focusedTool.method,
      headers: arrayToObject(focusedTool.headers),
      query: arrayToObject(focusedTool.query),
      input_schema: {
        example: arrayToObject(mapPropertiesToExample(focusedTool.properties)),
        properties: arrayToObject(focusedTool.properties),
      },
    }

    if (focusedTool.speech)
      object.speech = focusedTool.speech
    if (focusedTool.method === 'POST' && focusedTool.body) {
      const properties = focusedTool.properties
      const mappedBody = mapPropertiesToBody(properties)
      object.body = arrayToObject(mappedBody)
    }

    return object
  }

  const handleTestTool = async () => {
    setLoadingTesting(true)
    setTestResults(null)
    try {
      const options = {
        method: focusedTool.method,
        headers: arrayToObject(focusedTool.headers),
      }

      if (focusedTool.method === 'POST') {
        options.body = JSON.stringify(
          arrayToObject(mapPropertiesToExample(focusedTool.properties)),
        )
      }

      // focusedTool.url
      const response = await fetch(focusedTool.url, options)
      const data = await response.json()

      setLoadingTesting(false)
      return setTestResults({
        status: response.status,
        data,
      })
    }
    catch (error) {
      console.log(error)
      setLoadingTesting(false)
      return toast.error('An error occurred while testing the tool.')
    }
  }
  const handleUpdateTool = async () => {
    try {
      setLoadingCreating(true)

      if (validateToolName(focusedTool?.name)) {
        setLoadingCreating(false)
        return toast.error('Tool name is invalid.')
      }

      if (focusedTool?.name === '') {
        setLoadingCreating(false)
        return toast.error('Tool must include a name.')
      }

      if (focusedTool?.description === '') {
        setLoadingCreating(false)
        return toast.error('Tool must include a description.')
      }

      if (focusedTool?.url === '' || !focusedTool.url.includes('https://')) {
        setLoadingCreating(false)
        return toast.error('Tool must include a valid URL.')
      }

      const data = handleFormatToolData() // already string;

      const response = await fetch(
        `${config.API_URL}/v1/tools/${focusedTool?.tool_id}`,
        {
          method: 'POST',
          headers: {
            'authorization': apiKey,
            'Content-Type': 'application/json',
            ...(orgId && { 'x-bland-org-id': orgId }),
          },
          body: JSON.stringify(data),
        },
      )

      if (response.status === 200) {
        // const resData = await response.json();
        await handleGetTools()
        setLoadingCreating(false)
        return toast.success('Tool updated successfully.')
      }
      else {
        setLoadingCreating(false)
        return toast.error('An error occurred while updating the tool.')
      }
    }
    catch (error) {
      console.log(error)
      setLoadingCreating(false)
      return toast.error('An error occurred while updating the tool.')
    }
  }

  const handleSubmitTool = async () => {
    try {
      setLoadingCreating(true)

      if (validateToolName(focusedTool?.name)) {
        setLoadingCreating(false)
        return toast.error('Tool name is invalid.')
      }

      if (focusedTool?.name === '') {
        setLoadingCreating(false)
        return toast.error('Tool must include a name.')
      }

      if (focusedTool?.description === '') {
        setLoadingCreating(false)
        return toast.error('Tool must include a description.')
      }

      if (focusedTool?.url === '' || !focusedTool.url.includes('https://')) {
        setLoadingCreating(false)
        return toast.error('Tool must include a valid URL.')
      }

      const data = handleFormatToolData() // already string;

      const response = await fetch(`${config.API_URL}/v1/tools`, {
        method: 'POST',
        headers: {
          'authorization': apiKey,
          'Content-Type': 'application/json',
          ...(orgId && { 'x-bland-org-id': orgId }),
        },
        body: JSON.stringify(data),
      })

      if (response.status === 200) {
        // const resData = await response.json();
        navigation('/dashboard/playground')
        setLoadingCreating(false)
        return toast.success('Tool saved successfully.')
      }
      else {
        setLoadingCreating(false)
        return toast.error('An error occurred while saving the tool.')
      }
    }
    catch (error) {
      console.log(error)
      setLoadingCreating(false)
      return toast.error('An error occurred while saving the tool.')
    }
  }

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
      if (window.innerWidth <= 1015 && showCodeEditor) {
        setShowCodeEditor(false)
      }
      else if (window.innerWidth >= 1014 && !showCodeEditor) {
        setShowCodeEditor(true)
      }
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    handleGetTools()
  }, [])

  return (
    <PageWrapper>
      <ActionBar top spaceBetween>
        <PageTitle>Tools</PageTitle>

        <div
          style={{
            marginLeft: 'auto',
            display: 'flex',
            flexDirection: 'row',
            columnGap: '10px',
            alignItems: 'center',
          }}
        >
          <Button
            onClick={() => setShowAllTools(!showAllTools)}
            appearance="outline"
            style={{ borderRadius: '6px', background: '#F9FAFB' }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                columnGap: '7px',
                alignItems: 'centers',
              }}
            >
              <img src={ToolIcon} alt="Code Icon" />
              <p
                style={{
                  margin: 0,
                  padding: 0,
                  fontSize: '13px',
                  fontWeight: '500',
                }}
              >
                {showAllTools ? 'Hide' : 'Show'}
                {' '}
                My Tools
              </p>
            </div>
          </Button>
          <Button
            onClick={() => setShowCodeEditor(!showCodeEditor)}
            appearance="outline"
            style={{ borderRadius: '6px', background: '#F9FAFB' }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                columnGap: '7px',
                alignItems: 'centers',
              }}
            >
              <img src={CodeIcon} alt="Code Icon" />
              <p
                style={{
                  margin: 0,
                  padding: 0,
                  fontSize: '13px',
                  fontWeight: '500',
                }}
              >
                {showCodeEditor ? 'Hide' : 'Show'}
                {' '}
                JSON
              </p>
            </div>
          </Button>
          <Button
            onClick={() => setFocusedTool(initTool)}
            appearance="outline"
            style={{ borderRadius: '6px', background: '#F9FAFB' }}
          >
            <p
              style={{
                margin: 0,
                padding: 0,
                fontSize: '13px',
                fontWeight: '500',
              }}
            >
              Clear Tool
            </p>
          </Button>
          <Button
            loading={loadingCreating}
            onClick={() => {
              if (tools?.find(doc => doc.tool_id === focusedTool?.tool_id)) {
                handleUpdateTool()
              }
              else {
                handleSubmitTool()
              }
            }}
            style={{ borderRadius: '6px' }}
          >
            <p
              style={{
                margin: 0,
                padding: 0,
                fontSize: '13px',
                fontWeight: '500',
              }}
            >
              Save Tool
            </p>
          </Button>
        </div>
      </ActionBar>

      <ToolInformationHeaderSection>
        <div style={{ width: '100%' }}>
          <div
            style={{
              padding: 2.79,
              height: '80px',
              width: '80px',
              background: '#F9FAFB',
              borderRadius: 14.88,
              border: '0.93px solid #D1D5DB',
            }}
          >
            <img
              src={ToolDefaultIcon}
              alt="Tool Icon"
              style={{
                width: '100px',
                height: '74.42px',
                borderRadius: '74.42px',
              }}
            />
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              columnGap: '15px',
              marginTop: '10px',
            }}
          >
            <p
              style={{
                margin: 0,
                padding: 0,
                fontSize: '22px',
                fontWeight: '550',
                lineHeight: '30px',
                color: '#000',
              }}
            >
              {focusedTool.name || 'Custom Tool'}
            </p>

            <div
              style={{
                border: '2px solid #655FFF',
                padding: '2px 8px 2px 8px',
                width: '70px',
                height: '24px',
                borderRadius: '4px',
                background: '#655FFF1A',
              }}
            >
              <p
                style={{
                  color: '#655FFF',
                  fontSize: '12px',
                  fontWeight: '500',
                  textAlign: 'center',
                }}
              >
                Private
              </p>
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginTop: '10px',
            }}
          >
            <p
              style={{
                margin: 0,
                padding: 0,
                fontSize: '16px',
                lineHeight: '20px',
                fontWeight: '400',
                color: '#4B5563',
              }}
            >
              {focusedTool?.description || 'Your custom tool description.'}
            </p>
            <p
              style={{
                margin: 0,
                padding: 0,
                fontSize: '13px',
                lineHeight: '20px',
                fontWeight: '400',
                color: '#4B5563',
              }}
            >
              Created At:
              {' '}
              {getFormattedDateTime(focusedTool?.created_at)
              || getFormattedDateTime()}
            </p>
          </div>
        </div>
      </ToolInformationHeaderSection>

      <div
        style={{
          width: '100%',
          height: 1,
          background: '#EEEEEE',
          marginBottom: '40px',
          marginTop: '30px',
        }}
      />

      <Wrapper>
        <ToolWrapper codeEditorOpen={showCodeEditor}>
          <ToolHeader>
            <div>
              <p
                style={{
                  margin: 0,
                  padding: 0,
                  fontSize: '16px',
                  fontWeight: '500',
                  lineHeight: '20px',
                  color: '#030712',
                }}
              >
                Build a Custom Tool
              </p>
              <p
                style={{
                  margin: 0,
                  padding: 0,
                  fontSize: '12px',
                  fontWeight: '400',
                  lineHeight: '18px',
                  marginTop: '5px',
                  color: '#4B5563',
                }}
              >
                Build a custom tool to interact with the real world mid-call.
              </p>
            </div>

            <div
              onClick={() =>
                window.open(
                  'https://docs.bland.ai/tutorials/custom-tools#custom-tool-example',
                  '_blank',
                )}
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                columnGap: '4px',
                cursor: 'pointer',
              }}
            >
              <BookIcon style={{ color: '#655FFF' }} />
              <p
                style={{
                  margin: 0,
                  padding: 0,
                  fontSize: '13px',
                  lineHeight: '20px',
                  fontWeight: '500',
                  color: '#655FFF',
                }}
              >
                Documentation
              </p>
            </div>
          </ToolHeader>

          <ToolBody>
            <div>
              <span
                style={{
                  fontSize: '12px',
                  lineHeight: '18px',
                  fontWeight: '400',
                  color: '#4b5563',
                }}
              >
                Information such tool name, description and speech, dictates how
                your tool operates and gets invoked,
                {' '}
                <a
                  href="https://docs.bland.ai/tutorials/custom-tools#custom-tool-example"
                  target="_blank"
                  style={{ color: '#655FFF', cursor: 'pointer' }}
                >
                  learn more.
                </a>
              </span>
            </div>

            <FormFieldWrapper>
              <ModalInput
                value={focusedTool.name}
                onChange={event =>
                  updateKeyOnFocusedTool('name', event.target.value)}
                label="Name"
                placeholder="User Profile Tool"
              />

              <Description>
                <p
                  style={{
                    margin: 0,
                    padding: 0,
                    fontSize: '12px',
                    fontWeight: '500',
                    lineHeight: '18px',
                    color: '#4B5563',
                  }}
                >
                  Description
                </p>
                <textarea
                  value={focusedTool.description}
                  onChange={event =>
                    updateKeyOnFocusedTool('description', event.target.value)}
                  style={{
                    width: '100%',
                    height: '100px',
                    maxHeight: '200px',
                    borderRadius: '6px',
                    border: '1px solid #E5E7EB',
                    padding: '10px',
                  }}
                  placeholder="A custom tool that retrieves the user's profile information including their name, email, and phone number."
                />
              </Description>

              <Speech>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    columnGap: '8px',
                    cursor: 'pointer',
                  }}
                >
                  <p
                    style={{
                      margin: 0,
                      padding: 0,
                      fontSize: '12px',
                      fontWeight: '500',
                      lineHeight: '18px',
                      color: '#4B5563',
                    }}
                  >
                    Speech
                  </p>
                  <p
                    style={{
                      margin: 0,
                      padding: 0,
                      fontSize: '11px',
                      fontWeight: '350',
                      lineHeight: '18px',
                      color: '#4B5563',
                    }}
                  >
                    Optional
                  </p>
                </div>
                <p
                  style={{
                    margin: 0,
                    padding: 0,
                    fontSize: '11px',
                    fontWeight: '400',
                    lineHeight: '18px',
                    color: '#4B5563',
                  }}
                >
                  This is the text that the AI will say while it uses the tool.
                  Since tools can be verbally interrupted, shorter messages that
                  tell the user what the tool/AI are doing are best.
                </p>
                <textarea
                  value={focusedTool.speech}
                  onChange={event =>
                    updateKeyOnFocusedTool('speech', event.target.value)}
                  style={{
                    width: '100%',
                    height: '100px',
                    maxHeight: '200px',
                    borderRadius: '6px',
                    border: '1px solid #E5E7EB',
                    padding: '10px',
                  }}
                  placeholder="Fetching the user's profile information now..."
                />
              </Speech>
            </FormFieldWrapper>
          </ToolBody>

          <ToolBodySeperator />

          <ToolBody>
            <div>
              <span
                style={{
                  fontSize: '12px',
                  lineHeight: '18px',
                  fontWeight: '400',
                  color: '#4b5563',
                }}
              >
                Brief information about how to setup an API for custom tool with
                a link to documentation or article to
                {' '}
                <a
                  href="https://docs.bland.ai/tutorials/custom-tools#custom-tool-example"
                  target="_blank"
                  style={{ color: '#655FFF', cursor: 'pointer' }}
                >
                  learn more.
                </a>
              </span>
            </div>

            <FormFieldWrapper>
              {/* <ModalInput value={appendQueryValuesToUrl()} onChange={(event) => updateKeyOnFocusedTool("url", event.target.value)} label="URL" placeholder="https://api.example.com/v1/user/profile" /> */}
              <ModalInput
                label="Method"
                value={appendQueryValuesToUrl()}
                placeholder="https://app.bland.ai/enterprise"
                methodDropdown
                submitBtn
                submitBtnLoading={loadingTesting}
                submitBtnOnClick={() => handleTestTool()}
                submitBtnLabel="Test"
                onChange={e =>
                  updateKeyOnFocusedTool('url', e.target.value?.split('?')[0])}
                methodDropdownOnChange={e =>
                  updateKeyOnFocusedTool('method', e.target.value)}
              />

              {testResults && (
                <TestResultWrapper>
                  <TestResultGrid>
                    <TestResultItem>
                      <p
                        style={{
                          margin: 0,
                          padding: 0,
                          fontSize: '12px',
                          fontWeight: '500',
                          lineHeight: '18px',
                          color: '#4B5563',
                        }}
                      >
                        Status
                      </p>
                      <p
                        style={{
                          margin: 0,
                          padding: 0,
                          fontSize: '12px',
                          fontWeight: '400',
                          lineHeight: '18px',
                          color: '#4B5563',
                        }}
                      >
                        {testResults.status}
                      </p>
                    </TestResultItem>

                    <TestResultItem>
                      <p
                        style={{
                          margin: 0,
                          padding: 0,
                          fontSize: '12px',
                          fontWeight: '500',
                          lineHeight: '18px',
                          color: '#4B5563',
                        }}
                      >
                        Response
                      </p>
                      <p
                        style={{
                          margin: 0,
                          padding: 0,
                          fontSize: '12px',
                          fontWeight: '400',
                          lineHeight: '18px',
                          color: '#4B5563',
                        }}
                      >
                        {JSON.stringify(testResults.data)}
                      </p>
                    </TestResultItem>
                  </TestResultGrid>
                </TestResultWrapper>
              )}

              <SwitchRow>
                <p
                  style={{
                    margin: 0,
                    padding: 0,
                    fontSize: '12px',
                    fontWeight: '500',
                    lineHeight: '18px',
                    color: '#4B5563',
                  }}
                >
                  Query Parameters
                </p>

                <SwitchBody
                  on={queryParams}
                  onClick={() => setQueryParams(!queryParams)}
                >
                  <Flicker on={queryParams} />
                </SwitchBody>
              </SwitchRow>

              {queryParams && (
                <KeyValues
                  style={{ marginTop: -10 }}
                  obj={focusedTool.query}
                  onChange={setFocusedTool}
                  nestedKey="query"
                />
              )}

              <SwitchRow>
                <p
                  style={{
                    margin: 0,
                    padding: 0,
                    fontSize: '12px',
                    fontWeight: '500',
                    lineHeight: '18px',
                    color: '#4B5563',
                  }}
                >
                  Timeout
                </p>
                <SwitchBody on={timeout} onClick={() => setTimeout(!timeout)}>
                  <Flicker on={timeout} />
                </SwitchBody>
              </SwitchRow>

              {timeout && (
                <TimeoutWrapper style={{ marginTop: -10 }}>
                  <ModalInput
                    placeholder="0 (seconds)"
                    value={focusedTool.timeout}
                    onChange={event =>
                      updateKeyOnFocusedTool(
                        'timeout',
                        Number(event.target.value),
                      )}
                  />
                </TimeoutWrapper>
              )}

              <KeyValues
                description="These are the properties that the tool will use to make the API call. Each property should have a type (description) and an example value."
                obj={focusedTool.properties}
                onChange={setFocusedTool}
                nestedKey="properties"
                isProperty
              />

              <KeyValues
                obj={focusedTool.headers}
                onChange={setFocusedTool}
                nestedKey="headers"
              />

              {focusedTool.method === 'POST' && (
                <KeyValues
                  description="These values are automatically set when using by Properties. These values are use to make the API call."
                  obj={mapPropertiesToBody(focusedTool.properties)}
                  onChange={setFocusedTool}
                  nestedKey="body"
                  isAllowedToAdd={false}
                />
              )}
            </FormFieldWrapper>
          </ToolBody>
        </ToolWrapper>

        {showCodeEditor && (
          <RevisedCodeEditor
            data={handleFormatToolData()}
            apiKey={apiKey}
            collapseCode={showCodeEditor}
          />
        )}

        {showAllTools && (
          <AllToolsSidebarWrapper>
            <AllToolsRightSidebar>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  padding: '20px 15px 0px 20px',
                }}
              >
                <p
                  style={{
                    margin: 0,
                    padding: 0,
                    fontSize: '14px',
                    fontWeight: '500',
                    lineHeight: '20px',
                    color: '#000',
                  }}
                >
                  All Tools
                </p>
                <img
                  onClick={() => setShowAllTools(!showAllTools)}
                  src={CloseIcon}
                  alt="Close Icon"
                  style={{ cursor: 'pointer' }}
                />
              </div>

              {tools?.length > 0 && (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  {tools?.map((tool, index) => (
                    <ToolItemSidebar
                      key={index}
                      style={{
                        cursor: 'pointer',
                        paddingBottom: '20px',
                        zIndex: 1,
                      }}
                    >
                      <div
                        style={{
                          width: '100%',
                          height: '1px',
                          background: '#eeeeee',
                        }}
                      />
                      <div
                        style={{
                          padding: '20px 15px 0px 20px',
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          columnGap: '20px',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            columnGap: '20px',
                            width: '90%',
                          }}
                          onClick={() => {
                            setFocusedTool({
                              ...tool?.tool,
                              tool_id: tool?.tool_id,
                              created_at: tool?.created_at,
                              headers: objectToArray(tool?.tool?.headers),
                              query: objectToArray(tool?.tool?.query),
                              example: objectToArray(
                                tool?.tool?.input_schema.example,
                              ),
                              properties:
                                objectToArray(
                                  tool?.tool?.input_schema?.properties,
                                ).length > 0
                                  ? objectToArray(
                                      tool.tool.input_schema.properties,
                                    )
                                  : [{ '': { type: 'string', example: '' } }],
                            })
                            setShowAllTools(false)
                          }}
                        >
                          <div
                            style={{
                              padding: 1.67,
                              height: '48px',
                              width: '48px',
                              background: '#F9FAFB',
                              borderRadius: 8.93,
                              border: '0.56px solid #D1D5DB',
                            }}
                          >
                            <img
                              src={ToolDefaultIcon}
                              alt="Tool Icon"
                              style={{
                                width: '100px',
                                height: '44.65px',
                                borderRadius: '44.65px',
                                borderRight: 8.93,
                              }}
                            />
                          </div>

                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                            }}
                          >
                            <p
                              style={{
                                margin: 0,
                                padding: 0,
                                fontSize: '14px',
                                fontWeight: '500',
                                lineHeight: '18px',
                              }}
                            >
                              {tool?.tool?.name || 'Tool'}
                            </p>
                            {' '}
                            {focusedTool.tool_id === tool?.tool_id && (
                              <p
                                style={{
                                  color: '#433DFF',
                                  margin: 0,
                                  padding: 0,
                                  fontSize: 13,
                                  fontWeight: '550',
                                  lineHeight: '18px',
                                  marginLeft: '10px',
                                }}
                              >
                                (Viewing)
                              </p>
                            )}
                          </div>
                        </div>

                        <div
                          onClick={() => {
                            navigator.clipboard.writeText(tool?.tool_id)
                            toast.info('Tool Id copied to clipboard.')
                          }}
                          style={{
                            marginLeft: 'auto',
                            padding: '4px',
                            borderRadius: '4px',
                            border: '1px solid #eeeeee',
                            zIndex: 20000,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: 90,
                          }}
                        >
                          <p
                            style={{
                              fontWeight: '500',
                              fontSize: 14,
                              margin: 0,
                              padding: 0,
                            }}
                          >
                            Tool Id
                          </p>
                          <img
                            src={CopyIcon}
                            alt="trash-icon"
                            height={14}
                            width={14}
                          />
                        </div>
                      </div>
                    </ToolItemSidebar>
                  ))}
                </div>
              )}

              {tools?.length === 0 && (
                <div style={{ padding: '20px 15px 20px 20px' }}>
                  <p
                    className="font-bold"
                    style={{ color: '#555', fontWeight: '500' }}
                  >
                    You haven't created any tools yet :/.
                  </p>
                </div>
              )}
            </AllToolsRightSidebar>
          </AllToolsSidebarWrapper>
        )}
      </Wrapper>
    </PageWrapper>
  )
}

const ToolItemSidebar = styled.div`
  background: #fff;
  &:hover {
    background: #f5f5f5;
  }
`

const AllToolsRightSidebar = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  height: auto !important;
  background: #fff;
  overflow-y: scroll;
  padding-bottom: 70.5px;
`
//    padding: 20px 15px 20px 20px;

const AllToolsSidebarWrapper = styled.div`
  width: 30%;
  max-width: 640px;
  min-width: 340px;
  display: flex;
  height: 100% !important;
  position: fixed;
  zindex: 1000;
  right: 0;
  top: 52.5px;
  box-shadow: -5px 0px 10px rgba(0, 0, 0, 0.1);
`

const ToolInformationHeaderSection = styled.div`
  display: flex;
  width: 100%;
`

const TestResultWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  padding: 10px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
`

const TestResultGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  width: 100%;
`

const TestResultItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  column-gap: 40px;
  justify-content: center;
`

const ToolWrapper = styled.div`
  border-radius: 8px;
  border: 1px solid #e5e7eb;
  width: 100%;
  max-width: ${({ codeEditorOpen }) => (codeEditorOpen ? '70%' : '884px')};
  min-width: 450px;
`

const Description = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`

const Speech = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`

const ToolBodySeperator = styled.div`
  height: 1px;
  background: #e5e7eb;
  margin: 0 16px 0 20px;
`

const ToolHeader = styled.div`
  height: 82px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px 16px 20px 20px;
  border-radius: 8px;
  background: #f9fafb;
`

const ToolBody = styled.div`
  padding: 32px 24px 32px 20px;
  border-radius: 8px;
  background: #ffffff;
  grid-template-columns: 30% 70%;
  display: grid;
  gap: 28px;
`

const FormFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-right: 20px;
`

const SwitchRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`

const SwitchBody = styled.div`
  transition: background-color 0.1s ease;
  background-color: ${({ on }) => (on ? '#433DFF' : '#f0f0ed')};
  position: relative;
  height: 20px;
  width: 35px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  padding: 3px;
  cursor: pointer;
`

const Flicker = styled.p`
  border-radius: 100px;
  width: 17.5px;
  height: 15px;
  background-color: white;
  position: absolute;
  transition: transform 0.15s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  right: ${({ on }) => (on ? '3px' : 'auto')};
`

const TimeoutWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`
