import { TooltipText } from '_components/Text'
import RangeInput from 'components/core/RangeInput'
import { useFormContext, useWatch } from 'react-hook-form'

export default function Temperature({ fieldName }) {
  const currTemp = useWatch({
    name: fieldName,
    control: useFormContext().control,
  })

  const description = (
    <span>
      A value between 0 and 1 that controls the randomness of the LLM. 0 will
      cause more deterministic outputs while 1 will cause more random.
      <br />
      Example Values:
      {' '}
      <b>"0.9", "0.3", "0.5"</b>
    </span>
  )

  return (
    <RangeInput
      fieldName={fieldName}
      label={(
        <TooltipText tooltip={description} tooltipClassName="w-[300px]">
          Temperature:
          {' '}
          {currTemp}
        </TooltipText>
      )}
      min="0"
      max="1"
      step="0.1"
      value={currTemp}
      sliderValue={currTemp}
      type="range"
      id="tempSlider"
    />
  )
}
