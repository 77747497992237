import { useState, useEffect } from 'react';
import { Badge, Button, Spinner, Tooltip } from '@radix-ui/themes';
import {Button as HeroUIButton} from "@heroui/react"
import { ExternalLink, GitBranch, MessageSquare, Phone } from 'lucide-react';
import TempExamplePWImage from 'assets/images/ExamplePW.png';
import styled from 'styled-components';
import config from 'config';
import { $fetch } from 'utils/fetch';
import { getApiKey } from 'utils/funcs/browser/getApiKey';
import { getOrgId } from 'utils/funcs/browser/getOrgId';
import { toDtmy } from 'utils/formatting/toDtmy';
import { Loading } from 'components/core/Loading';
import InboundNumberDropdown from '../shared/InboundNumberDropdown';

const PreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
`;

const PathwayCard = styled.div`
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  background-color: #f9f9f9;
  overflow: hidden;
`;

const PathwayHeader = styled.div`
  padding: 16px;
  border-bottom: 1px solid #e0e0e0;
  background-color: #ffffff;
`;

const PathwayContent = styled.div`
  padding: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const VersionBox = styled.div`
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  overflow: hidden;
`;

const VersionItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid #e0e0e0;
  background-color: white;
`;

// Add this styled component for consistent badge styling
const StyledBadge = styled(Badge)`
  font-size: 10px;
  padding: 0 6px;
  height: 18px;
  line-height: 18px;
  display: inline-flex;
  align-items: center;
`;

const SMSPathwayPreview = ({ pathwayId }) => {
  const orgId = getOrgId();
  const [isLoading, setIsLoading] = useState(true);
  const [pathwayData, setPathwayData] = useState(null);
  const [versions, setVersions] = useState([]);
  const [stagingVersion, setStagingVersion] = useState(null);
  const [inboundNumbers, setInboundNumbers] = useState([]);
  const [currentInboundNumber, setCurrentInboundNumber] = useState(null);

  // Fetch pathway details
  useEffect(() => {
    const fetchPathwayData = async () => {
      setIsLoading(true);
      try {
        // Fetch pathway details
        const response = await $fetch(`/v1/convo_pathway/${pathwayId}`);
        if (response && !response.errors) {
          setPathwayData(response.data);
        }

        // Fetch versions
        const versionsResponse = await fetch(
          `${config.API_URL}/v1/pathway/${pathwayId}/versions`,
          {
            method: 'GET',
            headers: {
              authorization: getApiKey(),
              ...(orgId && { 'x-bland-org-id': orgId }),
            },
          }
        );
        
        if (versionsResponse.ok) {
          const versionsData = await versionsResponse.json();
          
          // Find staging version
          const staging = versionsData.find(v => v.is_staging);
          if (staging) {
            setStagingVersion(staging);
          }
          
          // Filter other versions
          setVersions(versionsData.filter(v => !v.is_staging && !v.is_prev_published).slice(0, 3));
        }

        // Fetch inbound numbers
        const [numbersRes, byotRes] = await Promise.all([
          $fetch('/inbound/get_inbound', { method: 'GET' }),
          $fetch('/byot/get', { method: 'GET' }),
        ]);

        const allNumbers = [];
        
        if (numbersRes && numbersRes.inboundNumbers) {
          numbersRes.inboundNumbers.forEach(item => {
            if (item && item.phone_number) {
              allNumbers.push({
                phone_number: item.phone_number,
                name: item.label || null,
                prompt: item.prompt || null,
                pathway_id: item.pathway_id || null,
                sms_config: item.sms_config || null,
              });
            }
          });
        }
        
        if (byotRes && byotRes.data) {
          byotRes.data.forEach(item => {
            if (item && item.phone_number) {
              allNumbers.push({
                phone_number: item.phone_number,
                name: item.label || null,
                prompt: item.prompt || null,
                pathway_id: item.pathway_id || null,
              });
            }
          });
        }
        
        setInboundNumbers(allNumbers);
        
        // Find current inbound number
        const current = allNumbers.find(
          num => num.sms_config && num.sms_config.pathway_id === pathwayId
        );
        
        if (current) {
          setCurrentInboundNumber(current.phone_number);
        }
      } catch (error) {
        console.error('Error fetching pathway data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (pathwayId) {
      fetchPathwayData();
    }
  }, [pathwayId, orgId]);

  const navigateToFullView = () => {
    window.location.href = `/dashboard/convo-pathways?id=${pathwayId}&view=splash`;
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-full">
        <Loading loading={true} />
      </div>
    );
  }

  return (
    <PreviewContainer>
      <div className="flex items-center justify-between">
        <div>
          <div className="flex items-center gap-2">
            <h1 className="text-2xl font-semibold">{pathwayData?.name || 'SMS Pathway'}</h1>
            <StyledBadge 
              variant="solid" 
              color="iris" 
              style={{ 
                fontSize: 11, 
                padding: '2px 8px',
                height: '22px',
                lineHeight: '18px'
              }}
            >
              <MessageSquare size={12} style={{ marginRight: '4px' }} />
              SMS
            </StyledBadge>
          </div>
          <p className="text-sm text-gray-500 mt-1">ID: {pathwayId}</p>
        </div>
        {/* <Button 
          size="2" 
          variant="bordered" 
          onClick={navigateToFullView}
          style={{ borderColor: 'black', color: 'black' }}
        >
          Open Full View
          <ExternalLink size={14} />
        </Button> */}
        <HeroUIButton
          color="default"
          variant="bordered"
          radius='sm'
          className='h-8'
          onPress={navigateToFullView}
          style={{ borderColor: 'black', color: 'black' }}
        >
          Open Full View
          <ExternalLink size={14} style={{ color: 'black', marginLeft: 5 }} />
        </HeroUIButton>
      </div>

      <PathwayCard>
        <PathwayHeader>
          <h3 className="text-lg font-semibold">Pathway Preview</h3>
        </PathwayHeader>
        <div className="p-4">
          <img
            src={TempExamplePWImage}
            alt="Pathway Preview"
            style={{
              width: '100%',
              maxHeight: 250,
              objectFit: 'cover',
              border: '1px solid #e0e0e0',
              borderRadius: 4,
            }}
          />
        </div>
      </PathwayCard>

      <div>
        <h3 className="text-lg font-semibold mb-3">Staging Version</h3>
        {stagingVersion ? (
          <VersionBox>
            <VersionItem>
              <div
                style={{
                  border: '1px solid #e0e0e0',
                  padding: 8,
                  borderRadius: '50%',
                  marginRight: 16,
                }}
              >
                <GitBranch size={16} />
              </div>
              <div>
                <code style={{ fontSize: 14, fontWeight: '550' }}>
                  {stagingVersion.name || `Version ${stagingVersion.version_number}`}
                </code>
                <div className="flex flex-wrap items-center gap-x-3 gap-y-1 mt-1">
                  <p className="text-xs text-gray-500">v/{stagingVersion.version_number}</p>
                  <p className="text-xs text-gray-500">
                    created on {toDtmy(new Date(stagingVersion.created_at))}
                  </p>
                  <StyledBadge variant="solid" color="green">
                    Staging
                  </StyledBadge>
                </div>
              </div>
              
              <div className="ml-auto">
                {inboundNumbers.length > 0 && (
                  <InboundNumberDropdown
                    inboundNumbers={inboundNumbers}
                    currentInboundNumber={currentInboundNumber}
                    pathwayId={pathwayId}
                    versionNumber={stagingVersion.version_number}
                    isSmsPathway={true}
                  />
                )}
              </div>
            </VersionItem>
          </VersionBox>
        ) : (
          <div className="text-sm text-gray-500 p-4 bg-gray-50 border border-gray-200 rounded-md">
            No staging version available
          </div>
        )}
      </div>

      {versions && versions.length > 0 && (
        <div>
          <h3 className="text-lg font-semibold mb-3">Recent Versions</h3>
          <VersionBox>
            {versions.map((version, index) => (
              <VersionItem
                key={version.version_number}
                style={{
                  borderBottom: index === versions.length - 1 ? 'none' : '1px solid #e0e0e0',
                }}
              >
                <div
                  style={{
                    border: '1px solid #e0e0e0',
                    padding: 8,
                    borderRadius: '50%',
                    marginRight: 16,
                  }}
                >
                  <GitBranch size={16} />
                </div>
                <div>
                  <code style={{ fontSize: 14, fontWeight: '550' }}>
                    {version.name || `Version ${version.version_number}`}
                  </code>
                  <div className="flex flex-wrap items-center gap-x-3 gap-y-1 mt-1">
                    <p className="text-xs text-gray-500">v/{version.version_number}</p>
                    <p className="text-xs text-gray-500">
                      created on {toDtmy(new Date(version.created_at))}
                    </p>
                  </div>
                </div>
              </VersionItem>
            ))}
          </VersionBox>
        </div>
      )}
    </PreviewContainer>
  );
};

export default SMSPathwayPreview; 