import { TooltipText } from '_components/Text'
// Import the question mark icon
import TextInput from '_components/TextInput'

export default function Voicemail_message({ fieldName }) {
  const description = (
    <span>
      When the AI encounters a voicemail, it will leave this message after the
      beep and then immediately end the call.
      <br />
      {' '}
      <br />
      <i>Warning:</i>
      {' '}
      If ivr_mode is set to true or voicemail_action is set to
      ignore, then this will still work for voicemails, but it will not hang up
      for IVR systems.
    </span>
  )
  return (
    <div>
      <div style={{ marginBottom: 5 }}>
        <TooltipText tooltip={description} tooltipClassName="w-[300px]">
          Voicemail Message
        </TooltipText>
      </div>
      <TextInput
        fieldName={fieldName}
        type="text"
        placeholder="Hey, Just checking in!"
      />
    </div>
  )
}
