import { cn, Button as HeroButton, Tooltip } from '@heroui/react'
import config from 'config'
import { ClipboardCopy, CopyPlus, Pencil, Trash } from 'lucide-react'
import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { GoDuplicate } from 'react-icons/go'
import { LuClipboardCopy } from 'react-icons/lu'
import { MdDeleteOutline } from 'react-icons/md'
import { toast } from 'react-toastify'
import { Handle, NodeToolbar, Position, useReactFlow } from 'reactflow'
import { getAuthToken } from 'utils/funcs/browser/getAuthToken'
import { getOrgId } from 'utils/funcs/browser/getOrgId'
import FlowContext from '../../contextFlow'
import NodeModal from '../node_modal'
import NodeIcon from './nodeIcon'

function DefaultNode(props) {
  const context = props?.context ?? null

  const orgId = getOrgId()
  const api_key = getAuthToken()

  const { id, data, type, selected } = props
  const { setNodes, setEdges } = useReactFlow()
  const { deleteElement, duplicateNode, selectionStateRef, elements }
    = useContext(FlowContext)
  const [isOpen, setIsOpen] = useState(false)

  const [transferPathwayName, setTransferPathwayName] = useState('')
  const [transferPathwayNodeName, setTransferPathwayNodeName] = useState('')

  const typeLabel
    = type === 'Vector DB Knowledge Base'
      ? 'Knowledge Base'
      : type === 'Knowledge Base'
        ? 'Large Text'
        : type

  const copyNode = useCallback(() => {
    // Create a selection state with only this node selected
    elements.nodes.forEach((node) => {
      selectionStateRef.current[node.id] = node.id === id
    })

    // Create the event with the document.body as target
    const event = new KeyboardEvent('keydown', {
      key: 'c',
      code: 'KeyC',
      ctrlKey: true,
      metaKey: true,
      bubbles: true,
      cancelable: true,
      view: window,
    })

    // Dispatch the event on the document.body instead of document
    document.body.dispatchEvent(event)

    toast('Node copied to clipboard. Command/Ctrl + V to paste node', {
      type: 'success',
      autoClose: 2000,
      position: 'bottom-right',
    })
  }, [elements.nodes, id, selectionStateRef])

  useEffect(() => {
    const fetchPathwayName = async () => {
      //   console.log("type", type, data.transferPathway);
      if (type === 'Transfer Pathway') {
        if (data.transferPathway && api_key) {
          const pathwayResponse = await fetch(
            `${config.API_URL}/v1/convo_pathway/${data.transferPathway}`,
            {
              method: 'GET',
              headers: {
                authorization: api_key,
                ...(orgId && { 'x-bland-org-id': orgId }),
              },
            },
          )
          if (pathwayResponse.ok) {
            const pathwayData = await pathwayResponse.json()
            setTransferPathwayName(pathwayData.name)
            if (pathwayData.nodes && data.transferPathwayNode) {
              const node = pathwayData.nodes.find(
                node => node.id === data.transferPathwayNode,
              )
              if (data.useManualInput) {
                setTransferPathwayNodeName(data.transferPathwayNode)
              }
              else {
                setTransferPathwayNodeName(node?.data?.name)
              }
            }
          }
          else {
            console.error('Failed to fetch pathway data')
          }
        }
      }
    }
    fetchPathwayName()
  }, [type, data.transferPathway, data.transferPathwayNode])

  const toolbarButtons = [
    {
      key: 'edit',
      icon: <Pencil size={18} />,
      tooltip: 'Edit Node',
      onClick: () => setIsOpen(true),
    },
    {
      key: 'delete',
      icon: <Trash size={18} />,
      tooltip: 'Delete Node',
      onClick: deleteElement,
    },
    {
      key: 'duplicate',
      icon: <CopyPlus size={18} />,
      tooltip: 'Duplicate Node',
      onClick: duplicateNode,
    },
    {
      key: 'copy',
      icon: <ClipboardCopy size={18} />,
      tooltip: 'Copy Node to Clipboard',
      onClick: copyNode,
    },
  ]

  return (
    <>
      <NodeToolbar position="right" align="start">
        <div className="inline-flex flex-col items-center justify-center" role="group">
          {toolbarButtons.map(button => (
            <Tooltip key={button.key} content={button.tooltip}>
              <HeroButton
                variant="light"
                color="primary"
                isIconOnly
                onPress={button.onClick}
                size="sm"
                radius="none"
              >
                {button.icon}
              </HeroButton>
            </Tooltip>
          ))}
        </div>

      </NodeToolbar>

      <div
        className={`rounded w-[320px]  border-gray-300 shadow-md ${
          data.isGlobal
            ? 'border-4 border-yellow-300 bg-yellow-100'
            : 'bg-white'
        } ${selected ? ' border-2 !border-indigo-500' : ' border-[0.8px]'} ${
          data.active ? 'ring-2 ring-blue-500 shadow-lg shadow-blue-200' : ''
        }`}
        onDoubleClick={() => setIsOpen(true)}
      >
        {/* Container for the content, which allows the divider to extend fully */}
        <div
          className={`divide-y ${
            data.isGlobal ? 'divide-yellow-300' : 'divide-gray-200'
          }`}
        >
          <div className="p-4 h-[48px] flex items-center">
            {data.isGlobal && (
              <div className="text-sm absolute top-0 right-0 bg-yellow-500 text-white px-[4px] py-[1px] rounded-bl-lg text-xs">
                Global
              </div>
            )}
            <div className="flex items-center justify-between w-full">
              <div className="flex items-center space-x-2.5">
                <span>
                  <NodeIcon
                    isStart={data.isStart}
                    isGlobal={data.isGlobal}
                    type={type}
                  />
                </span>
                <h2 className="text-[13px] font-medium leading-4 line-clamp-1">
                  {data.name}
                </h2>
              </div>
              <div className="text-gray-400 text-[11px] leading-2.5 font-medium">
                {typeLabel}
              </div>
              {/* <button onClick={() => setIsOpen(true)}>
              <EditIcon />
            </button> */}
            </div>
          </div>
          {/* This is the second part after the divider */}
          <div className="p-4">
            <div className="flex items-center gap-2.5">
              <p className="line-clamp-2 leading-[18px] font-normal text-[12px]">
                {data.text || data.prompt}
              </p>
            </div>

            {type === 'Route' && (
              <div className="flex-col items-center justify-center gap-2.5 mt-2.5">
                <p className="text-[12px] font-medium line-clamp-2">
                  Number of Routes -
                  {' '}
                  <span className="font-normal bg-indigo-50 px-[4px] py-[1px] rounded text-[10px] text-indigo-800">
                    {data.routes?.length || 0}
                  </span>
                </p>
              </div>
            )}

            {type === 'SMS' && (
              <div className="flex-col items-center justify-center gap-2.5">
                <p className="text-[12px] font-medium line-clamp-2">
                  SMS Message -
                  {' '}
                  <span className="font-normal">{data.message}</span>
                </p>
              </div>
            )}

            {type === 'Transfer Pathway' && (
              <div className="flex flex-col justify-center space-y-2.5 mt-2.5">
                <p className="text-[12px] font-medium">
                  Pathway Name -
                  {' '}
                  <span className="font-normal">{transferPathwayName}</span>
                </p>
                <p className="text-[12px] font-medium">
                  Start Node -
                  {' '}
                  <span className="font-normal">{transferPathwayNodeName}</span>
                </p>
                <p className="text-[12px] font-medium">
                  Pathway Version -
                  {' '}
                  <span className="font-normal">{data.version}</span>
                </p>
                <div className="mt-1.5">
                  <a
                    className="text-[12px] font-medium text-indigo-600 hover:underline"
                    href={`https://app.bland.ai/dashboard/convo-pathways?id=${data?.transferPathway}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Open Pathway
                  </a>
                </div>
              </div>
            )}

            {type === 'Press Button' && (
              <div className="flex items-center gap-2.5 mt-2.5">
                <p className="text-[12px] font-normal line-clamp-2">
                  {data.digit
                    ? (
                        <>
                          Button Digit -
                          {' '}
                          <span className="font-normal">{data.digit}</span>
                        </>
                      )
                    : data.sequence
                      ? (
                          <>
                            Sequence of Digits -
                            {' '}
                            <span className="font-normal">{data.sequence}</span>
                          </>
                        )
                      : (
                          <>
                            Agent Decision -
                            {' '}
                            <span className="font-normal">{data.digitPrompt}</span>
                          </>
                        )}
                </p>
              </div>
            )}

            {type === 'Custom Tool' && (
              <div className="flex-col items-center justify-center gap-2.5 mt-2.5">
                <p className="text-[12px] font-medium mb-2.5">
                  Tool Name -
                  {' '}
                  <span className="font-normal">{data?.tool?.tool?.name}</span>
                </p>
                <p className="text-[12px] font-medium">
                  Tool URL -
                  {' '}
                  <span className="font-normal">
                    {data?.is_staging
                      ? data?.tool?.staging_tool?.url
                      : data?.tool?.tool?.url}
                  </span>
                </p>

                {data?.is_staging && (
                  <p className="text-[12px] font-medium mt-1.5">
                    Staging -
                    {' '}
                    <span className="font-normal">Enabled</span>
                  </p>
                )}
              </div>
            )}

            {type === 'Twilio Flow Redirect' && (
              <div className="flex items-center justify-center gap-1.5 mt-2.5">
                <div className="bg-blue-100 text-blue-800 text-xs font-medium px-2 py-0.5 rounded-full flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-2.5 w-2.5 mr-1.5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                  Twilio Redirect
                </div>
                {data?.twilioFlowRedirect?.flowName && (
                  <span className="text-xs text-gray-500">
                    →
                    {' '}
                    {data.twilioFlowRedirect.flowName}
                  </span>
                )}
              </div>
            )}

            <div className="flex items-center gap-2.5 mt-2.5">

              {(data.transferNumber || (data.twilioAppTransferFields?.isEnabled && data.twilioAppTransferFields?.transferNumber)) && (
                <p className="border border-gray-200 bg-gray-100 flex items-center justify-center px-[8px] py-[6px] rounded text-[10px]">
                  {data.twilioAppTransferFields?.isEnabled ? data.twilioAppTransferFields.appSid : data.transferNumber}
                </p>
              )}
              {data.url && (
                <div className="border border-gray-200 bg-gray-100 rounded p-1.5 mt-1.5">
                  <p className="text-[10px] line-clamp-2 break-all overflow-hidden">
                    {data?.url}
                  </p>
                </div>
              )}
            </div>

            {(!data.isGlobal || data.enableGlobalAutoReturn === false) && (
              <>
                {!data.isStart && !data.isGlobal && (
                  <Handle
                    key={`target-${id}`}
                    type="target"
                    position={Position.Top}
                    className="!w-2 !h-2 !bg-indigo-500"
                  />
                )}
                {(type !== 'Transfer Call'
                  || (data.warmTransferFields?.isEnabled
                    && !data.warmTransferFields?.useCustomHoldMusic))
                  && type !== 'End Call'
                  && type !== 'Transfer Pathway'
                  && type !== 'Twilio Flow Redirect' && (
                  <Handle
                    type="source"
                    position={Position.Bottom}
                    className="!w-2 !h-2 !bg-indigo-500"
                  />
                )}
              </>
            )}
          </div>
          {data?.tag && (
            <div
              className="px-4 py-2.5"
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                columnGap: 10,
              }}
            >
              <div
                style={{
                  height: 10,
                  width: 10,
                  background: data?.tag?.color,
                  borderRadius: '50%',
                }}
              />
              <p style={{ fontSize: 12, fontWeight: '500' }}>
                {data?.tag?.name}
              </p>
            </div>
          )}
        </div>
        <NodeModal
          id={id}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          setNodes={setNodes}
          setEdges={setEdges}
          data={data}
          type={type}
          context={context}
        />
      </div>
    </>
  )
}

export default memo(DefaultNode)
