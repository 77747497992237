import { Button } from '@/components/ui/button'
import { Card } from '@/components/ui/card'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { AlertCircle, ArrowLeft, ChevronRight, Plus, X } from 'lucide-react'
import { useContext, useState } from 'react'
import { toast } from 'react-toastify'
import FlowContext from '../../contextFlow'
import { areConditionsValid } from '../conditionBuilder/components/conditions'
import { ConditionBuilderContent } from '../conditionBuilder/conditionBuilderContent'
import GlobalNodeOptions from '../globalSelect'
import useGlobalNodeOptions from './hooks/useGlobalNodeOptions'

function RouteSummary({ route, targetNodeName, onEdit, onDelete }) {
  const getConditionPreview = (conditions) => {
    if (conditions.length === 0)
      return null

    const firstCondition = conditions[0]
    if (firstCondition.isGroup) {
      return `${firstCondition.conditions.length} grouped conditions`
    }

    let preview = `${firstCondition.field} ${firstCondition.operator}`
    if (firstCondition.value) {
      preview += ` "${firstCondition.value}"`
    }

    return preview
  }

  const conditionCount = route.conditions.length
  const preview = getConditionPreview(route.conditions)

  return (
    <Card className="transition-all duration-200 hover:shadow-md border-slate-200">
      <button onClick={onEdit} className="w-full text-left p-2.5 relative group">
        <div className="flex items-center justify-between mb-2">
          <div className="flex items-center gap-2">
            <div className="px-2 py-1 bg-blue-50 text-blue-700 rounded font-medium">
              {targetNodeName || 'Select Node'}
            </div>
            {!targetNodeName && (
              <span className="text-amber-600 text-xs flex items-center gap-0.5">
                <AlertCircle className="w-2.5 h-2.5" />
                Node required
              </span>
            )}
          </div>
          <div className="flex items-center gap-1.5">
            <button
              onClick={(e) => {
                e.stopPropagation()
                onDelete()
              }}
              className="p-1 rounded text-slate-400 hover:text-red-600 hover:bg-red-50 transition-colors"
            >
              <X className="w-2.5 h-2.5" />
            </button>
            <ChevronRight className="w-3 h-3 text-slate-400 group-hover:text-slate-600 transition-colors" />
          </div>
        </div>

        <div className="space-y-1">
          {preview
            ? (
                <>
                  <div className="text-xs text-slate-600 font-medium">
                    Primary Condition
                  </div>
                  <div className="text-xs bg-slate-50 text-slate-700 p-1.5 rounded border border-slate-200">
                    {preview}
                  </div>
                  {conditionCount > 1 && (
                    <div className="text-2xs text-slate-500">
                      +
                      {' '}
                      {conditionCount - 1}
                      {' '}
                      more condition
                      {conditionCount - 1 !== 1 ? 's' : ''}
                    </div>
                  )}
                </>
              )
            : (
                <div className="text-xs text-slate-500 italic">
                  No conditions defined yet
                </div>
              )}
        </div>
      </button>
    </Card>
  )
}

function RouteModal({
  id,
  data,
  setIsOpen,
  nodeType,
  nodeOptions,
  onNodeTypeChange,
}) {
  const { elements, triggerUpdate, setShowEdgeSidebar }
    = useContext(FlowContext)
  const availableNodes = elements.nodes.filter(node => node.id !== id)

  const [name, setName] = useState(data.name || '')

  const [routes, setRoutes] = useState(data.routes || [])
  const [editingRouteIndex, setEditingRouteIndex] = useState(null)
  const [showValidation, setShowValidation] = useState(false)
  const [fallbackNodeId, setFallbackNodeId] = useState(
    data.fallbackNodeId || null,
  )

  const globalNodeOptions = useGlobalNodeOptions({
    data,
    elements,
    nodeId: id,
  })

  const updateNodeData = (id, routes, elements) => {
    const updatedNodes = elements.nodes.map((el) => {
      if (el.id === id) {
        return {
          ...el,
          data: { ...el.data, routes },
          type: 'Route',
        }
      }
      return el
    })

    triggerUpdate({ nodes: updatedNodes }, false)
  }

  const getNodeName = (nodeId) => {
    const node = availableNodes.find(n => n.id === nodeId)
    return node?.data.name || nodeId
  }

  const addNewRoute = () => {
    const newRoute = {
      targetNodeId: '',
      conditions: [],
    }
    setRoutes([...routes, newRoute])
    setEditingRouteIndex(routes.length)
  }

  const handleSave = () => {
    setShowValidation(true)

    let newEdges = []

    if (routes?.length > 0) {
      // Validate conditions
      for (const route of routes) {
        const { valid, reason } = areConditionsValid(route.conditions)
        if (!valid) {
          toast.error(reason)
          return
        }
      }

      // check for duplicate target nodes
      const targetNodes = new Set()
      for (const route of routes) {
        if (targetNodes.has(route.targetNodeId)) {
          toast.error(
            `Duplicate target node - ${getNodeName(route.targetNodeId)}`,
          )
          return
        }
        targetNodes.add(route.targetNodeId)
      }

      // Create edges from routes
      newEdges = routes.map((route) => {
        const edge = {
          id: `reactflow__edge-${id}-${route.targetNodeId}`,
          source: id,
          target: route.targetNodeId,
          type: 'custom',
          animated: true,
          sourceHandle: null,
          targetHandle: null,
          data: {
            setIsSideBarOpen: setShowEdgeSidebar,
            condition: route.conditions,
            label: route.conditions
              .map((cond) => {
                if (cond.isGroup) {
                  return `(${cond.conditions
                    .map(
                      (groupCond, i) =>
                        `${i > 0 ? 'And ' : ''}${groupCond.field} ${groupCond.operator} ${groupCond?.value}`,
                    )
                    .join(' ')})`
                }
                return `${cond.field} ${cond.operator} ${cond?.value}`
              })
              .join(' Or '),
          },
        }
        return edge
      })
    }

    // Add fallback edge if fallbackNodeId is set
    if (fallbackNodeId) {
      newEdges.push({
        id: `reactflow__edge-${id}-${fallbackNodeId}`,
        source: id,
        target: fallbackNodeId,
        type: 'custom',
        animated: true,
        sourceHandle: null,
        targetHandle: null,
        data: {
          setIsSideBarOpen: setShowEdgeSidebar,
          condition: [],
          label: 'Fallback Route',
        },
      })
    }

    // Remove any existing edges from this source node
    const filteredEdges = elements.edges.filter(edge => edge.source !== id)

    // Combine existing edges with new edges
    const updatedEdges = [...filteredEdges, ...newEdges]

    // Process the node data with global options
    const nodeData = {
      ...data,
      routes,
      name,
      fallbackNodeId,
    }

    const { processedData, processedEdges }
      = globalNodeOptions.processGlobalNodeOptions(nodeData, updatedEdges)

    // Update nodes with processed data
    const updatedNodes = elements.nodes.map((el) => {
      if (el.id === id) {
        return {
          ...el,
          data: processedData,
          type: 'Route',
        }
      }
      return el
    })

    setIsOpen(false)
    triggerUpdate({ nodes: updatedNodes, edges: processedEdges }, false)
  }

  if (editingRouteIndex !== null) {
    return (
      <div className="space-y-4 ">
        <div className="border-b pb-2.5">
          <Button
            variant="ghost"
            size="sm"
            onClick={() => setEditingRouteIndex(null)}
            className="flex items-center gap-1.5 text-slate-600 hover:text-slate-800 mb-2 ml-[-10px]"
          >
            <ArrowLeft className="w-2.5 h-2.5" />
            Back to Routes
          </Button>
          <h3 className="text-sm font-semibold text-slate-900">
            {routes[editingRouteIndex]?.targetNodeId
              ? (
                  <>
                    Create conditions to route to
                    {' '}
                    <span className="font-medium">
                      {getNodeName(routes[editingRouteIndex]?.targetNodeId)}
                    </span>
                  </>
                )
              : (
                  'Configure which node to create conditions for'
                )}
          </h3>
        </div>

        <div className="mb-4">
          <label className="block text-xs font-medium text-slate-700 mb-1">
            Target Node
          </label>
          <div className="w-[300px] relative z-[200]">
            <Select
              value={routes[editingRouteIndex]?.targetNodeId}
              onValueChange={(value) => {
                const newRoutes = [...routes]
                newRoutes[editingRouteIndex] = {
                  ...newRoutes[editingRouteIndex],
                  targetNodeId: value,
                }
                setRoutes(newRoutes)
              }}
            >
              <SelectTrigger className="h-6">
                <SelectValue placeholder="Select target node" />
              </SelectTrigger>
              <SelectContent className="z-[200]">
                {availableNodes.map(node => (
                  <SelectItem key={node.id} value={node.id}>
                    {node.data.name || node.id}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
        </div>

        <div className="relative z-[150]">
          <div className="mb-2.5">
            <h4 className="text-xs font-medium text-slate-700">
              Route Conditions
            </h4>
            <p className="text-xs text-slate-500 mt-0.5">
              Define when this route should be taken
            </p>
          </div>
          <ConditionBuilderContent
            className="z-[200]"
            id={id}
            conditions={routes[editingRouteIndex]?.conditions || []}
            onChange={(newConditions) => {
              const newRoutes = [...routes]
              newRoutes[editingRouteIndex] = {
                ...newRoutes[editingRouteIndex],
                conditions: newConditions,
              }
              setRoutes(newRoutes)
              updateNodeData(id, newRoutes, elements)
            }}
            onClose={() => setEditingRouteIndex(null)}
            isRouteModal
          />
        </div>
      </div>
    )
  }

  return (
    <div className="space-y-4 z-[50]">
      <div>
        {editingRouteIndex === null && (
          <div className="mb-2.5">
            <label className="block text-xs font-medium text-slate-700 mb-1">
              Node Type
            </label>
            <div className="w-full">
              <Select value={nodeType} onValueChange={onNodeTypeChange}>
                <SelectTrigger className="h-8">
                  <SelectValue placeholder="Select node type" />
                </SelectTrigger>
                <SelectContent>
                  {nodeOptions.map(option => (
                    <SelectItem key={option.id} value={option.value}>
                      {option.name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
          </div>
        )}

        <div className="mb-2.5">
          <div>Name:</div>
          <input
            type="text"
            value={name}
            onChange={e => setName(e.target.value)}
            className="border border-gray-300 p-1.5 rounded w-full"
          />
        </div>
        <div className="space-y-2.5">
          <h2 className="text-base font-semibold text-slate-900 mb-1.5">
            Route Configuration
          </h2>
          <p className="text-xs text-slate-600">
            This node does not generate any dialogue, and instead acts as a
            router. Define routes to different nodes based on specific
            conditions. Each route can have multiple conditions.
          </p>
        </div>
      </div>

      {routes.length > 0
        ? (
            <div className="space-y-2 max-h-[255px] overflow-y-auto">
              {routes.map((route, index) => (
                <RouteSummary
                  key={route.id}
                  route={route}
                  targetNodeName={getNodeName(route.targetNodeId)}
                  onEdit={() => setEditingRouteIndex(index)}
                  onDelete={() => setRoutes(routes.filter((_, i) => i !== index))}
                />
              ))}
            </div>
          )
        : (
            <div className="text-center py-5 bg-slate-50 rounded-md border border-dashed border-slate-200">
              <p className="text-slate-600 mb-2.5">No routes configured yet</p>
              <Button
                variant="outline"
                onClick={addNewRoute}
                className="flex items-center gap-1.5"
              >
                <Plus className="w-2.5 h-2.5" />
                Add Your First Route
              </Button>
            </div>
          )}

      {routes.length > 0 && (
        <div className="pt-2.5">
          <Button
            variant="outline"
            onClick={addNewRoute}
            className="flex items-center gap-1.5"
          >
            <Plus className="w-2.5 h-2.5" />
            Add Another Route
          </Button>
        </div>
      )}

      <div className="p-2.5 bg-slate-50 rounded-md border border-slate-200">
        <h3 className="text-xs font-medium text-slate-700 mb-1.5">
          Fallback Route
        </h3>
        <p className="text-xs text-slate-500 mb-2">
          Select a fallback node that will be used if none of the above
          conditions are met
        </p>
        <div className="w-[300px]">
          <Select value={fallbackNodeId} onValueChange={setFallbackNodeId}>
            <SelectTrigger className="h-6">
              <SelectValue placeholder="Select default route" />
            </SelectTrigger>
            <SelectContent className="z-[200]">
              <SelectItem value={null}>No default route</SelectItem>
              {availableNodes.map(node => (
                <SelectItem key={node.id} value={node.id}>
                  {node.data.name || node.id}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      </div>
      <GlobalNodeOptions {...globalNodeOptions} />
      <div className="flex justify-end gap-2 pt-2.5 border-t">
        <Button variant="outline" onClick={() => setIsOpen(false)}>
          Cancel
        </Button>
        <Button onClick={handleSave}>Save</Button>
      </div>
    </div>
  )
}

export default RouteModal
