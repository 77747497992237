import { ChevronDown, ChevronUp } from 'lucide-react'
import { useState } from 'react'

export function SimulationsOverview() {
  const [isExpanded, setIsExpanded] = useState(true)

  return (
    <div className="w-full bg-slate-100 p-2.5 rounded-md">
      <div
        className="flex items-center justify-between cursor-pointer"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <h2 className="text-xs font-bold leading-[20px] uppercase font-geist-mono">
          HOW IT WORKS
        </h2>
        {isExpanded
          ? (
              <ChevronUp className="w-2.5 h-2.5 text-gray-500" />
            )
          : (
              <ChevronDown className="w-2.5 h-2.5 text-gray-500" />
            )}
      </div>

      {isExpanded && (
        <ul className="space-y-2 mt-2.5">
          <li className="flex items-center gap-1.5">
            <div className="w-[6px] h-[6px] rounded-full bg-indigo-600"></div>
            <span className="text-xs font-inter">
              Run a Test Case: Click
              <span className="font-bold font-geist-mono mx-1.5">RUN</span>
              {' '}
              to
              create a new call, using the test case you created.
            </span>
          </li>
          <li className="flex items-center gap-1.5">
            <div className="w-[6px] h-[6px] rounded-full bg-gray-300"></div>
            <span className="text-xs font-inter">
              Status: Track status of the test case in the
              <span className="font-bold font-geist-mono mx-1.5">
                HISTORY
              </span>
              {' '}
              tab.
            </span>
          </li>
          <li className="flex items-center gap-1.5">
            <div className="w-[6px] h-[6px] rounded-full bg-gray-300"></div>
            <span className="text-xs font-inter">
              Review: Click the
              <span className="font-bold font-geist-mono mx-1.5">REVIEW</span>
              button to view the pathway logs for the test call.
            </span>
          </li>
        </ul>
      )}
    </div>
  )
}

