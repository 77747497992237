import { Switch, Transition } from '@headlessui/react'
import ConfirmModal from 'components/core/ConfirmModal'
import { useAuth } from 'hooks/useAuth'
import { useCallback, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { getOrgsBillingData } from 'utils/funcs/billing/getOrgsBillingData'
import { cancelRecharge } from '../../../utils/funcs/billing/cancelRecharge'
import { updateRechargeAmount } from '../../../utils/funcs/billing/updateRechargeAmount'

function Input({ fieldName, label, placeholder, type, register, disabled, onChange }) {
  return (
    <div className={`flex flex-col space-y-0.5 ${disabled ? 'opacity-50' : ''}`}>
      <label htmlFor={fieldName} className="text-xs font-medium text-gray-700">
        {label}
      </label>
      <input
        disabled={disabled}
        id={fieldName}
        type={type}
        placeholder={placeholder}
        {...register(fieldName, {
          onChange: (e) => {
            // react-hook-form's onChange handling
            register(fieldName).onChange(e);
            // the custom onChange for dataChanged
            onChange?.(e);
          }
        })}
        className="px-2 py-1.5 bg-white border border-gray-300 rounded shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
      />
    </div>
  )
}

function Button({ children, loading, isDirty, ...props }) {
  return (
    // eslint-disable-next-line react-dom/no-missing-button-type
    <button
      {...props}
      className={`inline-flex justify-center items-center px-2.5 py-1.5 border border-transparent text-xs font-medium ${
        isDirty ? 'bg-indigo-600 hover:bg-indigo-700' : 'bg-indigo-300'
      } rounded shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition duration-150 ease-in-out`}
      disabled={loading}
    >
      {loading
        ? (
            <svg
              className="animate-spin -ml-0.5 mr-2 h-3 w-3 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              >
              </circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              >
              </path>
            </svg>
          )
        : null}
      {children}
    </button>
  )
}

function Message({ show, message, type }) {
  return (
    <Transition
      show={show}
      enter="transition-opacity duration-75"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-150"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div
        className={`fixed bottom-2.5 right-2.5 ${type === 'success' ? 'bg-green-500' : 'bg-red-500'} text-white px-2.5 py-1.5 rounded shadow-lg`}
      >
        {message}
      </div>
    </Transition>
  )
}

export default function AutoRecharge() {
  const [loading, setLoading] = useState(false)
  const [userLoading, setUserLoading] = useState(true)
  const [showSuccess, setShowSuccess] = useState(false)
  const [showError, setShowError] = useState(false)
  const [dataChanged, setDataChanged] = useState(false)
  const { fetchUser, user } = useAuth()

  const methods = useForm({
    defaultValues: {
      recharge_enabled: false,
      refill_amt: 10,
      refill_threshold: 5
    },
    mode: "onChange",
    resolver: (values) => {
      const errors = {};
      
      if (values.recharge_enabled) {
        if (values.refill_amt < 10 || values.refill_amt > 10000) {
          errors.refill_amt = {
            type: "range",
            message: "Refill amount must be between $10 and $10,000"
          };
        }
        
        if (values.refill_threshold < 5 || values.refill_threshold > 10000) {
          errors.refill_threshold = {
            type: "range",
            message: "Refill threshold must be between $5 and $10,000"
          };
        }
      }
      
      return {
        values,
        errors
      };
    }
  })

  const { register, handleSubmit, watch, setValue, reset, formState: { errors, isDirty } } = methods

  const fetchRefillSettings = useCallback(async () => {
    try {
      const billingData = await getOrgsBillingData()
      console.log('billingData:', billingData)
      setValue('recharge_enabled', billingData.refill_enabled)
      if (billingData.refill_enabled) {
        setValue('refill_amt', billingData.refill_amount)
        setValue('refill_threshold', billingData.refill_at)
      }
      setDataChanged(false)
    }
    catch (error) {
      console.error('Failed to fetch user data:', error)
      setShowError(true)
      setTimeout(() => setShowError(false), 3000)
    }
    finally {
      setUserLoading(false)
    }
  }, [reset, methods])

  useEffect(() => {
    fetchRefillSettings()
  }, [fetchRefillSettings])

  const rechargeEnabled = watch('recharge_enabled')

  const validateRefillSettings = (refillEnabled, refillAmt, refillThreshold) => {
    if (!refillEnabled) {
      return [0, undefined]
    }
    refillAmt = Number.parseInt(refillAmt)
    refillThreshold = Number.parseInt(refillThreshold)
    // refillAmt must be between 10 and 1000. Not setting values < 10 to 0, since if refillEnabled is true, it's expected that it will be set to a valid value
    refillAmt = Math.max(Math.min(refillAmt, 10000), 10)

    // in this specific implementation, using undefined to skip any update,
    // since setting it to 0 will cause requests to fail
    if (!Number.isNaN(refillThreshold) && refillThreshold >= 5) {
      refillThreshold = Math.max(Math.min(refillThreshold, 10000), 10)
    }
    else {
      // intentionally undefined to skip being updated in the API request. It cannot be set to a value < 5 anyways
      refillThreshold = undefined
    }
    return [refillAmt, refillThreshold]
  }

  const onSaveRefill = async (formData) => {
    setLoading(true)
    try {
      if (formData.recharge_enabled) {
        const [refillAmt, refillThreshold] = validateRefillSettings(
          formData.recharge_enabled, 
          formData.refill_amt,
          formData.refill_threshold
        )
        await updateRechargeAmount(refillAmt, user.user.id, refillThreshold)
      }
      else {
        await cancelRecharge(user.user.id)
      }
      await Promise.all([fetchRefillSettings(), fetchUser()])
      setShowSuccess(true)
      setLoading(false)
      setTimeout(() => setShowSuccess(false), 3000)
    }
    catch (error) {
      console.error('Failed to update recharge amount:', error)
      setShowError(true)
      setLoading(false)
      setTimeout(() => setShowError(false), 3000)
    }
  }

  const onToggleRecharge = async (checked) => {
    setDataChanged(true)
    setValue('recharge_enabled', checked)
  }

  if (userLoading) {
    return (
      <div className="flex justify-center items-center h-16">
        <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-indigo-600"></div>
      </div>
    )
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSaveRefill)} className="space-y-4">
        <div className="flex items-center justify-between gap-6">
          <h5 className="text-sm font-semibold text-gray-900">Auto Recharge</h5>
          <div className='flex flex-row items-center space-x-1.5 gap-6'>
            <div className="text-sm text-gray-900">
              {rechargeEnabled ? 'Enabled' : 'Disabled'}
            </div>
            <Switch
              checked={rechargeEnabled}
              onChange={onToggleRecharge}
              className={`${
                rechargeEnabled ? 'bg-indigo-600' : 'bg-gray-200'
              } relative inline-flex items-center h-4 rounded-full w-7 transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
            >
              <span
                className={`${
                  rechargeEnabled ? 'translate-x-4' : 'translate-x-0.5'
                } inline-block w-2.5 h-2.5 transform bg-white rounded-full transition-transform`}
              />
            </Switch>
          </div>
        </div>
        <hr></hr>
        <div className="text-xs text-gray-500">
          Automatically refill your credits whenever your balance is low, so that you never run out. Select your refill amount and threshold below.<br></br>
          Note: Credit cards are highly recommended as a payment method to ensure fast recharges and uninterrupted service.
        </div>
        <div className="flex items-end space-x-2.5 space-y-2.5 pb-2.5">
          <div className="flex-grow space-y-1.5">
            <Input
              type="number"
              fieldName="refill_amt"
              label="Refill Amount ($)"
              placeholder="10"
              register={register}
              disabled={!rechargeEnabled}
              onChange={() => setDataChanged(true)}
            />
            <div className="text-xs text-gray-500">
              The number of credits to purchase at a time.
            </div>
            {errors.refill_amt && (
                <p className="mt-0.5 text-xs text-red-600">{errors.refill_amt.message}</p>
              )}
            <Input
              type="number"
              fieldName="refill_threshold"
              label="Refill Threshold ($)"
              placeholder="5"
              register={register}
              disabled={!rechargeEnabled}
              onChange={() => setDataChanged(true)}
            />
            <div className="text-xs text-gray-500">
              Refill your credits automatically any time your balance falls below this amount. By default, auto recharge is triggered when your balance falls below $5.
            </div>
            {errors.refill_threshold && (
                <p className="mt-0.5 text-xs text-red-600">{errors.refill_threshold.message}</p>
              )}
          </div>
        </div>
        <Button type="submit" loading={loading} isDirty={dataChanged || loading}>
          {loading ? 'Saving...' : 'Save Changes' }
        </Button>
      </form>
      <Message
        show={showSuccess}
        message="Refill amount saved successfully!"
        type="success"
      />
      <Message
        show={showError}
        message="An error occurred. Please try again."
        type="error"
      />
    </FormProvider>
  )
}
