import { Checkbox, cn, Form, Input, InputOtp } from '@heroui/react'
import { Turnstile } from '@marsidev/react-turnstile'
import AccountSelect from 'components/Auth/Login/AccountSelect'
import AuthButton from 'components/Hero/AuthButton'
import PhoneInput from 'components/Hero/PhoneInput'
import { TURNSTILE_SITE_KEY } from 'config/turnstile'
import { motion } from 'framer-motion'
import { useAuth } from 'hooks/useAuth'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useStep } from 'usehooks-ts'
import { $fetch } from 'utils/fetch'
import logo from '../assets/brand/bland-b.svg'
import tos from '../assets/files/tos.pdf'
import { Promotion } from '../components/Auth/SignUp/Promotion'
import WebDemo from './Onboarding/WebDemo'
import 'react-phone-input-2/lib/style.css'

export default function Auth() {
  const navigate = useNavigate()
  const { handleAuthResponse } = useAuth()
  const { register, handleSubmit, control } = useForm()

  const [turnstileToken, setTurnstileToken] = useState('')
  const [turnstileError, setTurnstileError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [currentStep, helpers] = useStep(4)

  const {
    goToNextStep,
    setStep,
  } = helpers

  const isLogin = window.location.pathname === '/login'

  const handleTurnstileSuccess = (token) => {
    setTurnstileError(false)
    setTurnstileToken(token)
  }

  const handleTurnstileError = () => {
    setTurnstileError(true)
  }

  const sendOtp = async ({ phone }) => {
    try {
      await $fetch('/signup/sendOtp', {
        method: 'POST',
        body: {
          phoneNumber: phone,
          token: turnstileToken,
          context: isLogin ? 'login' : 'signup',
        },
      })
      toast.success('Verification code sent successfully!')
      goToNextStep()
    }
    catch (error) {
      console.error(error)
      const errorMessage = error.data?.errors?.[0]?.message
      toast.error(errorMessage ?? 'An unknown error occurred')
    }
  }

  const verifyLoginOtp = async ({ phone, sms_code }) => {
    try {
      const { data } = await $fetch('/login/verifyOtp', {
        method: 'POST',
        body: {
          phoneNumber: phone,
          code: sms_code,
        },
      })
      await handleAuthResponse({ jwt: data.jwt })
      toast.success('Logged in successfully!')
      setStep(4)
    }
    catch (error) {
      console.error(error)
      const errorMessage = error.data?.errors?.[0]?.message
      toast.error(errorMessage ?? 'An unknown error occurred')
    }
  }

  const verifySignupOtp = async ({ phone, sms_code, first_name, last_name, email, email_code }) => {
    try {
      const { data } = await $fetch('/signup/verifyOtp', {
        method: 'POST',
        body: {
          phoneNumber: phone,
          code: sms_code,
          email_code,
          signupData: {
            first_name,
            last_name,
            email,
          },
        },
      })
      if (data.email_verification_required) {
        goToNextStep()
      }
      else {
        await handleAuthResponse({ jwt: data.jwt, org_id: data.initial_org_id })
        setStep(4)
      }
    }
    catch (error) {
      console.error(error)
      const errorMessage = error.data?.errors?.[0]?.message
      toast.error(errorMessage ?? 'An unknown error occurred')
    }
  }

  const onSubmit = async (formData) => {
    setLoading(true)
    try {
      if (currentStep === 1) {
        if (turnstileError) {
          toast.error('Please complete the security check')
          return
        }
        await sendOtp(formData)
      }
      if (currentStep === 2 || currentStep === 3) {
        if (isLogin) {
          await verifyLoginOtp(formData)
        }
        else {
          await verifySignupOtp(formData)
        }
      }
    }
    finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!isLogin) {
      const trackingPixel = document.createElement('img')
      trackingPixel.src = 'https://jelly.mdhv.io/v1/star.gif?pid=1GVTNGo1I6zeX2vulU5ZEL2tnVrO&src=mh&evt=hi'
      trackingPixel.style.display = 'none'
      trackingPixel.width = 0
      trackingPixel.height = 0
      trackingPixel.id = 'signup-tracking-pixel'
      document.head.appendChild(trackingPixel)

      return () => {
        const pixel = document.getElementById('signup-tracking-pixel')
        if (pixel)
          document.head.removeChild(pixel)
      }
    }
  }, [isLogin])

  return (
    <div className="h-screen w-screen overflow-x-hidden relative">
      <img alt="logo" src={logo} className="h-9 absolute z-50 top-6 left-9" />
      {currentStep === 4
        ? (
            <motion.div
              className="w-full relative h-full flex justify-center items-center"
              initial={{ backgroundColor: '#FFFFFF' }}
              animate={{ backgroundColor: '#F1EFE8' }}
              transition={{ duration: 2.5 }}
            >
              {isLogin
                ? (
                    <AccountSelect />
                  )
                : (
                    <WebDemo />
                  )}
            </motion.div>
          )
        : (
            <div className="grid w-full h-screen xl:grid-cols-2 grid-cols-1">
              <Form onSubmit={handleSubmit(onSubmit)} className={cn('flex flex-col gap-5 h-screen w-full xl:items-start xl:px-20 px-8 justify-center max-w-screen-sm', currentStep > 2 ? 'place-self-center' : 'xl:place-self-start place-self-center')}>
                {currentStep === 1 && (
                  <>
                    <h1 className="text-5xl font-taurus font-semibold tracking-tighter">
                      {isLogin ? 'Log in to Bland' : 'Sign up with Bland'}
                    </h1>

                    {!isLogin && (
                      <>
                        <div className="flex justify-between gap-4 w-full">
                          <Input
                            radius="sm"
                            variant="bordered"
                            label="First Name"
                            isRequired
                            {...register('first_name')}
                          />
                          <Input
                            radius="sm"
                            variant="bordered"
                            label="Last Name"
                            isRequired
                            {...register('last_name')}
                          />
                        </div>
                        <Input
                          radius="sm"
                          variant="bordered"
                          label="Email"
                          type="email"
                          isRequired
                          {...register('email')}
                        />
                      </>
                    )}
                    <Controller
                      name="phone"
                      control={control}
                      render={({ field }) => (
                        <PhoneInput
                          isRequired
                          fullWidth
                          size="lg"
                          {...field}
                        />
                      )}
                    />
                    {!isLogin && (
                      <Checkbox
                        size="sm"
                        radius="none"
                        variant="bordered"
                        {...register('agreed_tos')}
                        classNames={{ label: 'text-sm text-gray-500' }}
                        isRequired
                      >
                        I agree to the
                        {' '}
                        <a
                          href={tos}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="underline"
                        >
                          terms of service
                        </a>
                      </Checkbox>
                    )}

                    <AuthButton type="submit" disabled={turnstileError} isLoading={loading}>
                      {isLogin ? 'Get Code' : 'Sign Up'}
                    </AuthButton>

                    <div className="flex flex-row gap-0.5">
                      <p className="font-medium text-[#151515] text-opacity-50 font-taurus">
                        {isLogin
                          ? 'Need an account?'
                          : 'Already have an account?'}
                      </p>
                      <p
                        onClick={() => {
                          isLogin ? navigate('/signup') : navigate('/login')
                        }}
                        className="font-bold hover:cursor-pointer underline decoration-solid"
                      >
                        {isLogin ? 'Sign up here' : 'Log in here'}
                      </p>
                    </div>

                    <Turnstile
                      siteKey={TURNSTILE_SITE_KEY}
                      className="mx-auto"
                      onSuccess={handleTurnstileSuccess}
                      onError={handleTurnstileError}
                      options={{
                        appearance: 'interaction-only',
                        theme: 'light',
                        size: 'flexible',
                        action: 'auth',
                      }}
                    />
                  </>
                )}

                {currentStep === 2 && (
                  <span className="flex flex-col w-fit items-start mx-auto">
                    <p className="text-center text-sm text-gray-500">Enter the code sent to your phone</p>
                    <InputOtp
                      variant="faded"
                      {...register('sms_code')}
                      size="lg"
                      autoFocus
                      length={6}
                      classNames={{ errorMessage: 'w-80 text-wrap' }}
                    />
                    <AuthButton type="submit" isLoading={loading} className="mt-4">
                      Verify Code
                    </AuthButton>
                  </span>
                )}

                {currentStep === 3 && (
                  <span className="flex flex-col w-fit items-start mx-auto">
                    <p className="text-center text-sm text-gray-500">Enter the code sent to your email</p>
                    <InputOtp
                      variant="faded"
                      autoFocus
                      {...register('email_code')}
                      size="lg"
                      length={6}
                    />
                    <AuthButton type="submit" isLoading={loading} className="mt-4">
                      Verify Code
                    </AuthButton>
                  </span>
                )}
              </Form>

              <Promotion type={isLogin ? 'login' : 'signup'} />
            </div>
          )}
    </div>
  )
}
