import { Button, cn } from '@heroui/react'
import { useMutation } from '@tanstack/react-query'
import { BlandWebClient } from 'bland-client-js-sdk'
import VoiceGradientAvatar from 'components/Dashboard/SendCall/VoiceGradientReusable'
import { motion } from 'framer-motion'
import { useAuth } from 'hooks/useAuth'
import { useOnboarding } from 'hooks/useOnboarding'
import { ArrowRight } from 'lucide-react'
import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { salesTask } from 'utils/data/prompts/salesTask'
import { smallBusinessTask } from 'utils/data/prompts/smallBusinessTask'
import { telehealthTask } from 'utils/data/prompts/telehealthTask'
import { $fetch } from 'utils/fetch'
// import { BlandWebClient } from './BlandClient'
import PulsingOrb from './PulsingOrb'
import config from 'config'

const voiceOptions = [
  {
    name: 'June',    
    description: 'American Female.',
    id: '60fec350-03ff-48fa-9f31-c180f37b1a38',
  },
  {
    name: 'Brady',    
    description: 'A soft spoken, middle aged American male.',
    id: '923ef241-cffc-4b6d-a59a-9c3ec3614d53',
  },
  {
    name: 'Paige',    
    description: 'Calm, soft-tone female',
    id: '70f05206-71ab-4b39-b238-ed1bf17b365a',
  },
  {
    name: 'Karl',
    description: 'German male',
    id: '206a7d5d-8b10-4025-bd2b-25eb6c507e84',
  },
]

const promptOptions = [
  {
    label: 'Friendly greeting',
    value: 'Welcome the new user to Bland and ask them how their day is going. Be a good listener and ask follow up questions.',
  },
  {
    label: 'Lead qualification',
    value: salesTask,
  },
  {
    label: 'Appointment confirmation',
    value: telehealthTask,
  },
  {
    label: 'Business survey',
    value: smallBusinessTask,
  },
]

function PanelLabel({ children, className }) {
  return (
    <h3 className={cn('text-black absolute top-3 left-3 font-taurus font-semibold uppercase text-xs', className)}>
      {children}
    </h3>
  )
}

function ConfigContainer({ children, label, delay = 0 }) {
  return (
    <motion.div
      className="flex flex-col pt-12 gap-2 border border-gray-300 p-4 rounded-md relative bg-[#FFFDF5]"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay }}
    >
      <PanelLabel>
        {label}
      </PanelLabel>
      {children}
    </motion.div>
  )
}

export default function WebDemo() {
  const { user } = useAuth()
  const navigate = useNavigate()
  const { setState } = useOnboarding()

  const [activeVoice, setActiveVoice] = useState(voiceOptions[0].name)
  const [activePrompt, setActivePrompt] = useState(promptOptions[0].value)
  const [isCalling, setIsCalling] = useState(false)
  const [isAiTalking, setIsAiTalking] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const transcriptTimerRef = useRef(null)
  const clientRef = useRef(null)

  const { mutateAsync: createClient } = useMutation({
    mutationFn: async () => $fetch('/v1/pathway/test_agent/init', {
      method: 'POST',
      body: {
        voice: activeVoice,
        prompt: activePrompt,
      },
    }),
    onSuccess: async ({ data }) => {
      const client = new BlandWebClient(
        user?.user?.id,
        data.id,
        config.WS_URL,
      )

      client.on('conversationStarted', () => {
        setIsCalling(true)
      })

      client.on('conversationEnded', () => {
        setIsCalling(false)
        setIsAiTalking(false)
      })

      client.on('error', (error) => {
        console.error('[SDK] Error:', error)
        setIsCalling(false)
        setIsAiTalking(false)
      })

      client.on('transcripts', (data) => {
        const transcriptsArray = Array.isArray(data) ? data : [data]
        let assistantSpoke = false

        transcriptsArray.forEach((item) => {
          if (item.type === 'assistant') {
            assistantSpoke = true
          }
        })

        if (assistantSpoke) {
          setIsAiTalking(true)

          if (transcriptTimerRef.current) {
            clearTimeout(transcriptTimerRef.current)
          }

          transcriptTimerRef.current = setTimeout(() => {
            setIsAiTalking(false)
          }, 1500)
        }
      })

      clientRef.current = client
    },
    onError: () => {
      toast.error('Error starting conversation, please try again.')
    },
  })

  const { mutate: advanceOnboarding, isPending: isAdvancing } = useMutation({
    mutationFn: async () => $fetch('/call_config', {
      method: 'POST',
      body: {
        name: 'My First Persona',
        call_config: {
          voice: activeVoice,
          task: activePrompt,
        },
      },
    }),
    onSettled: ({ data }) => {
      setState({ personaId: data?.id, run: true })
      navigate('/dashboard/send-call')
    },
  })

  const stopConversation = async () => {
    if (clientRef.current) {
      await clientRef.current.stopConversation()    
    }
  }

  const startConversation = async () => {
    if (clientRef.current) {
      await clientRef.current.initConversation({
        sampleRate: 44100,
      })
    }
  }

  const resetConversation = async () => {
    setIsLoading(true)
    await stopConversation()
    await createClient()
    await startConversation()
    setIsLoading(false)
  }

  // Reset conversation when voice or prompt changes
  useEffect(() => {
    if (user) {
      resetConversation()
    }
  }, [activeVoice, activePrompt, user])

  // CLeanup
  useEffect(() => {
    return async () => {
      if (clientRef.current) {
        await clientRef.current.stopConversation()
        clientRef.current = null
      }
      if (transcriptTimerRef.current) {
        clearTimeout(transcriptTimerRef.current)
        transcriptTimerRef.current = null
      }
    }
  }, [])

  return (
   <>
      {/* CONTAINER */}
      <motion.div
        className="flex gap-4 max-w-3xl h-[600px] w-full"
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.6 }}
      >

        {/* LEFT CONTAINER */}
        <motion.div
          className="flex flex-col justify-between h-full"
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <motion.span
            className="flex flex-col items-start p-2 gap-1"
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.3 }}
          >
            <h3 className="text-black font-taurus text-2xl font-bold uppercase">
              1. Meet Blandie
            </h3>
            <p className="text-gray-500 tracking-wide font-taurus font-medium text-xs">
              Hint: Say hi! I'm listening.
            </p>
          </motion.span>

          <div className="flex flex-col gap-4 w-full">
            {/* PROMPT CONTAINER */}
            <ConfigContainer label="Prompt" delay={0.4}>
              {promptOptions.map((prompt, index) => (
                <motion.div
                  key={prompt.value}
                  initial={{ opacity: 0, x: -10 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.3, delay: 0.5 + (index * 0.1) }}
                >
                  <Button
                    size="sm"
                    onPress={() => setActivePrompt(prompt.value)}
                    className={cn('bg-[#E8E6DF] rounded-md w-full text-gray-500 font-medium uppercase font-taurus', activePrompt === prompt.value && 'bg-[#AFADA7] text-white')}
                  >
                    {prompt.label}
                  </Button>
                </motion.div>
              ))}
            </ConfigContainer>

            {/* VOICE CONTAINER */}
            <ConfigContainer label="Voice" delay={0.6}>
              {voiceOptions.map((voice, index) => (
                <motion.div
                  key={voice.name}
                  initial={{ opacity: 0, x: -10 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.3, delay: 0.7 + (index * 0.1) }}
                >
                  <Button
                    onPress={() => setActiveVoice(voice.name)}
                    className={cn('flex px-2 pr-10 font-taurus w-full justify-start bg-[#E8E6DF] rounded-md py-6', activeVoice === voice.name && 'bg-[#AFADA7] text-white')}
                    startContent={
                      <div className="flex shadow-sm border border-white rounded-lg">
                        <VoiceGradientAvatar name={voice.id} size={34} hoverAnimation isAnimated={false} borderRadius="10%" />                
                      </div>
                    }
                  >
                    <span className="flex flex-col justify-around items-start">
                      <p className="text-sm font-taurus">
                        {voice.name}
                      </p>
                      <p className="text-xs opacity-80 font-taurus">
                        {voice.description}
                      </p>
                    </span>
                  </Button>
                </motion.div>
              ))}
            </ConfigContainer>
          </div>
        </motion.div>

        {/* WEB AGENT CONTAINER */}
        <motion.div
          className="flex flex-col border border-gray-300 items-center grow h-full px-16 justify-center rounded-lg bg-black relative"
          initial={{ opacity: 0, x: 20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5, delay: 0.3 }}
        >
          <PanelLabel className="text-white">
            Blandie
          </PanelLabel>
          <PulsingOrb isAiTalking={isAiTalking} />

          <motion.div
            initial={{ opacity: 0, y: 0 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.9 }}
          >
            <Button
              className="bg-orange-500 text-white font-taurus rounded-md font-medium uppercase text-xs absolute bottom-4 inset-x-0 mx-4"
              variant="solid"
              size="sm"
              isLoading={isLoading}
              onPress={isCalling ? stopConversation : startConversation}
            >
              {isCalling ? 'End Conversation' : 'Start Conversation'}
            </Button>
          </motion.div>
        </motion.div>
      </motion.div>

      <motion.div
        initial={{ opacity: 0, y: 0 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 1 }}
      >
        <Button
          size="md"
          onPress={advanceOnboarding}
          isLoading={isAdvancing}
          className="bg-orange-500 font-taurus rounded-md font-medium uppercase text-xs absolute bottom-4 right-4 mx-4"
          startContent={<span>2.</span>}
          endContent={<ArrowRight className="w-4 h-4" />}
          variant="solid"
        >
          Send Your First Call
        </Button>
      </motion.div>
    </>
  )
}
