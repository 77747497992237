import axios from 'axios'
import ActionBar from 'components/core/ActionBar'
import Button from 'components/core/Button'
import Input from 'components/core/Input'
import { PageTitle } from 'components/core/PageTitle'
import { PageWrapper } from 'components/core/PageWrapper'
import Textarea from 'components/core/Textarea'
import config from 'config'
import { useAuth } from 'hooks/useAuth'
import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { getApiKey } from 'utils/funcs/browser/getApiKey'
import { getAuthToken } from 'utils/funcs/browser/getAuthToken'
import { getOrgId } from 'utils/funcs/browser/getOrgId'

export default function Report() {
  const methods = useForm()
  const token = getAuthToken()
  const apiKey = getApiKey()
  const { user } = useAuth()
  const orgId = getOrgId()
  const [loading, setLoading] = useState(false)

  const onSubmit = async (formData) => {
    console.log('token', token)
    setLoading(true)
    const data = {
      api_key: apiKey,
      contact_at: formData.contact_at,
      description: formData.description,
      title: formData.title,
      user_id: user.user.id,
    }
    const headers = {
      authorization: token,
      ...(orgId && { 'x-bland-org-id': orgId }),
    }
    try {
      const report = await axios.post(`${config.API_URL}/user/report`, data, {
        headers,
      })
      if (report) {
        toast.success('Report submitted successfully!')
        setLoading(false)
      }
    }
    catch (error) {
      toast.error('Error submitting report. Please try again.')
      setLoading(false)
    }
  }

  return (
    <PageWrapper>
      <ActionBar top>
        <PageTitle>Report Issue or Feature</PageTitle>
      </ActionBar>
      <FormProvider {...methods}>
        <Form onSubmit={methods.handleSubmit(onSubmit)}>
          <Input
            fieldName="title"
            label="Title (Bug/Feature)"
            placeholder="Enter a title..."
          />
          <Textarea
            fieldName="description"
            label="Description"
            placeholder="Descrbe the issue or feature in greater detail..."
          />
          <Input
            fieldName="contact_at"
            label="Contact Email/Phone"
            placeholder="Enter your email or phone number..."
          />
          <Button submit loading={loading}>
            Submit
          </Button>
        </Form>
      </FormProvider>
    </PageWrapper>
  )
}

const Form = styled.form`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`
