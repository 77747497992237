import { Toaster } from '@/components/ui/sonner'
import { Routes } from '@datadog/browser-rum-react/react-router-v6'
import { StatsigProvider } from '@statsig/react-bindings'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { Billing } from 'components/AccountSupport'
import Report from 'components/AccountSupport/Report/Report'
import {
  AddOns,
  Home,
  PhoneNumbers,
  SendCall,
  Testing,
} from 'components/Dashboard'
import AnalysisPresetConfigurator from 'components/Dashboard/Analysis/AnalysisPresetConfigurator'
import Analytics from 'components/Dashboard/Analytics/Analytics'
import Batches from 'components/Dashboard/Batches/Batches'
import CreateBatch from 'components/Dashboard/Batches/CreateBatch'
import CallLogs from 'components/Dashboard/CallLogs/CallLogs'
import ConvoPathways from 'components/Dashboard/ConvoPathways/ConvoPathways'
import AllEvents from 'components/Dashboard/Events/AllEvents'
import Event from 'components/Dashboard/Events/Events'
import Infrastructure from 'components/Dashboard/Infrastructure/infrastructure'
import NotFound from 'components/Dashboard/NotFound'
import PurchaseCredits from 'components/Dashboard/PurchaseCredits/PurchaseCredits'
import SharedReport from 'components/Dashboard/Report/SharedReport'
import Secrets from 'components/Dashboard/Secrets/Secrets'
import SMSDashboard from 'components/Dashboard/SMS'
import SMSHumanChat from 'components/Dashboard/SMS/Support'
import PlaygroundHub from 'components/Dashboard/Testing/Home'
import RevisedTools from 'components/Dashboard/Testing/RevisedTools'
import AllTools from 'components/Dashboard/Tools/AllTools'
import Tools from 'components/Dashboard/Tools/Tools'
import NewVoices from 'components/Dashboard/Voices/_Voices'
import { AuthProvider } from 'contexts/AuthProvider'
import { OnboardingProvider } from 'contexts/OnboardingProvider'
import Auth from 'pages/Auth'
import EmbeddedPathway from 'pages/embeddedPathway'
import Enterprise from 'pages/Enterprise'
import GeneratePathway from 'pages/GeneratePathway'
import KnowledgeBases from 'pages/KnowledgeBases'
import WebDemo from 'pages/Onboarding/WebDemo'
import PathwayShowcase from 'pages/pathwayShowcase'
import Settings from 'pages/Settings'
import ShowcasePage from 'pages/Tools/Tools'
import ToolSlug from 'pages/Tools/ToolSlug'
import {
  BrowserRouter,
  Navigate,
  Route,
  useLocation,
} from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import SearsDashboard from 'SearsDashboard'
import { queryClient } from 'utils/queryClient'
import { GlobalStyles } from 'utils/theme/global'
import { ThemeWrapper } from 'utils/theme/theme'
import { ClientSecretProvider } from './contexts/ClientSecretContext'
import Dashboard from './pages/Dashboard'

function DashboardRedirect() {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const page = searchParams.get('page')

  if (page === 'inbound-number') {
    return <Navigate to="/dashboard/phone-numbers" replace />
  }
  else if (page === 'home') {
    return <Navigate to="/dashboard" replace />
  }
  else if (page) {
    searchParams.delete('page')
    const queryString = searchParams.toString()
    const newPath = `/dashboard/${page}${queryString ? `?${queryString}` : ''}`
    return <Navigate to={newPath} replace />
  }
  return <Dashboard />
}

function HomeRedirect() {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const page = searchParams.get('page')

  if (page === 'inbound-number') {
    return <Navigate to="/dashboard/phone-numbers" replace />
  }
  else if (page) {
    searchParams.delete('page')
    const queryString = searchParams.toString()
    const newPath = `/dashboard/${page}${queryString ? `?${queryString}` : ''}`
    return <Navigate to={newPath} replace />
  }
  return <Navigate to="/dashboard" replace />
}

function App() {
  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <StatsigProvider sdkKey="client-Yl9uGwaYfYJmlIzZkLkjbyebzOKjzmDJ3FrvEG5fDDZ" user={{ userId: undefined }}>
          <AuthProvider>
            <OnboardingProvider>
              <ClientSecretProvider>
                <ReactQueryDevtools initialIsOpen={false} />
                <ThemeWrapper>
                  <GlobalStyles />
                  <Toaster />
                  <ToastContainer />
                  <Routes>
                    <Route path="/*" element={<NotFound />} />
                    <Route path="/" element={<HomeRedirect />} />
                    <Route path="/enterprise" element={<Enterprise />} />
                    <Route path="/login" element={<Auth />} />
                    <Route path="/signup" element={<Auth />} />
                    <Route path="/auth" element={<Auth />} />
                    <Route
                      path="/analytics/report/:id"
                      element={<SharedReport />}
                    />
                    <Route path="/showcase/pathway" element={<PathwayShowcase />} />
                    <Route path="/home" element={<HomeRedirect />} />
                    <Route path="/showcase/tools" element={<ShowcasePage />} />
                    <Route path="/showcase/tools/:id" element={<ToolSlug />} />

                    <Route path="/dashboard" element={<DashboardRedirect />}>
                      <Route path="" element={<Home />} />
                      <Route path="send-call" element={<SendCall />} />
                      <Route path="phone-numbers" element={<PhoneNumbers />} />
                      <Route path="call-logs" element={<CallLogs />}>
                        <Route path=":callId" element={<CallLogs />} />
                      </Route>
                      <Route path="voices" element={<NewVoices />} />
                      <Route path="convo-pathways" element={<ConvoPathways />} />
                      <Route path="analytics" element={<Analytics />} />
                      <Route path="tools" element={<AllTools />} />
                      <Route path="tool/:id" element={<Tools />} />
                      <Route path="tool" element={<Tools />} />
                      <Route path="infrastructure" element={<Infrastructure />} />
                      <Route path="settings" element={<Settings />} />
                      <Route path="playground" element={<PlaygroundHub />} />
                      <Route path="playground-tools" element={<RevisedTools />} />
                      <Route path="billing-details" element={<Billing />} />
                      <Route path="playground-chat" element={<Testing />} />
                      <Route path="batches" element={<Batches />} />
                      <Route path="create-batch" element={<CreateBatch />} />
                      <Route path="pay" element={<PurchaseCredits />} />
                      <Route path="add-ons" element={<AddOns />} />
                      <Route path="knowledge-bases" element={<KnowledgeBases />} />
                      <Route path="memory" element={<KnowledgeBases />} />
                      <Route path="memory/:memory_id" element={<KnowledgeBases />} />
                      <Route path="events" element={<Event />}>
                        <Route path=":id" element={<Event />} />
                      </Route>
                      <Route path="all-events" element={<AllEvents />} />
                      <Route path="secrets" element={<Secrets />} />
                      <Route
                        path="analysis-preset"
                        element={<AnalysisPresetConfigurator />}
                      />
                      <Route path="sms" element={<SMSDashboard />} />
                      <Route path="sms/support" element={<SMSHumanChat />} />
                      <Route path="sms/:conversationId" element={<SMSDashboard />} />
                      <Route path="report" element={<Report />} />
                      <Route path="*" element={<NotFound />} />
                    </Route>
                    {/* UNCOMMENT TO TEST WEB DEMO */}
                    {/* <Route path="web-demo" element={<WebDemo />} /> */}
                    <Route path="/generate-pathway" element={<GeneratePathway />} />
                    <Route
                      path="/analytics/dashboard/tempPathwayAnalytics"
                      element={<SearsDashboard />}
                    />
                    <Route path="/embed-pathway" element={<EmbeddedPathway />} />
                  </Routes>
                </ThemeWrapper>
              </ClientSecretProvider>
            </OnboardingProvider>
          </AuthProvider>
        </StatsigProvider>
      </QueryClientProvider>
    </BrowserRouter>
  )
}
export default App
