import { Button } from '@/components/ui/button'
import { Check, Plus } from 'lucide-react'

function WrongNodeContent({
  message,
  isConditionCorrect,
  setIsConditionCorrect,
  selectedNode,
  setSelectedNode,
  nodeOptions,
  handleAddToFinetuningExample,
  wrongNodeExampleAdded,
}) {
  return (
    <div className="flex flex-col space-y-2.5 w-full max-w-5xl">
      <div>
        <h3 className="text-sm font-semibold text-gray-800 mb-1.5">
          Was the condition at this node chosen correctly?
        </h3>
        <p className="text-xs text-gray-600 mb-2.5">
          The condition determines whether the conversation should continue
          along this path or choose a different route.
        </p>
        <div className="p-2 rounded-md mb-2.5 bg-gray-100 break-words">
          <strong>Selected Condition:</strong>
          {' '}
          <span
            className={`${message.promptInfo['Condition Achieved'] === true ? 'text-green-500' : 'text-red-500'}`}
          >
            {String(message.promptInfo['Condition Achieved'])}
          </span>
        </div>
        <div className="flex space-x-2.5">
          <Button
            onClick={() => setIsConditionCorrect(false)}
            className={`flex-1 ${
              isConditionCorrect === false
                ? 'bg-red-500 text-white hover:bg-red-600'
                : 'bg-white text-gray-800 hover:bg-red-200'
            }`}
          >
            No
          </Button>
          <Button
            onClick={() => setIsConditionCorrect(true)}
            className={`flex-1 ${
              isConditionCorrect === true
                ? 'bg-green-500 text-white hover:bg-green-600'
                : 'bg-white text-gray-800 hover:bg-green-200'
            }`}
          >
            Yes
          </Button>
        </div>
      </div>

      {isConditionCorrect === true && (
        <div>
          <h3 className="text-sm font-semibold text-gray-800 mb-1.5">
            Please select the correct route it should have picked
          </h3>

          <div className="p-2 rounded-md mb-2.5 bg-gray-100 text-gray-800 break-words truncate max-w-5xl">
            <strong>Selected Route:</strong>
            {' '}
            {message.promptInfo['Chosen Pathway']}
          </div>

          <div className="space-y-1.5 max-h-36 overflow-y-auto mb-2.5 max-w-5xl">
            {nodeOptions.map(option => (
              <Button
                key={option.id}
                onClick={() => setSelectedNode(option.id)}
                className={`bg-white hover:bg-white h-auto p-2.5 text-left justify-start items-start w-full rounded-md transition-all duration-200 ease-in-out ${
                  selectedNode === option.id
                    ? 'border-2 border-indigo-500'
                    : 'border border-gray-200'
                }`}
              >
                <div className="flex items-start">
                  <div className="flex-shrink-0 mt-0.5 mr-1.5">
                    {selectedNode === option.id && (
                      <Check className="h-3 w-3 text-blue-500" />
                    )}
                  </div>
                  <div className="text-gray-800">{option.label}</div>
                  <div className="text-gray-500 ml-1.5">
                    {option.node_name && `(Node Name: ${option.node_name})`}
                  </div>
                </div>
              </Button>
            ))}
          </div>
        </div>
      )}

      <div className="flex">
        <Button
          onClick={handleAddToFinetuningExample}
          disabled={
            isConditionCorrect === true
            && !selectedNode
            && wrongNodeExampleAdded
          }
          className="inline-flex items-center mt-2.5 bg-green-500 hover:bg-green-600 text-white font-semibold py-1.5 px-2.5 rounded-md shadow-md transition-all duration-200 ease-in-out"
        >
          {wrongNodeExampleAdded
            ? (
                <>
                  <Check className="mr-1.5 h-2.5 w-2.5" />
                  Added to Fine-tuning Examples
                </>
              )
            : (
                <>
                  <Plus className="mr-1.5 h-2.5 w-2.5" />
                  Add to Node's
                  {' '}
                  {isConditionCorrect ? 'Pathway' : 'Condition'}
                  {' '}
                  Fine-tuning Example
                </>
              )}
        </Button>
      </div>
    </div>
  )
}

export default WrongNodeContent
