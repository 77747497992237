import { Alert, Autocomplete, AutocompleteItem, AutocompleteSection, forwardRef } from '@heroui/react'
import { useQueries, useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { TbBrandTwilio } from 'react-icons/tb'
import { $fetch } from 'utils/fetch'
import logo from '../../assets/brand/bland-b.svg'

const PhoneSelect = forwardRef(({ onChange, value, ...props }, ref) => {
  const { setValue } = useFormContext()
  const encryptedKey = useWatch({
    name: 'encrypted_key',
  })

  const { data: encryptedKeys, isLoading: encryptedKeysLoading } = useQuery({
    queryKey: ['encryptedKeys'],
    queryFn: async () => {
      const { data } = await $fetch('/byot/get_keys')
      return data?.map(key => key.id)
    },
    staleTime: Infinity,
  })
  const sources = useMemo(() => {
    return [
      'inbound',
      ...(encryptedKeys || []),
    ]
  }, [encryptedKeys])

  const numbersBySource = useQueries({
    queries: sources.map(source => ({
      staleTime: Infinity,
      queryKey: ['phoneNumbers', source],
      queryFn: async () => {
        const { inbound_numbers } = await $fetch('/v1/inbound', {
          headers: {
            ...(source === 'inbound'
              ? {}
              : {
                  encrypted_key: source,
                }),
          },
        })
        return {
          source,
          numbers: inbound_numbers.map(({ phone_number }) => phone_number),
        }
      },
    })),
  })

  const allNumbers = useMemo(() => {
    // Skip if any query is still loading
    if (numbersBySource.some(query => query.isLoading)) {
      return []
    }

    // Get all numbers from non-inbound sources
    const nonInboundQueries = numbersBySource.filter(
      query => query.data && query.data?.source !== 'inbound',
    )

    // Get all phone numbers from non-inbound sources
    const nonInboundPhoneNumbers = nonInboundQueries.flatMap(({ data }) => data?.numbers || [])

    // Get inbound numbers query
    const inboundQuery = numbersBySource.find(
      query => query.data && query.data?.source === 'inbound',
    )

    // Filter out inbound numbers that exist in other sources
    const filteredInboundNumbers = inboundQuery?.data?.numbers?.filter(
      phone_number => !nonInboundPhoneNumbers.includes(phone_number),
    ) || []

    // Group numbers by source
    const result = nonInboundQueries.map(({ data }) => data)

    if (filteredInboundNumbers?.length > 0) {
      result.unshift({
        numbers: filteredInboundNumbers,
        source: 'inbound',
      })
    }

    return result
  }, [numbersBySource])

  return (
    <div className="flex flex-col gap-1">
      <Autocomplete
        ref={ref}
        onSelectionChange={(value) => {
          if (!value) {
            setValue('encrypted_key', null)
            onChange(null)
            return
          }
          const [source, phone_number] = value.split(':')
          if (source === 'inbound') {
            setValue('encrypted_key', null)
          }
          else {
            setValue('encrypted_key', source)
          }
          onChange(phone_number)
        }}
        selectedKey={encryptedKey ? `${encryptedKey}:${value}` : `inbound:${value}`}
        labelPlacement="outside"
        isLoading={encryptedKeysLoading || allNumbers.some(query => query.isLoading)}
        isVirtualized
        variant="bordered"
        radius="sm"
        label="From Number"
        isDisabled={allNumbers.length === 0}
        placeholder={allNumbers.length === 0 ? 'No numbers purchased' : 'Select a phone number'}
        description="Select a phone number from your purchased or BYOT numbers. If you don't select one, a random number will be used."
        {...props}
      >
        {allNumbers.map(({ numbers, source }) => (
          <AutocompleteSection key={source} showDivider title={source === 'inbound' ? 'Inbound' : `Twilio: ${source?.slice(0, 8)}...`}>
            {numbers?.map(number => (
              <AutocompleteItem
                key={`${source}:${number}`}
                startContent={source === 'inbound' ? <img alt="logo" src={logo} className="size-3 opacity-50" /> : <TbBrandTwilio className="text-red-500 opacity-50 text-lg" />}
                endContent={(source !== 'inbound' && (
                  <p className="text-xs text-gray-500 font-geist-mono">
                    {source.slice(0, 8)}
                    ...
                  </p>
                ))}
              >
                {number}
              </AutocompleteItem>
            ))}
          </AutocompleteSection>
        ))}
      </Autocomplete>
      {encryptedKey && (
        <Alert color="success" description="No need to worry about encrypted keys anymore! We've got you covered." title="Encrypted Key found!" />
      )}
    </div>
  )
})

export default PhoneSelect
