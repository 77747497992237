import { Button, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Select, SelectItem, useDisclosure } from '@heroui/react'
import { useFormContext } from 'contexts/formContext'
import { ArrowDownWideNarrow, ListFilterPlus } from 'lucide-react'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { useLocalStorage, useReadLocalStorage } from 'usehooks-ts'

export function SaveFilterModal() {
  const form = useFormContext()
  const { isOpen, onOpen, onOpenChange } = useDisclosure()
  const [savedFilters, setSavedFilters] = useLocalStorage('savedFilters', [])
  const [name, setName] = useState('')

  const handleSaveFilters = () => {
    setSavedFilters([...savedFilters, { name, ...form.state.values }])
    setName('')
    toast.success('Filters saved')
    onOpenChange(false)
  }
  return (
    <>
      <Button size="sm" variant="bordered" onPress={onOpen}>
        <ListFilterPlus size={16} />
        Save Filters
      </Button>
      <Modal radius="sm" placement="top-center" isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          {onClose => (
            <>
              <ModalHeader className="flex flex-col gap-1">Save Filters</ModalHeader>
              <ModalBody>
                <Input
                  label="Filter name"
                  placeholder="Enter a name for your filter"
                  variant="bordered"
                  radius="sm"
                  value={name}
                  onValueChange={setName}
                />
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="flat" onPress={onClose}>
                  Close
                </Button>
                <Button isDisabled={!name?.trim()} color="primary" onPress={handleSaveFilters}>
                  Save
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  )
}

export function LoadFilterModal() {
  const form = useFormContext()
  const { isOpen, onOpen, onOpenChange } = useDisclosure()
  const savedFilters = useReadLocalStorage('savedFilters')
  const [selectedSet, setSelectedSet] = useState(null)

  const handleLoadFilters = () => {
    const parsedSet = JSON.parse(selectedSet)
    form.reset({ filters: parsedSet }, true)
    form.handleSubmit()
    toast.success('Filter set loaded')
    onOpenChange(false)
  }

  const handleSelectionChange = (e) => {
    setSelectedSet(e.target.value)
  }

  return (
    <>
      <Button size="sm" variant="bordered" onPress={onOpen}>
        <ArrowDownWideNarrow size={16} />
        Load Filters
      </Button>
      <Modal radius="sm" placement="top-center" isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          {onClose => (
            <>
              <ModalHeader className="flex flex-col gap-1">Load Saved Filters</ModalHeader>
              <ModalBody>
                <Select label="Select a saved set" selectedKeys={[selectedSet]} onChange={handleSelectionChange} radius="sm" variant="bordered">
                  {savedFilters?.map(savedFilter => (
                    <SelectItem key={JSON.stringify(savedFilter.filters)}>{savedFilter.name}</SelectItem>
                  ))}
                </Select>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="flat" onPress={onClose}>
                  Close
                </Button>
                <form.Subscribe selector={state => state.isSubmitting}>
                  {({ isSubmitting }) => (
                    <Button
                      isDisabled={!selectedSet || isSubmitting}
                      isLoading={isSubmitting}
                      color="primary"
                      onPress={handleLoadFilters}
                    >
                      Load
                    </Button>
                  )}
                </form.Subscribe>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  )
}
