import Button from 'components/core/Button'
import Input from 'components/core/Input'
import Label from 'components/core/Label'
import { useEffect } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import styled from 'styled-components'

export default function RequestData({ fieldName, short }) {
  const { control, getValues, setValue, watch } = useFormContext()

  const { fields, append, remove } = useFieldArray({
    control,
    name: fieldName,
  })

  useEffect(() => {
    const currRequestData = getValues(fieldName)
    setValue(fieldName, currRequestData)
  }, [fieldName, getValues, setValue, watch])

  const onAdd = () => {
    append({ key: '', value: '' })
  }

  return (
    <Wrapper>
      <Label>Request Data</Label>
      <p className="text-muted-foreground mb-1.5">
        {short
          ? `Variables the agent has access to, and can be referenced using {{variable_name}} notation`
          : `When you want your AI to “know” a specific fact - like the caller’s
            name or other relevant context. The AI agent will be aware of both
            the key names as well as their corresponding values.`}
      </p>
      {fields.length > 0
        ? (
            <>
              <KeyValueWrapper>
                {fields.map((param, index) => (
                  <KeyValueGrid key={param.id}>
                    <KeyValueItem>
                      <Input
                        fieldName={`${fieldName}[${index}].key`}
                        placeholder="Key"
                        style={{ borderRadius: '0px', border: 'none' }}
                      />
                    </KeyValueItem>
                    <KeyValueItem>
                      <Input
                        fieldName={`${fieldName}[${index}].value`}
                        placeholder="Value"
                        style={{
                          borderRadius: '0px',
                          border: 'none',
                          width: '100%',
                        }}
                      />
                    </KeyValueItem>
                    <KeyValueItem>
                      <Button
                        onClick={() => remove(index)}
                        appearance="outline"
                        style={{
                          border: 'none',
                          borderRadius: '0px',
                          width: '100%',
                          height: '100%',
                        }}
                      >
                        -
                      </Button>
                    </KeyValueItem>
                  </KeyValueGrid>
                ))}
              </KeyValueWrapper>
              <Button
                onClick={onAdd}
                appearance="outline"
                style={{ width: 'auto !important' }}
              >
                + Key/Value
              </Button>
            </>
          )
        : (
            <Button
              onClick={onAdd}
              appearance="outline"
              style={{ width: 'auto !important' }}
            >
              + Key/Value
            </Button>
          )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  align-items: start;
`

const KeyValueWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid #eeeeee;
  gap: 1px;
`

const KeyValueGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 45px;
  background-color: #fbfbfa;
  grid-gap: 1px;
  width: 100%;
`

const KeyValueItem = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  align-items: center;
  box-sizing: border-box;
  outline: 1px solid #eeeeee;
`
