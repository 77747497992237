import { Switch } from '@headlessui/react'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { Tooltip } from '@radix-ui/themes'
import NodeTag from 'components/Dashboard/ConvoPathways/Components/NodeTag'
import LightTooltip from 'components/Dashboard/Parameters/ToolTipDesc'
import config from 'config'
import jmespath from 'jmespath'
import jsonpath from 'jsonpath'
import { AlertCircle } from 'lucide-react'
import { useContext, useEffect, useRef, useState } from 'react'
import { FaChevronDown, FaChevronUp, FaPlay } from 'react-icons/fa'
import { HiOutlinePlus } from 'react-icons/hi'
import { RxCross2 } from 'react-icons/rx'
import { toast } from 'react-toastify'
import { useReactFlow } from 'reactflow'
import { getAuthToken } from 'utils/funcs/browser/getAuthToken'
import { getOrgId } from 'utils/funcs/browser/getOrgId'
import ExtractInfo from '../Components/extractInfo'
import FlowContext from '../contextFlow'
import GlobalNodeOptions from './globalSelect'
import ModelOptions from './modelOptions'
import PathwayResponse from './WebhookResponsePathway'

function WebhookNodeModal({ id, setNodes, setIsOpen, data }) {
  const { getNodes, setEdges, getEdges } = useReactFlow()
  const [name, setName] = useState(data.name || '')
  const [text, setText] = useState(data.text || '')
  const [prompt, setPrompt] = useState(data.prompt || '')
  const [useStaticText, setUseStaticText] = useState(!data.prompt)
  const [modelOptions, setModelOptions] = useState(
    data.modelOptions || {
      modelType: 'smart',
      temperature: 0.2,
      block_interruptions: true,
      disableSilenceRepeat: true,
    },
  )
  const orgId = getOrgId()

  const { exportFlow, triggerUpdate, elements } = useContext(FlowContext)

  const [timeoutValue, setTimeoutValue] = useState(data.timeoutValue || 10)

  const [isGlobal, setIsGlobal] = useState(data.isGlobal)
  const [globalPathwayLabel, setGlobalPathwayLabel] = useState(
    data.globalLabel || '',
  )

  const [globalPathwayDescription, setGlobalPathwayDescription] = useState(
    data.globalDescription || '',
  )

  const [enableGlobalAutoReturn, setEnableGlobalAutoReturn] = useState(
    data.enableGlobalAutoReturn !== false,
  )

  const [prevNodePathwayLabel, setPrevNodePathwayLabel] = useState(
    data.prevNodePathwayLabel || '',
  )

  const [prevNodePathwayDescription, setPrevNodePathwayDescription] = useState(
    data.prevNodePathwayDescription || '',
  )

  const [isForwardingEnabled, setIsForwardingEnabled] = useState(false)
  const [forwardingNode, setForwardingNode] = useState('')

  let isWarnToast = false
  const [responsePathways, setResponsePathways] = useState(
    data.responsePathways || [
      ['Default/Webhook Completion', '', '', { id: '', name: '' }],
    ],
  )

  const [isExpanded, setIsExpanded] = useState(false)
  const [url, setURL] = useState(data.url || '')
  const [method, setMethod] = useState(data.method || 'GET')
  const [headers, setHeaders] = useState(data.headers || [['', '']])
  const [extractVars, setExtractVars] = useState(
    data.extractVars || [['', '', '', false]],
  )

  const [apiResponse, setApiResponse] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)

  const [responseData, setResponseData] = useState(
    data.responseData || [{ name: '', data: '', context: '' }],
  )

  const [useReroute, setUseReroute] = useState(true)

  const [useAuth, setUseAuth] = useState(data.auth ?? false)
  const [authToken, setAuthToken] = useState(data.auth?.token || '')
  const [authType, setAuthType] = useState(data.auth?.type || null)
  const [encodeAuth, setEncodeAuth] = useState(data.auth?.encode || false)

  const [tags, setTags] = useState(data.tag || null)
  const testApiRequest = async () => {
    setIsLoading(true)
    setError(null)
    try {
      const headerObj = Object.fromEntries(headers)
      // delete empty keys
      Object.keys(headerObj).forEach((key) => {
        if (headerObj[key] === '') {
          delete headerObj[key]
        }
      })

      if (useReroute) {
        // Rerouted version
        const runEndpointUrl = `${config.API_URL}/tools/run`
        if (useAuth && authToken) {
          let encodedToken = authToken
          if (encodeAuth) {
            encodedToken = btoa(authToken)
          }
          // need da extra space
          headerObj.Authorization = authType
            ? `${authType} ${encodedToken}`
            : encodedToken
        }
        const response = await fetch(runEndpointUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': getAuthToken(),
            ...(orgId && { 'x-bland-org-id': orgId }),
          },
          body: JSON.stringify({
            url,
            method,
            headers: useAuth || useHeader ? headerObj : undefined,
            body: useBody ? body : undefined,
          }),
        })

        const data = await response.json()

        if (data.data?.statusCode >= 200 && data.data?.statusCode < 300) {
          setApiResponse(data.data?.responseData)
        }
        else {
          throw new Error(
            `Request failed with status ${data.data?.statusCode}`,
          )
        }
      }
      else {
        // Direct version
        const response = await fetch(url, {
          method,
          headers: useHeader ? headerObj : undefined,
          body: useBody ? body : undefined,
        })
        const data = await response.json()
        setApiResponse(data?.data)
      }
    }
    catch (err) {
      setError(err.message)
    }
    finally {
      setIsLoading(false)
    }
  }

  const extractResponseData = (path) => {
    try {
      return path.split('.').reduce((o, i) => o[i], apiResponse)
    }
    catch (err) {
      return 'N/A'
    }
  }
  const [extractVarSettings, setExtractVarSettings] = useState(
    data.extractVarSettings || {},
  )

  const [body, setBody] = useState(data.body || '{}')

  const [useHeader, setUseHeader] = useState(!!data.headers)
  const [useBody, setUseBody] = useState(!!data.body)
  const [useExtractVars, setUseExtractVars] = useState(
    !!data.extractVars,
  )

  const [variableValidationErrors, setVariableValidationErrors] = useState([])

  const validateVariables = () => {
    // if useExtractVars is false, no need to validate
    if (!useExtractVars) {
      return true
    }
    const errors = []
    extractVars.forEach((variable, index) => {
      variable.forEach((field, fieldIndex) => {
        if (typeof field === 'string' && field.trim() === '') {
          errors.push({
            index,
            field: fieldIndex,
            message: 'Field is required',
          })
        }
      })
    })
    setVariableValidationErrors(errors)
    return errors.length === 0
  }

  const [useResponseData, setUseResponseData] = useState(
    !!data.responseData,
  )

  const [useSpeechDuringWebhook, setUseSpeechDuringWebhook] = useState(
    data.text || data.prompt,
  )

  const addHeader = () => {
    setHeaders([...headers, ['', '']])
  }

  const responseDataRef = useRef(null)

  const removeHeader = (index) => {
    setHeaders(headers.filter((_, i) => i !== index))
  }

  const updateHeader = (index, key, value) => {
    const updatedHeaders = headers.map((header, i) => {
      if (i === index) {
        return [key, value]
      }
      return header
    })
    setHeaders(updatedHeaders)
  }

  const extractPath = (obj, path = '$') => {
    const result = []

    if (Array.isArray(obj)) {
      obj.forEach((item, index) => {
        result.push(...extractPath(item, `${path}[${index}]`))
      })
    }
    else if (typeof obj === 'object' && obj !== null) {
      Object.entries(obj).forEach(([key, value]) => {
        const newPath = `${path}.${key}`
        result.push({ name: key, path: newPath, value: JSON.stringify(value) })
        if (typeof value === 'object' && value !== null) {
          result.push(...extractPath(value, newPath))
        }
      })
    }

    return result
  }

  const addToResponseData = (item) => {
    setResponseData([
      ...responseData,
      { name: item.name, data: item.path, context: '' },
    ])
    toast.success(
      'Value extracted and added to response data. You can reference this variable to decide which pathway to go to, or use it in future nodes.',
    )
    setTimeout(() => {
      responseDataRef.current.scrollIntoView({ behavior: 'smooth' })
    }, 100)
  }

  const renderJsonWithExtract = (obj, path = '$', depth = 0) => {
    const indent = depth * 2 // 20px indentation per level

    if (Array.isArray(obj)) {
      return (
        <div style={{ marginLeft: `${indent}px` }} className="my-0.5">
          [
          <div style={{ marginLeft: '20px' }}>
            {obj.map((item, index) => (
              <div key={index} className="my-0.5">
                {renderJsonWithExtract(item, `${path}[${index}]`, depth + 1)}
              </div>
            ))}
          </div>
          ]
        </div>
      )
    }
    else if (typeof obj === 'object' && obj !== null) {
      return (
        <div style={{ marginLeft: `${indent}px` }} className="my-0.5">
          {'{'}
          <div style={{ marginLeft: '20px' }}>
            {Object.entries(obj).map(([key, value], index, array) => (
              <div key={key} className="my-0.5 flex items-start">
                <button
                  onClick={() => {
                    setUseResponseData(true)
                    addToResponseData({
                      name: key,
                      path: `${path}.${key}`,
                      value: JSON.stringify(value),
                    })
                  }}
                  className="mr-1.5 bg-indigo-100 hover:bg-indigo-200 text-indigo-700 p-0.5 rounded text-2xs transition-colors duration-200 flex-shrink-0"
                  title="Extract this value"
                >
                  <HiOutlinePlus className="w-2.5 h-2.5" />
                </button>
                <span className="font-semibold text-purple-700 mr-1.5">
                  "
                  {key}
                  ":
                </span>
                <div className="flex-grow">
                  {renderJsonWithExtract(value, `${path}.${key}`, depth + 1)}
                </div>
              </div>
            ))}
          </div>
          {'}'}
        </div>
      )
    }
    else if (typeof obj === 'string') {
      return (
        <span className="text-green-600">
          "
          {obj}
          "
        </span>
      )
    }
    else if (typeof obj === 'number') {
      return <span className="text-blue-600">{obj}</span>
    }
    else if (typeof obj === 'boolean') {
      return <span className="text-red-600">{obj.toString()}</span>
    }
    else if (obj === null) {
      return <span className="text-gray-500">null</span>
    }
    else {
      return <span>{obj}</span>
    }
  }

  const addResponseData = () => {
    setResponseData([...responseData, { name: '', data: '', context: '' }])
  }

  const removeResponseData = (index) => {
    setResponseData(responseData.filter((_, i) => i !== index))
  }

  const updateResponseData = (index, field, value) => {
    const updatedData = responseData.map((item, i) => {
      if (i === index) {
        return { ...item, [field]: value }
      }
      return item
    })
    setResponseData(updatedData)
  }

  const isPathValid = (path) => {
    try {
      jsonpath.parse(path)
      return true
    }
    catch (e) {
      try {
        jmespath.search(path)
        return true
      }
      catch (e) {
        try {
          // dot notation equivalent to jsonpath with + $ added
          jsonpath.parse(`$${path}`)
          return true
        }
        catch (e) {
          return false
        }
      }
    }
  }

  useEffect(() => {
    setName(data.name || '')
    setText(data.text || '')
    setPrompt(data.prompt || '')
    setUseStaticText(!data.prompt)
    setModelOptions(
      data.modelOptions || { modelType: 'smart', temperature: 0.2 },
    )
    setTimeoutValue(data.timeoutValue || 10)
    setIsGlobal(data.isGlobal)
    setGlobalPathwayLabel(data.globalLabel || '')
    setGlobalPathwayDescription(data.globalDescription || '')
    setEnableGlobalAutoReturn(
      data.enableGlobalAutoReturn !== false,
    )
    setPrevNodePathwayLabel(data.prevNodePathwayLabel || '')
    setPrevNodePathwayDescription(data.prevNodePathwayDescription || '')
    setIsForwardingEnabled(false)
    setForwardingNode('')
    setResponsePathways(
      data.responsePathways || [
        ['Default/Webhook Completion', '', '', { id: '', name: '' }],
      ],
    )
    setURL(data.url || '')
    setMethod(data.method || 'GET')
    setHeaders(data.headers || [['', '']])
    setExtractVars(data.extractVars || [['', '', '', false]])
    setResponseData(data.responseData || [{ name: '', data: '', context: '' }])
    setBody(data.body || '{}')
    setUseHeader(!!data.headers)
    setUseBody(!!data.body)
    setUseExtractVars(!!data.extractVars)
    setUseResponseData(!!data.responseData)
    setUseSpeechDuringWebhook(data.text || data.prompt)
    setUseAuth(!!data.auth)
    setAuthToken(data.auth?.token || '')
    setAuthType(data.auth?.type || null)
    setEncodeAuth(data.auth?.encode || false)
    setTags(data.tag || [])
  }, [data])

  // useEffect for when useExtractVars is toggled, if its false then clear the validation errors
  useEffect(() => {
    if (!useExtractVars) {
      setVariableValidationErrors([])
    }
  }, [useExtractVars])

  return (
    <div className="space-y-2.5">
      <div className="space-y-1.5">
        <label>Name:</label>
        <input
          type="text"
          value={name}
          onChange={e => setName(e.target.value)}
          className="border border-gray-300 p-1.5 rounded w-full"
        />
      </div>

      <ExtractInfo
        setExtractVars={setExtractVars}
        useExtractVars={useExtractVars}
        extractVars={extractVars}
        setUseExtractVars={setUseExtractVars}
        validationErrors={variableValidationErrors}
        extractVarSettings={extractVarSettings}
        setExtractVarSettings={setExtractVarSettings}
        setValidationErrors={setVariableValidationErrors}
        isWebHook
      />

      <div className="border p-2.5 rounded shadow-sm bg-white">
        <div className="flex items-center justify-between mb-2.5">
          <h2 className="text-base">Webhook</h2>
          <button
            onClick={() => setIsExpanded(!isExpanded)}
            className="text-base"
          >
            {isExpanded ? <FaChevronUp /> : <FaChevronDown />}
          </button>
        </div>
        <p className="text-sm text-gray-600 mt-0.5">
          Add API details to fetch data from an external source.
        </p>
        {isExpanded && (
          <div className="space-y-2.5">
            <div className="flex items-center space-x-2.5">
              <select
                value={method}
                onChange={e => setMethod(e.target.value)}
                className="border border-gray-300 p-1.5 rounded text-sm"
              >
                <option value="GET">GET</option>
                <option value="POST">POST</option>
                <option value="PATCH">PATCH</option>
                <option value="PUT">PUT</option>
                <option value="DELETE">DELETE</option>
              </select>
              <input
                type="text"
                value={url}
                onChange={e => setURL(e.target.value)}
                placeholder="Enter URL"
                className="border border-gray-300 p-1.5 rounded flex-1 text-sm"
              />
            </div>
            <div className="space-y-1.5" style={{ zIndex: 9999 }}>
              <div
                className="flex items-center justify-between"
                style={{ zIndex: 9999 }}
              >
                <div
                  className="flex items-center gap-1.5"
                  style={{ zIndex: 9999 }}
                >
                  <Switch
                    checked={useAuth}
                    onChange={() => setUseAuth(!useAuth)}
                    className={`${
                      useAuth ? 'bg-indigo-600' : 'bg-gray-200'
                    } relative inline-flex h-4 w-7 items-center rounded-full`}
                    style={{ zIndex: 9999 }}
                  >
                    <span className="sr-only" style={{ zIndex: 9999 }}>
                      Enable Authorization
                    </span>
                    <span
                      className={`${
                        useAuth ? 'translate-x-4' : 'translate-x-0.5'
                      } inline-block h-2.5 w-2.5 transform rounded-full bg-white transition`}
                      style={{ zIndex: 9999 }}
                    />
                  </Switch>
                  <span
                    className="text-sm font-medium"
                    style={{ zIndex: 9999 }}
                  >
                    Authorization
                  </span>
                </div>
              </div>

              {useAuth && (
                <div
                  className="flex items-center space-x-2.5"
                  style={{ zIndex: 9999 }}
                >
                  <select
                    value={authType}
                    onChange={e => setAuthType(e.target.value)}
                    className="border border-gray-300 p-1.5 rounded text-xs"
                    style={{ zIndex: 9999 }}
                  >
                    <option value={null}>None</option>
                    <option value="Bearer">Bearer</option>
                    <option value="Basic">Basic</option>
                  </select>
                  <input
                    type="text"
                    value={authToken}
                    onChange={e => setAuthToken(e.target.value)}
                    placeholder="Enter token"
                    className="border border-gray-300 p-1.5 rounded flex-1 text-xs"
                    style={{ zIndex: 9999 }}
                  />
                  <div
                    className="flex items-center gap-1.5"
                    style={{ zIndex: 9999 }}
                  >
                    <Tooltip
                      content="Encode Token in Base 64"
                      side="top"
                      style={{ zIndex: 9999 }}
                    >
                      <div className="flex items-center">
                        <input
                          type="checkbox"
                          id="encodeAuth"
                          checked={encodeAuth}
                          onChange={e => setEncodeAuth(e.target.checked)}
                          className="rounded-sm border-gray-300 text-indigo-600 focus:ring-indigo-500"
                        />
                        <label
                          htmlFor="encodeAuth"
                          className="ml-1.5 text-xs font-medium text-gray-700"
                        >
                          Encode
                        </label>
                      </div>
                    </Tooltip>
                  </div>
                </div>
              )}
            </div>

            {/* Headers section */}
            <div className="space-y-1.5">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-1.5">
                  <Switch
                    checked={useHeader}
                    onChange={() => setUseHeader(!useHeader)}
                    className={`${
                      useHeader ? 'bg-indigo-600' : 'bg-gray-200'
                    } relative inline-flex h-4 w-7 items-center rounded-full`}
                  >
                    <span className="sr-only">Enable Headers</span>
                    <span
                      className={`${
                        useHeader ? 'translate-x-4' : 'translate-x-0.5'
                      } inline-block h-2.5 w-2.5 transform rounded-full bg-white transition`}
                    />
                  </Switch>
                  <span className="text-sm font-medium">Headers</span>
                </div>
                {useHeader && (
                  <button
                    onClick={addHeader}
                    className="bg-indigo-500 text-white p-1.5 rounded text-[11px]"
                  >
                    Add Header
                  </button>
                )}
              </div>
              {useHeader
                && headers.map((header, index) => (
                  <div key={index} className="flex items-center space-x-1.5">
                    <input
                      type="text"
                      placeholder="Key"
                      value={header[0]}
                      onChange={e =>
                        updateHeader(index, e.target.value, header[1])}
                      className="border border-gray-300 p-1.5 rounded w-full text-[11px]"
                    />
                    <input
                      type="text"
                      placeholder="Value"
                      value={header[1]}
                      onChange={e =>
                        updateHeader(index, header[0], e.target.value)}
                      className="border border-gray-300 p-1.5 rounded w-full text-[11px]"
                    />
                    <button
                      onClick={() => removeHeader(index)}
                      className="p-1.5 rounded-"
                    >
                      <RxCross2 color="red" />
                    </button>
                  </div>
                ))}
            </div>

            {/* Body section */}
            <div className="space-y-1.5">
              <div className="flex items-center gap-1.5">
                <Switch
                  checked={useBody}
                  onChange={() => setUseBody(!useBody)}
                  className={`${
                    useBody ? 'bg-indigo-600' : 'bg-gray-200'
                  } relative inline-flex h-4 w-7 items-center rounded-full`}
                >
                  <span className="sr-only">Enable Body</span>
                  <span
                    className={`${
                      useBody ? 'translate-x-4' : 'translate-x-0.5'
                    } inline-block h-2.5 w-2.5 transform rounded-full bg-white transition`}
                  />
                </Switch>
                <span className="text-sm font-medium">Body</span>
              </div>
              {useBody && (
                <textarea
                  value={body}
                  onChange={e => setBody(e.target.value)}
                  className="border border-gray-300 p-1.5 rounded w-full text-[11px] h-20"
                  placeholder="Enter request body (JSON)"
                />
              )}
            </div>

            {/* Timeout section */}
            <div className="space-y-1.5 mt-2.5">
              <div className="flex items-center gap-1.5">
                <span className="text-sm font-medium">Timeout (seconds)</span>
                <input
                  type="number"
                  max={999}
                  value={timeoutValue}
                  onChange={e => setTimeoutValue(Number.parseInt(e.target.value))}
                  className="border border-gray-300 p-1.5 rounded w-28 text-[11px]"
                  placeholder="Timeout in secs"
                />
              </div>
            </div>

            {/* Reroute toggle */}
            <div className="flex items-center space-x-1.5 mt-2.5">
              <span className="text-sm font-medium">
                Reroute through server
              </span>
              <Switch
                checked={useReroute}
                onChange={setUseReroute}
                className={`${
                  useReroute ? 'bg-indigo-600' : 'bg-gray-200'
                } relative inline-flex h-4 w-7 items-center rounded-full`}
              >
                <span className="sr-only">Reroute through server</span>
                <span
                  className={`${
                    useReroute ? 'translate-x-4' : 'translate-x-0.5'
                  } inline-block h-2.5 w-2.5 transform rounded-full bg-white transition`}
                />
              </Switch>
              <LightTooltip
                title={(
                  <span>
                    Rerouting through the server helps prevent CORS issues.
                    Disable only if you're experiencing problems with the
                    rerouted requests.
                    <br />
                    <br />
                    Warning: Disabling may cause CORS errors for some API
                    endpoints.
                  </span>
                )}
                placement="right-start"
                arrow
              >
                <HelpOutlineIcon
                  color="primary"
                  style={{ paddingLeft: '4px' }}
                />
              </LightTooltip>
            </div>

            {/* Test API Request button */}
            <div className="flex justify-center">
              <button
                onClick={testApiRequest}
                className="bg-green-500 text-white p-1.5 rounded text-sm flex items-center gap-1.5"
                disabled={isLoading}
              >
                {isLoading
                  ? (
                      'Testing...'
                    )
                  : (
                      <>
                        <FaPlay />
                        Test API Request
                      </>
                    )}
              </button>
            </div>

            {error && <div className="text-red-500 text-center">{error}</div>}

            {/* API Response section */}
            {apiResponse && (
              <div className="mt-2.5 space-y-1.5">
                <h3 className="text-base font-semibold">API Response:</h3>
                <div className="bg-gray-100 p-2.5 rounded overflow-x-auto">
                  {renderJsonWithExtract(apiResponse)}
                </div>
              </div>
            )}

            <div className="flex items-center justify-between">
              <div className="flex items-center gap-1.5">
                <Switch
                  checked={useResponseData}
                  onChange={() => setUseResponseData(!useResponseData)}
                  className={`${
                    useResponseData ? 'bg-indigo-600' : 'bg-gray-200'
                  } relative inline-flex h-4 w-7 items-center rounded-full`}
                >
                  <span className="sr-only">Enable Response Data</span>
                  <span
                    className={`${
                      useResponseData ? 'translate-x-4' : 'translate-x-0.5'
                    } inline-block h-2.5 w-2.5 transform rounded-full bg-white transition`}
                  />
                </Switch>
                <span className="text-sm font-medium">Response Data</span>
              </div>
              {useResponseData && (
                <button
                  onClick={addResponseData}
                  className="bg-indigo-500 text-white p-1.5 rounded text-[11px]"
                >
                  Add Response Data
                </button>
              )}
            </div>

            {/* Response Data Extraction section */}
            {useResponseData && (
              <div className="space-y-2.5" ref={responseDataRef}>
                <h3 className="text-base font-semibold">
                  Extracted Response Data:
                </h3>
                {responseData.map((item, index) => (
                  <div
                    key={index}
                    className="space-y-1.5 p-2.5 bg-gray-50 rounded-"
                  >
                    <div className="flex justify-between items-center">
                      <input
                        type="text"
                        placeholder="Variable name"
                        value={item.name}
                        onChange={e =>
                          updateResponseData(index, 'name', e.target.value)}
                        className="border border-gray-300 p-1.5 rounded w-1/2 text-[11px]"
                      />
                      <button onClick={() => removeResponseData(index)}>
                        <RxCross2 color="red" />
                      </button>
                    </div>
                    <div>
                      <input
                        type="text"
                        placeholder="JSONPath (e.g. $.data.items[0].id)"
                        value={item.data}
                        onChange={e => updateResponseData(index, 'data', e.target.value)}
                        className="border border-gray-300 p-1.5 rounded w-full text-[11px]"
                      />
                      {item.data && !isPathValid(item.data) && (
                        <div className="text-gray-500 text-2xs mt-0.5">
                          Invalid expression. Supported formats: jsonpath, jmespath, dot notation
                        </div>
                      )}
                    </div>
                    <input
                      type="text"
                      placeholder="Additional context and description"
                      value={item.context}
                      onChange={e =>
                        updateResponseData(index, 'context', e.target.value)}
                      className="border border-gray-300 p-1.5 rounded w-full text-[11px]"
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>

      <PathwayResponse
        responseVariables={responseData}
        responsePathways={responsePathways}
        setResponsePathways={setResponsePathways}
      />

      <div className="border p-2.5 rounded shadow-sm">
        <div className="flex items-center justify-between">
          <div>
            <label className="text-base">Send Speech During Webhook</label>
            <p className="text-sm text-gray-600 mt-0.5">
              Enable speech output while the webhook is running in the
              background.
            </p>
          </div>
          <Switch
            checked={useSpeechDuringWebhook}
            onChange={() => setUseSpeechDuringWebhook(!useSpeechDuringWebhook)}
            className={`${
              useSpeechDuringWebhook ? 'bg-indigo-600' : 'bg-gray-200'
            } relative inline-flex h-4 w-7 items-center rounded-full`}
          >
            <span className="sr-only">Enable Speech During Webhook</span>
            <span
              className={`${
                useSpeechDuringWebhook ? 'translate-x-4' : 'translate-x-0.5'
              } inline-block h-2.5 w-2.5 transform rounded-full bg-white transition`}
            />
          </Switch>
        </div>
        {useSpeechDuringWebhook && (
          <>
            <div className="flex items-center space-x-2.5 mt-2.5">
              <Switch
                checked={useStaticText}
                onChange={setUseStaticText}
                className={`${
                  useStaticText ? 'bg-indigo-600' : 'bg-gray-200'
                } relative inline-flex h-4 w-7 items-center rounded-full`}
              >
                <span className="sr-only">Static Text</span>
                <span
                  className={`${
                    useStaticText ? 'translate-x-4' : 'translate-x-0.5'
                  } inline-block h-2.5 w-2.5 transform rounded-full bg-white transition`}
                />
              </Switch>
              <label>
                Static Text
                {' '}
                <span className="italic text-sm">
                  (Use a specific dialogue. Uncheck for AI-generated text)
                </span>
              </label>
            </div>

            {useStaticText && (
              <>
                <div className="mt-2.5">Text:</div>
                <textarea
                  placeholder="Exact text to be spoken by the agent"
                  value={text}
                  onChange={e => setText(e.target.value)}
                  className="border border-gray-300 p-1.5 rounded w-full text-sm mt-1.5 min-h-[100px]"
                />
              </>
            )}
            {!useStaticText && (
              <>
                <div className="mt-2.5">Prompt:</div>
                <textarea
                  placeholder="Provide a goal/prompt for the agent (e.g., Ask for the customer's name)"
                  value={prompt}
                  onChange={e => setPrompt(e.target.value)}
                  className="border border-gray-300 p-1.5 rounded w-full text-sm mt-1.5 min-h-[100px]"
                />
              </>
            )}
          </>
        )}
      </div>

      <ModelOptions
        modelOptions={modelOptions}
        setModelOptions={setModelOptions}
        noSkipUserResponse
      />

      <GlobalNodeOptions
        isGlobal={isGlobal}
        setIsGlobal={setIsGlobal}
        globalPathwayLabel={globalPathwayLabel}
        setGlobalPathwayLabel={setGlobalPathwayLabel}
        globalPathwayDescription={globalPathwayDescription}
        setGlobalPathwayDescription={setGlobalPathwayDescription}
        isForwardingEnabled={isForwardingEnabled}
        setIsForwardingEnabled={setIsForwardingEnabled}
        forwardingNode={forwardingNode}
        setForwardingNode={setForwardingNode}
        enableGlobalAutoReturn={enableGlobalAutoReturn}
        setEnableGlobalAutoReturn={setEnableGlobalAutoReturn}
        prevNodePathwayLabel={prevNodePathwayLabel}
        setPrevNodePathwayLabel={setPrevNodePathwayLabel}
        prevNodePathwayDescription={prevNodePathwayDescription}
        setPrevNodePathwayDescription={setPrevNodePathwayDescription}
      />

      <NodeTag nodeTag={tags} setNodeTag={setTags} />

      <div className="mt-2.5 flex flex-row items-center">
        <button
          onClick={async () => {
            if (validateVariables()) {
              let updatedEdges = elements.edges
              const updatedNodes = elements.nodes.map((element_node) => {
                // element_node.draggable = true;
                if (element_node.id === id) {
                  const newEdges = []
                  const node = structuredClone(element_node)
                  for (const responsePathway of responsePathways) {
                    console.log('Response pathway', responsePathway)
                    if (responsePathway[0] === 'Default/Webhook Completion') {
                      if (
                        responsePathway[3].id === ''
                        || responsePathway[3].name === ''
                      ) {
                        toast.warn(
                          'Please select the default node to path to after webhook completion.',
                        )
                        isWarnToast = true
                        return node
                      }
                      const edge = {
                        id: `reactflow__edge-${id}-${responsePathway[3].id}`,
                        animated: true,
                        sourceHandle: null,
                        targetHandle: null,
                        type: 'custom',
                        source: id,
                        target: responsePathway[3].id,
                        data: {
                          label: `${responsePathway[0]}`,
                        },
                      }
                      newEdges.push(edge)
                      continue
                    }
                    if (
                      !responsePathway[1]
                      || !responsePathway[2]
                      || !responsePathway[3].id
                      || !responsePathway[3].name
                    ) {
                      toast.warn(
                        'Fields in \'Pathway after Webhook Response\' not filled out. Please fill in all fields.',
                      )
                      isWarnToast = true
                      break
                    }

                    const edge = {
                      id: `reactflow__edge-${id}-${responsePathway[3].id}`,
                      animated: true,
                      sourceHandle: null,
                      targetHandle: null,
                      type: 'custom',
                      source: id,
                      target: responsePathway[3].id,
                      data: {
                        label: `${responsePathway[0]} ${responsePathway[1]} ${responsePathway[2]}`,
                      },
                    }
                    newEdges.push(edge)
                  }

                  const currentEdges = elements.edges

                  const filteredEdges = currentEdges.filter(
                    edge => edge.source !== id,
                  )
                  updatedEdges = [...filteredEdges, ...newEdges]
                  node.data.name = name
                  node.type = 'Webhook'
                  if (
                    url.startsWith('http://')
                    || (node.data.url && node.data.url.startsWith('http://'))
                  ) {
                    isWarnToast = true
                    toast.warn(
                      'Please use HTTPS instead of HTTP for security reasons.',
                    )
                    return node
                  }
                  node.data.url = url
                  node.data.method = method
                  const nonEmptyHeaders = headers.filter(
                    header => header[0] !== '' || header[1] !== '',
                  )
                  if (nonEmptyHeaders.length > 0 && useHeader) {
                    node.data.headers = nonEmptyHeaders
                  }
                  else {
                    delete node.data.headers
                  }

                  if (useExtractVars) {
                    if (extractVarSettings) {
                      node.data.extractVarSettings = extractVarSettings
                    }
                    const resVars = extractVars.filter((varArr) => {
                      if (varArr[0] === 'bland-prompt') {
                        return true
                      }
                      // For all variables excluding "bland-prompt", ensure all fields are non-empty
                      return (
                        varArr[0] !== '' && varArr[1] !== '' && varArr[2] !== ''
                      )
                    })

                    const nonPromptVars = resVars.filter(
                      varArr => varArr[0] !== 'bland-prompt',
                    )

                    if (nonPromptVars.length === 0) {
                      // If there are no valid variables, remove extractVars completely
                      delete node.data.extractVars
                      setExtractVars([])
                    }
                    else {
                      // Save the variables including the "bland-prompt" if it exists and is non-empty
                      node.data.extractVars = resVars
                      setExtractVars(resVars)
                    }
                  }
                  else {
                    delete node.data.extractVars
                  }

                  if (body !== '{}' && useBody) {
                    node.data.body = body
                  }
                  else {
                    delete node.data.body
                  }

                  if (useAuth) {
                    node.data.auth = {
                      type: authType === 'None' ? null : authType,
                      token: authToken,
                      encode: encodeAuth,
                    }
                  }
                  else {
                    delete node.data.auth
                  }

                  if (isForwardingEnabled && forwardingNode) {
                    node.data.forwardingNode = forwardingNode
                  }
                  else {
                    delete node.data.forwardingNode
                  }

                  if (useResponseData && responseData.length > 0) {
                    node.data.responseData = responseData
                  }
                  else {
                    delete node.data.responseData
                  }
                  node.data.timeoutValue = timeoutValue
                  if (useSpeechDuringWebhook) {
                    if (useStaticText) {
                      node.data.text = text
                      setPrompt('')
                      delete node.data.prompt
                    }
                    else {
                      node.data.prompt = prompt
                      setText('')
                      delete node.data.text
                    }
                  }
                  else {
                    delete node.data.text
                    delete node.data.prompt
                  }
                  if (
                    responsePathways.length > 0
                    && responsePathways[0][0] !== ''
                  ) {
                    node.data.responsePathways = responsePathways
                  }
                  else {
                    delete node.data.responsePathways
                  }

                  if (modelOptions) {
                    if (modelOptions.newTemperature) {
                      modelOptions.newTemperature = Number.parseFloat(
                        modelOptions.newTemperature.toFixed(1),
                      )
                      delete modelOptions.temperature
                    }
                    else {
                      delete modelOptions.newTemperature
                    }
                    if (modelOptions.temperature) {
                      modelOptions.temperature = Number.parseFloat(
                        modelOptions.temperature.toFixed(1),
                      )
                    }
                    else {
                      delete modelOptions.temperature
                    }
                    if (modelOptions.interruptionThreshold) {
                      modelOptions.interruptionThreshold = Number.parseInt(
                        modelOptions.interruptionThreshold,
                      )
                    }

                    if (
                      modelOptions.conditionOverridesGlobalPathway
                      && modelOptions.conditionOverridesGlobalPathway === true
                    ) {
                      modelOptions.conditionOverridesGlobalPathway = true
                    }
                    else {
                      delete modelOptions.conditionOverridesGlobalPathway
                    }

                    node.data.modelOptions = modelOptions
                  }
                  else {
                    delete node.data.modelOptions
                  }
                  if (isGlobal) {
                    node.data.isGlobal = true
                    node.data.globalLabel = globalPathwayLabel
                    if (globalPathwayDescription) {
                      node.data.globalDescription = globalPathwayDescription
                    }
                    else {
                      delete node.data.globalDescription
                    }
                    if (!enableGlobalAutoReturn) {
                      // if auto return is disabled/treating like normal node
                      node.data.enableGlobalAutoReturn = false
                      if (prevNodePathwayLabel) {
                        node.data.prevNodePathwayLabel = prevNodePathwayLabel
                      }
                      if (prevNodePathwayDescription) {
                        node.data.prevNodePathwayDescription
                          = prevNodePathwayDescription
                      }
                    }
                    else {
                      updatedEdges = updatedEdges.filter(
                        edge => edge.target !== id && edge.source !== id,
                      )
                      delete node.data.enableGlobalAutoReturn
                      delete node.data.prevNodePathwayLabel
                      delete node.data.prevNodePathwayDescription
                    }
                  }
                  else {
                    delete node.data.isGlobal
                    delete node.data.globalLabel
                    delete node.data.globalDescription
                    delete node.data.enableGlobalAutoReturn
                    delete node.data.prevNodePathwayLabel
                    delete node.data.prevNodePathwayDescription
                  }
                  if (tags || tags === null) {
                    node.data.tag = tags
                  }
                  else {
                    delete node.data.tag
                  }
                  // setEdges(newEdges);
                  return node
                }
                return element_node
              })

              if (!isWarnToast) {
                triggerUpdate(
                  {
                    nodes: updatedNodes,
                    edges: updatedEdges,
                  },
                  false,
                )

                setIsOpen(false)
                // exportFlow();
              }
              isWarnToast = false
            }
          }}
          className="bg-indigo-500 text-white py-1.5 px-2.5 rounded-"
        >
          Save
        </button>
        {variableValidationErrors.length > 0 && (
          <div className="relative group ml-1.5">
            <AlertCircle className="h-4 w-4 text-red-500 " />
            <span className="absolute left-full ml-1.5 top-1/2 transform -translate-y-1/2 bg-red-100 text-red-800 text-2xs font-medium px-1.5 py-px rounded-sm hidden group-hover:block whitespace-nowrap">
              Some variables are missing fields
            </span>
          </div>
        )}
      </div>
    </div>
  )
}

export default WebhookNodeModal
