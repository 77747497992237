import { Input } from '@heroui/react'
import MultiInput from 'components/Hero/MultiInput'
import NestedForm from 'components/Hero/NestedForm'
import SwitchInput from 'components/Hero/SwitchInput'

const pronunciationGuideFields = [
  {
    name: 'word',
    component: Input,
    props: { placeholder: 'Enter word', label: 'Word', size: 'sm', variant: 'faded' },
  },
  {
    name: 'pronunciation',
    component: Input,
    props: { placeholder: 'Enter pronunciation', label: 'Pronunciation', size: 'sm', variant: 'faded' },
  },
  {
    name: 'case_sensitive',
    component: SwitchInput,
    props: { label: 'Case Sensitive' },
  },
  {
    name: 'spaced',
    component: SwitchInput,
    props: { label: 'Spaced' },
  },
]

export default function PronounciationGuide() {
  return (
    <MultiInput
      name="pronunciation_guide"
      label="Pronunciation Guide"
      description="The pronunciation guide is an array of objects that guides the LLM on how to say specific words. This is great for situations with complicated terms or names."
      className="w-full"
      labels={{
        add: 'Add Word',
        remove: 'Remove',
      }}
    >
      {({ index }) => (
        <NestedForm
          title={`Word ${index + 1}`}
          fields={pronunciationGuideFields}
          name={`pronunciation_guide.${index}`}
          className="w-full"
        />
      )}
    </MultiInput>
  )
}
