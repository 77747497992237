import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { $fetch } from 'utils/fetch'
import { toast } from 'react-toastify'
import { Form, Input, Textarea, Button } from '@heroui/react'
import DragDropUpload from 'components/core/DragDropUpload'
import { Sparkles } from 'lucide-react'

export function Mp3ToPathway() {
  const navigate = useNavigate();
  const [audioFile, setAudioFile] = useState(null)
  const [isUploading, setIsUploading] = useState(false)

  async function pollStatus(jobId) {
    const maxAttempts = 60; 
    const pollInterval = 5000;
    for (let attempt = 0; attempt < maxAttempts; attempt++) {
      try {
        const response = await $fetch(`/v1/pathway/generate/mp3/status/${jobId}`);
        if (response.errors) {
          throw new Error(response.message || 'Error checking status');
        }
        if (response.data.ready) {
          return response.data;
        }
        await new Promise(resolve => setTimeout(resolve, pollInterval));
      } catch (err) {
        console.error('Status check failed:', err);
        throw new Error('Failed to check processing status');
      }
    }
    throw new Error('Processing timed out. Please try again.');
  }

  const handleFilesAdded = (files) => {
    console.log(files)
    if (files && files.length > 0) {
      const file = files[0];
      setAudioFile(file);      
    } else {
      setAudioFile(null);
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsUploading(true);

    const formData = new FormData(e.currentTarget);

    // Check for audio file
    if (!audioFile) {
      toast.error('An MP3 file is required');
      return;
    }
    
    // Append the audio file to the FormData
    formData.append('audio', audioFile);

    try {
      const { data } = await $fetch('/v1/pathway/generate/mp3/upload', {
        method: 'POST',
        body: formData,
      });

      const { jobId } = data;
      const result = await pollStatus(jobId);
      console.log(result)
      toast.success(result.message || 'Pathway created successfully!');
      return navigate(`/dashboard/convo-pathways?id=${result.pathway_id}&view=splash`);
    } catch (err) {
      console.error('Submission failed:', err);
      toast.error(err.message || 'Upload failed. Please try again.');
    } finally {
      setIsUploading(false);
    }
  }

  return (
    <Form onSubmit={onSubmit} validationBehavior="native" className="space-y-4">
      <h1 className="text-2xl font-bold text-black">Create a pathway from audio</h1>
      <Input
        name="inputName"
        label="Pathway Name"
        placeholder="e.g. Customer Onboarding Flow"
        isRequired
      />
      <Input
        name="inputDesc"
        label="Description"
        placeholder="Step-by-step guide for new customer setup"
        isRequired
      />
      <Textarea
        name="context"
        label="Additional Context"
        placeholder="Add any relevant context or notes..."
      />
     <DragDropUpload
        label="File Upload"
        onFilesAdded={handleFilesAdded}
        uploadedFiles={audioFile ? [audioFile] : []}
        accept={{
          'audio/mp3': [],
          'audio/mpeg': [],
        }}
      />
      <Button type="submit" color="primary" isLoading={isUploading} className="ml-auto">
        <Sparkles size={16} />
        {isUploading ? 'Generating Pathway...' : 'Generate Pathway'}
      </Button>
    </Form>
  );
}
