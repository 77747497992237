import { OnboardingContext } from 'contexts/onboardingContext'
import { useContext } from 'react'

export function useOnboarding() {
  const context = useContext(OnboardingContext)

  if (!context) {
    throw new Error('useOnboardingContext must be used within a OnboardingProvider')
  }

  return context
}
