import { Button } from '@heroui/react'
import { FileText } from 'lucide-react'
import { useEffect, useState } from 'react'
import SwitchInput from '../../Hero/SwitchInput'
import MemorySidebar from './MemorySidebar'

export default function MemoryToggle({ onChange, value }) {
  const [memoryEnabled, setMemoryEnabled] = useState(!!value)
  const [showSidebar, setShowSidebar] = useState(false)

  useEffect(() => {
    setMemoryEnabled(!!value)
  }, [value])

  const handleToggleChange = (isEnabled) => {
    setMemoryEnabled(isEnabled)

    if (typeof onChange === 'function') {
      onChange(isEnabled ? true : null)
    }
  }

  const handleViewDetails = () => {
    setShowSidebar(true)
  }

  const handleCloseSidebar = () => {
    setShowSidebar(false)
  }

  return (
    <div className="w-full">
      <div className="space-y-2 w-full">
        <SwitchInput
          label="Enable Memory"
          description="When enabled, the agent will remember previous conversations with this caller."
          value={memoryEnabled}
          onValueChange={handleToggleChange}
          size="sm"
          aria-label="Enable memory"
          className="justify-between"
        />
        {memoryEnabled && (
          <div className="mt-2 justify-self-start ">
            <Button
              size="sm"
              variant="outline"
              color="primary"
              className="w-full"
              onPress={handleViewDetails}
              startContent={<FileText className="h-4 w-4" />}
              disabled={value === true || !value}
            >
              View Memory Details
            </Button>
          </div>
        )}
      </div>

      <MemorySidebar
        memoryId={value}
        onClose={handleCloseSidebar}
        isOpen={showSidebar && !!value}
      />
    </div>
  )
}
