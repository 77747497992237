import { useEffect, useState } from 'react'
import Retool from 'react-retool'
import { $fetch } from 'utils/fetch'
import 'reactflow/dist/style.css'

function RetoolDashboard({ appID }) {
  const [retoolEmbedUrl, setRetoolEmbedUrl] = useState('')

  useEffect(() => {
    // make a POST request to the backend to get the embed URL
    const options = {
      method: 'GET',
    }
    $fetch(`/dashboards/retool/${appID.replace('dashboard-', '')}`, options)
      .then(res => res)
      .then((data) => {
        setRetoolEmbedUrl(data.embedUrl)
      })
  }, [appID])

  return (
    <div className="h-screen  text-gray-900 ">
      {retoolEmbedUrl ? <Retool url={retoolEmbedUrl} /> : <p>Loading...</p>}
    </div>
  )
}

export default RetoolDashboard
