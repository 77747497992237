import { 
  Button, 
  Modal, 
  ModalContent, 
  ModalHeader, 
  ModalBody, 
  ModalFooter,
  addToast,
  Link,
  useDisclosure,
  Select,
  SelectItem,
  Alert
} from '@heroui/react'
import { MessageSquare, Phone, AlertTriangle, X, ChevronDown, Link as LinkIcon } from 'lucide-react'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { $fetch } from 'utils/fetch'
import PhoneInput from 'components/Hero/PhoneInput'
import { parsePhoneNumberWithError } from 'libphonenumber-js'
import InboundNumberDropdown from 'components/Dashboard/ConvoPathways/Components/shared/InboundNumberDropdown'

export default function SMSTestModal({ 
  isOpen, 
  onClose, 
  selectedNumber = null, 
  availableNumbers = [], 
  onSuccess = () => {},
  isViewPathway = false,
  pathwayId = null
}) {
  const [userNumber, setUserNumber] = useState('')
  const [agentNumber, setAgentNumber] = useState(selectedNumber || '')
  const [displayAgentNumber, setDisplayAgentNumber] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isChecking, setIsChecking] = useState(false)
  const [inputError, setInputError] = useState('')
  const [existingConversation, setExistingConversation] = useState(null)
  const [isLinkingNumber, setIsLinkingNumber] = useState(false)
  const { isOpen: isConfirmOpen, onOpen: onConfirmOpen, onClose: onConfirmClose } = useDisclosure()
  const navigate = useNavigate()
  
  // Function to get effective dropdown options
  const getEffectiveNumbers = () => {
    // If we have a selectedNumber but it's not in availableNumbers, add it
    if (selectedNumber && !availableNumbers.some(n => n.phone_number === selectedNumber)) {
      return [
        { phone_number: selectedNumber },
        ...availableNumbers
      ];
    }
    return availableNumbers;
  };

  useEffect(() => {
    if (selectedNumber) {
      setAgentNumber(selectedNumber);
      setDisplayAgentNumber(formatPhoneNumber(selectedNumber));
    } else if (availableNumbers.length > 0 && !agentNumber) {
      setAgentNumber(availableNumbers[0].phone_number);
      setDisplayAgentNumber(formatPhoneNumber(availableNumbers[0].phone_number));
    }
  }, [selectedNumber, availableNumbers]);


  // Format phone number for display
  const formatPhoneNumber = (number) => {
    if (!number || typeof number !== 'string') return number;
    try {
      return parsePhoneNumberWithError(number)?.formatNational() || number;
    } catch (error) {
      return number;
    }
  };

  const validatePhoneNumber = (number) => {
    // PhoneInput component handles validation internally
    return !!number && number.length > 5
  }

  const handleUserNumberChange = (value) => {
    setUserNumber(value)
    setInputError('')
    setExistingConversation(null)
  }

  const handleAgentNumberChange = (e) => {
    setAgentNumber(e.target.value);
    setDisplayAgentNumber(formatPhoneNumber(e.target.value));
  }

  const linkNumberToPathway = async (phoneNumber) => {
    if (!pathwayId) {
      addToast({
        title: 'Missing pathway information',
        description: 'Cannot link number without pathway ID',
        color: 'danger'
      });
      return;
    }
    
    setIsLinkingNumber(true);
    try {
      const response = await $fetch('/v1/sms/number/update', {
        method: 'POST',
        body: {
          phone_number: phoneNumber,
          pathway_id: pathwayId
        }
      });
      
      if (response.status === 'success') {
        addToast({
          title: 'Number linked successfully',
          description: `${formatPhoneNumber(phoneNumber)} is now linked to this pathway`,
          color: 'success'
        });
        
        // Set this number as the selected "from" number
        setAgentNumber(phoneNumber);
        setDisplayAgentNumber(formatPhoneNumber(phoneNumber));
        
        return true;
      } else {
        throw new Error(response.message || 'Failed to link number');
      }
    } catch (error) {
      addToast({
        title: 'Failed to link number',
        description: error.message || 'An unexpected error occurred',
        color: 'danger'
      });
      return false;
    } finally {
      setIsLinkingNumber(false);
    }
  };

  const checkExistingConversation = async () => {
    if (!validatePhoneNumber(userNumber)) {
      setInputError('Please enter a valid phone number')
      return null
    }

    setIsChecking(true)
    try {
      const response = await $fetch('/v1/sms/conversations', {
        method: 'GET',
        query: {
            filters: [{
                field: 'user_number',
                operator: 'eq',
                value: encodeURIComponent(userNumber)
            }]
        }
      });

      if (response?.data?.length > 0) {
        const existingConv = response.data[0]
        setExistingConversation(existingConv)
        onConfirmOpen()
        return existingConv
      }
      return null
    } catch (error) {
      console.error('Error checking for existing conversations:', error)
      return null
    } finally {
      setIsChecking(false)
    }
  }

  const deleteExistingConversation = async () => {
    if (!existingConversation?.id) return false
    
    try {
      await $fetch(`/v1/sms/conversations/${existingConversation.id}`, {
        method: 'DELETE'
      })
      
      addToast({
        title: 'Conversation deleted',
        description: 'The existing conversation has been deleted',
        color: 'info'
      })
      
      setExistingConversation(null)
      return true
    } catch (error) {
      addToast({
        title: 'Failed to delete conversation',
        description: error.message || 'An unexpected error occurred',
        color: 'danger'
      })
      return false
    }
  }

  const handleTestSMS = async () => {
    if (!validatePhoneNumber(userNumber)) {
      setInputError('Please enter a valid phone number')
      return
    }

    if (!agentNumber) {
      addToast({
        title: 'No agent number selected',
        description: 'Please select an SMS number to send from',
        color: 'warning'
      })
      return
    }

    // First check if there's an existing conversation
    const existing = await checkExistingConversation()
    
    // If there's an existing conversation, the confirmation dialog will open
    // The actual sending will happen from the confirmation dialog
    if (!existing) {
      sendTestSMS()
    }
  }

  const handleConfirmDelete = async () => {
    setIsLoading(true)
    const deleted = await deleteExistingConversation()
    if (deleted) {
      onConfirmClose()
      // Wait a moment before sending the new SMS
      setTimeout(() => {
        sendTestSMS()
      }, 500)
    } else {
      setIsLoading(false)
    }
  }

  const sendTestSMS = async () => {
    setIsLoading(true)
    
    try {
      const {data} = await $fetch('/v1/sms/trigger', {
        method: 'POST',
        body: {
          user_number: userNumber,
          agent_number: agentNumber
        }
      })
      
      addToast({
        title: 'SMS Sent Successfully',
        description: 'Check your phone for the test message',
        color: 'success'
      })
      
      onClose()
      onSuccess(data)
      

      if (!isViewPathway) {
      setTimeout(() => {
        if (data?.conversation_id) {
          navigate(`/dashboard/sms/${data.conversation_id}?tab=conversations`)
        } else {
          navigate('/dashboard/sms?tab=conversations')
        }
      }, 2000)
      }
    } catch (error) {
      addToast({
        title: 'Failed to send SMS',
        description: error.message || 'An unexpected error occurred',
        color: 'danger'
      })
    } finally {
      setIsLoading(false)
    }
  }

  const handleContinueWithoutDelete = () => {
    onConfirmClose()
    if (existingConversation?.id) {
      navigate(`/dashboard/sms/${existingConversation.id}?tab=conversations`)
    }
  }

  // Check if any of the available numbers is linked to the pathway
  const isNumberLinkedToPathway = (number) => {
    return number.sms_config && number.sms_config.pathway_id === pathwayId;
  };

  const linkedNumbers = availableNumbers.filter(isNumberLinkedToPathway);
  
  // Handle number change from InboundNumberDropdown
  const handleInboundNumberChanged = (phoneNumber) => {
    setAgentNumber(phoneNumber);
    setDisplayAgentNumber(formatPhoneNumber(phoneNumber));
    
    // Refresh the list of linked numbers
    const updatedNumber = availableNumbers.find(n => n.phone_number === phoneNumber);
    if (updatedNumber) {
      updatedNumber.sms_config = { 
        ...updatedNumber.sms_config,
        pathway_id: pathwayId
      };
    }
  };

  return (
    <>
      <Modal 
        isOpen={isOpen} 
        onClose={onClose} 
        size="md"
        hideCloseButton
        classNames={{
          base: "w-full max-w-lg",
          header: "border-b border-gray-200 p-4",
          body: "p-4",
          footer: "border-t border-gray-200 p-4"
        }}
      >
        <ModalContent>
          <ModalHeader className="flex justify-between items-center">
            <div className="flex items-center">
              <MessageSquare size={18} className="mr-2 text-gray-700" />
              <span className="text-lg font-medium text-gray-800">Test SMS Configuration</span>
            </div>
            <Button 
              isIconOnly 
              variant="light" 
              size="sm" 
              onPress={onClose}
              className="text-gray-400 hover:text-gray-600"
            >
              <X size={18} />
            </Button>
          </ModalHeader>
          
          <ModalBody>
            <div className="bg-gray-50 p-4 border-l-4 border-gray-300 mb-4">
              <div className="flex items-start">
                <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" className="text-gray-600 mr-3 mt-0.5">
                  <rect x="3" y="3" width="18" height="18" rx="2" />
                  <path d="M3 9h18M9 21V9" />
                </svg>
                <div className="text-sm text-gray-600">
                    {isViewPathway ? 'If you have an existing conversation with this number, it will be deleted and a new one will be created. This ensures that previous chats will not affect your conversation.' : 'After sending the test SMS, you\'ll be redirected to the SMS Dashboard to view the conversation.'}
                </div>
              </div>
            </div>
            
            {/* Alert when no number is linked to pathway */}
            {pathwayId && linkedNumbers.length === 0 && (
              <Alert color="warning" className="mb-4">
                <div className="flex items-start">
                  <AlertTriangle size={18} className="mr-2 text-amber-500 mt-0.5" />
                  <div>
                    <h4 className="text-sm font-medium text-amber-800">No inbound number set up</h4>
                    <p className="text-xs text-amber-700 mt-1">
                      No inbound number SMS is set up for this pathway ID. 
                      Please link a number to the pathway to send SMS messages.
                    </p>
                  </div>
                </div>
              </Alert>
            )}
            
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Send SMS From
              </label>
              
              {/* Show InboundNumberDropdown if no linked numbers and pathwayId exists */}
              {pathwayId && linkedNumbers.length === 0 ? (
                <div className="mb-2 ">
                  <InboundNumberDropdown
                    inboundNumbers={availableNumbers}
                    currentInboundNumber={agentNumber}
                    pathwayId={pathwayId}
                    versionNumber="1"
                    isSmsPathway={true}
                    onNumberChanged={handleInboundNumberChanged}

                  />
                  <p className="text-xs text-gray-500 mt-2">
                    Please select an inbound number to link to this pathway
                  </p>
                </div>
              ) : (
                <div className="relative">
                  <Select
                    placeholder="Select SMS Number"
                    value={agentNumber}
                    onChange={handleAgentNumberChange}
                    selectedKeys={agentNumber ? [agentNumber] : []}
                    startContent={<Phone size={16} className="text-gray-400" />}
                    selectorIcon={<ChevronDown size={16} className="text-gray-400" />}
                    radius="none"
                    variant="flat"
                    isDisabled={pathwayId && linkedNumbers.length === 0}
                    renderValue={(items) => {
                      return items.length > 0 
                        ? <div className="flex items-center text-gray-700">
                            {formatPhoneNumber(items[0].key)}
                          </div>
                        : <div className="text-gray-400">Select SMS Number</div>;
                    }}
                    classNames={{
                      base: "w-full",
                      trigger: "border border-gray-300 bg-white h-10 py-2 px-3 text-gray-700 focus:border-gray-400 focus:ring-0"
                    }}
                  >
                    {/* Use the getEffectiveNumbers function to include selectedNumber */}
                    {getEffectiveNumbers().map(number => (
                      <SelectItem 
                        key={number.phone_number} 
                        value={number.phone_number} 
                        textValue={formatPhoneNumber(number.phone_number)}
                      >
                        <div className="flex items-center justify-between w-full">
                          <div className="font-medium">
                            {formatPhoneNumber(number.phone_number)}
                          </div>
                          <div className="flex items-center text-xs text-gray-500">
                            {isNumberLinkedToPathway(number) && (
                              <div className="flex items-center mr-2">
                                <LinkIcon size={12} className="mr-1 text-green-500" />
                                <span className="text-green-600">Linked</span>
                              </div>
                            )}
                            {number.sms_config?.pathway_id && (
                              <span className="mr-1">ID: {number.sms_config.pathway_id.substring(0, 8)}...</span>
                            )}
                            {number.sms_config?.prompt && (
                              <span className="italic truncate max-w-[100px]">{number.sms_config.prompt.substring(0, 20)}{number.sms_config.prompt.length > 20 ? '...' : ''}</span>
                            )}
                          </div>
                        </div>
                      </SelectItem>
                    ))}
                  </Select>
                </div>
              )}
              
              {/* Show link button for selected but unlinked number */}
              {pathwayId && agentNumber && linkedNumbers.length > 0 && !linkedNumbers.some(n => n.phone_number === agentNumber) && (
                <div className="mt-2">
                  <Button
                    size="sm"
                    variant="flat"
                    color="primary"
                    className="text-xs"
                    startContent={<LinkIcon size={12} />}
                    onClick={() => linkNumberToPathway(agentNumber)}
                    isLoading={isLinkingNumber}
                  >
                    Link this number to pathway
                  </Button>
                  <p className="text-xs text-gray-500 mt-1">
                    Link this number to the current pathway to enable SMS interactions
                  </p>
                </div>
              )}
            </div>
            
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Your Phone Number
              </label>
              <PhoneInput
                value={userNumber}
                onChange={handleUserNumberChange}
                placeholder="Enter your phone number"
                fullWidth
                radius="none"
                classNames={{
                  base: "w-full",
                  inputWrapper: "h-10"
                }}
              />
            </div>
            
            <div className="flex items-center text-gray-500 text-xs mt-4">
              <MessageSquare size={14} className="mr-1" />
              <span>Need to view existing conversations? Visit the </span>
              <Link 
                href="/dashboard/sms" 
                className="text-gray-700 font-medium ml-1 hover:underline text-xs"
              >
                SMS Dashboard
              </Link>
            </div>
          </ModalBody>
          
          <ModalFooter className="flex justify-end gap-2">
            <Button 
              variant="flat"
              radius="none" 
              onPress={onClose}
              className="px-4 py-2 bg-white border border-gray-300 text-gray-700 hover:bg-gray-50"
            >
              Cancel
            </Button>
            <Button 
              radius="none"
              isLoading={isLoading || isChecking}
              onPress={handleTestSMS}
              className="px-4 py-2 bg-gray-800 text-white hover:bg-gray-900"
            >
              Send Test SMS
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Confirmation Modal for Existing Conversation */}
      <Modal 
        isOpen={isConfirmOpen} 
        onClose={onConfirmClose} 
        size="sm"
        hideCloseButton
        classNames={{
          base: "w-full max-w-md",
          header: "border-b border-gray-200 p-4",
          body: "p-4",
          footer: "border-t border-gray-200 p-4"
        }}
      >
        <ModalContent>
          <ModalHeader className="flex justify-between items-center">
            <div className="flex items-center">
              <AlertTriangle size={18} className="mr-2 text-amber-500" />
              <span className="text-lg font-medium text-gray-800">Existing Conversation</span>
            </div>
            <Button 
              isIconOnly 
              variant="light" 
              size="sm" 
              onPress={onConfirmClose}
              className="text-gray-400 hover:text-gray-600"
            >
              <X size={18} />
            </Button>
          </ModalHeader>
          
          <ModalBody>
            <p className="text-sm text-gray-600 mb-4">
              We found an existing SMS conversation with this phone number. What would you like to do?
            </p>
            
            <div className="bg-amber-50 p-3 border-l-4 border-amber-400 mb-4">
              <p className="text-xs text-gray-700">
                <strong>Note:</strong> Deleting the existing conversation will remove all message history.
              </p>
            </div>
          </ModalBody>
          
          <ModalFooter className="flex justify-end gap-2">
            <Button 
              variant="flat"
              radius="none" 
              onPress={handleContinueWithoutDelete}
              className="px-4 py-2 bg-white border border-gray-300 text-gray-700 hover:bg-gray-50"
            >
              View Existing
            </Button>
            <Button 
              radius="none"
              color="danger"
              variant="flat"
              isLoading={isLoading}
              onPress={handleConfirmDelete}
              className="px-4 py-2 bg-white border border-red-300 text-red-600 hover:bg-red-50"
            >
              Delete & Create New
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
} 