import { CardContent, CardDescription, CardTitle } from '@/components/ui/card'
import * as Icons from '@radix-ui/react-icons'
import {
  Flex,
  Button as RadixButton,
  Skeleton,
  TextField,
} from '@radix-ui/themes'
import blandlogo from 'assets/brand/logo-infinity.svg'
import heroImage from 'assets/images/ToolsHeroImg.png'
import config from 'config'
import { useAuth } from 'hooks/useAuth'
import { ArrowRight, CopyIcon, StarIcon, X } from 'lucide-react'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { Header } from './components/Header'

/* -------------------------------------------------------------------------- */
/* ----------------------------- Helper Functions ---------------------------- */
/* -------------------------------------------------------------------------- */

function getDescriptionLengthAndTruncate(description) {
  if (!description)
    return ''
  if (description.length > 100) {
    return `${description.substring(0, 100)}...`
  }
  return description
}

/* -------------------------------------------------------------------------- */
/* ----------------------------- Styled Components --------------------------- */
/* -------------------------------------------------------------------------- */

/**
 * Overall container with a light, neutral background
 * and subtle text color.
 */
const Container = styled.div`
  font-family: "Lato", sans-serif;
  margin: 0 auto;
  padding: 0px;
  background-color: #fafafa; 
  color: #333; 
  min-height: 100vh;
`

const MainContent = styled.main``

/**
 * The hero area: large top section with text on left, image on right.
 * Subtle background remains #fafafa.
 */
const HeroContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 70px;
  
  @media (max-width: 1019px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
  }
`

const HeroText = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 15px;
`

const HeroImage = styled.div`
  flex: 1;
  text-align: right;
  
  @media (max-width: 1019px) {
    text-align: left;
  }
`

/**
 * Subtle dividing line that picks up a pastel accent if you like.
 * We'll keep it fairly neutral here.
 */
const HeroLine = styled.div`
  width: 100%;
  height: 1px;
  background: #e0e0e0;
`

/**
 * Main content area: 2-column grid (sidebar + center).
 * The detail panel is absolutely positioned on top for the slide-out.
 */
const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 250px 1fr;
  gap: 20px;
  width: 100%;
  padding: 20px 70px;
  box-sizing: border-box;
  position: relative;

  @media (max-width: 1019px) {
    grid-template-columns: 1fr;
    padding: 20px;
  }
`

/**
 * Left Sidebar
 */
const SidebarContainer = styled.div`
  border-right: 1px solid #ddd;
  padding-right: 20px;

  @media (max-width: 1019px) {
    border-right: none;
    padding-right: 0;
    margin-bottom: 20px;
  }
`

const SidebarItem = styled.p`
  color: #666;
  cursor: pointer;
  font-weight: 500;
  margin: 10px 0;

  &:hover {
    color: #333;
    text-decoration: underline;
  }

  ${({ active }) =>
    active
    && `
    color: #000;
    font-weight: 600;
  `}
`

/**
 * The center container: either showing "collection icons"
 * or a "table of tools" for the selected collection.
 */
const CenterContainer = styled.div`
  overflow-y: auto;
  padding-bottom: 40px;
`

/*
   If no collection is selected, we show a grid of "collection cards."
   We'll keep them minimal with a soft border, subtle background.
*/
const CollectionsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  gap: 20px;
  margin-top: 20px;

  @media (max-width: 1019px) {
    grid-template-columns: 1fr;
  }
`

const CollectionCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 6px;
  padding: 20px;
  background-color: #fff;
  cursor: pointer;
  
  &:hover {
    background-color: #f0f0f0;
  }

  img {
    margin-bottom: 10px;
    object-fit: contain;
  }

  p {
    font-weight: 600;
    color: #333;
  }
`

/**
 * Table-like view (when a collection is selected)
 * with a dotted border and slight row striping.
 */
const TableContainer = styled.div`
  margin-top: 20px;
  border: 1px solid #ddd;
  border-radius: 6px;
  overflow: hidden;
`

const TableRow = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  border-bottom: 1px dotted #ccc;
  padding: 12px 16px;
  
  &:nth-child(odd) {
    background-color: #f9f9f9;
  }

  &:last-child {
    border-bottom: none;
  }

  cursor: pointer;

  &:hover {
    background-color: #eee;
  }
`

const ToolInfoCell = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  p {
    margin: 0;
  }
`

const ToolStatsCell = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
`

/**
 * The side "slide-out" detail panel.
 * Thinner accent bar (6px), and narrower overall width (~35%).
 */
const DetailSlideout = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 35%;
  height: 100%;
  background-color: #fff;
  box-shadow: -5px 0 15px rgba(0, 0, 0, 0.1);
  border-left: 6px solid #ffd46b; /* Pastel accent bar */
  padding: 30px;
  box-sizing: border-box;
  transition: transform 0.4s ease-in-out;
  transform: translateX(${props => (props.isOpen ? '0' : '120%')});
  display: flex;
  flex-direction: column;
  
  @media (max-width: 1019px) {
    position: fixed;
    top: 0;
    width: 100%;
    left: 0;
    border-left: 0; 
  }
`

/**
 * A small, absolutely positioned close button in the top-right corner
 * of the slide-out panel.
 */
const CloseButton = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  color: #999;
  &:hover {
    color: #333;
  }
`

const DetailCard = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  color: #333;
  flex: 1;

  h2 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  strong {
    font-weight: 600;
  }
  p {
    margin-bottom: 14px;
    font-size: 16px;
    line-height: 1.4;
  }
`

/* -------------------------------------------------------------------------- */
/* --------------------------- Sub Components -------------------------------- */
/* -------------------------------------------------------------------------- */

/**
 * Hero for the top of the page.
 * Minimal background (#fafafa),
 * Bolder text color (#000 or #333),
 * a soft accent if needed.
 */
function Hero({ title, description, ctaText, onCtaClick }) {
  return (
    <>
      <HeroContainer>
        <HeroText>
          <CardTitle style={{ fontWeight: '600', fontSize: 42, color: '#000' }}>
            {title}
          </CardTitle>
          <CardDescription
            style={{ fontSize: 18, lineHeight: 1.4, color: '#666' }}
          >
            {description}
          </CardDescription>
          <div>
            <RadixButton
              variant="solid"
              color="iris"
              type="button"
              style={{ cursor: 'pointer' }}
              onClick={onCtaClick}
            >
              {ctaText}
              <ArrowRight size={14} />
            </RadixButton>
          </div>
        </HeroText>

        <HeroImage>
          <img
            src={heroImage}
            alt={title}
            width="600"
            height="600"
            style={{ marginLeft: 'auto', maxWidth: '100%', height: 'auto' }}
          />
        </HeroImage>
      </HeroContainer>
      <HeroLine />
    </>
  )
}

/**
 * Left sidebar showing all the collection names (tags).
 */
function Sidebar({ collections, selectedCollection, onSelectCollection }) {
  return (
    <SidebarContainer>
      <CardTitle style={{ fontSize: 20, marginBottom: 10, color: '#333' }}>
        Collections
      </CardTitle>
      {collections.length === 0 && <p>No collections yet.</p>}
      {collections.map((tag, index) => (
        <SidebarItem
          key={index}
          onClick={() => onSelectCollection(tag)}
          active={tag === selectedCollection}
        >
          {tag}
        </SidebarItem>
      ))}
    </SidebarContainer>
  )
}

/**
 * The center column:
 * 1) If no collection is selected => show the "collection icons"
 * 2) If a collection is selected => show a table of those tools
 */
function CenterColumn({
  selectedCollection,
  search,
  onSearch,
  onSelectTool,
  onSelectCollection,
  onBackToCollections,
  displayedTools,
  collectionsMap,
}) {
  // If NO collection is selected, show "collection icon" cards
  if (!selectedCollection) {
    const entries = Object.entries(collectionsMap)
    if (entries.length === 0) {
      return (
        <CenterContainer>
          <p style={{ color: '#666' }}>No collections available.</p>
        </CenterContainer>
      )
    }
    return (
      <CenterContainer>
        <CardTitle style={{ fontSize: 24, color: '#333' }}>
          Browse Collections
        </CardTitle>
        <CollectionsGrid>
          {entries.map(([tagName, { logo }]) => (
            <CollectionCard
              key={tagName}
              onClick={() => onSelectCollection(tagName)}
            >
              <img
                src={logo || blandlogo}
                alt={tagName}
                width="80"
                height="80"
              />
              <p>{tagName}</p>
            </CollectionCard>
          ))}
        </CollectionsGrid>
      </CenterContainer>
    )
  }

  // Otherwise, show the "table style" list of tools
  return (
    <CenterContainer>
      <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
        <RadixButton
          variant="outline"
          color="gray"
          type="button"
          onClick={onBackToCollections}
          style={{ borderColor: '#ccc', color: '#666' }}
        >
          Back to Collections
        </RadixButton>
        <CardTitle style={{ fontSize: 24, margin: 0, color: '#333' }}>
          {selectedCollection}
        </CardTitle>
      </div>

      <TextField.Root
        onChange={onSearch}
        value={search}
        placeholder={`Search tools in ${selectedCollection}...`}
        style={{ marginTop: 10 }}
      >
        <TextField.Slot>
          <Icons.MagnifyingGlassIcon />
        </TextField.Slot>
      </TextField.Root>

      {displayedTools.length === 0 && (
        <Flex
          gap="2"
          wrap="wrap"
          align="center"
          justify="center"
          style={{ marginTop: 30 }}
        >
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
            }}
          >
            {[...Array.from({ length: 3 })].map((_, index) => (
              <Skeleton
                key={index}
                style={{
                  width: 'calc(33.333% - 10px)',
                  margin: '5px',
                  height: '100px',
                  background: '#eee',
                  borderRadius: '4px',
                }}
              />
            ))}
          </div>
        </Flex>
      )}

      {displayedTools.length > 0 && (
        <TableContainer>
          {displayedTools.map(tool => (
            <TableRow key={tool.tool_id} onClick={() => onSelectTool(tool)}>
              <img
                src={tool.image_url || blandlogo}
                alt={tool.title}
                width="40"
                height="40"
                style={{ objectFit: 'contain' }}
              />
              <ToolInfoCell>
                <p style={{ fontWeight: 600, color: '#333' }}>{tool.title}</p>
                <p style={{ fontSize: 14, color: '#666' }}>
                  {getDescriptionLengthAndTruncate(tool.description)}
                </p>
              </ToolInfoCell>
              <ToolStatsCell>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 4,
                    color: '#ff9900',
                  }}
                >
                  <StarIcon size={16} color="#ff9900" strokeWidth={3} />
                  <p style={{ fontSize: 14, fontWeight: '500', margin: 0 }}>
                    {tool.stars || 0}
                  </p>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 4,
                    color: '#aaa',
                  }}
                >
                  <CopyIcon size={16} color="#aaa" strokeWidth={3} />
                  <p style={{ fontSize: 14, fontWeight: '500', margin: 0 }}>
                    {tool.clones || 0}
                  </p>
                </div>
              </ToolStatsCell>
            </TableRow>
          ))}
        </TableContainer>
      )}
    </CenterContainer>
  )
}

/**
 * The detail panel slides in from the right.
 * Narrower accent bar + optional close button up top.
 */
function DetailPanel({ tool, onClose }) {
  const isOpen = Boolean(tool)

  return (
    <DetailSlideout isOpen={isOpen}>
      {isOpen && (
        <>
          {/* The X-out icon in the top-right corner */}
          <CloseButton onClick={onClose}>
            <X size={20} />
          </CloseButton>

          <DetailCard>
            <h2>{tool.title}</h2>
            <img
              src={tool.image_url || blandlogo}
              alt={tool.title}
              width="200"
              height="120"
              style={{ objectFit: 'contain', marginBottom: 20 }}
            />
            <p>
              <strong>Description:</strong>
              {' '}
              {tool.description || 'No description'}
            </p>
            <p>
              <strong>Stars:</strong>
              {' '}
              {tool.stars || 0}
            </p>
            <p>
              <strong>Clones:</strong>
              {' '}
              {tool.clones || 0}
            </p>
            <p>
              <strong>Tags:</strong>
              {' '}
              {(tool.tags || []).join(', ')}
            </p>
            <RadixButton
              variant="solid"
              color="iris"
              onClick={() =>
                window.location.assign(`/showcase/tools/${tool.tool_id}`)}
              style={{ marginTop: 20 }}
            >
              View full tool page
            </RadixButton>
          </DetailCard>
        </>
      )}
    </DetailSlideout>
  )
}

/* -------------------------------------------------------------------------- */
/* ---------------------------- Main Tools Page ------------------------------ */
/* -------------------------------------------------------------------------- */

export default function Tools() {
  const [collections, setCollections] = useState([])
  const [collectionsMap, setCollectionsMap] = useState({})
  const [selectedCollection, setSelectedCollection] = useState(null)
  const [selectedTool, setSelectedTool] = useState(null)
  const [search, setSearch] = useState('')

  const navigate = useNavigate()
  const { user } = useAuth()

  // Fetch all tools from the endpoint
  const loadAllTools = async () => {
    try {
      const response = await fetch(`${config.API_URL}/showcase/public/tools`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      })
      if (response.status !== 200) {
        console.error('Error loading tools')
        return
      }

      const responseObject = await response.json()
      if (responseObject.status === 'error') {
        console.error('Error loading tools')
        return
      }

      const fetchedTools = responseObject.data.data || []
      // Build a dictionary of tags => { logo, tools: [] }
      const map = {}
      fetchedTools.forEach((tool) => {
        if (Array.isArray(tool.tags)) {
          tool.tags.forEach((tag) => {
            if (!map[tag]) {
              // use first tool's image as the "collection" logo
              map[tag] = { logo: tool.image_url || blandlogo, tools: [] }
            }
            map[tag].tools.push(tool)
          })
        }
      })
      setCollectionsMap(map)
      setCollections(Object.keys(map).sort())
    }
    catch (err) {
      console.error('Error loading tools:', err)
    }
  }

  useEffect(() => {
    loadAllTools()
  }, [])

  // Selecting a collection
  const handleSelectCollection = (tag) => {
    setSelectedCollection(tag)
    setSelectedTool(null)
    setSearch('')
  }

  // Searching within a collection
  const handleSearch = (e) => {
    setSearch(e.target.value)
  }

  // Selecting a tool in the center
  const handleSelectTool = (tool) => {
    setSelectedTool(tool)
  }

  // Back to the main "collection icons" view
  const handleBackToCollections = () => {
    setSelectedCollection(null)
    setSelectedTool(null)
    setSearch('')
  }

  // Close the detail panel
  const handleCloseDetail = () => {
    setSelectedTool(null)
  }

  // Filter the tools in the chosen collection by the search term
  let toolsInSelectedCollection = []
  if (selectedCollection && collectionsMap[selectedCollection]) {
    toolsInSelectedCollection = collectionsMap[selectedCollection].tools
  }

  const displayedTools = toolsInSelectedCollection.filter(tool =>
    tool.title.toLowerCase().includes(search.toLowerCase()),
  )

  return (
    <Container>
      <Header />
      <MainContent>
        {user
          ? (
              <Hero
                title="Add tools, get work done."
                description="Pull reports, start calls, file tickets, and more live on call — right within Bland."
                ctaText="Add Tool"
                onCtaClick={() => navigate('/dashboard/tools')}
              />
            )
          : (
              <Hero
                title="Add tools, get work done."
                description="Pull reports, start calls, file tickets, and more live on call — right within Bland."
                ctaText="Sign up to add a tool"
                onCtaClick={() => navigate('/signup')}
              />
            )}

        <ContentWrapper>
          {/* Left Sidebar */}
          <Sidebar
            collections={collections}
            selectedCollection={selectedCollection}
            onSelectCollection={handleSelectCollection}
          />

          {/* Center Column (collection icons or table of tools) */}
          <CenterColumn
            selectedCollection={selectedCollection}
            search={search}
            onSearch={handleSearch}
            onSelectTool={handleSelectTool}
            onSelectCollection={handleSelectCollection}
            onBackToCollections={handleBackToCollections}
            displayedTools={displayedTools}
            collectionsMap={collectionsMap}
          />

          {/* Right Slide-Out Detail Panel with close button */}
          <DetailPanel tool={selectedTool} onClose={handleCloseDetail} />
        </ContentWrapper>
      </MainContent>
    </Container>
  )
}
