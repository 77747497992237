import { datadogRum } from '@datadog/browser-rum'
import { reactPlugin } from '@datadog/browser-rum-react'

if (import.meta.env.VITE_VERCEL_ENV === 'production') {
  datadogRum.init({
    applicationId: 'a9bca4db-5762-44b7-b143-842a74f8eafb',
    clientToken: 'pub0e386b6a7e6a9dfd97ea4491c9fb60b9',
    site: 'us5.datadoghq.com',
    service: 'frontend',
    env: import.meta.env.VITE_VERCEL_TARGET_ENV,
    version: import.meta.env.VITE_VERCEL_GIT_COMMIT_SHA,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    defaultPrivacyLevel: 'mask-user-input',
    plugins: [reactPlugin({ router: true })],
    allowedTracingUrls: ['https://api.bland.ai'],
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
  })
}
