import { Button } from '@/components/ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { Input } from '@/components/ui/input'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { Portal } from '@headlessui/react'
import { MoreHorizontal, Plus } from 'lucide-react'

const OPERATOR_OPTIONS = [
  { value: 'equals', label: 'equals' },
  { value: 'equals exact', label: 'equals exact (type and case sensitive )' },
  { value: 'not equals', label: 'not equals' },
  {
    value: 'not equals exact',
    label: 'not equals exact (type and case sensitive)',
  },
  { value: 'contains', label: 'contains (string)' },
  { value: 'does not contain', label: 'does not contain (string)' },
  { value: 'greater than', label: 'greater than' },
  { value: 'less than', label: 'less than' },
  { value: 'is', label: 'is (booleans/null/defined/empty)' },
]

const IS_OPERATOR_OPTIONS = [
  { value: 'true', label: 'true' },
  { value: 'false', label: 'false' },
  { value: 'null', label: 'null' },
  { value: 'not null', label: 'not null' },
  { value: 'defined', label: 'defined' },
  { value: 'undefined', label: 'undefined' },
  { value: 'empty', label: 'empty' },
  { value: 'not empty', label: 'not empty' },
]

// Validation functions
function isConditionValid(condition) {
  if (!condition.field || !condition.operator)
    return false
  //   if (!NO_VALUE_OPERATORS.includes(condition.operator) && !condition.value)
  // return false;
  return true
}

function isGroupValid(conditions) {
  return conditions.length >= 2 && conditions.every(isConditionValid)
}

function areConditionsValid(conditions) {
  for (const condition of conditions) {
    if (condition.isGroup) {
      if (!isGroupValid(condition.conditions)) {
        return {
          valid: false,
          reason: `Invalid group condition - ${condition.conditions.map(
            c => `${c.field} ${c.operator} ${c.value}`,
          )}`,
        }
      }
    }
    else if (!isConditionValid(condition)) {
      return {
        valid: false,
        reason: `Invalid single condition - ${condition.field} ${condition.operator} ${condition.value}`,
      }
    }
  }

  return { valid: true, reason: 'All conditions are valid' }
}

function SelectField({ value, onChange, options, placeholder, showError }) {
  return (
    <Select value={value} onValueChange={onChange}>
      <SelectTrigger
        className={`w-full h-8 ${showError ? 'border-red-500 ring-red-200' : ''}`}
      >
        <SelectValue placeholder={placeholder} />
      </SelectTrigger>
      <div className="relative z-[200]">
        <SelectContent position="popper" className="z-[200]">
          {options.map(option => (
            <SelectItem key={option.value} value={option.value}>
              {option.label}
            </SelectItem>
          ))}
        </SelectContent>
      </div>
    </Select>
  )
}

function ValueInput({ value, onChange, placeholder, showError }) {
  return (
    <Input
      className={`bg-gray-50 h-8 ${showError ? 'border-red-500 ring-red-200' : ''}`}
      type="text"
      placeholder={placeholder}
      value={value || ''}
      onChange={e => onChange(e.target.value)}
    />
  )
}

function OperatorLabel({ children }) {
  return (
    <span className="flex items-center justify-center font-medium bg-gray-50 px-2.5 py-1.5 rounded text-slate-600 border border-gray-200 w-[90px]">
      {children}
    </span>
  )
}

function SingleCondition({
  condition,
  onChange,
  onDelete,
  onGroup,
  showValidation,
}) {
  const showOperatorSelect = true
  const showValueInput = showOperatorSelect && condition.operator !== ''
  const isIsOperator = ['is', 'is not'].includes(condition.operator)

  const isFieldInvalid = showValidation && !condition.field
  const isOperatorInvalid
    = showValidation && condition.field && !condition.operator
  const isValueInvalid = showValidation && showValueInput && !condition.value

  return (
    <div className="flex-1 flex items-center gap-2.5">
      <div className="flex-1 flex items-center gap-2.5">
        <div className="w-[200px]">
          <Input
            type="text"
            value={condition.field}
            onChange={e => onChange({ ...condition, field: e.target.value })}
            placeholder="Variable name e.g. first_name"
            className={`h-8 ${isFieldInvalid ? 'border-red-500 ring-red-200' : ''}`}
          />
        </div>

        {showOperatorSelect && (
          <div className="w-[250px]">
            <SelectField
              value={condition.operator}
              onChange={value => onChange({ ...condition, operator: value })}
              options={OPERATOR_OPTIONS}
              placeholder="Select operator"
              showError={isOperatorInvalid}
            />
          </div>
        )}

        {showValueInput
        && (isIsOperator
          ? (
              <div className="w-[200px]">
                <SelectField
                  value={condition.value}
                  onChange={value => onChange({ ...condition, value })}
                  options={IS_OPERATOR_OPTIONS}
                  placeholder="Select value"
                  showError={isValueInvalid}
                />
              </div>
            )
          : (
              <ValueInput
                value={condition.value}
                onChange={value => onChange({ ...condition, value })}
                placeholder="Value"
                showError={isValueInvalid}
              />
            ))}
      </div>
      {/* <div
        className="z-[200] cursor-pointer hover:bg-gray-100 p-0.5 rounded-"
        onClick={onDelete}
      >
        <TrashIcon className="w-2.5 h-2.5" />
      </div> */}

      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" className="h-5 w-5 p-0 relative z-[50]">
            <MoreHorizontal className="h-2.5 w-2.5" />
          </Button>
        </DropdownMenuTrigger>
        <Portal>
          <DropdownMenuContent align="end" className="z-[1000]" sideOffset={5}>
            {onGroup && (
              <DropdownMenuItem onClick={onGroup}>
                Group condition
              </DropdownMenuItem>
            )}
            <DropdownMenuItem onClick={onDelete} className="text-red-600">
              Remove condition
            </DropdownMenuItem>
          </DropdownMenuContent>
        </Portal>
      </DropdownMenu>
    </div>
  )
}

function GroupCondition({ conditions, onChange, onDelete, showValidation }) {
  const isInvalid = showValidation && !isGroupValid(conditions)

  return (
    <div
      className={`bg-slate-50 rounded-md p-2.5 ${isInvalid ? 'border-2 border-red-300' : ''}`}
    >
      <div className="space-y-2.5">
        {conditions.map((condition, index) => (
          <div key={index} className="flex items-center gap-2.5">
            <OperatorLabel>{index === 0 ? 'If Variable' : 'And'}</OperatorLabel>
            <SingleCondition
              condition={condition}
              onChange={(updated) => {
                const newConditions = [...conditions]
                newConditions[index] = updated
                onChange(newConditions)
              }}
              onDelete={() => {
                if (conditions.length > 2) {
                  onChange(conditions.filter((_, i) => i !== index))
                }
                else {
                  onDelete()
                }
              }}
              showValidation={showValidation}
            />
          </div>
        ))}

        <div className="flex items-center justify-between">
          <Button
            variant="outline"
            size="sm"
            onClick={() => {
              onChange([...conditions, { field: '', operator: '', value: '' }])
            }}
          >
            <Plus className="w-2.5 h-2.5 mr-0.5" />
            {' '}
            Add condition
          </Button>
          <Button
            variant="ghost"
            size="sm"
            onClick={onDelete}
            className="text-slate-600 hover:text-slate-800"
          >
            Delete group
          </Button>
        </div>
      </div>
    </div>
  )
}

function Condition({
  condition,
  isFirst = false,
  onUpdate,
  onDelete,
  onGroupCondition,
  showValidation,
}) {
  const isGroup = condition.isGroup

  return (
    <div className="space-y-2.5 relative">
      <div className={`flex gap-2.5 ${isGroup ? 'items-start' : 'items-center'}`}>
        <div className={`${isGroup ? 'mt-5' : ''}`}>
          <OperatorLabel>{isFirst ? 'If Variable' : 'Or'}</OperatorLabel>
        </div>

        {isGroup
          ? (
              <div className="ml-2.5 flex-1">
                <GroupCondition
                  conditions={condition.conditions}
                  onChange={newConditions =>
                    onUpdate({ ...condition, conditions: newConditions })}
                  onDelete={onDelete}
                  showValidation={showValidation}
                />
              </div>
            )
          : (
              <SingleCondition
                condition={condition}
                onChange={onUpdate}
                onDelete={onDelete}
                onGroup={onGroupCondition}
                showValidation={showValidation}
              />
            )}
      </div>
    </div>
  )
}

export { areConditionsValid, Condition,   }
