import { $fetch } from 'utils/fetch'

export async function fetchPreviousCalls(orgId, callId, phoneNumber, direction, page = 1) {
  try {
    const params = {
      pageSize: 10,
      page: page,
      [direction]: phoneNumber,
      user_id: orgId,
    }

    if (callId) {
      params['call_id'] = `!${callId}`
    }


    const previous = await $fetch(`/user/logs?${new URLSearchParams(params)}`)

    return previous?.calls
  }
  catch (error) {
    return null
  }
}
