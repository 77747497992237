import React, { useState, useContext } from 'react';
import { Button } from '@radix-ui/themes';
import { FaRobot } from 'react-icons/fa';
import { toast } from 'react-toastify';
import TreePostCallActionsModal from './TreePostCallActionsModal';
import PostCallActionsContext from '../PostCallActionsContext';

const PostCallActionsPathway = () => {
  const [showModal, setShowModal] = useState(false);
  const { 
    onPostCallActionsChange, 
    setIsSaving,
    userIntegrations,
  } = useContext(PostCallActionsContext);
  


  // Only show the component if the user has the Slack integration
  const hasSlackIntegration = userIntegrations.some(
    connection => connection.provider_config_key === 'slack'
  );

  const handleSavePostCallActions = (actions) => {
    const saveSucceeded = onPostCallActionsChange(actions);
    setIsSaving(false); 
    
    if (saveSucceeded) {
      toast.success("Post call actions updated");
    }
  };

  if (!hasSlackIntegration) {
    return null;
  }

  return (
    <div>
      <Button
        size="1"
        color="violet"
        variant="solid"
        onClick={() => setShowModal(true)}
        style={{ width: '100%' }}
      >
        <FaRobot size={16} style={{ marginRight: '8px' }} />
        Post Call Actions
      </Button>
      
      {showModal && (
        <TreePostCallActionsModal
          isOpen={showModal}
          onClose={() => setShowModal(false)}
          onSave={handleSavePostCallActions}
        />
      )}
    </div>
  );
};

export default PostCallActionsPathway; 