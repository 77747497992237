import React, { useState, useEffect } from 'react';
import { AlertTriangle, Check, ChevronDown, ChevronRight, Clipboard } from 'lucide-react';

const NodeConflictView = ({ 
  conflict, 
  selectedVersion, 
  onSelectVersion, 
  editableContent, 
  setEditableContent,
  onSelectField
}) => {
  const [expandedDataProperties, setExpandedDataProperties] = useState({});
  const [highlightedField, setHighlightedField] = useState(null);
  
  // Production and SMS versions from the conflict
  const prodVersion = conflict.productionVersion || {};
  const smsVersion = conflict.smsVersion || {};
  
  // Extract data objects
  const prodData = prodVersion.data || {};
  const smsData = smsVersion.data || {};
  
  // Get node names from data.name if available
  const prodNodeName = prodData.name || prodVersion.name || "Unnamed Node";
  const smsNodeName = smsData.name || smsVersion.name || "Unnamed Node";
  
  // Toggle expansion of a data property
  const toggleDataProperty = (key) => {
    setExpandedDataProperties(prev => ({
      ...prev,
      [key]: !prev[key]
    }));
  };
  
  // Render a visual indicator for differences with different colors based on change type
  const renderDiffIndicator = (prodValue, smsValue) => {
    // Check if the field exists in both versions but with different values
    if (prodValue !== undefined && smsValue !== undefined && JSON.stringify(prodValue) !== JSON.stringify(smsValue)) {
      return <div className="w-3 h-3 rounded-full bg-orange-400" title="Changed value" />;
    }
    
    // Check if the field exists only in production (deleted in SMS)
    if (prodValue !== undefined && smsValue === undefined) {
      return <div className="w-3 h-3 rounded-full bg-red-500" title="Deleted in SMS version" />;
    }
    
    // Check if the field exists only in SMS (new field)
    if (prodValue === undefined && smsValue !== undefined) {
      return <div className="w-3 h-3 rounded-full bg-green-500" title="New in SMS version" />;
    }
    
    return null; // No difference
  };
  
  // Check if values are different
  const hasDifference = (prodValue, smsValue) => {
    return JSON.stringify(prodValue) !== JSON.stringify(smsValue);
  };
  
  // Render value with appropriate formatting
  const renderValue = (value) => {
    if (value === undefined) {
      return <span className="text-gray-400 italic">not present</span>;
    }
    
    if (value === null) {
      return <span className="text-gray-400 italic">null</span>;
    }
    
    if (typeof value === 'boolean') {
      return (
        <span className={`inline-flex items-center px-2 py-0.5 rounded text-xs font-medium ${
          value ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
        }`}>
          {value ? 'true' : 'false'}
        </span>
      );
    }
    
    if (typeof value === 'number') {
      return <span className="font-mono">{value}</span>;
    }
    
    if (typeof value === 'string') {
      if (value.length > 100) {
        return (
          <div className="max-h-24 overflow-y-auto bg-gray-50 p-2 rounded border border-gray-200">
            <pre className="whitespace-pre-wrap text-sm">{value}</pre>
          </div>
        );
      }
      return <span className="text-gray-700">"{value}"</span>;
    }
    
    if (Array.isArray(value)) {
      return (
        <div className="bg-gray-50 p-2 rounded border border-gray-200">
          <div className="text-sm text-gray-500 mb-1">Array [{value.length}] items:</div>
          <pre className="whitespace-pre-wrap text-xs">{JSON.stringify(value, null, 2)}</pre>
        </div>
      );
    }
    
    if (typeof value === 'object') {
      return (
        <div className="bg-gray-50 p-2 rounded border border-gray-200">
          <div className="text-sm text-gray-500 mb-1">Object with {Object.keys(value).length} properties:</div>
          <pre className="whitespace-pre-wrap text-xs">{JSON.stringify(value, null, 2)}</pre>
        </div>
      );
    }
    
    return String(value);
  };
  
  // Check if type is different
  const typeIsDifferent = prodVersion.type !== smsVersion.type;
  
  return (
    <div className="node-conflict-view">
      
      {/* Side-by-side Card Comparison */}
      <div className="grid grid-cols-2 gap-6">
        {/* Production Version Card */}
        <div 
          className={`border rounded-lg overflow-hidden bg-white shadow-sm ${
            selectedVersion === 'production' ? 'ring-2 ring-blue-500' : ''
          }`}
          onClick={() => onSelectVersion('production')}
        >
          <div className="bg-gray-50 px-4 py-3 border-b">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <div className="w-3 h-3 rounded-full bg-blue-500 mr-2"></div>
                <h4 className="font-medium text-gray-800">Production Pathway</h4>
                {prodVersion.isDefault && (
                  <span className="ml-2 px-2 py-0.5 text-xs font-medium bg-gray-100 text-gray-600 rounded">Default</span>
                )}
              </div>
              {/* Type field at top right */}
              <div className="flex items-center space-x-2">
                <span className="text-sm text-gray-600">Type:</span>
                <span className="text-sm font-medium">{prodVersion.type || "Unknown"}</span>
                {typeIsDifferent && renderDiffIndicator(prodVersion.type, smsVersion.type)}
              </div>
            </div>
          </div>
          
          <div className="divide-y">
            {/* Node Name */}
            <div className="p-4">
              <div className="text-sm text-center mb-2 text-gray-700">
                {prodNodeName}
              </div>
            </div>
            
            {/* Data Properties - Directly visible */}
            <div className="px-4 py-3 bg-gray-50 overflow-auto max-h-96">
              {Object.keys(prodData).length === 0 ? (
                <div className="text-sm text-gray-500 italic">No data properties</div>
              ) : (
                <div className="space-y-2">
                  {Object.entries(prodData)
                    .filter(([key]) => key !== 'globalPrompt') // Filter out globalPrompt
                    .map(([key, value]) => {
                      return (
                        <div key={`prod-${key}`} className="border rounded overflow-hidden">
                          {/* Data Property Header (Accordion Toggle) */}
                          <div 
                            className="flex items-center justify-between px-3 py-2 bg-gray-100 cursor-pointer hover:bg-gray-200"
                            onClick={(e) => {
                              e.stopPropagation(); // Stop event from bubbling to parent
                              toggleDataProperty(key);
                            }}
                          >
                            <div className="flex items-center">
                              <ChevronRight 
                                className={`h-4 w-4 text-gray-500 mr-1 transition-transform ${expandedDataProperties[key] ? 'transform rotate-90' : ''}`}
                              />
                              <div className="font-medium text-sm">{key}</div>
                            </div>
                            {renderDiffIndicator(value, smsData[key])}
                          </div>
                          
                          {/* Data Property Content (Collapsed by default) */}
                          {expandedDataProperties[key] && (
                            <div className="p-3" onClick={(e) => e.stopPropagation()}>
                              <div className="text-sm">{renderValue(value)}</div>
                            </div>
                          )}
                        </div>
                      );
                    })}
                </div>
              )}
            </div>
          </div>
        </div>
        
        {/* SMS Version Card */}
        <div 
          className={`border rounded-lg overflow-hidden bg-white shadow-sm ${
            selectedVersion === 'sms' ? 'ring-2 ring-indigo-500' : ''
          }`}
          onClick={() => onSelectVersion('sms')}
        >
          <div className="bg-gray-50 px-4 py-3 border-b">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <div className="w-3 h-3 rounded-full bg-indigo-500 mr-2"></div>
                <h4 className="font-medium text-gray-800">SMS Pathway</h4>
              </div>
              {/* Type field at top right */}
              <div className="flex items-center space-x-2">
                <span className="text-sm text-gray-600">Type:</span>
                <span className="text-sm font-medium">{smsVersion.type || "Unknown"}</span>
                {typeIsDifferent && renderDiffIndicator(prodVersion.type, smsVersion.type)}
              </div>
            </div>
          </div>
          
          <div className="divide-y">
            {/* Node Name */}
            <div className="p-4">
              <div className="text-sm text-center mb-2 text-gray-700">
                {smsNodeName}
              </div>
            </div>
            
            {/* Data Properties - Directly visible */}
            <div className="px-4 py-3 bg-gray-50 overflow-auto max-h-96">
              {Object.keys(smsData).length === 0 ? (
                <div className="text-sm text-gray-500 italic">No data properties</div>
              ) : (
                <div className="space-y-2">
                  {Object.entries(smsData)
                    .filter(([key]) => key !== 'globalPrompt') // Filter out globalPrompt
                    .map(([key, value]) => {
                      return (
                        <div key={`sms-${key}`} className="border rounded overflow-hidden">
                          {/* Data Property Header (Accordion Toggle) */}
                          <div 
                            className="flex items-center justify-between px-3 py-2 bg-gray-100 cursor-pointer hover:bg-gray-200"
                            onClick={(e) => {
                              e.stopPropagation(); // Stop event from bubbling to parent
                              toggleDataProperty(key);
                            }}
                          >
                            <div className="flex items-center">
                              <ChevronRight 
                                className={`h-4 w-4 text-gray-500 mr-1 transition-transform ${expandedDataProperties[key] ? 'transform rotate-90' : ''}`}
                              />
                              <div className="font-medium text-sm">{key}</div>
                            </div>
                            {renderDiffIndicator(prodData[key], value)}
                          </div>
                          
                          {/* Data Property Content (Collapsed by default) */}
                          {expandedDataProperties[key] && (
                            <div className="p-3" onClick={(e) => e.stopPropagation()}>
                              <div className="text-sm">{renderValue(value)}</div>
                            </div>
                          )}
                        </div>
                      );
                    })}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      
      {/* Custom Edit (shown only when selectedVersion is 'custom') */}
      {selectedVersion === 'custom' && (
        <div className="mt-6 border rounded-lg overflow-hidden bg-white shadow-sm ring-2 ring-green-500">
          <div className="bg-green-50 px-4 py-3 border-b">
            <h4 className="font-medium text-green-700">Custom Edit</h4>
          </div>
          
          <div className="p-4">
            {/* Type field */}
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-1">Node Type</label>
              <input
                type="text"
                className="w-full p-2 border rounded"
                value={editableContent.type || ''}
                onChange={(e) => setEditableContent({ ...editableContent, type: e.target.value })}
              />
            </div>
            
            {/* Data object (JSON editor) */}
            <div className="mb-4">
              <div className="flex items-center justify-between mb-1">
                <label className="block text-sm font-medium text-gray-700">
                  Data (JSON)
                </label>
                <span className="text-xs text-gray-500">Edit carefully to maintain valid JSON</span>
              </div>
              <textarea
                className="w-full p-2 border rounded min-h-[200px] font-mono text-sm"
                value={editableContent.data || '{}'}
                onChange={(e) => {
                  try {
                    // Try to parse the JSON to validate it
                    JSON.parse(e.target.value);
                    setEditableContent({ ...editableContent, data: e.target.value });
                  } catch (err) {
                    // Still update the field even if JSON is invalid
                    // This allows for work-in-progress editing
                    setEditableContent({ ...editableContent, data: e.target.value });
                  }
                }}
              />
              {/* JSON validation feedback */}
              {(() => {
                try {
                  JSON.parse(editableContent.data || '{}');
                  return (
                    <div className="mt-1 text-xs text-green-600 flex items-center">
                      <Check className="h-3 w-3 mr-1" />
                      Valid JSON
                    </div>
                  );
                } catch (err) {
                  return (
                    <div className="mt-1 text-xs text-red-600 flex items-center">
                      <AlertTriangle className="h-3 w-3 mr-1" />
                      Invalid JSON: {err.message}
                    </div>
                  );
                }
              })()}
              
              {/* Quick template buttons */}
              <div className="mt-3 flex flex-wrap gap-2">
                <button
                  className="text-xs px-2 py-1 bg-gray-100 hover:bg-gray-200 rounded flex items-center"
                  onClick={() => {
                    // Copy production data as a starting point
                    const prodDataCopy = {...prodData};
                    delete prodDataCopy.globalPrompt; // Remove globalPrompt from copied data
                    setEditableContent({
                      ...editableContent,
                      data: JSON.stringify(prodDataCopy, null, 2)
                    });
                  }}
                >
                  <Clipboard className="h-3 w-3 mr-1" />
                  Copy Production Data
                </button>
                <button
                  className="text-xs px-2 py-1 bg-gray-100 hover:bg-gray-200 rounded flex items-center"
                  onClick={() => {
                    // Copy SMS data as a starting point
                    const smsDataCopy = {...smsData};
                    delete smsDataCopy.globalPrompt; // Remove globalPrompt from copied data
                    setEditableContent({
                      ...editableContent,
                      data: JSON.stringify(smsDataCopy, null, 2)
                    });
                  }}
                >
                  <Clipboard className="h-3 w-3 mr-1" />
                  Copy SMS Data
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default NodeConflictView; 