import { Chip, Input, Kbd } from '@heroui/react'
import { useState } from 'react'

export default function KeywordsInput({ value: keywords, onChange, ...props }) {
  const [pendingKeyword, setPendingKeyword] = useState('')

  const handleClose = (wordToRemove) => {
    onChange(keywords.filter(word => word !== wordToRemove))
  }

  const handleAdd = () => {
    if (pendingKeyword) {
      onChange(keywords?.length ? [...keywords, pendingKeyword] : [pendingKeyword])
      setPendingKeyword('')
    }
  }
  return (
    <div className="flex flex-col gap-1">
      <Input
        label="Keywords"
        placeholder="Enter keywords"
        description="These keywords will be boosted in the transcription engine - recommended for proper nouns or words that are frequently mis-transcribed. Press Enter or use commas to add multiple keywords."
        value={pendingKeyword}
        variant="bordered"
        labelPlacement="outside"
        radius="sm"
        endContent={keywords?.length > 0
          ? (
              <div className="flex gap-2">
                {keywords?.map(word => (
                  <Chip key={word} variant="flat" size="sm" color="primary" onClose={() => handleClose(word)}>
                    {word}
                  </Chip>
                ))}
              </div>
            )
          : (
              <Kbd keys={['enter']} />
            )}
        onChange={e => setPendingKeyword(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ',') {
            e.preventDefault()
            handleAdd()
          }
        }}
        {...props}
      />

    </div>
  )
}
