import { Switch } from '@headlessui/react'
import {
  AlertCircle,
  ChevronDown,
  ChevronUp,
  Info,
  Plus,
  Trash2,
} from 'lucide-react'
import { useState } from 'react'
import InlineEditor from '../copilot/InlineEditor'

function ExtractInfo({
  extractVars,
  setExtractVars,
  useExtractVars,
  setUseExtractVars,
  validationErrors,
  setValidationErrors,
  extractVarSettings,
  setExtractVarSettings,
  isWebHook = false,
}) {
  const [isPromptOpen, setIsPromptOpen] = useState(false)
  const [isVariablesSectionOpen, setIsVariablesSectionOpen] = useState(false)
  const [isSettingsOpen, setIsSettingsOpen] = useState(false)
  const [activeEditorIndex, setActiveEditorIndex] = useState(null)

  const updateSettings = (key, value) => {
    setExtractVarSettings({
      ...extractVarSettings,
      [key]: value,
    })
  }

  const addVariable = () => {
    setIsVariablesSectionOpen(true)
    // Added accurateSpelling as the fourth element in the array
    setExtractVars([...extractVars, ['', '', '', false, false]])
  }

  const removeVariable = (index) => {
    setExtractVars(extractVars.filter((_, i) => i !== index))
    setValidationErrors(
      validationErrors.filter(error => error.index !== index),
    )
  }

  const updateVariable = (index, position, value) => {
    setExtractVars(
      extractVars.map((item, i) => {
        if (i === index) {
          const updatedItem = [...item]
          updatedItem[position] = value
          return updatedItem
        }
        return item
      }),
    )
    setValidationErrors(prev =>
      prev.filter(
        error => !(error.index === index && error.field === position),
      ),
    )
  }

  const handleCopilotSuggestion = async (prompt, index) => {
    updateVariable(index, 2, prompt)
  }

  const toggleAccurateSpelling = (index) => {
    setExtractVars(
      extractVars.map((item, i) => {
        if (i === index) {
          const updatedItem = [...item]
          updatedItem[3] = !updatedItem[3] // Toggle the fourth element
          return updatedItem
        }
        return item
      }),
    )
  }

  const updatePrompt = (value) => {
    const promptIndex = extractVars.findIndex(
      item => item[0] === 'bland-prompt',
    )
    if (promptIndex !== -1) {
      updateVariable(promptIndex, 2, value)
    }
    else {
      setExtractVars([
        ...extractVars,
        ['bland-prompt', 'string', value, false],
      ])
    }
  }

  const getPrompt = () => {
    const promptVar = extractVars.find(item => item[0] === 'bland-prompt')
    return promptVar ? promptVar[2] : ''
  }

  const getErrorMessage = (index) => {
    const errors = validationErrors.filter(error => error.index === index)
    if (errors.length === 3) {
      return 'All fields are required'
    }
    return errors
      .map((error) => {
        switch (error.field) {
          case 0:
            return 'Name is required'
          case 1:
            return 'Type is required'
          case 2:
            return 'Description is required'
          default:
            return ''
        }
      })
      .join(', ')
  }

  return (
    <div className="border p-4 rounded-md shadow-sm">
      <div className="flex items-center justify-between mb-2.5">
        <h2 className="text-base">Extract Call Info into Variables</h2>
        <Switch
          checked={useExtractVars}
          onChange={() => setUseExtractVars(!useExtractVars)}
          className={`${
            useExtractVars ? 'bg-indigo-600' : 'bg-gray-200'
          } relative inline-flex h-4 w-7 items-center rounded-full`}
        >
          <span className="sr-only">
            Enable Extract Call Info into Variables
          </span>
          <span
            className={`${
              useExtractVars ? 'translate-x-4' : 'translate-x-0.5'
            } inline-block h-2.5 w-2.5 transform rounded-full bg-white transition`}
          />
        </Switch>
      </div>

      <p className="text-sm text-gray-600 mb-2.5">
        {isWebHook
          ? 'Extract information from the call into variables, and use them in your webhook parameters. Gets run before the webhook is called.'
          : 'Run Dialogue Extraction before Next Node Dialogue. This will lead to higher latency, but allow variables to be used in next nodes dialogue.'}
      </p>

      {useExtractVars && (
        <div className="space-y-2.5">
          <div className="border-t pt-2.5">
            <button
              onClick={() => setIsPromptOpen(!isPromptOpen)}
              className="flex items-center gap-1.5 text-sm font-medium focus:outline-none"
            >
              <h3 className="text-sm">Extraction Prompt</h3>
              {isPromptOpen
                ? (
                    <ChevronUp className="h-3 w-3" />
                  )
                : (
                    <ChevronDown className="h-3 w-3" />
                  )}
            </button>
            {isPromptOpen && (
              <textarea
                value={getPrompt()}
                onChange={e => updatePrompt(e.target.value)}
                placeholder="Optional extraction prompt here.. Leaving empty will use our default prompt."
                className="w-full h-20 border border-gray-300 rounded p-1.5 mt-1.5 resize-vertical"
              />
            )}
          </div>

          <div className="border-t pt-2.5">
            <div className="flex justify-between items-center mb-1.5">
              <button
                onClick={() =>
                  setIsVariablesSectionOpen(!isVariablesSectionOpen)}
                className="flex items-center gap-1.5 text-sm font-medium focus:outline-none"
              >
                <h3 className="text-sm">Variables</h3>
                {extractVars.length > 0 && (
                  <span className="ml-1.5 bg-indigo-500 text-white text-xs font-semibold px-1.5 py-0.5 rounded-full">
                    {
                      extractVars.filter(item => item[0] !== 'bland-prompt')
                        .length
                    }
                  </span>
                )}
                {isVariablesSectionOpen
                  ? (
                      <ChevronUp className="h-3 w-3" />
                    )
                  : (
                      <ChevronDown className="h-3 w-3" />
                    )}
                {validationErrors.length > 0 && (
                  <div className="relative group">
                    <AlertCircle className="h-3 w-3 text-red-500 ml-1.5" />
                    <span className="absolute left-full ml-1.5 top-1/2 transform -translate-y-1/2 bg-red-100 text-red-800 text-2xs font-medium px-1.5 py-px rounded-sm hidden group-hover:block whitespace-nowrap">
                      Some variables are missing fields
                    </span>
                  </div>
                )}
              </button>
              <button
                onClick={addVariable}
                className="bg-indigo-600 text-white py-1.5 px-2.5 rounded hover:bg-indigo-700 transition-colors flex items-center gap-1.5"
              >
                <Plus className="h-2.5 w-2.5" />
                Add Variable
              </button>
            </div>
            {isVariablesSectionOpen && (
              <div className="space-y-2.5 mt-2.5">
                {extractVars
                  .filter(item => item[0] !== 'bland-prompt')
                  .map((item) => {
                    const fullIndex = extractVars.findIndex(v => v === item)
                    return (
                      <div
                        key={fullIndex}
                        className="space-y-2 p-2.5 bg-gray-50 rounded-md"
                      >
                        <div className="flex items-center gap-2.5">
                          <input
                            type="text"
                            value={item[0]}
                            placeholder="Variable Name (e.g. email/age)"
                            onChange={e =>
                              updateVariable(fullIndex, 0, e.target.value)}
                            className={`flex-1 border ${
                              validationErrors.some(
                                error =>
                                  error.index === fullIndex && error.field === 0,
                              )
                                ? 'border-red-500'
                                : 'border-gray-300'
                            } rounded p-1.5`}
                          />
                          <select
                            value={item[1]}
                            onChange={e =>
                              updateVariable(
                                fullIndex,
                                1,
                                e.target.value.toLowerCase(),
                              )}
                            className={`w-1/3 border ${
                              validationErrors.some(
                                error =>
                                  error.index === fullIndex && error.field === 1,
                              )
                                ? 'border-red-500'
                                : 'border-gray-300'
                            } rounded p-1.5`}
                          >
                            <option value="">Select Type</option>
                            <option value="string">String</option>
                            <option value="integer">Integer</option>
                            <option value="boolean">Boolean</option>
                          </select>
                          <button
                            onClick={() => removeVariable(fullIndex)}
                            className="p-1.5 rounded bg-red-100 hover:bg-red-200 transition-colors"
                            aria-label="Remove variable"
                          >
                            <Trash2 className="h-2.5 w-2.5 text-red-600" />
                          </button>
                        </div>
                        <textarea
                          placeholder="Variable Description (e.g. Extract the user's email address from the conversation)"
                          value={item[2]}
                          onChange={e =>
                            updateVariable(fullIndex, 2, e.target.value)}
                          className={`w-full h-16 border ${
                            validationErrors.some(
                              error =>
                                error.index === fullIndex && error.field === 2,
                            )
                              ? 'border-red-500'
                              : 'border-gray-300'
                          } rounded p-1.5 resize-vertical`}
                        />
                        <div className="flex items-center gap-2.5 pt-1.5">
                          <div className="flex items-center gap-1.5">
                            <span className="text-xs font-medium text-gray-700">
                              Spelling Precision Mode
                            </span>
                            <div className="relative group">
                              <Info className="h-2.5 w-2.5 text-gray-400" />
                              <span className="absolute bottom-5 left-0 bg-gray-800 text-white text-2xs px-2 py-1.5 rounded-sm hidden group-hover:block w-60 z-[9999]">
                                Optimizes for exact character-by-character
                                accuracy in extractions. Only enable when precise
                                spelling is critical [e.g. for serial numbers or
                                obtaining exact name spellings ("Sean" vs
                                "Shawn")]
                              </span>
                            </div>
                          </div>
                          <Switch
                            checked={item[3] || false}
                            onChange={() => toggleAccurateSpelling(fullIndex)}
                            className={`${
                              item[3] ? 'bg-indigo-600' : 'bg-gray-200'
                            } relative inline-flex h-4 w-7 items-center rounded-full`}
                          >
                            <span className="sr-only">
                              Enable Accurate Spelling
                            </span>
                            <span
                              className={`${
                                item[3] ? 'translate-x-4' : 'translate-x-0.5'
                              } inline-block h-2.5 w-2.5 transform rounded-full bg-white transition`}
                            />
                          </Switch>
                        </div>
                        {validationErrors.some(
                          error => error.index === fullIndex,
                        ) && (
                          <p className="text-red-500 text-xs mt-0.5">
                            {getErrorMessage(fullIndex)}
                          </p>
                        )}
                      </div>
                    )
                  })}
              </div>
            )}
          </div>

          <div className="border-t pt-2.5">
            <button
              onClick={() => setIsSettingsOpen(!isSettingsOpen)}
              className="flex items-center gap-1.5 text-sm font-medium focus:outline-none"
            >
              <h3 className="text-sm">Advanced Settings</h3>
              {isSettingsOpen
                ? (
                    <ChevronUp className="h-3 w-3" />
                  )
                : (
                    <ChevronDown className="h-3 w-3" />
                  )}
            </button>
            {isSettingsOpen && (
              <div className="flex items-center gap-2.5 pt-1.5">
                <div className="flex items-center gap-1.5">
                  <span className="text-xs font-medium text-gray-700">
                    Ignore Previously Extracted Values
                  </span>
                  <div className="relative group">
                    <Info className="h-2.5 w-2.5 text-gray-400" />
                    <span className="absolute bottom-full mb-1.5 left-1/2 -translate-x-1/2 bg-gray-800 text-white text-2xs px-1.5 py-0.5 rounded-sm hidden group-hover:block whitespace-nowrap z-10">
                      Enable to ingore previously extracted values while
                      extracting.
                    </span>
                  </div>
                </div>
                <Switch
                  checked={extractVarSettings?.ignorePrevious}
                  onChange={() =>
                    updateSettings(
                      'ignorePrevious',
                      !extractVarSettings?.ignorePrevious,
                    )}
                  className={`${
                    extractVarSettings?.ignorePrevious
                      ? 'bg-indigo-600'
                      : 'bg-gray-200'
                  } relative inline-flex h-4 w-7 items-center rounded-full`}
                >
                  <span className="sr-only">
                    Ignore Previously Extracted Values
                  </span>
                  <span
                    className={`${
                      extractVarSettings?.ignorePrevious
                        ? 'translate-x-4'
                        : 'translate-x-0.5'
                    } inline-block h-2.5 w-2.5 transform rounded-full bg-white transition`}
                  />
                </Switch>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default ExtractInfo
