import { Tabs } from '@radix-ui/themes'
import Gradient from 'assets/images/gradient.png'
import BillingAndPayments from 'components/AccountSupport/Billing/PaymentMethod'
import ActionBar from 'components/core/ActionBar'
import Keys from 'components/core/Keys'
import { PageWrapper } from 'components/core/PageWrapper'
import GradientLoadingAnimation from 'components/Reusables/GradientLoadingAnimation'
import { useAuth } from 'hooks/useAuth'
import { ChevronRight } from 'lucide-react'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import InsufficientPermissions from './InsufficientPermissions'
import OrgManagement from './OrgManagement'
import OrgHome from './Orgs'
import SettingsPage from './Settings/GeneralSettings'

function valueMapping(value) {
  switch (value) {
    case 'general':
      return 'General Settings'
    case 'users':
      return 'User Management'
    case 'api':
      return 'API Keys'
    case 'billing':
      return 'Billing'
    case 'add-ons':
      return 'Add-ons'
    case 'orgs':
      return 'Organizations'
    default:
      return 'General Settings'
  }
}

export default function Settings() {
  const { org, permissions } = useAuth()

  const [selectedTab, setSelectedTab] = useState('general')
  const [searchParams] = useSearchParams()
  const tab = searchParams.get('tab')

  const [isLoading, setIsLoading] = useState(true)
  const [hasPermissions, setHasPermissions] = useState(false)

  useEffect(() => {
    if (!tab)
      return
    switch (tab) {
      case 'orgs':
        setSelectedTab('orgs')
        break
      case 'users':
        setSelectedTab('users')
        break
      case 'keys':
        setSelectedTab('api')
        break
      case 'billing':
        setSelectedTab('billing')
        break
      default:
        setSelectedTab('general')
        break
    }
    searchParams.delete('tab')
  }, [searchParams, tab])

  useEffect(() => {
    // If selectedTarget is not defined yet, we're still loading
    if (!org) {
      setIsLoading(true)
      return
    }

    const canAccess
      = permissions?.includes('owner') || permissions?.includes('admin')
    setHasPermissions(canAccess)
    setIsLoading(false)
  }, [org, permissions])

  const Header = (
    <ActionBar top>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          columnGap: 10,
        }}
      >
        <img
          src={Gradient}
          alt="default-gradient"
          style={{
            height: 13,
            width: 13,
            borderRadius: 4,
            objectFit: 'cover',
          }}
        />

        <p style={{ fontSize: 13, fontWeight: 450, color: 'GrayText' }}>
          Organization
        </p>
        <ChevronRight size={15} color="lightgray" strokeWidth={3} />
        <p style={{ fontSize: 13, fontWeight: 450, color: '#000' }}>
          {org?.org_display_name}
        </p>
      </div>
    </ActionBar>
  )

  // If we're still loading, show a loading state
  if (isLoading) {
    return (
      <PageWrapper>
        {Header}
        <GradientLoadingAnimation variant="arcticAurora" />
      </PageWrapper>
    )
  }

  // If we have loaded data but user does not have permissions
  if (!hasPermissions) {
    return (
      <PageWrapper>
        {Header}
        <InsufficientPermissions />
      </PageWrapper>
    )
  }

  // Otherwise, we have permissions and can show the settings UI
  return (
    <PageWrapper style={{}}>
      {Header}
      <div>
        <p style={{ color: 'GrayText', fontWeight: '400', fontSize: 14 }}>
          Settings
        </p>
        <p style={{ fontSize: 24, fontWeight: '450', marginTop: 5 }}>
          {valueMapping(selectedTab)}
        </p>
      </div>

      <Tabs.Root
        value={selectedTab}
        onValueChange={value => setSelectedTab(value)}
        style={{ marginTop: 20 }}
      >
        <Tabs.List color="gray">
          <Tabs.Trigger value="general" style={{ cursor: 'pointer' }}>
            General
          </Tabs.Trigger>

          <Tabs.Trigger value="users" style={{ cursor: 'pointer' }}>
            Users
          </Tabs.Trigger>

          <Tabs.Trigger value="api" style={{ cursor: 'pointer' }}>
            API Keys
          </Tabs.Trigger>
          <Tabs.Trigger value="billing" style={{ cursor: 'pointer' }}>
            Billing
          </Tabs.Trigger>
          <Tabs.Trigger value="orgs" style={{ cursor: 'pointer' }}>
            Organizations
          </Tabs.Trigger>
        </Tabs.List>

        <Tabs.Content value="billing" style={{ padding: 20 }}>
          <BillingAndPayments />
          {/* <div
            style={{
              marginTop: 25,
              display: "flex",
              flexDirection: "column",
              gap: 20,
            }}
          >
            <p
              style={{
                fontSize: 18,
                fontWeight: "450",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                columnGap: 8,
              }}
            >
              Billing Details
            </p>
            <BillingAndPayments />
            <Divider />
            <AutoRecharge />
            <Divider />
            <Subscriptions
              portal_url={
                sessionStorage.getItem("billing_redirect_url") ||
                "/dashboard/settings"
              }
            />
          </div> */}
        </Tabs.Content>

        <Tabs.Content value="general" style={{ padding: 20 }}>
          <div style={{ marginTop: 25 }}>
            <SettingsPage />
          </div>
        </Tabs.Content>

        <Tabs.Content value="api" style={{ padding: 20 }}>
          <Keys />
        </Tabs.Content>

        <Tabs.Content value="orgs" style={{ padding: 20 }}>
          <OrgHome />
        </Tabs.Content>

        <Tabs.Content value="users" style={{ padding: 20 }}>
          <OrgManagement />
        </Tabs.Content>
      </Tabs.Root>
    </PageWrapper>
  )
}
