// /components/analytics/CallMap.jsx

import { AlbersUsa, Mercator } from '@visx/geo'
import { geoCentroid } from '@visx/vendor/d3-geo'
import { scaleThreshold } from 'd3-scale'
import { PhoneNumberUtil } from 'google-libphonenumber'
import React from 'react'

import * as topojson from 'topojson-client'
import areaCodeToLocation from './areaCodeData.json' // includes lat & lng
import countryPrefixes from './countryPrefixes.json'
import stateAbbrs from './us-abbr.json'
import usaTopology from './usa-topo.json'
// Topology files
import worldTopology from './world-topo.json'

/**
 * ======================================================
 * 1) Green color scale for states
 * =====================================================
 */
const colorScale = scaleThreshold().domain([1, 5, 10, 20, 30]).range([
  '#e0f2e3', // 1-4
  '#b9e2c8', // 5-9
  '#8bccaa', // 10-19
  '#5cab8a', // 20-29
  '#2e8b63', // 30+
  '#0f7049', // overflow
])

/**
 * unifyCountryName => handle "United States of America" vs "United States"
 */
function unifyCountryName(name) {
  if (name === 'United States of America')
    return 'United States'
  return name
}

/**
 * parseCountryCode => strip leading "+"
 */
function parseCountryCode(code) {
  if (!code)
    return ''
  return code.replace(/^\+/, '').trim()
}

/**
 * 2) WORLD MAP (GeoMercator)
 */
function GeoMercator({
  width,
  height,
  events = false,
  countryCounts = {},
}) {
  const background = '#f9f7e8'
  const world = topojson.feature(worldTopology, worldTopology.objects.units)
  const neutralFill = '#e1e5de'

  if (width < 10)
    return null
  const centerX = width / 2
  const centerY = height / 2
  const scale = (width / 630) * 100

  return (
    <svg width={width} height={height}>
      <rect x={0} y={0} width={width} height={height} fill={background} rx={14} />
      <Mercator
        data={world.features}
        scale={scale}
        translate={[centerX, centerY + 50]}
      >
        {mercator => (
          <g>
            {mercator.features.map(({ feature, path }, i) => {
              const rawName = feature.properties.name || feature.id || ''
              const countryName = unifyCountryName(rawName)
              const calls = countryCounts[countryName] || 0
              const fillColor = calls > 0 ? colorScale(calls) : neutralFill
              return (
                <path
                  key={`world-feature-${i}`}
                  d={path || ''}
                  fill={fillColor}
                  stroke={background}
                  strokeWidth={0.5}
                  onClick={() => {
                    if (events)
                      alert(`Clicked: ${countryName}`)
                  }}
                />
              )
            })}
          </g>
        )}
      </Mercator>
    </svg>
  )
}

/**
 * ======================================================
 * 3) US MAP (GeoAlbersUsa)
 *    - Dots placed by lat/lng if available
 *    - Arcs for justAdded calls last 45-75s
 *    - “Shockwave” circle at destination near end
 *    - Double-stroke arc (white outline + beige stroke)
 * =====================================================
 */
function GeoAlbersUsa({
  width,
  height,
  fullSize = true,
  stateCounts = {},
  areaCodeData = [],
}) {
  const background = '#FFFFF'
  const unitedStates = topojson.feature(usaTopology, usaTopology.objects.states)
    .features

  // We'll skip labeling these smaller states
  const ignoredStates = ['VT', 'NH', 'MA', 'RI', 'CT', 'NJ', 'DE', 'MD']

  // Some label offsets
  const coordOffsets = {
    FL: [11, 3],
    AK: [0, -4],
    CA: [-7, 0],
    NY: [5, 0],
    MI: [13, 20],
    LA: [-10, -3],
    HI: [-10, 10],
    ID: [0, 10],
    WV: [-2, 4],
    KY: [10, 0],
    TN: [0, 4],
  }

  // If fullSize => show text labels
  const [displayLabels] = React.useState(fullSize)

  // randomBetween => used to randomize arc durations
  function randomBetween(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min
  }

  /**
   * parse phone => areaCode
   */
  function extractAreaCode(phoneStr) {
    if (!phoneStr)
      return ''
    const clean = phoneStr.replace(/\D/g, '')
    if (clean.length >= 4 && clean.startsWith('1')) {
      return clean.substring(1, 4)
    }
    return clean.substring(0, 3)
  }

  /**
   * getProjectedPoint => if lat/lng exist => project them
   * else fallback to state centroid
   */
  function getProjectedPoint(areaCode, featureMap, projection) {
    const loc = areaCodeToLocation[areaCode]
    if (!loc)
      return null

    // 1) lat/lng?
    if (typeof loc.latitude === 'number' && typeof loc.longitude === 'number') {
      const coords = projection([loc.longitude, loc.latitude])
      if (coords)
        return coords
    }
    // 2) fallback to state centroid
    if (!loc.state)
      return null
    const stFeature = featureMap[loc.state]
    if (!stFeature)
      return null
    return projection(geoCentroid(stFeature)) || [0, 0]
  }

  /**
   * expandCallsIntoDots => place static dots by lat/lng or fallback
   */
  function expandCallsIntoDots(list, featureMap, projection) {
    const dots = []
    list.forEach(({ areaCode, count }) => {
      if (!areaCode)
        return
      const point = getProjectedPoint(areaCode, featureMap, projection)
      if (!point)
        return
      for (let i = 0; i < count; i++) {
        dots.push({
          x: point[0],
          y: point[1],
          areaCode,
        })
      }
    })
    return dots
  }

  /**
   * expandJustAddedArcs => for calls with `justAdded=true`
   */
  function expandJustAddedArcs(list, featureMap, projection) {
    const arcs = []
    list.forEach((rec) => {
      if (!rec.calls)
        return
      rec.calls.forEach((call) => {
        if (!call.justAdded)
          return

        const fallbackID = `fallback-${call.date}-${Math.random()}`
        const arcID = call.c_id || fallbackID

        // inbound => origin=from, else => origin=to
        const originAC = extractAreaCode(call.inbound ? call.from : call.to)
        const destAC = extractAreaCode(call.inbound ? call.to : call.from)

        if (!originAC || !destAC)
          return

        const originPt = getProjectedPoint(originAC, featureMap, projection)
        const destPt = getProjectedPoint(destAC, featureMap, projection)
        if (!originPt || !destPt)
          return

        const pathD = createArcPath(originPt, destPt)
        arcs.push({
          key: `arc-${arcID}`,
          d: pathD,
          duration: randomBetween(45, 75),
          destX: destPt[0],
          destY: destPt[1],
        })
      })
    })
    return arcs
  }

  /**
   * createArcPath => if same => circle, else => cubic
   */
  function createArcPath([x1, y1], [x2, y2]) {
    const dx = Math.abs(x1 - x2)
    const dy = Math.abs(y1 - y2)
    if (dx < 0.01 && dy < 0.01) {
      // same spot => circle
      const r = 16
      return `M ${x1 - r}, ${y1}
        a ${r},${r} 0 1,0 ${r * 2},0
        a ${r},${r} 0 1,0 -${r * 2},0`
    }
    const arcOffset = -60
    const cx1 = x1
    const cy1 = y1 + arcOffset
    const cx2 = x2
    const cy2 = y2 + arcOffset
    return `M ${x1},${y1}
      C ${cx1},${cy1} ${cx2},${cy2} ${x2},${y2}`
  }

  if (width < 10 || height < 10)
    return null
  const centerX = width / 2
  const centerY = height / 2
  const scale = (width + height) / 1.55

  return (
    <svg
      viewBox={`0 0 ${width} ${height}`}
      preserveAspectRatio="xMidYMid meet"
      style={{
        width: '100%',
        height: '100%',
        maxHeight: 300, // revert to keep map from blowing up in size
        background,
        borderRadius: 14,
      }}
    >
      <defs>
        <filter id="glow">
          <feGaussianBlur stdDeviation="1.2" result="coloredBlur" />
          <feMerge>
            <feMergeNode in="coloredBlur" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
        <style>
          {`
            /* Outline + main arc (double stroke) */
            .arc-outline {
              fill: none;
              stroke: #ffffff;
              stroke-width: 4;
              stroke-dasharray: 500;
              stroke-dashoffset: 500;
            }
            .arc-path {
              fill: none;
              stroke: #d2b48c; /* beige */
              stroke-width: 2;
              stroke-dasharray: 500;
              stroke-dashoffset: 500;
            }

            /* Shockwave circle: big visible fill to see it. */
            .shockwave {
              fill: rgba(210, 180, 140, 0.6);
              stroke: #d2b48c;
              stroke-width: 1;
              opacity: 0;
            }
            @keyframes shockwave {
              0% {
                r: 1;
                opacity: 1;
              }
              50% {
                r: 10;
                opacity: 0.9;
              }
              100% {
                r: 40;
                opacity: 0;
              }
            }

            /* Dots: lighter blue with slight transparency */
            .dot {
              fill: rgba(99, 179, 237, 0.5);
              stroke: #fff;
              stroke-width: 0.6;
              transition: fill 0.2s ease-in-out;
              cursor: pointer;
            }
            .dot:hover {
              /* Slightly less transparent => 0.7 */
              fill: rgba(99, 179, 237, 0.7);
            }
          `}
        </style>
      </defs>

      <AlbersUsa
        data={unitedStates}
        scale={scale}
        translate={[centerX, centerY - 25]}
      >
        {({ features, projection }) => {
          // Build map
          const featureMap = {}
          features.forEach(({ feature }) => {
            const abbr = stateAbbrs[feature.id]
            featureMap[abbr] = feature
          })

          // 1) dots
          const dots = expandCallsIntoDots(areaCodeData, featureMap, projection)

          // 2) arcs
          const arcs = expandJustAddedArcs(areaCodeData, featureMap, projection)

          return (
            <g>
              {/* Render states */}
              {features.map(({ feature, path }, i) => {
                const abbr = stateAbbrs[feature.id] || ''
                const centroid = projection(geoCentroid(feature)) || [0, 0]
                if (coordOffsets[abbr]) {
                  centroid[0] += coordOffsets[abbr][0]
                  centroid[1] += coordOffsets[abbr][1]
                }

                const callsForThisState = stateCounts[abbr] || 0
                const fillColor
                  = callsForThisState > 0
                    ? colorScale(callsForThisState)
                    : '#d1d5db'

                if (ignoredStates.includes(abbr)) {
                  return (
                    <path
                      key={`state-${i}`}
                      d={path || ''}
                      fill={fillColor}
                      stroke="#ffffff"
                      strokeWidth={0.5}
                    />
                  )
                }

                return (
                  <React.Fragment key={`state-${i}`}>
                    <path
                      d={path || ''}
                      fill={fillColor}
                      stroke="#ffffff"
                      strokeWidth={0.5}
                    />
                    {displayLabels && (
                      <text
                        transform={`translate(${centroid})`}
                        fontSize={Math.max(width / 75, 8)}
                        style={{
                          fill: '#2d513a',
                          fontFamily: 'system-ui',
                          fontWeight: 500,
                          cursor: 'default',
                        }}
                        textAnchor="middle"
                      >
                        {abbr}
                      </text>
                    )}
                  </React.Fragment>
                )
              })}

              {/* Dots (no tooltip text now) */}
              {dots
                .reduce((acc, dot) => {
                  // Combine dots close together into one
                  const existingDot = acc.find(
                    d =>
                      Math.abs(d.x - dot.x) < 10 && Math.abs(d.y - dot.y) < 10,
                  )
                  if (existingDot) {
                    existingDot.count += 1
                    existingDot.areaCodes.push(dot.areaCode)
                  }
                  else {
                    acc.push({ ...dot, count: 1, areaCodes: [dot.areaCode] })
                  }
                  return acc
                }, [])
                .map((dot, idx) => (
                  <circle
                    key={`dot-${idx}`}
                    className="dot"
                    cx={dot.x}
                    cy={dot.y}
                    r={Math.min(2 + dot.count, 10)}
                  />
                ))}

              {/* Arcs + shockwave => after dots, so arcs on top */}
              {arcs.map((arc) => {
                const dur = arc.duration
                return (
                  <React.Fragment key={arc.key}>
                    {/* Outline path first */}
                    <path
                      d={arc.d}
                      className="arc-outline"
                      style={{
                        animation: `arcDrawOutline ${dur}s linear forwards`,
                      }}
                    />
                    {/* Main arc stroke */}
                    <path
                      d={arc.d}
                      className="arc-path"
                      style={{
                        animation: `arcDrawMain ${dur}s linear forwards`,
                      }}
                    />
                    {/* Shockwave circle at destination */}
                    <circle
                      className="shockwave"
                      cx={arc.destX}
                      cy={arc.destY}
                      style={{
                        animation: `shockwave 3s ${dur}s ease-out forwards`,
                      }}
                    />
                  </React.Fragment>
                )
              })}
            </g>
          )
        }}
      </AlbersUsa>
      {/* Keyframes for arc animation */}
      <style>
        {`          
          @keyframes arcDrawOutline {
            0% {
              stroke-dashoffset: 500;
              opacity: 1;
            }
            10% {
              stroke-dashoffset: 0;
              opacity: 1;
            }
            80% {
              stroke-dashoffset: 0;
              opacity: 1;
            }
            90% {
              stroke-width: 6;
            }
            95% {
              stroke-width: 4;
            }
            100% {
              opacity: 0;
              stroke-width: 0;
            }
          }
          @keyframes arcDrawMain {
            0% {
              stroke-dashoffset: 500;
              opacity: 1;
            }
            10% {
              stroke-dashoffset: 0;
              opacity: 1;
            }
            80% {
              stroke-dashoffset: 0;
              opacity: 1;
            }
            90% {
              stroke-width: 4;
            }
            95% {
              stroke-width: 2;
            }
            100% {
              opacity: 0;
              stroke-width: 0;
            }
          }
        `}
      </style>
    </svg>
  )
}

/**
 * ======================================================
 * 4) High-level "CallMap"
 *    Summaries calls => decide US vs. World
 * =====================================================
 */
export default function CallMap({
  areaCodeData = [],
  navigate,
  ErrorDisplay,
  Button,
  ChevronRight,
}) {
  if (!areaCodeData || areaCodeData.length === 0) {
    return (
      <div
        style={{
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '10px',
          }}
        >
          <ErrorDisplay message="Your area code data will show up here once you send your first call." />
          <Button
            type="button"
            onClick={() => navigate('/dashboard/send-call')}
            variant="ghost"
            size="2"
            color="gray"
          >
            Send a Call to Get Started
            <ChevronRight size={14} />
          </Button>
        </div>
      </div>
    )
  }

  const phoneUtil = PhoneNumberUtil.getInstance()
  const stateCounts = {}
  const countryCounts = {}
  let isAllUS = true

  areaCodeData.forEach((rec) => {
    if (!rec.calls)
      return

    rec.calls.forEach((call) => {
      const numberStr = call.inbound ? call.from : call.to
      if (!numberStr)
        return
      try {
        const parsed = phoneUtil.parseAndKeepRawInput(numberStr, 'US')
        if (parsed.getCountryCode() === 1) {
          // US => increment state
          const n = parsed.getNationalNumber().toString()
          const ac = n.substring(0, 3)
          const loc = areaCodeToLocation[ac]
          if (loc && loc.state) {
            if (!stateCounts[loc.state])
              stateCounts[loc.state] = 0
            stateCounts[loc.state]++
          }
        }
        else {
          isAllUS = false
          const cStr = `+${parsed.getCountryCode()}`
          const cParsed = parseCountryCode(cStr)
          const cName = countryPrefixes[cParsed]
          if (cName) {
            if (!countryCounts[cName])
              countryCounts[cName] = 0
            countryCounts[cName]++
          }
        }
      }
      catch {}
    })
  })

  // If any call is outside US => show world
  if (!isAllUS) {
    let totalUS = 0
    Object.values(stateCounts).forEach(val => (totalUS += val))
    if (totalUS > 0) {
      if (!countryCounts['United States']) {
        countryCounts['United States'] = 0
      }
      countryCounts['United States'] += totalUS
    }
    // show world
    return (
      <div style={{ width: '100%', maxHeight: 300 }}>
        <GeoMercator width={500} height={300} countryCounts={countryCounts} />
      </div>
    )
  }

  // else => show US
  return (
    <div style={{ width: '100%', maxHeight: 300 }}>
      <GeoAlbersUsa
        width={500}
        height={300}
        fullSize
        stateCounts={stateCounts}
        areaCodeData={areaCodeData}
      />
    </div>
  )
}
