import { Button as ScButton } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { Dialog as DialogHeadless } from '@headlessui/react'
import { addToast, Button as HeroButton, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, useDisclosure } from '@heroui/react'
import * as Dialog from '@radix-ui/react-dialog'
import { MagnifyingGlassIcon, StarFilledIcon } from '@radix-ui/react-icons'
import {
  Badge,
  Button,
  IconButton,
  Tabs,
  TextField,
  Tooltip,
} from '@radix-ui/themes'
import ActionBar from 'components/core/ActionBar'
import DragDropUpload from 'components/core/DragDropUpload'
import { Loading } from 'components/core/Loading'
import { PageTitle } from 'components/core/PageTitle'
import { PageWrapper } from 'components/core/PageWrapper'
import config from 'config'
import {
  AU,
  DE,
  ES,
  FR,
  GB,
  IT,
  NL,
  SE,
  US,
} from 'country-flag-icons/react/3x2'
import { useAuth } from 'hooks/useAuth'
import { debounce } from 'lodash'
import {
  AudioLines,
  Bookmark,
  Clock,
  Copy,
  Delete,
  FileAudio,
  Mic,
  PlayIcon,
  Trash,
  Upload,
  User,
  WholeWord,
} from 'lucide-react'
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { $fetch } from 'utils/fetch'
import { getAuthToken } from 'utils/funcs/browser/getAuthToken'
import { getOrgId } from 'utils/funcs/browser/getOrgId'
import { uploadVoiceClone } from 'utils/funcs/voices/uploadVoiceClone'

const languages = [
  'english',
  'italian',
  'french',
  'german',
  'spanish',
  'british',
  'australian',
  'swedish',
  'dutch',
  'american',
  'filipino',
]

const guidelines = [
  {
    icon: <Clock size={12} />,
    text: 'Use recordings between 2 and 5 minutes for optimal results',
  },
  {
    icon: <Mic size={12} />,
    text: 'Record in a quiet space with minimal background noise for clarity',
  },
  {
    icon: <AudioLines size={12} />,
    text: 'Include natural pauses, inflection, and a flat and even tone in your recording',
  },
  {
    icon: <User size={12} />,
    text: 'Ensure the file has one clear voice with no overlapping sounds',
  },
  {
    icon: <WholeWord size={12} />,
    text: 'Say plenty of common words in the recording, and words relevant to your use case.',
  },
  {
    icon: <FileAudio size={12} />,
    text: 'For highest quality results, record at 48khz or 16khz.',
  },
]

const supportedFileTypes = ['.m4a', '.mp3', '.mpeg', '.x-m4a']

const LANGUAGE_ICONS = {
  english: <US style={{ width: 14, height: 14 }} />,
  italian: <IT style={{ width: 14, height: 14 }} />,
  french: <FR style={{ width: 14, height: 14 }} />,
  german: <DE style={{ width: 14, height: 14 }} />,
  spanish: <ES style={{ width: 14, height: 14 }} />,
  british: <GB style={{ width: 14, height: 14 }} />,
  australian: <AU style={{ width: 14, height: 14 }} />,
  swedish: <SE style={{ width: 14, height: 14 }} />,
  dutch: <NL style={{ width: 14, height: 14 }} />,
  american: <US style={{ width: 14, height: 14 }} />,
  filipino: <span style={{ fontSize: 10 }}>🇵🇭</span>,
}

function CreateVoiceCloneModal({ children }) {
  const [name, setName] = useState('')
  const [uFiles, setUFiles] = useState([])
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()

  const handleSubmit = async () => {
    // Check for files over 11MB
    const oversizedFiles = uFiles.filter(
      file => file.size > 11 * 1024 * 1024,
    )
    if (oversizedFiles.length > 0) {
      toast.error('Files must be under 11MB in size')
      return
    }

    try {
      setLoading(true)
      await uploadVoiceClone(uFiles, name)
      toast.success('Voice clone created!')
      setLoading(false)
      document.getElementById('close').click()
    }
    catch (error) {
      const errorType = error.data?.errors?.[0]?.error
      if (errorType === 'VOICE_LIMIT_REACHED') {
        addToast({
          color: 'danger',
          title: 'Voice Limit Reached',
          description: 'Delete voices or upgrade your plan to clone more.',
          classNames: { base: 'gap-x-0' },
          endContent: (
            <HeroButton size="sm" color="default" className="shrink-0" variant="flat" onPress={() => navigate('/dashboard/infrastructure')}>
              View Plans
            </HeroButton>
          ),
        })
      }
      else {
        toast.error(
          'Error creating voice clone.',
        )
      }
      console.error(error)
      document.getElementById('close').click()
      setLoading(false)
    }
  }

  return (
    <Dialog.Root>
      <Dialog.Trigger asChild>{children}</Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="bg-black/70 fixed inset-0 z-9999999999" />
        <Dialog.Content className="bg-white rounded-sm shadow-lg fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[65%] z-30">
          <div className="flex w-full">
            <div className="w-[65%] p-5 border-r border-gray-300">
              <Dialog.Title>Create a voice clone</Dialog.Title>
              <Dialog.Description style={{ color: 'GrayText' }}>
                Upload high quality audio to create a voice.
              </Dialog.Description>

              <div className="mt-2.5">
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <p>Voice name</p>

                  {!name && (
                    <p style={{ color: '#ef233c', fontSize: 12 }}>Required</p>
                  )}
                </div>
                <Input
                  value={name}
                  onChange={event => setName(event.target.value)}
                  placeholder="Name"
                  className="mt-0.5 focus-visible:ring-transparent"
                />

                {name.length > 30 && (
                  <p style={{ color: '#ef233c', fontSize: 12, marginTop: 2 }}>
                    Voice name must be shorter than 30 characters.
                  </p>
                )}

                <div className="mt-4 mb-4" />

                <p>Voice Description</p>
                <Input
                  placeholder="Describe the tone and accent of the voice"
                  className="mt-0.5 focus-visible:ring-transparent"
                />

                <div className="mt-4 mb-4" />

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <p>Upload Audio Files</p>
                  {uFiles.length === 0 && (
                    <p style={{ color: '#ef233c', fontSize: 12 }}>Required</p>
                  )}
                </div>
                <DragDropUpload
                  label="File Upload"
                  onFilesAdded={setUFiles}
                  uploadedFiles={uFiles}
                  multiple
                  accept={{
                    'audio/mp3': [],
                    'audio/mpeg': [],
                    'audio/x-m4a': [],
                    'audio/m4a': [],
                  }}
                />
              </div>
            </div>
            <div className="w-[35%] bg-gray-100 p-5">
              <Dialog.Title className="mb-2.5">Guidelines</Dialog.Title>
              <div>
                <div className="space-y-2 mb-2.5">
                  {guidelines.map((guideline, index) => (
                    <div key={index} className="flex items-center space-x-2">
                      <div className="p-2 bg-gray-50 rounded-sm">
                        {guideline.icon}
                      </div>
                      <p className="text-gray-700 text-xs">{guideline.text}</p>
                    </div>
                  ))}
                </div>

                <div className="mt-2.5">
                  <p className="text-2xs font-semibold text-gray-600 mb-1.5">
                    Supported File Types:
                  </p>
                  <div className="flex space-x-1.5">
                    {supportedFileTypes.map(type => (
                      <span
                        key={type}
                        className="bg-blue-100 text-blue-800 px-1.5 py-0.5 rounded-full text-2xs"
                      >
                        {type}
                      </span>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              columnGap: 4,
              alignItems: 'center',
              justifyContent: 'end',
            }}
            className="p-5 border-t-2 border-gray-200"
          >
            <Dialog.Close id="close">
              <ScButton variant="ghost" color="gray" type="button">
                <p>Cancel</p>
              </ScButton>
            </Dialog.Close>
            <ScButton
              loading={loading}
              disabled={
                loading || !name || uFiles.length === 0 || name.length > 30
              }
              onClick={() => handleSubmit()}
              type="button"
            >
              {loading
                ? (
                    <Loading loading={loading} color="white" />
                  )
                : (
                    <p>Clone Voice</p>
                  )}
            </ScButton>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}

function hashCode(str) {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i)
    hash = (hash << 5) - hash + char
    hash = hash & hash
  }
  return Math.abs(hash)
}

function pseudoRandom(seed) {
  let x = seed
  return () => {
    x = (x * 166452125 + 1013904223) & 0xFFFFFFFF
    return x >>> 0
  }
}

function generateGradientFromName(name, time = 0) {
  const seed = hashCode(name)
  const rand = pseudoRandom(seed)

  const colorSets = [
    {
      name: 'ocean',
      colors: [
        { h: 207, s: 57, l: 26 },
        { h: 207, s: 56, l: 48 },
        { h: 204, s: 38, l: 56 },
        { h: 208, s: 43, l: 77 },
      ],
      neutral: { h: 24, s: 58, l: 72 },
    },
    {
      name: 'volcanic',
      colors: [
        { h: 357, s: 83, l: 35 },
        { h: 350, s: 75, l: 45 },
        { h: 345, s: 65, l: 30 },
        { h: 355, s: 70, l: 40 },
      ],
      neutral: { h: 350, s: 25, l: 65 },
    },
    {
      name: 'arctic',
      colors: [
        { h: 200, s: 30, l: 85 },
        { h: 210, s: 40, l: 75 },
        { h: 220, s: 50, l: 65 },
        { h: 230, s: 60, l: 55 },
      ],
      neutral: { h: 220, s: 15, l: 70 },
    },
    {
      name: 'glacier',
      colors: [
        { h: 190, s: 40, l: 90 },
        { h: 200, s: 50, l: 80 },
        { h: 210, s: 60, l: 70 },
        { h: 220, s: 70, l: 60 },
      ],
      neutral: { h: 200, s: 20, l: 75 },
    },
    {
      name: 'iceberg',
      colors: [
        { h: 200, s: 45, l: 85 },
        { h: 210, s: 50, l: 75 },
        { h: 220, s: 55, l: 65 },
        { h: 230, s: 60, l: 55 },
      ],
      neutral: { h: 210, s: 20, l: 70 },
    },
    {
      name: 'polar_night',
      colors: [
        { h: 220, s: 30, l: 80 },
        { h: 230, s: 40, l: 70 },
        { h: 240, s: 50, l: 60 },
        { h: 250, s: 60, l: 50 },
      ],
      neutral: { h: 220, s: 20, l: 65 },
    },
    {
      name: 'autumn_glow',
      colors: [
        { h: 25, s: 60, l: 60 },
        { h: 15, s: 50, l: 50 },
        { h: 10, s: 40, l: 40 },
        { h: 5, s: 70, l: 30 },
      ],
      neutral: { h: 20, s: 30, l: 50 },
    },
    {
      name: 'stone_haven',
      colors: [
        { h: 30, s: 20, l: 70 },
        { h: 40, s: 25, l: 60 },
        { h: 50, s: 30, l: 50 },
        { h: 60, s: 35, l: 40 },
      ],
      neutral: { h: 45, s: 20, l: 65 },
    },
    {
      name: 'sunset_dunes',
      colors: [
        { h: 35, s: 50, l: 70 },
        { h: 25, s: 60, l: 60 },
        { h: 15, s: 70, l: 50 },
        { h: 5, s: 80, l: 40 },
      ],
      neutral: { h: 30, s: 25, l: 65 },
    },
    {
      name: 'nebula_meadow',
      colors: [
        { h: 290, s: 50, l: 60 },
        { h: 280, s: 40, l: 70 },
        { h: 320, s: 30, l: 55 },
        { h: 300, s: 60, l: 45 },
      ],
      neutral: { h: 270, s: 20, l: 65 },
    },
    {
      name: 'stellar_forest',
      colors: [
        { h: 150, s: 40, l: 50 },
        { h: 100, s: 30, l: 60 },
        { h: 160, s: 50, l: 40 },
        { h: 170, s: 60, l: 30 },
      ],
      neutral: { h: 160, s: 25, l: 55 },
    },
    {
      name: 'desert_rose',
      colors: [
        { h: 15, s: 60, l: 65 },
        { h: 10, s: 50, l: 55 },
        { h: 5, s: 40, l: 45 },
        { h: 20, s: 50, l: 75 },
      ],
      neutral: { h: 10, s: 30, l: 50 },
    },
    {
      name: 'forest_shade',
      colors: [
        { h: 120, s: 40, l: 30 },
        { h: 130, s: 50, l: 40 },
        { h: 110, s: 30, l: 50 },
        { h: 100, s: 40, l: 35 },
      ],
      neutral: { h: 120, s: 20, l: 45 },
    },
    {
      name: 'celestial_horizon',
      colors: [
        { h: 200, s: 70, l: 50 },
        { h: 220, s: 60, l: 60 },
        { h: 180, s: 50, l: 40 },
        { h: 210, s: 40, l: 45 },
      ],
      neutral: { h: 190, s: 30, l: 55 },
    },
    {
      name: 'sandstone_valley',
      colors: [
        { h: 30, s: 40, l: 70 },
        { h: 20, s: 30, l: 60 },
        { h: 25, s: 35, l: 50 },
        { h: 15, s: 25, l: 40 },
      ],
      neutral: { h: 25, s: 20, l: 55 },
    },
    {
      name: 'spring_meadow',
      colors: [
        { h: 90, s: 60, l: 70 },
        { h: 80, s: 50, l: 60 },
        { h: 100, s: 40, l: 50 },
        { h: 110, s: 50, l: 65 },
      ],
      neutral: { h: 95, s: 30, l: 55 },
    },
    {
      name: 'rainy_day',
      colors: [
        { h: 200, s: 20, l: 70 },
        { h: 210, s: 30, l: 60 },
        { h: 220, s: 40, l: 50 },
        { h: 230, s: 20, l: 40 },
      ],
      neutral: { h: 210, s: 15, l: 55 },
    },
    {
      name: 'orchard_blossom',
      colors: [
        { h: 320, s: 40, l: 75 },
        { h: 330, s: 50, l: 65 },
        { h: 340, s: 30, l: 55 },
        { h: 350, s: 60, l: 70 },
      ],
      neutral: { h: 330, s: 25, l: 60 },
    },
    {
      name: 'red_cliff',
      colors: [
        { h: 15, s: 60, l: 50 }, // Deep sandstone red
        { h: 20, s: 50, l: 60 }, // Burnt orange
        { h: 10, s: 40, l: 40 }, // Desert clay
        { h: 25, s: 30, l: 70 }, // Weathered peach
      ],
      neutral: { h: 20, s: 25, l: 55 }, // Soft terracotta beige
    },
    {
      name: 'moonstone_glow',
      colors: [
        { h: 230, s: 15, l: 80 }, // Pale bluish silver
        { h: 210, s: 25, l: 70 }, // Soft icy blue
        { h: 240, s: 10, l: 60 }, // Smoky gray-blue
        { h: 220, s: 20, l: 50 }, // Deep lunar gray
      ],
      neutral: { h: 220, s: 15, l: 65 }, // Subtle stony gray
    },
    {
      name: 'canyon_dusk',
      colors: [
        { h: 30, s: 40, l: 55 }, // Golden sandstone
        { h: 40, s: 50, l: 65 }, // Desert gold
        { h: 35, s: 30, l: 45 }, // Rocky ochre
        { h: 20, s: 20, l: 40 }, // Shadowed stone
      ],
      neutral: { h: 30, s: 25, l: 50 }, // Warm muted beige
    },
    {
      name: 'obsidian_shade',
      colors: [
        { h: 240, s: 20, l: 25 }, // Deep obsidian black
        { h: 250, s: 15, l: 35 }, // Midnight blue-gray
        { h: 260, s: 10, l: 45 }, // Smoky slate
        { h: 230, s: 20, l: 50 }, // Cool reflective gray
      ],
      neutral: { h: 240, s: 10, l: 40 }, // Charcoal gray
    },
    {
      name: 'desert_onyx',
      colors: [
        { h: 35, s: 50, l: 35 }, // Dark desert brown
        { h: 30, s: 40, l: 45 }, // Warm rust
        { h: 20, s: 30, l: 50 }, // Weathered tan
        { h: 15, s: 20, l: 60 }, // Sunlit beige
      ],
      neutral: { h: 25, s: 15, l: 40 }, // Subdued earthy gray
    },
    {
      name: 'granite_peaks',
      colors: [
        { h: 220, s: 15, l: 60 }, // Cool gray stone
        { h: 210, s: 20, l: 50 }, // Medium granite gray
        { h: 230, s: 10, l: 40 }, // Shadowed granite
        { h: 200, s: 25, l: 70 }, // Polished light gray
      ],
      neutral: { h: 220, s: 10, l: 55 }, // Smooth stony gray
    },
    {
      name: 'opal_reflection',
      colors: [
        { h: 180, s: 40, l: 65 }, // Aqua shimmer
        { h: 200, s: 30, l: 70 }, // Pale bluish-white
        { h: 220, s: 20, l: 60 }, // Soft reflective gray-blue
        { h: 170, s: 25, l: 50 }, // Greenish opal tone
      ],
      neutral: { h: 190, s: 15, l: 65 }, // Muted white-blue
    },
    {
      name: 'basalt_flow',
      colors: [
        { h: 240, s: 30, l: 20 }, // Volcanic black
        { h: 220, s: 20, l: 30 }, // Lava-cool gray
        { h: 210, s: 15, l: 40 }, // Dark basalt gray
        { h: 200, s: 10, l: 50 }, // Weathered stone
      ],
      neutral: { h: 230, s: 10, l: 35 }, // Cool volcanic gray
    },
    {
      name: 'sunbaked_terrace',
      colors: [
        { h: 20, s: 50, l: 50 }, // Warm terracotta
        { h: 25, s: 60, l: 65 }, // Sunlit clay
        { h: 15, s: 40, l: 45 }, // Dry earth
        { h: 30, s: 30, l: 35 }, // Shaded stone
      ],
      neutral: { h: 25, s: 20, l: 55 }, // Soft baked beige
    },
  ]

  const selectedSet = colorSets[seed % colorSets.length]
  const { colors, neutral } = selectedSet

  const density = 60
  const points = []

  const baseFreq = 0.2 + (seed % 1000) / 800
  const complexity = 0.25 + (seed % 1000) / 600

  const waveScale = 0.3 + (seed % 1000) / 2000 + Math.sin(time) * 0.1
  const turbulence = 0.9 + (seed % 1000) / 4000 + Math.cos(time * 1.5) * 0.1
  const direction = ((seed % 1000) / 1000) * Math.PI * 2 + time
  const phaseShift = (seed % 1000) / 200 + time * 3

  const primaryPattern = seed % 2
  const secondaryPattern = (seed >> 8) % 8
  const mixRatio = 0.8 + (seed % 1000) / 2500 + Math.sin(time * 0.5) * 0.15

  for (let y = 0; y < density; y++) {
    for (let x = 0; x < density; x++) {
      const normalizedX = x / density - 0.5
      const normalizedY = y / density - 0.5

      const animX = normalizedX + Math.sin(time + normalizedY * Math.PI) * 0.03
      const animY = normalizedY + Math.cos(time + normalizedX * Math.PI) * 0.04

      const angle = Math.atan2(animY, animX)
      const dist = Math.sqrt(animX * animX + animY * animY)

      let pattern1
      switch (primaryPattern) {
        case 0:
          pattern1 = Math.sin(
            animX * baseFreq * Math.PI
            + animY * complexity * Math.PI
            + phaseShift,
          )
          break
        case 1:
        default:
          pattern1 = Math.sin(
            dist * baseFreq * Math.PI * 2.5 + angle * complexity + phaseShift,
          )
          break
      }

      let pattern2
      switch (secondaryPattern) {
        case 0:
          pattern2 = Math.cos(animX * complexity + animY * baseFreq + time)
          break
        case 1:
          pattern2 = Math.sin(angle * complexity + dist * baseFreq + time)
          break
        default:
          pattern2 = Math.cos(
            dist * complexity * Math.PI + angle * baseFreq + time,
          )
          break
      }

      const basePattern = pattern1 * mixRatio + pattern2 * (1 - mixRatio)
      const turbulentPattern = Math.sin(
        basePattern * Math.PI + turbulence * Math.sin(angle * 4 + time),
      )

      const xOffset = animX + turbulentPattern * 0.8
      const yOffset = animY + turbulentPattern * 0.3

      const patternValue = (turbulentPattern + 1) / 2
      const intersectionStrength = Math.abs(pattern1 * pattern2)

      let hue, saturation, lightness, alpha

      if (intersectionStrength > 0.7) {
        const color = neutral
        hue = color.h
        saturation = color.s
        lightness = color.l
        alpha = 0.4 + intersectionStrength * 0.15
      }
      else {
        const colorIndex = Math.floor(patternValue * 4)
        const color = colors[colorIndex]
        hue = color.h
        saturation = color.s
        lightness = color.l
        alpha = 0.4 + Math.abs(turbulentPattern) * 0.15
      }

      const size = 8

      points.push(
        `radial-gradient(circle at center,
         hsla(${hue}, ${saturation}%, ${lightness}%, ${alpha}) 0%,
         hsla(${hue}, ${saturation}%, ${lightness}%, ${alpha * 0.5}) 50%,
         transparent 100%)
         ${(xOffset + 0.5) * 100}% ${(yOffset + 0.5) * 100}% / ${size}px ${size}px no-repeat`,
      )
    }
  }

  return points.join(', ')
}

const PixelAvatar = React.memo(({ name, isAnimating }) => {
  const [time, setTime] = useState(0)
  const animationRef = useRef()
  const [manualAnimation, setManualAnimation] = useState(false)
  const [inView, setInView] = useState(false)
  const [gradientBase, setGradientBase] = useState(null)
  const containerRef = useRef(null)

  // Intersection Observer to lazy load gradient
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setInView(true)
          observer.disconnect()
        }
      },
      { threshold: 0.1 },
    )
    if (containerRef.current)
      observer.observe(containerRef.current)
    return () => observer.disconnect()
  }, [])

  // Once in view, generate the initial gradient (with time=0)
  useEffect(() => {
    if (inView && gradientBase === null) {
      // Only generate if actually in view
      const initialGradient = generateGradientFromName(name, 0)
      setGradientBase(initialGradient)
    }
  }, [inView, gradientBase, name])

  // Animation logic on isAnimating change
  useEffect(() => {
    if ((isAnimating || manualAnimation) && inView) {
      const startTime = Date.now()
      const animate = () => {
        const currentTime = Date.now()
        const elapsed = (currentTime - startTime) / 2500
        setTime(elapsed)
        animationRef.current = requestAnimationFrame(animate)
      }
      animationRef.current = requestAnimationFrame(animate)
      return () => {
        if (animationRef.current)
          cancelAnimationFrame(animationRef.current)
      }
    }
    else {
      if (animationRef.current)
        cancelAnimationFrame(animationRef.current)
      setTime(0)
    }
  }, [isAnimating, manualAnimation, inView])

  const avatarStyle = useMemo(() => {
    // If not in view or gradient not set yet, no gradient calculation
    if (!inView || !gradientBase) {
      return {
        width: 34,
        height: 34,
        borderRadius: '50%',
        background: '#f3f3f3',
        overflow: 'hidden',
        position: 'relative',
      }
    }

    // If in view and gradient available, generate animated gradient
    return {
      width: 34,
      height: 34,
      borderRadius: '50%',
      background: generateGradientFromName(name, time),
      overflow: 'hidden',
      position: 'relative',
    }
  }, [name, time, gradientBase, inView])

  return (
    <div
      ref={containerRef}
      className="pixel-avatar"
      style={avatarStyle}
      onMouseEnter={() => {
        if (inView)
          setManualAnimation(true)
      }}
      onMouseLeave={() => {
        setManualAnimation(false)
      }}
    >
      {!gradientBase && inView && (
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div
            className="loading-spinner"
            style={{
              width: 14,
              height: 14,
              border: '2px solid #ccc',
              borderTopColor: '#555',
              borderRadius: '50%',
              animation: 'spin 1s linear infinite',
            }}
          >
          </div>
        </div>
      )}
      <style>
        {`
        @keyframes spin {
          0% { transform: rotate(0); }
          100% { transform: rotate(360deg); }
        }
      `}
      </style>
    </div>
  )
})

function VoicesTable({
  title,
  description,
  data,
  api_key,
  ttsText = 'Hey this is Blandie',
  personal = false,
}) {
  const [rawInput, setRawInput] = useState('')
  const [searchQuery, setSearchQuery] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [delVoiceId, setDelVoiceId] = useState(null)
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure({
    onClose: () => {
      setDelVoiceId(null)
    },
  })
  const { refetchUser } = useAuth()

  // Create a debounced function that updates the searchQuery from rawInput

  const [searchInput, setSearchInput] = useState('')
  const [isLoading, setIsLoading] = useState({
    isLoading: false,
    id: undefined,
  })
  const [saveLoading, setSaveLoading] = useState({
    isLoading: false,
    id: undefined,
  })
  const [animatingVoiceId, setAnimatingVoiceId] = useState(null)
  const orgId = getOrgId()

  const getVoiceGender = useCallback((voice) => {
    try {
      const description = voice?.description?.toLowerCase() || ''
      const tags = voice?.tags?.map(tag => tag.toLowerCase()) || []

      if (description.includes('female') || tags.includes('female'))
        return 'Female'
      if (description.includes('male') || tags.includes('male'))
        return 'Male'
      return undefined
    }
    catch {
      return undefined
    }
  }, [])

  const getVoiceLanguage = useCallback((voice) => {
    try {
      const languageTag = voice.tags
        .map(tag => tag.toLowerCase())
        .find(tag => languages.includes(tag))

      const languageInDescription = languages.find(lang =>
        voice.description.toLowerCase().includes(lang.toLowerCase()),
      )

      const foundLanguage = languageTag || languageInDescription

      return foundLanguage ? LANGUAGE_ICONS[foundLanguage.toLowerCase()] : null
    }
    catch {
      return null
    }
  }, [])

  const fetchTTSAudio = async (voiceId) => {
    try {
      setIsLoading({
        isLoading: true,
        id: voiceId,
      })
      const response = await fetch(
        `${config.API_URL}/v1/voices/${voiceId}/sample`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': api_key,
            ...(orgId && { 'x-bland-org-id': orgId }),
          },
          body: JSON.stringify({
            text: ttsText,
            response_type: 'stream',
          }),
        },
      )

      if (response.status === 429) {
        setIsLoading({ isLoading: false, id: undefined })
        return toast.error('Rate limit exceeded. Please try again later.')
      }

      let latency = null
      if (response.ok) {
        latency = response.headers.get('x-latency')
      }

      const mediaSource = new MediaSource()
      const audio = new Audio()
      audio.src = URL.createObjectURL(mediaSource)

      await new Promise((resolve, reject) => {
        mediaSource.addEventListener(
          'sourceopen',
          () => {
            const sourceBuffer = mediaSource.addSourceBuffer('audio/mpeg')
            const reader = response.body.getReader()

            const read = () => {
              reader
                .read()
                .then(({ done, value }) => {
                  if (done) {
                    if (sourceBuffer.updating) {
                      sourceBuffer.addEventListener(
                        'updateend',
                        () => {
                          mediaSource.endOfStream()
                          resolve()
                        },
                        { once: true },
                      )
                    }
                    else {
                      mediaSource.endOfStream()
                      resolve()
                    }
                    return
                  }
                  if (!sourceBuffer.updating) {
                    sourceBuffer.appendBuffer(value)
                    read()
                  }
                  else {
                    sourceBuffer.addEventListener('updateend', read, {
                      once: true,
                    })
                  }
                })
                .catch(reject)
            }

            read()
          },
          { once: true },
        )
      })

      audio.play().catch((err) => {
        alert(`Audio Error: ${err}`)
      })

      setIsLoading({ isLoading: false, id: undefined })
      setTimeout(() => setAnimatingVoiceId(null), 3000)
      return audio
    }
    catch (error) {
      console.error('Failed to fetch TTS audio:', error)
      setIsLoading({ isLoading: false, id: undefined })
      setAnimatingVoiceId(null)
    }
  }

  const addToLibrary = async (voiceId) => {
    try {
      setSaveLoading({
        isLoading: true,
        id: voiceId,
      })
      const response = await fetch(
        `${config.API_URL}/v1/voices/library/add/${voiceId}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': api_key,
            ...(orgId && { 'x-bland-org-id': orgId }),
          },
        },
      )

      if (response.ok) {
        toast.success(
          'Voice added to library. Please refresh the page to view it in your library.',
        )
      }
      else {
        toast.error('Failed to add voice to library.')
      }

      setSaveLoading({
        isLoading: false,
        id: undefined,
      })
    }
    catch (error) {
      setSaveLoading({
        isLoading: false,
        id: undefined,
      })
      console.error('Failed to add voice to library:', error)
    }
  }

  const preprocessedData = useMemo(() => {
    return data.map((item) => {
      const searchFields = [
        item.name?.toLowerCase() || '',
        item.description?.toLowerCase() || '',
        item.id?.toLowerCase() || '',
        ...(item.tags?.map(tag => tag.toLowerCase()) || []),
      ]
      const searchString = searchFields.join(' ')
      return { ...item, __searchString: searchString }
    })
  }, [data])

  const filteredData = useMemo(() => {
    if (!searchInput)
      return preprocessedData
    const searchLower = searchInput.toLowerCase()
    return preprocessedData.filter(item =>
      item.__searchString.includes(searchLower),
    )
  }, [searchInput, preprocessedData])

  const ITEMS_PER_PAGE = 20
  const VIRTUALIZATION_BUFFER = 5

  const deleteVoice = async () => {
    try {
      const response = await $fetch(`/v1/voices/${delVoiceId}`, {
        method: 'DELETE',
      })

      if (response?.data?.message === 'success') {
        setDelVoiceId(null)
        refetchUser()
        onClose()
        return toast.success('Voice removed successfully!')
      }

      return toast.error('Error deleting voice. Please try again later.')
    }
    catch (error) {
      console.error(error)
      return toast.error('Error deleting voice. Please try again later.')
    }
  }

  const paginationInfo = useMemo(() => {
    const totalPages = Math.ceil(filteredData.length / ITEMS_PER_PAGE)
    return {
      totalPages,
      totalItems: filteredData.length,
    }
  }, [filteredData])

  const pageNumbers = useMemo(() => {
    const { totalPages } = paginationInfo
    const maxPagesToShow = 5
    let startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2))
    const endPage = Math.min(totalPages, startPage + maxPagesToShow - 1)

    if (endPage === totalPages) {
      startPage = Math.max(1, totalPages - maxPagesToShow + 1)
    }

    return Array.from(
      { length: Math.min(endPage - startPage + 1, maxPagesToShow) },
      (_, i) => startPage + i,
    )
  }, [currentPage, paginationInfo.totalPages])

  const debouncedSearch = useMemo(
    () =>
      debounce((value) => {
        setSearchInput(value)
        setCurrentPage(1)
      }, 750),
    [setSearchInput, setCurrentPage],
  )

  useEffect(() => {
    debouncedSearch(rawInput)
  }, [rawInput])

  const [renderedData, setRenderedData] = useState([])

  useEffect(() => {
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE
    const endIndex = startIndex + ITEMS_PER_PAGE + VIRTUALIZATION_BUFFER
    const virtualizedData = filteredData.slice(startIndex, endIndex)
    setRenderedData(virtualizedData)
  }, [filteredData, currentPage])

  return (
    <>
      <div className="mb-2.5">
        <h2>
          {title}
          {' '}
          (
          {paginationInfo.totalItems || 0}
          )
        </h2>
        <p style={{ fontWeight: '400', color: 'GrayText' }}>{description}</p>
      </div>
      <TextField.Root
        value={rawInput}
        onChange={event => setRawInput(event.target.value)}
        className="w-full mb-4 focus:outline-none"
        placeholder="Search Voices..."
      >
        <TextField.Slot>
          <MagnifyingGlassIcon />
        </TextField.Slot>
      </TextField.Root>
      <table className="w-full border-collapse">
        <thead>
          <tr>
            <th />
            <th />
            <th />
          </tr>
        </thead>
        <tbody>
          {renderedData.map((item, key) => (
            <tr key={key} className="border-b border-gray-200 row-wrapper">
              <td className="p-4 py-5 border-r border-gray-100 border-dashed">
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    columnGap: 18,
                  }}
                >
                  <PixelAvatar
                    name={item?.name}
                    isAnimating={animatingVoiceId === item.id}
                  />
                  <div style={{ overflow: 'hidden' }}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        columnGap: 6,
                      }}
                    >
                      <p
                        style={{ fontSize: 14, fontWeight: '500' }}
                        onMouseEnter={() => {
                          setAnimatingVoiceId(item.id)
                        }}
                        onMouseLeave={() => {
                          setAnimatingVoiceId(null)
                        }}
                      >
                        {item?.name}
                      </p>

                      {getVoiceLanguage(item) && <>{getVoiceLanguage(item)}</>}
                      {getVoiceGender(item) && (
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            columnGap: 6,
                          }}
                        >
                          <div
                            style={{
                              height: 14,
                              background: '#f3f3f3',
                              width: 2,
                            }}
                          />
                          <p
                            style={{
                              fontSize: 10.5,
                              fontWeight: '450',
                              color: 'gray',
                            }}
                          >
                            {getVoiceGender(item)}
                          </p>
                        </div>
                      )}
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'start',
                        alignItems: 'center',
                        columnGap: 6,
                      }}
                    >
                      {item?.description && (
                        <p
                          style={{
                            fontSize: 10.5,
                            fontWeight: '400',
                            color: 'GrayText',
                          }}
                        >
                          {item?.description}
                        </p>
                      )}

                      {item?.average_rating && item.description && (
                        <div
                          style={{
                            height: 14,
                            background: '#f3f3f3',
                            width: 2,
                          }}
                        />
                      )}

                      {item?.average_rating && (
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                          }}
                        >
                          <StarFilledIcon
                            color="gold"
                            style={{
                              height: 10.5,
                              width: 10.5,
                              marginRight: 2,
                            }}
                          />
                          <p
                            style={{
                              fontSize: 10.5,
                              fontWeight: '400',
                              color: 'GrayText',
                            }}
                          >
                            {Number(item?.average_rating)?.toFixed(1)}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>

                  <div style={{ marginLeft: 'auto' }}>
                    <Tooltip
                      content="Copy voice ID to clipboard"
                      side="top"
                      align="start"
                      dir="rtl"
                    >
                      <Copy
                        onClick={() => {
                          window.navigator.clipboard.writeText(item.id)
                          return toast.success('Copied voice ID to clipboard.')
                        }}
                        style={{ cursor: 'pointer' }}
                        size={15}
                        color="gray"
                      />
                    </Tooltip>
                  </div>
                  {personal && (
                    <div>
                      <HeroButton
                        color="danger"
                        variant="flat"
                        size="sm"
                        isIconOnly
                        onPress={(event) => {
                          onOpen(event)
                          setDelVoiceId(item.id)
                        }}
                      >
                        <Trash color="red" size={14} />
                      </HeroButton>
                    </div>
                  )}
                </div>
              </td>
              <td className="p-4 border-r border-gray-100 border-dashed">
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: 6,
                  }}
                >
                  {item.tags?.filter(tag => tag !== 'cloned')?.map((tag, k) => (
                    <Badge key={k} color="iris" variant="soft" size="2">
                      {tag}
                    </Badge>
                  ))}
                </div>
              </td>
              <td className="p-4">
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: 8,
                  }}
                >
                  {!personal && (
                    <Tooltip
                      content="Save voice to your library"
                      side="bottom"
                      align="start"
                    >
                      <IconButton
                        loading={
                          saveLoading.isLoading && saveLoading.id === item.id
                        }
                        onClick={() => addToLibrary(item.id)}
                        color="violet"
                        size="1"
                        variant="ghost"
                      >
                        <Bookmark size={16} />
                      </IconButton>
                    </Tooltip>
                  )}

                  <Button
                    onMouseEnter={() => {
                      setAnimatingVoiceId(item.id)
                    }}
                    onMouseLeave={() => {
                      setAnimatingVoiceId(null)
                    }}
                    loading={isLoading.isLoading && isLoading.id === item.id}
                    type="button"
                    onClick={() => fetchTTSAudio(item.id)}
                    variant="soft"
                    color="gray"
                    size="1"
                    style={{ cursor: 'pointer' }}
                  >
                    Play
                    <PlayIcon size={10} strokeWidth={3} />
                  </Button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {paginationInfo.totalPages > 1 && (
        <div className="flex justify-center items-center mt-2.5 space-x-1.5">
          <Button
            variant="soft"
            color="gray"
            onClick={() => setCurrentPage(prev => Math.max(1, prev - 1))}
            disabled={currentPage === 1}
            size="1"
          >
            Previous
          </Button>

          {pageNumbers.map(number => (
            <Button
              key={number}
              variant={currentPage === number ? 'surface' : 'soft'}
              color={currentPage === number ? 'green' : 'gray'}
              onClick={() => setCurrentPage(number)}
              size="1"
            >
              {number}
            </Button>
          ))}

          <Button
            variant="soft"
            size="1"
            color="gray"
            onClick={() =>
              setCurrentPage(prev =>
                Math.min(paginationInfo.totalPages, prev + 1),
              )}
            disabled={currentPage === paginationInfo.totalPages}
          >
            Next
          </Button>
        </div>
      )}
      <Modal isOpen={isOpen} onOpenChange={onOpenChange} radius="sm" size="3xl">
        <ModalContent>
          {onClose => (
            <>
              <ModalHeader className="pb-0">
                <div className="text-2xl">
                  Delete Voice
                </div>
              </ModalHeader>
              <ModalBody>
                <div style={{ fontSize: 14, fontWeight: '450' }}>Are you sure you want to delete this voice? This action cannot be undone.</div>
              </ModalBody>
              <ModalFooter>
                <HeroButton color="grey" variant="flat" onPress={onClose}>
                  Cancel
                </HeroButton>
                <HeroButton color="danger" type="button" onPress={() => deleteVoice()}>
                  Delete
                </HeroButton>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  )
}

export default function NewVoices() {
  const { user } = useAuth()
  const { voice_options = [], user: lsUser } = user
  const api_key = getAuthToken(false)
  const [tab, setTab] = useState('curated')
  const [showCustomizeModal, setShowCustomizeModal] = useState(false)
  const [tempSampleText, setTempSampleText] = useState(
    'Hey this is Blandie, can you hear me alright?',
  )

  const privateVoicesList = useMemo(
    () =>
      voice_options.filter(
        voice =>
          voice.user_id !== null
          && (voice.user_id === lsUser?.id || voice.user_id === getOrgId())
          && !voice.name.includes('Public -'),
      ),
    [voice_options],
  )

  const curatedVoicesList = useMemo(
    () =>
      voice_options.filter(
        voice =>
          voice?.tags?.includes('Bland Curated') && voice?.user_id == null,
      ),
    [voice_options],
  )

  return (
    <PageWrapper>
      <ActionBar top spaceBetween>
        <PageTitle>Voices</PageTitle>
        <CreateVoiceCloneModal>
          <Button
            color="iris"
            variant="solid"
            className="align-items"
            style={{ cursor: 'pointer' }}
          >
            <Upload size={14} />
            <p style={{ fontSize: 14.3 }}>Upload new voice</p>
          </Button>
        </CreateVoiceCloneModal>
      </ActionBar>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <h1 className="font-semibold">Voice Library</h1>
        <Button
          variant="soft"
          type="button"
          style={{ cursor: 'pointer' }}
          onClick={() => setShowCustomizeModal(true)}
        >
          Customize TTS Text
        </Button>
      </div>

      <div style={{ marginTop: 20 }}>
        <Tabs.Root value={tab} onValueChange={value => setTab(value)}>
          <Tabs.List color="gray" highContrast className="mb-6">
            <Tabs.Trigger value="curated" style={{ cursor: 'pointer' }}>
              Curated Voices
            </Tabs.Trigger>
            <Tabs.Trigger value="account" style={{ cursor: 'pointer' }}>
              Your Voices
            </Tabs.Trigger>
            <Tabs.Trigger value="all" style={{ cursor: 'pointer' }}>
              All Voices
            </Tabs.Trigger>
          </Tabs.List>

          <Tabs.Content value="account">
            <VoicesTable
              ttsText={tempSampleText}
              title="Cloned & Saved Voices"
              description="Private cloned voices and saved voice library."
              api_key={api_key}
              data={privateVoicesList}
              personal
            />
          </Tabs.Content>
          <Tabs.Content value="curated">
            <VoicesTable
              ttsText={tempSampleText}
              title="Bland Curated Voices"
              description="Handpicked voices tailored for profressional use cases."
              api_key={api_key}
              data={curatedVoicesList}
            />
          </Tabs.Content>
          <Tabs.Content value="all">
            <VoicesTable
              ttsText={tempSampleText}
              title="All Public Voices"
              description="All available public voices, including cloned voices."
              api_key={api_key}
              data={
                voice_options?.filter(
                  item => item?.user_id !== getOrgId() || !item?.user_id,
                ) || []
              }
            />
          </Tabs.Content>
        </Tabs.Root>
      </div>

      <DialogHeadless
        open={showCustomizeModal}
        onClose={() => setShowCustomizeModal(false)}
        className="fixed inset-0 z-10 overflow-y-auto"
      >
        <div className="flex items-center justify-center min-h-screen">
          <DialogHeadless.Overlay className="fixed inset-0 bg-black opacity-30" />
          <div className="relative bg-white rounded-md w-[400px] mx-2.5 p-4 shadow-xl">
            <DialogHeadless.Title className="text-sm font-medium text-gray-900 mb-2.5">
              Customize TTS Sample Text
            </DialogHeadless.Title>
            <textarea
              value={tempSampleText}
              onChange={e => setTempSampleText(e.target.value)}
              className="w-full h-24 p-1.5 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-indigo-500 resize-none mb-2.5"
              placeholder="Enter your custom text here..."
            />
            <div className="flex justify-end space-x-2.5">
              <button
                onClick={() => setShowCustomizeModal(false)}
                className="px-2.5 py-1.5 bg-gray-200 text-gray-800 rounded hover:bg-gray-300 transition duration-300 ease-in-out"
              >
                Cancel
              </button>
              <button
                onClick={() => setShowCustomizeModal(false)}
                className="px-2.5 py-1.5 bg-indigo-600 text-white rounded hover:bg-indigo-700 transition duration-300 ease-in-out"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </DialogHeadless>
    </PageWrapper>
  )
}
