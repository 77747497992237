import { CreditCardIcon, PencilIcon } from '@heroicons/react/24/outline'
import { Chip } from '@heroui/react'
import { Badge, Button, Skeleton } from '@radix-ui/themes'
import { formatDistanceToNow } from 'date-fns'
import { useAuth } from 'hooks/useAuth'
import {
  BadgeCheck,
  CircleSlash,
  DollarSign,

} from 'lucide-react'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { $fetch } from 'utils/fetch'
import {
  generateInvoicePDF,
  getCreditPurchases,
} from 'utils/funcs/purchase-credits/purchaseCredits'
import { ReturnMap } from 'utils/tools/mapRateBilling'
import { getOrgId } from '../../../utils/funcs/browser/getOrgId'
import AutoRecharge from './AutoRecharge'
import EditBillingInfoModal from './EditBillingInfoModal'

function BillingAndPayments({ embedded = false }) {
  const orgId = getOrgId()
  const { org, credits } = useAuth()
  const [hasPaymentMethod, setHasPaymentMethod] = useState(false)
  const [stripeUrl, setStripeUrl] = useState()
  const [paymentHistory, setPaymentHistory] = useState(null)
  const [loadingPH, setLoadingPH] = useState(false)
  const [loading, setLoading] = useState(false)
  const [stripeCustomer, setStripeCustomer] = useState(null)

  const fetchStripeUrl = async () => {
    const headers = {
      ...(orgId && { 'x-bland-org-id': orgId }),
    }
    await $fetch('/pay/createDetails', {
      method: 'POST',
      headers,
    })
    const res = await $fetch(`/orgs/${orgId}/billing/session`)
    setStripeUrl(res.data?.redirect_url)
    sessionStorage.setItem('billing_redirect_url', res.data?.redirect_url)
  }

  const fetchStripeDetails = async () => {
    setLoading(true)
    const res = await $fetch('/billing/customer')

    if (res.data) {
      setHasPaymentMethod(res.data.has_payment_method)
      if (res?.data?.customer) {
        setStripeCustomer(res?.data?.customer)
      }
    }
    setLoading(false)
  }

  // We don't have access to payment details via /billing/customer
  // const getPaymentMethodIcon = () => {
  //   switch (paymentMethodType) {
  //     case 'card':
  //       return (
  //         <img
  //           src={`/cards/${paymentMethodDetails.brand}.svg`}
  //           alt="payment-method"
  //           className="w-6 h-4 object-contain"
  //         />
  //       )
  //     case 'us_bank_account':
  //       return <Landmark className="w-4 h-4 text-gray-600" />
  //     default:
  //       return <HelpCircle className="w-4 h-4 text-gray-600" />
  //   }
  // }

  // const formatPaymentMethodDetails = () => {
  //   if (paymentMethodType === 'card') {
  //     return `${paymentMethodDetails.brand.charAt(0).toUpperCase() + paymentMethodDetails.brand.slice(1)} **** ${paymentMethodDetails.last4}`
  //   }
  //   else if (paymentMethodType === 'us_bank_account') {
  //     return `Bank account **** ${paymentMethodDetails.last4}`
  //   }
  //   else {
  //     return 'Payment method on file'
  //   }
  // }

  const openStripePortal = async () => {
    if (!stripeUrl) {
      toast.error('You don\'t have permission to edit this payment method')
    }
    else {
      window.open(stripeUrl, '_blank')
    }
  }

  const handleGetPurchases = async () => {
    try {
      setLoadingPH(true)
      const purchases = await getCreditPurchases()
      if (purchases && typeof purchases === 'object') {
        setPaymentHistory(purchases)
      }

      setLoadingPH(false)
    }
    catch (error) {
      setLoadingPH(false)
    }
  }

  useEffect(() => {
    // To determine if there is a payment method on file
    fetchStripeDetails()
    // To determine if user has permissions
    fetchStripeUrl()
    // get payment history
    handleGetPurchases()
  }, [])

  // return (
  //   <div className="space-y-2.5">
  //     <h5 className="text-sm font-semibold text-gray-900">Payment Method</h5>
  //     {hasPaymentMethod ? (
  //       <div className="flex items-center justify-between p-2.5 bg-white rounded-md shadow-sm border border-gray-200">
  //         <div className="flex items-center space-x-2.5">
  //           {getPaymentMethodIcon()}
  //           <span className="text-xs font-medium text-gray-700">
  //             {formatPaymentMethodDetails()}
  //           </span>
  //         </div>
  //         <button
  //           onClick={openStripePortal}
  //           className="inline-flex items-center px-2 py-1.5 border border-gray-300 shadow-sm text-xs leading-2.5 font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
  //         >
  //           <PencilIcon className="h-2.5 w-2.5 mr-1.5" />
  //           Edit
  //         </button>
  //       </div>
  //     ) : loading ? (
  //       <Skeleton>
  //         <div className="flex items-center justify-between p-2.5 bg-white rounded-md shadow-sm border border-gray-200">
  //           <div className="flex items-center space-x-2.5">
  //             {getPaymentMethodIcon()}
  //             <span className="text-xs font-medium text-gray-700">
  //               {formatPaymentMethodDetails()}
  //             </span>
  //           </div>
  //           <button
  //             onClick={openStripePortal}
  //             className="inline-flex items-center px-2 py-1.5 border border-gray-300 shadow-sm text-xs leading-2.5 font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
  //           >
  //             <PencilIcon className="h-2.5 w-2.5 mr-1.5" />
  //             Edit
  //           </button>
  //         </div>
  //       </Skeleton>
  //     ) : (
  //       <div className="flex items-center justify-between p-2.5 bg-white rounded-md shadow-sm border border-gray-200">
  //         <span className="text-xs text-gray-500">
  //           No payment method added yet.
  //         </span>
  //         <button
  //           onClick={openStripePortal}
  //           className="inline-flex items-center px-2 py-1.5 border border-transparent text-xs leading-2.5 font-medium rounded text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
  //         >
  //           <CreditCardIcon className="h-2.5 w-2.5 mr-1.5" />
  //           Add Payment Method
  //         </button>
  //       </div>
  //     )}
  //   </div>
  // );

  return (
    <div className="py-5">
      <div className="border-[1.5px] rounded-lg border-color:#f0f0f0 shadow-sm">
        <div className="border-b border-color:#f0f0f0 p-4 py-5">
          <div className="flex justify-between items-center">
            <span className="text-black font-semibold text-base">
              Billing Details
            </span>
            <div className="flex items-center gap-2.5">
              <Button
                variant="outline"
                color="gray"
                radius="large"
                type="button"
                onClick={openStripePortal}
              >
                <span className="text-black">Manage Billing</span>
              </Button>
            </div>
          </div>
        </div>
        <div className="border-b border-color:#f0f0f0 p-4">
          <div className="flex items-center justify-between">
            <span className="text-black font-medium text-md">Current Plan</span>
            <div className="">
              <Badge size="2" radius="full">
                {ReturnMap(org.org_rate_limit)?.description}
              </Badge>
              <p></p>
            </div>
          </div>

          <div className="flex items-start justify-between mt-5">
            <span className="text-black font-normal text-xs">Rate Limit</span>
            <div className="flex flex-col gap-2">
              <p className="text-xs font-normal text-right">
                {ReturnMap(org.org_rate_limit)?.minute}
                {' '}
                calls / minute
              </p>
              <p className="text-xs font-normal text-right">
                {ReturnMap(org.org_rate_limit)?.hourly}
                {' '}
                calls / hourly
              </p>
              <p className="text-xs font-normal text-right">
                {ReturnMap(org.org_rate_limit)?.daily}
                {' '}
                calls / daily
              </p>
            </div>
          </div>
        </div>
        <div className="border-b border-color:#f0f0f0 p-4">
          <div className="flex items-center justify-between">
            <span className="text-black font-medium text-md">
              Available Credits
            </span>
            <span className="text-black font-normal text-xs">
              {credits?.toFixed(2) || 'Not available'}
            </span>
          </div>
        </div>
        <div className="border-b border-color:#f0f0f0 p-4">
          <div className="flex items-center justify-between">
            <span className="text-black font-medium text-md">
              Payment Method
            </span>
            <div>
              {hasPaymentMethod
                ? (
                    <Chip
                      size="sm"
                      color="success"
                      classNames={{
                        content: 'font-bold',
                      }}
                      variant="faded"
                      startContent={<BadgeCheck size={16} className="fill-green-500 text-gray-50" />}
                    >
                      Payment Method on File
                    </Chip>
                  )
                : loading
                  ? (
                      <Skeleton className="w-48 h-6" />
                    )
                  : (
                      <button
                        onClick={openStripePortal}
                        className="inline-flex items-center px-2 py-1.5 border border-transparent text-xs leading-2.5 font-medium rounded text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        <CreditCardIcon className="h-2.5 w-2.5 mr-1.5" />
                        Add Payment Method
                      </button>
                    )}
            </div>
          </div>
        </div>
        <div className="p-4 flex flex-col gap-4">
          <div className="flex items-center w-full">
            <span className="text-black font-medium text-md">
              Billing Information
            </span>
            <p className="text-gray-500 text-xs ml-4 mr-auto">
              This information will be displayed on your invoices.
            </p>
            <EditBillingInfoModal stripeCustomer={stripeCustomer} onSuccess={fetchStripeDetails} />
          </div>

          {stripeCustomer?.name
            && (
              <span className="flex justify-between">
                <p className="text-black font-medium text-md">Name</p>
                <p className="text-black font-normal text-md">{stripeCustomer?.name ?? 'Not set'}</p>
              </span>
            )}
          {stripeCustomer?.email && (
            <span className="flex justify-between">
              <p className="text-black font-medium text-md">Email</p>
              <p>{stripeCustomer?.email}</p>
            </span>
          )}
          {stripeCustomer?.phone && (
            <span className="flex justify-between">
              <p className="text-black font-medium text-md">Phone</p>
              <p>{stripeCustomer?.phone}</p>
            </span>
          )}
          {stripeCustomer?.address?.line1 && (
            <span className="flex justify-between">
              <p className="text-black font-medium text-md">Address</p>
              <span className="flex flex-col items-end gap-1">
                <p>{stripeCustomer?.address?.line1}</p>
                {stripeCustomer?.address?.line2 && (
                  <p>{stripeCustomer?.address?.line2}</p>
                )}
                <p>{stripeCustomer?.address?.city}</p>
                <p>{stripeCustomer?.address?.state}</p>
                <p>{stripeCustomer?.address?.postal_code}</p>
                <p>{stripeCustomer?.address?.country}</p>
              </span>
            </span>
          )}
          {stripeCustomer?.tax_id?.value && (
            <span className="flex justify-between">
              <p className="text-black font-medium text-md">Tax ID</p>
              <p>{stripeCustomer?.tax_id?.value ?? 'Not set'}</p>
            </span>
          )}
        </div>

      </div>

      {!embedded && (
        <div className="border-[1.5px] rounded-lg border-color:#f0f0f0 shadow-sm p-4 mt-8">
          <AutoRecharge />
        </div>
      )}

      <div className="mt-8 w-full">
        <div>
          <div>
            <span className="text-black font-medium text-lg">
              Credit Purchases
            </span>
          </div>
          <span className="text-md">
            Payment history only includes credit top ups. All subscriptions can
            be managed via the
            {' '}
            <span
              onClick={openStripePortal}
              style={{ textDecorationLine: 'underline', cursor: 'pointer' }}
            >
              Manage Billing portal ↗
            </span>
          </span>
        </div>
        <div
          className="mt-5"
          style={{ width: '100%', height: 1, background: '#f0f0f0' }}
        />
        {/* ^^^ ignore my fuckery here */}
        {loadingPH && paymentHistory === null && (
          <Skeleton height="300px" width="100%" className="mt-5" />
        )}
        {!loadingPH && paymentHistory?.length === 0 && (
          <div className="w-full flex justify-center mt-9">
            <div className="flex items-center gap-2">
              <CircleSlash size={14} color="gray" />
              No Payment History
            </div>
          </div>
        )}
        {!loadingPH && paymentHistory?.length > 0 && (
          <div className="flex flex-col gap-2.5">
            {paymentHistory?.map((payment, index) => (
              <div
                key={index}
                className="py-4 border-b"
                style={{ borderColor: '#f0f0f0' }}
              >
                <div className="flex items-center">
                  <div className="mr-1.5">
                    <p style={{ fontSize: 15, fontWeight: '400' }}>
                      Credit Top-Up
                    </p>
                  </div>
                  <div className="bg-green-50 p-0.5 rounded-">
                    <DollarSign size={10} strokeWidth={2} color="green" />
                  </div>
                  <div className="ml-auto">
                    <p
                      className="text-xs font-normal text-black"
                      style={{
                        textAlign: 'right',
                      }}
                    >
                      {payment?.amount?.toFixed(0) || '-'}
                      {' '}
                      credits
                    </p>
                    <p
                      style={{
                        fontSize: 9,
                        fontWeight: '450',
                        textAlign: 'right',
                        color: 'GrayText',
                      }}
                    >
                      {formatDistanceToNow(new Date(payment?.created_at), {
                        addSuffix: true,
                      })}
                    </p>
                  </div>
                  <div className="ml-4">
                    <Button
                      variant="outline"
                      color="gray"
                      radius="large"
                      type="button"
                      onClick={() =>
                        generateInvoicePDF(payment, stripeCustomer)}
                    >
                      {/* <FileDown color="black" size={12} /> */}
                      <span className="text-black">Invoice</span>
                    </Button>
                  </div>
                  {/* <div className="ml-1.5">
                    <Button variant="outline" color="gray" radius="large">
                      <span className="text-black">Receipt</span>
                    </Button>
                  </div> */}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default BillingAndPayments
