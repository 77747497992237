import AccountSelector from 'components/Dashboard/AccountSelector'
import { useAuth } from 'hooks/useAuth'
import { useOnboarding } from 'hooks/useOnboarding'
import { useCallback, useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import { dashboard } from '../../utils/data/menus/sideMenuItems'
import blandlogo from './../../assets/brand/bland-b.svg'
import blandfont from './../../assets/brand/new-bland-logo.svg'
import MenuItem from './MenuItem'

export default function SideMenu() {
  const { permissions, loading } = useAuth()
  const [hover, setHover] = useState(false)

  const [accountSelectorOpen, setAccountSelectorOpen] = useState(false) // New state for AccountSelector

  const {
    state: { sideMenuOpen },
  } = useOnboarding()

  const handleMouseEnter = () => {
    if (!accountSelectorOpen) {
      setHover(true)
    }
  }

  const handleMouseLeave = () => {
    if (!accountSelectorOpen) {
      setHover(false)
    }
  }

  useEffect(() => {
    const handleResize = () => {
      if (window.innerHeight < 519) {
        setAccountSelectorOpen(false)
      }
    }

    window.addEventListener('resize', handleResize)
    handleResize() // Call handleResize initially in case the window size is already less than 418px

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    if (sideMenuOpen === true) {
      setHover(true)
    }
    else {
      setHover(false)
    }
  }, [sideMenuOpen])

  const allowedSideBarItems = useCallback(() => {
    if (!loading) {
      if (permissions?.includes('viewer')) {
        return dashboard.filter(page => page.query !== 'send-call')
      }

      return dashboard
    }

    return dashboard
  }, [loading, permissions])

  return (
    <Wrapper
      hover={hover}
      selectorOpen={accountSelectorOpen}
      onMouseEnter={handleMouseEnter} // Updated to use the new function
      onMouseLeave={handleMouseLeave}
      id="side-menu"
    >
      <TopFixed hover={hover}>
        <Logo>
          <BrandIcon
            src={blandlogo}
            alt=""
            width={25}
            height={25}
            hover={hover}
          />
          <BrandFont src={blandfont} alt="" hover={hover} />
        </Logo>
      </TopFixed>
      <MenuSection>
        <Menu>
          {allowedSideBarItems().map(page => (
            <MenuItem key={page.query ?? page.text} className={page.query} page={page} setHover={setHover} hover={hover}>
              <Icon hover={hover}>{page.icon}</Icon>
              {hover && <Text>{page.text}</Text>}
            </MenuItem>
          ))}
        </Menu>
      </MenuSection>

      <BottomFixed
        hover={hover}
        selectorOpen={accountSelectorOpen}
        style={{ background: '#fff', padding: '7px' }}
        // onClick={(event) => {
        //   // event.preventDefault();
        //   // event.stopPropagation();
        //   setAccountSelectorOpen(true); // Set AccountSelector open
        // }}
      >
        <AccountSelector
          open={accountSelectorOpen}
          onClick={() => setAccountSelectorOpen(true)}
          onClose={() => {
            setAccountSelectorOpen(false)
            // setHover(false);
          }}
        />
        {' '}
        {/* Pass onClose prop */}
      </BottomFixed>
    </Wrapper>
  )
}

// Keyframe animation for spring effect
const springAnimation = keyframes`
  0% { transform: scaleY(0); }
  50% { transform: scaleY(1.1); }
  100% { transform: scaleY(1); }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  height: 100%;
  width: ${({ hover }) => (hover ? '250px' : '54px')};
  border-right: 1px solid #eeeeee;
  padding: 58px 5px 0px 5px;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 5000;
  transition: width 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  background: #fff;

  &::after {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    z-index: 4999;
    opacity: ${({ selectorOpen }) => (selectorOpen ? 1 : 0)};
    transition: opacity 0.3s ease-in-out;
    animation: ${({ selectorOpen }) =>
      selectorOpen ? springAnimation : 'none'}
      0.3s forwards;
    pointer-events: ${({ selectorOpen }) =>
      selectorOpen ? 'auto' : 'none'}; // Allow clicks only when open
  }
`

const MenuSection = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  width: 100%;
  overflow: hidden;
`

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
  overflow-y: auto;
`

const Logo = styled.div`
  border-bottom: 1px solid #eeeeee;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0px 12px;
  gap: 12px;
  position: relative;
`

const BrandIcon = styled.img`
  width: 27px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  opacity: ${({ hover }) => (hover ? 0 : 1)};
  transition: opacity 0.3s cubic-bezier(0.23, 1, 0.32, 1);
`

const BrandFont = styled.img`
  width: 120px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  opacity: ${({ hover }) => (hover ? 1 : 0)};
  transition: opacity 0.3s cubic-bezier(0.23, 1, 0.32, 1);
`

const Icon = styled.div`
  height: 100%;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Text = styled.div`
  white-space: nowrap;
  transition: 0.3s ease-in-out;
`

const TopFixed = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
  z-index: 100;
  height: 54px;
  width: ${({ hover }) => (hover ? '250px' : '54px')};
  border-right: 1px solid #eeeeee;
  overflow-x: hidden;
  transition: width 0.3s cubic-bezier(0.23, 1, 0.32, 1);
`

const BottomFixed = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  height: 54px;
  width: ${({ hover }) => (hover ? '250px' : '54px')};
  padding-top: 16px;
  padding-bottom: 16px;
  padding-right: 12px;
  padding-left: 12px;
  border-right: 1px solid #eeeeee;
  border-top: 1px solid #eeeeee;
  display: flex;
  align-items: center;
  overflow: hidden;
  z-index: 9999999999;
  transition: width 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  box-shadow: none;
`
