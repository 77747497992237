import { Button, Spinner } from '@radix-ui/themes'
import { CheckCheck } from 'lucide-react'
import { useCallback, useEffect, useState } from 'react'
import Editor from 'react-simple-wysiwyg'
import { toast } from 'react-toastify'
import { $fetch } from 'utils/fetch'

export function NoteEditor({ initialValue, callId, setNotes }) {
  const [html, setHtml] = useState(initialValue || '')
  const [loading, setLoading] = useState(false)
  const [lastSavedContent, setLastSavedContent] = useState(initialValue || '')

  useEffect(() => {
    setHtml(initialValue || '')
    setLastSavedContent(initialValue || '')
  }, [initialValue, callId])

  const autoSave = useCallback(
    async (content) => {
      try {
        if (content === lastSavedContent || loading) {
          return
        }
        setLoading(true)
        const data = await $fetch(`/v1/notes/update`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ comment: content, call_id: callId }),
        })

        if (data) {
          setNotes(content)
          setLastSavedContent(content)
          toast.success('Note saved', {
            position: 'bottom-right',
            autoClose: 200,
            delay: 200,
          })
        }
      }
      catch (error) {
        console.error('Error autosaving:', error)
        toast.error('Failed to autosave', {
          position: 'bottom-right',
        })
      }
      finally {
        setLoading(false)
      }
    },
    [callId, setNotes, loading, lastSavedContent],
  )

  useEffect(() => {
    let timer
    if (html !== lastSavedContent) {
      timer = setTimeout(() => {
        if (html !== '') {
          autoSave(html)
        }
      }, 1000)
    }
    return () => clearTimeout(timer)
  }, [html, lastSavedContent, autoSave])

  const handleChange = (event) => {
    setHtml(event.target.value)
  }

  return (
    <div className="flex flex-col gap-2.5">
      <div className="flex justify-between items-center">
        <Button
          loading={loading}
          variant="soft"
          color="iris"
          disabled={loading}
          onClick={() => autoSave(html)}
          style={{ cursor: 'pointer' }}
        >
          {loading
            ? (
                <>
                  <Spinner />
                </>
              )
            : (
                <>
                  <CheckCheck size={16} />
                  <p>Save</p>
                </>
              )}
        </Button>
      </div>

      <Editor
        key={`notes-${callId}`}
        style={{ height: '600px', padding: '12px' }}
        value={html}
        onChange={handleChange}
      />
    </div>
  )
}
