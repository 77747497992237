import axios from 'axios'
import config from 'config'
import { getOrgId } from 'utils/funcs/browser/getOrgId'
import { getAuthToken } from '../browser/getAuthToken'

export async function getInbound(encrypted_key) {
  const orgId = getOrgId()
  const headers = {
    authorization: getAuthToken(),
    encrypted_key,
    ...(orgId && { 'x-bland-org-id': orgId }),
  }
  try {
    const { data } = await axios.get(`${config.API_URL}/v1/inbound`, {
      headers,
    })
    return data
  }
  catch (error) {
    console.log('get inbound', error)
    return error.message
  }
}
