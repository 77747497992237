import { Button, Chip, Tooltip } from '@heroui/react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import config from 'config'
import { BookOpen, CalendarDays, ChevronDown, Clock, FileText, Phone, Smartphone, Trash2, Users } from 'lucide-react'
import { useState } from 'react'
import { $fetch } from 'utils/fetch'
import CopyCallID from '../../Reusables/CopyCallID'
// Mock data remains the same

export default function MemoryDetailsView({ memoryId }) {
  const [expandedUsers, setExpandedUsers] = useState({})
  const [confirmDelete, setConfirmDelete] = useState(null)
  const queryClient = useQueryClient()

  const { data, isLoading, error } = useQuery({
    queryKey: ['memoryDetails', memoryId],
    queryFn: async () => {
      const response = await $fetch(`/v1/memory/${memoryId}`)
      return response.data
    },
    enabled: !!memoryId,
  })

  // Mutation for deleting a call
  const deleteCallMutation = useMutation({
    mutationFn: async ({ callId }) => {
      return await $fetch(`/v1/memory/${memoryId}/call/${callId}/remove`, {
        method: 'POST',
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['memoryDetails', memoryId])
    },
  })

  // Mutation for deleting a user
  const deleteUserMutation = useMutation({
    mutationFn: async ({ phoneNumber }) => {
      return await $fetch(`/v1/memory/${memoryId}/user/remove`, {
        method: 'POST',
        body: {
          phone_number: phoneNumber,
        },
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['memoryDetails', memoryId])
      setConfirmDelete(null)
    },
  })

  // Mutation for deleting entire memory
  const deleteMemoryMutation = useMutation({
    mutationFn: async () => {
      return await $fetch(`/v1/memory/${memoryId}/delete`, {
        method: 'POST',
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['memoryDetails'])
      setConfirmDelete(null)
      // Potentially redirect or show success message
    },
  })

  const toggleUserExpansion = (phoneNumber) => {
    setExpandedUsers(prev => ({
      ...prev,
      [phoneNumber]: !prev[phoneNumber],
    }))
  }

  const formatDate = (dateString) => {
    const options = {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    }
    // Get the date portion
    const date = new Date(dateString).toLocaleDateString(undefined, options)

    // Format time separately to have more control
    const timeOptions = {
      hour: '2-digit',
      minute: '2-digit',
    }
    const time = new Date(dateString).toLocaleTimeString(undefined, timeOptions)

    return { date, time }
  }

  const handleDeleteCall = (callId) => {
    deleteCallMutation.mutate({ callId })
  }

  const handleDeleteUser = (phoneNumber) => {
    deleteUserMutation.mutate({ phoneNumber })
  }

  const handleDeleteMemory = () => {
    deleteMemoryMutation.mutate()
  }

  const viewTranscript = (callId) => {
    window.open(`${config.API_URL}/dashboard/call-logs/${callId}`, '_blank')
  }

  // Loading state
  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-48">
        <div className="animate-pulse flex flex-col items-center">
          <div className="h-10 w-10 bg-indigo-100 dark:bg-indigo-900/30 rounded-full mb-3"></div>
          <div className="h-3 w-28 bg-indigo-100 dark:bg-indigo-900/30 rounded mb-2"></div>
          <div className="h-2 w-20 bg-indigo-50 dark:bg-indigo-900/20 rounded"></div>
        </div>
      </div>
    )
  }

  // Error state
  if (error) {
    return (
      <div className="p-5 border border-red-200 dark:border-red-900/50 bg-red-50 dark:bg-red-900/20 rounded-lg text-center">
        <p className="text-red-600 dark:text-red-400">Error loading memory details</p>
        <p className="text-xs text-red-500 dark:text-red-300 mt-1">{error.message}</p>
      </div>
    )
  }

  // No data state
  if (!data || !data.users || data.users.length === 0) {
    return (
      <div className="p-5 border border-indigo-200 dark:border-indigo-900/50 bg-indigo-50 dark:bg-indigo-900/20 rounded-lg text-center">
        <p className="text-indigo-600 dark:text-indigo-400">No user data available for this memory</p>
      </div>
    )
  }

  const users = data.users

  const conversationsCount = users.reduce((sum, user) => sum + user.calls.length, 0)

  return (
    <div className="space-y-4">
      <div className="flex items-center justify-between mb-4 p-3 bg-white dark:bg-zinc-950 rounded-lg border shadow-sm">
        <div className="flex items-center">
          <div className="h-8 w-8 rounded-full bg-gradient-to-br from-indigo-500 to-purple-600 flex items-center justify-center text-white mr-3">
            <Users className="h-4 w-4" />
          </div>
          <div>
            <h3 className="text-base font-medium text-indigo-700 dark:text-indigo-300">Memory Details</h3>
            <p className="text-xs text-muted-foreground">
              <span className="inline-flex items-center">
                <Phone className="h-3 w-3 mr-1" />
                {users.length}
                {' '}
                user
                {users.length > 1 ? 's' : ''}
              </span>
              <span className="mx-1.5">•</span>
              <span className="inline-flex items-center">
                <FileText className="h-3 w-3 mr-1" />
                {conversationsCount}
                {' '}
                conversation
                {conversationsCount > 1 ? 's' : ''}
              </span>
            </p>
          </div>
        </div>

        {/* Delete entire memory button */}
        {confirmDelete === 'memory'
          ? (
              <div className="flex gap-2">
                <Button size="sm" variant="outline" color="gray" onPress={() => setConfirmDelete(null)}>
                  Cancel
                </Button>
                <Button size="sm" variant="solid" color="red" onPress={handleDeleteMemory}>
                  Confirm
                </Button>
              </div>
            )
          : (
              <Tooltip content="Delete Memory">
                <Button
                  size="icon"
                  variant="outline"
                  color="red"
                  onPress={() => setConfirmDelete('memory')}
                >
                  <Trash2 className="h-4 w-4" />
                </Button>
              </Tooltip>
            )}
      </div>

      <div className="space-y-3">
        {users.map(user => (
          <div
            key={user.phone_number}
            className="bg-white dark:bg-zinc-950 rounded-lg border shadow-sm overflow-hidden"
          >
            {/* User header - always visible */}
            <div
              className="flex flex-col p-3 cursor-pointer hover:bg-indigo-50 dark:hover:bg-indigo-900/20 transition-colors"
              onClick={() => toggleUserExpansion(user.phone_number)}
            >
              <div className="flex items-center">
                <div className="h-8 w-8 rounded-full bg-indigo-100 dark:bg-indigo-900/50 flex items-center justify-center mr-3">
                  <Smartphone className="h-4 w-4 text-indigo-600 dark:text-indigo-400" />
                </div>
                <div className="flex-1 min-w-0">
                  <div className="font-medium text-sm">{user.phone_number}</div>
                </div>
                <div className="flex items-center gap-2 ml-3">
                  <Chip size="sm" variant="flat" color="secondary" className="bg-indigo-100 text-indigo-700 dark:bg-indigo-900/50 dark:text-indigo-300 text-xs">
                    {user.calls.length}
                  </Chip>
                  <ChevronDown
                    className={`h-4 w-4 text-indigo-400 transition-transform duration-200 ${
                      expandedUsers[user.phone_number] ? 'rotate-180' : ''
                    }`}
                  />
                </div>
              </div>

              {/* Truncated user summary - always visible when collapsed */}
              {user.summary && !expandedUsers[user.phone_number] && (
                <div className="mt-2 text-xs text-gray-600 dark:text-gray-400 line-clamp-5 w-full">
                  {user.summary}
                </div>
              )}
            </div>

            {/* Expanded content */}
            {expandedUsers[user.phone_number] && (
              <div className="border-t px-3 py-3 bg-indigo-50/50 dark:bg-indigo-900/10">
                {/* User summary */}
                <div className="mb-4 p-3 bg-white dark:bg-zinc-950 rounded-md border border-indigo-100 dark:border-indigo-800/50">
                  <h4 className="text-xs font-medium mb-1 text-indigo-600 dark:text-indigo-400">User Summary</h4>
                  <p className="text-sm text-gray-700 dark:text-gray-300">{user.summary}</p>
                </div>

                <div className="flex justify-between items-center mb-3">
                  <h4 className="text-xs font-medium text-indigo-600 dark:text-indigo-400 flex items-center">
                    <Clock className="h-3 w-3 mr-1" />
                    Interaction History
                  </h4>

                  {/* Delete user button with confirmation */}
                  {confirmDelete === user.phone_number
                    ? (
                        <div className="flex gap-2">
                          <Button size="xs" variant="outline" color="gray" onClick={() => setConfirmDelete(null)}>
                            Cancel
                          </Button>
                          <Button
                            size="xs"
                            variant="solid"
                            color="red"
                            onClick={() => handleDeleteUser(user.phone_number)}
                          >
                            Confirm
                          </Button>
                        </div>
                      )
                    : (
                        <Tooltip content="Delete User">
                          <Button
                            size="icon"
                            variant="outline"
                            color="red"
                            className="h-6 w-6"
                            onClick={(e) => {
                              e.stopPropagation()
                              setConfirmDelete(user.phone_number)
                            }}
                          >
                            <Trash2 className="h-3 w-3" />
                          </Button>
                        </Tooltip>
                      )}
                </div>

                <div className="space-y-3">
                  {user.calls.map((call) => {
                    const { date, time } = formatDate(call.created_at)
                    return (
                      <div
                        key={call.call_id}
                        className="p-3 bg-white dark:bg-zinc-950 rounded-md border border-indigo-100 dark:border-indigo-800/50"
                      >
                        <div className="flex justify-between items-center mb-2">
                          <div className="flex-1">
                            <CopyCallID id={call.call_id} maxLength={20} />
                          </div>

                          <div className="flex gap-3">
                            <Tooltip content="View Call Log">
                              <Button
                                size="icon"
                                variant="ghost"
                                color="indigo"
                                className="h-7 w-7"
                                onPress={() => {
                                  viewTranscript(call.call_id)
                                }}
                              >
                                <BookOpen className="h-3.5 w-3.5" />
                              </Button>
                            </Tooltip>

                            <Tooltip content="Delete Call">
                              <Button
                                size="icon"
                                variant="ghost"
                                color="red"
                                className="h-7 w-7"
                                onClick={(e) => {
                                  e.stopPropagation()
                                  handleDeleteCall(call.call_id)
                                }}
                              >
                                <Trash2 className="h-3.5 w-3.5" />
                              </Button>
                            </Tooltip>
                          </div>
                        </div>

                        <div className="text-xs text-indigo-500 dark:text-indigo-400 flex items-center">
                          <CalendarDays className="h-3 w-3 mr-1" />
                          <span>{date}</span>
                          <span className="mx-1.5">•</span>
                          <span>{time}</span>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}
