import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  Line,
  LineChart,
  Pie,
  PieChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

function CustomLabel(props) {
  return (
    <foreignObject
      class="label-wrapper"
      x={props.viewBox.width - props.viewBox.x * 4} // Adjust x position to move to the right
      y={props.viewBox.y - 8} // Adjust y position to move to the top
      width={27}
      height={16}
    >
      <div
        xmlns="http://www.w3.org/1999/xhtml"
        style={{
          background: '#E5E7EB',
          borderRadius: '2px',
          color: '#111',
          fontSize: '10px',
          width: '27px',
          height: '16px',
          textAlign: 'center',
        }}
      >
        AVG
      </div>
    </foreignObject>
  )
}
function BlandChart({
  data = [
    { name: 'MON', calls: 5000, overage: 240, static: 400 },
    { name: 'TUE', calls: 3000, overage: 138, static: 400 },
    { name: 'WED', calls: 2000, overage: 980, static: 400 },
    { name: 'TH', calls: 3000, overage: 138, static: 400 },
    { name: 'FRI', calls: 2000, overage: 980, static: 400 },
  ],
  totalCalls,
  color = 'primary',
  percentageChange,
  type = 'bar',
  blur = false,
  title = 'Total Calls',
}) {
  const primary = color === 'primary' ? '#D8C4B6' : '#3E5879'
  const accent = color === 'primary' ? '#F5EFE7' : '#A4B8CC'
  const COLORS = ['#3B6EAE', '#D8C4B6', '#3E5879']

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        padding: '16px',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        border: '1px solid var(--Light-Borders-border-base, #E5E7EB)',
        gap: '0px',
      }}
    >
      <style>
        {`
          .recharts-wrapper {
            margin-left: 5%;
          }
        `}
      </style>
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
        }}
      >
        <div
          style={{
            display: 'flex',
            height: '57px',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '5.639px',
          }}
        >
          <div
            style={{
              color: '#9CA3AF',
              fontFamily: 'Geist Mono',
              fontSize: '10px',
              fontStyle: 'normal',
              fontWeight: 700,
              lineHeight: '20px' /* 200% */,
              letterSpacing: '0.8px',
              textTransform: 'uppercase',
            }}
          >
            {title}
          </div>
          <div
            style={{
              color: '#4B5563',
              fontFamily: '"Geist Mono"',
              fontSize: '24px',
              fontStyle: 'normal',
              fontWeight: 600,
              lineHeight: '30.075px' /* 125.313% */,
              letterSpacing: '0.48px',
            }}
          >
            {totalCalls?.toLocaleString()}
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            width: '66px',
            alignItems: 'center',
            gap: '3.759px',
            flexShrink: 0,
          }}
        >
          <div>
            {percentageChange !== undefined && (
              <div
                style={{
                  color: '#3E5879s',
                  fontFamily: '"Geist Mono"',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  lineHeight: '15.038px' /* 107.411% */,
                }}
              >
                {percentageChange}
                %
              </div>
            )}
          </div>
        </div>
      </div>
      {type === 'bar' && (
        <ResponsiveContainer
          width="100%"
          height={200}
          style={{
            filter: blur ? 'blur(5px)' : 'none',
          }}
        >
          <BarChart
            barGap={14}
            barSize={40}
            data={data}
            className="font-geist-mono text-[10px]"
          >
            <XAxis
              axisLine={{ stroke: '#9ca3af' }}
              dataKey="name"
              fill="#9ca3af"
              stroke="#9ca3af"
              tickLine={false}
            />
            <YAxis
              fill="#9ca3af"
              orientation="right"
              axisLine={false}
              stroke="#9ca3af"
              tickLine={false}
              tickFormatter={value =>
                value > 1000 ? `${value / 1000}k` : value}
            />
            <Tooltip
              content={({ active, payload, label }) => {
                if (active && payload && payload.length) {
                  return (
                    <div
                      style={{
                        backgroundColor: '#fff',
                        border: '1px solid #ccc',
                        padding: '10px',
                        borderRadius: '5px',
                      }}
                    >
                      <p style={{ margin: 0 }}>{label}</p>
                      {payload
                        .filter(entry => entry.name !== 'static')
                        .map((entry, index) => (
                          <p
                            key={`item-${index}`}
                            style={{ color: entry.color }}
                          >
                            {entry.name}
                            :
                            {entry.value}
                          </p>
                        ))}
                    </div>
                  )
                }
                return null
              }}
            />
            <Bar
              dataKey="static"
              fill="transparent"
              stackId="a"
              isAnimationActive={false}
            />

            <Bar
              dataKey="calls"
              fill={primary}
              stackId="a"
              style={{ paddingBottom: '10px' }}
            />
            <Bar
              dataKey="voicemail"
              fill={accent}
              stackId="a"
              style={{ paddingBottom: '10px' }}
            />
            <ReferenceLine
              strokeDasharray="5 5"
              strokeOpacity={1}
              alwaysShow
              y={
                data.reduce(
                  (acc, entry) => acc + entry.calls + (entry?.voicemail ?? 0),
                  0,
                ) / data.length
              }
              label={CustomLabel}
              stroke="#000"
            />
          </BarChart>
        </ResponsiveContainer>
      )}
      {type === 'pie' && (
        <ResponsiveContainer
          width="100%"
          height={200}
          className="font-geist-mono text-[10px]"
          style={{
            filter: blur ? 'blur(5px)' : 'none',
          }}
        >
          <PieChart>
            <Pie
              // activeIndex={this.state.activeIndex}
              // activeShape={renderActiveShape}
              data={data.slice(0, 3)}
              cx="50%"
              cy="50%"
              innerRadius={60}
              outerRadius={90}
              fill="#8884d8"
              dataKey="calls"
              // onMouseEnter={this.onPieEnter}
              labelLine={false}
              label={({
                cx,
                cy,
                midAngle,
                innerRadius,
                outerRadius,
                percent,
                index,
              }) => {
                const RADIAN = Math.PI / 180
                const radius = innerRadius + (outerRadius - innerRadius) * 0.5 // Adjust radius to keep text within slice
                const x = cx + radius * Math.cos(-midAngle * RADIAN)
                const y = cy + radius * Math.sin(-midAngle * RADIAN)

                return (
                  <text
                    x={x}
                    y={y}
                    fill="white"
                    textAnchor="middle" // Center text to ensure it stays within slice
                    dominantBaseline="central"
                    fontSize="13px"
                    fontWeight="bold"
                  >
                    {percent > 0 ? `${(percent * 100).toFixed(0)}%` : ''}
                  </text>
                )
              }}
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Legend
              verticalAlign="middle"
              align="right"
              layout="vertical"
              iconSize={10}
            />
            <Tooltip />
          </PieChart>
        </ResponsiveContainer>
      )}
      {type === 'line' && (
        <ResponsiveContainer
          width="100%"
          height={200}
          style={{
            filter: blur ? 'blur(5px)' : 'none',
            marginRight: '-30px', // Added negative left margin
          }}
        >
          <LineChart data={data} className="font-geist-mono text-[10px]">
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              axisLine={{ stroke: '#9ca3af' }}
              dataKey="name"
              fill="#9ca3af"
              stroke="#9ca3af"
              tickLine={false}
            />
            <YAxis
              fill="#9ca3af"
              orientation="right"
              axisLine={false}
              stroke="#9ca3af"
              tickLine={false}
              tickFormatter={value =>
                value > 1000 ? `${value / 1000}k` : value}
            />
            <Tooltip />
            {/* <Legend
              verticalAlign="middle"
              align="right"
              layout="vertical"
              iconSize={5}
              wrapperStyle={{ paddingLeft: "10px" }}
              iconType="circle"
            /> */}
            <Line
              type="monotone"
              dataKey="callPrice"
              stroke="#D8C4B6"
              dot={{ r: 3, fill: '#D8C4B6' }}
            />
            <Line
              type="monotone"
              dataKey="averagePrice"
              stroke="#3E5879"
              dot={{ r: 3, fill: '#3E5879' }}
            />
            <Line
              type="monotone"
              dataKey="rateOfChange"
              stroke="#3B6EAE"
              dot={{ r: 3, fill: '#3B6EAE' }}
            />
          </LineChart>
        </ResponsiveContainer>
      )}
    </div>
  )
}

export default BlandChart
