import { useState } from 'react'
import NodeModal from './node_modal'
import NodeIcon from './nodes/nodeIcon'

function NodePreviewCard({ node, onInsert, showStatusBadge = true }) {
  const [isModalOpen, setIsModalOpen] = useState(false)

  if (!node)
    return null

  // Transform node data to match the format expected by NodeModal
  const modalData = {
    id: node.id,
    data: {
      ...node.node_data?.data,
      name: node.name,
    },
    type: node.node_data?.type,
    public: node.public,
  }

  const getStatusBadge = () => {
    if (!showStatusBadge)
      return null

    if (node.public && node.approved === false) {
      return (
        <span className="text-[10px] px-1.5 py-px bg-yellow-50 text-yellow-700 rounded-full border border-yellow-200">
          Pending
        </span>
      )
    }
    else if (node.public) {
      return (
        <span className="text-[10px] px-1.5 py-px bg-green-50 text-green-700 rounded-full border border-green-200">
          Public
        </span>
      )
    }
    return null
  }

  return (
    <>
      <div className="rounded border-[0.8px] border-gray-300 shadow-md bg-white hover:shadow-lg transition-shadow">
        <div className="divide-y divide-gray-200">
          {/* Header section */}
          <div className="p-2.5 h-[42px] flex items-center">
            <div className="flex items-center justify-between w-full">
              <div className="flex items-center space-x-2">
                <span className="scale-90">
                  <NodeIcon type={node.node_data?.type} />
                </span>
                <h2 className="text-[12px] font-medium leading-3 line-clamp-1">
                  {node.name || 'Untitled Node'}
                </h2>
              </div>
              <div className="flex items-center gap-1.5">
                {getStatusBadge()}
                <div className="text-gray-400 text-[10px] leading-2.5 font-medium">
                  {node.node_data?.type}
                </div>
              </div>
            </div>
          </div>

          {/* Content section */}
          <div className="p-2.5 h-[90px] flex flex-col justify-between">
            <div>
              <p className="line-clamp-2 leading-[16px] font-normal text-[11px] min-h-[32px]">
                {node.description || 'No description provided'}
              </p>

              {/* Tags container with fixed height */}
              {/* <div className="flex flex-wrap gap-1.5 mt-2 h-[28px] overflow-hidden">
                {node.tags?.map((tag) => (
                  <span
                    key={tag}
                    className="text-[9px] px-[6px] py-[4px] bg-gray-100 text-gray-600 rounded border border-gray-200"
                  >
                    {tag}
                  </span>
                ))}
              </div> */}
            </div>

            {/* Buttons at bottom */}
            <div className="flex gap-1.5 mt-1.5">
              <button
                type="button"
                onClick={(e) => {
                  e.stopPropagation()
                  setIsModalOpen(true)
                }}
                className="text-[11px] px-2 py-0.5 text-gray-600 hover:bg-gray-100 rounded border border-gray-200"
              >
                Preview
              </button>

              {onInsert && (
                <button
                  type="button"
                  onClick={(e) => {
                    e.stopPropagation()
                    onInsert(modalData?.type, modalData?.data)
                  }}
                  className="text-[11px] px-2 py-0.5 text-indigo-600 hover:bg-indigo-50 rounded border border-indigo-200"
                >
                  Add to Pathway
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Node Modal */}
      <NodeModal
        id={modalData.id}
        data={modalData.data}
        type={modalData.type}
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        context="preview"
      />
    </>
  )
}

export default NodePreviewCard
