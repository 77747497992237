function replaceNestedPlaceholders(
  originalStringOrObject,
  originalVariableDict,
  max_depth = 4,
  toString = true,
) {
  let stringOrObject = structuredClone(originalStringOrObject)
  const variableDict = structuredClone(originalVariableDict)

  if (max_depth === 0) {
    return stringOrObject
  }
  if (typeof stringOrObject === 'string') {
    try {
      stringOrObject = JSON.parse(stringOrObject)
    }
    catch {
      // do nothing
    }

    const result = replacePlaceholders(stringOrObject, variableDict, toString)
    if (typeof result === 'string') {
      try {
        return JSON.parse(result)
      }
      catch {
        return result
      }
    }
    return result
  }
  else if (typeof stringOrObject === 'object') {
    for (const key in stringOrObject) {
      stringOrObject[key] = replaceNestedPlaceholders(
        stringOrObject[key],
        variableDict,
        max_depth - 1,
        toString,
      )
    }
  }
  else if (Array.isArray(stringOrObject)) {
    for (let i = 0; i < stringOrObject.length; i++) {
      stringOrObject[i] = replaceNestedPlaceholders(
        stringOrObject[i],
        variableDict,
        max_depth - 1,
        toString,
      )
    }
  }

  return stringOrObject
}

function replacePlaceholders(
  originalStringOrObject,
  originalVariableDict,
  toString = true,
  startDelimiter = '{{',
  endDelimiter = '}}',
) {
  const stringOrObject = structuredClone(originalStringOrObject)
  const variableDict = structuredClone(originalVariableDict)

  // replace all instances of {{ variable }} with the value of variable in variableDict
  // if variableDict is null, then just return the text

  if (!variableDict || typeof variableDict !== 'object') {
    return stringOrObject
  }

  // if variableDict has no keys, then just return the text
  if (Object.keys(variableDict).length === 0) {
    return stringOrObject
  }

  let newStringOrObject = structuredClone(stringOrObject)
  // Create a lower-cased version of variableDict for case-insensitive matching
  const lowerCaseDict = Object.keys(variableDict).reduce((acc, key) => {
    const processKey = (keyPath, value, depth) => {
      if (depth > 4)
        return // Stop if the depth exceeds 4

      if (typeof value === 'string' || typeof value === 'number') {
        acc[keyPath.toLowerCase()] = value
      }
      else if (Array.isArray(value)) {
        acc[keyPath.toLowerCase()] = JSON.stringify(value)
      }
      else if (typeof value === 'object' && value !== null) {
        acc[keyPath.toLowerCase()] = JSON.stringify(value)
        Object.keys(value).forEach((subKey) => {
          processKey(`${keyPath}.${subKey}`, value[subKey], depth + 1)
        })
      }
    }

    processKey(key, variableDict[key], 1) // Start with depth 1
    return acc
  }, {})

  // Use a regex to match all instances of the placeholders
  const pattern = new RegExp(
    `${startDelimiter}\\s*(.*?)\\s*${endDelimiter}`,
    'gi',
  )

  if (typeof newStringOrObject === 'string') {
    newStringOrObject = newStringOrObject?.replace(pattern, (match, key) => {
      key = key.toLowerCase()
      // Replace with the original value from lowerCaseDict if it exists
      return key in lowerCaseDict ? lowerCaseDict[key] : match
    })
  }

  // as an additional cleanup step, directly replace
  // `${startDelimiter}${key}${endDelimiter}` with the value of key in variableDict
  // this is to handle cases like {{{{{{{var}}}}}}} and such that would match against the *outside* delimiters
  for (const key in variableDict) {
    const exactStrings = [
      `${startDelimiter}${key?.toLowerCase()}${endDelimiter}`,
      `${startDelimiter} ${key?.toLowerCase()} ${endDelimiter}`,
      `${startDelimiter}  ${key?.toLowerCase()}  ${endDelimiter}`,
    ]

    for (const exactString of exactStrings) {
      if (
        typeof newStringOrObject === 'string'
        && newStringOrObject.includes(exactString)
      ) {
        newStringOrObject = newStringOrObject.replace(
          exactString,
          variableDict[key],
        )
      }
    }
  }

  // console.log("2 ", stringOrObject);

  return newStringOrObject
}

export { replaceNestedPlaceholders,  }
