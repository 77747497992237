import { Form, ScrollShadow } from '@heroui/react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import ActionBar from 'components/core/ActionBar'
import { PageTitle } from 'components/core/PageTitle'
import { PageWrapper } from 'components/core/PageWrapper'
import AccordionForm from 'components/Hero/AccordionForm'
import config from 'config'
import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { $fetch } from 'utils/fetch'
import { parseForJson } from 'utils/funcs/send-call/parseForJson'
import SendCallCTA from '../SendCall/SendCallCTA'
import BatchForm from './BatchForm'

export default function CreateBatch() {
  const queryClient = useQueryClient()
  const methods = useForm({
    defaultValues: {
      voice: 'June',
      wait_for_greeting: false,
      record: true,
      answered_by_enabled: true,
      noise_cancellation: false,
      interruption_threshold: 100,
      block_interruptions: false,
      max_duration: 12,
      model: 'base',
      memory_id: null,
      language: 'en',
      background_track: 'none',
      endpoint: config.API_URL,
      voicemail_action: 'hangup',
      prompt_modal: false,
      summary_prompt: null,
      file_id: null,
      column_mapping: {},
      // Hack for now
      transfer_phone_number: '+1',
    },
  })

  const { formState: { dirtyFields } } = methods

  const navigator = useNavigate()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const { data: rateLimits } = useQuery({
    queryKey: ['rateLimits'],
    queryFn: async () => {
      const { data } = await $fetch(`/user/rateLimits`)
      return data
    },
    enabled: false,
  })

  const onSubmit = async (formData) => {
    setIsSubmitting(true)
    try {
      const { encrypted_key, file_id, column_mapping, recipients_count, description, phone_number, ...requestData } = formData

      // Validate call data exists
      if (!file_id) {
        return toast.error(
          'Please upload a file before starting your batch.',
        )
      }

      const remainingHourlyLimit = rateLimits?.template?.hourly_limit - rateLimits?.hourlyCount
      const remainingDailyLimit = rateLimits?.template?.daily_limit - rateLimits?.dailyCount

      if (recipients_count > remainingHourlyLimit) {
        return toast.error(`This batch call will exceed your hourly limit. Remove recipients or try again later.`)
      }

      if (recipients_count > remainingDailyLimit) {
        return toast.error(`This batch call will exceed your daily limit. Remove recipients or try again later.`)
      }

      const global = parseForJson(requestData, dirtyFields)
      const cleanedMappings = parseForJson(column_mapping)

      await $fetch('/v2/batches/create', {
        method: 'POST',
        body: {
          global,
          file_id,
          column_mapping: cleanedMappings,
          description,
        },
        headers: {
          ...(encrypted_key ? { encrypted_key } : {}),
        },
      })

      queryClient.invalidateQueries({ queryKey: ['batches'] })
      toast.success('Batch call has been started.')
      return navigator('/dashboard/batches')
    }
    catch (err) {
      console.error(err)
      const errorMessage = err.data?.errors?.[0]?.message
      toast.error(
        errorMessage || 'Error starting your batch call.',
      )
    }
    finally {
      setIsSubmitting(false)
    }
  }

  return (
    <PageWrapper padding="54px 0px 0px 54px">
      <ActionBar top spaceBetween shiftRight>
        <PageTitle>Create Batch Call</PageTitle>
      </ActionBar>
      <FormProvider {...methods}>
        <Form onSubmit={methods.handleSubmit(onSubmit)} className="gap-0">
          {/* UNCOMMENT BELOW TO DEBUG */}
          {/* <pre
            style={{
              position: 'fixed',
              top: 20,
              right: 20,
              zIndex: 1000,
              background: '#f5f5f5',
              padding: 20,
              borderRadius: 4,
              maxHeight: 400,
              overflow: 'auto',
            }}
          >
            {JSON.stringify(methods.watch(), null, 2)}
          </pre> */}

          <ScrollShadow className="h-full w-full mb-[54px]" hideScrollBar>
            <AccordionForm formDefinition={BatchForm} />
          </ScrollShadow>

          <SendCallCTA
            type="batch"
            isLoading={isSubmitting}
          />

        </Form>
      </FormProvider>
    </PageWrapper>
  )
}
