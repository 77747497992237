import config from 'config'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { getAuthToken } from 'utils/funcs/browser/getAuthToken'
import { getOrgId } from 'utils/funcs/browser/getOrgId'

// interface TranscriptChunk {
//   transcript_id: string;
//   call_id: string;
//   text: string;
//   role: "assistant" | "user" | "system";
//   timestamp?: number;
// }

export function useTranscriptStream(callId) {
  const [transcripts, setTranscripts] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)
  const [isCallEnded, setIsCallEnded] = useState(false)

  useEffect(() => {
    // Reset transcripts whenever callId changes
    setTranscripts([])
    setIsLoading(true)
    setError(null)
    setIsCallEnded(false)

    if (!callId) {
      setIsLoading(false)
      return
    }

    const orgId = getOrgId()
    const authToken = getAuthToken()
    const abortController = new AbortController()

    const processStreamData = (data) => {
      const parsedData = typeof data === 'string' ? JSON.parse(data) : data

      const processMessages = (messages) => {
        setTranscripts((prev) => {
          return messages.reduce((acc, message) => {
            switch (message.type) {
              case 'new':
                return [...acc, message]
              case 'update':
                return acc.map(t =>
                  t.transcript_id === message.transcript_id
                    ? { ...t, ...message }
                    : t,
                )
              case 'delete':
                return acc.filter(
                  t => t.transcript_id !== message.transcript_id,
                )
              default:
                return acc
            }
          }, prev)
        })
      }

      switch (parsedData.type) {
        case 'initial':
          processMessages(parsedData.messages || [])
          break
        case 'new':
        case 'update':
        case 'delete':
          processMessages([parsedData])
          break
        case 'end':
          setIsCallEnded(true)
          break
        case 'error':
          setError(parsedData.message)
          toast.error(parsedData.message)
          break
      }

      setIsLoading(false)
    }

    const connectToStream = async () => {
      try {
        // i cannot use $fetch because it does not support SSE
        const response = await fetch(
          `${config.API_URL}/v1/calls/${callId}/transcript/stream`,
          {
            signal: abortController.signal,
            headers: {
              Accept: 'text/event-stream',
              authorization: authToken,
              ...(orgId && { 'x-bland-org-id': orgId }),
            },
          },
        )

        if (!response.ok)
          throw new Error('Transcript stream connection failed')

        const reader = response.body.getReader()
        const decoder = new TextDecoder()
        let buffer = ''

        while (true) {
          const { value, done } = await reader.read()
          if (done)
            break

          buffer += decoder.decode(value, { stream: true })
          const lines = buffer.split('\n')
          buffer = lines.pop() || ''

          for (const line of lines) {
            if (line.startsWith('data: ')) {
              const data = line.slice(6)
              processStreamData(data)
            }
          }
        }
      }
      catch (error) {
        if (!abortController.signal.aborted) {
          const errorMessage
            = 'Lost connection to transcript updates. Reconnecting...'
          console.error(errorMessage, error)
          setError(errorMessage)
          toast.error(errorMessage)
          setTimeout(connectToStream, 5000)
        }
      }
    }

    setIsLoading(true)
    connectToStream()

    return () => {
      abortController.abort()
    }
  }, [callId])

  return { transcripts, isLoading, error, isCallEnded }
}
