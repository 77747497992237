import { useCallback, useEffect, useState } from 'react'

import { $fetch } from 'utils/fetch'

function usePhoneNumbers() {
  const [inboundNumbers, setInboundNumbers] = useState([])
  const [twilioNumbers, setTwilioNumbers] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  const fetchInboundNumbers = useCallback(async () => {
    try {
      const response = await $fetch('/inbound/get_inbound')
      return response || { inboundNumbers: [] }
    }
    catch (err) {
      console.error('Error fetching inbound numbers:', err)
      return []
    }
  }, [])

  const fetchTwilioNumbers = useCallback(async () => {
    try {
      const response = await $fetch('/byot/get')
      return response.data || [] // Ensure we always return an array
    }
    catch (err) {
      console.error('Error fetching Twilio numbers:', err)
      return [] // Return an empty array on error
    }
  }, [])

  const fetchAllNumbers = useCallback(async () => {
    setLoading(true)
    setError(null)
    try {
      const [inboundData, twilioData] = await Promise.all([
        fetchInboundNumbers(),
        fetchTwilioNumbers(),
      ])

      setInboundNumbers(inboundData.inboundNumbers || [])
      setTwilioNumbers(twilioData || [])
    }
    catch (err) {
      setError(err.message || 'An error occurred while fetching phone numbers')
    }
    finally {
      setLoading(false)
    }
  }, [fetchInboundNumbers, fetchTwilioNumbers])

  useEffect(() => {
    fetchAllNumbers()
  }, [fetchAllNumbers])

  return {
    inboundNumbers,
    twilioNumbers,
    loading,
    setLoading,
    error,
    refreshNumbers: fetchAllNumbers,
  }
}

export default usePhoneNumbers
