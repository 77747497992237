import React, { useState, useEffect } from 'react';
import { ChevronLeft, ChevronRight, X, Check, MessageSquare, AlertTriangle, GitMerge } from 'lucide-react';
import { toast } from 'react-toastify';
import { $fetch } from 'utils/fetch';

import PathwayComparisonEngine from './MergeConflicts/PathwayComparisonEngine';
import ConflictResolver from './MergeConflicts/ConflictResolver';
import ConflictNavigation, { ProgressBar } from './MergeConflicts/ConflictNavigation';
import NodeConflictView from './MergeConflicts/NodeConflictView';
import EdgeConflictView from './MergeConflicts/EdgeConflictView';
import GlobalPromptConflictView from './MergeConflicts/GlobalPromptConflictView';
import MergeConfirmation from './MergeConflicts/MergeConfirmation';

const MergeConflictsModal = ({ onClose, mainPathwayData, smsPathwayData }) => {
  // State for conflicts and UI
  const [conflicts, setConflicts] = useState([]);
  const [allConflicts, setAllConflicts] = useState([]); // Store all conflicts including matches
  const [isLoading, setIsLoading] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedVersion, setSelectedVersion] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);
  
  // State for pathways data
  const [productionPathway, setProductionPathway] = useState(null);
  const [smsPathway, setSmsPathway] = useState(null);
  
  // State for conflict resolutions
  const [resolutions, setResolutions] = useState({});
  
  // State for field-level resolutions
  const [fieldResolutions, setFieldResolutions] = useState({});
  
  // State for expanded details sections
  const [expandedDetails, setExpandedDetails] = useState({
    prodPrompt: false,
    prodCondition: false,
    smsPrompt: false,
    smsCondition: false
  });
  
  // State for editable content
  const [editableContent, setEditableContent] = useState({
    prodPrompt: '',
    prodCondition: '',
    smsPrompt: '',
    smsCondition: '',
    type: '',
    data: ''
  });
  
  // Compare pathways when component mounts
  useEffect(() => {
    const comparePathways = () => {
      setIsLoading(true);
      try {
        // Store pathway data
        setProductionPathway(mainPathwayData);
        setSmsPathway(smsPathwayData);
        
        // Use PathwayComparisonEngine to identify conflicts
        const identifiedConflicts = PathwayComparisonEngine.comparePathways(mainPathwayData, smsPathwayData);
        console.log('identifiedConflicts', identifiedConflicts);
        
        // Store all conflicts including matches for reference
        setAllConflicts(identifiedConflicts);
        
        // Filter out conflicts with status 'matched' for the main conflicts array
        const actualConflicts = identifiedConflicts.filter(conflict => conflict.status !== 'matched');
        setConflicts(actualConflicts);
        
        // Initialize resolutions with defaults (including matched items for completeness)
        const initialResolutions = {};
        identifiedConflicts.forEach(conflict => {
          // Default to production for all conflict types
          initialResolutions[conflict.id] = 'production';
          
          // For matched items, set as 'production' since they're the same
          if (conflict.status === 'matched') {
            initialResolutions[conflict.id] = 'production';
          }
        });
        setResolutions(initialResolutions);
        
      } catch (error) {
        console.error('Error comparing pathways:', error);
        toast.error('Failed to compare pathway data');
      } finally {
        setIsLoading(false);
      }
    };
    
    if (mainPathwayData && smsPathwayData) {
      comparePathways();
    }
  }, [mainPathwayData, smsPathwayData]);
  
  // Initialize editable content when current conflict changes
  useEffect(() => {
    const currentConflict = conflicts[currentIndex];
    if (currentConflict) {
      const prodVersion = currentConflict.productionVersion || {};
      const smsVersion = currentConflict.smsVersion || {};
      
      if (currentConflict.type === 'globalPrompt') {
        // For global prompt conflicts
        setEditableContent({
          globalPrompt: prodVersion.globalPrompt || smsVersion.globalPrompt || ''
        });
      } else {
        // For node or edge conflicts
        setEditableContent({
          name: prodVersion.name || smsVersion.name || '',
          text: prodVersion.text || smsVersion.text || '',
          prompt: prodVersion.prompt || smsVersion.prompt || '',
          type: prodVersion.type || smsVersion.type || '',
          data: JSON.stringify(prodVersion.data || smsVersion.data || {}, null, 2)
        });
      }
      
      setSelectedVersion(''); // Reset selection for new conflict
    }
  }, [currentIndex, conflicts]);
  
  // Navigation functions
  const goToNext = () => {
    if (currentIndex < conflicts.length - 1) {
      setCurrentIndex(currentIndex + 1);
      setExpandedDetails({
        prodPrompt: false,
        prodCondition: false,
        smsPrompt: false,
        smsCondition: false
      });
    } else {
      // If we're at the last conflict, show the confirmation screen
      setShowConfirmation(true);
    }
  };

  const goToPrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
      setExpandedDetails({
        prodPrompt: false,
        prodCondition: false,
        smsPrompt: false,
        smsCondition: false
      });
    }
  };
  
  // Handle field-level resolution
  const handleFieldResolution = (conflictId, fieldPath, version) => {
    // Create a key that includes both the conflict ID and the field path
    const fieldResolutionKey = `${conflictId}_field_${fieldPath}`;
    const updatedFieldResolutions = { ...fieldResolutions, [fieldResolutionKey]: version };
    setFieldResolutions(updatedFieldResolutions);
    
    // Update the main resolutions object to indicate field-by-field resolution
    const updatedResolutions = { ...resolutions };
    // Only change the main resolution to field-by-field if it wasn't already set
    if (!updatedResolutions[conflictId] || updatedResolutions[conflictId] !== 'field-by-field') {
      updatedResolutions[conflictId] = 'field-by-field';
      setResolutions(updatedResolutions);
    }
    
    // Mark that a selection has been made
    setSelectedVersion('field-by-field');
  };

  // Handle applying resolution and moving to next conflict
  const handleApplyAndContinue = () => {
    const currentConflict = conflicts[currentIndex];
    if (currentConflict) {
      // Store the resolution
      const updatedResolutions = { ...resolutions };
      
      // Only update if a selection was made or it's not a conflict
      if (selectedVersion || currentConflict.status !== 'conflict') {
        if (selectedVersion) {
          updatedResolutions[currentConflict.id] = selectedVersion;
        } else {
          // Default resolution based on status
          updatedResolutions[currentConflict.id] = currentConflict.status === 'production-only' ? 
            'production' : currentConflict.status === 'sms-only' ? 
            'sms' : 'production';
        }
        
        // If custom, store the custom data
        if (selectedVersion === 'custom') {
          try {
            if (currentConflict.type === 'globalPrompt') {
              // For global prompt conflicts, store the custom global prompt
              updatedResolutions[`${currentConflict.id}_custom`] = {
                globalPrompt: editableContent.globalPrompt
              };
            } else {
              // For node or edge conflicts
              // Parse JSON data if provided
              const customData = {
                type: editableContent.type
              };
              
              // Only add data field if valid JSON
              if (editableContent.data) {
                try {
                  customData.data = JSON.parse(editableContent.data);
                } catch (err) {
                  toast.error('Invalid JSON in data field. Please fix before continuing.');
                  return; // Don't proceed if JSON is invalid
                }
              }
              
              updatedResolutions[`${currentConflict.id}_custom`] = customData;
            }
          } catch (error) {
            console.error('Error processing custom data:', error);
            toast.error('Error processing custom data');
            return;
          }
        }
        
        // If field-by-field, merge the field resolutions
        if (selectedVersion === 'field-by-field') {
          // Field resolutions are already stored separately
          // in the fieldResolutions state
          Object.keys(fieldResolutions)
            .filter(key => key.startsWith(`${currentConflict.id}_field_`))
            .forEach(key => {
              updatedResolutions[key] = fieldResolutions[key];
            });
        }
        
        setResolutions(updatedResolutions);
        goToNext();
      } else {
        toast.warning('Please select a version before continuing');
      }
    } else {
      goToNext();
    }
  };
  
  // Handle selection of version for conflict resolution
  const handleSelectVersion = (version) => {
    setSelectedVersion(version);
  };
  
  // Handle final confirmation and save
  const handleSaveAndClose = async () => {
    try {
      setIsLoading(true);
      
      // For the final merge, we need to include resolutions for ALL conflicts,
      // including the matched ones that weren't shown to the user
      const finalResolutions = { ...resolutions };
      
      // Generate the final merged pathway using ALL conflicts (including matches)
      const mergedPathway = ConflictResolver.generateFinalMergedPathway(
        productionPathway,
        smsPathway,
        allConflicts,  // Use all conflicts including matches
        finalResolutions
      );

      console.log("mergedPathway", mergedPathway);

      // update the sms pathway with the new details.
      const smsPathwayResponse =    await $fetch(`/v1/pathway/${smsPathway.id}/version/${smsPathway.version}`, {
        method: 'POST',
        body: JSON.stringify({
            nodes: mergedPathway.nodes,
            edges: mergedPathway.edges,
        })
      });

      console.log("smsPathwayResponse", smsPathwayResponse);
      
      toast.success('Pathways synchronized successfully');
      onClose();
      
    } catch (error) {
      console.error('Error saving merged pathway:', error);
      toast.error('Failed to save changes');
      setIsLoading(false);
    }
  };
  
  // Get the current conflict
  const currentConflict = conflicts[currentIndex];
  
  // Determine if the user can continue based on selected version
  const canContinue = (currentConflict?.status === 'conflict' && selectedVersion) || 
                      currentConflict?.status !== 'conflict';
  
  // Calculate number of actual differences (excluding matches)
  const differenceCount = conflicts.length;
  
  // Calculate stats for summary
  const matchedCount = allConflicts.filter(c => c.status === 'matched').length;
  const conflictCount = allConflicts.filter(c => c.status === 'conflict').length;
  const productionOnlyCount = allConflicts.filter(c => c.status === 'production-only').length;
  const smsOnlyCount = allConflicts.filter(c => c.status === 'sms-only').length;
  
  // Render loading state
  if (isLoading) {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white rounded-lg shadow-xl p-8 max-w-md mx-auto">
          <div className="flex justify-center">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-indigo-600"></div>
          </div>
          <p className="text-center mt-4 text-gray-700">Loading...</p>
        </div>
      </div>
    );
  }
  
  // Render confirmation screen
  if (showConfirmation) {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white rounded-lg shadow-xl w-11/12 max-w-6xl mx-auto max-h-[90vh] overflow-auto">
          <MergeConfirmation 
            conflicts={allConflicts}  // Show all conflicts in summary
            resolutions={resolutions}
            onConfirm={handleSaveAndClose}
            onCancel={() => setShowConfirmation(false)}
            mainPathwayData={mainPathwayData}
            smsPathwayData={smsPathwayData}
          />
        </div>
      </div>
    );
  }
  
  // Render main merge conflicts UI
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-xl w-11/12 max-w-6xl mx-auto max-h-[90vh] flex flex-col">
        {/* Header */}
        <div className="flex justify-between items-center p-4 border-b">
          <div className="flex items-center">
            <GitMerge className="h-5 w-5 text-indigo-600 mr-2" />
            <h2 className="text-lg font-bold text-gray-900">Resolve Merge Conflicts</h2>
            <div className="ml-4 text-sm text-gray-500">
              {productionPathway && smsPathway && (
                <>
                  <span className="font-medium">{productionPathway.name || productionPathway.id}</span>
                  <span className="mx-2">→</span>
                  <span className="font-medium">{smsPathway.name || smsPathway.id}</span>
                </>
              )}
            </div>
          </div>
          <div className="flex items-center">
            <div className="text-sm text-gray-500 mr-4">
              {differenceCount > 0 ? (
                <span>{currentIndex + 1} of {differenceCount} differences</span>
              ) : (
                <span>No differences found</span>
              )}
              <span className="ml-2 text-gray-400">({matchedCount} items match)</span>
            </div>
            <button className="text-gray-500 hover:text-gray-700" onClick={onClose}>
              <X size={20} />
            </button>
          </div>
        </div>

        {/* Progress bar */}
        <ProgressBar currentIndex={currentIndex} totalConflicts={differenceCount} />

        {differenceCount === 0 ? (
          <div className="flex-1 flex items-center justify-center p-8">
            <div className="text-center">
              <Check className="h-12 w-12 text-green-500 mx-auto mb-4" />
              <h3 className="text-lg font-semibold text-gray-800 mb-2">No differences found</h3>
              <p className="text-gray-600">
                Both pathways are in sync. All {matchedCount} nodes/edges match.
              </p>
              <button 
                className="mt-6 px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 focus:outline-none"
                onClick={onClose}
              >
                Close
              </button>
            </div>
          </div>
        ) : (
          <>
            {/* Conflict Item */}
            <div className="flex-1 overflow-auto p-6">
              <div className="mb-4">
                <div className="flex items-center mb-2">
                  {currentConflict.status === 'conflict' && (
                    <AlertTriangle className="h-5 w-5 text-amber-500 mr-2" />
                  )}
                  {currentConflict.status === 'production-only' && (
                    <div className="h-5 w-5 text-blue-500 mr-2 flex items-center justify-center">
                      <span className="text-xs font-bold">P</span>
                    </div>
                  )}
                  {currentConflict.status === 'sms-only' && (
                    <div className="h-5 w-5 text-indigo-500 mr-2 flex items-center justify-center">
                      <span className="text-xs font-bold">S</span>
                    </div>
                  )}
                  <h3 className="text-lg font-semibold text-gray-800">
                    {currentConflict.type === 'node' ? 'Node: ' : 
                     currentConflict.type === 'globalPrompt' ? '' : 'Pathway Label: '}
                    {currentConflict.name}
                  </h3>
                </div>
                
                <div className="text-sm text-gray-500 mb-4">
                  {currentConflict.status === 'conflict' && "This item has different content in both versions. Select which version to keep or make custom edits."}
                  {currentConflict.status === 'production-only' && "This item exists only in the Production pathway."}
                  {currentConflict.status === 'sms-only' && "This item exists only in the SMS pathway."}
                </div>
                
                {currentConflict.status === 'conflict' && (
                  <div className="flex space-x-2 mb-4">
                    <button 
                      onClick={() => handleSelectVersion('production')}
                      className={`py-1 px-3 rounded text-sm ${
                        selectedVersion === 'production' 
                          ? 'bg-blue-600 text-white' 
                          : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                      }`}
                    >
                      Use Production
                    </button>
                    <button 
                      onClick={() => handleSelectVersion('sms')}
                      className={`py-1 px-3 rounded text-sm ${
                        selectedVersion === 'sms' 
                          ? 'bg-indigo-600 text-white' 
                          : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                      }`}
                    >
                      Use SMS
                    </button>
                    {currentConflict.type !== 'globalPrompt' && (
                      <button 
                        onClick={() => handleSelectVersion('custom')}
                        className={`py-1 px-3 rounded text-sm ${
                          selectedVersion === 'custom' 
                            ? 'bg-green-600 text-white' 
                            : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                        }`}
                      >
                        Custom Edit
                      </button>
                    )}
                  </div>
                )}
              </div>

              {/* Render appropriate conflict view based on type */}
              {currentConflict.type === 'node' ? (
                <NodeConflictView 
                  conflict={currentConflict}
                  expandedDetails={expandedDetails}
                  setExpandedDetails={setExpandedDetails}
                  editableContent={editableContent}
                  setEditableContent={setEditableContent}
                  selectedVersion={selectedVersion}
                  onSelectVersion={handleSelectVersion}
                  onSelectField={(fieldPath, version) => 
                    handleFieldResolution(currentConflict.id, fieldPath, version)
                  }
                />
              ) : currentConflict.type === 'globalPrompt' ? (
                <GlobalPromptConflictView
                  conflict={currentConflict}
                  selectedVersion={selectedVersion}
                  onSelectVersion={handleSelectVersion}
                  editableContent={editableContent}
                  setEditableContent={setEditableContent}
                />
              ) : (
                <EdgeConflictView 
                  conflict={currentConflict}
                  selectedVersion={selectedVersion}
                  onSelectVersion={handleSelectVersion}
                  editableContent={editableContent}
                  setEditableContent={setEditableContent}
                  onSelectField={(fieldPath, version) => 
                    handleFieldResolution(currentConflict.id, fieldPath, version)
                  }
                />
              )}
            </div>

            {/* Navigation Footer */}
            <ConflictNavigation 
              currentIndex={currentIndex}
              totalConflicts={differenceCount}
              goToPrevious={goToPrevious}
              goToNext={goToNext}
              selectedVersion={selectedVersion}
              canContinue={canContinue}
              onApplyAndContinue={handleApplyAndContinue}
              conflicts={conflicts}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default MergeConflictsModal; 