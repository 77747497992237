import { useNavigate } from 'react-router-dom'
import { parsePhoneNumberWithError } from 'libphonenumber-js'

function ConversationMetadata({ conversation }) {
  const navigate = useNavigate();

  const formatPhoneNumber = (number) => {
    if (!number || typeof number !== 'string')
      return number
    try {
      return parsePhoneNumberWithError(number)?.formatNational()
    }
    catch {
      // Ignore parsing errors and return the original number
      return number
    }
  }

  const handleToNumberClick = (e) => {
    e.preventDefault();
    navigate("/dashboard/sms?tab=numbers");
  }

  return (
    <div className="mt-4 px-3 text-sm">
      <div className="grid grid-cols-2 gap-3">
        <div className="flex flex-col gap-1">
          <p className="text-gray-500">User Number:</p>
          <p className="font-semibold">{formatPhoneNumber(conversation?.user_number) || '-'}</p>
        </div>
        <div className="flex flex-col gap-1">
          <p className="text-gray-500">Agent Number:</p>
          {conversation?.agent_number ? (
            <a 
              href="#" 
              onClick={handleToNumberClick}
              className="font-semibold text-blue-600 underline hover:text-blue-800 cursor-pointer text-xs"
            >
              {formatPhoneNumber(conversation.agent_number)}
            </a>
          ) : (
            <p className="font-semibold">-</p>
          )}
        </div>
        <div className="flex flex-col gap-1">
          <p className="text-gray-500">Messages:</p>
          <p className="font-semibold">{conversation?.messages?.length || '-'}</p>
        </div>
        <div className="flex flex-col gap-1">
          <p className="text-gray-500">Started:</p>
          <p className="font-semibold">
            {conversation?.created_at ? new Date(conversation.created_at).toLocaleString() : '-'}
          </p>
        </div>
      </div>
    </div>
  )
}

export default ConversationMetadata 