import { Badge, Chip, Button, Tooltip } from '@heroui/react'
import { useQuery } from '@tanstack/react-query'
import { Brain, ChevronDown, Clock, CalendarDays, Smartphone, FileText, BookOpen } from 'lucide-react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { $fetch } from 'utils/fetch'
import { Loading } from 'components/core/Loading'
import CopyCallID from '../../Reusables/CopyCallID'

export default function CallMemoryView({ memoryId, phoneNumber, callId }) {
  const [expanded, setExpanded] = useState(true)
  const navigate = useNavigate()

  // Fetch memory details for the specific user
  const { data, isLoading, error } = useQuery({
    queryKey: ['memoryUserDetails', memoryId, phoneNumber],
    queryFn: async () => {
      const response = await $fetch(`/v1/memory/${memoryId}/user`, {
        method: 'POST',
        body: JSON.stringify({ phone_number: phoneNumber }),
      })
      return response.data
    },
    enabled: !!memoryId && !!phoneNumber,
  })

  // fetch call data
  const { data: callData, isLoading: callLoading, error: callError } = useQuery({
    queryKey: ['callData', memoryId, phoneNumber],
    queryFn: async () => {
      const response = await $fetch(`/v1/memory/${memoryId}/user/search`, {
        method: 'POST',
        body: JSON.stringify({ phone_number: phoneNumber }),
      })
      return response.data
    },
    enabled: !!memoryId && !!phoneNumber,
  })

  console.log({callData})
  

  const formatDate = (dateString) => {
    const options = { 
      month: 'short', 
      day: 'numeric',
      year: 'numeric'
    }
    // Get the date portion
    const date = new Date(dateString).toLocaleDateString(undefined, options)
    
    // Format time separately to have more control
    const timeOptions = {
      hour: '2-digit',
      minute: '2-digit'
    }
    const time = new Date(dateString).toLocaleTimeString(undefined, timeOptions)
    
    return { date, time }
  }

  const viewTranscript = (cId) => {
    navigate(`/dashboard/call-logs/${cId}`)
  }

  // Loading state
  if (isLoading) {
    return <Loading loading={isLoading} />
  }

  // Error state  
  if (error) {
    return (
      <div className="p-5 border border-red-200 bg-red-50 rounded-lg text-center">
        <p className="text-red-600">Error loading memory details</p>
        <p className="text-xs text-red-500 mt-1">{error.message}</p>
      </div>
    )
  }

  // No data state
  if (!data) {
    return (
      <div className="p-5 border border-indigo-200 bg-indigo-50 rounded-lg text-center">
        <p className="text-indigo-600">No memory data available for this user</p>
      </div>
    )
  }

  const callsCount = data.call_count ? data.call_count : 0

  return (
    <div className="space-y-4 px-1">
      <div className="flex items-center justify-between mb-4 p-3 bg-white rounded-lg border shadow-sm">
        <div className="flex items-center">
          <div className="h-8 w-8 rounded-full bg-gradient-to-br from-indigo-500 to-purple-600 flex items-center justify-center text-white mr-3">
            <Brain className="h-4 w-4" />
          </div>
          <div>
            <h3 className="text-base font-medium text-indigo-700">Memory Details</h3>
            <p className="text-xs text-muted-foreground">
                <span className="inline-flex items-center">
                    <Smartphone className="h-3 w-3 mr-1" />
                    {phoneNumber}
                </span>
              {callsCount > 0 && (
                <>
                  <span className="mx-1.5">•</span>
                  <span className="inline-flex items-center">
                    <FileText className="h-3 w-3 mr-1" />
                    {callsCount} conversation{callsCount > 1 ? 's' : ''}
                  </span>
                </>
              )}
            </p>
          </div>
        </div>
    
      </div>

      {expanded && (
        <div className="space-y-4">
          {/* User summary */}
          {data.summary && (
            <div className="p-4 bg-white rounded-lg border shadow-sm">
              <h4 className="text-sm font-medium mb-2 text-indigo-700">User Summary</h4>
              <p className="text-sm text-gray-700 whitespace-pre-line">{data.summary}</p>
            </div>
          )}

          {data.metadata && (
            <div className="p-4 bg-white rounded-lg border shadow-sm">
              <h4 className="text-sm font-medium mb-2 text-indigo-700">User Metadata</h4>
              <p className="text-sm text-gray-700 whitespace-pre-line">{data.metadata}</p>
            </div>
          )}
          
          {/* Calls history */}
          {callData && callData.length > 0 && (
            <div className="bg-white rounded-lg border shadow-sm overflow-hidden">
              <div className="p-3 border-b">
                <h4 className="text-sm font-medium text-indigo-700 flex items-center">
                  <Clock className="h-3.5 w-3.5 mr-1.5" />
                  Interaction History
                </h4>
              </div>
              
              <div className="divide-y">
                {callData.map(call => {
                  const { date, time } = formatDate(call.created_at);
                  const isCurrentCall = call.c_id === callId;
                  
                  return (
                    <div
                      key={call.c_id}
                      className={`p-3 ${isCurrentCall ? 'bg-indigo-50' : ''}`}
                    >
                      <div className="flex justify-between items-center mb-2">
                        <div className="flex-1">
                          <CopyCallID id={call.c_id} maxLength={20} />
                          {isCurrentCall && (
                            <Chip size="sm" variant="flat" color="indigo" className="ml-2">
                              Current Call
                            </Chip>
                          )}
                        </div>
                        
                        {!isCurrentCall && (
                          <Tooltip content="View Call Log">
                            <Button 
                              size="icon"
                              variant="ghost" 
                              color="indigo" 
                              className="h-7 w-7"
                              onPress={() => viewTranscript(call.c_id)}
                            >
                              <BookOpen className="h-3.5 w-3.5" />
                            </Button>
                          </Tooltip>
                        )}
                      </div>
                      
                      <div className="text-xs text-indigo-500 flex items-center">
                        <CalendarDays className="h-3 w-3 mr-1" />
                        <span>{date}</span>
                        <span className="mx-1.5">•</span>
                        <span>{time}</span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  )
} 