import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Switch } from '@/components/ui/switch'
import { Textarea } from '@/components/ui/textarea'

import { useContext, useState } from 'react'

import { useForm } from 'react-hook-form'

import { toast } from 'react-toastify'
import { $fetch } from 'utils/fetch'
import FlowContext from '../contextFlow'
import NodePreviewCard from './NodePreviewCard'
import NodeSelect from './NodeSelect'

async function updateNode(nodeId, data) {
  const response = await $fetch(`/v1/pathway/library/nodes/${nodeId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
    ignoreResponseError: true,
  })
  return response
}

function AddToLibraryForm({ open, onClose, editingNode, openNodeID }) {
  const { elements } = useContext(FlowContext)
  const nodes = elements.nodes
  //   const tagOptions = [
  //     { value: "information-collection", label: "Information Collection" },
  //     { value: "tone", label: "Optimized Tone Of Voice" },
  //     { value: "scheduling", label: "Scheduling" },
  //     { value: "workflows", label: "Workflows" },
  //   ];

  const [isLoading, setIsLoading] = useState(false)

  // Initialize form with editing node data if available
  const form = useForm({
    defaultValues: {
      nodeId: openNodeID || editingNode?.node_data?.id || '',
      name: editingNode?.name || '',
      description: editingNode?.description || '',
      tags: editingNode?.tags || [],
      public: editingNode?.public || false,
    },
  })

  const onSubmit = async (data) => {
    setIsLoading(true)
    try {
      const selectedNode = nodes.find(node => node.id === data.nodeId)
      const submitData = {
        ...data,
        node_data: selectedNode,
        nodeId: undefined,
        id: editingNode?.id || null,
      }

      if (editingNode) {
        const response = await updateNode(editingNode.id, submitData)
        if (response.errors) {
          toast.error('Failed to update node')
          return
        }
        toast.success('Node updated successfully and sent for review!')
      }
      else {
        // Existing create logic
        const response = await $fetch('/v1/pathway/library/nodes', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(submitData),
          ignoreResponseError: true,
        })
        if (response.errors) {
          toast.error('Failed to add node')
          return
        }
        toast.success('Node sent for review successfully!')
      }
      onClose()
    }
    catch (error) {
      toast.error(
        `An error occurred while ${editingNode ? 'updating' : 'saving'} the node`,
      )
    }
    finally {
      setIsLoading(false)
    }
  }

  // Watch form values for preview
  const formValues = form.watch()
  const selectedNode = nodes.find(node => node.id === formValues.nodeId)

  if (selectedNode) {
    selectedNode.data.isStart = false
  }

  // Create preview node object
  const previewNode = selectedNode
    ? {
        node_data: selectedNode,
        name: formValues.name || 'Untitled Node',
        description: formValues.description || 'No description provided',
        tags: formValues.tags || [],
        public: formValues.public || false,
      }
    : null

  if (!open)
    return null

  return (
    <div className="w-full px-2.5">
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="space-y-3 w-full"
        >
          <FormField
            control={form.control}
            name="nodeId"
            render={({ field }) => (
              <FormItem className="w-full">
                <FormLabel>Select Node</FormLabel>
                <FormControl>
                  <NodeSelect
                    nodes={nodes}
                    value={field.value}
                    onChange={field.onChange}
                    placeholder="Select a node to add to library"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Name</FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    className="w-full h-9"
                    placeholder="Enter a concise name for this node e.g. Email Collection Node"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="description"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Description</FormLabel>
                <FormControl>
                  <Textarea
                    {...field}
                    className="w-full resize-none"
                    rows={4}
                    placeholder="Enter a short description to explain what this node does e.g. Collects email from the user until they confirms its correct"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="public"
            render={({ field }) => (
              <FormItem className="flex flex-row items-center justify-between rounded-md border p-2.5">
                <div className="space-y-px">
                  <FormLabel className="text-xs">
                    Share with Community (Public)
                  </FormLabel>
                  <div className="text-xs text-muted-foreground mr-2.5">
                    Make this node available to other users (subject to
                    approval). You can still reuse it in other pathways within
                    your organization if not shared/approved.
                  </div>
                </div>
                <FormControl>
                  <Switch
                    checked={field.value}
                    onCheckedChange={field.onChange}
                  />
                </FormControl>
              </FormItem>
            )}
          />

          {/* <FormField
            control={form.control}
            name="tags"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Tags</FormLabel>
                <FormControl>
                  <MultiSelect
                    fieldName="tags"
                    options={tagOptions}
                    description="Select tags for your node"
                    className="w-full"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          /> */}

          {/* Preview Section */}
          <div className="border-t pt-4 w-full">
            <h4 className="text-xs font-medium mb-2">Preview</h4>
            <div className="w-full">
              <NodePreviewCard node={previewNode} />
            </div>
          </div>

          <div className="flex justify-end space-x-2 pt-2.5 border-t">
            <Button
              variant="outline"
              type="button"
              onClick={onClose}
              disabled={isLoading}
            >
              Cancel
            </Button>
            <Button type="submit" disabled={isLoading}>
              {isLoading ? 'Saving...' : 'Save'}
            </Button>
          </div>
        </form>
      </Form>
    </div>
  )
}

export default AddToLibraryForm
