import axios from 'axios'
import config from 'config'
import { getOrgId } from 'utils/funcs/browser/getOrgId'
import { getAuthToken } from '../browser/getAuthToken'

export async function getAvailableNumbers(user_id, area_code, country_code) {
  const token = getAuthToken()
  const orgId = getOrgId()
  try {
    if (area_code?.length >= 3) {
      const { data } = await axios.post(
        `${config.API_URL}/numbers/get_available_numbers`,
        {
          user_id,
          area_code,
          country_code,
        },
        {
          headers: {
            Authorization: token,
            ...(orgId && { 'x-bland-org-id': orgId }),
          },
        },
      )
      if (data?.data?.phone_numbers) {
        return data.data.phone_numbers
      }
      else {
        console.error(`HTTP error fetching available numbers.`, data)
        return data
      }
    }
  }
  catch (error) {
    console.error(error)
    return error.message
  }
}
