import { forwardRef, Select, SelectItem, SelectSection } from '@heroui/react'
import { useQuery } from '@tanstack/react-query'
import { $fetch } from 'utils/fetch'

const PathwayVersion = forwardRef(({ value, pathwayId, ...props }, ref) => {
  const { data: versions } = useQuery({
    queryKey: ['versions', pathwayId],
    queryFn: () => $fetch(`/v1/pathway/${pathwayId}/versions`),
    enabled: !!pathwayId,
  })

  const stagingVersion = versions?.find(v => v.is_staging)
  const devVersions = versions?.filter(
    v => !v.is_staging && !v.is_prev_published,
  )
  const historicalVersions = versions?.filter(v => v.is_prev_published)

  const renderValue = (value) => {
    const version = versions?.find(v => v.version_number.toString() === value)
    return version?.name || `Version ${version?.version_number}`
  }

  return (
    <Select
      label="Pathway Version"
      placeholder="Select the version of the pathway to use. Default is production."
      selectionMode="single"
      variant="bordered"
      radius="sm"
      labelPlacement="outside"
      selectedKeys={value && [value.toString()]}
      {...props}
      ref={ref}
      renderValue={(items) => {
        const { rendered } = items?.[0] ?? {}
        if (rendered) {
          return Array.isArray(rendered) ? rendered.join(' ') : rendered
        }
      }}
    >
      <SelectSection title="Current">
        <SelectItem
          key="0"
          className="flex items-center justify-between"
        >
          Production
        </SelectItem>
        {stagingVersion && (
          <SelectItem
            key={stagingVersion.version_number.toString()}
            className="flex items-center justify-between"
          >
            Staging
            {' '}
            {renderValue(stagingVersion.version_number.toString())}
          </SelectItem>
        )}
      </SelectSection>
      {devVersions?.length > 0 && (
        <SelectSection title="Development">
          {devVersions.map(version => (
            <SelectItem
              key={version.version_number.toString()}
              endContent={
                <p className="text-xs font-geist-mono text-muted-foreground">{version.version_number}</p>
              }
            >
              {renderValue(version.version_number.toString())}
            </SelectItem>
          ))}
        </SelectSection>
      )}
      {historicalVersions?.length > 0 && (
        <SelectSection title="Historical">
          {historicalVersions.map(version => (
            <SelectItem key={version.version_number.toString()}>
              {renderValue(version.version_number.toString())}
            </SelectItem>
          ))}
        </SelectSection>
      )}
    </Select>
  )
})

export default PathwayVersion
