import AudioComponent from 'components/Dashboard/CallLogs/AudioComponent.jsx'
import config from 'config'
import html2canvas from 'html2canvas'
import {
  Download,
  Minus,
  Pause,
  Play,
  TrendingDown,
  TrendingUp,
} from 'lucide-react'
// SearsInteractiveDashboard.js
import { useEffect, useState } from 'react'
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Pie,
  PieChart,
  ResponsiveContainer,
  Sankey,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { getOrgId } from 'utils/funcs/browser/getOrgId'

const COLORS = [
  '#FF6B6B',
  '#4ECDC4',
  '#45B7D1',
  '#FFA07A',
  '#98D8C8',
  '#F06595',
  '#74C0FC',
  '#B197FC',
  '#69DB7C',
  '#FFD43B',
]

const SUCCESS_COLOR = '#4CAF50' // Green
const FAILURE_COLOR = '#F44336' // Red

const TAGS = ['Reschedule', 'Cancel', 'CreateService']

function SearsInteractiveDashboard() {
  const [callData, setCallData] = useState([])
  const [loading, setLoading] = useState(true)
  const [activeTab, setActiveTab] = useState('overview')
  const [stats, setStats] = useState({
    totalCalls: 0,
    successCounts: {
      Reschedule: 0,
      Cancel: 0,
      CreateService: 0,
    },
    failureCounts: {
      Reschedule: 0,
      Cancel: 0,
      CreateService: 0,
    },
    totalCallsByTag: {
      Reschedule: 0,
      Cancel: 0,
      CreateService: 0,
    },
    avgCallLength: '0.00',
    flowPercentages: { Reschedule: '0.0', Cancel: '0.0', CreateService: '0.0' },
    successRates: { Reschedule: '0.0', Cancel: '0.0', CreateService: '0.0' },
    flowTagDistributions: {},
    callsWithSuccessTag: 0,
    callLengths: [],
    hourlyCallVolume: [],
  })
  const [audioPlaying, setAudioPlaying] = useState(null)

  const fetchCalls = async () => {
    setLoading(true)
    try {
      const token = localStorage.getItem('auth')
      const orgId = getOrgId()
      if (!token) {
        console.error('No authorization token found. Please log in.')
        // Redirect to login page or display a message
        return
      }

      const response = await fetch(`${config.API_URL}/analysis/sears`, {
        headers: {
          Authorization: `${token}`,
          ...(orgId && { 'x-bland-org-id': orgId }),
        },
      })

      if (!response.ok) {
        if (response.status === 401) {
          console.error('Unauthorized access - please log in again.')
          // Handle unauthorized access, possibly redirect to login
        }
        else {
          throw new Error(`HTTP error! status: ${response.status}`)
        }
        return
      }

      const data = await response.json()
      if (!data || !data.data) {
        throw new Error('Invalid data format received from API')
      }

      setCallData(data.data)
      calculateStats(data.data)
    }
    catch (error) {
      console.error('Error fetching call data:', error)
      // Set default values to prevent undefined errors
      setCallData([])
      setStats({
        totalCalls: 0,
        successCounts: {
          Reschedule: 0,
          Cancel: 0,
          CreateService: 0,
        },
        failureCounts: {
          Reschedule: 0,
          Cancel: 0,
          CreateService: 0,
        },
        totalCallsByTag: {
          Reschedule: 0,
          Cancel: 0,
          CreateService: 0,
        },
        avgCallLength: '0.00',
        flowPercentages: {
          Reschedule: '0.0',
          Cancel: '0.0',
          CreateService: '0.0',
        },
        successRates: {
          Reschedule: '0.0',
          Cancel: '0.0',
          CreateService: '0.0',
        },
        flowTagDistributions: {},
        callsWithSuccessTag: 0,
        callLengths: [],
        hourlyCallVolume: [],
      })
    }
    finally {
      setLoading(false)
    }
  }

  const [treat04asSuccess, setTreat04asSuccess] = useState(true)

  const hasSuccessTag = (tagName, mainFlowTag, containsO4Error = false) =>
    tagName.toLowerCase().includes('success')
    || tagName.toLowerCase().includes('diagnosticread')
    // tagName.includes("RescheduleFailed") ||
    || tagName === 'NoCancelKeepOriginal'
    || tagName === 'Reschedule - Webhook 04'
    || tagName === 'Cancel - Webhook 04'
    || tagName === 'NoCancelKeepOriginal'
    || tagName === 'ReConfirmDiagnostic'
    // tagName === "Call Terminated by User" ||
    || tagName === 'NoAvailability'
    || tagName === '04Reschedule'
    || tagName === 'CancelOStatus'
    || (mainFlowTag === 'Reschedule' && tagName === 'Cancel')
    || (mainFlowTag === 'Cancel' && tagName === 'Reschedule')
    || (treat04asSuccess && containsO4Error)
    || tagName === 'RescheduleKeepOriginal'

  const calculateStats = (calls) => {
    if (!Array.isArray(calls) || calls.length === 0) {
      // Handle case where there are no calls
      setStats({
        totalCalls: 0,
        successCounts: {
          Reschedule: 0,
          Cancel: 0,
          CreateService: 0,
        },
        failureCounts: {
          Reschedule: 0,
          Cancel: 0,
          CreateService: 0,
        },
        totalCallsByTag: {
          Reschedule: 0,
          Cancel: 0,
          CreateService: 0,
        },
        avgCallLength: '0.00',
        flowPercentages: {
          Reschedule: '0.0',
          Cancel: '0.0',
          CreateService: '0.0',
        },
        successRates: {
          Reschedule: '0.0',
          Cancel: '0.0',
          CreateService: '0.0',
        },
        flowTagDistributions: {},
        callsWithSuccessTag: 0,
        callLengths: [],
        hourlyCallVolume: [],
      })
      return
    }

    const totalCalls = calls.length
    const flowCounts = { Reschedule: 0, Cancel: 0, CreateService: 0 }
    const flowTagDistributions = {
      Reschedule: {},
      Cancel: {},
      CreateService: {},
    }
    const successCounts = { Reschedule: 0, Cancel: 0, CreateService: 0 }
    let totalCallLength = 0
    let callsWithSuccessTag = 0
    const callLengths = []
    const hourlyCallVolumeMap = {}

    calls.forEach((call) => {
      call.pathway_tags = call.pathway_tags.map((tag) => {
        if (tag.name === '04RescheduleFailure') {
          return {
            name: '04Reschedule',
            description: '04Reschedule',
          }
        }
        else {
          return tag
        }
      })

      call.pathway_tags.sort((a, b) => a.name.localeCompare(b.name))

      if (treat04asSuccess && call.pathway_logs_contain_04) {
        call.pathway_tags = call.pathway_tags.map((tag) => {
          if (tag.name === 'CancelFailed') {
            return {
              ...tag,
              name: 'Cancel - Webhook 04',
              description: 'Cancel - Webhook 04',
            }
          }
          else if (tag.name === 'RescheduleFailed') {
            return {
              ...tag,
              name: 'Reschedule - Webhook 04',
              description: 'Reschedule - Webhook 04',
            }
          }
          return tag
        })
      }
      else {
        call.pathway_tags = call.pathway_tags.map((tag) => {
          if (tag.name === 'Reschedule - Webhook 04') {
            return {
              ...tag,
              name: 'RescheduleFailed',
              description: 'RescheduleFailed',
            }
          }
          else if (tag.name === 'Cancel - Webhook 04') {
            return {
              ...tag,
              name: 'CancelFailed',
              description: 'CancelFailed',
            }
          }
          return tag
        })
      }
      totalCallLength += call.call_length
      callLengths.push({ id: call.id, length: call.call_length })

      const callDateTime = new Date(call.created_at)
      const hourKey = `${callDateTime.toISOString().split('T')[0]} ${callDateTime.getHours()}:00`
      hourlyCallVolumeMap[hourKey] = (hourlyCallVolumeMap[hourKey] || 0) + 1
      const mainFlowTag = call.pathway_tags.find(tag =>
        TAGS.includes(tag.name),
      )

      if (call.pathway_tags.length <= 1) {
        call.pathway_tags.push({
          name: 'Call Terminated by User',
          description: 'Call Terminated by User',
        })
      }
      // Check if call has any pathway tag indicating success or completion

      // Identify main flow tag

      if (mainFlowTag) {
        const successTagPresent = call.pathway_tags.some((tag) => {
          return hasSuccessTag(
            tag.name,
            mainFlowTag,
            call.pathway_logs_contain_04,
          )
        })

        if (successTagPresent) {
          callsWithSuccessTag++
        }

        const flowName = mainFlowTag.name
        flowCounts[flowName]++

        // Check if call has 'success' tag for this flow

        // Collect only one unique downstream tag, prioritize success tags
        let downstreamTag = null
        for (const tag of call.pathway_tags) {
          if (tag.name !== flowName) {
            if (
              hasSuccessTag(tag.name, mainFlowTag, call.pathway_logs_contain_04)
            ) {
              if (successTagPresent) {
                successCounts[flowName]++
              }
              downstreamTag = tag.name
              break // Exit loop if a success tag is found
            }
            else if (!downstreamTag) {
              downstreamTag = tag.name
            }
          }
        }

        if (downstreamTag) {
          if (!flowTagDistributions[flowName]) {
            flowTagDistributions[flowName] = {}
          }
          flowTagDistributions[flowName][downstreamTag]
            = (flowTagDistributions[flowName][downstreamTag] || 0) + 1
        }
      }
    })

    const totalCallsByTag = {
      Reschedule: Object.values(flowTagDistributions.Reschedule || {}).reduce(
        (acc, count) => acc + count,
        0,
      ),
      Cancel: Object.values(flowTagDistributions.Cancel || {}).reduce(
        (acc, count) => acc + count,
        0,
      ),
      CreateService: 0,
    }

    // Calculate percentages
    const flowPercentages = {}
    TAGS.forEach((tag) => {
      flowPercentages[tag] = totalCalls
        ? ((flowCounts[tag] / totalCalls) * 100).toFixed(1)
        : '0.0'
    })

    const successRates = {}
    TAGS.forEach((tag) => {
      const totalCallsForTag = totalCallsByTag[tag]
      const successRate = totalCallsForTag
        ? (successCounts[tag] / totalCallsForTag) * 100
        : 0
      successRates[tag] = successRate.toFixed(2)
    })

    const avgCallLength = totalCalls
      ? (totalCallLength / totalCalls).toFixed(2)
      : '0.00'

    const hourlyCallVolume = Object.keys(hourlyCallVolumeMap)
      .map(hourKey => ({
        datetime: hourKey,
        count: hourlyCallVolumeMap[hourKey],
      }))
      .sort((a, b) => new Date(a.datetime) - new Date(b.datetime))
    const failureCounts = {
      Reschedule: flowCounts.Reschedule - successCounts.Reschedule,
      Cancel: flowCounts.Cancel - successCounts.Cancel,
      CreateService: flowCounts.CreateService - successCounts.CreateService,
    }

    setStats({
      totalCalls,
      totalCallsByTag,
      failureCounts,
      successCounts,
      avgCallLength,
      flowPercentages,
      successRates,
      flowTagDistributions,
      callsWithSuccessTag,
      callLengths,
      hourlyCallVolume,
    })
  }

  useEffect(() => {
    fetchCalls()
  }, [])

  useEffect(() => {
    calculateStats(callData)
  }, [treat04asSuccess])

  const SuccessRateCard = ({ tag, rate }) => {
    let TrendIcon = Minus
    let trendColor = 'text-gray-500'
    if (rate > 50) {
      TrendIcon = TrendingUp
      trendColor = 'text-green-500'
    }
    else if (rate < 50) {
      TrendIcon = TrendingDown
      trendColor = 'text-red-500'
    }

    return (
      <div className="bg-white rounded-md shadow p-4 flex flex-col border border-gray-200">
        <h3 className="text-sm font-medium text-gray-900 mb-1.5">
          {tag}
          {' '}
          {tag === 'CreateService'
            ? 'Book Rate (of non transferred calls)'
            : tag !== 'CreateService Book Rate (Including Transferred Calls)'
              ? 'AI Handles'
              : ''}
        </h3>
        <div className="flex items-baseline justify-between">
          <p className="text-xl font-semibold text-gray-900">
            {rate}
            %
          </p>
        </div>
        <p className="text-xs text-gray-500 mt-1.5">
          Total Calls:
          {' '}
          {stats.totalCallsByTag[tag]}
        </p>
      </div>
    )
  }

  const FlowTagDistribution = ({ flowName, distribution }) => {
    // Ensure distribution is an object
    if (!distribution || Object.keys(distribution).length === 0) {
      return (
        <div className="bg-white rounded-md shadow p-4">
          {/* <h2 className="text-base font-semibold mb-2.5">
            {flowName} Tag Distribution
          </h2> */}
          <p className="text-gray-500">
            No downstream tags for
            {flowName}
          </p>
        </div>
      )
    }

    return (
      <div className="bg-white rounded-md shadow p-4">
        <h2 className="text-base font-semibold mb-2.5">
          {flowName}
          {' '}
          Outcome Distribution
        </h2>

        {(() => {
          const CustomNode = (props) => {
            return (
              <rect
                x={props.x + 4}
                y={props.y - 2}
                width={props.width - 8}
                height={props.height + 4}
                fill={
                  props.index === 0
                    ? '#4C51BF'
                    : hasSuccessTag(props.payload?.name ?? '', flowName)
                      ? SUCCESS_COLOR
                      : FAILURE_COLOR
                }
                rx={2.5}
              />
            )
          }

          const CustomLink = (props) => {
            return (
              <g>
                <path
                  d={`
                M${props.sourceX},${props.sourceY}
                C${props.sourceControlX},${props.sourceY} ${props.targetControlX},${props.targetY} ${props.targetX},${props.targetY}`}
                  fill="none"
                  stroke={
                    hasSuccessTag(props.payload?.target?.name ?? '', flowName)
                      ? '#4caf95' // Success color with blue hue
                      : '#F44336' // Failure color with blue hue
                  }
                  strokeOpacity={0.4}
                  strokeWidth={props.linkWidth}
                  strokeLinecap="butt"
                />
                <foreignObject
                  x={props.sourceX}
                  y={props.targetY - props.linkWidth / 2}
                  width={
                    Math.max(props.targetX, props.sourceX)
                    - Math.min(props.targetX, props.sourceX)
                  }
                  height={props.linkWidth}
                  style={{ overflow: 'visible' }}
                >
                  <div
                    style={{
                      boxSizing: 'border-box',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                      width: '100%',
                      height: '100%',
                      overflow: 'visible',
                      padding: '0.5em',
                      gap: 8,
                    }}
                  >
                    <div
                      style={{
                        fontSize: 10,
                        fontFamily: 'sans-serif',
                        textAlign: 'center',
                        backgroundColor: '#f1f5fe80',
                        padding: '0.25em 0.5em',
                        borderRadius: 4,
                        position: 'relative',
                        zIndex: 1,
                      }}
                    >
                      {props.payload.target.name
                        ? `${props.payload.target.name}: `
                        : ''}
                      {props.payload.value}
                    </div>
                  </div>
                </foreignObject>
              </g>
            )
          }

          return (
            <ResponsiveContainer width="100%" height={400}>
              <Sankey
                data={{
                  nodes: [
                    { name: flowName },
                    // { name: "Success" },
                    // { name: "Failure" },
                    ...Object.keys(distribution).map(tag => ({ name: tag })),
                  ],
                  links: [
                    // {
                    //   source: 0,
                    //   target: 1,
                    //   value: stats.successCounts[flowName],
                    // },
                    // {
                    //   source: 0,
                    //   target: 2,
                    //   value: stats.failureCounts[flowName],
                    // },
                    ...Object.entries(distribution).map(
                      ([tag, count], index) => ({
                        source: 0,
                        target: index + 1,
                        value: count,
                      }),
                    ),
                  ],
                }}
                node={CustomNode}
                link={CustomLink}
                nodeWidth={16}
                nodePadding={14}
                height={500}
                width={960}
                sort={false}
                margin={{
                  top: 20,
                  left: 20,
                  right: 20,
                  bottom: 20,
                }}
              />
            </ResponsiveContainer>
          )
        })()}
      </div>
    )
  }

  const AudioPlayer = ({ url }) => {
    const [isPlaying, setIsPlaying] = useState(false)

    const togglePlay = () => {
      if (audioPlaying === url) {
        setAudioPlaying(null)
        setIsPlaying(false)
      }
      else {
        setAudioPlaying(url)
        setIsPlaying(true)
      }
    }

    useEffect(() => {
      if (audioPlaying !== url) {
        setIsPlaying(false)
      }
    }, [audioPlaying, url])

    return (
      <button
        onClick={togglePlay}
        className="p-1.5 bg-indigo-500 text-white rounded-full"
      >
        {isPlaying ? <Pause size={16} /> : <Play size={16} />}
      </button>
    )
  }
  const CallsTable = ({ calls }) => {
    const [currentPage, setCurrentPage] = useState(1)
    const callsPerPage = 10

    if (!Array.isArray(calls) || calls.length === 0) {
      return (
        <div className="text-center text-gray-500">No calls available.</div>
      )
    }

    // Get current calls
    const indexOfLastCall = currentPage * callsPerPage
    const indexOfFirstCall = indexOfLastCall - callsPerPage
    const currentCalls = calls.slice(indexOfFirstCall, indexOfLastCall)

    // Change page
    const paginate = pageNumber => setCurrentPage(pageNumber)

    return (
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="bg-gray-100">
            <tr>
              {/* Table Headers */}
              <th className="px-4 py-2 text-left text-2xs font-medium text-gray-700 uppercase tracking-wider">
                ID
              </th>
              <th className="px-4 py-2 text-left text-2xs font-medium text-gray-700 uppercase tracking-wider">
                Date
              </th>
              <th className="px-4 py-2 text-left text-2xs font-medium text-gray-700 uppercase tracking-wider">
                Length (min)
              </th>
              <th className="px-4 py-2 text-left text-2xs font-medium text-gray-700 uppercase tracking-wider">
                Main Flow
              </th>
              <th className="px-4 py-2 text-left text-2xs font-medium text-gray-700 uppercase tracking-wider">
                Tags
              </th>
              <th className="px-4 py-2 text-left text-2xs font-medium text-gray-700 uppercase tracking-wider">
                Status
              </th>
              <th className="px-4 py-2 text-left text-2xs font-medium text-gray-700 uppercase tracking-wider">
                Recording
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {currentCalls.map(call => (
              <tr key={call.id} className="hover:bg-gray-50">
                <td className="px-4 py-2.5 whitespace-nowrap text-xs font-medium text-gray-900">
                  {call.id}
                </td>
                <td className="px-4 py-2.5 whitespace-nowrap text-xs text-gray-500">
                  {new Date(call.created_at).toLocaleString()}
                </td>
                <td className="px-4 py-2.5 whitespace-nowrap text-xs text-gray-500">
                  {call.call_length.toFixed(2)}
                </td>
                <td className="px-4 py-2.5 whitespace-nowrap text-xs text-gray-500">
                  {call.pathway_tags.find(tag => TAGS.includes(tag.name))
                    ?.name || 'N/A'}
                </td>
                <td className="px-4 py-2.5 whitespace-nowrap text-xs text-gray-500">
                  {Array.from(
                    new Set(call.pathway_tags.map(tag => tag.name)),
                  ).map(tagName => (
                    <span
                      key={tagName}
                      className="inline-flex items-center px-1.5 py-px rounded-full text-2xs font-medium bg-gray-200 text-gray-800 mr-1.5"
                    >
                      {tagName}
                    </span>
                  ))}
                </td>
                <td className="px-4 py-2.5 whitespace-nowrap text-xs text-gray-500">
                  {call.pathway_tags.length === 1
                    ? 'Unknown'
                    : call.pathway_tags.some(tag =>
                      hasSuccessTag(
                        tag.name,
                        call.pathway_tags.find(tag =>
                          TAGS.includes(tag.name),
                        ),
                        call.pathway_logs_contain_04,
                      ),
                    )
                      ? 'Success'
                      : 'Failure'}
                </td>
                <td className="px-4 py-2.5 whitespace-nowrap text-xs text-gray-500">
                  {call.recording_url
                    ? (
                        <AudioComponent
                          recordingUrl={call.recording_url?.replace('.mp3', '.wav')}
                        />
                      )
                    : (
                        'No Recording'
                      )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="mt-2.5 flex justify-center items-center">
          <button
            onClick={() => paginate(currentPage - 1)}
            disabled={currentPage === 1}
            className={`mx-0.5 px-2 py-0.5 rounded-sm ${
              currentPage === 1
                ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
            }`}
          >
            Previous
          </button>
          <button
            onClick={() => paginate(currentPage + 1)}
            disabled={currentPage === Math.ceil(calls.length / callsPerPage)}
            className={`mx-0.5 px-2 py-0.5 rounded-sm ${
              currentPage === Math.ceil(calls.length / callsPerPage)
                ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
            }`}
          >
            Next
          </button>
          <span className="mx-1.5"></span>
          <span className="mx-1.5">
            Page
            {' '}
            {currentPage}
            {' '}
            of
            {' '}
            {Math.ceil(calls.length / callsPerPage)}
          </span>
          <input
            type="text"
            id="page-input"
            min="1"
            max={Math.ceil(calls.length / callsPerPage)}
            defaultValue={currentPage}
            // onChange={(e) => {
            //   const value = parseInt(e.target.value);
            //   if (!isNaN(value) && value >= 1 && value <= Math.ceil(calls.length / callsPerPage)) {
            //     setCurrentPage(value);
            //   }
            // }}
            className="mx-1.5 w-10 px-1.5 py-0.5 border border-gray-300 rounded-sm appearance-none"
            style={{ WebkitAppearance: 'none', MozAppearance: 'textfield' }}
          />
          <button
            onClick={() => {
              const inputElement = document.getElementById('page-input')
              const page = Number.parseInt(inputElement.value)
              if (page >= 1 && page <= Math.ceil(calls.length / callsPerPage)) {
                paginate(page)
              }
            }}
            className="mx-0.5 px-2 py-0.5 rounded-sm bg-gray-200 text-gray-700 hover:bg-gray-300"
          >
            Go
          </button>
        </div>
      </div>
    )
  }

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen bg-gray-50 text-gray-800">
        Loading...
      </div>
    )
  }

  return (
    <div className="min-h-screen bg-gray-50 text-gray-900">
      {/* Header */}
      <header className="bg-white shadow border-b border-gray-200">
        <div className="max-w-10xl mx-auto px-2.5 sm:px-4 lg:px-5 py-4 flex justify-between items-center">
          <h1 className="text-xl font-bold tracking-tight">
            Sears Interactive Dashboard
          </h1>
          <div className="flex items-center space-x-2.5">
            <button
              onClick={() => {
                const element = document.querySelector('#abletocapture')
                // Remove the header from the element before capturing

                if (element) {
                  html2canvas(element).then((canvas) => {
                    const link = document.createElement('a')
                    link.download = 'sears_dashboard.png'
                    link.href = canvas.toDataURL('image/png')
                    link.click()
                  })
                }
              }}
              className="px-2.5 py-1.5 bg-indigo-600 text-white rounded flex items-center hover:bg-indigo-700 transition"
            >
              <Download size={16} className="mr-1.5" />
              {' '}
              Download
            </button>
            {/* <Switch
              checked={treat04asSuccess}
              onChange={() => setTreat04asSuccess(!treat04asSuccess)}
              className={`${
                treat04asSuccess ? "bg-indigo-600" : "bg-gray-200"
              } relative inline-flex h-4 w-7 items-center rounded-full`}
            >
              <span className="sr-only">Treat 04 as Success</span>
              <span
                className={`${
                  treat04asSuccess ? "translate-x-4" : "translate-x-0.5"
                } inline-block h-2.5 w-2.5 transform rounded-full bg-white transition`}
              />
            </Switch>
            <span className="ml-1.5 text-xs text-gray-700">
              Treat 04 as Success
            </span> */}

            {/* <button className="px-2.5 py-1.5 bg-gray-200 text-gray-700 rounded flex items-center hover:bg-gray-300 transition">
              <Share2 size={16} className="mr-1.5" /> Share
            </button> */}
          </div>
        </div>
      </header>

      <main className="max-w-10xl mx-auto px-2.5 sm:px-4 lg:px-5 py-5">
        {/* Tabs */}
        <div className="mb-5 flex justify-between items-center">
          <div className="flex space-x-2.5">
            <button
              onClick={() => setActiveTab('overview')}
              className={`px-2.5 py-1.5 rounded border ${activeTab === 'overview' ? 'bg-indigo-600 text-white border-indigo-600' : 'bg-white text-gray-700 border-gray-300'} hover:bg-indigo-50 transition`}
            >
              Overview
            </button>
            {/* <button
              onClick={() => setActiveTab("calls")}
              className={`px-2.5 py-1.5 rounded border ${activeTab === "calls" ? "bg-indigo-600 text-white border-indigo-600" : "bg-white text-gray-700 border-gray-300"} hover:bg-indigo-50 transition`}
            >
              Calls
            </button>
            <button
              onClick={() => setActiveTab("analytics")}
              className={`px-2.5 py-1.5 rounded border ${activeTab === "analytics" ? "bg-indigo-600 text-white border-indigo-600" : "bg-white text-gray-700 border-gray-300"} hover:bg-indigo-50 transition`}
            >
              Analytics
            </button> */}
          </div>
          {/* <button className="px-2.5 py-1.5 bg-white text-gray-700 rounded flex items-center border border-gray-300 hover:bg-gray-100 transition">
            <Filter size={16} className="mr-1.5" /> Filter
          </button> */}
        </div>

        <span id="abletocapture">
          {activeTab === 'overview' && (
            <>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-5">
                {TAGS.map(tag => (
                  <SuccessRateCard
                    key={tag}
                    tag={tag}
                    rate={(() => {
                      const distribution = stats.flowTagDistributions[tag]
                      if (!distribution)
                        return 0

                      if (tag === 'CreateService') {
                        const createSuccess
                          = distribution.CreateSuccess || 0
                        const callTerminated
                          = distribution['Call Terminated by User'] || 0
                        const noAvailability
                          = distribution.NoAvailability || 0
                        const UnableToConfirmProduct
                          = distribution.UnableToConfirmProduct || 0

                        const diagnosticRead
                          = distribution.DiagnosticRead || 0
                        // CreateSuccess / (CreateSuccess + Call Terminated + No Availability+ Diagnostric Read)
                        const totalRelevantCalls
                          = createSuccess
                            + callTerminated
                            + noAvailability
                            + UnableToConfirmProduct
                            + diagnosticRead
                        if (stats.totalCallsByTag.CreateService === 0) {
                          setStats({
                            ...stats,
                            totalCallsByTag: {
                              ...stats.totalCallsByTag,
                              CreateService: totalRelevantCalls,
                            },
                          })
                        }
                        return totalRelevantCalls === 0
                          ? 0
                          : (
                              (createSuccess / totalRelevantCalls)
                              * 100
                            ).toFixed(2)
                      }
                      else {
                        const totalCalls = Object.values(distribution).reduce(
                          (acc, count) => acc + count,
                          0,
                        )
                        const successCalls = Object.entries(
                          distribution,
                        ).reduce(
                          (acc, [key, count]) =>
                            hasSuccessTag(key, tag) ? acc + count : acc,
                          0,
                        )

                        return totalCalls === 0
                          ? 0
                          : ((successCalls / totalCalls) * 100).toFixed(2)
                      }
                    })()}
                  />
                ))}
                <SuccessRateCard
                  key="CreateService"
                  tag="CreateService Book Rate (Including Transferred Calls)"
                  rate={(() => {
                    const distribution
                      = stats.flowTagDistributions.CreateService
                    if (!distribution)
                      return 0

                    const createSuccess = distribution.CreateSuccess || 0
                    const callTerminated
                      = distribution['Call Terminated by User'] || 0
                    const noAvailability = distribution.NoAvailability || 0
                    const diagnosticRead = distribution.DiagnosticRead || 0
                    const customerTransfer
                      = distribution.CustomerTransfer || 0
                    const systemTransfer = distribution.SystemTransfer || 0
                    const confirmTransfer
                      = distribution['Confirm Transfer'] || 0
                    const UnableToConfirmProduct
                      = distribution.UnableToConfirmProduct || 0

                    // CreateSuccess / (CreateSuccess + Call Terminated + No Availability+ Diagnostric Read)
                    const totalRelevantCalls
                      = createSuccess
                        + callTerminated
                        + noAvailability
                        + UnableToConfirmProduct
                        + customerTransfer
                        + systemTransfer
                        + confirmTransfer
                        + diagnosticRead
                    if (
                      !stats.totalCallsByTag[
                        'CreateService Book Rate (Including Transferred Calls)'
                      ]
                    ) {
                      setStats({
                        ...stats,
                        totalCallsByTag: {
                          ...stats.totalCallsByTag,
                          'CreateService Book Rate (Including Transferred Calls)':
                            totalRelevantCalls,
                        },
                      })
                    }

                    return totalRelevantCalls === 0
                      ? 0
                      : ((createSuccess / totalRelevantCalls) * 100).toFixed(2)
                  })()}
                />
              </div>

              <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-5">
                {TAGS.map(tag => (
                  <FlowTagDistribution
                    key={tag}
                    flowName={tag}
                    distribution={stats.flowTagDistributions[tag]}
                  />
                ))}
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-5">
                <div className="bg-white rounded-md shadow p-4">
                  <h2 className="text-base font-semibold mb-2.5">
                    Call Flow Percentages
                  </h2>
                  <ResponsiveContainer width="100%" height={300}>
                    <PieChart>
                      <Pie
                        data={TAGS.map(tag => ({
                          name: tag,
                          value: Number.parseFloat(stats.flowPercentages[tag]),
                        }))}
                        cx="50%"
                        cy="50%"
                        outerRadius={80}
                        fill="#8884d8"
                        dataKey="value"
                        label={({ name, value }) => `${name} ${value}%`}
                      >
                        {TAGS.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={COLORS[index % COLORS.length]}
                          />
                        ))}
                      </Pie>
                      <Tooltip />
                    </PieChart>
                  </ResponsiveContainer>
                </div>

                <div className="bg-white rounded-md shadow p-4">
                  <h2 className="text-base font-semibold mb-2.5">
                    Hourly Call Volume
                  </h2>
                  <ResponsiveContainer width="100%" height={300}>
                    <BarChart data={stats.hourlyCallVolume}>
                      <CartesianGrid strokeDasharray="3 3" stroke="#E5E7EB" />
                      <XAxis
                        dataKey="datetime"
                        stroke="#4B5563"
                        tickFormatter={(value) => {
                          const date = new Date(value)
                          return date.toLocaleDateString()
                        }}
                      />
                      <YAxis stroke="#4B5563" />
                      <Tooltip
                        labelFormatter={(value) => {
                          const date = new Date(value)
                          return date.toLocaleString()
                        }}
                      />
                      <Bar dataKey="count" fill="#4C51BF" />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </>
          )}

          {activeTab === 'calls' && (
            <div className="bg-white rounded-md shadow p-4">
              <h2 className="text-base font-semibold mb-2.5">All Calls</h2>
              <CallsTable calls={callData} />
            </div>
          )}

          {activeTab === 'analytics' && (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="bg-white rounded-md shadow p-4 ">
                <h2 className="text-base font-semibold mb-2.5">Call Lengths</h2>
                <ResponsiveContainer width="100%" height={300}>
                  <BarChart
                    data={Object.entries(
                      stats.callLengths.reduce((acc, call) => {
                        const bucket = Math.floor(call.length * 10)
                        if (bucket > 0 && bucket < 100) {
                          acc[bucket] = (acc[bucket] || 0) + 1
                        }
                        return acc
                      }, {}),
                    ).map(([length, count]) => ({
                      length: Number.parseInt(length),
                      count,
                    }))}
                    margin={{ top: 10, right: 30, left: 0, bottom: 15 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" stroke="#E5E7EB" />
                    <XAxis
                      dataKey="length"
                      type="number"
                      stroke="#4B5563"
                      tickFormatter={value => (value / 10).toFixed(1)}
                      label={{
                        value: 'Call Length (minutes)',
                        position: 'insideBottom',
                        offset: -10,
                      }}
                    />
                    <YAxis
                      stroke="#4B5563"
                      label={{
                        value: 'Number of Calls',
                        angle: -90,
                        position: 'insideLeft',
                      }}
                    />
                    <Tooltip
                      formatter={(value, name, props) => [
                        `${value} calls`,
                        `${(props.payload.length / 10).toFixed(2)} minutes`,
                      ]}
                    />
                    <Bar dataKey="count" fill="#4C51BF" />
                  </BarChart>
                </ResponsiveContainer>
              </div>

              <div className="bg-white rounded-md shadow p-4">
                <h2 className="text-lg font-bold mb-4">Detailed Analytics</h2>
                <div className="space-y-2.5">
                  <div className="flex justify-between items-center border-b border-gray-200 pb-1.5">
                    <p className="text-sm text-gray-600">Total Calls:</p>
                    <span className="text-base font-semibold text-indigo-600">
                      {stats.totalCalls}
                    </span>
                  </div>
                  <div className="flex justify-between items-center border-b border-gray-200 pb-1.5">
                    <p className="text-sm text-gray-600">
                      Average Call Length:
                    </p>
                    <span className="text-base font-semibold text-indigo-600">
                      {stats.avgCallLength}
                      {' '}
                      min
                    </span>
                  </div>
                  <div className="flex justify-between items-center border-b border-gray-200 pb-1.5">
                    <p className="text-sm text-gray-600">
                      Calls with Success Tag:
                    </p>
                    <span className="text-base font-semibold text-indigo-600">
                      {stats.callsWithSuccessTag}
                    </span>
                  </div>
                  <div className="flex justify-between items-center">
                    <p className="text-sm text-gray-600">Success Rate:</p>
                    <span className="text-base font-semibold text-indigo-600">
                      {(
                        (stats.callsWithSuccessTag / stats.totalCalls)
                        * 100
                      ).toFixed(2)}
                      %
                    </span>
                  </div>
                </div>
                <div className="mt-4">
                  <h3 className="text-sm font-semibold mb-1.5 text-gray-700">
                    Quick Stats
                  </h3>
                  <div className="grid grid-cols-2 gap-2.5">
                    <div className="bg-indigo-100 p-2.5 rounded-md">
                      <p className="text-xs text-indigo-700">Busiest Hour</p>
                      <p className="text-sm font-semibold text-indigo-900">
                        {
                          stats.hourlyCallVolume.reduce((a, b) =>
                            a.count > b.count ? a : b,
                          ).datetime
                        }
                      </p>
                    </div>
                    <div className="bg-green-100 p-2.5 rounded-md">
                      <p className="text-xs text-green-700">Longest Call</p>
                      <p className="text-sm font-semibold text-green-900">
                        {Math.max(
                          ...stats.callLengths.map(call => call.length),
                        )}
                        {' '}
                        min
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </span>
      </main>

      {/* Footer */}
      <footer className="bg-white border-t border-gray-200 mt-5">
        <div className="max-w-10xl mx-auto px-2.5 sm:px-4 lg:px-5 py-4 flex justify-between items-center">
          <p className="text-xs text-gray-500">
            © 2023 Sears Dashboard. All rights reserved.
          </p>
          <div className="flex items-center space-x-2.5">
            <button className="text-xs text-gray-500 hover:text-gray-700">
              Privacy Policy
            </button>
            <button className="text-xs text-gray-500 hover:text-gray-700">
              Terms of Service
            </button>
          </div>
        </div>
      </footer>

      {/* Live Indicator */}
      <div className="fixed bottom-2.5 right-2.5 flex items-center bg-white rounded-full px-2 py-0.5 shadow-md border border-gray-300">
        <div className="animate-pulse mr-1.5">
          <div className="w-2 h-2 bg-green-500 rounded-full"></div>
        </div>
        <span className="text-xs font-medium text-gray-700">Live</span>
      </div>
    </div>
  )
}

export default SearsInteractiveDashboard
