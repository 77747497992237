import React, { useState, useRef, useEffect, useContext } from 'react';
import { Modal, Button, ModalContent, ModalHeader, ModalBody, ModalFooter, Tooltip } from '@heroui/react';
import { toast } from 'react-toastify';
import { useOnClickOutside } from 'usehooks-ts';
import actionSchemas from './ActionSchemas';
import ActionSchemaForm from './ActionSchemaForm';
import { set } from 'lodash';
import PostCallActionsContext from '../PostCallActionsContext';
import { Plus, Trash, ChevronDown, ChevronUp } from 'lucide-react';
import LoadSpinner from 'components/core/LoadSpinner'

const TreePostCallActionsModal = ({ 
  isOpen, 
  onClose, 
  onSave
}) => {
  const {
    postCallActions = [],  
    setIsSaving,
    userIntegrations,
    loadingIntegrations,
  } = useContext(PostCallActionsContext);
  
  
  
  const [localPostCallActions, setLocalPostCallActions] = useState(postCallActions || []);
  const [showActionDropdown, setShowActionDropdown] = useState(false);
  const [selectedActionIndex, setSelectedActionIndex] = useState(null);
  const actionDropdownRef = useRef(null);

  useOnClickOutside(actionDropdownRef, () => {
    setShowActionDropdown(false);
  });

  const isIntegrationEnabled = (integrationKey) => {
    if (!integrationKey) return true;
    return userIntegrations.some(
      connection => connection.provider_config_key === integrationKey
    );
  };

  const getActionSchemaForAction = (action) => {
    if (action?.id) {
      const foundSchemaById = actionSchemas.find(schema => schema.id === action.id);
      if (foundSchemaById) return foundSchemaById;
    }
    return null;
  };
  
  useEffect(() => {
    const updatedActions = postCallActions.map(action => {
      if (action.requiredIntegration !== undefined) {
        return action;
      }
      
      const schema = getActionSchemaForAction(action);
      if (schema) {
        return {
          ...action,
          requiredIntegration: schema.requiredIntegration
        };
      }
      
      return action;
    });
    
    setLocalPostCallActions(updatedActions);
  }, [postCallActions]);

  const getFilteredActions = () => {
    const currentActionIds = localPostCallActions.map(action => {
      const schema = getActionSchemaForAction(action);
      return schema ? schema.id : null;
    }).filter(Boolean);
    
    return actionSchemas.filter(action => 
      !currentActionIds.includes(action.id) && isIntegrationEnabled(action.requiredIntegration)
    );
  };

  const addPostCallAction = (actionId) => {
    const actionSchema = actionSchemas.find(action => action.id === actionId);
    if (!actionSchema) return;
    
    const newAction = {
      ...JSON.parse(JSON.stringify(actionSchema.config)),
      id: actionSchema.id,
      requiredIntegration: actionSchema.requiredIntegration
    };
    
    if (actionSchema.fields) {
      actionSchema.fields.forEach(field => {
        if (field.defaultValue !== undefined) {
          set(newAction, field.path, field.defaultValue);
        }
      });
    }
    
    setLocalPostCallActions([...localPostCallActions, newAction]);
    setShowActionDropdown(false);
    setSelectedActionIndex(localPostCallActions.length);
  };

  const removeAction = (index) => {
    const newActions = [...localPostCallActions];
    newActions.splice(index, 1);
    setLocalPostCallActions(newActions);
    setSelectedActionIndex(null);
  };

  const toggleActionExpansion = (index) => {
    if (selectedActionIndex === index) {
      setSelectedActionIndex(null);
    } else {
      setSelectedActionIndex(index);
    }
  };

  const handleSave = () => {
    const formattedActions = localPostCallActions.map(action => {
      const formattedAction = {...action};
      
      if (!formattedAction.url || !formattedAction.method) {
        toast.error(`Action "${formattedAction.name || 'unknown'}" is missing required fields`);
        return null;
      }
      
      if (!formattedAction.requiredIntegration) {
        const schema = getActionSchemaForAction(formattedAction);
        if (schema) {
          formattedAction.requiredIntegration = schema.requiredIntegration;
        }
      }
      
      const uiProps = ['_editing', '_expanded', '_selected'];
      uiProps.forEach(prop => {
        if (formattedAction.hasOwnProperty(prop)) {
          delete formattedAction[prop];
        }
      });
      
      return formattedAction;
    }).filter(Boolean);
    
    if (formattedActions.length === localPostCallActions.length) {
      setIsSaving(true);
      onSave(formattedActions);
      onClose();
    }
  };

  const filteredActions = getFilteredActions();

  if (!isOpen) return null;

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalContent>
        <ModalHeader>
          <div className="text-lg font-semibold">Pathway Post Call Actions</div>
        </ModalHeader>
        
        <ModalBody>
          <div className="space-y-4 mt-4">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-medium">Actions</h3>
              <div className="relative" ref={actionDropdownRef}>
                <Button 
                  onPress={() => setShowActionDropdown(!showActionDropdown)}
                  disabled={filteredActions.length === 0}
                  variant="flat"
                  color="primary"
                  radius="sm"
                  size="sm"
                  startContent={<Plus className="h-4 w-4" />}
                >
                  Add Action
                </Button>
                
                {showActionDropdown && filteredActions.length > 0 && (
                  <div className="absolute right-0 mt-2 w-72 bg-white border border-gray-300 rounded-md shadow-lg z-50">
                    {filteredActions.map(action => (
                      <div 
                        key={action.id} 
                        className="p-3 hover:bg-gray-100 cursor-pointer border-b last:border-b-0 flex flex-col"
                        onClick={() => addPostCallAction(action.id)}
                      >
                        <div className="font-medium">{action.name}</div>
                        <div className="text-xs text-foreground-400 mt-1">{action.description}</div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>

            {loadingIntegrations && (
              <div className="flex justify-center items-center py-4">
                <LoadSpinner />
              </div>
            )}

            {!loadingIntegrations && localPostCallActions.length === 0 && (
              <div className="text-center py-4 text-gray-500">
                No post call actions configured. Add an action to execute after a call is completed.
              </div>
            )}

            {localPostCallActions.map((action, index) => {
              const actionSchema = getActionSchemaForAction(action);
              const isExpanded = selectedActionIndex === index;
              
              return (
                <div 
                  key={index} 
                  className={`border rounded-md p-4 ${isExpanded ? 'border-blue-500' : 'border-gray-300'}`}
                >
                  <div 
                    className="flex justify-between items-center cursor-pointer"
                    onClick={() => toggleActionExpansion(index)}
                  >
                    <div className="flex items-center">
                      {isExpanded ? 
                        <ChevronUp className="h-4 w-4 mr-2 text-blue-500 flex-shrink-0" /> : 
                        <ChevronDown className="h-4 w-4 mr-2 text-gray-400 flex-shrink-0" />
                      }
                      <div>
                        <h4 className="font-medium">{action.name}</h4>
                        <p className="text-sm text-gray-600">{action.description}</p>
                      </div>
                    </div>
                    <div className="flex items-center" onClick={(e) => e.stopPropagation()}>
                      <Tooltip content="Remove">
                        <Button 
                          isIconOnly
                          variant="light"
                          color="danger"
                          size="sm"
                          aria-label="Remove"
                          onPress={(e) => {
                            if (e && e.stopPropagation) {
                              e.stopPropagation();
                            }
                            removeAction(index);
                          }}
                        >
                          <Trash className="h-4 w-4" />
                        </Button>
                      </Tooltip>
                    </div>
                  </div>

                  {isExpanded && (
                    <div className="mt-4">
                      {actionSchema ? (
                        <ActionSchemaForm 
                          actionSchema={actionSchema}
                          initialValues={action}
                          onUpdate={(data) => {
                            const newActions = [...localPostCallActions];
                            newActions[index] = { 
                              ...newActions[index],
                              ...data
                            };
                            setLocalPostCallActions(newActions);
                          }}
                        />
                      ) : (
                      <></>
                      )}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </ModalBody>

        <ModalFooter>
          <Button 
            variant="flat" 
            color="default" 
            onPress={onClose} 
            size="sm"
            className="mr-2"
          >
            Cancel
          </Button>
          <Button 
            variant="solid"
            color="primary"
            onPress={handleSave}
            size="sm"
          >
            Save Actions
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default TreePostCallActionsModal; 