import { Dialog, Transition } from '@headlessui/react'
import { useAuth } from 'hooks/useAuth'
import { Copy, Loader, User } from 'lucide-react'
import React, { Fragment } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { $fetch } from 'utils/fetch'
import { getOrgId } from 'utils/funcs/browser/getOrgId'
import PathwayFlow from './pathway_flow'

function PathwayModal({ show, onClose, pathway }) {
  const orgId = getOrgId()
  const [prevPathwayID, setPrevPathwayID] = React.useState(null)
  const [isLoading, setIsLoading] = React.useState(false)
  const [isCloned, setIsCloned] = React.useState(false)
  const navigate = useNavigate()
  const tagColors = {
    'Customer Service': 'bg-indigo-100 text-indigo-800 border-indigo-300',
    'AI': 'bg-blue-100 text-blue-800 border-blue-300',
    'Support': 'bg-purple-100 text-purple-800 border-purple-300',
    'Sales': 'bg-yellow-100 text-yellow-800 border-yellow-300',
    'Booking': 'bg-pink-100 text-pink-800 border-pink-300',
    'FAQ': 'bg-indigo-200 text-indigo-900 border-indigo-400',
    'Knowledge Base': 'bg-gray-100 text-gray-800 border-gray-300',
    'Lead Qualification': 'bg-cyan-100 text-cyan-800 border-cyan-300',
  }
  const { user } = useAuth()

  const handleClone = async () => {
    if (!user) {
      toast.warn('Please login to clone the pathway.', {
        autoClose: 3000,
      })
      return
    }

    setIsLoading(true)
    try {
      const response = await $fetch(`/v1/convo_pathway/clone`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ pathway_id: pathway.pathway_id }),
        ignoreResponseError: true,
      })

      if (!response.errors) {
        toast.success('Pathway cloned successfully', {
          autoClose: 3000,
        })

        const new_pathway_id = response.data.pathway_id

        setPrevPathwayID(new_pathway_id)
        setIsCloned(true)
      }
      else {
        toast.error('Failed to clone pathway', {
          autoClose: 3000,
        })
      }
    }
    catch (error) {
      toast.error('An error occurred while cloning the pathway', {
        autoClose: 3000,
      })
    }
    finally {
      setIsLoading(false)
    }
  }

  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={onClose}
      >
        <div className="min-h-screen px-2.5 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-50" />
          </Transition.Child>

          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-[80%] p-4 my-5 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-xl">
              <div className="flex justify-between items-center">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-4 text-gray-900"
                >
                  {pathway.title}
                </Dialog.Title>
                {/* <button
                  type="button"
                  className="text-gray-400"
                  onClick={onStar}
                >
                  <Star size={20} className={pathway.stars > 0 ? 'text-yellow-400' : 'text-gray-400'} />
                </button> */}
              </div>
              <div className="mt-1.5 flex gap-1.5">
                <User size={16} className="text-gray-400" />
                <p className="text-[12px] text-gray-600">{pathway.author}</p>
              </div>
              <div className="mt-2.5">
                <div className="flex justify-between items-center">
                  <div>
                    <div className="flex flex-wrap gap-1.5 mt-1.5">
                      {pathway.tags.map((tag, index) => (
                        <span
                          key={index}
                          className={`text-[11px] px-1.5 py-0.5 rounded-full border ${tagColors[tag] || 'bg-gray-200 text-gray-700 border-gray-300'}`}
                        >
                          {tag}
                        </span>
                      ))}
                    </div>
                  </div>
                  {user
                    ? (
                        isCloned
                          ? (
                              <button
                                onClick={() => {
                                  navigate(
                                    `/dashboard/convo-pathways?id=${prevPathwayID}`,
                                  )
                                }}
                                className="inline-flex justify-center px-2.5 py-1.5 text-[12px] font-medium text-green-500 bg-green-100 border border-gray-200 rounded hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-green-500 gap-1.5"
                              >
                                View Cloned Pathway
                              </button>
                            )
                          : (
                              <button
                                type="button"
                                disabled={isLoading}
                                className="inline-flex justify-center px-2.5 py-1.5 text-[12px] font-medium text-indigo-900 bg-indigo-100 border border-transparent rounded hover:bg-indigo-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-indigo-500 gap-1.5 disabled:opacity-50 disabled:cursor-not-allowed"
                                onClick={handleClone}
                              >
                                {isLoading
                                  ? (
                                      <>
                                        <Loader size={16} className="animate-spin" />
                                        Cloning...
                                      </>
                                    )
                                  : (
                                      <>
                                        <Copy size={16} />
                                        Clone Pathway
                                      </>
                                    )}
                              </button>
                            )
                      )
                    : (
                        <a
                          href="/signup"
                          className="inline-flex justify-center px-2.5 py-1.5 text-[12px] font-medium text-gray-700 bg-gray-100 border border-gray-300 rounded hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-gray-500 gap-1.5"
                        >
                          <Copy size={16} />
                          Sign Up to Clone
                        </a>
                      )}
                </div>
              </div>
              <div className="mt-2.5">
                <p className="text-[12px] text-gray-500">
                  {pathway.description}
                </p>
              </div>

              <hr className="my-2.5 border-gray-200" />

              <div className="mt-2.5 h-[60vh] mx-auto">
                <PathwayFlow nodes={pathway.nodes} edges={pathway.edges} />
              </div>

              <div className="mt-2.5 flex justify-end">
                <button
                  type="button"
                  className="inline-flex justify-center px-2.5 py-1.5 text-[12px] font-medium text-gray-700 bg-gray-100 border border-transparent rounded hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-gray-500"
                  onClick={onClose}
                >
                  Close
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}

export default PathwayModal
