import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog'
import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'
import { Textarea } from '@/components/ui/textarea'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip'
import { Tab, Tabs } from '@heroui/react'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import ActionBar from 'components/core/ActionBar'
import DragDropUpload from 'components/core/DragDropUpload'
import { PageTitle } from 'components/core/PageTitle.js'
import { PageWrapper } from 'components/core/PageWrapper.js'
import GradientLoadingAnimation from 'components/Reusables/GradientLoadingAnimation'
import { formatDistanceToNow } from 'date-fns'
import {
  ArrowUpDown,
  ChevronLeft,
  ChevronRight,
  EllipsisVertical,
  FolderMinus,
  Pencil,
  Trash2,
  Upload,
} from 'lucide-react'
import { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { $fetch } from 'utils/fetch'
import { z } from 'zod'
import ProcessModal from './KnowledgeBases/ProcessModal'
import MemoryStores from './MemoryStores/MemStore'

const kbCache = new Map()

async function fetchKnowledgeBase(id) {
  if (kbCache.has(id)) {
    return kbCache.get(id)
  }

  const { data } = await $fetch(`/v1/knowledgebases/${id}`, {
    headers: {
      'include-text': true,
    },
  })
  kbCache.set(id, data)
  return data
}

function DeleteKnowledgeBase({ knowledgeBase, onSuccess, open, setOpen }) {
  const [isDeleting, setIsDeleting] = useState(false)

  const handleDelete = async () => {
    setIsDeleting(true)
    try {
      await $fetch(`/v1/knowledgebases/${knowledgeBase.vector_id}`, {
        method: 'DELETE',
      })
      toast.success('Knowledge base deleted successfully')
      onSuccess()
    }
    catch (error) {
      console.error('Failed to delete knowledge base:', error)
      toast.error(error.message || 'Failed to delete knowledge base')
    }
    finally {
      setIsDeleting(false)
      setOpen(false)
    }
  }

  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogContent className="sm:max-w-[425px]">
        <AlertDialogHeader>
          <AlertDialogTitle>Delete Knowledge Base?</AlertDialogTitle>
          <AlertDialogDescription>
            This action cannot be undone. This will permanently delete the
            knowledge base "
            {knowledgeBase.name}
            " and remove all associated
            data.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction
            onClick={handleDelete}
            className="bg-red-600 hover:bg-red-700"
            disabled={isDeleting}
          >
            {isDeleting ? 'Deleting...' : 'Delete'}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}

function EditKnowledgeBase({ onSuccess, knowledgeBase, open, setOpen }) {
  const [isLoading, setIsLoading] = useState(true)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const form = useForm({
    defaultValues: {
      name: knowledgeBase.name,
      description: knowledgeBase.description,
      text: '',
    },
  })

  useEffect(() => {
    async function loadFullKB() {
      try {
        const fullKB = await fetchKnowledgeBase(knowledgeBase.vector_id)
        form.reset({
          name: fullKB.name,
          description: fullKB.description,
          text: fullKB.text,
        })
      }
      catch (error) {
        console.error('Failed to fetch full knowledge base:', error)
        toast.error('Failed to load knowledge base content')
      }
      finally {
        setIsLoading(false)
      }
    }

    if (open) {
      loadFullKB()
    }
  }, [open, knowledgeBase.vector_id])

  const onSubmit = async (values) => {
    setIsSubmitting(true)
    try {
      await $fetch(`/v1/knowledgebases/${knowledgeBase.vector_id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      })
      setOpen(false)
      onSuccess()
    }
    catch (error) {
      console.error('Failed to update knowledge base:', error)
    }
    finally {
      setIsSubmitting(false)
    }
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className="sm:max-w-[600px]">
        <DialogHeader>
          <DialogTitle>Edit Knowledge Base</DialogTitle>
          <DialogDescription>
            Update the name, description, and text content of your knowledge
            base.
          </DialogDescription>
        </DialogHeader>
        {isLoading
          ? (
              <div className="flex justify-center py-5">
                <GradientLoadingAnimation
                  message="Loading content..."
                  variant="roseGarden"
                  rounded="full"
                />
              </div>
            )
          : (
              <Form {...form}>
                <form
                  onSubmit={form.handleSubmit(onSubmit)}
                  className="w-full space-y-4 mt-5"
                >
                  <FormField
                    control={form.control}
                    name="name"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Name</FormLabel>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="description"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Description</FormLabel>
                        <FormControl>
                          <Textarea {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="text"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Text</FormLabel>
                        <FormControl>
                          <Textarea {...field} className="min-h-60" />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <DialogFooter>
                    <Button variant="outline" onClick={() => setOpen(false)}>
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      disabled={isSubmitting}
                      className="bg-blue-500 hover:bg-blue-600"
                    >
                      {isSubmitting ? 'Saving...' : 'Save changes'}
                    </Button>
                  </DialogFooter>
                </form>
              </Form>
            )}
      </DialogContent>
    </Dialog>
  )
}

function UploadSidebar({ onSuccess }) {
  const [uploadedFile, setUploadedFile] = useState(null)
  const [uploadComplete, setUploadComplete] = useState(false)
  const [openProcess, setOpenProcess] = useState(false)

  const form = useForm({
    defaultValues: {
      name: '',
      description: '',
      file: null,
    },
    resolver: zodResolver(
      z.object({
        name: z.string().min(1, 'Name is required'),
        description: z.string(),
        file: z.any().refine(file => file !== null, 'File is required'),
      }),
    ),
  })

  const handleFileChange = (files) => {
    setUploadedFile(files[0])
    form.setValue('file', files[0])
  }

  const onSubmit = async (values) => {
    setOpenProcess(true)

    try {
      const form = new FormData()
      form.append('file', uploadedFile)
      form.append('name', values.name)
      form.append('description', values.description)

      const endpoint
        = uploadedFile.type === 'application/pdf'
          || uploadedFile.type === 'text/plain'
          || uploadedFile.type === 'application/msword'
          || uploadedFile.type
          === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
          ? '/upload'
          : '/upload-media'

      const { data } = await $fetch(`/v1/knowledgebases${endpoint}`, {
        method: 'POST',
        body: form,
      })

      if (data) {
        toast.success('File successfully uploaded!')
      }
    }
    catch (error) {
      console.error(error)
      toast.error(error.message || 'Error uploading file.')
    }
    finally {
      setUploadComplete(true)
    }
  }

  return (
    <div className="flex flex-col gap-1.5 items-start min-w-[450px] h-full p-8">
      <h3 className="scroll-m-12 text-lg font-semibold tracking-tight">
        Upload Knowledge Base
      </h3>
      <p className="text-xs text-muted-foreground">
        Upload a file to be vectorized and used by your agents.
      </p>

      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="w-full space-y-4 mt-5"
        >
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Name</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="description"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Description</FormLabel>
                <FormControl>
                  <Textarea {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="file"
            render={({ field }) => (
              <FormItem>
                <FormLabel>File</FormLabel>
                <FormControl>
                  <DragDropUpload
                    label="File Upload"
                    onFilesAdded={handleFileChange}
                    uploadedFiles={uploadedFile ? [uploadedFile] : []}
                    multiple={false}
                    accept={{
                      'application/pdf': [],
                      'audio/mp3': [],
                      'audio/mpeg': [],
                      'video/mp4': [],
                      'text/plain': [],
                      'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                        [],
                    }}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </form>
      </Form>

      <Button
        onClick={form.handleSubmit(onSubmit)}
        color="iris"
        className="rounded-md self-end mt-8 bg-blue-500 hover:bg-blue-600"
        disabled={!uploadedFile}
      >
        <Upload size={12} className="mr-1.5" />
        Upload file
      </Button>
      {openProcess && (
        <ProcessModal
          open={openProcess}
          setOpen={setOpenProcess}
          uploadComplete={uploadComplete}
          onRefresh={onSuccess}
          mock={false}
        />
      )}
    </div>
  )
}

function TextCell({ row }) {
  const [showFullText, setShowFullText] = useState(false)
  const [fullText, setFullText] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const text = row.original.text ?? row.original.input_text

  const handleShowFullText = async () => {
    setShowFullText(true)
    if (!fullText) {
      setIsLoading(true)
      try {
        const fullKB = await fetchKnowledgeBase(row.original.vector_id)
        setFullText(fullKB.text)
      }
      catch (error) {
        console.error('Failed to fetch full text:', error)
        toast.error('Failed to load full text')
      }
      finally {
        setIsLoading(false)
      }
    }
  }

  return (
    <>
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <div
              role="button"
              onClick={handleShowFullText}
              className="line-clamp-2 min-w-0 max-w-full font-mono tracking-wide text-gray-600 cursor-pointer hover:text-gray-900"
            >
              {text}
            </div>
          </TooltipTrigger>
          <TooltipContent>
            <p>View full text</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>

      <Dialog open={showFullText} onOpenChange={setShowFullText}>
        <DialogContent className="max-w-5xl max-h-[80vh] overflow-y-auto">
          {isLoading
            ? (
                <div className="flex justify-center py-5">
                  <GradientLoadingAnimation
                    message="Loading content..."
                    variant="roseGarden"
                    rounded="full"
                  />
                </div>
              )
            : (
                <div className="font-mono whitespace-pre-wrap">
                  {fullText || text}
                </div>
              )}
        </DialogContent>
      </Dialog>
    </>
  )
}

function DataTable({ data, onRefresh }) {
  const [globalFilter, setGlobalFilter] = useState('')
  const [sorting, setSorting] = useState([])
  const [knowledgeBase, setKnowledgeBase] = useState(null)
  const [openEdit, setOpenEdit] = useState(false)
  const [openDelete, setOpenDelete] = useState(false)
  const columns = [
    {
      accessorKey: 'name',
      header: 'Name',
      cell: ({ row }) => (
        <div className="font-medium whitespace-nowrap">{row.original.name}</div>
      ),
    },
    {
      accessorKey: 'description',
      header: 'Description',
      cell: ({ row }) => (
        <div className="truncate max-w-[200px]">{row.original.description}</div>
      ),
    },
    {
      accessorKey: 'created_at',
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          >
            Created At
            <ArrowUpDown className="ml-1.5 h-2.5 w-2.5" />
          </Button>
        )
      },
      cell: ({ row }) => (
        <div className="whitespace-nowrap">
          {formatDistanceToNow(new Date(row.original.created_at), {
            addSuffix: true,
          })}
        </div>
      ),
    },
    {
      id: 'actions',
      cell: ({ row }) => (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" size="icon">
              <EllipsisVertical size={16} />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem
              onClick={() => {
                setKnowledgeBase(row.original)
                setOpenEdit(true)
              }}
            >
              <Pencil className="mr-0.5 h-2.5 w-2.5" />
              Edit
            </DropdownMenuItem>
            <DropdownMenuItem
              onClick={() => {
                setKnowledgeBase(row.original)
                setOpenDelete(true)
              }}
              className="text-red-600"
            >
              <Trash2 className="mr-0.5 h-2.5 w-2.5" />
              Delete
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      ),
    },
  ]

  const tableData = data?.vectors ?? []

  const table = useReactTable({
    data: tableData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    state: {
      globalFilter,
      sorting,
    },
  })

  return (
    <div className="w-full min-w-0 p-8">
      {table.getRowModel().rows?.length
        ? (
            <>
              <Input
                placeholder="Search knowledge bases..."
                value={globalFilter ?? ''}
                onChange={event => setGlobalFilter(event.target.value)}
                className="max-w-sm mb-2.5"
              />

              <div className="rounded-lg border">
                <Table>
                  <TableHeader>
                    {table.getHeaderGroups().map(headerGroup => (
                      <TableRow key={headerGroup.id}>
                        {headerGroup.headers.map(header => (
                          <TableHead key={header.id}>
                            {header.isPlaceholder
                              ? null
                              : flexRender(
                                  header.column.columnDef.header,
                                  header.getContext(),
                                )}
                          </TableHead>
                        ))}
                      </TableRow>
                    ))}
                  </TableHeader>
                  <TableBody>
                    {table.getRowModel().rows.map(row => (
                      <TableRow key={row.id}>
                        {row.getVisibleCells().map(cell => (
                          <TableCell key={cell.id}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext(),
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>

              <div className="flex justify-end py-2.5">
                <div className="flex items-center space-x-1.5">
                  <Button
                    variant="outline"
                    size="sm"
                    onClick={() => table.previousPage()}
                    disabled={!table.getCanPreviousPage()}
                  >
                    <ChevronLeft className="h-2.5 w-2.5" />
                  </Button>
                  <div className="flex w-[100px] items-center justify-center text-xs font-medium">
                    Page
                    {' '}
                    {table.getState().pagination.pageIndex + 1}
                    {' '}
                    of
                    {' '}
                    {table.getPageCount()}
                  </div>
                  <Button
                    variant="outline"
                    size="sm"
                    onClick={() => table.nextPage()}
                    disabled={!table.getCanNextPage()}
                  >
                    <ChevronRight className="h-2.5 w-2.5" />
                  </Button>
                </div>
              </div>
              {openEdit && (
                <EditKnowledgeBase
                  onSuccess={onRefresh}
                  knowledgeBase={knowledgeBase}
                  open={openEdit}
                  setOpen={setOpenEdit}
                />
              )}
              {openDelete && (
                <DeleteKnowledgeBase
                  onSuccess={onRefresh}
                  knowledgeBase={knowledgeBase}
                  open={openDelete}
                  setOpen={setOpenDelete}
                />
              )}
            </>
          )
        : (
            <div className="w-full h-full flex flex-col items-center gap-5 mt-40">
              <FolderMinus size={64} className="text-gray-800" />
              <h1 className="scroll-m-12 text-3xl tracking-tighter font-semibold">
                No knowledge bases yet.
              </h1>
              <p className="text-sm text-gray-600">Upload a file to get started.</p>
            </div>
          )}
    </div>
  )
}

export default function KnowledgeBases() {
  const [knowledgeBases, setKnowledgeBases] = useState({ vectors: [] })
  const [loading, setLoading] = useState(false)
  const [selectedTab, setSelectedTab] = useState('knowledgeBases')

  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams()

  // Check URL path to set initial tab
  useEffect(() => {
    if (location.pathname.includes('/memory')) {
      setSelectedTab('memoryStores')
    }
    else {
      setSelectedTab('knowledgeBases')
    }
  }, [location.pathname])

  const fetchKnowledgeBases = async () => {
    setLoading(true)
    try {
      const { data } = await $fetch('/v1/knowledgebases')

      if (data?.vectors) {
        setKnowledgeBases(data)
      }
    }
    catch (error) {
      console.error(error)
    }
    finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchKnowledgeBases()
  }, [])

  return (
    <PageWrapper padding="54px 0px 0px 84px">
      <ActionBar top>
        <div className="flex justify-between items-center w-full">
          <div className="flex-1">
            <PageTitle>{selectedTab === 'knowledgeBases' ? 'Knowledge Bases' : 'Memory Stores'}</PageTitle>
          </div>
          <div className="flex-1 flex justify-center">
            <Tabs
              selectedKey={selectedTab}
              defaultSelectedKey="knowledgeBases"
              onSelectionChange={(key) => {
                setSelectedTab(key)
                if (key === 'memoryStores') {
                  navigate('/dashboard/memory')
                }
                else {
                  navigate('/dashboard/knowledge-bases')
                }
              }}
              radius="sm"
            >
              <Tab title="Knowledge Bases" key="knowledgeBases" />
              <Tab title="Memory Store" key="memoryStores" />
            </Tabs>
          </div>
          <div className="flex-1"></div>
        </div>
      </ActionBar>

      {selectedTab === 'knowledgeBases' && (
        loading
          ? (
              <GradientLoadingAnimation
                message="Loading Knowledge Bases"
                variant="roseGarden"
                rounded="full"
              />
            )
          : (
              <div className="flex flex-row w-full h-full divide-x-2 divide-gray-100 gap-8">
                <div className="flex flex-col min-w-0 w-full">
                  <DataTable data={knowledgeBases} onRefresh={fetchKnowledgeBases} />
                </div>
                <div className="shrink-0">
                  <UploadSidebar onSuccess={fetchKnowledgeBases} />
                </div>
              </div>
            )
      )}

      {selectedTab === 'memoryStores' && (
        <div className="h-screen" style={{ overflow: 'hidden' }}>
          <MemoryStores />
        </div>
      )}
    </PageWrapper>
  )
}
