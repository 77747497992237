
const FieldType = {
  TEXT: 'text',
  TEXTAREA: 'textarea',
};

/**
 * Action schemas define:
 * - id: unique identifier for the action
 * - name: display name
 * - description: description of what the action does
 * - requiredIntegration: which integration provider is needed
 * - config: API configuration that matches the expected backend format
 * - fields: form fields for configuring the action
 */
const actionSchemas = [
  {
    id: 'slack_summary',
    name: 'Send Summary via Slack',
    description: 'Send a summary of the call to a Slack channel',
    requiredIntegration: 'slack',
    config: {
      url: 'https://slack.com/api/chat.postMessage',
      method: 'POST',
      name: 'Send Summary via Slack',
      description: 'Send a summary of the call to a Slack channel',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer {{INTEGRATIONSECRET.slack}}'
      },
      body: {
        channel: "", 
        text: "Call summary: {{summary}}"
      },
    },
    // Fields define the UI components to render for this action
    fields: [
      {
        id: 'channel',
        path: 'body.channel',
        label: 'Slack Channel',
        type: FieldType.TEXT,
        placeholder: 'C08EN0FQ7FY',
        description: 'The Slack channel ID to post to',
        required: true,
      },
      {
        id: 'message_template',
        path: 'body.text',
        label: 'Message Template',
        type: FieldType.TEXTAREA,
        placeholder: 'Call summary: {{summary}}',
        description: 'The message template to use. You can extract call data variables with {{summary}} format.',
        required: true,
        defaultValue: 'Call summary: {{summary}}',
      },
    ],
  },

//   commenting out for now
//   {
//     id: 'hubspot_call',
//     name: 'Log Call in HubSpot',
//     description: 'Create a call record in HubSpot CRM',
//     requiredIntegration: 'hubspot',
//     config: {
//       url: 'https://api.hubapi.com/crm/v3/objects/calls',
//       method: 'POST',
//       name: 'Log Call in HubSpot',
//       description: 'Create a call record in HubSpot CRM with call details',
//       timeout: 10000,
//       public: false,
//       speech: "Logging this call in HubSpot CRM",
//       headers: {
//         'Content-Type': 'application/json',
//         'Authorization': 'Bearer {{INTEGRATIONSECRET.hubspot}}'
//       },
//       body: {
//         properties: {
//           hs_timestamp: "{{timestamp}}",
//           hs_call_title: "",
//           hs_call_body: "{{summary}}",
//           hs_call_duration: "{{call_duration}}",
//           hs_call_direction: "OUTBOUND",
//           hs_call_disposition: "COMPLETED",
//           hs_call_status: "COMPLETED"
//         },
//         associations: [
//           {
//             to: {
//               id: ""
//             },
//             types: [
//               {
//                 associationCategory: "HUBSPOT_DEFINED",
//                 associationTypeId: 339
//               }
//             ]
//           }
//         ]
//       },
//       query: {},
//       input_schema: {
//         examples: {
//           hs_call_title: "Conversation with Client",
//           hs_call_body: "Discussed project updates and next steps.",
//           contactId: "51",
//           hs_call_duration: "600"
//         },
//         required: [
//           "hs_call_title"
//         ],
//         properties: {
//           hs_call_title: "string",
//           hs_call_body: "string",
//           contactId: "string",
//           hs_call_duration: "string"
//         }
//       }
//     },
//     fields: [
//       {
//         id: 'title',
//         path: 'body.properties.hs_call_title',
//         label: 'Call Title',
//         type: FieldType.TEXT,
//         placeholder: 'Conversation with {{customer_name}}',
//         required: true,
//         description: 'Title of the call record in HubSpot',
//         defaultValue: '',
//       },
//       {
//         id: 'body',
//         path: 'body.properties.hs_call_body',
//         label: 'Call Notes',
//         type: FieldType.TEXTAREA,
//         placeholder: '{{summary}}',
//         required: true,
//         description: 'Summary and notes from the call',
//         defaultValue: '{{summary}}',
//         rows: 3,
//       },
//       {
//         id: 'contactId',
//         path: 'body.associations[0].to.id',
//         label: 'Contact ID',
//         type: FieldType.TEXT,
//         placeholder: '{{contact_id}}',
//         required: true,
//         description: 'HubSpot contact ID to associate with this call',
//         defaultValue: '',
//       },
//       {
//         id: 'callDirection',
//         path: 'body.properties.hs_call_direction',
//         label: 'Call Direction',
//         type: FieldType.SELECT,
//         options: [
//           { label: 'Outbound', value: 'OUTBOUND' },
//           { label: 'Inbound', value: 'INBOUND' }
//         ],
//         required: true,
//         description: 'Direction of the call',
//         defaultValue: 'OUTBOUND',
//       },
//       {
//         id: 'callStatus',
//         path: 'body.properties.hs_call_status',
//         label: 'Call Status',
//         type: FieldType.SELECT,
//         options: [
//           { label: 'Completed', value: 'COMPLETED' },
//           { label: 'Busy', value: 'BUSY' },
//           { label: 'No Answer', value: 'NO_ANSWER' },
//           { label: 'Connecting', value: 'CONNECTING' }
//         ],
//         required: true,
//         description: 'Status of the call',
//         defaultValue: 'COMPLETED',
//       }
//     ],
//   }
];

// Export both FieldType and actionSchemas
export { FieldType };
export default actionSchemas; 