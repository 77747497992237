import GradientLoadingAnimation from 'components/Reusables/GradientLoadingAnimation'
import { Copy, ExternalLink } from 'lucide-react'
import { FiMinimize2 } from 'react-icons/fi'
import { toast } from 'react-toastify'

export function TranscriptPanel({
  onClose,
  transcripts = [],
  selectedCallId,
  isLoading,
  error,
  onNavigateToCallLogs,
  isCallEnded,
}) {
  const getRoleStyles = (role) => {
    switch (role) {
      case 'assistant':
        return 'bg-blue-50/80 border-l-3 border-blue-400 hover:bg-blue-50'
      case 'user':
        return 'bg-indigo-50/80 border-l-3 border-indigo-400 hover:bg-indigo-50'
      case 'agent-action':
        return 'bg-violet-50/80 border-l-3 border-violet-400 hover:bg-violet-50'
      default:
        return 'bg-white'
    }
  }

  return (
    <div className="h-full flex flex-col bg-white">
      <div className="px-5 py-3 border-b border-slate-200 bg-gradient-to-r from-slate-50 to-white">
        <div className="flex justify-between items-center">
          <div>
            <h3 className="text-base font-semibold bg-gradient-to-r from-slate-800 to-slate-600 bg-clip-text text-transparent">
              Live Transcript
            </h3>
            <div className="flex items-center space-x-2 mt-1.5">
              <p className="text-xs text-slate-600">
                Call ID:
                {' '}
                {selectedCallId}
              </p>
              <button
                onClick={() => {
                  navigator.clipboard.writeText(selectedCallId)
                  toast.success('Call ID copied to clipboard')
                }}
                className="text-slate-400 hover:text-blue-500 transition-all duration-200 hover:scale-110"
              >
                <Copy size={14} />
              </button>
            </div>
          </div>
          <button
            onClick={onClose}
            className="text-slate-500 hover:text-slate-700"
          >
            <FiMinimize2 size={20} />
          </button>
        </div>
      </div>
      <div className="p-5 space-y-4 overflow-y-auto flex-1">
        {isLoading
          ? (
              <div className="flex justify-center items-center h-full">
                <GradientLoadingAnimation
                  duration={10}
                  variant="mintForest"
                  message="Waiting for transcripts..."
                />
              </div>
            )
          : error
            ? (
                <div className="p-2.5 bg-red-50 text-red-700 rounded-lg">{error}</div>
              )
            : selectedCallId && transcripts?.length > 0
              ? (
                  <>
                    {isCallEnded && (
                      <div className="mb-4 p-2.5 bg-gradient-to-r from-slate-100 to-slate-50 rounded-md border border-slate-200 shadow-sm">
                        <p className="text-slate-700 font-mediummb-2">Call ended</p>
                        <button
                          onClick={onNavigateToCallLogs}
                          className="inline-flex items-center gap-1.5 text-blue-600 hover:text-blue-700 transition-colors"
                        >
                          <span>View full call details</span>
                          <ExternalLink size={14} />
                        </button>
                      </div>
                    )}
                    {transcripts.map(transcript => (
                      <div
                        key={transcript.transcript_id}
                        className={`p-3 rounded-md shadow-sm space-y-2 transition-all duration-200 ${getRoleStyles(
                          transcript.role,
                        )}`}
                      >
                        <div className="flex justify-between items-center">
                          <span className="text-xs font-semibold text-slate-700">
                            {transcript.role.charAt(0).toUpperCase()
                            + transcript.role.slice(1)}
                          </span>
                          {transcript.timestamp && (
                            <span className="text-2xs text-slate-500bg-white/50 px-1.5 py-0.5 rounded-full">
                              {new Date(transcript.timestamp).toLocaleTimeString()}
                            </span>
                          )}
                        </div>
                        <p className="text-slate-700 text-smleading-relaxed whitespace-pre-wrap">
                          {transcript.text}
                        </p>
                      </div>
                    ))}
                  </>
                )
              : (
                  <p className="text-slate-500 text-center">
                    No transcripts available yet
                  </p>
                )}
      </div>
    </div>
  )
}
