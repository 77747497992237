import { Badge } from '@/components/ui/badge'
import { Button } from '@/components/ui/button'
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { Input } from '@/components/ui/input'
import { ScrollArea } from '@/components/ui/scroll-area'
import { Tooltip } from '@/components/ui/tooltip'
import {
  CheckCircle2,
  ClipboardCopy,
  Filter,
  RefreshCw,
  RotateCw,
  Search,
  SortAsc,
  SortDesc,
  XCircle,
} from 'lucide-react'
import { useMemo, useState } from 'react'
import { toast } from 'react-toastify'

function StatusBadge({ status }) {
  const variants = {
    running: {
      className: 'bg-blue-50 text-blue-700 border-blue-200',
      icon: <RotateCw className="w-2 h-2 mr-0.5 animate-spin" />,
    },
    failed: {
      className: 'bg-red-50 text-red-700 border-red-200',
      icon: <XCircle className="w-2 h-2 mr-0.5" />,
    },
    pass: {
      className: 'bg-green-50 text-green-700 border-green-200',
      icon: <CheckCircle2 className="w-2 h-2 mr-0.5" />,
    },
  }

  return (
    <Badge
      variant="outline"
      className={`font-medium ${variants[status]?.className}`}
    >
      {variants[status]?.icon}
      {status.charAt(0).toUpperCase() + status.slice(1)}
    </Badge>
  )
}

function CopyableCallId({ callId }) {
  const truncatedId = `${callId.slice(0, 8)}...`

  const copyToClipboard = () => {
    navigator.clipboard.writeText(callId)
    toast.success('Call ID copied to clipboard')
  }

  return (
    <Tooltip content="Click to copy full ID">
      <Button
        variant="ghost"
        size="sm"
        className="font-mono text-2xs px-1.5 py-0.5 h-auto hover:bg-gray-100"
        onClick={copyToClipboard}
      >
        {truncatedId}
        <ClipboardCopy className="w-2 h-2 ml-0.5 text-gray-400" />
      </Button>
    </Tooltip>
  )
}

function RecentRunsTable({
  recentRuns = [],
  loading = false,
  onReplayCall,
  isReplayLoading,
  onRefresh,
}) {
  const [searchTerm, setSearchTerm] = useState('')
  const [statusFilter, setStatusFilter] = useState(new Set())
  const [sortConfig, setSortConfig] = useState({
    key: 'created_at',
    direction: 'desc',
  })

  // Filter and sort the data
  const filteredAndSortedRuns = useMemo(() => {
    const filtered = recentRuns.filter((run) => {
      const matchesSearch = run.test_case_name
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
      const matchesStatus
        = statusFilter.size === 0 || statusFilter.has(run.status)
      return matchesSearch && matchesStatus
    })

    return filtered.sort((a, b) => {
      if (sortConfig.key === 'created_at') {
        const dateA = new Date(a.created_at)
        const dateB = new Date(b.created_at)
        return sortConfig.direction === 'asc' ? dateA - dateB : dateB - dateA
      }
      return 0
    })
  }, [recentRuns, searchTerm, statusFilter, sortConfig])

  const toggleSort = () => {
    setSortConfig(prev => ({
      ...prev,
      direction: prev.direction === 'asc' ? 'desc' : 'asc',
    }))
  }

  const hasRunningTests = recentRuns.some(run => run.status === 'running')

  return (
    <div className="flex flex-col h-full">
      {/* Header */}
      <div className="flex flex-col space-y-2.5 p-2.5 border-b border-gray-200">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-2.5">
            <h2 className="text-xs font-semibold text-gray-900">
              Recent Test Runs
            </h2>
            {hasRunningTests && (
              <div className="flex items-center text-xs text-blue-600">
                <span className="flex space-x-0.5">
                  <span className="animate-[bounce_1.4s_infinite]">•</span>
                  <span className="animate-[bounce_1.4s_infinite_0.2s]">•</span>
                  <span className="animate-[bounce_1.4s_infinite_0.4s]">•</span>
                </span>
                <span className="ml-1.5">Tests in progress</span>
              </div>
            )}
          </div>

          <Button
            variant="outline"
            size="sm"
            onClick={onRefresh}
            disabled={loading}
            className="text-2xs"
          >
            <RefreshCw
              className={`w-2 h-2 mr-0.5 ${loading ? 'animate-spin' : ''}`}
            />
            Refresh
          </Button>
        </div>

        {/* Filters */}
        <div className="flex items-center space-x-2.5">
          <div className="flex-1 max-w-sm">
            <div className="relative">
              <Search className="absolute left-1.5 top-1.5 h-2.5 w-2.5 text-gray-500" />
              <Input
                placeholder="Search by test case name..."
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
                className="pl-5 text-xs"
              />
            </div>
          </div>

          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="outline" size="sm" className="text-2xs">
                <Filter className="w-2 h-2 mr-0.5" />
                Status
                {' '}
                {statusFilter.size > 0 && `(${statusFilter.size})`}
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end" className="w-24">
              {['pass', 'failed', 'running'].map(status => (
                <DropdownMenuCheckboxItem
                  key={status}
                  checked={statusFilter.has(status)}
                  onCheckedChange={(checked) => {
                    const newFilter = new Set(statusFilter)
                    if (checked) {
                      newFilter.add(status)
                    }
                    else {
                      newFilter.delete(status)
                    }
                    setStatusFilter(newFilter)
                  }}
                >
                  {status.charAt(0).toUpperCase() + status.slice(1)}
                </DropdownMenuCheckboxItem>
              ))}
            </DropdownMenuContent>
          </DropdownMenu>

          <Button
            variant="ghost"
            size="sm"
            onClick={toggleSort}
            className="text-2xs"
          >
            {sortConfig.direction === 'asc'
              ? (
                  <SortAsc className="w-2 h-2 mr-0.5" />
                )
              : (
                  <SortDesc className="w-2 h-2 mr-0.5" />
                )}
            Date
          </Button>
        </div>
      </div>

      {/* Table */}
      <ScrollArea className="flex-1">
        <div className="divide-y divide-gray-100">
          {filteredAndSortedRuns.length === 0
            ? (
                <div className="p-5 text-center text-gray-500">
                  No test runs match your filters
                </div>
              )
            : (
                filteredAndSortedRuns.map(run => (
                  <div
                    key={run.call_id}
                    className={`group p-2.5 ${
                      run.status === 'running'
                        ? 'bg-blue-50/50 animate-pulse'
                        : 'hover:bg-gray-50'
                    }`}
                  >
                    <div className="flex items-center justify-between">
                      <div className="flex items-center space-x-2.5 flex-1">
                        <StatusBadge status={run.status} />

                        <div className="flex-1">
                          <h3 className="text-xs font-medium text-gray-900">
                            {run.test_case_name}
                          </h3>
                          <div className="flex items-center space-x-1.5 mt-0.5">
                            <CopyableCallId callId={run.call_id} />
                            <span className="text-2xs text-gray-500">
                              {new Date(run.created_at).toLocaleString()}
                            </span>
                          </div>
                        </div>

                        <Button
                          variant="outline"
                          size="sm"
                          onClick={() => onReplayCall(run.call_id)}
                          disabled={isReplayLoading || run.status === 'running'}
                          className="text-2xs opacity-0 group-hover:opacity-100 transition-opacity"
                        >
                          {isReplayLoading
                            ? (
                                <RotateCw className="w-2 h-2 mr-0.5 animate-spin" />
                              )
                            : (
                                <RefreshCw className="w-2 h-2 mr-0.5" />
                              )}
                          {run.status === 'running' ? 'In Progress' : 'Review'}
                        </Button>
                      </div>
                    </div>
                  </div>
                ))
              )}
        </div>
      </ScrollArea>
    </div>
  )
}

export default RecentRunsTable
