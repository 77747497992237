import styled from 'styled-components'

export function ErrorText({ message }) {
  return <Error>{message}</Error>
}

const Error = styled.p`
  color: red;
  font-size: 0.75rem;
`
