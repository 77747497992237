import { Card, CardBody, CardHeader } from '@heroui/react'
import { Controller } from 'react-hook-form'

export default function NestedForm({
  title,
  fields,
  name,
  className = '',
}) {
  return (
    <Card className={className} radius="sm">
      {title && <CardHeader>{title}</CardHeader>}
      <CardBody className="flex flex-col gap-4">
        {fields?.map((field) => {
          const FieldComponent = field.component
          return (
            <Controller
              key={field.name}
              name={`${name}.${field.name}`}
              render={({ field: controllerField }) => {
                return (
                  <FieldComponent
                    {...field.props}
                    {...controllerField}
                  />
                )
              }}
            />
          )
        })}
      </CardBody>
    </Card>
  )
}
