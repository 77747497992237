import styled from 'styled-components'

export default function ActionBar({
  top,
  sideMenu,
  bottom,
  spaceBetween,
  shiftRight,
  children,
}) {
  return (
    <Bar
      $top={top}
      $bottom={bottom}
      $spaceBetween={spaceBetween}
      $sideMenu={sideMenu}
      $shiftRight={shiftRight}
    >
      {children}
    </Bar>
  )
}

const Bar = styled.div`
  position: fixed;
  bottom: ${({ $bottom }) => ($bottom ? '0px' : 'auto')};
  top: ${({ $top }) => ($top ? '0px' : 'auto')};
  background-color: white;
  z-index: 4000;
  height: 54px;
  width: ${({ $sideMenu }) => ($sideMenu ? '250px' : 'calc(100% - 54px)')};
  display: flex;
  align-items: center;
  justify-content: ${({ $spaceBetween }) =>
    $spaceBetween ? 'space-between' : 'flex-start'};
  border-bottom: ${({ $top }) => ($top ? '1px solid #EEEEEE' : 'none')};
  border-right: ${({ $sideMenu }) =>
    $sideMenu ? '1px solid #EEEEEE' : 'none'};
  border-top: ${({ $bottom }) => ($bottom ? '1px solid #EEEEEE' : 'none')};
  padding: 0px 15px;
  margin-left: ${({ $sideMenu, $shiftRight }) => ($sideMenu ? '54px' : $shiftRight ? '0px' : '-30px')};
`
