import { Accordion, AccordionItem, Button, Chip, Form, Input, Skeleton, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow, Tooltip } from '@heroui/react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import JsonView from '@uiw/react-json-view'
import { Check, Copy, Ghost, RotateCcw, Send } from 'lucide-react'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { $fetch } from 'utils/fetch'

export default function PostcallWebhook({ call }) {
  const queryClient = useQueryClient()
  const { data: webhookData, isLoading } = useQuery({
    queryKey: ['postCallWebhook', call?.c_id],
    queryFn: async () => {
      const { data } = await $fetch(`/v1/postcall/webhooks/${call?.c_id}`)
      return data
    },
    staleTime: Infinity,
    enabled: !!call?.c_id, // Enable fetching when call_id is available
  })

  const sortedMetadata = webhookData?.metadata
    ? [...webhookData.metadata].sort((a, b) => new Date(a.sent_at) - new Date(b.sent_at))
    : []

  const resendMutation = useMutation({
    mutationFn: async () => {
      const { data } = await $fetch('/v1/postcall/webhooks/resend', {
        method: 'POST',
        body: {
          call_ids: [call?.c_id],
        },
      })
      return data?.[0] // endpoint supports multiple call_ids, but we're only sending one
    },
    onSuccess: (callWebhookLogRecord) => {
      queryClient.setQueryData(['postCallWebhook', call?.c_id], callWebhookLogRecord)
    },
    onError: (error) => {
      console.error(error)
      toast.error('Failed to resend webhook request')
    },
  })

  const createMutation = useMutation({
    mutationFn: async (new_url) => {
      const { data } = await $fetch('/v1/postcall/webhooks/create', {
        method: 'POST',
        body: {
          call_ids: [call?.c_id],
          webhook_url: new_url,
        },
      })
      return data?.[0] // endpoint supports multiple call_ids, but we're only sending one
    },
    onSuccess: (callWebhookLogRecord) => {
      queryClient.setQueryData(['postCallWebhook', call?.c_id], callWebhookLogRecord)
    },
    onError: (error) => {
      console.error(error)
      toast.error('Failed to create webhook request')
    },
  })

  const [copied, setCopied] = useState(false)
  const handleCopy = () => {
    navigator.clipboard.writeText(JSON.stringify(webhookData?.payload))
    setCopied(true)
    setTimeout(() => setCopied(false), 2000)
  }

  return (
    <>
      {isLoading
        ? (
            <div className="flex flex-col items-center justify-center gap-4 pt-2">
              <Skeleton className="w-full h-6 rounded" />
              <Skeleton className="w-full h-36 rounded" />
              <Skeleton className="w-full h-36 rounded" />
            </div>
          )
        : webhookData && webhookData?.metadata && webhookData?.metadata?.length > 0
          ? (
              <div className="text-xs [&_*]:text-xs">
                <div className="flex items-center justify-between mb-1.5">
                  <div className="grid gap-2.5 w-full">
                    <div className="flex items-center justify-between">
                      <p className="font-medium">
                        Webhook
                      </p>
                      <Tooltip content="Re-sends webhook request with original payload">
                        <Button
                          startContent={
                            !resendMutation.isPending ? <RotateCcw size={12} /> : <></>
                          }
                          size="sm"
                          color="primary"
                          isLoading={resendMutation.isPending}
                          onPress={() => resendMutation.mutate()}
                        >
                          {resendMutation.isPending ? 'Sending...' : 'Resend'}
                        </Button>
                      </Tooltip>
                    </div>
                    <div className="flex flex-col gap-1.5">
                      <div className="text-muted-foreground">
                        {webhookData?.url}
                      </div>

                    </div>

                    {sortedMetadata?.[0] && (
                      <>
                        <Table aria-label="Latest webhook response">
                          <TableHeader>
                            <TableColumn>Response</TableColumn>
                            <TableColumn>Response Time</TableColumn>
                            <TableColumn>Sent At</TableColumn>
                          </TableHeader>
                          <TableBody>
                            <TableRow>
                              <TableCell>
                                <Chip
                                  radius="sm"

                                  variant="shadow"
                                  color={String(sortedMetadata[0].response_code).startsWith('2') ? 'success' : 'danger'}
                                >
                                  {sortedMetadata[0].response_code}
                                </Chip>
                              </TableCell>
                              <TableCell>{sortedMetadata[0].response_time}</TableCell>
                              <TableCell>
                                {new Date(sortedMetadata[0].sent_at).toLocaleString(undefined, {
                                  month: 'short',
                                  day: 'numeric',
                                  hour: '2-digit',
                                  minute: '2-digit',
                                })}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>

                        {sortedMetadata.length > 1 && (
                          <Accordion
                            type="single"
                            collapsible
                          >
                            <AccordionItem
                              key="history"
                              aria-label="Webhook History"
                              title="Resent Webhook History"
                              value="history"
                            >
                              <Table aria-label="Webhook history">
                                <TableHeader>
                                  <TableColumn>Response</TableColumn>
                                  <TableColumn>Response Time</TableColumn>
                                  <TableColumn>Sent At</TableColumn>
                                </TableHeader>
                                <TableBody>
                                  {sortedMetadata.slice(1).reverse().map(entry => (
                                    <TableRow key={entry.sent_at}>
                                      <TableCell>
                                        <Chip
                                          radius="sm"
                                          variant="shadow"
                                          color={String(entry.response_code).startsWith('2') ? 'success' : 'danger'}
                                        >
                                          {entry.response_code}
                                        </Chip>
                                      </TableCell>
                                      <TableCell>{entry.response_time}</TableCell>
                                      <TableCell>
                                        {new Date(entry.sent_at).toLocaleString(undefined, {
                                          month: 'short',
                                          day: 'numeric',
                                          hour: '2-digit',
                                          minute: '2-digit',
                                        })}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </AccordionItem>
                          </Accordion>
                        )}
                      </>
                    )}

                    <div>
                      <div className="flex items-center justify-between">
                        <p className="font-medium">
                          Payload
                        </p>
                        <Button color="primary" size="sm" onPress={handleCopy}>
                          {copied ? <Check size={12} /> : <Copy size={12} />}
                        </Button>
                      </div>
                      <JsonView
                        value={webhookData?.payload || {}}
                        theme="summerfruit:inverted"
                        style={{ padding: 5, background: '#fff', fontSize: 12 }}
                        enableClipboard={false}
                        displayDataTypes={false}
                        onEdit={false}
                        onAdd={false}
                        onDelete={false}
                        name={false}
                        collapsed={1}
                      />

                    </div>
                  </div>
                </div>
              </div>
            )
          : (
              <div className="flex flex-col items-center justify-center py-5 text-gray-500">
                <Ghost size={48} className="mb-2.5 opacity-50" />
                <p className="text-xs font-medium">This call didn't send a post-call webhook</p>

                <div className=" w-full max-w-md gap-3 flex flex-col mt-1.5">
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault()
                      const data = Object.fromEntries(new FormData(e.currentTarget))
                      createMutation.mutate(data.url)
                    }}
                  >
                    <Input
                      type="url"
                      radius="sm"
                      size="sm"
                      label="Webhook URL"
                      name="url"
                    />
                    <Button
                      color="primary"
                      size="sm"
                      startContent={<Send size={12} />}
                      type="submit"
                      className="w-full"
                      isLoading={createMutation.isPending}
                    >
                      Send Post Call Webhook
                    </Button>
                  </Form>

                </div>
              </div>
            )}
    </>
  )
}
