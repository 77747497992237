import { useQuery } from '@tanstack/react-query'
import { $fetch } from 'utils/fetch'

/**
 * Custom hook to fetch SMS conversations data
 * 
 * @param {Object} options - Query options
 * @param {number} options.currPage - Current page number
 * @param {number} options.pageSize - Number of items per page
 * @param {Array} options.filters - Array of filter objects
 * @param {string} options.sortBy - Field to sort by
 * @param {string} options.sortDir - Sort direction ('asc' or 'desc')
 * @param {boolean} options.enabled - Whether to enable the query
 * @returns {Object} Query result object
 */
export function useSMSConversations({ currPage, pageSize, filters, sortBy, sortDir, enabled = true }) {
  return useQuery({
    queryKey: ['smsConversations', currPage, pageSize, filters, sortBy, sortDir],
    queryFn: async () => {
      try {
        // Call the API endpoint for conversations
        const response = await $fetch('/v1/sms/conversations', {
          query: {
            page: currPage.toString(),
            pageSize: pageSize.toString(),
            sortBy,
            sortDir,
            filters: JSON.stringify(filters),
          },
        })

        return {
          conversations: response.data || [],
          pagination: {
            totalItems: response.data?.length || 0,
            totalPages: Math.ceil((response.data?.length || 0) / pageSize),
            currentPage: currPage,
            pageSize,
          },
        }
      }
      catch (error) {
        console.error('Error fetching SMS conversations:', error)
        throw error
      }
    },
    enabled,
  })
} 