import { EditIcon } from 'assets/icons/navigationIcons'
import { memo, useContext, useEffect, useRef, useState } from 'react'
import { MdSaveAlt } from 'react-icons/md'
import {
  BaseEdge,
  EdgeLabelRenderer,
  getSmoothStepPath,
} from 'reactflow'
import FlowContext from '../contextFlow'

function CustomEdge({
  id,
  data,
  source,
  target,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  selected,
  ...props
}) {
  const {
    isEditingEdge,
    setIsEditingEdge,
    elements,
    triggerUpdate,
    setShowEdgeSidebar: setIsSideBarOpen,
  } = useContext(FlowContext)
  const inputRef = useRef(null)
  const containerRef = useRef(null)

  const [inputLabel, setInputLabel] = useState(data.label)
  const [isExpanded, setIsExpanded] = useState(false)

  const isSelfConnecting = source === target
  let edgePath, labelX, labelY
  let circularEdge

  const edges = elements.edges

  useEffect(() => {
    setInputLabel(data.label)
  }, [data.label])

  useEffect(() => {
    setIsExpanded(isEditingEdge === id)
  }, [isEditingEdge, id])

  if (edges) {
    circularEdge = edges.find(
      edge =>
        edge.target === source && edge.source === target && edge.id !== id,
    )
  }

  const getEdgeOffset = () => {
    if (!edges) {
      return 0
    }
    const parallelEdges = edges.filter(
      e => e.source === source && e.target === target,
    )
    const index = parallelEdges.findIndex(e => e.id === id)
    const maxOffset = 50
    const edgeCount = parallelEdges.length

    if (edgeCount === 1) {
      return 0
    }
    else if (edgeCount % 2 === 0) {
      const position = index - (edgeCount - 1) / 2
      return (position * maxOffset) / (edgeCount / 2)
    }
    else {
      const position = index - Math.floor(edgeCount / 2)
      return (position * maxOffset) / Math.floor(edgeCount / 2)
    }
  }

  const offset = getEdgeOffset()

  if (isSelfConnecting) {
    const radiusX = 30
    const radiusY = 30
    edgePath = `M ${sourceX - 5} ${sourceY} A ${radiusX} ${radiusY} 0 1 0 ${sourceX + 2} ${sourceY}`
    labelX = sourceX
    labelY = sourceY + 60
  }
  else {
    [edgePath, labelX, labelY] = getSmoothStepPath({
      sourceX,
      sourceY,
      sourcePosition,
      targetX,
      targetY,
      targetPosition,
      borderRadius: 16, // Adds rounded-sm corners to the step path
      offset: 12, // Distance between the node and the first/last step segment
    })

    if (circularEdge) {
      labelX = sourceX + 0.25 * (targetX - sourceX)
      labelY = sourceY + 0.25 * (targetY - sourceY)
    }
  }

  // Adjust path for offset
  if (!isSelfConnecting && offset !== 0) {
    const dx = targetX - sourceX
    const dy = targetY - sourceY
    const angle = Math.atan2(dy, dx)
    const offsetX = offset * Math.sin(angle)
    const offsetY = -offset * Math.cos(angle)

    sourceX += offsetX
    sourceY += offsetY
    targetX += offsetX
    targetY += offsetY;

    [edgePath, labelX, labelY] = getSmoothStepPath({
      sourceX,
      sourceY,
      sourcePosition,
      targetX,
      targetY,
      targetPosition,
      borderRadius: 16,
      offset: 12,
    })
  }

  const adjustedLabelX = labelX + offset / 2
  const adjustedLabelY = labelY - offset / 2

  const handleEdit = () => {
    setIsEditingEdge(id)
    setIsExpanded(true)
    if (setIsSideBarOpen) {
      setIsSideBarOpen(true)
    }
  }

  const handleSubmit = () => {
    const updatedEdges = elements.edges.map((edge) => {
      if (edge.id === id) {
        return {
          ...edge,
          data: {
            ...edge.data,
            label: inputLabel.trim(),
          },
        }
      }
      return edge
    })
    triggerUpdate({ edges: updatedEdges }, false)
    setIsEditingEdge(null)
    setIsExpanded(false)
    if (setIsSideBarOpen) {
      setIsSideBarOpen(false)
    }
  }

  useEffect(() => {
    if (isEditingEdge === id && inputRef.current) {
      inputRef.current.focus()
    }
  }, [isEditingEdge, id])

  const handleInputChange = (e) => {
    setInputLabel(e.target.value)
  }

  return (
    <>
      <BaseEdge
        id={id}
        path={edgePath}
        className={`transition-all duration-300 ${
          selected
            ? 'stroke-gradient-indigo opacity-90'
            : data.isHighlighted
              ? 'stroke-indigo-400 opacity-75'
              : 'stroke-gray-300 opacity-50'
        }`}
        style={{
          strokeWidth: selected ? 2 : 1,
          filter: selected
            ? 'drop-shadow(0 0 3px rgba(59, 130, 246, 0.5))'
            : 'none',
        }}
      />
      <EdgeLabelRenderer>
        <div
          ref={containerRef}
          className={`absolute ${
            data.condition ? 'bg-slate-100' : 'bg-white'
          } border ${
            data.isHighlighted
              ? 'border-blue-500 ring-2 ring-blue-500 shadow-lg shadow-blue-200'
              : 'border-gray-400'
          } transition-all duration-200`}
          style={{
            transform: `translate(-50%, -50%) translate(${adjustedLabelX}px,${adjustedLabelY}px)`,
            pointerEvents: 'all',
            maxWidth: isExpanded ? '900px' : '200px',
          }}
        >
          {isExpanded
            ? (
                <div className="flex flex-col p-1.5">
                  <textarea
                    className="w-full bg-white px-2 py-2.5 text-xs border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none"
                    ref={inputRef}
                    value={inputLabel}
                    onChange={handleInputChange}
                    onKeyDown={e =>
                      e.key === 'Enter' && !e.shiftKey && handleSubmit()}
                    disabled={data.condition}
                    rows={3}
                  />
                  <button
                    onClick={handleSubmit}
                    className="mt-1.5 bg-blue-500 text-white px-2 py-0.5 text-xs hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300 flex items-center justify-center gap-1.5"
                  >
                    Save
                    <MdSaveAlt size={14} />
                  </button>
                </div>
              )
            : (
                <div className="flex items-center justify-between px-2 py-0.5 space-x-1.5">
                  <span
                    className={`flex-auto text-xs font-medium ${
                      data.isHighlighted ? 'text-blue-600' : 'text-gray-700'
                    } truncate max-w-[150px]`}
                  >
                    {inputLabel}
                  </span>
                  <button
                    onClick={handleEdit}
                    className="text-blue-700 hover:text-blue-700 focus:outline-none"
                  >
                    <EditIcon size={14} />
                  </button>
                </div>
              )}
        </div>
      </EdgeLabelRenderer>
    </>
  )
}

export default memo(CustomEdge)
