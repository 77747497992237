import { Dialog } from '@headlessui/react'
import Button from 'components/core/Button'
import { Loading } from 'components/core/Loading'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { $fetch } from 'utils/fetch'

export default function EditPathwayDetailsDialog({
  open,
  onClose,
  pathwayId,
  pathwayName,
  pathwayDescription,
  setRefreshPathways,
}) {
  const initialPathwayName = pathwayName
  const initialPathwayDescription = pathwayDescription

  const [name, setName] = useState(pathwayName)
  const [description, setDescription] = useState(pathwayDescription)

  const [loading, setLoading] = useState(false)

  const isEdited
    = name !== initialPathwayName || description !== initialPathwayDescription

  const handleEditPathwayDetails = async () => {
    // TODO
    try {
      setLoading(true)
      $fetch('/convo_pathway/update_pathway_name', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          pathway_id: pathwayId,
          name,
          description,
        }),
      })
        .then(res => res.data)
        .then((data) => {
          if (data.errors) {
            toast.error(`Error updating pathway name. ${data.error}`, {
              position: 'bottom-right',
            })
            onClose()
            setLoading(false)
            return
          }
          setRefreshPathways(prev => !prev)
          toast.success(data.message, {
            position: 'bottom-right',
          })
          onClose()
          setLoading(false)
        })
    }
    catch (error) {
      setLoading(false)
      onClose()
      console.error('Error updating pathway name', error)
      toast.error('Error updating pathway name', {
        position: 'bottom-right',
      })
    }
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 border border-gray-300 bg-white p-5 rounded-md shadow-lg w-[500px] space-y-2.5">
        <h1 className="text-lg text-indigo-500 font-bold mb-2.5 text-center">
          Edit Pathway Details
        </h1>
        <div className="space-y-1.5">
          <label>Name:</label>
          <input
            value={name}
            onChange={e => setName(e.target.value)}
            className="border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-indigo-500 focus:border-indigo-500 block w-full p-1.5"
            placeholder="Pathway Name"
          />
        </div>
        <div className="space-y-1.5">
          <label>Description:</label>
          <textarea
            value={description}
            onChange={e => setDescription(e.target.value)}
            className="border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-indigo-500 focus:border-indigo-500 block w-full p-1.5"
            placeholder="Pathway Description"
          />
        </div>
        <div
          className="pt-1.5
        flex justify-between space-x-2.5
        "
        >
          <Button onClick={onClose}>Cancel</Button>
          <div className="flex items-center gap-2.5">
            <Loading loading={loading} />
            <Button
              onClick={handleEditPathwayDetails}
              disabled={!isEdited || loading}
            >
              Save Changes
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  )
}
