// File: NoToolsPopup.jsx

import { Dialog, Transition } from '@headlessui/react'
import { Button } from '@radix-ui/themes'
import { Fragment } from 'react'

/**
 * A popup that shows "rainbow tiles" around some text, using large squares 
 * for most of the background. In the "safe zone," each big cell is subdivided 
 * into 7x7 mini-squares, skipping the entire 5x5 center region (100% skip)
 * so there's absolutely no coverage in the middle. The outer ring can appear 
 * partially, for an imperfect boundary.
 *
 * Clicking the background (overlay) now closes the popup, 
 * by calling `onClose()`.
 *
 * Props:
 *  - show: boolean => whether the dialog is visible
 *  - onClose: function => called to close the popup (click outside / ESC)
 *  - onExplore: function => "Explore Showcase" button
 *  - onCreate: function => "Create a Tool" button
 *  - title, body1, body2: text for heading & paragraphs
 *  - animationDuration: number => color-cycle seconds
 */
export default function NoToolsPopup({
  show,
  onClose,
  onExplore,
  onCreate,
  title = 'Make your first tool!',
  body1 = `Tools (Integrations) help you automate tasks, integrate external systems, and do more within your app.`,
  body2 = `Explore our public "Tool Showcase" to install a featured integration, or create your own from scratch!`,
  animationDuration = 48
}) {
  // "Big" grid dimensions
  const bigCols = 12
  const bigRows = 9

  // The safe zone: columns 0..4, rows 1..6
  // We'll subdivide each cell in this zone into 7×7 mini-squares.
  const safeZoneCols = [0, 1, 2, 3, 4]
  const safeZoneRows = [1, 2, 3, 4, 5, 6]


  // We'll do subCells=4 or so, plus a partial skip ring
  const subCells = 4
  const skipChanceRing = 0.5


  // Build the big cells array
  const bigCells = []
  for (let r = 0; r < bigRows; r++) {
    for (let c = 0; c < bigCols; c++) {
      bigCells.push({ r, c })
    }
  }

  // Keyframes for the rainbow color
  const keyframesStyle = `
    @keyframes rainbowShift {
      0%   { background-color: #ff6961; }
      25%  { background-color: #fdfd96; }
      50%  { background-color: #77dd77; }
      75%  { background-color: #84b6f4; }
      100% { background-color: #ff6961; }
    }
    .rainbow-tile {
      animation-name: rainbowShift;
      animation-duration: ${animationDuration}s;
      animation-iteration-count: infinite;
      animation-direction: alternate;
      animation-timing-function: ease-in-out;
      width: 100%;
      height: 100%;
    }
  `

  return (
    <>
      <style>{keyframesStyle}</style>

      <Transition appear show={show} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-[99999] overflow-y-auto h-screen"
          open={show}
          onClose={onClose} // <--- This allows clicking outside to close
        >
          <div className="h-full px-2.5 text-center flex flex-col w-full">
            {/* Fade overlay */}
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-200"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black/30 transition-opacity" />
            </Transition.Child>

            {/* Main scale transition */}
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-200"
              enterFrom="scale-95 opacity-0"
              enterTo="scale-100 opacity-100"
              leave="ease-in duration-100"
              leaveFrom="scale-100 opacity-100"
              leaveTo="scale-95 opacity-0"
            >
              <div
                className="max-w-4xl p-8 h-1/3 my-auto mx-auto flex flex-col
                  text-left transition-all transform bg-white shadow-xl
                  rounded-md relative"
              >
                {/* Big squares background */}
                <div className="absolute inset-0 -z-10 overflow-hidden">
                  <div
                    className={`w-full h-full grid grid-cols-${bigCols} grid-rows-${bigRows}`}
                  >
                    {bigCells.map(({ r, c }, idx) => {
                      const isSafeZone =
                        safeZoneCols.includes(c) &&
                        safeZoneRows.includes(r)
                      if (!isSafeZone) {
                        // Outside safe zone => 1 big square
                        const delay = (Math.random() * 3).toFixed(2)
                        return (
                          <div
                            key={idx}
                            className="rainbow-tile"
                            style={{ animationDelay: `${delay}s` }}
                          />
                        )
                      }
                      // Inside safe zone => subdivide
                      const subSquares = []
                      for (let sr = 0; sr < subCells; sr++) {
                        for (let sc = 0; sc < subCells; sc++) {

                          // Middle 5×5 => always skip
                          if (sr >= 1 && sr <= 100 && sc >= 1 && sc <= 100) {

                            subSquares.push({ skip: true })
                            continue
                          }
                          // Outer ring => skip w/ skipChanceRing
                          const skip = Math.random() < skipChanceRing
                          subSquares.push({ skip })
                        }
                      }
                      return (
                        <div key={idx} className="relative w-full h-full overflow-hidden">
                          <div
                            className={`absolute inset-0 grid grid-cols-${subCells} grid-rows-${subCells}`}
                          >
                            {subSquares.map(({ skip }, subIdx) => {
                              if (skip) {
                                return <div key={subIdx} className="w-full h-full" />
                              }
                              const delay = (Math.random() * 3).toFixed(2)
                              return (
                                <div
                                  key={subIdx}
                                  className="rainbow-tile"
                                  style={{ animationDelay: `${delay}s` }}
                                />
                              )
                            })}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>

                {/* Left "text enclave" */}
                <div className="w-[40%] mt-8 overflow-hidden">
                  <Dialog.Title className="text-3xl font-bold text-gray-900 mb-0 bg-white px-2">
                    {title}
                  </Dialog.Title>

                  <div className="text-gray-700 text-xl leading-7 relative z-10 font-bold bg-white px-2">
                    <p className="text-lg bg-white">{body1}</p>

                    <p className="bg-white pt-4">{body2}</p>
                  </div>

                  <div className="py-6 px-2 flex gap-2 relative z-10 bg-white">

                    <Button
                      variant="solid"
                      color="gray"
                      onClick={onExplore}
                      style={{ cursor: 'pointer', padding: '0.6rem 1.4rem' }}
                    >
                      Explore Showcase
                    </Button>
                    <Button
                      variant="solid"
                      color="iris"
                      onClick={onCreate}
                      style={{ cursor: 'pointer', padding: '0.6rem 1.4rem' }}
                    >
                      Create a Tool
                    </Button>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}
