import { useStatsigClient } from '@statsig/react-bindings'
import RangeInput from 'components/core/RangeInput'
import { useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import CloseIcon from '../../../../assets/icons/close-icon-black.svg'
import CodeIcon from '../../../../assets/icons/CodeIcon.svg'

function Slider({
  fieldName,
  using_key = false,
  currValue,
  label = 'Temperature',
  methods,
}) {
  const { client: statsigClient } = useStatsigClient()
  const config = statsigClient.getDynamicConfig('interruption_value')

  const settings = useMemo(() => {
    const defaultSettings = {
      min: 0,
      max: 1,
      step: 0.1,
    }

    if (fieldName?.toLowerCase() === 'interruption_threshold') {
      return {
        min: 50,
        max: config.get('limit'),
        step: 10,
      }
    }

    return defaultSettings
  }, [fieldName, statsigClient])

  return (
    <RangeInput
      fieldName={`${fieldName}.value`}
      label={<span>{`${label}: ${currValue ?? 'Default'}`}</span>}
      min={settings.min}
      max={settings.max}
      step={settings.step}
      value={currValue}
      sliderValue={currValue}
      id={`Slider-${fieldName}`}
      disabled={using_key}
      onChange={(e) => {
        methods.setValue(`${fieldName}.value`, e.target.value)
        methods.setValue(`${fieldName}.using_key`, false)
      }}
    />
  )
}

export default function SliderDropdown({
  keypairs,
  methods,
  fieldname,
  label,
}) {
  const watch = methods.watch(fieldname)
  const [isOpen, setIsOpen] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const dropdownRef = useRef(null)

  const filteredKeypairs = keypairs?.filter(doc =>
    Object.keys(doc)[0].toLowerCase().includes(searchTerm.toLowerCase()),
  )

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value)
  }

  const handleOptionClick = (optionValue) => {
    methods.setValue(`${fieldname}.value`, optionValue)
    methods.setValue(`${fieldname}.using_key`, true)
    setIsOpen(false)
    setSearchTerm('')
  }

  const handleOptionClear = () => {
    methods.setValue(
      `${fieldname}.value`,
      fieldname === 'temperature' ? 0.7 : 100,
    )
    methods.setValue(`${fieldname}.using_key`, false)
    setIsOpen(false)
    setSearchTerm('')
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false)
        setSearchTerm('')
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <DropdownContainer ref={dropdownRef}>
      {!watch.using_key && (
        <Slider
          fieldName={fieldname}
          label={label}
          currValue={watch.value}
          using_key={watch.using_key}
          methods={methods}
        />
      )}

      <StyledOption
        onClick={() => setIsOpen(!isOpen)}
        style={{ border: '1px solid #eeeeee', marginTop: 20 }}
      >
        <IconContainer>
          <img src={CodeIcon} style={{ height: 16, width: 16 }} alt="" />
        </IconContainer>

        <div>
          <p style={{ fontWeight: '300', fontSize: 10 }}>Key</p>
          <p style={{ fontWeight: '550', fontSize: 13 }}>{watch.value}</p>
        </div>

        <div onClick={handleOptionClear} style={{ marginLeft: 'auto' }}>
          <img src={CloseIcon} alt="close" />
        </div>
      </StyledOption>

      {isOpen && keypairs?.length > 0 && (
        <DropdownContent>
          <SearchInput
            type="text"
            placeholder="Search keys..."
            value={searchTerm}
            onChange={handleSearchChange}
            autoFocus
          />
          {filteredKeypairs.map((doc, ind) => (
            <StyledOption
              key={ind}
              style={{
                borderBottomLeftRadius:
                  ind === filteredKeypairs.length - 1 ? 5 : 0,
                borderBottomRightRadius:
                  ind === filteredKeypairs.length - 1 ? 5 : 0,
                borderBottom:
                  ind === filteredKeypairs.length - 1
                    ? '1px solid #eeeeee'
                    : 'none',
              }}
              onMouseDown={() => {
                handleOptionClick(Object.keys(doc)[0])
              }}
            >
              <IconContainer>
                <img src={CodeIcon} style={{ height: 16, width: 16 }} alt="" />
              </IconContainer>

              <div>
                <p style={{ fontWeight: '550', fontSize: 14 }}>
                  {Object.keys(doc)[0]}
                </p>
                <p style={{}}>
                  {typeof Object.values(doc)[0] === 'boolean'
                    ? Object.values(doc)[0]
                      ? 'true'
                      : 'false'
                    : typeof Object.values(doc)[0] === 'number'
                      ? Object.values(doc)[0]
                      : Object.values(doc)[0]?.substring(0, 100)}
                  {' '}
                  {Object.values(doc)[0]?.length >= 100 && '...'}
                </p>
              </div>
            </StyledOption>
          ))}
        </DropdownContent>
      )}
    </DropdownContainer>
  )
}

const DropdownContainer = styled.div`
  width: 100%;
`

const SearchInput = styled.input`
  position: sticky;
  top: 0;
  z-index: 1;
  background: white;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border: 1px solid #eeeeee;
  margin: 0;
  padding: 10px;
  width: 100%;
  outline: none;
`

const StyledOption = styled.div`
  border-top: 1px solid #eeeeee;
  border-left: 1px solid #eeeeee;
  border-right: 1px solid #eeeeee;
  cursor: pointer;
  padding: 10px 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 25px;
  border-top-left-radius: ${({ ind }) => (ind === 0 ? 5 : 0)}px;
  border-top-right-radius: ${({ ind }) => (ind === 0 ? 5 : 0)}px;
  &:hover {
    background-color: #f0f0f0; /* Change this to your desired hover background color */
  }
`

const IconContainer = styled.div`
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  padding: 5px;
`

const Label = styled.p`
  margin: 0px;
  padding: 0px;
  color: #000;
  font-size: 13px;
  font-weight: 500;
`

const DropdownContent = styled.div`
  width: 100%;
  background-color: white;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  max-height: 200px;
  overflow-y: auto;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  margin-top: 5px; /* Add a little space between the input and dropdown */
  margin-bottom: 10px;
`
