import Button from 'components/core/Button'
import { Loading } from 'components/core/Loading'
import { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { KeyValuesToObj } from 'utils/formatting/ValuesToObj'
import { testRequest } from 'utils/funcs/phone-numbers/inbound/testRequest'
import TestConsole from './TestConsole'

export default function TestRequest({ requestIndex }) {
  const [showConsole, setShowConsole] = useState()
  const [loading, setLoading] = useState()
  const [log, setLog] = useState()
  const { getValues } = useFormContext()

  const onTest = async () => {
    setLoading(true)
    const requestData = getValues(`dynamic_data[${requestIndex}]`)
    const url = requestData.url
    const headers = KeyValuesToObj(requestData.headers)
    const body = KeyValuesToObj(requestData.body)
    const query = requestData.query
    const method = requestData.method.toLowerCase()
    const response = await testRequest(url, headers, body, query, method)
    setShowConsole(true)
    setLog(response)
    setLoading(false)
  }

  return (
    <>
      <Button onClick={() => onTest()} style={{ width: 'auto' }}>
        Test Request
      </Button>
      {loading
        ? (
            <Loading loading={loading} />
          )
        : (
            showConsole && <TestConsole log={log} />
          )}
    </>
  )
}
