import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { Loading } from 'components/core/Loading'
import config from 'config'
import { useAuth } from 'hooks/useAuth'
import { debounce } from 'lodash'
import { useCallback, useContext, useEffect, useState } from 'react'
import { getAuthToken } from 'utils/funcs/browser/getAuthToken'
import { getOrgId } from 'utils/funcs/browser/getOrgId'
import FlowContext from '../contextFlow'

function PathwayTransferModal({ id, data, setIsOpen }) {
  const { embedMode } = useContext(FlowContext)
  const { user } = useAuth()
  const orgId = getOrgId()
  const [pathways, setPathways] = useState([])
  const [selectedPathwayNodes, setSelectedPathwayNodes] = useState([])
  const [name, setName] = useState(data.name || '')
  const [transferPathway, setTransferPathway] = useState(
    data.transferPathway || '',
  )
  const [usePathwayManualInput, setUsePathwayManualInput] = useState(
    data?.usePathwayManualInput || false,
  )
  const [transferPathwayNode, setTransferPathwayNode] = useState(
    data.transferPathwayNode || '',
  )
  const [versions, setVersions] = useState([])
  const [selectedVersion, setSelectedVersion] = useState(
    data.version || 'production',
  )
  const [useManualInput, setUseManualInput] = useState(
    data?.useManualInput || false,
  )

  // Loading states
  const [isLoadingPathways, setIsLoadingPathways] = useState(!embedMode)
  const [isLoadingVersions, setIsLoadingVersions] = useState(false)
  const [isLoadingNodes, setIsLoadingNodes] = useState(false)

  // Add state to track which dropdown is open
  const [openDropdown, setOpenDropdown] = useState(null)

  const getSelectedPathwayData = async (pathwayId, version) => {
    if (!pathwayId || !version)
      return
    setIsLoadingNodes(true)

    try {
      const response = await fetch(
        `${config.API_URL}/v1/pathway/${pathwayId}/version/${version}`,
        {
          method: 'GET',
          headers: {
            authorization: getAuthToken(),
            ...(orgId && { 'x-bland-org-id': orgId }),
          },
        },
      )

      if (response.ok) {
        const data = await response.json()
        const filteredNodes = data.nodes.filter(node => node.name !== null && node.name !== '')
        setSelectedPathwayNodes(filteredNodes)
      }
      else {
        console.error('Failed to fetch pathway data')
      }
    }
    catch (error) {
      console.error('Error fetching pathway data:', error)
    }
    finally {
      setIsLoadingNodes(false)
    }
  }

  const getPathwayVersions = async (pathwayId) => {
    if (!pathwayId)
      return
    setIsLoadingVersions(true)

    try {
      const response = await fetch(
        `${config.API_URL}/v1/pathway/${pathwayId}/versions`,
        {
          method: 'GET',
          headers: {
            authorization: getAuthToken(),
            ...(orgId && { 'x-bland-org-id': orgId }),
          },
        },
      )

      if (response.ok) {
        const data = await response.json()
        setVersions([
          { version_number: 0, name: 'Production', is_production: true },
          ...data,
        ])
      }
      else {
        console.error('Failed to fetch pathway versions')
      }
    }
    catch (error) {
      console.error('Error fetching pathway versions:', error)
    }
    finally {
      setIsLoadingVersions(false)
    }
  }

  // Debounced versions of the fetch functions
  const debouncedGetVersions = useCallback(
    debounce((pathwayId) => {
      getPathwayVersions(pathwayId)
    }, 500),
    [],
  )

  const debouncedGetNodes = useCallback(
    debounce((pathwayId, version) => {
      if (!useManualInput) {
        getSelectedPathwayData(pathwayId, version)
      }
    }, 500),
    [useManualInput],
  )

  useEffect(() => {
    if (transferPathway && !usePathwayManualInput) {
      debouncedGetVersions(transferPathway)
    }
    return () => {
      debouncedGetVersions.cancel()
    }
  }, [transferPathway, usePathwayManualInput, debouncedGetVersions])

  useEffect(() => {
    if (transferPathway && selectedVersion && !usePathwayManualInput) {
      debouncedGetNodes(transferPathway, selectedVersion)
    }
    return () => {
      debouncedGetNodes.cancel()
    }
  }, [
    transferPathway,
    selectedVersion,
    usePathwayManualInput,
    debouncedGetNodes,
  ])

  useEffect(() => {
    if (embedMode)
      return

    const fetchPathways = async () => {
      setIsLoadingPathways(true)
      try {
        const response = await fetch(`${config.API_URL}/v1/convo_pathway`, {
          method: 'GET',
          headers: {
            authorization: getAuthToken(),
            ...(orgId && { 'x-bland-org-id': orgId }),
          },
        })
        if (response.ok) {
          const data = await response.json()
          const pathways = data.map(pathway => ({
            id: pathway.id,
            name: pathway.name,
          }))

          setPathways(pathways)
        }
        else {
          console.error('Failed to fetch pathways')
        }
      }
      catch (error) {
        console.error('Error fetching pathways:', error)
      }
      finally {
        setIsLoadingPathways(false)
      }
    }

    if (!usePathwayManualInput) {
      fetchPathways()
    }
  }, [id, embedMode, usePathwayManualInput])

  const { triggerUpdate, elements } = useContext(FlowContext)

  const handlePathwayChange = e => setTransferPathway(e.target.value)

  return (
    <div className={`space-y-2.5 ${openDropdown ? 'pb-[200px]' : ''}`}>
      <div className="space-y-1.5">
        <div>Name:</div>
        <input
          type="text"
          value={name}
          onChange={e => setName(e.target.value)}
          className="border border-gray-300 p-1.5 rounded w-full"
        />

        <div className="space-y-1.5">
          <div className="flex items-center justify-between">
            <label className="block text-[12px] font-medium text-gray-700">
              Transfer Pathway
            </label>
            <div className="flex items-center space-x-1.5">
              <label className="text-[12px] text-gray-600">Manual Input</label>
              <input
                type="checkbox"
                checked={usePathwayManualInput}
                onChange={(e) => {
                  setUsePathwayManualInput(e.target.checked)
                  setTransferPathway('') // Reset pathway selection when toggling
                  setVersions([]) // Reset versions
                }}
                className="h-2.5 w-2.5 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded-sm"
              />
            </div>
          </div>

          <div className="relative">
            {usePathwayManualInput
              ? (
                  <div className="space-y-1.5">
                    <input
                      type="text"
                      value={transferPathway}
                      onChange={handlePathwayChange}
                      placeholder="Enter pathway ID or variable"
                      className="block w-full pl-2 pr-6 py-1.5 border border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 rounded text-[12px]"
                    />
                    <p className="text-[11px] text-gray-500 italic">
                      You can enter either a specific pathway ID (e.g.,
                      "pathway_123") or use a variable (e.g.,
                      {' '}
                      {'{{ pathwayIDVariable }}'}
                      ). Variables will be resolved at
                      runtime.
                    </p>
                  </div>
                )
              : isLoadingPathways
                ? (
                    <div className="border border-gray-300 p-1.5 rounded w-full h-[38px] flex items-center justify-center">
                      <Loading loading />
                    </div>
                  )
                : (
                    <Select
                      value={transferPathway}
                      onValueChange={value => setTransferPathway(value)}
                      onOpenChange={open =>
                        setOpenDropdown(open ? 'pathway' : null)}
                    >
                      <SelectTrigger className="w-full h-8">
                        <SelectValue placeholder="Select Pathway" />
                      </SelectTrigger>
                      <SelectContent position="popper" className="z-[1000]">
                        {pathways.map(el => (
                          <SelectItem key={el.id} value={el.id}>
                            {el.name}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  )}
          </div>
        </div>

        {transferPathway && (
          <>
            <label className="block text-[12px] font-medium text-gray-700">
              Pathway Version
            </label>
            <div className="relative">
              {isLoadingVersions && !usePathwayManualInput
                ? (
                    <div className="border border-gray-300 p-1.5 rounded w-full h-[38px] flex items-center justify-center">
                      <Loading loading />
                    </div>
                  )
                : (
                    <Select
                      value={selectedVersion}
                      onValueChange={value => setSelectedVersion(value)}
                      onOpenChange={open =>
                        setOpenDropdown(open ? 'version' : null)}
                    >
                      <SelectTrigger className="w-full h-8">
                        <SelectValue placeholder="Select Version" />
                      </SelectTrigger>
                      <SelectContent position="popper" className="z-[1000]">
                        {usePathwayManualInput
                          ? (
                              <>
                                <SelectItem value="production">Production</SelectItem>
                                <SelectItem value="staging">Staging</SelectItem>
                              </>
                            )
                          : (
                              versions.map(version => (
                                <SelectItem
                                  key={version.version_number}
                                  value={
                                    version.is_production
                                      ? 'production'
                                      : version.version_number.toString()
                                  }
                                >
                                  {version.name}
                                  {' '}
                                  {version.is_staging ? '(Staging)' : ''}
                                  {' '}
                                  {version.is_prev_published
                                    ? '(Previously Published)'
                                    : ''}
                                </SelectItem>
                              ))
                            )}
                      </SelectContent>
                    </Select>
                  )}
            </div>

            <div className="space-y-1.5">
              <div className="flex items-center justify-between">
                <label className="block text-[12px] font-medium text-gray-700">
                  Pathway Transfer Node
                </label>
                <div className="flex items-center space-x-1.5">
                  <label className="text-[12px] text-gray-600">
                    Manual Input
                  </label>
                  <input
                    type="checkbox"
                    checked={useManualInput}
                    onChange={(e) => {
                      setUseManualInput(e.target.checked)
                      setTransferPathwayNode('') // Reset node selection when toggling
                    }}
                    className="h-2.5 w-2.5 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded-sm"
                  />
                </div>
              </div>

              <div className="relative">
                {useManualInput
                  ? (
                      <div className="space-y-1.5">
                        <input
                          type="text"
                          value={transferPathwayNode}
                          onChange={e => setTransferPathwayNode(e.target.value)}
                          placeholder="Enter node ID or variable"
                          className="block w-full pl-2 pr-6 py-1.5 border border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 rounded text-[12px]"
                        />
                        <p className="text-[11px] text-gray-500 italic">
                          You can enter either a specific node ID (e.g., "node_123")
                          or use a variable (e.g.,
                          {' '}
                          {'{{ nodeIDVariable }}'}
                          ).
                          Variables will be resolved at runtime.
                        </p>
                      </div>
                    )
                  : isLoadingNodes
                    ? (
                        <div className="border border-gray-300 p-1.5 rounded w-full h-[38px] flex items-center justify-center">
                          <Loading loading />
                        </div>
                      )
                    : (
                        <Select
                          value={transferPathwayNode}
                          onValueChange={value => setTransferPathwayNode(value)}
                          onOpenChange={open =>
                            setOpenDropdown(open ? 'node' : null)}
                        >
                          <SelectTrigger className="w-full h-8">
                            <SelectValue placeholder="Select Node" />
                          </SelectTrigger>
                          <SelectContent position="popper" className="z-[1000]">
                            {selectedPathwayNodes.map(el => (
                              <SelectItem key={el.id} value={el.id}>
                                {el.data?.name}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      )}
              </div>
            </div>
          </>
        )}
      </div>

      <button
        onClick={() => {
          const node = elements.nodes.find(el => el.id === id)
          const data = {
            transferPathway,
            name,
            version: selectedVersion,
          }
          if (transferPathwayNode) {
            data.transferPathwayNode = transferPathwayNode
          }
          if (useManualInput) {
            data.useManualInput = true
          }
          if (usePathwayManualInput) {
            data.usePathwayManualInput = true
          }
          // update edges to remove all edges with this node as the source
          const edges = elements.edges.filter(el => el.source !== id)

          setIsOpen(false)
          triggerUpdate(
            {
              nodes: elements.nodes.map((el) => {
                if (el.id === id) {
                  return {
                    ...el,
                    data,
                    type: 'Transfer Pathway',
                  }
                }
                return el
              }),
              edges,
            },
            false,
          )
        }}
        className="bg-indigo-500 text-white py-1.5 px-2.5 rounded mt-2.5 hover:bg-indigo-600 transition-colors disabled:bg-indigo-300"
        disabled={isLoadingPathways || isLoadingVersions || isLoadingNodes}
      >
        Save
      </button>
    </div>
  )
}

export default PathwayTransferModal
