import { Button, Input, Modal, ModalBody, ModalContent, ModalHeader } from '@heroui/react'
import { useEffect, useMemo, useRef, useState } from 'react'
import { FaArrowRight, FaSearch, FaTimes } from 'react-icons/fa'

function NodeSearchPopup({ nodes, onSelect, onClose }) {
  const [searchValue, setSearchValue] = useState('')
  const [filteredNodes, setFilteredNodes] = useState([])
  const [selectedIndex, setSelectedIndex] = useState(0)
  const inputRef = useRef(null)
  const listRef = useRef(null)

  // Define handleNodeSelect before its usage in useEffect
  const handleNodeSelect = (node) => {
    onSelect(node)
    onClose()
  }

  // Filter nodes when search value changes
  useEffect(() => {
    if (nodes) {
      const filtered = searchValue
        ? nodes.filter(node =>
            node.data?.name?.toLowerCase().includes(searchValue.toLowerCase()))
        : nodes

      setFilteredNodes(filtered)
      setSelectedIndex(0) // Reset selection when filter changes
    }
  }, [searchValue, nodes])

  // Focus input when modal opens
  useEffect(() => {
    if (inputRef.current) {
      setTimeout(() => {
        inputRef.current.focus()
      }, 100)
    }
  }, [])

  // Handle keyboard navigation
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
        onClose()
      }
      else if (e.key === 'ArrowDown') {
        e.preventDefault()
        setSelectedIndex(prev =>
          prev < filteredNodes.length - 1 ? prev + 1 : prev,
        )
      }
      else if (e.key === 'ArrowUp') {
        e.preventDefault()
        setSelectedIndex(prev => prev > 0 ? prev - 1 : prev)
      }
      else if (e.key === 'Enter' && filteredNodes.length > 0) {
        handleNodeSelect(filteredNodes[selectedIndex])
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [onClose, filteredNodes, selectedIndex])

  // Scroll selected item into view
  useEffect(() => {
    if (listRef.current && filteredNodes.length > 0) {
      const selectedElement = listRef.current.querySelector(`[data-index="${selectedIndex}"]`)
      if (selectedElement) {
        selectedElement.scrollIntoView({ block: 'nearest', behavior: 'smooth' })
      }
    }
  }, [selectedIndex, filteredNodes])

  // Group nodes by type for better organization
  const groupedNodes = useMemo(() => {
    if (!filteredNodes.length)
      return {}

    return filteredNodes.reduce((acc, node) => {
      const nodeType = node?.type || 'Other'
      if (!acc[nodeType])
        acc[nodeType] = []
      acc[nodeType].push(node)
      return acc
    }, {})
  }, [filteredNodes])

  // Flatten grouped nodes for keyboard navigation
  const flattenedGroups = useMemo(() => {
    return Object.values(groupedNodes).flat()
  }, [groupedNodes])

  // Function to get node color based on type
  const getNodeColor = (nodeType) => {
    const colors = {
    //   'Default': '#4F46E5', // indigo
      'Transfer Call': '#EA580C', // orange
      'Webhook': '#059669', // emerald
      'Custom Tool': '#0891B2', // cyan
      'Knowledge Base': '#7C3AED', // violet
      'End Call': '#DC2626', // red
      'Default': '#6B7280', // gray
    }

    return colors[nodeType] || colors.Default
  }

  return (
    <Modal
      isOpen
      onClose={onClose}
      classNames={{
        backdrop: 'bg-black bg-opacity-50 backdrop-blur-sm',
        base: 'w-96 max-h-[80vh] max-w-lg z-50 overflow-hidden animate-fadeIn',
      }}
    >
      <ModalContent>
        {/* Header */}
        <ModalHeader className="bg-gradient-to-r from-gray-50 to-gray-100 flex items-center justify-between p-4 border-b">
          <div>
            <h3 className="text-lg font-semibold text-gray-800">
              Find Node
              {filteredNodes.length > 0 && (
                <span className="text-sm text-gray-500 ml-2">
                  (
                  {filteredNodes.length}
                  )
                </span>
              )}
            </h3>
            {filteredNodes.length > 0 && (
              <p className="text-xs text-gray-500 mt-1">
                Use arrow keys to navigate, Enter to select
              </p>
            )}
          </div>
          <Button
            isIconOnly
            variant="light"
            onClick={onClose}
            className="text-gray-400 hover:text-gray-700 transition-colors p-2 rounded-full hover:bg-gray-100"
            aria-label="Close"
          >
            <FaTimes />
          </Button>
        </ModalHeader>

        <ModalBody className="p-0">
          {/* Search input */}
          <div className="p-4">
            <div className="relative">
              <Input
                ref={inputRef}
                type="text"
                placeholder="Search nodes by name..."
                value={searchValue}
                onChange={e => setSearchValue(e.target.value)}
                className="w-full"
                startContent={<FaSearch className="h-4 w-4 text-gray-400" />}
                size="sm"
                variant="bordered"
              />
            </div>
          </div>

          {/* Node list */}
          <div
            ref={listRef}
            className="max-h-60 overflow-y-auto overflow-x-hidden"
            style={{ scrollbarWidth: 'thin' }}
          >
            {filteredNodes.length === 0
              ? (
                  <div className="flex flex-col items-center justify-center py-8 px-4 text-center">
                    <div className="bg-gray-100 rounded-full p-3 mb-2">
                      <FaSearch className="h-5 w-5 text-gray-400" />
                    </div>
                    <p className="text-gray-500 font-medium">No nodes found</p>
                    <p className="text-gray-400 text-sm mt-1">Try a different search term</p>
                  </div>
                )
              : (
                  Object.entries(groupedNodes).map(([groupName, groupNodes]) => (
                    <div key={groupName} className="mb-2">
                      <div className="px-4 py-1 text-xs font-medium text-gray-500 uppercase tracking-wider bg-gray-50">
                        {groupName}
                      </div>
                      <ul className="divide-y divide-gray-100">
                        {groupNodes.map((node) => {
                          const isSelected = node === flattenedGroups[selectedIndex]
                          const nodeType = node?.type || 'default'

                          return (
                            <li
                              key={node.id}
                              data-index={flattenedGroups.indexOf(node)}
                              onClick={() => handleNodeSelect(node)}
                              onMouseEnter={() => setSelectedIndex(flattenedGroups.indexOf(node))}
                              className={`py-2 px-4 cursor-pointer flex items-center text-sm transition-colors ${
                                isSelected
                                  ? 'bg-blue-50 text-blue-700'
                                  : 'hover:bg-gray-50'
                              }`}
                              title={node.data?.name || 'Unnamed node'}
                            >
                              <div
                                className="w-3 h-3 rounded-full mr-3 flex-shrink-0"
                                style={{ backgroundColor: getNodeColor(nodeType) }}
                              />
                              <div className="flex-1 truncate">
                                {node.data?.name || 'Unnamed node'}
                              </div>
                              {isSelected && (
                                <FaArrowRight className="h-3 w-3 text-blue-500 ml-2" />
                              )}
                            </li>
                          )
                        })}
                      </ul>
                    </div>
                  ))
                )}
          </div>

          {/* Footer */}
          {filteredNodes.length > 0 && (
            <div className="px-4 py-3 bg-gray-50 border-t border-gray-200 flex justify-end">
              <Button
                onClick={() => filteredNodes[selectedIndex] && handleNodeSelect(filteredNodes[selectedIndex])}
                color="primary"
                className="bg-blue-600 text-white px-4 py-2 rounded-md text-sm font-medium hover:bg-blue-700"
              >
                Go to selected
              </Button>
            </div>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default NodeSearchPopup
