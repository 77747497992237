import {
  Badge,
  Button,
  Dialog,
  DropdownMenu,
  Flex,
  IconButton,
  Spinner,
  TextField,
  Tooltip,
} from '@radix-ui/themes'
import { useMutation, useQuery } from '@tanstack/react-query'
import { AddUserModal, roles } from 'components/core/AddUserModal'
import { useAuth } from 'hooks/useAuth'
import {
  CircleHelp,
  CircleMinus,
  Crown,
  EllipsisIcon,
  ListFilter,
  Plus,
  Search,
  Send,
  Settings,
  UserPlus,
  Users,
  X,
} from 'lucide-react'
import { forwardRef, useEffect, useState } from 'react'
import { ThreeDots } from 'react-loader-spinner'
import { toast } from 'react-toastify'
import { $fetch } from 'utils/fetch'
import { addPlus, sanitizePhone } from 'utils/formatting/phone'
import { toDtmy } from 'utils/formatting/toDtmy'
import { GetOrgMembers, UpdateOrgMember } from 'utils/funcs/orgs/orgs'
// import { DialogItem } from "components/core/MgmtModal";

// ["owner", "admin", "operator", "viewer"] <- permission types

export default function OrgManagement() {
  const { orgs, org, loading, user } = useAuth()
  const [orgMembers, setOrgMembers] = useState(null)
  const [invModalOpen, setInvModalOpen] = useState(false)
  const [loadingData, setLoadingData] = useState(true)
  const [loadingUpdateOrg, setLoadingUpdateOrg] = useState(false)
  const [isUpdate, setIsUpdate] = useState(false)
  const [selected, setSelected] = useState(null)
  const [updateModal, setUpdateModal] = useState(false)

  const { data: invites, isFetching: loadingInvites, refetch: refetchInvites } = useQuery({
    queryKey: ['invites'],
    queryFn: async () => {
      const { data: invites } = await $fetch(`/orgs/${org?.id}/invites`)
      return invites
    },
    meta: {
      errorMessage: 'Error loading invites.',
    },
  })

  const revokeInvitation = useMutation({
    mutationFn: inviteId => $fetch(`/orgs/${org?.id}/invites/${inviteId}`, {
      method: 'DELETE',
    }),
    onSuccess: () => {
      refetchInvites()
      toast.success('Invitation revoked.')
    },
    onError: () => {
      toast.error('Error revoking invitation.')
    },
  })

  const loadOrgMembers = async () => {
    try {
      const result = await GetOrgMembers(org?.id, loadingData, setLoadingData)

      if (!result)
        return

      return setOrgMembers(result)
    }
    catch (error) {
      console.error(`ERROR @ _GetOrgMembers -> ${error}`)
      return toast.error('Error loading org members.')
    }
  }

  const updateUserOrgs = async (action, phoneNumber, role = null) => {
    const sanitizedPhone = sanitizePhone(phoneNumber)

    const phoneE64 = addPlus(sanitizedPhone)
    try {
      const { success, message } = await UpdateOrgMember(
        action,
        phoneE64,
        org?.id,
        role,
        loadingUpdateOrg,
        setLoadingUpdateOrg,
        action === 'add' || action === 'remove'
          ? 'members'
          : 'members/permissions',
      )

      if (success) {
        setInvModalOpen(false)
        // setUpdateModal(false);
        if (isUpdate) {
          setIsUpdate(false)
        }
        // await loadOrgMembers();
        return toast.success(message)
      }
      else {
        // setInvModalOpen(false);
        // setUpdateModal(false);
        return toast.error(message)
      }
    }
    catch (error) {
      console.error(`ERROR @ _UpdateOrgMember -> ${error}`)
      return toast.error(error.message ?? 'Error updating user permissions.')
    }
    finally {
      loadOrgMembers()
    }
  }

  const getBadgeColor = (permission) => {
    switch (permission) {
      case 'operator':
        return 'green'
      case 'admin':
        return 'blue'
      case 'viewer':
        return 'gray'
      case 'owner':
        return 'iris'
      default:
        return 'gray'
    }
  }

  useEffect(() => {
    loadOrgMembers()
  }, [org, orgs])

  const [isWayTooSmall, setIsWayTooSmall] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      setIsWayTooSmall(window.innerWidth <= 725)
    }

    window.addEventListener('resize', handleResize)
    handleResize()

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  if (isWayTooSmall) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="flex flex-col items-center justify-center bg-white border border-gray-300 rounded-md p-2.5 shadow-lg">
          <p className="text-gray-800 text-center">
            Window too small to view this screen.
          </p>
          <p className="text-gray-500 text-center">
            We recommend desktop screen size or larger.
          </p>
        </div>
      </div>
    )
  }

  if (!org && (loading || loadingData || !orgs))
    return <div />
  // if (!org || !orgMembers) return <div>{JSON.stringify({ org: org })}</div>;

  return (
    <div style={{ padding: 0 }}>
      {/* <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          columnGap: 10,
          flexWrap: "wrap",
          maxWidth: "70%",
        }}
      >
        <img
          src={Gradient}
          alt="default-gradient"
          style={{
            height: 13,
            width: 13,
            borderRadius: 4,
            objectFit: "cover",
          }}
        />
        <p
          onClick={() => navigate("/account/orgs")}
          style={{
            fontWeight: "450",
            fontSize: 13,
            color: "GrayText",
            // textShadow: "0 0px 4px rgba(0, 0, 0, 0.4)",
            transition: "text-shadow 0.3s ease-in-out",
            cursor: "pointer",
            position: "relative",
            zIndex: "1",
          }}
          onMouseEnter={(e) =>
            (e.currentTarget.style.textShadow = "0 2px 4px rgba(0, 0, 0, 0.2)")
          }
          onMouseLeave={(e) =>
            (e.currentTarget.style.textShadow = "0 0px 0px rgba(0, 0, 0, 0)")
          }
        >
          Orgs
        </p>
        <ChevronRight size={15} color="lightgray" strokeWidth={3} />
        <p style={{ fontWeight: "450", fontSize: 13, color: "GrayText" }}>
          {org?.org_slug || "Unnamed Org"}
        </p>
        <ChevronRight size={15} color="lightgray" strokeWidth={3} />
        <p style={{ fontWeight: "450", fontSize: 13, color: "#000" }}>
          User Management
        </p>
      </div> */}

      {/* <div style={{ margin: "25px 0px" }}>
        <p style={{ fontSize: 24, fontWeight: "450" }}>User management</p>
        <p style={{ color: "GrayText", fontWeight: "400", fontSize: 16 }}>
          Manage your team members and their account permissions here.
        </p>
      </div> */}

      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginTop: 25,
        }}
      >
        <p
          style={{
            fontSize: 18,
            fontWeight: '450',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            columnGap: 8,
          }}
        >
          All users
          {' '}
          <p style={{ fontSize: 18, fontWeight: '450', color: 'GrayText' }}>
            {loadingData ? <Spinner size="2" /> : orgMembers?.length || 0}
          </p>
        </p>

        <div
          style={{
            marginLeft: 'auto',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            flexWrap: 'wrap',
            columnGap: 10,
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Search
              color="gray"
              size={14}
              style={{ position: 'relative', left: 25, zIndex: 200 }}
            />
            <TextField.Root
              style={{
                padding: '0px 7px 0px 27px', // Adjusted padding to make space for the icon
                borderRadius: 6,
                position: 'relative',
                fontSize: 14,
              }}
              placeholder="Search by email..."
            />
          </div>

          {/* <TextField.Root color="gray" /> */}

          <Button
            size="2"
            variant="outline"
            color="gray"
            style={{ cursor: 'pointer', borderRadius: 6 }}
          >
            <ListFilter size={16} />
            <p style={{ fontSize: 14, fontWeight: '500', color: '#000' }}>
              Filters
            </p>
          </Button>
          <AddUserModal
            onClickLoading={loadingUpdateOrg}
            setOpen={(value) => {
              setInvModalOpen(value)
              if (isUpdate) {
                setIsUpdate(false)
              }
            }}
            onClick={(action, phoneNumber, role) => {
              updateUserOrgs(action, phoneNumber, role)
            }}
            open={invModalOpen}
            title={isUpdate ? 'Update user permissions' : 'Invite new user'}
            button={(
              <Button
                size="2"
                variant="solid"
                style={{
                  cursor: 'pointer',
                  background: '#343a40',
                  borderRadius: 6,
                }}
                onClick={() => setInvModalOpen(true)}
              >
                <Plus size={16} />
                <p style={{ fontSize: 14, fontWeight: '500' }}>Add User</p>
              </Button>
            )}
          />
        </div>
      </div>

      <div
        className="w-full"
        style={{
          border: '1px solid #eeeeee',
          background: '#fafafa',
          marginTop: 25,
        }}
      >
        <div className="flex flex-col">
          <div className="grid grid-cols-12 px-4 py-3 text-xs font-medium text-gray-500">
            <div
              className="col-span-3"
              style={{ fontSize: 14, fontWeight: '500' }}
            >
              <p style={{ fontSize: 13, fontWeight: '400' }}>Person</p>
            </div>
            <div
              className="col-span-2"
              style={{ fontSize: 14, fontWeight: '500' }}
            >
              <p style={{ fontSize: 13, fontWeight: '400' }}>Email</p>
            </div>
            <div
              className="col-span-2"
              style={{ fontSize: 14, fontWeight: '500' }}
            >
              <p style={{ fontSize: 13, fontWeight: '400' }}>Phone</p>
            </div>
            <div
              className="col-span-2"
              style={{ fontSize: 14, fontWeight: '500' }}
            >
              <p style={{ fontSize: 13, fontWeight: '400' }}>Role</p>
            </div>
            <div
              className="col-span-2 text-left"
              style={{ fontSize: 14, fontWeight: '500' }}
            >
              <p style={{ fontSize: 13, fontWeight: '400' }}>Date Added</p>
            </div>
            <div className="col-span-1"></div>
          </div>
        </div>
      </div>

      <div
        className="flex flex-col"
        style={{
          borderLeft: '1px solid #eeeeee',
          borderRight: '1px solid #eeeeee',
          borderBottom: '1px solid #eeeeee',
        }}
      >
        {loadingData && (
          <div className="flex items-center justify-center h-[300px]">
            <ThreeDots color="#eeeeee" height={40} width={40} />
          </div>
        )}

        {!loadingData
          && orgMembers !== null
          && orgMembers?.length > 0
          && orgMembers.map((member, index) => (
            <div
              key={`member-${index}`}
              className="grid grid-cols-12 px-4 py-4 items-center"
              style={{
                borderBottom:
                  index === orgMembers.length - 1 && (!invites || invites.length === 0)
                    ? 'none'
                    : '1px solid #e9e9e9',
              }}
            >
              <div className="col-span-3 flex items-center gap-3">
                <div
                  className="w-6 h-6 rounded-full bg-gray-100 flex items-center justify-center"
                  style={{ flexShrink: 0 }}
                >
                  <p
                    style={{ fontSize: 10, fontWeight: '400' }}
                    className="text-gray-500"
                  >
                    {member?.first_name?.charAt(0) ?? '-'}
                    {member?.last_name?.charAt(0) ?? '-'}
                  </p>
                </div>
                <div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      columnGap: 10,
                      gap: 5,
                    }}
                  >
                    <p style={{ fontWeight: '400', fontSize: 13 }}>
                      {member?.first_name ?? '-'}
                      {' '}
                      {member?.last_name ?? '-'}
                    </p>

                    {member?.permissions?.includes('owner') && (
                      <Tooltip side="right" content="Organization Owner">
                        <Crown color="#ff006e" size={14} />
                      </Tooltip>
                    )}

                    {member?.user_id === user?.id && (
                      <p
                        style={{
                          fontSize: 13,
                          fontWeight: '400',
                          color: 'GrayText',
                          marginLeft: '10px',
                        }}
                      >
                        (My account)
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-span-2">
                <p style={{ fontWeight: '400', fontSize: 13, color: 'gray' }}>
                  {member?.member_email || '-'}
                </p>
              </div>

              <div className="col-span-2">
                <p style={{ fontWeight: '400', fontSize: 13, color: 'gray' }}>
                  {member?.member_phone_number || '-'}
                </p>
              </div>

              <div className="col-span-2">
                {member?.permissions?.map((permission, index) => (
                  <Badge
                    key={index}
                    color="gray"
                    variant="grey"
                    size="2"
                    radius="full"
                  >
                    <p style={{ fontSize: 12, fontWeight: '400' }}>
                      {permission}
                    </p>
                  </Badge>
                ))}
              </div>
              <div className="col-span-2 text-left">
                <p
                  style={{
                    fontSize: 13,
                    fontWeight: '400',
                    color: 'gray',
                  }}
                >
                  {toDtmy(new Date(member.joined_at))}
                </p>
              </div>
              <div className="col-span-1 text-right">
                <DropdownMenu.Root dir="rtl">
                  <DropdownMenu.Trigger>
                    <IconButton
                      size="2"
                      variant="ghost"
                      color="gray"
                      radius="full"
                      style={{ cursor: 'pointer', marginRight: 10 }}
                    >
                      <EllipsisIcon
                        className="w-4 h-4"
                        color="gray"
                        strokeWidth={1}
                      />
                    </IconButton>
                  </DropdownMenu.Trigger>
                  <DropdownMenu.Content
                    side="bottom"
                    variant="soft"
                    style={{ width: 180 }}
                  >
                    {!member?.permissions?.includes('owner') && (
                      <>
                        <DialogItem
                          triggerChildren="Edit Permissions"
                          icon={<Settings size={14} />}
                          open={updateModal}
                          color="iris"
                          onOpenChange={(value) => {
                            setUpdateModal(value)
                            if (value === true) {
                              setSelected(
                                roles?.find(
                                  item =>
                                    item?.name?.toLowerCase()
                                    === member?.permissions?.[0]?.toLowerCase(),
                                ),
                              )
                            }
                          }}
                        >
                          <div
                            style={{
                              padding: 15,
                              borderBottom: '1px solid #e9e9e9',
                              background: '#ffffff',
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                            }}
                          >
                            <p style={{ fontSize: 13, fontWeight: '550' }}>
                              Update user permissions
                            </p>
                            <Dialog.Close>
                              <X
                                size={14}
                                style={{ cursor: 'pointer' }}
                                onClick={() => setUpdateModal(false)}
                              />
                            </Dialog.Close>
                          </div>

                          <Flex
                            direction="column"
                            gap="3"
                            style={{ padding: 15, background: '#f4f4f4' }}
                          >
                            <div className="space-y-1.5" style={{}}>
                              <p
                                style={{
                                  fontSize: 13,
                                  fontWeight: '450',
                                  color: '#000',
                                  marginBottom: 6,
                                }}
                              >
                                User permissions
                              </p>
                              {roles.map((role, index) => {
                              // const Icon = role.icon;
                                const isSelected = role.id === selected?.id

                                return (
                                  <button
                                    key={role.id}
                                    onClick={() => setSelected(role)}
                                    className={`w-full p-2.5 flex items-center justify-between rounded-md border transition-all duration-200 ${
                                      isSelected
                                        ? `${role.box}`
                                        : 'border-transparent hover:bg-[#eeeeee]'
                                    }`}
                                  >
                                    <div className="flex items-center space-x-2.5">
                                      <div
                                        className={`p-1.5 rounded ${isSelected ? `` : 'bg-gray-100'}`}
                                      >
                                        {/* <Icon
                        className={`h-3 w-3 ${isSelected ? "text-blue-500" : "text-gray-500"}`}
                      /> */}
                                        {role.img}
                                      </div>
                                      <div
                                        className="flex flex-col items-start"
                                        style={{ marginLeft: 15 }}
                                      >
                                        <span
                                          style={{ textAlign: 'left' }}
                                          className="text-xs font-semibold text-gray-900"
                                        >
                                          {role.name}
                                        </span>
                                        <span
                                          style={{ textAlign: 'left' }}
                                          className="text-2xs text-gray-500"
                                        >
                                          {role.description}
                                        </span>
                                      </div>
                                    </div>

                                    <div className="flex items-center space-x-1.5">
                                      <div
                                        className={`h-1.5 w-1.5 rounded-full ${role.indicator}`}
                                      />
                                      <div
                                        className={`h-2.5 w-2.5 rounded-full border-2 transition-all ${
                                          isSelected
                                            ? `${role.indicator} bg-blue-500`
                                            : 'border-gray-300'
                                        }`}
                                      >
                                        {isSelected && (
                                          <div className="h-full w-full rounded-full bg-white transform scale-[0.4]" />
                                        )}
                                      </div>
                                    </div>
                                  </button>
                                )
                              })}
                            </div>
                          </Flex>

                          <Flex
                            justify="end"
                            gap="3"
                            style={{
                              width: '100%',
                              padding: 15,
                              borderTop: '1px solid lightgray',
                            }}
                          >
                            <Dialog.Close>
                              <Button
                                onClick={() => setUpdateModal(false)}
                                style={{ cursor: 'pointer' }}
                                variant="soft"
                                color="gray"
                              >
                                Cancel
                              </Button>
                            </Dialog.Close>
                            <Dialog.Close style={{ cursor: 'pointer' }}>
                              <Button
                                loading={loadingUpdateOrg}
                                onClick={(event) => {
                                  event.stopPropagation()
                                  event.preventDefault()
                                  updateUserOrgs(
                                    'set',
                                    member.member_phone_number,
                                    selected?.name,
                                  )
                                }}
                                style={{ alignItems: 'center' }}
                                color="iris"
                              >
                                <p style={{ fontSize: 12 }}>
                                  Update permissions
                                </p>
                              </Button>
                            </Dialog.Close>
                          </Flex>
                        </DialogItem>
                        <DropdownMenu.Separator />
                        <DropdownMenu.Item
                          color="red"
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            updateUserOrgs(
                              'remove',
                              member.member_phone_number,
                            )
                            setOrgMembers(
                              orgMembers?.filter(
                                item =>
                                  item.member_phone_number
                                  !== user.member_phone_number,
                              ),
                            )
                          }}
                        >
                          <p style={{ fontSize: 13, fontWeight: '450' }}>
                            Remove User
                          </p>
                          <CircleMinus
                            size={14}
                            style={{ marginLeft: 'auto' }}
                          />
                        </DropdownMenu.Item>
                      </>
                    )}
                  </DropdownMenu.Content>
                </DropdownMenu.Root>
              </div>
            </div>
          ))}

        {!loadingInvites && invites && invites.length > 0
          && invites.map((invite, idx) => (
            <div
              key={`invite-${idx}`}
              className="grid grid-cols-12 px-4 py-4 items-center"
              style={{
                borderBottom: idx === invites.length - 1 ? 'none' : '1px solid #e9e9e9',
              }}
            >
              <div className="col-span-3 flex items-center gap-2">
                <Send size={16} className="text-gray-400" />
                <p className="text-sm text-gray-500">Invite Pending</p>
                <Tooltip side="right" content="User has not signed up for Bland yet">
                  <CircleHelp size={16} className="text-primary-300" />
                </Tooltip>

              </div>
              <div className="col-span-2">
                <p style={{ fontWeight: '400', fontSize: 13, color: 'gray' }}>-</p>
              </div>
              <div className="col-span-2">
                <p style={{ fontWeight: '400', fontSize: 13, color: 'gray' }}>{invite.phone_number}</p>
              </div>
              <div className="col-span-2">
                {invite.permissions && invite.permissions.map((permission, i) => (
                  <Badge
                    key={i}
                    color="gray"
                    variant="grey"
                    size="2"
                    radius="full"
                  >
                    <p style={{ fontSize: 12, fontWeight: '400' }}>{permission}</p>
                  </Badge>
                ))}
              </div>
              <div className="col-span-2 text-left">
                <p style={{ fontSize: 13, fontWeight: '400', color: 'gray' }}>
                  {toDtmy(new Date(invite.created_at))}
                </p>
              </div>
              <div className="col-span-1 text-right">
                <DropdownMenu.Root dir="rtl">
                  <DropdownMenu.Trigger>
                    <IconButton
                      size="2"
                      variant="ghost"
                      color="gray"
                      radius="full"
                      style={{ cursor: 'pointer', marginRight: 10 }}
                    >
                      <EllipsisIcon
                        className="w-4 h-4"
                        color="gray"
                        strokeWidth={1}
                      />
                    </IconButton>
                  </DropdownMenu.Trigger>
                  <DropdownMenu.Content
                    side="bottom"
                    variant="soft"
                    style={{ width: 180 }}
                  >
                    <DropdownMenu.Item
                      color="red"
                      style={{ cursor: 'pointer' }}
                      onClick={() => revokeInvitation.mutate(invite.id)}
                    >
                      <p style={{ fontSize: 13, fontWeight: '450' }}>
                        Revoke
                      </p>
                      <CircleMinus
                        size={14}
                        style={{ marginLeft: 'auto' }}
                      />
                    </DropdownMenu.Item>
                  </DropdownMenu.Content>
                </DropdownMenu.Root>
              </div>
            </div>
          ))}

        {/* Updated empty state condition to check both members and invites */}
        {((!orgMembers || orgMembers.length === 0 || (orgMembers.length === 1 && orgMembers?.[0]?.is_owner))
          && (!invites || invites.length === 0)) && (
          <div className="flex flex-col items-center justify-center min-h-[420px] p-5 bg-gradient-to-b from-gray-50 via-gray-50 to-white dark:from-gray-900 dark:via-gray-900 dark:to-gray-800 relative overflow-hidden">
            {/* Background Decorative Elements and empty state content */}
            <div className="absolute inset-0 overflow-hidden">
              <div className="absolute top-1/4 -left-6 w-24 h-24 bg-blue-500 rounded-full mix-blend-multiply filter blur-xl opacity-10 animate-blob"></div>
              <div className="absolute top-1/4 -right-6 w-24 h-24 bg-purple-500 rounded-full mix-blend-multiply filter blur-xl opacity-10 animate-blob animation-delay-2000"></div>
              <div className="absolute bottom-1/4 left-1/3 w-24 h-24 bg-indigo-500 rounded-full mix-blend-multiply filter blur-xl opacity-10 animate-blob animation-delay-4000"></div>
            </div>

            <div className="max-w-3xl mx-auto text-center relative z-10">
              <div className="mb-8 relative">
                <div className="w-36 h-36 mx-auto relative">
                  <div className="absolute inset-0 bg-gradient-to-r from-blue-500 to-purple-500 rounded-full opacity-20 animate-pulse"></div>
                  <div className="absolute inset-2.5 bg-gradient-to-r from-blue-400 to-purple-400 rounded-full opacity-30"></div>
                  <div className="absolute inset-0 flex items-center justify-center">
                    <div className="relative w-20 h-20">
                      <div className="absolute inset-0 flex items-center justify-center animate-spin-slow">
                        {[...Array.from({ length: 8 })].map((_, i) => (
                          <div
                            key={i}
                            className="absolute w-1.5 h-1.5 bg-white rounded-full"
                            style={{
                              transform: `rotate(${i * 45}deg) translateY(-24px)`,
                            }}
                          >
                          </div>
                        ))}
                      </div>
                      <div className="absolute inset-2.5 bg-gradient-to-br from-white to-blue-100 dark:from-gray-800 dark:to-gray-700 rounded-full shadow-lg flex items-center justify-center">
                        <Users className="w-8 h-8 text-blue-500 dark:text-blue-400" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <p style={{ fontSize: 24, fontWeight: '450' }}>
                Build Your Dream Team
              </p>

              <p
                style={{
                  fontSize: 14,
                  fontWeight: '400',
                  color: 'GrayText',
                }}
              >
                Transform ideas into reality with the perfect team. Start by inviting your first member and watch your vision come to life.
              </p>

              <div className="flex flex-col items-center space-y-5 mt-2.5">
                <Button
                  onClick={() => setInvModalOpen(true)}
                  className="h-10 px-6 text-sm rounded-full transition-all hover:scale-105 hover:shadow-lg bg-gradient-to-r from-blue-500 to-indigo-500 hover:from-blue-600 hover:to-indigo-600"
                  size="lg"
                  style={{ cursor: 'pointer', borderRadius: 6 }}
                  color="iris"
                >
                  <UserPlus className="mr-2 h-4 w-4" />
                  Add Your First Team Member
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

function FeatureCard({ icon: Icon, title, description }) {
  return (
    <div className="group p-5 rounded-xl bg-white dark:bg-gray-800 shadow-md hover:shadow-xl transition-all duration-300 hover:-translate-y-0.5">
      <div className="w-8 h-8 mb-2.5 rounded-full bg-blue-100 dark:bg-gray-700 flex items-center justify-center group-hover:scale-110 transition-transform duration-300">
        <Icon className="h-2.5 w-2.5 text-blue-500 dark:text-blue-400" />
      </div>
      <h3 className="text-sm font-semibold mb-1.5">{title}</h3>
      <p className="text-gray-500 dark:text-gray-400 text-xs">{description}</p>
    </div>
  )
}

const DialogItem = forwardRef((props, forwardedRef) => {
  const {
    triggerChildren,
    icon,
    children,
    onSelect,
    onOpenChange,
    ...itemProps
  } = props
  return (
    <Dialog.Root onOpenChange={onOpenChange}>
      <Dialog.Trigger asChild>
        <DropdownMenu.Item
          {...itemProps}
          ref={forwardedRef}
          className="DropdownMenuItem"
          style={{ justifyContent: 'space-between', cursor: 'pointer' }}
          color={props.color ?? 'gray'}
          onSelect={(event) => {
            event.preventDefault()
            onSelect && onSelect()
          }}
        >
          <p style={{ fontSize: 13, fontWeight: '450' }}>{triggerChildren}</p>
          {icon}
        </DropdownMenu.Item>
      </Dialog.Trigger>
      <Dialog.Content
        maxWidth="450px"
        style={{ padding: 0 }}
        // className="DialogContent"
      >
        {children}
      </Dialog.Content>
    </Dialog.Root>
  )
})
