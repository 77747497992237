import { useState } from 'react'
import { BiCopy } from 'react-icons/bi'
import SyntaxHighlighter from 'react-syntax-highlighter/dist/esm/default-highlight'
import { vs2015 } from 'react-syntax-highlighter/dist/esm/styles/hljs'

import { toast } from 'react-toastify'
import styled from 'styled-components'

const Languages = [
  { label: 'JSON', value: 'json' },
  { label: 'JavaScript', value: 'javascript' },
  { label: 'Python', value: 'python' },
  { label: 'cURL', value: 'bash' },
]

export default function RevisedCodeEditor({ data, apiKey, collapseCode }) {
  const prettyJson = JSON.stringify(data, null, 4)

  const codeString = {
    bash: `curl -X POST 'https://api.bland.ai/v1/calls'
            -H 'Content-Type: application/json' 
            -H 'Authorization: '${apiKey}'
            -d '${prettyJson}`,
    json: prettyJson,
    javascript: `const response = await fetch('https://api.bland.ai/v1/tools', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': '${apiKey}'
            },
            body: JSON.stringify(${prettyJson})})\n\nconst data = await response.json()`,
    python: `import requests
        
headers = {
            'Authorization': '${apiKey}'
        }

data = ${prettyJson}
\n
response = requests.post('https://api.bland.ai/v1/tools', json=data, headers=headers)`,
  }

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(codeString[selected])
    return toast.info('Code copied to clipboard', {
      pauseOnHover: false,
      autoClose: 1000,
    })
  }

  const [selected, setSelected] = useState('javascript')
  return (
    <Wrapper>
      <Header>
        {Languages.map((language, index) => (
          <div
            style={{ cursor: 'pointer' }}
            key={index}
            onClick={() => setSelected(language.value)}
          >
            <p
              style={{
                color: selected === language.value ? '#000' : '#4B5563',
                fontWeight: selected === language.value ? '500' : '450',
              }}
            >
              {language.label}
            </p>
            {selected === language.value && <SelectedBar />}
          </div>
        ))}
      </Header>

      <CodeWrapper>
        <div
          onClick={() => handleCopyToClipboard()}
          style={{ marginLeft: 'auto', cursor: 'pointer' }}
        >
          <BiCopy />
        </div>

        <SyntaxHighlighter
          wrapLongLines
          style={vs2015}
          language={selected}
          customStyle={{
            background: 'none',
            backgroundColor: 'none',
            margin: 0,
            padding: 0,
            color: 'darkgrey',
            fontSize: 12,
            fontWeight: '550',
          }}
        >
          {codeString[selected]}
        </SyntaxHighlighter>
      </CodeWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.63rem;
  height: 100% !important;
  width: 100%;
  max-width: 440px;
  border-radius: 8px;
  border: 1px solid #e5e7eb;
`

const SelectedBar = styled.div`
  height: 2px;
  width: 100%;
  background: #000;
  border-radius: 8px;
  position: relative;
  bottom: -10px;
`

const CodeWrapper = styled.div`
  width: 100%;
  position: sticky;
  top: 0px;
  border-radius: 0px;
  padding: 24px;
  background-color: transparent;
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
`

const Header = styled.div`
  height: 48px;
  border-bottom: 1px solid #e5e7eb;
  padding: 16px 24px 0px 24px;
  display: flex;
  column-gap: 1.25rem;
  justify-content: flex-start;
  align-items: flex-start;
`
