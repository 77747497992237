import { useQuery } from '@tanstack/react-query'
import { $fetch } from 'utils/fetch'

/**
 * Custom hook to fetch SMS numbers data
 * 
 * @param {Object} options - Query options
 * @param {boolean} options.enabled - Whether to enable the query
 * @returns {Object} Query result object
 */
export function useSMSNumbers({ enabled = true }) {
  return useQuery({
    queryKey: ['smsNumbers'],
    queryFn: async () => {
      const { data } = await $fetch('/v1/sms/numbers')
      return {
        numbers: data || [], // This will be the array of phone number objects with sms_config
      }
    },
    staleTime: 1000 * 60 * 5, // 5 minutes
    enabled,
  })
} 