const rateLimitTemplateMap = {
  1: {
    description: 'Default',
    daily: 1000,
    hourly: 1000,
    minute: 1000,
  },
  2: {
    description: 'Enterprise',
    daily: 100000,
    hourly: 10000,
    minute: 5000,
  },
  3: {
    description: 'Unlimited',
    daily: 10000000,
    hourly: 100000,
    minute: 5000000,
  },
  4: {
    description: 'Banned',
    daily: 0,
    hourly: 0,
    minute: 0,
  },
  5: {
    description: 'Start',
    daily: 100,
    hourly: 100,
    minute: 100,
  },
  6: {
    description: 'Build',
    daily: 2000,
    hourly: 1000,
    minute: 1000,
  },
  7: {
    description: 'Scale',
    daily: 5000,
    hourly: 1000,
    minute: 1000,
  },
  8: {
    description: 'Camp Lejune',
    daily: 100000,
    hourly: 100000,
    minute: 100000,
  },
  9: {
    description: 'Limited/Flagged',
    daily: 2,
    hourly: 2,
    minute: 1,
  },
}

export function ReturnMap(rateLimitTemplate = null) {
  try {
    if (!rateLimitTemplate)
      console.error('Required: Rate Limit Template')
    return rateLimitTemplateMap[rateLimitTemplate]
  }
  catch (error) {
    return null
  }
}
