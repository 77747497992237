import { Button, Form, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader } from '@heroui/react'
import { useAuth } from 'hooks/useAuth'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { $fetch } from 'utils/fetch'

export default function ImpersonateModal({ isOpen, onOpenChange }) {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { handleAuthResponse } = useAuth()

  const onSubmit = async (e) => {
    e.preventDefault()
    setIsSubmitting(true)

    const { org_id } = Object.fromEntries(new FormData(e.currentTarget))

    try {
      const { data: { jwt } } = await $fetch(`/internal/impersonate`, {
        method: 'POST',
        body: { org_id },
      })

      handleAuthResponse({ jwt, org_id })
      toast.success('Impersonated organization successfully.')
      onOpenChange(false)
      window.location.reload()
    }
    catch (error) {
      console.error(error)
      const errorMessage = error.data?.errors?.[0]?.message
      toast.error(errorMessage)
    }
    finally {
      setIsSubmitting(false)
    }
  }
  return (
    <Modal
      isOpen={isOpen}
      placement="top-center"
      onOpenChange={onOpenChange}
      classNames={{
        footer: 'ml-auto',
        body: 'flex flex-col gap-5 w-full',
      }}
      radius="sm"
      size="xl"
    >
      <ModalContent>
        <Form validationBehavior="native" onSubmit={onSubmit}>
          <ModalHeader>
            Impersonate Organization
          </ModalHeader>
          <ModalBody>
            <Input
              isRequired
              label="Organization ID"
              type="text"
              placeholder="Enter the organization ID"
              variant="bordered"
              color="primary"
              name="org_id"
            />
          </ModalBody>
          <ModalFooter>
            <Button radius="sm" color="primary" type="submit" isLoading={isSubmitting}>
              Impersonate
            </Button>
          </ModalFooter>
        </Form>
      </ModalContent>
    </Modal>
  )
}
