import { Button, Tooltip } from '@radix-ui/themes'
import { Copy } from 'lucide-react'
import { toast } from 'react-toastify'

function CopyCallID({ id, maxLength = 5 }) {
  const shortId = id.substring(0, maxLength)

  const handleCopy = (event) => {
    event.stopPropagation()
    navigator.clipboard.writeText(id)
    toast.success('Call ID copied to clipboard', {
      autoClose: 1000,
    })
  }

  return (
    <div
      style={{
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        className: 'dont-close-note',
      }}
    >
      <Tooltip content={id}>
        <Button
          variant="soft"
          size="1"
          color="gray"
          className="dont-close-note"
          style={{
            cursor: 'pointer',
            width: 'fit-content',
            minWidth: '80px',
          }}
          onClick={handleCopy}
        >
          <p style={{ fontSize: 12 }} className="dont-close-note">
            {shortId}
            ...
          </p>
          <Copy size={12} className="dont-close-note" />
        </Button>
      </Tooltip>
    </div>
  )
}

export default CopyCallID
