import { Button, Input, Listbox, ListboxItem, Popover, PopoverContent, PopoverTrigger, Tab, Tabs, Tooltip } from '@heroui/react'
import { useFilter } from '@react-aria/i18n'
import VoiceGradientAvatar from 'components/Dashboard/SendCall/VoiceGradientReusable'
import { useAuth } from 'hooks/useAuth'
import { ChevronDown, CirclePlay, Play, Search } from 'lucide-react'
import { useMemo, useState } from 'react'
import { $fetch } from 'utils/fetch'

export default function VoiceSelect({ onChange, value }) {
  const [voicePlaying, setVoicePlaying] = useState(null)
  const [isOpen, setIsOpen] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [activeTab, setActiveTab] = useState('curated')
  const { org, user } = useAuth()

  const { contains } = useFilter({
    sensitivity: 'base', // Ignore case and diacritics
  })

  // Define voice categories
  const voiceCategories = useMemo(() => {
    return {
      library: {
        title: 'Library Voices',
        voices: user.voice_options
          .filter(
            voice =>
              voice.user_id === org?.id,
          ),
      },
      curated: {
        title: 'Curated Voices',
        voices: user.voice_options
          .filter(voice => voice.tags?.includes('Bland Curated') && !voice.user_id),
      },
    }
  }, [user, org])

  // Get active voices based on selected tab
  const activeVoices = useMemo(() => {
    return voiceCategories[activeTab]?.voices || []
  }, [voiceCategories, activeTab])

  // Filter voices based on search query
  const filteredVoices = useMemo(() => {
    if (!activeVoices)
      return []

    if (!searchQuery.trim())
      return activeVoices

    return activeVoices.filter(voice =>
      contains(voice.name, searchQuery)
      || (voice.description && contains(voice.description, searchQuery)),
    )
  }, [activeVoices, searchQuery, contains])

  const playSample = async (voiceId, e) => {
    // Prevent the event from reaching the ListboxItem
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }

    setVoicePlaying(voiceId)
    try {
      const audioBlob = await $fetch(`/v1/voices/${voiceId}/sample`, {
        method: 'POST',
        body: {
          text: 'Hey this is Blandie, how can I help today?',
          response_type: 'stream',
        },
        responseType: 'blob',
      })

      // Create audio from blob
      const audio = new Audio(URL.createObjectURL(audioBlob))

      // Play when ready and clean up when done
      audio.onended = () => URL.revokeObjectURL(audio.src)
      await audio.play()

      return audio
    }
    catch (error) {
      console.error('Failed to fetch TTS audio:', error)
    }
    finally {
      setTimeout(() => {
        setVoicePlaying(null)
      }, 2000)
    }
  }

  function renderVoiceItem(voice) {
    return (
      <ListboxItem
        key={voice.name}
        textValue={voice.name}
        startContent={(
          <div className="shrink-0">
            <VoiceGradientAvatar name={voice.id} size={24} isAnimating={voicePlaying === voice.id} hoverAnimation={false} isAnimated={false} />
          </div>
        )}
        description={voice.description}
        classNames={{
          wrapper: 'truncate',
        }}
        endContent={(
          <Button
            isIconOnly
            size="sm"
            color="primary"
            variant="faded"
            radius="full"
            isLoading={voicePlaying === voice.id}
            onClick={e => playSample(voice.id, e)}
            className="opacity-0 group-hover:opacity-100 transition-opacity duration-100 min-w-6 size-6"
          >
            <Play size={12} />
          </Button>
        )}
      >
        {voice.name}
      </ListboxItem>
    )
  }

  const handleChange = (keys) => {
    const selectedKey = Array.from(keys)[0]
    onChange(selectedKey)
    setIsOpen(false)
  }

  return (
    <div className="flex flex-col gap-2">
      <label className="text-small font-medium text-foreground">Voice</label>
      <Popover isOpen={isOpen} onOpenChange={setIsOpen} triggerScaleOnOpen={false} radius="sm" classNames={{ content: 'w-[600px]' }}>
        <PopoverTrigger>
          <Button
            fullWidth
            variant="bordered"
            radius="sm"
            disableRipple
            disableAnimation
            className="justify-between"
            endContent={<ChevronDown size={14} className={isOpen ? 'rotate-180 transition-transform' : 'transition-transform'} />}
          >
            {value
              ? (
                  <div className="flex items-center gap-2">
                    <VoiceGradientAvatar name={user.voice_options?.find(voice => voice.name === value)?.id ?? value} size={20} />
                    <span>{value}</span>
                  </div>
                )
              : (
                  'Select a voice'
                )}
          </Button>
        </PopoverTrigger>
        <PopoverContent>
          <div className="flex flex-col gap-1 pt-2 w-full">
            {/* Tabs for voice categories */}
            <Tabs
              selectedKey={activeTab}
              onSelectionChange={setActiveTab}
              size="sm"
              radius="none"
              classNames={{
                tabList: 'w-full',
                base: 'mb-2',
              }}
            >
              <Tab key="curated" title="Curated" />
              <Tab key="library" title="Library" />
            </Tabs>

            {/* Search filter */}
            <Input
              size="sm"
              radius="none"
              type="search"
              value={searchQuery}
              onChange={e => setSearchQuery(e.target.value)}
              startContent={<Search size={16} />}
              placeholder="Search voices..."
            />

            {/* Listbox for voices */}
            <Listbox
              aria-label="Select a voice"
              selectionMode="single"
              selectedKeys={[value]}
              onSelectionChange={handleChange}
              virtualization={{
                maxListboxHeight: 200,
                itemHeight: 40,
              }}
              classNames={{
                base: 'w-full max-h-[400px]',
              }}
              isVirtualized
              emptyContent={<div className="p-2 text-center text-default-400">No voices found</div>}
            >
              {filteredVoices.map(voice => renderVoiceItem(voice))}
            </Listbox>
          </div>
        </PopoverContent>
      </Popover>
    </div>
  )
}
