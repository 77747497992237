import {
  AlertTriangle,
  CheckCircle,
  ChevronDown,
  ChevronRight,
} from 'lucide-react'
import { useState } from 'react'

function RenderUnitTestResults({ results }) {
  const [isExpanded, setIsExpanded] = useState(false)
  if (
    !results
    || !results.unitTestResults
    || Object.keys(results.unitTestResults).length === 0
  ) {
    return null
  }

  return (
    <div className="w-full">
      <div className="flex items-center justify-between">
        <button
          onClick={() => setIsExpanded(!isExpanded)}
          className="flex items-center focus:outline-none text-2xs font-medium mr-2.5"
        >
          {isExpanded
            ? (
                <ChevronDown className="w-2 h-2 mr-0.5" />
              )
            : (
                <ChevronRight className="w-2 h-2 mr-0.5" />
              )}
          {isExpanded ? 'Hide Details' : 'Show Details'}
        </button>
        <div className="flex items-center">
          {results.flag
            ? (
                <AlertTriangle className="w-2.5 h-2.5 text-red-500 mr-0.5" />
              )
            : (
                <CheckCircle className="w-2.5 h-2.5 text-green-500 mr-0.5" />
              )}
          <span
            className={`text-2xs font-medium ${
              results.flag ? 'text-red-500' : 'text-green-500'
            }`}
          >
            {results.flag ? 'Failed' : 'Passed'}
          </span>
        </div>
      </div>

      {isExpanded && (
        <div className="mt-1.5 space-y-0.5 text-2xs">
          {Object.entries(results.unitTestResults).map(([testName, result]) => (
            <div key={testName} className="flex items-start">
              <div className="flex-shrink-0 w-2.5 mt-0.5 mr-0.5">
                {result.passed
                  ? (
                      <CheckCircle className="w-2 h-2 text-green-500" />
                    )
                  : (
                      <AlertTriangle className="w-2 h-2 text-red-500" />
                    )}
              </div>
              <div className="flex-grow min-w-0">
                <div className="font-medium text-gray-600 truncate">
                  {testName}
                </div>
                <div className="text-gray-500 break-words">
                  {result.passed ? 'Pass' : result.reason}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default RenderUnitTestResults
