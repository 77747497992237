import { $fetch } from 'utils/fetch'
import { getOrgId } from '../browser/getOrgId'

export async function getOrgsBillingData() {
  try {
    const org_id = getOrgId()
    const data = await $fetch(`/orgs/${org_id}/billing`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    const res = {
      current_balance: 0,
      refill_amount: null,
      refill_at: null,
      refill_enabled: false,
    }
    if (data.data) {
      res.current_balance = data.data.current_balance
      res.refill_amount = Number.parseInt(data.data.refill_amount ?? '0')
      res.refill_enabled = res.refill_amount > 0
      res.refill_at = Number.parseInt(data.data.refill_at ?? '0')
    }
    return res
  }
  catch (error) {
    console.error('Error fetching refill amount:', error)
    throw error
  }
}
