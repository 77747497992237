import Button from 'components/core/Button'
import Input from 'components/core/Input'
import Label from 'components/core/Label'
import { useEffect } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import styled from 'styled-components'

export default function Metadata({ fieldName }) {
  const { control, getValues, setValue, watch } = useFormContext()

  const { fields, append, remove } = useFieldArray({
    control,
    name: fieldName,
  })

  useEffect(() => {
    const currRequestData = getValues(fieldName)
    setValue(fieldName, currRequestData)
  }, [fieldName, getValues, setValue, watch])

  const onAdd = () => {
    append({ key: '', value: '' })
  }
  const description = (
    <span>
      Add any additional information you want to associate with the call. This
      can be useful for tracking or categorizing calls.
      <br></br>
      <br></br>
      Anything that you put here will be returned in your webhook or in the call
      details under metadata.
    </span>
  )

  return (
    <Wrapper>
      <span>
        <Label>MetaData</Label>
      </span>
      <p className="text-muted-foreground mb-1.5">
        Add any additional information you want to associate with the call. This
        can be useful for tracking or categorizing calls. Anything that you put
        here will be returned in your webhook or in the call details under
        metadata.
      </p>
      {fields.length > 0
        ? (
            <>
              <KeyValueWrapper>
                {fields.map((param, index) => (
                  <KeyValueGrid key={param.id}>
                    <KeyValueItem>
                      <Input
                        fieldName={`${fieldName}[${index}].key`}
                        placeholder="Key"
                        style={{ borderRadius: '0px', border: 'none' }}
                      />
                    </KeyValueItem>
                    <KeyValueItem>
                      <Input
                        fieldName={`${fieldName}[${index}].value`}
                        placeholder="Value"
                        style={{
                          borderRadius: '0px',
                          border: 'none',
                          width: '100%',
                        }}
                      />
                    </KeyValueItem>
                    <KeyValueItem>
                      <Button
                        onClick={() => remove(index)}
                        appearance="outline"
                        style={{
                          border: 'none',
                          borderRadius: '0px',
                          width: '100%',
                          height: '100%',
                        }}
                      >
                        -
                      </Button>
                    </KeyValueItem>
                  </KeyValueGrid>
                ))}
              </KeyValueWrapper>
              <Button
                onClick={onAdd}
                appearance="outline"
                style={{ width: 'auto !important' }}
              >
                + Metadata
              </Button>
            </>
          )
        : (
            <Button
              onClick={onAdd}
              appearance="outline"
              style={{ width: 'auto !important' }}
            >
              + Metadata
            </Button>
          )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  align-items: start;
`

const KeyValueWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid #eeeeee;
  gap: 1px;
`

const KeyValueGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 45px;
  background-color: #fbfbfa;
  grid-gap: 1px;
  width: 100%;
`

const KeyValueItem = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  align-items: center;
  outline: 1px solid #eeeeee;
`
