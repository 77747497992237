import { createContext, useState } from 'react'

const ClientSecretContext = createContext()

function ClientSecretProvider({ children }) {
  const [clientSecret, setClientSecret] = useState('')

  return (
    <ClientSecretContext.Provider value={[clientSecret, setClientSecret]}>
      {children}
    </ClientSecretContext.Provider>
  )
}

export {  ClientSecretProvider }
