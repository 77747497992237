import { Button } from '@/components/ui/button'
import { Check, Plus } from 'lucide-react'
// HallucinationContent.js

function HallucinationContent({
  message,
  alternativeResponses,
  selectedResponseIndex,
  setSelectedResponseIndex,
  customResponse,
  setCustomResponse,
  handleAddToFinetuningExample,
  hallucinationExampleAdded,
}) {
  return (
    <div className="relative space-y-2.5 bg-white hover:bg-white w-[600px] overflow-x-clip">
      <div>
        <label className="block text-xs font-medium text-gray-700 mb-0.5">
          Original Response:
        </label>
        <div className="p-2 bg-gray-50 rounded-md text-gray-800">
          {message.content?.trim()}
        </div>
      </div>
      <h3 className="text-sm font-semibold text-gray-800 mb-1.5">
        Suggested alternative responses:
      </h3>
      {alternativeResponses.map((response, index) => (
        <Button
          key={index}
          onClick={() => setSelectedResponseIndex(index)}
          className={`flex w-[600px] flex-wrap break-words bg-white hover:bg-white h-auto p-2.5 text-left justify-start items-start rounded-md transition-all duration-200 ease-in-out ${
            selectedResponseIndex === index
              ? 'border-2 border-indigo-500'
              : 'border border-gray-200'
          }`}
        >
          <div className="flex items-start">
            <div className="flex-shrink-0 mt-0.5 mr-1.5">
              {selectedResponseIndex === index && (
                <Check className="h-3 w-3 text-blue-500" />
              )}
            </div>
            <div className="text-gray-800 break-words whitespace-normal">
              {response}
            </div>
          </div>
        </Button>
      ))}
      <Button
        onClick={() => setSelectedResponseIndex(null)}
        className={`h-auto p-2.5 text-left justify-start w-full rounded-md transition-all duration-200 ease-in-out ${
          selectedResponseIndex === null
            ? 'bg-indigo-50 hover:bg-indigo-100 border-2 border-indigo-500'
            : 'bg-white hover:bg-white border border-gray-200'
        }`}
      >
        <div className="flex items-start w-full">
          <div className="flex-shrink-0 mt-0.5 mr-1.5">
            {selectedResponseIndex === null && (
              <Check className="h-3 w-3 text-blue-500" />
            )}
          </div>
          <div className="text-gray-800">Other</div>
        </div>
      </Button>
      {selectedResponseIndex === null && (
        <div className="mt-2.5">
          <p className="text-gray-800 mb-1.5">Enter your expected response:</p>
          <textarea
            value={customResponse}
            onChange={e => setCustomResponse(e.target.value)}
            placeholder={message.content?.trim()}
            rows={3}
            className="mt-1.5 w-full p-2.5 border border-gray-300 focus:outline-none focus:border-indigo-500 rounded shadow-sm resize-none text-gray-800"
          />
        </div>
      )}

      <Button
        onClick={handleAddToFinetuningExample}
        disabled={
          selectedResponseIndex === null
          && customResponse.trim() === ''
          && hallucinationExampleAdded
        }
        className="mt-2.5 bg-green-500 hover:bg-green-600 text-white font-semibold py-1.5 px-2.5 rounded-md shadow-md transition-all duration-200 ease-in-out"
      >
        {hallucinationExampleAdded
          ? (
              <>
                <Check className="mr-1.5 h-2.5 w-2.5" />
                Added to Fine-tuning Examples
              </>
            )
          : (
              <>
                <Plus className="mr-1.5 h-2.5 w-2.5" />
                Add to Node's Fine-tuning Example
              </>
            )}
      </Button>
    </div>
  )
}

export default HallucinationContent
