import {
  Button,
  Dialog,
  DropdownMenu,
  Flex,
  Spinner,
  TextField,
} from '@radix-ui/themes'
import Gradient from 'assets/images/gradient.png'
import { useAuth } from 'hooks/useAuth'
import {
  EllipsisIcon,
  LogOut,
  Plus,
  Search,
  Users,
  X,
} from 'lucide-react'
import { useMemo, useState } from 'react'
import { ThreeDots } from 'react-loader-spinner'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { CreateOrg, LeaveOrg } from 'utils/funcs/orgs/orgs'

export default function OrgHome() {
  const { orgs, loading, selectOrg, fetchOrgs } = useAuth()
  const [loadingCreateOrg, setLoadingCreateOrg] = useState(false)
  const navigate = useNavigate()
  const [orgName, setOrgName] = useState('')
  const [modalOpen, setModalOpen] = useState(false)
  const [slugError, setSlugError] = useState('')
  const [searchQuery, setSearchQuery] = useState('')
  const [loadingLeaveOrg, setLoadingLeaveOrg] = useState(false)

  const validateSlug = (slug) => {
    const slugRegex = /^[a-z]+$/
    return slugRegex.test(slug)
  }

  const handleOrgNameChange = (e) => {
    let newSlug = e.target.value.toLowerCase()
    if (newSlug && !validateSlug(newSlug)) {
      setSlugError(
        'Slug must contain only lowercase letters, no numbers or special characters.',
      )
    }
    else {
      setSlugError('')
    }

    newSlug = newSlug.replace(/[^a-z]/g, '')
    setOrgName(newSlug)
  }

  const handleCreateOrg = async () => {
    const { success, message, data } = await CreateOrg(
      orgName,
      loadingCreateOrg,
      setLoadingCreateOrg,
    )

    if (success) {
      setOrgName('')
      setModalOpen(false)
      navigate(`/dashboard/settings`)
      return toast.success(message)
    }
    else {
      return toast.error(message)
    }
    await fetchOrgs()
  }

  const handleLeaveOrg = async (orgId) => {
    const { success, message } = await LeaveOrg(
      orgId,
      loadingLeaveOrg,
      setLoadingLeaveOrg,
    )

    if (success) {
      toast.success(message)
    }
    else {
      toast.error(message)
    }
    await fetchOrgs()
  }

  const filteredOrgs = useMemo(() => {
    return searchQuery === ''
      ? orgs
      : orgs.filter(
          org =>
            org.org_display_name
              .toLowerCase()
              .includes(searchQuery.toLowerCase()), // Filter logic
        )
  }, [orgs, searchQuery]) // Added searchQuery to dependencies

  return (
    <div style={{ padding: 0 }}>
      <div
        style={{
          marginTop: 25,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <p
          style={{
            fontSize: 18,
            fontWeight: '450',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            columnGap: 8,
          }}
        >
          All Orgs
          {' '}
          <p style={{ fontSize: 18, fontWeight: '450', color: 'GrayText' }}>
            {loading || loadingLeaveOrg
              ? (
                  <Spinner size="2" />
                )
              : (
                  orgs?.length || 0
                )}
          </p>
        </p>

        <div
          style={{
            marginLeft: 'auto',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            columnGap: 10,
            flexWrap: 'wrap',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Search
              style={{
                position: 'relative',
                left: 25,
                zIndex: 200,
              }}
              size={14}
              color="gray"
            />
            <TextField.Root
              style={{
                padding: '0px 7px 0px 27px', // Adjusted padding to make space for the icon
                borderRadius: 6,
                fontSize: 14,
                width: '100%',
                background: '#f9f9f9',
              }}
              placeholder="Search for an org..."
              value={searchQuery}
              onChange={e => setSearchQuery(e.target.value)}
            />
          </div>

          <Dialog.Root open={modalOpen} onOpenChange={setModalOpen}>
            <Dialog.Trigger>
              <Button
                // loading={loadingCreateOrg}
                // onClick={() => {}}
                variant="outline"
                color="gray"
                size="2"
                style={{
                  background: '#343a40',
                  color: '#fff',
                  cursor: 'pointer',
                  borderRadius: 6,
                  marginLeft: 0,
                }}
              >
                <Plus size={16} />
                <p style={{ fontSize: 14, fontWeight: '500' }}>
                  New Organization
                </p>
              </Button>
            </Dialog.Trigger>

            <Dialog.Content style={{ padding: 0 }}>
              <div
                style={{
                  padding: 15,
                  borderBottom: '1px solid #e9e9e9',
                  background: '#ffffff',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <p style={{ fontSize: 13, fontWeight: '550' }}>
                  Create new Organization
                </p>
                <X
                  size={14}
                  style={{ cursor: 'pointer' }}
                  onClick={() => setModalOpen(false)}
                />
              </div>

              <div style={{ padding: 15, background: '#f4f4f4' }}>
                <p
                  style={{
                    fontSize: 13,
                    fontWeight: '450',
                    marginTop: 0,
                    marginBottom: 6,
                  }}
                >
                  Organization Name
                </p>
                <TextField.Root
                  size="3"
                  value={orgName}
                  onChange={e => handleOrgNameChange(e)}
                  placeholder="johnbland"
                  style={{ background: '#f4f4f4' }}
                />
                <p
                  style={{
                    fontSize: 10,
                    fontWeight: '400',
                    color: 'GrayText',
                    marginTop: 6,
                  }}
                >
                  Organization name must be all lowercase and only contain
                  letters.
                </p>

                {slugError && (
                  <p
                    style={{
                      fontSize: 10,
                      fontWeight: '400',
                      color: 'red',
                      marginTop: 3,
                    }}
                  >
                    {slugError}
                  </p>
                )}
              </div>

              <Flex
                justify="end"
                gap="3"
                style={{
                  width: '100%',
                  padding: 15,
                  borderTop: '1px solid lightgray',
                }}
              >
                <Dialog.Close>
                  <Button
                    size="2"
                    variant="outline"
                    color="gray"
                    style={{ cursor: 'pointer', borderRadius: 6 }}
                  >
                    <p style={{ fontSize: 13, fontWeight: '500' }}>Close</p>
                  </Button>
                </Dialog.Close>
                <Button
                  size="2"
                  loading={loadingCreateOrg}
                  disabled={loadingCreateOrg}
                  onClick={handleCreateOrg}
                  color="iris"
                  variant="solid"
                  style={{ cursor: 'pointer', borderRadius: 6 }}
                >
                  <p style={{ fontSize: 13, fontWeight: '500' }}>Create</p>
                </Button>
              </Flex>
            </Dialog.Content>
          </Dialog.Root>
        </div>
      </div>

      {loadingLeaveOrg ? (
        <div
          className="flex items-center justify-center h-[400px]"
          style={{
            border: '1px solid #e9e9e9',
            borderRadius: 10,
            marginTop: 25,
          }}
        >
          <ThreeDots color="#eeeeee" height={40} width={40} />
        </div>
      ) : (
        <div style={{ marginTop: 25 }}>
          <div
            style={{
              width: '100%',
              borderRadius: 10,
              border: '1px solid #e9e9e9',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {filteredOrgs
              && filteredOrgs?.length > 0
              && filteredOrgs?.map((org, index) => (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    padding: '15px 15px',
                    borderBottom:
                      orgs?.length - 1 === index ? 'none' : '1px solid #f3f3f3',
                  }}
                  key={index}
                >
                  <img src={Gradient} alt="" className="w-5 h-5 rounded-full" />

                  <div style={{ marginLeft: 20 }}>
                    <p style={{ fontSize: 13, fontWeight: '450' }}>
                      {org?.org_display_name}
                    </p>
                    <p
                      style={{
                        fontSize: 12,
                        fontWeight: '350',
                        color: 'GrayText',
                      }}
                    >
                      {org?.permissions?.[0]}
                    </p>
                  </div>

                  <div style={{ marginLeft: 'auto' }}>
                    <DropdownMenu.Root dir="rtl">
                      <DropdownMenu.Trigger>
                        <Button
                          size="2"
                          variant="ghost"
                          color="gray"
                          radius="full"
                          // className="text-gray-500 hover:text-gray-700"
                          style={{ cursor: 'pointer' }}
                        >
                          <EllipsisIcon
                            className="w-4 h-6"
                            color="gray"
                            strokeWidth={1}
                          />
                        </Button>
                      </DropdownMenu.Trigger>
                      <DropdownMenu.Content
                        color="gray"
                        variant="soft"
                        style={{ width: 180 }}
                      >
                        {(org.permissions?.includes('owner')
                          || org.permissions?.includes('admin')) && (
                          <DropdownMenu.Item
                            onClick={() => {
                              selectOrg(org.org_id)
                              navigate(`/dashboard/settings?tab=users`)
                            }}
                            style={{
                              cursor: 'pointer',
                              justifyContent: 'space-between',
                            }}
                          >
                            <p style={{ fontSize: 13, fontWeight: '450' }}>
                              View Users
                            </p>

                            <Users size={14} style={{ marginLeft: 'auto' }} />
                          </DropdownMenu.Item>
                        )}

                        {(org.permissions.includes('operator')
                          || org.permissions.includes('viewer')
                          || org.permissions.includes('admin')) && (
                          <DropdownMenu.Item
                            color="red"
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleLeaveOrg(org.org_id)}
                          >
                            <p style={{ fontSize: 14, fontWeight: '500' }}>
                              Leave Org
                            </p>
                            <LogOut size={14} style={{ marginLeft: 'auto' }} />
                          </DropdownMenu.Item>
                        )}
                      </DropdownMenu.Content>
                    </DropdownMenu.Root>
                  </div>
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  )
}
