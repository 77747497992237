import { toast } from 'react-toastify'
import { $fetch } from 'utils/fetch'
import { queryClient } from 'utils/queryClient'

async function fetchRecording(c_id) {    
  return await queryClient.fetchQuery({
    queryKey: ['recording', c_id],
    queryFn: () => $fetch(`/v1/recordings/${c_id}?RequestedChannels=2`, {
      responseType: 'blob',
    }),
    staleTime: Infinity,
  })
}

export async function prefetchRecording(c_id) {    
  queryClient.prefetchQuery({
    queryKey: ['recording', c_id],
    queryFn: () => $fetch(`/v1/recordings/${c_id}?RequestedChannels=2`, {
      responseType: 'blob',
    }),    
  })
}

export async function downloadRecording(c_id) {
  const recordingBlob = await fetchRecording(c_id)

  if (recordingBlob) {
    const audioUrl = URL.createObjectURL(recordingBlob)

    const link = document.createElement('a')
    link.href = audioUrl
    link.download = `recording_${c_id}.wav`
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)

    // Cleanup: revoke the object URL to free up memory
    URL.revokeObjectURL(audioUrl)
  }
  else {
    toast.error('Failed to download the recording')
  }
}
