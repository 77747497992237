import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'
import { RxCross2 } from 'react-icons/rx'

export default function NewFolderDialog({ open, onClose, onCreate }) {
  const [newFolderName, setNewFolderName] = useState('')

  const handleCreate = () => {
    if (newFolderName.trim()) {
      onCreate(newFolderName)
      setNewFolderName('')
      onClose()
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault() // Prevent the default form submission
    handleCreate() // Call the create handler
  }

  return (
    <Transition show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={onClose}
      >
        <div className="min-h-screen px-2.5 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
          </Transition.Child>

          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-md p-4 my-5 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-xl">
              <Dialog.Title
                as="h3"
                className="text-sm font-medium leading-4 text-gray-900"
              >
                Create New Folder
              </Dialog.Title>
              <button
                onClick={onClose}
                className="absolute top-2 right-2 text-gray-400 hover:text-gray-500"
              >
                <RxCross2 className="h-4 w-4" aria-hidden="true" />
              </button>
              <form onSubmit={handleSubmit} className="mt-2.5">
                <input
                  type="text"
                  value={newFolderName}
                  onChange={e => setNewFolderName(e.target.value)}
                  placeholder="Folder Name"
                  className="w-full px-2 py-1.5 border border-gray-300 rounded shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                />

                <div className="mt-4 flex justify-end space-x-2">
                  <button
                    type="button"
                    className="inline-flex justify-center px-2.5 py-1.5 text-xs font-medium text-gray-700 bg-white border border-gray-300 rounded hover:bg-gray-50 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-indigo-500"
                    onClick={onClose}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit" // Changed to type="submit"
                    className="inline-flex justify-center px-2.5 py-1.5 text-xs font-medium text-white bg-indigo-600 border border-transparent rounded hover:bg-indigo-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-indigo-500"
                  >
                    Create
                  </button>
                </div>
              </form>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}
