import { Button } from '@/components/ui/button'
import { ChevronRight } from 'lucide-react'

function StepOne({ message, handleIssueTypeSelect }) {
  return (
    <div className="flex flex-col space-y-2.5 w-full px-5 pb-1.5">
      <div className="mb-4 space-y-2.5">
        <div>
          <label className="block text-xs font-medium text-gray-700 mb-0.5">
            Prompt:
          </label>
          <div className="p-2 bg-gray-50 rounded-md text-gray-800">
            {message.promptInfo?.['Current Node Prompt/Text'] || ''}
          </div>
        </div>
        <div>
          <label className="block text-xs font-medium text-gray-700 mb-0.5">
            Response:
          </label>
          <div className="p-2 bg-gray-50 rounded-md text-gray-800">
            {message.content?.trim()}
          </div>
        </div>
      </div>
      {['Hallucination', 'Wrong Node'].map(type => (
        <Button
          key={type}
          onClick={() =>
            handleIssueTypeSelect(type.toLowerCase().replace(' ', '_'))}
          className="h-10 text-sm bg-white hover:bg-gray-50 text-gray-800 border border-gray-200 rounded-md shadow-sm transition-all duration-200 ease-in-out transform hover:scale-105"
        >
          <span className="flex-grow text-left">{type}</span>
          <ChevronRight className="h-3 w-3 text-gray-400" />
        </Button>
      ))}
    </div>
  )
}

export default StepOne
