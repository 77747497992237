import { Button, Form, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, useDisclosure } from '@heroui/react'
import { useAuth } from 'hooks/useAuth'
import { Pencil } from 'lucide-react'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { $fetch } from 'utils/fetch'

export default function UpdateInfoModal({ firstName, lastName, email }) {
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { fetchUser } = useAuth()

  const onSubmit = async (e) => {
    e.preventDefault()
    setIsSubmitting(true)

    const data = Object.fromEntries(new FormData(e.currentTarget))

    try {
      await $fetch(`/user`, {
        method: 'PATCH',
        body: data,
      })

      toast.success('Your information has been updated.')
      fetchUser()
      onClose()
    }
    catch (error) {
      console.error(error)
      const errorMessage = error.data?.errors?.[0]?.message
      toast.error(errorMessage)
    }
    finally {
      setIsSubmitting(false)
    }
  }
  return (
    <>
      <Button size="sm" variant="flat" onClick={onOpen}>
        <Pencil size={16} />
        Edit
      </Button>
      <Modal
        isOpen={isOpen}
        placement="top-center"
        onOpenChange={onOpenChange}
        classNames={{
          footer: 'ml-auto',
          body: 'flex flex-col gap-5 w-full',
        }}
        radius="sm"
        size="xl"
      >
        <ModalContent>
          <Form validationBehavior="native" onSubmit={onSubmit}>
            <ModalHeader>
              Update Personal Information
            </ModalHeader>
            <ModalBody>
              <div className="flex gap-4">
                <Input
                  isRequired
                  label="First Name"
                  placeholder="Enter your first name"
                  defaultValue={firstName}
                  variant="bordered"
                  color="primary"
                  name="first_name"
                  pattern="[A-Za-z\s]+"
                />
                <Input
                  isRequired
                  label="Last Name"
                  placeholder="Enter your last name"
                  defaultValue={lastName}
                  variant="bordered"
                  color="primary"
                  name="last_name"
                  pattern="[A-Za-z\s]+"
                />
              </div>
              <Input
                isRequired
                label="Email"
                type="email"
                placeholder="Enter your email"
                defaultValue={email}
                variant="bordered"
                color="primary"
                name="email"
              />
            </ModalBody>
            <ModalFooter>
              <Button radius="sm" color="primary" type="submit" isLoading={isSubmitting}>
                Update
              </Button>
            </ModalFooter>
          </Form>
        </ModalContent>
      </Modal>
    </>
  )
}
