import { forwardRef, Tab, Tabs } from '@heroui/react'
import { MessageSquareMore, PhoneMissed, PhoneOff, Voicemail } from 'lucide-react'
import { useEffect, useState } from 'react'

const actionOptions = [
  {
    label: 'Hangup',
    key: 'hangup',
    icon: <PhoneOff size={16} />,
  },
  {
    label: 'Ignore',
    key: 'ignore',
    icon: <PhoneMissed size={16} />,
  },
  {
    label: 'Leave Message',
    key: 'leave_message',
    icon: <Voicemail size={16} />,
  },
  {
    label: 'Leave SMS',
    key: 'leave_sms',
    icon: <MessageSquareMore size={16} />,
  },
]

const VoicemailSelect = forwardRef(({ value, onChange, ...props }, ref) => {
  const [selectedKey, setSelectedKey] = useState(value || actionOptions[0].key)

  useEffect(() => {
    if (value) {
      setSelectedKey(value)
    }
  }, [value])

  const handleSelectionChange = (key) => {
    setSelectedKey(key)
    if (onChange) {
      onChange(key)
    }
  }

  return (
    <div ref={ref} {...props}>
      <div className="mb-1">
        <label className="text-small font-medium text-foreground">Voicemail Action</label>
        <p className="text-tiny text-foreground-400">This is processed separately from the AI's decision making, and overrides it.</p>
      </div>
      <Tabs
        selectedKey={selectedKey}
        onSelectionChange={handleSelectionChange}
        fullWidth
        size="sm"
        variant="solid"
        radius="none"
      >
        {actionOptions.map(option => (
          <Tab
            key={option.key}
            title={(
              <div className="flex items-center gap-2">
                {option.icon}
                <span>{option.label}</span>
              </div>
            )}
          />
        ))}
      </Tabs>
    </div>
  )
})

export default VoicemailSelect
