import axios from 'axios'
import config from 'config'
import { getOrgId } from 'utils/funcs/browser/getOrgId'

export async function generateEncryptedKey(api_key, account_sid, auth_token) {
  const orgId = getOrgId()
  const headers = {
    authorization: api_key,
    ...(orgId && { 'x-bland-org-id': orgId }),
  }
  try {
    console.log({ api_key, account_sid, auth_token })
    const { data } = await axios.post(
      `${config.API_URL}/v1/accounts`,
      { account_sid, auth_token },
      { headers },
    )
    console.log('encrypted key', data)
    return data
  }
  catch (error) {
    console.log('generate encrypted key', error)
    return error.message
  }
}
