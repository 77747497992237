import { Tooltip } from '@/components/ui/tooltip'
import { Button } from '@heroui/react'
import config from 'config'
import { useAuth } from 'hooks/useAuth'
import { useContext, useState } from 'react'
import { toast } from 'react-toastify'
import { getAuthToken } from 'utils/funcs/browser/getAuthToken'
import { getOrgId } from 'utils/funcs/browser/getOrgId'
import PathFill from '../../../../../assets/icons/simulation_path-fill.svg'
import Sparkles from '../../../../../assets/icons/simulation_prompt-16-regular.svg'
import ThumbsDown from '../../../../../assets/icons/simulations_thumbs_down.svg'
import FlowContext from '../../contextFlow'
import FineTuneModal from '../fine-tune/FineTuneModal'
import PromptEditSidebar from '../fine-tune/promptEditSidebar'

function ChatMessage({
  message,
  isHighlighted,
  isActive,
  onMessageClick,
  nodeInfo,
  setIsExpanded,
  isReplayMode,
  messageId,
  activeMessageId,
  onActionClick,
}) {
  const [isSubmittingFlag, setIsSubmittingFlag] = useState(false)
  const [showFineTuneModal, setShowFineTuneModal] = useState(false)
  const { user } = useAuth()
  const orgId = getOrgId()

  const { id, callId } = useContext(FlowContext)

  const isSidebarOpen = activeMessageId === messageId

  const handleFlagSubmission = async () => {
    if (!message.promptInfo)
      return

    setIsSubmittingFlag(true)
    try {
      const data = {
        call_id: message.promptInfo['Call ID'],
        pathway_id: message.promptInfo['Graph ID'],
        bad_output: message.promptInfo['Assistant Response'],
        global_prompt: message.promptInfo['Global Prompt'],
        prompt: message.promptInfo['Current Node Prompt/Text'],
        dialogue_example: message.promptInfo['Dialogue Example'],
        chat_history: message.promptInfo['Conversation History'],
      }

      const response = await fetch(`${config.API_URL}/v1/pathway/flag_node`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'authorization': getAuthToken(),
          ...(orgId && { 'x-bland-org-id': orgId }),
        },
        body: JSON.stringify(data),
      })

      if (!response.ok) {
        throw new Error('Failed to submit flag')
      }

      toast.success('Response flagged successfully')
    }
    catch (error) {
      console.error('Error flagging response:', error)
      toast.error('Failed to flag response')
    }
    finally {
      setIsSubmittingFlag(false)
    }
  }

  const handleActionClick = (e) => {
    // e.stopPropagation()
    if (messageId) {
      setIsExpanded(true)
    }
    onActionClick(messageId)
  }

  const renderMessageActions = () => {
    if (!message.promptInfo) {
      return null
    }
    else if (message.role === 'assistant') {
      return (
        <div className="flex gap-1.5 mt-1.5 pr-9">
          <Button
            variant="light"
            size="sm"
            onPress={handleActionClick}
            className="mr-auto text-2xs font-medium h-5 px-2 tracking-tighter text-gray-500 gap-0.5"
          >
            <img src={Sparkles} alt="sparkles" className="opacity-50" />
            Fine Tune
          </Button>

          <Tooltip content="Fine-Tune Decisions">
            <button
              className="text-gray-950 hover:text-gray-700 transition-colors"
              onClick={(e) => {
                e.stopPropagation()
                setShowFineTuneModal(true)
              }}
            >
              <img src={PathFill} alt="path fill" />
            </button>
          </Tooltip>

          <Tooltip content="Flag Response">
            <button
              className="text-gray-950 hover:text-gray-700 transition-colors"
              onClick={(e) => {
                e.stopPropagation()
                handleFlagSubmission()
              }}
              disabled={isSubmittingFlag}
            >
              <img
                src={ThumbsDown}
                alt="thumbs down"
                className={isSubmittingFlag ? 'opacity-50' : ''}
              />
            </button>
          </Tooltip>

          {showFineTuneModal && (
            <FineTuneModal
              isOpen={showFineTuneModal}
              onClose={() => setShowFineTuneModal(false)}
              message={message}
              callID={callId}
            />
          )}

          {isSidebarOpen && (
            <PromptEditSidebar
              isOpen={isSidebarOpen}
              onClose={() => onActionClick(null)}
              decision={message.promptInfo}
            />
          )}
        </div>
      )
    }
    return null
  }

  return (
    <div
      className={`w-full mb-5 cursor-pointer transition-all duration-300 ${
        isHighlighted ? 'scale-[1.02]' : ''
      }`}
      onClick={isReplayMode ? onMessageClick : undefined}
    >
      <div
        className={`relative p-2.5 rounded ${
          message.role === 'user'
            ? 'bg-[#F1F3F5] border border-[#E5E7EB] ml-10'
            : 'bg-[#D7E7FF] mr-10'
        } ${!isActive ? 'opacity-50' : 'opacity-100'} ${
          isReplayMode ? '' : 'cursor-default'
        } ${
          isSidebarOpen
            ? 'border-2 border-blue-400 shadow-[0_0_10px_rgba(59,130,246,0.2)]'
            : ''
        }`}
      >
        <div className="text-gray-950 text-[14px] font-medium leading-[20px] font-inter">
          {message.content || message.text}
        </div>
      </div>
      {renderMessageActions()}
    </div>
  )
}

export default ChatMessage
