import { Trash2 } from 'lucide-react'

function NodeTag({ nodeTag, setNodeTag }) {
  return (
    <div className="border border-gray-20 p-2.5 rounded-md shadow-sm overflow-hidden">
      <div className="flex flex-row items-center justify-between">
        <h3 className="text-lg text-gray-900">Node Tag</h3>
        {nodeTag && (
          <div
            className="flex flex-row items-center gap-1 px-1.5 py-px bg-[#e6e6e6] rounded cursor-pointer"
            onClick={() => {
              setNodeTag(null)
            }}
          >
            <p className="text-2xs font-medium text-gray-600">Remove Tag</p>
            <Trash2 size={12} color="grayText" />
          </div>
        )}
      </div>

      <p className="text-sm text-gray-500 mt-0.5 mb-2">
        Add a tag to your call when this node gets executed
      </p>

      <div className="mt-1.5">
        <p>Tag Name</p>
        <input
          className="w-full mt-0.5 p-1.5 border border-gray-300 rounded text-xs focus:ring-indigo-500 focus:border-indigo-500 mb-2"
          value={nodeTag?.name || ''}
          onChange={(event) => {
            if (nodeTag === null) {
              setNodeTag({
                name: event.target.value,
                color: '#000000',
              })
            }
            else {
              setNodeTag({
                ...nodeTag,
                name: event.target.value,
              })
            }
          }}
        />
      </div>

      <div className="mt-1">
        <p>Tag Color</p>
        <div className="mt-1.5 flex flex-row items-center justify-between w-full">
          <input
            className="w-full mr-1.5 mt-0 p-1.5 border border-gray-300 rounded text-xs focus:ring-indigo-500 focus:border-indigo-500 mb-2"
            value={nodeTag?.color || '#000000'}
            placeholder="#000000"
            onChange={(event) => {
              setNodeTag({
                ...nodeTag,
                color: event.target.value,
              })
            }}
          />

          <input
            className="border-0 p-0 m-0 h-[37px] w-[37px] bg-transparent rounded-none outline-none cursor-pointer -mt-[7px]"
            type="color"
            value={nodeTag?.color}
            onChange={(event) => {
              setNodeTag({
                ...nodeTag,
                color: event.target.value,
              })
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default NodeTag
