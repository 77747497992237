import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import Button from 'components/core/Button'
import Select from 'components/core/Select'
import config from 'config'
import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { toast } from 'react-toastify'
import { getAuthToken } from 'utils/funcs/browser/getAuthToken'
import { getOrgId } from 'utils/funcs/browser/getOrgId'
import LightTooltip from './ToolTipDesc'

export default function AnalysisPreset({ fieldName, isEventPage = false }) {
  const [presets, setPresets] = useState([])
  const [loading, setLoading] = useState(true)
  const { setValue, watch } = useFormContext()
  const orgId = getOrgId()
  const authToken = getAuthToken()
  const currentPreset = watch(
    isEventPage ? `analysis_preset.value` : fieldName,
  )

  useEffect(() => {
    fetchPresets()
  }, [])

  const fetchPresets = async () => {
    try {
      const response = await fetch(`${config.API_URL}/analysis_presets`, {
        headers: {
          Authorization: authToken,
          ...(orgId && { 'x-bland-org-id': orgId }),
        },
      })
      const jsonResponse = await response.json()
      if (Array.isArray(jsonResponse.data)) {
        setPresets(jsonResponse.data)
      }
      else {
        throw new TypeError('Invalid data format')
      }
      setLoading(false)
    }
    catch (error) {
      toast.error('Error fetching analysis presets.')
      setLoading(false)
    }
  }

  const handlePresetChange = (e) => {
    // if (isEventPage) {
    //   return setValue(fieldName, {
    //     using_key: false,
    //     value: e.target.value === "none" ? null : e.target.value,
    //   });
    // }
    setValue(
      isEventPage ? `analysis_preset.value` : fieldName,
      e.target.value === 'none' ? null : e.target.value,
    )
  }

  const openPresetsInNewTab = () => {
    window.open('/dashboard/analytics?tab=analysis_presets', '_blank')
  }

  const description = (
    <span>
      Select an Analysis Preset to use for this call. Analysis Presets define
      the structure for extracting specific information from the call.
    </span>
  )

  return (
    <div className="flex flex-col gap-1.5">
      <div className="flex justify-between items-center">
        <Select
          fieldName={isEventPage ? `analysis_preset.value` : fieldName}
          label={(
            <span>
              Analysis Preset
              <LightTooltip title={description} placement="right-start" arrow>
                <HelpOutlineIcon
                  color="primary"
                  style={{ paddingLeft: '4px' }}
                />
              </LightTooltip>
            </span>
          )}
          onChange={handlePresetChange}
          disabled={loading}
          value={currentPreset === null ? 'none' : currentPreset || ''}
        >
          <option value="">None</option>
          {presets.map(preset => (
            <option key={preset.id} value={preset.id}>
              {preset.name}
            </option>
          ))}
        </Select>
        <Button
          appearance="outline"
          onClick={openPresetsInNewTab}
          style={{
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '4px',
            height: '35px', // Adjust this value to match your design
            padding: '0 12px',
          }}
        >
          <span>Manage Presets</span>
          <OpenInNewIcon style={{ fontSize: '16px' }} />
        </Button>
      </div>
      {loading && <p className="text-xs text-gray-500">Loading presets...</p>}
    </div>
  )
}
