import { Button } from '@/components/ui/button'
import { File, X } from 'lucide-react'
import { useDropzone } from 'react-dropzone'
import {
  PiFileAudio,
  PiFileCsv,
  PiFileDoc,
  PiFilePdf,
  PiFileText,
  PiFileVideo,
} from 'react-icons/pi'

function DragDropUpload({
  onFilesAdded,
  uploadedFiles,
  multiple = false,
  accept = { 'audio/*': [] },
}) {
  const getReadableFormats = () => {
    const mimeTypes = Object.keys(accept)
    const formatMap = {
      'application/pdf': 'PDF',
      'audio/mp3': 'MP3',
      'video/mp4': 'MP4',
      'text/plain': 'TXT',
      'text/csv': 'CSV',
      'application/msword': 'DOC',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        'DOCX',
      'audio/mpeg': 'MPEG',
      'audio/m4a': 'M4A',
      'audio/x-m4a': 'M4A',
    }

    return mimeTypes
      .map(mime => formatMap[mime] || mime.split('/')[1])
      .filter((value, index, self) => self.indexOf(value) === index) // Remove duplicates
      .join(', ')
  }

  const formatFileSize = (bytes) => {
    if (!bytes)
      return 'Unknown size'
    if (bytes < 1024)
      return `${bytes} bytes`
    if (bytes < 1024 * 1024)
      return `${(bytes / 1024).toFixed(1)} KB`
    return `${(bytes / 1024 / 1024).toFixed(2)} MB`
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => {
      onFilesAdded(multiple ? acceptedFiles : acceptedFiles.slice(0, 1))
    },
    multiple,
    accept,
  })

  const isAudioOnly = Object.keys(accept).every(type =>
    type.startsWith('audio/'),
  )

  const isCsvOnly = Object.keys(accept).every(type => type === 'text/csv')

  return (
    <div
      {...getRootProps()}
      className={`flex flex-col gap-2.5 w-full ${
        isDragActive ? 'bg-gray-100' : 'bg-gray-50'
      } p-8 items-center justify-center rounded-md border-dashed border-2 border-gray-200`}
    >
      <input {...getInputProps()} />

      {isAudioOnly
        ? (
            <PiFileAudio size={30} className="text-gray-600" />
          )
        : isCsvOnly
          ? (
              <PiFileCsv size={30} className="text-gray-600" />
            )
          : (
              <div className="flex items-center justify-center gap-0.5">
                <PiFilePdf size={24} className="text-gray-600 rotate-6" />
                <PiFileDoc size={30} className="text-gray-600 -rotate-6" />
                <PiFileText size={36} className="text-gray-600" />
                <PiFileAudio size={30} className="text-gray-600 -rotate-6" />
                <PiFileVideo size={24} className="text-gray-600 rotate-6" />
              </div>
            )}

      <p className="text-gray-700 font-medium text-xs">
        Drag and drop
        {' '}
        {multiple ? 'files' : 'a file'}
        {' '}
        here or
        {' '}
        <span className="underline cursor-pointer">click</span>
        {' '}
        to upload
      </p>

      <p className="text-gray-500 text-xs">
        Supported formats:
        {' '}
        {getReadableFormats()}
      </p>

      {uploadedFiles.length > 0 && (
        <div className="flex flex-col gap-1.5 w-full">
          {uploadedFiles.map((file, index) => (
            <div
              key={index}
              className="bg-white rounded-md border w-full border-gray-200 p-2 flex gap-2.5 items-center justify-between"
            >
              <div className="flex gap-2 items-center min-w-0">
                <File size={24} className="shrink-0 text-gray-400" />
                <div className="flex flex-col min-w-0">
                  <span className="font-medium text-xs text-gray-900 truncate">
                    {file.name}
                  </span>
                  <div className="flex gap-1.5 text-2xs text-gray-500">
                    <span className="truncate">
                      {file.type || 'Unknown type'}
                    </span>
                    <span className="shrink-0">•</span>
                    <span className="shrink-0">
                      {file.size
                        ? formatFileSize(file.size)
                        : 'Unknown size'}
                    </span>
                  </div>
                </div>
              </div>
              <Button
                variant="icon"
                type="button"
                onClick={(e) => {
                  e.stopPropagation()
                  onFilesAdded(uploadedFiles.filter((_, i) => i !== index))
                }}
                className="shrink-0 text-gray-400 hover:text-gray-600 !p-1.5"
              >
                <X size={16} />
              </Button>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default DragDropUpload
