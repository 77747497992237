import { $fetch } from 'utils/fetch'

export async function uploadVoiceClone(files, voiceName) {
  try {
    const formData = new FormData()
    formData.append('name', voiceName)

    files.forEach((file, index) => {
      formData.append(`audio_samples`, file)
    })

    const { data, errors } = await $fetch('/v1/voices', {
      method: 'POST',
      body: formData,
    })
    if (errors) {
      throw new Error(errors)
    }
    return data
  }
  catch (error) {
    console.error('Error uploading voice clone:', error)
    throw error
  }
}
