import axios from 'axios'
import Button from 'components/core/Button'
import Modal from 'components/core/Modal'
import { PageTitle } from 'components/core/PageTitle'
import Tabs from 'components/core/Tabs'
import config from 'config'
import { useAuth } from 'hooks/useAuth'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { $fetch } from 'utils/fetch'
import { getAuthToken } from 'utils/funcs/browser/getAuthToken'
import { getOrgId } from 'utils/funcs/browser/getOrgId'
import PulsingLight from '../../../../assets/gifs/PulsingLight.gif'
import CloseIcon from '../../../../assets/icons/close-icon-black.svg'
import ServerIcon from '../../../../assets/icons/server-icon.svg'
import DailyCallsBarChart from './CallChart'
import ServerRack from './InfraMap'
import PingGauge from './Ping'
import StaticTable from './static_table'
import AtlassianStyleStatusBar from './statusBar'

const RATE_LIMITS = [
  {
    value: 'start',
    label: 'Start',
    hourly: '100',
    hourly_val: 100,
    daily_val: 100,
    daily: '100',
    concurrent: '10',
    price: 'Default',
    rate_limit_template: 5,
    voice_limit: 0,
  },
  {
    value: 'build',
    label: 'Build',
    daily: '2,000',
    daily_val: 2000,
    hourly: '1,000',
    hourly_val: 1000,
    concurrent: '50',
    price: '$299',
    rate_limit_template: 6,
    voice_limit: 5,
  },
  {
    value: 'scale',
    label: 'Scale',
    daily: '5,000',
    daily_val: 5000,
    hourly_val: 1000,
    hourly: '1,000',
    concurrent: '100',
    price: '$499',
    rate_limit_template: 7,
    voice_limit: 15,
  },
  {
    value: 'enterprise',
    label: 'Enterprise',
    daily: 'Unlimited',
    daily_val: 100000,
    hourly_val: 10000,
    hourly: 'Unlimited',
    concurrent: 'Unlimited',
    price: 'Contact Us',
    rate_limit_template: [2, 3],
    voice_limit: 'Unlimited',
  },
]

export function ServerAndLimits() {
  const [user_rate_limit_template, set_user_rate_limit_template]
    = useState(null)
  const { org } = useAuth()
  const [loadingIndex, setLoadingIndex] = useState(-1)
  const [loading, setLoading] = useState(false)
  const [prePurchaseModal, setPrePurchaseModal] = useState(false)
  const [cancelModal, setCancelModal] = useState(false)
  const [focusedPlan, setFocusedPlan] = useState({
    rateLimit: null,
    index: null,
  })
  const [lastSevenCalls, setLastSevenCalls] = useState([])
  const [placementGroup, setPlacementGroup] = useState(null)
  const orgId = getOrgId()

  function getMatchingRateLimitTemplate(userRateLimitTemplate) {
    console.log(org?.org_rate_limit, userRateLimitTemplate)
    if (org?.org_type === 'enterprise') {
      return RATE_LIMITS.find(plan => plan.value === 'enterprise')
    }

    return RATE_LIMITS.find((plan) => {
      if (Array.isArray(plan.rate_limit_template)) {
        return plan.rate_limit_template.includes(userRateLimitTemplate)
      }
      return plan.rate_limit_template === userRateLimitTemplate
    })
  }

  useEffect(() => {
    const fetchData = async () => {
      const [callsData] = await Promise.all([
        $fetch(`/analytics/last_seven_calls`, {}, false),
      ])

      // 1a) Process calls for charts
      if (callsData.data) {
        const now = new Date()

        const processedData = callsData.data
          .map(item => ({
            hour: new Date(item.hour),
            count: Number.parseInt(item.count, 10) || 0,
          }))
          .filter(item => item.hour <= now)
          .sort((a, b) => a.hour - b.hour)
        console.log(processedData)
        setLastSevenCalls(processedData)
      }
    }
    fetchData()
  }, [])

  const refreshUserData = async () => {
    set_user_rate_limit_template(org?.org_rate_limit)

    const newPlacementGroup = org?.placement_group
    setPlacementGroup(newPlacementGroup)
  }

  const handleSubscribeToPlan = async (rateLimit, index) => {
    setLoadingIndex(index)
    if (rateLimit.rate_limit_template === user_rate_limit_template) {
      return await handleCancelFromPlan(index)
    }

    const type
      = rateLimit.rate_limit_template === 6
        ? 'subscribe_build'
        : rateLimit.rate_limit_template === 7
          ? 'subscribe_scale'
          : ''

    let url = ''
    if (type === 'subscribe_build') {
      url = `${config.BILLING_ENDPOINT}/subscribe_build`
    }
    else if (type === 'subscribe_scale') {
      url = `${config.BILLING_ENDPOINT}/subscribe_scale`
    }

    const response = await axios({
      url,
      method: 'POST',
      headers: {
        'authorization': getAuthToken(),
        'content-type': 'application/json',
        ...(orgId && { 'x-bland-org-id': orgId }),
      },
      validateStatus: false,
    })

    if (response?.data) {
      if (response.status === 400) {
        setLoadingIndex(-1)

        if (response?.data?.message === 'not_complete_billing') {
          window.location.href = '/dashboard/billing-details'
          return toast.error('Payment Method not set up.')
        }

        if (response?.data?.message === 'payment_failed') {
          return toast.error('Payment Failed. Try again later.')
        }
      }

      if (
        (response.status === 200)
        & (response?.data?.data?.status === 'active')
      ) {
        await refreshUserData()
        setLoadingIndex(-1)
        return toast.success('Successfully Scaled Up.')
      }
      else {
        setLoadingIndex(-1)
        return toast.error('Unexpected Error.')
      }
    }

    return setLoadingIndex(-1)
  }

  const handleCancelFromPlan = async (index) => {
    try {
      const res = await $fetch(`/orgs/${orgId}/billing/session`)
      window.open(res.data?.redirect_url, '_blank')

      setLoadingIndex(-1)
    }
    catch (error) {
      setLoadingIndex(-1)
      return toast.error('Unexpected Error.', error)
    }
  }

  const handleCalculateRateLimitUsage = async () => {
    try {
      setLoading(true)
      const localizeDate = (utcDate) => {
        const date = new Date(utcDate)
        return date.toISOString().slice(0, 10)
      }

      const response = await $fetch(
        `/v1/calls?created_at=${localizeDate(new Date())}&limit=7`,
        {
          method: 'GET',
        },
      )

      if (!user_rate_limit_template)
        return console.log('NO_USER_RL_TEMPLATE_FOUND')
      const template = RATE_LIMITS.find((doc) => {
        if (
          org?.org_type === 'enterprise'
          || user_rate_limit_template === 2
          || user_rate_limit_template === 3
          || (placementGroup !== 'blandshared' && placementGroup !== 'bland')
        ) {
          return doc.value === 'enterprise'
        }
        else {
          return doc.rate_limit_template === user_rate_limit_template
        }
      })
      if (!template)
        return console.log('NO_TEMPLATE_FOUND')

      const dayUsage = Number(
        ((response?.total_count / template.daily_val) * 100).toFixed(2),
      )

      return setUsageData({
        hourUsage: 0,
        dayUsage,
        dayStr: `${response?.total_count} of ${template.daily} Calls`,
        hourStr: '',
      })
    }
    catch (error) {}
  }

  const checkIfDisabled = (rateLimit, index) => {
    let state = 'Contact Us'
    let isDisabled = false

    // If org is enterprise type
    if (org?.org_type === 'enterprise') {
      if (rateLimit.value === 'enterprise') {
        return { state: 'Current Plan', isDisabled: true }
      }
      // Disable all non-enterprise plans
      return { state: 'Lower Plan', isDisabled: true }
    }

    // For non-enterprise orgs, use existing rate limit template logic
    if (!user_rate_limit_template) {
      console.log('NO_USER_RL_TEMPLATE_FOUND')
      return { state, isDisabled: true }
    }

    const user_act_rl_template = RATE_LIMITS.find((doc) => {
      if (user_rate_limit_template === 2 || user_rate_limit_template === 3) {
        return doc.value === 'enterprise'
      }
      return doc.rate_limit_template === user_rate_limit_template
    })

    if (!user_act_rl_template) {
      console.log('NO_TEMPLATE_FOUND')
      return { state, isDisabled: true }
    }

    if (index === 3 && user_rate_limit_template === 2) {
      return { state: 'On Enterprise Plan', isDisabled: true }
    }

    if (
      Array.isArray(user_act_rl_template.rate_limit_template)
      && index === 3
    ) {
      return { state: 'Contact Us', isDisabled: false }
    }

    if (
      rateLimit.rate_limit_template === user_act_rl_template.rate_limit_template
    ) {
      if (rateLimit.value === 'start') {
        state = 'Base Plan'
        isDisabled = true
      }
      else {
        state = 'Cancel Plan'
        isDisabled = false
      }
    }
    else {
      if (
        index !== 3
        && Array.isArray(user_act_rl_template.rate_limit_template)
      ) {
        return { state: 'Lower Scale', isDisabled: false }
      }
      if (
        user_act_rl_template.rate_limit_template > rateLimit.rate_limit_template
      ) {
        state = 'Lower Plan'
        isDisabled = true
      }
      else if (
        user_act_rl_template.rate_limit_template < rateLimit.rate_limit_template
      ) {
        state = 'Increase Scale'
        isDisabled = false
      }
    }

    return { state, isDisabled }
  }

  useEffect(() => {
    if (user_rate_limit_template && placementGroup) {
      handleCalculateRateLimitUsage().finally(() => {
        setLoading(false)
      })
    }
  }, [user_rate_limit_template, placementGroup])

  useEffect(() => {
    refreshUserData()
  }, [])

  if (!loading && !user_rate_limit_template)
    return <div />
  if (loading)
    return <div />

  function aggregateHourlyToDaily(hourlyData, dailyRateLimit = 2000) {
    // 1) Build a dictionary { 'YYYY-MM-DD': sumOfCounts }
    const dailyTotals = {}

    hourlyData.forEach((rec) => {
      // Extract just "YYYY-MM-DD" from e.g. "2024-12-19T07:00:00Z"
      const dayStr = rec.hour.toISOString().slice(0, 10)
      dailyTotals[dayStr] = (dailyTotals[dayStr] || 0) + rec.count
    })

    // 2) Convert dictionary => array in the shape the chart expects
    const dailyData = Object.entries(dailyTotals).map(([date, callsUsed]) => {
      // Ensure callsUsed is non-negative
      const usedClamped = Math.max(callsUsed, 0)
      return {
        date, // e.g. "2024-12-19"
        callsUsed: usedClamped, // daily sum of calls
        remaining: Math.max(dailyRateLimit - usedClamped, 0),
      }
    })

    // 3) Sort by date ascending (optional)
    dailyData.sort((a, b) => (a.date < b.date ? -1 : 1))

    return dailyData
  }
  console.log(getMatchingRateLimitTemplate(user_rate_limit_template))

  return (
    <Wrapper style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
      <PageTitle style={{ fontSize: 16, fontWeight: '550' }}>
        Account Usage
      </PageTitle>

      {/* Optionally, usage progress bar can go here... */}

      {/*
        This container is now forced to at most half the viewport height
        with overflow if needed.
      */}
      <div className="w-full bg-gray-50 rounded border border-gray-200 max-h-[70vh] rounded-xl p-2.5 flex flex-col h-full ">
        <div className="w-full px-2.5 py-2.5 font-semibold">Server Status</div>
        <div className="w-full justify-between">
          <AtlassianStyleStatusBar />

          <div className="grid grid-cols-12 gap-2.5 ">
            {/* Charts Section - 7 columns */}
            <div className="col-span-5">
              <div className="h-full">
                <DailyCallsBarChart
                  lastSevenCalls={lastSevenCalls}
                  dailyRateLimit={
                    getMatchingRateLimitTemplate(user_rate_limit_template)
                      ?.daily_val
                  }
                  width={500}
                  height={250}
                  mockData={false}
                  chartScale={0.8}
                />
              </div>
            </div>

            {/* Ping Gauge - 2 columns */}
            <div className="col-span-2">
              <div className="h-full flex">
                <PingGauge />
              </div>
            </div>

            {/* Server Rack - 3 columns */}
            <div className="col-span-5">
              <div className="h-full flex">
                <ServerRack rate_limit_template={user_rate_limit_template} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style={{ marginTop: 10 }}>
        <Tabs
          relative
          tabs={[
            {
              item: 'Limits',
              panel: (
                <Container>
                  {RATE_LIMITS.map((rateLimit, index) => (
                    <Box
                      key={index}
                      style={{
                        border:
                          (user_rate_limit_template === 2
                            || user_rate_limit_template === 3
                            || user_rate_limit_template === 8)
                          && rateLimit?.value === 'enterprise'
                            ? '1px solid #3a86ff'
                            : '1px solid #eeeeee',
                        boxShadow:
                          (user_rate_limit_template === 2
                            || user_rate_limit_template === 3
                            || user_rate_limit_template === 8)
                          && rateLimit?.value === 'enterprise'
                            ? '0px 0px 10px 0px rgba(58,134,255,0.5)'
                            : 'none',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          width: '100%',
                        }}
                      >
                        <PageTitle style={{ fontSize: 16, fontWeight: '550' }}>
                          {rateLimit.label}
                          {(rateLimit.rate_limit_template
                            === user_rate_limit_template
                            || (user_rate_limit_template === 2
                              && rateLimit.value === 'enterprise')
                            || (user_rate_limit_template === 3
                              && rateLimit.value === 'enterprise')) && (
                            <span
                              style={{
                                marginLeft: '8px',
                                display: 'inline-block',
                              }}
                            >
                              <img
                                src={PulsingLight}
                                height={14}
                                width={14}
                                alt="Active plan"
                              />
                            </span>
                          )}
                        </PageTitle>
                      </div>
                      <div
                        id="daily-row"
                        style={{
                          borderTop: '2px solid #eeeeee',
                          borderLeft: '2px solid #eeeeee',
                          borderRight: '2px solid #eeeeee',
                          marginTop: 25,
                          overflow: 'hidden',
                        }}
                      >
                        <div
                          style={{
                            padding: 10,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            width: '100%',
                            justifyContent: 'space-between',
                          }}
                        >
                          <div
                            style={{
                              padding: '2px 6px',
                              background: '#eeeeee',
                            }}
                          >
                            <PageTitle
                              style={{
                                fontSize: 9,
                                fontWeight: '450',
                                color: 'grey',
                              }}
                            >
                              Daily Cap
                            </PageTitle>
                          </div>
                          <div
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <p
                              style={{
                                color: '#000',
                                fontSize: 12,
                                fontWeight: '450',
                              }}
                            >
                              {rateLimit.daily}
&nbsp;
                            </p>
                            <p
                              style={{
                                color: 'grey',
                                fontSize: 10,
                                fontWeight: '400',
                              }}
                            >
                              calls
                            </p>
                          </div>
                        </div>
                      </div>

                      <div
                        id="hourly-row"
                        style={{
                          borderTop: '1px solid #eeeeee',
                          borderLeft: '2px solid #eeeeee',
                          borderRight: '2px solid #eeeeee',
                          marginTop: 0,
                          overflow: 'hidden',
                        }}
                      >
                        <div
                          style={{
                            padding: 10,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            width: '100%',
                            justifyContent: 'space-between',
                          }}
                        >
                          <div
                            style={{
                              padding: '2px 6px',
                              background: '#eeeeee',
                            }}
                          >
                            <PageTitle
                              style={{
                                fontSize: 9,
                                fontWeight: '450',
                                color: 'grey',
                              }}
                            >
                              Hourly Cap
                            </PageTitle>
                          </div>
                          <div
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <p
                              style={{
                                color: '#000',
                                fontSize: 12,
                                fontWeight: '450',
                              }}
                            >
                              {rateLimit.hourly}
&nbsp;
                            </p>
                            <p
                              style={{
                                color: 'grey',
                                fontSize: 10,
                                fontWeight: '400',
                              }}
                            >
                              calls
                            </p>
                          </div>
                        </div>
                      </div>

                      <div
                        id="concurrency-row"
                        style={{
                          borderTop: '1px solid #eeeeee',
                          borderLeft: '2px solid #eeeeee',
                          borderRight: '2px solid #eeeeee',
                          marginTop: 0,
                          overflow: 'hidden',
                        }}
                      >
                        <div
                          style={{
                            padding: 10,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            width: '100%',
                            justifyContent: 'space-between',
                          }}
                        >
                          <div
                            style={{
                              padding: '2px 6px',
                              background: '#eeeeee',
                            }}
                          >
                            <PageTitle
                              style={{
                                fontSize: 9,
                                fontWeight: '450',
                                color: 'grey',
                              }}
                            >
                              Concurrency
                            </PageTitle>
                          </div>
                          <div
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <p
                              style={{
                                color: '#000',
                                fontSize: 12,
                                fontWeight: '450',
                              }}
                            >
                              {rateLimit.concurrent}
&nbsp;
                            </p>
                            <p
                              style={{
                                color: 'grey',
                                fontSize: 10,
                                fontWeight: '400',
                              }}
                            >
                              calls
                            </p>
                          </div>
                        </div>
                      </div>

                      <div
                        id="voice-limit-row"
                        style={{
                          borderTop: '1px solid #eeeeee',
                          borderLeft: '2px solid #eeeeee',
                          borderRight: '2px solid #eeeeee',
                          marginTop: 0,
                          overflow: 'hidden',
                        }}
                      >
                        <div
                          style={{
                            padding: 10,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            width: '100%',
                            justifyContent: 'space-between',
                          }}
                        >
                          <div
                            style={{
                              padding: '2px 6px',
                              background: '#eeeeee',
                            }}
                          >
                            <PageTitle
                              style={{
                                fontSize: 9,
                                fontWeight: '450',
                                color: 'grey',
                              }}
                            >
                              Voice Limit
                            </PageTitle>
                          </div>
                          <div
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <p
                              style={{
                                color: '#000',
                                fontSize: 12,
                                fontWeight: '450',
                              }}
                            >
                              {rateLimit.voice_limit}
&nbsp;
                            </p>
                            <p
                              style={{
                                color: 'grey',
                                fontSize: 10,
                                fontWeight: '400',
                              }}
                            >
                              clones
                            </p>
                          </div>
                        </div>
                      </div>

                      <div
                        id="concurrency-row"
                        style={{
                          borderTop: '1px solid #eeeeee',
                          borderLeft: '2px solid #eeeeee',
                          borderRight: '2px solid #eeeeee',
                          borderBottom: '2px solid #eeeeee',
                          marginTop: 0,
                          overflow: 'hidden',
                        }}
                      >
                        <div
                          style={{
                            padding: 10,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            width: '100%',
                            justifyContent: 'space-between',
                          }}
                        >
                          <div
                            style={{
                              padding: '2px 6px',
                              background: '#eeeeee',
                            }}
                          >
                            <PageTitle
                              style={{
                                fontSize: 9,
                                fontWeight: '450',
                                color: 'grey',
                              }}
                            >
                              Cost
                            </PageTitle>
                          </div>
                          <div
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <p
                              style={{
                                color: '#000',
                                fontSize: 12,
                                fontWeight: '450',
                              }}
                            >
                              {rateLimit.price}
&nbsp;
                            </p>
                            <p
                              style={{
                                color: 'grey',
                                fontSize: 10,
                                fontWeight: '400',
                              }}
                            >
                              {rateLimit.price === 'Default'
                                || rateLimit.price === 'Contact Us'
                                ? ''
                                : '/ month'}
                            </p>
                          </div>
                        </div>
                      </div>

                      <div style={{ marginTop: 20 }}>
                        <Button
                          loading={loadingIndex === index}
                          onClick={() => {
                            if (index === 3) {
                              window.location.href = '/enterprise'
                            }
                            else {
                              setFocusedPlan({
                                rateLimit,
                                index,
                              })

                              if (
                                rateLimit.rate_limit_template
                                === user_rate_limit_template
                              ) {
                                setCancelModal(true)
                              }
                              else {
                                setPrePurchaseModal(true)
                              }
                            }
                          }}
                          disabled={
                            checkIfDisabled(rateLimit, index).isDisabled
                          }
                          appearance={
                            checkIfDisabled(rateLimit, index)?.isDisabled
                            && 'outline'
                          }
                          style={{ borderRadius: 2 }}
                        >
                          <p>{checkIfDisabled(rateLimit, index).state}</p>
                        </Button>
                      </div>
                    </Box>
                  ))}
                </Container>
              ),
            },
            {
              item: 'Dedicated Infra',
              panel: (
                <InfraContainer>
                  <StaticTable />
                  {/* When auto finetuning is fully live, uncomment this to display it */}
                  {/* <PageTitle
                    style={{ fontSize: 10, fontWeight: "550", marginTop: 0 }}
                  >
                    Fine-Tuning
                  </PageTitle>
                  <div
                    style={{
                      width: "100%",
                      height: 2,
                      background: "#eeeeee",
                      marginTop: -20,
                    }}
                  />
                  <div
                    style={{
                      padding: "0px",
                      border: "none",
                      borderRadius: 1,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      marginTop: -15,
                    }}
                  >
                    <div
                      id="image-box"
                      style={{
                        padding: "5px 5px",
                        background: "#eeeeee",
                        height: 24,
                        width: 24,
                      }}
                    >
                      <img
                        src={FineTuneIcon}
                        style={{ height: 14, width: 14 }}
                      />
                    </div>
                    <div style={{ marginLeft: "25px", maxWidth: "75%" }}>
                      <PageTitle style={{ fontWeight: "500", fontSize: 16 }}>
                        Automatic Fine-Tuning
                      </PageTitle>
                      <p
                        style={{
                          fontSize: 13,
                          fontWeight: "400",
                          color: "grey",
                        }}
                      >
                        Automatically have Bland fine-tune Inference, STT and
                        TTS models based on your call recordings and data on
                        Bland.
                      </p>
                    </div>
                    <Button
                      onClick={() => {
                        window.location.href = "/enterprise";
                      }}
                      style={{
                        borderRadius: 6,
                        height: 30,
                        width: 60,
                        marginLeft: "auto",
                      }}
                    >
                      <p style={{ fontSize: 13, fontWeight: "600" }}>Enable</p>
                    </Button>
                  </div> */}
                </InfraContainer>
              ),
            },
          ]}
        />
      </div>

      <Modal open={prePurchaseModal} onClose={() => setPrePurchaseModal(false)}>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <p style={{ margin: 0, padding: 0, fontWeight: '500', fontSize: 16 }}>
            Confirm Purchase
          </p>
          <img
            onClick={() => setPrePurchaseModal(false)}
            style={{ cursor: 'pointer' }}
            src={CloseIcon}
          />
        </div>
        <p style={{ color: 'grey', fontSize: 13, fontWeight: '450' }}>
          This is a non-refundable purchase.
        </p>
        <div
          style={{
            border: '1px solid #eeeeee',
            borderRadius: 2,
            marginTop: 15,
            padding: '10px 10px',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <img src={ServerIcon} height={18} width={18} />
            <div style={{ marginLeft: 20 }}>
              <p style={{ fontSize: 14, fontWeight: '500' }}>
                {focusedPlan?.rateLimit?.label}
                {' '}
                Infrastructure
              </p>
              <p style={{ fontSize: 11, fontWeight: '450', color: 'grey' }}>
                {focusedPlan?.rateLimit?.daily}
                {' '}
                calls per day |
                {' '}
                {focusedPlan?.rateLimit?.hourly}
                {' '}
                calls per hour
              </p>
            </div>
            <div style={{ marginLeft: 'auto' }}>
              <p style={{ fontSize: 14, fontWeight: '500' }}>
                {focusedPlan?.rateLimit?.price}
                /m
              </p>
            </div>
          </div>
        </div>
        <Button
          onClick={() => {
            handleSubscribeToPlan(focusedPlan?.rateLimit, focusedPlan?.index)
            setPrePurchaseModal(false)
            setFocusedPlan({ rateLimit: null, index: null })
          }}
          style={{ borderRadius: 6, height: 35, marginTop: 20 }}
        >
          <p style={{ fontSize: 14, fontWeight: '550' }}>Confirm Purchase</p>
        </Button>
      </Modal>

      <Modal open={cancelModal} onClose={() => setCancelModal(false)}>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <p style={{ margin: 0, padding: 0, fontWeight: '500', fontSize: 16 }}>
            Cancel Plan
          </p>
          <img
            onClick={() => setPrePurchaseModal(false)}
            style={{ cursor: 'pointer' }}
            src={CloseIcon}
          />
        </div>
        <p style={{ color: 'grey', fontSize: 13, fontWeight: '450' }}>
          You will not be able to undo this once complete. Your plan will
          automatically be downgraded to START plan.
        </p>
        <div
          style={{
            border: '1px solid #eeeeee',
            borderRadius: 2,
            marginTop: 15,
            padding: '10px 10px',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <img src={ServerIcon} height={18} width={18} />
            <div style={{ marginLeft: 20 }}>
              <p style={{ fontSize: 14, fontWeight: '500' }}>
                {focusedPlan?.rateLimit?.label}
                {' '}
                Infrastructure
              </p>
              <p style={{ fontSize: 11, fontWeight: '450', color: 'grey' }}>
                {focusedPlan?.rateLimit?.daily}
                {' '}
                calls per day |
                {' '}
                {focusedPlan?.rateLimit?.hourly}
                {' '}
                calls per hour
              </p>
            </div>
            <div style={{ marginLeft: 'auto' }}>
              <p style={{ fontSize: 14, fontWeight: '500' }}>Cancel</p>
            </div>
          </div>
        </div>
        <Button
          onClick={() => {
            setLoadingIndex(focusedPlan?.index)
            handleCancelFromPlan(focusedPlan?.index)
            setCancelModal(false)
            setFocusedPlan({ rateLimit: null, index: null })
          }}
          style={{
            borderRadius: 6,
            height: 35,
            marginTop: 20,
            background: '#e63946',
          }}
        >
          <p style={{ fontSize: 14, fontWeight: '550' }}>
            Cancel
            {' '}
            {focusedPlan?.rateLimit?.label}
            {' '}
            Plan
          </p>
        </Button>
      </Modal>
    </Wrapper>
  )
}

// Styled Components
const Wrapper = styled.div`
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;
`

const InfraContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 35px;
`

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 25px;
  column-gap: 20px;
  gap: 20px;
`

const Box = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(50% - 10px);
  height: 375px;
  border-radius: 1px;
  border: 2px solid #eeeeee;
  overflow-x: clip;
  padding: 20px;
`
