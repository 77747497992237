import { Dialog, DialogContent } from '@/components/ui/dialog'
import {
  ArchiveBoxIcon, // was Boxes
  ArrowUpOnSquareIcon, // was CloudUpload
  DocumentTextIcon, // was FileText
  ServerStackIcon, // "Network"
} from '@heroicons/react/24/outline'
import WaveLoadingAnimation from 'components/Reusables/WaveLoadingAnimation'
import { AnimatePresence, motion } from 'framer-motion'
import { useEffect, useState } from 'react'

/**
 * A thick, glassy, wave-like circle.
 * No fluid simulation—just rotating layers for a dynamic effect.
 */

/**
 * StepInfo - displays the current step name & description under the loading bar.
 */
function StepInfo({ step, total, name, description }) {
  return (
    <div className="flex flex-col items-center mt-2.5 space-y-0.5 retro-step-text">
      {/* <span className="text-2xs text-blue-600">
        Step {step} of {total}
      </span> */}
      {/* <h4 className="text-xs text-gray-800 font-bold uppercase">{name}</h4> */}
      <p className="text-2xs text-gray-600 text-center max-w-sm px-1.5">
        {description}
      </p>
    </div>
  )
}

/**
 * SystemLogs - show only one log at a time, rotating every few seconds.
 * A more "modern" light-mode approach.
 */
function SystemLogs({ open, isWaiting }) {
  const [currentLog, setCurrentLog] = useState('')
  const [key, setKey] = useState(0) // use a key to re-mount for animation

  // A pool of random "realistic" log lines
  const logsPool = [
    'Initializing system environment...',
    'Connecting to primary server...',
    'Authentication token verified.',
    'Syncing with distributed nodes...',
    'Processing incoming user request...',
    'Session handshake completed.',
    'Allocating resources for task...',
    'Uploading dataset to cloud storage...',
    'Validating data schema...',
    'Compression algorithm applied successfully.',
    'Triggering background processes...',
    'Optimizing query plan...',
    'Caching frequently accessed data...',
    'Rebuilding search index...',
    'Extracting metadata from documents...',
    'Scheduling periodic updates...',
    'Encrypting sensitive information...',
    'Data backup initiated.',
    'Storing processed output in database...',
    'Subscribing to real-time updates...',
    'Applying security patches...',
    'Launching data pipeline job...',
    'Rendering visualization charts...',
    'Generating real-time analytics...',
    'Logging user activity...',
    'Monitoring server performance...',
    'Running health checks on subsystems...',
    'Distributing load across nodes...',
    'Partitioning database for scalability...',
    'Queuing tasks for execution...',
    'Serializing data for transport...',
    'Compiling dynamic content...',
    'Verifying cryptographic signatures...',
    'Establishing peer-to-peer connections...',
    'Creating snapshots of current state...',
    'Fetching configuration settings...',
    'Updating access control lists...',
    'Routing messages to endpoints...',
    'Assigning task priorities...',
    'Initiating preprocessing pipeline...',
    'Defining transformation rules...',
    'Detecting active user sessions...',
    'Streaming updates to subscribers...',
    'Index refresh completed successfully.',
    'Pinging remote nodes for latency check...',
    'Collecting system diagnostics...',
    'Initializing runtime dependencies...',
    'Synchronizing with global clock...',
    'Generating API response payload...',
    'Triggering notification events...',
  ]

  useEffect(() => {
    let interval
    if (open && !isWaiting) {
      // Immediately pick a random log when opened
      pickRandomLog()
      // Then pick a new random log every 2 seconds
      interval = setInterval(() => {
        pickRandomLog()
      }, 2000)
    }
    else {
      setCurrentLog('Hang tight... this is a large file')
    }
    return () => clearInterval(interval)
    // eslint-disable-next-line
  }, [open, isWaiting]);

  // Helper to pick a random log from logsPool
  function pickRandomLog() {
    const randomLog = logsPool[Math.floor(Math.random() * logsPool.length)]
    setCurrentLog(randomLog)
    setKey(prev => prev + 1) // update key to force re-mount of the item
  }

  return (
    <div className="flex flex-col items-center justify-center w-full max-w-full mt-4">
      {/* We'll animate the log line in/out */}
      <div className="w-full mx-auto text-center font-light p-2 text-gray-400 text-2xs font-mono">
        <AnimatePresence mode="wait">
          <motion.div
            key={key}
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.4 }}
          >
            {currentLog}
          </motion.div>
        </AnimatePresence>
      </div>
    </div>
  )
}

/**
 * ProcessMetrics - simulate random "server-like" metrics (chunks and vectors).
 */
function ProcessMetrics({ processId, open }) {
  const [chunks, setChunks] = useState(0)
  const [vectors, setVectors] = useState(0)

  // Update the metrics every 1 second to simulate random, real-time data
  useEffect(() => {
    let interval
    if (open) {
      interval = setInterval(() => {
        // Randomly increment or decrement metrics
        setChunks(prev =>
          Math.max(0, prev + Math.floor(Math.random() * 20 - 5)),
        )
        setVectors(prev =>
          Math.max(0, prev + Math.floor(Math.random() * 200 - 50)),
        )
      }, 1000)
    }
    return () => clearInterval(interval)
  }, [open])

  // On first mount or when re-opened, seed them
  useEffect(() => {
    if (open) {
      setChunks(Math.floor(Math.random() * 200))
      setVectors(Math.floor(Math.random() * 2000))
    }
  }, [open])

  return (
    <div className="flex flex-wrap items-center justify-center gap-1.5 text-2xs font-mono mt-4">
      <div className="bg-white/80 backdrop-blur-sm px-2 py-1 rounded text-gray-600">
        Process:
        {' '}
        {processId}
      </div>
      <div className="bg-white/80 backdrop-blur-sm px-2 py-1 rounded text-gray-600">
        Chunks:
        {' '}
        {chunks}
      </div>
      <div className="bg-white/80 backdrop-blur-sm px-2 py-1 rounded text-gray-600">
        Vectors:
        {' '}
        {vectors}
      </div>
    </div>
  )
}

/**
 * ProcessModal - main component combining everything:
 * - Glassy wave circle
 * - Smooth continuous loading bar
 * - Step info
 * - Metrics
 * - System logs
 */
export default function ProcessModal({
  open,
  setOpen,
  onRefresh,
  mock = false,
  uploadComplete,
}) {
  const [currentStep, setCurrentStep] = useState(-1)
  const [isWaiting, setIsWaiting] = useState(false)

  // We track a "target progress" based on the current step,
  // and a "rendered progress" that continuously animates towards target.
  const [renderedProgress, setRenderedProgress] = useState(0)

  // For demonstration, a random process ID
  const [processId] = useState(() =>
    Math.random().toString(36).substring(2, 15).toUpperCase(),
  )

  // Steps array
  const steps = [
    {
      name: 'Upload',
      description: 'Sending your file to our servers',
      icon: <ArrowUpOnSquareIcon className="w-6 h-6 text-blue-600" />,
    },
    {
      name: 'Extract',
      description: 'Extracting content',
      icon: <DocumentTextIcon className="w-6 h-6 text-blue-600" />,
    },
    {
      name: 'Process',
      description: 'Converting into vector embeddings',
      icon: <ServerStackIcon className="w-6 h-6 text-blue-600" />,
    },
    {
      name: 'Index',
      description: 'Making your content available to AI',
      icon: <ArchiveBoxIcon className="w-6 h-6 text-blue-600" />,
    },
  ]

  // Derived "target" progress based on current step
  const targetProgress = Math.min(
    ((currentStep + 1) / steps.length) * 100,
    100,
  )

  // Step-based logic
  useEffect(() => {
    if (currentStep === -1 && open) {
      // Start at step 0 when the modal first opens
      setCurrentStep(0)
    }
    else if (open && currentStep < steps.length - 1) {
      // Move to the next step after a delay
      const timer = setTimeout(
        () => {
          setCurrentStep(prev => prev + 1)
        },
        mock ? 3000 : 3000,
      )
      return () => clearTimeout(timer)
    }
    else if (mock && currentStep === steps.length - 1) {
      // If "mock", just loop back
      const loopTimer = setTimeout(() => {
        setCurrentStep(0)
      }, 1500)
      return () => clearTimeout(loopTimer)
    }
    else if (!mock && currentStep === steps.length - 1 && uploadComplete) {
      // After final step, close the modal & refresh
      const closeTimer = setTimeout(() => {
        onRefresh?.()
        setOpen(false)
      }, 3000)
      return () => clearTimeout(closeTimer)
    }
    else {
      setIsWaiting(true)
    }
  }, [
    currentStep,
    open,
    mock,
    steps.length,
    onRefresh,
    setOpen,
    uploadComplete,
  ])

  // If the modal closes externally, reset steps
  useEffect(() => {
    if (!open && !mock) {
      setCurrentStep(-1)
      setRenderedProgress(0)
    }
  }, [open, mock])

  // Smoothly animate "renderedProgress" toward "targetProgress"
  useEffect(() => {
    // If the modal isn't open, don't animate
    if (!open)
      return

    let animationFrame
    const animateProgress = () => {
      setRenderedProgress((prev) => {
        // Move gradually at about 1-2% per frame for a smooth approach
        const diff = targetProgress - prev
        if (Math.abs(diff) < 0.1) {
          return targetProgress // close enough
        }
        return prev + diff * 0.08 // approach with 8% of the difference
      })
      animationFrame = requestAnimationFrame(animateProgress)
    }

    animationFrame = requestAnimationFrame(animateProgress)
    return () => cancelAnimationFrame(animationFrame)
  }, [targetProgress, open])

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className="sm:max-w-screen-sm">
        {/*
        An overall container animation to gently scale & fade in the modal content
      */}
        <motion.div
          initial={{ opacity: 0, scale: 0.9, y: 20 }}
          animate={{ opacity: 1, scale: 1, y: 0 }}
          exit={{ opacity: 0, scale: 0.9, y: -20 }}
          transition={{
            duration: 0.8,
            ease: 'easeInOut',
          }}
          className="flex flex-col items-center justify-center space-y-2.5 z-90"
        >
          <h3 className="text-lg font-semibold tracking-tight text-gray-900 retro-step-text uppercase">
            Processing Knowledge Base
          </h3>
          <p className="text-xs text-gray-600 font-mono retro-step-text">
            Please wait while we process your data
          </p>

          <div className="relative flex flex-col w-full items-center justify-center py-5">
            {/* Glassy wave circle behind everything */}
            <WaveLoadingAnimation name="1" />

            {/* Smooth continuous loading bar (bigger, purple fill) */}
            <div className="my-4 ">
              <div className="relative w-52 h-5 border border-gray-800 retro-step-text  overflow-hidden opacity-50">
                <div
                  className="absolute top-0 left-0 h-5 bg-indigo-300 retro-step-text "
                  style={{
                    width: `${renderedProgress}%`,
                    transition: 'width 0ms',
                  }}
                />
                <span className="absolute w-full text-center text-2xs font-bold text-black top-1/2 transform -translate-y-1/2 p-2.5 font-mono">
                  {currentStep >= 0 && currentStep < steps.length
                    ? `${steps[currentStep].name}`
                    : 'Loading...'}
                </span>
              </div>

              {/* Show step details under the bar */}
              {currentStep >= 0 && currentStep < steps.length && (
                <StepInfo
                  step={currentStep + 1}
                  total={steps.length}
                  name={steps[currentStep].name}
                  description={steps[currentStep].description}
                />
              )}
            </div>

            {/* Bottom metrics, purely optional but shows "real-time" updates */}
            <ProcessMetrics processId={processId} open={open} />

            {/* Show "system logs" below metrics */}
            <SystemLogs open={open} isWaiting={isWaiting} />
          </div>
        </motion.div>
      </DialogContent>
    </Dialog>
  )
}
