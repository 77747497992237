import {
  Button,
  ButtonGroup,
  Chip,
  cn,
  Link,
  Progress,
  ScrollShadow,
  Spinner,
  Tab,
  Tabs,
  Tooltip,
} from '@heroui/react'
import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query'
import {
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import ActionBar from 'components/core/ActionBar'
import { PageTitle } from 'components/core/PageTitle'
import { PageWrapper } from 'components/core/PageWrapper'
import GradientLoadingAnimation from 'components/Reusables/GradientLoadingAnimation'
import { useCallLogsPagination } from 'hooks/useCallsPagination'
import { parsePhoneNumberWithError } from 'libphonenumber-js'
import { Activity, AlertCircle, ArrowDown, ArrowUp, Check, ChevronRight, Copy, Download, List, MoveDownRight, MoveUpRight, PhoneCall, Play, Voicemail, X } from 'lucide-react'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useLocalStorage } from 'usehooks-ts'
import { convertToMinutesAndSeconds } from 'utils/formatting/secsToMins'
import { toDtmy } from 'utils/formatting/toDtmy'
import { fetchCallLogs, getNextPageParam, getPreviousPageParam, initialPageParam } from 'utils/funcs/call-logs/callLogsQueries'
import { downloadRecording } from 'utils/funcs/call-logs/handleRecording'
import { parsePathwayTags } from 'utils/funcs/call-logs/parsePathwayTags'
import ActiveCallsView from './ActiveCalls/ActiveCallsView'
import CallDetailsSlideOut from './CallDetailsSlideOut'
import ExportCalls from './ExportCalls'
import FilterBar from './Filters/FilterBar'
import ResizableDrawer from './ResizeableDrawer'

function CopyButton({ value, displayText }) {
  const [copied, setCopied] = useState(false)

  const handleCopy = useCallback(() => {
    navigator.clipboard.writeText(value)
    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    }, 2000)
  }, [value])

  return (
    <Button
      variant="light"
      color="primary"
      disableRipple
      size="sm"
      endContent={copied ? <Check size={12} /> : <Copy size={12} />}
      onPress={handleCopy}
    >
      {displayText}
    </Button>
  )
}

function DownloadButton({ c_id }) {
  const [isDownloading, setIsDownloading] = useState(false)

  const handleDownload = useCallback(async () => {
    setIsDownloading(true)
    await downloadRecording(c_id)
    setIsDownloading(false)
  }, [c_id])

  return (
    <Button isLoading={isDownloading} variant="light" size="sm" radius="sm" color="secondary" isIconOnly onPress={handleDownload}>
      <Download size={14} />
    </Button>
  )
}

function PlayButton({ c_id }) {
  const [, setAutoplay] = useLocalStorage('autoplay', false)
  const navigate = useNavigate()

  const handlePlay = useCallback(() => {
    setAutoplay(true)
    navigate(`/dashboard/call-logs/${c_id}`)
  }, [c_id])

  return (
    <Button variant="light" size="sm" radius="sm" color="primary" isIconOnly onPress={handlePlay}>
      <Play size={14} />
    </Button>
  )
}

const colorMap = {
  'completed': 'success',
  'no-answer': 'default',
  'busy': 'secondary',
  'failed': 'danger',
  'canceled': 'warning',
}

function CallLogs() {
  const [searchParams, setSearchParams] = useSearchParams()
  const { callId } = useParams()
  const navigate = useNavigate()
  const tableContainerRef = useRef(null)

  const { take, TAKE_EXTRA_ROWS, ROW_HEIGHT } = useCallLogsPagination()
  const sortBy = searchParams.get('sortBy') || 'created_at'
  const sortDir = searchParams.get('sortDir') || 'desc'

  const [viewMode, setViewMode] = useState('all')
  const [liveCallId, setLiveCallId] = useState(null)

  const getFilters = useCallback(() => {
    const filters = []

    // Loop through all search params
    for (const [key, value] of searchParams.entries()) {
      if (!key.startsWith('filter:'))
        continue

      // Parse the filter structure from the key
      // Example: filter_name_eq = "John" becomes { key: "name", operator: "eq", value: "John" }
      const [, field, operator] = key.split(':')

      if (field && operator) {
        filters.push({
          field,
          operator,
          value,
        })
      }
    }

    return filters
  }, [searchParams])

  const filters = useMemo(() => getFilters(), [searchParams])

  const {
    data,
    fetchNextPage,
    isFetchingNextPage,
    isFetching,
    isLoading,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: ['callLogs', take, filters, sortBy, sortDir],
    queryFn: fetchCallLogs,
    initialPageParam,
    getNextPageParam,
    getPreviousPageParam,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 3, // 5 minutes
    placeholderData: keepPreviousData,
    meta: {
      errorMessage: 'Error fetching call logs',
    },
  })

  const totalCalls = useMemo(() => data?.pages?.[0]?.pagination?.totalCount ?? 0, [data])

  // Flatten the data array for the table
  const flatData = useMemo(
    () => data?.pages?.flatMap(page => page.calls) ?? [],
    [data],
  )

  // Function to fetch more data when scrolling to bottom
  const fetchMoreOnBottomReached = useCallback(
    (containerRefElement) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement

        if (
          scrollHeight - scrollTop - clientHeight < TAKE_EXTRA_ROWS * ROW_HEIGHT
          && !isFetchingNextPage
          && hasNextPage
        ) {
          fetchNextPage()
        }
      }
    },
    [fetchNextPage, isFetchingNextPage, hasNextPage],
  )

  // Check if we need to fetch more data on mount or after a fetch
  useEffect(() => {
    fetchMoreOnBottomReached(tableContainerRef.current)
  }, [fetchMoreOnBottomReached])

  const handleSortChange = (updater) => {
    const newSorting = updater([{ id: sortBy, desc: sortDir === 'desc' }])
    if (newSorting.length > 0) {
      const { id, desc } = newSorting[0]
      setSearchParams({
        ...Object.fromEntries(searchParams.entries()),
        sortBy: id,
        sortDir: desc ? 'desc' : 'asc',
      })
    }
  }

  const handleRowSelectionChange = (updater) => {
    const queryString = searchParams.toString()
    let c_id = null

    if (updater) {
      const newSelection = updater()
      c_id = Object.keys(newSelection)[0]
    }

    navigate(`/dashboard/call-logs/${c_id || ''}${queryString ? `?${queryString}` : ''}`)
  }

  // Define columns for the table
  const columns = useMemo(() => [
    {
      id: 'icon',
      header: `${totalCalls} Total`,
      size: 50,
      enableResizing: false,
      enableSorting: false,
      cell: ({ row }) => {
        const handleExpand = (e) => {
          e.stopPropagation()
          e.preventDefault()
          row.getToggleExpandedHandler()()
        }
        return row.original.transferred_call
          ? (
              <Tooltip content={row.getIsExpanded() ? 'Hide Transferred Call' : 'Show Transferred Call'}>
                <ChevronRight
                  size={20}
                  color="black"
                  onClick={handleExpand}
                  className={cn('mx-auto transition-transform', row.getIsExpanded() ? 'rotate-90' : 'animate-pulse')}
                />
              </Tooltip>
            )
          : row.original.inbound
            ? <MoveDownRight size={15} color="#4CAF50" className="mx-auto" />
            : <MoveUpRight size={15} color="#2196F3" className="mx-auto" />
      },
    },
    {
      accessorKey: 'to',
      header: 'To',
      enableSorting: false,
      cell: ({ getValue }) => {
        const value = getValue()
        if (!value || typeof value !== 'string')
          return value ?? '-'
        try {
          return parsePhoneNumberWithError(value)?.formatNational()
        }
        catch (error) {
          console.error(error)
          return value ?? '-'
        }
      },
    },
    {
      accessorKey: 'from',
      header: 'From',
      enableSorting: false,
      cell: ({ getValue }) => {
        const value = getValue()
        if (!value || typeof value !== 'string')
          return value ?? '-'
        try {
          return parsePhoneNumberWithError(value)?.formatNational()
        }
        catch (error) {
          console.error(error)
          return value ?? '-'
        }
      },
    },
    {
      accessorKey: 'call_length',
      header: 'Duration',
      size: 80,
      enableSorting: true,
      cell: ({ getValue }) => {
        const value = getValue()
        return value ? convertToMinutesAndSeconds(value) : '-'
      },
    },
    {
      accessorKey: 'recording_url',
      header: 'Recording',
      enableSorting: false,
      size: 80,
      cell: ({ getValue, row }) => {
        const url = getValue()
        return url
          ? (
              <ButtonGroup>
                <PlayButton c_id={row.original.c_id} />
                <DownloadButton c_id={row.original.c_id} />
              </ButtonGroup>
            )
          : '-'
      },
    },
    {
      accessorKey: 'created_at',
      enableSorting: true,
      header: 'Created',
      cell: ({ getValue }) => {
        const date = getValue()
        return date ? toDtmy(new Date(date)) : '-'
      },
    },
    {
      accessorKey: 'c_id',
      header: 'Call ID',
      size: 100,
      enableSorting: false,
      cell: ({ getValue }) => {
        const callId = getValue()
        return (
          <CopyButton value={callId} displayText={`${callId.substring(0, 6)}...`} />
        )
      },
    },
    {
      accessorKey: 'status',
      header: 'Status',
      enableSorting: false,
      cell: ({ getValue }) => {
        const status = getValue()
        return (
          <Chip
            variant="dot"
            radius="sm"
            size="sm"
            color={colorMap[status] ?? 'default'}
            className={`text-${colorMap[status]} uppercase`}
          >
            {status ?? 'unknown'}
          </Chip>
        )
      },
    },
    {
      accessorKey: 'pathway_id',
      header: 'Pathway',
      enableSorting: false,
      cell: ({ getValue, row }) => {
        const pathwayId = getValue()
        return pathwayId
          ? (
              <Link
                href={`/dashboard/convo-pathways?id=${pathwayId}`}
                size="sm"
                underline="always"
                className="text-tiny"
              >
                {row.original.convo_pathways?.name ?? 'Untitled Pathway'}
              </Link>
            )
          : '-'
      },
    },
    {
      accessorKey: 'pathway_tags',
      header: 'Tags',
      enableSorting: false,
      cell: ({ getValue }) => {
        const tags = getValue()
        const parsedTags = parsePathwayTags(tags)
        if (!parsedTags || !parsedTags?.length)
          return '-'

        return (
          <ScrollShadow
            orientation="horizontal"
            className="max-w-60 px-2 gap-1 flex"
            hideScrollBar
          >
            {parsedTags?.map(tag => (
              <Chip size="sm" color="default" variant="bordered" radius="sm" key={tag.name ?? tag}>
                <div className="flex items-center gap-1">
                  <div className="size-2 rounded-full" style={{ backgroundColor: tag.color ?? 'gray' }} />
                  {tag.name ?? tag}
                </div>
              </Chip>
            ))}
          </ScrollShadow>
        )
      },
    },
    {
      accessorKey: 'transferred_to',
      header: 'Transferred To',
      enableSorting: false,
      cell: ({ getValue }) => {
        const value = getValue()
        if (!value || typeof value !== 'string')
          return value ?? '-'
        try {
          return parsePhoneNumberWithError(value)?.formatNational()
        }
        catch (error) {
          console.error(error)
          return value ?? '-'
        }
      },
    },
    {
      accessorKey: 'batch_id',
      header: 'Batch ID',
      enableSorting: false,
      cell: ({ getValue }) => {
        const batchId = getValue()
        if (!batchId)
          return '-'
        return (
          <CopyButton value={batchId} displayText={`${batchId.substring(0, 6)}...`} />
        )
      },
    },
    {
      accessorKey: 'error_message',
      header: 'Error',
      enableSorting: false,
      cell: ({ getValue }) => {
        const value = getValue()
        return value
          ? (
              <Chip
                size="sm"
                startContent={<AlertCircle size={12} />}
                color="danger"
                radius="sm"
                className="mx-1"
                classNames={{
                  content: 'max-w-64 truncate',
                }}
              >
                {value}
              </Chip>
            )
          : '-'
      },
    },
  ], [totalCalls])

  // Setup TanStack Table
  const table = useReactTable({
    // STATE
    data: flatData || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting: [{ id: sortBy, desc: sortDir === 'desc' }],
      rowSelection: callId ? { [callId]: true } : {},
    },
    // EXPANSION
    getExpandedRowModel: getExpandedRowModel(),
    getRowCanExpand: row => !!row.original.transferred_call,
    getSubRows: row => row.transferred_call ? [row.transferred_call] : [],
    // RESIZING
    columnResizeMode: 'onChange',
    // SORTING
    manualSorting: true,
    sortDescFirst: true,
    onSortingChange: handleSortChange,
    // SELECTION
    getRowId: row => row.c_id,
    enableMultiRowSelection: false,
    enableSubRowSelection: false,
    onRowSelectionChange: handleRowSelectionChange,
  })

  useEffect(() => {
    // Get row information once
    const getRowInfo = () => {
      if (!callId || !flatData?.length)
        return null

      const rowModel = table.getRowModel()
      const selectedRow = table.getRow(callId)
      const currentIndex = rowModel.rows.findIndex(row => row.id === callId)
      const rowElement = tableContainerRef.current?.querySelector(`tr[data-row-id="${callId}"]`)
        || tableContainerRef.current?.querySelector(`tbody tr:nth-child(${selectedRow?.index + 1})`)

      return {
        rowModel,
        selectedRow,
        currentIndex,
        rowElement,
      }
    }

    const handleKeyDown = (e) => {
      const activeElement = document.activeElement
      const isEditableElement
        = activeElement.tagName === 'INPUT'
          || activeElement.tagName === 'TEXTAREA'
          || activeElement.contentEditable === 'true'
          || activeElement.closest('[contenteditable="true"]')
          || activeElement.closest('.ignore-audio-player')

      if (isEditableElement || !flatData?.length || !['ArrowDown', 'ArrowUp', 'j', 'k', 'Enter'].includes(e.key)) {
        return
      }

      const rowInfo = getRowInfo()
      if (!rowInfo)
        return

      const { rowModel, selectedRow, currentIndex } = rowInfo
      let nextIndex = currentIndex

      switch (e.key) {
        case 'ArrowDown':
        case 'k':
          e.preventDefault()
          nextIndex += 1
          break
        case 'ArrowUp':
        case 'j':
          e.preventDefault()
          if (selectedRow) {
            nextIndex -= 1
          }
          break
        case 'Enter':
          e.preventDefault()
          if (selectedRow?.subRows?.length) {
            return selectedRow.getToggleExpandedHandler()()
          }
          break
      }

      const nextRow = rowModel.rows[nextIndex]
      if (nextRow) {
        table.setRowSelection(() => ({ [nextRow.id]: true }))
      }
    }

    // Scroll to selected row
    const scrollToSelectedRow = () => {
      if (!tableContainerRef.current || !callId)
        return

      const rowInfo = getRowInfo()
      if (!rowInfo || !rowInfo.rowElement)
        return

      const { rowElement } = rowInfo
      const containerRect = tableContainerRef.current.getBoundingClientRect()
      const rowRect = rowElement.getBoundingClientRect()

      // Check if the row is fully visible in the viewport
      const isRowVisible
        = rowRect.top >= containerRect.top
          && rowRect.bottom <= containerRect.bottom

      // If not visible, scroll it into view
      if (!isRowVisible) {
        // Determine scroll direction
        if (rowRect.top < containerRect.top) {
          // Scroll up if row is above viewport
          rowElement.scrollIntoView({ block: 'start', behavior: 'smooth' })
        }
        else if (rowRect.bottom > containerRect.bottom) {
          // Scroll down if row is below viewport
          rowElement.scrollIntoView({ block: 'end', behavior: 'smooth' })
        }
      }
    }

    // Call it once when callId changes, with a small delay
    const timeoutId = setTimeout(scrollToSelectedRow, 10)

    window.addEventListener('keydown', handleKeyDown)

    // Clean up event listener and timeout
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
      clearTimeout(timeoutId)
    }
  }, [callId, flatData, table])

  return (
    <PageWrapper padding="54px 0px 0px 54px">
      <ActionBar spaceBetween shiftRight top>
        <PageTitle className="justify-self-start">Call Logs</PageTitle>
        <Tabs
          size="sm"
          radius="none"
          selectedKey={viewMode}
          onSelectionChange={(value) => {
            setViewMode(value)
          }}
          variant="underlined"
          className="ml-4"
        >
          {[{ value: 'all', label: 'All Calls', icon: <List size={12} /> }, { value: 'active', label: 'Active Calls', icon: <Activity size={12} /> }].map(doc => (
            <Tab
              id={doc.value}
              key={doc.value}
              title={(
                <div className="flex items-center space-x-2">
                  {doc.icon}
                  <span>{doc.label}</span>
                </div>
              )}
            />
          ))}
        </Tabs>
        <ExportCalls activeFilters={filters} />
        {isFetching && !isFetchingNextPage && (
          <Progress
            isIndeterminate
            aria-label="Loading..."
            className="absolute bottom-0 left-0"
            classNames={{ base: 'h-0.5', indicator: '!duration-1000' }}
            size="sm"
          />
        )}
      </ActionBar>
      {viewMode === 'active'
        ? (
            <ActiveCallsView
              handleViewDetails={() => navigate(`/dashboard/call-logs/${liveCallId}`)}
              setSelectedCallId={setLiveCallId}
              selectedCallId={liveCallId}
            />
          )
        : isLoading
          ? (
              <GradientLoadingAnimation
                message="Loading Call Logs"
                variant="roseGarden"
                rounded="full"
                gray
              />
            )
          : (
              <div className="h-full flex flex-col max-h-full">
                <FilterBar appliedFilters={filters} />

                {/* Table */}
                {!flatData?.length
                  ? (
                      <div className="w-full h-full flex flex-col items-center mt-40">
                        <Voicemail size={64} className="text-gray-800" />
                        <h1 className="text-3xl tracking-tighter font-semibold mb-6">
                          No calls found.
                        </h1>
                        {filters?.length > 0
                          ? (
                              <Button
                                variant="bordered"
                                color="default"
                                radius="sm"
                                size="lg"
                                onPress={() => {
                                  setSearchParams({ take })
                                }}
                              >
                                <X size={16} />
                                Clear Filters
                              </Button>
                            )
                          : (
                              <Button
                                variant="solid"
                                color="primary"
                                radius="sm"
                                size="lg"
                                as={Link}
                                href="/dashboard/send-call"
                              >
                                <PhoneCall size={16} />
                                Make your first call
                              </Button>
                            )}
                      </div>
                    )
                  : (
                      <ScrollShadow
                        ref={tableContainerRef}
                        className="w-full px-4 bg-gray-50 h-full"
                        onScroll={e => fetchMoreOnBottomReached(e.target)}
                        orientation="vertical"
                        visibility="bottom"
                        isEnabled={hasNextPage}
                      >
                        <table className="divide-y divide-gray-200 border-b w-full">
                          <thead
                            className="sticky top-0 bg-gray-50 z-20 outline outline-2 outline-gray-50 bg-opacity-90 after:content-[''] after:absolute after:left-0 after:right-0 after:bottom-0 after:h-[1px] after:bg-gray-200"
                          >
                            <tr>
                              {table.getHeaderGroups().map(headerGroup => (
                                headerGroup.headers.map(header => (
                                  <th
                                    key={header.id}
                                    className="h-8 text-center text-2xs relative font-medium font-taurus text-gray-500 uppercase tracking-wider whitespace-nowrap flex-nowrap"
                                    colSpan={header.colSpan}
                                    style={{ width: `${header.getSize()}px` }}
                                  >
                                    {header.isPlaceholder
                                      ? null
                                      : (
                                          <div
                                            {...{
                                              className: header.column.getCanSort() ? 'cursor-pointer select-none flex items-center justify-center gap-2' : '',
                                              onClick: header.column.getToggleSortingHandler(),
                                            }}
                                          >
                                            {flexRender(
                                              header.column.columnDef.header,
                                              header.getContext(),
                                            )}
                                            {{
                                              asc: <ArrowDown size={12} />,
                                              desc: <ArrowUp size={12} />,
                                            }[header.column.getIsSorted()] ?? null}
                                          </div>
                                        )}
                                    {header.column.getCanResize() && (
                                      <div
                                        {...{
                                          onMouseDown: header.getResizeHandler(),
                                          onTouchStart: header.getResizeHandler(),
                                          className: cn(
                                            'absolute bottom-0 h-full my-0.5 w-[2px] cursor-col-resize select-none touch-none group-hover:opacity-80 transition-opacity',
                                            table.options.columnResizeDirection,
                                            header.column.getIsResizing() ? 'isResizing' : '',
                                            table.options.columnResizeDirection === 'ltr' ? 'right-0' : 'left-0',
                                            header.column.getIsResizing() ? 'bg-primary-500 opacity-100' : 'bg-black/20 opacity-0',
                                            table.options.columnResizeMode === 'onEnd' && header.column.getIsResizing()
                                              ? `translate-x-[${
                                                (table.options.columnResizeDirection === 'rtl' ? -1 : 1)
                                                * (table.getState().columnSizingInfo.deltaOffset ?? 0)
                                              }px]`
                                              : '',
                                          ),
                                        }}
                                      />
                                    )}
                                  </th>
                                ))
                              ))}
                            </tr>
                          </thead>
                          <tbody className={cn('bg-white border-x divide-y divide-gray-200', { 'animate-pulse': isFetching && !isFetchingNextPage })}>
                            {table.getRowModel().rows.map(row => (
                              <React.Fragment key={row.id}>
                                <tr
                                  className={cn('divide-x-1 divide-gray-50 text-center hover:bg-gray-50 transition-colors duration-100 cursor-pointer', {
                                    'bg-gray-100': row.getIsSelected(),
                                    'bg-primary-50/50 hover:bg-primary-50': row.depth === 1,
                                  })}
                                  onClick={row.getToggleSelectedHandler()}
                                >
                                  {row.getVisibleCells().map(cell => (
                                    <td
                                      key={cell.id}
                                      className="h-11 whitespace-nowrap text-tiny text-gray-500"
                                    >
                                      {flexRender(
                                        cell.column.columnDef.cell,
                                        cell.getContext(),
                                      )}
                                    </td>
                                  ))}
                                </tr>
                              </React.Fragment>
                            ))}
                          </tbody>
                        </table>
                        {isFetchingNextPage && (
                          <div className="flex justify-center items-center py-4">
                            <Spinner
                              aria-label="Loading more call logs..."
                            />
                          </div>
                        )}
                      </ScrollShadow>
                    )}
              </div>
            )}

      {!!callId && (
        <ResizableDrawer>
          <CallDetailsSlideOut
            callId={callId}
            onClose={() => table.setRowSelection(null)}
          />
        </ResizableDrawer>
      )}
    </PageWrapper>
  )
}

export default CallLogs
