import { forwardRef, Select, SelectItem } from '@heroui/react'
import { Globe, Wand } from 'lucide-react'
import { FlagImage } from 'react-international-phone'

const languageOptions = [
  { key: 'en', label: 'English', iso2: 'us' },
  { key: 'babel', label: 'Babel (Experimental, All Languages)', icon: <Globe size={20} /> },
  { key: 'auto', label: 'Auto Detect (English & Spanish)', icon: <Wand size={20} /> },
  { key: 'en-US', label: 'English (US)', iso2: 'us' },
  { key: 'en-GB', label: 'English (UK)', iso2: 'gb' },
  { key: 'en-AU', label: 'English (Australia)', iso2: 'au' },
  { key: 'en-NZ', label: 'English (New Zealand)', iso2: 'nz' },
  { key: 'en-IN', label: 'English (India)', iso2: 'in' },
  { key: 'es', label: 'Spanish', iso2: 'es' },
  { key: 'es-419', label: 'Spanish (Latin America)', iso2: 'mx' },
  { key: 'fr', label: 'French', iso2: 'fr' },
  { key: 'fr-CA', label: 'French (Canada)', iso2: 'ca' },
  { key: 'de', label: 'German', iso2: 'de' },
  { key: 'el', label: 'Greek', iso2: 'gr' },
  { key: 'hi', label: 'Hindi', iso2: 'in' },
  { key: 'hi-Latn', label: 'Hindi (Latin script)', iso2: 'in' },
  { key: 'hu', label: 'Hungarian', iso2: 'hu' },
  { key: 'ja', label: 'Japanese', iso2: 'jp' },
  { key: 'ko', label: 'Korean', iso2: 'kr' },
  { key: 'ko-KR', label: 'Korean (Korea)', iso2: 'kr' },
  { key: 'vi', label: 'Vietnamese', iso2: 'vn' },
  { key: 'pt', label: 'Portuguese', iso2: 'pt' },
  { key: 'pt-BR', label: 'Portuguese (Brazil)', iso2: 'br' },
  { key: 'pt-PT', label: 'Portuguese (Portugal)', iso2: 'pt' },
  { key: 'zh', label: 'Chinese (Mandarin, Simplified)', iso2: 'cn' },
  { key: 'zh-CN', label: 'Chinese (Mandarin, Simplified, China)', iso2: 'cn' },
  { key: 'zh-Hans', label: 'Chinese (Mandarin, Simplified, Hans)', iso2: 'cn' },
  { key: 'zh-TW', label: 'Chinese (Mandarin, Traditional)', iso2: 'tw' },
  { key: 'zh-Hant', label: 'Chinese (Mandarin, Traditional, Hant)', iso2: 'tw' },
  { key: 'it', label: 'Italian', iso2: 'it' },
  { key: 'nl', label: 'Dutch', iso2: 'nl' },
  { key: 'pl', label: 'Polish', iso2: 'pl' },
  { key: 'ru', label: 'Russian', iso2: 'ru' },
  { key: 'sv', label: 'Swedish', iso2: 'se' },
  { key: 'sv-SE', label: 'Swedish (Sweden)', iso2: 'se' },
  { key: 'da', label: 'Danish', iso2: 'dk' },
  { key: 'da-DK', label: 'Danish (Denmark)', iso2: 'dk' },
  { key: 'fi', label: 'Finnish', iso2: 'fi' },
  { key: 'no', label: 'Norwegian', iso2: 'no' },
  { key: 'id', label: 'Indonesian', iso2: 'id' },
  { key: 'ms', label: 'Malay', iso2: 'my' },
  { key: 'tr', label: 'Turkish', iso2: 'tr' },
  { key: 'uk', label: 'Ukrainian', iso2: 'ua' },
  { key: 'bg', label: 'Bulgarian', iso2: 'bg' },
  { key: 'cs', label: 'Czech', iso2: 'cz' },
  { key: 'ro', label: 'Romanian', iso2: 'ro' },
  { key: 'sk', label: 'Slovak', iso2: 'sk' },
]

const LanguageSelect = forwardRef(({ value, ...props }, ref) => {
  return (
    <Select
      label="Language"
      labelPlacement="outside"
      description="Select a supported language of your choice. Optimizes every part of our API for that language - transcription,
      speech, and other inner workings."
      placeholder="Select a language..."
      variant="bordered"
      radius="sm"
      defaultSelectedKeys={['en']}
      selectedKeys={value && [value]}
      {...props}
      ref={ref}
    >
      {languageOptions.map(option => (
        <SelectItem key={option.key} startContent={option.iso2 ? <FlagImage iso2={option.iso2} /> : option.icon}>
          {option.label}
        </SelectItem>
      ))}
    </Select>
  )
})

export default LanguageSelect
