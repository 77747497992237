import { forwardRef, Select, SelectItem } from '@heroui/react'

const eventOptions = [
  { key: 'call', label: 'Call' },
  { key: 'queue', label: 'Queue' },
  { key: 'error', label: 'Error' },
  { key: 'latency', label: 'Latency' },
  { key: 'webhook', label: 'Webhook' },
  { key: 'tool', label: 'Tool' },
  { key: 'dynamic_data', label: 'Dynamic Data' },
]

const WebhookSelect = forwardRef(({ onChange, value, ...props }, ref) => {
  return (
    <Select
      label="Webhook Events"
      labelPlacement="outside"
      variant="bordered"
      radius="sm"
      placeholder="Select Events"
      selectedKeys={value}
      onSelectionChange={(keys) => {
        const selectedKeys = Array.from(keys)
        onChange(selectedKeys)
      }}
      selectionMode="multiple"
      description="Specify which events you want to stream to the webhook, during the call."
      {...props}
      ref={ref}
    >
      {eventOptions.map(option => (
        <SelectItem key={option.key}>
          {option.label}
        </SelectItem>
      ))}
    </Select>
  )
})

export default WebhookSelect
