import MultiSelect from 'components/core/MultiSelect'

export default function WebhookEvents({ fieldName }) {
  return (
    <MultiSelect
      fieldName={fieldName}
      label="Webhook Events"
      description="Specify which events you want to stream to the webhook, during the call. (Optional)"
      options={[
        { value: 'call', label: 'Call' },
        { value: 'queue', label: 'Queue' },
        { value: 'error', label: 'Error' },
        { value: 'latency', label: 'Latency' },
        { value: 'webhook', label: 'Webhook' },
        { value: 'tool', label: 'Tool' },
        { value: 'dynamic_data', label: 'Dynamic Data' },
      ]}
    />
  )
}
