// components/Settings/GeneralSettings/PersonalInfo.js
import { Flex } from '@radix-ui/themes'
import { useAuth } from 'hooks/useAuth'
import { Copy } from 'lucide-react'
import { toast } from 'react-toastify'
import UpdateInfoModal from './UpdateInfoModal'

/**
 * Displays personal info, profile picture, email & phone with copy on hover.
 *
 * Props:
 *   - account (object)
 *   - user (object) => could be org-level user or personal
 *   - data (object) => data from parent, might have phone or email
 *   - type (string) => "org" or "personal"
 *   - getProfilePicture (function) => returns React element for the avatar
 *   - copyHoverStates = { p1, setP1, p2, setP2 } => manages hover states for copy icons
 *
 * Notes:
 *   - This is a "clean and enterprise" style layout:
 *       - Subtle border, consistent spacing, good typographic hierarchy
 *       - Simple, methodical approach
 *   - No hardcoded placeholders except graceful fallback for names.
 */
export default function PersonalInfo({ getProfilePicture, copyHoverStates }) {
  const { user, permissions } = useAuth()
  const { p1, setP1, p2, setP2 } = copyHoverStates

  const account = user?.user

  /**
   * Attempt to form a full name with graceful fallback
   */
  const firstName = account?.first_name?.trim() || ''
  const lastName = account?.last_name?.trim() || ''
  const displayName = (`${firstName} ${lastName}`).trim() || 'User Name'

  /**
   * For phone/email, prefer the org data if type === "org",
   * else fall back to personal account data
   */
  const emailToShow = account?.email
  const phoneToShow = account?.phone_number

  /**
   * Possibly get the role or permission if `type === "org"`.
   * If none provided, fallback to "Personal Account" or "Organization Member".
   */
  const roleOrPermission = permissions?.[0] || 'Organization Member'

  return (
    <div style={{ marginBottom: 30 }}>
      <div className="flex w-full justify-between items-center mb-8 border-b border-gray-200 pb-4">
        {/* Header */}
        <div className="flex flex-col">
          <h2
            style={{
              fontSize: 20,
              fontWeight: 600,
              margin: 0,
              color: '#333',
            }}
          >
            Personal Information
          </h2>
          <p
            style={{
              color: '#6F6F6F',
              fontWeight: 400,
              fontSize: 14,
              marginTop: 6,
              marginBottom: 0,
            }}
          >
            Update account information and details here.
          </p>
        </div>
        <UpdateInfoModal firstName={firstName} lastName={lastName} email={emailToShow} />
      </div>

      {/* Avatar + Name */}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          flexWrap: 'wrap',
          gap: 20,
          marginBottom: 30,
        }}
      >
        {getProfilePicture()}

        <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
          <p
            style={{
              fontSize: 18,
              fontWeight: 500,
              margin: 0,
              color: '#2F2F2F',
            }}
          >
            {displayName}
          </p>
          <p
            style={{
              fontSize: 14,
              color: '#777',
              margin: 0,
            }}
          >
            {roleOrPermission}
          </p>
        </div>
      </div>

      {/* Email / Phone */}
      <Flex direction="column" gap="6">
        <Flex
          direction="row"
          justify="between"
          align="center"
          wrap="wrap"
          className="w-full"
          gap="7"
        >
          {/* Email */}
          <div style={{ flex: 1, minWidth: 240 }}>
            <p style={{ fontSize: 14, fontWeight: 500, marginBottom: 6 }}>
              Email Address
            </p>
            <div
              style={{
                background: '#fff',
                color: '#2F2F2F',
                cursor: 'pointer',
                padding: '8px 12px',
                border: '1px solid #e9e9e9',
                borderRadius: 4,
                position: 'relative',
                minHeight: 36,
                display: 'flex',
                alignItems: 'center',
              }}
              onMouseEnter={(event) => {
                setP1(true)
                event.currentTarget.style.backgroundColor = '#F9F9F9'
              }}
              onMouseLeave={(event) => {
                setP1(false)
                event.currentTarget.style.backgroundColor = '#fff'
              }}
              onClick={() => {
                navigator.clipboard.writeText(emailToShow)
                toast.success('Copied to clipboard')
              }}
            >
              <p
                style={{
                  fontSize: 14,
                  fontWeight: 400,
                  color: emailToShow ? '#505050' : '#c9c9c9',
                  margin: 0,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  flex: 1,
                }}
              >
                {emailToShow}
              </p>
              {/* Copy Icon on hover */}
              <div
                style={{
                  position: 'absolute',
                  right: 8,
                  top: '50%',
                  transform: `translateY(-50%) ${
                    p1 ? 'translateX(0)' : 'translateX(100%)'
                  }`,
                  transition: 'transform 0.3s ease-in-out, opacity 0.3s',
                  opacity: p1 ? 1 : 0,
                }}
              >
                <Copy size={16} color="#999" />
              </div>
            </div>
          </div>

          {/* Phone */}
          <div style={{ flex: 1, minWidth: 240 }}>
            <p style={{ fontSize: 14, fontWeight: 500, marginBottom: 6 }}>
              Phone Number
            </p>
            <div
              style={{
                background: '#fff',
                color: '#2F2F2F',
                cursor: 'pointer',
                padding: '8px 12px',
                border: '1px solid #e9e9e9',
                borderRadius: 4,
                position: 'relative',
                minHeight: 36,
                display: 'flex',
                alignItems: 'center',
              }}
              onMouseEnter={(event) => {
                setP2(true)
                event.currentTarget.style.backgroundColor = '#F9F9F9'
              }}
              onMouseLeave={(event) => {
                setP2(false)
                event.currentTarget.style.backgroundColor = '#fff'
              }}
              onClick={() => {
                navigator.clipboard.writeText(phoneToShow)
                toast.success('Copied to clipboard')
              }}
            >
              <p
                style={{
                  fontSize: 14,
                  fontWeight: 400,
                  color: phoneToShow ? '#505050' : '#c9c9c9',
                  margin: 0,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  flex: 1,
                }}
              >
                {phoneToShow}
              </p>
              {/* Copy Icon on hover */}
              <div
                style={{
                  position: 'absolute',
                  right: 8,
                  top: '50%',
                  transform: `translateY(-50%) ${
                    p2 ? 'translateX(0)' : 'translateX(100%)'
                  }`,
                  transition: 'transform 0.3s ease-in-out, opacity 0.3s',
                  opacity: p2 ? 1 : 0,
                }}
              >
                <Copy size={16} color="#999" />
              </div>
            </div>
          </div>
        </Flex>
      </Flex>
    </div>
  )
}
