import React, { useState, useEffect } from 'react';
import { GitMerge, ArrowRight } from 'lucide-react';
import { toast } from 'react-toastify';
import { $fetch } from 'utils/fetch';
import PathwaySelect from 'components/Hero/PathwaySelect';
import PathwayVersion from 'components/Hero/PathwayVersion';
import { FormProvider, useForm } from 'react-hook-form';
import MergeConflictsModal from './MergeConflictsModal';

const PathwayComparisonSelector = ({ 
  onClose, 
  initialMainPathwayId, 
  initialSmsPathwayId
}) => {
  const [showMergeModal, setShowMergeModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [pathwayData, setPathwayData] = useState({
    mainPathway: null,
    smsPathway: null
  });

  // Create two separate form contexts for the two pathway selectors with initial values if provided
  const mainPathwayForm = useForm({
    defaultValues: {
      main_pathway_id: initialMainPathwayId || '',
      main_pathway_version: '0' // Default to production version
    }
  });

  const smsPathwayForm = useForm({
    defaultValues: {
      sms_pathway_id: initialSmsPathwayId || '',
      sms_pathway_version: '0' // Default to production version
    }
  });

  // Set the form values when initialPathwayIds change
  useEffect(() => {
    if (initialMainPathwayId) {
      mainPathwayForm.setValue('main_pathway_id', initialMainPathwayId);
    }
    if (initialSmsPathwayId) {
      smsPathwayForm.setValue('sms_pathway_id', initialSmsPathwayId);
    }
  }, [initialMainPathwayId, initialSmsPathwayId, mainPathwayForm, smsPathwayForm]);

  // Create safe handlers for the component events
  const handleMainPathwaySelection = (e) => {
    // Extract the key from the keys array or set provided by the component
    const value = e && e.target ? e.target.value : 
                 Array.isArray(e) ? e[0] : 
                 e?.size > 0 ? Array.from(e)[0] : 
                 typeof e === 'string' ? e : null;
                 
    if (value) {
      mainPathwayForm.setValue('main_pathway_id', value);
    }
  };

  const handleMainVersionSelection = (e) => {
    // Extract the key safely from various possible formats
    const value = e && e.target ? e.target.value : 
                 Array.isArray(e) ? e[0] : 
                 e?.size > 0 ? Array.from(e)[0] : 
                 typeof e === 'string' ? e : null;

    if (value) {
      mainPathwayForm.setValue('main_pathway_version', value);
    }
  };

  const handleSmsPathwaySelection = (e) => {
    // Extract the key safely from various possible formats
    const value = e && e.target ? e.target.value : 
                 Array.isArray(e) ? e[0] : 
                 e?.size > 0 ? Array.from(e)[0] : 
                 typeof e === 'string' ? e : null;

    if (value) {
      smsPathwayForm.setValue('sms_pathway_id', value);
    }
  };

  const handleSmsVersionSelection = (e) => {
    // Extract the key safely from various possible formats
    const value = e && e.target ? e.target.value : 
                 Array.isArray(e) ? e[0] : 
                 e?.size > 0 ? Array.from(e)[0] : 
                 typeof e === 'string' ? e : null;

    if (value) {
      smsPathwayForm.setValue('sms_pathway_version', value);
    }
  };

  const handleCompare = async () => {
    const mainPathwayId = mainPathwayForm.getValues('main_pathway_id');
    const mainPathwayVersion = mainPathwayForm.getValues('main_pathway_version');
    const smsPathwayId = smsPathwayForm.getValues('sms_pathway_id');
    const smsPathwayVersion = smsPathwayForm.getValues('sms_pathway_version');

    if (!mainPathwayId || !smsPathwayId) {
      toast.error('Please select both pathways to compare');
      return;
    }

    setIsLoading(true);
    try {
      // Fetch both pathway versions using the API
      const mainPathwayData = await $fetch(`/v1/pathway/${mainPathwayId}/version/${mainPathwayVersion}`);
      const smsPathwayData = await $fetch(`/v1/pathway/${smsPathwayId}/version/${smsPathwayVersion}`);

      // Store the fetched data
      setPathwayData({
        mainPathway: {
          ...mainPathwayData,
          id: mainPathwayId,
          version: mainPathwayVersion
        },
        smsPathway: {
          ...smsPathwayData,
          id: smsPathwayId,
          version: smsPathwayVersion
        }
      });

      // Show the merge conflicts modal
      setShowMergeModal(true);
    } catch (error) {
      console.error('Error fetching pathway data:', error);
      toast.error('Failed to load pathway data for comparison');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {showMergeModal ? (
        <MergeConflictsModal
          onClose={() => {
            setShowMergeModal(false);
            onClose();
          }}
          mainPathwayData={pathwayData.mainPathway}
          smsPathwayData={pathwayData.smsPathway}
        />
      ) : (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg shadow-xl w-11/12 max-w-5xl mx-auto p-6">
            <div className="flex items-center mb-6">
              <GitMerge className="h-5 w-5 text-indigo-600 mr-2" />
              <h2 className="text-xl font-bold text-gray-900">Compare and Merge Pathways</h2>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              {/* Production Pathway Selection */}
              <div className="border p-4 rounded-lg shadow-sm">
                <h3 className="text-lg font-medium text-gray-800 mb-4">Production Pathway</h3>
                <FormProvider {...mainPathwayForm}>
                  <div className="space-y-12 w-full">
                    <PathwaySelect 
                    //   label="Production Pathway"
                      value={mainPathwayForm.watch('main_pathway_id')}
                      onSelectionChange={handleMainPathwaySelection}
                      className="w-full min-w-full"
                      style={{ width: '100%', minWidth: '300px' }}
                    />
                    {mainPathwayForm.watch('main_pathway_id') && (
                      <PathwayVersion 
                        pathwayId={mainPathwayForm.watch('main_pathway_id')} 
                        value={mainPathwayForm.watch('main_pathway_version')}
                        onSelectionChange={handleMainVersionSelection}
                        className="w-full min-w-full"
                        style={{ width: '100%', minWidth: '300px' }}
                      />
                    )}
                  </div>
                </FormProvider>
              </div>

              {/* SMS Pathway Selection */}
              <div className="border p-4 rounded-lg shadow-sm">
                <h3 className="text-lg font-medium text-gray-800 mb-4">SMS Pathway</h3>
                <FormProvider {...smsPathwayForm}>
                  <div className="space-y-12 w-full">
                    <PathwaySelect 
                    //   label="SMS Pathway"
                      value={smsPathwayForm.watch('sms_pathway_id')}
                      onSelectionChange={handleSmsPathwaySelection}
                      className="w-full min-w-full"
                      style={{ width: '100%', minWidth: '300px' }}
                    />
                    {smsPathwayForm.watch('sms_pathway_id') && (
                      <PathwayVersion 
                        pathwayId={smsPathwayForm.watch('sms_pathway_id')} 
                        value={smsPathwayForm.watch('sms_pathway_version')}
                        onSelectionChange={handleSmsVersionSelection}
                        className="w-full min-w-full"
                        style={{ width: '100%', minWidth: '300px' }}
                      />
                    )}
                  </div>
                </FormProvider>
              </div>
            </div>

            <div className="flex justify-between mt-8">
              <button
                onClick={onClose}
                className="px-4 py-2 text-gray-700 border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none"
              >
                Cancel
              </button>
              <button
                onClick={handleCompare}
                disabled={isLoading}
                className={`px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 focus:outline-none flex items-center ${isLoading ? 'opacity-70 cursor-not-allowed' : ''}`}
              >
                {isLoading ? (
                  <>
                    <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white mr-2"></div>
                    Loading...
                  </>
                ) : (
                  <>
                    Compare Pathways
                    <ArrowRight className="ml-2 h-4 w-4" />
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PathwayComparisonSelector; 