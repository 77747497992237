import { Database } from 'lucide-react'

function RequestData({ requestData }) {
  if (!requestData || Object.keys(requestData).length === 0) {
    return (
      <div className="flex flex-col items-center justify-center h-full p-8 text-center">
        <Database size={50} className="text-gray-300 mb-4" />
        <h3 className="text-xl font-semibold text-gray-600">No Variables Found</h3>
        <p className="text-gray-500 mt-2">
          This conversation doesn't have any request data or variables associated with it.
        </p>
      </div>
    )
  }

  // Convert the variables object to an array format for rendering
  const variablesArray = Object.entries(requestData).map(([key, value]) => ({
    key,
    value: typeof value === 'object' ? JSON.stringify(value) : String(value)
  }));

  return (
    <div className="flex-1 overflow-auto">
      <div className="bg-white rounded-md border border-gray-200 mb-4">
        <div className="bg-gray-50 p-3 border-b border-gray-200">
          <h3 className="text-sm font-medium text-gray-700">Request Variables</h3>
        </div>
        <div className="p-0">
          <table className="w-full text-left text-sm">
            <thead className="bg-gray-50 text-xs uppercase text-gray-700">
              <tr>
                <th className="px-4 py-2 font-medium">Key</th>
                <th className="px-4 py-2 font-medium">Value</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {variablesArray.map((item, idx) => (
                <tr key={idx} className="hover:bg-gray-50">
                  <td className="px-4 py-3 font-medium text-gray-900">{item.key}</td>
                  <td className="px-4 py-3 text-gray-700 break-all">{item.value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default RequestData 