import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { Switch } from '@headlessui/react'
import { useStatsigClient } from '@statsig/react-bindings'
import { useAuth } from 'hooks/useAuth'
import { AlertCircle, Bot, Copy, Plus, SquareArrowOutUpRight } from 'lucide-react'
import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { toast } from 'react-toastify'
import { $fetch } from 'utils/fetch'
import FlowContext from '../contextFlow'
import LibrarySidebarContext from '../contexts/librarySidebarContext'
import InlineEditor from '../copilot/InlineEditor'
import AmazonConnectModal from './amazonConnectModal'
import ConditionOptions from './conditionOptions'
import CustomToolModal from './customToolModal'
import ExtractInfo from './extractInfo'
import FineTuningExamples from './FineTuningExamples'
import GlobalNodeOptions from './globalSelect'
import useGlobalNodeOptions from './modals/hooks/useGlobalNodeOptions'
import IVRModal from './modals/IVRModal'
import RouteModal from './modals/RouteModal'
import ModelOptions from './modelOptions'
import ScheduleMeetingModal from './nodes/modals/scheduleMeeting'
import NodeTag from './NodeTag'
import PressButtonModal from './pressButtonModal'
import { TextAreaWithSuggestion } from './promptImprovementTextArea'
import RedirectTwilioFlowModal from './redirectTwilioFlowModal'
import SMSModal from './smsModal'
import StaticTextArea from './StaticTextArea'
import PathwayTransferModal from './transferPathwayModal'
import TwilioApplicationTransfer from './twilioApplicationTransfer'
import UnitTest from './unitTest'
import WarmTransferOptions from './warmTransferOptions'
import WebhookNodeModal from './webhook_node_modal'

function NodeModal({
  id,
  setNodes,
  setEdges,
  isOpen,
  setIsOpen,
  data,
  type,
  context,
  pathwayId,
  selectedVersion,
}) {
  const { user, org } = useAuth()
  const { client: statsig } = useStatsigClient()
  const isTransferChatEnabled = statsig.checkGate('sms_pathways')

  const { setCurrentOpenNodeID, setShowLibrarySidebar } = useContext(
    LibrarySidebarContext,
  )
  // im regretting using 1 modal for all the nodes..

  const kb_limit = [
    '92c9f81d-f7fb-4567-85de-c39b49ec8d14',
    '22ea48a5-12bc-4f71-af5c-a191304053b9',
  ].includes(user?.user?.id)
    ? 100000
    : 30000

  useEffect(() => {
    setName(data.name)
    setText(data.text)
    setKb(data.kb || '')
    setKbTool(data.kbTool || '')
    setPrompt(data.prompt || '')
    setTransferNumber(data.transferNumber || '')
    setIsForwardingEnabled(data.forwardingNode)
    setForwardingNode(data.forwardingNode || '')
    setModelOptions(
      data.modelOptions || {
        modelType: 'smart',
        temperature: 0.2,
      },
    )
    setUseExistingKb(!!data.kbTool)
    setUseSpeechDuringKB(data.speechDuringKBPrompt || false)
    setSpeechDuringKBPrompt(data.speechDuringKBPrompt || '')
    setCondition(data.condition || '')
    setUseStaticText(!data.prompt)
    setIsConditionChecked(data.condition)
    setIsGlobal(data.isGlobal || false)
    setGlobalPathwayLabel(data.globalLabel || '')
    setGlobalPathwayDescription(data.globalDescription || '')
    setEnableGlobalAutoReturn(
      data.enableGlobalAutoReturn !== false,
    )
    setPrevNodePathwayLabel(data.prevNodePathwayLabel || '')
    setPrevNodePathwayDescription(data.prevNodePathwayDescription || '')
    setNodeType(type || 'default')
    setExtractVars(data.extractVars || [['', '', '', false]])
    setUseExtractVars(!!data.extractVars)
    setExtractVarSettings(data.extractVarSettings || {})
    setDialogueExamples(data.dialogueExamples || [])
    setPathwayExamples(data.pathwayExamples || [])
    setConditionExamples(data.conditionExamples || [])
    setWarmTransferFields(
      data.warmTransferFields || {
        isEnabled: false,
        mergeCallPrompt: '',
        isMergeCallPromptStatic: false,
        agentPrompt: '',
        isAgentPromptStatic: false,
        userHandling: 'on-hold',
        holdMusicUrl: 'http://com.twilio.sounds.music.s3.amazonaws.com/MARKOVICHAMP-Borghestral.mp3',
        waitingPrompt: '',
        useCustomFromNumber: false,
        fromNumber: '',
        optimizeForIVR: true,
      },
    )
    setNodeTag(data.tag || null)
  }, [data, type])

  const {
    triggerUpdate,
    elements,
    embedMode,
    setIsNodeModalOpen,
  } = useContext(FlowContext)

  useEffect(() => {
    setIsNodeModalOpen(isOpen)
  }, [isOpen, setIsNodeModalOpen])

  const {
    isGlobal,
    setIsGlobal,
    globalPathwayLabel,
    setGlobalPathwayLabel,
    globalPathwayDescription,
    setGlobalPathwayDescription,
    enableGlobalAutoReturn,
    setEnableGlobalAutoReturn,
    prevNodePathwayLabel,
    setPrevNodePathwayLabel,
    prevNodePathwayDescription,
    setPrevNodePathwayDescription,
    enableReturnToPrevBeforeGlobal,
    setEnableReturnToPrevBeforeGlobal,
    isChildOfGlobalNode,
    isForwardingEnabled,
    setIsForwardingEnabled,
    forwardingNode,
    setForwardingNode,
  } = useGlobalNodeOptions({
    data,
    elements,
    nodeId: id,
  })

  const nodeOptions = useMemo(() => {
    const baseOptions = [
      { id: 1, name: 'Default', value: 'Default' },
      { id: 2, name: 'Large Text', value: 'Knowledge Base' },
      { id: 3, name: 'Transfer Call', value: 'Transfer Call' },
      { id: 4, name: 'End Call', value: 'End Call' },
      { id: 5, name: 'Webhook', value: 'Webhook' },
      { id: 6, name: 'Wait for Response', value: 'Wait for Response' },
      {
        id: 7,
        name: 'Knowledge Base',
        value: 'Vector DB Knowledge Base',
      },
      { id: 8, name: 'Transfer Pathway', value: 'Transfer Pathway' },
      { id: 9, name: 'Custom Tool', value: 'Custom Tool' },
      { id: 10, name: 'Press Button', value: 'Press Button' },
      { id: 11, name: 'Route', value: 'Route' },
      { id: 12, name: 'IVR', value: 'IVR' },
      {
        id: 13,
        name: 'Schedule Meeting',
        value: 'Schedule Meeting',
      },
    ]
    if (isTransferChatEnabled) {
      baseOptions.push({
        id: 14,
        name: 'Transfer Chat',
        value: 'Transfer Chat',
      })
    }

    const enterpriseOptions = [
      { id: 14, name: 'SMS', value: 'SMS', enterprise: true },
      {
        id: 15,
        name: 'Amazon Connect',
        value: 'Amazon Connect',
        enterprise: true,
      },
      {
        id: 16,
        name: 'Twilio Flow Redirect',
        value: 'Twilio Flow Redirect',
        enterprise: true,
      },
    ]

    return org?.org_type === 'enterprise'
      ? [...baseOptions, ...enterpriseOptions]
      : baseOptions
  }, [org?.org_type])

  const [name, setName] = useState(data.name)
  const [text, setText] = useState(data.text)
  const [kb, setKb] = useState(data.kb || '')
  const [kbTool, setKbTool] = useState(data.kbTool || '')
  const [useManualInput, setUseManualInput] = useState(
    data?.useManualInput || embedMode,
  )
  const [prompt, setPrompt] = useState(data.prompt || '')
  const [transferNumber, setTransferNumber] = useState(
    data.transferNumber || '',
  )
  const [modelOptions, setModelOptions] = useState(
    data.modelOptions || {
      modelType: 'smart',
      temperature: 0.2,
    },
  )

  const [kbOptions, setKbOptions] = useState([]) // State for dropdown options
  const [useExistingKb, setUseExistingKb] = useState(
    !!data.kbTool,
  )

  const [useSpeechDuringKB, setUseSpeechDuringKB] = useState(
    data.speechDuringKBPrompt || false,
  )

  const [speechDuringKBPrompt, setSpeechDuringKBPrompt] = useState(
    data.speechDuringKBPrompt || '',
  )

  const [condition, setCondition] = useState(data.condition || '')
  const [useStaticText, setUseStaticText] = useState(!data.prompt)
  const [isConditionChecked, setIsConditionChecked] = useState(data.condition)

  const [nodeTag, setNodeTag] = useState(
    data.tag || {
      name: 'default',
      color: '#000000',
    },
  )

  const [nodeType, setNodeType] = useState(type || 'default')

  const [extractVars, setExtractVars] = useState(
    data.extractVars || [['', '', '', false]],
  )
  const [useExtractVars, setUseExtractVars] = useState(
    !!data.extractVars,
  )
  const [extractVarSettings, setExtractVarSettings] = useState(
    data.extractVarSettings || {},
  )

  const [IVRActiveTab, setIVRActiveTab] = useState(
    data.prompt ? 'preview' : 'questions',
  )

  const [variableValidationErrors, setVariableValidationErrors] = useState([])
  const unitTestRef = useRef()
  const textAreaRef = useRef(null)

  const [ivrSurvey, setIvrSurvey] = useState(data.IVRSurvey || null)

  const handleIVRSurveyUpdate = (surveyData) => {
    setIvrSurvey(surveyData)
  }

  useEffect(() => {
    if (data.prompt) {
      setIVRActiveTab('preview')
    }
  }, [])

  const validateVariables = () => {
    // if useExtractVars is false, no need to validate
    if (!useExtractVars) {
      return true
    }
    const errors = []
    extractVars.forEach((variable, index) => {
      variable.forEach((field, fieldIndex) => {
        if (typeof field === 'string' && field.trim() === '') {
          errors.push({
            index,
            field: fieldIndex,
            message: 'Field is required',
          })
        }
      })
    })
    setVariableValidationErrors(errors)
    return errors.length === 0
  }

  const textareaRef = useRef(null)
  // useEffect for when useExtractVars is toggled, if its false then clear the validation errors
  useEffect(() => {
    if (!useExtractVars) {
      setVariableValidationErrors([])
    }
  }, [useExtractVars])

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto' // Reset height to get correct scrollHeight
      const newHeight = Math.min(textareaRef.current.scrollHeight, 200)
      textareaRef.current.style.height = `${newHeight}px`
    }
  })

  const deleteKbTool = async () => {
    const { data } = await $fetch(`/v1/knowledgebases/${kbTool}/delete`, {
      method: 'POST',
    })
    if (data) {
      setKbTool('')
      setKbOptions(kbOptions.filter(kb => kb.vector_id !== kbTool))
    }
    else {
      console.error('Failed to delete KB tool')
    }
  }

  const fetchKbOptions = async () => {
    const { data } = await $fetch(`/v1/knowledgebases`, {
      method: 'GET',
    })
    if (data) {
      setKbOptions(data.vectors)

      if (data.vectors.length === 0) {
        toast.warn('No Knowledge Bases found. Please create one first!')
      }
    }
    else {
      console.error('Failed to fetch KB options')
    }
  }

  useEffect(() => {
    if (nodeType === 'Vector DB Knowledge Base') {
      fetchKbOptions()
    }
  }, [nodeType])

  const [dialogueExamples, setDialogueExamples] = useState(
    data.dialogueExamples || [],
  )
  const [pathwayExamples, setPathwayExamples] = useState(
    data.pathwayExamples || [],
  )
  const [conditionExamples, setConditionExamples] = useState(
    data.conditionExamples || [],
  )

  useEffect(() => {
    setDialogueExamples(data.dialogueExamples || [])
  }, [data.dialogueExamples])

  useEffect(() => {
    setPathwayExamples(data.pathwayExamples || [])
  }, [data.pathwayExamples])

  useEffect(() => {
    setConditionExamples(data.conditionExamples || [])
  }, [data.conditionExamples])

  const copyNodeId = () => {
    navigator.clipboard.writeText(id)
    toast.success('Node ID copied to clipboard!')
  }

  const [warmTransferFields, setWarmTransferFields] = useState(
    data.warmTransferFields || {
      isEnabled: false,
      mergeCallPrompt: '',
      isMergeCallPromptStatic: false,
      agentPrompt: '',
      isAgentPromptStatic: false,
      userHandling: 'on-hold',
      holdMusicUrl:
        'http://com.twilio.sounds.music.s3.amazonaws.com/MARKOVICHAMP-Borghestral.mp3',
      waitingPrompt: '',
      useCustomFromNumber: false,
      fromNumber: '',
      optimizeForIVR: true,
    },
  )

  const [twilioAppTransferFields, setTwilioAppTransferFields] = useState(
    data.twilioAppTransferFields || {
      isEnabled: false,
      appSid: '',
      appParams: {},
    },
  )

  const transferType = useMemo(() => {
    return twilioAppTransferFields.isEnabled ? 'twilio-app' : 'phone'
  }, [twilioAppTransferFields.isEnabled])

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen} className="w-3/5">
      <DialogContent className="fixed w-3/5 !max-w-none bg-white px-8 py-5 left-[50%] top-[50%] -translate-x-[50%] -translate-y-[50%] space-y-2.5 overflow-y-scroll max-h-[90vh] ">
        <DialogHeader>
          <div className="flex justify-between items-center">
            <DialogTitle>
              {context === 'preview' ? 'Preview Node' : 'Edit Node'}
            </DialogTitle>
            {context !== 'preview' && (
              <div className="flex items-center space-x-1.5">
                <Button
                  onClick={() => {
                    setCurrentOpenNodeID(id)
                    setIsOpen(false)
                    setShowLibrarySidebar(true)
                  }}
                  className="flex items-center px-1.5 py-0.5 text-xs bg-indigo-100 hover:bg-indigo-200 rounded text-indigo-800"
                >
                  <Plus className="w-2.5 h-2.5 mr-0.5" />
                  Add to Node Library
                </Button>

                <button
                  onClick={copyNodeId}
                  className="flex items-center px-1.5 py-0.5 text-xs bg-gray-100 hover:bg-gray-200 rounded-sm"
                >
                  <Copy className="w-2.5 h-2.5 mr-0.5" />
                  Copy ID
                </button>
              </div>
            )}
          </div>
          {/* Optional Dialog Description */}
        </DialogHeader>

        {nodeType !== 'Route' && (
          <div>
            <label htmlFor="nodeType" className="block mb-1.5">
              Node Type
            </label>
            <Select
              value={nodeType}
              onValueChange={value => setNodeType(value)}
            >
              <SelectTrigger className="w-full h-8">
                <SelectValue placeholder="Select a node type" />
              </SelectTrigger>
              <SelectContent className="z-[10000]">
                {nodeOptions.map(option => (
                  <SelectItem key={option.id} value={option.value}>
                    {option.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
        )}

        {nodeType === 'Wait for Response' && (
          <div>
            <p className="text-gray-500 text-sm">
              This node will wait for a response from the user before
              proceeding.
            </p>
          </div>
        )}

        {nodeType === 'Webhook' ? (
          <WebhookNodeModal
            id={id}
            setNodes={setNodes}
            setEdges={setEdges}
            data={data}
            setIsOpen={setIsOpen}
          />
        ) : nodeType === 'Transfer Pathway' ? (
          <PathwayTransferModal id={id} data={data} setIsOpen={setIsOpen} />
        ) : nodeType === 'Custom Tool' ? (
          <CustomToolModal id={id} data={data} setIsOpen={setIsOpen} />
        ) : nodeType === 'SMS' ? (
          <SMSModal id={id} data={data} setIsOpen={setIsOpen} />
        ) : nodeType === 'Press Button' ? (
          <PressButtonModal id={id} data={data} setIsOpen={setIsOpen} />
        ) : nodeType === 'Amazon Connect' ? (
          <AmazonConnectModal id={id} data={data} setIsOpen={setIsOpen} />
        ) : nodeType === 'Schedule Meeting' ? (
          <ScheduleMeetingModal id={id} data={data} setIsOpen={setIsOpen} />
        ) : nodeType === 'Twilio Flow Redirect' ? (
          <RedirectTwilioFlowModal id={id} data={data} setIsOpen={setIsOpen} />
        ) : nodeType === 'Route' ? (
          <div>
            <RouteModal
              id={id}
              data={data}
              setIsOpen={setIsOpen}
              isNodeRoutingMode
              nodeType={nodeType}
              nodeOptions={nodeOptions}
              onNodeTypeChange={setNodeType}
            />
          </div>
        ) : nodeType === 'IVR' && IVRActiveTab === 'questions' ? (
          <IVRModal
            id={id}
            data={data}
            setIsOpen={setIsOpen}
            IVRActiveTab={IVRActiveTab}
            setIVRActiveTab={setIVRActiveTab}
            setPrompt={setPrompt}
            setCondition={setCondition}
            setUseStaticText={setUseStaticText}
            setIsConditionChecked={setIsConditionChecked}
            onSurveyUpdate={handleIVRSurveyUpdate}
          />
        ) : (
          <div className="space-y-2.5">
            <div>Name:</div>
            <input
              type="text"
              value={name}
              onChange={e => setName(e.target.value)}
              className="border border-gray-300 p-1.5 rounded w-full"
            />

            {
              // add a button to switch to the "questions tab"
              nodeType === 'IVR' && IVRActiveTab !== 'questions' && (
                <button
                  onClick={() => setIVRActiveTab('questions')}
                  className="bg-gray-100 hover:bg-gray-200 rounded p-1.5"
                >
                  Switch to Questions
                </button>
              )
            }

            {nodeType === 'Vector DB Knowledge Base' && (
              <>
                <div className="space-y-1.5 flex flex-col">
                  <div className="flex items-center justify-between">
                    <label className="block text-[12px] font-medium text-gray-700">
                      Knowledge Base
                    </label>
                    {!embedMode && (
                      <div className="flex items-center space-x-1.5">
                        <label className="text-[12px] text-gray-600">
                          Manual Input
                        </label>
                        <input
                          type="checkbox"
                          checked={useManualInput}
                          onChange={(e) => {
                            setUseManualInput(e.target.checked)
                            setKbTool('') // Reset KB selection when toggling
                          }}
                          className="h-2.5 w-2.5 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded-sm"
                        />
                      </div>
                    )}
                  </div>

                  {useManualInput
                    ? (
                        <div className="space-y-1.5">
                          <input
                            type="text"
                            value={kbTool}
                            onChange={e => setKbTool(e.target.value)}
                            placeholder="Enter Knowledge Base ID or variable"
                            className="block w-full pl-2 pr-6 py-1.5 border border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 rounded text-[12px]"
                          />
                          <p className="text-[11px] text-gray-500 italic">
                            You can enter either a specific Knowledge Base ID (e.g.,
                            "kb_123") or use a variable (e.g.,
                            {' '}
                            {'{{ kbVariable }}'}
                            ). Variables will be resolved at runtime.
                          </p>
                        </div>
                      )
                    : (
                        <>
                          <Select
                            value={kbTool}
                            onValueChange={value => setKbTool(value)}
                          >
                            <SelectTrigger className="w-full h-8">
                              <SelectValue placeholder="Select a Knowledge Base" />
                            </SelectTrigger>
                            <SelectContent className="z-[10000]">
                              <SelectItem value="none" disabled>
                                Select a Knowledge Base
                              </SelectItem>
                              {kbOptions.map(option => (
                                <SelectItem
                                  key={option.id}
                                  value={option.vector_id}
                                >
                                  {option.name}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>

                          <button
                            className="flex items-center px-1.5 py-0.5 gap-1.5 text-xs bg-gray-100 hover:bg-gray-200 rounded-sm ml-auto"
                            onClick={() =>
                              window.open('/dashboard/knowledge-bases', '_blank')}
                          >
                            <SquareArrowOutUpRight size={12} color="grayText" />
                            Manage Knowledge Bases
                          </button>
                        </>
                      )}
                </div>
              </>
            )}
            <div className="flex space-x-2.5">
              <Switch
                checked={useStaticText}
                onChange={setUseStaticText}
                className={`${
                  useStaticText ? 'bg-indigo-600' : 'bg-gray-200'
                } relative inline-flex h-4 w-7 items-center rounded-full`}
              >
                <span className="sr-only">Static Text</span>
                <span
                  className={`${
                    useStaticText ? 'translate-x-4' : 'translate-x-0.5'
                  } inline-block h-2.5 w-2.5 transform rounded-full bg-white transition`}
                />
              </Switch>
              <label>
                Static Text
                {' '}
                <span className="italic">
                  (When you want the agent to say a specific dialogue. Uncheck
                  to use AI generated text)
                </span>
                {' '}
              </label>
            </div>
            {useStaticText
              ? (
                  <>
                    <div>Text:</div>
                    <StaticTextArea
                      placeholder="Exact text to be spoken by the agent"
                      value={text}
                      onChange={e => setText(e)}
                      className="border border-gray-300 rounded w-full min-h-[100px]"
                    />
                  </>
                )
              : (
                  <div className="relative">
                    <InlineEditor
                      triggerRef={textareaRef}
                      textAreaValue={prompt}
                      setTextAreaValue={setPrompt}
                      type="Prompt"
                      buttonClassName="absolute -top-5 right-0"
                      popupClassName="absolute -top-[80px] left-0 w-[500px]"
                    />
                    <TextAreaWithSuggestion
                      ref={textAreaRef}
                      placeholder="Provide a short goal/prompt for what the agent needs to do - e.g. Ask for the customer's name"
                      value={prompt}
                      onChange={e => setPrompt(e)}
                      className="border border-gray-300 rounded w-full min-h-[100px] z-[10]"
                    />
                  </div>
                )}
            {nodeType === 'Knowledge Base' && (
              <>
                <div>Paste Plain Text Content:</div>
                <textarea
                  value={kb}
                  maxLength={kb_limit}
                  onChange={e => setKb(e.target.value)}
                  className="border border-gray-300 p-1.5 rounded w-full min-h-[100px]"
                />
                <div className="text-right text-xs mt-0.5">
                  {kb.length}
                  {' '}
                  /
                  {kb_limit}
                  {' '}
                  characters
                </div>
              </>
            )}

            {nodeType === 'Transfer Call' && (
              <>
                <div className="space-y-2.5">
                  <div>
                    <label className="block mb-1.5">Transfer Type</label>
                    <Select
                      value={transferType}
                      onValueChange={(value) => {
                        if (value === 'twilio-app') {
                          setTwilioAppTransferFields(prev => ({
                            ...prev,
                            isEnabled: true,
                          }))
                        }
                        else {
                          setTwilioAppTransferFields(prev => ({
                            ...prev,
                            isEnabled: false,
                          }))
                        }
                      }}
                      disabled={org?.org_type !== 'enterprise'}
                    >
                      <SelectTrigger className={`w-full h-8 ${org?.org_type !== 'enterprise' ? 'opacity-50 cursor-not-allowed' : ''}`}>
                        <SelectValue placeholder="Select transfer type" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="phone">Phone Number</SelectItem>
                        {org?.org_type === 'enterprise' && (
                          <SelectItem value="twilio-app">Twilio Application</SelectItem>
                        )}
                      </SelectContent>
                    </Select>
                  </div>

                  {transferType === 'twilio-app'
                    ? (
                        <TwilioApplicationTransfer
                          twilioAppTransferFields={twilioAppTransferFields}
                          setTwilioAppTransferFields={setTwilioAppTransferFields}
                        />
                      )
                    : (
                        <div>
                          <label className="block mb-1.5">Transfer Number</label>
                          <input
                            type="tel"
                            value={transferNumber}
                            onChange={e => setTransferNumber(e.target.value)}
                            pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                            placeholder="Enter phone number"
                            className="border border-gray-300 p-1.5 rounded w-full"
                          />
                        </div>
                      )}
                </div>

                <WarmTransferOptions
                  warmTransferFields={warmTransferFields}
                  setWarmTransferFields={setWarmTransferFields}
                />
              </>
            )}

            {nodeType === 'Transfer Chat' && isTransferChatEnabled && (
              <>
                <div>Transfer Number:</div>
                <input
                  type="tel"
                  value={transferNumber}
                  onChange={e => setTransferNumber(e.target.value)}
                  pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                  className="border border-gray-300 p-1.5 rounded w-full"
                />
              </>
            )}

            {nodeType === 'Vector DB Knowledge Base' && (
              <div className="border p-2.5 rounded shadow-sm">
                <div className="flex items-center justify-between">
                  <div>
                    <label className="text-base">
                      Send Speech During Knowledge Base Query
                    </label>
                    <p className="text-sm text-gray-600 mt-0.5">
                      Enable speech output while the Knowledge Base Query is
                      running in the background.
                    </p>
                  </div>
                  <Switch
                    checked={useSpeechDuringKB}
                    onChange={() => setUseSpeechDuringKB(!useSpeechDuringKB)}
                    className={`${
                      useSpeechDuringKB ? 'bg-indigo-600' : 'bg-gray-200'
                    } relative inline-flex h-4 w-7 items-center rounded-full`}
                  >
                    <span className="sr-only">Enable Speech During Query</span>
                    <span
                      className={`${
                        useSpeechDuringKB ? 'translate-x-4' : 'translate-x-0.5'
                      } inline-block h-2.5 w-2.5 transform rounded-full bg-white transition`}
                    />
                  </Switch>
                </div>
                {useSpeechDuringKB && (
                  <div className="space-x-2.5 mt-2.5 w-full">
                    <div className="mt-2.5">
                      Prompt:
                      <textarea
                        placeholder="Instructions for the agent to say while the Knowledge Base Query is running in the background."
                        value={speechDuringKBPrompt}
                        onChange={e =>
                          setSpeechDuringKBPrompt(e.target.value)}
                        className="border border-gray-300 p-1.5 rounded w-full text-sm mt-1.5 min-h-[100px]"
                      />
                    </div>
                  </div>
                )}
              </div>
            )}

            <ConditionOptions
              isConditionChecked={isConditionChecked}
              setIsConditionChecked={setIsConditionChecked}
              condition={condition}
              setCondition={setCondition}
            />

            <GlobalNodeOptions
              isGlobal={isGlobal}
              setIsGlobal={setIsGlobal}
              globalPathwayLabel={globalPathwayLabel}
              setGlobalPathwayLabel={setGlobalPathwayLabel}
              globalPathwayDescription={globalPathwayDescription}
              setGlobalPathwayDescription={setGlobalPathwayDescription}
              isForwardingEnabled={isForwardingEnabled}
              setIsForwardingEnabled={setIsForwardingEnabled}
              forwardingNode={forwardingNode}
              setForwardingNode={setForwardingNode}
              enableGlobalAutoReturn={enableGlobalAutoReturn}
              setEnableGlobalAutoReturn={setEnableGlobalAutoReturn}
              prevNodePathwayLabel={prevNodePathwayLabel}
              setPrevNodePathwayLabel={setPrevNodePathwayLabel}
              prevNodePathwayDescription={prevNodePathwayDescription}
              setPrevNodePathwayDescription={setPrevNodePathwayDescription}
              isChildOfGlobalNode={isChildOfGlobalNode}
              enableReturnToPrevBeforeGlobal={enableReturnToPrevBeforeGlobal}
              setEnableReturnToPrevBeforeGlobal={
                setEnableReturnToPrevBeforeGlobal
              }
            />

            <ModelOptions
              modelOptions={modelOptions}
              setModelOptions={setModelOptions}
              isIVRNode={nodeType === 'IVR'}
            />

            <ExtractInfo
              extractVars={extractVars}
              setExtractVars={setExtractVars}
              useExtractVars={useExtractVars}
              setUseExtractVars={setUseExtractVars}
              validationErrors={variableValidationErrors}
              extractVarSettings={extractVarSettings}
              setExtractVarSettings={setExtractVarSettings}
              setValidationErrors={setVariableValidationErrors}
            />

            <FineTuningExamples
              id={id}
              dialogueExamples={dialogueExamples}
              setDialogueExamples={setDialogueExamples}
              pathwayExamples={pathwayExamples}
              setPathwayExamples={setPathwayExamples}
              conditionExamples={conditionExamples}
              setConditionExamples={setConditionExamples}
            />

            <UnitTest
              ref={unitTestRef}
              initialTestData={data.unitTests || null}
            />

            <NodeTag nodeTag={nodeTag} setNodeTag={setNodeTag} />

            <div className="mt-2.5 flex flex-row items-center">
              <button
                onClick={() => {
                  if (validateVariables()) {
                    let edges = elements.edges
                    const updatedNodes = elements.nodes.map((element_node) => {
                      if (element_node.id === id) {
                        const node = structuredClone(element_node)
                        node.data.name = name
                        if (nodeTag) {
                          node.data.tag = nodeTag
                        }
                        else {
                          delete node.data.tag
                        }
                        node.type = nodeType
                        if (useStaticText) {
                          node.data.text = text
                          setPrompt('')
                          delete node.data.prompt
                        }
                        else {
                          node.data.prompt = prompt
                          setText('')
                          delete node.data.text
                        }

                        if (isForwardingEnabled && forwardingNode) {
                          node.data.forwardingNode = forwardingNode
                        }
                        else {
                          delete node.data.forwardingNode
                        }

                        if (isConditionChecked && condition) {
                          node.data.condition = condition
                        }
                        else {
                          delete node.data.condition
                        }
                        if (nodeType === 'Knowledge Base') {
                          node.data.kb = kb
                        }
                        else {
                          delete node.data.kb
                        }

                        if (nodeType === 'IVR') {
                          node.data.IVRSurvey = ivrSurvey
                        }

                        if (nodeType === 'Vector DB Knowledge Base') {
                          if (kbTool === '') {
                            toast.warn(
                              'Please select a Knowledge Base from the dropdown. If you don\'t have one, please create one first!',
                            )
                            return element_node
                          }
                          node.data.kbTool = kbTool
                          if (useSpeechDuringKB) {
                            node.data.speechDuringKBPrompt
                              = speechDuringKBPrompt
                          }
                          else {
                            delete node.data.speechDuringKBPrompt
                          }
                        }
                        else {
                          delete node.data.kbTool
                        }
                        if (nodeType === 'Transfer Call') {
                          node.data.transferNumber = transferNumber
                          if (!node.data.warmTransferFields?.isEnabled) {
                            edges = edges.filter(edge => edge.source !== id)
                          }
                          node.data.warmTransferFields = warmTransferFields
                          node.data.twilioAppTransferFields = twilioAppTransferFields
                        }
                        else {
                          delete node.data.warmTransferFields
                          delete node.data.twilioAppTransferFields
                        }

                        if (
                          nodeType === 'Transfer Chat'
                          && isTransferChatEnabled
                        ) {
                          node.data.transferNumber = transferNumber
                        }

                        if (
                          !['Transfer Call', 'Transfer Chat'].includes(nodeType)
                        ) {
                          delete node.data.transferNumber
                        }

                        if (dialogueExamples.length > 0) {
                          node.data.dialogueExamples = dialogueExamples
                        }
                        else {
                          delete node.data.dialogueExamples
                        }
                        if (pathwayExamples.length > 0) {
                          const savedPathwayEg = pathwayExamples.filter(
                            (example) => {
                              return (
                                example['Conversation History'] !== ''
                                && example['Chosen Pathway'] !== ''
                              )
                            },
                          )
                          node.data.pathwayExamples = savedPathwayEg
                        }
                        else {
                          delete node.data.pathwayExamples
                        }
                        if (conditionExamples.length > 0) {
                          node.data.conditionExamples = conditionExamples
                        }
                        else {
                          delete node.data.conditionExamples
                        }

                        if (node.data?.routeExamples) {
                          delete node.data.routeExamples
                        }

                        if (modelOptions) {
                          if (modelOptions.newTemperature) {
                            modelOptions.newTemperature = Math.min(
                              Math.max(
                                Number.parseFloat(modelOptions.newTemperature),
                                0,
                              ),
                              1,
                            )
                            delete modelOptions.temperature
                          }
                          else {
                            delete modelOptions.newTemperature
                          }
                          if (modelOptions.temperature) {
                            console.log(
                              'modelOptions.temperature',
                              modelOptions.temperature,
                              typeof modelOptions.temperature,
                            )
                            modelOptions.temperature = Number.parseFloat(
                              modelOptions.temperature.toFixed(1),
                            )
                          }
                          else {
                            delete modelOptions.temperature
                          }
                          if (modelOptions.interruptionThreshold) {
                            modelOptions.interruptionThreshold = Number.parseInt(
                              modelOptions.interruptionThreshold,
                            )
                          }
                          else {
                            delete modelOptions.interruptionThreshold
                          }
                          if (
                            modelOptions.skipUserResponse
                            && modelOptions.skipUserResponse === true
                          ) {
                            modelOptions.skipUserResponse = true
                          }
                          else {
                            modelOptions.skipUserResponse = false
                          }

                          if (
                            modelOptions.disableSilenceRepeat
                            && modelOptions.disableSilenceRepeat === true
                          ) {
                            modelOptions.disableSilenceRepeat = true
                          }
                          else {
                            modelOptions.disableSilenceRepeat = false
                          }

                          if (
                            modelOptions.conditionOverridesGlobalPathway
                            && modelOptions.conditionOverridesGlobalPathway
                            === true
                          ) {
                            modelOptions.conditionOverridesGlobalPathway = true
                          }
                          else {
                            delete modelOptions.conditionOverridesGlobalPathway
                          }

                          node.data.modelOptions = modelOptions
                        }
                        else {
                          delete node.data.modelOptions
                        }

                        if (isGlobal && !isChildOfGlobalNode) {
                          node.data.isGlobal = true
                          node.data.globalLabel = globalPathwayLabel
                          if (globalPathwayDescription) {
                            node.data.globalDescription
                              = globalPathwayDescription
                          }
                          else {
                            delete node.data.globalDescription
                          }
                          if (!enableGlobalAutoReturn) {
                            // if auto return is disabled/treating like normal node
                            node.data.enableGlobalAutoReturn = false
                            if (prevNodePathwayLabel) {
                              node.data.prevNodePathwayLabel
                                = prevNodePathwayLabel
                            }
                            if (prevNodePathwayDescription) {
                              node.data.prevNodePathwayDescription
                                = prevNodePathwayDescription
                            }
                          }
                          else {
                            edges = edges.filter(
                              edge =>
                                edge.target !== id && edge.source !== id,
                            )
                            delete node.data.enableGlobalAutoReturn
                            delete node.data.prevNodePathwayLabel
                            delete node.data.prevNodePathwayDescription
                          }
                        }
                        else {
                          delete node.data.isGlobal
                          delete node.data.globalLabel
                          delete node.data.globalDescription
                          delete node.data.enableGlobalAutoReturn
                          delete node.data.prevNodePathwayLabel
                          delete node.data.prevNodePathwayDescription
                        }

                        if (enableReturnToPrevBeforeGlobal) {
                          node.data.enableReturnToPrevBeforeGlobal = true
                        }
                        else {
                          delete node.data.enableReturnToPrevBeforeGlobal
                        }

                        delete node.data.body
                        delete node.data.method
                        delete node.data.url
                        delete node.data.responsePathways
                        delete node.data.timeoutValue

                        delete node.data.transferPathway
                        delete node.data.transferPathwayNode

                        const testData
                          = unitTestRef.current.getCurrentTestData()
                        // Add the test data to the node
                        if (testData.isEnabled) {
                          node.data.unitTests = testData
                        }
                        else {
                          delete node.data.unitTests
                        }

                        if (useExtractVars) {
                          if (extractVarSettings) {
                            node.data.extractVarSettings = extractVarSettings
                          }

                          const resVars = extractVars.filter((varArr) => {
                            if (varArr[0] === 'bland-prompt') {
                              return true
                            }
                            // For all variables excluding "bland-prompt", ensure all fields are non-empty
                            return (
                              varArr[0] !== ''
                              && varArr[1] !== ''
                              && varArr[2] !== ''
                            )
                          })

                          const nonPromptVars = resVars.filter(
                            varArr => varArr[0] !== 'bland-prompt',
                          )

                          if (nonPromptVars.length === 0) {
                            // If there are no valid variables, remove extractVars completely
                            delete node.data.extractVars
                            setExtractVars([])
                          }
                          else {
                            // Save the variables including the "bland-prompt" if it exists and is non-empty
                            node.data.extractVars = resVars
                            setExtractVars(resVars)
                          }
                        }
                        else {
                          delete node.data.extractVars
                        }
                        return node
                      }
                      return element_node
                    })
                    triggerUpdate(
                      {
                        nodes: updatedNodes,
                        edges,
                      },
                      false,
                    )
                    setIsOpen(false)
                    //   exportFlow();
                  }
                }}
                hidden={context === 'preview'}
                className="bg-indigo-500 text-white py-1.5 px-2.5 rounded-"
              >
                Save
              </button>
              {variableValidationErrors.length > 0 && (
                <div className="relative group ml-1.5">
                  <AlertCircle className="h-4 w-4 text-red-500 " />
                  <span className="absolute left-full ml-1.5 top-1/2 transform -translate-y-1/2 bg-red-100 text-red-800 text-2xs font-medium px-1.5 py-px rounded-sm hidden group-hover:block whitespace-nowrap">
                    Some variables are missing fields
                  </span>
                </div>
              )}
            </div>
          </div>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default NodeModal
