import Button from 'components/core/Button'
import { Divider } from 'components/core/Divider'
import Input from 'components/core/Input'
import Textarea from 'components/core/Textarea'
import { useFieldArray, useFormContext } from 'react-hook-form'
import styled from 'styled-components'
import SectionHeader from '../../../SectionHeader'

export default function Variables({ fieldName, requestIndex }) {
  const { control } = useFormContext()

  const { fields, append, remove } = useFieldArray({
    control,
    name: `${fieldName}[${requestIndex}].response_data`,
  })

  return (
    <Wrapper>
      {fields.length > 0
        ? (
            fields.map((response, responseIndex) => (
              <>
                <Divider dark />
                <Wrapper key={response.id}>
                  <SectionHeader>
                    <p className="font-bold">
                      Variable
                      {responseIndex + 1}
                    </p>
                    <Button
                      appearance="outline"
                      onClick={() => {
                        remove(responseIndex)
                      }}
                    >
                      -
                    </Button>
                  </SectionHeader>
                  <Input
                    label="JSON Path to Data"
                    fieldName={`${fieldName}[${requestIndex}].response_data[${responseIndex}].data`}
                  />
                  <Input
                    label="Variable Name"
                    fieldName={`${fieldName}[${requestIndex}].response_data[${responseIndex}].name`}
                  />
                  <Textarea
                    label="Context"
                    fieldName={`${fieldName}[${requestIndex}].response_data[${responseIndex}].context`}
                  />
                </Wrapper>
              </>
            ))
          )
        : (
            <p>No variables addded yet</p>
          )}
      <Button
        type="button"
        appearance="outline"
        onClick={() =>
          append({
            name: '',
            data: '$',
            context: '',
          })}
      >
        + Variable
      </Button>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 100%;
  align-items: start;
`
