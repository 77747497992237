import * as Icons from '@radix-ui/react-icons'
import {
  Button,
  DropdownMenu,
  Popover,
  Skeleton,
  Spinner,
  TextField,
} from '@radix-ui/themes'
import { useStatsigClient } from '@statsig/react-bindings'
import Avatar from 'components/Avatar/Avatar'
import { useAuth } from 'hooks/useAuth'
import {
  Check,
  ChevronsUpDown,
  Search,
} from 'lucide-react'
import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ImpersonateModal from './ImpersonateModal'

export default function AccountSelector({
  open,
  setOpen,
  onClick = null,
  onClose = null,
  children,
}) {
  const { orgs, selectOrg, user, signOut, credits, org, loading } = useAuth()
  const navigate = useNavigate()
  const { client: statsigClient } = useStatsigClient()
  const [openImpersonateModal, setOpenImpersonateModal] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')

  const filteredOrgs = useMemo(() =>
    orgs?.filter(org =>
      (org.org_display_name || 'Unknown Org').toLowerCase().includes(searchQuery.toLowerCase()),
    ), [orgs, searchQuery])

  return (
    <div style={{ width: '100%' }}>
      {/* <div>
        <label>
          <input type="checkbox" checked={one} onClick={() => setOne(!one)} />
          Show/Hide Credits
        </label>
        <p style={{ fontSize: 8, height: 100 }}>
          {active ? JSON.stringify(active) : "None"}
        </p>
      </div> */}
      <div style={{ width: '100%' }}>
        <Popover.Root
          open={open}
          onOpenChange={(open) => {
            if (onClose && !open) {
              return onClose()
            }

            if (onClick && open) {
              return onClick()
            }
          }}
        >
          <Popover.Trigger>
            <div
              id="main-component"
              style={{
                // borderRadius: 25,
                // border: "1px solid #f2f2f2",
                // padding: "8px 8px",
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                flexWrap: 'nowrap',
                justifyContent: 'space-between',
                borderRadius: 10,
                columnGap: 5,
                // transition: "background-color 0.3s ease",
                cursor: 'pointer',
                width: '100%',
                // maxWidth: 190,
                // minWidth: 190,
                backgroundColor: open ? '#f5f5f5' : 'transparent',
              }}
              // onMouseEnter={(e) => {
              //   e.currentTarget.style.backgroundColor = "#f9f9f9";
              // }}
              // onMouseLeave={(e) => {
              //   e.currentTarget.style.backgroundColor = "transparent";
              // }}
            >
              {!user || loading === true ? (
                <>
                  <Skeleton style={{ height: 24, width: 240 }} />
                </>
              ) : (
                <>
                  <Avatar org={org} />
                  <div
                    style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      marginLeft: 10,
                      width: '600px',
                    }}
                  >
                    {' '}
                    {/* <p
                      style={{
                        fontSize: one ? 12 : 14,
                        fontWeight: "500",
                        color: "#000",
                      }}
                    >
                      {active.length > 20
                        ? `${active.substring(0, 20)}...`
                        : active}
                    </p> */}
                    <p style={{ fontSize: 10, color: 'GrayText' }}>
                      {loading
                        ? (
                            <Spinner
                              size="1"
                              style={{ height: 10, marginTop: 5 }}
                            />
                          )
                        : (
                            `${credits?.toFixed(2) || 0} credits`
                          )}
                      {' '}
                    </p>
                  </div>
                  <ChevronsUpDown
                    size={16}
                    color="#000"
                    style={{ marginLeft: 'auto' }}
                  />
                </>
              )}
            </div>
          </Popover.Trigger>
          <Popover.Content
            // sticky="always"
            style={{
              'padding': 0,
              'minWidth': 300,
              'height': 'fit-content',
              'borderRadius': 10,
              'background': '#fff',
              'left': 0,
              'overflowY': 'scroll',
              'overflowX': 'hidden',
              'scrollbarWidth': 'none',
              'scrollbarColor': '#f1f1f1 #f1f1f1',
              'scrollBehavior': 'smooth',
              /* Add these styles for webkit browsers */
              '&::-webkit-scrollbar': {
                width: '4px', // Even thinner scrollbar
              },
              '&::-webkit-scrollbar-track': {
                background: '#f1f1f1',
              },
              '&::-webkit-scrollbar-thumb': {
                background: '#f1f1f1',
                borderRadius: '8px',
              },
              'boxShadow':
                '0 4px 8px -2px rgba(0, 0, 0, 0.1), 2px 2px 4px -2px rgba(0, 0, 0, 0.08)',
            }}
            updatePositionStrategy="always"
          >
            <div
              id="search"
              style={{
                borderBottom: '1px solid #e9e9e9',
                width: 'calc(100% + 10px)',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  position: 'relative',
                  height: 40,
                }}
              >
                <Search
                  style={{
                    position: 'absolute',
                    left: 10,
                    top: '50%',
                    transform: 'translateY(-50%)',
                  }}
                  size={13}
                  color="gray"
                />
                <TextField.Root
                  radius="none"
                  style={{
                    padding: '2px 8px 2px 27px', // Adjusted padding to make space for the icon
                    borderTopRightRadius: 3,
                    height: 40,
                    borderTopLeftRadius: 3,
                    // borderTop: "2px solid #eeeeee",
                    fontSize: 13,
                    fontWeight: '400',
                    width: 'calc(100% + 8px)',
                    outline: 'none', // Added to remove the blue outline on focus or selection
                  }}
                  placeholder="Find an org..."
                  value={searchQuery}
                  onChange={e => setSearchQuery(e.target.value)}
                />
              </div>
            </div>

            <Popover.Close>
              <div style={{ padding: 10 }}>
                <div
                  style={{
                    marginTop: 5,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                  }}
                >
                  <div
                    style={{
                      marginTop: 5,
                      display: 'flex',
                      flexDirection: 'column',
                      maxHeight: 200,
                      overflowY: 'scroll',
                      scrollbarWidth: 'none',
                      gap: 2,
                    }}
                  >
                    {filteredOrgs?.map((option, index) => (
                      <div
                        key={index}
                        onMouseEnter={(e) => {
                          if (org?.id !== option?.org_id) {
                            e.currentTarget.style.backgroundColor = '#f9f9f9'
                          }
                        }}
                        onMouseLeave={(e) => {
                          if (org?.id !== option?.org_id) {
                            e.currentTarget.style.backgroundColor
                              = 'transparent'
                          }
                        }}
                        onClick={() => {
                          selectOrg(option?.org_id)
                        }}
                        style={{
                          padding: 6,
                          borderRadius: 4,
                          cursor: 'pointer',
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          background:
                            org?.id === option?.org_id
                              ? '#e9e9e9'
                              : 'transparent',
                        }}
                      >
                        <Avatar org={option} size={28} />

                        <div style={{ marginLeft: 15 }}>
                          <p
                            style={{
                              fontWeight: '450',
                              fontSize: 14,
                            }}
                          >
                            {option?.org_display_name?.length > 20
                              ? `${option?.org_display_name?.substring(0, 20)}...`
                              : option?.org_display_name}
                          </p>
                          <p
                            style={{
                              marginTop: -3,
                              fontSize: 11,
                              fontWeight: '400',
                              color: 'GrayText',
                            }}
                          >
                            {option?.permissions?.[0]}
                          </p>
                        </div>
                        {org?.id === option?.org_id && (
                          <Check style={{ marginLeft: 'auto' }} size={13} />
                        )}
                      </div>
                    ))}

                    {!orgs?.length && (
                      <div style={{ padding: '0px 2px' }}>
                        <p
                          style={{
                            fontSize: 12,
                            fontWeight: 400,
                            color: 'GrayText',
                            marginBottom: 0,
                          }}
                        >
                          You don't have any organizations yet.
                        </p>
                      </div>
                    )}
                  </div>

                  {/* <div style={{ padding: "0px 4px", marginTop: 5 }}>
                  <Button
                    variant="ghost"
                    color="iris"
                    onMouseEnter={(e) => {
                      e.currentTarget.style.backgroundColor = "#f9f9f9";
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.backgroundColor = "transparent";
                    }}
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      padding: "8px 6px",
                      borderRadius: 4,
                      cursor: "pointer",
                    }}
                  >
                    <PlusCircleIcon size={13} />
                    <p
                      style={{
                        fontWeight: "500",
                        fontSize: 14,
                        marginLeft: 12,
                      }}
                    >
                      Create Org
                    </p>
                  </Button>
                </div> */}

                  <DropdownMenu.Separator
                    style={{ margin: '10px -10px 10px -10px' }}
                  />
                  {statsigClient.checkGate('impersonate_org') && (
                    <div style={{ padding: '0px 4px', marginTop: 7 }}>
                      <Button
                        variant="ghost"
                        color="iris"
                        onClick={() => setOpenImpersonateModal(true)}
                        onMouseEnter={(e) => {
                          e.currentTarget.style.backgroundColor = '#f9f9f9'
                        }}
                        onMouseLeave={(e) => {
                          e.currentTarget.style.backgroundColor = 'transparent'
                        }}
                        style={{
                          width: '100%',
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                          gap: 10,
                          borderRadius: 4,
                          // padding: "0px 14px",
                          cursor: 'pointer',
                        }}
                      >
                        <Icons.IdCardIcon style={{ color: 'GrayText' }} />
                        <p
                          style={{
                            fontWeight: '450',
                            fontSize: 14,
                            color: 'GrayText',
                          }}
                        >
                          Impersonate
                        </p>
                      </Button>
                    </div>
                  )}

                  <div style={{ padding: '0px 4px', marginTop: 7 }}>
                    <Button
                      variant="ghost"
                      color="iris"
                      onClick={() => navigate('/dashboard/settings')}
                      onMouseEnter={(e) => {
                        e.currentTarget.style.backgroundColor = '#f9f9f9'
                      }}
                      onMouseLeave={(e) => {
                        e.currentTarget.style.backgroundColor = 'transparent'
                      }}
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        gap: 10,
                        borderRadius: 4,
                        // padding: "0px 14px",
                        cursor: 'pointer',
                      }}
                    >
                      <Icons.GearIcon style={{ color: 'GrayText' }} />
                      <p
                        style={{
                          fontWeight: '450',
                          fontSize: 14,
                          color: 'GrayText',
                        }}
                      >
                        Settings
                      </p>
                    </Button>
                  </div>

                  {/* <div style={{ padding: "0px 4px", marginTop: 7 }}>
                  <Button
                    variant="ghost"
                    color="iris"
                    onClick={() => navigate("/dashboard/billing-details")}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.backgroundColor = "#f9f9f9";
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.backgroundColor = "transparent";
                    }}
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      borderRadius: 4,
                      // padding: "0px 14px",
                      cursor: "pointer",
                    }}
                  >
                    <p
                      style={{
                        fontWeight: "450",
                        fontSize: 14,
                        color: "GrayText",
                      }}
                    >
                      Billing
                    </p>
                  </Button>
                </div> */}

                  <div style={{ padding: '0px 4px', marginTop: 7 }}>
                    <Button
                      variant="ghost"
                      color="iris"
                      onMouseEnter={(e) => {
                        e.currentTarget.style.backgroundColor = '#f9f9f9'
                      }}
                      onMouseLeave={(e) => {
                        e.currentTarget.style.backgroundColor = 'transparent'
                      }}
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        borderRadius: 4,
                        gap: 10,
                        // padding: "0px 14px",
                        cursor: 'pointer',
                      }}
                    >
                      <Icons.DiscordLogoIcon style={{ color: 'GrayText' }} />
                      <p
                        style={{
                          fontWeight: '450',
                          fontSize: 14,
                          color: 'GrayText',
                        }}
                      >
                        Discord
                      </p>
                    </Button>
                  </div>

                  <div style={{ padding: '0px 4px', marginTop: 7 }}>
                    <Button
                      variant="ghost"
                      color="iris"
                      onMouseEnter={(e) => {
                        e.currentTarget.style.backgroundColor = '#f9f9f9'
                      }}
                      onMouseLeave={(e) => {
                        e.currentTarget.style.backgroundColor = 'transparent'
                      }}
                      onClick={() =>
                        window.open('https://docs.bland.ai', '_blank')}
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        borderRadius: 4,
                        gap: 10,
                        // padding: "0px 14px",
                        cursor: 'pointer',
                      }}
                    >
                      <Icons.ReaderIcon style={{ color: 'GrayText' }} />
                      <p
                        style={{
                          fontWeight: '450',
                          fontSize: 14,
                          color: 'GrayText',
                        }}
                      >
                        Documentation
                      </p>
                    </Button>
                  </div>

                  <div style={{ padding: '0px 4px', marginTop: 7 }}>
                    <Button
                      variant="ghost"
                      color="iris"
                      onMouseEnter={(e) => {
                        e.currentTarget.style.backgroundColor = '#f9f9f9'
                      }}
                      onMouseLeave={(e) => {
                        e.currentTarget.style.backgroundColor = 'transparent'
                      }}
                      onClick={() =>
                        window.open('https://university.bland.ai', '_blank')}
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        borderRadius: 4,
                        gap: 10,
                        // padding: "0px 14px",
                        cursor: 'pointer',
                      }}
                    >
                      <Icons.CardStackMinusIcon style={{ color: 'GrayText' }} />
                      <p
                        style={{
                          fontWeight: '450',
                          fontSize: 14,
                          color: 'GrayText',
                        }}
                      >
                        Bland University
                      </p>
                    </Button>
                  </div>

                  <div style={{ padding: '0px 4px', marginTop: 7 }}>
                    <Button
                      variant="ghost"
                      color="crimson"
                      onClick={(event) => {
                        event.stopPropagation()
                        event.preventDefault()
                        signOut()
                      }}
                      // onMouseEnter={(e) => {
                      //   e.currentTarget.style.backgroundColor = "#f9f9f9";
                      // }}
                      // onMouseLeave={(e) => {
                      //   e.currentTarget.style.backgroundColor = "transparent";
                      // }}
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        borderRadius: 4,
                        gap: 10,
                        // padding: "0px 14px",
                        cursor: 'pointer',
                      }}
                    >
                      <Icons.ExitIcon style={{ color: 'GrayText' }} />
                      <p
                        style={{
                          fontWeight: '450',
                          fontSize: 14,
                          // color: "#000",
                        }}
                      >
                        Sign out
                      </p>
                    </Button>
                  </div>
                </div>
              </div>
            </Popover.Close>
          </Popover.Content>
        </Popover.Root>
      </div>
      <ImpersonateModal
        isOpen={openImpersonateModal}
        onOpenChange={setOpenImpersonateModal}
      />
    </div>
  )
}
