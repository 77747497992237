import React, { useState } from 'react';
import { AlertTriangle, Check, Clipboard } from 'lucide-react';

const GlobalPromptConflictView = ({ 
  conflict, 
  selectedVersion, 
  onSelectVersion, 
  editableContent, 
  setEditableContent 
}) => {
  // Production and SMS versions from the conflict
  const prodVersion = conflict.productionVersion || {};
  const smsVersion = conflict.smsVersion || {};
  
  // Helper function to extract global prompt from a version object
  const extractGlobalPrompt = (version) => {
    // Method 1: Check if version has globalConfig.globalPrompt
    if (version.globalConfig && typeof version.globalConfig.globalPrompt === 'string') {
      return version.globalConfig.globalPrompt;
    }
    
    // Method 2: Check if version has globalPrompt directly
    if (typeof version.globalPrompt === 'string') {
      return version.globalPrompt;
    }
    
    // Method 3: Get from nodes' data.globalPrompt
    if (version.nodes && version.nodes.length > 0) {
      // Check each node
      for (const node of version.nodes) {
        // Check for data.globalPrompt
        if (node.data && typeof node.data.globalPrompt === 'string') {
          return node.data.globalPrompt;
        }
        
        // Check for node with globalConfig.globalPrompt
        if (node.globalConfig && typeof node.globalConfig.globalPrompt === 'string') {
          return node.globalConfig.globalPrompt;
        }
      }
    }
    
    // Not found
    return "";
  };
  
  // Get global prompts from both versions
  const prodGlobalPrompt = extractGlobalPrompt(prodVersion);
  const smsGlobalPrompt = extractGlobalPrompt(smsVersion);
  
  // Render a visual indicator for differences with different colors based on change type
  const renderDiffIndicator = (prodValue, smsValue) => {
    // Check if the field exists in both versions but with different values
    if (prodValue && smsValue && prodValue !== smsValue) {
      return <div className="w-3 h-3 rounded-full bg-orange-400" title="Changed value" />;
    }
    
    // Check if the field exists only in production (deleted in SMS)
    if (prodValue && !smsValue) {
      return <div className="w-3 h-3 rounded-full bg-red-500" title="Deleted in SMS version" />;
    }
    
    // Check if the field exists only in SMS (new field)
    if (!prodValue && smsValue) {
      return <div className="w-3 h-3 rounded-full bg-green-500" title="New in SMS version" />;
    }
    
    return null; // No difference
  };
  
  // Render value with appropriate formatting for text areas
  const renderTextValue = (value) => {
    if (value === undefined || value === null || value === "") {
      return <span className="text-gray-400 italic">not present</span>;
    }
    
    return (
      <div className="max-h-96 overflow-y-auto bg-gray-50 p-4 rounded border border-gray-200">
        <pre className="whitespace-pre-wrap text-sm">{value}</pre>
      </div>
    );
  };

  // Check if there is a difference in global prompts
  const hasGlobalPromptDifference = prodGlobalPrompt !== smsGlobalPrompt;

  return (
    <div className="global-prompt-conflict-view">
      
      {/* Side-by-side Card Comparison */}
      <div className="grid grid-cols-2 gap-6">
        {/* Production Version Card */}
        <div 
          className={`border rounded-lg overflow-hidden bg-white shadow-sm ${
            selectedVersion === 'production' ? 'ring-2 ring-blue-500' : ''
          }`}
          onClick={() => onSelectVersion('production')}
        >
          <div className="bg-gray-50 px-4 py-3 border-b">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <div className="w-3 h-3 rounded-full bg-blue-500 mr-2"></div>
                <h4 className="font-medium text-gray-800">Production Global Prompt</h4>
              </div>
              {hasGlobalPromptDifference && (
                <div className="flex items-center">
                  {renderDiffIndicator(prodGlobalPrompt, smsGlobalPrompt)}
                </div>
              )}
            </div>
          </div>
          
          <div className="p-4">
            {renderTextValue(prodGlobalPrompt)}
          </div>
        </div>
        
        {/* SMS Version Card */}
        <div 
          className={`border rounded-lg overflow-hidden bg-white shadow-sm ${
            selectedVersion === 'sms' ? 'ring-2 ring-indigo-500' : ''
          }`}
          onClick={() => onSelectVersion('sms')}
        >
          <div className="bg-gray-50 px-4 py-3 border-b">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <div className="w-3 h-3 rounded-full bg-indigo-500 mr-2"></div>
                <h4 className="font-medium text-gray-800">SMS Global Prompt</h4>
              </div>
              {hasGlobalPromptDifference && (
                <div className="flex items-center">
                  {renderDiffIndicator(prodGlobalPrompt, smsGlobalPrompt)}
                </div>
              )}
            </div>
          </div>
          
          <div className="p-4">
            {renderTextValue(smsGlobalPrompt)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GlobalPromptConflictView; 