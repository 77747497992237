import { useCallback, useState, } from "react";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";

const TwilioApplicationTransfer = ({twilioAppTransferFields, setTwilioAppTransferFields}) => {
  const [applicationSid, setApplicationSid] = useState(twilioAppTransferFields?.appSid ?? "");
  
  const [keyValuePairs, setKeyValuePairs] = useState(() => {
    try {
      const parsed = twilioAppTransferFields.appParams ? twilioAppTransferFields.appParams : {};
      return Object.entries(parsed).map(([key, value]) => ({ key, value })) || [{ key: "", value: "" }];
    } catch {
      return [{ key: "", value: "" }];
    }
  });

  const updateField = useCallback(
    (field, value) => {
      setTwilioAppTransferFields(prev => ({ ...prev, [field]: value }))
    },
    [setTwilioAppTransferFields],
  )

  // Key-Value Pair handlers
  const addKeyValuePair = () => {
    const newPairs = [...keyValuePairs, { key: "", value: "" }];
    setKeyValuePairs(newPairs);
    updateKVPField(newPairs);
  };

  const removeKeyValuePair = (index) => {
    const newPairs = keyValuePairs.filter((_, i) => i !== index);
    setKeyValuePairs(newPairs);
    updateKVPField(newPairs);
  };

  const updatePair = (index, type, value) => {
    const newPairs = [...keyValuePairs];
    newPairs[index][type] = value;
    setKeyValuePairs(newPairs);
    updateKVPField(newPairs);
  };

  const updateKVPField = (pairs) => {
    // Convert array of key-value pairs to object before saving
    const paramsObject = pairs.reduce((acc, { key, value }) => {
      if (key) {  // Only include pairs with non-empty keys
        acc[key] = value;
      }
      return acc;
    }, {});
    
    setTwilioAppTransferFields(prev => ({ 
      ...prev, 
      appParams: paramsObject
    }));
  };
  return (
    <div className="border p-2.5 rounded shadow-sm">
      <div className="flex items-center justify-between">
        <h3 className="text-base">Twilio Application Transfer</h3>
      </div>
      <p className="text-xs text-gray-600 mt-1.5">
        Transfer to a custom Twilio Application and pass through variables by Bland throughout the call.
      </p>

      <div className="mt-4 space-y-4">
        <div>
          <Label htmlFor="application-sid" className="text-xs font-medium">
            Application SID
          </Label>
          <p className="text-xs text-gray-500 mt-0.5">
            Enter the Twilio application SID that will handle the transfer.
          </p>
          <Input
            id="application-sid"
            value={applicationSid}
            onChange={(e) => {
              setApplicationSid(e.target.value);
              updateField('appSid', e.target.value);
            }}
            placeholder="Enter Application SID"
            className="mt-1.5"
          />
        </div>

        <div>
          <Label className="text-xs font-medium">Parameters</Label>
          <p className="text-xs text-gray-500 mt-0.5">
            Add variables that will be passed to the application during transfer.
          </p>
          <div className="space-y-2 mt-1.5">
            {keyValuePairs.map((pair, index) => (
              <div key={index} className="flex gap-2">
                <Input
                  placeholder="Key"
                  value={pair.key}
                  onChange={(e) => updatePair(index, "key", e.target.value)}
                  className="flex-1"
                />
                <Input
                  placeholder="Value"
                  value={pair.value}
                  onChange={(e) => updatePair(index, "value", e.target.value)}
                  className="flex-1"
                />
                <Button
                  type="button"
                  onClick={() => removeKeyValuePair(index)}
                  className="px-2"
                >
                  -
                </Button>
              </div>
            ))}
            <div className="flex justify-end">
              <Button
                type="button"
                onClick={addKeyValuePair}
                className="px-2"
              >
                +
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TwilioApplicationTransfer;
