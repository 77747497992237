import { TooltipText } from '_components/Text'
import TextInput from '_components/TextInput'
import { useFormContext, useWatch } from 'react-hook-form'

export default function MaxDuration({ fieldName }) {
  const currentMaxDuration = useWatch({
    name: fieldName,
    control: useFormContext().control,
  })

  const description = (
    <span>
      When the call starts, a timer is set for the
      {' '}
      <b>max_duration</b>
      {' '}
      minutes.
      At the end of that timer, if the call is still active it will be
      automatically ended.
      {' '}
      <br></br>
      Example Values:
      <b> 20, 2</b>
    </span>
  )
  return (
    <TextInput
      fieldName={fieldName}
      label={(
        <TooltipText tooltip={description} tooltipClassName="w-[300px]">
          Max Duration (mins)
        </TooltipText>
      )}
      type="number"
      min="1"
      placeholder="12"
      value={currentMaxDuration}
    />
  )
}
