import { Button, useDisclosure, Tooltip } from '@heroui/react'
import { MessageSquare } from 'lucide-react'
import SMSTestModal from './SMSTestModal'

export default function SMSTestButton({ 
  selectedNumber, 
  disabled = false, 
  highlight = false,
  onSuccess = () => {},
  onClick = null,
  availableNumbers = [],
  isViewPathway = false,
  pathwayId = null
}) {

  const { isOpen, onOpen, onClose } = useDisclosure()
  
  const handleButtonClick = () => {
    if (onClick) {
      onClick()
    } else {
      onOpen()
    }
  }

  return (
    <>
      <Tooltip content="Test your SMS configuration">
        <Button
          color="primary"
          variant="flat"
          size="sm"
          startContent={<MessageSquare size={16} className="text-black" />}
          className={highlight ? "animate-pulse bg-gray-100 text-black border border-gray-200" : "bg-gray-50 text-black border border-gray-200 hover:bg-gray-100"}
          onPress={handleButtonClick}
          isDisabled={disabled}
          id="sms-test-button"
        >
          Test SMS
        </Button>
      </Tooltip>

      {!onClick && (
        <SMSTestModal 
          isOpen={isOpen} 
          onClose={onClose} 
          selectedNumber={selectedNumber} 
          availableNumbers={availableNumbers}
          onSuccess={onSuccess}
          isViewPathway={isViewPathway}
          pathwayId={pathwayId}
        />
      )}
    </>
  )
} 