import config from 'config'
import { ofetch } from 'ofetch'
import { getAuthToken } from 'utils/funcs/browser/getAuthToken'
import { getOrgId } from 'utils/funcs/browser/getOrgId'

export const $fetch = ofetch.create({
  baseURL: config.API_URL,
  retry: false,
  timeout: 1000 * 120, // Default Node fetch timeout
  async onRequest({ options }) {
    const headersToRemove = new Set([
      'authorization',
      'auth',
      'x-bland-org-id',
    ])

    const originalHeaders
      = options.headers instanceof Headers
        ? Object.fromEntries(options.headers.entries())
        : options.headers || {}

    const filteredHeaders = Object.entries(originalHeaders).reduce(
      (acc, [key, value]) => {
        if (!headersToRemove.has(key.toLowerCase())) {
          acc[key] = value
        }
        return acc
      },
      {},
    )

    const authToken = getAuthToken()
    const orgId = getOrgId()

    options.headers = {
      ...filteredHeaders,
      ...(authToken && { Authorization: authToken }),
      ...(orgId && { 'x-bland-org-id': orgId }),
    }
  },
})
