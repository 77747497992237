import { forwardRef, Textarea } from '@heroui/react'

const SummaryInput = forwardRef((props, ref) => {
  return (
    <Textarea
      ref={ref}
      label="Summary Prompt"
      radius="sm"
      variant="bordered"
      placeholder="Enter a prompt for the summary generated post-call"
      labelPlacement="outside"
      minRows={20}
      {...props}
    />
  )
})

export default SummaryInput
