import { useMemo } from 'react'
import { useSetState } from 'react-use'
import { OnboardingContext } from './onboardingContext'

const onboardingState = {
  run: false,
  stepIndex: 0,
  steps: [],
  tourActive: false,
  sideMenuOpen: false,
  personaId: null,
}

export function OnboardingProvider(props) {
  const [state, setState] = useSetState(onboardingState)

  const value = useMemo(
    () => ({
      state,
      setState,
    }),
    [setState, state],
  )

  return <OnboardingContext.Provider value={value} {...props} />
}
