import { useCallback, useEffect, useMemo, useState } from 'react'
import { $fetch } from 'utils/fetch'
import { getOrgId } from 'utils/funcs/browser/getOrgId'
import ChatUI from './chat/chatUI'
import ReplayControls from './ReplayControls'

function PathwayReplay({
  callId,
  onClose,
  onNodesFocus,
  setCallID,
  setShowChat,
}) {
  const [isPlaying, setIsPlaying] = useState(false)
  const [replayIndex, setReplayIndex] = useState(0)
  const [replaySpeed, setReplaySpeed] = useState(500)
  const [callData, setCallData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  const orgId = getOrgId()

  const fetchCallData = useCallback(async () => {
    setLoading(true)
    setError(null)
    try {
      const data = await $fetch(
        `/analytics/pathways/call_path?callId=${callId}`,
      )
      setCallData(data.data.data)
      setCallID(callId)
      setShowChat(true)
    }
    catch (err) {
      console.error(err)
      setError('Failed to load replay data. Please try again.')
    }
    finally {
      setLoading(false)
    }
  }, [callId])

  useEffect(() => {
    fetchCallData()
  }, [fetchCallData])

  const { filteredDialogue, filteredCallPath, dialogueIndices }
    = useMemo(() => {
      if (!callData || !callData.dialogue || !callData.callPath) {
        return {
          filteredDialogue: [],
          filteredCallPath: [],
          dialogueIndices: [],
        }
      }

      const filtered = callData.dialogue.reduce(
        (acc, message, dialogueIndex) => {
          if (message.text && message.text.trim() !== '') {
            acc.filteredDialogue.push(message)
            acc.dialogueIndices.push(dialogueIndex)

            // Find the first preceding non-null call path entry
            let callPathIndex = dialogueIndex
            while (
              callPathIndex >= 0
              && callData.callPath[callPathIndex] === null
            ) {
              callPathIndex--
            }

            // If we found a non-null entry, use it; otherwise, use null
            const callPathEntry
              = callPathIndex >= 0 ? callData.callPath[callPathIndex] : null
            acc.filteredCallPath.push(callPathEntry)
          }
          return acc
        },
        { filteredDialogue: [], filteredCallPath: [], dialogueIndices: [] },
      )

      return filtered
    }, [callData])

  useEffect(() => {
    setIsPlaying(false)
    setReplayIndex(0)
    setError(null)
  }, [callId])

  const togglePlay = useCallback(() => {
    setIsPlaying(prev => !prev)
  }, [])

  const reset = useCallback(() => {
    setReplayIndex(0)
    setIsPlaying(false)
  }, [])

  const handleSpeedChange = useCallback((e) => {
    setReplaySpeed(Number(e.target.value))
  }, [])

  const jumpToMessage = useCallback(
    (index) => {
      setReplayIndex(Math.min(Math.max(0, index), filteredDialogue.length - 1))
    },
    [filteredDialogue],
  )

  useEffect(() => {
    let intervalId
    if (isPlaying && filteredDialogue.length > 0) {
      if (replayIndex === filteredDialogue.length - 1) {
        setReplayIndex(0)
      }
      intervalId = setInterval(() => {
        setReplayIndex((prevIndex) => {
          if (prevIndex < filteredDialogue.length - 1) {
            return prevIndex + 1
          }
          else {
            setIsPlaying(false)
            return prevIndex
          }
        })
      }, replaySpeed)
    }
    return () => clearInterval(intervalId)
  }, [isPlaying, filteredDialogue, replaySpeed])

  useEffect(() => {
    const activePath = filteredCallPath.slice(0, replayIndex + 1)
    onNodesFocus(activePath)
  }, [replayIndex, filteredCallPath])

  return (
    <>
      <ReplayControls
        isPlaying={isPlaying}
        togglePlay={togglePlay}
        reset={reset}
        replaySpeed={replaySpeed}
        handleSpeedChange={handleSpeedChange}
        jumpToMessage={jumpToMessage}
        replayIndex={replayIndex}
        totalSteps={filteredDialogue.length}
        onClose={onClose}
        loading={loading}
        error={error}
        onRetry={fetchCallData}
        noData={!callData || filteredCallPath.length === 0}
      />
      {!loading && !error && callData && (
        <ChatUI
          messages={filteredDialogue}
          replayIndex={replayIndex}
          onReplayIndexChange={jumpToMessage}
          callPath={filteredCallPath}
          isReplayMode
          onClose={onClose}
        />
      )}
    </>
  )
}

export default PathwayReplay
