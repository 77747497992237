import Button from 'components/core/Button'
import { useEffect, useRef, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import styled from 'styled-components'
import CloseIcon from '../../../../assets/icons/close-icon-black.svg'
import CodeIcon from '../../../../assets/icons/CodeIcon.svg'

export default function BooleanDropdown({ keypairs, fieldname, label }) {
  const { setValue, getValues, watch } = useFormContext()
  const [bool, setBool] = useState(getValues(`${fieldname}.value` || false))
  const [isOpen, setIsOpen] = useState(false)
  const dropdownRef = useRef(null)

  useEffect(() => {
    setBool(getValues(`${fieldname}.value`))
  }, [getValues, `${fieldname}.value`])

  const toggleSwitch = () => {
    if (fieldname && !getValues(`${fieldname}.using_key`)) {
      const newValue = !bool
      setBool(newValue)
      setValue(`${fieldname}.value`, newValue)
    }
  }

  const handleOptionClick = (optionValue) => {
    setValue(`${fieldname}.value`, optionValue)
    setValue(`${fieldname}.using_key`, true)
    setIsOpen(false) // Close dropdown on option select
  }

  const handleOptionClear = () => {
    setValue(`${fieldname}.value`, false)
    setValue(`${fieldname}.using_key`, false)
    setIsOpen(false)
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [dropdownRef])

  return (
    <ToggleContainer ref={dropdownRef}>
      {getValues(fieldname)?.using_key
        ? (
            <>
              <InputContainer>
                <Label style={{ marginBottom: 10 }}>{label}</Label>
                <StyledOption
                  onClick={() => setIsOpen(!isOpen)}
                  style={{ border: '1px solid #eeeeee' }}
                >
                  <IconContainer>
                    <img src={CodeIcon} style={{ height: 16, width: 16 }} alt="" />
                  </IconContainer>

                  <div>
                    <p style={{ fontWeight: '300', fontSize: 10 }}>Key</p>
                    <p style={{ fontWeight: '550', fontSize: 13 }}>
                      {getValues(`${fieldname}.value`)}
                    </p>
                  </div>

                  <div onClick={handleOptionClear} style={{ marginLeft: 'auto' }}>
                    <img src={CloseIcon} alt="close" />
                  </div>
                </StyledOption>
              </InputContainer>
            </>
          )
        : (
            <>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  width: '100%',
                  justifyContent: 'space-between',
                }}
              >
                <Label>{label}</Label>

                <Button
                  onClick={() => setIsOpen(true)}
                  appearance="outline"
                  style={{ marginLeft: 'auto', marginRight: 20, borderRadius: 6 }}
                >
                  <img src={CodeIcon} style={{ height: 14, width: 14 }} />
                </Button>
                <SwitchBody on={bool} onClick={toggleSwitch}>
                  <Flicker on={bool} />
                </SwitchBody>
              </div>
            </>
          )}

      {isOpen && keypairs?.length > 0 && (
        <DropdownContent style={{ marginTop: 15 }}>
          {keypairs.map((doc, ind) => (
            <StyledOption
              key={ind}
              style={{
                borderBottomLeftRadius: ind === keypairs.length - 1 ? 5 : 0,
                borderBottomRightRadius: ind === keypairs.length - 1 ? 5 : 0,
                borderBottom:
                  ind === keypairs.length - 1 ? '1px solid #eeeeee' : 'none',
              }}
              onMouseDown={() => handleOptionClick(Object.keys(doc)[0])} // Set input value on option click
            >
              <IconContainer>
                <img src={CodeIcon} style={{ height: 16, width: 16 }} alt="" />
              </IconContainer>

              <div>
                <p style={{ fontWeight: '550', fontSize: 14 }}>
                  {Object.keys(doc)[0]}
                </p>
                <p style={{}}>
                  {typeof Object.values(doc)[0] === 'boolean'
                    ? Object.values(doc)[0]
                      ? 'true'
                      : 'false'
                    : typeof Object.values(doc)[0] === 'number'
                      ? Object.values(doc)[0]
                      : Object.values(doc)[0]?.substring(0, 100)}
                  {Object.values(doc)[0]?.length >= 100 && '...'}
                </p>
              </div>
            </StyledOption>
          ))}
        </DropdownContent>
      )}
    </ToggleContainer>
  )
}

const StyledOption = styled.div`
  border-top: 1px solid #eeeeee;
  border-left: 1px solid #eeeeee;
  border-right: 1px solid #eeeeee;
  cursor: pointer;
  padding: 10px 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 25px;
  border-top-left-radius: ${({ ind }) => (ind === 0 ? 5 : 0)}px;
  border-top-right-radius: ${({ ind }) => (ind === 0 ? 5 : 0)}px;
  &:hover {
    background-color: #f0f0f0; /* Change this to your desired hover background color */
  }
`

const InputContainer = styled.div``

const IconContainer = styled.div`
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  padding: 5px;
`

const DropdownContent = styled.div`
  width: 100%;
  background-color: white;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  max-height: 200px;
  overflow-y: auto;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  margin-top: 5px; /* Add a little space between the input and dropdown */
  margin-bottom: 10px;
`

const ToggleContainer = styled.div`
  padding: 15px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  margin-bottom: 10px;
`

const Label = styled.p`
  margin: 0px;
  padding: 0px;
  color: #000;
  font-size: 13px;
  font-weight: 500;
`

const SwitchBody = styled.div`
  transition: background-color 0.1s ease;
  background-color: ${({ on }) => (on ? '#433DFF' : '#f0f0ed')};
  position: relative;
  height: 30px;
  width: 50px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  padding: 3px;
  cursor: pointer;
`

const Flicker = styled.p`
  border-radius: 100px;
  width: 25px;
  height: 25px;
  background-color: white;
  position: absolute;
  transition: transform 0.15s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  right: ${({ on }) => (on ? '3px' : 'auto')};
`
