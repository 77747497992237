import { useNavigate, useSearchParams } from 'react-router-dom'
import styled from 'styled-components'

export default function MenuItem({ page, children, hover, setHover, className }) {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const currentPage = searchParams.get('page')

  // when we switch the page query, it changes the page component.
  function handleClick(page, e) {
    e.stopPropagation()
    if (page.href) {
      setHover(false)
    }
    else {
      navigate(`${page.query}`)
      setHover(false)
    }
  }

  // Conditional render link menu items based on presence of href key.
  const Link = page.href ? 'a' : 'div'
  const linkProps = page.href ? { href: page.href, target: 'blank' } : {}
  return (
    <Link {...linkProps} className={className}>
      <Item
        $hover={hover}
        onClick={e => handleClick(page, e)}
        $currentPage={currentPage}
        $page={page}
      >
        {children}
      </Item>
    </Link>
  )
}

const Item = styled.div`
  display: flex;
  align-items: start;
  flex-direction: row;
  column-gap: 5px;
  width: ${({ $hover }) => ($hover ? '238px' : 'auto')};
  height: 40px;
  text-align: left;
  border-radius: 5px;
  transition:
    background-color 200ms ease-in-out,
    transform 100ms ease-in-out;
  align-items: center;
  &:hover {
    background-color: #fbfbfa;
    cursor: pointer !important;
  }

  ${({ $currentPage, $page }) =>
    $currentPage === $page.query
    && `
    background-color: #FBFBFA;
    color: #433DFF;
  `}

  ${({ $currentPage, $page }) =>
    $currentPage !== $page.query
    && `
    color: #686767;
    &:hover {
      border-color: red;
      color: black;
    }
  `}
`
