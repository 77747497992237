import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import { Input } from '@/components/ui/input'

import { EyeIcon, EyeOffIcon } from 'lucide-react'
import { useState } from 'react'
import { toast } from 'sonner'
import { $fetch } from 'utils/fetch'

export function ShareReportDialog({ open, onOpenChange, reportData }) {
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [expiration, setExpiration] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [sharedLink, setSharedLink] = useState(null)
  const [urlSlug, setUrlSlug] = useState('')
  const baseUrl = `https://app.bland.ai/analytics/report/`

  const validateSlug = (slug) => {
    // Only allow alphanumeric characters, hyphens, and underscores
    const slugRegex = /^[\w-]*$/
    return slugRegex.test(slug)
  }

  const getPreviewUrl = () => {
    if (!urlSlug)
      return `${baseUrl}<generated-id>`
    return `${baseUrl}${urlSlug}`
  }

  const handleShare = async () => {
    if (!password || !confirmPassword) {
      toast.error('Please fill in both password fields')
      return
    }

    if (password !== confirmPassword) {
      toast.error('Passwords do not match')
      return
    }

    if (!expiration) {
      toast.error('Please select an expiration period')
      return
    }

    if (urlSlug && !validateSlug(urlSlug)) {
      toast.error(
        'URL slug can only contain letters, numbers, hyphens, and underscores',
      )
      return
    }

    try {
      const response = await $fetch('/v1/analytics/report/share', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          report_data: reportData,
          password,
          url_slug: urlSlug || undefined,
          expires_at:
            expiration === 'never'
              ? null
              : new Date(
                  Date.now() + Number.parseInt(expiration) * 24 * 60 * 60 * 1000,
                ).toISOString(),
        }),
      })

      const link = response.data.report_link
      setSharedLink(link)
      await navigator.clipboard.writeText(link)
      toast.success('Share link copied to clipboard!')
    }
    catch (error) {
      if (error.data.errors[0].error === 'URL_SLUG_TAKEN') {
        toast.error('URL slug already taken. Please try a different one.')
      }
      else {
        toast.error('Failed to generate share link')
        console.error('Error sharing report:', error)
      }
    }
  }

  const handleClose = () => {
    setSharedLink(null)
    setPassword('')
    setConfirmPassword('')
    setExpiration('')
    setUrlSlug('')
    onOpenChange(false)
  }

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[600px]">
        {sharedLink
          ? (
              <div className="py-5 space-y-4">
                <div className="text-center space-y-1.5">
                  <div className="text-green-600 mb-2.5">
                    <svg
                      className="mx-auto h-8 w-8"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  </div>
                  <h3 className="text-base font-semibold text-gray-900">
                    Your report is ready!
                  </h3>
                  <p className="text-xs text-gray-500">
                    The link has been copied to your clipboard
                  </p>
                </div>

                <div className="p-2.5 bg-gray-50 rounded-md">
                  <p className="text-xs text-gray-600 font-mono break-all text-center">
                    {sharedLink}
                  </p>
                </div>

                <div className="flex justify-center space-x-2">
                  <Button variant="outline" onClick={handleClose}>
                    Close
                  </Button>
                  <Button
                    onClick={() => window.open(sharedLink, '_blank')}
                    className="px-5"
                  >
                    Open Report →
                  </Button>
                </div>
              </div>
            )
          : (
              <>
                <DialogHeader>
                  <DialogTitle className="text-base font-semibold">
                    Generate Report Link
                  </DialogTitle>
                  <DialogDescription>
                    Create a secure, time-limited link to share your report.
                  </DialogDescription>
                </DialogHeader>
                <div className="space-y-4 py-2.5">
                  <div className="space-y-2.5">
                    <div className="space-y-1.5">
                      <label className="text-xs font-medium text-gray-700">
                        Custom URL Slug (Optional)
                      </label>
                      <Input
                        type="text"
                        placeholder="e.g. amex / q4-2023"
                        value={urlSlug}
                        onChange={(e) => {
                          const newValue = e.target.value.trim()
                          if (validateSlug(newValue) || newValue === '') {
                            setUrlSlug(newValue)
                          }
                        }}
                        maxLength={50}
                        className="h-8"
                      />
                      <p className="text-2xs text-gray-500">
                        Only letters, numbers, hyphens, and underscores allowed. Max
                        50 characters.
                      </p>
                      <div className="mt-1.5 p-2 bg-gray-50 rounded-md">
                        <p className="text-xs text-gray-600 font-mono break-all">
                          {getPreviewUrl()}
                        </p>
                      </div>
                    </div>
                    <div className="space-y-1.5">
                      <label className="text-xs font-medium text-gray-700">
                        Password Protection
                      </label>
                      <div className="relative">
                        <Input
                          type={showPassword ? 'text' : 'password'}
                          placeholder="Enter password"
                          value={password}
                          onChange={e => setPassword(e.target.value)}
                          className="pr-6 h-8"
                        />
                        <button
                          type="button"
                          onClick={() => setShowPassword(!showPassword)}
                          className="absolute right-2 top-1/2 -translate-y-1/2 text-gray-500 hover:text-gray-700"
                        >
                          {showPassword
                            ? (
                                <EyeOffIcon className="h-2.5 w-2.5" />
                              )
                            : (
                                <EyeIcon className="h-2.5 w-2.5" />
                              )}
                        </button>
                      </div>
                    </div>
                    <div className="space-y-1.5">
                      <label className="text-xs font-medium text-gray-700">
                        Confirm Password
                      </label>
                      <div className="relative">
                        <Input
                          type={showConfirmPassword ? 'text' : 'password'}
                          placeholder="Confirm password"
                          value={confirmPassword}
                          onChange={e => setConfirmPassword(e.target.value)}
                          className="pr-6 h-8"
                        />
                        <button
                          type="button"
                          onClick={() =>
                            setShowConfirmPassword(!showConfirmPassword)}
                          className="absolute right-2 top-1/2 -translate-y-1/2 text-gray-500 hover:text-gray-700"
                        >
                          {showConfirmPassword
                            ? (
                                <EyeOffIcon className="h-2.5 w-2.5" />
                              )
                            : (
                                <EyeIcon className="h-2.5 w-2.5" />
                              )}
                        </button>
                      </div>
                    </div>
                    <div className="space-y-1.5">
                      <label className="text-xs font-medium text-gray-700">
                        Link Expiration
                      </label>
                      <div className="grid grid-cols-4 gap-2">
                        {[
                          { value: '3', label: '3 Days' },
                          { value: '7', label: '7 Days' },
                          { value: '30', label: '30 Days' },
                          { value: 'never', label: '∞ Never' },
                        ].map(option => (
                          <button
                            key={option.value}
                            type="button"
                            onClick={() => setExpiration(option.value)}
                            className={`p-2 text-center rounded-md border ${
                              expiration === option.value
                                ? 'border-primary bg-primary/10 text-black'
                                : 'border-gray-200 hover:border-gray-300'
                            }`}
                          >
                            {option.label}
                          </button>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <DialogFooter>
                  <Button variant="outline" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button onClick={handleShare}>Generate Report Link</Button>
                </DialogFooter>
              </>
            )}
      </DialogContent>
    </Dialog>
  )
}
