import { Button as UiButton } from '@/components/ui/button'
import { cn } from '@/lib/utils'
import { cva } from 'class-variance-authority' // New utility import
import React from 'react'
import { ThreeDots } from 'react-loader-spinner'

// Define the button variant styles using `cva`
const buttonVariants = cva(
  'inline-flex items-center justify-center font-medium disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        default: `bg-[#534DFC] text-white hover:bg-[#6A63FD] hover:brightness-110 hover:shadow-md transition-all duration-200`, // Vibrant primary blue with a subtle hover.
        defaultGrey: `bg-[#343a40] text-white hover:bg-[#5A5A5A] hover:brightness-110 hover:shadow-md transition-all duration-200`, // Greyscale version of default
        destructive:
          'bg-[#DDDDFA] text-[#1F2B4A] hover:bg-[#9491FB] hover:brightness-110 hover:shadow-md transition-all duration-200', // Softer with a contrasting hover.
        destructiveGrey:
          'bg-[#E0E0E0] text-[#333333] hover:bg-[#C0C0C0] hover:brightness-110 hover:shadow-md transition-all duration-200', // Greyscale version of destructive
        surface: `border-[1.5px] border-[#9491FB] bg-[#EFEFFE] text-[#534DFC] hover:bg-[#DDDDFA] hover:brightness-110 hover:shadow-md hover:border-[#534DFC] transition-all duration-200`, // Clean outline with visibly brighter hover effect.
        surfaceGrey: `border-[1.5px] border-[#adb5bd] bg-[#F5F5F5] text-[#343a40] hover:bg-[#E0E0E0] hover:brightness-110 hover:shadow-md hover:border-[#495057] transition-all duration-200`, // Greyscale version of surface
        outline: `bg-[#9491FB] text-white hover:bg-[#9694E5] hover:brightness-110 hover:shadow-md transition-all duration-200`, // Slightly muted purple for secondary actions.
        outlineGrey: `bg-[#A0A0A0] text-white hover:bg-[#B0B0B0] hover:brightness-110 hover:shadow-md transition-all duration-200`, // Greyscale version of outline
        ghost:
          'bg-transparent text-[#534DFC] hover:bg-[#DDDDFA] hover:text-[#534DFC]', // Transparent with color hint.
        ghostGrey:
          'bg-transparent text-[#343a40] hover:bg-[#f3f3f3] hover:text-[#333333]', // Greyscale version of ghost
        link: 'text-[#534DFC] underline-offset-4 hover:underline', // Textual link with the primary blue.
        linkGrey: 'text-[#343a40] underline-offset-4 hover:underline', // Greyscale version of link
        soft: `bg-[#EFEFFE] text-[#534DFC] hover:bg-[#DDDDFA]`, // Soft background with a subtle hover effect.
        softGrey: `bg-[#f2f2f2] text-[#343a40] hover:bg-[#e5e5e5]`, // Greyscale version of soft
      },
      size: {
        sm: 'h-7 px-4 text-[13px]',
        md: 'h-[36px] px-6 text-[16.5px]',
        lg: 'h-[42px] px-7 text-[18.5px]',
        icon: 'h-6 w-6',
      },
      radius: {
        default: 'rounded-[6px]',
        rounded: 'rounded-[8px]',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'md',
      radius: 'default',
    },
  },
)

function getIconColor(variant) {
  switch (variant) {
    case 'default':
      return '#EBEBE5'
    case 'destructive':
      return '#1F2B4A'
    case 'surface':
      return '#534DFC'
    case 'soft':
      return '#534DFC'
    case 'ghost':
      return '#534DFC'
    default:
      return '#534DFC'
  }
}

export default function Button({
  children,
  variant = 'default',
  size = 'md',
  radius = 'default',
  onClick,
  isLoading = false,
  startIcon,
  endIcon,
  className = '',
  width,
  ...props
}) {
  const getIconSize = (size) => {
    switch (size) {
      case 'sm':
        return 12
      case 'md':
        return 14
      case 'lg':
        return 16
      case 'icon':
        return 18
      default:
        return 16
    }
  }

  const iconSize = getIconSize(size)

  return (
    <UiButton
      type="button"
      className={cn(buttonVariants({ variant, size, radius }), className)}
      disabled={isLoading || props.disabled}
      onClick={onClick}
      style={{ width }}
      {...props}
    >
      {isLoading
        ? (
            <ThreeDots
              height={iconSize}
              width={iconSize}
              radius="9"
              color={getIconColor(variant)}
              ariaLabel="three-dots-loading"
            />
          )
        : (
            <>
              {startIcon && (
                <span className="mr-2 inline-flex shrink-0 items-center justify-center">
                  {React.cloneElement(startIcon, {
                    size: iconSize,
                    className: 'stroke-[1.5px]',
                  })}
                </span>
              )}
              <span>{children}</span>
              {endIcon && (
                <span className="ml-2 inline-flex shrink-0 items-center justify-center">
                  {React.cloneElement(endIcon, {
                    size: iconSize,
                    className: 'stroke-[1.5px]',
                  })}
                </span>
              )}
            </>
          )}
    </UiButton>
  )
}
