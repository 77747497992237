import orbGif from 'assets/gifs/talking-orb.gif'
import { motion, useAnimation } from 'framer-motion'
import { useEffect, useState } from 'react'

export default function PulsingOrb({
  isAiTalking = false,
  pulseIntensity = 1.15,
  idleScale = 1.0,
}) {
  // Set up animation control
  const controls = useAnimation()

  // Keep track of pulse cycle for variation
  const [pulseCount, setPulseCount] = useState(0)

  // Function to create irregular pulsing with natural variation
  useEffect(() => {
    let animationInterval

    const animatePulse = async () => {
      if (isAiTalking) {
        // Generate slightly random values for more natural motion
        const randomScale = pulseIntensity * (0.97 + Math.random() * 0.06)
        const randomDuration = 0.8 + Math.random() * 0.4

        // Sequence of animations to create the pulse
        await controls.start({
          scale: randomScale,
          transition: {
            duration: randomDuration * 0.4,
            type: 'spring',
            stiffness: 300,
            damping: 15,
          },
        })

        await controls.start({
          scale: idleScale + ((randomScale - idleScale) * 0.6),
          transition: {
            duration: randomDuration * 0.6,
            type: 'spring',
            stiffness: 200,
            damping: 12,
          },
        })

        setPulseCount(prev => prev + 1)
      }
    }

    if (isAiTalking) {
      // Start initial pulse
      animatePulse()

      // Set up interval for continuous pulsing
      animationInterval = setInterval(() => {
        animatePulse()
      }, 1200) // Base interval, gets varied by random durations
    }
    else {
      // When AI stops talking, smoothly return to idle size
      controls.start({
        scale: idleScale,
        transition: {
          duration: 0.7,
          type: 'spring',
          stiffness: 150,
          damping: 15,
        },
      })
    }

    return () => {
      if (animationInterval)
        clearInterval(animationInterval)
    }
  }, [isAiTalking, controls, pulseIntensity, idleScale, pulseCount])

  return (

    <motion.div
      className="relative rounded-full overflow-hidden"
      initial={{ scale: idleScale }}
      animate={controls}
    >
      <img
        src={orbGif}
        alt="AI Orb"
        className="size-52 object-cover"
      />
    </motion.div>

  )
}
