import { Input, Tab, Tabs } from '@heroui/react'
import PathwaySelect from 'components/Hero/PathwaySelect'
import PathwayVersion from 'components/Hero/PathwayVersion'
import PromptInput from 'components/Hero/PromptInput'
import { Network, Text } from 'lucide-react'
import { useEffect, useState } from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'

export default function PromptOrPathway({ isInbound = false, isSMS = false }) {
  const { setValue } = useFormContext()
  const [selectedKey, setSelectedKey] = useState('prompt')

  const pathwayIdField = isSMS ? 'sms_config.pathway_id' : 'pathway_id'

  const pathwayId = useWatch({
    name: pathwayIdField,
  })

  useEffect(() => {
    if (pathwayId) {
      setSelectedKey('pathway')
    }
  }, [pathwayId])

  return (
    <div className="flex flex-col gap-2 w-full">
      <Tabs
        fullWidth
        size="sm"
        variant="solid"
        radius="none"
        keepContentMounted
        defaultSelectedKey={selectedKey}
        selectedKey={selectedKey}
        onSelectionChange={(key) => {
          if (key === 'pathway') {
            if (isInbound) {
              setValue(isSMS ? 'sms_config.first_sentence' : 'first_sentence', null)
            }
            setValue(isSMS ? 'sms_config.objective' : 'task', null)
            setValue(isSMS ? 'sms_config.custom_tools' : 'task', null)
            setValue('tools', [])
            setValue(isSMS ? 'sms_config.transfer_phone_number' : 'transfer_phone_number', null)
            setValue(isSMS ? 'sms_config.transfer_list' : 'transfer_list', {})
            setValue(isSMS ? 'sms_config.pathway_ids' : 'pathway_ids', [])
            setSelectedKey('pathway')
          }
          else {
            setValue(isSMS ? 'sms_config.pathway_id' : 'pathway_id', null)
            setValue(isSMS ? 'sms_config.pathway_version' : 'pathway_version', null)
            setSelectedKey('prompt')
          }
        }}
      >
        <Tab
          key="prompt"
          title={(
            <div className="flex items-center space-x-2">
              <Text size={16} />
              <span>Prompt</span>
            </div>
          )}
        >
          <div className="flex flex-col items-center gap-5 w-full">
            <Controller
              name={isSMS ? 'sms_config.prompt' : 'task'}
              render={({ field }) => (
                <PromptInput {...field} />
              )}
            />

            <Controller
              name={isSMS ? 'sms_config.first_sentence' : 'first_sentence'}
              render={({ field }) => (
                <Input
                  label="First Sentence"
                  variant="bordered"
                  radius="sm"
                  labelPlacement="outside"
                  placeholder={`Enter a first sentence for the ${isSMS ? 'SMS' : 'call'}`}
                  {...field}
                />
              )}
            />

          </div>
        </Tab>
        <Tab
          key="pathway"
          title={(
            <div className="flex items-center space-x-2">
              <Network size={16} />
              <span>Pathway</span>
            </div>
          )}
          onSelectionChange={() => {
            setValue(isSMS ? 'sms_config.pathway_id' : 'pathway_id', null)
          }}
        >
          <div className="flex flex-col items-center gap-5 w-full">
            <Controller
              name={pathwayIdField}
              render={({ field, fieldState: { invalid, error } }) => (
                <PathwaySelect
                  isInvalid={invalid}
                  errorMessage={error?.message}
                  {...field}
                />
              )}
            />
            {pathwayId && (
              <Controller
                name={isSMS ? 'sms_config.pathway_version' : 'pathway_version'}
                render={({ field }) => (
                  <PathwayVersion pathwayId={pathwayId} {...field} />
                )}
              />
            )}
          </div>
        </Tab>
      </Tabs>
    </div>
  )
}
