import {
  addToast,
  Button,
  Progress,
  Tab,
  Tabs,
} from '@heroui/react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import ActionBar from 'components/core/ActionBar'
import { PageTitle } from 'components/core/PageTitle'
import { PageWrapper } from 'components/core/PageWrapper'
import GradientLoadingAnimation from 'components/Reusables/GradientLoadingAnimation'
import { MessageSquare, Plus, Settings } from 'lucide-react'
import { useCallback, useMemo, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { $fetch } from 'utils/fetch'
import ResizableDrawer from '../CallLogs/ResizeableDrawer'
import ConversationDetailsSlideOut from './components/SMSSlideout'
import SMSNumbersTable from './components/SMSNumbersTable'
import ConversationsTable from './components/ConversationsTable'
import { useSMSConversations } from './hooks/useSMSConversations'
import { useSMSNumbers } from './hooks/useSMSNumbers'
import { useConversationDetails } from './hooks/useConversationDetails'
import SMSTestButton from './SMSTestButton'

function SMSDashboard() {
  const [searchParams, setSearchParams] = useSearchParams()
  const { conversationId } = useParams()
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  // Parse values from URL search params
  const currPage = Number.parseInt(searchParams.get('currPage') || '1', 10)
  const pageSize = Number.parseInt(searchParams.get('pageSize') || '25', 10)
  const sortBy = searchParams.get('sortBy') || 'created_at'
  const sortDir = searchParams.get('sortDir') || 'desc'
  const activeTab = searchParams.get('tab') || 'conversations'

  const getFilters = useCallback(() => {
    const filters = []
    for (const [key, value] of searchParams.entries()) {
      if (!key.startsWith('filter:'))
        continue
      const [, field, operator] = key.split(':')
      if (field && operator) {
        filters.push({
          field,
          operator,
          value,
        })
      }
    }
    return filters
  }, [searchParams])

  const filters = useMemo(() => getFilters(), [getFilters])

  // Use custom hooks for data fetching
  const {
    data: conversationsData,
    isLoading: isLoadingConversations,
    isFetching: isFetchingConversations,
  } = useSMSConversations({
    currPage,
    pageSize,
    filters,
    sortBy,
    sortDir,
    enabled: activeTab === 'conversations',
  })

  const {
    data: conversationDetails,
    isLoading: isLoadingConversationDetails,
  } = useConversationDetails({
    conversationId,
    enabled: !!conversationId,
  })

  const {
    data: numbersData,
    isLoading: isLoadingNumbers,
    isFetching: isFetchingNumbers,
  } = useSMSNumbers({
    enabled: true,
  })

  const handleTestSMSSuccess = (response) => {
    queryClient.invalidateQueries({ queryKey: ['smsConversations'] });
  }

  // Add delete conversation mutation
  const deleteConversation = useMutation({
    mutationFn: async (conversationId) => {
      return await $fetch(`/v1/sms/conversations/${conversationId}`, {
        method: 'DELETE',
      })
    },
    onSuccess: (_, deletedId) => {
      addToast({
        title: 'Conversation deleted successfully',
        color: 'success',
      })
      // Close the conversation details if it's currently open
      if (conversationId === deletedId) {
        navigate('/dashboard/sms?tab=conversations')
      }
      // Invalidate queries to refresh the list
      queryClient.invalidateQueries({ queryKey: ['smsConversations'] })
    },
    onError: (error) => {
      addToast({
        title: `Failed to delete conversation: ${error.message || 'Unknown error'}`,
        color: 'danger',
      })
    },
  })

  const handleDeleteConversation = (id, event) => {
    event.stopPropagation()
    deleteConversation.mutate(id)
  }

  const handlePaginationChange = (updater) => {
    const newPaginationVal = updater({
      pageIndex: currPage - 1,
      pageSize,
    })
    searchParams.set('currPage', newPaginationVal.pageIndex + 1)
    searchParams.set('pageSize', newPaginationVal.pageSize)
    setSearchParams(searchParams)
  }

  const handleSortChange = (updater) => {
    const newSorting = updater([{ id: sortBy, desc: sortDir === 'desc' }])
    if (newSorting.length > 0) {
      const { id, desc } = newSorting[0]
      setSearchParams({
        ...searchParams,
        sortBy: id,
        sortDir: desc ? 'desc' : 'asc',
      })
    }
  }

  const handlePageSizeChange = (newSize) => {
    searchParams.set('pageSize', newSize)
    setSearchParams(searchParams)
  }

  const handleTabChange = (key) => {
    searchParams.set('tab', key)
    setSearchParams(searchParams)
  }

  const toggleRowClick = (newId) => {
    const id = newId === conversationId ? null : newId

    // Make a clone of current search params
    const newSearchParams = new URLSearchParams(searchParams)

    // Always ensure tab is set to conversations when viewing a conversation
    if (id) {
      newSearchParams.set('tab', 'conversations')
    }

    const queryString = newSearchParams.toString()
    navigate(`/dashboard/sms/${id || ''}${queryString ? `?${queryString}` : ''}`)
  }

  return (
    <PageWrapper padding="54px 0px 0px 54px">
      <ActionBar shiftRight top>
        <PageTitle className="justify-self-start">SMS Dashboard</PageTitle>
        <Tabs
          size="sm"
          radius="none"
          selectedKey={activeTab}
          onSelectionChange={handleTabChange}
          variant="underlined"
          className="ml-4"
        >
          <Tab
            id="conversations"
            key="conversations"
            title={(
              <div className="flex items-center space-x-2">
                <MessageSquare size={12} />
                <span>Conversations</span>
              </div>
            )}
          />
          <Tab
            id="numbers"
            key="numbers"
            title={(
              <div className="flex items-center space-x-2">
                <Settings size={12} />
                <span>SMS Numbers</span>
              </div>
            )}
          />
        </Tabs>
        
        {/* {activeTab === 'conversations' && <SMSFilterBar activeFilters={filters} />} */}
        
        <div className="flex-grow"></div>
        
        {activeTab === 'conversations' && (
          <SMSTestButton 
            selectedNumber={numbersData?.numbers?.[0]?.phone_number}
            availableNumbers={numbersData?.numbers || []}
            onSuccess={handleTestSMSSuccess}
            highlight={false}
          />
        )}
        
        {activeTab === 'numbers' && (
            <Button
              variant="bordered"
              color="secondary"
              size="sm"
              radius="none"
              startContent={<Plus size={16} />}
              onPress={() => navigate('/dashboard/phone-numbers')}
              className="shadow-sm hover:shadow transition-shadow"
            >
              New Phone Number
            </Button>
        )}
      </ActionBar>

      {activeTab === 'conversations'
        ? (isLoadingConversations
            ? (
                <GradientLoadingAnimation message="Loading SMS Conversations" variant="roseGarden" rounded="full" gray />
              )
            : (
                <ConversationsTable
                  data={conversationsData?.conversations || []}
                  pagination={conversationsData?.pagination}
                  isLoading={isLoadingConversations}
                  isFetching={isFetchingConversations}
                  handlePaginationChange={handlePaginationChange}
                  handleSortChange={handleSortChange}
                  sortBy={sortBy}
                  sortDir={sortDir}
                  pageSize={pageSize}
                  onPageSizeChange={handlePageSizeChange}
                  toggleRowClick={toggleRowClick}
                  selectedId={conversationId}
                  handleDeleteConversation={handleDeleteConversation}
                />
              )
          )
        : (isLoadingNumbers
            ? (
                <GradientLoadingAnimation message="Loading SMS Numbers" variant="roseGarden" rounded="full" gray />
              )
            : (
                <SMSNumbersTable
                  data={numbersData?.numbers || []}
                  _isLoading={isLoadingNumbers}
                  isFetching={isFetchingNumbers}
                />
              )
          )}

      <ActionBar shiftRight bottom>
        {(isFetchingConversations || isFetchingNumbers) && (
          <Progress
            isIndeterminate
            aria-label="Loading..."
            className="absolute top-0 left-0"
            classNames={{ base: 'h-0.5', indicator: '!duration-1000' }}
            size="sm"
          />
        )}
      </ActionBar>

      {!!conversationId && (
        <ResizableDrawer>
          <ConversationDetailsSlideOut
            conversationData={conversationDetails || { id: conversationId }}
            isLoading={isLoadingConversationDetails}
            onClose={() => toggleRowClick()}
          />
        </ResizableDrawer>
      )}
    </PageWrapper>
  )
}

export default SMSDashboard 