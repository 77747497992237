import { Loading } from 'components/core/Loading'
import dagre from 'dagre'
import { AnimatePresence, motion } from 'framer-motion'
import { ChevronRight } from 'lucide-react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import ReactFlow, {
  Background,
  Controls,
  Handle,
  MiniMap,
  Position,
  useEdgesState,
  useNodesState,
} from 'reactflow'
import { $fetch } from 'utils/fetch'
import ReplayLogs from './Components/ReplayComponent'
import 'reactflow/dist/style.css'

function getColor(value, min, max) {
  const normalizedValue = (value - min) / (max - min)
  const colorStops = [
    { point: 0, color: [255, 0, 0] },
    { point: 0.1, color: [255, 69, 0] },
    { point: 0.2, color: [255, 140, 0] },
    { point: 0.3, color: [255, 165, 0] },
    { point: 0.4, color: [255, 215, 0] },
    { point: 0.5, color: [255, 255, 0] },
    { point: 0.6, color: [204, 255, 0] },
    { point: 0.7, color: [173, 255, 47] },
    { point: 0.8, color: [127, 255, 0] },
    { point: 0.9, color: [0, 255, 127] },
    { point: 1, color: [0, 128, 0] },
  ]
  let lowerStop = colorStops[0]
  let upperStop = colorStops[colorStops.length - 1]

  for (let i = 1; i < colorStops.length; i++) {
    if (normalizedValue <= colorStops[i].point) {
      upperStop = colorStops[i]
      lowerStop = colorStops[i - 1]
      break
    }
  }

  const rangeBetweenStops = upperStop.point - lowerStop.point
  const valueFromLowerStop = normalizedValue - lowerStop.point
  const ratio = valueFromLowerStop / rangeBetweenStops

  const r = Math.round(
    lowerStop.color[0] + ratio * (upperStop.color[0] - lowerStop.color[0]),
  )
  const g = Math.round(
    lowerStop.color[1] + ratio * (upperStop.color[1] - lowerStop.color[1]),
  )
  const b = Math.round(
    lowerStop.color[2] + ratio * (upperStop.color[2] - upperStop.color[2]),
  )

  return `rgb(${r}, ${g}, ${b})`
}

function lightenColor(rgb, percent) {
  const r = Math.min(
    255,
    Math.round(rgb[0] + ((255 - rgb[0]) * percent) / 100),
  )
  const g = Math.min(
    255,
    Math.round(rgb[1] + ((255 - rgb[1]) * percent) / 100),
  )
  const b = Math.min(
    255,
    Math.round(rgb[2] + ((255 - rgb[2]) * percent) / 100),
  )
  return `rgb(${r}, ${g}, ${b})`
}

// Custom Node component
function CustomNode({ data }) {
  const color = getColor(data.frequency, data.minFrequency, data.maxFrequency)
  if (!color)
    return null
  const [r, g, b] = color.match(/\d+/g).map(Number)
  const borderColor = lightenColor([r, g, b], 40)

  return (
    <div
      className={`p-1.5 rounded-md shadow-md border border-gray-200 ${data.isHighlighted ? 'ring-2 ring-blue-500' : ''}`}
      style={{ background: color }}
      onClick={() => data.onClick(data.id)}
    >
      <div className="font-semibold text-white truncate">{data.label}</div>
      <div className="text-2xs text-white">
        Freq:
        {data.frequency}
      </div>
      <Handle
        type="target"
        position={Position.Top}
        className="w-1.5 h-1.5 bg-white"
      />
      <Handle
        type="source"
        position={Position.Bottom}
        className="w-1.5 h-1.5 bg-white"
      />
    </div>
  )
}

function CustomEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  style = {},
  data,
}) {
  const edgePath = `M${sourceX},${sourceY} C${sourceX},${(sourceY + targetY) / 2} ${targetX},${(sourceY + targetY) / 2} ${targetX},${targetY}`
  return (
    <>
      <path
        id={id}
        style={{ ...style, strokeWidth: data.thickness }}
        className={`react-flow__edge-path ${data.isHighlighted ? 'stroke-blue-500' : 'stroke-gray-300'}`}
        d={edgePath}
      />
      {data?.label && (
        <text>
          <textPath
            href={`#${id}`}
            className="text-2xs fill-gray-600"
            startOffset="50%"
            textAnchor="middle"
          >
            {data.label}
          </textPath>
        </text>
      )}
    </>
  )
}

const nodeTypes = { custom: CustomNode }
const edgeTypes = { custom: CustomEdge }

function StatDisplay({ title, value, change }) {
  return (
    <div className="bg-white p-2.5 rounded-md shadow-sm border border-gray-200">
      <h3 className="text-xs font-medium text-gray-500 mb-0.5">{title}</h3>
      <div className="flex items-baseline">
        <span className="text-lg font-semibold text-gray-900">{value}</span>
        {change && (
          <span
            className={`ml-1.5 text-xs font-medium ${change >= 0 ? 'text-emerald-600' : 'text-rose-600'}`}
          >
            {change >= 0 ? '↑' : '↓'}
            {' '}
            {Math.abs(change)}
            %
          </span>
        )}
      </div>
    </div>
  )
}

function LoadingSpinner() {
  return (
    <div className="flex justify-center items-center h-full">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="flex flex-col items-center justify-center h-full text-gray-400"
      >
        <svg
          className="animate-spin h-10 w-10 mb-2.5"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          >
          </circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          >
          </path>
        </svg>
      </motion.div>
    </div>
  )
}

function Drawer({ isOpen, onClose, children }) {
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ x: '100%' }}
          animate={{ x: 0 }}
          exit={{ x: '100%' }}
          transition={{ type: 'spring', stiffness: 300, damping: 30 }}
          className="fixed right-0 top-0 h-screen w-1/3 bg-white shadow-lg z-50 overflow-y-auto border-l border-gray-200 z-[999999]"
        >
          {/* <button onClick={onClose} className="absolute top-0 right-6 text-gray-500 hover:text-gray-700 z-[99999]">
                    <X size={24} />
                </button> */}
          <div className="p-4">{children}</div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

function PathwayHeatmap() {
  const [pathways, setPathways] = useState([])
  const [selectedPathway, setSelectedPathway] = useState(null)
  const [heatmapData, setHeatmapData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [warning, setWarning] = useState(null)
  const [nodes, setNodes, onNodesChange] = useNodesState([])
  const [edges, setEdges, onEdgesChange] = useEdgesState([])
  const [selectedCallId, setSelectedCallId] = useState(null)
  const [replayIndex, setReplayIndex] = useState(0)
  const [isPlaying, setIsPlaying] = useState(false)
  const [replaySpeed, setReplaySpeed] = useState(1000)
  const [callLogs, setCallLogs] = useState(null)
  const [selectedNodeId, setSelectedNodeId] = useState(null)
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const handleBack = useCallback(() => {
    setSelectedPathway(null)
    setHeatmapData(null)
    setError(null)
    setWarning(null)
  }, [])

  const handleSpeedChange = useCallback((e) => {
    setReplaySpeed(Number(e.target.value))
  }, [])

  const fetchPathways = useCallback(async () => {
    setLoading(true)
    setError(null)
    try {
      const response = await $fetch('/convo_pathway/get')

      const extractAllPathways = (items) => {
        let pathways = []
        for (const item of items) {
          if (item.type === 'pathway') {
            pathways.push(item)
          }
          else if (item.type === 'folder' && item.children) {
            pathways = pathways.concat(extractAllPathways(item.children))
          }
        }
        return pathways
      }
      const extractedPathways = extractAllPathways(response.data.data)
      setPathways(extractedPathways)
    }
    catch (err) {
      setError(err.message)
    }
    finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    fetchPathways()
  }, [fetchPathways])

  const fetchCallLogs = useCallback(async (callId) => {
    setLoading(true)
    setError(null)
    try {
      const response = await $fetch(
        `/analytics/pathways/call_replay?callId=${callId}`,
      )
      const data = await response.data
      setCallLogs(data.data)
    }
    catch (err) {
      setError(err.message)
    }
    finally {
      setLoading(false)
    }
  }, [])

  const highlightPath = useCallback(
    (callId) => {
      if (!heatmapData || !heatmapData.callPaths)
        return

      const path = heatmapData.callPaths[callId].nodes

      if (!path || !Array.isArray(path))
        return

      const newNodes = nodes.map(node => ({
        ...node,
        data: {
          ...node.data,
          isHighlighted: path?.includes(node.id),
        },
      }))

      const newEdges = edges.map(edge => ({
        ...edge,
        data: {
          ...edge.data,
          isHighlighted:
            path?.includes(edge.source) && path?.includes(edge.target),
        },
      }))

      setNodes(newNodes)
      setEdges(newEdges)
      setSelectedCallId(callId)
      setReplayIndex(0)
      setIsPlaying(false)
      fetchCallLogs(callId)
      setIsDrawerOpen(true)
    },
    [heatmapData, nodes, edges, setNodes, setEdges, fetchCallLogs],
  )

  const fetchHeatmap = useCallback(async (pathwayId) => {
    setLoading(true)
    setError(null)
    try {
      const response = await $fetch(
        `/analytics/pathways_heatmap?pathwayId=${pathwayId}`,
        { ignoreResponseError: true },
      )

      if (response.errors) {
        setError(response.errors[0].message)
        setLoading(false)
        return
      }

      console.log(response.data)

      setHeatmapData(response?.data?.data)
      createNodesAndEdges(response.data?.data)
    }
    catch (err) {
      console.error(err)
      setError(err.message)
    }
    finally {
      setLoading(false)
    }
  }, [])

  const createNodesAndEdges = useCallback(
    (data) => {
      if (
        !data
        || !data.nodes
        || !data.edges
        || !data.nodeFrequency
        || !data.heatMap
      ) {
        return
      }

      const { nodes, edges, nodeFrequency, heatMap } = data
      const frequencies = Object.values(nodeFrequency)
      const minFrequency = Math.min(...frequencies)
      const maxFrequency = Math.max(...frequencies)

      const newNodes = nodes.map(node => ({
        id: node.id,
        type: 'custom',
        data: {
          id: node.id,
          label: node.data.name,
          frequency: nodeFrequency[node.id] || 0,
          minFrequency,
          maxFrequency,
          prompt: node.data.prompt,
          onClick: setSelectedNodeId,
        },
        position: node.position,
      }))

      const maxEdgeCount = Math.max(...heatMap.map(h => h.count))
      const newEdges = edges.map((edge) => {
        const heatMapEntry = heatMap.find(
          h => h.source === edge.source && h.target === edge.target,
        )
        const thickness = heatMapEntry
          ? 1 + (heatMapEntry.count / maxEdgeCount) * 3
          : 1
        return {
          id: edge.id,
          source: edge.source,
          target: edge.target,
          type: 'custom',
          data: {
            label: heatMapEntry ? `${heatMapEntry.count}` : '',
            thickness,
          },
          animated: !!heatMapEntry,
        }
      })

      setNodes(newNodes)
      setEdges(newEdges)
    },
    [setNodes, setEdges],
  )

  const getLayoutedElements = useCallback((nodes, edges, direction = 'TB') => {
    const dagreGraph = new dagre.graphlib.Graph()
    dagreGraph.setDefaultEdgeLabel(() => ({}))
    const nodeWidth = 150
    const nodeHeight = 60
    const isHorizontal = direction === 'LR'
    dagreGraph.setGraph({ rankdir: direction })

    nodes.forEach((node) => {
      dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight })
    })

    edges.forEach((edge) => {
      dagreGraph.setEdge(edge.source, edge.target)
    })

    dagre.layout(dagreGraph)

    return nodes.map((node) => {
      const nodeWithPosition = dagreGraph.node(node.id)
      node.targetPosition = isHorizontal ? 'left' : 'top'
      node.sourcePosition = isHorizontal ? 'right' : 'bottom'
      node.position = {
        x: nodeWithPosition.x - nodeWidth / 2,
        y: nodeWithPosition.y - nodeHeight / 2,
      }
      return node
    })
  }, [])

  const onLayout = useCallback(
    (direction) => {
      const layoutedNodes = getLayoutedElements(nodes, edges, direction)
      setNodes([...layoutedNodes])
    },
    [nodes, edges, setNodes, getLayoutedElements],
  )

  useEffect(() => {
    if (nodes.length > 0 && edges.length > 0) {
      onLayout('TB')
    }
  }, [nodes, edges, onLayout])

  const toggleReplay = useCallback(() => {
    setIsPlaying(prev => !prev)
  }, [])

  const resetReplay = useCallback(() => {
    setReplayIndex(0)
    setIsPlaying(false)
  }, [])

  useEffect(() => {
    let intervalId
    if (isPlaying && selectedCallId && heatmapData) {
      const path = heatmapData.callPaths[selectedCallId].nodes
      if (path) {
        intervalId = setInterval(() => {
          setReplayIndex((prevIndex) => {
            if (prevIndex < path.length - 1) {
              return prevIndex + 1
            }
            else {
              setIsPlaying(false)
              return prevIndex
            }
          })
        }, replaySpeed)
      }
    }
    return () => clearInterval(intervalId)
  }, [isPlaying, selectedCallId, heatmapData, replaySpeed])

  useEffect(() => {
    if (selectedCallId && heatmapData) {
      const path = heatmapData.callPaths[selectedCallId].nodes
      if (path) {
        const newNodes = nodes.map(node => ({
          ...node,
          data: {
            ...node.data,
            isHighlighted:
              path?.includes(node.id) && path.indexOf(node.id) <= replayIndex,
          },
        }))
        const newEdges = edges.map(edge => ({
          ...edge,
          data: {
            ...edge.data,
            isHighlighted:
              path?.includes(edge.source)
              && path?.includes(edge.target)
              && path.indexOf(edge.source) < replayIndex
              && path.indexOf(edge.target) <= replayIndex,
          },
        }))
        setNodes(newNodes)
        setEdges(newEdges)
      }
    }
  }, [replayIndex, selectedCallId, heatmapData, nodes, edges])

  const filteredDialogue = useMemo(() => {
    if (!callLogs || !callLogs.dialogue)
      return []
    return callLogs.dialogue.filter(
      message => message.text && message.text.trim() !== '',
    )
  }, [callLogs])

  const currentNodeDialogue = useMemo(() => {
    if (!selectedNodeId || !filteredDialogue)
      return null
    return filteredDialogue.find(
      message => message.nodeId === selectedNodeId,
    )
  }, [selectedNodeId, filteredDialogue])

  return (
    <div className="h-screen  text-gray-900 ">
      {loading && <Loading loading={loading} />}

      {error ? (
        <div>
          <button
            onClick={handleBack}
            className="my-2.5  px-2.5 py-1.5 bg-gray-200 text-gray-700 rounded hover:bg-gray-300 transition-colors"
          >
            Back to Pathways
          </button>
          <div
            className="bg-red-50 border-l-4 border-red-500 text-red-700 p-2.5 mb-4"
            role="alert"
          >
            <p className="font-bold">Error</p>
            <p>{error}</p>
          </div>
        </div>
      ) : (
        warning && (
          <div>
            <button
              onClick={handleBack}
              className="my-2.5 px-2.5 py-1.5 bg-gray-200 text-gray-700 rounded hover:bg-gray-300 transition-colors"
            >
              Back to Pathways
            </button>
            <div
              className="bg-yellow-50 border-l-4 border-yellow-500 text-yellow-700 p-2.5 mb-4"
              role="alert"
            >
              <p className="font-bold">Analytics Unavailable</p>
              <p>{warning}</p>
              {/* Navigate to the Conversational Pathways page with the pathway id selected,/convo-pathways?id=278fc600-6a10-475f-b735-de3a0c7b4d44 */}
            </div>
            <Link
              className="px-2.5 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
              to={`/dashboard/convo-pathways?id=${selectedPathway.id}`}
              target="_blank"
            >
              View Pathway
            </Link>
          </div>
        )
      )}

      {!selectedPathway && (
        <div className="mb-8 mt-8">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {pathways.map(pathway => (
              <button
                key={pathway.id}
                onClick={() => {
                  setSelectedPathway(pathway)
                  fetchHeatmap(pathway.id)
                }}
                className="p-4 bg-white rounded-md shadow-sm border border-gray-200 hover:shadow-md transition-shadow text-left"
              >
                <h3 className="text-base font-semibold text-gray-800 mb-1.5">
                  {pathway.name}
                </h3>
                <p className="text-xs text-gray-600">{pathway.description}</p>
              </button>
            ))}
          </div>
        </div>
      )}

      {selectedPathway && heatmapData && (
        <div className="space-y-5">
          <div className="flex justify-between items-center">
            {/* <h2 className="text-xl font-semibold text-gray-800">Pathway: {selectedPathway.name}</h2> */}
            <button
              onClick={handleBack}
              className="px-2.5 py-1.5 bg-gray-200 text-gray-700 rounded hover:bg-gray-300 transition-colors"
            >
              Back to Pathways
            </button>
          </div>

          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4">
            <StatDisplay title="Total Calls" value={heatmapData.totalCalls} />
            <StatDisplay title="Unique Users" value={heatmapData.uniqueUsers} />
            {heatmapData.avgCallDuration && (
              <StatDisplay
                title="Avg. Call Duration"
                value={`${heatmapData.avgCallDuration.toFixed(2)}m`}
              />
            )}
            {/* <StatDisplay title="Conversion Rate" value={`${(heatmapData.conversionRate * 100).toFixed(2)}%`} /> */}
            <StatDisplay title="Total Nodes" value={heatmapData.totalNodes} />
            <StatDisplay title="Total Edges" value={heatmapData.totalEdges} />
          </div>

          <div className="flex gap-5">
            <div className="w-2/3 bg-white p-1.5 rounded-md shadow-sm border border-gray-200">
              <h3 className="text-lg font-semibold text-gray-800">
                Pathway Heatmap
              </h3>

              <div style={{ height: '400px' }}>
                <ReactFlow
                  nodes={nodes}
                  edges={edges}
                  onNodesChange={onNodesChange}
                  onEdgesChange={onEdgesChange}
                  nodeTypes={nodeTypes}
                  edgeTypes={edgeTypes}
                  fitView
                >
                  <Background color="#f0f0f0" gap={12} />
                  <Controls />
                  <MiniMap
                    nodeColor={node =>
                      getColor(
                        node.data.frequency,
                        node.data.minFrequency,
                        node.data.maxFrequency,
                      )}
                    maskColor="rgba(240, 240, 240, 0.6)"
                  />
                </ReactFlow>
              </div>
            </div>
            <div className="w-1/3 space-y-5">
              {/* <div className="bg-white p-4 rounded-md shadow-sm border border-gray-200">
                                <h3 className="text-base font-semibold mb-2.5 text-gray-800">Call Volume Trend</h3>
                                <ResponsiveContainer width="100%" height={200}>
                                    <LineChart data={heatmapData.timeSeries}>
                                        <CartesianGrid strokeDasharray="3 3" stroke="#e0e0e0" />
                                        <XAxis dataKey="date" stroke="#666" />
                                        <YAxis stroke="#666" />
                                        <Tooltip />
                                        <Line type="monotone" dataKey="calls" stroke="#3b82f6" strokeWidth={2} />
                                    </LineChart>
                                </ResponsiveContainer>
                            </div> */}
              <div className="bg-white p-4 rounded-md shadow-sm border border-gray-200">
                <h3 className="text-base font-semibold mb-2.5 text-gray-800">
                  Top 5 Most Traversed Paths
                </h3>
                <ul className="space-y-1.5">
                  {heatmapData.heatMap
                    .sort((a, b) => b.count - a.count)
                    .slice(0, 5)
                    .map((path, index) => (
                      <li
                        key={index}
                        className="flex justify-between items-center bg-gray-50 p-2 rounded-sm"
                      >
                        <span className="text-xs text-gray-700">
                          {path.source}
                          {' '}
                          →
                          {path.target}
                        </span>
                        <span className="font-semibold text-gray-900">
                          {path.count}
                        </span>
                      </li>
                    ))}
                </ul>
              </div>
              <div className="bg-white p-4 rounded-md shadow-sm border border-gray-200">
                <h3 className="text-base font-semibold mb-2.5 text-gray-800">
                  Call Logs
                </h3>
                <div className="h-40 overflow-y-auto">
                  {heatmapData.callIds.map(callId => (
                    <button
                      key={callId.c_id}
                      onClick={() => highlightPath(callId.c_id)}
                      className="w-full text-left p-1.5 hover:bg-gray-100 transition-colors text-gray-700"
                    >
                      {callId.c_id}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <Drawer isOpen={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
            {selectedCallId && (
              <ReplayLogs
                dialogue={filteredDialogue}
                replayIndex={replayIndex}
                isPlaying={isPlaying}
                replaySpeed={replaySpeed}
                onTogglePlay={toggleReplay}
                onClose={setIsDrawerOpen}
                onReset={resetReplay}
                onSpeedChange={handleSpeedChange}
              />
            )}
          </Drawer>

          {currentNodeDialogue && (
            <div className="bg-white p-4 rounded-md shadow-sm border border-gray-200">
              <h3 className="text-lg font-semibold mb-2.5 text-gray-800">
                Selected Node Dialogue
              </h3>
              <div className="p-2.5 rounded-sm bg-gray-50">
                <p className="font-medium text-gray-800">
                  {currentNodeDialogue.role === 'assistant' ? 'AI' : 'User'}
                </p>
                <p className="text-xs mt-1.5 text-gray-700">
                  {currentNodeDialogue.text}
                </p>
                <p className="text-2xs text-gray-500 mt-1.5">
                  Node:
                  {' '}
                  {currentNodeDialogue.nodeId}
                </p>
                <p className="text-2xs text-gray-500">
                  {new Date(currentNodeDialogue.timestamp).toLocaleString()}
                </p>
              </div>
            </div>
          )}

          <div className="bg-white p-4 rounded-md shadow-sm border border-gray-200">
            <h3 className="text-lg font-semibold mb-2.5 text-gray-800">
              Key Insights
            </h3>
            <ul className="space-y-2 text-xs">
              <li className="flex items-start">
                <ChevronRight className="flex-shrink-0 w-3 h-3 text-blue-500 mr-1.5 mt-px" />
                <div>
                  <span className="font-medium">Most traversed path:</span>
                  <span className="ml-0.5">
                    {heatmapData.mostCommonPath.source}
                  </span>
                  {' '}
                  to
                  <span className="ml-0.5 font-medium">
                    {heatmapData.mostCommonPath.target}
                  </span>
                  <span className="ml-0.5">
                    (
                    {heatmapData.mostCommonPath.count}
                    {' '}
                    times)
                  </span>
                </div>
              </li>
              <li className="flex items-start">
                <ChevronRight className="flex-shrink-0 w-3 h-3 text-blue-500 mr-1.5 mt-px" />
                <div>
                  <span className="font-medium">Busiest node:</span>
                  <span className="ml-0.5">
                    {
                      Object.entries(heatmapData.nodeFrequency).sort(
                        (a, b) => b[1] - a[1],
                      )[0][0]
                    }
                  </span>
                  <span className="ml-0.5">
                    (traversed
                    {' '}
                    {
                      Object.entries(heatmapData.nodeFrequency).sort(
                        (a, b) => b[1] - a[1],
                      )[0][1]
                    }
                    {' '}
                    times)
                  </span>
                </div>
              </li>
              <li className="flex items-start">
                <ChevronRight className="flex-shrink-0 w-3 h-3 text-blue-500 mr-1.5 mt-px" />
                <div>
                  <span className="font-medium">Least used node:</span>
                  <span className="ml-0.5">
                    {
                      Object.entries(heatmapData.nodeFrequency).sort(
                        (a, b) => a[1] - b[1],
                      )[0][0]
                    }
                  </span>
                  <span className="ml-0.5">
                    (traversed only
                    {' '}
                    {
                      Object.entries(heatmapData.nodeFrequency).sort(
                        (a, b) => a[1] - b[1],
                      )[0][1]
                    }
                    {' '}
                    times)
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      )}
    </div>
  )
}

export default PathwayHeatmap
