import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import { Input } from '@/components/ui/input'
import BlandLogo from 'assets/brand/new-bland-logo.svg'
import { PageWrapper } from 'components/core/PageWrapper'
import config from 'config'
import { AlertCircle, Loader2 } from 'lucide-react'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import report_bg from './components/report_bg.png'
import ReportPage from './MainReportPage'

export default function SharedReport() {
  const [isAuthorized, setIsAuthorized] = useState(false)
  const [password, setPassword] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const [reportData, setReportData] = useState(null)
  const { id } = useParams()

  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsLoading(true)
    try {
      const response = await fetch(`${config.API_URL}/v1/analytics/report/${id}`, {
        headers: {
          Authorization: password,
        },
      })

      if (response.ok) {
        setIsAuthorized(true)
        const data = await response.json()
        setReportData(data.data)
      }
      else {
        setError('Invalid password')
      }
    }
    catch (err) {
      setError('Failed to authenticate')
    }
    setIsLoading(false)
  }

  return (
    <>
      {!isAuthorized && (
        <div
          className="fixed inset-0 w-full h-full -z-10 mt-[136px]"
          style={{
            backgroundImage: `url(${report_bg})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            filter: 'blur(8px)',
            opacity: '0.4',
          }}
        />
      )}
      <div className="bg-white">
        <div className="flex justify-between items-center mb-5 mt-8 ml-[52px] mr-[40px]">
          <div className="flex items-center gap-1.5">
            <img src={BlandLogo} alt="Bland Logo" className="w-[180px] h-10" />
          </div>
          <div>
            <a
              href="https://bland.ai"
              target="_blank"
              rel="noopener noreferrer"
              className="px-2.5 py-1.5 bg-red-600 text-white rounded hover:bg-red-700 transition-colors"
            >
              Try Bland
            </a>
          </div>
        </div>
        <hr className="border-gray-200" />
      </div>

      <PageWrapper className="-mt-[40px] -ml-[20px]">
        {!isAuthorized
          ? (
              <Dialog open={!isAuthorized} modal>
                <DialogContent className="sm:max-w-[500px]">
                  <DialogHeader>
                    <DialogTitle className="text-lg font-semibold text-center">
                      Protected Report
                    </DialogTitle>
                    <p className="text-gray-500 text-center mt-1.5">
                      Please enter the password to access this report
                    </p>
                  </DialogHeader>
                  <form onSubmit={handleSubmit} className="space-y-2.5 py-4">
                    <div className="space-y-1.5">
                      {error && (
                        <div className="bg-red-50 border border-red-200 rounded p-2 mb-2.5">
                          <p className="text-red-600 text-xs flex items-center">
                            <AlertCircle className="h-2.5 w-2.5 mr-1.5" />
                            {error}
                          </p>
                        </div>
                      )}
                      <Input
                        type="password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        placeholder="Enter report password"
                        className={`w-full text-sm p-4 ${error ? 'border-red-200 focus:ring-red-500' : ''}`}
                      />
                    </div>
                    <Button
                      type="submit"
                      className="w-full text-sm py-4 mt-5 bg-gray-950 hover:bg-gray-700"
                      disabled={isLoading}
                    >
                      {isLoading
                        ? (
                            <span className="flex items-center justify-center">
                              <Loader2 className="animate-spin -ml-0.5 mr-2 h-3 w-3" />
                              Verifying...
                            </span>
                          )
                        : (
                            'Access Report'
                          )}
                    </Button>
                  </form>
                </DialogContent>
              </Dialog>
            )
          : (
              <ReportPage liveReport liveReportData={reportData} />
            )}
      </PageWrapper>
    </>
  )
}
