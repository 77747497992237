import { Info, ServerCog, Settings } from 'lucide-react'
import { Tooltip, Button as RadixButton } from '@radix-ui/themes'
import { useQuery } from '@tanstack/react-query'
import { Loading } from 'components/core/Loading'
import { $fetch } from 'utils/fetch'

function SMSConfigInfo({ agentNumber, isLoading }) {
  const { data: configData, isLoading: isLoadingConfig } = useQuery({
    queryKey: ['smsConfig', agentNumber],
    queryFn: async () => {
      if (!agentNumber) return null

      // url encode the agent number
      const encodedAgentNumber = encodeURIComponent(agentNumber)
      
      // Fetch SMS configuration for this phone number
      const inboundData = await $fetch(`/v1/inbound/${encodedAgentNumber}`)
      return inboundData?.sms_config || null
    },
    enabled: !!agentNumber && !isLoading,
    staleTime: 1000 * 60 * 5, // 5 minutes
  })

  if (isLoadingConfig) {
    return (
      <div className="mt-4 px-3 mx-auto max-w-2xl w-full">
        <div className="flex justify-center items-center py-4">
          <Loading loading={true} size="sm" />
        </div>
      </div>
    )
  }

  if (!configData) {
    return (
      <div className="mt-4 px-3 py-2 mx-auto max-w-2xl w-full">
        <div className="bg-gray-50 rounded-md p-3 flex items-center gap-2 text-gray-500 text-sm">
          <Info size={16} />
          <p>No SMS configuration found for this number.</p>
        </div>
      </div>
    )
  }

  // Determine if we're using objective or pathway
  const isUsingObjective = !!configData.objective
  
  const handleConfigClick = () => {
    // Navigate to the phone numbers dashboard with the appropriate params
    window.location.href = `/dashboard/phone-numbers?number=${encodeURIComponent(agentNumber)}&tab=sms`;
  }
  
  return (
    <div className="mt-2 px-3 mx-auto max-w-2xl w-full">
      <div className="bg-white rounded-md border border-gray-200">
        <div className="bg-gray-50 p-3 border-b border-gray-200 flex items-center justify-between">
          <div className="flex items-center gap-2">
            <ServerCog size={16} className="text-gray-600" />
            <h3 className="text-sm font-medium text-gray-700">SMS Configuration</h3>
          </div>
          <Tooltip content="Edit Phone Number SMS Config">
            <RadixButton
              variant="ghost"
              size="1"
              color="gray"
              style={{ cursor: 'pointer' }}
              onClick={handleConfigClick}
            >
              <Settings size={14} />
            </RadixButton>
          </Tooltip>
        </div>
        <div className="p-4">
          <div className="grid grid-cols-2 gap-4 text-sm">
            {isUsingObjective ? (
              <div className="col-span-2 flex flex-col gap-1">
                <p className="text-gray-500">Objective:</p>
                <p className="font-medium text-gray-800 break-words">
                  {configData.objective ? `${configData.objective.substring(0, 50)}...` : '-'}
                </p>
              </div>
            ) : (
              <>
                <div className="flex flex-col gap-1">
                  <p className="text-gray-500">Pathway ID:</p>
                  <p className="font-medium text-gray-800">{configData.pathway_id || ''}</p>
                </div>
                <div className="flex flex-col gap-1">
                  <p className="text-gray-500">Pathway Version:</p>
                  <p className="font-medium text-gray-800">{configData.pathway_version || '-'}</p>
                </div>
                <div className="flex flex-col gap-1">
                  <p className="text-gray-500">Start Node ID:</p>
                  <p className="font-medium text-gray-800">{configData.start_node_id || '-'}</p>
                </div>
              </>
            )}
            
            <div className="flex flex-col gap-1">
              <p className="text-gray-500">Temperature:</p>
              <p className="font-medium text-gray-800">{configData.temperature?.toFixed(1) || '-'}</p>
            </div>
            
            {/* Add request_data display */}
            {configData.request_data && Object.keys(configData.request_data).length > 0 && (
              <div className="col-span-2 flex flex-col gap-1">
                <p className="text-gray-500">Request Data:</p>
                <div className="bg-gray-50 p-2 rounded-md text-xs font-mono overflow-x-auto">
                  <pre className="whitespace-pre-wrap">{JSON.stringify(configData.request_data, null, 2)}</pre>
                </div>
              </div>
            )}
            
            {configData.webhook && (
              <div className="col-span-2 flex flex-col gap-1">
                <p className="text-gray-500">Webhook:</p>
                <p className="font-medium text-gray-800 break-words">{configData.webhook}</p>
              </div>
            )}
            
            {configData.tools && configData.tools.length > 0 && (
              <div className="col-span-2 flex flex-col gap-1">
                <p className="text-gray-500">Tools:</p>
                <div className="flex flex-wrap gap-2">
                  {configData.tools.map((tool, idx) => (
                    <span key={idx} className="bg-gray-100 text-gray-700 px-2 py-1 rounded-md text-xs">
                      {typeof tool === 'object' ? tool.name || JSON.stringify(tool) : tool}
                    </span>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SMSConfigInfo 