import { useMemo, useState } from 'react'

export default function useGlobalNodeOptions({ data, elements, nodeId }) {
  const initialData = {
    isGlobal: data.isGlobal || false,
    globalLabel: data.globalLabel || '',
    globalDescription: data.globalDescription || '',
    enableGlobalAutoReturn: data.enableGlobalAutoReturn !== false,
    prevNodePathwayLabel: data.prevNodePathwayLabel || '',
    prevNodePathwayDescription: data.prevNodePathwayDescription || '',
    enableReturnToPrevBeforeGlobal:
      data.enableReturnToPrevBeforeGlobal || false,
    isForwardingEnabled: data.forwardingNode || false,
    forwardingNode: data.forwardingNode || '',
  }

  const [isGlobal, setIsGlobal] = useState(initialData.isGlobal)
  const [globalPathwayLabel, setGlobalPathwayLabel] = useState(
    initialData.globalLabel,
  )
  const [globalPathwayDescription, setGlobalPathwayDescription] = useState(
    initialData.globalDescription,
  )
  const [enableGlobalAutoReturn, setEnableGlobalAutoReturn] = useState(
    initialData.enableGlobalAutoReturn,
  )
  const [prevNodePathwayLabel, setPrevNodePathwayLabel] = useState(
    initialData.prevNodePathwayLabel,
  )
  const [prevNodePathwayDescription, setPrevNodePathwayDescription] = useState(
    initialData.prevNodePathwayDescription,
  )
  const [enableReturnToPrevBeforeGlobal, setEnableReturnToPrevBeforeGlobal]
    = useState(initialData.enableReturnToPrevBeforeGlobal)
  const [isForwardingEnabled, setIsForwardingEnabled] = useState(
    initialData.isForwardingEnabled,
  )
  const [forwardingNode, setForwardingNode] = useState(
    initialData.forwardingNode,
  )

  const isChildOfGlobalNode = useMemo(() => {
    if (!elements.edges || isGlobal) {
      return false
    }
    const parentNodes = elements.edges
      .filter(edge => edge.target === nodeId)
      .map(edge => edge.source)

    const parentNodesData = elements.nodes?.filter(node =>
      parentNodes.includes(node.id),
    )

    return parentNodesData?.some(node => node.data.isGlobal)
  }, [elements, nodeId, isGlobal])

  const processGlobalNodeOptions = (nodeData, newEdges) => {
    const processedData = { ...nodeData }
    let processedEdges = [...newEdges]
    if (isGlobal && !isChildOfGlobalNode) {
      processedData.isGlobal = true
      processedData.globalLabel = globalPathwayLabel

      if (globalPathwayDescription) {
        processedData.globalDescription = globalPathwayDescription
      }
      else {
        delete processedData.globalDescription
      }

      if (!enableGlobalAutoReturn) {
        processedData.enableGlobalAutoReturn = false
        if (prevNodePathwayLabel) {
          processedData.prevNodePathwayLabel = prevNodePathwayLabel
        }
        if (prevNodePathwayDescription) {
          processedData.prevNodePathwayDescription = prevNodePathwayDescription
        }
      }
      else {
        // Remove ALL edges connected to this node when auto-return is enabled
        processedEdges = processedEdges.filter(
          edge => edge.target !== nodeId && edge.source !== nodeId,
        )
        delete processedData.enableGlobalAutoReturn
        delete processedData.prevNodePathwayLabel
        delete processedData.prevNodePathwayDescription
      }
    }
    else {
      delete processedData.isGlobal
      delete processedData.globalLabel
      delete processedData.globalDescription
      delete processedData.enableGlobalAutoReturn
      delete processedData.prevNodePathwayLabel
      delete processedData.prevNodePathwayDescription
    }

    if (enableReturnToPrevBeforeGlobal) {
      processedData.enableReturnToPrevBeforeGlobal = true
    }
    else {
      delete processedData.enableReturnToPrevBeforeGlobal
    }

    if (isForwardingEnabled && forwardingNode) {
      processedData.forwardingNode = forwardingNode
    }
    else {
      delete processedData.forwardingNode
    }

    return { processedData, processedEdges }
  }

  return {
    isGlobal,
    setIsGlobal,
    globalPathwayLabel,
    setGlobalPathwayLabel,
    globalPathwayDescription,
    setGlobalPathwayDescription,
    enableGlobalAutoReturn,
    setEnableGlobalAutoReturn,
    prevNodePathwayLabel,
    setPrevNodePathwayLabel,
    prevNodePathwayDescription,
    setPrevNodePathwayDescription,
    enableReturnToPrevBeforeGlobal,
    setEnableReturnToPrevBeforeGlobal,
    isChildOfGlobalNode,
    isForwardingEnabled,
    setIsForwardingEnabled,
    forwardingNode,
    setForwardingNode,
    processGlobalNodeOptions,
  }
}
