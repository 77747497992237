import { useEffect, useState } from 'react'
import { IoCheckmark } from 'react-icons/io5'

/**
 * ========== COLOR VARIABLES ==========
 * Update these in one spot as needed!
 */
const COLOR_GREEN = '#4BA796' // Pale green
const COLOR_YELLOW = '#FFC107' // Darker yellow
const COLOR_RED = '#CF364C' // Lighter red
const COLOR_DEFAULT = COLOR_GREEN // If no data for that day
const COLOR_PLACEHOLDER = '#E2E8F0' // A light grey for padded "days"

/**
 * Generate an array of date strings for the past N days (inclusive),
 * ending with today in "YYYY-MM-DD" format.
 */
function getLastNDays(n) {
  const days = []
  const today = new Date()
  for (let i = n - 1; i >= 0; i--) {
    const d = new Date(today)
    d.setDate(d.getDate() - i)
    days.push(d.toISOString().split('T')[0]) // "YYYY-MM-DD"
  }
  return days
}

/**
 * Convert an RSS pubDate (e.g. "Mon, 02 Dec 2024 16:30:15 -0800")
 * to "YYYY-MM-DD" for grouping by day.
 */
function toLocalDateString(pubDate) {
  const d = new Date(pubDate)
  return d.toISOString().split('T')[0]
}

/**
 * Determine a severity color for a single incident based on keywords.
 */
function getIncidentColor(title, description) {
  const text = (title + description).toLowerCase()

  if (text.includes('outage') || text.includes('tsunami') || text.includes('major')) {
    return COLOR_RED
  }
  else if (
    text.includes('degrad')
    || text.includes('partial')
    || text.includes('delay')
    || text.includes('warning')
  ) {
    return COLOR_YELLOW
  }
  return COLOR_GREEN // default
}

export default function AtlassianStyleStatusBar() {
  const [dayData, setDayData] = useState({}) // dayData[day] = { color, incidents: [...] }
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    async function fetchRSS() {
      try {
        const response = await fetch('https://status.bland.ai/history.rss')
        const xmlString = await response.text()

        const parser = new DOMParser()
        const doc = parser.parseFromString(xmlString, 'text/xml')
        const items = doc.querySelectorAll('channel > item')

        const tmpDayData = {}
        items.forEach((item) => {
          const title = item.querySelector('title')?.textContent || ''
          const description = item.querySelector('description')?.textContent || ''
          const pubDate = item.querySelector('pubDate')?.textContent || ''

          const dayStr = toLocalDateString(pubDate)
          if (!tmpDayData[dayStr]) {
            tmpDayData[dayStr] = {
              color: COLOR_GREEN,
              incidents: [],
            }
          }

          tmpDayData[dayStr].incidents.push({ title, description })

          const incColor = getIncidentColor(title, description)
          // Red overrides everything
          if (incColor === COLOR_RED) {
            tmpDayData[dayStr].color = COLOR_RED
          }
          // Yellow only overrides if the day's color isn't already red
          else if (incColor === COLOR_YELLOW && tmpDayData[dayStr].color !== COLOR_RED) {
            tmpDayData[dayStr].color = COLOR_YELLOW
          }
        })

        setDayData(tmpDayData)
        setIsLoading(false)
      }
      catch (error) {
        console.error('Error fetching RSS feed:', error)
        setIsLoading(false)
      }
    }

    fetchRSS()
  }, [])

  // Generate the last 90 days
  const last90Days = getLastNDays(90)

  // OPTIONAL LEFT PADDING:
  const leftPadDays = 10
  const paddedDays = Array.from({ length: leftPadDays }).fill(null).concat(last90Days)

  if (isLoading) {
    return (
      <div className="font-sans m-2.5">
        <p>Loading status…</p>
      </div>
    )
  }

  return (
    // w-full => fill parent horizontally
    <div className="w-full  p-2.5 font-sans">
      {/* Header row */}
      <div className="flex items-center justify-between mb-1.5">
        <h2 className="text-xs font-semibold flex items-center">
          <IoCheckmark className="text-white bg-green-400 rounded-full mr-1.5" />
          api.bland.ai
        </h2>
        <span className="text-xs font-semibold" style={{ color: COLOR_GREEN }}>
          99.978% uptime
        </span>
      </div>

      {/*
        The bar chart container:
        - We center all days using justify-center
        - flex-wrap => bars will wrap to a new line if they're too wide
        - ensures no horizontal scrollbar or overflow
      */}
      <div className="flex flex-wrap justify-center gap-0.5 p-1.5 mx-auto w-full">
        {paddedDays.map((day, idx) => {
          if (day === null) {
            // This is a “dummy” left-pad day
            return (
              <div
                key={`pad-${idx}`}
                title="(spacing placeholder)"
                className="w-[5px] h-9 rounded-sm"
                style={{ backgroundColor: COLOR_PLACEHOLDER }}
              />
            )
          }

          const color = dayData[day]?.color || COLOR_DEFAULT
          const incidents = dayData[day]?.incidents || []

          let tooltip = `Date: ${day} | `
          if (incidents.length === 0) {
            tooltip += 'No incidents'
          }
          else {
            const incDetails = incidents.map((inc, i2) => {
              const shortDesc = inc.description.slice(0, 50).replace(/\s+/g, ' ')
              return `#${i2 + 1}: ${inc.title} => ${shortDesc}...`
            })
            tooltip += incDetails.join(' | ')
          }

          return (
            <div
              key={day}
              title={tooltip}
              className="w-[8px] h-10 rounded-sm cursor-pointer"
              style={{ backgroundColor: color }}
            />
          )
        })}
      </div>

      {/* "90 days ago" and "Today" labels */}
      <div className="flex justify-between mt-0.5">
        <span className="text-2xs text-gray-600">90 days ago</span>
        <span className="text-2xs text-gray-600">Today</span>
      </div>
    </div>
  )
}
