import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { Loading } from 'components/core/Loading'
import { STRIPE_PUBLIC_KEY } from 'config/stripe'
import CheckoutForm from './CheckoutForm'

export default function Checkout({ clientSecret, amount, onSuccess }) {
  const stripePromise = loadStripe(STRIPE_PUBLIC_KEY)
  const loading = !clientSecret

  return (
    <>
      {loading
        ? (
            <Loading loading={loading} />
          )
        : (
            clientSecret && (
              <Elements stripe={stripePromise} options={{ clientSecret }}>
                <CheckoutForm amount={amount} onSuccess={onSuccess} />
              </Elements>
            )
          )}
    </>
  )
}
