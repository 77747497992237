import { KeyValuesToObj } from 'utils/formatting/ValuesToObj'

export function parseForJson(values, dirtyFields = {}) {
  // FILTER
  const { save_prompt_modal, prompt_modal, encrypted_key, ...formState } = values
  const from = values?.from?.length < 10 ? null : values?.from
  const transfer_phone_number
    = values?.transfer_phone_number?.length < 10 ? null : values?.transfer_phone_number

  // CALENDLY
  const calendly
    = values?.calendly_url?.length > 0
      ? {
          url: values?.calendly_url,
          timezone: values?.calendly_timezone,
        }
      : {}

  // ARRAY TRANSFORM
  const request_data = KeyValuesToObj(values.request_data)
  const analysis_schema = KeyValuesToObj(values.analysis_schema)
  const transfer_list = KeyValuesToObj(values.transfer_list)
  const metadata = KeyValuesToObj(values.metadata)
  const pathway_ids = KeyValuesToObj(values.pathway_ids)
  const dynamic_data = values.dynamic_data?.map((item) => {
    const newItem = {}
    for (const key in item) {
      if (key === 'response_data') {
        newItem[key] = item[key]
      }
      else {
        newItem[key] = Array.isArray(item[key])
          ? KeyValuesToObj(item[key])
          : item[key]
      }
    }
    return newItem
  })

  // INTEGER TRANSFORM
  const pathway_version = values?.pathway_version ? Number.parseInt(values?.pathway_version) : null

  const filteredFormState = Object.entries({
    ...formState,
    from,
    transfer_phone_number,
    calendly,
    request_data,
    analysis_schema,
    transfer_list,
    metadata,
    pathway_ids,
    dynamic_data,
    pathway_version,
  }).reduce((acc, [key, value]) => {
    // Always include phone_number and record regardless of their values
    if (key === 'phone_number' || key === 'record') {
      acc[key] = value
      return acc
    }

    // Check if value is empty or matches default
    const isEmptyArray = Array.isArray(value) && value.length === 0
    const isEmptyObject = typeof value === 'object' && value !== null && Object.keys(value).length === 0
    const isEmptyString = value === ''
    const isNullish = value === null || value === undefined
    const isEmpty = isEmptyArray || isEmptyObject || isEmptyString || isNullish

    const isDirty
      // Short circuit if directly true
      = dirtyFields[key] === true
      // For objects/arrays, check for nested "true" values
        || (dirtyFields[key] && typeof dirtyFields[key] === 'object'
          && JSON.stringify(dirtyFields[key]).includes('true'))

    // Include the field if:
    // 1. It's not empty, OR
    // 2. It's empty but the user explicitly changed it (it's dirty)
    if (!isEmpty || isDirty) {
      acc[key] = value
    }

    return acc
  }, {})

  return filteredFormState
}
