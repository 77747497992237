import { Input, Switch } from '@heroui/react'
import GradientLoadingAnimation from 'components/Reusables/GradientLoadingAnimation'
import { format } from 'date-fns'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { $fetch } from 'utils/fetch'
import { fetchPreviousCalls } from 'utils/funcs/call-logs/fetchPrevious'
import { getOrgId } from 'utils/funcs/browser/getOrgId'

function AddCallModal({ isOpen, onClose, phoneNumber, memoryId }) {
  const [selectedCall, setSelectedCall] = useState('')
  const [loadingPreviousCalls, setLoadingPreviousCalls] = useState(false)
  const [previousCalls, setPreviousCalls] = useState([])
  const [isInbound, setIsInbound] = useState(true)
  const [page, setPage] = useState(1)
  const [manualCallId, setManualCallId] = useState('')
  const [isAddingCall, setIsAddingCall] = useState(false)

  const fetchCalls = async () => {
    setLoadingPreviousCalls(true)
    try {
      const calls = await fetchPreviousCalls(getOrgId(), null, phoneNumber, isInbound ? 'to' : 'from', page)
      setPreviousCalls(calls)
    }
    catch (error) {
      console.error('Failed to fetch previous calls:', error)
      toast.error('Failed to fetch previous calls')
    }
    finally {
      setLoadingPreviousCalls(false)
    }
  }

  useEffect(() => {
    if (isOpen && phoneNumber) {
      fetchCalls()
      // Clear selected call when page changes
      setSelectedCall('')
    }
  }, [isOpen, phoneNumber, isInbound, page])

  const handleCallSelection = (callId) => {
    setSelectedCall(callId)
    // Clear manual call ID when a call is selected from the list
    setManualCallId('')
  }

  const handleAddSelectedCall = async () => {
    if (!memoryId) {
      toast.error('Memory ID is required')
      return
    }

    // Use either the selected call or manual call ID
    const callId = selectedCall || manualCallId.trim()
    
    if (!callId) {
      toast.error('No call selected')
      return
    }

    setIsAddingCall(true)
    try {
      // Call the API endpoint to add call to memory
      await $fetch(`/v1/memory/${memoryId}/add-call`, {
        method: 'POST',
        body: JSON.stringify({ call_id: callId }),
      })
      
      toast.success('Successfully added call to memory')
      onClose()
    } catch (error) {
      console.error('Failed to add call to memory:', error)
      toast.error('Failed to add call to memory')
    } finally {
      setIsAddingCall(false)
    }
  }

  // When manual call ID is entered, clear the selected call from the list
  const handleManualCallIdChange = (value) => {
    setManualCallId(value)
    if (value.trim()) {
      setSelectedCall('')
    }
  }

  if (!isOpen)
    return null

  return (
    <div className="fixed mt-10 inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
      <div className="bg-white rounded-lg shadow-xl max-w-5xl w-full p-6">
        <div className="flex justify-between items-center mb-4">
          <div>
            <h3 className="text-lg font-semibold">Add Previous Call</h3>
            <p className="text-sm text-gray-500">
              Phone number:
              {phoneNumber}
            </p>
          </div>
          <button className="text-gray-500 hover:text-gray-700" onClick={onClose}>&times;</button>
        </div>

        {/* Manual Call ID Input */}
        <div className="mb-6">
          <label htmlFor="manualCallId" className="block text-sm font-medium text-gray-700 mb-1">
            Add Call by ID
          </label>
          <div className="flex gap-2">
            <Input
              value={manualCallId}
              onValueChange={handleManualCallIdChange}
              placeholder="Enter call ID manually"
              className="flex-1 rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
          </div>
          <p className="mt-1 text-xs text-gray-500">
            Enter a call ID directly if you know it exists
          </p>
        </div>

        {/* Direction Toggle */}
        <div className="mb-6 flex items-center gap-2">
          <Switch
            isSelected={isInbound}
            onValueChange={(value) => {
              setIsInbound(value)
              setPage(1) // Reset to page 1 when changing direction
            }}
            className="relative inline-flex h-6 w-11 items-center rounded-full"
          >
            <span className="sr-only">Toggle call direction</span>
          </Switch>
          <span className="text-sm text-gray-700">
            {isInbound ? `Inbound Calls to ${phoneNumber}` : `Outbound Calls from ${phoneNumber}`}
          </span>
        </div>

        {/* Calls Table */}
        <div className="border rounded-lg overflow-hidden mb-6">
          {loadingPreviousCalls
            ? (
                <div className="flex justify-center p-8">
                  <GradientLoadingAnimation message="Loading calls..." />
                </div>
              )
            : (
                <div className="max-h-[400px] overflow-y-auto">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50 sticky top-0 z-10">
                      <tr>
                        <th scope="col" className="w-12 px-6 py-3 bg-gray-50">
                          Select
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Call ID
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          From
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          To
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Created At
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200 overflow-y-auto">
                      {previousCalls.map(call => (
                        <tr key={call.c_id} className="hover:bg-gray-50">
                          <td className="px-6 py-4 whitespace-nowrap">
                            <input
                              type="radio"
                              name="callSelection"
                              className="rounded-full border-gray-300"
                              checked={selectedCall === call.c_id}
                              onChange={() => handleCallSelection(call.c_id)}
                            />
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap font-mono text-sm text-gray-900">
                            {call.c_id}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {call.from}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {call.to}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {format(new Date(call.created_at), 'PPpp')}
                          </td>
                        </tr>
                      ))}
                      {previousCalls.length === 0 && (
                        <tr>
                          <td colSpan="5" className="px-6 py-4 text-center text-sm text-gray-500">
                            No calls found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              )}
        </div>

        {/* Pagination Controls */}
        <div className="flex justify-between items-center mb-4">
          <div>
            <span className="text-sm text-gray-500">
              {loadingPreviousCalls ? 'Loading...' : `Page ${page} ${previousCalls.length === 0 && page > 1 ? '(No more results)' : ''}`}
            </span>
          </div>
          <div className="flex space-x-2">
            <button
              type="button"
              className="px-3 py-1 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
              onClick={() => setPage(prev => Math.max(prev - 1, 1))}
              disabled={page === 1 || loadingPreviousCalls}
            >
              {loadingPreviousCalls ? '...' : 'Previous'}
            </button>
            <button
              type="button"
              className="px-3 py-1 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
              onClick={() => setPage(prev => prev + 1)}
              disabled={previousCalls.length === 0 || loadingPreviousCalls}
            >
              {loadingPreviousCalls ? '...' : 'Next'}
            </button>
          </div>
        </div>

        {/* Action Buttons */}
        <div className="flex justify-between items-center">
          <div className="text-sm text-gray-500">
            {selectedCall ? '1 call selected' : manualCallId.trim() ? '1 manual entry' : 'No call selected'}
          </div>
          <div className="flex space-x-3">
            <button
              type="button"
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
              onClick={onClose}
              disabled={isAddingCall}
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={handleAddSelectedCall}
              className="px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed"
              disabled={(!selectedCall && !manualCallId.trim()) || isAddingCall}
            >
              {isAddingCall ? 'Adding...' : 'Add Call'}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddCallModal
