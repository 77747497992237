import { Alert, Button, Link } from '@heroui/react'
import { useQuery } from '@tanstack/react-query'
import { Loading } from 'components/core/Loading'
import InboundForm from 'components/Dashboard/PhoneNumbers/Inbound/InboundForm'
import SMSForm from 'components/Dashboard/PhoneNumbers/Inbound/SMSForm'
import SendCallCTA from 'components/Dashboard/SendCall/SendCallCTA'
import SMSConfigSavedToast from 'components/Dashboard/SMS/SMSConfigSavedToast'
import SMSTestButton from 'components/Dashboard/SMS/SMSTestButton'
import SMSTestModal from 'components/Dashboard/SMS/SMSTestModal'
import AccordionForm from 'components/Hero/AccordionForm'
import LinearForm from 'components/Hero/LinearForm'
import { motion } from 'framer-motion'
import { useEffect, useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useSearchParams } from 'react-router-dom'
import { $fetch } from 'utils/fetch'
import { objectToKeyValue } from 'utils/formatting/objToKeyValues'

export default function Inbound({ submitting, selectedNumber, configJustSaved = false }) {
  const { reset, setValue } = useFormContext()
  const [searchParams] = useSearchParams()
  const activeTab = useMemo(() => searchParams.get('tab') || 'call', [searchParams])
  const [isTestModalOpen, setIsTestModalOpen] = useState(false)

  const isUSNumber = useMemo(() =>
    selectedNumber && selectedNumber.startsWith('+1'), [selectedNumber])

  const { data: details, isFetching } = useQuery({
    queryKey: ['inbound', selectedNumber],
    queryFn: () => $fetch(`/v1/inbound/${selectedNumber}`),
    enabled: !!selectedNumber,
  })

  const { data: encryptedKey } = useQuery({
    queryKey: ['encryptedKeys', details?.account_sid],
    queryFn: async () => {
      const { data } = await $fetch(`/byot/get_keys`, {
        params: {
          account_sid: details?.account_sid,
        },
      })
      return data?.[0]?.id || null
    },
    enabled: !!details?.account_sid,
  })

  useEffect(() => {
    if (details) {
      reset({
        ...details,
        tools: details.custom_tools?.length > 0 ? details.custom_tools : details.tools || [],
        temperature: details.temperature || 0.5,
        interruption_threshold: details.interruption_threshold || 100,
        model: details.model || 'base',
        language: details.language || 'en-US',
        request_data: objectToKeyValue(details.request_data ?? {}),
        transfer_list: objectToKeyValue(details.transfer_list ?? {}),
        pathway_ids: objectToKeyValue(details.pathway_ids ?? {}),
        analysis_schema: objectToKeyValue(details.analysis_schema ?? {}),
        metadata: objectToKeyValue(details.metadata ?? {}),
        memory_id: details.memory_id || null,
        task: details.prompt ?? details.task ?? null,
        sms_config: {
          tools: details.sms_config?.tools || [],
          temperature: details.sms_config?.temperature || 0,
          model: details.sms_config?.model || null,
          request_data: objectToKeyValue(details.sms_config?.request_data || details.request_data || {}),
          pathway_id: details.sms_config?.pathway_id || null,
          pathway_version: details.sms_config?.pathway_version || null,
          start_node_id: details.sms_config?.start_node_id || null,
          objective: details.sms_config?.objective || null,
          time_out: details.sms_config?.time_out || null,
          webhook: details.sms_config?.webhook || null,
          prompt: details.sms_config?.prompt || null,
          messaging_service_sid: details.sms_config?.messaging_service_sid || details.messaging_service_sid || null,
        },
      })
    }
  }, [details])

  useEffect(() => {
    if (encryptedKey) {
      setValue('encrypted_key', encryptedKey)
    }
  }, [encryptedKey, setValue])

  if (isFetching) {
    return <Loading loading={isFetching} />
  }

  return (
    <div className="w-full overflow-x-hidden">
      {activeTab === 'sms' && (
        <SMSConfigSavedToast
          configJustSaved={configJustSaved}
          onTestSMS={() => setIsTestModalOpen(true)}
        />
      )}

      {activeTab === 'call' && (
        <AccordionForm formDefinition={InboundForm} className="pb-[54px]" />
      )}
      {activeTab === 'sms' && (
        <motion.div
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3 }}
          className="w-full overflow-hidden"
        >
          <div className="py-3 px-4 flex flex-col gap-2 w-full">
            {encryptedKey && (
              <>
                <Alert
                  radius="none"
                  classNames={{ base: 'max-h-fit' }}
                  color="warning"
                  description="Messaging Service SID is required for BYOT numbers. Your SMS functionality will not work without it"
                  title="BYOT Configuration Required"
                />
                {isUSNumber && (
                  <Alert
                    radius="none"
                    classNames={{ base: 'max-h-fit' }}
                    color="warning"
                    description="You must also enable A2P (Application-to-Person) 10DLC compliance for this number in your Twilio account, or SMS will not work properly."
                    title="US Number Detected"
                  />
                )}
              </>
            )}

          </div>
          <LinearForm
            formDefinition={SMSForm}
            className="pb-24 px-4"
          />
        </motion.div>
      )}

      <SendCallCTA
        type={activeTab === 'sms' ? 'sms' : 'inbound'}
        isLoading={submitting}
      >
        {activeTab === 'sms' && (
          <>
            <Button showAnchorIcon as={Link} href="/dashboard/sms" size="sm" variant="solid" color="primary">
              SMS Dashboard
            </Button>
            {/* <SMSTestButton
              selectedNumber={selectedNumber}
              disabled={submitting}
              onClick={() => setIsTestModalOpen(true)}
            /> */}
          </>
        )}
      </SendCallCTA>

      <SMSTestModal
        isOpen={isTestModalOpen}
        onClose={() => setIsTestModalOpen(false)}
        selectedNumber={selectedNumber}
      />
    </div>
  )
}