import React from 'react'

const LibrarySidebarContext = React.createContext({
  showLibrarySidebar: false,
  setShowLibrarySidebar: () => {},
  currentOpenNodeID: null,
  setCurrentOpenNodeID: () => {},
})

export default LibrarySidebarContext
