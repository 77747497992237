import config from 'config'
import { getAuthToken } from 'utils/funcs/browser/getAuthToken'
import { getOrgId } from 'utils/funcs/browser/getOrgId'

export function getNodeOptions(elements, message) {
  const nodeOptions = elements.edges
    ?.filter(edge => edge.source === message.promptInfo['Previous Node ID'])
    ?.map(edge => ({
      id: edge.target,
      label: edge?.data?.label,
    }))

  elements.nodes?.forEach((node) => {
    for (let i = 0; i < nodeOptions.length; i++) {
      if (node.id === nodeOptions[i].id) {
        nodeOptions[i].node_name = node.data.name
      }
    }

    if (node.data.globalLabel) {
      nodeOptions.push({
        id: node.id,
        label: node.data.globalLabel,
        node_name: node.data.name,
      })
    }
  })

  return nodeOptions
}

export async function handleGenerateAlternatives(setIsLoading, setAlternativeResponses, setStep, user, message, toast) {
  const orgId = getOrgId()
  setIsLoading(true)
  try {
    const response = await fetch(`${config.API_URL}/v1/pathway/respond`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'authorization': getAuthToken(),
        ...(orgId && { 'x-bland-org-id': orgId }),
      },
      body: JSON.stringify({
        globalPrompt: message.promptInfo['Global Prompt'],
        dialoguePrompt: message.promptInfo['Current Node Prompt/Text'],
        dialogueExamples: message.promptInfo['Dialogue Example'],
        chatHistory: message.promptInfo['Conversation History'],
      }),
    })

    const data = await response.json()

    const responses = data.anonymizedResponses.map(
      response => response?.output ?? 'Failed generation',
    )

    if (response.ok) {
      setAlternativeResponses(responses)
      setStep(2)
    }
    else {
      console.error('Error generating alternatives:', data)
      toast.error('Failed to generate alternative responses')
    }
  }
  catch (error) {
    console.error('Error generating alternatives:', error)
    toast.error('Failed to generate alternative responses')
  }
  finally {
    setIsLoading(false)
  }
}

export function addToFinetuningExample(issueType, nodes, message, selectedResponseIndex, alternativeResponses, customResponse, isConditionCorrect, selectedNode, nodeOptions) {
  let updatedNodes = [...nodes]

  if (issueType === 'hallucination') {
    const currentNodeId = message.promptInfo['Current Node ID']
    updatedNodes = updatedNodes.map((node) => {
      if (node.id === currentNodeId) {
        return {
          ...node,
          data: {
            ...node.data,
            dialogueExamples: [
              ...(node.data.dialogueExamples || []),
              {
                'Conversation History':
                  message.promptInfo['Conversation History'],
                'Assistant Response':
                  selectedResponseIndex !== null
                    ? alternativeResponses[selectedResponseIndex]
                    : customResponse,
              },
            ],
          },
        }
      }
      return node
    })
  }
  else if (issueType === 'wrong_node') {
    if (isConditionCorrect === false) {
      const nodeId = message.promptInfo['Previous Node ID']
      updatedNodes = updatedNodes.map((node) => {
        if (node.id === nodeId) {
          return {
            ...node,
            data: {
              ...node.data,
              conditionExamples: [
                ...(node.data.conditionExamples || []),
                {
                  'Conversation History':
                    message.promptInfo['Conversation History'],
                  'Condition Achieved': 'false',
                },
              ],
            },
          }
        }
        return node
      })
    }
    else if (isConditionCorrect === true && selectedNode) {
      const nodeId = message.promptInfo['Previous Node ID']
      updatedNodes = updatedNodes.map((node) => {
        if (node.id === nodeId) {
          return {
            ...node,
            data: {
              ...node.data,
              pathwayExamples: [
                ...(node.data.pathwayExamples || []),
                {
                  'Conversation History':
                    message.promptInfo['Conversation History'],
                  'Chosen Pathway': nodeOptions.find(
                    option => option.id === selectedNode,
                  )?.label,
                },
              ],
            },
          }
        }
        return node
      })
    }
  }

  return updatedNodes
}

export async function handleSubmit(issueType, callID, graphID, message, selectedResponseIndex, alternativeResponses, customResponse, user, isConditionCorrect, selectedNode, setIsLoading, onClose, toast) {
  const orgId = getOrgId()
  setIsLoading(true)
  try {
    if (issueType === 'hallucination') {
      const data = {
        call_id: callID,
        pathway_id: graphID,
        bad_output: message.content,
        expected_output:
          selectedResponseIndex !== null
            ? alternativeResponses[selectedResponseIndex]
            : customResponse,
        global_prompt: message.promptInfo['Global Prompt'],
        prompt: message.promptInfo['Current Node Prompt/Text'],
        dialogue_example: message.promptInfo['Dialogue Example'],
        chat_history: message.promptInfo['Conversation History'],
        model_responses: alternativeResponses,
        selected_model_index: selectedResponseIndex,
      }

      const response = await fetch(`${config.API_URL}/v1/pathway/flag_node`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'authorization': getAuthToken(),
          ...(orgId && { 'x-bland-org-id': orgId }),
        },
        body: JSON.stringify(data),
      })

      if (response.ok) {
        toast.success('Response flagged successfully')
        onClose()
      }
      else {
        console.error('Error flagging response:', response.statusText)
        toast.error('Failed to flag response')
      }
    }
    else if (issueType === 'wrong_node') {
      // Implement wrong node submission logic here if needed
      toast.success('Node issue reported successfully')
      onClose()
    }
  }
  catch (error) {
    console.error('Error flagging response:', error)
    toast.error(
      issueType === 'hallucination'
        ? 'Failed to flag response'
        : 'Failed to report node issue',
    )
  }
  finally {
    setIsLoading(false)
  }
}

export function getDialogTitle(step, issueType) {
  if (step === 1) {
    return 'What\'s wrong with this response?'
  }
  else if (issueType === 'hallucination') {
    return 'Fine-tune the correct response'
  }
  else {
    return 'Report Wrong Node Issue'
  }
}
