import { useEffect } from 'react'

export default function Enterprise() {
  function getCookieValue(cookieName) {
    const name = `${cookieName}=`
    const decodedCookie = decodeURIComponent(document.cookie)
    const ca = decodedCookie.split(';')

    console.log({
      name,
      cookieName,
      decodedCookie,
      ca,
      cookies: document.cookie,
    })

    for (let i = 0; i < ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) === ' ') {
        c = c.substring(1)
      }

      if (c.indexOf(name) === 0) {
        console.log({ csub: c.substring(name.length, c.length) })
        return c.substring(name.length, c.length)
      }
    }

    return ''
  }

  function extractUTMParams(url) {
    let utmParams = ''
    const urlObj = new URL(url)
    const searchParams = new URLSearchParams(urlObj.search)
    const utmKeys = [
      'utm_source',
      'utm_medium',
      'utm_campaign',
      'utm_term',
      'utm_content',
    ]
    utmKeys.forEach((key) => {
      if (searchParams.has(key)) {
        utmParams += `${key}=${searchParams.get(key)}&`
      }
    })

    return utmParams.slice(0, -1)
  }

  window.addEventListener('load', (event) => {
    console.log({ event })
    const landingUrl = window.location.href
    console.log({ landingUrl })
    let utmParams = ''
    if (landingUrl) {
      utmParams = extractUTMParams(landingUrl)
    }

    console.log({ landingUrl, utmParams })

    const iframe = document.querySelector('iframe')
    const iframeSrc = iframe.src
    iframe.src = `${iframeSrc}?${utmParams}`
  })

  const _hsq = (window._hsq = window._hsq || [])
  console.log({ _hsq })

  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://js.hs-scripts.com/45282614.js'
    script.async = true

    document.body.appendChild(script)
    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        margin: 0,
        padding: 0,
        overflow: 'hidden',
      }}
    >
      <iframe
        title="default-form"
        style={{
          border: 'none',
          height: '100%',
          width: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
        }}
        src="https://forms.default.com/361589"
        allowFullScreen
      >
      </iframe>
    </div>
  )
}
