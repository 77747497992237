import React from 'react';
import { Input, Textarea } from '@heroui/react';
import { FieldType } from './ActionSchemas';

/**
 * A component that dynamically renders form fields based on their type and configuration
 */
const DynamicFormField = ({ field, value, onChange }) => {
  const { id, type, label, placeholder, description, required, defaultValue } = field;

  const handleChange = (e) => {
    let newValue;
    
    if (e && e.target && e.target.value !== undefined) {
      newValue = e.target.value;
    } else if (e !== undefined) {
      newValue = e;
    } else {
      return;
    }
    
    onChange( newValue);
  };
  
  const commonProps = {
    id,
    placeholder,
    required,
    onChange: handleChange,
    value: value || '',
    label: label + (required ? ' *' : ''),
    labelPlacement: "outside",
    variant: "bordered",
    size: "sm",
    description: description,
    fullWidth: true,
    className: "w-full",
  };

  // Handle field rendering based on type
  switch (type) {
    case FieldType.TEXT:
      return <Input {...commonProps} />;
      
    case FieldType.TEXTAREA:
      return <Textarea minRows={3} {...commonProps} />;
      
    default:
      return null; // Unknown field type
  }
};

export default DynamicFormField; 